// Library
import React from 'react';

// Context
import { useComponentsPool } from '../../../ComponentsPool';

const MiddleSection = () => {
    const { Component } = useComponentsPool();
    return (
        <div className="middle-section">
            <Component
                componentName={'LeftSection'}
            />
            <Component
                componentName={'CenterSection'}
            />
            <Component
                componentName={'RightSection'}
            />
        </div>
    );
};

export default MiddleSection;

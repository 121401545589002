// Libs
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Component
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const TogglePrioritiesButton = props => {
    const [t] = useTranslation();
    const [priority, setPriority] = useState(props.value);

    const changePriority = (event, newPriority) => {
        if (newPriority !== null) {
            setPriority(newPriority);
            props.onChange(newPriority)
        }
    };

    return (
        <div className='select-wrapper' style={{marginTop: 20, marginBottom: 20}}>
            <ToggleButtonGroup
                exclusive
                value={priority}
                onChange={changePriority}
                classes={{
                    root: 'toggle_button_group'
                }}
            >
                <ToggleButton
                    classes={{
                        root: 'toggle_button',
                        selected: 'toggle_button-selected priority_0',
                        label: 'toggle_button_label'
                    }}
                    value={0}
                >
                    {t('tickets:LOW')}
                </ToggleButton>
                <ToggleButton
                    classes={{
                        root: 'toggle_button',
                        selected: 'toggle_button-selected priority_1',
                        label: 'toggle_button_label'
                    }}
                    value={1}
                >
                    {t('tickets:MEDIUM')}
                </ToggleButton>
                <ToggleButton
                    classes={{
                        root: 'toggle_button',
                        selected: 'toggle_button-selected priority_2',
                        label: 'toggle_button_label'
                    }}
                    value={2}
                >
                    {t('tickets:HIGH')}
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
    )
};

export default TogglePrioritiesButton;

import {
    find, concat, includes, uniq, isEmpty
} from 'lodash';
import { getGroupNameFromId } from '../groups/helper';
import moment from "moment";
import { getWeekDays } from '../rounds/helper';
import { getLastReceivedDeviceData } from './actions';

// Get a device from its id
export const getDeviceFromId = (devices, id) => find(devices, { device_id: id });

export const getDeviceFromReference = (devices, reference) => find(devices, { device_reference: reference });

// Get a device name from his id
export const getDeviceNameFromId = (devices, device_id) => {
    const device = find(devices, { device_id });
    if (device) return device.device_name || device.device_reference;
    return '';
};

// Get all DEVICES from a group
export const getDeviceListByGroupId = (list, groupId) => {
    const devices = [];
    list.forEach(device => {
        if (device.group_ids.length > 0 && device.group_ids.includes(groupId)) {
            devices.push(device);
        }
    });
    return devices;
};

// Get all DEVICES ID from a group
export const getDeviceIdsByGroupId = (list, groupId) => {
    const devices = [];
    list.forEach(device => {
        if (device.group_ids.length > 0 && device.group_ids.includes(groupId)) {
            devices.push(device.device_id);
        }
    });
    return devices;
};

export const hotDeskingGroupDevices = ['SMARTDESK', 'BUTTON_RESET', 'LED'];
export const presenceLedGroupDevices = ['SMARTDESK', 'LED'];

export const getDeviceGroupIcon = group => {
    if (group.devices?.some(device => device.custom_field.type === 'BUTTON_SATISFACTION')) {
        return 'BUTTON_SATISFACTION';
    }
    if (group.devices?.some(device => device.custom_field.type === 'BUTTON_POLL')) {
        return 'BUTTON_POLL';
    }
    if (group.devices?.some(device => device.custom_field.type === 'BUTTON_RATING')) {
        return 'BUTTON_RATING';
    }
    if (group.devices?.every(device => presenceLedGroupDevices.includes(device.custom_field.type))) {
        return 'PRESENCE_LED_DEVICE_GROUP';
    }
    if (group.devices?.every(device => hotDeskingGroupDevices.includes(device.custom_field.type))) {
        return 'HOT_DESKING';
    }
    return 'DEVICE_GROUP';
};

// Transform an array of ids into array of name (for debug purposes only)
export const withNames = (devices, deviceIds) => {
    const names = [];
    deviceIds.forEach(group => { names.push(getDeviceNameFromId(devices, group)); });
    return names;
};

export const getDevicesOfGroup = (devices, groups, groupId) => {
    const fetchDevices = (id, checkedGroups, savedDevices) => {
        const selectedGroup = find(groups, group => group.group_id === id);
        if (includes(checkedGroups, id)) return savedDevices;
        const allGroups = concat(checkedGroups, id);
        let allDevices = concat(savedDevices, getDeviceIdsByGroupId(devices, id));
        if (selectedGroup) {
            selectedGroup.group_ids.forEach(group => {
                allDevices = uniq(concat(allDevices, fetchDevices(group, allGroups, allDevices)));
            });
        }
        return allDevices;
    };
    return fetchDevices(groupId, [], []);
};

export const getGroupsOfDevices = devicesList => {
    let alreadySelectedGroups = [];
    devicesList.forEach(device => {
        alreadySelectedGroups = concat(alreadySelectedGroups, device.group_ids);
    });
    return uniq(alreadySelectedGroups);
};

// Get a device name from his reference
export const getDeviceNameFromReference = (devices, device_reference) => {
    const device = find(devices, { device_reference });
    return device ? device.device_name || device.id : device_reference;
};

export const hasSatisfactionDevice = deviceList => deviceList?.some(group => group.devices?.some(device => device.custom_field.type === 'BUTTON_SATISFACTION'));

export const hasRatingDevice = deviceList => deviceList?.some(group => group.devices?.some(device => device.custom_field.type === 'BUTTON_RATING'));

export const getDeviceMainParameters = (device, groupList) => {
    if (device) {
        return {
            device_id: device.device_id,
            device_name: device.device_name,
            building: {
                id: device.custom_field?.batiment_group_id,
                name: getGroupNameFromId(groupList, device.custom_field?.batiment_group_id)
            },
            floor: {
                id: device.custom_field.floor_group_id,
                name: getGroupNameFromId(groupList, device.custom_field?.floor_group_id)
            },
            position: {
                id: device.custom_field.position_group_id,
                name: getGroupNameFromId(groupList, device.custom_field?.position_group_id)
            }
        };
    }
    return {};
};


export const hasLWPAreaConfig = data_points => {
    return data_points.hasOwnProperty('radius') && data_points.hasOwnProperty('coordinates');
};

export const retrieveLwpAreaConfig = (dispatch, devices, instanceData) => { // Retrieve LWP Area Config to display the right text on passages
    if (instanceData) { // We retrieve the last status only if we don't already have the last status of devices related to the instance
        let lwpIdsOnInstance = instanceData.filter(data => {
            const device = getDeviceFromId(devices, data?.event_detail?.device_id);
            return device && device.custom_field.type === 'LWP';
        }).map(data => data.event_detail.device_id);
        lwpIdsOnInstance = [...new Set(lwpIdsOnInstance)]; // Remove duplicates
        if (lwpIdsOnInstance.length > 0) {
            const lwpWithLastDataRetrieved = devices.filter(device => lwpIdsOnInstance.includes(device.device_id)
                && device.data?.find(data => data?.data_type === 'geofence_config')
            );
            // Retrieve the "geofence_config" of LWP devices (to display the right validated text on passages) + only if it wasn't already retrieved
            if (lwpWithLastDataRetrieved.length !== lwpIdsOnInstance.length) { dispatch(getLastReceivedDeviceData('LWP')); }
        }
    } else { // We retrieve the last status if at least one of the devices on the workspace doesn't have its last status
        const lwpWithoutLastDataRetrieved = devices.filter(device => device.custom_field?.type === 'LWP'
            && !device.data?.find(data => data?.data_type === 'geofence_config')
        );
        if (lwpWithoutLastDataRetrieved.length > 0) { dispatch(getLastReceivedDeviceData('LWP')); }
    }
};


export const isQrcodeOrTaqtDeviceType = deviceType => deviceType === 'QRCODE' || deviceType === 'TAQT';

export const getPassageDeviceTypes = () => ['TAQT', 'PASSAGE_BUTTON', 'LWP'];

export const isPassageDeviceType = device => device.custom_field?.type === 'QRCODE' && device.custom_field?.config?.modes?.includes('PASSAGE_VALIDATION')
    || getPassageDeviceTypes().includes(device.custom_field?.type);

export const isQRCodePassageDeviceType = device => device.custom_field?.type === 'QRCODE' && device.custom_field?.config?.modes?.includes('PASSAGE_VALIDATION');

export const isQRCodeDeclarativeDeviceType = device => device?.custom_field?.type === 'QRCODE' && device.custom_field?.config?.modes?.includes('ISSUE_REPORT');

export const isQRCodeDocumentDeviceType = device => device?.custom_field?.type === 'QRCODE' && device.custom_field?.config?.modes?.includes('DOCUMENT');

export const allQRCodeModes = ['PASSAGE_VALIDATION', 'PASSAGE_VISUALIZATION', 'ISSUE_REPORT', 'DOCUMENT', 'EMERGENCY_CALL'];

export const isLargerModeIconStyle = mode => ['ISSUE_REPORT'].includes(mode);

export const getPassageDevices = deviceList => deviceList
    .filter(device => isPassageDeviceType(device)
        && device.custom_field?.batiment_group_id
        && device.custom_field?.floor_group_id
        && device.custom_field?.position_group_id);

export const getDocumentQRCodes = deviceList => deviceList
    .filter(device => isQRCodeDocumentDeviceType(device)
        && device.custom_field?.batiment_group_id
        && device.custom_field?.floor_group_id
        && device.custom_field?.position_group_id);

export const roundDeviceMatchesJobsIds = (device, jobs) => isQRCodePassageDeviceType(device)
        && device.custom_field?.batiment_group_id
        && device.custom_field?.floor_group_id
        && device.custom_field?.position_group_id
        && device.custom_field?.job_group_ids
        && jobs.every(job => device.custom_field?.job_group_ids?.includes(job));

export const getPassageDevicesWithJobsIds = (deviceList, jobs) => deviceList
    .filter(device => roundDeviceMatchesJobsIds(device, jobs));

export const getDevicesInfosFromDevices = (devices, groupList) => {
    const result = [];
    devices.forEach(device => {
        if (device) {
            result.push({
                id: device.device_id,
                device_id: device.device_id,
                hardware_ids: device.hardware_ids,
                device_name: device.device_name,
                batiment_group_id: device.custom_field?.batiment_group_id,
                batiment_group_name: getGroupNameFromId(groupList, device.custom_field?.batiment_group_id),
                floor_group_id: device.custom_field?.floor_group_id,
                floor_group_name: getGroupNameFromId(groupList, device.custom_field?.floor_group_id),
                position_group_id: device.custom_field?.position_group_id,
                position_group_name: getGroupNameFromId(groupList, device.custom_field?.position_group_id)
            });
        }
    });
    return result;
};

export const getDeviceListFromIds = (deviceList, deviceIds) => {
    const result = [];
    deviceIds.forEach(deviceId => result.push(getDeviceFromId(deviceList, deviceId)));
    return result;
};

export const getCondition = (conditions, data_type) => {
    let result = {};
    if (conditions) {
        conditions.forEach(condition => {
            if (condition.code === 'Temp' && data_type === 'temperature') result = condition;
            if (condition.code === 'Humi' && data_type === 'humidity') result = condition;
            if (condition.code === 'CO2' && data_type === 'co2') result = condition;
            if (condition.code === 'passage' && data_type === 'people') result = condition;
            if (condition.code === 'Lumi' && data_type === 'luminosity') result = condition;
            if (condition.status === 'BATTERY' && data_type === 'battery_level') result = condition;
        });
    }
    return result;
};

export const getState = (conditions, conditionType, data_type, value) => {
    const condition = getCondition(conditions, data_type);
    if (condition && conditionType === 'LIMITE') {
        return condition.limit_min !== null && value < condition.limit_min
            || condition.limit_max !== null && value > condition.limit_max;
    }
    return false;
};

export const getDeviceDataTypeFromCode = code => {
    if (code === 'Temp') {
        return 'temperature';
    }
    if (code === 'Humi') {
        return 'humidity';
    }
    if (code === 'CO2') {
        return 'co2';
    }
    if (code === 'passage') {
        return 'people';
    }
    return '';
};

export const isDeviceInAnomaly = device => {
    if (device.custom_field.type === 'SMARTDESK') {
        const valueDevice = device.data.find(el => el.data_type === 'status')?.data_points[0].value;
        if (valueDevice === 'TO CLEAN') return true;
    }
    if (device.custom_field.type.includes('TORK_')) {
        const valueDevice = device.data.find(el => el.data_type === 'status')?.data_points[0].value;
        if (valueDevice === 'Red') return true;
    }
    if (isEmpty(device.conditions)) {
        return false;
    }
    if (['AIRWITS', 'AIRWITS_CO2', 'TES-5MRO'].includes(device.custom_field.type)) {
        const humidityCode = device.conditions.find(condition => condition?.code === 'Humi').code;
        const tempCode = device.conditions.find(condition => condition?.code === 'Temp').code;
        let humidityState = false;
        let temperatureState = false;
        let co2State = false;

        if(humidityCode) {
            const dataType = getDeviceDataTypeFromCode(humidityCode);
            const obj = device.data.find(data => data.data_type === dataType);
            if (obj && obj.data_points?.length > 0) {
                const value = obj.data_points[0]?.value;
                humidityState = getState(device.conditions, 'LIMITE', dataType, value);
            }
        }

        if(tempCode) {
            const dataType = getDeviceDataTypeFromCode(tempCode);
            const obj = device.data.find(data => data.data_type === dataType);
            if (obj && obj.data_points?.length > 0) {
                const value = obj.data_points[0]?.value;
                temperatureState = getState(device.conditions, 'LIMITE', dataType, value);
            }
        }
        if (device.custom_field.type === 'AIRWITS_CO2') {
            const co2Code = device.conditions.find(condition => condition?.code === 'CO2').code;
            if (co2Code) {
                const dataType = getDeviceDataTypeFromCode(co2Code);
                const obj = device.data.find(data => data.data_type === dataType);
                if (obj && obj.data_points?.length > 0) {
                    const value = obj.data_points[0]?.value;
                    co2State = getState(device.conditions, 'LIMITE', dataType, value);
                }
            }
        }
        return humidityState || temperatureState || co2State;
    }
    if (device.custom_field.type === 'LUMINOSITY') {
        const luminosityCondition = device.conditions.filter(condition => condition?.code === 'Lumi');
        const conditions = luminosityCondition.map(condition => ({
            start : condition.hour_start,
            end : condition.hour_end,
            lux_min : condition.limit_min,
            lux_max : condition.limit_max
        }));

        const luminosity = device.data.find(el => el.data_type === 'luminosity')?.data_points[0].value;
        const luminosityLastTimestamp = moment(device.data.find(el => el.data_type === 'luminosity')?.data_points[0].timestamp).format('HH:mm:ss');

        return conditions.some(condition => {
            if (condition.start !== null && condition.start <= luminosityLastTimestamp
                && condition.end !== null && luminosityLastTimestamp <= condition.end) {
                if (condition.lux_min !== null && luminosity < condition.lux_min
                    || condition.lux_max !== null && luminosity > condition.lux_max) {
                    return true;
                }
                return false;
            }
            return false;
        });
    }
    if (device.custom_field.type === 'SENSE_Dry_Contact_4_v1') {
        const valueDevice = device.data.find(el => el.data_type === 'status')?.data_points[0].value;

        if (valueDevice === 'error' || valueDevice === 'OUVERT') return true;
    }
    if (device.custom_field.type === 'TEMPERATURE_CONNIT') {
        if (device.conditions?.length > 0) {
            const condMax = device.conditions[0].limit_max;
            const condMin = device.conditions[0].limit_min;
            const valueDevice = device.data.find(el => el.data_type === 'temperature')?.data_points[0].value;
            if (condMin !== null && valueDevice < condMin
                || condMax !== null && valueDevice > condMax) return true;
        }
    }
    if (device.custom_field.type === 'INFRARED') {
        const peopleCode = device.conditions.find(condition => condition?.code === 'passage')?.code;
        const dataType = getDeviceDataTypeFromCode(peopleCode);
        const totalPeople = device.data.find(el => el.data_type === 'people')?.data_points[0].value;
        return getState(device.conditions, 'LIMITE', dataType, totalPeople);
    }
    return false;
};

export const getDefaultAlertsSelectedDays = deviceAlertTimeRange => {
    if (deviceAlertTimeRange?.days?.length === 0) {
        return [];
    }
    if (deviceAlertTimeRange?.days?.length > 0) {
        return deviceAlertTimeRange.days.map(dayIdx => getWeekDays()[dayIdx >= 1 ? dayIdx - 1 : dayIdx]?.value); // Monday is 1, Sunday is 7
    }
    if (!deviceAlertTimeRange || !deviceAlertTimeRange?.days || deviceAlertTimeRange === {}) { // By default if the ws doesn't exist the user can receive alerts everyday
        return getWeekDays().map(weekDay => weekDay.value);
    }
    return deviceAlertTimeRange?.days;
}

export const userCanSeeDevice = (devices, deviceId) => getDeviceNameFromId(devices, deviceId);


// Libs
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { useSecondMainColorTooltip } from '../../../layout/helper';
import { useComponentsPool } from '../../../../ComponentsPool';
import 'react-date-range/dist/styles.css';
import RoundStepsLists from '../StepComponents/RoundStepsLists';
import { workspaceDoesntHaveQrcodePassageDevices } from '../../helper';

const RoundModalSecondSection = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const tooltipStyle = useSecondMainColorTooltip();
    const deviceList = useSelector(state => state.devices.list);
    const [validationCount, setValidationCount] = useState(props.state.devices.length > 0
        ? props.state.devices[0].validation_count
        : 1);
    const disabledValidationCount = props.state.devices.length === 0;

    useEffect(() => { // Everytime the validationCount is updated, update the devices in the state
        if (props.state.devices.length > 0 && validationCount !== props.state.devices[0].validation_count) {
            const updatedDevices = props.state.devices.map(device => ({ ...device, validation_count: validationCount }));
            props.setState({ ...props.state, devices: updatedDevices });
        }
    }, [validationCount]);

    useEffect(() => { // Everytime the devices are updated check if the section is complete
        props.setSectionValidation(props.state.devices.length > 0 && !isNaN(validationCount) && validationCount !== 'error_field');
    }, [props.state.devices]);

    return (
        <>
            <RoundStepsLists
                withNewColor
                hasTooltipOnTitle
                hideRoundStepsExplanation
                hideSelectedListSection
                devices_ids={props.state.devices.map(device => device.id)}
                changeState={value => {
                    const devices = value.devices_ids.map(deviceId => ({ id: deviceId, validation_count: validationCount }));
                    props.setState({ ...props.state, devices });
                }}
            />
            <div className={'validation-count-field fields-wrapper'}>
                <div className={'field-text'}>{t('rounds:each_steps_must_be_made')}</div>
                <Tooltip
                    title={disabledValidationCount ? <Trans>{t('rounds:round_no_validation_count_field')}</Trans> : ''}
                    className={'second-main-color-tooltip'}
                    placement={'top'}
                    classes={tooltipStyle}
                >
                    <div>
                        <Component
                            componentName={'EditableField'}
                            renderValue={'Input'}
                            editionComponent={'Input'}
                            value={validationCount}
                            required
                            props={{
                                value: validationCount,
                                disabled: disabledValidationCount,
                                forbidMathsSymbolsSymbols: true,
                                onlyNumber: true,
                                type: 'number',
                                onChange: value => { setValidationCount(Number(value)); },
                                inputProps: { min: 1, max: 100, step: 1 },
                                error: true
                            }}
                        />
                    </div>
                </Tooltip>
                <div>{t('rounds:times')}</div>
                <Tooltip
                    className={'second-main-color-tooltip'}
                    placement={'top'}
                    classes={tooltipStyle}
                    title={t('rounds:step_validation_count_explanation')}
                >
                    <div><Component componentName={'Icon'} type={'info'} /></div>
                </Tooltip>
            </div>
            {!workspaceDoesntHaveQrcodePassageDevices(deviceList) && (
                <div className={'fields-wrapper second-main-color-chip'}>
                    <div className={'fields-wrapper-title'}>
                        {t('rounds:select_expected_jobs')}
                        <Tooltip
                            className={'second-main-color-tooltip'}
                            placement={'top'}
                            classes={tooltipStyle}
                            title={t('rounds:select_expected_jobs_explanation')}
                        >
                            <div><Component componentName={'Icon'} type={'info'} /></div>
                        </Tooltip>
                    </div>
                    <Component
                        componentName={'EditableField'}
                        renderValue={'SelectRoundJobs'}
                        editionComponent={'SelectRoundJobs'}
                        props={{
                            onChange: value => { props.setState({ ...props.state, round_expected_jobs: value }); },
                            selected: props.state.round_expected_jobs,
                            devicesIds: props.state.devices.map(device => device.id),
                            assigneesIds: props.state.assignees_ids
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default RoundModalSecondSection;

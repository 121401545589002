import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Context
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { isEmpty } from 'lodash';
import { Tooltip } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
// Actions
import { toast } from 'react-toastify';
import { useComponentsPool } from '../../../ComponentsPool';
import createLoadingSelector from '../../layout/actions';
// Helpers
import { Toast } from '../../notification/components';
import { sendCommentDocument } from '../../tickets/actions';
import { postDeviceComments } from '../../devices/actions';
import { postInstanceComments } from '../../rounds/actions';
import { allowedInputFileType } from '../../layout/helper';

const loadingSelector = createLoadingSelector(['POST_DEVICE_COMMENTS', 'UPLOAD_COMMENT_DOCUMENT', 'POST_INSTANCE_COMMENTS']);
const ValidationPassageComment = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const [comment, setComment] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const currentUser = useSelector(state => state.users.currentUser);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const dispatch = useDispatch();
    const [emptyInput, setEmptyInput] = useState(false);
    const choosePicture = React.createRef();
    const [commentSaved, setCommentSaved] = useState(false);

    const sendComment = () => {
        if (props.sendingType === 'DeviceComment') {
            dispatch(postDeviceComments(props.resourceId, comment, uploadedFile, currentUser.id, props.relatedPassageId))
                .then(() => {
                    setCommentSaved(true);
                    setEmptyInput(!emptyInput);
                    setComment('');
                    setUploadedFile(null);
                });
        } else if (props.sendingType === 'InstanceComment') {
            dispatch(postInstanceComments(props.resourceId, comment, uploadedFile, currentUser.id))
                .then(() => {
                    setCommentSaved(true);
                    setEmptyInput(!emptyInput);
                    setComment('');
                    setUploadedFile(null);
                });
        }
    };

    const disableSendComment = isLoading || isEmpty(uploadedFile) && isEmpty(comment?.trim()) || comment === 'error_field';

    const showUploader = () => {
        choosePicture.current.click();
    };

    const sendAttachment = file => {
        const maxFileSize = workspaceSettings.profile_picture_max_file_size_bytes;
        if (file) {
            if (file.size <= maxFileSize) {
                if (allowedInputFileType.includes(file.type)) {
                    dispatch(sendCommentDocument(file))
                        .then(response => {
                            setUploadedFile(response.value.url_picture);
                        });
                } else {
                    toast(
                        <Toast
                            isConfirm
                            message={t('notifications:upload_comment_document_error_format')}
                            icon={'frown'}
                            type={'error'}
                        />, {
                            position: toast.POSITION.BOTTOM_LEFT,
                            className: 'normal',
                            bodyClassName: 'notification-body grow-font-size',
                            progressClassName: 'error-custom-progress-bar'
                        }
                    );
                }
            } else {
                toast(
                    <Toast
                        isConfirm
                        message={t('notifications:upload_comment_document_error_size')}
                        icon={'frown'}
                        type={'error'}
                    />, {
                        position: toast.POSITION.BOTTOM_LEFT,
                        className: 'normal',
                        bodyClassName: 'notification-body grow-font-size',
                        progressClassName: 'error-custom-progress-bar'
                    }
                );
            }
        }
    };

    const fileChangedHandler = e => {
        e.preventDefault();
        sendAttachment(e.target.files[0]);
    };

    const _handleKeyPress = e => {
        if (!disableSendComment && e.key === 'Enter' && !e.shiftKey) {
            sendComment();
        }
    };

    if (commentSaved && props.sendingType === 'DeviceComment') {
        return (
            <div className={'comment-sent-message'}>
                <span>{t('devices:comment_sent')}</span>
                <Component componentName={'Icon'} type={'send'} />
            </div>
        );
    }
    return (
        <div className={'mobile-comment-container'}>
            <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
            <div className={'mobile-comment'} style={props.style}>
                <TextField
                    id="outlined-multiline-flexible"
                    placeholder={t('passages:add_comment')}
                    multiline={!props.singleLine}
                    rowsMax={4}
                    rows={props.rows || 1}
                    value={comment} // use '' to empty the textField once a comment was sent
                    onChange={event => setComment(event.target.value)}
                    inputProps={{ maxLength: 512 }}
                    onKeyPress={e => props.enabledEnter && _handleKeyPress(e)}
                    disabled={props.disabled}
                />
                <div className={'upload-comment-container'}>
                    <div className={'upload-comment'}>
                        {
                            !isEmpty(uploadedFile)
                                ? (
                                    <div className={'upload-comment-button choosen'}>
                                        <Tooltip
                                            arrow={false}
                                            title={isMobile ? '' : t(t('rounds:delete_attached_document'))}
                                            placement={'top'}
                                        >
                                            <Button onClick={() => { setUploadedFile(null); }}>
                                                <Component componentName={'Icon'} type={'close'} />
                                            </Button>
                                        </Tooltip>
                                        <Component componentName={'Icon'} type={'image'} />
                                    </div>
                                )
                                : (
                                    <Tooltip
                                        arrow={false}
                                        title={isMobile || props.disabled ? '' : t(t('rounds:add_attached_document'))}
                                        placement={'top'}
                                    >
                                        <div className={'upload-comment-button'}>
                                            <Button disabled={props.disabled} onClick={showUploader}>
                                                <Component componentName={'Icon'} type={isMobile ? 'photo' : 'attachment'} />
                                            </Button>
                                        </div>
                                    </Tooltip>
                                )
                        }
                    </div>
                    <div className={'send-button'}>
                        <Tooltip
                            arrow={false}
                            title={isMobile ? '' : t(t('common:send'))}
                            placement={'top'}
                        >
                            <Button
                                onClick={sendComment}
                                color="primary"
                                disabled={disableSendComment}
                            >
                                <Component componentName={'Icon'} type={'send'} />
                            </Button>
                        </Tooltip>
                    </div>
                    <input
                        ref={choosePicture}
                        hidden
                        type="file"
                        accept={allowedInputFileType.toString()}
                        onChange={value => fileChangedHandler(value)}
                    />
                </div>
            </div>
        </div>
    );
};

export default ValidationPassageComment;

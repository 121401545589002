import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { DialogActions } from '@material-ui/core';
import {
    Facebook, Instagram, Language, LinkedIn, Mail
} from '@material-ui/icons';
import '../style.changelog.scss';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

const ChangelogModalFooter = () => {
    const [t] = useTranslation();

    const getSocialIcon = socialClassname => {
        const socialName = socialClassname.split('-button')[0];
        switch (socialName) {
            case 'linkedin':
                return <LinkedIn />;
            case 'instagram':
                return <Instagram />;
            case 'facebook':
                return <Facebook />;
            case 'blog':
                return <Language />;
            case 'newsletter':
                return <Mail />;
        }
    };

    const socialParameters = [
        {
            url: 'https://www.linkedin.com/company/merciyanis/', classname: 'linkedin-button', tooltip: 'our_linkedin'
        },
        {
            url: 'https://www.instagram.com/merciyanis/?hl=fr', classname: 'instagram-button', tooltip: 'our_instagram'
        },
        {
            url: 'https://www.facebook.com/MerciYanis/', classname: 'facebook-button', tooltip: 'our_facebook'
        },
        {
            url: 'https://www.merciyanis.com/ressources/blog', classname: 'blog-button', tooltip: 'our_blog'
        },
        {
            url: 'https://www.merciyanis.com/newsletter', classname: 'newsletter-button', tooltip: 'our_newsletter'
        }
    ];


    return (
        <DialogActions className={'changelog-footer'}>
            { socialParameters.map(social => (
                <Tooltip
                    key={social.classname}
                    title={t(`changelogs:${social.tooltip}`)}
                    placement={'top'}
                >
                    <IconButton
                        className={social.classname}
                        href={social.url}
                        target={'_blank'}
                        rel="noopener noreferrer"
                    >
                        {getSocialIcon(social.classname)}
                    </IconButton>

                </Tooltip>
            )
            )}
        </DialogActions>
    );
};

export default ChangelogModalFooter;

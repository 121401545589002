// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';

const UserExpirationToken = props => {
    const [t] = useTranslation();
    const duration = Math.floor(
        (props.resource.invite_estimate_timestamp_invalid - Math.floor(Date.now() / 1000)) / 86400
    );
    const unit = duration === 1 ? t('common:day') : t('common:days');

    return duration < 0 ? (
        <span className={'expiration-span-obsolete'}>
            {`${t('users:expiration_since') } ${-duration} ${unit}`}
        </span>
    ) : (
        <span className={'expiration-span'}>
            {`${t('users:expiration_in') } ${duration} ${unit}`}
        </span>
    );
};

export default UserExpirationToken;

// Libs
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
// Helper
import { getUserName } from '../../helper';

const UserName = props => {
    const { Component } = useComponentsPool();
    const settings = useSelector(state => state.workspace.settings);
    const [user] = useState(props.resource);

    return useMemo(() => (
        <div className="table-name">
            { user?.invite_email
                ? <div className={'invite-avatar'}><img src={settings.logo_no_text_url} alt={'Company logo'} /></div>
                : <Component componentName={'UserAvatarsGrouped'} user={user} />}
            <div className={'group'}>
                <div className={'title'}>{getUserName(user)}</div>
                <em>{user?.workspace_role?.workspace_role_name}</em>
            </div>
        </div>
    ), [user.first_name, user.last_name, user?.profile_picture_url, user.workspace_role]);
};

export default UserName;

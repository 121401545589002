// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useComponentsPool } from '../../../ComponentsPool';
import { getUnassignedNfc } from '../actions';

const SelectNfcTag = props => {
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const nfcs = useSelector(state => state.nfcs.list);
    const shownUser = useSelector(state => state.users.userShownInDetailsPopup);
    const [unassignedNfc, setUnsassignedNfc] = useState([]);

    useEffect(() => {
        dispatch(getUnassignedNfc()).then(res => {
            // In the list the user must see all of the unassgined tags as well as the one he is assigned to
            const userCurrentNfc = shownUser.nfc_tag ? [nfcs.find(nfc => nfc.tag === shownUser.nfc_tag)] : [];
            setUnsassignedNfc([...res.value, ...userCurrentNfc]);
        });
    }, []);

    return (
        <Component
            componentName={'Select'}
            required={false}
            options={unassignedNfc.map(nfc => ({ id: nfc.id, value: nfc.tag }))}
            selected={{
                id: nfcs.find(nfc => nfc.tag === shownUser.nfc_tag)?.id,
                value: shownUser.nfc_tag
            }}
            onChange={value => { props.onChange(value.value); }}
        />
    );
};

export default SelectNfcTag;

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useComponentsPool } from '../../../../ComponentsPool';
import useRouter from '../../../../utils/hook/useRouter';
import { getInstancesFromTo, getRounds } from '../../actions';
import RoundViewSwitcher, { getCurrentRoundView, VIEW_CALENDAR, VIEW_LIST } from '../RoundViewSwitcher/RoundViewSwitcher';
import ExportInstanceBtn from './export/ExportInstanceBtn';
import SendMailNotificationBtn from './sendNotification/SendMailNotificationBtn';
import { Link } from '@mui/material';
import classnames from 'classnames';
import DateNavigatorArrow from './calendarDateNavigator/DateNavigatorArrow';
import { RoundsContext } from '../../RoundsContext';
import moment from 'moment';
import { capitalize } from 'lodash';
import { nextDayDate, nextWeekDate, previousDayDate, previousWeekDate } from '../../helper';
import queryString from 'query-string';
import { END_DATE_PARAM, PARAMS_DATE_FORMAT, START_DATE_PARAM } from '../../constants';
import { maxDate } from "../../../layout/components/DateWeekPicker";

const RoundToolbar = props => {
    const roundsContext = useContext(RoundsContext);
    const dispatch = useDispatch();
    const { Component } = useComponentsPool();
    const { query, history, location } = useRouter();
    const [t] = useTranslation();
    const active_mode = useSelector(({ rounds }) => rounds.active_mode);

    const workspaceSettings = useSelector(state => state.workspace.settings);
    const currentUserRole = useSelector(state => state.users.currentUser.workspace_role?.workspace_role_name);
    const isListView = getCurrentRoundView(query, currentUserRole, workspaceSettings) === VIEW_LIST;
    const isCalendarView = getCurrentRoundView(query, currentUserRole, workspaceSettings) === VIEW_CALENDAR;

    // Remove start and end dates from URL on list view
    useEffect(() => {
        if (!isCalendarView) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.delete(START_DATE_PARAM);
            searchParams.delete(END_DATE_PARAM);
            history.push({
                search: `?${searchParams}`
            });
        }
    }, [isCalendarView, history]);

    const goToDay = (direction) => {
        const newDate =
            direction === 'next'
                ? nextDayDate(roundsContext.getters.selectedDayOnCalendar)
                : direction === 'prev'
                ? previousDayDate(roundsContext.getters.selectedDayOnCalendar)
                : roundsContext.getters.selectedDayOnCalendar;

        const currentSelectedDay = moment(roundsContext.getters.selectedDayOnCalendar);
        const isNewWeek = !newDate.isSame(currentSelectedDay, 'week');
        roundsContext.setters.setSelectedDayOnCalendar(newDate);
        if (isNewWeek) {
            const weekStartDate = moment(newDate).startOf('week');
            const weekEndDate = moment(newDate).endOf('week');
            roundsContext.setters.setCurrentWeekDates({ start: weekStartDate, end: weekEndDate });
            const { view } = query;
            const newQueries = {
                view,
                [START_DATE_PARAM]: weekStartDate.format(PARAMS_DATE_FORMAT),
                [END_DATE_PARAM]: weekEndDate.format(PARAMS_DATE_FORMAT)
            };
            history.push({ search: queryString.stringify(newQueries) });
        }
    };

    const goToWeek = (direction) => {
        const newDate =
            direction === 'next'
                ? nextWeekDate(roundsContext.getters.selectedDayOnCalendar)
                : direction === 'prev'
                ? previousWeekDate(roundsContext.getters.selectedDayOnCalendar)
                : roundsContext.getters.selectedDayOnCalendar;

        const weekStartDate = moment(newDate).startOf('week');
        const weekEndDate = moment(newDate).endOf('week');
        roundsContext.setters.setCurrentWeekDates({ start: weekStartDate, end: weekEndDate });
        roundsContext.setters.setSelectedDayOnCalendar(newDate);

        // push new query params to URL
        const { view } = query;
        const newQueries = {
            view,
            [START_DATE_PARAM]: weekStartDate.format(PARAMS_DATE_FORMAT),
            [END_DATE_PARAM]: weekEndDate.format(PARAMS_DATE_FORMAT)
        };
        history.push({ search: queryString.stringify(newQueries) });
    };

    const isNextWeekForbidden = (() => {
        const nextWeek = nextWeekDate(roundsContext.getters.selectedDayOnCalendar);
        return nextWeek.isAfter(maxDate);
    })();

    const isNextDayForbidden = (() => {
        const nextDay = nextDayDate(roundsContext.getters.selectedDayOnCalendar);
        return nextDay.isAfter(maxDate);
    })();

    const onWeekChange = weekEvent => {
        const { startDate, endDate } = weekEvent;

        const startOfWeek = moment(startDate).format(PARAMS_DATE_FORMAT);
        const endOfWeek = moment(endDate).format(PARAMS_DATE_FORMAT);
        roundsContext.setters.setSelectedDayOnCalendar(startDate);
        roundsContext.setters.setCurrentWeekDates({ start: startOfWeek, end: endOfWeek });
        props.onCalendarWeekChange(startDate);

        // push new query params to URL
        const { view } = query;
        const newQueries = { view, [START_DATE_PARAM]: startOfWeek, [END_DATE_PARAM]: endOfWeek };
        history.push({ search: queryString.stringify(newQueries) });
    };

    const loadListRounds = () => {
        dispatch(getRounds(!active_mode));
    };

    const params = new URLSearchParams(location.search);
    const start = params.get(START_DATE_PARAM);
    const end = params.get(END_DATE_PARAM);
    const exportFileName = `export_rondes_${start}_${end}`;

    return (
        <div className={classnames('change-round-mode change-round-mode-calendar-list', isListView && 'change-round-mode-list-view')}>
            <RoundViewSwitcher />
            {isCalendarView && props.currentView === 'week' && (
                <div className="right-btn">
                    <SendMailNotificationBtn />
                    <ExportInstanceBtn fileName={exportFileName} />
                    <Component
                        componentName="DateWeekPicker"
                        onChange={onWeekChange}
                        showLabel={false}
                    />
                    <DateNavigatorArrow
                        type="previous"
                        tooltipText={t('common:previous_week')}
                        onClick={() => goToWeek('prev')}
                    />
                    <DateNavigatorArrow
                        type="next"
                        tooltipText={t('common:next_week')}
                        disabled={isNextWeekForbidden}
                        onClick={() => goToWeek('next')}
                    />
                </div>
            )}
            {isCalendarView && props.currentView === 'day' && (
                <>
                    <div className={'date-wrapper'}>
                        <p className={'date'}>
                            {capitalize(moment(roundsContext.getters.selectedDayOnCalendar).format('ddd DD MMMM'))}
                        </p>
                    </div>
                    <div className={'calendar-day-view-navigation-buttons'}>
                        <div
                            className={'calendar-day-view-back-button'}
                            role={'button'}
                            tabIndex={0}
                            onClick={() => roundsContext.setters.setCalendarViewMode('week')}
                        >
                            <span>{t('rounds:back_to_week_view')}</span>
                        </div>
                        <DateNavigatorArrow
                            type="previous"
                            tooltipText={t('common:previous_day')}
                            onClick={() => goToDay('prev')}
                        />
                        <DateNavigatorArrow
                            type="next"
                            tooltipText={t('common:next_day')}
                            disabled={isNextDayForbidden}
                            onClick={() => goToDay('next')}
                        />
                    </div>
                </>
            )}
            {isListView && (
                <Link className="switch-view-page" underline="hover" onClick={loadListRounds}>
                    {t(`rounds:see_${active_mode ? 'archived_rounds' : 'active_rounds'}`)}
                </Link>
            )}
        </div>
    );
};

export default RoundToolbar;

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
// Actions
import createLoadingSelector from '../../layout/actions';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
import useUpdateForm from '../../layout/forms/UseUpdateForm';

const loadingSelector = createLoadingSelector(['EDIT_USER']);
const UserDetailsPopup = () => {
    const { Component } = useComponentsPool();
    const editableFields = useSelector(state => sortBy(state.users.editableFields, 'weight'));
    const users = useSelector(state => state.users);
    const user = users.userShownInDetailsPopup;
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));

    const extraFields = [{ props: { value: 'username' } }, { props: { value: 'subscriptions' } }];

    const { updateState, state } = useUpdateForm([...editableFields, ...extraFields], user, 'id');
    const content = users.detailPopupParts.map(part => ({
        width: part.width,
        content: <Component
            componentName={part.component}
            updateState={(resource, value) => updateState(resource, value)}
            state={state}
        />
    }));

    return (
        <Component
            componentName={'Modal'}
            loading={isLoading}
            contentWidth={'100%'}
            content={content}
            classNames={'details-popup'}
        />
    );
};

export default UserDetailsPopup;

// Libs
import update from 'immutability-helper';
import {
    cloneDeep, remove, find, findIndex, concat, sortBy, includes, chain
} from 'lodash';
import { getGroupNameFromId } from '../groups/helper';
import { calculateTicketMovementInfo } from './helper';
import { TicketMovementDirection } from './enums';

const initialState = () => ({
    list: [
        { id: 'NEW', name: 'NEW', tickets: [], isVisible: true },
        { id: 'PENDING', name: 'PENDING', tickets: [], isVisible: true },
        { id: 'INPROGRESS', name: 'INPROGRESS', tickets: [], isVisible: true },
        { id: 'COMPLETED', name: 'COMPLETED', tickets: [], isVisible: true },
        { id: 'ARCHIVED', name: 'ARCHIVED', tickets: [], isVisible: false }
    ],
    tags: [],
    priorityLevels: [
        { id: 0, name: 'LOW' },
        { id: 1, name: 'MEDIUM' },
        { id: 2, name: 'HIGH' }
    ],
    editableFields: [{
        name: 'priority',
        title: 'tickets:priority',
        renderValue: 'TicketPriority',
        editionComponent: 'TicketPrioritySelector',
        props: { value: 'priority', placeHolder: '', withTranslation: true, required: true },
        weight: 201
    }, {
        name: 'ticket_assigned_user',
        title: 'tickets:assigned_user',
        renderValue: 'TicketAssigners',
        editionComponent: 'SelectAssigners',
        props: { value: 'assigners', placeHolder: '', multiple: true },
        weight: 200
    }, {
        name: 'ticket_followers',
        title: 'tickets:followers_user',
        subtitle: 'tickets:followers_user_explanation',
        renderValue: 'TicketFollowers',
        disabledInput: true,
        props: { value: 'followers', placeHolder: '', multiple: true },
        weight: 202
    }, {
        name: 'ticket_external_followers',
        title: 'tickets:external_followers_user',
        subtitle: 'tickets:external_followers_user_explanation',
        renderValue: 'TicketFollowers',
        disabledInput: true,
        props: { value: 'external_followers', placeHolder: '', multiple: true },
        weight: 204
    }, {
        name: 'ticket_skill',
        title: 'tickets:skill',
        renderValue: 'TicketSimpleViewGroup',
        editionComponent: 'GroupSelector',
        props: {
            required: true,
            value: ['custom_field', 'skill_group_id'],
            placeholder: '',
            groupType: 'SKILL',
            multiple: false,
            showSelectAll: true
        },
        weight: 40
    }, {
        name: 'ticket_batiment',
        title: 'tickets:batiment',
        componentName: 'EditableField',
        renderValue: 'TicketSimpleViewGroup',
        editionComponent: 'GroupSelector',
        props: {
            required: true,
            value: ['custom_field', 'batiment_group_id'],
            placeholder: '',
            groupType: 'BATIMENT',
            multiple: false
        },
        weight: 10
    }, {
        name: 'ticket_description',
        title: 'tickets:description',
        componentName: 'EditableField',
        renderValue: 'TicketSimpleString',
        editionComponent: 'Input',
        props: {
            value: 'description',
            renderValue: 'description',
            required: false,
            rules: ['maxLength-128'],
            hide_roles: ['User']
        },
        weight: 205
    }, {
        name: 'ticket_tags',
        title: 'tickets:ticket_tags',
        subtitle: 'tickets:ticket_tags_explanation',
        componentName: 'EditableField',
        renderValue: 'TagsCreatableSelect',
        editionComponent: 'TagsCreatableSelect',
        props: {
            value: 'tags',
            required: false,
            hide_roles: ['User']
        },
        weight: 203
    }],
    archivedTicketsTableColumns: [{
        name: 'name',
        formTitle: true,
        title: 'tickets:tickets_archived',
        component: 'ArchivedTicketNameAndCategory',
        weight: 100
    }, {
        name: 'location',
        title: 'devices:location',
        component: 'ObjectLocation',
        weight: 200
    }, {
        name: 'dates',
        title: 'tickets:creation_and_closing_dates',
        component: 'ArchivedTicketDates',
        weight: 300
    }, {
        name: 'counter_priority',
        title: 'tickets:counter_and_priority',
        customUserTitle: 'tickets:counter',
        component: 'ArchivedTicketCounterAndPriority',
        weight: 400
    }, {
        name: 'assignees',
        title: 'tickets:assigned_user',
        component: 'ArchivedTicketAssignees',
        hide_roles: ['User'],
        weight: 500
    }
    ],
    detailPopupParts: [
        { width: '50%', component: 'TicketDetailsPopupPartLeft' },
        { width: '50%', component: 'TicketDetailsPopupPartRight' }
    ],
    ticketShownInDetailsPopup: {},
    selectedStatusMobile: 'NEW'
});

let newSource;
let newDestination;
let sourceIndex;
let destinationIndex;
let selectedTicket;
let newList;
let categoryIndex;
let index;
let newState;
let ticketIndex;
let previousCategory;
let previousCategoryIndex;

const ticketsReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'CHANGE_SELECTED_STATUS_MOBILE':
            return update(state, { selectedStatusMobile: { $set: action.payload } });
        case 'CREATE_TICKET_FULFILLED':
        case 'CREATE_TICKET_VIA_DECLARATIVE_QRCODE_FULFILLED':
            sourceIndex = findIndex(state.list, list => list?.id === action.payload.status);
            newSource = cloneDeep(find(state.list, list => list?.id === action.payload.status).tickets);
            if (newSource.length === 0 || newSource[0]?.id !== action?.payload?.id) {
                newSource.unshift(action.payload);
                return update(state, {
                    list: {
                        [sourceIndex]: { tickets: { $set: newSource } }
                    }
                });
            }
            return state;
        case 'CHANGE_TICKET_POPUP_PARTS':
            return update(state, {
                detailPopupParts: {
                    $set: action.payload
                }
            });
        case 'ADD_TICKET_EDITABLE_FIELD':
            return update(state, { editableFields: { $push: [action.payload] } });
        case 'GET_TICKETS_FULFILLED':
            if (!action.payload.active) {
                return update(state, {
                    list: {
                        $set: [
                            { id: 'NEW', name: 'NEW', tickets: state.list.find(c => c.id === 'NEW')?.tickets, isVisible: false },
                            { id: 'PENDING', name: 'PENDING', tickets: state.list.find(c => c.id === 'PENDING')?.tickets, isVisible: false },
                            { id: 'INPROGRESS', name: 'INPROGRESS', tickets: state.list.find(c => c.id === 'INPROGRESS')?.tickets, isVisible: false },
                            { id: 'COMPLETED', name: 'COMPLETED', tickets: state.list.find(c => c.id === 'COMPLETED')?.tickets, isVisible: false },
                            { id: 'ARCHIVED', name: 'ARCHIVED', tickets: action.payload.data.results.filter(t => !t.active), isVisible: true }
                        ]
                    }
                });
            }
            return update(state, {
                list: {
                    $set: [
                        { id: 'NEW', name: 'NEW', tickets: action.payload.data.results.filter(t => t.status === 'NEW' && t.active), isVisible: true },
                        { id: 'PENDING', name: 'PENDING', tickets: action.payload.data.results.filter(t => t.status === 'PENDING' && t.active), isVisible: true },
                        { id: 'INPROGRESS', name: 'INPROGRESS', tickets: action.payload.data.results.filter(t => t.status === 'INPROGRESS' && t.active), isVisible: true },
                        { id: 'COMPLETED', name: 'COMPLETED', tickets: action.payload.data.results.filter(t => t.status === 'COMPLETED' && t.active), isVisible: true },
                        { id: 'ARCHIVED', name: 'ARCHIVED', tickets: state.list.find(c => c.id === 'ARCHIVED')?.tickets, isVisible: false }
                    ]
                }
            });
        case 'SET_VISIBLE_TICKETS':
            newList = cloneDeep(state.list);
            if (action.payload === 'ARCHIVED') {
                newList.forEach(category => { category.isVisible = category.id === 'ARCHIVED'; });
                return update(state, { list: { $set: newList } });
            }
            newList.forEach(category => { category.isVisible = category.id !== 'ARCHIVED'; });
            return update(state, { list: { $set: newList } });
        case 'GET_MINIMAL_TICKET_FULFILLED':
            if (action.payload?.id) {
                return update(state, {
                    ticketShownInDetailsPopup: { $set: action.payload }
                });
            }
            return state;
        case 'RESET_SELECTED_TICKET':
            return update(state, {
                ticketShownInDetailsPopup: { $set: {} }
            });
        case 'NEW_TICKET_FROM_WEBSOCKET':
            sourceIndex = findIndex(state.list, list => list.id === action.payload.status);
            newSource = cloneDeep(find(state.list, list => list.id === action.payload.status).tickets);
            newSource.unshift(action.payload);
            return update(state, {
                list: {
                    [sourceIndex]: { tickets: { $set: newSource } }
                }
            });
        case 'UPDATE_TICKET_FROM_WEBSOCKET':
            ticketIndex = -1;
            state.list.forEach(category => {
                index = findIndex(category.tickets, ticket => ticket.id === action.payload.id);
                if (index !== -1) {
                    ticketIndex = cloneDeep(index);
                    previousCategoryIndex = findIndex(state.list, list => list.id === category.id);
                    previousCategory = category.name;
                }
            });
            // If no ticket
            if (ticketIndex === -1) {
                sourceIndex = findIndex(state.list, list => list.id === action.payload.status);
                newSource = cloneDeep(find(state.list, list => list.id === action.payload.status).tickets);
                newSource.unshift(action.payload);
                return update(state, {
                    list: {
                        [sourceIndex]: { tickets: { $set: newSource } }
                    }
                });
            }
            // Else
            if (previousCategory === action.payload.status) {
                // Status isn't updated
                return update(state, {
                    list:
                        { [previousCategoryIndex]: { tickets: { [ticketIndex]: { $set: action.payload } } } }
                });
            }
            // Status is updated
            newState = cloneDeep(state);
            // Remove ticket from old column
            newSource = cloneDeep(find(state.list, list => list?.id === previousCategory).tickets);
            remove(newSource, ticket => ticket?.id === action.payload.id);
            // Create in new one
            destinationIndex = findIndex(state.list, list => list.id === action.payload.status);
            newDestination = cloneDeep(find(state.list, list => list.id === action.payload.status).tickets);
            newDestination.unshift(action.payload);
            return update(state, {
                list: {
                    [destinationIndex]: { tickets: { $set: newDestination } },
                    [previousCategoryIndex]: { tickets: { $set: newSource } }
                }
            });
        case 'MOVE_TICKET':
            // Edits on source
            sourceIndex = findIndex(state.list, list => list?.id === action.payload.source.droppableId);
            newSource = cloneDeep(find(state.list, list => list?.id === action.payload.source.droppableId).tickets);
            selectedTicket = remove(newSource, ticket => ticket?.id === action.payload.draggedTicketId);
            selectedTicket[0].status = action.payload.destination.droppableId;
            // Edits on destination
            destinationIndex = findIndex(state.list, list => list?.id === action.payload.destination.droppableId);
            if (action.payload.destination.droppableId !== action.payload.source.droppableId) {
                newDestination = cloneDeep(
                    find(state.list, list => list?.id === action.payload.destination.droppableId).tickets
                );
            } else {
                newDestination = cloneDeep(newSource);
            }
            newDestination.splice(action.payload.destination.index, 0, selectedTicket[0]);
            return update(state, {
                list: {
                    [sourceIndex]: { tickets: { $set: newSource } },
                    [destinationIndex]: { tickets: { $set: newDestination } }
                }
            });

        case 'MOVE_TICKETS':
            // Edits on source tickets
            const sourceTickets = cloneDeep(find(state.list, (column) => column.id === action.payload.source.droppableId))?.tickets;

            // Remove tickets from source and change their status to destination status
            const selectedTickets = chain(sourceTickets)
                .remove((ticket) => includes(action.payload.checkedTicketIDs, ticket.id))
                .map((ticket) => ({
                    ...ticket,
                    status: action.payload.destination.droppableId
                }))
                .value();

            // Edits on destination tickets
            const destinationTickets =
                // Are tickets from the same column ?
                action.payload.destination.droppableId === action.payload.source.droppableId
                    ? cloneDeep(sourceTickets)
                    : cloneDeep(find(state.list, (column) => column.id === action.payload.destination.droppableId))
                        ?.tickets;

            // Source and destination index for updating the state
            const sourceColumnIndex = findIndex(state.list, (column) => column.id === action.payload.source.droppableId);
            const destinationColumnIndex = findIndex(state.list, (column) => column.id === action.payload.destination.droppableId);

            const sourceTickets_copy = cloneDeep(find(state.list, (column) => column.id === action.payload.source.droppableId))?.tickets;

            const ticketMovementInfo = calculateTicketMovementInfo(
                sourceTickets_copy,
                action.payload.checkedTicketIDs,
                action.payload.destination.index
            );

            if (
                action.payload.source.droppableId === action.payload.destination.droppableId &&
                ticketMovementInfo.movementDirection === TicketMovementDirection.DOWN
            ) {
                destinationTickets.splice(
                    action.payload.destination.index - ticketMovementInfo.selectedTicketsBeforeDestinationIndex + 1,
                    0,
                    ...selectedTickets
                );
            } else {
                destinationTickets.splice(action.payload.destination.index, 0, ...selectedTickets);
            }

            return sourceColumnIndex === destinationColumnIndex
                ? update(state, {
                    list: {
                        [destinationColumnIndex]: {tickets: {$set: destinationTickets}}
                    }
                })
                : update(state, {
                    list: {
                        [sourceColumnIndex]: {tickets: {$set: sourceTickets}},
                        [destinationColumnIndex]: {tickets: {$set: destinationTickets}}
                    }
                });

        case 'DELETE_TICKETS_FULFILLED':
        case 'DELETE_TICKET_FULFILLED':
            newList = cloneDeep(state.list);
            action.payload.forEach(ticketToDelete => {
                newList.forEach(column => {
                    remove(column.tickets, ticket => ticket?.id === ticketToDelete);
                });
            });
            return update(state, { list: { $set: newList } });
        case 'SET_TICKET_DETAIL':
            if (action.payload.ticket?.custom_field) {
                const groupList = action.payload.groupList;
                if (action.payload.ticket.custom_field.floor_group_id) {
                    const floor_label = getGroupNameFromId(
                        groupList,
                        action.payload.ticket.custom_field.floor_group_id
                    );
                    action.payload.ticket.custom_field['floor_label'] = floor_label ? floor_label : '';
                }
                if (action.payload.ticket.custom_field.position_group_id) {
                    const position_label = getGroupNameFromId(
                        groupList,
                        action.payload.ticket.custom_field.position_group_id
                    );
                    action.payload.ticket.custom_field['position_label'] = position_label ? position_label : '';
                }
            }
            action.payload.ticket['updatedComments'] = [];
            action.payload.ticket['summary'] = [];
            action.payload.ticket['comments'] = [];
            action.payload.ticket['operations'] = [];
            action.payload.ticket['commentsTotalSummary'] = [];
            action.payload.ticket['operationsTotalSummary'] = [];
            return update(state, {
                ticketShownInDetailsPopup: { $set: {
                    ...state.ticketShownInDetailsPopup,
                    ...action.payload.ticket
                } }
            });
        case 'EDIT_TICKET_FULFILLED':
            state.list.forEach(category => {
                previousCategory = null
                index = findIndex(category.tickets, ticket => ticket?.id === action?.payload?.id);
                if (index !== -1) {
                    if (category.tickets[index].status !== action.payload.status) {
                        previousCategoryIndex = findIndex(state.list, c => c?.id === category?.id);
                        previousCategory = cloneDeep(state.list[previousCategoryIndex]);
                        remove(previousCategory.tickets, ticket => ticket?.id === action?.payload?.id);
                        categoryIndex = findIndex(state.list, c => c?.id === action.payload.status);
                        newList = cloneDeep(state.list[categoryIndex]);
                        newList.tickets.unshift(action.payload);
                    } else {
                        ticketIndex = cloneDeep(index);
                        categoryIndex = findIndex(state.list, c => c?.id === category?.id);
                    }
                }
            });
            if (previousCategory) {
                return update(state, {
                    list: {
                        [categoryIndex]: { $set: newList },
                        [previousCategoryIndex]: { $set: previousCategory }
                    }
                });
            }
            if (state.ticketShownInDetailsPopup?.id === action?.payload?.id) {
                return update(state, {
                    list: { [categoryIndex]: { tickets: { [ticketIndex]: { $set: action.payload } } } },
                    ticketShownInDetailsPopup: { $set: { ...state.ticketShownInDetailsPopup, ...action.payload } }
                });
            }
            return update(state, {
                list:
                    { [categoryIndex]: { tickets: { [ticketIndex]: { $set: action.payload } } } }
            });
        case 'GET_TICKET_SUMMARY_FULFILLED':
            return update(state, {
                ticketShownInDetailsPopup: {
                    summary: { $set: action.payload.data.summary },
                    totalSummary: { $set: action.payload.data.count }
                }
            });
        case 'GET_TICKET_OPERATIONS_FULFILLED':
            if (action.payload.data) {
                return update(state, {
                    ticketShownInDetailsPopup: {
                        operations: { $set: action.payload.data.operations },
                        operationsTotalSummary: { $set: action.payload.data.count }
                    }
                });
            }
            return state;
        case 'GET_TICKET_COMMENTS_FULFILLED':
            return update(state, {
                ticketShownInDetailsPopup: {
                    comments: { $set: action.payload.data.comments },
                    commentsTotalSummary: { $set: action.payload.data.count }
                }
            });
        case 'EDIT_TICKET_COMMENTS_FULFILLED':
            return update(state, {
                ticketShownInDetailsPopup: {
                    comments: { $set: action.payload.data.comments }
                }
            });
        case 'GET_TAGS_FULFILLED':
            return update(state, { tags: { $set: action.payload } });
        case 'CREATE_TAG_FULFILLED':
            if (!state.tags.includes(action.payload)) {
                const stateCopy = [...state.tags];
                stateCopy.push({ id: action.payload.id, title: action.payload.title });
                return update(state, { tags: { $set: sortBy(stateCopy, 'title') } });
            }
            return state;
        case 'UPDATE_TICKET_FULFILLED':
            state.list.forEach(category => {
                index = findIndex(category.tickets, ticket => ticket.id === action.payload.id);
                if (index !== -1) {
                    ticketIndex = cloneDeep(index);
                    categoryIndex = findIndex(state.list, c => c.id === category.id);
                }
            });
            return update(state, {
                list:
                    { [categoryIndex]: { tickets: { [ticketIndex]: { $set: action.payload } } } }
            });
        case 'SAVE_TICKET_ORDER_REJECTED':
        case 'SAVE_TICKET_ORDER_FAILED':
        case 'UPDATE_TICKET_REJECTED':
        case 'UPDATE_TICKET_FAILED':
            return state;
        case 'POST_TICKET_COMMENTS_FULFILLED':
            return update(state, {
                ticketShownInDetailsPopup: {
                    comments: { $set: concat(action.payload.data, state.ticketShownInDetailsPopup.comments) },
                    commentsTotalSummary: { $set: state.ticketShownInDetailsPopup.commentsTotalSummary + 1 }
                }
            });
        case 'SHARE_TICKET_COMMENT_FULFILLED':
            newList = cloneDeep(state.ticketShownInDetailsPopup.comments);
            remove(newList, comment => comment.id === action.payload.data.id);
            return update(state, {
                ticketShownInDetailsPopup: {
                    comments: { $set: concat(action.payload.data, newList) }
                }
            });
        case 'DELETE_COMMENT_FULFILLED':
            newList = cloneDeep(state.ticketShownInDetailsPopup.comments);
            remove(newList, comment => comment.id === action.payload);
            return update(state, {
                ticketShownInDetailsPopup: {
                    comments: { $set: newList },
                    commentsTotalSummary: { $set: state.ticketShownInDetailsPopup.commentsTotalSummary - 1 }
                }
            });
        case 'GET_TICKET_FULFILLED':
            newState = cloneDeep(state);
            if (action.payload.id) {
                const ticket = action.payload.data;
                state.list.forEach(category => {
                    index = findIndex(category.tickets, t => t.id === ticket.id);
                    if (index !== -1) {
                        ticketIndex = index;
                        categoryIndex = findIndex(state.list, c => c.id === category.id);
                    }
                });
                if (categoryIndex !== undefined && categoryIndex !== -1) {
                    // UPDATE TICKET
                    if (state.list[categoryIndex].id !== ticket.status) {
                        // move ticket
                        newList = cloneDeep(state.list);
                        newList.forEach(column => {
                            remove(column.tickets, t => t.id === ticket.id);
                        });
                        categoryIndex = findIndex(newList, c => c.id === ticket.status);
                        newList[categoryIndex].tickets.unshift(ticket);
                        return update(state, { list: { $set: newList } });
                    }
                    // update only
                    return update(state, {
                        list: { [categoryIndex]: { tickets: { [ticketIndex]: { $set: ticket } } } }
                    });
                } else {
                    // CREATE TICKET
                    sourceIndex = findIndex(state.list, list => list.id === ticket.status);
                    newSource = cloneDeep(find(state.list, list => list.id === ticket.status).tickets);
                    if (newSource.length > 0 && newSource[0].id !== ticket.id) {
                        newSource.unshift(ticket);
                        newState = update(state, {
                            list: {
                                [sourceIndex]: { tickets: { $set: newSource } }
                            }
                        });
                    }
                }
            }
            return newState;
        case 'NEW_WEBSOCKET_MESSAGE':
            newState = cloneDeep(state);
            if (action.payload.message_type === 'DELETE_TICKET') {
                const ticket = action.payload.message_data;
                newList = cloneDeep(state.list);
                newList.forEach(column => {
                    remove(column.tickets, t => t.id === ticket.id);
                });
                return update(state, { list: { $set: newList } });
            }
            return state;
        default:
            return state;
    }
};

export default ticketsReducer;

// Libs
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Context
import { useModal } from '../../../modal/ModalContext';
import { useComponentsPool } from '../../../../ComponentsPool';
// Actions
import { editDevice } from '../../actions';

const DeviceGroups = () => {
    const { Component } = useComponentsPool();
    const modal = useModal();
    const profile = useSelector(state => state.users.currentUser);
    const [device] = useState(modal.getItem());
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const newGroups = groups => ({ group_ids: device.group_ids.filter(id => !groups.includes(id)) });
    return isEmpty(device.group_ids)
        ? <i className={'placeholder_text'}>{t('common:not_in_group')}</i>
        : (
            <Component
                componentName={'GroupButton'}
                view={'device'}
                key={device.device_id}
                group_memberships={device.group_ids}
                groupRights={profile.group_rights}
                resourceType={'Device'}
                iconType={'device'}
                maxVisible={2}
                isInactive={device.is_inactive_chip_delete}
                deleteIcon
                removeGroup={groups => dispatch(editDevice(device.device_id, newGroups(groups), 'EDIT_DEVICE_GROUPS'))}
                index
            />
        );
};

export default DeviceGroups;

// Libs
import React from 'react';
// Components
import Switch from '@material-ui/core/Switch';

const TypeTwoChoices = props => {
    const getRightBoolAndText = (settingType, value) => {
        let bool;
        let leftText;
        let rightText;
        if (settingType === 'mode_floor' || settingType === 'mode_position' || settingType === 'mode_title') {
            bool = value !== 'close';
            leftText = 'close';
            rightText = 'open';
        } else if (settingType === 'contractor_tickets_mode') {
            bool = value === 'ALL';
            leftText = 'assignés';
            rightText = 'tickets skills';
        } else if (settingType === 'user_tickets_mode') {
            bool = value === 'ALL';
            leftText = 'created';
            rightText = 'all';
        } else if (settingType === 'show_azure_ad_connect') {
            bool = value === true;
            leftText = 'Désactivée';
            rightText = 'Activée';
        }
        return [bool, leftText, rightText];
    };

    const valueBySetting = settingType => {
        // There can only be TWO objects in values !
        let values;
        if (settingType === 'mode_floor' || settingType === 'mode_position' || settingType === 'mode_title') {
            values = [
                { bool: true, value: 'open' },
                { bool: false, value: 'close' }
            ];
        } else if (settingType === 'user_tickets_mode') {
            values = [
                { bool: true, value: 'ALL' },
                { bool: false, value: 'CREATED' }
            ];
        } else if (settingType === 'contractor_tickets_mode') {
            values = [
                { bool: true, value: 'ALL' },
                { bool: false, value: 'ASSIGNATED' }
            ];
        } else if (settingType === 'show_azure_ad_connect') {
            values = [
                { bool: true, value: true },
                { bool: false, value: false }
            ];
        }
        return values;
    };

    const getSettingValue = (e, settingType, value) => {
        const rightValue = valueBySetting(settingType).filter(group => group.bool !== value)[0].value;
        props.setNewValue(rightValue);
    };

    return (
        <div>
            <span className={'left-lang'}>{getRightBoolAndText(props.fn, props.value)[1]}</span>
            <Switch
                checked={getRightBoolAndText(props.fn, props.value)[0]}
                onChange={e => getSettingValue(e, props.fn, getRightBoolAndText(props.fn, props.value)[0])}
                value={props.name}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <span>{getRightBoolAndText(props.fn, props.value)[2]}</span>
        </div>
    );
};

export default TypeTwoChoices;

import update from 'immutability-helper';

const initialState = () => ({
    roles: [],
    workspaceExist: null,
    workspaceCreatedTokens: [],
    workspaceSlug: '',
    securityCode: '',
    workspaceName: '',
    workspaceCreatedWithSuccess: false,
    settings: {},
    userWorkspacesList: {
        email: '',
        accounts: [],
        invites: []
    }
});

const workspaceReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'GET_WORKSPACE_ROLES_FULFILLED':
            return update(state, { roles: { $set: action.payload } });
        case 'CHECK_WORKSPACE_NAME_REJECTED':
            return update(state, {
                workspaceExist: { $set: true },
                securityCode: { $set: '' }
            });
        case 'CREATE_WORKSPACE_FULFILLED':
            return update(state, { workspaceCreatedWithSuccess: { $set: true } });
        case 'GET_WORKSPACE_SETTINGS_FULFILLED':
            return update(state, { settings: { $set: action.payload } });
        case 'UPDATE_WORKSPACE_SETTINGS_FULFILLED':
        case 'UPDATE_WORKSPACE_SETTING_FULFILLED':
            return update(state, { settings: { $merge: action.payload } });
        case 'SET_WORKSPACE_NAME_FULFILLED':
            return update(state, { settings: { name: { $set: action.payload } } });
        case 'SET_GEOLOCALISATION':
            return update(state, { settings: { geoLocalisation: { $set: action.payload } } });
        case 'CREATE_TOKEN_FULFILLED':
            return update(state, { workspaceCreatedTokens: { $set: action.payload.data } });
        case 'CLEAR_WORKSPACE_CREATION':
            return initialState();
        case 'GET_USER_WORKSPACES_LIST_FULFILLED':
            return update(state, { userWorkspacesList: { $set: action.payload } });
        default:
            return state;
    }
};

export default workspaceReducer;

// Libs
import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useComponentsPool } from '../../../../ComponentsPool';
import { getMuiTranslations, getScssVariables } from '../../../layout/helper';
import { useFootBar } from '../../../footbar/FootBarContext';
import { useModal } from '../../../modal/ModalContext';
import { setGroupShownInDetailPopup } from '../../../groups/actions';

// Context
const SettingsTable = props => {
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const footBar = useFootBar();
    const modal = useModal();
    const [t] = useTranslation();
    const theme = createTheme(getMuiTranslations());
    const rows = props.rows;
    const groupList = useSelector(state => state.groups.list);
    /*
        Rows object must be like this :
        {
            name: "The name of the fist column", // required
            group_ids: "The group ids to appear in columns after"
        }
    */
    const headCells = props.headCells;
    /*
        headCells object parameters are :
        {
            id: "idName" => the id of the cell, if the id contains _groups then the column cannot be sorted
            toHide: false => if set to true, the column will be hidden in the table
            hasIcon: false => if set to true, the column will contain an avatar that can be stacked with another if needed
            positionToRight: false => if set to true, the data will be aligned at the right within the column
            disablePadding: false => if set to true, a padding will be added on the cell
            label: "" => the label, it is the name of the column
        }
    */

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    // This method is created for cross-browser compatibility, if you don't
    // need to support IE11, you can use Array.prototype.sort() directly
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);

    // get the right number of pages (once it's loaded)
    useEffect(() => {
        setRowsPerPage(rows.length);
    }, [rows]);

    const handleClickOnRow = async (event, rowId) => {
        await dispatch(setGroupShownInDetailPopup(rowId));
        const selectedGroup = groupList.find(item => rowId === item.group_id);
        footBar.close();
        modal.update({ name: 'SettingsDetailsPopup', item: selectedGroup, withFootBar: true });
    };

    const handleClick = (event, name) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = name => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const checkboxColor = getScssVariables().primaryColor;
    const fixedTableHeight = rowsPerPage === rows.length;
    // const rowsHaveMoreThanMaxVisibleGroups = rows.filter()find()
    const rowsHeight = headCells.find(cell => cell.id?.includes('_groups'))
        ? 64
        : 53;
    const maxVisibleGroups = 4;

    const getComponentFromHeadCell = (headCell, row) => {
        if (headCell.id === 'name') {
            return (
                <TableCell
                    key={headCell.id}
                    sx={{
                        padding: '0 16px 0 16px', height: rowsHeight
                    }}
                >
                    <div className={headCell.hasIcon ? 'table-cell-with-icon' : ''}>
                        {headCell.hasIcon && (
                            <Component
                                componentName={'SettingsTableSkillsIcons'}
                                skillName={row.nameIcon}
                            />
                        )}
                        {row.name}
                    </div>

                </TableCell>
            );
        }
        if (headCell.id === 'linked_resources') {
            const chipsToDisplay = [];
            if (row.linked_tickets_count && row.linked_tickets_count !== 0) {
                chipsToDisplay.push({
                    id: `${row.id}-tickets-count`,
                    value: row.linked_tickets_count > 1
                        ? `${row.linked_tickets_count} ${t('tickets:tickets')?.toLowerCase()}`
                        : `${row.linked_tickets_count} ${t('tickets:ticket')}`,
                    iconType: 'tickets'
                });
            } if (row.linked_rounds_count && row.linked_rounds_count !== 0) {
                chipsToDisplay.push({
                    id: `${row.id}-rounds-count`,
                    value: row.linked_rounds_count > 1
                        ? `${row.linked_rounds_count} ${t('rounds:active_rounds').toLowerCase()}`
                        : `${row.linked_rounds_count} ${t('rounds:active_round')}`,
                    iconType: 'rounds'
                });
            } if (row.linked_devices_count && row.linked_devices_count !== 0) {
                chipsToDisplay.push({
                    id: `${row.id}-devices-count`,
                    value: row.linked_devices_count > 1
                        ? `${row.linked_devices_count} ${t('devices:devices')?.toLowerCase()}`
                        : `${row.linked_devices_count} ${t('devices:device')}`,
                    iconType: 'devices'
                });
            }
            return (
                <TableCell key={headCell.id} sx={{ padding: '0 16px 0 16px', height: rowsHeight }}>
                    <Component
                        componentName={'ChipButton'}
                        maxVisible={3}
                        chipsToDisplay={chipsToDisplay}
                    />
                </TableCell>
            );
        }
        return (
            <TableCell key={headCell.id} sx={{ padding: '0 16px 0 16px', height: rowsHeight }}>
                <Component
                    componentName={'GroupButton'}
                    isNotInGroupList={headCell.id?.includes('templates')}
                    listToFilterFrom={props.templatesList}
                    key={row.group_id}
                    group_memberships={row.group_ids}
                    maxVisible={maxVisibleGroups}
                />
            </TableCell>
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <Paper className={'settings-table-wrapper'} sx={{ height: fixedTableHeight ? '92%' : 'auto', overflow: 'hidden' }}>
                <Component
                    componentName={'SettingsTableToolbar'}
                    numSelected={selected.length}
                    selected={selected}
                    setSelected={(element) => setSelected(element)}
                    tableTitle={props.tableTitle}
                    customHeight={'8%'}
                />
                <TableContainer sx={{ height: '82%' }}>
                    <Table
                        stickyHeader
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <Component
                            componentName={'SettingsTableHead'}
                            customHeight={'10%'}
                            headCells={headCells}
                            columnsToHide={props.columnsToHide}
                            numSelected={selected.length}
                            rows={rows}
                            order={order}
                            orderBy={orderBy}
                            setOrder={value => setOrder(value)}
                            setOrderBy={value => setOrderBy(value)}
                            setSelected={value => setSelected(value)}
                            checkboxColor={checkboxColor}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
             rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={event => handleClickOnRow(event, row.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    style={{ color: checkboxColor }}
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    onClick={event => handleClick(event, row.id)}
                                                />
                                            </TableCell>
                                            {headCells
                                                .filter(headCell => !props.columnsToHide.includes(headCell.id))
                                                .map(headCell => getComponentFromHeadCell(headCell, row))
                                            }
                                        </TableRow>
                                    );
                                })}
                            {/* To make sure the last page has the same heights as others*/}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: rowsHeight * emptyRows + (emptyRows > 1 ? rowsPerPage - 1 : 1) // get the right size for the row (rowsPerPage - 1 to count the borders height)
                                    }}
                                >
                                    <TableCell colSpan={headCells.length + 1} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[3, 4, 5, 6, 7, 8, 9, 10, { label: t('settings:max'), value: rows.length }]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </ThemeProvider>
    );
};

SettingsTable.propTypes = {
    columnsToHide: PropTypes.array.isRequired,
    headCells: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    tableTitle: PropTypes.string.isRequired
};

export default SettingsTable;

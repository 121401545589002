// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { capitalize, isEmpty, sortBy } from 'lodash';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
// Actions
import { getGroupSummary, setGroupShownInDetailPopup } from '../actions';
// Context
import { useModal } from '../../modal/ModalContext';
import { useFootBar } from '../../footbar/FootBarContext';
import { useComponentsPool } from '../../../ComponentsPool';
// Helper
import { filterTable } from '../../navbar/helper';

const filterGroup = (filters, list, props) => filterTable(
    filters,
    list,
    props.usersList,
    [],
    ['user', 'group']
);

export default () => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const isSort = useSelector(state => state.workspace?.settings?.table_sorting_group);
    const groupList = useSelector(state => state.groups.list);
    const table = useSelector(state => state.core.table);
    const tableColumns = useSelector(state => sortBy(state.groups.tableColumns, 'weight'));
    const [groups, setGroups] = useState(groupList);
    const filters = useSelector(state => state.navbar.filters);
    const usersList = useSelector(state => state.users.list);
    const dispatch = useDispatch();
    const modal = useModal();
    const footBar = useFootBar();
    const { getComponentFile } = useComponentsPool();
    const [columnExtensions] = useState([
        { columnName: 'name', sortingEnabled: false },
        { columnName: 'info', sortingEnabled: false },
        { columnName: 'addUser', sortingEnabled: false }
    ]);

    const prepareColumn = (column, count) => {
        let title = t(column.title, { count });
        if (column.isCapitalized) {
            title = capitalize(title);
        }
        return {
            name: column.name,
            title,
            customProps: column.props,
            component: column.component ? getComponentFile(column.component) : null
        };
    };

    const UserGroupTableColumns = tableColumns.map(column => prepareColumn(column, groupList.length));

    const getLogs = async group => {
        await dispatch(setGroupShownInDetailPopup(group.id));
        await dispatch(getGroupSummary(group.id, 1));
        const selected = groupList.find(item => group.id === item.group_id);
        footBar.close();
        modal.update({ name: 'GroupDetailsPopup', item: selected, withFootBar: true });
    };

    const toggleSelected = selected => {
        const selectFiltered = groupList.filter(group => selected.includes(group.group_id));
        footBar.update({ selected, list: selectFiltered, type: 'groups:user' });
    };

    useEffect(() => {
        setGroups(filterGroup(filters, groupList, { usersList }));
    }, [filters, groupList]);

    return isEmpty(groupList) ? <Component componentName={'NoData'} type={'groups'} popup={'CreateGroupPopup'} /> : (
        <AutoSizer disableWidth>
            {({ height }) => (
                <Component
                    componentName={'Table'}
                    height={height}
                    columns={UserGroupTableColumns}
                    list={groups}
                    columnExtensions={columnExtensions}
                    onRowClick={alert => (table.disableRowClick.includes('group') ? {} : getLogs(alert))}
                    toggleSelected={toggleSelected}
                    isSort={isSort}
                />
            )}
        </AutoSizer>
    );
};

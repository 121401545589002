import React from 'react';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import Button from '../../modules/layout/components/Button';
import Input from '../../modules/layout/components/Input';
import config from '../../config.json';

export const devMode = (_, e) => {
    const { currentTarget: { value } } = e;
    localStorage.removeItem('refresh');
    localStorage.removeItem('access');
    localStorage.setItem('iot_rocket_workspace', value);
    localStorage.setItem('iot_rocket_api_url', `${window.location.protocol}//${ value }.api.local.iot-rocket.space`);
    axios.defaults.baseURL = `${localStorage.getItem('iot_rocket_api_url')}/v${config.apiVersion}/`;
};

export const DebugLocalStorage = () => {
    const [workspaceName] = React.useState(localStorage.getItem('iot_rocket_workspace'));
    const [isActive, setIsActive] = React.useState(workspaceName === null || workspaceName === '' || workspaceName === `${window.location.origin.replace(/^http[s]*:\/\//, '')}/login`);
    React.useEffect(() => {
        const name = localStorage.getItem('iot_rocket_workspace');
        setIsActive(name === null || name === '' || name === `${window.location.origin.replace(/^http[s]*:\/\//, '')}/login`);
    }, [localStorage.getItem('iot_rocket_workspace')]);

    return IS_DEBUG && isActive ? (
        <div
            style={{
                border: '1px solid #cccccc', borderRadius: '5px', padding: '15px', margin: '15px'
            }}
        >
            <Typography variant={'subtitle2'} style={{ textAlign: 'center' }}>DEV MODE:</Typography>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <Typography variant={'subtitle2'} style={{ textAlign: 'center' }}>N'oublier pas de modifier votre localStorage</Typography>
            <Divider light />
            <Input
                required
                type={'text'}
                label={'Workspace name'}
                onChangeState={(_, e) => devMode(_, e)}
            />
        </div>
    ) : null;
};

export const SelectGraphType = props => (IS_DEBUG ? ['curve', 'gauge', 'piechart', 'column'].map(type => <Button onClick={() => props.switchGraph(type)} key={type} text={type} />) : <span />);

export default () => {
    console.log(`%c --------------------------------`, 'color: #BC84E5'); // eslint-disable-line
    console.log(`%c ---- DEBUG_MODE_IOT_ROCKET -----`, 'color: #BC84E5'); // eslint-disable-line
    console.log(`%c --------------------------------`, 'color: #BC84E5'); // eslint-disable-line
};

/* eslint-disable object-curly-newline */
import update from 'immutability-helper';
import { find, includes, isEmpty } from 'lodash';

const initialState = () => ({
    actionsToTrigger:
        [
            { actions: ['ALERT_SMS_NO_NUMBER_WARNING'], type: 'warning', message: 'alert_sms_no_number_warning', icon: 'exclamationTriangleLight' },
            { actions: ['ADD_DEVICE_FAILED', 'ADD_DEVICE_REJECTED'], message: 'add_device_failed', type: 'error', icon: 'frown' },
            { actions: ['ADD_DEVICE_FULFILLED'], message: 'add_device_success', type: 'successful', icon: 'check' },
            { actions: ['ERROR'], type: 'error', icon: 'frown' },
            { actions: ['RESET_NOTIFICATION'], message: '', type: '', icon: '' },
            { actions: ['EDIT_DEVICE_FULFILLED'], message: 'edit_device_success', type: 'successful', icon: 'check' },
            { actions: ['RESET_DEVICE_FULFILLED'], message: 'reset_device_success', type: 'successful', icon: 'check' },
            { actions: ['EDIT_DEVICE_GROUPS_FULFILLED'], message: 'edit_device_group_success', type: 'successful', icon: 'check' },
            { actions: ['EDIT_DEVICE_FAILED'], message: 'edit_device_error', type: 'error', icon: 'frown' },
            { actions: ['REFRESH_TOKENS_FAILED', 'REFRESH_TOKENS_REJECTED', 'CHECK_ACCOUNT_ACTIVATION_TOKEN_FAILED', 'CHECK_ACCOUNT_ACTIVATION_TOKEN_REJECTED'], message: 'invalid_token', type: 'error', icon: 'frown' },
            { actions: ['UPLOAD_AVATAR_FAILED', 'UPLOAD_AVATAR_FULFILLED'], message: 'upload_avatar_success', type: 'successful', icon: 'check' },
            { actions: ['SET_PREVIEW_SETTINGS_FULFILLED'], message: 'settings_save_success', type: 'successful', icon: 'check' },
            { actions: ['ADD_DEVICE_TO_GROUP_FAILED', 'CHANGE_ROLE_FAILED', 'CHANGE_ROLE_REJECTED'], message: 'change_role_error', type: 'error', icon: 'frown' },
            { actions: ['CONFIG_DEVICE_FAILED', 'CREATE_GROUP_FAILED', 'DELETE_GRAPH_FAILED', 'EDIT_GROUP_FAILED', 'EDIT_USER_PROFILE_FAILED', 'GET_FILTRED_DEVICE_LIST_FAILED', 'GET_FILTRED_DEVICE_LIST_REJECTED'], message: 'filter_device_error', type: 'error', icon: 'frown' },
            { actions: ['EDIT_GROUP_REJECTED'], message: 'edit_group_error', type: 'error', icon: 'frown' },
            { actions: ['GET_GROUP_ACLS_FAILED', 'GET_GROUP_ACLS_REJECTED'], message: 'get_group_acls_error', type: 'error', icon: 'frown' },
            { actions: ['GET_GROUP_LIST_FAILED', 'GET_GROUP_LIST_REJECTED'], message: 'get_group_list_error', type: 'error', icon: 'frown' },
            { actions: ['GET_GROUP_ROLES_FAILED', 'GET_GROUP_ROLES_REJECTED'], message: 'get_group_roles_error', type: 'error', icon: 'frown' },
            { actions: ['GET_USER_LIST_FAILED', 'GET_USER_LIST_REJECTED'], message: 'get_user_list_error', type: 'error', icon: 'frown' },
            { actions: ['GET_WORKSPACE_ACLS_FAILED', 'GET_WORKSPACE_ACLS_REJECTED'], message: 'get_workspace_acls_error', type: 'error', icon: 'frown' },
            { actions: ['GET_WORKSPACE_ROLES_FAILED', 'GET_WORKSPACE_ROLES_REJECTED'], message: 'get_workspace_roles_error', type: 'error', icon: 'frown' },
            { actions: ['MAKE_USER_ADMIN_FAILED', 'MAKE_USER_ADMIN_REJECTED'], message: 'make_admin_error', type: 'error', icon: 'frown' },
            { actions: ['REMOVE_GROUP_FAILED', 'CHECK_REGISTER_TOKEN_FAILED', 'CHECK_REGISTER_TOKEN_REJECTED'], message: 'check_register_token_error', type: 'error', icon: 'frown' },
            { actions: ['REGISTER_FROM_INVITE_FULFILLED'], message: 'register_from_invite_success', type: 'successful', icon: 'check' },
            { actions: ['REGISTER_FROM_INVITE_REJECTED', 'REGISTER_FROM_INVITE_FAILED', 'REGISTER_REJECTED', 'REGISTER_FAILED'], message: 'register_from_invite_error', type: 'error', icon: 'frown' },
            { actions: ['EDIT_PROFILE_FAILED', 'RESET_PASSWORD_TOKEN_FAILED'], message: 'password_token_error', type: 'error', icon: 'frown' },
            { actions: ['LOGIN_REJECTED'], message: 'bad_credentials', type: 'error', icon: 'frown' },
            { actions: ['EDIT_DASHBOARD_FAILED'], message: 'edit_dashboard_error', type: 'error', icon: 'frown' },
            { actions: ['RESET_PASSWORD_FULFILLED'], message: 'reset_password_success', type: 'successful', icon: 'check' },
            { actions: ['RESET_PASSWORD_REJECTED'], message: 'reset_password_error', type: 'error', icon: 'frown' },
            { actions: ['CREATE_ALERT_REJECTED', 'EDIT_ALERT_REJECTED'], message: 'edit_alert_error', type: 'error', icon: 'frown' },
            { actions: ['EDIT_GROUP_FULFILLED'], message: 'edit_group_success', type: 'successful', icon: 'check' },
            { actions: ['EDIT_BATIMENT_FULFILLED'], message: 'edit_batiment_success', type: 'successful', icon: 'check' },
            { actions: ['EDIT_SKILL_FULFILLED'], message: 'edit_skill_success', type: 'successful', icon: 'check' },
            { actions: ['EDIT_BATIMENT_REJECTED', 'EDIT_BATIMENT_FAILED'], message: 'edit_batiment_error', type: 'error', icon: 'frown' },
            { actions: ['EDIT_SKILL_REJECTED', 'EDIT_SKILL_FAILED'], message: 'edit_skill_error', type: 'error', icon: 'frown' },
            { actions: ['CREATE_GROUP_FULFILLED'], message: 'create_group_success', type: 'successful', icon: 'check' },
            { actions: ['INVITE_USER_FULFILLED'], message: 'user_successfully_invited', type: 'successful', icon: 'check' },
            { actions: ['INVITE_USER_REJECTED', 'INVITE_USER_FAILED', 'RESEND_INVITE_FAILED', 'RESEND_INVITE_REJECTED'], message: 'user_invited_reject', type: 'error', icon: 'frown' },
            { actions: ['DELETE_INVITE_FULFILLED'], message: 'invite_successfully_deleted', type: 'successful', icon: 'check' },
            { actions: ['DELETE_INVITES_FULFILLED'], message: 'invite_successfully_deleted_plural', type: 'successful', icon: 'check' },
            { actions: ['DELETE_INVITE_REJECTED', 'DELETE_INVITE_FAILED'], message: 'invite_deleted_error', type: 'error', icon: 'frown' },
            { actions: ['DELETE_INVITES_REJECTED', 'DELETE_INVITES_FAILED'], message: 'invites_deleted_error', type: 'error', icon: 'frown' },
            { actions: ['INVITE_USERS_FROM_CSV_REJECTED'], type: 'error', message: 'invite_users_from_csv_error', icon: 'frown', isErrorWithMessage: true },
            { actions: ['INVITE_USERS_FROM_CSV_ERROR'], type: 'error', message: 'invite_users_from_csv_error', icon: 'frown', isErrorWithMessage: true },
            { actions: ['INVITE_USERS_FROM_CSV_FULFILLED'], type: 'successful', message: 'invite_users_from_csv_success', icon: 'check' },
            { actions: ['REMOVE_GROUP_FULFILLED'], message: 'delete_group_success', type: 'successful', icon: 'check' },
            { actions: ['REMOVE_GROUPS_FULFILLED'], message: 'delete_group_success_plural', type: 'successful', icon: 'check' },
            { actions: ['ADD_SKILLS_CSV_FILE_REJECTED'], type: 'error', message: 'add_skills_csv_file_error', icon: 'frown', isErrorWithMessage: true },
            { actions: ['ADD_SKILLS_CSV_FILE_FULFILLED'], type: 'successful', message: 'add_skills_csv_file_success', icon: 'check' },
            { actions: ['ADD_BUILDINGS_CSV_FILE_REJECTED'], type: 'error', message: 'add_buildings_csv_file_error', icon: 'frown', isErrorWithMessage: true },
            { actions: ['ADD_BUILDINGS_CSV_FILE_FULFILLED'], type: 'successful', message: 'add_buildings_csv_file_success', icon: 'check' },
            { actions: ['REMOVE_GROUP_FROM_USER_FULFILLED'], message: 'user_updated_success', type: 'successful', icon: 'check' },
            { actions: ['DELETE_USER_FULFILLED'], message: 'user_delete_success', type: 'successful', icon: 'check' },
            { actions: ['DELETE_USERS_REJECTED', 'DELETE_USERS_FAILED'], message: 'users_delete_error', type: 'error', icon: 'frown' },
            { actions: ['DELETE_USER_REJECTED', 'DELETE_USER_FAILED'], message: 'users_delete_error', type: 'error', icon: 'frown' },
            { actions: ['DELETE_USERS_FULFILLED'], message: 'user_delete_success_plural', type: 'successful', icon: 'check' },
            { actions: ['ADD_GROUP_TO_DEVICE_FULFILLED'], message: 'update_device_to_group_success', type: 'successful', icon: 'check' },
            { actions: ['ADD_GROUP_TO_USER_FULFILLED'], message: 'update_user_to_group_success', type: 'successful', icon: 'check' },
            { actions: ['CHANGE_ROLE_FULFILLED'], message: 'user_role_changed_success', type: 'successful', icon: 'check' },
            { actions: ['CHANGE_ROLE_FULFILLED'], message: 'user_role_changed_success', type: 'successful', icon: 'check' },
            { actions: ['EDIT_ALERT_FULFILLED'], message: 'edit_alert_success', type: 'successful', icon: 'check' },
            { actions: ['DELETE_ALERT_FULFILLED'], message: 'delete_alert_success', type: 'successful', icon: 'check' },
            { actions: ['USERS_DELETED_FROM_GROUP'], message: 'user_delete_from_group_success', type: 'successful', icon: 'check' },
            { actions: ['EDIT_USER_PROFILE_FULFILLED'], message: 'edit_profile_success', type: 'successful', icon: 'check' },
            { actions: ['EDIT_USER_PROFILE_REJECTED'], message: 'edit_profile_error', type: 'error', icon: 'frown' },
            { actions: ['USERS_ADDED_TO_GROUP'], message: 'add_group_to_user_success', type: 'successful', icon: 'check' },
            { actions: ['ADD_USERS_WITHOUT_MAIL_FROM_CSV_FULFILLED'], type: 'successful', message: 'add_users_without_mail_success', icon: 'check' },
            { actions: ['ADD_USERS_WITHOUT_MAIL_FROM_CSV_REJECTED'], type: 'error', message: 'add_users_without_mail_error', icon: 'frown', isErrorWithMessage: true },
            { actions: ['ADD_NFC_TAG_CSV_FILE_REJECTED'], type: 'error', message: 'add_nfc_tag_csv_file_error', icon: 'frown', isErrorWithMessage: true },
            { actions: ['ADD_NFC_TAG_CSV_FILE_FULFILLED'], type: 'successful', message: 'add_nfc_tag_csv_file_success', icon: 'check' },
            { actions: ['REMOVE_GROUP_TO_DEVICE_FULFILLED'], message: 'remove_group_device_success', type: 'successful', icon: 'check' },
            { actions: ['POST_DEVICE_COMMENTS_FULFILLED'], message: 'post_device_comments_success', type: 'successful', icon: 'check' },
            { actions: ['POST_INSTANCE_COMMENTS_FULFILLED'], message: 'post_device_comments_success', type: 'successful', icon: 'check' },
            { actions: ['DEVICES_DELETED_FROM_GROUP'], message: 'delete_device_from_group_success', type: 'successful', icon: 'check' },
            { actions: ['GROUP_ADDED_TO_DEVICES'], message: 'add_device_to_group_success', type: 'successful', icon: 'check' },
            { actions: ['SEND_RESET_PASSWORD_INVITE_FULFILLED'], message: 'send_invite_reset_password_success', type: 'successful', icon: 'check' },
            { actions: ['DELETE_DASHBOARD_FULFILLED'], message: 'delete_dashboard_success', type: 'successful', icon: 'check' },
            { actions: ['DELETE_DASHBOARDS_FULFILLED'], message: 'delete_dashboard_success_plural', type: 'successful', icon: 'check' },
            { actions: ['EDIT_DASHBOARD_FULFILLED'], message: 'edit_dashboard_success', type: 'successful', icon: 'check' },
            { actions: ['CREATE_DASHBOARD_FULFILLED'], message: 'create_dashboard_success', type: 'successful', icon: 'check' },
            { actions: ['EDIT_USER_FULFILLED'], message: 'edit_user_success', type: 'successful', icon: 'check' },
            { actions: ['ADD_USER_NOTIFICATION_REGISTRATION_FULFILLED'], message: 'add_user_notification_registration_success', type: 'successful', icon: 'check' },
            { actions: ['DELETE_USER_NOTIFICATION_REGISTRATION_FULFILLED'], message: 'delete_user_notification_registration_success', type: 'successful', icon: 'check' },
            { actions: ['ADD_USER_NOTIFICATION_REGISTRATION_REJECTED', 'ADD_USER_NOTIFICATION_REGISTRATION_FAILED'], message: 'add_user_notification_registration_error', type: 'error', icon: 'frown' },
            { actions: ['DELETE_USER_NOTIFICATION_REGISTRATION_REJECTED', 'DELETE_USER_NOTIFICATION_REGISTRATION_FAILED'], message: 'delete_user_notification_registration_error', type: 'error', icon: 'frown' },
            { actions: ['EDIT_USER_REJECTED', 'EDIT_USER_FAILED'], message: 'edit_user_error', type: 'error', icon: 'frown' },
            { actions: ['CREATE_ALERT_FULFILLED'], message: 'create_alert_success', type: 'successful', icon: 'check' },
            { actions: ['CHECK_ACCOUNT_ACTIVATION_TOKEN_FULFILLED'], message: 'check_account_activation_token_success', type: 'successful', icon: 'check' },
            { actions: ['RESEND_INVITE_FULFILLED'], message: 'user_successfully_invited', type: 'successful', icon: 'check' },
            { actions: ['SEND_RESET_PASSWORD_INVITE_FAILED', 'SEND_RESET_PASSWORD_INVITE_REJECTED', 'CREATE_WORKSPACE_FAILED', 'CREATE_WORKSPACE_REJECTED', 'SEND_INVITES_FAILED', 'SEND_INVITES_REJECTED', 'CREATE_TOKEN_FAILED', 'CREATE_TOKEN_REJECTED', 'RECOVER_WORKSPACE_FAILED', 'RECOVER_WORKSPACE_REJECTED'], message: 'error_occurred', type: 'error', icon: 'frown' },
            { actions: ['RECOVER_WORKSPACE__INVITE_FULFILLED', 'RECOVER_WORKSPACE_FULFILLED'], message: 'recover_workspace_email_sent', type: 'successful', icon: 'check' },
            { actions: ['CHECK_SECURITY_CODE_REJECTED'], message: 'invalid_security_code', type: 'error', icon: 'frown' },
            { actions: ['CREATE_WORKSPACE_FULFILLED'], message: 'create_workspace_success', type: 'successful', icon: 'check' },
            { actions: ['SEND_INVITES_FULFILLED'], message: 'send_invites_success', type: 'successful', icon: 'check' },
            { actions: ['CHECK_WORKSPACE_NAME_REJECTED'], message: 'workspace_already_exists', type: 'error', icon: 'frown' },
            { actions: ['HARDWARE_IDS_ALREADY_ASSIGN_FULFILLED', 'HARDWARE_IDS_ALREADY_ASSIGN'], message: 'hardware_already_assigned', sub_message: 'hardware_already_assigned_sub', type: 'error', icon: 'exclamationTriangle', toast_type: 'confirm' },
            { actions: ['EDIT_DEVICE_REJECTED'], type: 'error', message: 'edit_device_error', icon: 'frown' },
            { actions: ['ADD_DEVICE_CSV_FILE_REJECTED'], type: 'error', message: 'add_device_csv_file_error', icon: 'frown', isErrorWithMessage: true },
            { actions: ['ADD_DEVICE_CSV_FILE_FULFILLED'], type: 'successful', message: 'add_device_csv_file_success', icon: 'check' },
            { actions: ['POST_DEVICE_COMMENTS_REJECTED'], message: 'post_device_comments_error', type: 'error', icon: 'frown' },
            { actions: ['POST_DEVICE_COMMENTS_FAILED'], message: 'post_device_comments_error', type: 'error', icon: 'frown' },
            { actions: ['POST_INSTANCE_COMMENTS_REJECTED'], message: 'post_device_comments_error', type: 'error', icon: 'frown' },
            { actions: ['POST_INSTANCE_COMMENTS_FAILED'], message: 'post_device_comments_error', type: 'error', icon: 'frown' },
            { actions: ['DELETE_DEVICE_FULFILLED'], type: 'successful', message: 'delete_device_success', icon: 'check' },
            { actions: ['DELETE_DEVICES_FULFILLED'], type: 'successful', message: 'delete_devices_success', icon: 'check' },
            { actions: ['DELETE_DEVICE_REJECTED', 'DELETE_DEVICE_FAILED'], type: 'error', message: 'delete_device_failed', icon: 'frown', isErrorWithMessage: true },
            { actions: ['DELETE_DEVICES_REJECTED', 'DELETE_DEVICES_FAILED'], type: 'error', message: 'delete_devices_failed', icon: 'frown', isErrorWithMessage: true },
            { actions: ['SAVE_PASSAGE_REJECTED', 'SAVE_PASSAGE_FAILED'], type: 'error', message: 'rejected_passage_message', icon: 'frown' },
            { actions: ['GET_ROUND_DATA_EXPORT_REJECTED', 'GET_ROUND_DATA_EXPORT_FAILED'], type: 'error', message: 'get_round_data_export_error', icon: 'frown' },
            { actions: ['GET_INSTANCE_EVENTS_LIST_REJECTED', 'GET_INSTANCE_EVENTS_FAILED'], type: 'error', message: 'get_instance_events_list_error', icon: 'frown' },
            { actions: ['EDIT_ROUND_REJECTED', 'EDIT_ROUND_FAILED'], type: 'error', message: 'edit_round_error', icon: 'frown' },
            { actions: ['EDIT_ROUND_CUSTOM_ERRORS_REJECTED', 'EDIT_ROUND_CUSTOM_ERRORS_FAILED'], type: 'error', message: 'edit_round_error', icon: 'frown', isErrorWithMessage: true },
            { actions: ['EDIT_ROUND_FULFILLED', 'EDIT_ROUND_CUSTOM_ERRORS_FULFILLED'], type: 'successful', message: 'edit_round_success', icon: 'check' },
            { actions: ['DELETE_ROUND_REJECTED', 'DELETE_ROUND_FAILED'], type: 'error', message: 'delete_round_error', icon: 'frown' },
            { actions: ['ARCHIVE_ROUND_REJECTED', 'ARCHIVE_ROUND_FAILED'], type: 'error', message: 'archive_round_error', icon: 'frown' },
            { actions: ['DELETE_ROUND_FULFILLED'], type: 'successful', message: 'delete_round', icon: 'check' },
            { actions: ['ARCHIVE_ROUND_FULFILLED'], type: 'successful', message: 'archive_round', icon: 'check' },
            { actions: ['DELETE_INSTANCE_FULFILLED'], type: 'successful', message: 'delete_instance', icon: 'check' },
            { actions: ['DELETE_INSTANCE_REJECTED'], type: 'error', message: 'delete_instance_error', icon: 'frown' },
            { actions: ['DELETE_INSTANCE_FAILED'], type: 'error', message: 'delete_instance_error', icon: 'frown' },
            { actions: ['EXPORT_INSTANCES_REJECTED', 'EXPORT_INSTANCES_FAILED'], type: 'error', message: 'export_instances_error', icon: 'frown' },
            { actions: ['UPDATE_INSTANCE_REJECTED', 'UPDATE_INSTANCE_FAILED'], type: 'error', message: 'update_instance_error', icon: 'frown' },
            { actions: ['CREATE_ROUND_FULFILLED', 'CREATE_ROUND_CUSTOM_ERRORS_FULFILLED'], type: 'successful', message: 'create_round_success', icon: 'check' },
            { actions: ['CREATE_ROUND_REJECTED', 'CREATE_ROUND_FAILED'], type: 'error', message: 'create_round_error', icon: 'frown' },
            { actions: ['CREATE_ROUND_CUSTOM_ERRORS_REJECTED', 'CREATE_ROUND_CUSTOM_ERRORS_FAILED'], type: 'error', message: 'create_round_error', isErrorWithMessage: true, icon: 'frown' },
            { actions: ['ADD_ROUNDS_CSV_FILE_REJECTED'], type: 'error', message: 'add_round_csv_file_error', icon: 'frown', isErrorWithMessage: true },
            { actions: ['ADD_ROUNDS_CSV_FILE_FULFILLED'], type: 'successful', message: 'add_round_csv_file_success', icon: 'check' },
            { actions: ['POST_TICKET_COMMENTS_REJECTED'], type: 'error', message: 'post_ticket_comment_error', icon: 'frown' },
            { actions: ['EVENT_IS_TOO_SHORT_ERROR'], type: 'error', message: 'event_cannot_last_less_than_a_minute', icon: 'frown' },
            { actions: ['NO_FINISHED_EVENT_TO_EXPORT_ERROR'], type: 'error', message: 'no_finished_event_to_export', icon: 'frown' },
            { actions: ['EVENT_IS_TOO_LONG_ERROR'], type: 'error', message: 'event_cannot_last_more_than_a_year', icon: 'frown' },
            { actions: ['STARTED_AT_BIGGER_THAN_ENDED_AT_ERROR'], type: 'error', message: 'start_date_cannot_be_greater_than_end_date', icon: 'frown' },
            { actions: ['JOB_WAS_REMOVED_WARNING'], type: 'warning', message: 'job_was_removed', icon: 'exclamationTriangleLight' },
            { actions: ['JOBS_WERE_REMOVED_WARNING'], type: 'warning', message: 'jobs_were_removed', icon: 'exclamationTriangleLight' },
            { actions: ['ASSIGNEE_WAS_REMOVED_WARNING'], type: 'warning', message: 'assigned_user_removed_from_round', icon: 'exclamationTriangleLight' },
            { actions: ['ASSIGNEES_WERE_REMOVED_WARNING'], type: 'warning', message: 'assigned_users_removed_from_round', icon: 'exclamationTriangleLight' },
            { actions: ['FOLLOWER_WAS_REMOVED_WARNING'], type: 'warning', message: 'follower_user_removed_from_round', icon: 'exclamationTriangleLight' },
            { actions: ['FOLLOWERS_WERE_REMOVED_WARNING'], type: 'warning', message: 'follower_users_removed_from_round', icon: 'exclamationTriangleLight' },
            { actions: ['RECURRENCE_WAS_RESET_WARNING'], type: 'warning', message: 'recurrence_was_reset', icon: 'exclamationTriangleLight' },
            { actions: ['ALL_EVENTS_WERE_UPDATED_SUCCESS'], type: 'successful', message: 'all_events_were_updated', icon: 'check' },
            { actions: ['SOME_EVENTS_WERENT_UPDATED_WARNING'], type: 'warning', message: 'some_events_werent_updated', icon: 'exclamationTriangleLight' },
            { actions: ['NO_EVENTS_UPDATED_ERROR'], type: 'error', message: 'no_events_updated', icon: 'frown' },
            { actions: ['ALL_ROUNDS_WERE_ARCHIVED_SUCCESS'], type: 'successful', message: 'all_rounds_archived_successfully', icon: 'check' },
            { actions: ['SOME_ROUNDS_WERENT_ARCHIVED_WARNING'], type: 'warning', message: 'rounds_archived_partially', icon: 'exclamationTriangleLight' },
            { actions: ['NO_ROUNDS_ARCHIVED_ERROR'], type: 'error', message: 'no_rounds_archived', icon: 'frown' },
            { name: 'status', subjectWithTarget: 'ticket', translate: 'logs:edit_status' },
            { name: 'title', subjectWithTarget: 'ticket', translate: 'logs:edit_title' },
            { name: 'assigners', subjectWithTarget: 'ticket', translate: 'logs:assigners', translateNoSubject: 'logs:assigners' },
            { actions: ['CREATE_TICKET_FULFILLED'], type: 'successful', message: 'create_ticket', icon: 'check' },
            { actions: ['CREATE_TICKET_FAILED', 'CREATE_TICKET_REJECTED'], type: 'error', message: 'create_ticket_error', icon: 'frown' },
            { actions: ['UPDATE_TICKET_FAILED', 'UPDATE_TICKET_REJECTED'], type: 'error', message: 'update_ticket_error', icon: 'frown' },
            {
                actions: [
                    'GET_TICKET_SUMMARY_FAILED', 'GET_TICKET_SUMMARY_REJECTED',
                    'GET_TICKET_FROM_KEY_FAILED', 'GET_TICKET_FROM_KEY_REJECTED'
                ],
                type: 'error',
                message: 'get_ticket_failed',
                icon: 'frown'
            },
            {
                actions: [
                    'GET_TICKETS_FAILED', 'GET_TICKETS_REJECTED',
                ],
                type: 'error',
                message: 'get_tickets_error',
                icon: 'frown'
            },
            {
                actions: [
                    'EDIT_TICKET_FULFILLED'
                ],
                type: 'successful',
                message: 'edit_ticket_success',
                icon: 'check'
            },
            {
                actions: [
                    'EDIT_TICKET_REJECTED',
                    'EDIT_TICKET_FAILED'
                ],
                type: 'error',
                message: 'edit_ticket_error',
                icon: 'frown'
            },
            {
                actions: [
                    'DELETE_TICKET_FULFILLED'
                ],
                type: 'successful',
                message: 'delete_ticket_success',
                icon: 'check'
            },
            {
                actions: [
                    'DELETE_TICKETS_FULFILLED'
                ],
                type: 'successful',
                message: 'delete_tickets_success',
                icon: 'check'
            },
            {
                actions: [
                    'DELETE_TICKETS_FAILED',
                    'DELETE_TICKETS_REJECTED'
                ],
                type: 'error',
                message: 'delete_tickets_error',
                icon: 'frown'
            },
            {
                actions: [
                    'DELETE_TICKET_FAILED',
                    'DELETE_TICKET_REJECTED'
                ],
                type: 'error',
                message: 'delete_ticket_error',
                icon: 'frown'
            },
            {
                actions: [
                    'DELETE_INSTANCE_COMMENTS_FULFILLED',
                    'DELETE_DEVICE_COMMENTS_FULFILLED'
                ],
                type: 'successful',
                message: 'delete_comment_success',
                icon: 'check'
            },
            {
                actions: [
                    'DELETE_INSTANCE_COMMENTS_REJECTED',
                    'DELETE_INSTANCE_COMMENTS_FAILED',
                    'DELETE_DEVICE_COMMENTS_REJECTED',
                    'DELETE_DEVICE_COMMENTS_FAILED'],
                type: 'error',
                message: 'delete_comment_error',
                icon: 'frown'
            },
            { actions: ['SEND_ROUND_NOTIF_LINK_FAILED', 'SEND_ROUND_NOTIF_LINK_REJECTED'], type: 'error', message: 'email_notification_error', icon: 'frown' },
            { actions: ['SEND_ROUND_NOTIF_LINK_FULFILLED'], type: 'successful', message: 'email_notification_success', icon: 'check' },
            {
                actions: ['UPDATE_BUILDING_FAILED'],
                type: 'error',
                message: 'update_building_error',
                icon: 'frown'
            }, {
                actions: ['UPDATE_BUILDING_REJECTED'],
                type: 'error',
                message: 'update_building_error_name_already_exist',
                icon: 'frown'
            }, {
                actions: [
                    'DELETE_BUILDINGS_REJECTED',
                    'DELETE_BUILDINGS_FAILED'
                ],
                type: 'error',
                message: 'delete_buildings_error',
                icon: 'frown'
            },{
                actions: [
                    'CREATE_BUILDING_REJECTED',
                    'CREATE_BUILDING_FAILED'
                ],
                type: 'error',
                message: 'create_buildings_error',
                icon: 'frown'
            },
            { actions: ['UPDATE_WORKSPACE_SETTING_FULFILLED', 'EDIT_DOCUMENT_FULFILLED'], type: 'successful', message: 'modifications_were_made', icon: 'check' },
            { actions: ['UPDATE_WORKSPACE_SETTING_REJECTED', 'EDIT_DOCUMENT_REJECTED', 'EDIT_DOCUMENT_FAILED'], type: 'error', message: 'modifications_werent_made', icon: 'frown' },
            { actions: ['DELETE_DOCUMENT_REJECTED', 'DELETE_DOCUMENT_FAILED'], type: 'error', message: 'delete_document_error', icon: 'frown' },
            { actions: ['DELETE_DOCUMENT_FULFILLED'], type: 'successful', message: 'delete_document_success', icon: 'check' },
            { actions: ['CREATE_DOCUMENT_FULFILLED'], type: 'successful', message: 'create_document_success', icon: 'check' },
            { actions: ['CREATE_DOCUMENT_REJECTED', 'CREATE_DOCUMENT_FAILED'], type: 'error', message: 'create_document_error', icon: 'frown' },
            { actions: ['UPDATE_BUILDING_FULFILLED'], type: 'successful', message: 'update_building', icon: 'check' },
            { actions: ['CREATE_BUILDING_FULFILLED'], type: 'successful', message: 'create_building', icon: 'check' },
            { actions: ['DELETE_GROUP_FULFILLED'], type: 'successful', message: 'delete_group_success', icon: 'check' },
            { actions: ['DELETE_BATIMENT_FULFILLED'], type: 'successful', message: 'delete_batiment_success', icon: 'check' },
            { actions: ['DELETE_SKILL_FULFILLED'], type: 'successful', message: 'delete_skill_success', icon: 'check' },
            { actions: ['DELETE_JOB_FULFILLED'], type: 'successful', message: 'delete_job_success', icon: 'check' },
            { actions: ['DELETE_GROUPS_FULFILLED'], type: 'successful', message: 'delete_groups_success', icon: 'check' },
            { actions: ['DELETE_BATIMENTS_FULFILLED'], type: 'successful', message: 'delete_batiments_success', icon: 'check' },
            { actions: ['DELETE_SKILLS_FULFILLED'], type: 'successful', message: 'delete_skills_success', icon: 'check' },
            { actions: ['DELETE_JOBS_FULFILLED'], type: 'successful', message: 'delete_jobs_success', icon: 'check' },
            { actions: ['DELETE_GROUP_REJECTED', 'DELETE_GROUP_FAILED'], type: 'error', message: 'delete_group_error', icon: 'frown', isErrorWithMessage: true },
            { actions: ['DELETE_BATIMENT_REJECTED', 'DELETE_BATIMENT_FAILED'], type: 'error', message: 'delete_batiment_error', icon: 'frown', isErrorWithMessage: true },
            { actions: ['DELETE_SKILL_REJECTED', 'DELETE_SKILL_FAILED'], type: 'error', message: 'delete_skill_error', icon: 'frown', isErrorWithMessage: true },
            { actions: ['DELETE_GROUPS_REJECTED', 'DELETE_GROUPS_FAILED'], type: 'error', message: 'delete_groups_error', icon: 'frown', isErrorWithMessage: true },
            { actions: ['DELETE_BATIMENTS_REJECTED', 'DELETE_BATIMENTS_FAILED'], type: 'error', message: 'delete_batiments_error', icon: 'frown', isErrorWithMessage: true },
            { actions: ['DELETE_SKILLS_REJECTED', 'DELETE_SKILLS_FAILED'], type: 'error', message: 'delete_skills_error', icon: 'frown', isErrorWithMessage: true },
            { actions: ['TICKETS_MOVE_DIFF_COL_ERROR'], type: 'error', message: 'tickets_move_diff_col_error', icon: 'frown' },
            { actions: ['UPDATE_TICKETS_FULFILLED'], type: 'successful', message: 'update_tickets_success', icon: 'check' },
            { actions: ['UPDATE_TICKETS_REJECTED'], type: 'error', message: 'update_tickets_error', icon: 'frown' },
            { actions: ['NETWORK_CONNECTION_ERROR'], type: 'error', message: 'network_connection_error', icon: 'frown' },
        ],
    messageInformation: { toast_type: '', sub_message: '', message: '', type: '', icon: '', count: 0 }
});

let _action;

const notificationReducer = (state = initialState(), action) => {
    if (action.type === 'ADD_NOTIFICATION_ON_ACTION') {
        return update(state, { actionsToTrigger: { $push: action.payload } });
    }
    _action = find(state.actionsToTrigger, a => includes(a.actions, action.type));
    if (!isEmpty(_action)) {
        let message = _action.message;
        if (_action.isErrorWithMessage && action.payload?.response?.data?.detail) {
            message = action.payload.response.data.detail;
        } else if (_action.isErrorWithMessage && action.payload?.response?.data?.length > 0 && action.payload.response.data[0] !== '<') {
            message = action.payload.response.data[0];
        }
        return update(state, {
            messageInformation: {
                $set: {
                    message,
                    type: _action.type,
                    icon: _action.icon ? _action.icon : '',
                    count: _action.count ? _action.count : 0,
                    sub_message: _action.sub_message ? _action.sub_message : '',
                    toast_type: _action.toast_type ? _action.toast_type : '',
                }
            }
        });
    }

    if (action.type === 'NOTIFICATION') {
        return update(state, {
            messageInformation: {
                $set: {
                    message: action.payload?.message !== undefined ? action.payload.message : '',
                    type: action.payload?.type !== undefined ? action.payload.type : state.type,
                    icon: action.payload?.icon !== undefined ? action.payload.icon : state.icon,
                    count: action.payload?.count !== undefined ? action.payload.count : state.count
                }
            }
        });
    }
    return state;
};

export default notificationReducer;

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import { useComponentsPool } from '../../../ComponentsPool';

const RoleSelector = props => {
    const { Component } = useComponentsPool();
    const groups = useSelector(state => state.groups);
    const [t] = useTranslation();

    return (
        <Component
            componentName={'Select'}
            rules={props.rules}
            required={props.required}
            multiple={props.multiple}
            placeHolder={'groups:please_select_role'}
            onChange={value => props.onChange(value)}
            options={groups.roles.map(role => ({
                id: role.group_role_id,
                value: t(`user:${role.group_role_name}`)
            }))}
        />
    );
};

export default RoleSelector;

import update from 'immutability-helper';

import { findIndex } from 'lodash';

const initialState = () => ({
    list: []
});

let index;

const subscriptionReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'GET_SUBSCRIPTION_LIST_FULFILLED':
            return update(state, { list: { $push: action.payload } });
        case 'CREATE_SUBSCRIPTION_FULFILLED':
            return update(state, { list: { $push: [action.payload] } });
        case 'UPDATE_SUBSCRIPTION_FULFILLED':
            index = findIndex(state.list, item => item.id === action.payload.id);
            return update(state, { list: { [index]: { $set: action.payload } } });
        case 'DELETE_SUBSCRIPTION_FULFILLED':
            index = findIndex(state.list, item => item.id === action.payload);
            return update(state, { list: { $splice: [[index, 1]] } });

        default:
            return state;
    }
};

export default subscriptionReducer;

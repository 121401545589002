// Libs
import React, { useState } from 'react';
// Components
import { ChromePicker } from 'react-color';
import Popover from '@material-ui/core/Popover';

const TypeColor = props => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    return (
        <div className={'type-color'}>
            <div
                role={'button'}
                tabIndex={0}
                className={'color'}
                style={{ backgroundColor: props.value }}
                onClick={e => setAnchorEl(e.currentTarget)}
            />
            <span>{props.value.toUpperCase()}</span>
            <Popover
                id={'workspace-settings-color-picker'}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={() => setAnchorEl(null)}
                disableRestoreFocus
            >
                <ChromePicker
                    disableAlpha
                    color={props.value}
                    onChangeComplete={color => {
                        document.documentElement.style.setProperty(`--${props.fn.replace('_', '-')}--rgb`, `${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}`);
                        document.documentElement.style.setProperty(`--${props.fn.replace('_', '-')}`, color.hex);
                        props.setNewValue(color.hex);
                    }}
                />
            </Popover>

        </div>
    );
};

export default TypeColor;

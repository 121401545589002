// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import CircularProgress from '@material-ui/core/CircularProgress';
import { isEqual, shuffle } from 'lodash';
import { getAppLanguage, getLoadingSentences } from '../helper';
import { useComponentsPool } from '../../../ComponentsPool';

const AppLoader = () => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const core = useSelector(state => state.core);
    let sentences = shuffle(getLoadingSentences());
    let currentSentence = sentences[0];
    const [displayedSentence, setDisplayedSentence] = useState(currentSentence);

    useEffect(() => { // Avoid multiple rendering of sentences
        setInterval(() => {
            sentences.splice(0, 1); // Remove the displayed sentence
            if (sentences.length === 0) { // Once all the sentences were displayed we retrieve them again
                sentences = shuffle(getLoadingSentences());
                if (isEqual(currentSentence, sentences[0])) { // We cannot display the same sentence twice in a row
                    sentences.splice(0, 1);
                }
            }
            currentSentence = sentences[0];
            setDisplayedSentence(currentSentence);
        }, 7000);
    }, []);

    const getRightSentence = sentence => {
        const appLang = getAppLanguage();
        const sentenceRightLang = `${appLang}_sentence`;
        return sentence[sentenceRightLang];
    };

    return (
        <div id="app_initialization" className="content">
            {core.initializationError ? (
                <div className={'step-wrapper loading-text'}>
                    <div className="error">
                        <p className={'error-title'}>{ t('notifications:error') }</p>
                        <p className={'error-subtitle'}>{ t('common:contact_your_admin') }</p>
                    </div>
                    <Component componentName={'Icon'} type="ErrorLaptop" />
                </div>
            ) : (
                <div className="step-wrapper did-you-know-wrapper">
                    <div className={'page-header'}><h1>{t('common:did_you_know')}</h1></div>
                    <div className={'did-you-know-content'}>{getRightSentence(displayedSentence)}</div>
                    <div className={'loading-circle'}>
                        <CircularProgress />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AppLoader;


// Libs
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Context
import { useModal } from '../../../modal/ModalContext';
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';
// Actions
import { editUser } from '../../actions';

export default props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const modal = useModal();
    const footBar = useFootBar();
    const item = modal.getWithFootBar() ? modal.getItem() : footBar.getItem();

    const changeUserRole = user => {
        if (user.workspace_role && user.workspace_role?.workspace_role_name?.toLowerCase() !== 'admin') {
            const workspace_role_id = props.workspaceRole.reduce((acc, role) => {
                if (role?.workspace_role_name?.toLowerCase() === 'admin') return role.workspace_role_id;
                return `${acc}`; // TODO : does not work
            }, '');
            dispatch(editUser(user.id, { workspace_role_id }, 'CHANGE_ROLE'));
        }
        if (!user.workspace_role || ['admin', 'owner'].indexOf(user.workspace_role?.workspace_role_name.toLowerCase()) !== -1) {
            const workspace_role_id = props.workspaceRole.reduce((acc, role) => {
                if (role?.workspace_role_name?.toLowerCase() === 'user') return role.workspace_role_id;
                return `${acc}`;
            }, '');
            dispatch(editUser(user.id, { workspace_role_id }, 'CHANGE_ROLE'));
        }
        footBar.close();
    };

    return (
        <Component
            componentName={'FootbarButton'}
            withConfirmationPopover
            displayCondition={props.userWorkspaceRights.includes('UPDATE:User')
            && item.workspace_role && item.workspace_role?.workspace_role_name !== 'Owner'
            && !props.hasInvitations && props.hasConfiguredUsers}
            buttonText={item.workspace_role?.workspace_role_name === 'Admin' ? t('descriptions:make_user') : t('descriptions:make_admin')}
            popoverText={item.workspace_role?.workspace_role_name === 'Admin' ? t('descriptions:change_role_to_user') : t('descriptions:change_role_to_admin')}
            callbackAction={() => changeUserRole(item)}
            cancelButtonLabel={t('common:do_not_change')}
            confirmationButtonLabel={t('common:yes_change')}
            buttonIcon={'crown'}
        />
    );
};

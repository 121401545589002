// Libs
import {
    isEmpty, drop, includes, filter, find, concat, uniqBy
} from 'lodash';
// Helpers
import { getDeviceIdByGroupId, getUsersByGroupId } from '../groups/helper';
// Config
import store from '../../store';

const getIdName = currentFilter => {
    if (currentFilter.device_id) return 'device_id';
    if (currentFilter.group_id) return 'group_id';
    if (currentFilter.id) return 'id';
    if (currentFilter.alert_id) return 'alert_id';
    if (currentFilter.dashboard_id) return 'dashboard_id';
    return '';
};

const filterByUser = props => {
    const filteredList = [];
    const { list, id, users } = props;
    if (list[0].group_id) { // list -> group
        list?.forEach(group => {
            const usersInGroup = getUsersByGroupId(users, group.group_id);
            if (find(usersInGroup, user => user.id === id)) {
                filteredList.push(group);
            }
        });
    } else if (list[0].recipient_for_notifications || list[0].recipient_group_for_notifications) { // list -> alert
        list?.forEach(alert => {
            if (alert.recipient_for_notifications === id) {
                filteredList.push(alert);
            } else if (alert.recipient_group_for_notifications) {
                const usersInGroup = getUsersByGroupId(users, alert.recipient_group_for_notifications);
                if (find(usersInGroup, user => user.id === id)) {
                    filteredList.push(alert);
                }
            }
        });
    } else { // list -> user
        return filter(list, user => user.id === id);
    }
    return filteredList;
};

const filterByDevice = props => {
    const filteredList = [];
    const { list, id, devices } = props;
    if (list[0].group_id) { // list -> deviceGroup
        list?.forEach(deviceGroup => {
            const devicesInGroup = getDeviceIdByGroupId(devices, deviceGroup.group_id);
            if (find(devicesInGroup, device => device === id)) {
                filteredList.push(deviceGroup);
            }
        });
    } else if (list[0].device_to_check || list[0].device_group_to_check) { // list -> alert
        list?.forEach(alert => {
            if (alert.device_to_check === id) {
                filteredList.push(alert);
            } else if (alert.device_group_to_check) {
                const devicesInGroup = getDeviceIdByGroupId(devices, alert.device_group_to_check);
                if (find(devicesInGroup, device => device === id)) {
                    filteredList.push(alert);
                }
            }
        });
    } else { // list -> device
        return filter(list, device => device.device_id === id);
    }
    return filteredList;
};

const filterByGroup = props => {
    const filteredList = [];
    const { list, id } = props;
    if (list[0].group_id) { // list -> deviceGroup
        return filter(list, group => group.group_id === id);
    }
    if (list[0].group_memberships) { // list -> user list
        list?.forEach(user => {
            if (find(user.group_memberships, group => group.group.group_id === id)) {
                filteredList.push(user);
            }
        });
    }
    if (list[0].group_ids) { // list -> dashboard
        list?.forEach(dashboard => {
            if (find(dashboard.group_ids, group_id => group_id === id)) {
                filteredList.push(dashboard);
            }
        });
    } else if (list[0].device_to_check || list[0].device_group_to_check) { // list -> alert
        return filter(list, alert => alert.device_group_to_check === id);
    } else { // list -> device list
        list?.forEach(device => {
            if (find(device.group_ids, group_id => group_id === id)) {
                filteredList.push(device);
            }
        });
    }
    return filteredList;
};

const filterByAlert = props => filter(props.list, alert => alert.alert_id === props.id);

const filterByDashboard = props => filter(props.list, dashboard => dashboard.dashboard_id === props.id);

const filterFunctions = {
    user: filterByUser,
    device: filterByDevice,
    group: filterByGroup,
    alert: filterByAlert,
    dashboard: filterByDashboard
};

// eslint-disable-next-line import/prefer-default-export
export const filterTable = (filters, list, users, devices, filterByCategory, customFunctions, disableFilterByRelation, isCalendarFilter = false) => { // eslint-disable-line
    if (isEmpty(list) || isEmpty(filters)) return list;
    const props = {
        id: filters[0].id, list, users, devices, id_bis: filters[0].id_bis
    };
    let filteredList = list;
    if (includes(filterByCategory, filters[0]?.type)) {
        const type = filters[0]?.type;
        if (type === 'rounds' && filterByCategory.includes('rounds') && isCalendarFilter) {
            props.isCalendarFilter = isCalendarFilter;
        }
        filteredList = !isEmpty(customFunctions) ? customFunctions[type](props) : filterFunctions[type](props);
    }
    //
    if (!disableFilterByRelation && store.getState().core.settings.filterByGroupRelation
        && window.location.pathname !== `/${filters[0]?.type}s` && filters[0].groups && filters[0].groups.length > 0
        && filteredList.length > 0) {
        filteredList = [];
        filters[0].groups.forEach(id => {
            filteredList = concat(filteredList, filterByGroup({
                id, list, users, devices
            }));
        });
        if (!isEmpty(filteredList)) {
            filteredList = uniqBy(filteredList, getIdName(filteredList[0]));
        }
    }
    const newFilters = drop(filters);
    return isEmpty(newFilters) ? filteredList : filterTable(newFilters, filteredList, users, devices, filterByCategory, customFunctions, disableFilterByRelation); // eslint-disable-line
};

import { isEmpty } from 'lodash';

export const getTemplatesList = templates => {
    const returnTemplates = [];
    templates.forEach(template => {
        returnTemplates.push({ id: template.id, value: template.title });
    });
    return returnTemplates;
};

export const getTemplatesFromSkill = (templatesList, skill) => templatesList.filter(template => template.groups === skill);

export const getTemplateInstructionFromTitleAndSkillId = (templatesList, title, skillId) => {
    const filteredTemplate = templatesList.filter(template => template.title === title && template.groups === skillId);
    return !isEmpty(filteredTemplate) ? filteredTemplate[0].instructions : '';
};

export const getTemplatesIdsFromSkill = (templatesList, skill) => getTemplatesFromSkill(templatesList, skill).map(template => template.id);

export const shapeTemplatesListAsGroupsList = templatesList => templatesList.map(template => ({ group_name: template.title, group_id: template.id }));

// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// Components
import { Typography } from '@material-ui/core';
// Scss
import '../scss/pageHeader.scss';

const PageHeader = props => {
    const [t] = useTranslation();
    return (
        <div className="page-header">
            <Typography variant={props.type}>{props.text || t('common:page_header_welcome_upper_text')}</Typography>
            <Typography variant={props.subType}>{props.subText || t('common:page_header_welcome_lower_text_user')}</Typography>
            { props.formExplanationText && <p className={'form-explanation-text'}>{props.formExplanationText}</p>}
        </div>
    );
};

PageHeader.defaultProps = {
    subType: 'h3',
    type: 'h1'
};

PageHeader.propTypes = {
    subType: PropTypes.string,
    type: PropTypes.string
};

export default PageHeader;

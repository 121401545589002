// Lib
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Context
import { isEqual } from 'lodash';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import * as moment from 'moment/moment';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import { Tooltip } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { useComponentsPool } from '../../../../ComponentsPool';
import Icon from '../../../layout/components/Icon';
import { deleteDocument, editDocument, uploadDocument } from '../../../documents/actions';
import DevicesTransferList from '../../../devices/components/DevicesTransferList';
import {
    deepNotEqual,
    EVENT_IMPORT_FILE_ERROR,
    getInputFileUrlForThumbnail,
    segmentTrack,
    useSecondMainColorTooltip
} from '../../../layout/helper';
import { ConfigTabsContext } from '../../ConfigContext';
import { documentFormatIsAvailable, getDocumentInputFileAcceptFormats } from '../../../documents/helper';
import { userCanSeeDevice } from '../../../devices/helper';
import { Toast } from '../../../notification/components';

const EditQRCodesDocument = props => {
    const { t } = useTranslation();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const tooltipStyle = useSecondMainColorTooltip();
    const devices = useSelector(state => state.devices.list);
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [displayEditFileButton, setDisplayEditFileButton] = useState(false);
    const [userIsChoosingFile, setUserIsChoosingFile] = useState(false);
    const [initialDocument] = useState(props.documentInEdition);
    const [modifiedDocument, setModifiedDocument] = useState(initialDocument);
    const [toDeleteDocument, setToDeleteDocument] = useState(false);
    const [hiddenDevicesIds] = useState(initialDocument?.currently_assigned_device_ids.filter(assignedDeviceId => !userCanSeeDevice(devices, assignedDeviceId)));
    const [enableSaveButton, setEnableSaveButton] = useState(false);
    const [enableCancelButton, setEnableCancelButton] = useState(false);
    const [disableTabs, setDisableTabs] = useContext(ConfigTabsContext);

    const uploadImage = files => {
        setIsLoadingImg(true);
        const fileFormat = files[0]?.type?.split('/')[1];
        if (fileFormat && documentFormatIsAvailable(fileFormat)) {
            dispatch(uploadDocument(files[0])).then(res => {
                // The type is only modified for the preview to work
                setModifiedDocument({ ...modifiedDocument, url: res.value.media_url, type: fileFormat.toUpperCase() });
                setTimeout(() => { // File takes around 0.5 sec to appear
                    setIsLoadingImg(false);
                }, 500);
            }).catch(error => {
                segmentTrack(EVENT_IMPORT_FILE_ERROR, { fileSizeInBytes: files[0]?.size, fileFormat });
                toast(
                    <Toast
                        message={t(`notifications:${error.response?.status === 413 ? 'upload_document_error_size' : 'upload_document_error'}`)}
                        icon={'frown'}
                        type={'error'}
                    />, {
                        position: toast.POSITION.BOTTOM_LEFT,
                        className: 'normal',
                        bodyClassName: 'notification-body grow-font-size',
                        progressClassName: 'error-custom-progress-bar'
                    }
                );
                setTimeout(() => { // File takes around 0.5 sec to appear
                    setIsLoadingImg(false);
                }, 500);
            });
        } else {
            toast(
                <Toast
                    message={t('notifications:upload_document_error_format')}
                    icon={'frown'}
                    type={'error'}
                />, {
                    position: toast.POSITION.BOTTOM_LEFT,
                    className: 'normal',
                    bodyClassName: 'notification-body grow-font-size',
                    progressClassName: 'error-custom-progress-bar'
                }
            );
            segmentTrack(EVENT_IMPORT_FILE_ERROR, { fileSizeInBytes: files[0]?.size, fileFormat });
            setTimeout(() => { // File takes around 0.5 sec to appear
                setIsLoadingImg(false);
            }, 500);
        }
    };

    useEffect(() => { // Enable save button, cancel button, and tabs when needed
        const areNotEqualsQRcodes = !isEqual(initialDocument.currently_assigned_device_ids.sort(), modifiedDocument.currently_assigned_device_ids.sort());
        const enableSave = (deepNotEqual(initialDocument, modifiedDocument) || areNotEqualsQRcodes) && !Object.values(modifiedDocument).find(conf => conf === 'error_field');
        const enableCancel = deepNotEqual(initialDocument, modifiedDocument) || areNotEqualsQRcodes || Object.values(modifiedDocument).find(conf => conf === 'error_field');

        setEnableSaveButton(enableSave);
        setEnableCancelButton(enableCancel);
        setDisableTabs(enableSave || enableCancel);
    }, [modifiedDocument]);

    return (
        <div className={'configuration-page-content configuration-page-content-at-the-front'}>
            <Tooltip placement={'right'} classes={tooltipStyle} title={disableTabs ? t('common:switch_button_mode_eye_disabled') : ''}>
                <div className={disableTabs && 'disabled-button'}>
                    <Button className={'go-back-button'} disabled={disableTabs} onClick={() => props.goBackToDocumentsListView()}>
                        <Icon componentName={'Icon'} type={'leftArrow'} />
                        <span>{t('common:back')}</span>
                    </Button>
                </div>
            </Tooltip>
            <div className={'edit-document-page'}>
                <div className={'update-date-and-delete-button'}>
                    {`${t('common:modified_on')} ${moment(modifiedDocument.updated_at).format('DD/MM/YYYY')}`}
                    <Component
                        componentName={'Button'}
                        className={'custom-delete-button'}
                        text={t('common:delete')}
                        onClick={() => setToDeleteDocument(true)}
                    />
                </div>
                <div className={classNames(['document-preview-and-inputs', { 'document-img-is-loading': isLoadingImg }])}>
                    <div className={'document-edit-file-img-wrapper'} onClick={() => { !userIsChoosingFile && !isLoadingImg && props.setPreviewedDocument(modifiedDocument); }}>
                        <div
                            className={classNames(['document-card-content-overlay', { 'preview-button-wrapper': displayEditFileButton }])}
                            onMouseOver={() => setDisplayEditFileButton(true)}
                            onMouseLeave={() => setDisplayEditFileButton(false)}
                        >
                            {displayEditFileButton && (
                                <Button
                                    className={'upload-file-button'}
                                    component="label" // For the upload component to work
                                    htmlFor={'hidden-input'}
                                    onMouseOver={() => setUserIsChoosingFile(true)}
                                    onMouseLeave={() => setUserIsChoosingFile(false)}
                                >
                                    <Icon componentName={'Icon'} type={'faPen'} />
                                    <span>{t('custom:edit_default')}</span>

                                </Button>
                            )}
                            <input
                                id={'hidden-input'}
                                type="file"
                                accept={getDocumentInputFileAcceptFormats()}
                                onChange={e => { uploadImage(e.target.files); }}
                                hidden
                            />
                        </div>
                        {isLoadingImg && <CircularProgress />}
                        <img alt="file-preview" src={getInputFileUrlForThumbnail(modifiedDocument.url, modifiedDocument.type)} />
                    </div>
                    <div className={'inputs'}>
                        <Component
                            title={t('documents:document_name')}
                            componentName={'EditableField'}
                            renderValue={'Input'}
                            required
                            props={{
                                value: modifiedDocument.title,
                                changeInputValue: modifiedDocument.title,
                                required: true,
                                rules: ['maxLength-40', 'selectNotEmpty'],
                                onChange: value => setModifiedDocument({ ...modifiedDocument, title: value })
                            }}
                        />
                        <Component
                            title={t('common:description')}
                            componentName={'EditableField'}
                            renderValue={'Input'}
                            props={{
                                value: modifiedDocument.description,
                                changeInputValue: modifiedDocument.description,
                                rules: ['maxLength-80'],
                                onChange: value => setModifiedDocument({ ...modifiedDocument, description: value })
                            }}
                        />
                        <div className={'accessibility-title'}>{t('documents:visibility')}</div>
                        <div className={'second-main-color-switch switch-with-one-text'}>
                            <Switch
                                checked={!modifiedDocument.needs_authentication}
                                onChange={e => {
                                    setModifiedDocument({ ...modifiedDocument, needs_authentication: !e.target.checked })
                                }}
                            />
                            <span>{t('documents:accessible_with_account')}</span>
                        </div>
                    </div>
                </div>
                <DevicesTransferList
                    hiddenSelectedDevicesIds={hiddenDevicesIds}
                    devices_ids={modifiedDocument.currently_assigned_device_ids} // QR Codes are filtered in DevicesTransferList component
                    changeState={values => setModifiedDocument({ ...modifiedDocument, currently_assigned_device_ids: values.devices_ids })}
                />
            </div>
            <div className={'configuration-menu-footer'}>
                <Component
                    componentName={'Button'}
                    className={'custom-cancel-button'}
                    onClick={() => setModifiedDocument(initialDocument)}
                    text={t('common:cancel')}
                    disabled={!enableCancelButton}
                />
                <Component
                    componentName={'Button'}
                    className={'custom-save-button'}
                    onClick={() => {
                        const onlyModifiedParams = {};
                        Object.keys(modifiedDocument).forEach(key => {
                            if ((key === 'url' || key === 'title' || key === 'description' || key === 'needs_authentication') && modifiedDocument[key] !== initialDocument[key]) {
                                onlyModifiedParams[key] = modifiedDocument[key];
                            } else if (key === 'currently_assigned_device_ids' && !isEqual(modifiedDocument[key].sort(), initialDocument[key].sort())) {
                                onlyModifiedParams.currently_assigned_device_ids = modifiedDocument[key];
                                 hiddenDevicesIds.forEach(hiddenDeviceId => {
                                    if (!onlyModifiedParams.currently_assigned_device_ids.includes(hiddenDeviceId)) {
                                        onlyModifiedParams.currently_assigned_device_ids.push(hiddenDeviceId);
                                    }
                                });
                            }
                        });
                        dispatch(editDocument(modifiedDocument.id, onlyModifiedParams)).then(() => {
                            props.goBackToDocumentsListView();
                            setDisableTabs(false);
                        });
                    }}
                    text={t('common:save')}
                    disabled={!enableSaveButton}
                />
            </div>
            <Dialog // Delete document dialog
                className={'simple-modal'}
                open={toDeleteDocument}
                onClose={() => setToDeleteDocument(false)}
            >
                <h3 className={'custom-modal-title'}>{t('documents:document_deletion')}</h3>
                <div className={'custom-modal-content'}>
                    <p>
                        {t('documents:about_to_delete_document')}<span className={'custom-highlighted-text'}>{` "${initialDocument.title}"`}</span>
                        <span>{`. ${t('documents:delete_document_consequences')}`}</span>
                    </p>
                    <p>{t('documents:document_associated_to')}<span className={'custom-highlighted-text'}>{` ${initialDocument.currently_assigned_device_ids.length} ${t('common:qrcodes')}`}</span>.</p>
                    <p className={'custom-modal-action-text'}>{t('common:would_you_like_to_continue')}</p>
                </div>
                <div className={'custom-modal-action-buttons'}>
                    <Button
                        className={'custom-cancel-button'}
                        onClick={() => setToDeleteDocument(false)}
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button
                        className={'custom-delete-button'}
                        onClick={() => {
                            dispatch(deleteDocument(modifiedDocument.id)).then(() => {
                                props.goBackToDocumentsListView();
                                setDisableTabs(false);
                            });
                        }}
                    >
                        {t('documents:delete_document')}
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};

export default EditQRCodesDocument;

// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Context
import { useModal } from '../../modal/ModalContext';
import { useComponentsPool } from '../../../ComponentsPool';
import { useFootBar } from '../FootBarContext';

const Footbar = props => {
    const modal = useModal();
    const footbar = useFootBar();
    const [t] = useTranslation();
    const { Component } = useComponentsPool();

    let leftActions = null;

    if (FOOTBAR_POSITION === 'top' && modal.isVisible) {
        leftActions = (
            <Component
                className="return-button"
                componentName={'Button'}
                onClick={modal.close}
                startAdornment
                icon={'leftArrow'}
                color={'white'}
                text={t(`${props.locale }:return_to_list`)}
            />
        );
    }

    if (FOOTBAR_POSITION !== 'top' || !modal.isVisible) {
        leftActions = <Component componentName={'ActionBarCheckbox'} list={props.resourceList} />;
    }

    return (
        <div className="actionBar-container">
            { leftActions }
            { props.switchButtonCallback && modal.getName() === props.modal ? (
                <>
                    <Component
                        componentName={'SwitchModeButtons'}
                        resourceType={props.resourceType}
                        callback={() => props.switchButtonCallback()}
                    />
                    {props.alwaysDisplayActionButtons && !footbar.getEditionMode() && (
                        <div className={'action-buttons'}>
                            { props.actionButtons }
                        </div>
                    )}
                </>
            ) : (
                <div className={'action-buttons'}>
                    { props.actionButtons }
                </div>
            )}
        </div>
    );
};

Footbar.defaultProps = {
    alwaysDisplayActionButtons: false,
    modal: '',
    switchButtonCallback: null
};

Footbar.propTypes = {
    actionButtons: PropTypes.array.isRequired,
    alwaysDisplayActionButtons: PropTypes.bool,
    modal: PropTypes.string,
    resourceList: PropTypes.array.isRequired,
    resourceType: PropTypes.string.isRequired,
    switchButtonCallback: PropTypes.func
};

export default Footbar;

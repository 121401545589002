// Libs
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Context
import { useModal } from '../../modal/ModalContext';
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import { createGroup } from '../actions';
import createLoadingSelector from '../../layout/actions';
// Helper
import { isValid } from '../../layout/helper';

const loadingSelector = createLoadingSelector(['ADD_GROUP_TO_DEVICE', 'ADD_GROUP_TO_USER', 'CREATE_GROUP']);
const CreateGroupPopup = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const modal = useModal();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const groups = useSelector(state => state.groups.list);
    const [groupName, setGroupName] = useState('');
    const [groupsDevices, setGroupsDevices] = useState([]);
    const [groupsGroups, setGroupsGroups] = useState([]);
    const [addGroupToUser, setAddGroupToUser] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [isUser] = useState(props.location.pathname === '/groups/users');

    const addGroup = async () => {
        const data = { group_name: groupName };
        if (isUser) {
            data.user_ids = addGroupToUser;
            data.selected_role = selectedRole.id;
        } else {
            if (!isEmpty(groupsDevices)) data.device_ids = groupsDevices;
            if (!isEmpty(groupsGroups)) data.group_ids = groupsGroups;
        }
        await dispatch(createGroup(data));
        modal.close();
    };

    const head = <span>{t('groups:create_group')}</span>;

    const onChange = values => {
        setGroupsGroups(values.selected_groups);
        setGroupsDevices(values.selected_devices);
    };

    const content = (
        <div className={'iot-rocket-form'}>
            <Component
                componentName={'Input'}
                autoFocus
                required
                rules={['isAcceptableString', 'checkIfGroupAlreadyExists', 'maxLength-64']}
                list={groups}
                label={'groups:group_name'}
                onChange={value => setGroupName(value)}
            />
            { isUser ? (
                <>
                    <Component
                        componentName={'UsersSelector'}
                        multiple
                        onChange={setAddGroupToUser}
                    />
                    <Component componentName={'RoleSelector'} name="EditRole" onChange={role => setSelectedRole(role)} />
                </>
            ) : (
                <Component
                    componentName={'DeviceAndGroupSelector'}
                    required
                    onChange={onChange}
                    showDesc
                    showIcon
                    selectedDevices={groupsDevices}
                    selectedGroups={groupsGroups}
                    selectType
                    showAllDevices
                />
            )}
        </div>
    );

    return (
        <Component
            componentName={'Modal'}
            head={head}
            content={content}
            loading={isLoading}
            submitButton
            submitButtonLabel={'common:save'}
            onHandleSubmit={addGroup}
            onSubmitDisabled={isValid(groupName)}
        />
    );
};

export default withRouter(CreateGroupPopup);

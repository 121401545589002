import { clientsClaim } from 'workbox-core';
import { precacheAndRoute } from 'workbox-precaching';

import { setupAssetsCaching } from './services/service-worker-assets-caching';
import { setupRequestsCaching } from './services/service-worker-requests-caching';
import { setupStoreDeviceMessagesOnNetworkError } from './services/service-worker-store-device-messages-on-network-error';
import { setupFirebaseMessaging } from './services/service-worker-firebase-messaging';

if (typeof importScripts === 'function') {
    importScripts('https://storage.googleapis.com/workbox-cdn/releases/6.1.5/workbox-sw.js');
    importScripts('https://www.gstatic.com/firebasejs/10.1.0/firebase-app-compat.js');
    importScripts('https://www.gstatic.com/firebasejs/10.1.0/firebase-messaging-compat.js');
}

self.addEventListener('install', event => {
    // Force le service worker à devenir le nouveau service worker actif
    event.waitUntil(self.skipWaiting());
});

self.addEventListener('activate', event => {
    // Prend le contrôle immédiat de toutes les pages ouvertes
    event.waitUntil(clientsClaim());
});

self.addEventListener('message', event => {
    if (event.data && event.data.action === 'RELOAD_CLIENT_SERVICEWORKER') {
        event.waitUntil(
            self.skipWaiting().then(() => {
                return clientsClaim();
            }).then(() => {
                event.ports[0].postMessage({ status: 'success', action: 'refresh', target: 'all' });
            }).catch(err => {
                event.ports[0].postMessage({ status: 'error', message: err });
            })
        );
    }
});

// Precache all of the assets generated by your build process.
// Their URLs are injected into the manifest variable below.
// This variable must be present somewhere in your service worker file,
// even if you decide not to use precaching. See https://cra.link/PWA
const manifest = self.__WB_MANIFEST;
precacheAndRoute(manifest);

setupAssetsCaching();

setupRequestsCaching();

setupStoreDeviceMessagesOnNetworkError();

setupFirebaseMessaging();


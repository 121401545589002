// Libs
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Drawer } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useComponentsPool } from '../../../../ComponentsPool';
import { emptyInstanceEventsList, getInstanceEventsList } from '../../actions';
import createLoadingSelector from '../../../layout/actions';

const loadingSelector = createLoadingSelector(['GET_INSTANCE_EVENTS_LIST']);

const RoundDetailsDrawer = props => {
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const {Component} = useComponentsPool();
    const useStyles = makeStyles(() => ({
        tooltip: {
            '&::before': {
                marginLeft: '35px'
            }
        },
        drawer: {
            marginLeft: 'auto',
            '& .MuiBackdrop-root': {
                display: 'none'
            },
            '& .MuiDrawer-paper': {
                position: 'absolute',
                transition: 'none !important'
            }
        }
    }));

    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const startTime = moment(props.instance.started_at);
    const endTime = moment(props.instance.ended_at);
    let currentInstanceDateTime = '';
    if (moment(props.instance.started_at).format('DD/MM/YYYY') === moment(props.instance.ended_at).format('DD/MM/YYYY')) {
        currentInstanceDateTime = `${moment(props.instance.started_at).format('[Le] DD/MM/YYYY')} 
        ${startTime.format('[de] HH[h]mm')} à ${endTime.format('HH[h]mm')}`;
    } else {
        currentInstanceDateTime = `${t('common:from_date_hour_to_date_hour', { // Sentence : Du DD/MM/YY à HH[h]mm au DD/MM/YY à HH[h]mm
            startDate: moment(props.instance.started_at).format('DD/MM/YY'),
            startHour: startTime.format('HH[h]mm'),
            endDate: moment(props.instance.ended_at).format('DD/MM/YY'),
            endHour: endTime.format('HH[h]mm')
        })}`;
    }

    return (
        <React.Fragment key={'round-detail-drawer-key'}>
            <Tooltip
                arrow={false}
                classes={{ tooltip: classes.tooltip }}
                title={t('rounds:history-detail')}
                placement={'top'}
            >
                <div
                    tabIndex={0}
                    role={'button'}
                    className={'history-detail-button'}
                    onClick={() => {
                        setIsOpen(true);
                        dispatch(
                            getInstanceEventsList(
                                props.instance,
                                false,
                                currentInstanceDateTime
                            )
                        );
                    }}
                >
                    <Button>
                        <Component
                            componentName={'Icon'}
                            type={'eye'}
                        />
                    </Button>
                </div>
            </Tooltip>
            <Drawer
                anchor="right"
                open={isOpen}
                variant="persistent"
            >
                {
                    isLoading
                        ? (
                            <Box className={classes.drawer} role="presentation">
                                <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
                            </Box>
                        ) : (
                            <Box
                                className={classes.drawer}
                                role="presentation"
                            >
                                <Component
                                    componentName={'RoundHistory'}
                                    currentInstance={props.instance}
                                    backCallBack={() => { setIsOpen(false); dispatch(emptyInstanceEventsList()); }}
                                />
                            </Box>
                        )
                }
            </Drawer>
        </React.Fragment>
    );
};

export default RoundDetailsDrawer;

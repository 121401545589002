// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
    filter, find, includes, isArray, isEmpty, concat, uniqBy, cloneDeep
} from 'lodash';
import { AutoSizer, List } from 'react-virtualized';
// Components
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import { uuid } from 'uuidv4';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
import useRouter from '../../../utils/hook/useRouter';
// Helper
import { addFilter, resetFilters } from '../actions';
import { getTraductionOfGroup } from '../../groups/helper';
import { getUserGroupIdsByType, getVisibleUsers } from '../../users/helper';
import {
    getCurrentRoundView,
    VIEW_CALENDAR,
    VIEW_LIST
} from '../../rounds/components/RoundViewSwitcher/RoundViewSwitcher';
import { getDeviceGroupIcon, isPassageDeviceType, isDeviceInAnomaly } from '../../devices/helper';
import { useFootBar } from '../../footbar/FootBarContext';

// Groups we can filter by depending on the path
const groupTypesByRoute = {
    '/tickets': ['SKILL', 'BATIMENT', 'FLOOR', 'POSITION'],
    '/devices': ['SKILL', 'DEVICE', 'BATIMENT', 'FLOOR', 'POSITION'],
    '/users': ['SKILL', 'BATIMENT'],
    '/dashboards': ['']
};

// Objects we can filter by depending on the path
const filterByRoute = {
    '/tickets': ['tickets', 'user', 'group', 'assigners', 'messagingUsers', 'tags'],
    '/devices': ['device', 'group'],
    '/users': ['user', 'group', 'invite'],
    '/rounds': ['rounds']
};

const formatName = (name, subText, t) => {
    const formText = text => {
        let returnText = '';
        if (text.length > 3) {
            for (let i = 0; i < 3; i = i + 1) {
                returnText = `${returnText} ${text[i]}, `;
            }
            return `${t('common:related_to')} ${returnText} ${t('common:and')} ${text.length - 3} ${t('common:other')}`;
        }
        return text.join(', ');
    };

    return (
        <div className={'result-text-container'}>
            <p>{name}</p>
            {/* eslint-disable-next-line no-nested-ternary */}
            { isArray(subText) ? subText.length > 3 ? (
                <Tooltip title={subText.map(text => <p key={text}>{text}</p>)}>
                    <em>{formText(subText)}</em>
                </Tooltip>
            ) : <em>{subText.join(', ')}</em> : <em>{subText}</em>}
        </div>
    );
};
// Formats all the users/devices/groups/dashboards on a single array for filtering
const setOptions = (currentUser, props, t, groups, filterSkills, workspaceSettings) => {
    const options = [];
    if (includes(filterByRoute[window.location.pathname], 'messagingUsers')
        && currentUser.workspace_role?.workspace_role_name !== 'User'
        && currentUser.workspace_role?.workspace_role_name !== 'Contractor') {
        props.messagingUsers.forEach(messagingUser => {
            options.push({
                category: 'messagingUsers',
                queryName: messagingUser.user_name,
                displayedName: messagingUser.user_name,
                name: t('search:tickets_messaging', { user: messagingUser.user_name }),
                devices: [],
                groups: [],
                id: messagingUser.id
            });
        });
    }
    if (includes(filterByRoute[window.location.pathname], 'user')
        || includes(filterByRoute[window.location.pathname], 'assigners')) {
        getVisibleUsers(props.users).forEach(user => {
            if (!user?.invite_email) {
                const userName = `${user.first_name} ${user.last_name} ${user.nfc_tag ? '(' + user.nfc_tag + ')' : ''}` || '';
                if (includes(filterByRoute[window.location.pathname], 'user')
                    && (
                        currentUser.workspace_role?.workspace_role_name !== 'User'
                        || currentUser.workspace_role?.workspace_role_name === 'User'
                        && workspaceSettings.supervision_skills_to_users
                        && currentUser.group_memberships.some(group => group?.group?.custom_field?.type === 'SKILL')
                    )) {
                    options.push({
                        category: 'user',
                        queryName: `${userName} ${user?.username}`.toLowerCase(),
                        name: window.location.pathname === '/tickets'
                            ? t('search:tickets_author', { user: userName })
                            : `${userName}`,
                        displayedName:
                            formatName(`${userName}`, user?.username, props.t),
                        groups: user.group_memberships
                            .map(membership => (membership.group ? membership.group.group_id : [])),
                        id: user.id,
                        nfc_tag: user.nfc_tag
                    });
                }
                if (includes(filterByRoute[window.location.pathname], 'assigners') && currentUser.workspace_role?.workspace_role_name !== 'User'
                && currentUser.workspace_role?.workspace_role_name !== 'Contractor') {
                    options.push({
                        category: 'assigners',
                        queryName: `${userName} ${user?.username}`.toLowerCase(),
                        name: t('search:tickets_assigners', { user: userName }),
                        groups: user.group_memberships
                            .map(membership => (membership.group ? membership.group.group_id : [])),
                        id: user?.username,
                        id_bis: user.id
                    });
                }
            } else if (includes(filterByRoute[window.location.pathname], 'invite')) {
                options.push({
                    category: 'invite',
                    queryName: user?.invite_email,
                    name: user?.invite_email,
                    displayedName: user?.invite_email,
                    id: user?.id
                });
            }
        });
    }
    if (includes(filterByRoute[window.location.pathname], 'device')) {
        let name = '';
        props.devices.forEach(device => {
            name = device.device_name;
            if (device.hardware_ids?.length > 0) {
                name = `${name } (${ device.hardware_ids[0] })`;
            }
            options.push({
                category: 'device',
                queryName: name,
                displayedName: name,
                name,
                devices: [],
                groups: device.group_ids,
                id: device.device_id,
                groupType: device.custom_field?.type
            });
        });
        props.deviceGroups?.forEach(group => {
            name = group.group_name;
            options.push({
                category: 'group',
                queryName: name,
                displayedName: name,
                name,
                devices: group.devices,
                groups: [],
                id: group.group_id,
                groupType: getDeviceGroupIcon(group)
            });
        });
    }
    if (includes(filterByRoute[window.location.pathname], 'dashboard')) {
        props.dashboards.forEach(dashboard => {
            options.push({
                queryName: dashboard.name.toLowerCase(),
                category: 'dashboard',
                name: dashboard.name,
                id: dashboard.dashboard_id,
                groups: dashboard.groups
            });
        });
    }
    if (
        includes(filterByRoute[window.location.pathname], 'tags') && // Only on tickets
        includes(['Owner', 'Admin', 'Contractor'], currentUser.workspace_role?.workspace_role_name) // Only Owner, Admin and Contractor can filter by tags
    ) {
        props?.tags?.forEach(tag => {
            options.push({
                category: 'tags',
                queryName: tag.title?.toLowerCase(),
                name: t('search:ticket_tag', { tag: tag.title }),
                id: tag.id
            });
        });
    }
    if (includes(filterByRoute[window.location.pathname], 'group')) {
        let filteredGroups = [];

        if (window.location.pathname === '/tickets') {
            const excludeTypes = currentUser.workspace_role?.workspace_role_name === 'Owner'
                ? []
                : filterSkills ? ['BATIMENT', 'SKILL'] : ['BATIMENT'];
            filteredGroups = filter(props.groups, group => includes(
                groupTypesByRoute[window.location.pathname], group.custom_field.type)
                && includes(getUserGroupIdsByType(currentUser, groups, excludeTypes), group.group_id)
            );
        } else if (window.location.pathname === '/devices') {
            const excludeTypes = currentUser.workspace_role?.workspace_role_name === 'Owner'
                ? []
                : ['BATIMENT'];
            filteredGroups = filter(props.groups, group => includes(
                groupTypesByRoute[window.location.pathname], group.custom_field.type)
                && includes(getUserGroupIdsByType(currentUser, groups, excludeTypes), group.group_id)
                && group.custom_field.type !== 'SKILL'
            );
        } else {
            filteredGroups = filter(props.groups,
                group => includes(groupTypesByRoute[window.location.pathname], group.custom_field.type));
        }

        filteredGroups.forEach(group => {
            options.push({
                queryName: getTraductionOfGroup(group.group_name, t).toLowerCase(),
                category: 'group',
                name: group.group_name,
                id: group.group_id,
                groupType: group.custom_field?.type
            });
        });
    }
    return options;
};

const options = [{
    id: uuid(),
    onlyOn: '/tickets',
    excludeRoles: ['User', 'Contractor'],
    category: 'defaultOptions',
    displayedName: 'tickets:not_assigned',
    icon: 'noUser',
    filterFunction: list => filter(list, ticket => isEmpty(ticket.assigners))
}, {
    id: uuid(),
    onlyOn: '/tickets',
    category: 'defaultOptions',
    displayedName: 'tickets:old_today',
    icon: 'dateFilter',
    filterFunction: list => filter(list, ticket => moment(ticket.created_at).isBetween(moment().subtract(24, 'hours'), moment()))
}, {
    id: uuid(),
    onlyOn: '/tickets',
    category: 'defaultOptions',
    displayedName: 'tickets:old_this_week',
    icon: 'dateFilter',
    filterFunction: list => filter(list, ticket => moment(ticket.created_at).isBetween(moment().subtract(1, 'week'), moment()))
}, {
    id: uuid(),
    onlyOn: '/tickets',
    category: 'defaultOptions',
    displayedName: 'tickets:old_this_month',
    icon: 'dateFilter',
    filterFunction: list => filter(list, ticket => moment(ticket.created_at).isBetween(moment().subtract(1, 'month'), moment()))
}, {
    id: uuid(),
    onlyOn: '/tickets',
    category: 'defaultOptions',
    displayedName: 'tickets:old_3_month',
    icon: 'dateFilter',
    filterFunction: list => filter(list, ticket => moment(ticket.created_at).isBetween(moment().subtract(3, 'month'), moment()))
}, {
    id: uuid(),
    onlyOn: '/tickets',
    category: 'defaultOptions',
    displayedName: 'tickets:old_this_year',
    icon: 'dateFilter',
    filterFunction: list => filter(list, ticket => moment(ticket.created_at).isBetween(moment().subtract(1, 'year'), moment()))
}, {
    id: uuid(),
    onlyOn: '/tickets',
    excludeRoles: ['User'],
    category: 'defaultOptions',
    displayedName: 'tickets:priority_low',
    icon: 'normalPriority',
    filterFunction: list => filter(list, ticket => ticket.priority === 0)
}, {
    id: uuid(),
    onlyOn: '/tickets',
    excludeRoles: ['User'],
    category: 'defaultOptions',
    displayedName: 'tickets:priority_normal',
    icon: 'highPriority',
    filterFunction: list => filter(list, ticket => ticket.priority === 1)
}, {
    id: uuid(),
    onlyOn: '/tickets',
    excludeRoles: ['User'],
    category: 'defaultOptions',
    displayedName: 'tickets:priority_high',
    icon: 'urgentPriority',
    filterFunction: list => filter(list, ticket => ticket.priority === 2)
}, {
    id: uuid(),
    onlyOn: '/users',
    category: 'defaultOptions',
    displayedName: 'search:user',
    icon: 'user_role',
    filterFunction: list => filter(list, user => user.workspace_role.workspace_role_name === 'User')
}, {
    id: uuid(),
    onlyOn: '/users',
    category: 'defaultOptions',
    displayedName: 'search:contractor',
    icon: 'contractor_role',
    filterFunction: list => filter(list, user => user.workspace_role.workspace_role_name === 'Contractor')
}, {
    id: uuid(),
    onlyOn: '/users',
    category: 'defaultOptions',
    displayedName: 'search:admin',
    icon: 'admin_role',
    filterFunction: list => filter(list, user => user.workspace_role.workspace_role_name === 'Admin')
}, {
    id: uuid(),
    onlyOn: '/rounds',
    category: 'defaultOptions',
    displayedName: 'search:round_with_incomplete_last_instance',
    icon: 'ClipboardCross',
    filterFunction: list => filter(list, round => round.last_instance?.status === 'INCOMPLETE')
}];

const addDefaultOptions = (devices, deviceGroups, currentRoundView, metabaseUrl) => {
    const result = cloneDeep(options);
    if (window.location.pathname === '/dashboards' && isEmpty(metabaseUrl)) {
        result.push({
            id: uuid(),
            onlyOn: '/dashboards',
            category: 'defaultOptions',
            displayedName: 'graphs:one_hour',
            icon: 'dateFilter'
        }, {
            id: uuid(),
            onlyOn: '/dashboards',
            category: 'defaultOptions',
            displayedName: 'graphs:one_day',
            icon: 'dateFilter'
        }, {
            id: uuid(),
            onlyOn: '/dashboards',
            category: 'defaultOptions',
            displayedName: 'graphs:one_week',
            icon: 'dateFilter'
        }, {
            id: uuid(),
            onlyOn: '/dashboards',
            category: 'defaultOptions',
            displayedName: 'graphs:two_week',
            icon: 'dateFilter'
        }, {
            id: uuid(),
            onlyOn: '/dashboards',
            category: 'defaultOptions',
            displayedName: 'graphs:one_month',
            icon: 'dateFilter'
        }, {
            id: uuid(),
            onlyOn: '/dashboards',
            category: 'defaultOptions',
            displayedName: 'graphs:three_month',
            icon: 'dateFilter'
        }, {
            id: uuid(),
            onlyOn: '/dashboards',
            category: 'defaultOptions',
            displayedName: 'graphs:since_beginning',
            icon: 'dateFilter'
        });
    }
    if (devices && window.location.pathname !== '/rounds') {
        if (devices.some(device => isPassageDeviceType(device))) {
            const hasPassageDataToday = device => {
                const hasDataPoints = device.data?.length > 0 && device.data[0].data_points?.length > 0;
                if (!hasDataPoints) { return false; }
                const lwpPassageData = device.data.find(data => data.data_type === 'status')?.data_points[0]?.value === 'BUTTON_CLICK'
                    ? device.data.find(data => data.data_type === 'status')
                    : null;
                const dataTimeStamp = device.custom_field.type === 'LWP'
                    ? lwpPassageData?.data_points[0]?.timestamp
                    : device.data[0].data_points[0].timestamp;
                const isBeforeToday = moment(dataTimeStamp).format('X')
                    < moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('X');
                return (
                    isPassageDeviceType(device) && (!dataTimeStamp || isBeforeToday)
                );
            };
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:passage_devices_last_validation',
                icon: 'time',
                filterFunction: list => filter(list, device => hasPassageDataToday(device))
            });
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:passage_devices',
                icon: 'rounds',
                filterFunction: list => filter(list, device => isPassageDeviceType(device))
            });
        }
        if (devices.find(device => device.custom_field.type === 'SMARTDESK'
            || device.custom_field.type === 'TES-5MRO'
            || device.custom_field.type === 'AIRWITS'
            || device.custom_field.type === 'AIRWITS_CO2'
            || device.custom_field.type === 'LUMINOSITY'
            || device.custom_field.type === 'SENSE_Dry_Contact_4_v1'
            || device.custom_field.type === 'TEMPERATURE_CONNIT'
            || device.custom_field.type === 'INFRARED'
            || device.custom_field.type.includes('TORK_')
        )) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:anomaly_devices',
                icon: 'caution',
                filterFunction: list => filter(list, device => isDeviceInAnomaly(device))
            });
        }
        if (devices.find(device => device.custom_field.type === 'BUTTON')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:BUTTON',
                icon: 'BUTTON',
                filterFunction: list => filter(list, device => device.custom_field.type === 'BUTTON')
            });
        }
        if (devices.find(device => device.custom_field.type === 'TEMPERATURE_CONNIT')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:TEMPERATURE_CONNIT',
                icon: 'TEMPERATURE_CONNIT',
                filterFunction: list => filter(list, device => device.custom_field.type === 'TEMPERATURE_CONNIT')
            });
        }
        if (devices.find(device => device.custom_field.type === 'AIRWITS_CO2' || device.custom_field.type === 'AIRWITS')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:AIRWITS_CO2',
                icon: 'AIRWITS_CO2',
                filterFunction: list => filter(list, device => device.custom_field.type === 'AIRWITS_CO2' || device.custom_field.type === 'AIRWITS')
            });
        }
        if (devices.find(device => device.custom_field.type === 'LUMINOSITY')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:LUMINOSITY',
                icon: 'LUMINOSITY',
                filterFunction: list => filter(list, device => device.custom_field.type === 'LUMINOSITY')
            });
        }
        if (devices.find(device => device.custom_field.type === 'SENSE_Dry_Contact_4_v1')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:SENSE_Dry_Contact_4_v1',
                icon: 'SENSE_Dry_Contact_4_v1',
                filterFunction: list => filter(list, device => device.custom_field.type === 'SENSE_Dry_Contact_4_v1')
            });
        }
        if (devices.find(device => device?.custom_field?.type === 'SIMPLE_LEAK')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:SIMPLE_LEAK',
                icon: 'SIMPLE_LEAK',
                filterFunction: list => filter(list, device => device?.custom_field?.type === 'SIMPLE_LEAK')
            });
        }
        if (devices.find(device => device.custom_field.type === 'SMARTDESK')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:SMARTDESK',
                icon: 'SMARTDESK',
                filterFunction: list => filter(list, device => device.custom_field.type === 'SMARTDESK')
            });
        }
        if (devices.find(device => device.custom_field.type === 'LWP')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:LWP',
                icon: 'LWP',
                filterFunction: list => filter(list, device => device.custom_field.type === 'LWP')
            });
        }
        if (devices.find(device => device.custom_field.type === 'TORK_BIN')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:TORK_BIN',
                icon: 'TORK_BIN',
                filterFunction: list => filter(list, device => device.custom_field.type === 'TORK_BIN')
            });
        }
        if (devices.find(device => device.custom_field.type === 'TORK_TOWEL')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:TORK_TOWEL',
                icon: 'TORK_TOWEL',
                filterFunction: list => filter(list, device => device.custom_field.type === 'TORK_TOWEL')
            });
        }
        if (devices.find(device => device.custom_field.type === 'TORK_DISPENSER')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:TORK_DISPENSER',
                icon: 'TORK_DISPENSER',
                filterFunction: list => filter(list, device => device.custom_field.type === 'TORK_DISPENSER')
            });
        }
        if (devices.find(device => device.custom_field.type === 'TES-5MRO')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:TES-5MRO',
                icon: 'TES-5MRO',
                filterFunction: list => filter(list, device => device.custom_field.type === 'TES-5MRO')
            });
        }
        if (devices.find(device => device.custom_field.type === 'LED')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:LED',
                icon: 'LED',
                filterFunction: list => filter(list, device => device.custom_field.type === 'LED')
            });
        }
        if (devices.find(device => device.custom_field.type === 'INFRARED')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:INFRARED',
                icon: 'INFRARED',
                filterFunction: list => filter(list, device => device.custom_field.type === 'INFRARED')
            });
        }
        if (devices.find(device => device.custom_field.type === 'BUTTON_RESET')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:BUTTON_RESET',
                icon: 'BUTTON_RESET',
                filterFunction: list => filter(list, device => device.custom_field.type === 'BUTTON_RESET')
            });
        }
        if (devices.find(device => device.custom_field.type === 'TAQT')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:TAQT',
                icon: 'TAQT',
                filterFunction: list => filter(list, device => device.custom_field.type === 'TAQT')
            });
        }
        if (devices.find(device => device.custom_field.type === 'PASSAGE_BUTTON')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:PASSAGE_BUTTON',
                icon: 'PASSAGE_BUTTON',
                filterFunction: list => filter(list, device => device.custom_field.type === 'PASSAGE_BUTTON')
            });
        }
        if (devices.find(device => device.custom_field.type === 'QRCODE')) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:QRCODE',
                icon: 'QRCODE',
                filterFunction: list => filter(list, device => device.custom_field.type === 'QRCODE')
            });
        }
        if (devices.find(device => device.custom_field.type === 'BUTTON_SATISFACTION')
            || deviceGroups?.find(group => group.devices.find(device => device.custom_field.type === 'BUTTON_SATISFACTION'))
        ) {
            result.push({
                id: uuid(),
                onlyOn: '/devices',
                category: 'defaultOptions',
                displayedName: 'search:BUTTON_SATISFACTION',
                icon: 'BUTTON_SATISFACTION',
                filterFunction: list => filter(list, obj => obj.custom_field.type === 'BUTTON_SATISFACTION'
                    || obj.devices?.some(device => device.custom_field.type === 'BUTTON_SATISFACTION'))
            });
        }

        if (deviceGroups) {
            if (deviceGroups.find(group => group.devices.find(device => device.custom_field.type === 'BUTTON_POLL'))) {
                result.push({
                    id: uuid(),
                    onlyOn: '/devices',
                    category: 'defaultOptions',
                    displayedName: 'search:BUTTON_POLL',
                    icon: 'BUTTON_POLL',
                    filterFunction: list => filter(list, obj => obj.custom_field.type === 'BUTTON_POLL'
                        || obj.devices?.some(device => device.custom_field.type === 'BUTTON_POLL'))
                });
            }
            if (deviceGroups.find(group => group.devices.find(device => device.custom_field.type === 'BUTTON_RATING'))) {
                result.push({
                    id: uuid(),
                    onlyOn: '/devices',
                    category: 'defaultOptions',
                    displayedName: 'search:BUTTON_RATING',
                    icon: 'BUTTON_RATING',
                    filterFunction: list => filter(list, obj => obj.custom_field.type === 'BUTTON_RATING'
                        || obj.devices?.some(device => device.custom_field.type === 'BUTTON_RATING'))
                });
            }
            if (deviceGroups.find(group => group.devices.find(device => device.custom_field.type === 'SMARTDESK')
                && group.devices.find(device => device.custom_field.type === 'LED')
                && group.devices.find(device => device.custom_field.type === 'BUTTON_RESET'))) {
                result.push({
                    id: uuid(),
                    onlyOn: '/devices',
                    category: 'defaultOptions',
                    displayedName: 'search:HOT_DESKING',
                    icon: 'HOT_DESKING',
                    filterFunction: list => filter(list, obj => obj.devices?.find(device => device.custom_field.type === 'SMARTDESK')
                    && obj.devices?.find(device => device.custom_field.type === 'LED')
                    && obj.devices?.find(device => device.custom_field.type === 'BUTTON_RESET'))
                });
                result.push({
                    id: uuid(),
                    onlyOn: '/devices',
                    category: 'defaultOptions',
                    displayedName: 'search:hot_desking_to_clean',
                    icon: 'to_clean',
                    filterFunction: list => filter(list, obj => obj.devices?.find(device => device.custom_field.type === 'SMARTDESK')
                        && obj.devices?.find(device => device.custom_field.type === 'LED')
                        && obj.devices?.find(device => device.custom_field.type === 'BUTTON_RESET')
                        && !isEmpty(obj.data) && !isEmpty(obj.data[0].data_points) && obj.data[0].data_points[0].value === 'TO CLEAN')
                });
            } else if (deviceGroups.find(group => group.devices.find(device => device.custom_field.type === 'SMARTDESK')
                && group.devices.find(device => device.custom_field.type === 'LED'))) {
                result.push({
                    id: uuid(),
                    onlyOn: '/devices',
                    category: 'defaultOptions',
                    displayedName: 'search:PRESENCE_LED_DEVICE_GROUP',
                    icon: 'PRESENCE_LED_DEVICE_GROUP',
                    filterFunction: list => filter(list, obj => obj.devices?.find(device => device.custom_field.type === 'SMARTDESK')
                    && obj.devices?.find(device => device.custom_field.type === 'LED'))
                });
            }
        }
    } else if (window.location.pathname === '/rounds' && currentRoundView === VIEW_CALENDAR) {
        result.push({
            id: uuid(),
            onlyOn: '/rounds',
            category: 'defaultOptions',
            displayedName: 'search:round_completed_more_than_80_percent',
            icon: 'PercentIcon',
            customIconStyle: 'high-validation',
            filterFunction: list => filter(list, instance => instance.expected_steps_count
                && instance.validated_steps_count
                && Math.round(instance.validated_steps_count / instance.expected_steps_count * 100) > 80
            )
        }, {
            id: uuid(),
            onlyOn: '/rounds',
            category: 'defaultOptions',
            displayedName: 'search:round_completed_more_than_50_percent',
            icon: 'PercentIcon',
            customIconStyle: 'medium-validation',
            filterFunction: list => filter(list, instance => instance.expected_steps_count
                && instance.validated_steps_count
                && Math.round(instance.validated_steps_count / instance.expected_steps_count * 100) <= 80
                && Math.round(instance.validated_steps_count / instance.expected_steps_count * 100) >= 50
            )
        }, {
            id: uuid(),
            onlyOn: '/rounds',
            category: 'defaultOptions',
            displayedName: 'search:round_completed_less_than_50_percent',
            icon: 'PercentIcon',
            customIconStyle: 'small-validation',
            filterFunction: list => filter(list, instance => instance.expected_steps_count
                && (instance.validated_steps_count || instance.validated_steps_count === 0)
                && Math.round(instance.validated_steps_count / instance.expected_steps_count * 100) < 50
            )
        });
    }

    return result;
};

const QueryResults = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [minimumQuerySize] = useState(1);
    const navbarFilters = useSelector(state => state.navbar.filters);
    const users = useSelector(state => state.users.list);
    const messagingUsers = useSelector(state => state.messaging.list);
    const tickets = useSelector(state => state.tickets.list);
    const tags = useSelector(state => state.tickets.tags);
    const dashboards = useSelector(state => state.dashboards.list);
    const metabaseUrl = useSelector(state => state.dashboards.metabaseUrl);
    const groups = useSelector(state => state.groups.list);
    const devices = useSelector(state => state.devices.list);
    const rounds = useSelector(state => state.rounds.list);
    const calendarInstances = useSelector(state => state.rounds.roundShownInDetailsPopup.instances);
    const deviceGroups = useSelector(state => state.groups.device_groups);
    const currentUser = useSelector(state => state.users.currentUser);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const filterSkills = currentUser.workspace_role.workspace_role_name === 'Contractor'
        || currentUser.workspace_role.workspace_role_name === 'Admin' && workspaceSettings.filter_admin_on_skills
        || currentUser.workspace_role.workspace_role_name === 'User' && workspaceSettings.filter_user_on_skills
        || currentUser.workspace_role.workspace_role_name === 'User' && workspaceSettings.supervision_skills_to_users;
    const [allResults, setAllResults] = useState(() => setOptions(currentUser, {
        users, devices, dashboards, groups, tickets, tags, rounds, deviceGroups, messagingUsers
    }, t, groups, filterSkills, workspaceSettings));
    const { query } = useRouter();
    const currentRoundView = getCurrentRoundView(query);
    const [defaultOptions, setDefaultOptions] = useState(addDefaultOptions(devices, deviceGroups, currentRoundView, metabaseUrl));
    const [matchingResults, setMatchingResults] = useState([]);
    const [filtersToOptions, setFiltersToOptions] = useState([]);
    const footBar = useFootBar();

    const addGroupsRelated = queryResults => {
        const updatedQueryResults = queryResults;
        return updatedQueryResults;
    };

    // Filters the list comparing the query and the names
    const filterResults = () => {
        const queryResults = [];
        setMatchingResults([]);
        if (props.query.length >= minimumQuerySize) {
            let normalizeQueryName = '';
            let normalizeName = '';
            const normalizeQuery = props.query?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.replace(/\s/g, '')?.toLowerCase();
            let normalizeTraducedName = '';
            let normalizeNfcTag = '';

            if (includes(filterByRoute[window.location.pathname], 'tickets')) {
                let flatIsTicket = false;
                tickets.filter(category => category.isVisible).forEach(category => {
                    if (!flatIsTicket) {
                        flatIsTicket = category.tickets.some(ticket => ticket.title
                            ?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')
                            ?.replace(/\s/g, '')?.toLowerCase().includes(normalizeQuery)
                        );
                    }
                    if (category.tickets.find(ticket => ticket.key.toString() === normalizeQuery)) {
                        queryResults.push({
                            queryName: normalizeQuery,
                            category: 'key',
                            name: t('search:ticket_key', { key: props.query }),
                            id: normalizeQuery
                        });
                    }
                });
                if (flatIsTicket) {
                    queryResults.push({
                        queryName: normalizeQuery,
                        category: 'tickets',
                        name: t('search:ticket_title', { title: props.query }),
                        id: props.query
                    });
                }
            }
            if (includes(filterByRoute[window.location.pathname], 'rounds')) {
                let flatIsRound = false;
                if (currentRoundView === VIEW_CALENDAR) {
                    // We need to filter by calendarInstances and not rounds in case a user doesn't have a round but
                    // has one of its instances (if the round's buildings were modified and he doesn't have the rights anymore)
                    calendarInstances?.forEach(round => {
                        if (!flatIsRound) {
                            flatIsRound = round.round_name
                                ?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')
                                ?.replace(/\s/g, '')?.toLowerCase().includes(normalizeQuery);
                        }
                    });
                } else {
                    rounds?.forEach(round => {
                        if (!flatIsRound) {
                            flatIsRound = round.name
                                ?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')
                                ?.replace(/\s/g, '')?.toLowerCase().includes(normalizeQuery);
                        }
                    });
                }
                if (flatIsRound) {
                    queryResults.push({
                        queryName: normalizeQuery,
                        category: 'rounds',
                        name: t('search:round_name', { name: props.query }),
                        id: props.query
                    });
                }
            }
            allResults.forEach(result => {
                normalizeQueryName = result.queryName?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.replace(/\s/g, '')?.toLowerCase();
                normalizeName = result.name?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.replace(/\s/g, '')?.toLowerCase();
                normalizeTraducedName = getTraductionOfGroup(props.query, t)?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.replace(/\s/g, '')?.toLowerCase();
                normalizeNfcTag = result.nfc_tag?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.replace(/\s/g, '')?.toLowerCase();

                if (includes(normalizeQueryName || normalizeName, normalizeQuery)
                    || includes(normalizeQueryName || normalizeName, normalizeTraducedName)
                    || includes(normalizeNfcTag, normalizeQuery)
                ) {
                    queryResults.push(result);
                }
            });
            queryResults.forEach((queryResult, idx) => {
                // If the queryresult is a group but one of the device of this group is visible in the devices table, we cannot filter by this group
                if (window.location.pathname === '/devices' && queryResult.category === 'group' && queryResult.groupType === 'DEVICE') {
                    const resultToHideIdx = devices.findIndex(
                        device => device.custom_field?.device_group_ids?.includes(queryResult.id)
                    );
                    if (resultToHideIdx !== -1) { queryResults.splice(idx, 1); }
                }
            });
            setMatchingResults(uniqBy(concat(filtersToOptions, addGroupsRelated(queryResults)), 'id'));
        }
    };

    useEffect(() => {
        if (props.inputFocus && !isEmpty(filter(navbarFilters, f => f.category === 'defaultOptions'))) {
            setMatchingResults(filtersToOptions.slice(0));
        }
        if (!isEmpty(props.query)) {
            filterResults();
        }
    }, [props.inputFocus]);

    useEffect(() => {
        const matchingFilters = [];
        filter(navbarFilters, f => f.type !== 'defaultOptions' && f.type !== 'tickets' && f.type !== 'rounds').forEach(currentFilter => {
            matchingFilters.push(find(allResults, result => result.id === currentFilter.id));
        });
        setFiltersToOptions(matchingFilters);
        filterResults();
    }, [navbarFilters]);

    useEffect(() => {
        filterResults();
    }, [props.query]);

    useEffect(() => {
        setDefaultOptions(addDefaultOptions(devices, deviceGroups, currentRoundView, metabaseUrl));
    }, [devices, metabaseUrl]);

    // Reload results if there is a new device, user, dashboard, group
    useEffect(() => {
        const setResult = async () => {
            await setAllResults(setOptions(currentUser, {
                users, devices, dashboards, groups, rounds, tickets, tags, deviceGroups, messagingUsers
            }, t, groups, filterSkills, workspaceSettings));
            filterResults();
        };
        setResult();
    }, [devices, users, dashboards, groups, rounds, calendarInstances, tickets, tags, messagingUsers, window.location.pathname]);

    const toggleFilter = newFilter => {
        dispatch(resetFilters());
        footBar.update({ selected: [], list: [] });
        props.clearText();
        dispatch(addFilter({
            id: newFilter.id,
            type: newFilter.category,
            name: newFilter.displayedName,
            filterFunction: newFilter.filterFunction,
            id_bis: newFilter.id_bis
        }));
    };

    // eslint-disable-next-line consistent-return
    const getRoundsFilterFunction = filterName => {
        // eslint-disable-next-line default-case
        switch (filterName) {
            case 'search:round_with_incomplete_last_instance':
                return currentRoundView === VIEW_LIST
                    ? list => filter(list, round => round.last_instance?.status === 'INCOMPLETE')
                    : list => filter(list, round => round.status === 'INCOMPLETE');
        }
    };

    // everytime we switch from the list view to the calendar view (or the contrary), we need to give the right filter function to the current filter
    useEffect(() => {
        // get the view default options
        setDefaultOptions(addDefaultOptions(devices, deviceGroups, currentRoundView, metabaseUrl));
        if (includes(filterByRoute[window.location.pathname], 'rounds') && window.location.pathname === '/rounds') {
            const incompleteLastInstanceFilterName = 'search:round_with_incomplete_last_instance';
            const currentRoundFilterName = navbarFilters.find(currentFilter => currentFilter.name === incompleteLastInstanceFilterName)?.name;
            const newFilter = options.find(option => option.displayedName === currentRoundFilterName);
            if (newFilter) {
                newFilter.filterFunction = getRoundsFilterFunction(incompleteLastInstanceFilterName);
                toggleFilter(newFilter);
            }
            const hiddenRoundsFilterForListView = ['search:round_completed_more_than_80_percent', 'search:round_completed_more_than_50_percent', 'search:round_completed_less_than_50_percent']
            // when we go to the list view, if there was a filter selected that isn't allowed on the list view we need to reset the filters
            if (currentRoundView === VIEW_LIST && navbarFilters.length !== 0
                && navbarFilters.map(roundFilter => roundFilter.name)
                    .some(roundFilter => hiddenRoundsFilterForListView.includes(roundFilter))
            ) {
                dispatch(resetFilters());
            }
        }
    }, [currentRoundView, window.location.pathname]);

    const rowRenderer = ({ index, style }) => {
        const result = matchingResults[index];
        if (result) {
            const selected = find(navbarFilters, res => res.id === result.id);
            const iconType = result.category === 'link'
                ? 'group'
                : result.groupType
                    ? result.groupType === 'SKILL'
                        ? result.name
                        : result.groupType
                    : result.category;
            const displayName = !selected && typeof result.displayedName === 'string' && result.category === 'group'
                ? <p>{ getTraductionOfGroup(result.displayedName, t) }</p>
                : result.name && result.category === 'group'
                    ? <p>{getTraductionOfGroup(result.name, t)}</p>
                    : <p>{result.name}</p>;
            return (
                <Button
                    key={result.id}
                    style={style}
                    className={classNames('result-container', selected ? 'selected-result' : '')}
                    onClick={() => props.toggleFilter(result)}
                >
                    <Component componentName={'Icon'} type={iconType} />
                    { displayName }
                    { selected && (
                        <div className={'check-icon'}>
                            <span>{t('search:selected_resource')}</span>
                            <Component componentName={'Icon'} type={'check'} />
                        </div>
                    )}
                    { selected && (
                        <div className={'delete-icon'}>
                            <span>{t('search:unselect_resource')}</span>
                            <Component componentName={'Icon'} type={'close'} />
                        </div>
                    ) }
                </Button>
            );
        }
    };

    const defaultRowRenderer = ({ index, style }) => {
        const result = defaultOptionOnRoute[index];
        const selected = find(navbarFilters, res => res.id === result.id);
        const displayedName = result.category === 'assigners' ? result.displayedName : t(result.displayedName);
        const incompleteLastInstanceFilterName = 'search:round_with_incomplete_last_instance';
        // when we add a filter, for the round view we must put the right filter function (it differs if it's one the calendar or list view)
        if (includes(filterByRoute[window.location.pathname], 'rounds') && window.location.pathname === '/rounds' && result.displayedName === incompleteLastInstanceFilterName) {
            result.filterFunction = getRoundsFilterFunction(incompleteLastInstanceFilterName);
        }
        return window.location.pathname === result.onlyOn
            && !includes(result.excludeRoles, currentUser.workspace_role.workspace_role_name) && (
            <Button
                key={result.displayedName}
                style={style}
                className={`result-container default-options ${selected ? 'selected' : ''} ${result.customIconStyle ? result.customIconStyle : ''}`}
                onClick={() => toggleFilter(result)}
            >
                <Component componentName={'Icon'} type={result.icon} />
                { displayedName }
            </Button>
        );
    };

    const rowCount = matchingResults.length;

    const defaultOptionOnRoute = filter(defaultOptions, opt => opt.onlyOn === window.location.pathname
        && !includes(opt.excludeRoles, currentUser.workspace_role.workspace_role_name)
    );
    const defaultRowCount = defaultOptionOnRoute.length;
    return !isEmpty(matchingResults) && !isEmpty(props.query) ? (
        <div
            className={classNames(
                'query-results-container',
                { 'query-results-container-maximized': props.displayResultContainer },
                { 'footbar-is-top': FOOTBAR_POSITION === 'top' }
            )}
            style={props.displayResultContainer && matchingResults.length > 0
                ? { padding: '10px 0 0 0', width: props.width, left: props.left }
                : {}}
        >
            <div className="query-results-wrapper">
                <div style={{ flex: '1 1 auto', maxHeight: '600px', height: `${rowCount * 40}px` }}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <List
                                height={height}
                                width={width}
                                rowCount={rowCount}
                                rowHeight={40}
                                rowRenderer={rowRenderer}
                            />
                        )}
                    </AutoSizer>
                </div>
            </div>
        </div>
    ) : (
        <>
            {props.inputFocus && defaultRowCount > 0 && (
                <div
                    className={classNames('query-results-container query-results-container-maximized', { 'footbar-is-top': FOOTBAR_POSITION === 'top' })}
                    style={{ width: props.width, left: props.left }}
                >
                    <div className="query-results-wrapper">
                        <div style={{ flex: '1 1 auto', height: `${defaultRowCount * 40}px` }}>
                            <AutoSizer>
                                {({ width, height }) => (
                                    <List
                                        height={height}
                                        width={width}
                                        rowCount={defaultRowCount}
                                        rowHeight={40}
                                        rowRenderer={defaultRowRenderer}
                                    />
                                )}
                            </AutoSizer>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default withRouter(QueryResults);

// Lib
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Context
import Switch from '@material-ui/core/Switch';
import { useComponentsPool } from '../../../../ComponentsPool';

const RoundsTicketsConfiguration = props => {
    const { t } = useTranslation();
    const { Component } = useComponentsPool();
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const initialConfig = {
        create_rounds_tickets: !!workspaceSettings.create_rounds_tickets
    };
    const [modifiedConfig, setModifiedConfig] = useState(initialConfig);

    useEffect(() => {
        if (props.cancelModifs) {
            setModifiedConfig(initialConfig);
            props.setCancelModfis(false);
        }
    }, [props.cancelModifs]);

    useEffect(() => {
        const configToModify = [];
        Object.entries(modifiedConfig).forEach(config => {
            const initialConfigValue = initialConfig[config[0]];
            if (config[1] !== initialConfigValue) {
                configToModify.push({
                    configName: config[0],
                    configValue: config[1]
                });
            }
        });
        props.modifyCurrentConfig(configToModify);
    }, [modifiedConfig]);

    return (
        <>
            <div className={'configuration-page-top-content'}>
                <Component componentName={'Icon'} type={'mailTicket'} />
                <div className={'configuration-page-title'}><h3>{t('workspace:configure_rounds_tickets')}</h3></div>
            </div>
            <div className={'configuration-page-text'}>
                <div className={'configuration-page-section-wrapper'}>
                    <div className={'configuration-page-section-title'}>{t('workspace:incomplete_round')}</div>
                    <div className={'configuration-page-explanation-wrapper'}>
                        <div className={'configuration-page-explanation-content'}>
                            <div className={'configuration-page-explanation-text'}>
                                <p>{t('workspace:rounds_tickets_explanation')}</p>
                            </div>
                            <div className={'configuration-page-explanation-fields'}>
                                <span>{t('common:deactivate')}</span>
                                <Switch
                                    checked={modifiedConfig.create_rounds_tickets}
                                    onChange={e => setModifiedConfig({ ...modifiedConfig, create_rounds_tickets: e.target.checked })}
                                />
                                <span className={modifiedConfig.create_rounds_tickets && 'selected-option-text'}>{t('common:activate')}</span>
                            </div>
                        </div>
                        <div className={'configuration-page-explanation'}>
                            <span role={'img'} aria-label={'clock-emoji'}>🕑</span>
                            <span className={'summary-alerts'}>
                                {` ${t('workspace:incomplete_round_explanation')}`}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RoundsTicketsConfiguration;

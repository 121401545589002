import axios from 'axios';
import moment from 'moment';
import { map } from 'lodash';
import { findTicket } from './helper';
import { EVENT_DELETE_TICKET, EVENT_MOVE_TICKET, EVENT_MOVE_TICKETS, segmentTrack } from '../layout/helper';

export const getTickets = (active = true, isSupervisorMode = false, supervisorMode = 'my_tickets') => {
    if (isSupervisorMode) {
        return {
            type: 'GET_TICKETS',
            payload: axios.get(`/tickets/?active=${active}&supervisor_tickets=${supervisorMode}`)
                .then(res => ({active, data: res.data}))
                .catch(error => {
                    console.error('GET_TICKETS', error); // eslint-disable-line
                    return Promise.reject(error);
                })
        };
    }
    return {
        type: 'GET_TICKETS',
        payload: axios.get(`/tickets/?active=${active}`)
            .then(res => ({active, data: res.data}))
            .catch(error => {
                console.error('GET_TICKETS', error); // eslint-disable-line
                return Promise.reject(error);
            })
    };
};

export const updateTicketOnState = (ticket, actionType) => ({
    type: `${actionType}_FROM_WEBSOCKET`,
    payload: ticket
});

export const setVisibleTickets = active_mode => ({
    type: 'SET_VISIBLE_TICKETS',
    payload: active_mode
});

export const getTicketByKey = (key, code) => ({
    type: 'GET_MINIMAL_TICKET',
    payload: axios.post('/tickets/search-by-key', { key, code })
        .then(res => res.data)
        .catch(error => {
            console.error('GET_TICKETS', error); // eslint-disable-line
            return Promise.reject(error);
        })
});

export const getTags = () => ({
    type: 'GET_TAGS',
    payload: axios.get('/tags/')
        .then(res => res.data)
        .catch(error => Promise.reject(error))
});

export const createTag = tagTitle => ({
    type: 'CREATE_TAG',
    payload: axios.post('/tags/', tagTitle)
        .then(res => res.data)
        .catch(error => Promise.reject(error))
});

export const addTicketEditableField = field => ({
    type: 'ADD_TICKET_EDITABLE_FIELD',
    payload: field
});

export const changeTicketPopupParts = data => ({
    type: 'CHANGE_TICKET_POPUP_PARTS',
    payload: data
});

export const deleteTickets = data => {
    segmentTrack(EVENT_DELETE_TICKET, { ticketsIds: data.ticket_ids });
    return {
        type: data.ticket_ids.length > 1 ? 'DELETE_TICKETS' : 'DELETE_TICKET',
        payload: axios.delete('/tickets/', { data })
            .then(res => (res.data.nb_tickets_deleted === data.ticket_ids.length ? data.ticket_ids : ''))
            .catch(error => {
                console.log('DELETE_TICKET', error); // eslint-disable-line
                return Promise.reject(error);
            })
    };
};

export const setTicketShownInDetailPopup = (ticket, groupList) => ({
    type: 'SET_TICKET_DETAIL',
    payload: {
        ticket,
        groupList
    }
});


export const moveTicket = (data, tickets) => {
    const resolutionTime = moment.duration(moment().diff(moment(findTicket(tickets, data.draggedTicketId)?.created_at)));
    const segmentData = {
        ticketId: data.draggedTicketId,
        ticketOldColumn: data.source.droppableId,
        ticketCurrentColumn: data.destination.droppableId
    };
    if (data.destination.droppableId === 'COMPLETED') segmentData.ticketResolutionTimeInHours = resolutionTime.asHours();
    segmentTrack(EVENT_MOVE_TICKET, segmentData);
    return {
        type: 'MOVE_TICKET',
        payload: data
    };
};

export const moveTickets = (data, tickets) => {
    const { source, destination, checkedTicketIDs } = data;

    const segmentData = {
        ticketsOldColumn: source.droppableId,
        ticketsCurrentColumn: destination.droppableId
    };
    if (destination.droppableId === 'COMPLETED') {
        segmentData.tickets = map(checkedTicketIDs, (ticketId) => {
            const duration = moment.duration(
                moment().diff(
                    moment(findTicket(tickets, ticketId)?.created_at)
                )
            );
            return {
                id: ticketId,
                resolutionTimeInHours: duration.asHours()
            };
        });
    } else {
        segmentData.ticketIDs = checkedTicketIDs;
    }
    segmentTrack(EVENT_MOVE_TICKETS, segmentData);
    return {
        type: 'MOVE_TICKETS',
        payload: data
    };
};

export const updateTicket = (ticketId, data, anonymousConnexion = false) => {
    const headers = anonymousConnexion ? {
        'x-master-api-key': QRCODE_ISSUE_REPORT_API_KEY,
        username: USERNAME_ISSUE_REPORT,
        password: PASSWORD_ISSUE_REPORT
    } : {};
    return {
        type: 'UPDATE_TICKET',
        payload: axios.patch(`/tickets/${ticketId}/`, data, { headers })
            .then(res => res.data)
            .catch(error => {
                console.error('UPDATE_TICKET', error); // eslint-disable-line
                return Promise.reject(error);
            })
    };
};

export const updateTickets = (checkedTickets, newStatus, columns) => {
    // Create a weight for each ticket to reorder them in the backend
    const ticketOrder = {};
    let weight = 0;
    columns?.forEach(column => {
        column.tickets?.forEach(ticket => {
            ticketOrder[ticket?.id] = weight;
            weight = weight + 1;
        });
    });

    const requestBody = {
        status: newStatus,
        ticket_ids: checkedTickets.map(t => t?.id),
        tickets_order: ticketOrder
    };

    return {
        type: 'UPDATE_TICKETS',
        payload: axios
            .put(`/tickets/`, requestBody)
            .then((res) => res.data)
            .catch((error) => {
                console.error('UPDATE_TICKETS =>', error.response); // eslint-disable-line
                return Promise.reject(error);
            })
    };
};

export const createTicket = (data, anonymousConnexion = false, createdBydeclarativeQRCode = false) => {
    const headers = anonymousConnexion
        ? {
            'x-master-api-key': QRCODE_ISSUE_REPORT_API_KEY,
            username: USERNAME_ISSUE_REPORT,
            password: PASSWORD_ISSUE_REPORT
        }
        : {};
    return {
        type: !createdBydeclarativeQRCode ? 'CREATE_TICKET' : 'CREATE_TICKET_VIA_DECLARATIVE_QRCODE',
        payload: axios.post('/tickets/', { ...data, status: 'NEW' }, { headers })
            .then(res => res.data)
            .catch(error => {
                console.error('CREATE_TICKET', error); // eslint-disable-line
                return Promise.reject(error);
            })
    };
};

export const saveTicketOrder = columns => {
    const ticketOrder = {};
    let weight = 0;
    columns?.forEach(column => {
        column.tickets?.forEach(ticket => {
            ticketOrder[ticket?.id] = weight;
            weight = weight + 1;
        });
    });
    return {
        type: 'SAVE_TICKET_ORDER',
        payload: axios.post('/tickets/update-order', { tickets_order: ticketOrder })
            .then(res => res.data)
            .catch(error => {
                console.error('SAVE_TICKET_ORDER', error); // eslint-disable-line
                return Promise.reject(error);
            })
    };
};

export const getTicketSummary = (id, page) => ({
    type: 'GET_TICKET_SUMMARY',
    payload: axios.get(`/tickets/summary/${id}/page/${page}`)
        .then(res => ({ id, data: res.data }))
        .catch(error => Promise.reject(error))
});
export const resetSelectedTicket = () => ({ type: 'RESET_SELECTED_TICKET' });

export const editTicket = (id, data) => ({
    type: 'EDIT_TICKET',
    payload: axios.patch(`/tickets/${id}/`, data)
        .then(res => res.data)
        .catch(error => {
            if (error.response.status === 403) throw new Error(error);
            console.error("EDIT_TICKET_ERROR", error); // eslint-disable-line
            return Promise.reject(error);
        })
});

export const getTicketComments = id => ({
    type: 'GET_TICKET_COMMENTS',
    payload: axios.get(`/tickets/comments/${id}}`)
        .then(res => ({ id, data: res.data }))
        .catch(error => Promise.reject(error))
});

export const postTicketComments = (ticket, content, author, custom_field, urls, anonymousConnexion = false) => {
    const headers = anonymousConnexion
        ? {
            'x-master-api-key': QRCODE_ISSUE_REPORT_API_KEY,
            username: USERNAME_ISSUE_REPORT,
            password: PASSWORD_ISSUE_REPORT
        }
        : {};
    return {
        type: 'POST_TICKET_COMMENTS',
        payload: axios.post('/tickets/comments/', {
            content,
            ticket,
            author,
            custom_field,
            urls
        }, { headers })
            .then(res => ({ ticket, data: res.data }))
    };
};

export const deleteComment = id => ({
    type: 'DELETE_COMMENT',
    payload: axios.delete(`/tickets/comments/${id}/`)
        .then(() => id)
        .catch(error => Promise.reject(error))
});

export const sendCommentDocument = (picture, anonymousConnexion = false) => {
    const formData = new FormData();
    formData.append('profilePicture', picture, picture.name);
    const headers = anonymousConnexion
        ? {
            'x-master-api-key': QRCODE_ISSUE_REPORT_API_KEY,
            username: USERNAME_ISSUE_REPORT,
            password: PASSWORD_ISSUE_REPORT
        }
        : {};
    return {
        type: 'UPLOAD_COMMENT_DOCUMENT',
        payload: axios
            .post('/tickets/upload-comment-document', formData, { headers })
            .then(res => res.data)
            .catch(error => {
                console.error('UPLOAD_COMMENT_DOCUMENT_ERROR', error); // eslint-disable-line
                return Promise.reject(error);
            })
    };
};

export const changeSelectedStatusMobile = status => ({
    type: 'CHANGE_SELECTED_STATUS_MOBILE',
    payload: status
});

export const shareTicketComment = (comment_id, share_type) => ({
    type: 'SHARE_TICKET_COMMENT',
    payload: axios.post('/tickets/share-comment', {
        comment_id: comment_id,
        share_type: share_type
    })
        .then(res => res.data)
        .catch(error => Promise.reject(error))
});

export const editTicketComments = (id, data) => ({
    type: 'EDIT_TICKET_COMMENTS',
    payload: axios.patch(`/tickets/comments/${id}/`, data)
        .then(res => res.data)
        .catch(error => {
            if (error.response.status === 403) throw new Error(error);
            console.error("EDIT_TICKET_COMMENTS_ERROR", error); // eslint-disable-line
            return Promise.reject(error);
        })
});

export const getTicketOperations = id => ({
    type: 'GET_TICKET_OPERATIONS',
    payload: axios.get(`/operations/${id}`)
        .then(res => ({ id, data: res.data }))
        .catch(error => Promise.reject(error))
});

export const setPlayingSoundOff = () => ({
    type: 'SET_PLAYING_SOUND_OFF'
});

export const getTicket = id => ({
    type: 'GET_TICKET',
    payload: axios.get(`/tickets/${id}`)
        .then(res => ({ id, data: res.data }))
        .catch(error => Promise.reject(error))
});

export const getTicketFromKey = key => ({
    type: 'GET_TICKET_FROM_KEY',
    payload: axios.get(`/tickets/?key=${key}`)
        .then(res => ({ data: res.data }))
        .catch(error => Promise.reject(error))
});

export const ticketsMoveDiffColErrorNotif = () => ({ type: 'TICKETS_MOVE_DIFF_COL_ERROR' });
export const networkConnectionErrorNotif = () => ({ type: 'NETWORK_CONNECTION_ERROR'});



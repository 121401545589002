import axios from 'axios';

export const getUnassignedNfc = () => ({
    type: 'GET_UNASSIGNED_NFC',
    payload: axios
        .get('/nfc/?unassigned_only=true')
        .then(res => res.data)
});

export const getNfcs = () => ({
    type: 'GET_NFC_LIST',
    payload: axios
        .get('/nfc/')
        .then(res => res.data)
});

// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import { getHardwareList } from '../actions';

const DeviceHardwareAssignment = props => {
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const hardware_list = useSelector(state => state.devices.hardware_list);
    const [hardwareList, setHardwareList] = useState([]);
    const [hardwareId, setHardwareId] = useState(null);

    useEffect(() => {
        dispatch(getHardwareList());
    }, []);

    useEffect(() => {
        if (props.value) setHardwareId(props.value ? props.value[0] : null);
    }, [props.value]);

    useEffect(() => {
        const hardware = hardware_list
            .filter(item => item.hardware_ids.length > 0)
            .map(item => ({ title: item.hardware_ids[0] }))
            .filter(v => v.title && v.title.length > 0)
            .filter((v, i, a) => a.indexOf(v) === i) // remove duplicate
            .sort((a, b) => {
                if (a.title > b.title) return 1;
                if (a.title < b.title) return -1;
                return 0;
            });
        setHardwareList(hardware);
    }, [hardware_list]);

    return (
        <Component
            componentName={'InputAutocomplete'}
            noPlaceholder={props.noPlaceholder}
            placeholder={props.placeHolder !== undefined ? props.placeHolder : 'devices:create_search_hardware_ids'}
            onChange={value => {
                setHardwareId(value ? value.title : null);
                props.onChange(value ? [value.title] : []);
            }}
            multiple={false}
            value={hardwareId ? { title: hardwareId } : null}
            className={'device-hardware-ids'}
            list={hardwareList}
        />
    );
};

export default DeviceHardwareAssignment;

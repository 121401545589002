// Libs
import React, {useState} from 'react';
// Component
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const TogglePrioritiesButton = props => {
    const possibleValue = [
        { title : "Aucun", value : "[]"},
        { title : "Floor", value : "[floor]"},
        { title : "Floor & Position", value : "[floor,position]"}
    ];

    const getRightValue = (value) => {
        if (value) {
            let chosenValue;
            if (value.includes("floor") && value.includes("position")) {
                chosenValue = possibleValue[2].value
            } else if (value.includes("floor") && !value.includes("position")) {
                chosenValue = possibleValue[1].value
            } else {
                chosenValue = possibleValue[0].value
            }
            return chosenValue;
        }
        return null;
    };

    const [settingValue, setSettingValue] = useState(getRightValue(props.value));

    const changePriority = (event, newRequiredFields) => {
        if (newRequiredFields !== null) {
            setSettingValue(newRequiredFields);
            props.setNewValue(newRequiredFields)
        }
    };

    return (
        <div className='select-wrapper'>
            <ToggleButtonGroup
                exclusive
                value={settingValue}
                onChange={changePriority}
                classes={{
                    root: 'toggle_button_group'
                }}
            >
                {
                    possibleValue.map(requiredField =>
                        <ToggleButton
                            classes={{
                                selected: 'toggle_button-selected selected_group',
                                label: 'toggle_button_label'
                            }}
                            value={requiredField.value}
                        >
                            {requiredField.title}
                        </ToggleButton>
                    )
                }
            </ToggleButtonGroup>
        </div>
    )
};

export default TogglePrioritiesButton;

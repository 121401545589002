// Lib
import React from 'react';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
// Actions

const QRCodesConfiguration = props => {
    const { Component } = useComponentsPool();
    const tabs = [
        {
            componentName: 'QRCodesDocuments'
        }
    ];

    return (
        <Component componentName={tabs[props.tabIdx].componentName} />
    );
};

export default QRCodesConfiguration;

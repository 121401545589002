// Libs
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useModal } from '../../../modal/ModalContext';

const TicketGroups = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const ticket = useSelector(state => state.tickets.ticketShownInDetailsPopup);

    const ticketGroups = [];
    switch (props.groupType) {
        case 'BATIMENT':
            ticketGroups.push(ticket?.custom_field?.batiment_group_id);
            break;
        case 'SKILL':
            ticketGroups.push(ticket?.custom_field?.skill_group_id);
            break;
        case 'FLOOR':
            ticketGroups.push(ticket?.custom_field?.floor_group_id);
            break;
        case 'POSITION':
            ticketGroups.push(ticket?.custom_field?.position_group_id);
            break;
        default:
            break;
    }

    return isEmpty(ticketGroups)
        ? <i className={'placeholder_text'}>{ t('tickets:not_in_group') }</i>
        : (
            <Component
                componentName={'GroupButton'}
                view={'ticket'}
                key={ticket.id}
                group_memberships={ticketGroups}
                resourceType={'Ticket'}
                maxVisible={3}
                index
            />
        );
};

export default TicketGroups;

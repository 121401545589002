// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';

// isMobile
import { isMobile } from 'react-device-detect';

// Components
import TicketView from './TicketView';


export default props => {
    const {
        index, data, style
    } = props;
    const ticket = data[index];
    const patchedStyle = {
        ...style,
        width: '98%',
        top: style.top + 8,
        left: style.left + 8
    };

    if (!ticket) return <div />;

    const currentUser = useSelector(state => state.users.currentUser);
    const disabledDrag = currentUser.workspace_role?.workspace_role_name === 'User' || isMobile;

    return (
        <Draggable
            key={ticket.id}
            draggableId={ticket.id}
            index={index}
            isDragDisabled={disabledDrag}
        >
            {provided => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                        ...patchedStyle,
                        ...provided.draggableProps.style
                    }}
                >
                    <TicketView
                        ticket={ticket}
                    />
                </div>
            )}
        </Draggable>
    );
};

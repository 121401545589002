import React, { useState } from 'react';

const FormContext = React.createContext({});
// Create a provider for components to consume and subscribe to changes
const FormProvider = props => {
    const [formState, setFormState] = useState({});

    return (
        <FormContext.Provider value={{ formState, setFormState }}>
            {props.children}
        </FormContext.Provider>
    );
};

const useFormState = () => {
    const context = React.useContext(FormContext);
    if (context === undefined) {
        throw new Error('useFormState must be used within a FormProvider');
    }
    return context;
};

export { FormProvider, useFormState };

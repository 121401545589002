import { isMobile } from 'react-device-detect';
import {preloadQRCodesCache, getDeviceByHardwareId, getLastReceivedDeviceData} from '../devices/actions';
import getWorkspaceByDeviceHardwareId from '../workspaces/actions';
import { getDailyRoundsInstancesByStatus } from '../rounds/actions';
import { notification } from '../notification/actions';

export function callEndpointsForOfflineModeOnMobile(dispatch, t, forcingStrategy = null) {
    if (isMobile) {
        dispatch(preloadQRCodesCache(forcingStrategy));
        dispatch(getDailyRoundsInstancesByStatus(true, null, false));

        if (forcingStrategy) {
            dispatch(notification(
                t('notifications:synchronize_qrcodes_inprogress'),
                'successful',
                'infoCircle'
            ));
        }
    }
}


export const sendMessageToServiceWorker = (wb, msg) => wb.messageSW(msg);

// Libs
import React from 'react';
import classNames from 'classnames';
import { useComponentsPool } from '../../../../ComponentsPool';
import { getDeviceGroupIcon } from '../../helper';

const DeviceName = props => {
    const { Component } = useComponentsPool();

    const type = props.resource.group_id ? 'GROUP' : 'DEVICE';
    const id = props.resource.device_id || props.resource.group_id;
    const icon = type === 'DEVICE'
        ? props.resource.custom_field.type || 'merciyanis_devices'
        : getDeviceGroupIcon(props.resource);
    const name = type === 'DEVICE'
        ? props.resource.device_name || props.resource.hardware_ids[0]
        : props.resource.group_name;
    const hardware_ids = type === 'DEVICE'
        ? props.resource.hardware_ids
        : '';

    return (
        <div
            className={classNames(['table-name'])}
            key={id}
        >
            <Component componentName={'Icon'} type={icon} />
            <div className="group">
                <div className={'title'}>{name}</div>
                <em>{hardware_ids}</em>
            </div>
        </div>
    );
};

export default DeviceName;

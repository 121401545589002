// Libs
import debounce from 'debounce';
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
    filter, cloneDeep, find, remove, some
} from 'lodash';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { List } from 'react-virtualized';
import Checkbox from '@material-ui/core/Checkbox';
import classNames from 'classnames';
import { useFootBar } from '../../footbar/FootBarContext';

// Components
import IotRocketDraggable from './Draggable';
import TicketView from './TicketView';

// Helper
import { filterTable } from '../../navbar/helper';
import { canSeeMobileBottomNavigation } from '../../users/helper';
import {customTicketFilterFunctions, customTicketFiltersByRole, selectedTicketsCount} from '../helper';

const filterGroup = (filters, list, currentUserRole) => {
    const newFilters = cloneDeep(filters);
    let newList = cloneDeep(list);

    newFilters.forEach(paramFilter => {
        if (paramFilter.filterFunction) {
            newList = paramFilter.filterFunction(list);
        }
    });

    return filterTable(
        filter(filters, f => f.type !== 'defaultOptions'),
        newList,
        [],
        [],
        customTicketFiltersByRole[currentUserRole],
        customTicketFilterFunctions,
        true
    );
};


export default props => {
    const [t] = useTranslation();
    const filters = useSelector(state => state.navbar.filters);
    const currentUser = useSelector(state => state.users.currentUser);
    const tickets = filterGroup(filters, props.category.tickets, currentUser.workspace_role.workspace_role_name);
    const refContainer = useRef(null);
    const footbar = useFootBar();
    const [ticketWidth, setTicketWidth] = useState(100);
    const [columnHeight, setColumnHeight] = useState(isMobile ? '80vh' : 500);
    const [selected, setSelected] = useState(false);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const history = useHistory();
    const devices = useSelector(state => state.devices.list);
    const selectedTickets = footbar.getList();

    const calcElementSize = () => {
        if (refContainer) {
            setTicketWidth(refContainer.current?.offsetWidth);
            setColumnHeight(refContainer.current?.offsetHeight);
        }
    };

    const toggleSelection = () => {
        const selectedTickets = cloneDeep(footbar.getList());
        if (selectedTickets
            .filter(obj => obj.status === props.category.name).length === tickets.length
            || footbar.getList().length === 0) {
            setSelected(!selected);
            tickets.forEach(ticket => (find(selectedTickets, selectedTicket => selectedTicket?.id === ticket?.id)
                ? remove(selectedTickets, selectedTicket => selectedTicket?.id === ticket?.id)
                : selectedTickets.push(ticket))
            );
        } else {
            tickets.forEach(ticket => {
                if (!find(selectedTickets, selectedTicket => selectedTicket.id === ticket.id)) {
                    setSelected(true);
                    selectedTickets.push(ticket);
                }
            });
        }
        footbar.update({
            selected: selectedTickets.map(selectedTicket => selectedTicket.id),
            list: selectedTickets,
            type: 'ticket'
        });
    };

    const selectedTicketsCountInColumn = selectedTicketsCount(selectedTickets, tickets);
    const areAllCardsSelected = selectedTicketsCountInColumn === tickets.length && tickets.length > 0;
    const areCardsPartlySelected = selectedTicketsCountInColumn > 0 && selectedTicketsCountInColumn < tickets.length;
    
    useEffect(calcElementSize, [refContainer]);
    window.addEventListener('resize', debounce(calcElementSize, 200));


    useEffect(() => {
        setSelected(!(
            footbar.getList().filter(obj => obj.status === props.category.name).length !== props.category.tickets.length
            || footbar.getList().filter(obj => obj.status === props.category.name).length === 0)
        );
    }, [footbar.getList(), props.category.tickets.length]);

    const canDeleteAllColumnTickets = () => currentUser.workspace_role.workspace_role_name === 'Owner'
        || currentUser.workspace_role.workspace_role_name === 'Admin' && !workspaceSettings.block_tickets_removal;

    return (
        <div className={'droppable-container'} ref={refContainer} style={{ width: props.width }}>
            <div className={'title'}>
                {canDeleteAllColumnTickets() && <Checkbox checked={areAllCardsSelected} indeterminate={areCardsPartlySelected} onChange={toggleSelection} color={'primary'} />}
                <h3>{t(`tickets:${props.category.name}`)}</h3>
                <span className={'ticket-number-in-col'}>{`(${tickets.length})`}</span>
            </div>
            <Droppable
                key={props.category.id}
                droppableId={props.category.id}
                mode="virtual"
                renderClone={(provided, snapshot, rubric) => (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                    >
                        <TicketView
                            isDragging={snapshot.isDragging}
                            ticket={tickets[rubric.source.index]}
                        />
                    </div>
                )}
            >
                { provided => (
                    <div ref={provided.innerRef} className={classNames('column-container', canSeeMobileBottomNavigation(currentUser, history.location.pathname, devices, workspaceSettings) ? 'column-with-bottom-navigation' : '')}>
                        <List
                            height={columnHeight}
                            rowCount={tickets.length}
                            rowHeight={isMobile ? 121 : 145}
                            outerRef={provided.innerRef}
                            width={ticketWidth}
                            itemData={tickets}
                            ref={ref => {
                                // react-virtualized has no way to get the list's ref that I can so
                                // So we use the `ReactDOM.findDOMNode(ref)` escape hatch to get the ref
                                if (ref) {
                                    // eslint-disable-next-line react/no-find-dom-node
                                    const whatHasMyLifeComeTo = ReactDOM.findDOMNode(ref);
                                    if (whatHasMyLifeComeTo instanceof HTMLElement) {
                                        provided.innerRef(whatHasMyLifeComeTo);
                                    }
                                }
                            }}
                            style={{
                                transition: 'background-color 0.2s ease'
                            }}
                            /* eslint-disable-next-line max-len */
                            rowRenderer={({ index, style }) => <IotRocketDraggable key={index} index={index} style={style} data={tickets} />
                            }
                        />
                    </div>
                )}
            </Droppable>
        </div>
    );
};

import axios from 'axios';
import { isEmpty } from 'lodash';
import { addDeviceToGroupLocally } from '../devices/actions';

export const notification = payload => ({ type: 'NOTIFICATION', payload });

export const getGroupRoles = () => ({
    type: 'GET_GROUP_ROLES',
    payload: axios.get('/acl/group-roles/')
        .then(res => res)
});

export const createGroup = data => {
    let group = {};
    return dispatch => {
        dispatch({ type: 'CREATE_GROUP_PENDING' });
        axios.post('/groups/', data)
            .then(res => {
                group = res.data;
                dispatch({ type: 'CREATE_GROUP_FULFILLED', payload: group });
                if (!isEmpty(data.device_ids)) {
                    dispatch(addDeviceToGroupLocally(data.device_ids, group.group_id));
                }
            });
    };
};

export const getGroupList = (anonymousConnexion = false) => {
    const headers = anonymousConnexion
        ? {
            'x-master-api-key': QRCODE_ISSUE_REPORT_API_KEY,
            username: USERNAME_ISSUE_REPORT,
            password: PASSWORD_ISSUE_REPORT
        }
        : {};
    return {
        type: 'GET_GROUP_LIST',
        payload: axios.get('/groups/', { headers })
            .then(res => res.data)
    };
};

export const editGroup = (id, data, groupType = 'DEVICE') => {
    let type = 'EDIT_GROUP';
    if (groupType === 'BATIMENT') {
        type = 'EDIT_BATIMENT';
    } else if (groupType === 'SKILL') {
        type = 'EDIT_SKILL';
    }

    // LEGACY - If group of devices, we use the PUT
    // Specific treatment is made on the backend side, will be uniformized soon
    if (type === 'EDIT_GROUP') {
        return {
            type,
            payload: axios.put(`/groups/${id}/`, data)
                .then(res => ({ res: res.data, req: data }))
        };
    }
    // We use the patch for any other group update
    return {
        type,
        payload: axios.patch(`/groups/${id}/`, data)
            .then(res => ({ res: res.data, req: data }))
    };
};

export const setGroupShownInDetailPopup = groupId => ({ type: 'SET_GROUP_DETAIL', payload: groupId });

export const resetSelectedGroup = () => ({ type: 'RESET_SELECTED_GROUP' });

export const getGroupSummary = (group_id, page) => ({
    type: 'GET_GROUP_SUMMARY',
    payload: axios.get(`/groups/summary/${group_id}/page/${page}`)
        .then(res => ({ group_id, data: res.data }))
});

export const getGroup = group_id => ({
    type: 'GET_GROUP',
    payload: axios.get(`/groups/${group_id}/`)
        .then(res => ({ group_id, data: res.data }))
});

export const getPreviewDeviceGroupData = (group_id, startDate, endDate) => ({
    type: 'GET_GROUP_DATA_PREVIEW',
    payload: axios.get(`/groups/get_group_data_preview/${group_id}?from_date=${startDate}&to_date=${endDate}`)
        .then(res => ({ group_id, data: res.data }))
        .catch(error => error)
});

export const resetPreviewDeviceGroupData = () => ({ type: 'RESET_GROUP_DATA_PREVIEW' });

export const getDeviceGroupDataExport = group_id => ({
    type: 'GET_GROUP_DATA_EXPORT',
    payload: axios.get(`/groups/get_group_data_export/${group_id}`)
        .then(res => ({ group_id, data: res.data }))
        .catch(error => error)
});

export const getGroupsExport = (groups_ids, timeSlots = {}) => ({
    type: 'GET_GROUPS_EXPORT',
    payload: axios.post('/groups/', { groups_ids }, {
        params: {
            action: 'export',
            time_slots: JSON.stringify(timeSlots)
        }
    })
        .then(res => ({ groups_ids, data: res.data }))
        .catch(error => error)
});


export const addGroupEditableField = field => ({ type: 'ADD_GROUP_EDITABLE_FIELD', payload: field });

export const addSkillsFromCSV = csv_file => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('skills', csv_file);
    return {
        type: 'ADD_SKILLS_CSV_FILE',
        payload: axios.post('/groups/save-skills-from-file', formData, config)
            .then(res => res.data)
    };
};

export const addBuildingsFromCSV = csv_file => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('buildings', csv_file);
    return {
        type: 'ADD_BUILDINGS_CSV_FILE',
        payload: axios.post('/groups/save-buildings-from-file', formData, config)
            .then(res => res.data)
    };
};

export const deleteGroups = (data, groupType = 'GROUP') => {
    let type = 'DELETE_GROUP';
    if (groupType === 'BATIMENT') {
        type = 'DELETE_BATIMENT';
    } else if (groupType === 'SKILL') {
        type = 'DELETE_SKILL';
    } else if (groupType === 'JOB') {
        type = 'DELETE_JOB';
    }
    return {
        type: data.group_ids.length > 1 ? `${type}S` : type,
        payload: axios.delete('/groups/', { data })
            .then(() => data)
    };
};

import {concat, filter, findIndex, forEach, includes, isEmpty, some} from 'lodash';
import moment from 'moment';
import { getUserGroupIds, getVisibleUsers } from '../users/helper';
import { updateNotification } from '../navbar/actions';
import { getGroupNameFromId } from '../groups/helper';
import {
    getTicketComments, getTicketOperations, getTicketSummary, setTicketShownInDetailPopup
} from './actions';
import { EVENT_OPEN_TICKET, segmentTrack } from '../layout/helper';
import {TicketMovementDirection} from "./enums";

export const findTicket = (ticketList, ticketId) => {
    let tickets = [];
    ticketList.forEach(category => {
        tickets = concat(tickets, category.tickets);
    });
    return tickets.find(ticket => ticket?.id === ticketId);
};

export const concatTickets = ticketList => {
    let tickets = [];
    ticketList.forEach(category => {
        tickets = concat(tickets, category.tickets);
    });
    return tickets;
};

export const findTicketByKey = (ticketList, ticketKey) => {
    let tickets = [];
    ticketList.forEach(category => {
        tickets = concat(tickets, category.tickets);
    });
    return tickets.find(ticket => ticket.key.toString() === ticketKey);
};

export const enableDeleteOnTicket = (isMobile, currentUser, ticket, workspaceSettings) => {
    if (workspaceSettings.block_tickets_removal) {
        return currentUser?.username === 'merciyanis@iot-rocket.space';
    }
    return currentUser.workspace_role?.workspace_role_name === 'Owner' || currentUser.workspace_role?.workspace_role_name === 'Admin'
        || currentUser.workspace_role?.workspace_role_name === 'User' && ticket.author === currentUser?.id && ticket.status === 'NEW'
        || currentUser.workspace_role?.workspace_role_name === 'Contractor' && ticket.author === currentUser?.id;
};

export const getWorkspaceGroupIdsByGroupType = (groupList, groupType, parentId = null) => {
    let group = [];
    if (groupType === 'BATIMENT') {
        group = groupList?.length > 0 ? groupList.filter(group => group?.custom_field?.type === groupType) : [];
    } else if (groupType === 'FLOOR' || groupType === 'POSITION') {
        if (parentId) {
            group = groupList?.length > 0 ? groupList.filter(group => group.group_id === parentId)[0].group_ids : [];
        }
    }
    return group;
};


export const getAssignersByGroupId = (list, filter_admin_on_skills, batimentId, skillId) => {
    const users = [];
    getVisibleUsers(list).filter(user => user?.invite_email === undefined
        && user?.workspace_role?.workspace_role_name !== 'User'
    ).forEach(user => {
        const userGroups = getUserGroupIds(user);
        if (user.workspace_role?.workspace_role_name === 'Owner') {
            users.push(user);
        } else if (user.workspace_role.workspace_role_name === 'Contractor'
            || (filter_admin_on_skills && user.workspace_role.workspace_role_name === 'Admin')
        ) {
            if (userGroups.includes(batimentId) && userGroups.includes(skillId)) {
                users.push(user);
            }
        } else if (userGroups.includes(batimentId)) {
            users.push(user);
        }
    });
    return users;
};

export const openTicket = async (e, dispatch, footbar, modal, groupList, notifications, ticket, isSelected = false) => {
    const ticketNotifs = notifications.flat().filter(notif => notif.object?.related_ticket.key === ticket.key);
    // Mark as "read" every notif related to the opened ticket
    if (ticketNotifs && ticket?.key) {
        ticketNotifs
            .filter(notif => !notif.seen_at)
            .forEach(notif => dispatch(updateNotification(notif.id, { seen_at: moment().format() })));
    }
    if (e?.target.tagName.toLowerCase() === 'input') return; // Note: this prevents the popup from opening when an input is called (in this case, the checkbox)
    segmentTrack(EVENT_OPEN_TICKET, { ticketId: ticket?.id, ticketOpenedByClickingOnIt: true });
    await dispatch(setTicketShownInDetailPopup(ticket, groupList)); // here
    await dispatch(getTicketSummary(ticket?.id, 1));
    await dispatch(getTicketComments(ticket?.id, 1));
    await dispatch(getTicketOperations(ticket?.id, 1));
    footbar.close();
    modal.update({ name: 'TicketDetailsPopup', item: isSelected, withFootBar: true });
    window.history.replaceState({}, '', `/tickets?key=${ticket.key.toString()}`);
};

export const customTicketFiltersByRole = {
    User: ['tickets', 'group', 'key', 'user'],
    Owner: ['tickets', 'tags', 'user', 'group', 'assigners', 'key', 'messagingUsers'],
    Contractor: ['tickets', 'tags', 'user', 'group', 'assigners', 'key', 'messagingUsers'],
    Admin: ['tickets', 'tags', 'user', 'group', 'assigners', 'key', 'messagingUsers']
};

export const customTicketFilterFunctions = {
    tickets: props => filter(props.list, ticket => ticket.title?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '')?.replace(/\s/g, '')?.toLowerCase().includes(
            props?.id?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.replace(/\s/g, '')?.toLowerCase()
        )),
    tags: props => filter(props.list, ticket => includes(ticket.tags, props.id)),
    assigners: props => filter(props.list, ticket => includes(ticket.assigners, props.id_bis)),
    user: props => filter(props.list, ticket => includes(ticket.author, props?.id)),
    group: props => filter(props.list, ticket => ticket?.custom_field?.batiment_group_id === props?.id
        || ticket?.custom_field?.floor_group_id === props?.id
        || ticket?.custom_field?.position_group_id === props?.id
        || ticket?.custom_field?.skill_group_id === props?.id
    ),
    messagingUsers: props => filter(props.list, ticket => ticket?.custom_field.author_messaging_user_id === props?.id),
    key: props => filter(props.list, ticket => ticket.key.toString() === props?.id?.toString())
};

export const hasRightOnTicket = (ticket, user,
    filter_admin_on_skills = false, filter_user_on_skills = false, user_tickets_mode = 'CREATED', groupList = [], contractor_tickets_mode = 'ASSIGNATED') => {
    if (ticket.custom_field.batiment_group_id && ticket.custom_field.skill_group_id) {
        const userGroupIds = getUserGroupIds(user);
        if (userGroupIds.find(group => group === ticket.custom_field.batiment_group_id)) {
            if (isEmpty(getGroupNameFromId(groupList, ticket.custom_field.skill_group_id))) {
                return false;
            }
            if (user.workspace_role.workspace_role_name === 'Contractor') {
                if (userGroupIds.find(group => group === ticket.custom_field.skill_group_id)) {
                    if (contractor_tickets_mode === 'ASSIGNATED') {
                        return !!ticket.assigners.find(assigner => assigner === user.id);
                    }
                    return true;
                }
            }
            if (user.workspace_role.workspace_role_name === 'Admin') {
                if (filter_admin_on_skills) {
                    return !!userGroupIds.find(group => group === ticket.custom_field.skill_group_id);
                }
                return true;
            }
            if (user.workspace_role.workspace_role_name === 'User') {
                if (user_tickets_mode === 'CREATED') {
                    return user.id === ticket.author_id;
                }
                if (filter_user_on_skills) {
                    return !!userGroupIds.find(group => group === ticket.custom_field.skill_group_id);
                }
                return true;
            }
        } else if (user.username === 'merciyanis@iot-rocket.space') { // merciyanis user has no batiments and skills
            return true;
        }
    }
    return false;
};

/**
 * This func calculates the movement direction of the selected tickets inside the current col
 * and the number of the selected tickets that are before the destination index
 * @param {{}[]} sourceTickets
 * @param {number[]} checkedTicketIDs
 * @param {number} destinationIndex
 * @return {{movementDirection: string , selectedTicketsBeforeDestinationIndex: number}} => movementDirection = up/down using ticketMovementDirection enum
 */
export const calculateTicketMovementInfo = (sourceTickets, checkedTicketIDs, destinationIndex) => {
    let selectedTicketsBeforeDestinationIndex = 0;
    let minIndex = 0;
    forEach(checkedTicketIDs, (checkedTicketId, index) => {
        const ticketIndex = findIndex(sourceTickets, (sourceTicket) => sourceTicket.id === checkedTicketId);
        if (ticketIndex < destinationIndex) {
            selectedTicketsBeforeDestinationIndex++;
        }
        if (index === 0) {
            minIndex = ticketIndex;
            return;
        }
        minIndex = ticketIndex < minIndex ? ticketIndex : minIndex;
    });

    const movementDirection =
        destinationIndex > minIndex
            ? TicketMovementDirection.DOWN
            : destinationIndex < minIndex
            ? TicketMovementDirection.UP
            : null;

    return {
        movementDirection,
        selectedTicketsBeforeDestinationIndex
    };
};

/**
 * Calculates the number of selected tickets from the current column of tickets
 * @param {{}[]} selectedTicketsList
 * @param {{}[]} columnTicketsList
 * @return {number}
 */
export const selectedTicketsCount = (selectedTicketsList, columnTicketsList) => {
    // number of cards that are selected from current column
    let numberOfSelectedCards = 0;
    selectedTicketsList.forEach((selectedTicket) => {
        const isCurrentCardSelected = some(
            columnTicketsList,
            (currentColumnTicket) => currentColumnTicket.id === selectedTicket.id
        );
        if (isCurrentCardSelected) numberOfSelectedCards += 1;
    });
    return numberOfSelectedCards;
};

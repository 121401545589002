// Libs
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useModal } from '../../../modal/ModalContext';

const RoundBuildings = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const modal = useModal();
    const [round] = useState(props.resource || modal.getItem());

    const roundBuildings = round.round_devices_locations;

    return isEmpty(roundBuildings)
        ? <i className={'placeholder_text'}>{t('common:not_in_group_BUILDING')}</i>
        : (
            <Component
                componentName={'BuildingButton'}
                group_memberships={roundBuildings}
                iconType={'user'}
                maxVisible={2}
            />
        );
};

export default RoundBuildings;

// Libs
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
// Context
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useFootBar } from '../FootBarContext';
import { useComponentsPool } from '../../../ComponentsPool';
import { useModal } from '../../modal/ModalContext';

// Actions
import { getGroupNameFromId } from '../../groups/helper';
import { getRoundDataExport } from '../../rounds/actions';
import { exportRoundTableToCsvFile, isMoreThan24Hours } from '../../rounds/helper';
import { getDeviceDataExport, resetSelectedDevice } from '../../devices/actions';
import { getDeviceGroupDataExport, resetSelectedGroup } from '../../groups/actions';
import { saveStringToPdf } from '../../../utils/fileUtils';

const tableTooltip = { tooltip: 'table-tooltip' };
const useStyles = makeStyles(() => ({
    tooltip: {
        width: 250
    }
}));

export function exportTableToFile(objectInformations, objectType, filename, rows, t, workspaceName, currentUser) {
    let csvFile = t('devices:date');
    let data_types = '';
    let groupDataType = '';
    let objectInformationsHeaders;
    if (objectType === 'GROUP') {
        if (rows.length > 0) {
            groupDataType = rows[0].data_type;
            if (groupDataType === 'press_count') {
                data_types = `${data_types};${t('export:template_title')};${t(`export:${groupDataType}`)}`;
                objectInformationsHeaders = `${t('devices:device_hardware_id')};${
                    t('devices:device_name')};${
                    t('devices:building_group')};${
                    t('dashboards:csv_header_floor')};${
                    t('dashboards:csv_header_position')}`;
                csvFile = `${csvFile}${data_types};${objectInformationsHeaders}\n`;
            } else if (groupDataType === 'status') {
                data_types = `${data_types};${t(`export:${groupDataType}`)}`;
                objectInformationsHeaders = `${t('groups:group_name')};${
                    t('groups:batiment_group')};${
                    t('groups:floor_group')};${
                    t('groups:position_group')}`;
                csvFile = `${csvFile}${data_types};${objectInformationsHeaders}\n`;
            }
        }
    } else {
        let hasHeaderAgent = false;
        // Sort for TES-5MRO
        rows.sort((a,b) => {
            if (a.data_type === 'status') return -1;
            if (b.data_type === 'status') return 1;
            return 0;
        });
        for (let j = 0; j < rows.length; j = j + 1) {
            if (rows[j].data_points[0].temperature !== undefined) data_types = `${data_types};${t('export:temp')}`;
            else if (rows[j].data_points[0].humidity !== undefined) data_types = `${data_types};${t('export:Humi')}`;
            else if (rows[j].data_points[0].co2 !== undefined) data_types = `${data_types};${t('export:CO2')}`;
            else if (rows[j].data_points[0].luminosity !== undefined) data_types = `${data_types};${t('export:Lumi')}`;
            else if (rows[j].data_points[0].press_count !== undefined) data_types = `${data_types};${t('export:press_count')}`;
            else if (rows[j].data_points[0].state !== undefined) data_types = `${data_types};${t('export:state')}`;
            else if (rows[j].data_points[0].status !== undefined) data_types = `${data_types};${t('export:status')}`;
            else if (rows[j].data_points[0].people !== undefined) data_types = `${data_types};${t('export:people')}`;
            else if (rows[j].data_points[0].jobs !== undefined) data_types = `${data_types};${t('export:jobs')}`;
            else if (rows[j].data_points[0].gps_location_status !== undefined) data_types = `${data_types};${t('export:gps_location_status')}`;
            else if (rows[j].data_points[0].battery_voltage !== undefined) data_types = `${data_types};${t('export:battery_level')}`;

            if ((rows[j].data_points[0].agent !== undefined || rows[j].data_points[0].author !== undefined) && !hasHeaderAgent) {
                data_types = `${data_types};${t('export:agent')}`;
                hasHeaderAgent = true;
            }
            if (rows[j].data_points[0].comment !== undefined) data_types = `${data_types};${t('export:comment')}`;
            if (rows[j].data_points[0].nfc_tag !== undefined) data_types = `${data_types};${t('export:nfc_tag')}`;
            if (rows[j].data_points[0].passage_type !== undefined) data_types = `${data_types};${t('export:passage_type')}`;
            if (rows[j].data_points[0].duration_since_last_enter !== undefined) data_types = `${data_types};${t('export:duration_since_last_enter')}`;
        }
        objectInformationsHeaders = `${t('devices:device_hardware_id')};${
            t('devices:device_name')};${
            t('devices:building_group')};${
            t('dashboards:csv_header_floor')};${
            t('dashboards:csv_header_position')}`;
        csvFile = `${csvFile}${data_types};${objectInformationsHeaders}\n`;
    }

    const processRow = function (row, deviceType = null) {
        if (!row) return '';
        let value = '';
        if (row.temperature !== undefined) value = `${row.temperature}`.replace('.', ',');
        else if (row.humidity !== undefined) value = `${row.humidity}`.replace('.', ',');
        else if (row.co2 !== undefined) value = `${row.co2}`.replace('.', ',');
        else if (row.luminosity !== undefined) value = `${row.luminosity}`.replace('.', ',');
        else if (row.press_count !== undefined) value = `${row.press_count}`.replace('.', ',');
        else if (row.state !== undefined) value = `${row.state}`.replace('.', ',');
        else if (row.status !== undefined) {
            if (deviceType.includes('TORK_')) {
                value = `${t(`devices:status_${row.status}_${deviceType}`)}`.replace('.', ',');
            } else if (deviceType === 'LWP') {
                value = `${t(`export:status_${row.status}_${deviceType}`)}`.replace('.', ',');
            } else {
                value = `${t(`export:${row.status}`)}`.replace('.', ',');
            }
        }
        else if (row.people !== undefined) value = `${row.people}`.replace('.', ',');
        else if (row.jobs !== undefined) value = row.jobs.join(', ').replace('.', ',');
        else if (row.gps_location_status !== undefined) {
            value = row.gps_location_status === null
                ? ''
                : `${t(`export:gps_location_status_${row.gps_location_status}`)}`.replace('.', ',');
        }
        else if (row.battery_voltage !== undefined) value = `${row.battery_voltage}`.replace('.', ',');
        // Avoid author repetition in case of related device message id
        if (row.author !== undefined && !row.related_device_message_id) {
            value = value
                ? value + `; ${row.author}`.replace('.', ',')
                : `${row.author}`.replace('.', ',');
        }

        if (row.comment !== undefined ) {
            value = value
                ? `${value }; ${row.comment.replace(/\n/g, ' ')}`
                : `${row.comment.replace(/\n/g, ' ')}`;
        }

        if (row.agent !== undefined) {
            value = value
                ? value + `; ${row.agent}`.replace('.', ',')
                : `${row.agent}`.replace('.', ',');
        }
        if (row.nfc_tag !== undefined) {
            value = value
                ? value + `; ${row.nfc_tag}`.replace('.', ',')
                : `${row.nfc_tag}`.replace('.', ',');
        }
        if (row.passage_type !== undefined) {
            value = value
                ? value + `; ${t(`passages:${row.passage_type}`)}`.replace('.', ',')
                : `${t(`passages:${row.passage_type}`)}`.replace('.', ',');
        }
        if (row.duration_since_last_enter !== undefined) {
            value = value
                ? value + `; ${row.duration_since_last_enter}`.replace('.', ',')
                : `${row.duration_since_last_enter}`.replace('.', ',');
        }
        return `${value}`;
    };

    let value = '';
    let date;
    let isError = false;
    if (objectType === 'GROUP') {
        for (let i = 0; i < rows?.length; i = i + 1) {
            date = moment(rows[i].timestamp.toLocaleString().replace(/"/g, '""')).format('DD/MM/YYYY HH:mm:ss');
            if (groupDataType === 'press_count') {
                value = `${t(`devices:${rows[i].value.toString()}`)};${
                    rows[i].press_count.toLocaleString()};${
                    rows[i].device_hardware_id};${
                    rows[i].device_name}`;
            } else if (groupDataType === 'status') {
                value = t(`groups:${rows[i].status.toLocaleString()}`);
            }
            csvFile = `${csvFile}${date};${value};${objectInformations}\n`;
        }
    } else if (rows?.length > 0) {
        const entryMap = new Map();

        // Check is performed based on the first data object
        for (let i = 0; i < rows[0].data_points.length; i = i + 1) {
            value = '';
            date = rows[0].data_points[i].timestamp.toLocaleString().replace(/"/g, '""');
            date = moment(date).format('DD/MM/YYYY HH:mm:ss');
            if (!entryMap.has(date)) {
                isError = false;
                if (objectType === 'QRCODE') {
                    let isFounded = false;
                    let refTimestamp = '';
                    let refId = '';

                    value = processRow(rows[0].data_points[i], objectType);

                    refTimestamp = rows[0].data_points[i]?.timestamp;
                    refId = rows[0].data_points[i]?.id;

                    for (let j = 1; j < rows.length; j = j + 1) {
                        isFounded = false;
                        for (let k = 0; k < rows[j]?.data_points.length; k = k + 1) {
                            if ((refId !== '' && rows[j].data_points[k]?.related_device_message_id === refId) || (rows[j].data_points[k]?.timestamp === refTimestamp)) {
                                value = `${value};${processRow(rows[j].data_points[k], objectType)}`;
                                isFounded = true;
                            }
                        }
                        if (!isFounded) value = `${value};`;
                    }
                } else {
                    if (objectType === 'TES-5MRO' && data_types.includes(t('export:temp')) && data_types.includes(t('export:Humi'))) {
                        value = processRow(rows[0].data_points[i], objectType); // It's status
                        let value1 = '';
                        let value2 = '';
                        for (let j = 0; j < rows.length; j = j + 1) { // It's temperature
                            if (rows[0].data_points[i]?.timestamp === rows[1].data_points[j]?.timestamp) {
                                value1 = processRow(rows[1].data_points[j], objectType);
                            }
                            if (rows[0].data_points[i]?.timestamp === rows[2].data_points[j]?.timestamp) {
                                value2 = processRow(rows[2].data_points[j], objectType);
                            }
                        }
                        value = `${value};${value1};${value2}`;
                    } else {
                        for (let j = 0; j < rows.length; j = j + 1) {
                            if (rows[j].data_points[i]?.status === '' && objectType !== 'TES-5MRO') {
                                isError = true;
                            } else if (j === 0) value = processRow(rows[j].data_points[i], objectType);
                            else value = `${value};${processRow(rows[j].data_points[i], objectType)}`;
                        }
                    }

                }
                if (!isError) {
                    entryMap.set(`${date}`, `${date};${value};${objectInformations}\n`);
                }
            }
        }

        // Check if additional data
        const additionalRows = rows.filter(row => row.data_type && row.data_type === 'comment');

        for (let j = 0; j < additionalRows.length; j++) {
            for (let i = 0; i < additionalRows[j].data_points.length; i = i + 1) {
                value = '';
                date = additionalRows[j].data_points[i].timestamp.toLocaleString().replace(/"/g, '""');
                date = moment(date).format('DD/MM/YYYY HH:mm:ss');
                //Comments with related device message id are already treated as messages
                if (!entryMap.has(date) && !additionalRows[j].data_points[i]?.related_device_message_id) {
                    value = `${value};${processRow(additionalRows[j].data_points[i], objectType)}`;
                    entryMap.set(`${date}`, `${date};${value};${objectInformations}\n`);
                }
            }
        }

        // Sorting of entryMap
        const mapDesc = new Map([...entryMap.entries()].sort((a, b) => moment(b[0], 'DD/MM/YYYY HH:mm:ss').toDate() - moment(a[0], 'DD/MM/YYYY HH:mm:ss').toDate()));
        mapDesc.forEach(rowValue => csvFile = `${csvFile}${rowValue}`);
    }

    if (filename.endsWith('.pdf')) {
        saveStringToPdf(csvFile,
            filename,
            '',
            '',
            `${t('export:exported_by')} ${currentUser?.first_name} ${currentUser?.last_name} ${t('common:date_the_at', { dayAndMonth: moment().format('DD/MM/YYYY'), hoursAndMinutes: moment().format('HH:mm:ss') }).toLowerCase()}`,
            workspaceName);
    } else {
        // CSV export by default
        const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
}

const SwitchModeButtons = props => {
    const { Component } = useComponentsPool();
    const footbar = useFootBar();
    const [t] = useTranslation();
    const userRights = useSelector(state => state.users.currentUser.workspace_rights);
    const currentUser = useSelector(state => state.users.currentUser);
    const modal = useModal();
    const dispatch = useDispatch();
    const device = useSelector(state => state.devices.deviceShownInDetailsPopup);
    const group = useSelector(state => state.groups.groupShownInDetailsPopup);
    const round = useSelector(state => state.rounds.roundShownInDetailsPopup);
    const roundsActiveMode = useSelector(state => state.rounds.active_mode);
    const ticketsActiveMode = useSelector(state => state.tickets.list.find(category => category?.id === 'ARCHIVED')?.isVisible === false);
    const groupList = useSelector(state => state.groups.list);
    const classes = useStyles();
    const [isOpenTooltip, setIsOpenTooltip] = useState(false);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const workspace = localStorage.getItem('iot_rocket_workspace');
    const disabledClose = footbar.isEdited;
    let disableSaveForComment = false;
    const disableEditButton = props.resourceType === 'Round' && !round.round_next_up_instance;
    if (props.resourceType === 'Ticket' && footbar.editionObject?.isNotSentComment) {
        disableSaveForComment = footbar.editionObject.isNotSentComment;
    }
    const [wasClickedSaveButton, setWasClickedSaveButton] = useState(false);
    const disableSave = !footbar.isEdited || footbar.isError || disableSaveForComment || wasClickedSaveButton;
    const exportButtonLabel = useState(
        device.custom_field?.type === 'QRCODE' && device.custom_field?.config?.modes?.includes('PASSAGE_VALIDATION')
            ? t('devices:export_passage_data')
            : device.custom_field?.type?.includes('BUTTON')
                ? t('common:switch_button_mode_export_1_year')
                : t('common:switch_button_mode_export_6_month')
    );

    const exportRound = () => {
        dispatch(getRoundDataExport(round.id, !roundsActiveMode)).then(res => {
            if (res.action?.payload) {
                exportRoundTableToCsvFile(t('rounds:export_file_name', {
                    fileName: round.name.toLowerCase()
                        .replace(/\s+/g, '_')
                        .replace(/[^\w\s]/gi, '_')
                        .replace(' ', '_')
                }), res.action.payload, round.name, t);
            }
        });
    };

    const handleClick = async (e, fileExtension = '.csv') => {
        if (!isEmpty(device)) {
            let datas = device.dataExport;
            if (isEmpty(device.dataExport)) {
                const result = await dispatch(getDeviceDataExport(device.device_id));
                datas = result ? result.action.payload.data : [];
            }
            const batiment = getGroupNameFromId(groupList, device.custom_field?.batiment_group_id);
            const floor = getGroupNameFromId(groupList, device.custom_field?.floor_group_id);
            const position = getGroupNameFromId(groupList, device.custom_field?.position_group_id);

            const deviceInformations = `${device.hardware_ids?.length > 0 ? device.hardware_ids[0] : ' '};${
                device.device_name};${
                batiment || ' '};${
                floor || ' '};${
                position || ' '}`;

            // Get exported period text based on device type => BUTTON types = 1 year / other types = 6 months
            const exportPeriod = device.custom_field?.type?.includes('BUTTON')
                ? t('common:for_the_past_1_year')
                : t('common:for_the_last_6_months');

            // replace spaces with '_'
            const formattedExportPeriod = exportPeriod.split(' ').join('_');
            // transform the group name to => lowercase + replace all white spaces & all characters that are not letter/number/underscores with "_"
            const formattedDeviceName = device.device_name
                .toLowerCase()
                .replace(/\s+/g, '_')
                .replace(/[^\w\s]/gi, '_')
                .replace(' ', '_');

            exportTableToFile(
                deviceInformations,
                device.custom_field?.type,
                `${t('devices:export_file_name')}${formattedDeviceName}_${formattedExportPeriod}${fileExtension}`,
                datas,
                t,
                workspace,
                currentUser
            );
        } else if (!isEmpty(group)) {
            let datas = group.dataExport;
            if (isEmpty(group.dataExport)) {
                const result = await dispatch(getDeviceGroupDataExport(group.group_id));
                datas = result ? result.action.payload.data : [];
            }
            const batiment = getGroupNameFromId(groupList, group.custom_field?.batiment_group_id);
            const floor = getGroupNameFromId(groupList, group.custom_field?.floor_group_id);
            const position = getGroupNameFromId(groupList, group.custom_field?.position_group_id);

            let groupInformations = `${batiment};${floor};${position}`;
            if (group.devices.find(obj => obj.custom_field.type === 'SMARTDESK')) {
                groupInformations = `${group.group_name};${groupInformations}`;
            }

            // Get exported period text based on device type => BUTTON types = 1 year / other types = 6 months
            const exportPeriod = device.custom_field?.type?.includes('BUTTON')
                ? t('common:for_the_past_1_year')
                : t('common:for_the_last_6_months');

            // replace spaces with '_'
            const formattedExportPeriod = exportPeriod.split(' ').join('_')
            // transform the group name to => lowercase + replace all white spaces & all characters that are not letter/number/underscores with "_"
            const formattedGroupName = group.group_name
                .toLowerCase()
                .replace(/\s+/g, '_')
                .replace(/[^\w\s]/gi, '_')
                .replace(' ', '_');

            exportTableToFile(
                groupInformations,
                'GROUP',
                `${t('devices:export_file_name')}${formattedGroupName}_${formattedExportPeriod}${fileExtension}`,
                datas,
                t,
                workspace,
                currentUser
            );
        }
    };
    return (
        <>
            <div className={'switch-mode-buttons'}>
                <Tooltip
                    placement={'top'}
                    key={'visualize'}
                    title={disabledClose ? t('common:switch_button_mode_eye_disabled') : t('common:visualize')}
                >
                    <div
                        className={classNames(
                            'switch-button',
                            !footbar.getEditionMode() ? 'selected' : '',
                            disabledClose ? 'disabled' : ''
                        )}
                        tabIndex={0}
                        role={'button'}
                        onClick={() => !disabledClose && footbar.set({ editionMode: false })}
                    >
                        <Component componentName={'Icon'} type={'eye'} />
                    </div>
                </Tooltip>
                {props.resourceType === 'Round'
                && (
                    <Tooltip
                        placement={'top'}
                        key={'switch_button_export'}
                        classes={tableTooltip}
                        title={(
                            <Component componentName={'Description'} content={t('common:switch_button_mode_export')} />)}
                    >
                        <div
                            className={'switch-button'}
                            tabIndex={0}
                            role={'button'}
                            onClick={() => exportRound()}
                        >
                            <Component componentName={'Icon'} type={'export'} />
                        </div>
                    </Tooltip>
                )
                }
                {(userRights.includes(`UPDATE:${props.resourceType}`)
                    && !(props.resourceType === 'Round' && !roundsActiveMode)
                        && !(props.resourceType === 'Ticket' && !ticketsActiveMode))
                    && (
                        <>
                            <Tooltip
                                placement={'top'}
                                key={'edit'}
                                title={disableEditButton ? t('rounds:disable_round_edition') : t('common:edit')}
                            >
                                <div
                                    className={classNames('switch-button', footbar.getEditionMode() ? 'selected' : '', disableEditButton ? 'disabled-edit-button' : '')}
                                    tabIndex={0}
                                    role={'button'}
                                    onClick={() => !disableEditButton && footbar.set({ editionMode: true })}
                                >
                                    <Component componentName={'Icon'} type={'faPen'} />
                                </div>
                            </Tooltip>
                            {props.resourceType === 'Device' && device.data?.length > 0 && !device.data[0].error
                            && (
                                workspaceSettings.name === 'demo' ? (
                                    <Tooltip
                                        placement={'top'}
                                        key={'switch_button_export'}
                                        classes={tableTooltip}
                                        open={isOpenTooltip}
                                        title={(
                                            <Component
                                                componentName={'Description'}
                                                content={exportButtonLabel}
                                            />
                                        )}
                                    >
                                        <div
                                            className={'switch-button'}
                                            tabIndex={0}
                                            role={'button'}
                                            onMouseEnter={() => setIsOpenTooltip(true)}
                                            onMouseLeave={() => setIsOpenTooltip(false)}
                                        >
                                            <Component
                                                componentName={'FootbarButton'}
                                                withConfirmationPopover
                                                confirmationButtonLabel={t('dashboards:exportCSV')}
                                                callbackAction={e => handleClick(e, '.csv')}
                                                secondConfirmationButtonLabel={t('dashboards:exportPDF')}
                                                secondCallbackAction={e => handleClick(e, '.pdf')}
                                                customStyle={'custom-button'}
                                                displayCondition
                                                popoverText={t('export:choose_export_type')}
                                                buttonIcon={'export'}
                                                setIsOpenPopover={isOpen => isOpen && setIsOpenTooltip(false)}
                                            />
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        placement={'top'}
                                        key={'switch_button_export'}
                                        classes={tableTooltip}
                                        title={(
                                            <Component
                                                componentName={'Description'}
                                                content={exportButtonLabel}
                                            />
                                        )}
                                    >
                                        <div
                                            className={'switch-button'}
                                            tabIndex={0}
                                            role={'button'}
                                            onClick={() => handleClick()}
                                        >
                                            <Component componentName={'Icon'} type={'export'} />
                                        </div>
                                    </Tooltip>
                                )
                            )}
                        </>
                    )
                }
                {props.resourceType === 'Group' && !isEmpty(group.data) && (
                    <Tooltip
                        placement={'top'}
                        key={'switch_button_export'}
                        classes={tableTooltip}
                        title={(
                            <Component componentName={'Description'} content={t('common:switch_button_mode_export')} />)}
                    >
                        <div
                            className={'switch-button'}
                            tabIndex={0}
                            role={'button'}
                            onClick={() => handleClick()}
                        >
                            <Component componentName={'Icon'} type={'export'} />
                        </div>
                    </Tooltip>
                )}
            </div>
            <div className={'action-buttons'}>
                {footbar.getEditionMode() && (
                    <div>
                        <Component
                            componentName={'Button'}
                            onClick={() => {
                                footbar.set({ editionMode: false });
                            }}
                            startAdornment
                            icon={'arrowLeft'}
                            text={t('common:cancel')}
                            variant={'outlined'}
                            className={'confirmation-button'}
                        />
                        <Tooltip
                            placement={'top-end'}
                            key={'disable_save'}
                            title={disableSaveForComment ? t('common:disable_save_button') : ''}
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <div className={'disable-save-button-container'}>
                                <Component
                                    componentName={'Button'}
                                    onClick={() => {
                                        setWasClickedSaveButton(true);
                                        return props.callback()
                                            ?.then(() => {
                                                modal.close();
                                                dispatch(resetSelectedDevice());
                                                dispatch(resetSelectedGroup());
                                                footbar.set({ editionMode: false, isEdited: false });
                                            })
                                            ?.catch(() => { setWasClickedSaveButton(false); });
                                    }}
                                    startAdornment
                                    disabled={disableSave}
                                    icon={'save'}
                                    text={t('common:save')}
                                    variant={'outlined'}
                                    className={'confirmation-button save-button'}
                                />
                            </div>
                        </Tooltip>
                    </div>
                )}
            </div>
        </>
    );
};

SwitchModeButtons.propTypes = {
    callback: PropTypes.func.isRequired,
    resourceType: PropTypes.string.isRequired
};

export default SwitchModeButtons;

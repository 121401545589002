// Library
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const BottomSection = () => {
    const currentUser = useSelector(state => state.users.currentUser);

    useEffect(() => {
        // NPS
        const appMerciYanisOnLocal = window.location.hostname === 'localhost' && DOMAIN_NAME.includes('local.iot-rocket.space');

        // To decomment when testing on local (don't forget to delete sent data on satismeter
        if (!appMerciYanisOnLocal) {
            // eslint-disable-next-line prefer-rest-params
            (function () { window.satismeter = window.satismeter || function () { (window.satismeter.q = window.satismeter.q || []).push(arguments); }; window.satismeter.l = 1 * new Date(); const script = document.createElement('script'); const parent = document.getElementsByTagName('script')[0].parentNode; script.async = 1; script.src = 'https://app.satismeter.com/js'; parent.appendChild(script); }());

            // eslint-disable-next-line no-undef
            satismeter({
                writeKey: SATISMETER_WRITE_KEY,
                userId: currentUser.id,
                traits: {
                    name: `${currentUser.first_name} ${currentUser.last_name}`,
                    username: currentUser.username,
                    first_name: currentUser.first_name,
                    last_name: currentUser.last_name,
                    email: currentUser.email,
                    workspace: localStorage.getItem('iot_rocket_workspace'),
                    workspace_role: currentUser.workspace_role.workspace_role_name,
                    company: currentUser.custom_field.company,
                    created_at: currentUser.created_at // ISO8601 Date,
                }
            });
        }
    }, [currentUser]);

    return (
        <div className="bottom-section" />
    );
};


export default BottomSection;

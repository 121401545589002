import { Strategy, NetworkFirst, StaleWhileRevalidate } from 'workbox-strategies';

class NetworkFirstWithTimeoutStrategy extends Strategy {
    constructor(options) {
        super(options);
        this.networkFirst = new NetworkFirst(options);
        this.staleWhileRevalidate = new StaleWhileRevalidate(options);
        this.networkTimeoutSeconds = options.networkTimeoutSeconds || 10; // Définit le délai d'attente réseau
    }

    async _handle(request, handler) {
        let response;
        try {
            // Essayer de récupérer la réponse réseau avec un délai d'attente
            response = await this._getNetworkResponseWithTimeout(request, handler);
        } catch (error) {
            // Si une erreur se produit ou si le délai est dépassé, utilise la stratégie StaleWhileRevalidate
            response = await this.staleWhileRevalidate.handle({ request, event: handler.event });
        }
        return response;
    }

    _getNetworkResponseWithTimeout(request, handler) {
        return new Promise((resolve, reject) => {
            let networkResponseReceived = false;

            const timeoutId = setTimeout(() => {
                if (!networkResponseReceived) {
                    reject(new Error('Network response timed out.'));
                }
            }, this.networkTimeoutSeconds * 1000);

            this.networkFirst.handle({ request, event: handler.event }).then(response => {
                networkResponseReceived = true;
                clearTimeout(timeoutId);
                resolve(response);
            }).catch(reject);
        });
    }
}

export { NetworkFirstWithTimeoutStrategy };

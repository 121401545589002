// Lib
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Radio, RadioGroup } from '@mui/material';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// Context
import Chip from '@material-ui/core/Chip';
import classnames from 'classnames';
import { useComponentsPool } from '../../../../ComponentsPool';
import { gettext, getWeekDays } from '../../../rounds/helper';
import { getTimeSlotRecapText } from '../../../layout/helper';
import { getDefaultAlertsSelectedDays } from '../../../devices/helper';


const DevicesAlertsTimeSlot = props => {
    const { t } = useTranslation();
    const { Component } = useComponentsPool();
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const deviceAlertTimeRange = workspaceSettings?.device_alert_time_range;
    const [radioButtonValue, setRadioButtonValue] = useState(deviceAlertTimeRange?.hour_start && deviceAlertTimeRange?.hour_end ? 'custom_time_slot' : '24/24');
    const [hourStart, setHourStart] = useState(deviceAlertTimeRange?.hour_start);
    const [hourEnd, setHourEnd] = useState(deviceAlertTimeRange?.hour_end);
    const weekDays = getWeekDays();
    const [selectedDays, setSelectedDays] = useState(getDefaultAlertsSelectedDays(deviceAlertTimeRange));
    const recapSentenceAndStyle = getTimeSlotRecapText(t, hourStart, hourEnd, selectedDays, radioButtonValue === '24/24', false);

    useEffect(() => {
        if (props.cancelModifs) {
            setRadioButtonValue(deviceAlertTimeRange?.hour_start && deviceAlertTimeRange?.hour_end ? 'custom_time_slot' : '24/24');
            setSelectedDays(getDefaultAlertsSelectedDays(deviceAlertTimeRange));
            setHourStart(deviceAlertTimeRange?.hour_start);
            setHourEnd(deviceAlertTimeRange?.hour_end);
            props.setCancelModfis(false);
        }
    }, [props.cancelModifs]);

    const handleChangeRadioButton = newRadioButtonValue => {
        setRadioButtonValue(newRadioButtonValue);
    };

    useEffect(() => {
        // everytime the button radio, the hours or the days changed
        let newValue;
        if (selectedDays && selectedDays?.length === 0) {
            newValue = { days: [] };
        } else if (radioButtonValue === '24/24') {
            newValue = {
                days: selectedDays?.map(selectedDay => weekDays.findIndex(weekDay => weekDay.value === selectedDay) + 1),
                hour_start: null,
                hour_end: null
            };
        } else {
            newValue = {
                days: selectedDays?.map(selectedDay => weekDays.findIndex(weekDay => weekDay.value === selectedDay) + 1),
                hour_start: !hourStart || !hourEnd || recapSentenceAndStyle[0] === 'summary-error' ? 'error_field' : hourStart,
                hour_end: !hourStart || !hourEnd || recapSentenceAndStyle[0] === 'summary-error' ? 'error_field' : hourEnd
            };
        }
        props.modifyCurrentConfig([{
            configName: 'device_alert_time_range',
            configValue: newValue
        }]);
    }, [radioButtonValue, selectedDays, hourStart, hourEnd]);

    return (
        <>
            <div className={'configuration-page-top-content'}>
                <Component componentName={'Icon'} type={'clockEnveloppe'} />
                <div className={'configuration-page-title'}>
                    <h3>{t('workspace:configure_devices_alerts_conditions')}</h3>
                    <p>
                        {t('workspace:choose_when_to_receive_devices_alerts')}
                        <span className={'bold-text'}>{` ${t('devices:temperature')}`}</span>
                        <span>,</span>
                        <span className={'bold-text'}>{` ${t('devices:air_quality')}`}</span>
                    </p>
                    <p>{t('workspace:devices_alerts')}</p>
                </div>
            </div>
            <div className={'configuration-page-text'}>
                <div className={'configuration-page-section-wrapper'}>
                    <div className={'configuration-page-section-title'}>{t('workspace:device_alerts_conditions')}</div>
                    <div className={'configuration-page-explanation-wrapper'}>
                        <div className={'configuration-page-explanation-content explanation-has-multi-lines'}>
                            <div className={'configuration-page-explanation-text'}>
                                <p>{t('workspace:device_alerts_conditions_question1')}</p>
                            </div>
                            <div className={'configuration-page-explanation-fields'}>
                                <div className={'weekday-choice-wrapper'}>
                                    <div className={'weekday-choice'}>
                                        {weekDays.map(weekDay => (
                                            <Chip
                                                key={weekDay.id}
                                                clickable
                                                classes={
                                                selectedDays?.includes(weekDay.value)
                                                    ? { root: 'weekday-chip selected-weekday-chip' }
                                                    : { root: 'weekday-chip' }
                                                }
                                                value={weekDay.value}
                                                onClick={() => {
                                                    // get the selected days
                                                    const selectedDaysCopy = selectedDays?.length > 0 ? [...selectedDays] : [];
                                                    if (!selectedDaysCopy.find(selectedDay => selectedDay === weekDay.value)) {
                                                        selectedDaysCopy.push(weekDay.value);
                                                    } else {
                                                        selectedDaysCopy.splice(selectedDaysCopy.indexOf(weekDay.value), 1);
                                                    }
                                                    setSelectedDays(selectedDaysCopy);
                                                }}
                                                className={'group-name'}
                                                component={'div'}
                                                label={gettext(weekDay.value).charAt(0).toUpperCase()}
                                            />
                                        ))}
                                        {selectedDays?.length === 7 && <span className={'all-days-selected-text-star'}>*</span>}
                                    </div>
                                    {selectedDays?.length === 7 && <span className={'all-days-selected-text'}>{`* ${t('common:all_days_selected')}`}</span>}
                                </div>
                            </div>
                        </div>
                        <div className={'configuration-page-explanation-content explanation-has-multi-lines'}>
                            <div className={'configuration-page-explanation-text'}>
                                <p>{t('workspace:device_alerts_conditions_question2')}</p>
                            </div>
                            <div className={'configuration-page-explanation-fields'}>
                                <RadioGroup
                                    row
                                    value={radioButtonValue}
                                    onChange={e => handleChangeRadioButton(e.target.value)}
                                >
                                    <FormControlLabel
                                        className={radioButtonValue !== '24/24' && 'is-not-checked'}
                                        value="24/24"
                                        control={<Radio />}
                                        label={t('common:24/7')}
                                    />
                                    <FormControlLabel
                                        className={classnames('config-recurrence-fields', { 'is-not-checked': radioButtonValue !== 'custom_time_slot' })}
                                        value="custom_time_slot"
                                        control={<Radio />}
                                        label={(
                                            <div className={'recurrence-edition'}>
                                                <span>{t('common:custom_hours')}</span>
                                                <div className={'time-choice'}>
                                                    <span className={'time-choice-text'}>{t('common:from_second')}</span>
                                                    <Component
                                                        componentName={'EditableField'}
                                                        renderValue={'Input'}
                                                        title={''}
                                                        required
                                                        props={{
                                                            disabled: radioButtonValue !== 'custom_time_slot',
                                                            value: hourStart,
                                                            changeInputValue: hourStart,
                                                            emptyInput: radioButtonValue !== 'custom_time_slot' ? true : undefined,
                                                            type: 'time',
                                                            onChange: value => { setHourStart(value); }
                                                        }}
                                                    />
                                                    <span className={'time-choice-text'}>{` ${t('common:to_second')}`}</span>
                                                    <Component
                                                        componentName={'EditableField'}
                                                        renderValue={'Input'}
                                                        title={''}
                                                        required
                                                        props={{
                                                            disabled: radioButtonValue !== 'custom_time_slot',
                                                            value: hourEnd,
                                                            changeInputValue: hourEnd,
                                                            emptyInput: radioButtonValue !== 'custom_time_slot' ? true : undefined,
                                                            type: 'time',
                                                            onChange: value => { setHourEnd(value); }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                        <div className={'configuration-page-explanation'}>
                            <span role={'img'} aria-label={'clock-emoji'}>{'🕑 '}</span>
                            <span className={recapSentenceAndStyle[0]}>
                                {recapSentenceAndStyle[1]}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DevicesAlertsTimeSlot;

import React from 'react';
import Button from '@material-ui/core/Button';

const InformationBanner = ({
    icon, primaryLabel, secondaryLabel, buttonLabel, onClick, marginBottom
}) => (
    <div className={'information-banner'} style={{ marginBottom: marginBottom }}>
        <div className={'information-banner-left-part'}>
            <div className={'primary-text-with-icon-container'}>
                {icon}
                <span className={'information-banner-primary-text'}>{primaryLabel}</span>
            </div>
            <span className={'information-banner-secondary-text'}>{secondaryLabel}</span>
        </div>
        {
            buttonLabel && onClick && (
                <Button
                    className={'information-banner-button'}
                    onClick={onClick}
                >
                    <span>{buttonLabel}</span>
                </Button>
            )
        }
    </div>
);

export default InformationBanner;
// Libs
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
// Context
import { useComponentsPool } from '../../../ComponentsPool';

export default props => {
    const ticketInDetailsPopup = useSelector(state => state.tickets.ticketShownInDetailsPopup);
    const assigners = props.assigners ? props.assigners : ticketInDetailsPopup.assigners;
    const users = useSelector(state => state.users.list);
    const { Component } = useComponentsPool();

    return (
        <Component
            componentName={'UserAvatarsGrouped'}
            usersByGroups={assigners?.map(user => find(users, u => u?.id === user))}
            shortenText={props.shortenText}
        />
    );
};

// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';
// Actions
import { archiveMultipleRounds, archiveRound } from '../../actions';

const ArchiveRoundFootbarButton = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const footbar = useFootBar();
    const { Component } = useComponentsPool();
    const [list, setList] = useState(footbar.getList());
    const currentUser = useSelector(state => state.users.currentUser);
    const isOwner = currentUser.workspace_role?.workspace_role_name === 'Owner';

    const onArchiveClick = () => {
        const selectedRoundIDs = footbar.getList().map((round) => round.id);
        if (selectedRoundIDs.length === 1) {
            dispatch(archiveRound(selectedRoundIDs[0]));
        } else if (selectedRoundIDs.length > 1) {
            dispatch(archiveMultipleRounds(selectedRoundIDs));
        }
        footbar.close();
    };

    const archiveButtonDisplayCondition =
        currentUser.workspace_rights.includes('DELETE:Round') &&
        (list.length === 1 || (list.length > 1 && isOwner)) && // Only OWNER can archive multiple rounds / Others can archive only one round at a time
        list.every((round) => round.active && round.last_instance); // Check if all rounds are archivable

    const archiveButtonText = list.length === 1 ? t('rounds:archive_round') : t('rounds:archive_rounds');
    const archiveButtonPopoverText =
        list.length === 1 ? t('rounds:archive_round_confirmation') : t('rounds:archive_rounds_confirmation');

    useEffect(() => {
        setList(footbar.getList());
    }, [footbar.getList()]);

    return (
        <Component
            componentName={'FootbarButton'}
            withConfirmationPopover
            displayCondition={archiveButtonDisplayCondition}
            buttonText={archiveButtonText}
            popoverText={archiveButtonPopoverText}
            callbackAction={onArchiveClick}
            buttonIcon={'archive'}
            key={'archive'}
            confirmationButtonLabel={t('common:yes_archive')}
            cancelButtonLabel={t('common:do_not_archive')}
        />
    );
};

export default ArchiveRoundFootbarButton;

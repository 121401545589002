// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { getGroupNameFromId } from '../helper';

const GroupString = props => {
    const groupList = useSelector(state => state.groups.list);
    const groupName = getGroupNameFromId(groupList, props.value);
    return (
        <p className={'info-value'}>
            { groupName }
        </p>
    );
};

export default GroupString;

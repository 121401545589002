// Lib
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Context
import Switch from '@material-ui/core/Switch';
import { useComponentsPool } from '../../../../ComponentsPool';
import { isPassageDeviceType } from '../../../devices/helper';

const UsersRights = props => {
    const { t } = useTranslation();
    const { Component } = useComponentsPool();
    const devices = useSelector(state => state.devices.list);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const [visibleTicketsOptions] = useState([{ id: 'ALL', value: t('workspace:visible_tickets_for_users_all_option') }, { id: 'CREATED', value: t('workspace:visible_tickets_for_users_created_option') }]);
    const skillsList = useSelector(state => state.groups.list)?.filter(group => group?.custom_field?.type === 'SKILL')?.map(group => group?.group_name);
    const initialConfig = {
        show_user_calendar_view: !!workspaceSettings.show_user_calendar_view,
        user_skills_hidden: workspaceSettings.user_skills_hidden?.split(',').map(skillToHide => skillToHide.trim()).filter(skillToHide => skillsList.includes(skillToHide)),
        user_tickets_mode: visibleTicketsOptions.find(e => e.id === workspaceSettings.user_tickets_mode) || visibleTicketsOptions.find(e => e.id === 'CREATED')
    };
    const [modifiedConfig, setModifiedConfig] = useState(initialConfig);

    useEffect(() => {
        if (props.cancelModifs) {
            setModifiedConfig(initialConfig);
            props.setCancelModfis(false);
        }
    }, [props.cancelModifs]);

    useEffect(() => {
        const configToModify = [];
        Object.entries(modifiedConfig).forEach(config => {
            const initialConfigValue = initialConfig[config[0]];
            if (config[0] === 'user_tickets_mode') {
                if (config[1]?.id !== initialConfigValue.id) {
                    configToModify.push({
                        configName: config[0],
                        configValue: config[1].id
                    });
                }
            } else if (config[0] === 'user_skills_hidden') {
                if (JSON.stringify(config[1]?.sort()) !== JSON.stringify(initialConfig.user_skills_hidden?.sort())) {
                    configToModify.push({
                        configName: config[0],
                        configValue: config[1].toString()
                    });
                }
            } else if (config[1] !== initialConfigValue) {
                configToModify.push({
                    configName: config[0],
                    configValue: config[1]
                });
            }
        });
        props.modifyCurrentConfig(configToModify);
    }, [modifiedConfig]);

    const chipRenderLimit = window.screen.width <= 1280 ? 3 : 4;
    return (
        <>
            <div className={'configuration-page-top-content'}>
                <Component componentName={'Icon'} type={'userConfig'} />
                <div className={'configuration-page-title'}><h3>{t('workspace:configure_users_rights')}</h3></div>
            </div>
            <div className={'configuration-page-text'}>
                <div className={'configuration-page-section-wrapper'}>
                    <div className={'configuration-page-section-title'}>{t('tickets:tickets')}</div>
                    <div className={'configuration-page-explanation-wrapper first-section-explanation'}>
                        <div className={'configuration-page-explanation-title'}>{t('workspace:visible_tickets')}</div>
                        <div className={'configuration-page-explanation-content'}>
                            <div className={'configuration-page-explanation-text'}>
                                <p>{t('workspace:visible_tickets_for_users_explanation')}</p>
                            </div>
                            <div className={'configuration-page-explanation-fields select-visible-tickets selected-option-text'}>
                                <Component
                                    componentName={'EditableField'}
                                    renderValue={'Select'}
                                    required
                                    props={{
                                        required: true,
                                        options: visibleTicketsOptions,
                                        selected: modifiedConfig.user_tickets_mode,
                                        changeSelectValue: modifiedConfig.user_tickets_mode,
                                        onChange: value => setModifiedConfig({ ...modifiedConfig, user_tickets_mode: value })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'configuration-page-explanation-wrapper last-section-explanation'}>
                        <div className={'configuration-page-explanation-title'}>{t('workspace:categories_to_hide')}</div>
                        <div className={'configuration-page-explanation-column-content'}>
                            <div className={'configuration-page-explanation-text'}>
                                <p>{t('workspace:visible_skills_for_users_explanation')}</p>
                            </div>
                            <div className={`chip-render-limit-${chipRenderLimit} configuration-page-explanation-fields select-multiple-chips selected-option-text`}>
                                <Component
                                    componentName={'EditableField'}
                                    renderValue={'Select'}
                                    props={{
                                        renderValue: 'chip',
                                        renderChipsWithIcons: true,
                                        multiple: true,
                                        chipRenderLimit,
                                        options: skillsList?.map((skillName, idx) => ({ id: idx, value: skillName })),
                                        selected: modifiedConfig.user_skills_hidden?.map(skillName => ({ id: skillsList.indexOf(skillName), value: skillName })),
                                        changeSelectValue: modifiedConfig.user_skills_hidden?.map(skillName => ({ id: skillsList.indexOf(skillName), value: skillName })),
                                        onChange: values => { setModifiedConfig({ ...modifiedConfig, user_skills_hidden: values?.map(v => v.value) }); },
                                        showSelectAll: true
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {devices.find(device => isPassageDeviceType(device)) && (
                    <div className={'configuration-page-section-wrapper'}>
                        <div className={'configuration-page-section-title'}>{t('rounds:planification')}</div>
                        <div className={'configuration-page-explanation-wrapper'}>
                            <div className={'configuration-page-explanation-title'}>{t('workspace:access')}</div>
                            <div className={'configuration-page-explanation-content'}>
                                <div className={'configuration-page-explanation-text'}>
                                    <p>{t('workspace:show_round_view_to_role_explanation', { roleName: t('search:user') })}</p>
                                </div>
                                <div className={'configuration-page-explanation-fields'}>
                                    <span>{t('common:do_not_give_access')}</span>
                                    <Switch
                                        checked={modifiedConfig.show_user_calendar_view}
                                        onChange={e => setModifiedConfig({ ...modifiedConfig, show_user_calendar_view: e.target.checked })}
                                    />
                                    <span className={modifiedConfig.show_user_calendar_view && 'selected-option-text'}>{t('common:give_access')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default UsersRights;

// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';
import { useCryptr } from '@cryptr/cryptr-react';
import classNames from 'classnames';
import { Workbox } from 'workbox-window';
// Context
import { useModal } from '../../modal/ModalContext';
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import { logout } from '../../authentication/actions';
import { setWorkspaceSettingsPanel } from '../actions';
// helper
import { getUserName, isOwnerOrAdmin } from '../../users/helper';
import Icon from '../../layout/components/Icon';
import { capitalize } from '../../core/helper';
import { callEndpointsForOfflineModeOnMobile, sendMessageToServiceWorker } from '../../core/offlineHelpers';
import { setIsLoggable } from '../../users/actions';
import { isQRCodePassageDeviceType } from '../../devices/helper';
import { notification } from '../../notification/actions';


const ProfilePicture = () => {
    const { Component } = useComponentsPool();
    const modal = useModal();
    const history = useHistory();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const currentUser = useSelector(state => state.users.currentUser);
    const isLogged = useSelector(state => state.authentication.isLogged);
    const devices = useSelector(state => state.devices.list);
    const [userName, setUserName] = useState(() => getUserName(currentUser));
    const [profileMenuDisplay, setProfileMenuDisplay] = useState(false);
    const { logOut } = useCryptr();
    const userWorkspacesList = useSelector(state => state.workspace.userWorkspacesList);
    const [showOfflineSynchroButton, setShowOfflineSynchroButton] = useState(false);
    const [showReloadCache, setShowReloadCache] = useState(false);

    const editMenu = modalName => {
        setProfileMenuDisplay(false);
        modal.open(modalName);
    };

    useEffect(() => {
        setUserName(getUserName(currentUser));
    }, [currentUser]);

    useEffect(() => {
        setShowOfflineSynchroButton(
            isMobile
            && isLogged
            && devices.some(device => isQRCodePassageDeviceType(device))
            && 'serviceWorker' in navigator && navigator.serviceWorker.controller
        );
        setShowReloadCache('serviceWorker' in navigator && navigator.serviceWorker.controller);
    }, []);

    const canHaveMenuSeparator = isOwnerOrAdmin(currentUser) && !isMobile;
    const getLogoutButton = () => {
        return (
            <>
                {canHaveMenuSeparator && <hr />}
                <div
                    role={'button'}
                    tabIndex={-2}
                    className="logout-button"
                    onClick={() => {
                        if (!isEmpty(currentUser.social_id)) {
                            const workspaceUrl = localStorage.getItem('iot_rocket_workspace')
                                ? `https://${localStorage.getItem('iot_rocket_workspace')}.app.merciyanis.com`
                                : null;
                            logOut(workspaceUrl);
                        }
                        dispatch(logout(false, false));
                    }}
                >
                    <Icon type={'logout'} />
                    <span>{t('common:log_out')}</span>
                </div>
            </>

        );
    };
    return (
        <div
            className={`profile-menu-wrapper ${profileMenuDisplay ? 'displayed' : ''}`}
            onMouseOver={() => setProfileMenuDisplay(true)}
            onFocus={() => {}}
            onMouseOut={() => setProfileMenuDisplay(false)}
            onBlur={() => setProfileMenuDisplay(false)}
        >
            {!isLogged && (
                <div role={'button'} tabIndex={-2} className="login-button qrcode-form-login" onClick={() => dispatch(setIsLoggable(true))}>
                    {t('common:login')}
                </div>
            )}
            <div
                className={classNames({
                    'profile-menu-container': true,
                    displayed: profileMenuDisplay
                })}
            >
                <div className="profile-menu-buttons">
                    {canHaveMenuSeparator && userWorkspacesList.accounts.length > 1
                        && (
                            <>
                                <hr />
                                <div className={'profile-menu-text-separator'}>{currentUser.first_name?.toUpperCase()}</div>
                            </>
                        )
                    }
                    <div
                        role={'button'}
                        tabIndex={-1}
                        className="edit-profile-button"
                        onClick={() => {
                            editMenu('EditUserProfilePopup');
                        }}
                    >
                        <Icon type={'editProfile'} />
                        <span>{t('common:edit_my_profile')}</span>
                    </div>
                    {
                        showOfflineSynchroButton && (
                            <div
                                role={'button'}
                                tabIndex={-1}
                                className="edit-profile-button"
                                onClick={() => { callEndpointsForOfflineModeOnMobile(dispatch, t, 'NetworkFirst'); }}
                            >
                                <Icon type={'faArrowsRotate'} />
                                <span>{t('common:synchronize_offline_passage_qrcodes')}</span>
                            </div>
                        )
                    }
                    {
                        showReloadCache && (
                            <div
                                role={'button'}
                                tabIndex={-1}
                                className="edit-profile-button"
                                onClick={() => {
                                    const wb = new Workbox('/service-worker.js');
                                    // updateViaCache ensures that the browser always checks for the latest version of the serviceworker
                                    wb.register({ updateViaCache: 'none' }).then(() => {
                                        // Send message to Service Worker
                                        sendMessageToServiceWorker(wb,{
                                            action: 'RELOAD_CLIENT_SERVICEWORKER'
                                        }).then(result => {
                                            // If sucess reload page
                                            if (result.status === 'success') {
                                                window.location.reload();
                                            } else {
                                                dispatch(notification(t('notifications:reload_client_serviceworker_error'), 'error', 'frown'));
                                            }
                                        });
                                    });

                                }}
                            >
                                <Icon type={'faArrowsRotate'} />
                                <span>{t('common:reload_client_serviceworker')}</span>
                            </div>
                        )
                    }
                    {
                        userWorkspacesList.accounts.length > 1 && (
                            <div
                                role={'button'}
                                tabIndex={-1}
                                className="edit-profile-button"
                                onClick={() => {
                                    window.location.href = window.location.href.replace(history.location.pathname, '/workspaces');
                                }}
                            >
                                <Icon type={'accessWorkspaces'} />
                                <span>{t('common:access_workspaces')}</span>
                            </div>
                        )
                    }
                    {canHaveMenuSeparator && (
                        <>
                            <hr />
                            <div className={'profile-menu-text-separator'}>{workspaceSettings.name.toUpperCase()}</div>
                        </>
                    )}
                    {!isMobile && isOwnerOrAdmin(currentUser) && (
                        <div
                            role={'button'}
                            tabIndex={-3}
                            className="edit-profile-button"
                            onClick={() => editMenu('WorkspaceConfigurationMenu')}
                        >
                            <Icon type={'cog'} />
                            <span>{t('workspace:configure_workspace')}</span>
                        </div>
                    )}
                    {!isMobile && currentUser.workspace_role?.workspace_role_name === 'Owner' && (
                        <div
                            role={'button'}
                            tabIndex={-3}
                            className="edit-profile-button"
                            onClick={() => dispatch(setWorkspaceSettingsPanel(true))}
                        >
                            <Icon type={'faSlidersH'} />
                            <span>{t('common:edit_workspace')}</span>
                        </div>
                    )}
                    {(
                        <a href={'https://help.merciyanis.com/'} target={'_blank'} rel={'noopener noreferrer'}>
                            <div
                                role={'button'}
                                tabIndex={-4}
                                className="edit-profile-button"
                            >
                                <Icon type={'bookOpen'} />
                                <span>{t('workspace:help_center')}</span>
                                <Icon type={'shortcutOpener'} />

                            </div>
                        </a>
                    )}
                    {isLogged && getLogoutButton()}
                </div>
            </div>
            {isLogged && (
                <div className={`logo-no-text-wrapper ${profileMenuDisplay ? 'displayed' : ''}`}>
                    {!isMobile
                        ? <img className={'company-logo'} src={workspaceSettings.logo_no_text_url} alt={'Company logo'} />
                        : (
                            <img
                                className={'company-logo'}
                                src={'https://res.cloudinary.com/iotv-keepcontact/image/upload/v1528904820/iot-rocket/user.png'}
                                alt={'Company logo'}
                            />
                        )
                    }
                    <Component
                        componentName={'UserAvatar'}
                        user={currentUser}
                        picture={currentUser?.profile_picture_url}
                        size="34"
                    />
                </div>
            )}
            {
                !isMobile
                && (
                    <div className={`user-info ${profileMenuDisplay ? 'hovered' : ''}`}>
                        <div className={'workspace-wrapper'}>
                            <h2>{capitalize(workspaceSettings.name)}</h2>
                            <Icon type={'chevronBottom'} />
                        </div>
                        <p>{userName}</p>
                    </div>
                )
            }
        </div>
    );
};

export default ProfilePicture;

// Libs
import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
// Components
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Helper
import { getDeviceFromId, getDevicesOfGroup } from '../../devices/helper';
import { useModal } from '../../modal/ModalContext';

const GroupedItems = props => {
    const { Component } = useComponentsPool();
    const modal = useModal();
    const devices = useSelector(state => state.devices.list);
    const maxDisplayableItems = useSelector(state => state.groups.itemDisplayedOnChipList);
    const groups = useSelector(state => state.groups.list);
    const [groupId] = useState(props.group_id || modal.getItem().group_id);
    const [itemList, setItemList] = useState(() => getDevicesOfGroup(devices, groups, groupId));
    const displayedDevice = getDeviceFromId(devices, itemList[0]);

    useEffect(() => {
        setItemList(() => getDevicesOfGroup(devices, groups, groupId));
    }, [devices]);

    return (
        <div className={'button-wrapper'}>
            { itemList.length > 0 && (
                <Tooltip placement={'top'} title={displayedDevice.device_name || displayedDevice.device_reference}>
                    <Chip
                        className={'group-name'}
                        icon={<Component componentName={'Icon'} type={'device'} />}
                        label={displayedDevice.device_name || displayedDevice.device_reference}
                    />
                </Tooltip>
            )}
            { itemList.length > maxDisplayableItems && (
                <Component componentName={'Popover'} buttonLabel={`+${itemList.length - maxDisplayableItems}`}>
                    <div className={'user-group-popover'}>
                        { map(itemList.slice(1, itemList.length), device => {
                            const currentDevice = getDeviceFromId(devices, device);
                            return (
                                <Tooltip
                                    placement={'top'}
                                    key={device}
                                    title={currentDevice.device_name || currentDevice.device_name.device_reference}
                                >
                                    <Chip
                                        icon={<Component componentName={'Icon'} type={'device'} />}
                                        label={currentDevice.device_name || currentDevice.device_reference}
                                    />
                                </Tooltip>
                            );
                        })}
                    </div>
                </Component>
            )}
        </div>
    );
};

export default GroupedItems;

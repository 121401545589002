// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';
// Actions
import { deleteDevices } from '../../actions';

export default () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const footbar = useFootBar();
    const { Component } = useComponentsPool();
    const [list, setList] = useState(footbar.getList());

    useEffect(() => {
        setList(footbar.getList());
    }, [footbar.getList()]);

    const removeDevices = () => {
        dispatch(deleteDevices({ device_ids: footbar.getList().map(device => device.device_id) }));
        footbar.close();
    };

    return (
        <Component
            componentName={'FootbarButton'}
            withConfirmationPopover
            displayCondition
            buttonText={list.length > 1 ? t('devices:delete_devices') : t('devices:delete_device')}
            popoverText={list.length > 1 ? t('devices:delete_devices_confirmation') : t('devices:delete_device_confirmation')}
            callbackAction={removeDevices}
            buttonIcon={'trash'}
            key={'trash'}
        />
    );
};

// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Scss
import '../scss/googleConnect.scss';

const GoogleConnect = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const showGoogleConnect = useSelector(state => state.workspace.settings.show_google_connect);

    const logInWithGoogle = e => {
        e.preventDefault();
        try {
            const lsWorkspace = localStorage.getItem('iot_rocket_workspace');
            const lsApiUrl = localStorage.getItem('iot_rocket_api_url');
            const generalApiUrl = lsApiUrl.replace(/^[^.]+\./g, '');
            window.location.href = [
                window.location.protocol,
                '//',
                generalApiUrl,
                '/auth/oauth/login/google-oauth2/?workspace=',
                lsWorkspace,
                '&action=login'
            ].join('');
        } catch {
            throw new Error('Can not get workspace / api url from localstorage');
        }
    };

    const registerWithGoogle = e => {
        e.preventDefault();
        try {
            const lsWorkspace = localStorage.getItem('iot_rocket_workspace');
            const lsApiUrl = localStorage.getItem('iot_rocket_api_url');
            const generalApiUrl = lsApiUrl.replace(/^[^.]+\./g, '');
            window.location.href = [
                window.location.protocol,
                '//',
                generalApiUrl,
                '/auth/oauth/login/google-oauth2/?workspace=',
                lsWorkspace,
                '&action=register&token=',
                window.location.href.split('?token=')[1]
            ].join('');
        } catch (err) {
            throw new Error(err);
        }
    };

    return showGoogleConnect ? (
        <div className={'google-connect'}>
            <div className="separator">{t('common:or')}</div>
            <Component
                componentName={'Button'}
                onClick={props.login ? logInWithGoogle : registerWithGoogle}
                startAdornment
                icon={'google'}
                text={props.login ? t('common:log_in_with_google') : t('common:sign_up')}
            />
        </div>
    ) : '';
};

export default GoogleConnect;

// Libs
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
// Context
import { useTranslation } from 'react-i18next';
import {cloneDeep, every } from 'lodash';
import { useComponentsPool } from '../../../../ComponentsPool';
import { useModal } from '../../../modal/ModalContext';
import { useFootBar } from '../../../footbar/FootBarContext';
// Actions
import { editRound } from '../../actions';
import { getStringParamValueFromRrule, traduceRRule } from '../../helper';
import { resetFilters } from '../../../navbar/actions';

const RoundActionBar = () => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const currentUserRole = useSelector(state => state.users.currentUser.workspace_role?.workspace_role_name);
    const round = useSelector(state => state.rounds.roundShownInDetailsPopup);
    const rounds = useSelector(state => state.rounds.list);
    const footBar = useFootBar();
    const modal = useModal();
    const list = modal.getWithFootBar() ? [modal.getItem()] : footBar.getList();

    const switchButtonCallback = () => {
        const roundCopy = cloneDeep(round);

        // Put every round's param to the right format
        delete Object.assign(roundCopy, { devices: roundCopy.round_devices.map(device => ({ id: device.device_id, validation_count: round.round_devices_max_validation_count })) }).round_devices;
        delete Object.assign(roundCopy, { followers_ids: roundCopy.round_followers.map(follower => follower.id) }).round_followers;
        delete Object.assign(roundCopy, { invite_followers_ids: roundCopy.round_invite_followers.map(follower => follower.id) }).round_invite_followers;
        delete Object.assign(roundCopy, { assignees_ids: roundCopy.round_assignees.map(assignee => assignee.id) }).round_assignees;

        // Variable for editRound segment
        const startedAt = moment(footBar.getEditionObject().hasOwnProperty('started_at') ? footBar.getEditionObject().started_at : round.started_at);
        const endedAt = moment(footBar.getEditionObject().hasOwnProperty('ended_at') ? footBar.getEditionObject().ended_at : round.ended_at);
        const rrule = footBar.getEditionObject().hasOwnProperty('rrule') ? footBar.getEditionObject().rrule : round.rrule;
        const isUntilEndOfMonth = footBar.getEditionObject().hasOwnProperty('is_until_end_of_month') ? footBar.getEditionObject().is_until_end_of_month : round.is_until_end_of_month;
        const isMoreThanOrEqualTo24Hours = endedAt.diff(startedAt, 'hours', true) >= 24;
        const isLessThanAMonth = endedAt.diff(startedAt, 'months', true) < 1;
        const isNotUntilEndOfMonthOption = isMoreThanOrEqualTo24Hours && isLessThanAMonth
            && endedAt.date() === endedAt.daysInMonth() && endedAt.date() !== 31
            && !isUntilEndOfMonth && getStringParamValueFromRrule(rrule?.replace('RRULE:', ''), 'FREQ=') === 'MONTHLY';

        return dispatch(editRound(roundCopy.id, footBar.getEditionObject(), roundCopy, currentUserRole, isNotUntilEndOfMonthOption))
            .then(res => {
                dispatch(resetFilters());
                traduceRRule(t, res.value.rrule, res.value.name, res.value.started_at, res.value.ended_at, footBar.getEditionObject().is_until_end_of_month, true)
            });
    };

    const footbarButtons = useMemo(() => {
        const areAllRoundsArchivableOrDeletable =
            every(list, (round) => round.active && !round.last_instance) ||
            every(list, (round) => round.active && round.last_instance);

        const roundsWithDifferentStatusWarningText =
            list.length === 0 ? null : !areAllRoundsArchivableOrDeletable ? (
                <span key={'error_action_button'} className={'error_action_button'}>
                    {t('rounds:rounds_selection_with_different_status_warning')}
                </span>
            ) : null;

        return [
            <Component key={'round-archive-button'} componentName={'ArchiveRoundFootbarButton'} list={list} />,
            <Component key={'round-delete-button'} componentName={'DeleteRoundFootbarButton'} list={list} />,
            roundsWithDifferentStatusWarningText
        ];
    }, [list]);

    return (
        <Component
            componentName={'Footbar'}
            modal={'RoundDetailsPopup'}
            resourceList={rounds}
            actionButtons={footbarButtons}
            switchButtonCallback={() => switchButtonCallback()}
            resourceType={'Round'}
            locale={'rounds'}
        />
    );
};

export default RoundActionBar;

// Libs
import React from 'react';
// Context
import { useComponentsPool } from '../../../ComponentsPool';

export default props => {
    const { Component } = useComponentsPool();

    return (
        <>
            <Component
                componentName={'GroupSelector'}
                required={props.required}
                multiple={props.multiple}
                showSelectAll
                isSelectedToText
                onChange={value => props.onGroupSelectionChange(value)}
            />
            <Component
                componentName={'RoleSelector'}
                required={props.required}
                onChange={role => props.onChangeRole(role)}
            />
        </>
    );
};

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';

const RoundFollowersAvatar = props => {
    const roundShownInDetailsPopup = useSelector(state => state.rounds.roundShownInDetailsPopup);
    const round_followers = props.resource?.round_followers
        ? props.resource.round_followers?.concat(props.resource.round_invite_followers)
        : roundShownInDetailsPopup.round_followers?.concat(roundShownInDetailsPopup.round_invite_followers);
    const users = useSelector(state => state.users.list);
    const { Component } = useComponentsPool();

    return (
        <Component
            componentName={'FollowersAvatarsGrouped'}
            displayEmoji={'✉️'}
            usersByGroups={round_followers.filter(follower => find(users, user => user.id === follower.id))}
            shortenText
        />
    );
};

export default RoundFollowersAvatar;

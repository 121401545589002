// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default props => {
    const [t] = useTranslation();

    const devices = useSelector(state => state.devices);
    const groups = useSelector(state => state.groups.list);

    const device = devices.deviceShownInDetailsPopup;
    const custom_field = props.value[1];
    const group_id = device.custom_field[custom_field];
    const group = groups.find(g => g.group_id === group_id);

    return (
        <p className={`info-value ${custom_field}`}>
            {group ? group.group_name : t('common:undefined')}
        </p>
    );
};


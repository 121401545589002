// Libs
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    concat, difference, remove, sortBy
} from 'lodash';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
import { useModal } from '../../modal/ModalContext';
import { useFootBar } from '../../footbar/FootBarContext';
import { useFormState } from '../../layout/forms/api/useFormState';
// Actions
import { editGroup } from '../actions';
import { editDeviceLocally } from '../../devices/actions';
// Helper
import { getDeviceFromId, getDeviceIdsByGroupId } from '../../devices/helper';

const getDevicesDeleted = (devices, groupId, currentDevices) => {
    const defaultDevices = getDeviceIdsByGroupId(devices, groupId);
    return difference(defaultDevices, currentDevices);
};

const GroupActionBar = () => {
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const footBar = useFootBar();
    const modal = useModal();
    const { formState } = useFormState();
    const userWorkspaceRights = useSelector(state => state.users.currentUser.workspace_rights);
    const groups = useSelector(state => state.groups.list);
    const devices = useSelector(state => state.devices.list);
    const buttons = useSelector(state => sortBy(state.groups.footbarButtons, 'weight'));
    const list = modal.getWithFootBar() ? [modal.getItem()] : footBar.getList();

    const updateGroup = () => {
        const update = {
            ...formState,
            device_ids: formState.devices.device_ids
        };
        dispatch(editGroup(update.group_id, update))
            .then(res => {
                if (res.action?.type?.includes('_FULFILLED') && res.action?.payload?.req?.devices) {
                    const payload = res.action.payload;
                    getDevicesDeleted(devices, payload.res.group_id, payload.req.devices.device_ids)?.forEach(device => {
                        const deletedDevice = getDeviceFromId(devices, device);
                        dispatch(editDeviceLocally(device, [{
                            ...deletedDevice,
                            group_ids: remove(deletedDevice.group_ids, group => group !== payload.res.group_id)
                        }]));
                    });
                    payload.req.devices.device_ids?.forEach(device => {
                        const selectedDevice = getDeviceFromId(devices, device);
                        if (!selectedDevice.group_ids.includes(payload.res.group_id)) {
                            dispatch(editDeviceLocally(device, [{
                                ...selectedDevice,
                                group_ids: concat(selectedDevice.group_ids, payload.res.group_id)
                            }]));
                        }
                    });
                }
            });
    };

    const footbarButtons = useMemo(() => buttons.map(button => (
        <Component
            key={button.component}
            componentName={button.component}
            userWorkspaceRights={userWorkspaceRights}
            list={list}
        />
    )), [buttons]);

    return (
        <Component
            componentName={'Footbar'}
            actionButtons={footbarButtons}
            modal={'GroupDetailsPopup'}
            resourceList={groups}
            switchButtonCallback={() => updateGroup()}
            resourceType={'Group'}
            locale={'groups'}
        />
    );
};

export default GroupActionBar;

// Libs
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Fab from '@material-ui/core/Fab';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import createLoadingSelector from '../../../layout/actions';
import { useComponentsPool } from '../../../../ComponentsPool';
import Icon from '../../../layout/components/Icon';
import { emptyInstanceEventsList } from '../../actions';
import { useModal } from '../../../modal/ModalContext';
import { MainContext } from '../../../layout/MainContext';
import MobileInstanceCommentsList from './MobileInstanceCommentsList';
import MobileInstancePassagesList from './MobileInstancePassagesList';
import ValidationPassageComment from '../../../passages/MobileComponents/ValidationPassageComment';
import ScrollContainerForSwipeableViews from '../../../layout/components/ScrollContainerForSwipeableViews';

const loadingSelector = createLoadingSelector(['GET_INSTANCE_EVENTS_LIST']);
const MobileRoundsInstanceDetails = () => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const modal = useModal();
    const [, setCurrentComponentOnTop] = useContext(MainContext);
    const [instanceCardDetails, setInstanceCardDetails] = useState({});
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const [displayedPageIdx, setDisplayedPageIdx] = useState(0);
    const currentInstance = useSelector(state => state.rounds.roundInstanceShownInDetailsPopup.currentInstance);
    const currentUser = useSelector(state => state.users.currentUser);
    const isCurrentUserAssignedToInstance = currentInstance?.assignees_ids?.includes(currentUser.id);
    const eventsList = useSelector(state => state.rounds.roundInstanceShownInDetailsPopup.eventsList);
    const currentInstancesListStatus = useSelector(state => state.rounds.currentInstancesListStatus);
    // Current assignees can only see the top menu on unfinished instances
    const canAccessCurrentAssigneePage = isCurrentUserAssignedToInstance && currentInstancesListStatus !== 'FINISHED';

    useEffect(() => {
        // For current user assigned to event, action button doesn't need to be higher (since there isn't a section to add comment)
        setCurrentComponentOnTop(canAccessCurrentAssigneePage && displayedPageIdx === 0 ? 'MobileInstancePassagesList' : 'MobileRoundsInstanceDetails');
    }, [displayedPageIdx, canAccessCurrentAssigneePage]);

    const useStyles = makeStyles(() => ({
        paper: {
            height: 'inherit',
            width: 'inherit',
            justifyContent: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'transparent',
            boxShadow: 'none'
        }
    }));
    const classes = useStyles();

    const displayInstancePassages = passages => {
        setInstanceCardDetails(passages);
    };

    const getCardIconFromStatus = (type, status) => {
        let iconType;
        switch (status) {
            case 'NOT_STARTED':
                iconType = type === 'assigner' ? 'UserClock' : 'StopWatchClockIcon';
                break;
            case 'COMPLETE':
                iconType = type === 'assigner' ? 'UserClipboardCheck' : 'CheckCircleRounded';
                break;
            case 'INCOMPLETE':
                iconType = type === 'assigner' ? 'UserClipboardCross' : 'ErrorRounded';
                break;
            case 'IN_PROGRESS':
            default:
                iconType = type === 'assigner' ? 'UserStopWatchIcon' : 'StopWatchIcon';
                break;
        }
        return [<Icon type={iconType} />, `${status}-status-card-${type}`];
    };

    const getCardStatus = passages => {
        let status = currentInstance.status;
        if (passages.every(passage => passage.event_detail.status === 'OK')) { // Override currentInstance status if every passage is validated
            status = 'COMPLETE';
        }
        return status;
    };

    const getInstanceDetailsAssignerOrDeviceSection = (passages, hasAssigneesSection) => {
        let cardsToDisplay;
        if (hasAssigneesSection) {
            // Group passages by assignees
            const assignees_ids = [... new Set(currentInstance.assignees_ids)] // remove id duplications
            const assigneesPassages = assignees_ids?.map(assigneeId => passages.filter(passage => passage.event_detail.agent_id === assigneeId));
            cardsToDisplay = assigneesPassages.map(assigneePassages => ({ cardPassages: assigneePassages, cardStatus: getCardStatus(assigneePassages) }));
        } else {
            // Group passages by devices
            const devicesIds = [... new Set(passages.map(passage => passage.event_detail.device_id))] // get ids and remove duplications
            const devicesPassages = devicesIds?.map(deviceId => passages.filter(passage => passage.event_detail.device_id === deviceId));
            cardsToDisplay = devicesPassages.map(devicePassages => ({ cardPassages: devicePassages, cardStatus: getCardStatus(devicePassages) }));
        }

        // Put completed instances at the end
        cardsToDisplay.sort(a => (a.cardStatus === 'COMPLETE' ? 1 : -1));

        let itemsToDisplay;
        if (hasAssigneesSection) { // Assignees cards content
            itemsToDisplay = cardsToDisplay.map(card => {
                return (
                    <div
                        className={'instance-assigner-card'}
                        onClick={() => {
                            setCurrentComponentOnTop('MobileInstancePassagesList');
                            displayInstancePassages({
                                title: card.cardPassages[0]?.event_detail?.agent_name,
                                passages: card.cardPassages
                            });
                        }}
                    >
                        <div className={`instance-assigner-card-status ${getCardIconFromStatus('assigner', card.cardStatus)[1]}`}>
                            {getCardIconFromStatus('assigner', card.cardStatus)[0]}
                        </div>
                        <div className={'instance-assigner-card-name'}>
                            <div className={'instance-assigner-card-name-text'}>{card.cardPassages[0]?.event_detail?.agent_name}</div>
                        </div>
                    </div>
                );
            });
        } else { // Passage devices cards content
            itemsToDisplay = cardsToDisplay.map(card => {
                return (
                    <div
                        className={'instance-device-card'}
                        onClick={() => {
                            setCurrentComponentOnTop('MobileInstancePassagesList');
                            displayInstancePassages({
                                title: card.cardPassages[0]?.event_detail?.device_name,
                                passages: card.cardPassages
                            });
                        }}
                    >
                        <div className={`instance-device-card-status ${getCardIconFromStatus('device', card.cardStatus)[1]}`}>
                            {getCardIconFromStatus('device', card.cardStatus)[0]}
                        </div>
                        <div className={'instance-device-locations-wrapper'}>
                            <div className={'instance-device-building'}>{card.cardPassages[0]?.event_detail.building_name}</div>
                            <div className={'instance-device-floor'}>{card.cardPassages[0]?.event_detail.floor_name}</div>
                            <div className={'instance-device-position'}>{card.cardPassages[0]?.event_detail.position_name}</div>
                        </div>
                    </div>
                );
            });
        }
        return <ScrollContainerForSwipeableViews items={itemsToDisplay} />;
    };

    const getInstanceDetailsPages = () => {
        const hasAssigneesSection = currentInstance.assignees_ids?.length > 0;
        const currentAssigneePassages = eventsList.filter(event => event.event_type === 'PASSAGE' && event.event_detail.agent_id === currentUser.id);
        const passages = eventsList.filter(event => event.event_type === 'PASSAGE');
        const comments = eventsList.filter(event => event.event_type === 'COMMENT');
        const pages = [
            <div className={'round-instance-summary-page-wrapper'}>
                <div className={canAccessCurrentAssigneePage ? 'round-instance-current-assigner-page-wrapper' : 'round-instance-page-wrapper'}>
                    {canAccessCurrentAssigneePage && (
                        <Tooltip placement={'top'} title={currentInstance?.round_name}>
                            <div className={'round-instance-summary-round-title'}><span>{currentInstance?.round_name}</span>
                            </div>
                        </Tooltip>
                    )}
                    <div className={canAccessCurrentAssigneePage ? 'round-instance-current-assigner-page-main-content' : 'round-instance-page-main-content'}>
                        <div className={'round-instance-summary-first-title'}>{t(`rounds:${hasAssigneesSection ? 'people_required_to_validate' : 'events_passages'}`)}</div>
                        {getInstanceDetailsAssignerOrDeviceSection(passages, hasAssigneesSection)}
                        <div className={'round-instance-summary-second-title'}>{t('common:comments')}</div>
                        <MobileInstanceCommentsList comments={comments} />
                    </div>
                </div>
                <div className={'round-instance-add-comment-wrapper'}>
                    <ValidationPassageComment
                        sendingType={'InstanceComment'}
                        singleLine
                        loadingSelectorList={['']}
                        rows={2}
                        style={{ backgroundColor: '#f3f3f3' }}
                        resourceId={currentInstance?.id}
                        roundId={currentInstance?.round}
                        enabledEnter
                    />
                </div>
            </div>
        ];
        if (canAccessCurrentAssigneePage) {
            const curentAssignerPage = (
                <div className={'round-instance-summary-page-wrapper'}>
                    {!currentAssigneePassages || currentAssigneePassages.length === 0
                        ? <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
                        : (
                            <div className={'round-instance-current-assigner-page-wrapper'}>
                                <Tooltip placement={'top'} title={currentInstance?.round_name}>
                                    <div className={'round-instance-summary-round-title'}><span>{currentInstance?.round_name}</span></div>
                                </Tooltip>
                                <div className={'round-instance-current-assigner-page-main-content'}>
                                    <div className={'round-instance-summary-first-title'}>{t('rounds:passage_to_make')}</div>
                                    <MobileInstancePassagesList
                                        currentAssigneeCompletedHisPassages={currentAssigneePassages.every(event => event.event_detail.status === 'OK')}
                                        // Retrieve the current assignee passages (we only display passages he did not validate)
                                        passages={currentAssigneePassages.filter(event => event.event_detail.status !== 'OK')}
                                        currentInstance={currentInstance}
                                    />
                                </div>
                            </div>
                        )}
                </div>
            );
            pages.unshift(curentAssignerPage);
        }
        return pages;
    };

    return (
        eventsList.length === 0
            ? <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
            : (
                <Component
                    contentWidth={'100%'}
                    classNames={'details-popup side-content round-instance-details-page-mobile'}
                    componentName={'Modal'}
                    loading={isLoading}
                    removeModalCloseButton
                    content={(
                        <>
                            <div className={'mobile-details-tabs'}>
                                {canAccessCurrentAssigneePage ? (
                                    <Tabs
                                        value={displayedPageIdx}
                                        onChange={(e, value) => { setDisplayedPageIdx(value); }}
                                        variant="fullWidth"
                                    >
                                        <Tab disableRipple value={0} label={t('rounds:my_validations')} />
                                        <Tab disableRipple value={1} label={t('tickets:historic')} />
                                    </Tabs>
                                ) : <div className={'details-single-tab'}>{currentInstance?.round_name}</div>}
                                <Fab
                                    className={'topbar-button-close-modal'}
                                    onClick={() => {
                                        setCurrentComponentOnTop('MobileRoundsInstancesList');
                                        dispatch(emptyInstanceEventsList()); // Prevent seeing the previously clicked instance when loading the page
                                        modal.close();
                                    }}
                                >
                                    <Icon type={'close'} />
                                </Fab>
                            </div>
                            <SwipeableViews
                                className={'round-instance-details-mobile-swipeable-views'}
                                slideStyle={{
                                    height: '100%',
                                    width: '100%',
                                    overflow: 'hidden'
                                }}
                                index={displayedPageIdx}
                                onChangeIndex={currentIndex => { setDisplayedPageIdx(currentIndex); }}
                            >
                                {getInstanceDetailsPages()}
                            </SwipeableViews>
                            {/* If user clicked on a card (assigner or device) -> display the passages related to it */}
                            {instanceCardDetails.passages?.length > 0 && (
                                <div className={'round-instance-details-mobile-wrapper'}>
                                    <Slide
                                        in={instanceCardDetails.passages?.length > 0}
                                        direction="left"
                                        timeout={{ enter: 400 }}
                                    >
                                        <Paper className={classes.paper} style={{ width: '100%' }}>
                                            <div className="round-instance-passages-page-mobile">
                                                <div className="instance-details-page-header">
                                                    <span
                                                        onClick={() => {
                                                            displayInstancePassages({});
                                                            setCurrentComponentOnTop('MobileRoundsInstanceDetails');
                                                        }}
                                                    >
                                                        <Icon type={'ArrowBackIcon'} />
                                                    </span>
                                                    <div className={'instance-details-page-header-titles-wrapper'}>
                                                        <p className={'instance-details-page-header-detail'}>{t('rounds:passage_details')}</p>
                                                        <p className={'instance-details-page-header-card-name'}>{instanceCardDetails?.title}</p>
                                                    </div>
                                                </div>
                                                <MobileInstancePassagesList
                                                    withMarginRight
                                                    passages={instanceCardDetails.passages}
                                                    currentInstance={currentInstance}
                                                />
                                            </div>
                                        </Paper>
                                    </Slide>
                                </div>
                            )}
                        </>
                    )}
                />
            )
    );
};

export default MobileRoundsInstanceDetails;

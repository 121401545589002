// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// Context
import DataButton from '../../../layout/components/DataButton';
import {getState, isDeviceInAnomaly} from '../../helper';

const DeviceData = props => {
    const [t] = useTranslation();
    const selectedData = props.resource.data ? props.resource.data : [];
    let jobs = '';
    let passage_type = '';
    const isLWPDevice = props.resource?.custom_field?.type === 'LWP';
    const getDataButtonBasedOnDataType = (data, idx) => {
        const { value, timestamp } = data.data_points[0];
        const isLwpPassage = isLWPDevice && data.data_type === 'status' && value === 'BUTTON_CLICK';
        const displayStatusChip = props.resource?.custom_field?.type !== 'SIMPLE_LEAK' && !isLWPDevice
          && !props.resource?.custom_field?.type.includes('TORK_') && (value !== 'OUVERT' || value !== 'FERME');

        if (props.resource?.custom_field?.type === 'TEMPERATURE_CONNIT' && data.data_type === 'temperature') {
            return (
                <DataButton
                    key={`connit${idx}`}
                    label={`${Number.isInteger(value) ? value : value.toFixed(1)}${data.unit}`}
                    icon={data.data_type}
                    state={isDeviceInAnomaly(props.resource)}
                    tooltip={moment(timestamp).format('DD/MM/YYYY HH[h]mm')}
                />
            );
        }
        if (data.data_type === 'press_count' && !isLWPDevice) {
            return (
                <DataButton
                    key={`press_count${idx}`}
                    label={moment(timestamp).format('DD/MM/YYYY [à] HH[h]mm')}
                    icon={data.data_type}
                    state={false}
                    tooltip={t('devices:last_click')}
                />
            );
        }
        if (data.data_type === 'battery_level' && isLWPDevice) {
            return (
                <DataButton
                    key={`press_count${idx}`}
                    label={`${value}%`}
                    icon={''}
                    state={getState(props.resource.conditions, 'LIMITE', data.data_type, value)}
                    tooltip={moment(timestamp).format('DD/MM/YYYY HH[h]mm')}
                />
            );
        }
        if (data.data_type === 'gps_location_status' && isLWPDevice && value === 'OUT_OF_AREA') {
            return (
                <DataButton
                    key={`press_count${idx}`}
                    label={t('export:gps_location_status_OUT_OF_AREA')}
                    icon={''}
                    state={true}
                    tooltip={moment(timestamp).format('DD/MM/YYYY HH[h]mm')}
                />
            );
        }
        if (isLwpPassage) {
            return (
                <DataButton
                    key={`press_count${idx}`}
                    label={moment(timestamp).format('DD/MM/YYYY [à] HH[h]mm')}
                    icon={''}
                    state={false}
                    tooltip={t('devices:last_passage')}
                />
            );
        }
        if (data.data_type === 'status' && props.resource?.custom_field?.type === 'SENSE_Dry_Contact_4_v1' && (value === 'OUVERT' || value === 'FERME' )) {
            return (
                <DataButton
                    key={`simple-leak${idx}`}
                    label={t(`devices:${value}`)}
                    icon={data.data_type}
                    state={value !== 'FERME'}
                    tooltip={`${t('devices:last_status')} le ${moment(timestamp).format('DD/MM/YYYY à HH[h]mm')}`}
                />
            );
        }
        if (data.data_type === 'status' && displayStatusChip) {
            return (
                <DataButton
                    key={`simple-leak${idx}`}
                    label={t(`devices:status_${value}`)}
                    icon={data.data_type}
                    state={value !== 'resolution' && value !== 'CLEANED' && value !== 'OCCUPIED' && value !== 'FREE'}
                    tooltip={`${t('devices:last_status')} le ${moment(timestamp).format('DD/MM/YYYY à HH[h]mm')}`}
                />
            );
        }

        if (data.data_type === 'status' && props.resource?.custom_field?.type.includes('TORK_')) {
            return (
                <DataButton
                    key={`simple-leak${idx}`}
                    label={t(`devices:status_${value}_${props.resource?.custom_field?.type}`)}
                    icon={data.data_type}
                    state={value === 'Red'}
                    tooltip={`${t('devices:last_status')} le ${moment(timestamp).format('DD/MM/YYYY à HH[h]mm')}`}
                />
            );
        }

        if (data.data_type === 'jobs' && value?.length > 0) {
            jobs = value.join(', ');
        }
        if (data.data_type === 'passage_type' && value) {
            passage_type = value;
        }
        if (data.data_type === 'agent') {
            return (
                <DataButton
                    key={`agent${idx}`}
                    label={timestamp ? moment(timestamp).format('DD/MM/YYYY [à] HH[h]mm') : '-'}
                    icon={data.data_type}
                    state={false}
                    tooltip={`${value}${jobs ? ' - ' : ''}${jobs}${passage_type ? ' - ' : ''}${t(`passages:${passage_type}`)}`}
                />
            );
        }
        if (data.data_type === 'nfc_tag') {
            return (
                <DataButton
                    key={`nfc_tag${idx}`}
                    label={timestamp ? moment(timestamp).format('DD/MM/YYYY [à] HH[h]mm') : '-'}
                    icon={data.data_type}
                    state={false}
                    tooltip={`${value}${jobs ? ' - ' : ''}${jobs}${passage_type ? ' - ' : ''}${t(`passages:${passage_type}`)}`}
                />
            );
        }
        if (data.data_type === 'temperature' || data.data_type === 'humidity' || data.data_type === 'co2') {
            return (
                <DataButton
                    key={`airwits${idx}`}
                    label={`${Number.isInteger(value) ? value : value.toFixed(1)}${data.unit}`}
                    icon={data.data_type}
                    state={getState(props.resource.conditions, 'LIMITE', data.data_type, value)}
                    tooltip={moment(timestamp).format('DD/MM/YYYY HH[h]mm')}
                />
            );
        }
        if (data.data_type === 'luminosity') {
            return (
                <DataButton
                    key={`luminosity${idx}`}
                    label={`${Number.isInteger(value) ? value : value.toFixed(1)}${data.unit}`}
                    icon={data.data_type}
                    state={isDeviceInAnomaly(props.resource)}
                    tooltip={moment(timestamp).format('DD/MM/YYYY HH[h]mm')}
                />
            );
        }
        if (props.resource?.custom_field?.counter && data.data_type === 'people') {
            const passage_counter = parseInt(props.resource.custom_field?.counter, 10);
            const translation_plurality = passage_counter > 1 || passage_counter === 0 ? 'devices:passages_counter_plural' : 'devices:passages_counter';
            return (
                <DataButton
                    key={`people${idx}`}
                    label={t(translation_plurality, { counter: passage_counter })}
                    icon={data.data_type}
                    state={getState(props.resource.conditions, 'LIMITE', data.data_type, passage_counter)}
                    tooltip={moment(timestamp).format('DD/MM/YYYY HH[h]mm')}
                />
            );
        }
        return <></>;
    };
    return (
        <div className={'device-data-container'}>
            {selectedData
                .filter(data => data.data_points[0]?.value)
                .map((data, idx) => getDataButtonBasedOnDataType(data, idx))}
        </div>
    );
};


export default DeviceData;

// Libs
import React, { useState } from 'react';
import moment from 'moment';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import {
    getTimeSlotFromStringWithDate,
    getUntilDateFromDateAndEndedAt, isOneDayTimeSlot, objectHasProperty
} from '../../../layout/helper';
import { getDayName, getStringParamValueFromRrule } from '../../helper';

const RoundRecurrentRruleAndDateFields = props => {
    const { Component } = useComponentsPool();
    const [startDayDate, setStartDayDate] = useState(moment(props.startDate).toDate());
    const [untilDayDate, setUntilDayDate] = useState(null);
    const startedAt = moment(props.started_at).format('HH[:]mm');
    const endedAt = moment(props.ended_at).format('HH[:]mm');

    // everytime we select new days or we change the recurrence
    const onRecurrenceChange = (newValue = null, chosenStartDay = null) => {
        if (newValue && objectHasProperty(newValue, 'rrule')) {
            props.changeState({ rrule: newValue?.rrule });
        } else {
            const startedAtHours = newValue?.started_at ? newValue.started_at : startedAt;
            const endedAtHours = newValue?.ended_at ? newValue.ended_at : endedAt;
            // chosenStartDay ? chosenStartDay : startDayDate
            const chosenStartDayDate = chosenStartDay || startDayDate;
            const timeSlot = getTimeSlotFromStringWithDate(startedAtHours, endedAtHours, moment(chosenStartDayDate).format());
            props.changeState(timeSlot);
            const currentUntilDateDate = getStringParamValueFromRrule(props.rrule, 'UNTIL=')
                ? moment(getStringParamValueFromRrule(props.rrule, 'UNTIL=')).format('YYYY-MM-DD')
                : null;
            if (
                // if there is an untilDate and the round was on 1 day and the startDate date is equal to the untilDate date
                currentUntilDateDate && startedAt && endedAt && isOneDayTimeSlot(startedAt, endedAt)
                && moment(startDayDate).format('YYYY-MM-DD') === currentUntilDateDate
            ) {
                // if we changed the started_at field we take the new value else we take the old one
                const currentStartedAt = newValue?.started_at ? moment(timeSlot.started_at).format('HH[:]mm') : startedAt;
                // if we changed the ended_at field we take the new value else we take the old one
                const currentEndedAt = newValue?.ended_at ? moment(timeSlot.ended_at).format('HH[:]mm') : endedAt;
                // if the round will be on 2 days the until field need to be reset since the user cannot choose the same date for the startDate and untilDate
                if (!isOneDayTimeSlot(currentStartedAt, currentEndedAt)) {
                    setUntilDayDate(null);
                }
            }
        }
    };

    const onDayDateChange = (selectedDayDate, fieldName) => {
        if (fieldName === 'startDate') { setStartDayDate(selectedDayDate); }
        if (fieldName === 'untilDate') {
            setUntilDayDate(!selectedDayDate
                ? null
                : getUntilDateFromDateAndEndedAt(selectedDayDate, moment(props.ended_at).format('HH[:]mm'))
            );
        }
        // we will only change the started at and ended at dates if we change the startDayDate
        onRecurrenceChange(null, fieldName === 'startDate' ? selectedDayDate : null);
    };

    return (
        <>
            <Component
                componentName={'RoundRecurrence'}
                untilDate={untilDayDate}
                started_at={startedAt}
                ended_at={endedAt}
                selectedDays={[getDayName(startDayDate.getDay())]}
                changeState={onRecurrenceChange}
                hideRecurrence
            />
            <Component
                componentName={'DaysPicker'}
                started_at={startedAt}
                ended_at={endedAt}
                startDate={startDayDate}
                untilDate={untilDayDate}
                onChange={(e, fieldName) => onDayDateChange(e, fieldName)}
            />
        </>
    );
};

export default RoundRecurrentRruleAndDateFields;

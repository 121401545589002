// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';
// Helper
import { getUsersOptionFromUser } from '../../../users/helper';
import { getRoundEditionLeftSideBottomFields, maxNumberOfChipsInFieldOfPopupLeftSide } from '../../helper';

const RoundUsersText = props => {
    const getRightRoundUsers = (round, usersFieldType) => {
        switch (usersFieldType) {
            case 'assignees':
                return round.round_assignees;
            case 'followers':
            default:
                return round.round_followers?.concat(round.round_invite_followers);
        }
    };

    const round = useSelector(state => state.rounds.roundShownInDetailsPopup);
    const round_users = getUsersOptionFromUser(getRightRoundUsers(round, props.usersFieldType));
    const { Component } = useComponentsPool();
    const footbar = useFootBar();
    const bottomFields = getRoundEditionLeftSideBottomFields(footbar, round);
    // eslint-disable-next-line max-len
    const maxNumberOfChipsToDisplay = maxNumberOfChipsInFieldOfPopupLeftSide(Object.entries(bottomFields).filter(obj => obj[1].isDisplayed).length);

    if (isEmpty(round_users)) return '-';
    return (
        <div className={'followers-container'}>
            <Component
                componentName={'ChipButton'}
                maxVisible={maxNumberOfChipsToDisplay}
                chipsToDisplay={round_users}
            />
        </div>
    );
};

export default RoundUsersText;

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const ObjectLocation = props => {
    const id = props.resource.device_id || props.resource.group_id;
    const groups = useSelector(state => state.groups.list);

    let groupBatiment = groups.find(g => g.group_id === props.resource.custom_field?.batiment_group_id);
    let groupFloor = groups.find(g => g.group_id === props.resource.custom_field?.floor_group_id);
    const groupPosition = groups.find(g => g.group_id === props.resource.custom_field?.position_group_id);

    if (groups.length === 1) {
        groupBatiment = groupFloor;
        groupFloor = groupPosition;
    }

    return (
        <div
            className={classNames(['table-name'])}
            key={id}
        >
            <div className="table-row-content-text group">
                <span className={'title'}>{groupBatiment?.group_name}</span>
                <em>{groupFloor?.group_name}</em>
                <em>{groupPosition?.group_name}</em>
            </div>
        </div>
    );
};

export default ObjectLocation;

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { useComponentsPool } from '../../../../ComponentsPool';
import { getDeviceFromId, isPassageDeviceType } from '../../helper';
import { getId } from '../../../layout/helper';
import { useModal } from '../../../modal/ModalContext';


const DeviceAssociatedRoundsSimpleString = () => {
    const { Component } = useComponentsPool();
    const modal = useModal();
    const devices = useSelector(state => state.devices.list);
    const device = getDeviceFromId(devices, getId(modal.getItem()));

    const associated_rounds = [];
    if (isPassageDeviceType(device) && device.associated_rounds) {
        device.associated_rounds.forEach(associated_round => {
            associated_rounds.push({ id: associated_round?.id, value: associated_round?.name });
        });
    }

    return (
        <Component
            componentName={'ChipButton'}
            maxVisible={3}
            chipsToDisplay={associated_rounds}
        />
    );
};

export default DeviceAssociatedRoundsSimpleString;

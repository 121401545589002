// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useComponentsPool } from '../../../ComponentsPool';
import { getDeviceFromBatiment, getTicketsFromBatiment } from '../../groups/helper';
import { concatTickets } from '../../tickets/helper';

const BuildingsTable = () => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const groupList = useSelector(state => state.groups.list);
    const ticketList = useSelector(state => state.tickets.list);
    const deviceList = useSelector(state => state.devices.list);
    const [rows, setRows] = useState([]);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const modeFloorIsClose = workspaceSettings.mode_floor === 'close';
    const oneBuildingHasFloor = groupList.find(group => group.custom_field.type === 'BATIMENT' && group.group_ids?.length > 0);

    useEffect(() => {
        setRows(groupList
            .filter(group => group.custom_field.type === 'BATIMENT')
            .map(group => ({
                name: group.group_name,
                id: group.group_id,
                group_ids: group.group_ids,
                linked_tickets_count: getTicketsFromBatiment(group.group_id, concatTickets(ticketList)).length,
                linked_devices_count: getDeviceFromBatiment(group.group_id, deviceList).length
            }))
        );
    }, [groupList, deviceList, ticketList]);

    const headCells = [
        {
            id: 'name',
            toHide: false,
            hasIcon: false,
            positionToRight: false,
            disablePadding: false,
            label: t('settings:first_column_name')
        }, {
            id: 'floors_groups',
            hasIcon: false,
            toHide: !(modeFloorIsClose && oneBuildingHasFloor),
            positionToRight: false,
            disablePadding: false,
            label: t('settings:floor_column_name')
        }, {
            id: 'linked_resources',
            hasIcon: false,
            positionToRight: false,
            disablePadding: false,
            label: t('settings:linked_resources')
        }
    ];

    const columnsToHide = headCells.filter(cell => cell.toHide).map(cell => cell.id);
    return (
        <Component
            componentName={'SettingsTable'}
            headCells={headCells}
            tableTitle={'BATIMENT'}
            rows={rows}
            columnsToHide={columnsToHide}
        />
    );
};

export default BuildingsTable;

// Libs
import React, { forwardRef, useRef } from 'react';
import { uuid } from 'uuidv4';
// Components
import { Skeleton } from '@material-ui/lab';
// Components
import Svg from './svg';

export const getSkeleton = models => {
    const skeletons = [];
    let mod = {};
    models.forEach((model, j) => {
        mod = { ...mod, [model]: model === 'id' ? `${model}--${j}` : <Skeleton variant={'text'} height={20} /> };
    });
    for (let i = 0; i < Object.keys(mod).length * 3; i = i + 1) {
        skeletons.push({ ...mod, id: uuid() });
    }
    return skeletons;
};

const SkeletonGraph = forwardRef((_, ref) => {
    const testRef = useRef(ref);
    return (
        <div ref={testRef} style={{ width: '94%', margin: 'auto' }}>
            <Skeleton variant={'text'} height={30} />
            <Svg ref={testRef} />
        </div>
    );
});

export default SkeletonGraph;

// Libs
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import ReduxThunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';

import config from './config.json';

const reducers = {};
config.modules.forEach(({ reducer }) => {
    if (reducer) {
        try {
            reducers[reducer] = require(`./modules/${reducer}/reducer`).default; // eslint-disable-line
        } catch (error) {
            console.error('Warrning: cannot load reducer for module "' + reducer + '"'); // eslint-disable-line
            console.error(error); // eslint-disable-line
        }
    }
});

PLUGINS.forEach(plugin => {
    try {
        reducers[plugin] = require(`./plugins/${plugin}/app/reducer`).default; // eslint-disable-line
    } catch (e) {
        console.log(`Warning: cannot load reducer for plugin "${plugin}"`); // eslint-disable-line
    }
});

const errorMiddleware = (/* store */) => next => action => {
    try {
        next(action);
    } catch (e) {
        console.error('Error catch in error middleware:\n', e); // eslint-disable-line
    }
};
let middlewares;
if (IS_PRODUCTION && !IS_DEBUG) {
    middlewares = applyMiddleware(promiseMiddleware(), errorMiddleware, ReduxThunk);
} else {
    middlewares = applyMiddleware(promiseMiddleware(), errorMiddleware, ReduxThunk, createLogger({ duration: true }));
}

const appReducer = combineReducers(reducers);
const states = {};
const store = createStore(appReducer, states, middlewares);

export default store;

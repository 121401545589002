// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useModal } from '../../../modal/ModalContext';
// Actions
import { resendInvitation } from '../../actions';

export default props => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const { Component } = useComponentsPool();
    const modal = useModal();

    const resendInvites = async () => {
        await Promise.all(props.list.map(async ({ id }) => dispatch(resendInvitation(id))));
        modal.close();
    };

    return (
        <Component
            componentName={'FootbarButton'}
            withConfirmationPopover
            displayCondition={props.userWorkspaceRights.includes('UPDATE:User') && props.hasInvitations && !props.hasConfiguredUsers}
            buttonText={props.list.length > 1 ? t('descriptions:resend_mails') : t('descriptions:resend_mail')}
            callbackAction={() => resendInvites()}
            popoverText={props.list.length > 1 ? t('users:resend_invites') : t('users:resend_invite', { email: props?.list[0]?.invite_email })}
            cancelButtonLabel={t('common:do_not_send')}
            confirmationButtonLabel={t('common:yes_send')}
            buttonIcon={'email'}
        />
    );
};

import React, {useEffect} from 'react';
// Libs
import ReactEcharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
// Helper
import CountUp from 'react-countup';
import { easeOutQuad, getPressCountInTotal } from '../../helper';

const PollGraph = props => {
    const eChartsRef = React.useRef(null);
    const [t] = useTranslation();

    const graphData = props.graphData;

    const sumClicks = jsonObject => {
        let total = 0;
        Object.keys(jsonObject).forEach(data => {
            total = total + jsonObject[data];
        });
        return total;
    };

    const clickTotal = sumClicks(graphData);
    const getPercentage = value => (value === 0 ? 0 : Math.round(value * 100 / clickTotal));

    const keys = Object.keys(graphData).map(k => `${t('groups:choice')} ${k} (${getPercentage(graphData[k])}%)`);
    const values = Object.values(graphData);

    const animationDelay = 0.3;
    const animationDuration = 1.5;
    const timeoutDelay = animationDelay * 1000;


    useEffect(() => {
        // animation for the stars on the graph
        setTimeout(() => {
            // eslint-disable-next-line no-unused-expressions
            eChartsRef.current?.getEchartsInstance().setOption({
                series: [{
                    animationDurationUpdate: animationDuration * 1000,
                    data: values
                }]
            });
        }, timeoutDelay);
    }, [graphData]);

    return (
        <div className={'echarts-graph-wrapper'}>
            <p className={'graph-title'}>
                <CountUp
                    end={clickTotal}
                    delay={animationDelay}
                    duration={animationDuration}
                    useEasing
                    easingFn={easeOutQuad}
                />
                {` ${getPressCountInTotal(t, clickTotal)}`}
            </p>
            <ReactEcharts
                className={'echarts-graph'}
                ref={eChartsRef}
                style={{width: '100%', height: '100%'}}
                option={{
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: keys,
                        axisTick: {
                            alignWithLabel: true
                        }
                    },
                    yAxis: {
                        type: 'value',
                        minInterval: 1
                    },
                    series: [{
                        data: keys.map(() => 0),
                        type: 'bar',
                        name: t('groups:clicks'),
                        barWidth: '60%'
                    }]
                }}
            />
        </div>

    );
};
export default PollGraph;

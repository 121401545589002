// Libs
import React from 'react';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';

const GroupName = props => {
    const { Component } = useComponentsPool();

    return (
        <div className="table-name">
            <Component componentName={'Icon'} type={'userGroup'} />
            <div className="group">
                <div className="title">{props.resource.group_name}</div>
            </div>
        </div>
    );
};

export default GroupName;

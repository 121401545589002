import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { useComponentsPool } from '../../../../../ComponentsPool';
import useRouter from '../../../../../utils/hook/useRouter';
import { useFootBar } from '../../../../footbar/FootBarContext';
import { deleteInstance, getInstancesFromTo } from '../../../actions';
import './DeleteInstanceBtn.scss';


const DeleteInstanceBtn = ({ instance, onDelete, withTooltip }) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const footbar = useFootBar();
    const { Component } = useComponentsPool();
    const { query } = useRouter();
    const [isDisplayedPopover, setIsDisplayedPopover] = useState(false);

    const onConfirm = () => {
        const currentStartDate = query.selectedStartedDate;
        const currentEndDate = query.selectedEndedDate;

        dispatch(deleteInstance(instance.id))
            .then(() => {
                dispatch(getInstancesFromTo(currentStartDate, currentEndDate));
                footbar.close();
                onDelete();
            });
    };

    return (
        <Tooltip
            arrow={false}
            title={withTooltip && !isDisplayedPopover ? t('rounds:delete_event') : ''}
            placement={'top'}
        >
            <div className="delete-instance-btn">
                <Component
                    componentName={'FootbarButton'}
                    withConfirmationPopover
                    customPopoverPosition
                    noArrow
                    displayCondition
                    popoverText={t('notifications:delete_instance_confirm')}
                    callbackAction={onConfirm}
                    setIsOpenPopover={isOpen => setIsDisplayedPopover(isOpen)}
                    buttonIcon={'trash'}
                    key={'trash'}
                />
            </div>
      </Tooltip>
    );
};

export default DeleteInstanceBtn;

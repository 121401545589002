// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';

const DeviceInformation = props => {
    const [t] = useTranslation();

    return props.resource.device_description !== ''
        ? props.resource.device_description
        : <i className={'placeholder_text'}>{t('common:not_information')}</i>;
};

export default DeviceInformation;

// Libs
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import Drawer from '@material-ui/core/Drawer';
import TypeColor from './SettingsType/TypeColor';
import TypeBool from './SettingsType/TypeBool';
import TypeText from './SettingsType/TypeText';
import TypeImage from './SettingsType/TypeImage';
import TypeTwoChoices from './SettingsType/TypeTwoChoices';
import TypeToggle from './SettingsType/TypeToggle';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import { setWorkspaceSettingsPanel } from '../actions';
import { updateWorkspaceSetting } from '../../core/actions';

const WorkspaceSettings = () => {
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const workspaceSettingsOpen = useSelector(state => state.navbar.workspaceSettingsPanel);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const [settings, setSettings] = useState([
        { name: 'Logo', fn: 'logo_url', value: workspaceSettings.logo_url, type: 'link' }, // eslint-disable-line
        { name: 'Logo without text', fn: 'logo_no_text_url', value: workspaceSettings.logo_no_text_url, type: 'link' }, // eslint-disable-line
        { name: 'Password pas de majuscule', fn: 'password_without_uppercase', value: workspaceSettings.password_without_uppercase, type: 'bool' }, // eslint-disable-line
        { name: 'Password pas de minuscule', fn: 'password_without_lowercase', value: workspaceSettings.password_without_lowercase, type: 'bool' }, // eslint-disable-line
        { name: 'Password pas de nombre', fn: 'password_without_digits', value: workspaceSettings.password_without_digits, type: 'bool' }, // eslint-disable-line
        { name: 'Password pas de symbole', fn: 'password_without_symbols', value: workspaceSettings.password_without_symbols, type: 'bool' }, // eslint-disable-line
        { name: 'Password min char', fn: 'password_min_char', value: workspaceSettings.password_min_char, type: 'number' }, // eslint-disable-line
        { name: 'Catégories à cacher aux occupants', fn: 'user_skills_hidden', value: JSON.stringify(workspaceSettings.user_skills_hidden), type: 'text' }, // eslint-disable-line
        { name: 'Filter user on skills', fn: 'filter_user_on_skills', value: workspaceSettings.filter_user_on_skills, type: 'bool' }, // eslint-disable-line
        { name: 'Filter admin on skills', fn: 'filter_admin_on_skills', value: workspaceSettings.filter_admin_on_skills, type: 'bool' }, // eslint-disable-line
        { name: "Cacher noms pour les occupants", fn: 'hide_user_author', value: workspaceSettings.hide_user_author, type: 'bool' }, // eslint-disable-line
        { name: "Afficher vue calendrier pour occupants", fn: 'show_user_calendar_view', value: workspaceSettings.show_user_calendar_view, type: 'bool' }, // eslint-disable-line
        { name: "Afficher vue calendrier pour techniciens", fn: 'show_contractor_calendar_view', value: workspaceSettings.show_contractor_calendar_view, type: 'bool' }, // eslint-disable-line
        { name: "Afficher des prestations sur les 10 derniers passages pour les occupants et les non connectés", fn: 'show_jobs_of_qrcode_passages_for_users_and_non_connected_users', value: workspaceSettings?.show_jobs_of_qrcode_passages_for_users_and_non_connected_users ?? false, type: 'bool' }, // eslint-disable-line
        { name: "Cacher vue capteur pour presta", fn: 'hide_contractor_device_view', value: workspaceSettings.hide_contractor_device_view, type: 'bool' }, // eslint-disable-line
        { name: "Bloquer la suppression de tickets", fn: 'block_tickets_removal', value: workspaceSettings.block_tickets_removal, type: 'bool' }, // eslint-disable-line
        { name: 'Required fields', fn: 'required_fields', value: workspaceSettings.required_fields, type: 'toggle' }, // eslint-disable-line
        { name: 'Mode floor', fn: 'mode_floor', value: workspaceSettings.mode_floor, type: 'twoChoices' }, // eslint-disable-line
        { name: 'Mode title', fn: 'mode_title', value: workspaceSettings.mode_title, type: 'twoChoices' }, // eslint-disable-line
        { name: 'User tickets mode', fn: 'user_tickets_mode', value: workspaceSettings.user_tickets_mode, type: 'twoChoices' }, // eslint-disable-line
        { name: 'Tickets visibles par presta', fn: 'contractor_tickets_mode', value: workspaceSettings.contractor_tickets_mode, type: 'twoChoices' }, // eslint-disable-line
        { name: 'Afficher la vue des tickets sur mobile pour les utilisateurs sans mail', fn: 'show_tickets_view_for_users_without_email_on_mobile', value: workspaceSettings.show_tickets_view_for_users_without_email_on_mobile ?? false, type: 'bool' }, // eslint-disable-line
        { name: 'Permettre partage sur chatbot aux prestataires', fn: 'show_chatbot_sharing_to_contractor', value: workspaceSettings.show_chatbot_sharing_to_contractor, type: 'bool' }, // eslint-disable-line
        {
            name: 'Les prestas peuvent modifier les assignations (seulement si Tickets visibles par presta = tickets skills)',
            fn: 'is_contractor_rights_on_assignment',
            value: workspaceSettings.is_contractor_rights_on_assignment,
            type: 'bool'
        },
        {
            name: 'Les prestas peuvent modifier la priorité',
            fn: 'is_contractor_rights_on_priority',
            value: workspaceSettings.is_contractor_rights_on_priority,
            type: 'bool'
        },
        {
            name: 'Les occupants peuvent modifier la priorité',
            fn: 'is_user_rights_on_priority',
            value: workspaceSettings.is_user_rights_on_priority,
            type: 'bool'
        },
        { name: 'Mail obligatoire sur les qrcodes decla', fn: 'mandatory_mail_in_qrcode', value: workspaceSettings.mandatory_mail_in_qrcode, type: 'bool' }, // eslint-disable-line
        {
            name: 'Ajouter appel d\'urgence sur QR Codes par bâtiment',
            fn: 'building_qrcode_emergency_call_mapping',
            value: JSON.stringify(workspaceSettings.building_qrcode_emergency_call_mapping),
            type: 'text'
        },
        { name: 'Création ticket rondes', fn: 'create_rounds_tickets', value: workspaceSettings.create_rounds_tickets, type: 'bool' }, // eslint-disable-line
        { name: 'Activer les alertes et tickets de rondes', fn: 'round_alerts', value: workspaceSettings.round_alerts, type: 'bool' }, // eslint-disable-line
        { name: 'Autoriser la modification des horaires de passages de rondes', fn: 'allow_round_passages_edition', value: workspaceSettings.allow_round_passages_edition, type: 'bool' }, // eslint-disable-line
        {
            name: 'Types de capteur à clotûrer auto',
            fn: 'automatic_closing_author_options',
            value: workspaceSettings.automatic_closing_author_options,
            type: 'text'
        },
        { name: 'Notification sonore', fn: 'notification_sound', value: workspaceSettings.notification_sound, type: 'link' }, // eslint-disable-line
        {
            name: 'Connexion Azure AD',
            fn: 'show_azure_ad_connect',
            value: workspaceSettings.show_azure_ad_connect,
            type: 'twoChoices'
        },
        {
            name: 'Cryptr org domain',
            fn: 'cryptr_org_domain',
            value: JSON.stringify(workspaceSettings.cryptr_org_domain),
            type: 'text'
        },
        { name: 'Samfm client mode connection', fn: 'samfm_client_mode_connection', value: workspaceSettings.samfm_client_mode_connection, type: 'text' }, // eslint-disable-line
        { name: 'Samfm client id', fn: 'samfm_client_id', value: workspaceSettings.samfm_client_id, type: 'text' }, // eslint-disable-line
        { name: 'Samfm client secret', fn: 'samfm_client_secret', value: workspaceSettings.samfm_client_secret, type: 'text' }, // eslint-disable-line
        { name: 'Samfm api url', fn: 'samfm_api_url', value: workspaceSettings.samfm_api_url, type: 'text' }, // eslint-disable-line,
        { name: 'Service Now url', fn: 'servicenow_api_url', value: workspaceSettings.servicenow_api_url, type: 'text' }, // eslint-disable-line
        { name: 'Service Now username', fn: 'servicenow_username', value: workspaceSettings.servicenow_username, type: 'text' }, // eslint-disable-line
        { name: 'Service Now password', fn: 'servicenow_password', value: workspaceSettings.servicenow_password, type: 'text' }, // eslint-disable-line
        {
            name: 'Service Now switcher',
            fn: 'servicenow_switcher',
            value: JSON.stringify(workspaceSettings.servicenow_switcher),
            type: 'text'
        },
        {
            name: 'NouvelAtrium url',
            fn: 'nouvelatrium_api_url',
            value: JSON.stringify(workspaceSettings.nouvelatrium_api_url),
            type: 'text'
        },
        {
            name: 'NouvelAtrium login',
            fn: 'nouvelatrium_api_login',
            value: JSON.stringify(workspaceSettings.nouvelatrium_api_login),
            type: 'text'
        },
        {
            name: 'NouvelAtrium pass',
            fn: 'nouvelatrium_api_pass',
            value: JSON.stringify(workspaceSettings.nouvelatrium_api_pass),
            type: 'text'
        },
        {
            name: 'Activer lien avec Deskare',
            fn: 'has_deskare_connexion',
            value: workspaceSettings.has_deskare_connexion,
            type: 'bool'
        },
        {
            name: 'Activer lien avec Insiteo',
            fn: 'has_insiteo_connexion',
            value: workspaceSettings.has_insiteo_connexion,
            type: 'bool'
        },
        {
            name: 'Insiteo api url',
            fn: 'insiteo_api_url',
            value: workspaceSettings.insiteo_api_url,
            type: 'text'
        },
        {
            name: 'OneAtalian api url',
            fn: 'one_atalian_api_url',
            value: workspaceSettings.one_atalian_api_url,
            type: 'text'
        },
        {
            name: 'OneAtalian api key',
            fn: 'one_atalian_api_key',
            value: workspaceSettings.one_atalian_api_key,
            type: 'text'
        },
        {
            name: 'SharingCloud url',
            fn: 'sharingcloud_api_url',
            value: workspaceSettings.sharingcloud_api_url,
            type: 'text'
        },
        {
            name: 'SharingCloud auth url',
            fn: 'sharingcloud_auth_url',
            value: workspaceSettings.sharingcloud_auth_url,
            type: 'text'
        },
        {
            name: 'SharingCloud client id',
            fn: 'sharingcloud_client_id',
            value: workspaceSettings.sharingcloud_client_id,
            type: 'text'
        },
        {
            name: 'SharingCloud client secret',
            fn: 'sharingcloud_client_secret',
            value: workspaceSettings.sharingcloud_client_secret,
            type: 'text'
        },
        {
            name: 'Spinalcom switcher',
            fn: 'spinalcom_switcher',
            value: JSON.stringify(workspaceSettings.spinalcom_switcher),
            type: 'text'
        },
        {
            name: 'Spinalcom auth url',
            fn: 'spinalcom_auth_url',
            value: workspaceSettings.spinalcom_auth_url,
            type: 'text'
        },
        {
            name: 'Spinalcom api url',
            fn: 'spinalcom_api_url',
            value: workspaceSettings.spinalcom_api_url,
            type: 'text'
        },
        {
            name: 'Spinalcom api secret',
            fn: 'spinalcom_api_secret',
            value: workspaceSettings.spinalcom_api_secret,
            type: 'text'
        },
        {
            name: 'Spinalcom api clientid',
            fn: 'spinalcom_api_clientid',
            value: workspaceSettings.spinalcom_api_clientid,
            type: 'text'
        },
        {
            name: 'Liste des dashboards Metabase',
            fn: 'metabase_dashboards_mapping',
            value:  JSON.stringify(workspaceSettings.metabase_dashboards_mapping),
            type: 'text'
        },
        {
            name: 'Numéro du dashboard metabase à intégrer dans les Statistiques',
            fn: 'metabase_dashboard_number_to_dashboard_tab',
            value: workspaceSettings.metabase_dashboard_number_to_dashboard_tab,
            type: 'number'
        }
    ]);

    const setNewValue = (value, index) => {
        const tempSetting = Object.assign([], settings);
        if (tempSetting[index].value !== value) {
            if (tempSetting[index].type === 'bool') {
                tempSetting[index].value = !tempSetting[index].value;
                dispatch(updateWorkspaceSetting(
                    tempSetting[index].fn,
                    tempSetting[index].value,
                    workspaceSettings[tempSetting[index].fn] !== undefined
                ));
            } else {
                tempSetting[index].value = value;
                dispatch(updateWorkspaceSetting(
                    tempSetting[index].fn,
                    value,
                    workspaceSettings[tempSetting[index].fn] !== undefined
                ));
            }
            setSettings(tempSetting);
        }
    };

    return (
        <Drawer anchor={'right'} open={workspaceSettingsOpen} onClose={() => dispatch(setWorkspaceSettingsPanel(false))}>
            <div className={'workspace-settings-container'}>
                <div role={'button'} tabIndex={0} className={'exit-button'} onClick={() => dispatch(setWorkspaceSettingsPanel(false))}>
                    <Component componentName={'Icon'} type={'close'} />
                </div>
                <h2>{'Workspace settings'}</h2>
                <div className={'settings-container'}>
                    { settings.map((setting, ndx) => setting && (
                        <div key={setting.fn} className={'setting'}>
                            <em>{setting.name}</em>
                            {setting.type === 'bool' && (
                                <div>
                                    <span className={'left-lang'}>{'false'}</span>
                                    <TypeBool
                                        setNewValue={value => setNewValue(value, ndx)}
                                        value={setting.value}
                                        name={setting.name}
                                    />
                                    <span className={'left-lang'}>{'true'}</span>
                                </div>
                            )}
                            {setting.type === 'toggle' && (
                                <TypeToggle
                                    setNewValue={value => setNewValue(value, ndx)}
                                    value={setting.value}
                                    name={setting.name}
                                />
                            )}
                            {setting.type === 'twoChoices' && (
                                <TypeTwoChoices
                                    setNewValue={value => setNewValue(value, ndx)}
                                    value={setting.value}
                                    fn={setting.fn}
                                    name={setting.name}
                                />
                            )}
                            {setting.type === 'color' && (
                                <TypeColor
                                    value={setting.value}
                                    fn={setting.fn}
                                    setNewValue={value => setNewValue(value, ndx)}
                                />
                            )}
                            {(setting.type === 'number' || setting.type === 'text') && (
                                <TypeText
                                    setNewValue={value => setNewValue(value, ndx)}
                                    value={setting.value}
                                    number={setting.type === 'number'}
                                />
                            )}
                            {setting.type === 'link' && (
                                <TypeImage
                                    value={setting.value}
                                    setNewValue={value => setNewValue(value, ndx)}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </Drawer>
    );
};

export default WorkspaceSettings;

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const DeviceGroupSimpleViewGroup = props => {
    const [t] = useTranslation();

    const groupList = useSelector(state => state.groups.list);
    const deviceGroupList = useSelector(state => state.groups.groupShownInDetailsPopup);
    const custom_field = props.value[1];

    const group_id = deviceGroupList.custom_field[custom_field];
    const group = groupList.find(g => g.group_id === group_id);

    return (
        <p className={`info-value ${custom_field}`}>
            {group ? group.group_name : t('common:undefined')}
        </p>
    );
};

export default DeviceGroupSimpleViewGroup;

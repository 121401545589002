import axios from 'axios';

export const updateWorkspaceName = name => ({ type: 'UPDATE_WORKSPACE_NAME', payload: name });

export const setWorkspaceName = workspace_name => ({ type: 'SET_WORKSPACE_NAME_FULFILLED', payload: workspace_name });

export const getWorkspaceRoles = () => ({
    type: 'GET_WORKSPACE_ROLES',
    payload: axios.get('/acl/workspace-roles/')
        .then(res => res.data.results)
});

export const getWorkspaceSettings = () => ({
    type: 'GET_WORKSPACE_SETTINGS',
    payload: axios.get('/workspace/settings/')
        .then(res => res.data)
});

export const restorePasswordInvite = (workspaceName, email) => ({
    type: 'SEND_RESET_PASSWORD_INVITE',
    payload: axios.post('/users/reset-password', { lang: localStorage.getItem('i18nextLng'), email })
});

export const recoverWorkspace = email => ({
    type: 'RECOVER_WORKSPACE',
    payload: axios.post('/workspace/recover', { email })
});

export const checkSecurityCode = security_code => ({
    type: 'CHECK_SECURITY_CODE',
    payload: axios.post('/workspace/check_security_code', { security_code })
});

export const checkWorkspaceExist = workspace => ({
    type: 'CHECK_WORKSPACE_NAME',
    payload: axios.post('/workspace/check-exists', { workspace }).then(res => res.data)
});

export const clearWorkspaceCreation = () => ({ type: 'CLEAR_WORKSPACE_CREATION' });

export const createWorkspace = (workspaceName, securityCode, authorName) => ({
    type: 'CREATE_WORKSPACE',
    payload: axios.post('/workspace/', {
        workspace_name: workspaceName,
        security_code: securityCode,
        author_name: authorName
    })
});

export const sendInvites = (workspaceSlug, emails, accessToken) => ({
    type: 'SEND_INVITES',
    payload: axios.post(
        `${window.location.protocol}//${workspaceSlug}.api.${window.location.hostname}/v1/invite/`, {
            email: emails,
            group: [],
            lang: localStorage.getItem('i18nextLng').slice(0, 2)
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }
    )
});

export const getUserWorkspacesFromToken = token => ({
    type: 'GET_USER_WORKSPACES_LIST',
    payload: axios.get(`/workspace?token=${token}`)
        .then(res => res.data)
});

export const getCurrentUserWorkspaces = () => ({
    type: 'GET_USER_WORKSPACES_LIST',
    payload: axios.get('/workspace/get-workspaces-list')
        .then(res => res.data)
});

// Libs
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { useComponentsPool } from '../../../../../ComponentsPool';
import createLoadingSelector from '../../../../layout/actions';
import { getTemplateInstructionFromTitleAndSkillId } from '../../../../templates/helper';

const loadingSelector = createLoadingSelector(['UPLOAD_COMMENT_DOCUMENT']);
export default props => {
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const titleMode = workspaceSettings.mode_title ? workspaceSettings.mode_title : 'close';
    const templatesList = useSelector(state => state.templates.list);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const [details, setDetails] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState(props.urls);

    useEffect(() => {
        props.changeState({ urls: uploadedFiles });
    }, [uploadedFiles]);

    return (
        <div className={classNames(['ticket-step', 'form-control'])}>
            <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
            <div className={'editable-field info'}>
                <Component
                    componentName={'EditableField'}
                    renderValue={'GroupSelector'}
                    required
                    title={t('tickets:skill')}
                    props={{
                        placeholder: '',
                        onChange: value => {
                            const workspace = localStorage.getItem('iot_rocket_workspace');
                            if (workspace === 'fnacdarty' && value === '1df84be1-57f8-4cd4-9d4e-c86340e52e7d') {
                                setDetails('⚠️ Pour une demande de badge veuillez passer par LANDesk: [cliquez ici](http://portailmydosi.intranet.darty.fr/LDSD.WebAccessExp/ss/Logon/Logon.rails)');
                            } else {
                                setDetails('');
                            }
                            props.changeState({ skill_group_id: value });
                        },
                        groupType: 'SKILL',
                        isCreateTicketPopup: true
                    }}
                />
            </div>
            {
                titleMode === 'close'
                    ? (
                        <div className={'editable-field info'}>
                            <Component
                                componentName={'EditableField'}
                                renderValue={'TicketTemplateSelector'}
                                required
                                title={t('tickets:title')}
                                props={{
                                    placeholder: '',
                                    onChange: value => {
                                        setDetails(getTemplateInstructionFromTitleAndSkillId(templatesList, value, props.skill_group_id));
                                        props.changeState({ title: value });
                                    },
                                    modeHierarchy: true,
                                    parent: props.skill_group_id,
                                    value: props.title,
                                    selected: props.title,
                                    object: 'ticket'
                                }}
                            />
                        </div>
                    )
                    : (
                        <div className={'editable-field info'}>
                            <Component
                                componentName={'EditableField'}
                                renderValue={'Input'}
                                required
                                title={t('tickets:title')}
                                value={props.title}
                                props={{
                                    rules: ['maxLength-128'],
                                    label: '',
                                    value: props.title,
                                    onChange: value => props.changeState({ title: value })
                                }}
                            />
                        </div>
                    )
            }
            <div className={'create-ticket-comment'}>
                <div className={'editable-field info'}>
                    <span className={'info-title'}>{t('tickets:description_comment')}</span>
                    <span className={'info-sub-title'}>
                        {
                            isEmpty(details)
                                ? t('tickets:description_subtitle')
                                : (
                                    <ReactMarkdown linkTarget="_blank" remarkPlugins={[gfm]}>
                                        {details}
                                    </ReactMarkdown>
                                )
                        }
                    </span>
                    <Component
                        componentName={'Input'}
                        rules={['maxLength-512']}
                        multiline={4}
                        label={''}
                        value={props.comment}
                        onChange={value => props.changeState({ comment: value })}
                        showAttachmentUploader
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={value => setUploadedFiles(value)}
                    />
                </div>
            </div>
        </div>
    );
};

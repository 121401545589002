// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useComponentsPool } from '../../../../ComponentsPool';
import { getGroupNameFromId } from '../../../groups/helper';
import { getTimeOnTwoDigits } from '../../../layout/helper';


const ExportInfoButton = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();

    const list = props.list;
    const groupList = useSelector(state => state.groups.list);

    const exportInfo = async () => {
        if (!isEmpty(list)) {
            const currentDate = new Date();

            const day = currentDate.getDate();
            // Months are indexed from 0, so 1 is added to obtain the actual month.
            const month = currentDate.getMonth() + 1;
            const year = currentDate.getFullYear();

            const formattedDate = `${getTimeOnTwoDigits(day)}_${getTimeOnTwoDigits(month)}_${year}`;
            const filename = `${t('export:device_info_export_filename')}_${formattedDate}.csv`;
            // Headers : hardware_ids, device_name, batiment, floor, position, type, modes (si QR Code)
            // Add modes column if there is QR Code type in list to export
            const hasQRCode = list.some(element => element.custom_field.type === 'QRCODE');
            let result = `${t('devices:hardware_id')};${
                t('devices:device_name')};${
                t('groups:batiment_group')};${
                t('groups:floor_group')};${
                t('groups:position_group')};${
                t('common:type')}`;
            if (hasQRCode) {
                result = `${result};${t('devices:activated_modes')}\n`;
            } else {
                result = `${result}\n`;
            }
            // Get column value for each device in list
            list.forEach(element => {
                let modes = '';
                const type = element.custom_field.type;
                if (type === 'QRCODE') {
                    element.custom_field.config.modes.forEach(mode => {
                        if (!isEmpty(modes)) {
                            modes = `${modes} // ${t(`devices:${mode}`)}`;
                        } else { // for first mode
                            modes = `;${t(`devices:${mode}`)}`;
                        }
                    });
                }
                if(element===undefined || element.hardware_ids === undefined) {
                    console.log('ici');
                    console.log(element);
                }
                result = `${result}"${element.hardware_ids[0] || ''}";${
                    element.device_name};${
                    getGroupNameFromId(groupList, element.custom_field.batiment_group_id)};${
                    getGroupNameFromId(groupList, element.custom_field.floor_group_id)};${
                    getGroupNameFromId(groupList, element.custom_field.position_group_id)};${
                    t(`devices:${type}`)}${modes}\n`;
            });
            const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), result], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename);
            } else {
                const link = document.createElement('a');
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }
    };

    return (
        <Component
            displayCondition
            componentName={'FootbarButton'}
            buttonText={t('devices:export_info')}
            callbackAction={() => exportInfo()}
            buttonIcon={'exportTable'}
        />
    );
};

export default ExportInfoButton;

// Libs
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import {useTranslation} from "react-i18next";
import {find} from "lodash";
import {newWebsocketMessage} from "../../../core/actions";

const DeviceJobConfigSelector = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const options = [
        { id: '1', value: t('devices:SINGLE_JOB') },
        { id: 'N', value: t('devices:MULTIPLE_JOBS') }
    ];
    const maxSelectedJobs = useSelector(
        state => state.devices.deviceShownInDetailsPopup.custom_field.config.max_selected_jobs
    );
    const jobConfig = maxSelectedJobs?.toString() === '1' || !maxSelectedJobs ? t('devices:SINGLE_JOB') : t('devices:MULTIPLE_JOBS');
    const selected = options.find(option => option.value === jobConfig);

    const changeConfig = newConfig => {
        props.onChange(newConfig.id);
    };
    return (
        <Component
            componentName={'Select'}
            rules={props.rules}
            required={props.required}
            multiple={props.multiple}
            placeHolder={props.placeholder}
            onChange={changeConfig}
            options={options.map(option => ({
                id: option.id,
                value: option.value
            }))}
            selected={selected}
            renderValue={'chip'}
            showSelectAll
            isSelectedToText={[]}
        />
    );
};

export default DeviceJobConfigSelector;

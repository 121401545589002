import * as moment from 'moment';
import i18n from 'i18next';

const GraphConfig = {
    chart: {
        showEmpty: true,
        displayErrors: true,
        reflow: true,
        animation: false,
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        polar: false,
        height: '350px'
    },
    lang: {
        months: moment.months(),
        weekdays: moment.weekdays(),
        shortMonths: moment.monthsShort(),
        downloadPNG: i18n.t('hightcharts:downloadPNG'),
        downloadJPEG: i18n.t('hightcharts:downloadJPEG'),
        downloadPDF: i18n.t('hightcharts:downloadPDF'),
        downloadSVG: i18n.t('hightcharts:downloadSVG'),
        exportButtonTitle: i18n.t('hightcharts:exportButtonTitle'),
        loading: i18n.t('hightcharts:loading'),
        printButtonTitle: i18n.t('hightcharts:printButtonTitle'),
        resetZoom: i18n.t('hightcharts:resetZoom'),
        resetZoomTitle: i18n.t('hightcharts:resetZoomTitle'),
        thousandsSep: ' ',
        decimalPoint: ','
    },
    boost: {
        allowForce: true,
        enabled: true,
        useGPUTranslations: true
    },
    noData: {
        style: {
            display: 'block',
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030',
            useHTML: true
        }
    },
    reflow: true,
    credits: { enabled: false },
    title: {
        text: null,
        style: {
            fontSize: '24px'
        }
    },
    time: {
        useUTC: false,
        timezone: 'Europe/Paris'
    },
    navigator: {
        includeInDataExport: false
    },
    tooltip: {
        shared: false,
        backgroundColor: '#f0f0f0',
        followPointer: true,
        followTouchMove: true,
        borderColor: null,
        borderRadius: 3,
        borderWidth: 1,
        crosshairs: true,
        hideDelay: 500,
        padding: 8,
        shadow: true,
        shape: 'callout',
        snap: 10,
        split: false,
        useHTML: false,
        valuePrefix: '',
        xDateFormat: ''
    },
    legend: {
        floating: false,
        reversed: false,
        layout: 'horizontal',
        maxHeight: 120,
        useHTML: true,
        itemMarginTop: 30,
        verticalAlign: 'bottom',
        margin: 5,
        padding: 5,
        itemDistance: 40,
        itemWidth: 150,
        symbolWidth: 0,
        align: 'left',
        itemStyle: {
            fontFamily: '"Lato"',
            color: '#333333',
            cursor: 'pointer',
            backgroundColor: '#F3F3F3',
            padding: '10px',
            borderRadius: '20px',
            textOverflow: 'ellipsis',
            // overflow: 'hidden',
            'white-space': 'nowrap',
            width: 150
        },
        itemHiddenStyle: {
            color: '#cccccc',
            backgroundColor: '#f7f7f7'
        },
        itemHoverStyle: {
            color: '#000000',
            backgroundColor: '#f7f7f7'
        },
        backgroundColor: 'transparent',
        borderWidth: 0,
        borderColor: 'transparent',
        borderRadius: 0,
        labelFormatter() {
            const { name, color, visible } = this;
            return `
                <div class="legend-wrapper" style={{display: 'flex'}}>
                    <span class="square" style="background-color: ${visible ? color : 'rgb(204, 204, 204)'}"></span>
                    <span class="highChart-label">${name}</span>
                </div>
            `;
        },
        navigation: {
            activeColor: '#003399',
            animation: false,
            arrowSize: 12,
            enabled: true,
            inactiveColor: '#cccccc'
        },
        style: {
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: 'bold',
            padding: '5px'
        }
    },
    responsive: {
        rules: [{
            condition: {
                // maxWidth: 500,
                maxHeight: 500
            },
            chartOptions: {
                chart: {
                    height: 260
                },
                subtitle: {
                    text: null
                },
                navigator: {
                    enabled: false
                }
            }
        }]
    },
    exporting: {
        enabled: false,
        piechart: {
            chartOptions: {
                legend: {
                    enabled: true
                }
            },
            buttons: {
                contextButton: {
                    enabled: true,
                    menuItems: [
                        'viewFullscreen',
                        'printChart',
                        'separator',
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadSVG',
                        'separator',
                        'downloadPDF',
                        'downloadCSV',
                        'separator',
                        // 'viewData',
                        'openInCloud'
                    ]
                }
            }
        },
        gauge: {
            sourceWidth: 1920,
            sourceHeight: 1080,
            chartOptions: {
                chart: {
                    type: 'solidgauge',
                    height: '40%',
                    marginBottom: 40
                },
                pane: {
                    startAngle: -90,
                    endAngle: 90,
                    size: '150%',
                    center: [
                        '50%',
                        '94%'
                    ]
                },
                title: {
                    text: 'Title',
                    y: 25,
                    style: {
                        fontSize: '18px'
                    }
                }
            },
            buttons: {
                contextButton: {
                    enabled: true,
                    menuItems: [
                        'viewFullscreen',
                        'printChart',
                        'separator',
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadSVG',
                        'separator',
                        'downloadPDF',
                        'downloadCSV',
                        'separator',
                        // 'viewData',
                        'openInCloud'
                    ]
                }
            }
        },
        curve: {
            lineWidth: 1.5,
            sourceWidth: 1920,
            sourceHeight: 1080,
            chartOptions: {
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                yAxis: {
                    labels: {
                        style: {
                            fontSize: '16px'
                        }
                    }
                },
                xAxis: {
                    labels: {
                        style: {
                            fontSize: '16px'
                        }
                    }
                },
                legend: {
                    enabled: true
                }
            },
            csv: {
                dateFormat: '%Y-%m-%d;%H;%M;%S'
            },
            buttons: {
                contextButton: {
                    enabled: true,
                    menuItems: [
                        'viewFullscreen',
                        'printChart',
                        'separator',
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadSVG',
                        'separator',
                        'downloadPDF',
                        'downloadCSV',
                        'separator',
                        // 'viewData',
                        'openInCloud'
                    ]
                }
            }
        }
    }
};

export default GraphConfig;

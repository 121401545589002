// Libs
import React, { useState } from 'react';
import classnames from 'classnames';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';

const TypeText = props => {
    const { Component } = useComponentsPool();
    const [editMode, setEditMode] = useState(false);
    const [newValue, setNewValue] = useState(props.value);

    const setInputValue = () => {
        props.setNewValue(newValue);
        setEditMode(false);
    };

    return (
        <div className={classnames('type-text', props.number ? 'number-input' : '')}>
            {editMode && (
                <>
                    <Component
                        componentName={'Input'}
                        value={newValue}
                        label={''}
                        onChange={value => setNewValue(value)}
                        type={props.number ? 'number' : ''}
                    />
                    <div
                        role={'button'}
                        tabIndex={0}
                        className={'icon-container'}
                        onClick={() => setInputValue()}
                    >
                        <Component componentName={'Icon'} type={'check'} color={'green'} />
                    </div>
                </>
            )}
            {!editMode && (
                <>
                    <div className={'text'}><span>{props.value}</span></div>
                    <div
                        role={'button'}
                        tabIndex={0}
                        className={'edit-icon-container'}
                        onClick={() => setEditMode(true)}
                    >
                        <Component componentName={'Icon'} type={'edit'} />
                    </div>
                </>
            )}
        </div>

    );
};

export default TypeText;

// Libs
import React from 'react';
import classNames from 'classnames';

const RoundName = props => {
    const name = props.resource.name;

    return (
        <div
            className={classNames(['table-name'])}
            key={props.resource.name}
        >
            <div className="group">
                <div className={'title'}>{name}</div>
            </div>
        </div>
    );
};

export default RoundName;

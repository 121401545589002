// Library
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
// Actions
import {
    loginByToken, refreshTokens, checkTokenBeforeRequest, refreshTokensFunc, updateErrorFunc
} from '../authentication/actions';
import { setGeolocalisation } from './actions';
import { setWorkspaceName } from '../workspace/actions';
// Tools
import store from '../../store';

const dispatch = store.dispatch;

export const setRequestInterceptor = async () => {
    const accessJWT = localStorage.getItem('iot_rocket_access_JWT');
    const isAccessJWTExpired = () => moment(jwtDecode(accessJWT).exp * 1000).subtract(4, 'minutes')
        .isBefore(moment());
    if (accessJWT) {
        axios.interceptors.request.use(request => {
            const originalRequest = request;

            const isLoginRequest = request => !!(request.url && (request.url === 'auth/token' || request.url === 'auth/token/refresh')); // eslint-disable-line
            if (!isLoginRequest(originalRequest)) {
                if (isAccessJWTExpired()) {
                    return checkTokenBeforeRequest()
                        .then(res => {
                            dispatch(refreshTokensFunc(res.data));
                            originalRequest.headers.common.Authorization = `Bearer ${ res.data.access}`;
                            return Promise.resolve(originalRequest);
                        })
                        .catch(err => {
                            dispatch(updateErrorFunc('error'));
                            return Promise.reject(err);
                        });
                }
            }
            return request;
        }, err => {
            dispatch(updateErrorFunc('error'));
            return Promise.reject(err);
        });
    }
};

export const setUpApiUrlWithLocalStorage = () => {
    const lsApiUrl = 'iot_rocket_api_url';
    const isApp = window.location.hostname !== DOMAIN_NAME;
    let apiUrl = '';
    try {
        const appMerciYanisOnProd = window.location.href.includes('https://app.merciyanis.com');
        if (appMerciYanisOnProd) { // specific redirection for qrcodes
            localStorage.setItem('iot_rocket_workspace', '');
            localStorage.setItem('iot_rocket_api_url', 'https://api.merciyanis.com');
            localStorage.setItem('iot_rocket_websocket_url', '');
            apiUrl = 'https://api.merciyanis.com';
        } else {
            apiUrl = localStorage.getItem(lsApiUrl);
            if (!apiUrl) {
                if (isApp) {
                    // change the `app` part of URL to `api`
                    const splittedUrl = window.location.href.split('.');
                    splittedUrl[1] = 'api';

                    // remove path of URL
                    const url = splittedUrl.join('.');
                    const regexResult = /(^https?:\/\/[^/]*).*$/.exec(url);
                    apiUrl = regexResult[1];
                } else {
                    apiUrl = `${window.location.protocol}//api.${window.location.host}`;
                }
                localStorage.setItem(lsApiUrl, apiUrl);
            }
        }

        axios.defaults.baseURL = `${apiUrl}/`;
    } catch (e) {
        !IS_PRODUCTION && console.error(e); // eslint-disable-line
        throw new Error('Set up api url with localstorage has failed');
    }
};

export const setUpWebsocketUrlWithLocalStorage = (currentWorskpace = null) => {
    const lsWsUrl = 'iot_rocket_websocket_url';
    const isApp = window.location.hostname !== DOMAIN_NAME;
    try {
        let wsUrl = localStorage.getItem(lsWsUrl);
        let workspace = localStorage.getItem('iot_rocket_workspace');
        const appMerciYanisOnProd = window.location.href.includes('https://app.merciyanis.com');
        const appMerciYanisOnLocal = DOMAIN_NAME.includes('local.iot-rocket.space');

        if (appMerciYanisOnLocal) { // ON LOCAL
            if (currentWorskpace) { // if it's url qrcodes on mobile
                localStorage.setItem('iot_rocket_workspace', currentWorskpace);
                localStorage.setItem('iot_rocket_api_url', `http://${currentWorskpace}.api.local.iot-rocket.space`);
                localStorage.setItem(lsWsUrl, 'ws://websocket.local.iot-rocket.space/ws/' + currentWorskpace + '/');
                if (currentWorskpace !== workspace) {
                    window.location.reload();
                }
            } else if (workspace) { // if workspace is specified in local storage
                localStorage.setItem('iot_rocket_workspace', workspace);
                localStorage.setItem('iot_rocket_api_url', `http://${workspace}.api.local.iot-rocket.space`);
                localStorage.setItem(lsWsUrl, 'ws://websocket.local.iot-rocket.space/ws/' + workspace + '/');
            } else { // by default
                localStorage.setItem('i18nextLng', 'fr');
                localStorage.setItem('iot_rocket_workspace', 'demo');
                localStorage.setItem('iot_rocket_api_url', 'http://demo.api.local.iot-rocket.space');
                localStorage.setItem(lsWsUrl, 'ws://websocket.local.iot-rocket.space/ws/demo/');
                window.location.reload();
            }
        } else if (appMerciYanisOnProd) { // ON PROD without workspace = on https://app.merciyanis.com
            if (currentWorskpace) { // specific redirection for qrcodes
                const urlSuffixe = window.location.href.split('https://app.merciyanis.com');
                if (urlSuffixe?.length > 1) {
                    window.location.href = `https://${currentWorskpace}.app.merciyanis.com${urlSuffixe[1]}`;
                } else {
                    window.location.href = `https://${currentWorskpace}.app.merciyanis.com`;
                }
            } else {
                localStorage.setItem('iot_rocket_workspace', '');
                localStorage.setItem('iot_rocket_api_url', 'https://api.merciyanis.com');
                localStorage.setItem(lsWsUrl, '');
            }
        } else if (!wsUrl && isApp) { // ON PROD other cases
            if (!workspace) {
                const regexResultWorkspace = /^https?:\/\/([^.]*).*$/.exec(window.location.href);
                workspace = regexResultWorkspace[1];
            }
            wsUrl = `${window.location.protocol === 'http:' ? 'ws://' : 'wss://'}websocket.${DOMAIN_NAME}/ws/${workspace}/`;
            localStorage.setItem(lsWsUrl, wsUrl);
        }
    } catch (e) {
        !IS_PRODUCTION && console.error(e); // eslint-disable-line
        throw new Error('Set up websocket url with localstorage has failed');
    }
};

export const setUpWorkspaceWithLocalStorage = () => {
    const lsWorkspace = 'iot_rocket_workspace';
    try {
        const workspace = localStorage.getItem(lsWorkspace);
        if (!workspace) {
            const regexResultWorkspace = /^https?:\/\/([^.]*).*$/.exec(window.location.href);
            localStorage.setItem(lsWorkspace, regexResultWorkspace[1]);
            dispatch(setWorkspaceName(regexResultWorkspace[1]));
        } else {
            dispatch(setWorkspaceName(workspace));
        }
    } catch (e) {
        !IS_PRODUCTION && console.error(e); // eslint-disable-line
        throw new Error('Set up workspace with localstorage has failed');
    }
};

export const setUpGeolocalisation = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
            dispatch(setGeolocalisation({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            }));
        });
    }
};

export const autoLogin = async () => {
    const lsRefreshJWT = 'iot_rocket_refresh_JWT';
    const refreshJWT = localStorage.getItem(lsRefreshJWT);

    if (refreshJWT && refreshJWT.length > 0) {
        await dispatch(loginByToken(refreshJWT));
    }
};

export const refreshToken = async () => {
    const lsRefreshJWT = 'iot_rocket_refresh_JWT';
    const refreshJWT = localStorage.getItem(lsRefreshJWT);
    if (!refreshJWT) return;
    const momentAccessJWTExpiration = moment(jwtDecode(refreshJWT).exp * 1000)
        .subtract(4, 'minutes')
        .isBefore(moment());

    if (!store.getState().authentication.isLogged || !momentAccessJWTExpiration) {
        return;
    }
    await dispatch(refreshTokens());
};

export const setAutoRelogger = () => {
    try {
        setInterval(refreshToken, 4 * 60 * 1000); // 4 minutes
    } catch (e) {
        console.error(e); // eslint-disable-line
        throw new Error('Set up relogger with localstorage has failed');
    }
};

export const loadPlugins = async () => {
    const pluginModules = [];
    PLUGINS.forEach(plugin => {
        // try {
        pluginModules.push(require(`../../plugins/${plugin}/app/`).bootstrap()); // eslint-disable-line
        // } catch (err) {
        //     console.error(err); // eslint-disable-line
        //     throw()
        // }
    });
};

export const loadPluginsData = async () => {
    const pluginModules = [];
    PLUGINS.forEach(plugin => {
        try {
            pluginModules.push(require(`../../plugins/${plugin}/app/`).getDataOnLogin()); // eslint-disable-line
        } catch (err) {
            console.error(err); // eslint-disable-line
            throw new Error(`Cannot load data on login for module ${plugin}`);
        }
    });
};

export default setRequestInterceptor;

// Libs
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
// Components
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Fab from '@material-ui/core/Fab';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { editTicket, resetSelectedTicket } from '../../tickets/actions';
import { getGroupList, resetSelectedGroup } from '../../groups/actions';
import { Toast } from '../../notification/components';
import { useFormState } from '../../layout/forms/api/useFormState';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
import { useModal } from '../ModalContext';
// SCSS
import { useFootBar } from '../../footbar/FootBarContext';

import { resetFilters } from '../../navbar/actions';
import { resetSelectedDevice } from '../../devices/actions';
import {emptyInstanceEventsList} from '../../rounds/actions';

const tableTooltip = { tooltip: 'table-tooltip' };

const DialogTitle = memo(props => {
    const { children, onClose } = props;
    const { Component } = useComponentsPool();
    return (
        <MuiDialogTitle
            disableTypography
            className={classNames(['root-title-modal', { 'modal-disabled': props.loading }])}
        >
            <Typography className={'head'} component={'div'}>{children}</Typography>
            {onClose ? (
                <IconButton href={''} aria-label="Close" className={'close-button-modal'} onClick={onClose}>
                    <Component componentName={'Icon'} type={'close'} />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const ModalDialog = props => {
    const { Component } = useComponentsPool();
    const modal = useModal();
    const [t] = useTranslation();
    const [open, setOpen] = useState(props.open || false);
    const [isLoading, setIsLoading] = useState(props.loading);
    const [disabled, setDisabled] = useState(props.onSubmitDisabled);
    const [closeButtonLabelTitle, setCancelButtonLabelTitle] = useState(t(props.closeButtonLabel));
    const [submitButtonLabelTitle, setSubmitButtonLabelTitle] = useState(t(props.submitButtonLabel));
    const [steps] = useState(props.steps);
    const [disabledStep, setDisabledStep] = useState(props.validStep || false);
    const [datas, setDatas] = useState({
        head: props.head,
        content: props.content,
        footer: props.footer,
        closeButton: props.closeButton,
        submitButton: props.submitButton
    });
    const footBar = useFootBar();
    const dispatch = useDispatch();

    const handleSubmit = e => {
        e.preventDefault();
        if (props.onHandleSubmit && steps === 0 && !disabled) props.onHandleSubmit();
    };

    useEffect(() => {
        setDisabled(props.onSubmitDisabled === undefined ? false : props.onSubmitDisabled);
    }, [props.onSubmitDisabled]);

    useEffect(() => {
        setIsLoading(props.loading);
        setDatas({
            head: props.head,
            content: props.content,
            footer: props.footer,
            closeButton: props.closeButton,
            submitButton: props.submitButton
        });
        if (!open) {
            setOpen(true);
        }
    }, [props.loading]);

    useEffect(() => {
        setDatas({
            head: props.head,
            content: props.content,
            footer: props.footer,
            closeButton: props.closeButton,
            submitButton: props.submitButton
        });
    }, [props.content]);

    useEffect(() => {
        setDisabledStep(props.disabledStep);
    }, [props.disabledStep]);

    useEffect(() => {
        setSubmitButtonLabelTitle(t(props.submitButtonLabel));
    }, [props.submitButtonLabel]);

    useEffect(() => {
        setCancelButtonLabelTitle(t(props.closeButtonLabel));
    }, [props.closeButtonLabel]);

    const { formState } = useFormState();

    const handleClose = () => {
        if (props.customModalRedirection) { return modal.open(props.customModalRedirection); }
        if (props.modalName === 'roundCreatePopup') {
            dispatch(resetFilters());
        }
        let isClosed = false;
        if (isMobile) {
            if (footBar.isError) {
                toast(
                    <Toast
                        isConfirm
                        message={t(`notifications:ticket_modifications_error_mobile`)}
                        icon={'frown'}
                        type={'error-custom'}
                    />, {
                        position: toast.POSITION.BOTTOM_LEFT,
                        className: 'normal',
                        bodyClassName: 'notification-body grow-font-size',
                        progressClassName: 'error-custom-progress-bar'
                    }
                );
            } else {
                // On mobile there is editing modal only on ticket details popup
                if (footBar.isEdited && footBar.getEditionMode()) {
                    return dispatch(editTicket(formState.id, formState))
                        .then(() => {
                            dispatch(getGroupList());
                            setOpen(false);
                            setIsLoading(false);
                            setDatas({});
                            modal.close();
                            dispatch(resetSelectedTicket());
                            dispatch(resetSelectedDevice());
                            dispatch(resetSelectedGroup());
                            dispatch(emptyInstanceEventsList());
                            footBar.set({ editionMode: false });
                        });
                } else {
                    setOpen(false);
                    setIsLoading(false);
                    setDatas({});
                    modal.close();
                    if (props.modalName === 'ticketDetailsPopup') {
                        dispatch(resetSelectedTicket());
                    }
                    dispatch(resetSelectedDevice());
                    dispatch(resetSelectedGroup());
                    dispatch(emptyInstanceEventsList());
                    footBar.set({ editionMode: false });
                }
                isClosed = true;
            }
        } else {
            setOpen(false);
            setIsLoading(false);
            setDatas({});
            modal.close();
            if (props.modalName === 'ticketDetailsPopup') {
                dispatch(resetSelectedTicket());
            }
            dispatch(resetSelectedDevice());
            dispatch(resetSelectedGroup());
            dispatch(emptyInstanceEventsList());
            footBar.set({ editionMode: false });
            isClosed = true;
        }
        if (isClosed) {
            const location = window.location.href.split('?key=');
            if (location?.length > 1) {
                window.history.replaceState({}, '', location[0]);
            }
        }
    };

    useEffect(() => {
        if (props.closeModal) { handleClose(); }
    }, [props.closeModal]);

    const history = useHistory();
    const customButtonStyle = isMobile && props?.classNames === 'qrcode-scanner-popup-mobile';
    const getCloseComponent = () => {
        if (footBar.isEdited && !isMobile) {
            return (
                <Tooltip
                    placement={'left'}
                    key={'tooltip_close_popup'}
                    classes={tableTooltip}
                    title={
                        (<Component
                            componentName={'Description'}
                            content={t('common:switch_button_mode_eye_disabled')}
                        />)}
                >
                    <Fab className={'button-close-modal disabled'}>
                        <Component componentName={'Icon'} type={'close'} />
                    </Fab>
                </Tooltip>

            );
        }
        return !props.removeModalCloseButton && (
            <Fab
                className={classNames([
                    customButtonStyle ? 'custom-button-close-modal' : '',
                    props.closeButtonHideAndReappear ? 'button-close-modal close-button-hide-reappear' : 'button-close-modal'
                ])}
                onClick={handleClose}
                disabled={footBar.isEdited && !isMobile}
            >
                <Component componentName={'Icon'} type={'close'} />
            </Fab>
        );
    }

    return (
        <Dialog
            className={classNames([
                'modal-container',
                {
                    'with-foot-bar': props.withFootBar,
                    'no-overflow': props.noOverflow,
                    'modal-mobile': isMobile
                },
                `modal-container-${props.modalContainer}`
            ])}
            fullWidth
            fullScreen
            open={open}
            aria-labelledby="customized-dialog-title"
            aria-describedby="alert-dialog-description"
            onEscapeKeyDown={handleClose}
        >
            { FOOTBAR_POSITION !== 'top' || (FOOTBAR_POSITION === 'top' && !modal.getWithFootBar())
                ? getCloseComponent()
                : null
            }
            <form onSubmit={e => handleSubmit(e)}>
                <div className={classNames([
                    'modal-dialog',
                    FOOTBAR_POSITION === 'top' ? 'footbar-is-top' : null,
                    props.classNames
                ])}
                >
                    <Component componentName={'LoaderBarTop'} className={'modal-loader'} isLoading={isLoading} />
                    {datas.head !== null && (
                        <div style={{ width: props.contentWidth }} className={'dialog-title'}>
                            { steps > 0 && <Component componentName={'Stepper'} steps={steps} activeStep={props.currentStep} /> }
                            <DialogTitle id="customized-dialog-title" loading={isLoading}>
                                {datas.head}
                            </DialogTitle>
                        </div>
                    )}
                    <MuiDialogContent style={props.isRelativeModal ? { position: 'relative', width: props.contentWidth } : { width: props.contentWidth, height: props.contentHeight }} className={classNames(['modal-content-iot-rocket', { 'modal-disabled': isLoading, 'no-overflow': props.noOverflow }])}>
                        {Array.isArray(datas.content) ? datas.content.map((content, idx) => (
                            <div
                                key={idx}
                                className={'side-content'}
                                style={{ width: content.width }}
                            >
                                { content.content }
                            </div>
                        )) : datas.content }
                    </MuiDialogContent>
                    {(datas.closeButton || datas.submitButton || steps > 0) && (
                        <MuiDialogActions className={classNames(['dialog-action-root', { 'modal-disabled': isLoading }])}>
                            {
                                steps > 0 ? (
                                    <React.Fragment>
                                        <Component
                                            componentName={'Button'}
                                            onClick={() => props.onHandlePreviousStep()}
                                            text={t('common:prev')}
                                            className={'back-button'}
                                            disabled={props.currentStep - 1 < 0}
                                            startAdornment
                                            icon={'back'}
                                        />
                                        <Component
                                            componentName={'Button'}
                                            onClick={() => props.onHandleNextStep()}
                                            text={submitButtonLabelTitle}
                                            className={'next-button'}
                                            disabled={disabledStep}
                                            endAdornment
                                            icon={'next'}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {datas.closeButton && (
                                            <Component
                                                componentName={'Button'}
                                                className={'close'}
                                                onClick={handleClose}
                                                text={closeButtonLabelTitle}
                                            />
                                        )}
                                        {datas.submitButton && (
                                            <Component
                                                componentName={'Button'}
                                                className={'submit'}
                                                onClick={() => props.onHandleSubmit()}
                                                text={submitButtonLabelTitle}
                                                disabled={disabled}
                                            />
                                        )}
                                    </React.Fragment>
                                )
                            }
                        </MuiDialogActions>
                    )}
                </div>
            </form>
        </Dialog>
    );
};

ModalDialog.defaultProps = {
    closeButtonLabel: 'common:cancel',
    contentWidth: '550px',
    currentStep: 0,
    disabledStep: false,
    head: null,
    loading: false,
    noOverflow: false,
    onHandleNextStep: undefined,
    onHandlePreviousStep: undefined,
    onHandleSubmit: undefined,
    onSubmitDisabled: false,
    open: false,
    steps: 0,
    submitButtonLabel: 'common:save',
    withFootBar: false
};

ModalDialog.propTypes = {
    closeButtonLabel: PropTypes.string,
    contentWidth: PropTypes.string,
    currentStep: PropTypes.number,
    disabledStep: PropTypes.bool,
    head: PropTypes.node,
    loading: PropTypes.bool,
    noOverflow: PropTypes.bool,
    onHandleNextStep: PropTypes.func,
    onHandlePreviousStep: PropTypes.func,
    onHandleSubmit: PropTypes.func,
    onSubmitDisabled: PropTypes.bool,
    open: PropTypes.bool,
    steps: PropTypes.number,
    submitButtonLabel: PropTypes.string,
    withFootBar: PropTypes.bool
};

export default withMobileDialog()(ModalDialog);

// Libs
import React from 'react';
// Context
import { useComponentsPool } from '../../ComponentsPool';
// SCSS
import './style.user.scss';
import store from '../../store';

const dispatch = store.dispatch;

const Users = () => {
    const { Component } = useComponentsPool();
    return <Component componentName={'UsersIndex'} />;
};

export default Users;

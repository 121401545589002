import { useLayoutEffect, useState } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

/* Detect resize event on div or other */
const getRect = element => {
    if (!element) {
        return {
            bottom: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            width: 0
        };
    }
    return element.getBoundingClientRect();
};

export const UseRect = (ref, timer) => {
    const [rect, setRect] = useState(getRect(ref ? ref.current : null));
    const [time] = useState(timer);

    useLayoutEffect(() => {
        const element = ref.current;
        if (!element) return;
        const debouncedHandleResize = debounce(() => {
            if (!ref.current) return;
            // Update client rect
            setRect(getRect(ref.current));
        }, time);
        if (typeof ResizeObserver === 'function') {
            let resizeObserver = new ResizeObserver(() => debouncedHandleResize());
            resizeObserver.observe(element);
            return () => { // eslint-disable-line
                if (!resizeObserver) return;
                resizeObserver.disconnect();
                resizeObserver = null;
            };
        }
        // Browser support, remove freely
        window.addEventListener('resize', debouncedHandleResize);
        return () => window.removeEventListener('resize', debouncedHandleResize); // eslint-disable-line
    }, [ref.current]);

    return rect;
};


UseRect.defaultProps = {
    timer: 100
};

UseRect.propTypes = {
    ref: PropTypes.any.isRequired,
    timer: PropTypes.number
};

export default UseRect;

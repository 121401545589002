import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { checkAccountActivationToken } from '../actions';

const AccountActivation = props => {
    const dispatch = useDispatch();
    const { location } = props;
    const token = location.search.split('?token=')[1];
    dispatch(checkAccountActivationToken(token));
    return <Redirect to="/login" />;
};

export default AccountActivation;

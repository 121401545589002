import {
    concat, find, includes, uniq, isEmpty
} from 'lodash';

// Get all device IDS from a group
export const getDeviceIdByGroupId = (list, groupId) => {
    const devices = [];
    list?.forEach(device => {
        if (device.group_ids.length > 0) {
            device.group_ids?.forEach(group => {
                if (group === groupId) {
                    devices.push(device.device_id);
                }
            });
        }
    });
    return devices;
};

// Get all users from a group
export const getUsersByGroupId = (list, groupId) => {
    const users = [];
    list?.forEach(user => {
        if (user.group_memberships?.length > 0) {
            user.group_memberships?.forEach(group_membership => {
                if (group_membership.group.group_id === groupId) {
                    users.push(user);
                }
            });
        }
    });
    return users;
};

// Get all users not in a group
export const getUsersNotInGroupByGroupId = (list, groupId) => {
    const users = [];
    list?.forEach(user => {
        if (user.group_memberships.length > 0) {
            const group_member_ship_id = user.group_memberships.map(GM => GM.group.group_id) || [];
            if (!includes(group_member_ship_id, groupId)) {
                users.push(user);
            }
        } else {
            users.push(user);
        }
    });
    return users;
};

// Get if group inactive is disable on the device
export const getDisableGroup = (device, group, settings) => {
    if (device && !device.hardware_ids) return false;
    return group.group_id === settings.inactive_group_id && device.hardware_ids.length === 0;
};

// Get all device not in a group
export const getGroupNotInDeviceByDevice = (list, devices, settings) => {
    const groups = [];
    list?.forEach(group => {
        const index = devices.findIndex(device => includes(device.group_ids, group.group_id));
        if (index === -1) {
            groups.push({
                ...group,
                isDisable: getDisableGroup(devices[index], group, settings)
            });
        }
    });
    return groups;
};

// Get all users not in a group
export const getGroupNotInUsersByUser = (list, users) => {
    const groups = [];
    list?.forEach(group => {
        if (users.findIndex(user => includes(user.group_memberships
            .map(GM => GM.group.group_id), group.group_id)) === -1) {
            groups.push(group);
        }
    });
    return groups;
};

// Get all users from a group
export const checkGroupInUserById = (user, groupId) => user.group_memberships.some(groupMembership => !includes(groupId, groupMembership.group.group_id)); // eslint-disable-line

export const getDeviceNamesByGroupId = (devicesList, groupId) => {
    const devices = [];
    devicesList?.forEach(device => {
        if (device.group_ids?.length > 0) {
            device.group_ids?.forEach(group => {
                if (group === groupId) {
                    devices.push(device.device_name);
                }
            });
        }
    });
    return devices;
};

export const getGroupNameFromId = (groups, groupId) => {
    const group = find(groups, obj => obj.group_id === groupId);
    return group?.group_name || '';
};

export const getGroupByDeviceGroupsIds = (list, groupIds) => {
    const groups = [];
    list?.forEach(group => {
        if (includes(groupIds, group.group_id)) {
            groups.push(group);
        }
    });
    return groups;
};

export const getDeviceIdsByGroupIds = (list, groupIds) => {
    const devices = [];
    list?.forEach(device => {
        if (device.group_ids.length > 0) {
            groupIds?.forEach(groupId => {
                device.group_ids?.forEach(group => {
                    if (group === groupId) {
                        devices.push(device.device_id);
                    }
                });
            });
        }
    });
    return uniq(devices);
};

export const getGroupById = (groups, groupId) => find(groups, group => group.group_id === groupId);

export const isBatimentHasFloors = (groups, groupId) => !isEmpty(getGroupById(groups, groupId)?.group_ids);

export const getGroupByNameAndType = (groups, groupName, groupType) => find(groups, group => group?.group_name?.trim() === groupName?.trim() && group?.custom_field?.type === groupType);

// Transform an array of ids into array of name (for debug purposes only)
export const withNames = (groups, groupIds) => {
    const names = [];
    groupIds?.forEach(group => { names.push(getGroupNameFromId(groups, group)); });
    return names;
};

export const getGroupsOfGroup = (groups, groupId) => {
    const fetchGroups = (id, checkedGroups) => {
        const selectedGroup = find(groups, group => group.group_id === id);
        if (includes(checkedGroups, id)) return checkedGroups;
        let allGroups = concat(checkedGroups, id);
        if (selectedGroup) {
            selectedGroup.group_ids?.forEach(group => {
                allGroups = uniq(concat(allGroups, fetchGroups(group, allGroups)));
            });
        }
        return allGroups;
    };
    return fetchGroups(groupId, []);
};

export const formGroupsOptions = (t, groups) => {
    const groupsOptions = [];
    groups?.forEach(group => {
        groupsOptions.push({ id: group.group_id, value: group.group_name });
    });
    return groupsOptions;
};

export const getTraductionOfGroup = (group_name, t) => {
    if (!group_name) return '';
    const traduced_group_name = t(`groups:${group_name.toUpperCase()}`);
    // pas de traduction disponible
    if (traduced_group_name.toUpperCase() === group_name.toUpperCase()) {
        return group_name;
    }
    return traduced_group_name;
};

export const getUserGroupTypeIds = (user, type) => {
    const groups = [];
    user.group_memberships
        ?.filter(membership => membership.group.custom_field?.type === type)
        ?.forEach(membership => { groups.push(membership.group.group_id); });
    return groups;
};

export const getLocationGroups = groupList => groupList.filter(group => group?.custom_field?.type === 'FLOOR'
        || group?.custom_field?.type === 'POSITION'
        || group?.custom_field?.type === 'BATIMENT');

export const getCorrespondingGroups = (objectList, groupList) => {
    const groups = [];
    objectList?.forEach(object => {
        const building = groupList.find(group => group.group_id === object?.batiment_group_id);
        const floor = groupList.find(group => group.group_id === object?.floor_group_id);
        const position = groupList.find(group => group.group_id === object?.position_group_id);
        if (!groups.includes(building)) groups.push(building);
        if (!groups.includes(floor)) groups.push(floor);
        if (!groups.includes(position)) groups.push(position);
    });
    return groups;
};

export const getLocationAsFilters = (objectList, groupList) => {
    const groups = [];
    const locationsGroupList = getLocationGroups(groupList);
    objectList?.forEach(object => {
        const building = locationsGroupList.find(group => group.group_id === object?.batiment_group_id);
        const floor = locationsGroupList.find(group => group.group_id === object?.floor_group_id);
        const position = locationsGroupList.find(group => group.group_id === object?.position_group_id);
        if (building) groups.push(building);
        if (floor) groups.push(floor);
        if (position) groups.push(position);
    });
    return groups.map(group => ({ filterName: group.group_name, filterIcon: group.custom_field.type, filterBy: group.custom_field.type?.toLowerCase() }));
};

export const getMultipleLocationAsFilters = (objectList, groupList) => {
    let groups = [];
    const locationsGroupList = getLocationGroups(groupList);
    objectList?.forEach(object => {
        groups = groups.concat(locationsGroupList.filter(
            group => object?.batiment_group_ids.includes(group.group_id)
                        || object?.floor_group_ids.includes(group.group_id)
                        || object?.position_group_ids.includes(group.group_id)
        ));
    });
    return groups.map(group => ({ filterName: group.group_name, filterIcon: group.custom_field.type, filterBy: group.custom_field.type?.toLowerCase() }));
};

export const sortByBuildingName = (a, b) => {
    if (a.batiment_group_name > b.batiment_group_name) return 1;
    if (b.batiment_group_name > a.batiment_group_name) return -1;

    return 0;
};

export const getDeviceOfGroupFromReference = (deviceGroups, reference) => {
    let result;
    deviceGroups?.forEach(group => {
        const flag = group.devices.find(device => device.device_reference === reference);
        if (flag) { result = flag; }
    });
    return result;
};

export const getGroupsListFromType = (groups, type) => groups.filter(group => group?.custom_field?.type === type);

export const getGroupIdsFromGroupNamesAndGroupType = (groupNames, groupList, groupType) => {
    const groupsListByType = getGroupsListFromType(groupList, groupType);
    return groupNames?.map(
        groupName => groupsListByType.find(group => group.group_name === groupName)?.group_id
    );
};

export const getJobGroupIdByExpectedJobName = (expectedJobName, groupList) => {
    return getGroupsListFromType(groupList, 'JOB').find(jobGroup => jobGroup.group_name === expectedJobName)?.group_id;
};

export const getTicketsFromBatiment = (buildingId, ticketList) => ticketList
    .filter(element => element.custom_field?.batiment_group_id === buildingId) || [];

export const getTicketsFromSkill = (skillId, ticketList) => ticketList
    .filter(element => element.custom_field?.skill_group_id === skillId) || [];

export const getDeviceFromBatiment = (buildingId, deviceList) => deviceList
    .filter(element => element.custom_field?.batiment_group_id === buildingId) || [];

export const getDeviceFromJob = (jobId, deviceList) => deviceList
    .filter(element => element.custom_field?.job_group_ids?.includes(jobId)) || [];

// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// Component
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { isEmpty } from 'lodash';
import { getTemplatesFromSkill } from '../../templates/helper';
import { isBatimentHasFloors } from '../../groups/helper';

const TicketToggleGroups = props => {
    const [group, setGroup] = useState(props.selected);
    const required = props.required;
    const [options, setOptions] = useState(props.options);
    const groupsList = useSelector(state => state.groups.list);
    const workspaceSettings = useSelector(state => state.workspace.settings);

    const titleMode = workspaceSettings.mode_title ? workspaceSettings.mode_title : 'close';
    const floorMode = workspaceSettings.mode_floor ? workspaceSettings.mode_floor : 'close';

    const changeGroup = (event, newGroup) => {
        if (!required) {
            setGroup(newGroup);
            props.onChange(newGroup);
        } else if (required && newGroup !== null) {
            setGroup(newGroup);
            props.onChange(newGroup);
        }
    };

    useEffect(() => {
        if (props.isCreateTicketPopup && !isEmpty(props.options)
            && props.groupType === 'BATIMENT' && floorMode === 'close') {
            setOptions(props.options.filter(g => isBatimentHasFloors(groupsList, g.id)));
        }
    }, []);


    return (
        <div className={'select-wrapper'} style={{marginTop: 20, marginBottom: 20}}>
            <ToggleButtonGroup
                exclusive
                value={group}
                onChange={changeGroup}
                classes={{
                    root: 'toggle_button_group'
                }}
            >
                {
                    options && options.map(option => (
                        <ToggleButton
                            classes={{
                                root: 'toggle_button',
                                selected: 'toggle_button-selected selected_group',
                                label: 'toggle_button_label'
                            }}
                            key={option?.id}
                            value={option?.id}
                        >
                            {option.value}
                        </ToggleButton>
                    ))
                }
            </ToggleButtonGroup>
        </div>
    );
};

export default TicketToggleGroups;

import { Modal } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import { useComponentsPool } from '../../../../../ComponentsPool';
import Button from '../../../../layout/components/Button';
import { getStringParamValueFromRrule, traduceRRule } from '../../../helper';
import RoundStepsLists from '../../StepComponents/RoundStepsLists';
import './CalendarCreationPopup.scss';
import { createRound, getInstancesFromTo } from '../../../actions';
import { resetFilters } from '../../../../navbar/actions';
import { getDeviceList } from '../../../../devices/actions';
import { isValid } from '../../../../layout/helper';
import useRouter from '../../../../../utils/hook/useRouter';
import { VIEW_LIST } from '../../RoundViewSwitcher/RoundViewSwitcher';

const CalendarCreationPopup = ({ start, end, onClose, currentWeekFirstDay }) => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const currentUserRole = useSelector(state => state.users.currentUser.workspace_role?.workspace_role_name);
    const [underEdition, setUnderEdition] = useState({
        devices_ids: [],
        started_at: moment(start).set({ s: 0 }).format(),
        ended_at: moment(end).set({ s: 59 }).format(),
        name: '',
        rrule: '',
        assignees_ids: [],
        followers_ids: [],
        invite_followers_ids: [],
        round_expected_jobs: []
    });
    const [recurrence, setRecurrence] = useState(false);
    // when the name of the round changes
    const onNameChange = value => {
        const newName = value === 'error_field' ? '' : value;
        setUnderEdition({
            ...underEdition,
            name: newName
        });
    };

    // if we change the recurrence or the round's steps
    const changeState = newState => {
        setUnderEdition({
            ...underEdition,
            ...newState
        });
    };

    const [disableSave, setDisableSave] = useState(true);
    // check if the save needs to be disabled everytime we change a field on the popup
    useEffect(() => {
        const untilDate = getStringParamValueFromRrule(underEdition.rrule, 'UNTIL=');
        const disableWhenReccurrence = recurrence && untilDate
        && moment(underEdition.started_at).set({ h: 0, m: 0, s: 0 }).isAfter(moment(untilDate).set({ h: 0, m: 0, s: 59 }));
        setDisableSave(
            isValid(underEdition.name) || underEdition.devices_ids.length === 0 || !underEdition.rrule
            || !underEdition.started_at || !underEdition.ended_at
            || moment(underEdition.ended_at).format('HH:mm') === moment(underEdition.started_at).format('HH:mm')
            || disableWhenReccurrence
        );
    }, [underEdition, recurrence]);

    const onChangeRecurrence = () => {
        setRecurrence(!recurrence);
        setUnderEdition({
            ...underEdition,
            started_at: moment(start).set({ s: 0 }).format(),
            ended_at: moment(end).set({ s: 59 }).format(),
            rrule: ''
        });
    };

    const { query } = useRouter();
    const devices = useSelector(state => state.devices.list);
    const onSave = async () => {
        // prevent multiple round creation
        setDisableSave(true);
        const formattedData = { ...underEdition };
        // TODO passer vraie valeur de validation_count
        formattedData.devices = underEdition.devices_ids.map(device_id => ({ id: device_id, validation_count: 1 }));
        delete formattedData.devices_ids;
        await dispatch(createRound(formattedData, query?.view, devices, currentUserRole)).then(res => {
            traduceRRule(t, res.value.rrule, res.value.name, res.value.started_at, res.value.ended_at, res.value.is_until_end_of_month, true);
            dispatch(resetFilters());
            // get the instances of the current calendar week
            dispatch(getInstancesFromTo(
                currentWeekFirstDay.clone().startOf('isoWeek').format('YYYY-MM-DD'),
                currentWeekFirstDay.clone().endOf('isoWeek').format('YYYY-MM-DD')
            ));
        });
        onClose(true);
    };
    return (
        <Modal open onClose={onClose}>
            <div className="calendar-creation-popup">
                <div className="header">
                    <div className="round-clouds-wrapper">
                        <Component componentName={'Icon'} type={'roundClouds'} />
                    </div>
                    <div className="name">
                        <IconButton className="close-button" onClick={onClose}>
                            <Component componentName={'Icon'} type={'close'} />
                        </IconButton>
                        <div className="round-title">
                            <div className="round-icon">
                                <Component componentName={'Icon'} type={'roundsFilled2'} />
                            </div>
                            <div className="name-input">
                                <div className="name-label">
                                    <div>{t('rounds:round_name')}</div>
                                </div>
                                <div>
                                    <Component
                                        componentName={'EditableField'}
                                        renderValue={'Input'}
                                        required
                                        title=""
                                        props={{
                                            rules: ['maxLength-64'],
                                            autoFocus: true,
                                            onChange: onNameChange
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="period">
                        <div className="period-switch">
                            <div className="left-label">
                                {t('rounds:punctual')}
                            </div>
                            <FormControlLabel
                                control={(
                                    <Switch
                                        checked={recurrence}
                                        onChange={onChangeRecurrence}
                                        name="recurrence-switch"
                                        color="primary"
                                    />
                                )}
                                label={t('rounds:recurrent')}
                            />
                        </div>
                        <div className={classNames(['period-selector', recurrence ? 'reccurent-period-selector' : 'one-time-period-selector'])}>
                            {recurrence ? (
                                <>
                                    <Component
                                        componentName={'RoundRecurrentRruleAndDateFields'}
                                        changeState={changeState}
                                        startDate={start}
                                        started_at={underEdition.started_at}
                                        ended_at={underEdition.ended_at}
                                        rrule={underEdition.rrule}
                                    />
                                </>
                            ) : (
                                <>
                                    <Component
                                        componentName={'RoundOneTimeRruleAndDateFields'}
                                        changeState={changeState}
                                        startDate={start}
                                        started_at={underEdition.started_at}
                                        ended_at={underEdition.ended_at}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="content">
                    <RoundStepsLists
                        devices_ids={underEdition.devices_ids}
                        changeState={changeState}
                    />
                </div>
                <Box p={2} className="footer">
                    <Button onClick={onClose} text={t('common:cancel')} className="close" />
                    <Button onClick={onSave} text={t('common:create')} className="next" disabled={disableSave} />
                </Box>
            </div>
        </Modal>
    );
};

export default CalendarCreationPopup;

// Libs
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Components
import { useComponentsPool } from '../../../../ComponentsPool';
import { createTag } from '../../actions';
import { useFootBar } from '../../../footbar/FootBarContext';

const TagsCreatableSelect = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const footbar = useFootBar();
    const isEditionMode = footbar.getEditionMode();
    const currentUserRole = useSelector(state => state.users.currentUser.workspace_role?.workspace_role_name);
    const tags = useSelector(state => state.tickets?.tags);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(tags.map(tag => ({ value: tag.id, label: tag.title })));
    }, [tags]);

    const handleCreate = inputValue => {
        setIsLoading(true);
        dispatch(createTag({ title: inputValue }))
            .then(res => {
                const newOption = { value: res.value.id, label: res.value.title };
                setOptions(prev => [...prev, newOption]);
                setIsLoading(false);
                const selectedTags = [...props.selected];
                selectedTags.push(newOption.value);
                props.onChange(selectedTags);
            });
    };

    const maxVisibleTags = 3;
    return (
        <>
            <Component
                key={'tickets_tags'}
                componentName={'CreatableSelectComponent'}
                renderOnlySelect={!isEditionMode}
                canCreate={currentUserRole === 'Owner' || currentUserRole === 'Admin'}
                placeholder={''}
                isMulti
                maxLength={16}
                maxVisible={maxVisibleTags}
                isClearable={false} // user cannot delete all of it's tags at once
                isLoading={isLoading}
                options={options}
                value={props.selected?.sort().map(tagId => ({ value: tagId, label: tags.find(tag => tag.id === tagId)?.title }))}
                onChange={selectedTags => { props.onChange(selectedTags.map(tag => tag.value)?.sort()); }}
                formatCreateLabel={values => `${t('common:create')} "${values}"`}
                noOptionsMessage={() => t('tickets:no_tags')}
                onCreateOption={handleCreate}
            />
        </>

    );
};

export default TagsCreatableSelect;

// Libs
import React from 'react';
// Context
import { useComponentsPool } from '../../ComponentsPool';
// SCSS
import './style.dashboard.scss';

const Dashboards = () => {
    const { Component } = useComponentsPool();
    return <Component componentName={'DashboardsIndex'} />;
};

export default Dashboards;

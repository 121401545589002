// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
// Context
import {
    cloneDeep, find, remove
} from 'lodash';
import { makeStyles } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import { isMobile } from 'react-device-detect';
import SwipeableViews from 'react-swipeable-views';
import { toast } from 'react-toastify';
import { useComponentsPool } from '../../../ComponentsPool';
import { useModal } from '../../modal/ModalContext';
// Actions
import {
    deleteTickets, updateTicket, getTickets
} from '../actions';
import { useFootBar } from '../../footbar/FootBarContext';

// isMobile
import { Toast } from '../../notification/components';

import { enableDeleteOnTicket, findTicket, openTicket } from '../helper';
import { EVENT_MOVE_TICKET, segmentTrack } from '../../layout/helper';

export default props => {
    const { Component } = useComponentsPool();
    const modal = useModal();
    const dispatch = useDispatch();
    const isDragging = props.isDragging;
    const footbar = useFootBar();
    const currentUser = useSelector(state => state.users.currentUser);
    const groupList = useSelector(state => state.groups.list);
    const [isSelected, setIsSelected] = useState(false);
    const [t] = useTranslation();
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const ticketCounter = props.ticket?.custom_field?.counter && props.ticket?.custom_field?.counter !== '1' && props.ticket?.custom_field?.counter !== 1
        ? props.ticket.custom_field?.counter
        : null;
    const tickets = useSelector(state => state.tickets);
    const notifications = useSelector(state => state.navbar.notificationsList);
    const isSupervisorMode = currentUser.workspace_role
        && currentUser.workspace_role.workspace_role_name === 'User'
        && workspaceSettings.supervision_skills_to_users;
    const hasRightOnPriority = (currentUser.workspace_role?.workspace_role_name === 'User' && (workspaceSettings.is_user_rights_on_priority || false))
    || currentUser.workspace_role?.workspace_role_name !== 'User';

    const useStyles = makeStyles(() => ({
        badge: {
            transform: 'none !important'
        },
        root: {
            display: 'flex !important',
            padding: '2%'
        }
    }));
    const classes = useStyles();

    const toggleSelection = () => {
        const selectedTickets = cloneDeep(footbar.getList());
        if (find(selectedTickets, t => t?.id === props?.ticket?.id)) {
            remove(selectedTickets, t => t?.id === props?.ticket?.id);
        } else {
            selectedTickets.push(props.ticket);
        }
        footbar.update({
            selected: selectedTickets.map(t => t?.id),
            list: selectedTickets,
            type: 'ticket'
        });
    };

    useEffect(() => {
        setIsSelected(find(cloneDeep(footbar.getList()), obj => obj?.id === props?.ticket?.id));
    }, [footbar.getList()]);

    if (isMobile) {
        const enableUpdateStatus = currentUser.workspace_role.workspace_role_name !== 'User';
        const enableDelete = currentUser?.username === 'merciyanis@iot-rocket.space' ? true : enableDeleteOnTicket(isMobile, currentUser, props.ticket, workspaceSettings);

        const views = [];
        if (enableUpdateStatus) {
            views.push(
                <div className={`container-status-choices ticket-view ${isDragging ? 'dragging' : ''} ${isSelected ? 'selected' : ''}`}>
                    { tickets.list.filter(category => category.name !== tickets.selectedStatusMobile && category.name !== 'ARCHIVED')
                        .map(category => (
                            <div
                                className="status-choice"
                                onClick={() => {
                                    const resolutionTime = moment.duration(
                                        moment().diff(moment(findTicket(tickets.list, props?.ticket?.id)?.created_at))
                                    );
                                    const segmentData = {
                                        ticketId: props?.ticket?.id,
                                        ticketOldColumn: props?.ticket?.status,
                                        ticketCurrentColumn: category.name
                                    };
                                    if (category.name === 'COMPLETED') segmentData.ticketResolutionTimeInHours = resolutionTime.asHours();
                                    segmentTrack(EVENT_MOVE_TICKET, segmentData);
                                    dispatch(updateTicket(props?.ticket?.id, { status: category.name }))
                                        .then(() => {
                                            toast(
                                                <Toast
                                                    isConfirm
                                                    message={t('notifications:ticket_swipe_ticket_success')}
                                                    icon={'check'}
                                                    type={'successful'}
                                                />, {
                                                    position: toast.POSITION.BOTTOM_LEFT,
                                                    className: 'normal',
                                                    bodyClassName: 'notification-body grow-font-size',
                                                    progressClassName: 'successful-progress-bar'
                                                }
                                            );
                                            // If isSupervisorMode user only update his ticket (so default mode my_tickets)
                                            dispatch(getTickets(true, isSupervisorMode));
                                        });
                                }
                                }
                            >
                                <span className="label noselect container-swipe">
                                    <span className="noselect">
                                        {t('tickets:swipe_mobile')}
                                    </span>
                                    <span className="column_label noselect">
                                        {t(`tickets:${category.name}_mobile`)}
                                    </span>
                                </span>
                            </div>
                        ))}
                </div>
            );
        }
        views.push(
            <Badge
                key={props?.ticket?.id}
                badgeContent={ticketCounter}
                classes={ticketCounter > 0
                    ? { root: classes.root, badge: classes.badge }
                    : { root: classes.root }}
                color="primary"
            >
                <div
                    tabIndex={0}
                    role={'button'}
                    className={`ticket-view ${isDragging ? 'dragging' : ''} ${isSelected ? 'selected' : ''}`}
                    onClick={e => openTicket(e, dispatch, footbar, modal, groupList, notifications, props?.ticket, isSelected)}
                >
                    {
                        hasRightOnPriority && <div className={`ticket-level level-${props.ticket.priority}`} />
                    }
                    <Component componentName={'TicketViewUpperPart'} toggleSelection={toggleSelection} ticket={props.ticket} />
                    <Component componentName={'TicketViewMiddlePart'} ticket={props.ticket} />
                    <Component componentName={'TicketViewLowerPart'} ticket={props.ticket} />
                </div>
            </Badge>
        );
        if (enableDelete) {
            views.push(
                <div
                    style={{backgroundColor: 'red'}}
                    className={`container-delete-ticket ticket-view ${isDragging ? 'dragging' : ''} ${isSelected ? 'selected' : ''}`}
                    onClick={() => {
                        dispatch(deleteTickets({ticket_ids: [props?.ticket?.id]}));
                    }}
                >
                    <span className="label">{t('tickets:delete_mobile')}</span>
                    <Component componentName={'Icon'} type={'trash'} />
                </div>
            );
        }
        let initialView;
        if (views.length > 2) {
            initialView = 1;
        } else if (views.length !== 2 || enableDelete) {
            initialView = 0;
        } else {
            initialView = 1;
        }
        return (
            <SwipeableViews index={initialView}>
                {views}
            </SwipeableViews>
        );
    }

    return (
        <Badge badgeContent={ticketCounter} color="primary">
            <div
                tabIndex={0}
                role={'button'}
                className={`ticket-view ${isDragging ? 'dragging' : ''} ${isSelected ? 'selected' : ''}`}
                onClick={e => openTicket(e, dispatch, footbar, modal, groupList, notifications, props?.ticket, isSelected)}
            >
                {
                    hasRightOnPriority && <div className={`ticket-level level-${props.ticket.priority}`} />
                }
                <Component componentName={'TicketViewUpperPart'} toggleSelection={toggleSelection} ticket={props.ticket} />
                <Component componentName={'TicketViewMiddlePart'} ticket={props.ticket} />
                <Component componentName={'TicketViewLowerPart'} ticket={props.ticket} />
            </div>
        </Badge>
    );
};

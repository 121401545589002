// Libs
import React from 'react';
// Context
import { useModal } from './ModalContext';
import { useComponentsPool } from '../../ComponentsPool';

export default () => {
    const modal = useModal();
    const { Component } = useComponentsPool();

    return modal.getName() && modal.getIsVisible() && (
        <Component
            componentName={modal.getName()}
            list={modal.getList()}
            item={modal.getItem()}
        />
    );
};

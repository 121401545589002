// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';
// Actions
import { deleteRound } from '../../actions';
import { getDeviceList } from '../../../devices/actions';

const DeleteRoundFootbarButton = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const footbar = useFootBar();
    const { Component } = useComponentsPool();
    const [list, setList] = useState(footbar.getList());
    const currentUser = useSelector(state => state.users.currentUser);

    useEffect(() => {
        setList(footbar.getList());
    }, [footbar.getList()]);
    return (
        <Component
            componentName={'FootbarButton'}
            withConfirmationPopover
            displayCondition={currentUser.workspace_rights.includes('DELETE:Round')
                && list.length === 1
                && list.filter(round => round.active && !round.last_instance).length > 0
            }
            buttonText={t('rounds:delete_round')}
            popoverText={t('rounds:delete_round_confirmation')}
            callbackAction={() => {
                dispatch(deleteRound(footbar.getList().map(round => round.id)))
                footbar.close();
            }}
            buttonIcon={'trash'}
            key={'trash'}
        />
    );
};

export default DeleteRoundFootbarButton;

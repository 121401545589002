import update from 'immutability-helper';

const initialState = () => ({
    list: []
});

export const templatesAtTheEnd = ['Autre', 'Autres', 'Autres (précisez)', 'Divers', 'Autre (précisez)', 'Autre demande', 'Autres demandes', 'TECHSTOP'];

const templateReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'GET_TEMPLATE_LIST_FULFILLED':
            return update(state, {
                list: {
                    $set: action.payload.sort((a, b) => {
                        if (templatesAtTheEnd.includes(a?.title)) return 1;
                        if (templatesAtTheEnd.includes(b?.title)) return -1;
                        return 0;
                    })
                }
            });
        default:
            return state;
    }
};

export default templateReducer;

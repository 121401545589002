// Libs
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Helper
import { getValue } from '../../layout/helper';
import { getPreviewDeviceGroupData, resetPreviewDeviceGroupData } from '../actions';
import { hotDeskingGroupDevices } from '../../devices/helper';

const GroupDetailsPopupLeft = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const group = useSelector(state => state.groups.groupShownInDetailsPopup);
    const editableFields = useSelector(state => sortBy(state.groups.editableFields, 'weight'));
    const dispatch = useDispatch();

    const handleGraphDataChange = changedDates => {
        if (changedDates.startDate && changedDates.endDate) {
            dispatch(getPreviewDeviceGroupData(group.group_id, changedDates.startDate, changedDates.endDate));
        } else {
            dispatch(resetPreviewDeviceGroupData());
        }
    };

    return (
        <>
            {!group.devices.every(device => hotDeskingGroupDevices.includes(device?.custom_field?.type)) && (
                <div className="device-date-range-filter-component">
                    <Component
                        componentName={'DeviceDateRangeFilter'}
                        onDateChange={d => handleGraphDataChange(d)}
                        maxMonthSlot={6}
                    />
                </div>
            )}
            <Component componentName={'DeviceDetailsGraph'} type={'GROUP'} graphData={group.data} />
            <div className={'resource-infos-wrapper'}>
                <div className={'title'}>
                    <span>{t('common:resource_name')}</span>
                    <Component
                        key={'group_name'}
                        componentName={'EditableField'}
                        title={''}
                        props={{
                            value: 'group_name',
                            required: true,
                            rules: ['maxLength-64', 'selectNotEmpty']
                        }}
                        value={() => getValue('group_name', group)}
                        renderValue={'ModalHeadSimpleString'}
                        editionComponent={'Input'}
                        onChange={values => props.updateState('group_name', values)}
                    />
                </div>
                <div className={'resource-infos'}>
                    {React.useMemo(() => editableFields.map(field => {
                        if (!field.props.rules) field.props.rules = [];
                        if (field.name === 'group_floor_group') {
                            field.props.parent = props.state.custom_field?.batiment_group_id;
                        }
                        return (
                            <Component
                                key={field.title}
                                title={t(field.title)}
                                subtitle={t(field.subtitle)}
                                componentName={'EditableField'}
                                renderValue={field.renderValue}
                                editionComponent={field.editionComponent}
                                onChange={value => props.updateState(
                                    field.props.editedValue ? field.props.editedValue : field.props.value,
                                    value
                                )}
                                props={field.props}
                                disabledInput={field.disabledInput}
                                value={getValue(field.props.value, group)}
                            />
                        );
                    }), [editableFields])}
                </div>
            </div>
        </>
    );
};
export default GroupDetailsPopupLeft;

// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// Context
import { useComponentsPool } from '../../../ComponentsPool';
import { getTemplatesList } from '../helper';
import { useFormState } from '../../layout/forms/api/useFormState';

const TicketTemplateSelector = props => {
    const { Component } = useComponentsPool();
    const templatesList = useSelector(state => state.templates.list);

    const { formState } = useFormState();
    const editedTicket = useSelector(state => state.tickets.ticketShownInDetailsPopup);

    const [options, setOptions] = useState([]);

    let selected = {
        "value": templatesList.find(template => template.title === props.value || template.id === props.value)?.title,
        "id": templatesList.find(template => template.title === props.value || template.id === props.value)?.id
    }

    const [disabled, setDisabled] = useState(false);
    const [emptySelect, setEmptySelect] = useState(false);

    useEffect(() => {
        setEmptySelect(false);
    }, [emptySelect]);

    useEffect(() => {
        if (props.modeHierarchy && !Array.isArray(props.parent)) {
            setOptionsFromParent(props.parent)
            if (!isEmpty(props.parent)) {
                if (old !== null) {
                    if (old !== props.parent) {
                        setEmptySelect(true);
                    }
                }
                setOld(props.parent);
            }
        }
    }, [props.parent]);


    const [old, setOld] = useState(null);

    const setOptionsFromParent = parent => {
        if (!parent || (Array.isArray(parent) && parent.length === 0)) {
            setDisabled(true);
        } else {
            setDisabled(false);
            setOptions(getTemplatesList(templatesList
                .filter(template => template.groups?.toString() === parent?.toString())));
        }
    };

    useEffect(() => {
        if (props.modeHierarchy && Array.isArray(props.parent) && !isEmpty(props.parent)) {
            let i1 = props.parent[0];
            let i2 = props.parent[1];

            let currentParentValue;
            if (!formState[i1]?.[i2] && formState[i1]?.[i2] !== '') {
                if (!isEmpty(editedTicket)) {
                    setOptionsFromParent(editedTicket[i1]?.[i2]);
                }
                currentParentValue = editedTicket[i1]?.[i2];
            } else {
                currentParentValue = formState[i1]?.[i2];
                setOptionsFromParent(formState[i1]?.[i2]);
            }

            if (old !== null) {
                if (old !== currentParentValue) {
                    setEmptySelect(true);
                }
            }
            setOld(currentParentValue);
        }
    }, [formState]);

    return (
        <Component
            componentName={'Select'}
            required={props.required}
            multiple={false}
            placeHolder={props.placeholder}
            onChange={obj => props.onChange(obj.value)}
            options={options}
            renderValue={'chip'}
            selected={selected}
            showSelectAll={false}
            disabled={disabled}
            emptySelect={emptySelect}
            rules={props.rules}
        />
    );
};

TicketTemplateSelector.defaultProps = {
    isFilled: false,
    multiple: false,
    placeholder: '',
    required: true,
    showSelectAll: false
};

TicketTemplateSelector.propTypes = {
    isFilled: PropTypes.bool,
    multiple: PropTypes.bool,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    showSelectAll: PropTypes.bool
};

export default TicketTemplateSelector;

// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
// Components
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// SCSS
import '../styles/_inputAutoComplete.scss';

const filter = createFilterOptions();

const useStyles = makeStyles({
    listbox: {
        '& ul': { padding: 0, margin: 0 }
    }
});

const InputAutocomplete = ({
    multiple, required, placeholder, list, onChange, value, noPlaceholder
}) => {
    const classes = useStyles();
    const [t] = useTranslation();

    return (
        <div className={'iot-rocket-input'}>
            <div className={'iot-rocket-input-wrapper'}>
                <Autocomplete
                    multiple={multiple}
                    id={'virtualize-demo'}
                    disableListWrap
                    classes={classes}
                    options={list}
                    renderInput={params => <TextField required={required} {...params} label={!noPlaceholder ? t(placeholder) : ''} />}
                    renderOption={option => <Typography noWrap>{option.title}</Typography>}
                    value={value}
                    onChange={(event, newValue) => {
                        onChange(newValue);
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (filtered.length === 0) {
                            filtered.push({
                                inputValue: params.inputValue,
                                title: params.inputValue // We can add text before value "Add"
                            });
                        }
                        if (filtered.filter(item => item.title === params.inputValue).length === 0 && params.inputValue !== '') {
                            filtered.unshift({ title: params.inputValue });
                        }
                        return filtered;
                    }}
                    getOptionLabel={option => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') return option;
                        if (option && option.inputValue) return option.inputValue;
                        if (option && option.title) return option.title;
                        return [];
                    }}
                />
            </div>
        </div>
    );
};

export default InputAutocomplete;

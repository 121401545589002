// Libs
import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEmpty, find } from 'lodash';
// Components
import AppBar from '@material-ui/core/AppBar/AppBar';
import { isMobile } from 'react-device-detect';
// Context
import { useComponentsPool } from '../../ComponentsPool';
// style
import './style.navbar.scss';
// Actions
import { resetFilters, addFilter, removeFilter } from './actions';
import { useFootBar } from '../footbar/FootBarContext';
import OfflineBanner from "../layout/components/OfflineBanner";


const Sidebar = memo(() => {
    const { Component } = useComponentsPool();
    const footBar = useFootBar();
    const auth = useSelector(state => state.authentication);
    const dispatch = useDispatch();
    const navbar = useSelector(state => state.navbar);
    const [inputFocused, setInputFocused] = useState(false);
    const [query, setQuery] = useState('');
    const [displayRoutesDropdown, setRoutesDropdown] = useState(false);
    const [displayResultContainer, setDisplayResultContainer] = useState(false);
    const [emptyInput, setEmptyInput] = useState(false);
    const [inputFocus, setInputFocus] = useState(false);
    const [queryResultWidth, setQueryResultWidth] = useState(0);
    const [pathSelectorWidth, setPathSelectorWidth] = useState(0);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const addMerciYanisLogo = workspaceSettings.logo_no_text_url !== 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1564046890/logo-rond-my.png';

    const exitFocusMode = () => {
        setInputFocused(false);
        setInputFocus(false);
        if (!isEmpty(navbar.filters)) dispatch(resetFilters());
        setRoutesDropdown(false);
        setQuery('');
        setEmptyInput(!emptyInput);
    };

    const toggleFilter = filter => {
        if (!find(navbar.filters, res => res.id === filter.id)) {
            dispatch(resetFilters());
            setQuery('');
            footBar.update({ selected: [], list: [] });
            dispatch(addFilter({
                id: filter.id,
                type: filter.category,
                name: filter.name,
                groups: filter.groups,
                groupType: filter.groupType,
                id_bis: filter.id_bis
            }));
        } else {
            dispatch(removeFilter(filter.id));
        }
    };

    useEffect(() => {
        setRoutesDropdown(false);
    }, [window.location.href]);

    useEffect(() => {
        if (inputFocused === true) setDisplayResultContainer(false);
    }, [inputFocused]);

    useEffect(() => {
        if (!isEmpty(navbar.filters)) {
            setInputFocused(true);
            setDisplayResultContainer(true);
            setEmptyInput(!emptyInput);
        } else {
            exitFocusMode();
        }
    }, [navbar.filters]);

    useEffect(() => {
        if (displayRoutesDropdown) {
            setDisplayResultContainer(false);
        }
    }, [displayRoutesDropdown]);

    if (isMobile) {
        return <Component componentName={'MobileNavBar'} />;
    }

    return (
        <>
            <Component componentName={'NavigationDropdown'} routesDropdown={displayRoutesDropdown} />
            <Component
                componentName={'QueryResults'}
                toggleFilter={toggleFilter}
                query={query}
                clearText={() => setEmptyInput(!emptyInput)}
                displayResultContainer={displayResultContainer}
                inputFocus={inputFocus}
                width={queryResultWidth - pathSelectorWidth - 17}
                left={pathSelectorWidth + 38}
            />
            <div className={classNames([
                'header',
                workspaceSettings.name === 'gsf-soutenance' ? 'gsf-color' : '',
                inputFocused ? 'search-mode' : ''
            ])}>
                <AppBar position="static" className={classNames(['action-buttons', { disable: false }])}>
                    <div className={'navbar'}>
                        { !navbar.showEditForm && auth.isLogged && (
                            <div className={'navigation-utils'}>
                                {!inputFocused && addMerciYanisLogo && <Component componentName={'MerciYanisLogoNavbar'} />}
                                <Component componentName={'SidebarNav'} focused={!inputFocused} />
                                <Component
                                    componentName={'SearchInput'}
                                    emptyInput={emptyInput}
                                    setPathSelectorWidth={setPathSelectorWidth}
                                    setQueryResultWidth={setQueryResultWidth}
                                    clearText={() => setQuery('')}
                                    setDisplayResultContainer={setDisplayResultContainer}
                                    routesDropdownAction={value => setRoutesDropdown(value)}
                                    displayRoutesDropdown={displayRoutesDropdown}
                                    focused={inputFocused}
                                    setQuery={setQuery}
                                    query={query}
                                    expandSearchInput={() => {
                                        if (!isEmpty(navbar.filters)) setDisplayResultContainer(true);
                                        setInputFocused(true);
                                    }}
                                    exitFocusMode={() => exitFocusMode()}
                                    setInputEvent={focus => setInputFocus(focus)}
                                />
                            </div>
                        )}
                        <div className={'notification-profile-picture-container'}>
                            <Component componentName={'NotificationMenu'} />
                            <Component componentName={'HelpMenu'} />
                            <Component componentName={'ProfilePicture'} />
                        </div>
                        <Component componentName={'WorkspaceSettings'} />
                    </div>
                </AppBar>
            </div>
            <OfflineBanner />
        </>
    );
});

export default Sidebar;

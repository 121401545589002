// Libs
import React from 'react';
import { useSelector } from 'react-redux';

// Context
import { useComponentsPool } from '../../ComponentsPool';
import { addDeviceEditableField } from './actions';
import store from '../../store';
// SCSS
import './style.device.scss';
import {addGroupEditableField} from "../groups/actions";

const dispatch = store.dispatch;

const Devices = () => {
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const modeFloorIsOpen = workspaceSettings.mode_floor !== 'close';
    const modePositionIsOpen = workspaceSettings.mode_position !== 'close';
    const required_fields = workspaceSettings.required_fields || '';
    const devicesEditableFields = useSelector(state => state.devices.editableFields);

    if (!devicesEditableFields.find(editableField => editableField.name === 'device_floor_group')) {
        dispatch(addDeviceEditableField({
            name: 'device_floor_group',
            title: 'groups:floor_group',
            renderValue: 'DeviceSimpleViewGroup',
            editionComponent: modeFloorIsOpen ? 'TicketInputShowGroupName' : 'GroupSelector',
            props: {
                rules: ['maxLength-64'],
                editedValue: modeFloorIsOpen ? ['custom_field', 'floor_label'] : ['custom_field', 'floor_group_id'],
                value: ['custom_field', 'floor_group_id'],
                placeholder: '',
                groupType: 'FLOOR',
                modeHierarchy: true,
                parent: ['custom_field', 'batiment_group_id'],
                object: 'device',
                required: required_fields.includes('floor'),
                serviceNowField: 'floor'
            },
            weight: 500
        }));
    }
    if (!devicesEditableFields.find(editableField => editableField.name === 'device_position_group')) {
        dispatch(addDeviceEditableField({
            name: 'device_position_group',
            title: 'groups:position_group',
            renderValue: 'DeviceSimpleViewGroup',
            editionComponent: modePositionIsOpen ? 'TicketInputShowGroupName' : 'GroupSelector',
            props: {
                rules: ['maxLength-64'],
                editedValue: modePositionIsOpen ? ['custom_field', 'position_label'] : ['custom_field', 'position_group_id'],
                value: ['custom_field', 'position_group_id'],
                placeholder: '',
                groupType: 'POSITION',
                modeHierarchy: true,
                parent: ['custom_field', 'floor_group_id'],
                object: 'device',
                required: required_fields.includes('position'),
                serviceNowField: 'position'
            },
            weight: 600
        }));
    }
    const groupsEditableFields = useSelector(state => state.groups.editableFields);

    if (!groupsEditableFields.find(editableField => editableField.name === 'group_floor_group')) {
        dispatch(addGroupEditableField({
            name: 'group_floor_group',
            title: 'groups:floor_group',
            renderValue: 'DeviceGroupSimpleViewGroup',
            editionComponent: modeFloorIsOpen ? 'TicketInputShowGroupName' : 'GroupSelector',
            props: {
                rules: ['maxLength-64'],
                editedValue: modeFloorIsOpen ? ['custom_field', 'floor_label'] : ['custom_field', 'floor_group_id'],
                value: ['custom_field', 'floor_group_id'],
                placeholder: '',
                groupType: 'FLOOR',
                modeHierarchy: true,
                parent: ['custom_field', 'batiment_group_id'],
                object: 'group',
                required: required_fields.includes('floor'),
                serviceNowField: 'floor'
            },
            weight: 500
        }));
    }
    if (!groupsEditableFields.find(editableField => editableField.name === 'group_position_group')) {
        dispatch(addGroupEditableField({
            name: 'group_position_group',
            title: 'groups:position_group',
            renderValue: 'DeviceGroupSimpleViewGroup',
            editionComponent: modePositionIsOpen ? 'TicketInputShowGroupName' : 'GroupSelector',
            props: {
                rules: ['maxLength-64'],
                editedValue: modePositionIsOpen ? ['custom_field', 'position_label'] : ['custom_field', 'position_group_id'],
                value: ['custom_field', 'position_group_id'],
                placeholder: '',
                groupType: 'POSITION',
                modeHierarchy: true,
                parent: ['custom_field', 'floor_group_id'],
                object: 'group',
                required: required_fields.includes('position'),
                serviceNowField: 'position'
            },
            weight: 600
        }));
    }
    const { Component } = useComponentsPool();
    return <Component componentName={'DevicesIndex'} />;
};

export default Devices;

// Libs
import React from 'react';
// Context
import { useSelector } from 'react-redux';
import { useComponentsPool } from '../../../ComponentsPool';

export default props => {
    const { Component } = useComponentsPool();
    const groups = useSelector(state => state.groups.list);
    const group = groups.find(g => g.group_id === props.value || g.group_name === props.value);

    return (
        <Component
            componentName={'Input'}
            onChange={props.onChange}
            rules={props.rules}
            value={group?.group_name}
        />
    );
};

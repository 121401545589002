// Libs
import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import { capitalize, concat, difference, filter, isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import axios from 'axios';
import fileDownload from 'js-file-download';
// Helper
import { enUS, frFR } from '@mui/material/locale';
import { browserName, browserVersion, isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import {getDeviceFromId, getDevicesOfGroup} from '../devices/helper';
import store from '../../store';
import scssVariables from './styles/_theme.scss';
import {getDeviceOfGroupFromReference, getGroupById, getGroupNameFromId} from '../groups/helper';
import { gettext, getWeekDays } from '../rounds/helper';

export const convertSecondsToHumanTime = (seconds, t) => {
    const intSeconds = parseInt(seconds, 10);
    let time = '';
    if (intSeconds > 0 && intSeconds < 3600) {
        time = `${moment.duration(intSeconds * 1000).asMinutes()} ${t('common:minutes')}`;
    } else if (intSeconds >= 3600 && intSeconds < 86400) {
        time = `${moment.duration(intSeconds * 1000).asHours()} ${t('common:hours')}`;
    } else if (intSeconds >= 86400 && intSeconds < 604800) {
        time = `${moment.duration(intSeconds * 1000).asDays()} ${t('common:days')}`;
    } else if (intSeconds >= 604800 && intSeconds < 2678400) {
        time = `${moment.duration(intSeconds * 1000).asWeeks()} ${t('common:weeks')}`;
    } else if (intSeconds >= 2678400) {
        time = `${moment.duration(intSeconds * 1000).asMonths()} ${t('common:months')}`;
    }
    return time;
};

export const isJSONArray = arr => {
    if (!Array.isArray(arr)) {
        return false;
    }
    let result = true;
    arr.forEach(element => {
        result = result && typeof element === 'object' && element !== null;
    });
    return result;
};

export const setDevicesAndGroupsOptions = (devices, groups, t, addDesc = false, showAllDevices) => {
    const groupList = [];
    groups?.forEach(group => {
        // const deviceIds = devices.filter(device => device.group_ids.includes(group.group_id));
        const deviceIds = getDevicesOfGroup(devices, groups, group.group_id);
        const desc = {};
        if (addDesc) {
            desc.text = t('common:select-item-group-device-count', {count: deviceIds.length});
            desc.hover = t('common:select-item-group-hover-unselected', {count: deviceIds.length});
            desc.selected = t('common:select-item-group-selected', {count: deviceIds.length});
            desc.hoverSelected = t('common:select-item-group-hover-selected', {count: deviceIds.length});
        }
        groupList.push({
            id: group.group_id,
            value: group.group_name,
            type: 'group',
            deviceIds,
            desc,
            selected: false
        });
    });
    const deviceList = [];
    devices?.forEach(device => {
        const desc = {};
        if (addDesc) {
            const groupCnt = device.group_ids.length;
            if (groupCnt === 1) {
                const groupName = groups.filter(group => group.group_id === device.group_ids[0])[0].group_name;
                desc.text = t('common:select-item-device-group-name', {groupName});
            } else {
                desc.text = t('common:select-item-device-group-count', {count: groupCnt});
            }
        }
        deviceList.push({
            id: device.device_id,
            value: device.device_name || device.device_reference,
            type: 'device',
            group_ids: device.group_ids,
            desc,
            selected: false
        });
    });
    return concat(groupList, showAllDevices ? deviceList : filter(deviceList, device => !isEmpty(device.group_ids)));
};

export const getDifference = (array1, array2) => {
    const diff1 = difference(array1, array2);
    const diff2 = difference(array2, array1);
    if (!isEmpty(diff1)) return diff1;
    if (!isEmpty(diff2)) return diff2;
    return [];
};

export const getId = resource => {
    const coreState = store.getState().core;
    let resourceId;
    coreState.resourceIdProperties?.forEach(elementId => {
        if (!resourceId && resource[elementId]) {
            resourceId = resource[elementId];
        }
    });
    if (resourceId) return resourceId;
    throw new Error('Cannot find an identifier field for resource', resource);
};

export const getName = resource => {
    const coreState = store.getState().core;
    if (resource && resource.username) return `${resource.first_name} ${resource.last_name}`;
    let resourceName;
    coreState.resourceNameProperties?.forEach(elementName => {
        if (!resourceName && resource && resource[elementName]) {
            resourceName = resource[elementName];
        }
    });
    return resourceName || '';
};

export const isValid = value => value === 'error_field' || isEmpty(value);

export const getValue = (valueArray, resource) => {
    let obj = resource;
    if (Array.isArray(valueArray)) {
        valueArray?.forEach(value => {
            obj = obj[value];
        });
        return obj;
    }
    return resource[valueArray];
};

export const getRoutes = () => {
    const state = store.getState();
    const {modules} = state.core.settings;
    const {defaultRoute} = state.navbar;

    const routes = [];
    modules.filter(route => route.route)?.forEach(({
        override, isPlugin, route, subRoutes
    }) => {
        let ComponentImport;
        if (isPlugin) {
            ComponentImport = require(`../../plugins/${route}/app`).default; // eslint-disable-line
        } else {
            ComponentImport = require(`../${route}`).default; // eslint-disable-line
        }
        routes.push(
            <Route
                exact
                key={route}
                path={`/${override || route}`}
                render={() => <ComponentImport/>}
            />
        );
        if (subRoutes) {
            subRoutes?.forEach(subRoute => {
                const SubComponentImport = require(`../${subRoute.component}`).default; // eslint-disable-line
                routes.push(
                    <Route
                        exact
                        key={subRoute.route}
                        path={`/${subRoute.route}`}
                        render={() => <SubComponentImport/>}
                    />
                );
            });
        }
    });

    return (
        <section className={'view-wrapper table_view_wrapper'}>
            <Switch>
                {routes}
                <Redirect to={`/${defaultRoute}`}/>
            </Switch>
        </section>
    );
};


export function deepNotEqual(initialState, state) {
    let isError = false;

    Object.keys(state)?.forEach(item => {
        if (state[item] === 'error_field') isError = true;
        else if (Array.isArray(state[item])) {
            state[item].map(obj => {
                if (obj.content === 'error_field') isError = true;
                else if (JSON.stringify(obj).includes('error_field')) isError = true;
            });
        } else if (item === 'custom_field') {
            Object.keys(state[item])?.forEach(custom_field => {
                if (Array.isArray(state[item][custom_field])) {
                    state[item][custom_field].map(obj => {
                        if (obj.content === 'error_field') isError = true;
                    });
                } else if (state[item][custom_field] === 'error_field') {
                    isError = true;
                }
            });
        }
    });
    if (isError) return false;
    return !isEqual(initialState, state);
}

export const getObjectType = (object, devices, groups) => {
    if (getDeviceFromId(devices, object.id)) {
        return 'DEVICE';
    }
    if (getGroupById(groups, object.id)) {
        return 'GROUP';
    }
    return '';
};

export const getTimeOnTwoDigits = timeString => (timeString < 10 ? `0${timeString}` : timeString);

export const getDayAndMonthFromDate = (dateString, delimiter = ':') => {
    const date = new Date(dateString);
    const day = date.getDate().toString().length === 1 ? `0${date.getDate()}` : date.getDate();
    const month = (date.getMonth() + 1).toString().length === 1 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    return `${day}${delimiter}${month}`;
};

export const getHoursAndMinutesFromDateWithoutOffset = (dateString, delimiter = ':', removeFirstZeroHour = false) => {
    return moment(dateString).format(removeFirstZeroHour ? `H[${delimiter}]mm` : `HH[${delimiter}]mm`);
};

export const getHoursAndMinutesFromDate = (dateString, delimiter = ':', removeFirstZeroHour = false) => {
    const offSet = -new Date().getTimezoneOffset();
    return moment(dateString).utcOffset(offSet).format(removeFirstZeroHour ? `H[${delimiter}]mm` : `HH[${delimiter}]mm`);
};

export const getDurationTextBetweenTwoDates = (t, firstDate = null, secondDate, roundName) => {
    const fDate = firstDate || moment();
    const durationBetweenDates = moment.duration(moment(secondDate).diff(fDate));
    let sentenceParts = [];
    const timeBetweenDates = Math.abs(new Date(secondDate).getTime() - new Date(fDate).getTime());
    const numberOfDaysBetweenDates = timeBetweenDates / (1000 * 3600 * 24);

    // We display the right sentence based on the number of days between the two dates
    // (we cannot directly use durationBetweenDates because if for example we have the following dates : 01/01/23 an 02/02/23
    // durationBetweenDates.days() will be equal to 1 and not 32
    if (numberOfDaysBetweenDates >= 365) { // Only display the year(s)
        if (durationBetweenDates.years() >= 1) {
            sentenceParts = [`${t('common:in')} ${durationBetweenDates.years()} ${t('rounds:year')}`];
        }
    } else if (numberOfDaysBetweenDates >= 31) { // Only display the month(s)
        if (durationBetweenDates.months() >= 1) {
            sentenceParts = [`${t('common:in')} ${durationBetweenDates.months()} ${t('rounds:month')}`];
        }
    } else if (numberOfDaysBetweenDates >= 1) { // Only display the day(s)
        sentenceParts = [`${t('common:in')} ${Math.ceil(numberOfDaysBetweenDates)} ${t('rounds:days')}`];
    } else if (moment(secondDate).diff(moment(fDate), 'minutes') < 1) { // Only display that it's less than a minute
        sentenceParts = [`${t('common:in')} ${t('rounds:less_than_a_minute')}`];
    } else { // Only display the hour(s) and/or minute(s)
        if (durationBetweenDates.hours() >= 1) { sentenceParts.push(`${t('common:in')} ${durationBetweenDates.hours()} ${t('rounds:hours')}`); }
        if (durationBetweenDates.minutes() >= 1) {
            if (durationBetweenDates.hours() >= 1) {
                sentenceParts.push(`${durationBetweenDates.minutes()} ${t('rounds:minutes')}`);
            } else {
                sentenceParts.push(`${t('common:in')} ${durationBetweenDates.minutes()} ${t('rounds:minutes')}`);
            }
        }
    }
    return sentenceParts.join(', ');
};

export const getTimeSlotFromString = (startedAt, endedAt) => {
    const started_at_hour = startedAt?.split(':')[0];
    const started_at_min = startedAt?.split(':')[1];
    const ended_at_hour = endedAt?.split(':')[0];
    const ended_at_min = endedAt?.split(':')[1];
    const same_day = moment().set({
        h: ended_at_hour,
        m: ended_at_min
    }).format() > moment().set({h: started_at_hour, m: started_at_min}).format();

    // Check if timeslot is spreading across two different days
    let ended_at_day;
    if (same_day) {
        ended_at_day = moment();
    } else {
        ended_at_day = moment().add(1, 'days');
    }
    const correctEndedAtHour = ended_at_hour && !Number.isNaN(parseInt(ended_at_hour, 10));
    const correctEndedAtMinute = ended_at_min && !Number.isNaN(parseInt(ended_at_min, 10));
    return {
        started_at: started_at_hour && started_at_min ? moment().set({
            h: started_at_hour,
            m: started_at_min,
            s: 0
        }).format() : '',
        ended_at: correctEndedAtHour && correctEndedAtMinute ? ended_at_day.set({
            h: ended_at_hour,
            m: ended_at_min,
            s: 59
        }).format() : ''
    };
};

export const getUntilDateFromDateAndEndedAt = (date, endedAt) => {
    const ended_at_hour = endedAt?.split(':')[0];
    const ended_at_min = endedAt?.split(':')[1];
    return moment(date).set({
        h: ended_at_hour,
        m: ended_at_min,
        s: 59
    }).toDate();
};

export const getUntilDateIsoStringFromDateAndEndedAt = (date, endedAt) => {
    const ended_at_hour = endedAt?.split(':')[0];
    const ended_at_min = endedAt?.split(':')[1];
    return moment(date).set({
        h: ended_at_hour,
        m: ended_at_min,
        s: 59
    }).toISOString();
};

export const isOneDayTimeSlot = (startedAt, endedAt) => {
    const started_at_hour = startedAt?.split(':')[0];
    const started_at_min = startedAt?.split(':')[1];
    const ended_at_hour = endedAt?.split(':')[0];
    const ended_at_min = endedAt?.split(':')[1];
    return moment().set({
        h: ended_at_hour,
        m: ended_at_min
    }).format() > moment().set({ h: started_at_hour, m: started_at_min }).format();
};

export const getTimeSlotFromStringWithDate = (startedAt, endedAt, startDate) => {
    const started_at_hour = startedAt?.split(':')[0];
    const started_at_min = startedAt?.split(':')[1];
    const ended_at_hour = endedAt?.split(':')[0];
    const ended_at_min = endedAt?.split(':')[1];
    const same_day = moment().set({
        h: ended_at_hour,
        m: ended_at_min
    }).format() > moment().set({ h: started_at_hour, m: started_at_min }).format();

    // Check if timeslot is spreading across two different days
    // the ended at date is the starting date + the hours of the ended_at field
    let ended_at_day;
    if (same_day) {
        ended_at_day = moment(startDate);
    } else {
        ended_at_day = moment(startDate).add(1, 'days');
    }
    const correctEndedAtHour = ended_at_hour && !Number.isNaN(parseInt(ended_at_hour, 10));
    const correctEndedAtMinute = ended_at_min && !Number.isNaN(parseInt(ended_at_min, 10));
    return {
        started_at: started_at_hour && started_at_min ? moment(startDate).set({
            h: started_at_hour,
            m: started_at_min,
            s: 0
        }).format() : '',
        ended_at: correctEndedAtHour && correctEndedAtMinute ? ended_at_day.set({
            h: ended_at_hour,
            m: ended_at_min,
            s: 59
        }).format() : ''
    };
};

export const getHoursDifferenceBetweenHours = (startedAt, endedAt) => {
    const startDate = moment().set({ h: startedAt.split(':')[0], m: startedAt.split(':')[1] });
    const endDate = moment().set({ h: endedAt.split(':')[0], m: endedAt.split(':')[1] });
    return (endDate - startDate) / (60 * 60 * 1000);
};

export const getTimeSlotRecapText = (t, startedAt, endedAt, days, allDay, isDeviceDetailPopup = false) => {
    if (!allDay) {
        // Check potential hours errors
        if (!endedAt || !startedAt || startedAt === 'error_field' || endedAt === 'error_field') { return ['summary', t('common:unknown_from_to')]; }
        if (endedAt === startedAt) { return ['summary-error', t('notifications:hours_cannot_be_not_equal')]; }
        const hoursDifference = getHoursDifferenceBetweenHours(startedAt, endedAt);
        if (hoursDifference < 2 && hoursDifference > 0 || hoursDifference < -22 && hoursDifference < 0 || hoursDifference === 0) {
            return ['summary-error', t('common:time_slot_must_be_more_than_2h')];
        }
    }
    if (days.length === 0) {
        let text;
        if (isDeviceDetailPopup) {
            text = `${t('workspace:you_chose_not_to_receive_alerts')}, ${t('devices:for_the_following_device_type').toLowerCase()}: ${t('devices:temperature')}, ${t('devices:air_quality')}`;
        } else {
            text = t('workspace:you_chose_not_to_receive_alerts');
        }
        return ['summary-no-alerts', text];
    }
    let sentence = t('common:every').toLowerCase();
    if (days.length === 5 && days.includes('Monday') && days.includes('Tuesday') && days.includes('Wednesday')
        && days.includes('Friday') && days.includes('Thursday')) {
        sentence = `${sentence} ${gettext('weekdays')}`;
    } else if (days.length === 7) {
        sentence = `${sentence} ${gettext('days')}`;
    } else {
        const sortedDays = [];
        getWeekDays().forEach(weekDay => {
            if (days.includes(weekDay.value)) {
                if (sortedDays.length === 0) {
                    sortedDays.push(capitalize(gettext(weekDay.value)));
                } else {
                    sortedDays.push(` ${capitalize(gettext(weekDay.value))}`);
                }
            }
        });
        sentence = `${sentence} ${sortedDays.toString()}`;
    }
    if (allDay) {
        sentence = `${sentence}, ${t('common:all_day').toLowerCase()}`;
    } else {
        sentence = `${sentence}, ${t('common:between').toLowerCase()} ${startedAt.replace(':', 'h')} ${t('common:and')} ${endedAt.replace(':', 'h')}`;
    }
    if (!isDeviceDetailPopup) {
        sentence = <>{`${t('workspace:you_chose_to_receive_alerts')} `}<span className={'bold-text'}>{sentence}</span></>;
    } else {
        sentence = `${t('devices:alert_device_time_range_explanations', { time_range: sentence })}`;
    }
    return ['summary-alerts', sentence];
};

export const prepareExportContents = (objectType, groups, devicesList, rows, t, deviceType = null) => {
    let result = '';
    let isTeosDevice = false;

    const dataTypesList = [];
    let data_types = '';
    const data = rows.data || [];
    let resultTab = '';
    if (isEmpty(rows.data)) {
        return null;
    }
    if (objectType === 'DEVICE') {
        let hasHeaderAgent = false;
        let hasComment = false;
        // Sort for TES-5MRO
        data.sort((a,b) => {
            if (a.data_type === 'status') return -1;
            if (b.data_type === 'status') return 1;
            return 0;
        });
        for (let j = 0; j < data.length; j = j + 1) {
            if (!dataTypesList.includes('temperature') && data[j].data_points[0].temperature !== undefined) {
                dataTypesList.push('temperature');
                data_types = `${data_types};${t('export:temp')}`;
            } else if (!dataTypesList.includes('humidity') && data[j].data_points[0].humidity !== undefined) {
                dataTypesList.push('humidity');
                data_types = `${data_types};${t('export:Humi')}`;
            } else if (!dataTypesList.includes('co2') && data[j].data_points[0].co2 !== undefined) {
                dataTypesList.push('co2');
                data_types = `${data_types};${t('export:CO2')}`;
            } else if (!dataTypesList.includes('luminosity') && data[j].data_points[0].luminosity !== undefined) {
                dataTypesList.push('luminosity');
                data_types = `${data_types};${t('export:Lumi')}`;
            } else if (!dataTypesList.includes('press_count') && data[j].data_points[0].press_count !== undefined) {
                dataTypesList.push('press_count');
                data_types = `${data_types};${t('export:press_count')}`;
            } else if (!dataTypesList.includes('state') && data[j].data_points[0].state !== undefined) {
                dataTypesList.push('state');
                data_types = `${data_types};${t('export:state')}`;
            } else if (!dataTypesList.includes('status') && data[j].data_points[0].status !== undefined) {
                dataTypesList.push('status');
                data_types = `${data_types};${t('export:status')}`;
            } else if (!dataTypesList.includes('people') && data[j].data_points[0].people !== undefined) {
                dataTypesList.push('people');
                data_types = `${data_types};${t('export:people')}`;
            } else if (!dataTypesList.includes('jobs') && data[j].data_points[0].jobs !== undefined) {
                dataTypesList.push('jobs');
                data_types = `${data_types};${t('export:jobs')}`;
            } else if (!dataTypesList.includes('gps_location_status') && data[j].data_points[0].gps_location_status !== undefined) {
                dataTypesList.push('gps_location_status');
                data_types = `${data_types};${t('export:gps_location_status')}`;
            } else if (!dataTypesList.includes('battery_voltage') && data[j].data_points[0].battery_voltage !== undefined) {
                dataTypesList.push('battery_voltage');
                data_types = `${data_types};${t('export:battery_level')}`;
            }

            if ((!dataTypesList.includes('agent') && data[j].data_points[0].agent !== undefined) ||
                (!dataTypesList.includes('agent') && data[j].data_points[0].author !== undefined) && !hasHeaderAgent) {
                hasHeaderAgent = true;
                dataTypesList.push('agent');
                data_types = `${data_types};${t('export:agent')}`;
            }

            if (!dataTypesList.includes('comment') && data[j].data_points[0].comment !== undefined) {
                hasComment = true;
                dataTypesList.push('comment');
                data_types = `${data_types};${t('export:comment')}`;
            }

            if (!dataTypesList.includes('nfc_tag') && data[j].data_points[0].nfc_tag !== undefined) {
                dataTypesList.push('nfc_tag');
                data_types = `${data_types};${t('export:nfc_tag')}`;
            }
            if (!dataTypesList.includes('passage_type') && data[j].data_points[0].passage_type !== undefined) {
                dataTypesList.push('passage_type');
                data_types = `${data_types};${t('export:passage_type')}`;
            }
            if (!dataTypesList.includes('duration_since_last_enter') && data[j].data_points[0].duration_since_last_enter !== undefined) {
                dataTypesList.push('duration_since_last_enter');
                data_types = `${data_types};${t('export:duration_since_last_enter')}`;
            }
        }
        const objectInformationsHeaders = `${t('devices:device_hardware_id')};${
            t('devices:device_name')};${
            t('devices:building_group')};${
            t('dashboards:csv_header_floor')};${
            t('dashboards:csv_header_position')}`;
        result = `${t('devices:date')}${data_types};${objectInformationsHeaders}\n`;

        // TES-5MRO in mode status, temperature and humidity
        if (dataTypesList.includes('status') && dataTypesList.includes('temperature') && dataTypesList.includes('humidity')) {
            isTeosDevice = true;
        }
        const idsList = rows.devices_ids || [];
        idsList?.forEach(objId => {
            const device = getDeviceFromId(devicesList, objId);
            if (device) {
                let deviceInformation;
                const dataTypesFromTimestamp = [];
                const deviceDatas = data.filter(obj => obj.device_reference === device.device_reference);
                const timestampList = [];
                let rowMap = new Map();
                // Sort for TES-5MRO
                deviceDatas.sort((a,b) => {
                    if (a.data_type === 'status') return -1;
                    if (b.data_type === 'status') return 1;
                    return 0;
                });
                for (let i = 0; i < deviceDatas.length; i = i + 1) {
                    const row = deviceDatas.filter(obj => obj.data_type === deviceDatas[i].data_type)[0];
                    if (!deviceInformation) {
                        deviceInformation = `;${row.device_hardware_id};${row.device_name};${
                            getGroupNameFromId(groups.list, device.custom_field?.batiment_group_id)};${
                            getGroupNameFromId(groups.list, device.custom_field?.floor_group_id)};${
                            getGroupNameFromId(groups.list, device.custom_field?.position_group_id)}`;
                    }
                    if (!isTeosDevice) {
                        row.data_points?.forEach(dataPoint => {
                            if (!timestampList.includes(dataPoint.timestamp)) {
                                timestampList.push(dataPoint.timestamp);
                                dataTypesFromTimestamp[dataPoint.timestamp] = '';
                            }

                            // Additional data for comment, we add the author
                            if (deviceDatas[i].data_type === 'comment') {

                                if (!dataPoint.related_device_message_id) {
                                    if (dataTypesList.includes('jobs')) {
                                        // Saving one column for jobs
                                        dataTypesFromTimestamp[dataPoint.timestamp] = `${dataTypesFromTimestamp[dataPoint.timestamp]};;${dataPoint['author']}`;
                                    } else {
                                        dataTypesFromTimestamp[dataPoint.timestamp] = `${dataTypesFromTimestamp[dataPoint.timestamp]};${dataPoint['author']}`;
                                    }
                                }
                                dataTypesFromTimestamp[dataPoint.timestamp] = `${dataTypesFromTimestamp[dataPoint.timestamp]};${dataPoint[deviceDatas[i].data_type]}`;

                                if (dataPoint.related_device_message_id) {
                                    for (let k = 0; k < deviceDatas.length; k = k + 1) {
                                      deviceDatas[k].data_points?.forEach(dataPointSearched => {
                                          if (dataPointSearched.id===dataPoint.related_device_message_id) {
                                              rowMap.set(dataPointSearched.timestamp, `${moment(dataPoint.timestamp).format('DD/MM/YYYY HH:mm:ss')}${dataTypesFromTimestamp[dataPointSearched.timestamp]?.replace(/\n/g, ' ')}${dataTypesFromTimestamp[dataPoint.timestamp]?.replace(/\n/g, ' ')}${deviceInformation}\n`);

                                          }
                                      });
                                    }
                                    // Find the message
                                } else {
                                    rowMap.set(dataPoint.timestamp, `${moment(dataPoint.timestamp).format('DD/MM/YYYY HH:mm:ss')}${dataTypesFromTimestamp[dataPoint.timestamp]?.replace(/\n/g, ' ')}${deviceInformation}\n`);

                                }

                            } else if (deviceDatas[i].data_type === 'nfc_tag') { // Additional data for nfc tag, we add agent
                                dataTypesFromTimestamp[dataPoint.timestamp] = `${dataTypesFromTimestamp[dataPoint.timestamp]
                                };${dataPoint['agent']};${dataPoint[deviceDatas[i].data_type]}`;

                                if (hasComment) {
                                    rowMap.set(dataPoint.timestamp, `${moment(dataPoint.timestamp).format('DD/MM/YYYY HH:mm:ss')}${dataTypesFromTimestamp[dataPoint.timestamp]};${deviceInformation}\n`);
                                } else {
                                    rowMap.set(dataPoint.timestamp, `${moment(dataPoint.timestamp).format('DD/MM/YYYY HH:mm:ss')}${dataTypesFromTimestamp[dataPoint.timestamp]}${deviceInformation}\n`);
                                }
                            } else {
                                let lineValue = dataPoint[deviceDatas[i].data_type];
                                if (deviceDatas[i].data_type === 'status' && dataPoint[deviceDatas[i].data_type]) {
                                    if (deviceType && deviceType.includes('TORK_')) {
                                        lineValue = `${t(`devices:status_${dataPoint[deviceDatas[i].data_type]}_${deviceType}`)}`;
                                    } else if (deviceType && deviceType === 'LWP') {
                                        lineValue = `${t(`export:status_${dataPoint[deviceDatas[i].data_type]}_${deviceType}`)}`;
                                    } else {
                                        lineValue = `${t(`export:${dataPoint[deviceDatas[i].data_type]}`)}`;
                                    }
                                }
                                if (deviceDatas[i].data_type === 'gps_location_status') {
                                    lineValue = dataPoint[deviceDatas[i].data_type] === null
                                        ? ''
                                        : `${t(`export:gps_location_status_${dataPoint[deviceDatas[i].data_type]}`)}`;
                                }
                                if (deviceDatas[i].data_type === 'battery_level' && dataPoint['battery_voltage']) {
                                    lineValue = `${dataPoint['battery_voltage']}`;
                                }

                                dataTypesFromTimestamp[dataPoint.timestamp] = `${dataTypesFromTimestamp[dataPoint.timestamp]
                                };${lineValue}`;

                                if (hasComment) {
                                    rowMap.set(dataPoint.timestamp, `${moment(dataPoint.timestamp).format('DD/MM/YYYY HH:mm:ss')}${dataTypesFromTimestamp[dataPoint.timestamp]};${deviceInformation}\n`);
                                } else {
                                    rowMap.set(dataPoint.timestamp, `${moment(dataPoint.timestamp).format('DD/MM/YYYY HH:mm:ss')}${dataTypesFromTimestamp[dataPoint.timestamp]}${deviceInformation}\n`);
                                }
                            }
                        });
                    } else {
                        row.data_points?.forEach(dataPoint => {
                            if (!timestampList.includes(dataPoint.timestamp)) {
                                timestampList.push(dataPoint.timestamp);
                                dataTypesFromTimestamp[dataPoint.timestamp] = '';
                            }

                            let lineValue = dataPoint[deviceDatas[i].data_type];
                            if (deviceDatas[i].data_type === 'status') {
                                if (dataPoint[deviceDatas[i].data_type]) {
                                    lineValue = `${t(`export:${dataPoint[deviceDatas[i].data_type]}`)};;`;
                                }
                            }
                            dataTypesFromTimestamp[dataPoint.timestamp] = `${dataTypesFromTimestamp[dataPoint.timestamp] || ''};${lineValue || ''}`;

                            rowMap.set(dataPoint.timestamp, `${moment(dataPoint.timestamp).format('DD/MM/YYYY HH:mm:ss')}${dataTypesFromTimestamp[dataPoint.timestamp]}${deviceInformation}\n`);
                        });
                    }
                }

                let mapDesc = new Map([...rowMap.entries()].sort((a, b) => moment(b[0], 'DD/MM/YYYY HH:mm:ss').toDate() - moment(a[0], 'DD/MM/YYYY HH:mm:ss').toDate()));
                mapDesc?.forEach(rowValue => resultTab = `${resultTab}${rowValue}`);
            }
        });
    } else if (objectType === 'GROUP') {
        let groupDataType = '';
        if (!isEmpty(rows.data)) {
            let objectInformationsHeaders;

            groupDataType = rows.data[0].data_type;
            dataTypesList.push(groupDataType);
            if (groupDataType === 'press_count') {
                data_types = `${data_types};${t('export:template_title')};${t(`export:${groupDataType}`)}`;
                objectInformationsHeaders = `${t('devices:device_hardware_id')};${
                    t('groups:group_name')};${
                    t('devices:building_group')};${
                    t('dashboards:csv_header_floor')};${
                    t('dashboards:csv_header_position')}`;

                result = `${t('devices:date')}${data_types};${objectInformationsHeaders}\n`;

                let rowData = '';
                rows.data?.forEach(deviceData => {
                    const device = getDeviceOfGroupFromReference(groups.device_groups, deviceData.device_reference);


                    rowData = `${moment(deviceData.timestamp).format('DD/MM/YYYY HH:mm:ss')};${t(`devices:${deviceData.value}`)};${
                        deviceData.press_count};${deviceData.device_hardware_id};${
                        device.device_name};${
                        getGroupNameFromId(groups.list, device.custom_field?.batiment_group_id)};${
                        getGroupNameFromId(groups.list, device.custom_field?.floor_group_id)};${
                        getGroupNameFromId(groups.list, device.custom_field?.position_group_id)}`;
                    resultTab = `${resultTab}${rowData}\n`;
                });
            } else if (groupDataType === 'status') {
                data_types = `${data_types};${t(`export:${groupDataType}`)}`;
                objectInformationsHeaders = `${t('groups:group_name')};${
                    t('groups:batiment_group')};${
                    t('groups:floor_group')};${
                    t('groups:position_group')}`;

                result = `${t('devices:date')}${data_types};${objectInformationsHeaders}\n`;

                let rowData = '';
                rows.data?.forEach(groupData => {
                    const group = getGroupById(groups.list, groupData.group_id);
                    rowData = `${moment(groupData.timestamp).format('DD/MM/YYYY HH:mm:ss')};${
                        t(`groups:${groupData.status}`)};${group.group_name};${
                        getGroupNameFromId(groups.list, group.custom_field?.batiment_group_id)};${
                        getGroupNameFromId(groups.list, group.custom_field?.floor_group_id)};${
                        getGroupNameFromId(groups.list, group.custom_field?.position_group_id)}`;
                    resultTab = `${resultTab}${rowData}\n`;
                });
            }
        }
    }
    result = result + resultTab;
    return result;
};

export const objectHasProperty = (object, propertyName) => Object.prototype.hasOwnProperty.call(object, propertyName);

export const getBluePalette = () => ['#00639C', '#2491bf', '#3cbcdd', '#67cce4', '#53ccd8', '#3ECCCC',
    '#00B2B6', '#2b97a1', '#008BAC', '#005A8F', '#06448e', '#003B7F', '#26367D', '#011056', '#212E6B',
    '#414C80', '#495D99', '#4b5fb3', '#7384b3', '#00639C', '#2491bf', '#3cbcdd', '#67cce4', '#53ccd8', '#3ECCCC',
    '#00B2B6', '#2b97a1', '#008BAC', '#005A8F', '#06448e', '#003B7F', '#26367D', '#011056', '#212E6B',
    '#414C80', '#495D99', '#4b5fb3', '#7384b3'];

export const getMatchingAttributesFromServiceNow = (serviceNowSwitcher, attributeName) => {
    if (!isEmpty(serviceNowSwitcher) && !isEmpty(attributeName)) {
        if (serviceNowSwitcher.extra_attributes_matching === attributeName) {
            return `(${attributeName})`;
        }
        if (serviceNowSwitcher.attributes_matching && attributeName in serviceNowSwitcher.attributes_matching) {
            return `(${serviceNowSwitcher.attributes_matching[attributeName]})`;
        }
    }
    return '';
};

// retrieve the exported scss variables
export const getScssVariables = () => scssVariables;

export const useSecondMainColorTooltip = makeStyles(() => ({
    tooltip: {
        background: `${getScssVariables().secondMainColorLightest} !important`,
        color: `${getScssVariables().secondMainColor} !important`,
        padding: props => props.padding || '10px !important',
        textAlign: props => props.alignment || 'center'
    }
}));

export const getAppLanguage = () => (localStorage.getItem('i18nextLng') !== null ? localStorage.getItem('i18nextLng').substr(0, 2) : 'en');

// ERROR EVENTS
export const EVENT_IMPORT_FILE_ERROR = 'Error encountered on document import';
export const EVENT_ERROR_QRCODE = 'Error encountered on QR Code';

// TICKET EVENTS
export const EVENT_MOVE_TICKET = 'User moved ticket';
export const EVENT_MOVE_TICKETS = 'User moved tickets';
export const EVENT_DELETE_TICKET = 'User deleted ticket';
export const EVENT_OPEN_TICKET = 'User opened ticket';
export const EVENT_OPEN_TICKET_HISTORIC_MOBILE = 'User opened ticket historic on mobile';

// WEBSOCKET EVENTS
export const EVENT_WEBSOCKET_CONNECTION_LOST = 'Websocket connection was lost';

// DEVICE EVENTS
export const EVENT_EXPORT_DEVICE = 'User exported device';
export const EVENT_SCAN_QRCODE = 'User scanned QR Code';
export const EVENT_CHOOSE_QRCODE_FORM = 'User chose QR Code Form';
export const EVENT_LET_USER_MAIL = 'User let his mail on QR Code';
export const EVENT_CREATE_QRCODE_TICKET = 'User created QR Code ticket';
export const EVENT_CLICK_QRCODE_INCIDENT = 'User clicked on QR Code incident';
export const EVENT_CLICK_QRCODE_DOCUMENT = 'User clicked on QR Code document';
export const EVENT_CANT_VALIDATE_QRCODE_PASSAGE = 'User couldn\'t validate his passage';
export const EVENT_VALIDATE_QRCODE_PASSAGE = 'User validated his passage';
export const EVENT_VALIDATE_QRCODE_OFFLINE_PASSAGE = 'User validated his passage while being offline';
export const EVENT_CLICK_ON_EMERGENCY_CALL_BUTTON = 'User clicked on QR Code emergency call';

// ROUND EVENTS
export const EVENT_CREATE_ROUND = 'User created round';
export const EVENT_MODIFY_ROUND = 'User modified round';
export const EVENT_EXPORT_ROUND = 'User exported round';
export const EVENT_EXPORT_INSTANCE = 'User exported round instance(s)';
export const EVENT_WRONG_ROUND_RECURRENCE = 'User entered a wrong round recurrence';
export const EVENT_LONG_DURATION = 'User entered an event duration greater than 363';
export const EVENT_DATA_EXPORT_TIMEOUT = 'Round data export timed out';

// STAT EVENTS
export const EVENT_FILTER_VIEW = 'User filtered view';

// CONFIG EVENTS
export const EVENT_OPEN_CONFIGURATION_MENU = 'User opened configuration menu';
export const EVENT_OPEN_DOCUMENT_CONFIG_MENU = 'User opened document configuration menu';
export const EVENT_MODIFY_WORKSPACE_SETTINGS = 'User modified workspace settings';

// OTHER EVENTS
export const EVENT_INITIALISATION_TIME = 'App initialized';
export const EVENT_SWITCH_VIEW = 'User switched to another view';
export const EVENT_REFRESH_PAGE = 'User refreshed page';

const allowSegmentRequests = currentUser => store.getState().workspace?.settings.name !== 'elise' && !currentUser?.email?.includes('@merciyanis.com')
    && currentUser.email !== 'merciyanis@iot-rocket.space';

export const segmentIdentify = (currentUser, currentUserWorkspaces) => {
    if (allowSegmentRequests(currentUser)) {
        analytics.identify(currentUser?.id, {
            firstName: currentUser?.first_name,
            lastName: currentUser?.last_name,
            email: currentUser?.email,
            workspace_role: currentUser?.workspace_role?.workspace_role_name,
            workspaces: currentUserWorkspaces
        });
    }
};

const getSegmentDefaultProperties = defaultProperties => ({
    appLanguage: getAppLanguage(),
    browserName,
    browserVersion,
    deviceType: isMobile ? 'Mobile' : 'Desktop',
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    workspace: defaultProperties?.workspace || store.getState()?.workspace?.settings?.name,
    path: window.location.pathname
});

export const segmentTrack = (eventName, eventProperties = {}, defaultProperties = {}) => {
    let userId = store.getState()?.users?.currentUser?.id;

    if (eventProperties.userId) {
        userId = eventProperties.userId;
        delete eventProperties.userId;
    }

    if (allowSegmentRequests(store.getState().users.currentUser)) {
        analytics.track(eventName, {
            eventProperties,
            userAppProperties: getSegmentDefaultProperties(defaultProperties),
            userId
        });
    }
};

export const segmentTrackLink = (link, eventName, eventProperties = {}, defaultProperties = {}) => {
    let userId = store.getState()?.users?.currentUser?.id;

    if (eventProperties.userId) {
        userId = eventProperties.userId;
        delete eventProperties.userId;
    }

    if (allowSegmentRequests(store.getState().users.currentUser)) {
        analytics.trackLink(link, eventName, {
            eventProperties,
            userAppProperties: getSegmentDefaultProperties(defaultProperties),
            userId
        });
    }
};

export const segmentPage = () => {
    if (allowSegmentRequests(store.getState().users.currentUser)) {
        analytics.page(EVENT_SWITCH_VIEW, {
            userAppProperties: getSegmentDefaultProperties(),
            userId: store.getState().users.currentUser?.id
        });
    }
};

export const getMuiTranslations = () => {
    switch (getAppLanguage()) {
        case 'fr':
            return frFR;
        case 'en':
            return enUS;
        default:
            return enUS;
    }
};

export const getTimeSuffixFormat = () => {
    switch (getAppLanguage()) {
        case 'fr':
            return {
                relativeTime: {
                    s: '%d s',
                    m: '1 min',
                    mm: '%d min',
                    h: '1 h',
                    hh: '%d h',
                    d: '1 j',
                    dd: '%d j',
                    M: '1 mois',
                    MM: '%d mois',
                    y: '1 an',
                    yy: '%d ans'
                }
            };
        case 'en':
        default:
            return {
                relativeTime: {
                    s: '%d s',
                    m: '1 min',
                    mm: '%d min',
                    h: '1 h',
                    hh: '%d h',
                    d: '1 d',
                    dd: '%d d',
                    M: '1 mth',
                    MM: '%d mth',
                    y: '1 y',
                    yy: '%d y'
                }
            };
    }
};

export const downloadFileFromUrl = url => {
    axios.get(url, { responseType: 'blob' })
        .then(res => {
            fileDownload(res.data, url?.split('/')?.pop());
        });
};

export const fileCannotBeTransformedToImage = ['XLS', 'XLSX', 'DOC', 'DOCX'];

// File types that can be viewed with the lib '@cyntler/react-doc-viewer'
// 'XLS', 'XLSX', 'DOC' and 'DOCX' were removed because they didn't seem to work
export const fileTypeAllowedForDocViewer = ['BMP', 'HTM', 'HTML', 'JPG', 'JPEG', 'PDF', 'PNG', 'PPT', 'PPTX', 'TIFF', 'TXT'];

// Allowed file type
export const allowedInputFileType = ['image/png', 'image/jpeg', 'image/jpg', 'image/tiff', 'image/x-icon', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

export const iconForFileWithoutThumbnail = (fileType, isWhiteVersion = false) => {
    const type = fileType?.toUpperCase();
    if (fileCannotBeTransformedToImage.includes(type)) {
        if (type === 'XLS' || type === 'XLSX') { return isWhiteVersion ? 'excelFileWhite' : 'excelFile'; }
        if (type === 'DOC' || type === 'DOCX') { return isWhiteVersion ? 'wordFileWhite' : 'wordFile'; }
    }
    return '';
};

export const getInputFileUrlForThumbnail = (url, docType) => {
    if (!fileCannotBeTransformedToImage.includes(docType?.toUpperCase())) {
        // Replace the last occurrence of 'docType' by '.jpg'
        const replacement = '.jpg';
        const idx = url?.lastIndexOf(`.${docType?.toLowerCase()}`);
        return url?.slice(0, idx) + replacement;
    }
    return '';
};

export const nbMaxFilesByInput = 10;

export const getLoadingSentences = () => [{
    fr_sentence: '📱 Les QR Codes de déclaration d’incidents permettent d’optimiser la gestion des incidents techniques… en 2 clics !',
    en_sentence: '📱 Incidents declaration QR Codes help you optimize technical incidents’ management… in 2 clicks !'
}, {
    fr_sentence: '📊 Découvrez le top 5 des demandes au sein de vos bâtiments grâce à l’onglet statistiques de la plateforme.',
    en_sentence: '📊 See the top 5 requests within your buildings through the statistics of the platform.'
}, {
    fr_sentence: '🌆 Le smart building en 3 idées : améliorer l\'efficacité énergétique, simplifier l\'exploitation, assurer le confort des occupants.',
    en_sentence: '🌆 Smart building in 3 ideas : improve energy efficiency, simplify operations, ensure occupants’ comfort.'
}, {
    fr_sentence: '💎 Ne ratez plus les dernières fonctionnalités grâce à la pop-up « Quoi de neuf sur MerciYanis ? » !',
    en_sentence: '💎 Don\'t miss the latest features thanks to the "What\'s new on MerciYanis?" pop up.'
}, {
    fr_sentence: '🚨 L’alerte SMS vous informe sur la création, le suivi et l’assignation à un ticket.',
    en_sentence: '🚨 SMS alert informs you about the creation, follow-up and assignment of a ticket.'
}, {
    fr_sentence: '💡 « La chose la plus importante en communication, c’est d’entendre ce qui n’est pas dit. » - Peter Drucker',
    en_sentence: '💡 "The most important thing in communication is to hear what is not being said." Peter Drucker'
}, {
    fr_sentence: '🔍 La barre de filtres vous permet de filtrer vos tickets par catégorie, par lieu et même par auteur.',
    en_sentence: '🔍 The filter box allows you to filter tickets by their category, their location and even their author.'
},
{
    fr_sentence: '📈 Dans la vue statistiques, vous pouvez utiliser la barre de filtres pour appliquer une sélection temporelle.',
    en_sentence: '📈 In the stats view, the filter box allows you to apply a chronological selection.'
}, {
    fr_sentence: '🤝 Pour 68% des moins de 35 ans, les bureaux sont un critère de choix au moment de rejoindre une entreprise.',
    en_sentence: '🤝 For 68% of people under 35 years old, offices are a major criteria in deciding which company to join.'
},
{
    fr_sentence: '💰 En moyenne, l’environnement de travail a coûté 584 euros / m² en 2020.\n (Arseg Buzzy Ratios)',
    en_sentence: '💰 On average, the workplace cost 584€ / m² in 2020.'
},
{
    fr_sentence: '💼 1,1 million de français ont un emploi dans l’environnement de travail, c’est près de 4,3 % de la population active française !',
    en_sentence: '💼 1,1 million french workers perfom a job in workplace management. That is 4,3% of the french active population !'
}, {
    fr_sentence: '🖲 MerciYanis  propose plus de 15 capteurs pour gérer les demandes des collaborateurs, la maintenance et la propreté.',
    en_sentence: '🖲 MerciYanis offers over 15 sensors to handle collaborators’ requests, facility management and cleanliness.'
},
{
    fr_sentence: '🗓 MerciYanis a aussi un module planification afin de gérer vos rondes de propreté, sécurité, maintenance… et tout cela à partir d’un calendrier !',
    en_sentence: '🗓 MerciYanis also offers a planification module to help you manage your cleaning, security and maintenance rounds, all thanks to a calendar.'
},
{
    fr_sentence: '💾 Vous pouvez exporter toutes les données de vos objets connectés dans la vue “Capteurs”.',
    en_sentence: '💾 You can export all of your connected objects’ data in the “Sensors” view.'
},
{
    fr_sentence: '⭐ Actuellement, les salariés accordent une note de 6,5/10 à leur espace de travail, qu’ils jugent « correct ». (Etude Polycom)',
    en_sentence: '⭐ Currently, employees give a 6,5/10 score to their workplace, judging it “correct”.'
},
{
    fr_sentence: '💻 Vous pouvez accéder à notre plateforme aussi bien via un ordinateur que via téléphone !',
    en_sentence: '💻 You can access our platform via a computer as well as via smartphone!'
},
{
    fr_sentence: '🔋 Nos capteurs sont sur batterie, vous n’avez aucune installation électrique à faire pour vous équiper.',
    en_sentence: '🔋 Our sensors work on battery, you don’t need additional electrical installation to equip your buildings.'
}, {
    fr_sentence: '🧹 Faites de la propreté à l’usage grâce à nos capteurs qui comptent le nombre de personnes passées dans vos sanitaires ou dans vos salles de réunion.',
    en_sentence: '🧹 Clean only when necessary thanks to our sensors counting the number of people visiting sanitaries or meeting rooms.'
}, {
    fr_sentence: '🎟️ Il est possible de clôturer, commenter et modifier un ticket depuis votre mobile.',
    en_sentence: '🎟️ It is possible to close, comment and modify a ticket from your smartphone.'
}, {
    fr_sentence: '⏲️ Vous pouvez consulter toutes les prestations de ménage passées, en cours et à venir directement depuis votre mobile.',
    en_sentence: '⏲️ It is possible to see previous, current and forthcoming cleaning actions directly from your smartphone.'
}, {
    fr_sentence: '🌡 Les capteurs de température viennent monitorer vos bâtiments. Alerting et traçabilité à la clé, de quoi suivre à la lettre votre plan de sobriété !',
    en_sentence: '🌡 Temperature sensors monitor your buildings. Alerting and traceability, so you can follow by the book your sobriety plan!'
}, {
    fr_sentence: '🤓 La QVT regroupe les actions liant à la fois l’amélioration des conditions de travail des salariés et la performance globale des entreprises.',
    en_sentence: '🤓 QWL includes actions linking both the improvement of employees working conditions and the overall performance of companies'
}, {
    fr_sentence: '🔔 La cloche de notification située en haut à droite vous informe de toutes les nouveautés sur les tickets que vous suivez.',
    en_sentence: '🔔 The notification bell located at the top right informs you of all the news on the tickets you follow.'
}, {
    fr_sentence: '🔖 Vous pouvez ajouter des étiquettes sur chacun des tickets à traiter, afin de les rassembler et de les catégoriser plus facilement !',
    en_sentence: '🔖 You can add tags to each ticket to be processed, so you can group and categorize them more easily !'
}, {
    fr_sentence: '📁 Pensez à archiver vos tickets qui sont “Terminés” afin d’avoir une vue ticket plus agréable et moins chargée.',
    en_sentence: '📁 Think of archiving your tickets that are "Completed" in order to benefit a more pleasant and less busy ticket view.'
}, {
    fr_sentence: '⚙️ Paramétrez votre espace de travail comme vous le souhaitez en vous rendant dans le menu Configurer l\'espace, sous votre profil.',
    en_sentence: '⚙️ Set up your workspace as you wish by clicking on the "Configure workspace" menu, under your profile.'
}, {
    fr_sentence: '🔘 Ne recevez plus d’alertes de capteurs à des heures indésirables en vous rendant dans le menu Configurer l\'espace, sous votre profil',
    en_sentence: '🔘 Stop receiving sensor alerts at unwanted times by going to the "Configure Space" menu, under your profile.'
}, {
    fr_sentence: '🤳🏽 Avec le QR code 4 en 1, vous pouvez faire une demande, consulter un document, tracer une intervention, et visualiser les dernières prestations.',
    en_sentence: '🤳🏽 With the 4-in-1 QR code, you can make a request, consult a document, trace an intervention, and view the latest services made.'
}, {
    fr_sentence: '💶 Selon l\'ARSEG, le coût annuel du poste de travail d’un salarié est de 13 000 € (Arseg Buzzy Ratios).',
    en_sentence: '💶 According to ARSEG, the annual cost of an employee\'s workstation is €13,000 (Arseg Buzzy Ratios).'
}, {
    fr_sentence: '💨 Une étude de l\'Université de Californie a montré que l\'augmentation de la ventilation dans les bureaux peut améliorer la productivité de 8%.',
    en_sentence: '💨 A study from the University of California shows that raising ventilation in the office can improve productivity by 8%.'
}, {
    fr_sentence: '💪 L\'Université de Harvard a constaté que les améliorations de la qualité de l\'air intérieur peuvent réduire l\'absentéisme lié à la maladie de 4,4% !',
    en_sentence: '💪 Harvard University noticed that improving the indoor air quality can reduce sickness absenteeism by 4,4% !'
}, {
    fr_sentence: '🏆 Il existe plusieurs labels et certifications, de la construction du bâtiment à l’exploitation de l’environnement de travail : MerciYanis peut vous aider à les obtenir (ou améliorer vos scores 😉).',
    en_sentence: '🏆 Various labels and certifications exist, from building construction to workplace management : MerciYanis can help you get them (or get better scores 😉).'
}, {
    fr_sentence: '🧘 Selon une enquête réalisée par le cabinet d\'études Gensler, 92% des collaborateurs estiment que la santé et le bien-être sont des facteurs importants pour évaluer la qualité de leur environnement de travail.',
    en_sentence: '🧘 According to the survey firm Gensler, 92% of collaborators say health and well being are important factors when evaluating the quality of their workplace environment.'
}, {
    fr_sentence: '⚡️ Une étude de l\'Université de technologie de Vienne a montré que l\'utilisation de capteurs IoT dans les bâtiments permettait de réduire la consommation d\'énergie de 20 à 30 %.',
    en_sentence: '⚡️ A study from the University of Technology of Vienna showed that the use of IoT sensors in the buildings helped lower energy consumption down to 20 to 30%.'
}, {
    fr_sentence: '🌱 Selon une étude menée de l\'ADEME, plus de 50% des entreprises françaises ont mis en place des actions concrètes pour réduire leur consommation d\'énergie (capteurs, isolation, panneaux solaires…).',
    en_sentence: '🌱 According to a study conducted by the ADEME, over 50% of French companies have implemented concrete actions to lower their energy consumption (sensors, insulation, solar panels…).'
}, {
    fr_sentence: '🤩 Selon l’ADEME, la consommation d\'énergie diminue de 7 à 10 % pour chaque degré de température baissé.',
    en_sentence: '🤩 According to the ADEME, energy consumption is reduced by 7 to 10 % for every temperature degree lowered.'
}, {
    fr_sentence: '🫧 Réduire le nettoyage inutile de 10 sanitaires permet d\'économiser jusqu\'à 17 000€ / an.',
    en_sentence: '🫧 Cutting out the useless cleaning of 10 sanitaries means saving 17 000€ / year.'
}, {
    fr_sentence: '🎰 Les entreprises peuvent économiser jusqu\'à 30% sur leurs coûts immobiliers en adoptant une stratégie de flex office (source : JLL).',
    en_sentence: '🎰 Companies can save up to 30% on their property costs by adopting a flex office strategy (source: JLL).'
}, {
    fr_sentence: '🥰 69% des employés estiment que le flex office contribue à l’amélioration de leur bien-être au travail (source : Regus).',
    en_sentence: '🥰 69% of employees believe that the flex office contributes to improving their well-being at work (source: Regus).'
}, {
    fr_sentence: '💼 85% des entreprises qui ont mis en place des stratégies de flex office ont constaté une amélioration de la collaboration entre les équipes (source : Workplace Group).',
    en_sentence: '💼 85% of companies that have introduced flex office strategies have seen an improvement in collaboration between teams (source: Workplace Group).'
}, {
    fr_sentence: '🌈 93% de ceux qui travaillent au bureau estiment que leur organisation pourrait améliorer leur expérience au bureau (source : Appspace).',
    en_sentence: '🌈 93% of office workers believe their organisation could improve their office experience (source: Appspace).'
}, {
    fr_sentence: '🛀 Les employés travaillant dans des locaux propres et bien entretenus sont jusqu\'à 25% plus productifs que ceux travaillant dans des environnements sales et mal entretenus (source : McKinsey & Company).',
    en_sentence: '🛀 Employees working in clean, well-maintained premises are up to 25% more productive than those working in dirty, poorly maintained environments (source: McKinsey & Company).'
}, {
    fr_sentence: '🤒 87% des actifs interrogés estiment que la propreté et l\'hygiène sont des critères déterminants pour assurer santé et sécurité sur leur lieu de travail (source : décision-achats).',
    en_sentence: '🤒 87% of working people surveyed believe that cleanliness and hygiene are decisive factors in ensuring health and safety in the workplace (source: décision-achats).'
}, {
    fr_sentence: '🔥 95% des salariés se disent plus motivés quand ils rentrent dans un espace de travail propre (source : Workplace magazine).',
    en_sentence: '🔥 95% of employees say they are more motivated when they enter a clean workspace (source: Workplace magazine).'
}, {
    fr_sentence: '🧼 84% des français estiment que la propreté contribue fortement à leur qualité de vie et bien-être au travail (source : BVA - Monde de la Propreté 2023).',
    en_sentence: '🧼 84% of French people believe that cleaning makes a major contribution to their quality of life and well-being at work (source: BVA - Monde de la Propreté 2023).'
}, {
    fr_sentence: '🥬 Les bâtiments représentent 39% des émissions mondiales de carbone, dont 30% proviennent de l\'énergie gaspillée en raison d\'inefficacités (source : AIE).',
    en_sentence: '🥬 Buildings are responsible for 39% of global carbon emissions, 30% of which come from energy wasted through inefficiency (source: AIE).'
}, {
    fr_sentence: '⚡️ La surveillance de votre consommation d’énergie peut aider à réduire votre consommation de 2 à 3 %.',
    en_sentence: '⚡️ Monitoring your energy consumption can help reduce your consumption by 2 to 3%.'
}];

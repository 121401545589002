// Libs
import React from 'react';
import { useSelector } from 'react-redux';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';

const DashboardGroups = props => {
    const { Component } = useComponentsPool();
    const profile = useSelector(state => state.users.currentUser);

    return (
        <Component
            componentName={'GroupButton'}
            view={'dashboard'}
            key={props.resource.dashboard_id}
            group_memberships={props.resource.groups}
            groupRights={profile.group_rights}
            resourceType={'Device'}
            iconType={'device'}
            maxVisible={2}
            index
        />
    );
};

export default DashboardGroups;

// Libs
import React from 'react';
import { Calendar as RDRCalendar } from 'react-date-range';
import onClickOutside from 'react-onclickoutside';
import moment from 'moment';
import * as rdrLocales from 'react-date-range/src/locale';
// SCSS
import 'react-date-range/dist/styles.css'; // main style file

const lang = localStorage.getItem('i18nextLng') !== null ? localStorage.getItem('i18nextLng').substr(0, 2) : 'en';
const init = {
    selection: {
        startDate: moment(new Date()),
        endDate: moment(new Date()),
        key: 'selection'
    },
    lang
};

function Calendar(props) {
    return (
        <RDRCalendar
            locale={rdrLocales[init.lang]}
            date={props.value}
            className={'PreviewArea calendarMode'}
            onChange={props.onChange}
            direction="horizontal"
        />
    );
}

export default onClickOutside(Calendar);


import update from 'immutability-helper';
import {
    findIndex, cloneDeep, remove, isEmpty
} from 'lodash';

const initialState = () => ({
    DashboardsFlatList: [],
    list: [],
    selectedDashboard: { graphs: [] },
    updating: false,
    timeSelection: false,
    tableColumns: [{
        name: 'name',
        isCapitalized: true,
        title: 'common:dashboard',
        component: 'DashboardTableName'
    }, {
        name: 'accessibility',
        isCapitalized: true,
        title: 'common:accessibility',
        component: 'DashboardTableGroups'
    }],
    footbarButtons: [],
    ticketsDashboard: [],
    metabaseUrl: ''
});

let indexDashboard;
let indexDashboardEdit;
let selectedDashboard;
let newDashboard;
let newList;

const dashboardReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'ADD_DASHBOARD_FOOTBAR_BUTTON':
            return update(state, { footbarButtons: { $push: action.payload } });
        case 'REMOVE_DASHBOARD_FOOTBAR_BUTTON':
            newDashboard = cloneDeep(state.footbarButtons);
            remove(newDashboard, type => type.component === action.payload);
            return update(state, { footbarButtons: { $set: newDashboard } });
        case 'TIME_SELECTION_CHANGE':
            return update(state, { timeSelection: { $set: action.payload } });
        case 'UPDATE_DASHBOARD_SETTINGS_FULFILLED':
            indexDashboard = findIndex(state.list, item => item.dashboard_id === action.payload.dashboard_id);
            return update(state, {
                list: { [indexDashboard]: { settings: { $set: action.payload.settings } } },
                selectedDashboard: { settings: { $set: action.payload.settings } }
            });
        case 'DELETE_DASHBOARD_FULFILLED':
        case 'DELETE_DASHBOARDS_FULFILLED':
            newList = cloneDeep(state.list);
            action.payload.forEach(droppedDashboard => {
                newList.splice(findIndex(newList, item => item.dashboard_id === droppedDashboard), 1);
            });
            return update(state, { list: { $set: newList } });
        case 'EDIT_DASHBOARD_FULFILLED':
            indexDashboardEdit = findIndex(state.list, item => item.dashboard_id === action.payload.dashboard_id);
            return update(state, {
                list: { [indexDashboardEdit]: { name: { $set: action.payload.name } } },
                selectedDashboard: { $set: initialState().selectedDashboard }
            });
        case 'GET_DASHBOARD_LIST_FULFILLED':
            return update(state, { list: { $set: action.payload } });
        case 'CREATE_DASHBOARD_FULFILLED':
            newDashboard = { ...action.payload, ...initialState().selectedDashboard };
            return update(state, { list: { $push: [newDashboard] } });
        case 'GET_DASHBOARD_FOR_PREVIEW_FULFILLED':
            indexDashboard = findIndex(state.list, item => item.dashboard_id === action.payload.dashboard_id);
            if (indexDashboard === -1) {
                return update(state, { list: { $push: [action.payload] } });
            }
            return update(state, { list: { [indexDashboard]: { $set: action.payload } } });
        case 'GET_DASHBOARD_FULFILLED':
            indexDashboard = findIndex(state.list, item => item.dashboard_id === action.payload.dashboard_id);
            selectedDashboard = action.payload;
            return update(state, { list: { [indexDashboard]: { $set: selectedDashboard } }, selectedDashboard: { $set: selectedDashboard } }); // eslint-disable-line
        case 'SELECTED_DASHBOARD':
            return update(state, { selectedDashboard: { $set: action.payload } });
        case 'SET_FOCUSED_NAV_BUTTON':
            if (action.payload === '/dashboards') return update(state, { selectedDashboard: { $set: initialState().selectedDashboard } });
            return state;
        case 'GET_TICKETS_DASHBOARDS_FULFILLED':
            return update(state, { ticketsDashboard: { $set: action.payload.data } });
        case 'GET_METABASE_URL_FULFILLED':
            if (!isEmpty(action.payload.data)) {
                return update(state, { metabaseUrl: { $set: action.payload.data } });
            }
            return state;
        case 'LOGOUT_FULFILLED':
            return initialState();
        default:
            return state;
    }
};

export default dashboardReducer;

import React, { createContext, useEffect, useState } from 'react';
import moment from 'moment/moment';
import { getAppLanguage } from '../layout/helper';
import useRouter from '../../utils/hook/useRouter';
import { DEFAULT_ROUND_VIEW, END_DATE_PARAM, PARAMS_DATE_FORMAT, START_DATE_PARAM } from './constants';
import { VIEW_CALENDAR } from './components/RoundViewSwitcher/RoundViewSwitcher';
import queryString from 'query-string';
import { getInstancesFromTo } from './actions';
import { useDispatch } from 'react-redux';

// Default context values
export const RoundsContext = createContext({ setters: {}, getters: {} });
const RoundsProvider = ({ children }) => {
    moment.locale(getAppLanguage(), { week: { dow: 1 } }); // Calendar week starts with Monday no matter the app language
    const { query, history } = useRouter();
    const dispatch = useDispatch();
    const startOfTheWeek = moment(query[START_DATE_PARAM]) ?? moment().startOf('isoWeek'); // from query params or now
    const endOfTheWeek = moment(query[END_DATE_PARAM]) ?? moment().endOf('isoWeek'); // from query params or now
    const roundsViewType = query?.view ?? DEFAULT_ROUND_VIEW; // default to calendar view

    const [calendarViewMode, setCalendarViewMode] = useState('week'); // default to week view
    const [selectedDayOnCalendar, setSelectedDayOnCalendar] = useState(startOfTheWeek); // by default => the fist day of the selected week
    const [currentWeekDates, setCurrentWeekDates] = useState({
        start: startOfTheWeek,
        end: endOfTheWeek
    });

    // URL modifier useEffect for "/rounds"
    useEffect(() => {
        if (roundsViewType === VIEW_CALENDAR) {
            // FYI : moment(undefined) = moment()
            const isStartDateValid = query?.[START_DATE_PARAM] ? moment(query[START_DATE_PARAM]).isValid() : false; // undefined or null check
            const isEndDateValid = query?.[END_DATE_PARAM] ? moment(query[START_DATE_PARAM]).isValid() : false;

            const startDate = isStartDateValid ? moment(query[START_DATE_PARAM]) : moment().startOf('isoWeek');
            const endDate = isEndDateValid ? moment(query[END_DATE_PARAM]) : moment().endOf('isoWeek');
            const dayDiff = endDate.diff(startDate, 'days');
            const areDatesInSameWeek = startDate.isSame(endDate, 'week');

            // we fetch the round instances (events) from the server
            if (isStartDateValid && isEndDateValid && dayDiff === 6 && areDatesInSameWeek) {
                dispatch(getInstancesFromTo(startDate.format(PARAMS_DATE_FORMAT), endDate.format(PARAMS_DATE_FORMAT)));
                return;
            }

            // We decide based on the start date by default if there is any error in param dates
            const newStartDate = startDate.clone().startOf('isoWeek');
            const newEndDate = startDate.clone().endOf('isoWeek');
            const newQueries = {
                view: roundsViewType,
                [START_DATE_PARAM]: newStartDate.format(PARAMS_DATE_FORMAT),
                [END_DATE_PARAM]: newEndDate.format(PARAMS_DATE_FORMAT)
            };

            setCurrentWeekDates({ start: newStartDate, end: newEndDate });
            setSelectedDayOnCalendar(newStartDate);
            history.push({ search: queryString.stringify(newQueries) });
            return;
        }
    }, [query[START_DATE_PARAM], query[END_DATE_PARAM], query['view']]);

    // setters and getters of states in the context for a better organisation
    const setters = { setCalendarViewMode, setSelectedDayOnCalendar, setCurrentWeekDates };
    const getters = { calendarViewMode, selectedDayOnCalendar, currentWeekDates };

    return <RoundsContext.Provider value={{ setters, getters }}>{children}</RoundsContext.Provider>;
};

export default RoundsProvider;

// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment/moment';
// Icons
import Icon from '../../../layout/components/Icon';
// Helpers
import { useComponentsPool } from '../../../../ComponentsPool';
import {
    getDurationTextBetweenTwoDates,
    getHoursAndMinutesFromDate
} from '../../../layout/helper';
import { getDailyRoundsInstancesByStatus, getInstanceEventsList } from '../../actions';
import createLoadingSelector from '../../../layout/actions';
import { isContractorWithoutMail } from '../../../users/helper';
import { getRoundInstanceStatus, isMoreThan24Hours, isOnWholeDay } from '../../helper';
import { useModal } from '../../../modal/ModalContext';
import { retrieveLwpAreaConfig } from '../../../devices/helper';

const loadingSelector = createLoadingSelector(['GET_DAILY_ROUNDS_INSTANCES']);
const MobileRoundsInstancesList = () => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const dispatch = useDispatch();
    const modal = useModal();
    const [validationStatus, setValidationStatus] = useState('rejected');
    const dailyRoundsInstancesList = useSelector(state => state.rounds.dailyRoundsInstancesList);
    const defaultStatus = 'IN_PROGRESS';
    const [instancesListToDisplay, setInstancesListToDisplay] = useState(dailyRoundsInstancesList[`${defaultStatus}`]);
    const [instancesListStatus, setInstancesListStatus] = useState(defaultStatus);
    const currentUser = useSelector(state => state.users.currentUser);
    const devices = useSelector(state => state.devices.list);

    useEffect(() => {
        dispatch(getDailyRoundsInstancesByStatus(true, defaultStatus));
    }, []);

    const getInstancesListByStatus = roundStatus => {
        switch (roundStatus) {
            case 'FINISHED':
                return dailyRoundsInstancesList.FINISHED;
            case 'NOT_STARTED':
                return dailyRoundsInstancesList.NOT_STARTED;
            case 'IN_PROGRESS':
            default:
                return dailyRoundsInstancesList.IN_PROGRESS;
        }
    };

    // Everytime we click on a status button, we change the list of instances to display
    const changeInstancesListByStatus = newStatus => {
        setInstancesListStatus(newStatus);
        dispatch(getDailyRoundsInstancesByStatus(true, newStatus));
    };

    // Everytime the instances in the store changes we change the instance list to display
    useEffect(() => {
        setInstancesListToDisplay(getInstancesListByStatus(instancesListStatus));
        setValidationStatus('show_daily-rounds-instances_list');
    }, [dailyRoundsInstancesList]);

    const getInstanceStatusTextAndIcon = dailyRoundInstance => {
        const status = dailyRoundInstance.status;
        let instanceIcon;
        let instanceText;
        if (status === 'COMPLETE' || status === 'INCOMPLETE') {
            instanceText = t(`rounds:${getRoundInstanceStatus(dailyRoundInstance.status, dailyRoundInstance.validated_steps_count)}_explanations`);
            instanceIcon = status === 'COMPLETE' ? 'CheckCircleRounded' : 'ErrorRounded';
        } else if (status === 'NOT_STARTED') {
            instanceText = `${t('rounds:beginning') } ${ getDurationTextBetweenTwoDates(t, null, dailyRoundInstance.started_at, dailyRoundInstance.round_name)}`;
            instanceIcon = 'StopWatchClockIcon';
        } else if (status === 'IN_PROGRESS') {
            const todayDate = new Date();
            const endedDate = new Date(dailyRoundInstance.ended_at);
            if (todayDate > endedDate) {
                instanceText = `${t('rounds:validation_in_progress')}`;
            } else {
                instanceText = `${t('rounds:end') } ${ getDurationTextBetweenTwoDates(t, null, dailyRoundInstance.ended_at, dailyRoundInstance.round_name)}`;
            }

            instanceIcon = 'StopWatchIcon';
        }
        return { icon: instanceIcon, text: instanceText };
    };

    const displayInstanceDetails = instance => {
        dispatch(getInstanceEventsList(instance, true)).then(res => retrieveLwpAreaConfig(dispatch, devices, res.value.data));
        modal.open('MobileRoundsInstanceDetails');
    };

    const getInstancesSections = (sectionsWrapperTitle, instancesList) => (
        <>
            <div className={'daily-rounds-instances-mobile-title-divider'}>
                <span className={'line-divider'} />
                <div className={'title-divider'}>{sectionsWrapperTitle}</div>
                <span className={'line-divider'} />
            </div>
            {instancesList.map(instance => {
                const instanceTextAndIcon = getInstanceStatusTextAndIcon(instance);
                const instanceIcon = instanceTextAndIcon.icon;
                const instanceText = instanceTextAndIcon.text;
                return (
                    <div
                        key={instance.id}
                        className={`daily-rounds-instances-mobile-item ${instance.status}-instance-item`}
                        onClick={() => displayInstanceDetails(instance, instancesListStatus)}
                    >
                        <div className="instance-name-and-hours">
                            <div className="instance-name">{instance.round_name}</div>
                            <div className="instance-hours">
                                {isOnWholeDay(instance.started_at, instance.ended_at) && !isMoreThan24Hours(instance.started_at, instance.ended_at)
                                    ? `${getHoursAndMinutesFromDate(instance.started_at, 'h')} - ${getHoursAndMinutesFromDate(instance.ended_at, 'h')}`
                                    : `${moment(instance.started_at).format('DD/MM/YY HH[h]mm')} - ${moment(instance.ended_at).format('DD/MM/YY HH[h]mm')}`
                                }
                            </div>
                        </div>
                        <div className={'instance-status-text'}>
                            {instanceText}
                            <Component componentName={'Icon'} type={instanceIcon} />
                        </div>
                    </div>
                );
            })
            }
        </>
    );

    const getDailyRoundsInstancesList = () => {
        let content = <></>;
        if (instancesListToDisplay.length === 0) {
            content = (
                <div className="success-result">
                    <div className="validate-result">
                        <Component componentName={'Icon'} type={'not_implemented'} />
                    </div>
                    <h3>{t(`rounds:no_round_instances_${instancesListStatus}`)}</h3>
                </div>
            );
        } else {
            // If currentUser is assigned to an event currently not started or in progress => we display rounds but we split them by sections
            if (instancesListToDisplay.find(instance => instance.assignees_ids?.includes(currentUser.id))
                && (instancesListStatus === 'IN_PROGRESS' || instancesListStatus === 'NOT_STARTED')
            ) {
                const instancesCurrentUserNeedsToValidate = instancesListToDisplay.filter(instance => instance.assignees_ids?.includes(currentUser.id));
                const firstSectionsWrapperTitle = t(`rounds:my_rounds_to_validate${instancesCurrentUserNeedsToValidate.length > 1 ? '_plural' : '' }`, { roundsCount: instancesCurrentUserNeedsToValidate.length });
                const everyOtherInstances = instancesListToDisplay.filter(instance => !instancesCurrentUserNeedsToValidate.includes(instance));
                const secondSectionsWrapperTitle = t('rounds:every_rounds', { roundsCount: everyOtherInstances.length });
                content = (
                    <>
                        {getInstancesSections(firstSectionsWrapperTitle, instancesCurrentUserNeedsToValidate)}
                        {everyOtherInstances.length > 0 && getInstancesSections(secondSectionsWrapperTitle, everyOtherInstances)}
                    </>
                );
            } else { // if currentUser isn't assigned to an event or if the event is over => we display every rounds
                content = getInstancesSections(t('rounds:every_rounds', { roundsCount:instancesListToDisplay.length }), instancesListToDisplay);
            }
        }
        return content;
    };

    const getComponent = () => {
        switch (validationStatus) {
            case 'show_daily-rounds-instances_list':
                return (
                    <div className="success-result" style={{ marginTop: '10px' }}>
                        <div className={'change-round-mode'}>
                            <Component
                                componentName={'Button'}
                                onClick={() => { changeInstancesListByStatus('IN_PROGRESS'); }}
                                disabled={instancesListStatus === 'IN_PROGRESS'}
                                text={t('rounds:IN_PROGRESS_WITHOUT_EMOJI')}
                                className={'change-round-mode-button'}
                            />
                            <Component
                                componentName={'Button'}
                                onClick={() => { changeInstancesListByStatus('NOT_STARTED'); }}
                                disabled={instancesListStatus === 'NOT_STARTED'}
                                text={t('rounds:forthcoming')}
                                className={'change-round-mode-button'}
                            />
                            <Component
                                componentName={'Button'}
                                onClick={() => { changeInstancesListByStatus('FINISHED'); }}
                                disabled={instancesListStatus === 'FINISHED'}
                                text={t('rounds:finished')}
                                className={'change-round-mode-button'}
                            />
                        </div>
                        <div className={classNames('daily-rounds-instances-mobile-wrapper', isContractorWithoutMail(currentUser) ? '' : 'rounds-instances-list-without-switch-user')}>
                            {getDailyRoundsInstancesList()}
                        </div>
                    </div>
                );
            case 'rejected':
            default:
                return (
                    <div className="success-result">
                        <div className="validate-result">
                            <Icon type="ErrorLaptop" />
                        </div>
                        <h3>{`${t('notifications:error')}.`}</h3>
                        <h3>{t('common:please_contact_responsible')}</h3>
                    </div>
                );
        }
    };

    return (
        isLoading
            ? <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
            : (
                <div className={'round-view-mobile-content'}>
                    {getComponent()}
                </div>
            )
    );
};

export default MobileRoundsInstancesList;

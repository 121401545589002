// Libs
import React from 'react';
// Context
import { useComponentsPool } from '../../../ComponentsPool';


const Toast = props => {
    const { Component } = useComponentsPool();
    const getIconColor = () => { if (props.type === 'successful') return '#3ecccc'; if (props.type === 'warning') return '#FFB50D'; return '#ed7474'; };
    return (
        <div className="notification-container">
            <div className="notif-icon">
                { props.icon && <Component componentName={'Icon'} type={props.icon} color={getIconColor()} /> }
            </div>
            <div className={`notif-message-${props.type}`}>
                {props.message}
                {props.subMessage && <p className={'sub-content'}>{props.subMessage}</p>}
            </div>
        </div>
    );
};

export default Toast;

import {
    isEqual, toLower, upperFirst, difference, omit, isEmpty
} from 'lodash';
import moment from "moment";

const hasOwnProperty = Object.prototype.hasOwnProperty;

// inlined Object.is polyfill to avoid requiring consumers ship their own
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
const is = (x, y) => {
    // SameValue algorithm
    if (x === y) {
        // Steps 1-5, 7-10
        // Steps 6.b-6.e: +0 != -0
        // Added the nonzero y check to make Flow happy, but it is redundant
        return x !== 0 || y !== 0 || 1 / x === 1 / y;
    }
    // Step 6.a: NaN == NaN
    return x !== x && y !== y; // eslint-disable-line
};

// Performs equality by iterating through keys on an object and returning false
// when any key has values which are not strictly equal between the arguments.
// Returns true when the values of all keys are strictly equal.
const shallowEqual = (objA, objB) => {
    if (is(objA, objB)) return true;
    if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) return false;
    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) return false;
    // Test for A's keys different from B.
    for (let i = 0; i < keysA.length; i = i + 1) {
        if (!hasOwnProperty.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) return false;
    }
    return true;
};

export const shallowCompare = (instance, nextProps, nextState) => {
    if (nextState === undefined) {
        return !shallowEqual(instance, nextProps);
    }
    return !shallowEqual(instance, nextProps) || !shallowEqual(instance, nextState);
};

export const attrsCompare = (prevState, nextProps, attr) => {
    const arrayStateAttr = prevState.map(state => state[attr]);
    const arrayPropsAttr = nextProps.map(props => props[attr]);
    if (arrayPropsAttr !== undefined && arrayPropsAttr.length !== 0 && arrayPropsAttr[0].props) {
        const componentState = arrayStateAttr.map(state => state.props.children);
        const componentProps = arrayPropsAttr.map(props => props.props.children);
        return !isEqual(componentProps, componentState);
    }
    return !isEqual(arrayPropsAttr, arrayStateAttr);
};

export const differenceState = (prevState, nextProps) => {
    // add item
    if (prevState.length > nextProps.length) {
        return { type: 'add', difference: difference(prevState, nextProps) };
    }
    // delete item
    if (prevState.length < nextProps.length) {
        return { type: 'delete', difference: difference(nextProps, prevState) };
    }
    // edit item
    return { type: 'update', difference: difference(prevState, nextProps) };
    // return null;
};

export const isEmptyObject = ObjectA => typeof ObjectA === 'object' && Object.keys(ObjectA).length === 0;
export const capitalize = str => upperFirst(toLower(str));
export const symmetricDifference = (a1, a2) => a1
    .filter(x => !a2.includes(x))
    .concat(a2.filter(x => !a1.includes(x)));

// Get the locale language from local storage
export const getLocaleLanguage = () => localStorage.getItem('i18nextLng').slice(0, 2);

export const hexToRgb = (hex, isString) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (isString) {
        return `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`;
    }
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};

/**
 * A function to remove given query param key/s from given URL
 * @param {String} url URL to change
 * @param {String | String[]} param Query param key/s to remove from URL
 * @return {String} the new URL after removing query param key/s
 */
export function removeParamFromURL(url, param) {
    if (Array.isArray(param) && param.length > 0) {
        const urlObj = new URL(url);
        for (const paramKey of param) {
            if (urlObj.searchParams.has(paramKey)) {
                urlObj.searchParams.delete(paramKey);
                continue;
            }
            console.error(`Query param '${paramKey}' does not exist in the URL ${url}`);
        }
        return urlObj.toString();
    } else if (typeof param === 'string') {
        const urlObj = new URL(url);
        if (!urlObj.searchParams.has(param)) {
            console.error(`Query param '${param}' does not exist in the URL ${url}`);
            return urlObj.toString();
        }
        urlObj.searchParams.delete(param);
        return urlObj.toString();
    }
}

/**
 * this function will return an object of modified fields in a form
 * @param initState object that represent the init state of the datta in the form
 * @param editionState object that represent all the fields that are modified
 * @param { {field : value} } fieldValueObject object that represent the field and the value that will be updated
 * @return { {} | {key: value} } object that represent all the modified fields with their new values
 */
export function updateFormState(initState, editionState, fieldValueObject) {
    if (!fieldValueObject || !initState || !editionState) throw new Error('FieldObject, initState and editionState are required');
    const { field, value } = fieldValueObject;

    // does not exist in editionState ( not modified yet )
    // already modified in editionState but not equal to initState ( modified )
    // already modified in editionState but equal to initState ( reset to default )
    const canUpdate =
        !editionState.hasOwnProperty(field) ||
        (editionState.hasOwnProperty(field) && editionState[field] !== initState[field]);
    // cannot update and it is equal to init
    if (!canUpdate && editionState[field] === initState[field]) {
        return omit(editionState, field);
    }
    return { ...editionState, [field]: value };
}

export function isDateTimeOutOfRange(dateTime, minDateTime, maxDateTime) {
    if (!dateTime || !minDateTime || !maxDateTime) return true;

    // Convert to moment objects
    const currentDateTime = moment(dateTime);
    const minDate = moment(minDateTime);
    const maxDate = moment(maxDateTime);

    // Check if dateTime is before minDate or after maxDate
    return currentDateTime.isBefore(minDate, 'minute') || currentDateTime.isAfter(maxDate, 'minute');
}

export function loadIntercomScript(appId) {
    return new Promise((resolve, reject) => {
        if (window.Intercom) {
            console.log('Intercom already loaded.');
            return resolve(window.Intercom);
        }

        // Assurez-vous que les settings sont correctement définis avant le chargement du script
        window.intercomSettings = {
            api_base: 'https://api-iam.eu.intercom.io',
            custom_launcher_selector: '.help-icon',
            alignment: 'left',
            horizontal_padding: 20,
            vertical_padding: 20,
            hide_default_launcher: true
        };

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${appId}`;
        s.onload = () => {
            console.log('Intercom script loaded successfully.');
            resolve(window.Intercom);
        };
        s.onerror = () => reject(new Error('Failed to load Intercom script'));

        const x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
    });
}

export function saveCsvFile(csvFile, filename) {
    if (isEmpty(csvFile)) {
        return;
    }
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

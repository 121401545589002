// Libs
import React from 'react';

const StepLocation = props => {
    let building_name;
    let floor_name;
    let position_name;
    if (props.rightFormatObject) {
        building_name = props.rightFormatObject.building_name ? props.rightFormatObject.building_name : '';
        floor_name = props.rightFormatObject.floor_name ? `, ${props.rightFormatObject.floor_name}` : '';
        position_name = props.rightFormatObject.position_name ? `, ${props.rightFormatObject.position_name}` : '';
    } else {
        building_name = props.resource?.building?.name ? props.resource.building.name : '';
        floor_name = props.resource?.floor?.name ? `, ${props.resource.floor.name}` : '';
        position_name = props.resource?.position?.name ? `, ${props.resource.position.name}` : '';
    }
    const location = `${building_name}${floor_name}${position_name}`;

    return (
        <span>{location}</span>
    );
};

export default StepLocation;

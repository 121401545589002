// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
// Context
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '@material-ui/core/Button';
import { isEmpty } from 'lodash';
import { useCryptr } from '@cryptr/cryptr-react';
// Helpers
import { useComponentsPool } from '../../../ComponentsPool';
import { logout } from '../../authentication/actions';
import QRCodeBackButton from '../../qrcodes/components/QRCodeBackButton';

const MobileTopUserBanner = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.users.currentUser);
    const { logOut } = useCryptr();
    const history = useHistory();

    const getLogoutButton = () => (
        <Button
            startIcon={<Component componentName={'Icon'} type={'logout'} />}
            onClick={() => {
                if (!isEmpty(currentUser?.social_id)) {
                    const workspaceUrl = localStorage.getItem('iot_rocket_workspace')
                        ? `https://${localStorage.getItem('iot_rocket_workspace')}.app.merciyanis.com`
                        : null;
                    logOut(workspaceUrl);
                }
                // If the user wants to switch user from the passage validation form,
                // we prevent the reload to send him to the login page instead of the beginning of the qr code form
                dispatch(logout(history.location.pathname.includes('/PASSAGE_VALIDATION'), true));
            }}
        >
            {t('passages:switch_user')}
        </Button>
    );

    return !isEmpty(currentUser) ? (
        <div className={'agent-banner'}>
            {props.hasGoBackArrow && <QRCodeBackButton />}
            <Component componentName={'UserAvatar'} user={currentUser} picture={currentUser?.profile_picture_url} size={'10vh'} />
            <div className={'connection-banner'}>
                <span className={'user-name'}>
                    {t('passages:validation_passage_title', {
                        user: `${currentUser?.first_name } ${ currentUser?.last_name}`
                    })}
                </span>
                { getLogoutButton() }
            </div>
        </div>
    ) : <></>;
};

export default MobileTopUserBanner;

import update from 'immutability-helper';

// It works only with actions containing an api call or actions that contains following actions suffix
const loadingReducer = (state = { loading: {} }, action) => {
    const { type } = action;
    const matches = /(.*)_(PENDING|FULFILLED|FAILED|REJECTED|REQUEST)/.exec(type);
    // not a *_PENDING / *_FULFILLED /  *_FAILED /  *_REJECTED actions, so we ignore them
    if (!matches) return state;
    const [, requestName, requestState] = matches;
    const REQUESTS = ['REQUEST', 'PENDING'];
    return update(state, { loading: { [requestName]: { $set: REQUESTS.includes(requestState) } } });
};

export default loadingReducer;

// Libs
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
// isMobile
import { isMobile } from 'react-device-detect';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useModal } from '../../../modal/ModalContext';
import { useFootBar } from '../../../footbar/FootBarContext';
// Helper
import { getValue } from '../../../layout/helper';
import { getDeviceFromId, isQRCodeDeclarativeDeviceType } from '../../../devices/helper';

const TicketDetailsPopupPartLeft = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const modal = useModal();
    const footBarDispatch = useFootBar();
    const ticket = useSelector(state => state.tickets.ticketShownInDetailsPopup);
    const devices = useSelector(state => state.devices.list);
    const editableFields = useSelector(state => sortBy(state.tickets.editableFields, 'weight'));
    const currentUser = useSelector(state => state.users.currentUser);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const titleMode = workspaceSettings.mode_title || 'close';
    const hasRightToSeeAssignersField = workspaceSettings.is_contractor_rights_on_assignment || false;
    const hasRightToSeePriorityField = workspaceSettings.is_contractor_rights_on_priority || false;

    useEffect(() => {
        if (isMobile) {
            // ON MOBILE : there is no footbar so it is always edition mode
            footBarDispatch.set({ editionMode: true });
            // if the ticket isn't being edited anymore but it changed then a comment was sent
            // => we stay in edition mode
        } else if (!(footBarDispatch.isEdited && footBarDispatch.editionMode)) {
            footBarDispatch.set({ editionMode: false });
        }
        modal.setItem(ticket);
    }, [ticket]);

    const isDeclarativeQrcodeticket = isQRCodeDeclarativeDeviceType(getDeviceFromId(devices, ticket?.custom_field?.author_device_id));
    return (
        <>
            <div className={'resource-infos-wrapper'}>
                <div className={'title'}>
                    <span key={`ticketkey-${ticket.key}`} className={'ticket-key'}>{`Ticket n°${ticket.key}`}</span>
                    <>
                        <Component
                            key={'ticket_name'}
                            componentName={'EditableField'}
                            renderValue={'TicketSimpleString'}
                            editionComponent={titleMode === 'close' && currentUser.workspace_role.workspace_role_name !== 'User'
                                ? 'TicketTemplateSelector'
                                : 'Input'
                            }
                            title={''}
                            props={titleMode === 'close' && currentUser.workspace_role.workspace_role_name !== 'User' ? {
                                value: props.state.title,
                                renderValue: 'title',
                                required: true,
                                rules: ['selectNotEmpty'],
                                placeholder: '',
                                modeHierarchy: true,
                                parent: props.state.custom_field.skill_group_id,
                                object: 'ticket'
                            } : {
                                value: 'title',
                                renderValue: 'title',
                                required: true,
                                disabled: currentUser.workspace_role.workspace_role_name === 'User',
                                rules: ['maxLength-128', 'selectNotEmpty']
                            }}
                            value={props.state.title}
                            onChange={value => props.updateState('title', value)}
                        />
                        {!ticket.active && (
                            <div className={'archived-ticket-comment-info'}>
                                <span role={'img'} aria-label={'light-emoji'}>💡</span>
                                {t('tickets:cannot_update_archived_ticket')}
                            </div>
                        )}
                    </>
                </div>
                <div className={'resource-infos'}>
                    { React.useMemo(() => editableFields.map(field => {
                        const valueField = getValue(field.props.value, ticket);
                        if (currentUser.workspace_role.workspace_role_name === 'User') {
                            field.props.disabled = true;
                            if (field.name === 'ticket_floor' || field.name === 'ticket_position') {
                                field.disabledInput = true;
                            }
                        }
                        if (field.name === 'ticket_floor') {
                            field.props.parent = props.state.custom_field?.batiment_group_id;
                        } else if (field.name === 'priority') {
                            if (currentUser.workspace_role?.workspace_role_name === 'Contractor') {
                                field.props.disabled = !hasRightToSeePriorityField;
                            }
                        } else if (field.name === 'ticket_assigned_user') {
                            field.props.batimentGroupId = props.state.custom_field?.batiment_group_id;
                            field.props.skillGroupId = props.state.custom_field?.skill_group_id;
                            if (currentUser.workspace_role.workspace_role_name === 'Contractor') {
                                field.props.disabled = hasRightToSeeAssignersField !== true;
                            }
                            field.props.selected = props.state.assigners;
                        } else if (field.name === 'ticket_tags') {
                              field.props.selected = props.state.tags;
                        }
                        if (!isDeclarativeQrcodeticket && field.name === 'ticket_external_followers') { // We can see requesters only on declarative qrcode ticket
                            return <></>;
                        }
                        if (!field.props.hide_roles || !field.props.hide_roles?.includes(currentUser.workspace_role.workspace_role_name)) {
                            return (
                                <Component
                                    key={field.title}
                                    title={t(field.title)}
                                    subtitle={t(field.subtitle)}
                                    componentName={'EditableField'}
                                    renderValue={field.renderValue}
                                    editionComponent={field.editionComponent}
                                    onChange={value => {
                                        props.updateState(
                                            field.props.editedValue ? field.props.editedValue : field.props.value,
                                            value
                                        );
                                    }}
                                    props={field.props}
                                    disabledInput={field.disabledInput}
                                    value={valueField}
                                />
                            );
                        }
                    }), [editableFields])}
                </div>
            </div>
        </>
    );
};

export default TicketDetailsPopupPartLeft;

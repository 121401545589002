// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
// Actions
import createLoadingSelector from '../../layout/actions';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
import useUpdateForm from '../../layout/forms/UseUpdateForm';

const loadingSelector = createLoadingSelector(['EDIT_DEVICE', 'GET_DEVICE_DATA_EXPORT']);
const DeviceDetailsPopup = props => {
    const { Component } = useComponentsPool();
    const devices = useSelector(state => state.devices);
    const device = useSelector(state => state.devices.deviceShownInDetailsPopup);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const editableFields = useSelector(state => sortBy(state.devices.editableFields, 'weight'));

    const moreFunction = state => () => ({
        ...state
    });
    const { updateState, state } = useUpdateForm([...editableFields, { props: { value: 'device_name' } }], device, 'device_id', moreFunction);

    const content = devices.detailPopupParts.map(part => ({
        width: part.width,
        content: <Component
            componentName={part.component}
            item={props.item}
            updateState={(resource, value) => updateState(resource, value)}
            state={state}
        />
    }));

    return (
        <Component
            componentName={'Modal'}
            loading={isLoading}
            content={content}
            contentWidth={'100%'}
            classNames={'details-popup device-details-popup'}
        />
    );
};

export default DeviceDetailsPopup;

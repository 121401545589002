// Libs
import React from 'react';
import { useSelector } from 'react-redux';
// Helpers
import { useComponentsPool } from '../../../../ComponentsPool';
import { isContractorWithoutMail } from '../../../users/helper';
import MobileRoundsInstancesList from './MobileRoundsInstancesList';

const MobileRoundsView = () => {
    const { Component } = useComponentsPool();
    const currentUser = useSelector(state => state.users.currentUser);

    return (
        <div className="round-view-mobile-wrapper center-section">
            <div className="content">
                {/* Only contractor without mail have the top user banner */}
                {isContractorWithoutMail(currentUser) && <Component componentName={'MobileTopUserBanner'} smallerBanner />}
                <MobileRoundsInstancesList />
            </div>
        </div>
    );
};

export default MobileRoundsView;

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useComponentsPool } from '../../../ComponentsPool';

import { changeSelectedStatusMobile } from '../../tickets/actions';
import store from '../../../store';
import OfflineBanner from '../../layout/components/OfflineBanner';

const MobileNavBar = () => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = store.dispatch;

    const tickets = useSelector(state => state.tickets.list);
    const selectedStatus = useSelector(state => state.tickets.selectedStatusMobile);
    const history = useHistory();
    const isTicketsRoute = history.location.pathname.includes('/tickets');

    const ConnexionHeader = (
        <div className="user-menu">
            <div className="container-logo">
                <img
                    className={'company-logo'}
                    src={'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1548668112/logo.svg'}
                    alt={'Company logo'}
                />
                <span>{'MerciYanis'}</span>
            </div>
            <Component componentName={'ProfilePicture'} />
        </div>
    );

    if (isTicketsRoute) {
        return (
            <div className="mobile-bar round-view-mobile-wrapper">
                {ConnexionHeader}
                <OfflineBanner />
                <div className="mobile-navigation-bar change-round-mode">
                    <div className={'mobile-navigation-bar-buttons'}>
                        {
                            tickets.filter(category => category.name !== 'ARCHIVED').map(status => (
                                <Component
                                    componentName={'Button'}
                                    onClick={() => { dispatch(changeSelectedStatusMobile(status.name)); }}
                                    text={t(`tickets:${status.name}_mobile`)}
                                    className={'change-round-mode-button'}
                                    disabled={selectedStatus === status.name}
                                />
                            ))
                        }
                    </div>

                </div>
            </div>
        );
    }

    return (
        <div className="mobile-bar passages-mobile-bar">
            {ConnexionHeader}
            <OfflineBanner />
        </div>
    );
};

MobileNavBar.propTypes = {};

export default MobileNavBar;

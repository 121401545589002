import Box from '@material-ui/core/Box/Box';
import Popover from '@material-ui/core/Popover/Popover';
import classnames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getInstanceEventsList } from '../../../actions';
import RoundHistory from '../../PopupComponents/RoundHistory';
import ExportInstanceBtn from '../../RoundToolbar/export/ExportInstanceBtn';
import EditInstanceBtn from '../../RoundToolbar/EditInstanceBtn';
import DeleteInstanceBtn from './DeleteInstanceBtn';
import './InstanceDetailsPopup.scss';
import InstanceStatusChip from './InstanceStatusChip';
import { getRoundInstanceStatus, traduceRRule } from '../../../helper';
import { useTranslation } from 'react-i18next';
import { canCreateUpdateDeleteInstance } from '../../../../users/helper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    retrieveLwpAreaConfig
} from '../../../../devices/helper';

const anchorOrigin = {
    vertical: 'center',
    horizontal: 'right'
};

const transformOrigin = {
    vertical: 'center',
    horizontal: 'left'
};
const theme = createTheme({
    palette: {
        primary: {
            main: getComputedStyle(document.documentElement).getPropertyValue('--complete-color')
        }
    }
});

const InstanceDetailsPopup = ({ current, onClose }) => {
    const dispatch = useDispatch();
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const currentUserRole = useSelector(state => state.users.currentUser.workspace_role?.workspace_role_name);
    const { instance, anchorEl } = current;
    const [isInEditionMode, setIsInEditionMode] = useState(false);
    const [editedPassages, setEditedPassages] = useState([]);
    const devices = useSelector(state => state.devices.list);
    const rounds = useSelector(state => state.rounds.list);
    const currentRound = rounds.find(item => item.id === instance.round);
    const allow_round_passages_edition = useSelector(state => state.workspace.settings.allow_round_passages_edition);

    useEffect(() => {
        if (instance) {
            dispatch(getInstanceEventsList(instance, false)).then(
                res => {
                    retrieveLwpAreaConfig(dispatch, devices, res.value.data);
                }
            );
        }
    }, [instance]);

    const startedAt = capitalize(moment(instance.started_at).format('ddd DD MMMM HH[h]mm'));
    const sameDay = moment(instance.started_at).format('YYYYMMDD') === moment(instance.ended_at).format('YYYYMMDD');
    const endedAt = sameDay ? moment(instance.ended_at).format('HH[h]mm') : capitalize(moment(instance.ended_at).format('ddd DD MMMM HH[h]mm'));

    const locations = currentRound?.round_devices_locations
        ? currentRound.round_devices_locations.map(item => item.name) : [];
    const locationsStr = locations.join(',');
    const [t] = useTranslation();
    const uniqueInstance = instance.round_rrule.includes('COUNT=1');
    const [stringRecurrence] = useState(traduceRRule(t, instance.round_rrule, instance.round_name, null, null, instance.round_is_until_end_of_month, true));
    const instanceStatus = getRoundInstanceStatus(
        instance.status, instance.validated_steps_count
    );

    return (
        <Popover
            open
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            onClose={onClose}
            className={classnames('instance-details-popup', instanceStatus)}
        >
            <Box p={2} className={classnames(['instance-details-popup-content', { 'in-edition-instance-details-popup-content': isInEditionMode }])}>
                <div className="header">
                    <div>
                        <div className="title">{instance.round_name}</div>
                        <div className="locations">
                            {locationsStr}
                        </div>
                        <div className="hours">{`${startedAt} - ${endedAt}`}</div>
                        <div className="recurrence">{!uniqueInstance && capitalize(stringRecurrence)}</div>
                    </div>
                    <div className="rightHeader">
                        {
                            (instanceStatus === 'PARTIAL_INCOMPLETE' || instanceStatus === 'COMPLETE' || instanceStatus === 'INCOMPLETE')
                                && instance.validated_steps_count !== null && instance.expected_steps_count !== null
                                && (
                                    <ThemeProvider theme={theme}>
                                        <Box className="box-wrapper" sx={{ position: 'relative', display: 'inline-flex' }}>
                                            <CircularProgress
                                                variant="determinate"
                                                thickness={4}
                                                size={'5rem'}
                                                className={'foreground'}
                                                value={instanceStatus === 'INCOMPLETE'
                                                    ? 0
                                                    : Math.round(
                                                        instance.validated_steps_count
                                                        / instance.expected_steps_count * 100
                                                    )
                                                }
                                            />
                                            <CircularProgress
                                                variant="determinate"
                                                value={100}
                                                size={'5rem'}
                                                className={'background'}
                                                thickness={4}
                                                color={'grey'}
                                            />
                                            <Box
                                                sx={{
                                                    top: 0,
                                                    left: 0,
                                                    bottom: 0,
                                                    right: 0,
                                                    position: 'absolute',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <Typography variant="subtitle1" component="div" color="text.secondary">
                                                    {`${instanceStatus === 'INCOMPLETE'
                                                        ? 0
                                                        : Math.round(
                                                            instance.validated_steps_count
                                                            / instance.expected_steps_count * 100
                                                        )}%`
                                                    }
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </ThemeProvider>
                                )
                        }
                        <div className={'toolbar-and-chip-content'}>
                            <div className="toolbar">
                                {canCreateUpdateDeleteInstance(currentUserRole) && allow_round_passages_edition && (
                                    <EditInstanceBtn
                                        setEditedPassages={() => setEditedPassages([])}
                                        isInEditionMode={isInEditionMode}
                                        setIsInEditionMode={value => setIsInEditionMode(value)}
                                    />
                                )}
                                <ExportInstanceBtn fileName="export" instanceToExport={instance} withTooltip
                                                   canExportPdfAndCsv={workspaceSettings.name === 'demo' || workspaceSettings.name === 'novo-nordisk'}
                                />
                                {canCreateUpdateDeleteInstance(currentUserRole)
                                    && <DeleteInstanceBtn instance={instance} onDelete={onClose} withTooltip />
                                }
                            </div>
                            <InstanceStatusChip instance={instance} withTooltip />
                        </div>
                    </div>
                </div>
                <div className="content">
                    <RoundHistory
                        currentInstance={instance}
                        backCallBack={onClose}
                        showBack={false}
                        hasLoader
                        editedPassages={editedPassages}
                        setEditedPassages={value => setEditedPassages(value)}
                        isInEditionMode={isInEditionMode}
                        setIsInEditionMode={value => setIsInEditionMode(value)}
                    />
                </div>
            </Box>
        </Popover>
    );
};

export default InstanceDetailsPopup;

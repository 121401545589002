import React from 'react';
import { useTranslation } from 'react-i18next';
import { useComponentsPool } from '../../../ComponentsPool';

import '../../rounds/style.scss';

export const BUILDINGS_LIST = 'buildings';
export const SKILLS_LIST = 'skills';
export const JOBS_LIST = 'jobs';

const SettingViewSwitcher = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();

    return (
        <div className={'change-round-mode'}>
            <Component
                componentName={'Button'}
                onClick={() => props.setCurrentList(BUILDINGS_LIST)}
                disabled={props.currentList === BUILDINGS_LIST}
                text={t('settings:BATIMENT')}
                className={'change-round-mode-button'}
                icon={'userGroup'}
                startAdornment
            />
            <Component
                componentName={'Button'}
                onClick={() => props.setCurrentList(SKILLS_LIST)}
                disabled={props.currentList === SKILLS_LIST}
                text={t('settings:SKILL')}
                className={'change-round-mode-button'}
                icon={'tickets'}
                startAdornment
            />
            <Component
                componentName={'Button'}
                onClick={() => props.setCurrentList(JOBS_LIST)}
                disabled={props.currentList === JOBS_LIST}
                text={t('settings:JOB')}
                className={'change-round-mode-button'}
                icon={'rounds'}
                startAdornment
            />
        </div>
    );
};

export default SettingViewSwitcher;

// Lib
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Context
import Switch from '@material-ui/core/Switch';
import { useComponentsPool } from '../../../../ComponentsPool';
import { isPassageDeviceType } from '../../../devices/helper';

const ContractorsRights = props => {
    const { t } = useTranslation();
    const { Component } = useComponentsPool();
    const devices = useSelector(state => state.devices.list);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const [visibleTicketsOptions] = useState([{ id: 'ALL', value: t('workspace:visible_tickets_for_contractors_all_option') }, { id: 'ASSIGNATED', value: t('workspace:visible_tickets_for_contractors_assignated_option') }]);
    const initialConfig = {
        show_contractor_calendar_view: !!workspaceSettings.show_contractor_calendar_view,
        hide_contractor_device_view: !(workspaceSettings.hide_contractor_device_view === undefined || workspaceSettings.hide_contractor_device_view),
        is_contractor_rights_on_assignment: !!workspaceSettings.is_contractor_rights_on_assignment,
        is_contractor_rights_on_priority: !!workspaceSettings.is_contractor_rights_on_priority,
        show_chatbot_sharing_to_contractor: !!workspaceSettings.show_chatbot_sharing_to_contractor,
        contractor_tickets_mode: visibleTicketsOptions.find(e => e.id === workspaceSettings.contractor_tickets_mode) || visibleTicketsOptions.find(e => e.id === 'ASSIGNATED')
    };
    const [modifiedConfig, setModifiedConfig] = useState(initialConfig);

    useEffect(() => {
        if (props.cancelModifs) {
            setModifiedConfig(initialConfig);
            props.setCancelModfis(false);
        }
    }, [props.cancelModifs]);

    useEffect(() => {
        const configToModify = [];
        Object.entries(modifiedConfig).forEach(config => {
            const initialConfigValue = initialConfig[config[0]];
            if (config[0] === 'contractor_tickets_mode') {
                if (config[1]?.id !== initialConfigValue.id) {
                    configToModify.push({
                        configName: config[0],
                        configValue: config[1].id
                    });
                }
            } else if (config[1] !== initialConfigValue) {
                configToModify.push({
                    configName: config[0],
                    configValue: config[1]
                });
            }
        });
        const hideDeviceView = configToModify.find(conf => conf.configName === 'hide_contractor_device_view');
        if (hideDeviceView) { hideDeviceView.configValue = !hideDeviceView.configValue; }
        props.modifyCurrentConfig(configToModify);
    }, [modifiedConfig]);

    return (
        <>
            <div className={'configuration-page-top-content'}>
                <Component componentName={'Icon'} type={'contractorConfig'} />
                <div className={'configuration-page-title'}><h3>{t('workspace:configure_contractors_rights')}</h3></div>
            </div>
            <div className={'configuration-page-text'}>
                <div className={'configuration-page-section-wrapper'}>
                    <div className={'configuration-page-section-title'}>{t('tickets:tickets')}</div>
                    <div className={'configuration-page-explanation-wrapper first-section-explanation'}>
                        <div className={'configuration-page-explanation-title'}>{t('workspace:visible_tickets')}</div>
                        <div className={'configuration-page-explanation-content'}>
                            <div className={'configuration-page-explanation-text'}>
                                <p>{t('workspace:visible_tickets_for_contractors_explanation')}</p>
                            </div>
                            <div className={'configuration-page-explanation-fields select-visible-tickets selected-option-text'}>
                                <Component
                                    componentName={'EditableField'}
                                    renderValue={'Select'}
                                    required
                                    props={{
                                        required: true,
                                        options: visibleTicketsOptions,
                                        selected: modifiedConfig.contractor_tickets_mode,
                                        changeSelectValue: modifiedConfig.contractor_tickets_mode,
                                        onChange: item => {
                                            if (item.id === 'ASSIGNATED') {
                                                // If the contractors can only see tickets they are assigned to (contractor_tickets_mode) then they cannot modify ticket's assignation field (is_contractor_rights_on_assignment)
                                                setModifiedConfig({ ...modifiedConfig, contractor_tickets_mode: item, is_contractor_rights_on_assignment: false });
                                            } else {
                                                setModifiedConfig({ ...modifiedConfig, contractor_tickets_mode: item });
                                            }
                                        },
                                        customWidth: true
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'configuration-page-explanation-wrapper last-section-explanation'}>
                        <div className={'configuration-page-explanation-title'}>{t('workspace:available_actions')}</div>
                        <div className={'configuration-page-explanation-content'}>
                            <div className={'configuration-page-explanation-text'}>
                                <p>{t('workspace:doable_ticket_actions_for_contractors_explanation')}</p>
                            </div>
                            <div>
                                {modifiedConfig.contractor_tickets_mode.id === 'ALL' && (
                                    <div className={'configuration-page-explanation-fields'}>
                                        <span className={'explanation-field-text'}>{t('workspace:modify_assignation')}</span>
                                        <span>{t('common:deactivate')}</span>
                                        <Switch
                                            checked={modifiedConfig.is_contractor_rights_on_assignment}
                                            onChange={e => setModifiedConfig({ ...modifiedConfig, is_contractor_rights_on_assignment: e.target.checked })}
                                        />
                                        <span className={modifiedConfig.is_contractor_rights_on_assignment && 'selected-option-text'}>{t('common:activate')}</span>
                                    </div>
                                )}
                                <div className={'configuration-page-explanation-fields'}>
                                    <span className={'explanation-field-text'}>{t('workspace:modify_priority')}</span>
                                    <span>{t('common:deactivate')}</span>
                                    <Switch
                                        checked={modifiedConfig.is_contractor_rights_on_priority}
                                        onChange={e => setModifiedConfig({ ...modifiedConfig, is_contractor_rights_on_priority: e.target.checked })}
                                    />
                                    <span className={modifiedConfig.is_contractor_rights_on_priority && 'selected-option-text'}>{t('common:activate')}</span>
                                </div>
                                <div className={'configuration-page-explanation-fields'}>
                                    <span className={'explanation-field-text'}>{t('workspace:share_comments_on_teams')}</span>
                                    <span>{t('common:deactivate')}</span>
                                    <Switch
                                        checked={modifiedConfig.show_chatbot_sharing_to_contractor}
                                        onChange={e => setModifiedConfig({ ...modifiedConfig, show_chatbot_sharing_to_contractor: e.target.checked })}
                                    />
                                    <span className={modifiedConfig.show_chatbot_sharing_to_contractor && 'selected-option-text'}>{t('common:activate')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {devices.find(device => isPassageDeviceType(device)) && (
                    <div className={'configuration-page-section-wrapper'}>
                        <div className={'configuration-page-section-title'}>{t('rounds:planification')}</div>
                        <div className={'configuration-page-explanation-wrapper'}>
                            <div className={'configuration-page-explanation-title'}>{t('workspace:access')}</div>
                            <div className={'configuration-page-explanation-content'}>
                                <div className={'configuration-page-explanation-text'}>
                                    <p>{t('workspace:show_round_view_to_role_explanation', { roleName: t('search:contractor') })}</p>
                                </div>
                                <div className={'configuration-page-explanation-fields'}>
                                    <span>{t('common:do_not_give_access')}</span>
                                    <Switch
                                        checked={modifiedConfig.show_contractor_calendar_view}
                                        onChange={e => setModifiedConfig({ ...modifiedConfig, show_contractor_calendar_view: e.target.checked })}
                                    />
                                    <span className={modifiedConfig.show_contractor_calendar_view && 'selected-option-text'}>{t('common:give_access')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className={'configuration-page-section-wrapper'}>
                    <div className={'configuration-page-section-title'}>{t('common:devices')}</div>
                    <div className={'configuration-page-explanation-wrapper'}>
                        <div className={'configuration-page-explanation-title'}>{t('workspace:access')}</div>
                        <div className={'configuration-page-explanation-content'}>
                            <div className={'configuration-page-explanation-text'}>
                                <p>{t('workspace:show_device_view_to_contractors_explanation')}</p>
                            </div>
                            <div className={'configuration-page-explanation-fields'}>
                                <span>{t('common:do_not_give_access')}</span>
                                <Switch
                                    checked={modifiedConfig.hide_contractor_device_view}
                                    onChange={e => setModifiedConfig({ ...modifiedConfig, hide_contractor_device_view: e.target.checked })}
                                />
                                <span className={modifiedConfig.hide_contractor_device_view && 'selected-option-text'}>{t('common:give_access')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContractorsRights;

// Libs
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { traduceRRule } from '../../helper';

export default props => {
    const [t] = useTranslation();
    const round = useSelector(state => state.rounds.roundShownInDetailsPopup);
    const [stringRecurrence] = useState(traduceRRule(
        t, props.rrule, round.name,
        props.started_at || round.started_at,
        props.ended_at || round.ended_at,
        round.is_until_end_of_month
    ));

    return (
        <div className={classNames(['round-planification-container', props.isNotValidName ? 'error-padding' : ''])}>
            <span>
                {capitalize(stringRecurrence)}
            </span>
        </div>
    );
};

// Library
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Backdrop, makeStyles } from '@material-ui/core';
import cssVars from 'css-vars-ponyfill';
import { isEmpty } from 'lodash';
import { isMobile, isIE } from 'react-device-detect';
import { CryptrProvider } from '@cryptr/cryptr-react';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment/moment';

// Custom hook
import useRouter from '../../utils/hook/useRouter';

// Component
import Toast from '../notification';
import Websocket from './Websocket';
import Authentification from '../authentication';
import iotRocketBanner from './components/banner';
import { useComponentsPool } from '../../ComponentsPool';
import QRCodeRouter from '../qrcodes';

// Actions
import {
    layoutReady,
    resourceLoaded,
    setInitializationError
} from './actions';
import {
    getCurrentUserWorkspaces,
    getWorkspaceRoles,
    getWorkspaceSettings
} from '../workspace/actions';
import {
    getInvitedUsers, getUsers, getCurrentUser, setGroupRights, setWorkspaceRights, getCurrentUserHMAC
} from '../users/actions';
import { getGroupList, getGroupRoles } from '../groups/actions';
import { getGroupACL, getWorkspaceACLs } from '../acl/actions';
import { getDeviceList, getDeviceByHardwareId, getLastReceivedDeviceData } from '../devices/actions';
import { getDashboardList, getMetabaseUrl, getTicketsDashboards } from '../dashboards/actions';
import { setFocusedNavButton, getNotifications } from '../navbar/actions';

// Pre Settings
import {
    setRequestInterceptor, setUpApiUrlWithLocalStorage, setUpWorkspaceWithLocalStorage, setAutoRelogger,
    autoLogin, setUpGeolocalisation, refreshToken, setUpWebsocketUrlWithLocalStorage,
    loadPlugins, loadPluginsData
} from './bootstrapFunctions';

// Scss
import '../layout/style.scss';
import '../layout/styles/_popover.scss';
import { hexToRgb } from './helper';
import { callEndpointsForOfflineModeOnMobile } from './offlineHelpers';
import { getTickets, getTicketByKey, getTags } from '../tickets/actions';
import getMessagingUsersList from '../messaging/actions';
import getTemplateList from '../templates/actions';

// Helper
import { contractorRoleCanSeeCalendarView, isContractorWithoutMail, userRoleCanSeeCalendarView } from '../users/helper';
import {getLatestChangelog} from '../changelogs/actions';
import getWorkspaceByDeviceHardwareId from '../workspaces/actions';
import { getNfcs } from '../nfcs/actions';
import AuthenticationBackground from '../layout/components/AuthenticationBackground';
import { EVENT_INITIALISATION_TIME, segmentIdentify, segmentTrack } from '../layout/helper';
import { isPassageDeviceType } from '../devices/helper';
import { useTranslation } from 'react-i18next';

// Used to make a bridge between CSS and JS
cssVars({
    // Targets
    rootElement: document,
    shadowDOM: false,
    // Sources
    include: 'link[rel=stylesheet],style',
    exclude: '',
    variables: {},
    // Options
    onlyLegacy: true,
    preserveStatic: true,
    preserveVars: false,
    silent: false,
    updateDOM: true,
    updateURLs: true,
    watch: false
});

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 500,
        color: '#fff'
    }
}));

const Core = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const router = useRouter();
    const [t] = useTranslation();
    const core = useSelector(state => state.core);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const notification = useSelector(state => state.notification);
    const authentication = useSelector(state => state.authentication);
    const groups = useSelector(state => state.groups.list);
    const acls = useSelector(state => state.acl);
    const users = useSelector(state => state.users);
    const { Component } = useComponentsPool();
    const history = useHistory();
    const isPassagesRoute = isMobile
        && (/^\/passages\/[A-Za-z0-9]/.test(history.location.pathname)
            || /^\/qrcodes\/[A-Za-z0-9]/.test(history.location.pathname));
    const isTeamsCommentRoute = /^\/teams-comment-space/.test(history.location.pathname);
    const currentUser = useSelector(state => state.users.currentUser);
    const isMultiWorkspacesHomePageRoute = window.location.pathname.includes('workspaces');
    const isWorkspaceCreationPageRoute = window.location.pathname.includes('create-workspace-GHBVTUU6');
    const [deviceWasRetrieved, setDeviceWasRetrieved] = useState(false);
    const [dateAtInitialisation, setDateAtInitialisation] = useState(null);
    const devices = useSelector(state => state.devices.list);
    const [isFakeReady, setIsFakeReady] = useState(false);

    const getQRCodeHardwareIdInUrl = () => {
        return history.location.pathname
            .replace('passages', '')
            .replace('qrcodes', '')
            .replace('DOCUMENT', '')
            .replace('PASSAGE_VALIDATION', '')
            .replace('PASSAGE_VISUALIZATION', '')
            .replace('EMERGENCY_CALL', '')
            .replace('ISSUE_REPORT', '')
            .replace(/\//g, '');
    };

    const handleApiError = error => {
        if (error?.response?.status === StatusCodes.NOT_FOUND) {
            setIsFakeReady(true);
            router.push('/not-found');
        }
    };

    // BOOTSTRAP
    useEffect(() => {
        !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=APP_SEGMENT_ANALYTICS_WRITE_KEY;analytics.SNIPPET_VERSION="4.15.2";
            analytics.load(APP_SEGMENT_ANALYTICS_WRITE_KEY);
        }}();
        (async function () {
            console.log(`%c ${ iotRocketBanner }`, 'color: #BC84E5'); // eslint-disable-line

            const loadWorkspaceSettings = async () => {
                await dispatch(getWorkspaceSettings());
            };

            if (localStorage.getItem('iot_rocket_access_JWT') === undefined
                || localStorage.getItem('iot_rocket_refresh_JWT') === undefined) {
                localStorage.removeItem('iot_rocket_access_JWT');
                localStorage.removeItem('iot_rocket_refresh_JWT');
                if (!window.location.pathname.match(/^\/login.*/)) {
                    router.push('/login');
                }
            }

            // Custom redirection to workspace management if we are not in a workspace
            setUpApiUrlWithLocalStorage();
            if (isPassagesRoute) { // then we are on app.merciyanis.com and we need to retrieve the device and set the workspace info
                const res = await dispatch(getWorkspaceByDeviceHardwareId(getQRCodeHardwareIdInUrl(), true, false));
                setUpWebsocketUrlWithLocalStorage(res?.value?.workspace);
            } else {
                setUpWebsocketUrlWithLocalStorage();
            }

            // redirect to correct root
            const isApp = window.location.hostname !== DOMAIN_NAME;
            if (!IS_DEBUG) {
                if (!isApp) { // eslint-disable-line
                    if (!window.location.pathname.match(/^\/workspace.*/)) { // redirect to workspace if we are on homepage
                        router.push('/workspace');
                    }
                }
            }

            // bootstrap app if needed
            if (isApp) {
                loadWorkspaceSettings()
                    .then(() => {
                        refreshToken()
                            .then(autoLogin)
                            .then(async () => {
                                setRequestInterceptor();
                                setUpWorkspaceWithLocalStorage();
                                setAutoRelogger();
                                await loadPlugins();
                                dispatch(layoutReady());
                            });
                    }).catch(handleApiError);
            } else {
                loadWorkspaceSettings()
                    .then(() => {
                        dispatch(layoutReady());
                    })
                    .catch(handleApiError);
            }
        }());
    }, []);

    // When we are logged in, get application
    useEffect(() => {
        setDateAtInitialisation(moment());
        const loadApp = async () => {
            // Teams page => peut importe si on est connecté ou pas
            if (isTeamsCommentRoute && (!authentication.isLogged || !core.resourceLoaded)) {
                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams) {
                    const ticketKey = urlParams.get('key');
                    const ticketCode = urlParams.get('code');
                    dispatch(getTicketByKey(ticketKey, ticketCode))
                        .then(() => {
                            dispatch(resourceLoaded());
                        })
                        .catch(() => {
                            // To display customize error message
                            dispatch(resourceLoaded());
                        });
                }
            } else {
                if (!authentication.isLogged && isPassagesRoute) {
                    // Users must access the form of an incident QRCODE even if they aren't logged
                    dispatch(getDeviceByHardwareId(getQRCodeHardwareIdInUrl(), true, true));
                    setDeviceWasRetrieved(true); // Avoid to get device twice (because of the time the app takes to detect that the user is logged)
                }
                if (authentication.isLogged && !core.resourceLoaded) {
                    if (isPassagesRoute) {
                        if (!deviceWasRetrieved) {
                            dispatch(getDeviceByHardwareId(getQRCodeHardwareIdInUrl(), false, true));
                        }
                        dispatch(getUsers());
                        dispatch(getCurrentUser()).then(currentLoggedUser => {
                            callEndpointsForOfflineModeOnMobile(dispatch, t, null);
                            // to remove when getCurrentUser will send the current user workspaces
                            dispatch(getCurrentUserWorkspaces()).then(res => {
                                segmentIdentify(currentLoggedUser?.value, res?.value?.accounts);
                            });
                        });
                        // TODO TEMPORAIRE: pour une demo sur https://app.merciyanis.com/qrcodes/demo-chatbot
                        dispatch(getCurrentUserHMAC(true)).catch(error => {
                            console.error("GENERATE_HMAC_ERROR", error); // eslint-disable-line
                        });
                    } else {
                        // GET USER FIRST
                        dispatch(getInvitedUsers());
                        dispatch(getGroupList(0));
                        await dispatch(getUsers());
                        let loggedUser = {};
                        await dispatch(getCurrentUser()).then(res => {
                            loggedUser = res?.value;
                            callEndpointsForOfflineModeOnMobile(dispatch, t, null);
                        });
                        await dispatch(getCurrentUserHMAC(true)).catch(error => {
                            console.error("GENERATE_HMAC_ERROR", error); // eslint-disable-line
                        });
                        const supervision_skills_to_users = workspaceSettings.supervision_skills_to_users;
                        const isSupervisorMode = loggedUser.workspace_role
                            && loggedUser.workspace_role.workspace_role_name === 'User'
                            && supervision_skills_to_users;
                        // If isSupervisorMode at application launch, display my_tickets view mode
                        await dispatch(getTickets(true, isSupervisorMode));

                        dispatch(getMetabaseUrl());
                        await Promise.all([
                            dispatch(getDashboardList()),
                            dispatch(getTicketsDashboards()),
                            dispatch(getMessagingUsersList()),
                            dispatch(getTemplateList()),
                            dispatch(getDeviceList(0)),
                            dispatch(getWorkspaceRoles()),
                            dispatch(getNotifications()),
                            dispatch(getWorkspaceACLs()),
                            dispatch(getGroupRoles()),
                            dispatch(getGroupACL()),
                            // to remove when getCurrentUser will send the current user workspaces
                            dispatch(getCurrentUserWorkspaces()).then(res => {
                                segmentIdentify(loggedUser, res?.value?.accounts);
                            }),
                            dispatch(getLatestChangelog()),
                            dispatch(getNfcs()),
                            dispatch(getTags()),
                            loadPluginsData()
                        ])
                            .then(() => {
                                dispatch(setFocusedNavButton(router.pathname));
                                dispatch(resourceLoaded());
                            })
                            .catch(err => {
                                dispatch(setInitializationError(err));
                                console.error(err); // eslint-disable-line
                            });

                    }
                }
            }
        };
        loadApp();
    }, [authentication.isLogged]);

    // CREATE ACL STUFF
    useEffect(() => {
        // setting workspace rights
        if (!isEmpty(acls.workspace.rights) && !isEmpty(users.currentUser)) {
            if (isEmpty(users.currentUser)) return;
            const workspaceRights = acls.workspace.rights;
            const workspaceRoleName = users.currentUser.workspace_role?.workspace_role_name;
            const userWorkspaceRights = workspaceRights[workspaceRoleName];
            dispatch(setWorkspaceRights(userWorkspaceRights));

            // setting group rights
            const groupRights = acls.group.rights;
            const userGroupRights = {};
            if (users.currentUser.group_memberships) {
                users.currentUser?.group_memberships?.forEach(group => {
                    const groupId = group.group.group_id;
                    const groupRoleName = group.group_role.group_role_name;
                    userGroupRights[groupId] = userWorkspaceRights.slice(0).concat(
                        groupRights[groupRoleName]
                    );

                    // remove duplicated entries
                    userGroupRights[groupId] = userGroupRights[groupId]
                        .filter((el, index, arr) => index === arr.indexOf(el));
                });
            }
            // add workspace rights to group not assigned
            groups?.forEach(g => {
                const groupId = g.group_id;
                if (userGroupRights[groupId] === undefined) {
                    userGroupRights[groupId] = userWorkspaceRights;
                }
            });
            dispatch(setGroupRights(userGroupRights));
        }
    }, [acls, groups]);

    useEffect(() => { // allow or not access to a view depending on the user role
        if (!isEmpty(currentUser)) {
            const userRoleCantAccessRoundsView = !userRoleCanSeeCalendarView(workspaceSettings.show_user_calendar_view) || isMobile;
            const contractorRoleCantAccessRoundsView = !contractorRoleCanSeeCalendarView(workspaceSettings.show_contractor_calendar_view) && !isMobile;
            const disabledViewsForUser = ['/devices', '/users', '/dashboards', userRoleCantAccessRoundsView && '/rounds', '/settings'];
            const disabledViewsForAdmin = ['/settings'];
            const disabledViewsForContractor = ['/users', '/dashboards', contractorRoleCantAccessRoundsView && '/rounds', '/settings'];
            const disabledRoutesForContractorWithoutMailOnMobile = ['/devices', '/users', '/dashboards', '/workspaces', '/settings'];
            const disabledRoutesForContractorWithoutMailOnPlatform = ['/users', '/dashboards', '/workspaces', '/settings'];

            const userDefaultRoute = '/tickets';
            const contractorWithoutMailDefaultRouteOnMobile = '/rounds';
            const contractorWithoutMailDefaultRouteOnPlatform = '/tickets';
            const accessibleRoutesOnMobile = ['/tickets', '/rounds'];

            const hasPassageDeviceInWorkspace = devices.some(device => isPassageDeviceType(device));

            // On mobile navigation
            if(isMobile) {
                const isRouteAccessibleOnMobile = accessibleRoutesOnMobile.includes(window.location.pathname)
                    || window.location.pathname.includes('/qrcodes')
                    || window.location.pathname.includes('/passages');
                const canUsersWithoutEmailSeeTicketsViewOnMobile = !!workspaceSettings?.['show_tickets_view_for_users_without_email_on_mobile'];

                // Blocking access to unavailable routes for mobile devices by redirecting to "/tickets"
                if (!isRouteAccessibleOnMobile) {
                    // Users without mail are not allowed to see rounds if there is no passage sensor, and they have the right to see tickets view
                    if (isContractorWithoutMail(currentUser)) {
                        // WS access to tickets = false
                        if (!canUsersWithoutEmailSeeTicketsViewOnMobile) {
                            history.push(contractorWithoutMailDefaultRouteOnMobile); // redirect to "/rounds"
                            return;
                        }
                        // WS access to tickets = true
                        if (canUsersWithoutEmailSeeTicketsViewOnMobile) {
                            if (hasPassageDeviceInWorkspace) {
                                history.push(contractorWithoutMailDefaultRouteOnMobile); // redirect to "/rounds"
                                return;
                            }
                            history.push(userDefaultRoute); // redirect to "/tickets"
                            return;
                        }
                    }
                    // Other users to "/tickets"
                    history.push(userDefaultRoute);
                    return;
                }
            }

            if (isContractorWithoutMail(currentUser)) {
                const isAccessingADisabledRouteOnPlatform = disabledRoutesForContractorWithoutMailOnPlatform.includes(window.location.pathname)
                const isAccessingADisabledRouteOnMobile = disabledRoutesForContractorWithoutMailOnMobile.includes(window.location.pathname)
                const canUsersWithoutEmailSeeTicketsViewOnMobile = !!workspaceSettings?.['show_tickets_view_for_users_without_email_on_mobile'];
                if (!isMobile && isAccessingADisabledRouteOnPlatform) {
                    history.push(contractorWithoutMailDefaultRouteOnPlatform);
                }
                // Accessing a forbidden route for mobile devices OR accessing '/tickets' with canUsersWithoutEmailSeeTicketsViewOnMobile = false
                if (isMobile && (isAccessingADisabledRouteOnMobile || (window.location.pathname.includes('/tickets') && !canUsersWithoutEmailSeeTicketsViewOnMobile))) {
                    history.push(contractorWithoutMailDefaultRouteOnMobile);
                }
                // Accessing '/ticket' with canUsersWithoutEmailSeeTicketsViewOnMobile = true OR '/rounds' are ALLOWED

            } else if (currentUser.workspace_role?.workspace_role_name === 'Contractor') {
                const hasRoundSkill = currentUser?.group_memberships?.some(group => group?.group?.group_name === 'Rondes');
                // Technician without round skill or no passage sensor on workspace will be redirected to "/tickets" ( ON MOBILE )
                if (
                    isMobile &&
                    window.location.pathname.includes('/rounds') &&
                    (!hasPassageDeviceInWorkspace || !hasRoundSkill)
                ) {
                    history.push(userDefaultRoute);
                }
                if (
                    window.location.href.includes('redirect=')
                    && !disabledViewsForContractor.some(route => window.location.href.includes(route))
                ) { // Redirect from account creation (round_subscription_no_account_email)
                    history.push(window.location.href.substring(window.location.href.indexOf('redirect=')).replace('redirect=',''));
                } else if (disabledViewsForContractor.some(route => window.location.pathname.includes(route))) {
                    history.push(userDefaultRoute);
                }
            } else if (currentUser.workspace_role?.workspace_role_name === 'User'
                && disabledViewsForUser.some(route => window.location.pathname.includes(route))
            ) {
                history.push(userDefaultRoute);
            } else if (currentUser.workspace_role?.workspace_role_name === 'Admin') {
                if (
                    window.location.href.includes('redirect=')
                    && !disabledViewsForContractor.some(route => window.location.href.includes(route))
                ) { // Redirect from account creation (round_subscription_no_account_email)
                    history.push(window.location.href.substring(window.location.href.indexOf('redirect=')).replace('redirect=',''));
                } else if (disabledViewsForAdmin.some(route => window.location.pathname.includes(route))) {
                    history.push(userDefaultRoute);
                }
            }
        }
    }, [currentUser, window.location.pathname]);

    // REACT TO SETTINGS
    useEffect(() => {
        // setting app colors
        document.documentElement.style.setProperty('--primary-color--rgb', hexToRgb('#011056', true));
        document.documentElement.style.setProperty('--primary-color', '#011056');
        document.documentElement.style.setProperty('--secondary-color--rgb', hexToRgb('#E5E5E5', true));
        document.documentElement.style.setProperty('--secondary-color', '#E5E5E5');
        document.documentElement.style.setProperty('--in-progress-color', '#ffb50d');
        document.documentElement.style.setProperty('--complete-color', '#57C9AE');

        if (workspaceSettings.ask_location) {
            setUpGeolocalisation();
        }
    }, [workspaceSettings]);

    useEffect(() => {
        if (core.resourceLoaded) {
            const initialisationDuration = moment.duration(moment().diff(dateAtInitialisation));
            segmentTrack(EVENT_INITIALISATION_TIME, { appInitialisationSeconds: initialisationDuration.asSeconds() })
        }
    }, [core.resourceLoaded]);

    const App = core.resourceLoaded
        ? <Component componentName={'Layout'} />
        : <AuthenticationBackground><Component componentName={'AppLoader'} /></AuthenticationBackground>;

    const getComponent = () => {
        if (isPassagesRoute) {
            return <QRCodeRouter />;
        }
        if (isTeamsCommentRoute) {
            return <Component componentName={'TeamsCommentForm'} />;
        }
        if (isMultiWorkspacesHomePageRoute || isWorkspaceCreationPageRoute) {
            return <Authentification />;
        }
        if (authentication.isLogged) {
            return App;
        }
        return <Authentification />;
    };

    const getApp = () => {
        if (workspaceSettings.show_azure_ad_connect
            && !isEmpty(workspaceSettings.cryptr_tenant_domain)
            && !isEmpty(workspaceSettings.cryptr_client_id)
            && !isEmpty(workspaceSettings.cryptr_identity_id)) {
            const cryptrConfig = {
                audience: window.location.origin,
                client_id: workspaceSettings.cryptr_client_id,
                default_locale: 'fr',
                default_redirect_uri: window.location.origin + '/sso',
                telemetry: workspaceSettings.cryptr_telemetry || 'FALSE',
                tenant_domain: workspaceSettings.cryptr_tenant_domain,
                cryptr_base_url: 'https://merciyanis.authent.me',
                dedicated_server: 'true',
                fixed_pkce: true
            };
            return <CryptrProvider {...cryptrConfig}>{ getComponent() }</CryptrProvider>;
        }
        return getComponent();
    };

    return (core.ready || isFakeReady) && (
        <div id="app" className={isMobile ? 'mobile-app' : isIE ? 'ie-app' : ''}>
            <Helmet>
                <title>{workspaceSettings.tab_title}</title>
                <link rel="icon" type="image/png" href="https://res.cloudinary.com/dkdjhrtdv/image/upload/v1605683560/webclip_256.png" />
                CUSTOM_INTEGRATION
            </Helmet>
            <Websocket />
            <Backdrop
                className={classes.backdrop}
                open={notification.toast_type && notification.toast_type === 'confirm' || false}
            />
            <Toast />
            { getApp() }
        </div>
    );
};

export default Core;

// Libs
import React from 'react';
import {getScssVariables} from "../helper";

const PictureAndTexts = props => (
    <div className={'picture-and-texts-wrapper'}>
        <div style={{ width: props.customSvgWidth || '50vw' }}>
            {props.picture}
        </div>
        <div className={'picture-and-texts-text'}>
            {props.mainText && (
                <span className={'picture-and-texts-main-text'}>{props.mainText}</span>
            )}
            {props.subText && (
                <span
                    className={'picture-and-texts-sub-text'}
                    style={{ color: props.customSubTextColor || `${getScssVariables().textColor}`, marginBottom: props.customSubTextMarginBottom }}
                >
                    {props.subText}
                </span>
            )}
        </div>
    </div>
);

export default PictureAndTexts;

// Libs
import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
// Components
import Popover from '@material-ui/core/Popover';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Scss
import '../styles/_popover.scss';

const ConfirmationPopover = props => {
    const { Component } = useComponentsPool();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selected, setSelected] = useState(false);

    const handleClick = event => {
        event.stopPropagation();
        setSelected(!selected);
        setAnchorEl(event.currentTarget);
    };

    const handleConfirmation = () => {
        props.confirmationButtonAction();
        setAnchorEl(null);
    };

    const handleSecondConfirmation = () => {
        props.secondConfirmationButtonAction();
        setAnchorEl(null);
    };

    useEffect(() => {
        if (props.setIsOpenPopover) {
            props.setIsOpenPopover(Boolean(anchorEl));
        }
    }, [anchorEl]);

    return (
        <>
            <div
                role={'button'}
                tabIndex={0}
                onClick={handleClick}
            >
                {props.actionButton}
            </div>
            <Popover
                classes={{ paper: `MuiPopover-paper no-overflow-paper ${props.noArrow ? 'no-arrow' : 'bottom-arrow'} ${props.customPopoverPosition ? '' : 'delete-popover'}` }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                disableRestoreFocus
            >
                <OutsideClickHandler onOutsideClick={() => setAnchorEl(null)}>
                    <div className={'popover-content'}>
                        <p>{props.popoverText}</p>
                        <div className={'popover-actions'}>
                            <Component
                                componentName={'Button'}
                                onClick={() => handleConfirmation()}
                                text={props.confirmationButtonLabel}
                                variant={'outlined'}
                                className={props.customStyle || 'confirmation-button'}
                            />
                            { props.secondConfirmationButtonLabel && (
                                <Component
                                    componentName={'Button'}
                                    onClick={() => handleSecondConfirmation()}
                                    text={props.secondConfirmationButtonLabel}
                                    variant={'outlined'}
                                    className={props.customStyle || 'confirmation-button'}
                                />
                            )}
                            { props.cancelButtonLabel && (
                                <Component
                                    componentName={'Button'}
                                    onClick={() => setAnchorEl(null)}
                                    text={props.cancelButtonLabel}
                                    variant={'outlined'}
                                    className={'cancel-button'}
                                />
                            )}
                        </div>
                    </div>
                </OutsideClickHandler>
            </Popover>
        </>
    );
};

export default ConfirmationPopover;

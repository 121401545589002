// Libs
import React from 'react';
import { useSelector } from 'react-redux';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
// Helper
import { getUserName, getUserFromId } from '../../../users/helper';

const getAssigners = (assignersIds, users) => {
    const assignersName = [];
    assignersIds.forEach(assigner => {
        assignersName.push({ id: assigner, value: getUserName(getUserFromId(users, assigner)) });
    });
    return assignersName;
};

const TicketAssigners = props => {
    const ticketInDetailsPopup = useSelector(state => state.tickets.ticketShownInDetailsPopup);
    const users = useSelector(state => state.users.list);
    const assignersIds = props.assigners ? props.assigners : ticketInDetailsPopup.assigners;
    const assigners = getAssigners(assignersIds, users);
    const { Component } = useComponentsPool();

    return (
        <Component
            componentName={'ChipButton'}
            maxVisible={4}
            chipsToDisplay={assigners}
        />
    );
};

export default TicketAssigners;

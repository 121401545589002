// Libs
import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import onClickOutside from 'react-onclickoutside';
import moment from 'moment';
import * as rdrLocales from 'react-date-range/src/locale';
// SCSS
import 'react-date-range/dist/styles.css'; // main style file

const lang = localStorage.getItem('i18nextLng') !== null ? localStorage.getItem('i18nextLng').substr(0, 2) : 'en';
const init = {
    selection: {
        startDate: moment(new Date()),
        endDate: moment(new Date()),
        key: 'selection'
    },
    lang
};

const handleSelect = (ranges, props) => {
    props.onSelect(ranges);
    return ranges.selection;
};

function DateRangePicker(props, { value }) {
    const [selection, setSelection] = useState(value || init.selection);
    return (
        <DateRange
            locale={rdrLocales[init.lang]}
            months={2}
            ranges={[selection]}
            onChange={ranges => setSelection(handleSelect(ranges, props))}
            moveRangeOnFirstSelection={false}
            className={'PreviewArea'}
            direction="horizontal"
        />
    );
}

export default onClickOutside(DateRangePicker);

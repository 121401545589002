// Lib
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
// Actions
import { updateWorkspaceSetting } from '../../../core/actions';
import { EVENT_MODIFY_WORKSPACE_SETTINGS, segmentTrack } from '../../../layout/helper';
import { ConfigTabsContext } from '../../ConfigContext';

const TicketsConfiguration = props => {
    const { t } = useTranslation();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const [currentlyModifiedConfig, setCurrentlyModifiedConfig] = useState([]);
    const [cancelModifs, setCancelModifs] = useState(false);
    const [enableSaveButton, setEnableSaveButton] = useState(false);
    const [enableCancelButton, setEnableCancelButton] = useState(false);
    const [, setDisableTabs] = useContext(ConfigTabsContext);
    const tabs = [
        {
            componentName: 'ArchivedTicketsConfiguration',
            configName: 'auto_ticket_archiving'
        }
    ];

    useEffect(() => { // Enable save button, cancel button, and tabs when needed
        const enableSave = currentlyModifiedConfig.length !== 0 && !currentlyModifiedConfig.find(conf => conf.configValue === 'error_field');
        const enableCancel = currentlyModifiedConfig.length !== 0 || currentlyModifiedConfig.find(conf => conf.configValue === 'error_field');
        setEnableSaveButton(enableSave);
        setEnableCancelButton(enableCancel);
        setDisableTabs(enableSave || enableCancel);
    }, [currentlyModifiedConfig]);

    return (
        <div className={'configuration-page-content'}>
            <Component componentName={tabs[props.tabIdx].componentName} cancelModifs={cancelModifs} setCancelModfis={value => setCancelModifs(value)} modifyCurrentConfig={newValue => setCurrentlyModifiedConfig(newValue)} />
            <div className={'configuration-menu-footer'}>
                <Component
                    componentName={'Button'}
                    className={'custom-cancel-button'}
                    onClick={() => setCancelModifs(true)}
                    text={t('common:cancel')}
                    disabled={!enableCancelButton}
                />
                <Component
                    componentName={'Button'}
                    className={'custom-save-button'}
                    onClick={() => {
                        dispatch(updateWorkspaceSetting(
                            currentlyModifiedConfig[0]?.configName,
                            currentlyModifiedConfig[0]?.configValue,
                            Object.prototype.hasOwnProperty.call(workspaceSettings, currentlyModifiedConfig[0]?.configName)
                        )).then(() => {
                            const modifiedWorkspaceSettings = [{
                                workspaceSettingName: currentlyModifiedConfig[0]?.configName,
                                oldWorkspaceSettingValue: workspaceSettings[currentlyModifiedConfig[0]?.configName],
                                newWorkspaceSettingValue: currentlyModifiedConfig[0]?.configValue
                            }];
                            segmentTrack(EVENT_MODIFY_WORKSPACE_SETTINGS, { modifiedWorkspaceSettings });
                            setCurrentlyModifiedConfig([]);
                            setEnableSaveButton(false);
                            setEnableCancelButton(false);
                            setCancelModifs(false);
                            setDisableTabs(false);
                        });
                    }}
                    text={t('common:save')}
                    disabled={!enableSaveButton}
                />
            </div>
        </div>
    );
};

export default TicketsConfiguration;

// Libs
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

export default () => {
    const [t] = useTranslation();
    const levels = useSelector(state => state.tickets.priorityLevels);
    const ticket = useSelector(state => state.tickets.ticketShownInDetailsPopup);
    const [value] = useState(find(levels, level => level?.id === ticket.priority));

    return (
        <p className={`info-value ${value ? '' : 'undefined'}`}>
            {t(`tickets:${value.name}`)}
        </p>
    );
};

// Libs
import React from 'react';
import { Route, Switch } from 'react-router';
// Components
import AuthenticationBackground from '../layout/components/AuthenticationBackground';
// Context
import { useComponentsPool } from '../../ComponentsPool';
// Scss
import './style.scss';

export default () => {
    const { Component } = useComponentsPool();

    return (
        <AuthenticationBackground>
            <div className="content">
                <div className="step-wrapper">
                    <Component componentName={'MultiWorkspacesHomePage'} />
                </div>
            </div>
        </AuthenticationBackground>
    );
};

import moment from 'moment';
import 'moment/locale/fr'; // Make french translations available
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsOnline } from 'react-use-is-online';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import classnames from 'classnames';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { emptyInstanceEventsList, updateInstance } from '../actions';
import {
    calendarFormats,
    eventPropGetter
} from './CalendarComponents/calendar-util';
import './RoundsCalendar.scss';
import CalendarCreationPopup from './CalendarComponents/CalendarCreationPopup/CalendarCreationPopup';
import InstanceDetailsPopup from './CalendarComponents/CalendarDetailPopup/InstanceDetailsPopup';
import InstanceEvent from './CalendarComponents/InstanceEvent';
import { useComponentsPool } from '../../../ComponentsPool';
import { canCreateUpdateDeleteInstance } from '../../users/helper';
import { getAppLanguage } from '../../layout/helper';
import createLoadingSelector from '../../layout/actions';
import { isAllDayEvent } from '../helper';
import { RoundsContext } from '../RoundsContext';
import { notification } from '../../notification/actions';
import { retrieveLwpAreaConfig } from '../../devices/helper';

moment.locale(getAppLanguage(), { week: { dow: 1 } }); // Calendar week starts with Monday no matter the app language
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const now = new Date();

const loadingSelector = createLoadingSelector(['GET_ROUND_INSTANCES']);
const RoundsCalendar = props => {
    const roundsContext = useContext(RoundsContext)
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const { isOnline } = useIsOnline();
    const { Component } = useComponentsPool();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const currentUserRole = useSelector(state => state.users.currentUser.workspace_role?.workspace_role_name);
    const devices = useSelector(state => state.devices.list);
    const instances = props.calendarInstances;
    const [events, setEvents] = useState([]);
    const [, setDisplayEveryAllDayEvents] = useState(false);
    const [current, setCurrent] = useState();
    const [creation, setCreation] = useState();
    const offSet = -new Date().getTimezoneOffset();
    const dayLayoutAlgorithm = roundsContext.getters.calendarViewMode === 'day' ? 'no-overlap' : 'overlap';
    const minDisplayedAllDayEvent = 2;
    // Used to retrieve the updated values in 'components' of react-big-calendar
    // We cannot retrieve the updated state with useState because of a bug on the library
    const allDayEventsRef = useRef([]);
    const displayEveryAllDaysEventsRef = useRef(false);
    const [mouseIsOnallDayCell, setMouseIsOnallDayCell] = useState(false);

     useEffect(() => {
        if (devices.find(device => device.custom_field.type === 'LWP')) { retrieveLwpAreaConfig(dispatch, devices); }
    }, []);

    useEffect(() => { allDayEventsRef.current = events.filter(event => event.allDay); }, [events]);

    const timeGutterHeader = () => {
        if (allDayEventsRef.current.length === 0 || allDayEventsRef.current.length === minDisplayedAllDayEvent) { return <></> }
        return (
            <>
                <div
                    role={'button'}
                    className={'allday-event-toggle'}
                    onClick={() => { // Hide or display allDay events
                        displayEveryAllDaysEventsRef.current = displayEveryAllDaysEventsRef.current !== true;
                        setDisplayEveryAllDayEvents(prev => !prev); // Required to update the component
                    }}
                >
                    <Component componentName={'Icon'} type={displayEveryAllDaysEventsRef.current ? 'chevronUp' : 'chevronBottom'} />
                </div>
            </>
        );
    };

    const resetEventActionParams = eventCopy => {
        const event = eventCopy;
        if (event.resizedDirection) { delete event.resizedDirection; }
        if (event.action) { delete event.action; }
        return event;
    };

    // when we start to move or resize an event
    const onDragStart = data => {
        const { event, action, direction } = data;
        const idx = events.indexOf(event);
        const newEvents = [...events];

        // in case the user cancelled his action, we need to reset the params
        // (since it will not go in onEventResizeOrDnd since it was cancelled)
        newEvents[idx] = resetEventActionParams(newEvents[idx]);

        // then we put the right values
        newEvents[idx].resizedDirection = direction;
        newEvents[idx].action = action;
    };

    const updateEventsAfterResizeOrDnd = (eventsCopy, idx) => {
        const newEvents = eventsCopy;
        setEvents(newEvents);
        // In the reducer we only update the started_at and ended_at for now
        dispatch(
            updateInstance(
                newEvents[idx].resource.id,
                { started_at: moment(newEvents[idx].start).utcOffset(offSet).format(), ended_at: moment(newEvents[idx].end).utcOffset(offSet).format() }
            )
        );
    };

    const onEventDropOrResize = data => {
        if (isOnline) {
            const { event, start, end } = data;
            const idx = events.indexOf(event);
            const newEvents = [...events];
            let eventWasResizedOrMoved = false;

            if (newEvents[idx].action === 'resize') {
                const eventHoursDuration = moment.duration(moment(end).diff(moment(newEvents[idx].resource?.started_at))).asHours();
                const eventMinutesDuration = moment.duration(moment(end).diff(moment(newEvents[idx].resource?.started_at))).asMinutes();
                // We only update the event if it is less than 24hours (+ we can only resize downwardly)
                if (newEvents[idx].resizedDirection === 'DOWN' && eventMinutesDuration >= 1 && eventHoursDuration < 24) {
                    newEvents[idx].end = end;
                    eventWasResizedOrMoved = true;
                }
            } else { // else we are dropping the event
                // When we drag and drop an event both start date and end date changed
                newEvents[idx].start = start;
                newEvents[idx].end = end;
                eventWasResizedOrMoved = true;
            }

            newEvents[idx] = resetEventActionParams(newEvents[idx]);

            if (eventWasResizedOrMoved) {
                updateEventsAfterResizeOrDnd(newEvents, idx, eventWasResizedOrMoved);
            }
        } else {
            dispatch(notification(t('notifications:update_instance_error'), 'error', 'frown'));
        }
    };

    const onView = selectedView => {
        roundsContext.setters.setCalendarViewMode(selectedView);
    };

    const onNavigate = selectedDate => {
        roundsContext.setters.setSelectedDayOnCalendar(selectedDate)
    };

    const onSelectEvent = (e, event) => {
        if (!e.resource) { // On grouped events
            // We redirect to the 'day' view in order to see every grouped instances
            if (!e.allDay) { roundsContext.setters.setCalendarViewMode('day'); }
            roundsContext.setters.setSelectedDayOnCalendar(e.start);
        } else {
            setCurrent({
                instance: e.resource,
                anchorEl: event.currentTarget
            });
        }
    };

    // Check whether the mouse is on the allDay cell
    if (document.getElementsByClassName('rbc-allday-cell').length > 0) {
        document.getElementsByClassName('rbc-allday-cell')[0].onmouseover = () => { setMouseIsOnallDayCell(true); };
        document.getElementsByClassName('rbc-allday-cell')[0].onmouseout = () => { setMouseIsOnallDayCell(false); };
    }

    const onSelectSlot = e => {
        // Disable event creation via the allDay cells
        if (!mouseIsOnallDayCell) { setCreation(e); }
    };

    const onCreationClose = () => {
        setCreation(null);
    };

    const onCloseDetailPopup = () => {
        dispatch(emptyInstanceEventsList());
        setCurrent(null);
    };

    useEffect(() => {
        if (roundsContext.getters.calendarViewMode === 'week') {
            roundsContext.setters.setSelectedDayOnCalendar(
                moment(roundsContext.getters.currentWeekDates.start).clone().startOf('isoWeek').format('YYYY-MM-DD')
            );
        }
    }, [events]);

    useEffect(() => {
        const results = instances ? instances.map(instance => {
            const start = new Date(instance.started_at);
            const end = new Date(instance.ended_at);
            return {
                start,
                end,
                title: instance.round_name,
                allDay: isAllDayEvent(instance.started_at, instance.ended_at),
                resource: instance
            };
        }) : [];
        setEvents(results);
        if (roundsContext.getters.calendarViewMode === 'week') {
            const groupedEvents = [];
            let maxCount = 1;
            results.forEach(event => { // We group event if they start and end at the same times and are not allDay events
                const duplicatedEvent = groupedEvents.find(obj => obj.start.getTime() === event.start.getTime() &&
                    obj.end.getTime() === event.end.getTime() && !event.allDay
                );
                if (!duplicatedEvent) {
                    groupedEvents.push({
                        start: event.start,
                        end: event.end,
                        title: event.title,
                        allDay: isAllDayEvent(event.start, event.end),
                        resource: event.resource,
                        count: 1,
                        groupedStatus: event.resource.status
                    });
                } else {
                    const newCount = duplicatedEvent.count + 1;
                    duplicatedEvent.count = newCount;
                    duplicatedEvent.resource = undefined;
                    duplicatedEvent.title = `${newCount} ${t('rounds:rounds')}`;
                    duplicatedEvent.groupedStatus = duplicatedEvent.groupedStatus !== 'GROUPED' && event.resource.status === duplicatedEvent.groupedStatus
                        ? duplicatedEvent.groupedStatus
                        : 'GROUPED';
                    if (newCount > maxCount) maxCount = newCount;
                }
            });
            // modifying this will affect the drag and drop conditions
            if (maxCount >= 5) {
                setEvents(groupedEvents);
            }
        }
    }, [instances, roundsContext.getters.calendarViewMode]);

    const initTime = now;
    initTime.setHours(9);

    // we cannot move instances that are finished nor the bloc of grouped instances
    const isEnabledDndAndResize = event => (
        roundsContext.getters.calendarViewMode === 'week'
            ? event?.resource?.status !== 'COMPLETE' && event?.resource?.status !== 'INCOMPLETE' && (event?.count ? event.count === 1 : true) && !isAllDayEvent(event.start, event.end)
            : event?.resource?.status !== 'COMPLETE' && event?.resource?.status !== 'INCOMPLETE' && !isAllDayEvent(event.start, event.end)
    );

    props.onViewChange(roundsContext.getters.calendarViewMode);

    return (
        <>
            <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
            {/* Calendar view container */}
            <div className={classnames([`merciyanis-calendar ${roundsContext.getters.calendarViewMode}-view-calendar`, { 'calendar-without-allday-event': !events.find(event => event.allDay) }])}>
                <DnDCalendar
                    localizer={localizer}
                    defaultDate={now}
                    date={roundsContext.getters.selectedDayOnCalendar} // if selectedDayOnCalendar is null => it will use current date by default
                    scrollToTime={initTime}
                    views={['week', 'day']}
                    view={roundsContext.getters.calendarViewMode}
                    onView={onView}
                    onNavigate={onNavigate}
                    defaultView="week"
                    formats={calendarFormats}
                    events={events}
                    allDayMaxRows={displayEveryAllDaysEventsRef.current ? 'infinity' : minDisplayedAllDayEvent} // Number of allDay events to display
                    popup // Display a popup when clicking on '+x events' text ("messages" param below) on the calendar
                    messages={{ showMore: total => `+${total} ${t(`rounds:${total > 1 ? 'instances' : 'instance'}`)}` }}
                    toolbar={false}
                    showMultiDayTimes
                    dayLayoutAlgorithm={dayLayoutAlgorithm}
                    components={{
                        event: InstanceEvent,
                        timeGutterHeader
                    }}
                    // enable the creation of event if the user have the right
                    selectable={canCreateUpdateDeleteInstance(currentUserRole)}
                    eventPropGetter={eventPropGetter}
                    onSelectEvent={onSelectEvent}
                    onSelectSlot={onSelectSlot}
                    style={{ height: '100%' }}
                    tooltipAccessor={null}
                    onDragStart={onDragStart}
                    onEventResize={onEventDropOrResize}
                    onEventDrop={onEventDropOrResize}
                    // enable both drag and resize if the user have the right
                    draggableAccessor={event => canCreateUpdateDeleteInstance(currentUserRole) && isEnabledDndAndResize(event)}
                />
            </div>
            {current && <InstanceDetailsPopup current={current} onClose={onCloseDetailPopup} />}
            {creation && <CalendarCreationPopup start={creation.start} end={creation.end} onClose={onCreationClose} currentWeekFirstDay={moment(roundsContext.getters.currentWeekDates.start)} />}
        </>
    );
};
export default RoundsCalendar;

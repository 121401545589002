import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import Icon from '../../../layout/components/Icon';
import { getRoundInstanceStatus, getStatusFromInstanceAndEventStatus } from '../../helper';
import createLoadingSelector from '../../../layout/actions';

const EditInstanceBtn = ({ setEditedPassages, isInEditionMode, setIsInEditionMode }) => {
    const loadingSelector = createLoadingSelector(['GET_INSTANCE_EVENTS_LIST']);
    const [t] = useTranslation();
    const currentInstance = useSelector(state => state.rounds.roundInstanceShownInDetailsPopup.currentInstance);
    const roundInstanceShownInDetailsPopup = useSelector(state => state.rounds.roundInstanceShownInDetailsPopup);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));

    const handleClick = () => {
        setIsInEditionMode(!isInEditionMode);
        if (isInEditionMode) { // Then isInEditionMode will become false, and the modifs must be canceled
            setEditedPassages();
        }
    };

    const roundInstanceStatus = getRoundInstanceStatus(currentInstance.status, currentInstance.validated_steps_count);
    const editionButtonDisabledStatusAndTooltip = () => {
        let isDisabled = false;
        let tooltipText;
        const instancePassages = roundInstanceShownInDetailsPopup.eventsList.filter(event => event.event_type === 'PASSAGE');

        if (isLoading) { // We cannot edit passages while it's still loading
            tooltipText = '';
            isDisabled = true;
        } else if (roundInstanceStatus === 'NOT_STARTED') { // We cannot edit passages that are not started
            const statusTranslation = t(`rounds:${roundInstanceStatus}_without_emoji`);
            tooltipText = t('rounds:cannot_edit_passages_schedules_because_of_status', { roundInstanceStatus: statusTranslation });
            isDisabled = true;
        } else if (
            roundInstanceStatus === 'IN_PROGRESS' &&
            instancePassages.every(event => getStatusFromInstanceAndEventStatus(currentInstance.status, event.event_detail.status) !== 'COMPLETE')
        ) {
            tooltipText = t('rounds:cannot_edit_passages_schedules_because_not_completed');
            isDisabled = true;
        } else if (isInEditionMode) {
            tooltipText = t('common:cancel_changes');
        } else {
            tooltipText = t('rounds:edit_passages_schedules');
        }
        return { isDisabled, tooltipText };
    };

    const disableButton = editionButtonDisabledStatusAndTooltip().isDisabled;
    const tooltipText = editionButtonDisabledStatusAndTooltip().tooltipText;

    return (
        <Tooltip arrow={false} title={tooltipText} placement={'top'}>
            <div className={classnames('edit-btn', { 'disabled-effect': disableButton })}>
                <IconButton onClick={() => handleClick()} disabled={disableButton}>
                    <Icon type={'faPen'} />
                </IconButton>
            </div>
        </Tooltip>
    );
};

export default EditInstanceBtn;

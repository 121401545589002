// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useModal } from '../../../modal/ModalContext';
import { useFootBar } from '../../../footbar/FootBarContext';
// Actions

export default props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const modal = useModal();
    const footBar = useFootBar();
    const item = modal.getWithFootBar() ? modal.getItem() : footBar.getItem();

    return (
        <Component
            componentName={'FootbarButton'}
            displayCondition={props.userWorkspaceRights.includes('UPDATE:User') && props.list.length === 1 && !props.hasInvitations}
            buttonText={t('descriptions:add_user_to_group', { count: props.list.length })}
            callbackAction={() => modal.update({ name: 'AddGroupToUserPopup', item })}
            buttonIcon={'addUser'}
        />
    );
};

// Libs
import React, { useEffect, useState } from 'react';
// Actions
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../layout/components/Icon';
import MobileDocumentQRCodeFileViewer from './MobileDocumentQRCodeFileViewer';
import { getQRCodeDocuments } from '../actions';
import createLoadingSelector from '../../layout/actions';
import { useComponentsPool } from '../../../ComponentsPool';
import QRCodeBackButton from './QRCodeBackButton';
import BarrierCone from '../../layout/assets/barrier-cone.svg';
import { setIsLoggable } from '../../users/actions';
import { EVENT_CLICK_QRCODE_DOCUMENT, segmentTrack } from '../../layout/helper';
import { getDocumentTypeIcon } from '../../documents/helper';

const loadingSelector = createLoadingSelector(['GET_QRCODE_DOCUMENTS']);
const DocumentQRCodeForm = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [documents, setDocuments] = useState([]);
    const [file, setFile] = useState({});
    const [openDocumentViewer, setOpenDocumentViewer] = useState(false);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const isLogged = useSelector(state => state.authentication.isLogged);
    const [isManualLoading, setIsManualLoadingsLoading] = useState(true);
    // Users can access documents list if there are documents
    const canAccessDocumentPage = documents.length > 0;

    useEffect(() => {
        if (props.device?.device_id) {
            dispatch(getQRCodeDocuments(props.device.device_id, !isLogged)).then(res => setDocuments(res.value));
        }
        // Set manual loading to prevent the error page display
        const timer = setTimeout(() => {
            setIsManualLoadingsLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const getCannotAccessDocumentPage = () => {
        return (
            <Component
                componentName={'MobileErrorPage'}
                title={t('passages:qrcode_in_work')}
                subtitle={t('passages:you_will_be_able_soon')}
                icon={<BarrierCone />}
                hasGoBackArrow={props.qrcodesHasManyMode}
            />
        );
    };

    return (
        <div className="incident-page">
            {(isLoading || !canAccessDocumentPage && isManualLoading)
                && (
                    <Component
                        componentName={'LoaderBarTop'}
                        isLoading={isLoading || !canAccessDocumentPage && isManualLoading}
                    />
                )
            }
            {canAccessDocumentPage ? (
                <>
                    <div className={'qrcode-form-title-wrapper document-qrcode-form-title-wrapper'}>
                        {props.qrcodesHasManyMode && <QRCodeBackButton />}
                        <p>{t('passages:which_document_to_see')}</p>
                    </div>
                    <div className={'qrcode-doc-form-wrapper'}>
                        {documents.sort((a, b) => a.needs_authentication - b.needs_authentication).map(document => // We display documents requiring an authentication last
                            (
                                document.needs_authentication && !isLogged ? ( // Document accessible when authenticated only
                                    <div className={'qrcode-doc-button'}>
                                        <div className={'qrcode-mode-button-left-part-logged qrcode-document-type-icon-logged'}>
                                            <Icon type={'padlock'} />
                                        </div>
                                        <div
                                            className={'qrcode-doc-button-right-part'}
                                            onClick={() => segmentTrack(EVENT_CLICK_QRCODE_DOCUMENT, { qrCodeId: props.device?.device_id })}
                                        >
                                            <p className={classnames(['qrcode-doc-button-title'])}>{document.title}</p>
                                            <p className={'qrcode-doc-button-description'}>
                                                <span className={'underlined-text'} onClick={() => dispatch(setIsLoggable(true))}>{t('workspaces:reconnect')}</span>{` ${t('common:to_access')}`}
                                            </p>
                                        </div>
                                    </div>
                                ) : ( // Document accessible without authentication
                                    <div className={'qrcode-doc-button'}>
                                        <div className={'qrcode-mode-button-left-part qrcode-document-type-icon'}>
                                            <Icon type={getDocumentTypeIcon(document.type)} />
                                        </div>
                                        <div
                                            className={'qrcode-doc-button-right-part'}
                                            onClick={() => {
                                                setFile(document);
                                                setOpenDocumentViewer(true);
                                                segmentTrack(EVENT_CLICK_QRCODE_DOCUMENT, { qrCodeId: props.device?.device_id });
                                            }}
                                        >
                                            <p className={classnames(['qrcode-doc-button-title', !document.description && 'qrcode-doc-button-title-without-description'])}>
                                                {document.title}
                                            </p>
                                            {document.description && (
                                                <p className={'qrcode-doc-button-description'}>
                                                    {document.description}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                )
                            )
                        )}
                    </div>
                    {openDocumentViewer && <MobileDocumentQRCodeFileViewer fileId={file?.id} device={props.device} closeModal={() => setOpenDocumentViewer(false)} />}
                </>
            ) : isManualLoading
                ? <></>
                : getCannotAccessDocumentPage()
            }
        </div>
    );
};

export default DocumentQRCodeForm;

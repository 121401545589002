// Lib
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Radio, RadioGroup } from '@mui/material';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';

const ArchivedTicketsConfiguration = props => {
    const { t } = useTranslation();
    const { Component } = useComponentsPool();
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const nbDaysBeforeArchiving = workspaceSettings?.nb_days_auto_archiving_completed_tickets;
    const [enableAutoArchivingConfig, setEnableAutoArchivingConfig] = useState(!!(nbDaysBeforeArchiving && nbDaysBeforeArchiving !== 0));
    const initialConfig = {
        nb_days_auto_archiving_completed_tickets: nbDaysBeforeArchiving
    };
    const [modifiedConfig, setModifiedConfig] = useState(initialConfig);

    useEffect(() => {
        if (props.cancelModifs) {
            setEnableAutoArchivingConfig(!!(nbDaysBeforeArchiving && nbDaysBeforeArchiving !== 0));
            setModifiedConfig(initialConfig);
            props.setCancelModfis(false);
        }
    }, [props.cancelModifs]);

    const handleChangeRadioButton = newRadioButtonValue => {
        // When we switch to archive_every_x_days radio button we disable the save button
        setEnableAutoArchivingConfig(newRadioButtonValue);
        setModifiedConfig({ ...modifiedConfig, nb_days_auto_archiving_completed_tickets: !newRadioButtonValue ? null : 'error_field' });
    };

    useEffect(() => {
        const configToModify = [];
        Object.entries(modifiedConfig).forEach(config => {
            const initialConfigValue = initialConfig[config[0]];
            if (config[1] !== initialConfigValue) {
                configToModify.push({
                    configName: config[0],
                    configValue: enableAutoArchivingConfig
                        ? config[1] && !isNaN(config[1]) && config[1] !== 0
                            ? parseInt(config[1], 10)
                            : 'error_field'
                        : null
                });
            }
        });
        props.modifyCurrentConfig(configToModify);
    }, [modifiedConfig]);

    const getTextForAutoArchiving = nbDaysWS => {
        if (isNaN(nbDaysWS) || nbDaysWS === 'error_field') {
            return t('common:unknown_nbdays');
        }
        const nbDaysAsText = nbDaysWS > 1 ? t('workspace:x_days', { nbDays: nbDaysWS }) : t('workspace:x_day', { nbDays: nbDaysWS });
        return (
            <>
                {t('workspace:ticket_archiving_explanation')}
                <span className={'bold-text'}>{` ${nbDaysAsText}. `}</span>
                {t('workspace:ticket_archiving_explanation2')}
            </>
        );
    };
    return (
        <>
            <div className={'configuration-page-top-content'}>
                <Component componentName={'Icon'} type={'archiveClock'} />
                <div className={'configuration-page-title'}>
                    <h3>{t('workspace:configure_auto_ticket_archiving')}</h3>
                    <p>{t('workspace:choose_when_to_archive_tickets')}</p>
                </div>
            </div>
            <div className={'configuration-page-text'}>
                <div className={'configuration-page-section-wrapper'}>
                    <div className={'configuration-page-section-title'}>{t('workspace:auto_ticket_archiving')}</div>
                    <div className={'configuration-page-explanation-wrapper'}>
                        <div className={'configuration-page-explanation-content explanation-has-multi-lines'}>
                            <div className={'configuration-page-explanation-text'}>
                                <p>{t('workspace:ticket_archiving_date_question')}</p>
                                <p>{t('workspace:ticket_archiving_modification_time')}</p>
                            </div>
                            <div className={'configuration-page-explanation-fields'}>
                                <RadioGroup
                                    row
                                    value={enableAutoArchivingConfig}
                                    onChange={e => handleChangeRadioButton(e.target.value === 'true')}
                                >
                                    <FormControlLabel
                                        className={!enableAutoArchivingConfig && 'is-not-checked'}
                                        value={true}
                                        control={<Radio />}
                                        label={(
                                            <div className={'archived_radio_field'}>
                                                {t('common:after')}
                                                <Component
                                                    title={''}
                                                    componentName={'EditableField'}
                                                    renderValue={'Input'}
                                                    props={{
                                                        value: modifiedConfig.nb_days_auto_archiving_completed_tickets,
                                                        changeInputValue: modifiedConfig.nb_days_auto_archiving_completed_tickets,
                                                        forbidMathsSymbolsSymbols: true,
                                                        onlyNumber: true,
                                                        type: 'number',
                                                        emptyInput: !enableAutoArchivingConfig ? true : undefined,
                                                        disabled: enableAutoArchivingConfig === false,
                                                        onChange: value => { // We cannot put empty values
                                                            if (value) { setModifiedConfig({ ...modifiedConfig, nb_days_auto_archiving_completed_tickets: Number(value) }); }
                                                        },
                                                        inputProps: { min: 1, max: 999, step: 1 },
                                                        error: true
                                                    }}
                                                />
                                                {t('common:days')}
                                            </div>
                                        )}
                                    />
                                    <FormControlLabel
                                        className={enableAutoArchivingConfig ? 'is-not-checked' : 'selected-option-text'}
                                        value={false}
                                        control={<Radio />}
                                        label={t('common:never')}
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                        <div className={'configuration-page-explanation'}>
                            <span role={'img'} aria-label={'light-emoji'}>💡</span>
                            {enableAutoArchivingConfig
                                ? getTextForAutoArchiving(modifiedConfig.nb_days_auto_archiving_completed_tickets)
                                : (
                                    <>
                                        <span className={'bold-text'}>{t('workspace:no_ticket_archiving')}</span>
                                        {` ${t('workspace:no_ticket_archiving_explanation')}`}
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ArchivedTicketsConfiguration;

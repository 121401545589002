// Libs
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
// Components
import {
    Getter,
    Plugin, Template, TemplateConnector, TemplatePlaceholder
} from '@devexpress/dx-react-core';
import {
    Grid, TableHeaderRow, TableSelection, VirtualTable, Table
} from '@devexpress/dx-react-grid-material-ui';
import {
    IntegratedSelection, IntegratedSorting, SelectionState, SortingState, TableColumnVisibility
} from '@devexpress/dx-react-grid';
import ButtonTableToggle from './ButtonTableToggle';
// Helper
import { getId } from '../helper';
// Style
import '../styles/_table.scss';
// Context
import { useModal } from '../../modal/ModalContext';
import { useFootBar } from '../../footbar/FootBarContext';
import { useComponentsPool } from '../../../ComponentsPool';

/* DOC IMPORTANT */
/**
 * For table-striped
 * https://codesandbox.io/s/devextreme-react-grid-for-material-ui-forked-3zwhx?file=/index.js:1363-1757
 *
 * For table-striped with TableSelection
 * https://stackblitz.com/edit/react-vnuh8n?file=index.js
 * https://stackblitz.com/edit/react-c3qmzr?file=index.js
 * https://stackblitz.com/edit/react-wagpnw?file=index.js
 *
 * Issue github
 * https://github.com/DevExpress/devextreme-reactive/issues/437
 */

/**
 * Disable checkbox by cell
 * https://stackblitz.com/edit/react-44mgbv?file=index.js
 */

const formatRow = (list, props) => list.map(resource => {
    const returnObj = { id: getId(resource) };
    props.columns?.forEach(column => {
        const CustomComponent = column.component;
        if (CustomComponent === null) {
            if (resource.is_inactive !== undefined) {
                returnObj[column.name] = resource.is_inactive;
            } else {
                returnObj[column.name] = resource[column.name];
            }
        } else {
            returnObj[column.name] = (
                <CustomComponent
                    resource={resource}
                    column={column}
                    props={column.customProps}
                />
            );
        }
    });
    return returnObj;
});

const disableCheckbox = ['2f14b839-4914-41f2-bca2-6f91c3f9ac92'];
const rowSelectionEnabled = row => !disableCheckbox.includes(row.name.props.resource.group_id);
/**
 * Plugin override template => SELECTION
 * Filter enabled selection
 */
const PatchedIntegratedSelection = props => {
    const { ...restProps } = props;
    let rowCopy = null;
    return (
        <Plugin>
            <Getter
                name="rows"
                computed={({ rows }) => {
                    rowCopy = rows;
                    return rows.filter(rowSelectionEnabled);
                }}
            />
            <IntegratedSelection {...restProps} />
            <Getter
                name="rows"
                computed={() => rowCopy}
            />
        </Plugin>
    );
};

const PatchedTableSelection = props => {
    const { ...restProps } = props;
    return (
        <TableSelection
            cellComponent={cellProps => (rowSelectionEnabled(cellProps.tableRow.row) && !props.disableTableCheckbox
                ? <TableSelection.Cell {...cellProps} />
                : <VirtualTable.StubCell {...cellProps} />
            )}
            {...restProps}
        />
    );
};

const styles = () => ({
    tableStriped: { backgroundColor: '#fff' },
    odd: { background: 'linear-gradient(to top,rgba(255, 255, 255,50%),rgba(255, 255, 255,50%)) #E5E5E5;' }
});
// eslint-disable-next-line max-len
const TableComponentBase = ({ classes, ...restProps }) => <Table.Table {...restProps} className={classes.tableStriped} />;
export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

/**
 * Plugin override template => TABLE ROW
 * Add rowindex param for table-striped
 */
class TableRowsIndexer extends React.PureComponent {
    render() {
        return (
            <Plugin>
                <Template
                    name="tableRow"
                    predicate={({ tableRow }) => tableRow?.type === Table.ROW_TYPE}
                >
                    {({ tableRow, ...restParams }) => (
                        <TemplateConnector>
                            {props => {
                                const rowindex = props.rows.indexOf(tableRow.row);
                                return (
                                    <TemplatePlaceholder
                                        params={{
                                            rowindex,
                                            tableRow,
                                            selected: props.selection,
                                            ...restParams
                                        }}
                                    />
                                );
                            }}
                        </TemplateConnector>
                    )}
                </Template>
            </Plugin>
        );
    }
}

const rowStyle = { cursor: 'pointer' };
const IotRocketTable = props => {
    const { Component } = useComponentsPool();
    const [selection, setSelection] = useState([]);
    const [columnExtensions] = useState(props.columnExtensions);
    const [rows, setRows] = useState(() => formatRow(props.list, props));
    const modal = useModal();
    const [t] = useTranslation();
    const footbar = useFootBar();

    useEffect(() => {
        setSelection(footbar.selected);
    }, [footbar.selected]);

    useEffect(() => {
        setRows(formatRow(props.list, props));
    }, [props.list]);

    const SelectionTableRow = useMemo(() => withStyles(styles, { name: 'Row' })(
        ({
            classes, rowindex, row, tableRow, selected, ...restProps
        }) => {
            const propsCopy = { ...restProps };
            delete propsCopy.selectByRowClick; // Fix: "React does not recognize the `selectByRowClick` prop on a DOM element"
            return (
                <Table.Row
                    {...propsCopy}
                    {...{ hover: true }}
                    highlighted={restProps.highlighted.toString()} // Fix: "Received `false` for a non-boolean attribute `highlighted`"
                    className={rowindex % 2 ? classes.odd : rows[rowindex]?.is_inactive ? 'disabled' : null}
                    onClick={e => {
                        if (IS_DEBUG && e.shiftKey && e.ctrlKey) modal.update({ name: 'DebugModal', item: rows[rowindex] });
                        else if (props.onRowClick) props.onRowClick(rows[rowindex]);
                    }}
                    onToggle={() => {}}
                    selected={selected.findIndex(i => i === tableRow.rowId) > -1}
                    style={rowStyle}
                />
            );
        }
    ), [rows]);

    const TableRow = useMemo(() => withStyles(styles, { name: 'Row' })(
        ({
            classes, rowindex, row, ...restProps
        }) => (
            <Table.Row
                {...restProps}
                {...{ hover: true }}
                className={rowindex % 2 ? classes.odd : rows[rowindex]?.is_inactive ? 'disabled' : null}
                onClick={e => {
                    if (IS_DEBUG && e.shiftKey && e.ctrlKey) modal.update({ name: 'DebugModal', item: rows[rowindex] });
                    else if (props.onRowClick) props.onRowClick(rows[rowindex]);
                }}
                style={rowStyle}
            />
        )
    ), [rows]);

    const getRowId = row => row.id;

    const [virtualTableOptions] = useState(props.toggleSelected !== undefined ? {} : { rowComponent: TableRow });

    if (props.emptyNotVisible && rows.length === 0 || rows.length === 0 && !props.emptyNotVisible) {
        return (
            <div className={'no-data'}>
                <Component componentName={'NoData'} view={'table'} />
            </div>
        );
    }
    return (
        <div
            className={`table_view_container ${props.className}`}
            style={{ height: props.height, maxHeight: props.maxHeight || 'auto' }}
        >
            <div className={'table'}>
                {props.className.includes('not-configured-table') && <ButtonTableToggle {...props} />}
                <Grid rows={rows} columns={props.columns} getRowId={getRowId}>
                    <SortingState columnExtensions={columnExtensions} />
                    {props.toggleSelected !== undefined && (
                        <SelectionState
                            selection={selection}
                            onSelectionChange={selected => {
                                setSelection(selected);
                                props.toggleSelected(selected); // if you need no selectable rows => difference(selected, this.props.selectable)
                            }}
                        />
                    )}
                    <IntegratedSorting />
                    {/* Override template integration */}
                    {props.toggleSelected !== undefined && <PatchedIntegratedSelection />}
                    <VirtualTable
                        height={props.height || 'auto'}
                        tableComponent={TableComponent}
                        estimatedRowHeight={70}
                        {...virtualTableOptions}
                    />
                    <TableHeaderRow showSortingControls={props.isSort} />
                    <TableColumnVisibility
                        emptyMessageComponent={''}
                        hiddenColumnNames={props.hiddenColumnNames}
                    />
                    {props.toggleSelected !== undefined && (
                        <PatchedTableSelection
                            disableTableCheckbox={props.disableTableCheckbox}
                            rowComponent={SelectionTableRow}
                            showSelectAll={!props.disableTableCheckbox}
                            highlightRow
                        />
                    )}
                    {/* Override template */}
                    {/* It's for table-striped */}
                    <TableRowsIndexer />
                </Grid>
            </div>
        </div>
    );
};

IotRocketTable.defaultProps = {
    className: 'full-height-table',
    columnExtensions: [],
    hiddenColumnNames: [],
    toggleSelected: undefined
};

IotRocketTable.propTypes = {
    className: PropTypes.string,
    columnExtensions: PropTypes.arrayOf(PropTypes.shape({
        columnName: PropTypes.string,
        sortingEnabled: PropTypes.bool
    })),
    columns: PropTypes.arrayOf(PropTypes.shape({
        component: PropTypes.func,
        name: PropTypes.string.isRequired,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
    })).isRequired,
    hiddenColumnNames: PropTypes.array,
    list: PropTypes.array.isRequired,
    toggleSelected: PropTypes.func
};

export default React.memo(IotRocketTable);

// Libs
import React, { useMemo, useState } from 'react';
import { isEmpty, replace } from 'lodash';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
// Components
import Icon from '../../../layout/components/Icon';
// Helper
import { isEmail } from '../../helper';


const UserContact = props => {
    const [user] = useState(props.resource);
    const [t] = useTranslation();
    const userSmsSubscriptions = user?.subscriptions?.filter(sub => sub.strategies.includes('sms'));
    let userSmsTypes = '';
    if (!isEmpty(userSmsSubscriptions) && user.workspace_role.workspace_role_name !== 'User') {
        userSmsSubscriptions.forEach(sub => {
            sub.types.forEach(type => {
                if (!userSmsTypes.includes(type)) {
                    userSmsTypes = (
                        isEmpty(userSmsTypes) ? userSmsTypes : `${userSmsTypes}, `
                    ) + t(`subscriptions:${type.replace(':', '_')}`);
                }
            });
        });
    }
    const userEmailSubscriptions = user?.subscriptions?.filter(sub => sub.strategies.includes('email'));
    let userEmailTypes = '';
    if (!isEmpty(userEmailSubscriptions) && isEmail(user)) {
        userEmailSubscriptions.forEach(sub => {
            sub.types.forEach(type => {
                if (!userEmailTypes.includes(type)) {
                    userEmailTypes = (
                        isEmpty(userEmailTypes) ? userEmailTypes : `${userEmailTypes}, `
                    ) + t(`subscriptions:${type.replace(':', '_')}`);
                }
            });
        });
    }

    return useMemo(() => (

        <div className={'user-contact'}>
            {!isEmpty(user.email) && (
                !isEmpty(userEmailTypes)
                    ? (
                        <Tooltip
                            title={`${t('subscriptions:Email_subscriptions_activation')} : ${userEmailTypes}`}
                            placement={'top'}
                        >
                            <div className={'user-with-subscription'}>
                                <Icon type="bellOn" />
                                <span>{ user.email }</span>
                            </div>
                        </Tooltip>
                    )
                    : (
                        <Tooltip
                            title={t('subscriptions:Email_subscriptions_deactivation')}
                            placement={'top'}
                        >
                            <div className={'user-without-subscription'}>
                                <Icon type="bellSlash" />
                                <span>{ user.email }</span>
                            </div>
                        </Tooltip>
                    )
            )}
            {!isEmpty(user.telephone) && (
                !isEmpty(userSmsTypes)
                    ? (
                        <Tooltip
                            title={`${t('subscriptions:Sms_subscriptions_activation')} : ${userSmsTypes}`}
                            placement={'top'}
                        >
                            <div className={'user-with-subscription'}>
                                <Icon type="bellOn" />
                                <span>{ replace(user.telephone, '+33', '0') }</span>
                            </div>
                        </Tooltip>
                    )
                    : (
                        <Tooltip
                            title={t('subscriptions:Sms_subscriptions_deactivation')}
                            placement={'top'}
                        >
                            <div className={'user-without-subscription'}>
                                <Icon type="bellSlash" style={{color: 'red'}} />
                                <span>{ replace(user.telephone, '+33', '0') }</span>
                            </div>
                        </Tooltip>
                    )
            )}
        </div>
    ), [user?.email, user?.telephone]);
};

export default UserContact;

/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import config from './config.json';

const resources = { fr: {}, en: {} };
config.localeFiles.forEach(local => {
    try {
        resources.fr[local] = require(`./locales/fr/${local}.json`); // eslint-disable-line
        resources.en[local] = require(`./locales/en/${local}.json`); // eslint-disable-line
    } catch (e) {
        throw new Error(`i18n Error, the module ${local} is not found or can not be imported !: ${e}`);
    }
});

PLUGINS.forEach(plugin => {
    // OVERRIDE Translation
    config.localeFiles.forEach(local => {
        try {
            // FR
            const translationFR = require(`./plugins/${plugin}/app/locales/fr/${local}.json`);
            resources.fr[local] = Object.assign(resources.fr[local], translationFR);
        } catch (e) {
            // stub
        }
        // EN
        try {
            const translationEN = require(`./plugins/${plugin}/app/locales/en/${local}.json`);
            resources.en[local] = Object.assign(resources.en[local], translationEN);
        } catch (e) {
            // stub
        }
    });
    // CUSTOM Translation
    const newTranslations = require(`./plugins/${plugin}/app/module.json`);
    if (newTranslations.newLocales) {
        newTranslations.newLocales.forEach(translation => {
            // FR
            try {
                const newTranslationFR = require(`./plugins/${plugin}/app/locales/fr/${translation}.json`);
                resources.fr[translation] = Object.assign(
                    resources.fr[translation] ? resources.fr[translation] : {},
                    newTranslationFR
                );
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
            }

            // EN
            try {
                const newTranslationEN = require(`./plugins/${plugin}/app/locales/en/${translation}.json`);
                resources.en[translation] = Object.assign(
                    resources.en[translation] ? resources.en[translation] : {},
                    newTranslationEN
                );
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        });
    }
});

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        ns: config.localeFiles,
        defaultNS: 'common',
        resources,
        debug: false,
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
            format(value, format) {
                if (format === 'uppercase') {
                    return value.toUpperCase();
                }
                return value;
            }
        }
    });

export default i18n;

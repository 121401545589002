import update from 'immutability-helper';

const initialState = () => ({
    list: []
});

const nfcReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'GET_NFC_LIST_FULFILLED':
            return update(state, { list: { $set: action.payload } });
        default:
            return state;
    }
};

export default nfcReducer;

// Libs
import React from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import Fab from '@material-ui/core/Fab';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { useComponentsPool } from '../../../ComponentsPool';
import Icon from '../../layout/components/Icon';
import { fileTypeAllowedForDocViewer } from '../../layout/helper';

const FileViewer = props => {
    const { Component } = useComponentsPool();
    const document = props.document;

    const FileHeader = () => (
        <div className={'file-viewer-header'}>
            <p>{document?.title}</p>
            <Fab
                className={'topbar-button-close-modal'}
                onClick={() => props.closeDialog()}
            >
                <Icon type={'close'} />
            </Fab>
        </div>
    );

    const CustomLoader = () => <Component componentName={'LoaderBarTop'} isLoading />;
    const dialogContent = () => {
        if (fileTypeAllowedForDocViewer.includes(document.type.toUpperCase())) {
            const header = props.hasDocumentHeader ? { overrideComponent: FileHeader } : { disableHeader: true };
            return (
                <DocViewer
                    config={{
                        header,
                        loadingRenderer: { overrideComponent: CustomLoader }
                    }}
                    className={classNames([{ 'png-file-wrapper': document.type === 'PNG' }])}
                    pluginRenderers={DocViewerRenderers}
                    documents={[{ uri: document.url }]}
                />
            );
        }
        if (document.type === 'GIF') {
            return (
                <>
                    {props.hasDocumentHeader && FileHeader()}
                    <div className={'custom-viewer-container'}>
                        <img src={document.url} alt={'file-displayed'} />
                    </div>
                </>
            );
        }
        if (document.type === 'MP4') {
            return (
                <>
                    {props.hasDocumentHeader && FileHeader()}
                    <div className={'custom-viewer-container'}>
                        {/* #t=0.001 is required for the video thumbnail to appear on IOS devices */}
                        <video controls src={`${document.url}#t=0.001`} />
                    </div>
                </>
            );
        }
    };

    return (
        <div className={'file-viewer-content'}>
            {document
                ? dialogContent()
                : isMobile && (
                    <>
                        {FileHeader()}
                        <Component componentName={'MobileErrorPage'} />
                    </>
                )
            }
        </div>
    );
};
export default FileViewer;
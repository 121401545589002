// Libs
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Redirect } from 'react-router-dom';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import { checkResetPasswordToken, resetPasswordAction } from '../actions';
import createLoadingSelector from '../../layout/actions';

const loadingSelector = createLoadingSelector(['RESET_PASSWORD']);

export default props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const resetPasswordTokenIsValid = useSelector(state => state.authentication.resetPassword.tokenCheck.isValid);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState(null);
    const [isInit, setIsInit] = useState(true);
    const [areWrongFields, setAreWrongFields] = useState('');
    const [disableReset, setDisableReset] = useState(false);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));

    useEffect(() => {
        setAreWrongFields(
            isEmpty(newPassword)
            || isEmpty(confirmPassword)
            || newPassword === 'error_field'
            || confirmPassword === 'error_field'
            || newPassword !== confirmPassword);
    }, [newPassword, confirmPassword]);

    useEffect(() => {
        const getTokenCheck = async () => {
            await dispatch(checkResetPasswordToken(props.location.search.split('=')[1]));
        };
        if (isInit) {
            getTokenCheck()
                .then(() => setIsInit(false));
        }
    }, [isInit]);

    useEffect(() => {
        setToken(resetPasswordTokenIsValid);
    }, [resetPasswordTokenIsValid]);

    const onResetPassword = () => {
        dispatch(resetPasswordAction(newPassword, props.location.search.split('=')[1]));
        setDisableReset(true); // Prevent spam on the reset button
    };

    // If the token is not valid anymore we redirect to /forgetpassword
    if (!props.isLoading && token === false) {
        return (
            <Redirect
                to={{
                    pathname: '/forgetpassword',
                    state: { loginRedirectButtonText: t('common:log_in_second'), formExplanationText: t('common:expired_reset_password_link') }
                }}
            />
        );
    }
    if (!props.isLoading && token === true) {
        return (
            <div className={'home-form'}>
                <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
                <Component componentName={'PageHeader'} subText={t('common:reset_your_password')} />
                <form className="form-wrapper">
                    <Component
                        componentName={'Input'}
                        autoFocus
                        required
                        type={'password'}
                        rules={['isPassword']}
                        startAdornment={<Component componentName={'Icon'} type={'password'} />}
                        label={'common:new_password'}
                        onChange={setNewPassword}
                    />
                    <Component
                        componentName={'Input'}
                        required
                        type={'password'}
                        rules={['isPassword']}
                        startAdornment={<Component componentName={'Icon'} type={'password'} />}
                        label={'common:confirm_new_password'}
                        onChange={setConfirmPassword}
                    />
                    <div className="submit-button-wrapper">
                        <Component
                            componentName={'Button'}
                            onClick={onResetPassword}
                            text={t('common:reset_password')}
                            disabled={disableReset || areWrongFields}
                        />
                    </div>
                </form>
            </div>
        );
    }
    return <Component componentName={'LoaderBarTop'} isLoading={props.isLoading} />;
};

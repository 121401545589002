import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { fr } from 'react-date-range/dist/locale';
import { useTranslation } from 'react-i18next';
import { useComponentsPool } from '../../../../../ComponentsPool';
import './DayPicker.scss';

const DayPicker = ({ date, onChange, customString, maxDate = undefined, minDate = undefined }) => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const [showPicker, setShowPicker] = useState(false);
    const calendarRef = useRef(null);

    // Close the calendar component on outside clicks
    useEffect(() => {
        const outSideClickHandler = (event) => {
            if (showPicker && calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowPicker(false);
            }
        };
        document.body.addEventListener('click', outSideClickHandler);

        return () => {
            document.body.removeEventListener('click', outSideClickHandler);
        };
    }, [showPicker]);

    const onDateChange = (e) => {
        setShowPicker(false);
        onChange(e);
    };

    const showDatePicker = () => {
        // If it is not an invalid date
        if (isFinite(date)) {
            setShowPicker(true);
        }
    };

    const displayDate = moment(date).format('DD/MM/YYYY');

    return (
        <div className="day-label-picker">
            {customString && <span className={'date-first-string'}>{customString}</span>}
            <span>&nbsp;</span>
            <span className={'chosen-date'}>{displayDate}</span>
            {/* if the calendar is already open, when we click on the icon we close it */}
            <IconButton onClick={() => (showPicker ? setShowPicker(false) : showDatePicker())}>
                <Component componentName="Icon" type="event" />
            </IconButton>
            {showPicker && (
                // Why ref is added to DIV not to <Calendar/> ? because we need to verify underlying dom element not instance of the component.
                <div ref={calendarRef} id="calenderWrapper" className="day-picker">
                    <Calendar date={date} minDate={minDate} maxDate={maxDate} locale={fr} onChange={onDateChange} />
                </div>
            )}
        </div>
    );
};

export default DayPicker;

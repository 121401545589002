// Libs
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { some } from 'lodash';
import { InfiniteLoader } from 'react-virtualized';
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {BATCH_API_CALL_STATUS} from "../../constants";

// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import createLoadingSelector from '../../../layout/actions';
import { createInstanceEvent, editInstanceEvent, getRoundInstance, updateRoundInstanceEventsList } from '../../actions';
import { useModal } from '../../../modal/ModalContext';
import {
    failedAllEventsUpdateNotif,
    partialSuccessfulEventsUpdateNotif,
    successfulAllEventsUpdateNotif
} from '../../../notification/actions';
import CircularProgress from "@material-ui/core/CircularProgress";

const loadingSelector = createLoadingSelector(['GET_INSTANCE_EVENTS_LIST', 'GET_LAST_RECEIVED_DEVICE_DATA']);

const RoundHistory = ({ backCallBack, currentInstance, showBack = true, hasLoader = false, editedPassages, setEditedPassages, isInEditionMode, setIsInEditionMode }) => {
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const modal = useModal();
    const dispatch = useDispatch();
    const instanceEvents = useSelector(state => state.rounds.roundInstanceShownInDetailsPopup.eventsList);
    const instanceId = useSelector(state => state.rounds.roundInstanceShownInDetailsPopup.id);
    const roundId = useSelector(state => state.rounds.roundInstanceShownInDetailsPopup.roundId);
    const roundShownInDetailsPopupInstances = useSelector(state => state.rounds.roundShownInDetailsPopup.instances);
    const [eventsList, setEventsList] = useState([]);
    const [isSaveInProgress, setIsSaveInProgress] = useState(false)
    let instanceValidatedPassages = '';

    if (roundShownInDetailsPopupInstances?.length > 0) {
        if (roundShownInDetailsPopupInstances[0].validated_steps_count === null
            || roundShownInDetailsPopupInstances[0].expected_steps_count === null) {
            instanceValidatedPassages = `${t('rounds:validated-steps-with-assignation')} ${eventsList.filter(event => event.event_detail.status === 'OK').length}`;
        } else if (eventsList.length > 0) {
            instanceValidatedPassages = `${t('rounds:validated-steps-with-assignation')}
            ${roundShownInDetailsPopupInstances[0].validated_steps_count} /
            ${roundShownInDetailsPopupInstances[0].expected_steps_count}`;
        }
    }

    const onSaveButtonClick = () => {
        setIsSaveInProgress(true);
        const passagesPromises = editedPassages.map(passage => {
            const data = { event_timestamp: passage.event_timestamp };
            if (passage.modificationType === 'create') return createInstanceEvent(passage.event_validation_condition_id, data);
            if (passage.modificationType === 'update') return editInstanceEvent(passage.event_validation_condition_id, passage, data);
        });

        Promise.allSettled(passagesPromises.map(promise => promise.payload))
            .then(results => {
                const successfulApiCalls = results
                    .filter(result => result.status === 'fulfilled')
                    .map(result => result?.value?.data);

                const apiCallsStatus =
                    results.length === successfulApiCalls.length
                        ? BATCH_API_CALL_STATUS.ALL_FULFILLED
                        : successfulApiCalls.length === 0
                        ? BATCH_API_CALL_STATUS.ALL_REJECTED
                        : BATCH_API_CALL_STATUS.PARTIAL_FULFILLED;

                dispatch(updateRoundInstanceEventsList(successfulApiCalls)); // list of updated events sent by api

                if (apiCallsStatus === BATCH_API_CALL_STATUS.ALL_FULFILLED) dispatch(successfulAllEventsUpdateNotif());
                if (apiCallsStatus === BATCH_API_CALL_STATUS.PARTIAL_FULFILLED) dispatch(partialSuccessfulEventsUpdateNotif());
                if (apiCallsStatus === BATCH_API_CALL_STATUS.ALL_REJECTED) dispatch(failedAllEventsUpdateNotif());

                setEditedPassages([]);
                setIsInEditionMode(false);
                backCallBack() // close the popup after saving the changes
                // Refresh the current instance (others cannot be refreshed)
                dispatch(getRoundInstance(instanceId, roundId));
            })
            .catch(error => {
                // TODO: handle promise.allSettled error => for ex : something went wrong during the events update
                console.error(error);
            })
            .finally(() => setIsSaveInProgress(false));
    };


    useEffect(() => {
        if (instanceEvents) {
            setEventsList(instanceEvents);
        }
    }, [instanceEvents]);

    const disabledSaveButton = editedPassages?.length === 0 || some(editedPassages, { hasError: true }) || isSaveInProgress;
    const saveButtonToolTip = disabledSaveButton
        ? isSaveInProgress
            ? t('common:save_in_progress')
            : t(`common:${some(editedPassages, { hasError: true }) ? 'invalid_dates_hours' : 'no_changes_made'}`)
        : '';

    return (
        <div className={'round-history-container'}>
            {showBack && (
                <div className={'header-container'}>
                    <div className={'back-button'} role={'button'} tabIndex={0} onClick={backCallBack}>
                        <Component componentName={'Icon'} type={'ArrowBackIcon'} />
                    </div>
                    <div className={'step-count'}>{instanceValidatedPassages}</div>
                </div>
            )}
            <Component
                sendingType={'InstanceComment'}
                componentName={'ValidationPassageComment'}
                loadingSelectorList={['']}
                rows={3}
                style={{ backgroundColor: '#f3f3f3', marginBottom: '20px', paddingTop: '10px' }}
                resourceId={instanceId}
                roundId={roundId}
                enabledEnter
                disabled={isLoading}
            />
            <div
                className={isInEditionMode ? 'round-history-content-edition' : 'round-history-content'}
                style={{
                    display: 'flex', overflowY: 'auto', overflowX: 'hidden', height: 'inherit', position: 'relative'
                }}
            >
                {
                    isLoading && hasLoader
                        ? <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
                        : (
                            <div style={{ flex: '1 1 auto', width: '100%' }}>
                                <InfiniteLoader
                                    isRowLoaded={() => true}
                                    loadMoreRows={() => {}}
                                    rowCount={eventsList.length}
                                    minimumBatchSize={0}
                                    threshold={0}
                                    overscanRowCount={1}
                                >
                                    {() => eventsList.map((event, index) => (
                                        <Component
                                            key={`round-instance-log-${index}`}
                                            componentName={'RoundInstanceEventsLog'}
                                            eventsList={eventsList}
                                            event={event}
                                            eventIndex={index}
                                            currentInstance={currentInstance}
                                            editedPassages={editedPassages}
                                            setEditedPassages={value => setEditedPassages(value)}
                                            isInEditionMode={isInEditionMode}
                                        />
                                    ))}
                                </InfiniteLoader>
                            </div>
                        )}
            </div>
            {isInEditionMode && (
                <div className={'round-history-footer'}>
                    <Button
                        className={'custom-cancel-button'}
                        onClick={() => {
                            setEditedPassages([]);
                            setIsInEditionMode(false);
                        }}
                    >
                        {t('common:cancel')}
                    </Button>
                    <Tooltip
                        placement={'top'}
                        arrow={false}
                        title={saveButtonToolTip}
                    >
                        <span>
                            <Button
                                className={'save-primary-button'}
                                disabled={disabledSaveButton}
                                onClick={() => onSaveButtonClick() }
                            >
                                {isSaveInProgress ?
                                    (<>
                                        <CircularProgress size={20} />
                                        <p className="instance-save-button-text">{t('common:save')}</p>
                                    </>)
                                    : t('common:save')
                                }
                            </Button>
                        </span>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};

export default RoundHistory;

import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
// Components
import { isEmpty } from 'lodash';
// Context
import classNames from 'classnames';
// Helper
import { getStatusFromInstanceAndEventStatus, isInAreaPassage } from '../../helper';
import { getDeviceFromId } from '../../../devices/helper';
import Icon from '../../../layout/components/Icon';
import ClipboardCheck from "../../../layout/assets/clipboardCheck.svg";
import PictureAndTexts from "../../../layout/components/PictureAndTexts";


const MobileInstancePassagesList = props => {
    const [t] = useTranslation();
    const currentInstance = props.currentInstance;
    const passages = props.passages;
    const devices = useSelector(state => state.devices.list);

    const getStatusText = (status, event_timestamp) => {
        let text;
        if (status === 'OK') {
            const eventDate = moment(event_timestamp).format('DD/MM/YY');
            const eventTime = moment(event_timestamp).format('HH[h]mm');
            text = `${eventDate} ${eventTime}`;
        } else {
            text = t('rounds:step_not_validated');
        }
        return (
            <span>{text}</span>
        );
    };

    const getPassageCardFooterSentence = (passageStatus, eventDetail) => {
        let sentence;
        let sentenceCount;
        let passageAgent;
        // We always display the jobs it there is jobs on the passage
        const passageJobs = eventDetail.job_names?.length > 0 ? eventDetail.job_names?.join(', ') : null;
        const device = getDeviceFromId(devices, eventDetail.device_id);

        if (passageStatus === 'IN_PROGRESS' || passageStatus === 'NOT_STARTED') { // Sentence can be : 'A valider : Presta n°1 (0/2)'
            sentence = t('rounds:to_be_validated_with');
            if (eventDetail.validation_count > 1) {
                sentenceCount = `(${eventDetail.validation_count - eventDetail.remaining_validation_count}/${eventDetail.validation_count})`;
            }
        } else if (passageStatus === 'INCOMPLETE') { // Sentence can be : 'Validé : Presta n°1 (0/1)' or 'Validé en zone (0/1)'
            sentence = isInAreaPassage(device) ? t('passages:passage_status_OK_IN_AREA') : t('passages:passage_status_OK');
            sentenceCount = `(${eventDetail.validation_count - eventDetail.remaining_validation_count}/${eventDetail.validation_count})`;
        } else { // COMPLETE status
            // Sentence can be : 'Validé : Presta n°1, Presta n°2' or 'Validé en zone'
            // We always display 'Validé' or 'Validé en zone' unless it's on an instance without assignees where the passage was validated by an agent but without a job
            if (!(currentInstance.assignees_ids?.length === 0 && eventDetail.agent_name && !eventDetail.job_names)) {
                sentence = isInAreaPassage(device) ? t('passages:passage_status_OK_IN_AREA') : t('passages:passage_status_OK');
            }
            // We only display the agent name if it's a round without assignees
            if (currentInstance.assignees_ids?.length === 0 && eventDetail.agent_name) {
                passageAgent = eventDetail.agent_name;
            }
        }

        return (
            <div className={'passage-footer-text-wrapper'}>
                {(sentence || passageJobs) && (
                    <div className={'passage-footer-text'}>
                        {sentence && (
                            <span className={`${passageStatus}-passage-footer-text-beginning passage-footer-text-beginning`}>
                                {sentence}
                            </span>
                        )}
                        {passageJobs && (
                            <>
                                <span className={`${passageStatus}-passage-footer-text-colon passage-footer-text-colon`}>{':'}</span>
                                <span className={'passage-footer-text-content'}>{passageJobs}</span>
                            </>
                        )}
                       {sentenceCount && (
                            <span className={`${passageStatus}-passage-footer-text-count passage-footer-text-count`}>
                                {sentenceCount}
                            </span>
                        )}
                    </div>
                )}
                {/* We only display agent identity on instance without assignees */}
                {passageAgent && (
                    <div className={'passage-footer-text'}>
                        <span className={`${passageStatus}-passage-footer-text-beginning passage-footer-text-beginning`}>{t('rounds:validated_by')}</span>
                        <span className={'passage-footer-text-colon'}>{':'}</span>
                        <span className={'passage-footer-text-content'}>{passageAgent}</span>
                    </div>
                )}
            </div>
        );
    };

    const getPassageComponent = eventDetail => {
        if (eventDetail) {
            const status = getStatusFromInstanceAndEventStatus(currentInstance.status, eventDetail.status);

            return (
                <div className={'passage-section'}>
                    {eventDetail.building_name && <div className={`passage-section-header ${status}-passage-section-header`}>{eventDetail.building_name}</div>}
                    <div className={'passage-section-center'}>
                        <div className={'passage-section-floor'}>{eventDetail.floor_name || ''}</div>
                        <div className={'passage-section-position'}>{eventDetail.position_name || ''}</div>
                    </div>
                    <div className={'passage-section-divider'} />
                    <div className={'passage-section-footer'}>
                        <Tooltip
                            className={'tooltip'}
                            placement={'top'}
                            title={eventDetail.device_name}
                        >
                            <div><Icon type={getDeviceFromId(devices, eventDetail.device_id)?.custom_field.type} /></div>
                        </Tooltip>
                        {getPassageCardFooterSentence(status, eventDetail)}
                    </div>
                </div>
            );
        }
        return <></>;
    };

    const getStatusIcon = (passageStatus, instanceStatus) => {
        let passageStatusAnomaly;
        if (instanceStatus === 'IN_PROGRESS') {
            passageStatusAnomaly = 'StopWatchIcon';
        } else if (instanceStatus === 'NOT_STARTED') {
            passageStatusAnomaly = 'StopWatchClockIcon';
        } else { passageStatusAnomaly = 'ErrorRounded'; }

        return (
            <div className={passageStatusAnomaly === 'StopWatchClockIcon' ? 'row-status-not-started-icon' : 'row-status-icon'}>
                <Icon type={passageStatus === 'OK' ? 'CheckCircleRounded' : passageStatusAnomaly} />
            </div>
        );
    };

    return (
        <div className={classNames(['instance-details-passages-wrapper', { 'instance-with-margin-right': props.withMarginRight }])}>
            <div style={{ flex: '1 1 auto' }}>
                {props.currentAssigneeCompletedHisPassages
                    ? (
                        <PictureAndTexts
                            picture={<ClipboardCheck />}
                            mainText={t('rounds:passages_all_made')}
                            subText={t('rounds:passages_all_made_subtitle')}
                        />
                    )
                    : passages.map((event, index) => (
                        <div className={'row-item-mobile'} key={`row-${index}`}>
                            <div className={'row-icon'}>
                                <span className={'row-details'}>
                                    {getStatusText(event.event_detail?.status, event.event_timestamp)}
                                </span>
                                <div className={'row-timeline'}>
                                    {getStatusIcon(event.event_detail?.status, currentInstance.status)}
                                    {passages.length !== index + 1 && <div className={'line'} />}
                                </div>
                            </div>
                            <div className={'row-content'}>
                                {getPassageComponent(event.event_detail)}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default MobileInstancePassagesList;

// Libs
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Context
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import format from 'date-fns/format';

// Helper
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';


class LocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date) {
        return format(date, 'd MMM yyyy', { locale: this.locale });
    }
}

const DeviceDateRangeFilter = props => {
    const [t] = useTranslation();
    const [dateStart, setDateStart] = useState(props.startDate || moment().subtract(6, 'months').format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState(props.endDate || moment().format('YYYY-MM-DD'));
    const [businessError, setBusinessError] = useState('');
    const isDateRangeValid = (start, end) => {
        if (moment(start).format('YYYY-MM-DD') <= moment(end).format('YYYY-MM-DD')) {
            if (moment(start).format('YYYY-MM-DD') >= moment(end).subtract(props.maxMonthSlot || 1, 'months').format('YYYY-MM-DD')) {
                setBusinessError('');
                if (props.onErrorChange) props.onErrorChange(false);
                return true;
            }
        }
        setBusinessError(t('groups:date_range_max_range_error', { maxMonthSlot: props.maxMonthSlot || 1 }));
        if (props.onErrorChange) props.onErrorChange(true);
        return false;
    };

    const handleDateStartChange = d => {
        setDateStart(moment(d).format('YYYY-MM-DD'));

        if (moment(d).isValid() && isDateRangeValid(d, dateEnd)) {
            props.onDateChange({ startDate: moment(d).format('YYYY-MM-DD'), endDate: dateEnd });
        } else {
            props.onDateChange({ startDate: null, endDate: dateEnd });
        }
    };

    const handleDateEndChange = d => {
        setDateEnd(moment(d).format('YYYY-MM-DD'));

        if (moment(d).isValid() && isDateRangeValid(dateStart, d)) {
            props.onDateChange({ startDate: dateStart, endDate: moment(d).format('YYYY-MM-DD') });
        } else {
            props.onDateChange({ startDate: dateStart, endDate: null });
        }
    };


    return (
        <>
            <MuiPickersUtilsProvider locale={frLocale} utils={LocalizedUtils}>
                <span>
                    <KeyboardDatePicker
                        variant="inline"
                        autoOk
                        value={dateStart}
                        disableFuture
                        placeholder="dd/MM/YYYY"
                        label={t('groups:date_range_since')}
                        onChange={d => {
                            handleDateStartChange(d);
                        }}
                        maxDateMessage={t('groups:date_range_max_date_message')}
                        maxDate={moment()}
                        invalidDateMessage={t('groups:date_range_invalid_date_message')}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                    />
                </span>
                <span>
                    <KeyboardDatePicker
                        className={'date-right'}
                        variant="inline"
                        autoOk
                        value={dateEnd}
                        disableFuture
                        placeholder="dd/MM/YYYY"
                        label={t('groups:date_range_to')}
                        onChange={d => {
                            handleDateEndChange(d);
                        }}
                        maxDateMessage={t('groups:date_range_max_date_message')}
                        maxDate={moment()}
                        invalidDateMessage={t('groups:date_range_invalid_date_message')}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                    />
                </span>
            </MuiPickersUtilsProvider>
            {!isEmpty(businessError) && <div className={'date-range-business-error'}>{businessError}</div>}
        </>
    );
};
export default DeviceDateRangeFilter;

// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import Badge from '@material-ui/core/Badge';
import Popover from '@material-ui/core/Popover';
import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router';
import moment from 'moment';
import { groupBy } from 'lodash';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Actions & Helpers
import { getUserFromId } from '../../users/helper';
import { updateNotification } from '../actions';
import {
    getAppLanguage,
    getInputFileUrlForThumbnail,
    getTimeSuffixFormat, iconForFileWithoutThumbnail
} from '../../layout/helper';
import { capitalize } from '../../core/helper';
import Icon from '../../layout/components/Icon';

const NotificationMenu = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const users = useSelector(state => state.users.list);
    const [anchorEl, setAnchorEl] = useState(null);
    const [id, setId] = useState(undefined);
    const notifications = useSelector(state => state.navbar.notificationsList);
    const [groupedNotifs, setGroupedNotifs] = useState([]);
    const [someNotifWerentSeen, setSomeNotifWerentSeen] = useState(false);

    // Get the right date suffix for the notifs depending on the app language
    moment.locale(getAppLanguage(), getTimeSuffixFormat());

    useEffect(() => {
        setSomeNotifWerentSeen(notifications.flat().find(notif => !notif.seen_at));
        notifications.forEach(groupedNotification => { // Sort the grouped notifications so the newest appears first
            if (groupedNotification.length > 0) {
                groupedNotification.sort((a, b) => (a.object?.created_at < b.object?.created_at ? 1 : -1));
            }
        });
        // Sort the notifications so the newest appears first
        setGroupedNotifs(notifications.sort((a, b) => (a[0].object?.created_at < b[0].object?.created_at ? 1 : -1)));
    }, [notifications]);

    useEffect(() => {
        setId(open ? 'notification-popover' : undefined);
    }, [open]);

    const getNotificationContent = groupedNotif => {
        const groupedNotifObjectType = groupedNotif[0].object?.custom_field.type;
        const nbSenders = Object.keys(groupBy(groupedNotif, 'sender_name')).length;
        const externalNotif = groupedNotifObjectType === 'SLACK_MESSAGE' || groupedNotifObjectType === 'TEAMS_MESSAGE' || groupedNotifObjectType === 'DOIT_MESSAGE';

        // Those variables changes depending on the number of senders or grouped notifications
        let avatar; let author; let verb; let content;

        // No matter the number of senders or grouped notifications, those variables don't change
        const subject = `${t('tickets:the_ticket')} ${groupedNotif[0].object?.related_ticket.key}`;
        const creation_date = groupedNotif[0].object?.created_at;

        if (nbSenders === 1) { // ONE SENDER
            author = groupedNotif[0].sender_name;
            verb = t('tickets:commented');
            if (externalNotif) {
                avatar = (
                    <div className={classNames(['icon', { 'smaller-size-icon': groupedNotifObjectType === 'SLACK_MESSAGE' }])}>
                        <Component componentName={'Icon'} type={groupedNotifObjectType} />
                    </div>
                );
            } else {
                avatar = (
                    <Component
                        componentName={'UserAvatar'}
                        user={getUserFromId(users, groupedNotif[0].sender?.id)}
                        picture={getUserFromId(users, groupedNotif[0].sender?.id)?.profile_picture_url}
                        size="37"
                    />
                );
            }
        } else { // MULTIPLE SENDERS
            author = t('common:x_persons', { nbPersons: nbSenders });
            verb = t('tickets:commented_plural');
            avatar = (
                <div className={'multiple-persons-avatar'}>
                    <Component componentName={'Icon'} type={'userGroupFilled'}/>
                    <p>{nbSenders}</p>
                </div>
            );
        }

        if (groupedNotif.length === 1) { // ONE NOTIF
            if (groupedNotif[0].object?.urls?.length > 0) {
                verb = externalNotif
                    ? t('messaging:commented_messaging', { messaging_type: capitalize(groupedNotifObjectType.split('_MESSAGE')[0]) })
                    : t('tickets:commented');
                let displayedFiles = groupedNotif[0].object.urls;
                const notifTextContent = groupedNotif[0].object.content;
                const notifWithTextAndDoc = displayedFiles.length > 0 && notifTextContent;
                let hiddenFiles = [];
                if (displayedFiles.length > 3) {
                    hiddenFiles = displayedFiles.slice(3);
                    displayedFiles = displayedFiles.slice(0, 3);
                }
                content = (
                    <>
                        {notifTextContent && <p className={classNames([{ 'notif-with-text-and-doc': notifWithTextAndDoc }])}>{notifTextContent}</p>}
                        <div className={'notif-files'}>
                            {displayedFiles.map(url => {
                                const fileType = url?.split('.')?.pop();
                                const fileThumbnailUrl = getInputFileUrlForThumbnail(url, fileType);
                                return (
                                    fileThumbnailUrl
                                        ? <img src={fileThumbnailUrl} alt={fileType} />
                                        : (
                                            <div className={'notif-file-without-preview'}>
                                                <Icon
                                                    componentName={'Icon'}
                                                    type={iconForFileWithoutThumbnail(fileType)}
                                                />
                                            </div>
                                        )
                                );
                            })}
                            {hiddenFiles.length > 0 && (
                                <div className={'hidden-notif-files'}>
                                    {getInputFileUrlForThumbnail(hiddenFiles[0], hiddenFiles[0].split('.').pop()) && (
                                        <img
                                            src={getInputFileUrlForThumbnail(hiddenFiles[0], hiddenFiles[0].split('.').pop())}
                                            alt={hiddenFiles[0].split('.').pop()}
                                        />
                                    )}
                                    <p>{`+${hiddenFiles.length}`}</p>
                                </div>
                            )}
                        </div>
                    </>
                );
            } else {
                content = <p>{groupedNotif[0].object?.content}</p>;
            }
        } else { // MULTIPLE NOTIFS
            content = t('tickets:new_comments', { nbComments: groupedNotif.length });
        }

        return (
            <>
                <div className={'notification-avatar-and-text'}>
                    {avatar}
                    <div className="form-text">
                        <div className="title">
                            <div className="author">{author}</div>
                            <div className="verb">{verb}</div>
                            <div className="subject">{subject}</div>
                        </div>
                        <div className="explanation">{content}</div>
                    </div>
                </div>
                <div className={'notification-date'}>{ moment(creation_date).fromNow(true) }</div>
            </>
        );
    };

    const handleClick = groupedNotif => {
        groupedNotif.forEach(notif => {
            if (!notif.seen_at) {
                dispatch(updateNotification(notif.id, { seen_at: moment().format() }));
            }
        });
        history.push(`/tickets?key=${groupedNotif[0].object?.related_ticket.key}`);
        setAnchorEl(null);
    };

    return (
        <>
            <Tooltip
                className={'tooltip_edit_alert'}
                classes={{ tooltip: 'table-tooltip' }}
                placement={'bottom'}
                title={t('common:notifications')}
            >
                <div
                    aria-describedby={id}
                    role={'button'}
                    tabIndex={0}
                    className={'notification-icon'}
                    onClick={e => setAnchorEl(e.currentTarget)}
                >
                    <Badge color={'primary'} badgeContent={0} showZero={someNotifWerentSeen} variant="dot">
                        <Component componentName={'Icon'} type={anchorEl ? 'bellTicketFilled' : 'bellTicket'} />
                    </Badge>
                </div>
            </Tooltip>
            <Popover
                id={id}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                open={Boolean(anchorEl)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <div className={'notifications-popover-wrapper'}>
                    <h4>{t('common:notifications')}</h4>
                    <div className={'notifications-popover-content'}>
                        {groupedNotifs.map(groupedNotif => (
                            <div
                                key={groupedNotif.id}
                                className={classNames([
                                    'notification-wrapper',
                                    { 'notification-is-not-seen': groupedNotif.length > 1 ? groupedNotif.find(notif => !notif.seen_at) : !groupedNotif[0].seen_at }
                                ])}
                                role={'button'}
                                tabIndex={0}
                                onClick={() => handleClick(groupedNotif)}
                            >
                                {getNotificationContent(groupedNotif)}
                            </div>
                        ))}
                        <div className={'notification-wrapper no-more-notification-wrapper'}>
                            <Component componentName={'Icon'} type={'teaCup'} />
                            <p>{t(`common:${groupedNotifs.length !== 0 ? 'no_other_notif_the_last_thirty_days' : 'no_notif_the_last_thirty_days'}`)}</p>
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default NotificationMenu;

// Libs
import React, { useEffect, useState } from 'react';
// Context
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// Components
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Icon from '../../layout/components/Icon';
import { useComponentsPool } from '../../../ComponentsPool';
// Helper
import {
    getDevicesInfosFromDevices,
    getDeviceListFromIds,
    getDocumentQRCodes
} from '../helper';
import {
    getGroupNameFromId,
    sortByBuildingName
} from '../../groups/helper';
import DocumentSearchBar from '../../navbar/components/DocumentSearchBar';
import { useSecondMainColorTooltip } from "../../layout/helper";

const DevicesTransferList = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const tooltipStyle = useSecondMainColorTooltip();
    const groupList = useSelector(state => state.groups.list);
    const deviceList = useSelector(state => state.devices.list);
    const [checked, setChecked] = useState([]);

    const getSelectedStepsList = devicesIds => (
        devicesIds.length > 0
            ? getDevicesInfosFromDevices(getDeviceListFromIds(deviceList, devicesIds), groupList).sort(sortByBuildingName)
            : []
    );

    const getAvailableStepsList = devicesIds => (devicesIds.length > 0
    // eslint-disable-next-line max-len
        ? getDevicesInfosFromDevices(getDocumentQRCodes(deviceList), groupList).filter(device => !devicesIds.includes(device.id)).sort(sortByBuildingName)
        // eslint-disable-next-line max-len
        : getDevicesInfosFromDevices(getDocumentQRCodes(deviceList), groupList).sort(sortByBuildingName));

    const [isInitialSelectedSteps, setIsInitialSelectedSteps] = useState(true);
    const [selectedSteps, setSelectedSteps] = useState(getSelectedStepsList(props.devices_ids));
    const [availableSteps, setAvailableSteps] = useState(getAvailableStepsList(props.devices_ids));
    const [filteredAvailableSteps, setFilteredAvailableSteps] = useState([]);

    useEffect(() => {
        setIsInitialSelectedSteps(true);
        setSelectedSteps(getSelectedStepsList(props.devices_ids));
        setAvailableSteps(getAvailableStepsList(props.devices_ids));
    }, [props.devices_ids]);

    function not(a, b) {
        return a.filter(value => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
        return a.filter(value => b.indexOf(value) !== -1);
    }

    const leftChecked = intersection(checked, availableSteps);
    const rightChecked = intersection(checked, selectedSteps);

    function union(a, b) {
        return [...a, ...not(b, a)];
    }

    const getListTitle = listName => {
        if (listName === 'availableSteps') {
            return availableSteps.length <= 1
                ? t('documents:document_available_qrcode', { count: availableSteps.length })
                : t('documents:document_available_qrcodes', { count: availableSteps.length });
        }
        return selectedSteps.length <= 1
            ? t('documents:document_selected_qrcode', { count: selectedSteps.length })
            : t('documents:document_selected_qrcodes', { count: selectedSteps.length });
    };

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const countCheckedSteps = steps => intersection(checked, steps).length;

    const handleToggleAll = steps => () => {
        if (countCheckedSteps(steps) === steps.length) {
            setChecked(not(checked, steps));
        } else {
            setChecked(union(checked, steps));
        }
    };

    const handleCheckedRight = () => {
        const newAvailableSteps = not(availableSteps, leftChecked);
        setAvailableSteps(newAvailableSteps.sort(sortByBuildingName));
        setSelectedSteps(selectedSteps.concat(leftChecked).sort(sortByBuildingName));
        setChecked(not(checked, leftChecked));
        setIsInitialSelectedSteps(false);
    };

    const handleCheckedLeft = () => {
        const newAvailableSteps = availableSteps.concat(rightChecked);
        setAvailableSteps(newAvailableSteps.sort(sortByBuildingName));
        setSelectedSteps(not(selectedSteps, rightChecked).sort(sortByBuildingName));
        setChecked(not(checked, rightChecked));
        setIsInitialSelectedSteps(false);
    };

    useEffect(() => {
        if (!isInitialSelectedSteps) {
            const selectedStepsIds = [];
            selectedSteps.forEach(step => selectedStepsIds.push(step.id));
            props.changeState({ devices_ids: selectedStepsIds });
        }
    }, [selectedSteps]);

    const customList = type => {
        let steps = selectedSteps;
        if (type === 'availableSteps') {
            steps = filteredAvailableSteps.length > 0
                ? availableSteps.filter(step => filteredAvailableSteps.find(object => object.id === step.id)) // Filter the results based on the searchbar
                : availableSteps;
        }
        const countCheckedItems = countCheckedSteps(steps);
        const title = getListTitle(type);
        const numberOfSteps = type === 'selectedSteps' ? steps.length + props.hiddenSelectedDevicesIds.length : steps.length;
        const listHasSection = type === 'availableSteps' || props.hiddenSelectedDevicesIds.length > 0;
        const displayedSection = () => {
            if (type === 'availableSteps') {
                return (
                    <DocumentSearchBar
                        objectsToFilter={availableSteps}
                        filterBy={['locations', 'devices']}
                        setResults={res => setFilteredAvailableSteps(res)}
                    />
                );
            }
            if (props.hiddenSelectedDevicesIds.length > 0) {
                return (
                    <div className={'info-section'}>
                        <span>💡</span>
                        {props.hiddenSelectedDevicesIds.length === 1
                            ? t('documents:a_qrcode_is_hidden')
                            : t('documents:some_qrcodes_are_hidden', { numberOfQrcodes: props.hiddenSelectedDevicesIds.length })
                        }
                    </div>
                );
            }
        };
        return (
            <>
                {
                    <div className={'steps-list-header-wrapper'}>
                        {type === 'selectedSteps' && (
                            <Tooltip
                                className={'tooltip'}
                                placement={'top'}
                                classes={tooltipStyle}
                                title={t('documents:document_will_appear_on_qrcodes')}
                            >
                                <div><Component componentName={'Icon'} type={'info'} /></div>
                            </Tooltip>
                        )}
                        <div className="steps-list-header">
                            <p>{`${title} (${numberOfSteps})`}</p>
                        </div>
                    </div>
                }
                {listHasSection && displayedSection()} {/*  Section OR searchbar*/}
                <div className={classNames(['step-lists', { 'step-lists-without-section': !listHasSection }])}>
                    <ListItem className="step-columns-name">
                        <div className="checkbox-all-steps">
                            <Checkbox
                                onClick={handleToggleAll(steps)}
                                checked={countCheckedItems === steps.length && steps.length !== 0}
                                indeterminate={countCheckedItems !== steps.length && countCheckedItems !== 0}
                                disabled={steps.length === 0}
                                inputProps={{ 'aria-label': 'all steps selected' }}
                            />
                        </div>
                        <ListItemText primary={t('devices:building_group')} />
                        <ListItemText
                            className="step-second-column"
                            primary={t('rounds:floor_and_position')}
                        />
                        <ListItemText
                            className="step-last-column"
                            primary={t('devices:qrcode_name')}
                        />
                    </ListItem>
                    <Divider />
                    <List className={'step-list-content'} dense component="div" role="list">
                        {type === 'selectedSteps' && numberOfSteps === 0 && (
                            <div className={'empty-devices-list'}>
                                <Icon type={'exclamationTriangleLight'} />
                                {t('notifications:choose_qrcode_for_document')}
                            </div>
                        )}
                        {steps.map(value => {
                            const labelId = `transfer-list-all-item-${value}-label`;
                            return (
                                <ListItem
                                    key={value.id}
                                    role="listitem"
                                    button
                                    onClick={handleToggle(value)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={checked.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={labelId}
                                        primary={getGroupNameFromId(groupList, value?.batiment_group_id)}
                                    />
                                    <ListItemText
                                        className="step-second-column"
                                        id={labelId}
                                        primary={getGroupNameFromId(groupList, value?.floor_group_id)}
                                        secondary={getGroupNameFromId(groupList, value?.position_group_id)}
                                    />
                                    <ListItemText
                                        className="step-last-column"
                                        id={labelId}
                                        primary={value.device_name}
                                    />
                                </ListItem>
                            );
                        })}
                        <ListItem />
                    </List>
                </div>
            </>
        );
    };

    return (
        <div className={'create-round-modal'}>
            <div className={'devices-transfer-list'}>
                <div className="left-device-list-wrapper">
                    {customList('availableSteps')}
                </div>
                <div className="transfer-buttons">
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected selectedSteps"
                    >
                        <Component componentName={'Icon'} type={'rightArrow'} />
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected availableSteps"
                    >
                        <Component componentName={'Icon'} type={'leftArrow'} />
                    </Button>
                </div>
                <div className="right-device-list-wrapper">
                    {customList('selectedSteps')}
                </div>
            </div>
        </div>
    );
};

export default DevicesTransferList;

// Polyfill
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './polyfill';
// import './modules/core/hooks/use-why-did-you-render';
// Libs
import React from 'react';
import { useDispatch } from 'react-redux';
import { hydrate, render } from 'react-dom';
// Component
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { Workbox } from 'workbox-window';

import Providers from './Providers';
import Core from './modules/core';

const rootElement = document.getElementById('root');

const WebApp = () => (
    <Providers>
        <Core />
    </Providers>
);

if (rootElement.hasChildNodes()) {
    hydrate(<WebApp />, rootElement);
} else {
    render(<WebApp />, rootElement);
}

// Initialize Firebase with web app's Firebase configuration
const app = initializeApp(JSON.parse(FIREBASE_CONFIG));

let serviceWorkerRegistration = null;
if ('serviceWorker' in navigator) {
    const wb = new Workbox('/service-worker.js');
    // updateViaCache ensures that the browser always checks for the latest version of the serviceworker
    wb.register({ updateViaCache: 'none' })
        .then(registration => {
            serviceWorkerRegistration = registration;

            // When the page loads, checks for a new version of the serviceworker
            serviceWorkerRegistration.update();
        });
}

isSupported().then(async value => {
    if (value) {
        console.log('Browser supports Firebase');

        if (FIREBASE_CONFIG !== "{}") {
            // Initialize Firebase Cloud Messaging and get a reference to the service
            const messaging = getMessaging(app);

            onMessage(messaging, payload => {
                // STUB : Should be correlated with the in-app notification system. Currently not the case
                console.log('Message received. ', payload);
            });
            console.log('Firebase was successfully initialized');

        } else {
            console.error('Unable to initialize Firebase: Firebase config is missing');
        }

    } else {
        console.error('Browser does not support Firebase : this browser cannot subscribe to push notifications');
    }
});


async function askPermission() {
    console.log('Requesting permission to push notifications ...');

    // Initialize Firebase Cloud Messaging and get a reference to the service
    const messaging = getMessaging(app);

    return Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
            console.log('The user accepted the push notification permission request.');
            console.log(serviceWorkerRegistration);
            // Get token with VAPID public key to authorize send requests to web push services
            // Public and private keys are configured on the Firebase project
            return getToken(messaging, { serviceWorkerRegistration, vapidKey: PUSH_PUBLIC_APPLICATION_SERVER_KEY }).then(fcm_token => {
                if (fcm_token) {
                    console.log('Successfully got FCM token');
                    console.log('Current token is', fcm_token);
                    // Send the token to the server and update the UI if necessary
                    return fcm_token;
                } else {
                    // Show permission request UI
                    console.log('No FCM registration token available.');
                    throw new Error('No FCM registration token available.');
                }
            }).catch(err => {
                console.log('An error occurred while retrieving FCM token.', err);
                throw err;

                // ...
            });
        } else if (permission === 'denied') {
            console.error('The user explicitly denied the push notification permission request.');
            throw new Error('The user explicitly denied the push notification permission request.');

        } else {
            console.error('Unable to get push notification permission.');
            throw new Error('Unable to get push notification permission.');

        }
    }).catch(err => {
        console.log('An error occurred while getting push notification permission.', err);
        throw new Error('An error occurred while getting push notification permission.');

    });
}

export { askPermission };

import update from 'immutability-helper';
import { cloneDeep, concat, findIndex, orderBy, remove } from 'lodash';
import moment from 'moment';
import { getRoundInstanceStatusByValidatedSteps } from "./helper";

const initialState = () => ({
    isLoading: false,
    list: [],
    currentInstancesStatus: '',
    dailyRoundsInstancesList: {
        IN_PROGRESS: [],
        NOT_STARTED: [],
        FINISHED: []
    },
    active_mode: true,
    roundShownInDetailsPopup: {},
    roundInstanceShownInDetailsPopup: {
        id: '',
        roundId: '',
        eventsList: [],
        currentInstance: '',
        currentInstanceDateTime: ''
    },
    roundsTableColumns: [{
        name: 'name',
        formTitle: true,
        title: 'rounds:round_name',
        component: 'RoundName',
        weight: 100
    }, {
        name: 'building',
        title: 'rounds:building',
        component: 'RoundBuildings',
        weight: 200
    }, {
        name: 'recurrence',
        title: 'rounds:recurrence',
        component: 'RoundRecurrenceSimpleString',
        weight: 300
    }, {
        name: 'round_followers',
        title: 'rounds:people_to_alert',
        component: 'RoundFollowersAvatar',
        weight: 400
    }],
    archivedRoundsTableColumns: [{
        name: 'name',
        formTitle: true,
        title: 'rounds:round_name',
        component: 'RoundName',
        weight: 100
    }, {
        name: 'building',
        title: 'rounds:building',
        component: 'RoundBuildings',
        weight: 200
    }, {
        name: 'recurrence',
        title: 'rounds:recurrence',
        component: 'RoundRecurrenceSimpleString',
        weight: 300
    }, {
        name: 'archiving_date',
        title: 'rounds:archiving_date',
        component: 'RoundArchivingDate',
        weight: 400
    }],
    editableFields: [{
        name: 'round_devices',
        title: '',
        renderValue: 'RoundSteps',
        editionComponent: 'RoundSteps',
        props: { value: 'round_devices', placeHolder: '', required: true },
        weight: 100
    }, {
        name: 'planification',
        title: '',
        renderValue: 'RoundPlanification',
        editionComponent: 'RoundPlanification',
        props: { value: 'rrule', placeHolder: '', multiple: true },
        weight: 150
    }, {
        name: 'followers',
        title: 'people_to_alert',
        subtitle: 'rounds:select_people_to_alert_explanation',
        renderValue: 'RoundUsersText',
        editionComponent: 'SelectRoundUsers',
        props: { value: 'round_followers', placeHolder: '', multiple: true },
        weight: 200
    }, {
        name: 'assignees',
        title: 'round_assignees',
        subtitle: 'rounds:select_people_to_assign_explanation',
        renderValue: 'RoundUsersText',
        editionComponent: 'SelectRoundUsers',
        props: { value: 'round_assignees', placeHolder: '', multiple: true },
        weight: 250
    }, {
        name: 'expectedjobs',
        title: 'round_expected_jobs',
        subtitle: 'rounds:select_expected_jobs_explanation',
        renderValue: 'RoundJobsText',
        editionComponent: 'SelectRoundJobs',
        props: { value: 'round_expected_jobs', placeHolder: '', multiple: true },
        weight: 300
    }],
    detailPopupParts: [
        { width: '60%', component: 'RoundDetailsPopupPartLeft' },
        { width: '40%', component: 'RoundDetailsPopupPartRight' }
    ]
});

let index;
let newList;

const roundsReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'CREATE_ROUND_CUSTOM_ERRORS_FULFILLED':
        case 'CREATE_ROUND_FULFILLED':
            if (action.payload) {
                return update(state, { list: { $push: [action.payload] } });
            }
            return state;
        case 'GET_ROUNDS_FULFILLED':
            if (action.payload) {
                return update(state, {
                    list: { $set: action.payload.data },
                    active_mode: { $set: action.payload.active }
                });
            }
            return state;
        case 'GET_DAILY_ROUNDS_INSTANCES_FULFILLED':
            if (action.payload) {
                const status = action.payload.status === 'COMPLETE' || action.payload.status === 'INCOMPLETE' ? 'FINISHED' : action.payload.status;
                let instancesList = action.payload.data.results;
                if (status === 'NOT_STARTED') {
                    const badInstanceIds = [];
                    instancesList.forEach(instance => {
                        if (moment().isAfter(moment(instance.started_at))) {
                            badInstanceIds.push(instance.id);
                        }
                    });
                    instancesList = instancesList.filter(instance => !badInstanceIds.includes(instance.id));
                }
                return update(state, {
                    currentInstancesListStatus: { $set: status },
                    dailyRoundsInstancesList: { [status]: { $set: instancesList } }
                });
            }
            return state;
        case 'EDIT_ROUND_CUSTOM_ERRORS_FULFILLED':
        case 'EDIT_ROUND_FULFILLED':
            if (action.payload) {
                index = findIndex(state.list, item => item.id === action.payload.id);
                if (state.roundShownInDetailsPopup.id === action.payload.id) {
                    return update(state, {
                        list: { [index]: { $set: action.payload } },
                        roundShownInDetailsPopup: { $set: { ...state.roundShownInDetailsPopup, ...action.payload } }
                    });
                }
                return update(state, { list: { [index]: { $set: action.payload } } });
            }
            return state;
        case 'UPDATE_INSTANCE_FULFILLED':
            if (action.payload) {
                index = findIndex(state.roundShownInDetailsPopup.instances, item => item.id === action.payload.id);
                return update(state, {
                    roundShownInDetailsPopup: {
                        instances: {
                            [index]: {
                                started_at: { $set: action.payload.started_at },
                                ended_at: { $set: action.payload.ended_at }
                            }
                        }
                    }
                });
            }
            return state;
        case 'SET_ROUND_DETAIL_FULFILLED':
            if (action.payload) {
                return update(state, { roundShownInDetailsPopup: { $set: action.payload } });
            }
            return state;
        case 'GET_ROUND_INSTANCE_FULFILLED':
            if (action.payload) {
                index = findIndex(state.roundShownInDetailsPopup.instances, item => item.id === action.payload.id);
                return update(state, {
                    roundShownInDetailsPopup: {
                        instances: {
                            [index]: { $set: { ...state.roundShownInDetailsPopup.instances[index], ...action.payload } }
                        }
                    }
                });
            }
            return state;
        case 'GET_ROUND_INSTANCES_FULFILLED':
            if (action.payload) {
                return update(state, {
                    roundShownInDetailsPopup: {
                        instances: { $set: action.payload.results }
                    }
                });
            }
            return state;
        case 'GET_ROUND_DATA_EXPORT_FULFILLED':
            if (action.payload) {
                return update(state, {
                    roundShownInDetailsPopup: {
                        dataExport: { $set: action.payload }
                    }
                });
            }
            return state;
        case 'ARCHIVE_ROUND_FULFILLED':
            index = findIndex(
                state.list,
                round => round.id === action.payload.id
            );
            return update(state, {
                list: { $splice: [[index, 1]] }
            });
        case 'UPDATE_ACTIVE_ROUNDS_WITH_ARCHIVED':
            const archivedIds = action.payload.map(round => round.id);
            return update(state, {
                list: { $set: state.list.filter(round => !archivedIds.includes(round.id)) }
            });
        case 'DELETE_ROUND_FULFILLED':
            if (action?.payload.id?.length > 0) {
                index = findIndex(
                    state.list,
                    round => round.id === action.payload.id[0]
                );
                return update(state, {
                    list: { $splice: [[index, 1]] }
                });
            }
            return state;
        case 'POST_ROUND_COMMENTS_FULFILLED':
            return update(state, {
                roundShownInDetailsPopup: {
                    comments: { $set: concat(action.payload.data, state.roundShownInDetailsPopup.comments) }
                }
            });
        case 'GET_INSTANCE_EVENTS_LIST_FULFILLED':
            return update(state, {
                roundInstanceShownInDetailsPopup: {
                    id: { $set: action.payload.id },
                    roundId: { $set: action.payload.roundId },
                    eventsList: { $set: action.payload.data },
                    currentInstance: { $set: action.payload.instance },
                    currentInstanceDateTime: {
                        $set:
                            action.payload.currentInstanceDateTime
                            || state.roundInstanceShownInDetailsPopup.currentInstanceDateTime
                    }
                }
            });
        case 'UPDATE_INSTANCE_EVENTS_LIST': {
            const updatedEventsList = [
                ...state.roundInstanceShownInDetailsPopup.eventsList
            ];
            const currentOffset = moment().utcOffset();
            // Put timestamp to timezone format so every timestamp of the list can be compared to each other
            const formattedDateString = action.payload.map(newEvent => ({
                ...newEvent,
                event_timestamp: moment(newEvent.event_timestamp).utc().utcOffset(currentOffset).format('YYYY-MM-DDTHH:mm:ssZ')
            }));
            formattedDateString.forEach(newEvent => {
                // get index by event_id
                index = updatedEventsList.findIndex(event => event.event_detail?.id === newEvent.event_detail?.id);
                // get index by validation-condition-id
                const indexByValidationConditionID = updatedEventsList.findIndex(
                    (event) =>
                        event.event_detail?.validation_condition_id === newEvent.event_detail?.validation_condition_id
                );

                if (index !== -1) { // if event exists by its event_id => it is an event update
                    updatedEventsList[index] = newEvent;
                } else {
                    // if event does not exist by its event_id
                    // we search by its validation-condition-id
                    // if found => it is an event creation
                    if (indexByValidationConditionID !== -1) {
                        updatedEventsList[indexByValidationConditionID] = newEvent;
                    }
                }
            });

            // reordered oldest event to newest
            const reorderedEventsList = orderBy(updatedEventsList, ['event_timestamp'], ['desc']);
            return update(state, {
                roundInstanceShownInDetailsPopup: {
                    eventsList: { $set: reorderedEventsList }
                }
            });
        }
        case 'UPDATE_INSTANCE_STATUS':
            const instanceIndex = state.roundShownInDetailsPopup.instances?.findIndex(instance => instance.id === action.payload.instanceId) ?? -1;
            if (instanceIndex === -1) { // if instance not found or undefined value
                return state;
            }
            return update(state, {
                roundShownInDetailsPopup: {
                    instances: {
                        [instanceIndex]: {
                            status: { $set: action.payload.status }
                        }
                    }
                }
            });

        case 'EMPTY_INSTANCE_EVENTS_LIST':
            return update(state, {
                roundInstanceShownInDetailsPopup: {
                    id: { $set: '' },
                    roundId: { $set: '' },
                    eventsList: { $set: [] },
                    currentInstance: { $set: [] },
                    currentInstanceDateTime: { $set: '' }
                }
            });
        case 'POST_INSTANCE_COMMENTS_FULFILLED':
            return update(state, {
                roundInstanceShownInDetailsPopup: {
                    eventsList: {
                        $unshift: [{
                            event_type: 'COMMENT',
                            event_timestamp: action.payload.created_at,
                            event_detail: {
                                author_name: action.payload.author_name,
                                comment: action.payload.text_content,
                                uploaded_content_url: action.payload.uploaded_content_url,
                                author_id: action.payload.author_id,
                                round_instance_comment_id: action.payload.id
                            }
                        }]
                    }
                }
            });
        case 'DELETE_INSTANCE_COMMENTS_FULFILLED':
            newList = cloneDeep(state.roundInstanceShownInDetailsPopup.eventsList);
            remove(newList, event => event.event_detail.round_instance_comment_id === action.payload);
            return update(state, {
                roundInstanceShownInDetailsPopup: {
                    eventsList: { $set: newList }
                }
            });
        case 'DELETE_DEVICE_COMMENTS_FULFILLED':
            newList = cloneDeep(state.roundInstanceShownInDetailsPopup.eventsList);
            remove(newList, event => event.event_detail.device_comment_id === action.payload);
            return update(state, {
                roundInstanceShownInDetailsPopup: {
                    eventsList: { $set: newList }
                }
            });
        case 'DELETE_INSTANCE':
            return update(state, {
                roundInstanceShownInDetailsPopup: {
                    id: { $set: '' },
                    roundId: { $set: '' },
                    eventsList: { $set: [] },
                    currentInstance: { $set: '' },
                    currentInstanceDateTime: { $set: '' }
                }
            });
        case 'UPDATE_ROUNDS_LOADING_STATUS':
            return update(state, { isLoading: { $set: action.payload } });

        default:
            return state;
    }
};

export default roundsReducer;

// Libs
import validator from 'validator';
import passwordValidator from 'password-validator';
import { find, isString } from 'lodash';
// Store
import store from '../../store';

const isEmail = value => {
    if (value.length > 0 && !validator.isEmail(value.trim())) {
        return { message: 'email_check' };
    }
    return null;
};

const isAcceptableStringLength = (value, maxLength) => {
    const length = maxLength.includes('maxLength-') ? maxLength.substring(10) : 64;
    if (!validator.isLength(value.toString().trim(), { max: length })) {
        return { message: 'max_length_exceeded', count: length };
    }
    return null;
};

const isPassword = value => {
    const state = store.getState();
    const schema = new passwordValidator(); // eslint-disable-line
    if (state.workspace.settings.password_without_uppercase !== true) schema.has().uppercase();
    if (state.workspace.settings.password_without_lowercase !== true) schema.has().lowercase();
    if (state.workspace.settings.password_without_digits !== true) schema.has().digits();
    if (state.workspace.settings.password_without_symbols !== true) schema.has().symbols();
    schema.is().min(parseInt(state.workspace.settings.password_min_char, 10) || 8);

    if (value.length > 0 && !schema.validate(value, { list: false })) {
        return {
            message: 'wrong_password_format',
            count: parseInt(state.workspace.settings.password_min_char, 10) || 8,
            uppercase: state.workspace.settings.password_without_uppercase === true || false,
            lowercase: state.workspace.settings.password_without_lowercase === true || false,
            digits: state.workspace.settings.password_without_digits === true || false,
            symbols: state.workspace.settings.password_without_symbols === true || false
        };
    }
    return null;
};

const isAcceptableString = value => {
    if (value.length > 0 && validator.isDataURI(value)
      || validator.isMagnetURI(value)
      || validator.isURL(value)
      || validator.isJSON(value)
      || validator.isJWT(value)
      || validator.contains(value, '.')
      || validator.contains(value, '<')
      || validator.contains(value, '>')
      || validator.contains(value, ';')
      || validator.contains(value, ':')
      || validator.contains(value, ',')
      || validator.contains(value, '!')
      || validator.contains(value, '\\')
    ) {
        return { message: 'bad_format' };
    }
    return null;
};

const selectNotEmpty = value => {
    let formValue;
    if (value[0] !== undefined || value) {
        formValue = value[0] || value;
    }
    if (formValue === undefined || formValue === null || formValue.length === 0) {
        return { message: 'not_empty' };
    }
    return null;
};

export const notMin = (value, min) => {
    if (value < min) return { message: 'not_min' };
    return null;
};

export const notMax = (value, max) => {
    if (value > max) return { message: 'not_max' };
    return null;
};

export const notStep = (value, step) => {
    if (step === 1 && value % 1 !== 0) {
        return { message: 'not_step1'};
    }
    if ( step === 0.5 && value % 0.5 !== 0) {
        return { message: 'not_step0_5'};
    }
    return null;
};



const checkIfGroupAlreadyExists = (value, list) => {
    const matchGroups = list.filter(group => group.group_name.toLowerCase() === value.toLowerCase());
    if (matchGroups.length >= 1) {
        return { message: 'cannot_create_already_exists' };
    }
    return null;
};

const checkIfDashboardAlreadyExists = (value, list) => (find(list, dashboard => dashboard.name === value) ? { message: 'cannot_create_dashboard_already_exists' } : null);

const checkIfUserAlreadyExists = (value, list) => {
    const users = list.filter(user => (user?.username ? user.username.toLowerCase() === value.toLowerCase() : null));
    const invites = list.filter(invite => (invite?.invite_email ? invite.invite_email.toLowerCase() === value.toLowerCase() : null)); // eslint-disable-line
    if (value.length && invites.length >= 1) return { message: 'cannot_invite_invite_already_exists' };
    if (value.length && users.length >= 1) return { message: 'cannot_invite_already_exists' };
    return null;
};

const isUserId = (value, list) => {
    if (value.length > 0) {
        if (validator.isEmail(value)) { return { message: 'user_id_check' }; }
        return checkIfUserAlreadyExists(value, list);
    }
    return null;
};

const checkIfPhoneAlreadyExists = (value, list) => {
    const matchUsers = list.filter(user => user.invite_tel === value);
    if (value.length && matchUsers.length) {
        return { message: 'cannot_invite_already_exists' };
    }
    return null;
};

const isPhoneNumber = value => {
    const found = value.match(/^(?:(?:\+|00)[0-9]{1,3}[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/); // eslint-disable-line
    if (value.length && !found) {
        return { message: 'bad_phone_format' };
    }
    return null;
};

const isRequired = value => {
    if (!value || value.length === 0 || (isString(value) && !value.trim().length)) {
        return { message: 'required_field' };
    }
    return null;
};

export default (validFunc, value, list) => {
    if (validFunc.includes('maxLength-')) {
        return isAcceptableStringLength(value, validFunc);
    }
    switch (validFunc) {
        case 'isRequired':
            return isRequired(value);
        case 'isEmail':
            return isEmail(value);
        case 'isUserId':
            return isUserId(value, list);
        case 'isPhoneNumber':
            return isPhoneNumber(value);
        case 'isAcceptableString':
            return isAcceptableString(value);
        case 'isPassword':
            return isPassword(value);
        case 'selectNotEmpty':
            if (typeof value === 'string') return selectNotEmpty(value?.trim());
            return selectNotEmpty(value);
        case 'checkIfUserAlreadyExists':
            return checkIfUserAlreadyExists(value, list);
        case 'checkIfDashboardAlreadyExists':
            return checkIfDashboardAlreadyExists(value, list);
        case 'checkIfGroupAlreadyExists':
            return checkIfGroupAlreadyExists(value, list);
        case 'checkIfPhoneAlreadyExists':
            return checkIfPhoneAlreadyExists(value, list);
        default:
            return null;
    }
};

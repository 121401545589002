// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useComponentsPool } from '../../../ComponentsPool';

const NoData = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();

    return (
        <div className={'nodata-wrapper'}>
            <div className="nodata-container">
                {props.textOnly
                    ? <div className="nodata-text">{t(`common:no_data_${props.view}`)}</div>
                    : (
                        <>
                            <div className="nodata-image">
                                <Component componentName={'Icon'} type={'no_data'} />
                            </div>
                            <div className="nodata-text">
                                {t(`common:no_data_${props.view}`)}
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default NoData;

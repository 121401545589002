// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';

// Context
import { useComponentsPool } from '../../../ComponentsPool';
import Icon from '../../layout/components/Icon';
import { loadIntercomScript } from '../../core/helper';

const HelpMenu = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const [helpClicked, setHelpClicked] = useState(false);
    const currentUser = useSelector(state => state.users.currentUser);
    const currentUserHMAC = useSelector(state => state.users.currentUserHMAC);
    const workspaceSettings = useSelector(state => state.workspace.settings);

    useEffect(() => {
        loadIntercomScript(INTERCOM_APP_ID)
            .then(() => {
                if (typeof window.Intercom === 'function') {
                    // Enregistrer les callbacks seulement si Intercom est correctement chargé
                    window.Intercom('onHide', () => {
                        setHelpClicked(false);
                    });

                    window.Intercom('onShow', () => {
                        setHelpClicked(true);
                    });
                } else {
                    console.warn('Intercom is not initialized properly.');
                }
            });

        // Nettoyage : arrêt de Intercom et suppression des callbacks lorsque le composant est démonté
        return () => {
            if (typeof window.Intercom === 'function') {
                window.Intercom('shutdown');
            }
        };
    }, []);

    useEffect(() => {
        // Initialisation d'Intercom ou update de la config
        if (!props.isDemo) {
            if (currentUserHMAC && currentUser?.id) {
                const intercomAction = window.Intercom.booted ? 'update' : 'boot';
                window.Intercom(intercomAction, {
                    api_base: 'https://api-iam.eu.intercom.io',
                    app_id: INTERCOM_APP_ID,
                    user_id: currentUser?.id,
                    email: currentUser?.email,
                    name : `${currentUser?.first_name} ${currentUser?.last_name}`,
                    avatar: {
                        'type': 'avatar',
                        'image_url' : currentUser?.profile_picture_url
                    },
                    user_hash: currentUserHMAC,
                    hide_default_launcher: true,
                    workspace: workspaceSettings.name,
                    role: currentUser?.workspace_role?.workspace_role_name
                });
                window.Intercom.booted = true;
            }
        }
    }, [currentUser, currentUserHMAC]);

    // Pour l'option Chatbot IA dans les QR Codes
    if (props.isDemo) {
        return (
            <div className={'qrcode-mode-button'}>
                <div className={classNames(['qrcode-mode-button-left-part qrcode-mode-button-larger-icon'])}>
                    <Icon type={'chatbot'} />
                </div>
                <div
                    role={'button'}
                    tabIndex={-1}
                    className={classNames(['qrcode-mode-button-right-part help-icon', { 'help-icon-disabled': !currentUserHMAC }])}
                    onClick={() => {
                        setHelpClicked(!helpClicked);
                    }}
                >
                    <p>{'Poser une question à l\'IA'}</p>
                    <div className={'qrcode-arrow-back'}>
                        <Icon type="ArrowForwardIcon" />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <>
            <Tooltip
                className={'tooltip_edit_alert'}
                classes={{ tooltip: 'table-tooltip' }}
                placement={'bottom'}
                title={currentUserHMAC ? t('common:help') : t('common:help_not_working')}
            >
                <div
                    className={classNames(['help-icon', { 'help-icon-disabled': !currentUserHMAC }])}
                    onClick={e => {
                        if (currentUserHMAC) {
                            setHelpClicked(!helpClicked);
                        }
                    }}
                >
                    <Component componentName={'Icon'} type={helpClicked ? 'helpInterrogationMarkFilled' : 'helpInterrogationMark'} />

                </div>
            </Tooltip>
        </>
    );
};

export default HelpMenu;

// Libs
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

const RoundDeviceMaxValidationCount = props => {
    const [t] = useTranslation();
    return (
        <Tooltip
            placement={'top'}
            arrow={false}
            title={t('rounds:step-counts-explanations',
                { 'step-count': props.column.round_devices_max_validation_count }
            )}
        >
            <Chip
                key={`step-count-of${props.resource.device_id}`}
                label={props.column.round_devices_max_validation_count}
                className={'group-name'}
            />
        </Tooltip>
    );
};

export default RoundDeviceMaxValidationCount;

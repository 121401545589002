// Libs
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Components
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// SCSS
import '../styles/_button.scss';

const ButtonIotRocket = props => {
    const { Component } = useComponentsPool();

    return (
        <Button
            className={classNames('iot-rocket-button', props.className)}
            href={props.href || ''}
            disabled={props.disabled}
            onClick={props.onClick}
            style={{ color: props.color, backgroundColor: props.backgroundColor }}
            type={props.type}
            variant={props.variant ? props.variant : 'text'}
            loading={props.loading}
            onKeyPress={event => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    return false;
                }
                return true;
            }}
        >
            {props.startAdornment && <Component componentName={'Icon'} type={props.icon} />}
            {!props.loading && props.text}
            {props.endAdornment && <Component componentName={'Icon'} type={props.icon} />}
            {props.loading && <CircularProgress size={22} className={'popup_button_loader'} />}
        </Button>
    );
};

ButtonIotRocket.defaultProps = {
    className: '',
    color: '',
    disabled: false,
    endAdornment: false,
    href: '',
    icon: '',
    startAdornment: false,
    type: 'button'
};

ButtonIotRocket.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    endAdornment: PropTypes.bool,
    href: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    startAdornment: PropTypes.bool,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.any]).isRequired,
    type: PropTypes.string
};

export default ButtonIotRocket;

import { Queue } from 'workbox-background-sync';


export function postMessageToAllClients(messages) {
    self.clients.matchAll().then(clients_ => {
        clients_.forEach(client => {
            client.postMessage(messages);
        });
    });
}


export const setupStoreDeviceMessagesOnNetworkError = () => {
    const deviceMessagesQueue = new Queue('deviceMessages');

    self.addEventListener('message', event => {
        if (event.data && event.data.action === 'FLUSH_QUEUE' && event.data.queueName === 'deviceMessages') {

            // Tentative de relecture des requêtes en attente
            deviceMessagesQueue.replayRequests().then(() => {
                deviceMessagesQueue.getAll()
                    .then(queueEntries => {
                        postMessageToAllClients({ type: 'BROADCAST_PENDING_DEVICE_MESSAGES_COUNT', value: queueEntries.length });
                        event.ports[0].postMessage(queueEntries.length);
                    });
            });
        }
        if (event.data && event.data.action === 'GET_PENDING_DEVICE_MESSAGES_COUNT') {
            deviceMessagesQueue.getAll()
                .then(queueEntries => {
                    event.ports[0].postMessage(queueEntries.length);
                });
        }
    });

    self.addEventListener('online', () => {
        // Force replay when browser detects it is back online
        deviceMessagesQueue.replayRequests().then(() => {
            deviceMessagesQueue.getAll()
                .then(queueEntries => {
                    postMessageToAllClients({ type: 'BROADCAST_PENDING_DEVICE_MESSAGES_COUNT', value: queueEntries.length });
                });
        });
    });

    // Catch outgoing POST requests to device messages and store them in case of network failures
    self.addEventListener('fetch', event => {
        const url = new URL(event.request.url);
        if (event.request.method === 'POST') {
            if (url.pathname.match(/devices\/[0-9a-f-]+\/messages/)) {
                event.respondWith(
                    fetch(event.request.clone())
                        .catch(async () => deviceMessagesQueue.pushRequest({ request: event.request })
                            .then(response => {
                                if (!navigator.onLine) {
                                    // Caching the request on Workbox Queue for later replay when the network returns
                                    deviceMessagesQueue.getAll()
                                        .then(queueEntries => {
                                            postMessageToAllClients({ type: 'BROADCAST_PENDING_DEVICE_MESSAGES_COUNT', value: queueEntries.length });
                                        });
                                    // Customization of the feedback sent to the app so that it can react
                                    return new Response(JSON.stringify({
                                        status: 'pending',
                                        message: 'PENDING_NETWORK'
                                    }), {
                                        status: 202,
                                        headers: { 'Content-Type': 'application/json' }
                                    });
                                }
                                return response;
                            }))
                );
            }
        }
    });
};

import axios from 'axios';
import jwtDecode from 'jwt-decode';
import store from '../../store';
import { segmentIdentify } from '../layout/helper';

export const updateErrorFunc = payload => ({ type: 'NOTIFICATION', payload });
export const notification = payload => ({ type: 'NOTIFICATION', payload });

export const sendPicture = picture => {
    let formData;
    if (picture === '') {
        formData = { profile_picture: '' };
    } else {
        formData = new FormData();
        formData.append('profile_picture', picture, picture.name);
    }
    return {
        type: 'UPLOAD_AVATAR',
        payload: axios.post('users/upload/avatar', formData)
            .then(res => res.data)
    };
};

export const getInvitedUsers = () => ({
    type: 'GET_INVITATIONS_LIST',
    payload: axios.get('/invites/')
        .then(res => res.data.results)
});

export const getUsers = () => ({
    type: 'GET_USER_LIST',
    payload: axios.get('/users/')
        .then(res => res.data)
});

export const getCurrentUser = () => {
    const token = localStorage.getItem('iot_rocket_refresh_JWT');
    const userId = jwtDecode(token).user_id;
    return {
        type: 'GET_CURRENT_USER',
        payload: axios.get(`/users/${userId}/`)
            .then(res => res.data)
    };
};

export const getCurrentUserHMAC = () => ({
    type: 'GET_CURRENT_USER_HMAC',
    payload: axios.post('/users/generate-hmac')
        .then(res => res.data.hmac)
});

export const deleteInvitation = data => ({
    type: data.invite_ids.length > 1 ? 'DELETE_INVITES' : 'DELETE_INVITE',
    payload: axios.delete('/invites/', { data })
        .then(res => (res.data.nb_invites_deleted === data.invite_ids.length ? data.invite_ids : ''))
});

export const resendInvitation = id => ({
    type: 'RESEND_INVITE',
    payload: axios
        .post('/invites/refresh', { invite_id: id })
        .then(res => res.data)
        .catch(error => {
            console.error("RESEND_INVITE_ERROR", error); // eslint-disable-line
            return Promise.reject(error);
        })
});

export const changeUserDetailPopupParts = parts => ({
    type: 'CHANGE_USER_DETAIL_POPUP_PARTS',
    payload: parts
});

export const setGroupRights = rights => ({ type: 'SET_GROUP_RIGHTS', payload: rights });

export const setWorkspaceRights = rights => ({ type: 'SET_WORKSPACE_RIGHTS', payload: rights });

export const addUserNotificationRegistration = (data, msg) => ({
    type: msg || 'ADD_USER_NOTIFICATION_REGISTRATION',
    payload: axios.post('/users/notification-registrations/', data)
        .then(res => res.data)
});

export const getUserNotificationRegistration = token => ({
    type: 'GET_USER_NOTIFICATION_REGISTRATION',
    payload: axios.get(`/users/notification-registrations/?token=${token}`)
        .then(res => res.data)
});

export const deleteUserNotificationRegistration = token => ({
    type: 'DELETE_USER_NOTIFICATION_REGISTRATION',
    payload: axios.post('/users/disable-push-notif-from-token', { token })
        .then(res => res.data)
});

export const editUser = (id, data, msg) => ({
    type: msg || 'EDIT_USER',
    payload: axios.patch(`/users/${id}/`, data)
        .then(res => {
            if (store.getState().users.currentUser?.id === id) {
                segmentIdentify(res.data, store.getState().workspace.userWorkspacesList.accounts);
            }
            return res.data;
        })
});

export const getUser = id => ({
    type: 'GET_USER',
    payload: axios.get(`/users/${id}/`)
        .then(res => res.data)
});

export const addGroupToUser = data => ({
    type: 'ADD_GROUP_TO_USER',
    payload: axios.put('/groups/memberships/users/', data)
        .then(res => res.data)
});

export const removeUserFromGroup = (userId, groupId) => ({
    type: 'REMOVE_GROUP_FROM_USER',
    payload: axios.delete(`/groups/memberships/users/${userId}/${groupId}/`)
        .then(() => ({ group_id: groupId, id: userId }))
});

export const deleteUser = data => ({
    type: data.user_ids.length > 1 ? 'DELETE_USERS' : 'DELETE_USER',
    payload: axios.delete('/users/', { data })
        .then(res => (res.data.nb_users_deleted === data.user_ids.length ? data.user_ids : ''))
});

export const setUserShownInDetailPopup = user => ({ type: 'SET_USER_DETAIL', payload: user });

export const getUserSummary = (user_id, page) => ({
    type: 'GET_USER_SUMMARY',
    payload: axios.get(`/users/summary/${user_id}/page/${page}`)
        .then(res => ({ user_id, data: res.data, page }))
});

export const setIsLoggable = isLoggable => ({ type: 'IS_LOGGABLE_USER', payload: isLoggable });

export const addUsersWithoutMailFromCSV = file => {
    const formData = new FormData();
    formData.append('users', file, file.name);
    return {
        type: 'ADD_USERS_WITHOUT_MAIL_FROM_CSV',
        payload: axios
            .post('users/register-without-mail', formData)
            .then(res => res.data)
    };
};

export const inviteTeammate = inviteFields => ({
    type: 'INVITE_USER',
    payload: axios
        .post('/invites/', { ...inviteFields })
        .then(res => res.data)
        .catch(error => {
            console.error('INVITE_USER_ERROR', error); // eslint-disable-line
            return Promise.reject(error);
        })
});

export const inviteTeammateFromCSV = file => {
    const formData = new FormData();
    formData.append('invites', file, file.name);
    return {
        type: 'INVITE_USERS_FROM_CSV',
        payload: axios
            .post('/invites/', formData)
            .then(res => res.data)
    };
};

export const addNfcTagFromCSV = file => {
    const formData = new FormData();
    formData.append('nfc', file, file.name);
    return {
        type: 'ADD_NFC_TAG_CSV_FILE',
        payload: axios
            .post('/nfc/', formData)
            .then(res => res.data)
    };
};

export const editUserProfileErrorNotif = () => ({ type: 'EDIT_USER_PROFILE_REJECTED' });
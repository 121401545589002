// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uuid } from 'uuidv4';
import { isEmpty, isNaN, cloneDeep } from 'lodash';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Toast } from '../../../notification/components';
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';
import DeviceMeasureEditableField from './DeviceMeasureEditableField';
import { getMatchingAttributesFromServiceNow, getTimeSlotRecapText } from '../../../layout/helper';
import { getWeekDays } from '../../../rounds/helper';
import { getDefaultAlertsSelectedDays } from '../../helper';

const DeviceDetailsPopupRight = props => {
    const devices = useSelector(state => state.devices);
    const deviceTemplates = useSelector(state => state.devices.deviceShownInDetailsPopup.templates);
    const serviceNowSwitcher = useSelector(state => state.workspace.settings.servicenow_switcher);
    const deviceAlertTimeRange = useSelector(state => state.workspace.settings.device_alert_time_range);
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const footBar = useFootBar();
    const editionMode = footBar.getEditionMode();
    const [deletedConditionIdx, setDeletedConditionIdx] = useState([]);
    const [addedConditions, setAddedConditions] = useState([]);

    const [showConditions, setShowConditions] = useState(false);
    const [luminosityConditions, setLuminosityConditions] = useState(devices.deviceShownInDetailsPopup.conditions);
    const [templates, setTemplates] = useState([]);
    const skillList = useSelector(state => state.groups.list.filter(group => group.custom_field?.type === 'SKILL'));
    const [skillOptions, setSkillOptions] = useState([]);

    useEffect(() => {
        if (!editionMode && devices.deviceShownInDetailsPopup.custom_field.type === 'LUMINOSITY') {
            const conditions = [];
            devices.deviceShownInDetailsPopup.conditions.forEach(condition => {
                conditions.push({
                    lumi_min: condition.limit_min,
                    lumi_max: condition.limit_max,
                    hour_start: condition.hour_start,
                    hour_end: condition.hour_end,
                    id: condition.id
                });
            });
            setLuminosityConditions(conditions);
            setDeletedConditionIdx([]);
            setAddedConditions([]);
        } else if (!editionMode && devices.deviceShownInDetailsPopup.custom_field.type === 'BUTTON') {
            setTemplates(cloneDeep(deviceTemplates));
        }
    }, [editionMode]);

    useEffect(() => {
        if (devices.deviceShownInDetailsPopup.conditions?.length !== 0
            && ['TEMPERATURE_CONNIT', 'INFRARED', 'AIRWITS', 'AIRWITS_CO2', 'LUMINOSITY', 'TES-5MRO']
                .includes(devices.deviceShownInDetailsPopup.custom_field.type)
        ) { setShowConditions(true); }
        const newOptions = [];
        skillList.forEach(skill => newOptions.push({ id: skill.group_id, value: skill.group_name }));
        setSkillOptions(newOptions);
    }, []);

    const getPassageComponent = condition => (
        <DeviceMeasureEditableField
            icon={<Component componentName={'Icon'} type={'INFRARED'} />}
            title={t('devices:passage_threshold')}
            disabledLeft
            minRight={0}
            maxRight={999999}
            onChangeRight={value => props.updateState('passage_max', value)}
            valueRight={parseFloat(condition?.limit_max)}
            nameRight={'passage_max'}
            key={'div_passage'}
        />
    );

    const getTempComponent = condition => (
        <DeviceMeasureEditableField
            icon={<Component componentName={'Icon'} type={'TEMPERATURE_CONNIT'} />}
            title={t('devices:temperature_threshold')}
            required
            step={0.5}
            minLeft={'-22'}
            maxLeft={85}
            onChangeLeft={value => props.updateState('temp_min', value)}
            valueLeft={parseFloat(condition?.limit_min)}
            nameLeft={'temp_min'}
            minRight={'-22'}
            maxRight={85}
            onChangeRight={value => props.updateState('temp_max', value)}
            valueRight={parseFloat(condition?.limit_max)}
            nameRight={'temp_max'}
            key={'div_temp'}
        />
    );

    const getCO2Component = condition => (
        <DeviceMeasureEditableField
            icon={<Component componentName={'Icon'} type={'AIRWITS_CO2'} />}
            title={t('devices:CO2_threshold')}
            minLeft={0}
            maxLeft={30000}
            onChangeLeft={value => props.updateState('CO2_min', value)}
            valueLeft={parseFloat(condition?.limit_min)}
            nameLeft={'CO2_min'}
            minRight={0}
            maxRight={30000}
            onChangeRight={value => props.updateState('CO2_max', value)}
            valueRight={parseFloat(condition?.limit_max)}
            nameRight={'CO2_max'}
            key={'div_CO2'}
        />
    );

    const getHumidityComponent = condition => (
        <DeviceMeasureEditableField
            icon={<Component componentName={'Icon'} type={'AIRWITS'} />}
            title={t('devices:humidity_threshold')}
            minLeft={0}
            maxLeft={100}
            onChangeLeft={value => props.updateState('humidity_min', value)}
            valueLeft={parseFloat(condition?.limit_min)}
            nameLeft={'humidity_min'}
            minRight={0}
            maxRight={100}
            onChangeRight={value => props.updateState('humidity_max', value)}
            valueRight={parseFloat(condition?.limit_max)}
            nameRight={'humidity_max'}
            key={'humidity_max'}
        />
    );

    const checkConditions = (min_threshold, max_threshold, conditions) => {
        let error = false;
        let flag = false;
        conditions.forEach(condition => {
            const hour_start = condition.hour_start;
            const hour_end = condition.hour_end;
            if (condition.lumi_min && min_threshold && condition.lumi_min < min_threshold) {
                error = t('devices:threshold_min', min_threshold);
                flag = true;
            } if (condition.lumi_max && min_threshold && condition.lumi_max < min_threshold) {
                error = t('devices:threshold_min', min_threshold);
                flag = true;
            } if (condition.lumi_min && max_threshold && condition.lumi_min > max_threshold) {
                error = t('devices:threshold_max', max_threshold);
                flag = true;
            } if (condition.lumi_max && max_threshold && condition.lumi_max > max_threshold) {
                error = t('devices:threshold_max', max_threshold);
                flag = true;
            } if (condition.lumi_min && condition.lumi_max
                && parseFloat(condition.lumi_min) >= parseFloat(condition.lumi_max)) {
                error = t('devices:threshold_min_max');
                flag = true;
            } if (isNaN(parseFloat(condition.lumi_min)) && isNaN(parseFloat(condition.lumi_max))) {
                error = t('devices:threshold_null');
                flag = true;
            } if (parseFloat(condition.lumi_min) === parseFloat(condition.lumi_max)) {
                error = t('devices:threshold_equals');
                flag = true;
            } if (condition.hour_start && condition.hour_end && condition.hour_start === condition.hour_end) {
                error = t('devices:hours_equals');
            }
            const otherHours = conditions.filter(obj => obj.id !== condition.id
                && obj.hour_start && obj.hour_end);
            if (otherHours.length > 0 && hour_start && hour_end) {
                const p_start = moment(hour_start, 'HH:mm');
                const p_end = moment(hour_end, 'HH:mm');
                otherHours.forEach(hours => {
                    const h_start = moment(hours.hour_start, 'HH:mm');
                    const h_end = moment(hours.hour_end, 'HH:mm');
                    if (h_start.isSame(p_start) && h_end.isSame(p_end)) {
                        error = t('devices:hours_overlap');
                        flag = true;
                    } else if (h_start.isBefore(h_end)) {
                        if (p_start.isSameOrAfter(h_start) && p_start.isBefore(h_end)
                            || p_end.isAfter(h_start) && p_end.isSameOrBefore(h_end)) {
                            error = t('devices:hours_overlap');
                            flag = true;
                        }
                    } else if (!(p_start.isSameOrAfter(h_end) && p_start.isSameOrBefore(h_start))
                        || !(p_end.isSameOrAfter(h_end) && p_end.isSameOrBefore(h_start))) {
                        error = t('devices:hours_overlap');
                        flag = true;
                    }
                });
            }
        });
        footBar.set({ isError: flag });
        if (error) {
            toast(
                <Toast
                    isConfirm
                    message={error}
                    icon={'frown'}
                    type={'error'}
                />, {
                    position: toast.POSITION.BOTTOM_LEFT,
                    className: 'normal',
                    bodyClassName: 'notification-body grow-font-size',
                    progressClassName: 'error-custom-progress-bar'
                }
            );
        }
    };

    const getLuminosityComponent = condition => {
        const min_threshold = 0;
        const max_threshold = 100000;
        const step = 0.5;
        return (
            <DeviceMeasureEditableField
                key={`luminosity-condition-${condition.id}`}
                icon={<Component componentName={'Icon'} type={'LUMINOSITY'} />}
                title={t('devices:luminosity_threshold')}
                step={step}
                minLeft={min_threshold}
                maxLeft={max_threshold}
                tooltipText={t('devices:luminosity_threshold_explanations')}
                valueLeft={luminosityConditions.find(obj => obj.id === condition.id)?.lumi_min}
                onChangeLeft={value => {
                    const newArr = [...luminosityConditions];
                    newArr.find(obj => obj.id === condition.id).lumi_min = !isEmpty(value) && !isNaN(parseFloat(value))
                        ? parseFloat(value) : null;
                    setLuminosityConditions(newArr);
                    props.updateState('conditions', luminosityConditions);
                }}
                minRight={min_threshold}
                maxRight={max_threshold}
                valueRight={luminosityConditions.find(obj => obj.id === condition.id)?.lumi_max}
                onChangeRight={value => {
                    const newArr = [...luminosityConditions];
                    newArr.find(obj => obj.id === condition.id).lumi_max = !isEmpty(value) && !isNaN(parseFloat(value))
                        ? parseFloat(value) : null;
                    setLuminosityConditions(newArr);
                    props.updateState('conditions', luminosityConditions);
                }}
                hour_start={luminosityConditions.find(obj => obj.id === condition.id)?.hour_start}
                onChangeStartHour={value => {
                    const newArr = [...luminosityConditions];
                    newArr.find(obj => obj.id === condition.id).hour_start = value || 'error_field';
                    setLuminosityConditions(newArr);
                    props.updateState('conditions', luminosityConditions);
                }}
                hour_end={luminosityConditions.find(obj => obj.id === condition.id)?.hour_end}
                onChangeEndHour={value => {
                    const newArr = [...luminosityConditions];
                    newArr.find(obj => obj.id === condition.id).hour_end = value || 'error_field';
                    setLuminosityConditions(newArr);
                    props.updateState('conditions', luminosityConditions);
                }}
                onBlur={() => checkConditions(min_threshold, max_threshold, luminosityConditions)}
                enabledTrash={luminosityConditions.length > 1}
                onDelete={() => {
                    if (luminosityConditions.length > 1) {
                        const newArray = luminosityConditions.filter(obj => obj.id !== condition.id);
                        setLuminosityConditions(newArray);
                        props.updateState('conditions', newArray);
                        setDeletedConditionIdx([...deletedConditionIdx, condition.id]);
                        checkConditions(min_threshold, max_threshold, newArray);
                    }
                }}
            />
);
    };

    const getTemplateComponent = template => {
        const selectedTemplate = templates.find(obj => obj.id === template.id);
        const fields = [{
            componentName: 'EditableField',
            editionComponent: 'Input',
            name: 'template_title',
            props: {
                value: selectedTemplate.title,
                required: true,
                placeholder: '',
                multiple: false,
                rules: ['maxLength-128']
            },
            renderValue: 'SimpleString',
            title: `${t('tickets:title')} ${getMatchingAttributesFromServiceNow(serviceNowSwitcher, 'title')}`
        }, {
            componentName: 'EditableField',
            editionComponent: 'GroupSelect',
            name: 'template_skill',
            props: {
                value: selectedTemplate.skill_id,
                required: true,
                placeholder: '',
                groupType: 'SKILL',
                multiple: false,
                rules: [],
                options: skillOptions
            },
            renderValue: 'GroupString',
            title: `${t('tickets:skill')} ${getMatchingAttributesFromServiceNow(serviceNowSwitcher, 'skill')}`
        }];
        return (
            <div className={'template-edition-component'}>
                { fields.map(field => (
                    <Component
                        key={field.title}
                        title={t(field.title)}
                        componentName={'EditableField'}
                        renderValue={field.renderValue}
                        editionComponent={field.editionComponent}
                        onChange={e => {
                            const newArr = [...templates];
                            if (field.name === 'template_skill') {
                                selectedTemplate.skill_id = e.target.value;
                            } else if (field.name === 'template_title') {
                                selectedTemplate.title = e;
                            }
                            setTemplates(newArr);
                            props.updateState('templates', templates);
                        }}
                        props={field.props}
                        value={field.props.value}
                    />
                ))}
            </div>

        );
    };

    if (devices.deviceShownInDetailsPopup.custom_field.type === 'BUTTON') {
        return (
            <div className="logs">
                <div className={'logs-title'}>
                    <h2>{t('devices:configuration')}</h2>
                </div>
                <div className="config_measures">
                    <span className="form-text form-text-explanations">
                        {t('devices:alert_button_explanations')}
                    </span>
                    { templates.map(template => getTemplateComponent(template)) }
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="logs">
                <div className={'logs-title'}>
                    <h2>{t('devices:configuration')}</h2>
                </div>
                {showConditions
                    ? (
                        <div className="config_measures">
                            <span className="form-text form-text-explanations">
                                {['TEMPERATURE_CONNIT', 'AIRWITS', 'AIRWITS_CO2', 'TES-5MRO'].includes(devices.deviceShownInDetailsPopup.custom_field.type)
                                    ? getTimeSlotRecapText(
                                        t,
                                        deviceAlertTimeRange?.hour_start,
                                        deviceAlertTimeRange?.hour_end,
                                        getDefaultAlertsSelectedDays(deviceAlertTimeRange)
                                        , !deviceAlertTimeRange?.hour_start || !deviceAlertTimeRange?.hour_end,
                                        true
                                    )[1]
                                    : t('devices:alert_device_explanations')
                                }
                            </span>
                            {devices.deviceShownInDetailsPopup.conditions
                                ?.filter(obj => !deletedConditionIdx.includes(obj.id))
                                .sort((a, b) => {
                                    if (a.code > b.code) return 1;
                                    if (a.code < b.code) return -1;
                                    return 0;
                                }).map(condition => {
                                    if (condition.code === 'passage') {
                                        return getPassageComponent(condition);
                                    }
                                    if (condition.code === 'Temp' || devices.deviceShownInDetailsPopup.custom_field.type === 'TEMPERATURE_CONNIT') {
                                        return getTempComponent(condition);
                                    }
                                    if (condition.code === 'CO2' && devices.deviceShownInDetailsPopup.custom_field.type === 'AIRWITS_CO2') {
                                        return getCO2Component(condition);
                                    }
                                    if (condition.code === 'Humi') {
                                        return getHumidityComponent(condition);
                                    }
                                    if (devices.deviceShownInDetailsPopup.custom_field.type === 'LUMINOSITY') {
                                        return getLuminosityComponent(condition);
                                    }
                                    return null;
                                })}
                            { addedConditions
                                ?.filter(obj => !deletedConditionIdx.includes(obj.id))
                                .map(condition => getLuminosityComponent(condition))
                            }
                            { editionMode && devices.deviceShownInDetailsPopup.custom_field.type === 'LUMINOSITY' && luminosityConditions.length < 3 && (
                                <Button
                                    className={'add-new-line-button'}
                                    onClick={() => {
                                        const newCondition = {
                                            lumi_min: null,
                                            lumi_max: 300,
                                            hour_start: '18:00',
                                            hour_end: '07:00',
                                            id: uuid()
                                        };
                                        let array = addedConditions;
                                        array.push(newCondition);
                                        setAddedConditions(array);
                                        array = luminosityConditions;
                                        array.push(newCondition);
                                        setLuminosityConditions(array);
                                        props.updateState('conditions', luminosityConditions);
                                        checkConditions(0, 100000, array);
                                    }}
                                >
                                    {t('common:add_new_line')}
                                </Button>
                            )}
                        </div>
                    )
                    : (
                        <div className="config_measures_empty">
                            <div className="empty-table-wrapper">
                                <Component componentName={'Icon'} type={'not_implemented'} />
                                <span>{t('devices:no_configuration')}</span>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
};

export default DeviceDetailsPopupRight;

import React, { useEffect } from 'react';

/*
 Component to enable horizontal scroll on a div nested in SwipeableViews component
 => Scroll was prevented because of a bug on the react-swipeable-views lib
*/

const ScrollContainerForSwipeableViews = props => {
    const container = React.createRef();

    const isolateTouch = e => e.stopPropagation();

    useEffect(() => {
        const containerNode = container.current;

        if (!containerNode) { return; }

        containerNode.addEventListener('touchstart', isolateTouch, { passive: true });
        containerNode.addEventListener('touchmove', isolateTouch, { passive: true });
        containerNode.addEventListener('touchend', isolateTouch, { passive: true });
    }, []);


    return (
        <div className={'scroll-container-for-swipeable-views'} ref={container}>
            {props.items}
        </div>
    );
};

export default ScrollContainerForSwipeableViews;

// Libs
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
// Components
import CreatableSelect from 'react-select/creatable';
import { FormHelperText, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';

const MultiValue = ({ children, ...props }) => (
    <Tooltip title={props.data.label}>
        <div className={'creatable-select-multivalue'}>
            <components.MultiValue {...props}>
                {children}
            </components.MultiValue>
        </div>
    </Tooltip>
);

const CreatableSelectComponent = props => {
    const [primaryColor] = useState(getComputedStyle(document.documentElement).getPropertyValue('--primary-color'));
    const [isError, setIsError] = useState(false);
    const maxLength = props.maxLength || 64;
    const [t] = useTranslation();

    const customStyles = {
        container: provided => ({
            ...provided,
            width: '100%'
        }),
        input: provided => ({
            ...provided,
            cursor: 'text',
            minWidth: '10%'
        }),
        menu: provided => ({
            ...provided,
            maxHeight: '16vh',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
            borderRadius: '4px',
            position: 'absolute',
            top: '40px'
        }),
        menuList: provided => ({
            ...provided,
            maxHeight: 'inherit'
        }),
        option: (provided, state) => {
            let backgroundColor;
            let cursor = 'pointer';
            if (state.isDisabled) { cursor = 'default'; }
            if (state.isSelected) {
                backgroundColor = primaryColor;
                if (state.isDisabled) {
                    backgroundColor = '#011056c4';
                } else if (state.isFocused) {
                    backgroundColor = '#000c46';
                } else {
                    backgroundColor = primaryColor;
                }
            } else {
                backgroundColor = '#f3f3f3';
                if (state.isDisabled) {
                    backgroundColor = '#f3f3f3';
                } else if (state.isFocused) {
                    backgroundColor = '#0110562b';
                }
            }
            return {
                ...provided,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                backgroundColor,
                borderBottom: '1px solid transparent',
                padding: '12px',
                transition: '0.05s',
                cursor
            };
        },
        control: () => {
            let borderBottom = 'unset';
            if (!props.renderOnlySelect) { borderBottom = '1px solid #c7c5c5'; }
            return {
                borderBottom,
                display: 'flex'
            };
        },
        valueContainer: provided => {
            let flexWrap = 'unset';
            if (props.renderOnlySelect) { flexWrap = 'wrap'; }
            return {
                ...provided,
                width: '90%',
                flexWrap,
                overflow: 'hidden'
            };
        },
        indicatorsContainer: provided => ({
            ...provided,
            display: 'flex',
            justifyContent: 'right',
            cursor: 'pointer',
            width: '10%'
        }),
        multiValue: provided => {
            let paddingRight = 'unset';
            if (props.renderOnlySelect) {
                paddingRight = '3px';
            }
            return {
                ...provided,
                paddingRight,
                display: 'flex',
                height: '32px',
                alignItems: 'center',
                borderRadius: '5px',
                marginRight: '8px',
                backgroundColor: '#e0e0e0',
                cursor: 'default'
            };
        }
    };
    const cannotSelectMoreOptions = props.value?.length >= props.maxVisible;
    return (
        <div className={'creatable-select'}>
            {props.renderOnlySelect ? ( // display a select where we cannot do any action (=renderValue)
                <Select
                    {...props}
                    styles={customStyles}
                    components={{
                        MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null, MultiValueRemove: () => null
                    }}
                    isSearchable={false}
                    menuIsOpen={false}
                />
            ) : (
                <>
                    <CreatableSelect
                        {...props}
                        components={{ MultiValue, IndicatorSeparator: () => null }}
                        isValidNewOption={
                            inputValue => inputValue.length !== 0 && inputValue?.trim().length !== 0 // we cannot create an empty option
                                && props.canCreate // user can only create if he has the rights to
                                && !props.options.find(opt => opt.label === inputValue?.trim()) // we cannot create an already existing option unless it's not the same case
                        }
                        styles={customStyles}
                        onInputChange={
                            inputValue => (inputValue.length <= maxLength
                                ? inputValue
                                : inputValue.slice(0, maxLength))
                        }
                        onChange={values => {
                            props.onChange(values);
                            if (props.required && isEmpty(values)) {
                                setIsError(true);
                                props.onChange('error_field');
                            } else {
                                setIsError(false);
                            }
                        }}
                        hideSelectedOptions={false} // The selected options still appears in the list
                        closeMenuOnSelect={false}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 10,
                            colors: {
                                ...theme.colors,
                                primary25: '#CCCFDD',
                                primary50: '#CCCFDD',
                                primary: isError ? 'red' : primaryColor
                            }
                        })}
                        isOptionDisabled={() => cannotSelectMoreOptions}
                        loadingMessage={() => t('common:loading')}
                        options={cannotSelectMoreOptions ? [] : props.options}
                        noOptionsMessage={() => (
                            cannotSelectMoreOptions // if a user cannot add more options we tell him by putting a message
                                ? props.canCreate
                                    ? null // if the user have the right to create options the message will be displayed on the create label
                                    : t('tickets:ticket_tags_max_selected') // else, we display it here
                                : props.noOptionsMessage())
                        }
                        formatCreateLabel={values => (cannotSelectMoreOptions ? t('tickets:ticket_tags_max_selected') : props.formatCreateLabel(values))}
                    />
                    { isError
                        && <FormHelperText className={'creatable-select-error'} error>{t('errors:not_empty')}</FormHelperText>
                    }
                </>
            )}
        </div>

    );
};

export default CreatableSelectComponent;

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// Components
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';

const DeviceBattery = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const settings = useSelector(state => state.core.settings);
    const selectedData = props.resource.data.find(data => data.data_type === settings.battery_label);
    if (!selectedData) return <Typography className={'placeholder_text'} variant={'subtitle2'}>{t('common:no_data')}</Typography>;
    const { unit } = selectedData;
    const { value, timestamp } = selectedData.data_points[0];
    return (
        <Tooltip
            classes={{ tooltip: 'table-tooltip' }}
            placement={'top'}
            title={<Component componentName={'Description'} className="action_button" content={`${value} ${unit} ${t('common:at')} ${moment(timestamp).format('DD-MM-YYYY')}`} />}
        >
            <div className={'progress-container'}>
                <LinearProgress variant="determinate" value={value} />
                <i className={'value-after-progress-bar'}>{`${value} ${unit || ''}`}</i>
            </div>
        </Tooltip>
    );
};

export default DeviceBattery;

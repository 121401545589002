// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, isEmpty, sortBy, filter, includes, cloneDeep } from 'lodash';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
// Context
import { useFootBar } from '../../footbar/FootBarContext';
import { useModal } from '../../modal/ModalContext';
// Helper
import { filterTable } from '../../navbar/helper';
import { getUserGroupIds, getVisibleUsers } from '../helper';
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import { getUser } from '../actions';

const paramAutoSizer = {
    maxRowVisible: 3, // Max row visible for toggle table
    heightRow: 80, // Height row
    heightHeader: 80, // Height header
    minHeightTable: 80 // Min height table toggle
};

const customFilterFunctions = {
    user: props => filter(props.list, user => user.id === props.id),
    group: props => filter(props.list, user => includes(getUserGroupIds(user), props.id)),
    invite: props => filter(props.list, user => user.id === props.id),
};

const filterGroup = (filters, list, props) => {
    const newFilters = cloneDeep(filters);
    let newList = cloneDeep(list);

    newFilters?.forEach(paramFilter => {
        if (paramFilter.filterFunction) {
            newList = paramFilter.filterFunction(list);
        }
    });

    return filterTable(
        filter(filters, f => f?.type !== 'defaultOptions'),
        newList,
        props.usersList,
        [],
        ['user', 'group', 'invite'],
        customFilterFunctions,
        true
    );
};

// Filter user by type
const filterByTable = (type, users, currentUser = null) => {
    let list;
    if (type === 'invited') {
        list = users.filter(item => item?.invite_email !== undefined);
    } else {
        list = getVisibleUsers(users, currentUser).filter(item => item?.invite_email === undefined);
    }
    return list;
};

// Calculate height table open
const getHeight = (list, param) => (list.length < param.maxRowVisible
    ? param.heightRow * (list.length + 1)
    : param.heightRow * (param.maxRowVisible + 1));

export default () => {
    const { getComponentFile, Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const footBar = useFootBar();
    const modal = useModal();
    const currentUser = useSelector(state => state.users.currentUser);
    const usersList = useSelector(state => state.users.list);
    const filters = useSelector(state => state.navbar.filters);
    const invitedUsersTableColumns = useSelector(state => sortBy(state.users.invitedUsersTableColumns, 'weight'));
    const usersTableColumns = useSelector(state => sortBy(state.users.usersTableColumns, 'weight'));
    // Users
    const [users, setUsers] = useState(filterByTable('', usersList, currentUser));
    const [open] = useState(false);
    // Invited Users
    const [invited, setInvited] = useState(() => filterByTable('invited', usersList));
    const [openInvited, setOpenInvited] = useState(false);
    // Others
    const [columnExtensions] = useState([
        { columnName: 'accessibility', sortingEnabled: false },
        { columnName: 'contact', sortingEnabled: false },
        { columnName: 'name', sortingEnabled: false },
        { columnName: 'nfc', sortingEnabled: false }
    ]);

    const prepareColumn = (column, count) => {
        let title = t(column.title, { count });
        if (column.isCapitalized) {
            title = capitalize(title);
        }
        if (column.formTitle) {
            title = (
                <div className={'table-head-title'}>
                    <span>{title}</span>
                    <em>{`(${count})`}</em>
                </div>
            );
        }
        return {
            name: column.name,
            title,
            component: column.component ? getComponentFile(column.component) : null
        };
    };

    const InvitedUsersTableColumns = invitedUsersTableColumns.map(column => prepareColumn(column, invited.length));
    const UsersTableColumns = usersTableColumns.map(column => prepareColumn(column, users.length));

    // Update list, if data updated
    useEffect(() => {
        setUsers(filterGroup(filters, filterByTable('', usersList, currentUser), { usersList }));
        setInvited(filterGroup(filters, filterByTable('invited', usersList), { usersList }));
    }, [usersList, filters]);

    const toggleSelected = selected => {
        const selectFiltered = usersList.filter(user => selected.includes(user.id));
        footBar.update({ selected, list: selectFiltered, type: 'user' });
    };

    // OnClick row table
    const getLogs = async user => {
        const result = await dispatch(getUser(user.id));
        const selected = result ? result.action.payload : {};
        footBar.close();
        modal.update({ name: 'UserDetailsPopup', item: selected, withFootBar: true });
    };

    return isEmpty(usersList) ? <Component componentName={'NoData'} type={'users'} popup={'InvitePopup'} /> : (
        <div className={'table-wrapper'}>
            { invited.length > 0 && (
                <Component
                    componentName={'Table'}
                    height={(users?.length === 0 || openInvited)
                        ? getHeight(invited, paramAutoSizer)
                        : paramAutoSizer.minHeightTable}
                    open={users?.length === 0 || openInvited}
                    toggleSelected={toggleSelected}
                    columns={InvitedUsersTableColumns}
                    columnExtensions={columnExtensions}
                    emptyNotVisible
                    index={2}
                    list={invited}
                    resize={() => setOpenInvited(val => !val)}
                    className={`not-configured-table ${openInvited ? 'opened' : ''}`}
                />
            )}
            <div className={'user-table-autoSizer'}>
                {
                    users?.length > 0
                        ? (
                            <AutoSizer disableWidth>
                                {({ height }) => (
                                    <div style={{ height }}>
                                        <Component
                                            componentName={'Table'}
                                            height={height}
                                            open={open}
                                            columns={UsersTableColumns}
                                            toggleSelected={toggleSelected}
                                            columnExtensions={columnExtensions}
                                            list={filterGroup(filters, filterByTable('', usersList, currentUser), {usersList})}
                                            onRowClick={user => getLogs(user)}
                                        />
                                    </div>
                                )}
                            </AutoSizer>
                        )
                        : invited.length > 0
                            ? <></>
                            : (
                                <div className={'no-data'}>
                                    <Component componentName={'NoData'} view={'user'} />
                                </div>
                            )
                }
            </div>
        </div>
    );
};

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
import { useModal } from '../../modal/ModalContext';

export default () => {
    const { Component } = useComponentsPool();
    const userWorkspaceRights = useSelector(state => state.users.currentUser.workspace_rights);
    const currentUser = useSelector(state => state.users.currentUser);
    const [t] = useTranslation();
    const modal = useModal();

    if (currentUser.workspace_role.workspace_role_name === 'Owner') {
        return (
            <Component
                componentName={'ActionButton'}
                canUserCreate={userWorkspaceRights.includes('CREATE:Group')}
                iconButton={(<Component componentName={'Icon'} type={'settings'} />)}
                tooltipTitle={''}
                actions={[
                    {
                        icon: <Component componentName={'Icon'} type={'csvFile'} />,
                        name: t('csv:add_skills_from_csv'),
                        onClick: () => modal.update({ name: 'CSVAddSkillsPopup' })
                    },
                    {
                        icon: <Component componentName={'Icon'} type={'csvFile'} />,
                        name: t('csv:add_buildings_from_csv'),
                        onClick: () => modal.update({ name: 'CSVAddBuildingsPopup' })
                    }
                ]}
            />
        );
    }
};

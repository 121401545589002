// Libs
import React, { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { traduceRRule } from '../../helper';

const RoundRecurrenceSimpleString = props => {
    const [t] = useTranslation();
    return <span>{capitalize(traduceRRule(t, props.resource.rrule, props.resource.name, props.resource.started_at, props.resource.ended_at, props.resource.is_until_end_of_month))}</span>;
};

export default RoundRecurrenceSimpleString;

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { getTraductionOfGroup } from '../../groups/helper';
import { useTranslation } from 'react-i18next';

export default props => {
    const [t] = useTranslation();
    const tickets = useSelector(state => state.tickets);
    const groups = useSelector(state => state.groups.list);

    const ticket = tickets.ticketShownInDetailsPopup;

    let traduced_group_name = '';
    let custom_field = '';
    if (Array.isArray(props.value)) {
        if (props.value[1] === 'floor_label') {
            custom_field = 'floor_group_id';
        } else if (props.value[1] === 'position_label') {
            custom_field = 'position_group_id';
        } else {
            custom_field = props.value[1];
        }
        const group_id = ticket?.custom_field[custom_field];
        const group = groups.find(g => g.group_id === group_id || g.group_name === group_id);
        traduced_group_name = group?.group_name ? getTraductionOfGroup(group?.group_name, t) : '';
    } else {
        traduced_group_name = props.value;
    }

    return (
        <p className={`info-value ${custom_field}`}>
            {traduced_group_name}
        </p>
    );
};

// Libs
import React, {
    forwardRef, useEffect, useRef, useState
} from 'react';
import * as moment from 'moment';
import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
drilldown(Highcharts);
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';
import Boost from 'highcharts/modules/boost';
import Exporting from 'highcharts/modules/exporting';
import PropTypes from 'prop-types';

// Default graph config
import GraphConfig from './default.graph.config';
import { useTranslation } from 'react-i18next';
import { useComponentsPool } from '../../../../ComponentsPool';
import classNames from 'classnames';

moment.locale(localStorage.getItem('i18nextLng'));
moment.locale('fr');
var offset = -(new Date().getTimezoneOffset() / 60);
Highcharts.setOptions(GraphConfig.lang);
HighchartsMore(Highcharts);
SolidGauge(Highcharts);
Boost(Highcharts);
Exporting(Highcharts);


const GaugeGraph = forwardRef((props, ref) => {
    const chart = useRef(ref);

    const [t] = useTranslation();
    const { Component } = useComponentsPool();

    let series = [];
    let trad;

    if (props.isDrillDown) {
        props.data?.series.map(point => {
            trad = t(`groups:${point.name?.toString().toUpperCase()}`) === point.name?.toString().toUpperCase()
                ? point.name
                : t(`groups:${point.name?.toString().toUpperCase()}`);
            series.push({
                "name": trad,
                "y": point.value,
                "drilldown": point.drilldown
            });
        });
    } else {
        props.data.map(point => {
            if (props.period_name) {
                if (props.period_name === 'month')
                    trad = t(`graphs:${props.period_name}_${point.name?.split('/')[0]}`) + ' ' + point.name?.split('/')[1]
                else if (props.period_name === 'week')
                    trad = `${t('graphs:week')} ${point.name}`
                else if (props.period_name === 'day')
                    trad = props.label === 'press_count'
                        ? point.name
                        : `${point.name?.split('/')[0]} ${t(`graphs:month_${point.name?.split('/')[1]}`)}`
                else if (props.period_name === 'hour')
                    trad = (parseInt(point.name) + offset).toString() + 'h';
                else if (props.period_name === 'minute')  {
                    let hours = parseInt(point.name.split(":")[0]) + offset;
                    let minutes = point.name.split(":")[1];
                    trad = hours + "h" + minutes
                }
                else
                    trad = point.name
            } else {
                trad = t(`groups:${point.name?.toString().toUpperCase()}`) === point.name?.toString().toUpperCase()
                    ? point.name
                    : t(`groups:${point.name?.toString().toUpperCase()}`);
            }
            series.push([trad, point.value]);
        });
    }

    const [options, setOptions] = useState({
        solidgauge: {
            animation: true,
            dataLabels: {
                enabled: props.mode === 'details',
                color: '#011056',
                style: {
                    color: 'contrast',
                    fontSize: '28px',
                    fontWeight: 'bold',
                    textOutline: '1px contrast'
                },
                y: -60,
                x: 0,
                borderWidth: 0,
                useHTML: true
            },
            linecap: 'round',
            stickyTracking: true
        },
        chart: {
            type: 'column',
            height: 170,
            width: 880,
            reflow: true
        },
        xAxis: {
            type: 'category',
            labels: {
                style: {
                    fontSize: '11px'
                }
            }
        },
        credits: {
            enabled: false
        },
        title: '',
        yAxis: {
            min: 0,
            allowDecimals: false,
            title: {
                text: t(`dashboards:${props.label}_yaxis`)
            }
        },
        legend: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        tooltip: {
            borderRadius: 0,
            //enabled: true,
            shape: 'square',
            //outside: true,
            shadow: false,
            useHTML: true,
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            formatter() {
                return `${'<div class="highcharts-tooltip-custom" style="border-radius: .1vw;box-shadow: 0 0 5px 0px rgba(0,0,0,0.13); width: 100%;'
                    + 'padding: 15px;background: #F6F6F6;display: flex; flex-direction: column;'
                    + 'justify-items: center"><div style="color:#011056'};font-size:20px;font-weight: bold; text-align: center; width: 100%" >${ this.point.name }
                        </div>`
                    + `<div style="font-size: 15px;color: #858585; width: 100%; text-align: center; font-weight: bold">
                    ${Math.round(this.y * 100) / 100 }${Math.round(this.y * 100) / 100 > 1
                        ? props.isDeviceGraph ? ' ' + t(`devices:${props.label}_units`) : ' tickets'
                        : props.isDeviceGraph ? ' ' + t(`devices:${props.label}_unit`) : ' ticket' }</div></div>`;
            },
            positioner: props.isDeviceGraph ? function (labelWidth, labelHeight, point) {
                const tooltipXposition = point.plotX - labelWidth * Math.ceil(point.plotX / 100) - labelWidth;
                return {
                    x: tooltipXposition,
                    y: point.plotY - 30
                };
            } : undefined
        },
        series: [{
            name: t(`graphs:${props.label}`),
            data: series,
            innerSize: '80%',
            loading: false,
            color: '#011056'
        }],
        lang: {
            drillUpText: t('graphs:back_drilldown_buildings')
        },
        drilldown: {
            series: props.data?.drilldown ? props.data?.drilldown : {},
            drillUpButton: {
                position: {
                    y: 0
                },
                theme: {
                    fill: '#3ECCCC',
                    strokeWidth: 1,
                    stroke: '#3ECCCC',
                    height: 12,
                    states: {
                        hover: {
                            fill: '#7bdcdc',
                            color: 'white'
                        },
                        select: {
                            stroke: '#039',
                            fill: '#a4edba',
                            color: 'white'
                        }
                    }
                }
            }
        }
    });

    useEffect(() => {
        series = [];
        if (props.isDrillDown) {
            props.data?.series?.map(point => {
                trad = t(`groups:${point.name?.toString().toUpperCase()}`) === point.name?.toString().toUpperCase()
                    ? point.name?.toString()
                    : t(`groups:${point.name?.toString().toUpperCase()}`);
                series.push({
                    "name": trad,
                    "y": point.value,
                    "drilldown": point.drilldown
                });
                setOptions(prevState => ({
                    ...prevState,
                    series: [{
                        name: t(`graphs:${props.label}`),
                        data: series,
                        innerSize: '60%',
                        loading: false,
                        color: '#011056'
                    }],
                    yAxis: {
                        min: 0,
                        allowDecimals: false,
                        title: {
                            text: t(`dashboards:${props.label}_yaxis`)
                        }
                    },
                    drilldown: {
                        series: props.data?.drilldown ? props.data?.drilldown : {},
                        drillUpButton: {
                            position: {
                                y: 0
                            },
                            theme: {
                                fill: 'red',
                                strokeWidth: 1,
                                stroke: '#93cdff',
                                height: 12,
                                states: {
                                    hover: {
                                        fill: '#93cdff',

                                    },
                                    select: {
                                        stroke: '#039',
                                        fill: '#a4edba'
                                    }
                                }
                            }
                        }
                    },
                }));
            });
        } else {
            props.data?.map(point => {
                if (props.period_name) {
                    if (props.period_name === 'month')
                        trad = t(`graphs:${props.period_name}_${point.name?.split('/')[0]}`) + ' ' + point.name?.split('/')[1]
                    else if (props.period_name === 'week') {
                        trad = props.label === 'press_count'
                        ? `${t('graphs:week_button')} ${point.name}`
                        : `${t('graphs:week')} ${point.name}`
                    }
                    else if (props.period_name === 'day')
                        trad = props.label === 'press_count'
                            ? point.name
                            : `${point.name?.split('/')[0]} ${t(`graphs:month_${point.name?.split('/')[1]}`)}`
                    else if (props.period_name === 'hour')
                        trad = (parseInt(point.name) + offset).toString() + 'h';
                    else if (props.period_name === 'minute')  {
                        let hours = parseInt(point.name.split(":")[0]) + offset;
                        let minutes = point.name.split(":")[1];
                        trad = hours + "h" + minutes
                    }
                    else
                        trad = point.name
                } else {
                    trad = t(`groups:${point.name?.toString().toUpperCase()}`) === point.name?.toString().toUpperCase()
                        ? point.name
                        : t(`groups:${point.name?.toString().toUpperCase()}`);
                }
                series.push([trad, point.value]);
            });
            setOptions(prevState => ({
                ...prevState,
                series: [{
                    name: t(`graphs:${props.label}`),
                    data: series,
                    innerSize: '60%',
                    loading: false,
                    color: '#011056'
                }],
                yAxis: {
                    min: 0,
                    allowDecimals: false,
                    title: {
                        text: t(`dashboards:${props.label}_yaxis`)
                    }
                },
                drilldown: {
                    series: props.data?.drilldown ? props.data?.drilldown : {},
                    drillUpButton: {
                        position: {
                            y: 0
                        },
                        theme: {
                            fill: 'red',
                            strokeWidth: 1,
                            stroke: '#93cdff',
                            height: 12,
                            states: {
                                hover: {
                                    fill: '#93cdff',

                                },
                                select: {
                                    stroke: '#039',
                                    fill: '#a4edba'
                                }
                            }
                        }
                    }
                }
            }));
        }
    }, [props.data]);

    const getTops = () => {
        let tops = series.sort((a, b) => {
            if (a[1] > b[1]) return -1;
            if (a[1] < b[1]) return 1;
            return 0;
        });
        return (
            <div className='top-graph-data'>
                {
                    tops.slice(0, 5).map((top, idx) => {
                        return (
                            <div className='incident'>
                                <span className='incident-title incident-number'>{`${idx + 1}.`}</span>
                                <span className='incident-title'>{`${top[0]}`}</span>
                                <span className='incident-quantity'>{`(${top[1]} tickets)`}</span>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    if (props.data?.length > 0 || (props.isDrillDown && props.data?.series?.length > 0)) {
        let label = props.from_date && props.period_name
            ? t(`dashboards:${props.label}`, {
                'from_timestamp': moment(props.from_date).format('DD/MM/YYYY'),
                'period': t(`dashboards:${props.period_name}`)
            })
            : t(`dashboards:${props.label}`)
        return (
            props.isDeviceGraph ? (
                <div className={'graph-component device-histogram'} id={'graph-view-curve'}>
                    <HighchartsReact highcharts={Highcharts} options={options} ref={chart} />
                </div>
            ) : (
                <div className={classNames({
                    'top-graph-container': true
                })}>
                    <span className="top-graph-title">{label}</span>
                    <div className="gauge-graph-with-top-legend">
                        { props.withTopLegend && getTops() }
                        <div className={'graph-component gauge'} id={'graph-view-curve'}>
                            <HighchartsReact highcharts={Highcharts} options={options} ref={chart} />
                        </div>
                    </div>
                </div>
            )
        );
    }
    return (<Component componentName={'GraphNoData'} />)

});

GaugeGraph.defaultProps = {
    editing: false,
    showLegend: false
};

GaugeGraph.propTypes = {
    editing: PropTypes.bool,
    graph: PropTypes.shape({
        _graph_data: PropTypes.shape.isRequired,
        aggregate_operation_name: PropTypes.string,
        aggregate_period_name: PropTypes.string,
        colors: PropTypes.array,
        dashboard_ids: PropTypes.array,
        // device_data_seuil_min_max: PropTypes.shape,
        // device_data_min_max: PropTypes.array,
        device_data_types: PropTypes.array,
        device_ids: PropTypes.array,
        from_timestamp: PropTypes.string,
        graph_id: PropTypes.string,
        group_id: PropTypes.string,
        is_last_value: PropTypes.bool,
        max_value: PropTypes.number,
        metric_text: PropTypes.string,
        min_value: PropTypes.number,
        name: PropTypes.string,
        start_offset_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        // threshold: PropTypes.shape,
        to_timestamp: PropTypes.string,
        type: PropTypes.string
    }).isRequired,
    showLegend: PropTypes.bool
};

export default GaugeGraph;

// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toLower } from 'lodash';
// Components
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
import { useFootBar } from '../../footbar/FootBarContext';
import { useModal } from '../../modal/ModalContext';
// Actions
import { setFocusedNavButton } from '../actions';
import { segmentPage } from '../../layout/helper';

const NavButton = props => {
    const { Component } = useComponentsPool();
    const footBar = useFootBar();
    const modal = useModal();
    const dispatch = useDispatch();

    return (
        <div
            role={'button'}
            tabIndex={0}
            className={classNames('button-container', props.focused ? '' : 'minimized')}
            onClick={e => {
                // If the user clicks on a view he's already on, we do nothing
                // To prevent the calendar view to switch from calendar (default view) to list when clicking on NavButton
                if (!window.location.pathname.includes(props.route)) {
                    segmentPage();
                    if (props.disabled) {
                        e.stopPropagation();
                        return false;
                    }
                    dispatch(setFocusedNavButton(toLower(props.route)));
                    modal.close();
                    footBar.close();
                    props.setCurrentRoute(toLower(props.route));
                    props.history.push(toLower(props.route));
                    return true;
                }
            }}
        >
            <BottomNavigationAction
                showLabel={props.focused}
                icon={<Component componentName={'Icon'} type={`${toLower(props.icon)}${toLower(props.route) === props.currentRoute ? 'Filled' : ''}`} />}
                label={props.label}
                disabled={props.disabled}
                className={'sidebar-navigation-item'}
            />
        </div>
    );
};

NavButton.propTypes = {
    currentRoute: PropTypes.string.isRequired,
    focused: PropTypes.bool.isRequired,
    icon: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    setCurrentRoute: PropTypes.func.isRequired
};

export default withRouter(NavButton);

// Libs
import React from 'react';
// Scss
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import AuthenticationBackground from '../layout/components/AuthenticationBackground';

import './style.scss';

export default () => {
    const [t] = useTranslation();

    return (
        <div className="content">
            <div className="step-wrapper">
                <div className={isMobile ? 'multi-workspaces-page-wrapper-mobile' : 'multi-workspaces-page-wrapper'}>
                    <>
                        <div className={'multi-workspaces-header'}>
                            <span className={'page-header welcome-back-title'}>
                                <h1>{t('common:welcome_text')}</h1>
                                <span role={'img'} aria-label={'party-emoji'}>🎉</span>
                            </span>
                        </div>
                        <div className={'multi-workspaces-footer'}>
                            <a href="https://www.merciyanis.com/connexion" className={'footer-link'}>{t('common:log_in')}</a>
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
};

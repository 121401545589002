// Libs
import React from 'react';
// Components
import store from './store';

const initState = {
    getComponentFile: name => {
        const coreState = store.getState().core;
        const file = coreState.components.list[name]?.file;
        if (file) {
            return require(`${file}`).default; // eslint-disable-line
        }
        throw new Error(`Unknown component ${name}`);
    },
    Component: props => {
        const coreState = store.getState().core;
        try {
            const file = coreState.components.list[props.componentName]?.file;
            if (file) {
                const Component = require(`${file}`).default; // eslint-disable-line
                return <Component {...props} />;
            }
        } catch (e) {
            if (props.componentName === undefined) {
                console.error(`Unknow component : ${props.componentName} hasn\'t been set`); // eslint-disable-line
            } else {
                console.error(`Unknow component : ${props.componentName} not found`); // eslint-disable-line
            }
            throw e;
        }
        return null;
    }
};

export const PoolContext = React.createContext(initState);

const ComponentsPoolProvider = ({ children }) => (
    <PoolContext.Provider value={initState}>
        {children}
    </PoolContext.Provider>
);

const useComponentsPool = () => {
    const context = React.useContext(PoolContext);
    if (context === undefined) throw new Error('useComponentsPool must be used within a PoolProvider');
    return context;
};

export { ComponentsPoolProvider, useComponentsPool };

// Libs
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { RRule } from 'rrule';
import { toast } from 'react-toastify';
import moment from 'moment';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
// Context
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Chip from '@material-ui/core/Chip';
import { useComponentsPool } from '../../../../ComponentsPool';
import { Toast } from '../../../notification/components';
import { useFootBar } from '../../../footbar/FootBarContext';
// Helpers
import {
    getByWeekDayFromSelectedDays, gettext, getWeekDays
} from '../../helper';
import { getTimeOnTwoDigits } from '../../../layout/helper';

moment.locale('fr');


const RoundRecurrence = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const footbar = useFootBar();
    const recurrenceOptions = [
        { id: 'WEEKLY', value: capitalize(gettext('weeks')) },
        { id: 'DAILY', value: capitalize(gettext('days')) }
    ];
    const [recurrence] = useState(recurrenceOptions.find(rec => rec.id === 'WEEKLY'));
    const weekDays = getWeekDays();
    const currentDayIdx = new Date().getDay();
    const currentDay = currentDayIdx === 0 ? weekDays[weekDays.length - 1] : weekDays[currentDayIdx - 1];
    const currentDayId = currentDay.id;
    const [selectedDays, setSelectedDays] = useState(props.selectedDays?.length !== 0
        ? props.selectedDays : [currentDay.value]);
    const [rruleWeekDay, setRruleWeekDay] = useState(props.selectedDays?.length !== 0
        ? getByWeekDayFromSelectedDays(props.selectedDays) : [RRule[currentDayId]]);
    const [rruleInterval, setRruleInterval] = useState(props.interval ? props.interval : '1');
    const [rrule, setRrule] = useState(null);
    const isInteger = value => value === parseInt(value, 10).toString();
    const [isInitialRruleForEdition, setIsInitialRruleForEdition] = useState(props.isEditionMode);

    useEffect(() => {
        if (rruleWeekDay.length > 0 && isInteger(rruleInterval)) {
            let roundRrule;
            if (props.untilDate) {
                roundRrule = new RRule({
                    freq: RRule[recurrence.id],
                    interval: parseInt(rruleInterval, 10),
                    byweekday: rruleWeekDay.sort(),
                    until: moment(props.untilDate).toISOString()
                });
            } else {
                roundRrule = new RRule({
                    freq: RRule[recurrence.id],
                    interval: parseInt(rruleInterval, 10),
                    byweekday: rruleWeekDay.sort()
                });
            }
            setRrule(roundRrule);
            if (!isInitialRruleForEdition) {
                props.changeState({ rrule: roundRrule.toString(), selectedDays, interval: rruleInterval });
            }
        } else {
            setRrule('');
            if (!isInitialRruleForEdition) props.changeState({ rrule: null });
        }
        setIsInitialRruleForEdition(false);
    }, [rruleWeekDay, rruleInterval, props.untilDate]);

    useEffect(() => {
        if (props.started_at && props.ended_at && props.ended_at === props.started_at) {
            toast(
                <Toast
                    message={t('notifications:hours_cannot_be_not_equal')}
                    icon={'frown'}
                    type={'error'}
                />, {
                    position: toast.POSITION.BOTTOM_LEFT,
                    className: 'normal',
                    bodyClassName: 'notification-body grow-font-size',
                    progressClassName: 'error-custom-progress-bar'
                }
            );
        }
    }, [props.started_at, props.ended_at]);

    useEffect(() => {
        if (!isInteger(rruleInterval)) {
            toast(
                <Toast
                    message={t('notifications:freq_must_be_integer')}
                    icon={'frown'}
                    type={'error'}
                />, {
                    position: toast.POSITION.BOTTOM_LEFT,
                    className: 'normal',
                    bodyClassName: 'notification-body grow-font-size',
                    progressClassName: 'error-custom-progress-bar'
                }
            );
        }
    }, [rruleInterval]);

    const changeSelectedDays = (e, dayToAddOrRemove) => {
        // get the selected days
        const selectedDaysCopy = [...selectedDays];
        if (!selectedDaysCopy.find(selectedDay => selectedDay === dayToAddOrRemove)) {
            selectedDaysCopy.push(dayToAddOrRemove);
        } else {
            selectedDaysCopy.splice(selectedDaysCopy.indexOf(dayToAddOrRemove), 1);
        }
        setSelectedDays(selectedDaysCopy);

        setRruleWeekDay(getByWeekDayFromSelectedDays(selectedDaysCopy));
    };

    return (
        <div className={classNames(['ticket-step', 'form-control', 'create-round-modal', props.isEditionMode ? 'round-edition-recurrence' : ''])}>
            <div className={footbar.editionMode ? 'recurrence-edition' : 'resource-infos'}>
                <div className={'round-modal-row-content'}>
                    <div className={'content-with-editable-field recurrence-choice'}>
                        <p className={'recurrence-every-text'}>{capitalize(gettext('every'))}</p>
                        <Component
                            title={''}
                            componentName={'EditableField'}
                            renderValue={'Input'}
                            editionComponent={'Input'}
                            onlyNumber
                            value={rruleInterval}
                            props={{
                                onChange: value => setRruleInterval(value),
                                inputProps: { min: 1, max: 53, step: 1 },
                                type: 'number',
                                value: rruleInterval,
                                error: true
                            }}
                        />
                        <span>{t('rounds:weeks')}</span>
                    </div>
                </div>
                <div className={'round-modal-row-content'}>
                    <div className={'content-with-editable-field time-choice'}>
                        <span className={'time-choice-text'}>{t('common:from_second')}</span>
                        <TimePicker
                            className={'hours-time-picker'}
                            timeSteps={{ hours: 1, minutes: 5 }}
                            ampm={false} // 24 hours clock
                            value={props.started_at && dayjs().hour(props.started_at.split(':')[0]).minute(props.started_at.split(':')[1])}
                            slotProps={{ textField: { placeholder: '--:--' }, actionBar: { sx: { display: 'none' } } }}
                            onChange={time => props.changeState({ started_at: time ? `${getTimeOnTwoDigits(time.$H)}:${getTimeOnTwoDigits(time.$m)}` : 'error-field' })}
                        />
                        <span className={'time-choice-text'}>{` ${t('common:to_second')}`}</span>
                        <TimePicker
                            className={'hours-time-picker'}
                            timeSteps={{ hours: 1, minutes: 5 }}
                            ampm={false} // 24 hours clock
                            value={props.ended_at && dayjs().hour(props.ended_at.split(':')[0]).minute(props.ended_at.split(':')[1])}
                            slotProps={{ textField: { placeholder: '--:--' }, actionBar: { sx: { display: 'none' } } }}
                            onChange={time => props.changeState({ ended_at: time ? `${getTimeOnTwoDigits(time.$H)}:${getTimeOnTwoDigits(time.$m)}` : 'error-field' })}
                        />
                    </div>
                    <div className={'weekday-choice'}>
                        {weekDays.map(weekDay => (
                            <Chip
                                key={weekDay.id}
                                clickable
                                classes={selectedDays.includes(weekDay.value)
                                    ? { root: 'weekday-chip selected-weekday-chip' }
                                    : { root: 'weekday-chip' }
                                }
                                value={weekDay.value}
                                onClick={e => {
                                    changeSelectedDays(e, weekDay.value);
                                }}
                                className={'group-name'}
                                component={'div'}
                                label={gettext(weekDay.value).charAt(0).toUpperCase()}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoundRecurrence;

// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// isMobile
import { isMobile } from 'react-device-detect';
import LinearProgress from '@material-ui/core/LinearProgress';
// Context
import { useTranslation } from 'react-i18next';
import { useComponentsPool } from '../../../ComponentsPool';
import Icon from '../../layout/components/Icon';
// Helper
import { getBluePalette } from '../../layout/helper';
// SCSS
import '../style.scss';
import { getUserWorkspacesFromToken } from '../../workspace/actions';
import createLoadingSelector from '../../layout/actions';

const loadingSelector = createLoadingSelector(['GET_USER_WORKSPACES_LIST']);
const MultiWorkspacesHomePage = () => {
    const [isInitialized, setIsInitialized] = useState(false);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const userWorkspacesList = useSelector(state => state.workspace.userWorkspacesList);
    const [bluePalette] = useState(getBluePalette());
    const authentication = useSelector(state => state.authentication);

    const redirectToWorkspace = workspaceName => {
        if (DOMAIN_NAME.includes('local.iot-rocket.space')) {
            window.location.href = `${GENERIC_APP_URL}/login?email=${userWorkspacesList.email}`;
        } else {
            window.location.href = `https://${workspaceName}.app.${DOMAIN_NAME}/login?email=${userWorkspacesList.email}`;
        }
    };

    useEffect(() => {
        if (userWorkspacesList?.accounts?.length === 0) {
            let token;
            window.location.href?.split('?').forEach(filter => {
                if (filter.includes('token=')) {
                    token = filter.split('token=')[1];
                }
            });
            if (token) {
                dispatch(getUserWorkspacesFromToken(token));
            }
        }
        setTimeout(() => { // Avoid loading page to render multiple time
            setIsInitialized(true);
        }, 2000);
    }, []);

    useEffect(() => {
        // If a user has only one workspace we redirect him to it
        if (userWorkspacesList.accounts.length === 1) {
            redirectToWorkspace(userWorkspacesList.accounts[0]);
        }
    }, [userWorkspacesList]);

    const getLoadingComponent = () => <Component componentName={'AppLoader'} />;

    const getErrorComponent = () => {
        if (authentication.isLogged) {
            return getLoadingComponent();
        }
        return (
            <div className="center-content-page">
                <Icon type="ErrorLaptop" />
                <h3>{t('workspaces:expired_magic_link') + ' 🤔'}</h3>
                <a href="https://www.merciyanis.com/connexion" className={'footer-link'}>{t('workspaces:reconnect')}</a>
            </div>
        );
    };

    const getRedirectionComponent = () => {
        return (
            <div className={'multi-workspaces-footer'}>
                <p className={'footer-text'}>{t('workspaces:workspace_doesnt_appear')}</p>
                <a href="https://www.merciyanis.com/connexion" className={'footer-link'}>{t('workspaces:try_another_mail_adress')}</a>
            </div>
        );
    };

    if (!isInitialized || isLoading) {
        return getLoadingComponent();
    }

    return (
        <div className={isMobile ? 'multi-workspaces-page-wrapper-mobile' : 'multi-workspaces-page-wrapper'}>
            {userWorkspacesList.accounts?.length > 0 ? (
                <>
                    <div className={'multi-workspaces-header'}>
                        <span className={'page-header welcome-back-title'}>
                            <h1>{t('workspaces:good_to_see_you_again')}</h1>
                            <span role={'img'} aria-label={'party-emoji'}>🎉</span>
                        </span>
                        <div className={'header-content'}>
                            <p className={'choose-workspace-text'}>{t('workspaces:choose_a_workspace_to_log_into')}</p>
                            <span className={'choose-workspace-subtext'}>
                                <span className={'workspace-for'}>{`${t('workspaces:workspaces_for')} `}</span>
                                <span className={'user-mail-text'}>
                                    {
                                        userWorkspacesList.email === 'merciyanis@iot-rocket.space'
                                            ? 'l\'équipe MerciYanis'
                                            : userWorkspacesList.email
                                    }
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className={'user-workspaces-wrapper'}>
                        {userWorkspacesList.accounts.map((workspace, index) => (
                            <Component
                                key={`${workspace}button`}
                                componentName={'Button'}
                                className={'workspace-item'}
                                backgroundColor={bluePalette[index]}
                                onClick={() => redirectToWorkspace(workspace)}
                                text={<span className={'workspace-name'}>{workspace}</span>}
                                endAdornment
                                icon={'rightArrow'}
                            />
                        ))}
                    </div>
                    {getRedirectionComponent()}
                </>
            ) : getErrorComponent() }
        </div>
    );
};

export default MultiWorkspacesHomePage;

// Libs
import update from 'immutability-helper';
import { cloneDeep, concat, filter, findIndex, groupBy, remove } from 'lodash';

const initialState = () => ({
    showEditForm: false,
    isVisible: false,
    defaultRoute: 'tickets',
    focusedNavButton: '/tickets',
    filters: [],
    filterGroups: [],
    workspaceSettingsPanel: false,
    notificationsList: [],
    filtersFunctions: {
        tickets: props => filter(props.list, resource => resource.id === props.id)
    }
});

let index;
let groupedIdx;
let newFilters;
let newList;
let newNotif;
const navbarReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'SET_FOCUSED_NAV_BUTTON':
            return update(state, { focusedNavButton: { $set: action.payload } });
        case 'SET_DEFAULT_ROUTE':
            return update(state, { defaultRoute: { $set: action.payload } });
        case 'SET_WORKSPACE_SETTINGS_PANEL':
            return update(state, { workspaceSettingsPanel: { $set: action.payload } });
        case 'ADD_FILTER':
            if (action.payload?.type === 'link') {
                return update(state, { filters: { $set: concat(action.payload, state.filters) } });
            }
            return update(state, { filters: { $set: concat(state.filters, action.payload) } });
        case 'REMOVE_FILTER':
            newFilters = state.filters.filter(currentFilter => currentFilter.id !== action.payload);
            return update(state, { filters: { $set: newFilters } });
        case 'RESET_FILTERS':
            return update(state, { filters: { $set: [] }, filterGroups: { $set: [] } });
        case 'GET_NOTIFICATIONS_FULFILLED':
            // Add sender_name on all notifs
            newList = action.payload.map(notification => ({
                ...notification,
                sender_name: notification.sender?.full_name || notification.object?.messaging_user_name
            }));
            // Group notifs by ticket's key
            newList = Object.values(groupBy(newList, notif => notif.object?.related_ticket?.key));
            return update(state, { notificationsList: { $set: newList } });
        case 'UPDATE_NOTIFICATION_FULFILLED':
            newNotif = action.payload;
            // Find the updated notif in the grouped notifs
            state.notificationsList.forEach((groupedNotif, groupedNotifIdx) => {
                if (findIndex(groupedNotif, notif => notif.id === newNotif.id) !== -1) {
                    index = findIndex(groupedNotif, notif => notif.id === newNotif.id);
                    groupedIdx = groupedNotifIdx;
                }
            });
            if (index !== -1 && groupedIdx !== -1) {
                // Add sender_name on the notif
                newNotif.sender_name = newNotif.sender?.full_name || newNotif.object?.messaging_user_name;
                return update(state, { notificationsList: { [groupedIdx]: { [index]: { $set: newNotif } } } });
            }
            return state;
        case 'NEW_WEBSOCKET_MESSAGE':
            if (action.payload.message_type === 'CREATE_NOTIFICATION') {
                newNotif = action.payload.message_data;
                // Add sender_name on the notif
                newNotif.sender_name = newNotif.sender?.full_name || newNotif.object?.messaging_user_name;

                // Check if the notif can be grouped based on the ticket key
                const groupIdx = state.notificationsList.findIndex(groupedNotif => groupedNotif[0].object.related_ticket.key === newNotif.object.related_ticket.key);
                newList = cloneDeep(state.notificationsList);
                if (groupIdx !== -1) { // The new notif is placed last in the list
                    newList[groupIdx].push(newNotif);
                } else {
                    newList.push([newNotif]);
                }
                return update(state, { notificationsList: { $set: newList } });
            }
            if (action.payload.message_type === 'UPDATE_NOTIFICATION') {
                newNotif = action.payload.message_data;
                // Find the updated notif in the grouped notifs
                state.notificationsList.forEach((groupedNotif, groupedNotifIdx) => {
                    if (findIndex(groupedNotif, notif => notif.id === newNotif.id) !== -1) {
                        index = findIndex(groupedNotif, notif => notif.id === newNotif.id);
                        groupedIdx = groupedNotifIdx;
                    }
                });
                if (index !== -1 && groupedIdx !== -1) {
                    // Add sender_name on the notif
                    newNotif.sender_name = newNotif.sender?.full_name || newNotif.object?.messaging_user_name;
                    return update(state, { notificationsList: { [groupedIdx]: { [index]: { $set: newNotif } } } });
                }
                return state;
            }
            if (action.payload.message_type === 'DELETE_NOTIFICATIONS') {
                newList = cloneDeep(state.notificationsList);
                const notifsToRemoveIds = action.payload.message_data;
                // Find the deleted notifs in the grouped notifs
                notifsToRemoveIds.forEach(notifToRemoveId => {
                    newList.forEach(groupedNotif => {
                        remove(groupedNotif, notif => notif.id === notifToRemoveId);
                    });
                });
                // Remove the empty arrays that might exists
                newList = newList.filter(groupedNotif => groupedNotif.length !== 0);
                return update(state, { notificationsList: { $set: newList } });
            }
            return state;
        case 'LOGOUT_FULFILLED':
            return initialState();
        default:
            return state;
    }
};

export default navbarReducer;

// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { concat, sortBy, reverse } from 'lodash';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';

import createLoadingSelector from '../../../layout/actions';

const loadingSelector = createLoadingSelector(['UPLOAD_COMMENT_DOCUMENT', 'GET_TICKET_SUMMARY', 'GET_TICKET_COMMENTS', 'GET_TICKET_OPERATIONS']);

const TicketDetailsPopupPartRight = props => {
    const { Component } = useComponentsPool();
    const ticket = useSelector(state => state.tickets.ticketShownInDetailsPopup);
    const [summary, setSummary] = useState([]);
    const [totalLogs, setTotalLogs] = useState(0);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));

    useEffect(() => {
        setSummary(reverse(sortBy(concat(ticket.summary, ticket.comments, ticket.operations), 'created_at')));
    }, [ticket.comments]);

    useEffect(() => {
        setTotalLogs(ticket.commentsTotalSummary + ticket.totalSummary + ticket.operationsTotalSummary);
    }, [ticket.commentsTotalSummary]);

    return (
        <>
            <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
            <Component
                componentName={'TicketLogs'}
                resourceId={ticket?.id}
                summary={summary}
                totalSummary={totalLogs}
                state={props.state}
                updateState={props.updateState}
            />
        </>
    );
};

export default TicketDetailsPopupPartRight;


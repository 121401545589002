// Libs
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
// Actions
import createLoadingSelector from '../../../layout/actions';
import { createTicket } from '../../actions';
// Helper
import { isValid } from '../../../layout/helper';
import { getGroupList } from '../../../groups/actions';
import Step0 from './TicketCreatePopupSteps/step0';
import Step1 from './TicketCreatePopupSteps/step1';
import Step2 from './TicketCreatePopupSteps/step2';

import { useModal } from '../../../modal/ModalContext';

const loadingSelector = createLoadingSelector(['CREATE_TICKET']);

const CreateTicketPopup = () => {
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const floorMode = workspaceSettings.mode_floor ? workspaceSettings.mode_floor : 'close';
    const positionMode = workspaceSettings.mode_position ? workspaceSettings.mode_position : 'close';
    const titleMode = workspaceSettings.mode_title ? workspaceSettings.mode_title : 'close';
    const currentUser = useSelector(state => state.users.currentUser);
    const currentUserRole = currentUser.workspace_role?.workspace_role_name;
    const dispatch = useDispatch();
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const modal = useModal();
    const hasRightToSeeAssignersField = workspaceSettings.is_contractor_rights_on_assignment || false;
    const hasRightToSeePriorityField = workspaceSettings.is_contractor_rights_on_priority || false;
    const userHasRightToSeePriorityField = workspaceSettings.is_user_rights_on_priority || false;
    const hasRightToSeeLastStep = currentUserRole === 'Admin'
        || currentUserRole === 'Owner'
        || currentUserRole === 'Contractor' && (hasRightToSeeAssignersField || hasRightToSeePriorityField)
        || currentUserRole === 'User' && userHasRightToSeePriorityField;

    const [ticketData, setTicketData] = useState({
        title: null,
        comment: null,
        urls: [],
        fileType: null,
        assigners: currentUserRole === 'Contractor' ? [currentUser.id] : [],
        priority: 0,
        author: currentUser?.id,
        position_label: null,
        floor_label: '',
        batiment_group_id: null,
        skill_group_id: null,
        position_group_id: null,
        floor_group_id: null
    });

    const [currentStep, setCurrentStep] = useState(0);
    const [firstStepDisabled, setFirstStepDisabled] = useState(true);
    const [secondStepDisabled, setSecondStepDisabled] = useState(true);

    const required_fields = workspaceSettings.required_fields ? workspaceSettings.required_fields : '';

    useEffect(() => {
        modal.setItem({
            title: ticketData.title,
            comment: ticketData.comment,
            urls: ticketData.urls,
            fileType: ticketData.fileType,
            assigners: ticketData.assigners,
            priority: ticketData.priority,
            author: currentUser?.id,
            custom_field: {
                position_label: ticketData,
                floor_label: ticketData?.floor_label,
                batiment_group_id: ticketData?.batiment_group_id,
                skill_group_id: ticketData?.skill_group_id,
                position_group_id: ticketData?.position_group_id,
                floor_group_id: ticketData?.floor_group_id
            }
        });
    }, []);

    useEffect(() => {
        const {
            title, batiment_group_id, floor_group_id, floor_label, position_group_id, position_label, skill_group_id
        } = ticketData;

        switch (currentStep) {
            case 0:
                let floor = floorMode === 'close' ? floor_group_id : floor_label?.replace(/\s/g, '');
                let position = positionMode === 'close' ? position_group_id : position_label?.replace(/\s/g, '');
                setFirstStepDisabled(
                    (!batiment_group_id) ||
                    (required_fields.includes('floor') && !floor) ||
                    (required_fields.includes('position') && !position)
                );
                break;
            case 1:
                let isNotEmptyTitle = titleMode === 'close' ? title : title?.replace(/\s/g, '');
                setSecondStepDisabled(isValid(title) || !skill_group_id || !isNotEmptyTitle);
                break;
            default:
                break;
        }
    }, [ticketData]);

    const changeState = newState => {
        const newTicketData = { ...ticketData, ...newState };

        // reset linked fields
        if (titleMode === 'close' && newState.skill_group_id && newTicketData.title) {
            delete newTicketData['title'];
        }
        if ((newState?.batiment_group_id || newState?.batiment_group_id === '') &&
            (newTicketData.floor_group_id || newTicketData.floor_group_id === '')
        ) {
            delete newTicketData['floor_group_id'];
            if (newTicketData.position_group_id) {
                delete newTicketData['position_group_id'];
            }
        }
        if ((newState.floor_group_id || newState.floor_group_id === '') &&
            newTicketData.position_group_id
        ) {
            delete newTicketData['position_group_id']
        }

        if (newState.floor_label === 'error_field') {
            delete newTicketData['floor_label'];
        }
        if (newState.position_label === 'error_field') {
            delete newTicketData['position_label'];
        }
        setTicketData(newTicketData);

        modal.setItem({
            title: newTicketData.title?.trim(),
            comment: newTicketData.comment?.trim() === '' ? '' : newTicketData.comment,
            urls: newTicketData.urls,
            fileType: 'MERCIYANIS_MESSAGE',
            assigners: newTicketData.assigners,
            priority: newTicketData.priority,
            author: currentUser?.id,
            custom_field: {
                position_label: newTicketData?.position_label?.trim(),
                floor_label: newTicketData?.floor_label?.trim(),
                batiment_group_id: newTicketData?.batiment_group_id,
                skill_group_id: newTicketData?.skill_group_id,
                position_group_id: newTicketData?.position_group_id,
                floor_group_id: newTicketData?.floor_group_id
            }
        });
    };

    const handlePreviousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleNextStep = async () => {
        switch (currentStep) {
            case 0:
                setCurrentStep(1);
                break;
            case 1:
                if (hasRightToSeeLastStep) {
                    setCurrentStep(2);
                } else {
                    await dispatch(createTicket(modal.getItem())).then(() => {
                        dispatch(getGroupList());
                    });
                    modal.close();
                }
                break;
            case 2:
                await dispatch(createTicket(modal.getItem())).then(() => {
                    dispatch(getGroupList());
                });
                modal.close();
                break;
            default:
                break;
        }
    };

    const steps = [{
        head: (
            <React.Fragment>
                <span className={'title-ticket-create-popup'}>{t('tickets:create_ticket_title_1', {user: currentUser?.first_name})}</span>
                <Typography variant={'subtitle2'}>{t('tickets:create_ticket_step1')}</Typography>
            </React.Fragment>
        ),
        disabled: firstStepDisabled,
        content: <Step0 changeState={changeState} {...ticketData} />,
        submitButtonLabelTitle: 'common:next_step',
        contentWidth: '700px'
    }, {
        head: (
            <React.Fragment>
                <span className={'title-ticket-create-popup'}>{t('tickets:create_ticket_title_2')}</span>
                <Typography variant={'subtitle2'}>{t('tickets:create_ticket_step2')}</Typography>
            </React.Fragment>
        ),
        disabled: secondStepDisabled,
        content: <Step1 changeState={changeState} {...ticketData} />,
        submitButtonLabelTitle: hasRightToSeeLastStep ? 'common:next_step' : 'common:save',
        contentWidth: '700px'
    }, {
        head: (
            <React.Fragment>
                <span className={'title-ticket-create-popup'}>{t('tickets:create_ticket_title_3')}</span>
                <Typography variant={'subtitle2'}>{t('tickets:create_ticket_step3')}</Typography>
            </React.Fragment>
        ),
        disabled: false,
        content: <Step2 changeState={changeState} {...ticketData} />,
        submitButtonLabelTitle: 'common:save',
        contentWidth: '700px'
    }];


    return (
        <Component
            componentName={'Modal'}
            modalContainer={'creation-ticket'}
            loading={isLoading}
            head={steps[currentStep].head}
            content={steps[currentStep].content}
            disabledStep={steps[currentStep].disabled}
            steps={steps.length}
            currentStep={currentStep}
            classNames={currentStep === 1 ? 'ticket-create-popup ticket-create-popup-step1' : 'ticket-create-popup'}
            contentWidth={steps[currentStep].contentWidth}
            submitButtonLabel={steps[currentStep].submitButtonLabelTitle}
            onHandleNextStep={handleNextStep}
            onHandlePreviousStep={handlePreviousStep}
            onHandleSubmit={() => {
                modal.close();
            }}
            noOverflow
        />
    );
};

export default withRouter(CreateTicketPopup);




import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import { fr } from 'react-date-range/dist/locale';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Toast } from '../../../../notification/components';
import { useComponentsPool } from '../../../../../ComponentsPool';
import './DayPicker.scss';
import { isOneDayTimeSlot } from '../../../../layout/helper';
import Button from '../../../../layout/components/Button';

const DaysPicker = ({ startDate, untilDate, onChange, started_at, ended_at }) => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const [showPicker, setShowPicker] = useState('');
    const onDateChange = (e, fieldName) => {
        setShowPicker('');
        onChange(e, fieldName);
    };
    const showDatePicker = datePickerName => {
        // If it is not an invalid date
        if (isFinite(datePickerName === 'startDatePicker' ? startDate : untilDate)) {
            setShowPicker(datePickerName);
        }
    };

    const displayStartDate = moment(startDate).format('DD/MM/YYYY');
    const displayUntilDate = untilDate ? moment(untilDate).format('DD/MM/YYYY') : '_ _ /_ _ /_ _ _ _';

    useEffect(() => {
        if (startDate && untilDate && moment(startDate).set({ h: 0, m: 0, s: 0 }).isAfter(moment(untilDate).set({ h: 0, m: 0, s: 59 }))) {
            toast(
                <Toast
                    message={t('notifications:start_date_cannot_be_greater_than_end_date')}
                    icon={'frown'}
                    type={'error'}
                />, {
                    position: toast.POSITION.BOTTOM_LEFT,
                    className: 'normal',
                    bodyClassName: 'notification-body grow-font-size',
                    progressClassName: 'error-custom-progress-bar'
                }
            );
        }
    }, [startDate, untilDate]);

    const [showButton, setShowButton] = useState(true);

    const handleCloseEndDate = () => {
        setShowButton(true);
        onChange(null, 'untilDate');
    };

    return (
        <div className="start-end-days-picker">
            <div className="day-label-picker">
                <span>
                    {t('rounds:start_date')}
                </span>
                <span>&nbsp;</span>
                <span>
                    {displayStartDate}
                </span>
                {/* if the calendar is already open, when we click on the icon we close it */}
                <IconButton onClick={() => (showPicker === 'startDatePicker' ? setShowPicker('') : showDatePicker('startDatePicker'))}>
                    <Component componentName="Icon" type="event" />
                </IconButton>
                {showPicker === 'startDatePicker' && (
                    untilDate ? (
                        <Calendar
                            className="day-picker"
                            // the user cannot select a start date greater than the until date
                            // + if the time slot is on 2 day he cannot select a start date equal to the until date
                            maxDate={isOneDayTimeSlot(started_at, ended_at) ? untilDate : moment(untilDate).subtract(1, 'days').toDate()}
                            date={startDate}
                            locale={fr}
                            onChange={e => onDateChange(e, 'startDate')}
                        />
                    ) : (
                        <Calendar
                            className="day-picker"
                            date={startDate}
                            locale={fr}
                            onChange={e => onDateChange(e, 'startDate')}
                        />
                    )
                )}
            </div>
            <div className="day-label-picker">
                {showButton
                    ? <Button onClick={() => setShowButton(false)} text={t('rounds:add_end_date')} className="close" />
                    : (
                        <>
                            <span>
                                {t('rounds:end_date')}
                            </span>
                            <span>&nbsp;</span>
                            <span className={untilDate ? 'chosen-end-date' : 'default-end-date'}>
                                {displayUntilDate}
                                {untilDate && (
                                    <Component
                                        componentName={'Button'}
                                        onClick={handleCloseEndDate}
                                        text={'x'}
                                    />
                                )}
                            </span>
                            {/* if the calendar is already open, when we click on the icon we close it */}
                            <IconButton onClick={() => (showPicker === 'untilDatePicker' ? setShowPicker('') : showDatePicker('untilDatePicker'))}>
                                <Component componentName="Icon" type="event" />
                            </IconButton>
                            {showPicker === 'untilDatePicker' && (
                                <Calendar
                                    className="day-picker"
                                    // the user cannot select an until date lower than the start date
                                    // + if the time slot is on 2 day he cannot select an until date equal to the start date
                                    minDate={isOneDayTimeSlot(started_at, ended_at) ? startDate : moment(startDate).add(1, 'days').toDate()}
                                    date={untilDate}
                                    locale={fr}
                                    onChange={e => onDateChange(e, 'untilDate')}
                                />
                            )}
                        </>
                    )
                }
            </div>
        </div>

    );
};

export default DaysPicker;

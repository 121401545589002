/* eslint-disable no-console */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Websocket from 'react-websocket';
import { isMobile } from 'react-device-detect';

import { newWebsocketMessage } from './actions';
import {EVENT_WEBSOCKET_CONNECTION_LOST, segmentTrack} from '../layout/helper';
import { getTickets } from '../tickets/actions';

export default () => {
    const dispatch = useDispatch();
    const wsUrl = localStorage.getItem('iot_rocket_websocket_url');
    const currentUser = useSelector(state => state.users.currentUser);
    const filter_admin_on_skills = useSelector(state => state.workspace.settings.filter_admin_on_skills);
    const filter_user_on_skills = useSelector(state => state.workspace.settings.filter_user_on_skills);
    const user_tickets_mode = useSelector(state => state.workspace.settings.user_tickets_mode);
    const contractor_tickets_mode = useSelector(state => state.workspace.settings.contractor_tickets_mode);
    const groupList = useSelector(state => state.groups.list);
    const ticketsActiveMode = useSelector(state => state.tickets.list.find(category => category?.id === 'ARCHIVED')?.isVisible === false);

    if (!wsUrl) return null;
    if (currentUser && currentUser.id) {
        return (
            <>
                <Websocket
                    url={wsUrl}
                    onMessage={msg => {
                        const data = JSON.parse(msg);
                        dispatch(newWebsocketMessage({
                            message_type: data.message_type,
                            message_data: JSON.parse(
                                data.message_data
                            ),
                            message_author: data.message_author
                        }, currentUser, filter_admin_on_skills, filter_user_on_skills, user_tickets_mode, groupList, contractor_tickets_mode));
                    }}
                    onOpen={() => console.log('Connection to workspace websocket successfull')}
                    onClose={() => {
                        segmentTrack(EVENT_WEBSOCKET_CONNECTION_LOST, { userMail: currentUser.email });
                        console.error('Connection to workspace websocket lost');
                        if ((localStorage.getItem('iot_rocket_workspace') === 'elise'
                            || localStorage.getItem('iot_rocket_workspace') === 'nxp')
                            && !isMobile) {
                            dispatch(getTickets(ticketsActiveMode, 'my_tickets'));
                        }
                    }}
                />
                <Websocket
                    url={wsUrl.concat(currentUser.id, '/')}
                    onMessage={msg => {
                        const data = JSON.parse(msg);
                        dispatch(newWebsocketMessage({
                            message_type: data.message_type,
                            message_data: JSON.parse(
                                data.message_data
                            ),
                            message_author: data.message_author
                        }, currentUser, filter_admin_on_skills, filter_user_on_skills, user_tickets_mode, groupList, contractor_tickets_mode));
                    }}
                    onOpen={() => console.log('Connection to notification websocket successfull')}
                    onClose={() => console.error('Connection to notification websocket lost')}
                />
            </>
        );
    }
    return (
        <Websocket
            url={wsUrl}
            onMessage={msg => {
                const data = JSON.parse(msg);
                dispatch(newWebsocketMessage({
                    message_type: data.message_type,
                    message_data: JSON.parse(
                        data.message_data
                    ),
                    message_author: data.message_author
                }, currentUser, filter_admin_on_skills, filter_user_on_skills, user_tickets_mode, groupList, contractor_tickets_mode));
            }}
            onOpen={() => console.log('Connection to workspace websocket successfull')}
            onClose={() => console.error('Connection to workspace websocket lost')}
        />
    );
};

import React from 'react';

export default <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
>
    <g>
        <g>
            <path d="M501.801,466.153H61.144l55.608-61.873c4.883,2.589,10.446,4.062,16.348,4.062c13.586,0,25.382-7.781,31.188-19.115
                l56.818,14.347c1.503,17.938,16.574,32.078,34.895,32.078c19.314,0,35.027-15.713,35.027-35.027
                c0-10.651-4.784-20.201-12.31-26.63l46.335-110.741l32.997,84.143c-7.887,6.429-12.936,16.213-12.936,27.157
                c0,19.314,15.714,35.027,35.028,35.027s35.027-15.713,35.027-35.027c0-3.946-0.666-7.738-1.875-11.28l40.474-40.476
                c3.983-3.983,3.983-10.441,0-14.425c-3.983-3.982-10.441-3.982-14.424,0l-38.182,38.182c-5.859-4.411-13.141-7.029-21.023-7.029
                c-1.082,0-2.151,0.056-3.209,0.153l-40.571-103.457l40.757-97.409c0.997,0.086,2.003,0.139,3.022,0.139
                c16.219,0,29.893-11.081,33.861-26.07h64.21c5.633,0,10.199-4.567,10.199-10.199c0-5.632-4.566-10.199-10.199-10.199h-64.971
                c-4.753-13.712-17.79-23.588-33.099-23.588c-19.314,0-35.028,15.713-35.028,35.028c0,11.011,5.112,20.845,13.081,27.272
                l-32.53,77.746l-47.52-121.177c7.14-6.417,11.641-15.717,11.641-26.051c0-19.315-15.714-35.028-35.028-35.028
                S219.73,42.399,219.73,61.714c0,8.356,2.946,16.036,7.847,22.063l-84.08,127.394c-3.644-1.288-7.561-1.996-11.64-1.996
                c-4.185,0-8.199,0.74-11.922,2.092l-72.226-95.035V12.062c0-5.632-4.566-10.199-10.199-10.199S27.31,6.43,27.31,12.062v100.287
                H10.199C4.566,112.348,0,116.916,0,122.548c0,5.632,4.566,10.199,10.199,10.199h17.112v102.502H10.199
                C4.566,235.249,0,239.816,0,245.448c0,5.632,4.566,10.199,10.199,10.199h17.112v104.986H10.199C4.566,360.633,0,365.2,0,370.832
                c0,5.632,4.566,10.199,10.199,10.199h17.112v95.321c0,5.632,4.566,10.199,10.199,10.199h87.873v13.387
                c0,5.632,4.566,10.199,10.199,10.199c5.633,0,10.199-4.567,10.199-10.199v-13.387h102.502v13.387
                c0,5.632,4.566,10.199,10.199,10.199s10.199-4.567,10.199-10.199v-13.387h104.984v13.387c0,5.632,4.566,10.199,10.199,10.199
                s10.199-4.567,10.199-10.199v-13.387h107.735c5.633,0,10.199-4.567,10.199-10.199S507.434,466.153,501.801,466.153z
                 M380.143,359.926c8.065,0,14.629,6.563,14.629,14.63c0,8.065-6.563,14.629-14.629,14.629c-8.067,0-14.63-6.563-14.63-14.629
                C365.513,366.489,372.076,359.926,380.143,359.926z M380.142,89.297c8.067,0,14.629,6.563,14.629,14.63
                c0,8.067-6.563,14.63-14.629,14.63c-8.067,0-14.63-6.563-14.63-14.63C365.512,95.86,372.075,89.297,380.142,89.297z
                 M133.1,387.943c-8.067,0-14.629-6.563-14.629-14.63c0-8.066,6.563-14.629,14.629-14.629s14.629,6.563,14.629,14.629
                C147.728,381.381,141.166,387.943,133.1,387.943z M256,415.254c-8.067,0-14.629-6.563-14.629-14.629
                c0-8.067,6.563-14.63,14.629-14.63s14.629,6.563,14.629,14.63C270.629,408.691,264.067,415.254,256,415.254z M254.759,47.089
                c8.067,0,14.63,6.563,14.63,14.63s-6.563,14.63-14.63,14.63s-14.629-6.563-14.629-14.63S246.692,47.089,254.759,47.089z
                 M131.857,229.578c8.068,0,14.63,6.562,14.63,14.629s-6.563,14.63-14.63,14.63s-14.629-6.563-14.629-14.63
                C117.229,236.141,123.792,229.578,131.857,229.578z M47.71,149.948l55.91,73.566c-4.263,5.802-6.789,12.957-6.789,20.692
                c0,19.315,15.713,35.028,35.027,35.028c19.314,0,35.028-15.713,35.028-35.028c0-7.832-2.584-15.071-6.945-20.911l84.539-128.09
                c3.252,1,6.703,1.541,10.278,1.541c1.65,0,3.272-0.123,4.862-0.345l54.735,139.574l-54.336,129.86
                c-1.32-0.151-2.66-0.238-4.02-0.238c-13.145,0-24.617,7.282-30.607,18.021l-57.53-14.528
                c-2.093-17.328-16.88-30.805-34.764-30.805c-19.314,0-35.027,15.713-35.027,35.027c0,5.995,1.516,11.642,4.182,16.581
                L47.71,450.583V149.948z"
            />
        </g>
    </g>
    <g>
        <g>
            <path d="M488.529,272.375c-3.983-3.983-10.441-3.983-14.424,0l-8.07,8.07c-3.983,3.984-3.983,10.441,0,14.425
                c1.992,1.991,4.602,2.987,7.212,2.987c2.61,0,5.221-0.996,7.212-2.987l8.07-8.07C492.511,282.816,492.511,276.359,488.529,272.375
                z"
            />
        </g>
    </g>
               </svg>;

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

// Component
import LoginForm from './components/LogInForm';
import SignUpInvitationForm from './components/SignUpForm';
import AccountActivation from './components/AccountActivation';
import ResetPasswordFrom from './components/ResetPasswordFrom';
import ForgetPasswordForm from './components/ForgetPasswordForm';
import AuthenticationBackground from '../layout/components/AuthenticationBackground';
import Workspace from '../workspace';
import MultiWorkspacesHomePage from '../workspaces/components/MultiWorkspacesHomePage';
import SsoRedirection from '../cryptr/components/SsoRedirection';
import QRCodeRouter from '../qrcodes';

// SCSS
import './style.authentication.scss';
import WorkspaceCreationPage from '../workspaces/components/WorkspaceCreationPage';
import NotFound from '../core/components/NotFound';


export default () => {
    const authState = useSelector(state => state.authentication);
    if (authState.jwt) return <Redirect push to="/" />;

    const getSwitchComponent = () => (
        <div className="content">
            <div className="step-wrapper">
                <Switch>
                    <Route exact path="/login" component={LoginForm} />
                    <Route exact path="/not-found" component={NotFound} />
                    <Route path="/login/error/:error" component={LoginForm} />
                    <Route path="/login/:token" component={LoginForm} />
                    <Route path="/forgetpassword" component={ForgetPasswordForm} />
                    <Route path="/account-activation" component={AccountActivation} />
                    <Route path="/reset-password" component={ResetPasswordFrom} />
                    <Route path="/register" component={SignUpInvitationForm} />
                    <Route path="/workspace" component={Workspace} />
                    <Route path="/workspaces" component={MultiWorkspacesHomePage} />
                    <Route path="/create-workspace-GHBVTUU6" component={WorkspaceCreationPage} />
                    <Route path="/sso" component={SsoRedirection} />
                    {isMobile && <Route path="/passages/:hardware_id" component={QRCodeRouter} />}
                    {isMobile && <Route path="/qrcodes/:hardware_id" component={QRCodeRouter} />}
                    <Route component={LoginForm} />
                </Switch>
            </div>
        </div>
    );
    return (
        <AuthenticationBackground fullWidth={window.location.pathname.includes('workspaces')}>
            { getSwitchComponent() }
        </AuthenticationBackground>
    );
};

import React from 'react';

const initState = {
    isVisible: false,
    selected: [], // List of array Ids
    list: [], // list array of object
    type: '',
    isEdited: false,
    editionObject: {},
    editionMode: false,
    editionObjectId: '', // ID of the resource being edited (ex. user.id, device.device_id, etc ...). Used to call the edit methods
    getEditionObject: () => {},
    getEditionMode: () => {},
    getEditionObjectId: () => {},
    getList: () => {},
    getItem: () => {},
    set: () => {},
    close: () => {},
    update: () => {}
};
export const FootBarStateContext = React.createContext(initState);
export const FootBarDispatchContext = React.createContext(null);

const footBarReducer = (state, action) => {
    switch (action.type) {
        case 'update': {
            return { ...state, ...action.payload };
        }
        default: { throw new Error(`Unhandled action type: ${action.type}`); }
    }
};

const findItem = (props, list) => {
    if (list.length !== 0) {
        if (props === undefined) return list[0];
        return list[0][props];
    }
    return [];
};

const checkIsVisible = (paramSelected, stateSelected) => {
    if (paramSelected === undefined && stateSelected.length > 0) return true;
    return paramSelected !== undefined && paramSelected.length > 0; //  && stateSelected.length > 0
};

const FootBarProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(footBarReducer, initState);

    const setFootBar = payload => {
        dispatch({ type: 'update', payload });
    };

    const getters = {
        getIsVisible: () => state.isVisible,
        getSelected: () => state.selected,
        getItem: props => findItem(props, state.list),
        getList: () => state.list,
        getEditionMode: () => state.editionMode,
        getEditionObject: () => state.editionObject,
        getEditionObjectId: () => state.editionObjectId
    };

    const setters = {
        setItem: item => setFootBar({ ...state, item }),
        close: () => setFootBar(initState),
        update: param => setFootBar({ ...param, isVisible: checkIsVisible(param.selected, state.selected), item: param.item || {} }), // eslint-disable-line
        set: param => setFootBar({ ...state, ...param })
    };

    return (
        <FootBarStateContext.Provider value={{ ...state, ...getters, ...setters }}>
            <FootBarDispatchContext.Provider value={dispatch}>
                {children}
            </FootBarDispatchContext.Provider>
        </FootBarStateContext.Provider>
    );
};

const useFootBar = () => {
    const context = React.useContext(FootBarStateContext);
    if (context === undefined) {
        throw new Error('useFootBar must be used within a FootBarProvider');
    }
    return context;
};

const useFootBarDispatch = () => {
    const context = React.useContext(FootBarDispatchContext);
    if (context === undefined) {
        throw new Error('useFootBarDispatch must be used within a FootBarProvider');
    }
    return context;
};

export { FootBarProvider, useFootBar, useFootBarDispatch };

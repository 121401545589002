// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Context
import { useModal } from '../../../modal/ModalContext';
// Helper
import { getDeviceFromId } from '../../helper';
import { getId, getValue } from '../../../layout/helper';

const DeviceMaxSelectedJobs = props => {
    const [t] = useTranslation();
    const modal = useModal();
    const devices = useSelector(state => state.devices.list);
    const device = getDeviceFromId(devices, getId(modal.getItem()));
    const value = getValue(props.value, device);

    return (
        <p className={'info-value'}>
            {value?.toString() === '1' || !value ? t('devices:SINGLE_JOB') : t('devices:MULTIPLE_JOBS')}
        </p>
    );
};

export default DeviceMaxSelectedJobs;

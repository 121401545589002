// Libs
import React from 'react';
import moment from 'moment';

const RoundArchivingDate = props => {
    const archivingDate = props.resource.deleted_at;
    return <span>{moment(archivingDate).format('[Le] DD/MM/YYYY [à] HH[h]mm')}</span>;
};

export default RoundArchivingDate;

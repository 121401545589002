// Libs
import React, { useState } from 'react';
// Context
import { useComponentsPool } from '../../ComponentsPool';
// Scss
import './style.scss';
import { BUILDINGS_LIST, JOBS_LIST, SKILLS_LIST, TEST_LIST } from './components/SettingViewSwitcher';

export default () => {
  const { Component } = useComponentsPool();

  const [currentList, setCurrentList] = useState(BUILDINGS_LIST);

  const getTableComponent = () => {
    if (currentList === BUILDINGS_LIST) return <Component componentName={'BuildingsTable'} />;
    if (currentList === SKILLS_LIST) return <Component componentName={'SkillsTable'} />;
    if (currentList === JOBS_LIST) return <Component componentName={'JobsTable'} />;
    return <div />;
  };

  const iframe = document.querySelector('iframe');

// Wait for the iframe to load
iframe.onload = () => {
  const token = "your-secure-token"; // Replace with your actual token
  iframe.contentWindow.postMessage(
    { type: "AUTH_TOKEN", token: token },
    "http://localhost:3000" // Replace with your iframe's URL
  );
};

console.log("localStorage.getItem('iot_rocket_access_JWT')", localStorage.getItem('iot_rocket_access_JWT'))
  return (
    <div className={'settings-view-wrapper'}>
        <iframe
          id="inlineFrameExample"
          title="Inline Frame Example"
          width="700"
          height="500"
          src={`http://localhost:5010?token=${localStorage.getItem('iot_rocket_access_JWT')}`}
        >
        </iframe>
     
    </div>
  );
};

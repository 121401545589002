// Libs
import React from 'react';
import { useSelector } from 'react-redux';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import createLoadingSelector from '../../layout/actions';
import useUpdateForm from "../../layout/forms/UseUpdateForm";
import {sortBy} from "lodash";
// Helper

const loadingSelector = createLoadingSelector(['EDIT_GROUP']);
const GroupDetailsPopup = props => {
    const { Component } = useComponentsPool();
    const groups = useSelector(state => state.groups);
    const group = useSelector(state => state.groups.groupShownInDetailsPopup);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const editableFields = useSelector(state => sortBy(state.groups.editableFields, 'weight'));

    const moreFunction = state => () => ({
        ...state
    });
    const { updateState, state } = useUpdateForm([...editableFields, { props: { value: 'group_name' } }], group, 'group_id', moreFunction);


    const content = groups.detailPopupParts.map(part => ({
        width: part.width,
        content: <Component
            componentName={part.component}
            item={props.item}
            updateState={(resource, value) => updateState(resource, value)}
            state={state}
        />
    }));

    return (
        <Component
            componentName={'Modal'}
            loading={isLoading}
            content={content}
            contentWidth={'100%'}
            classNames={'details-popup'}
        />
    );
};

export default GroupDetailsPopup;

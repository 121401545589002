import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const CompanyLogo = ({ logo }) => {
    const FALLBACK_LOGO = 'https://res.cloudinary.com/dcprlxu6c/image/upload/v1548927942/logo_merciyanis_blanc.svg';

    const logoUrl = useMemo(() => (!!logo ? logo : FALLBACK_LOGO), [logo]);

    return <img className="company_logo" src={logoUrl} alt="MerciYanis" />;
};

CompanyLogo.defaultProps = {
    logo: undefined
};

CompanyLogo.propTypes = {
    logo: PropTypes.string
};

export default memo(CompanyLogo);

// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';

const UserWorkspaceRoleEditableField = props => {
    const [t] = useTranslation();
    return (
        <div className="info">
            <span className={'info-title'}>{t('common:workspace_role')}</span>
            <p className={'info-value'}>{t(`common:${props.user.workspace_role?.workspace_role_name?.toLowerCase()}`)}</p>
        </div>
    );
};

export default UserWorkspaceRoleEditableField;

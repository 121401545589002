import axios from 'axios';
import moment from 'moment';
import { groupBy, isEmpty } from 'lodash';
import {
    EVENT_CREATE_ROUND, EVENT_EXPORT_INSTANCE, EVENT_EXPORT_ROUND, EVENT_LONG_DURATION, EVENT_MODIFY_ROUND, segmentTrack
} from '../layout/helper';
import { getDeviceFromId } from '../devices/helper';
import { getApiCallsStatus, getIntParamValueFromRrule, getStringParamValueFromRrule } from './helper';
import { BATCH_API_CALL_STATUS } from "./constants";
import {
    failedAllRoundsArchiveNotif,
    partialSuccessfulRoundsArchiveNotif,
    successfulAllRoundsArchiveNotif
} from "../notification/actions";

export const createRound = (data, roundView, devicesList, userRole) => {
    let nbRoundDevicesByType = groupBy(data.devices.map(device => getDeviceFromId(devicesList, device.id)), device => device?.custom_field?.type);
    nbRoundDevicesByType = Object.entries(nbRoundDevicesByType).map(devices => {
        if (devices.length >= 1) {
            const key = devices[0];
            const object = {};
            object[key] = devices[1].length;
            return object;
        }
    });

    const startedAt = moment(data.started_at);
    const endedAt = moment(data.ended_at);
    // Variable for  segment to know who uses the first monthly option instead of is_until_end_of_month
    const isMoreThanOrEqualTo24Hours = endedAt.diff(startedAt, 'hours', true) >= 24;
    const isLessThanAMonth = endedAt.diff(startedAt, 'months', true) < 1;
    const isNotUntilEndOfMonthOption = isMoreThanOrEqualTo24Hours && isLessThanAMonth
    && endedAt.date() === endedAt.daysInMonth() && endedAt.date() !== 31
    && !data.is_until_end_of_month && getStringParamValueFromRrule(data.rrule?.replace('RRULE:', ''), 'FREQ=') === 'MONTHLY';

    // Retrieve every rrule params
    const rruleString = data.rrule.replace('RRULE:', '');
    const freq = getStringParamValueFromRrule(rruleString, 'FREQ='); // Ex: FREQ=DAILY
    const interval = getIntParamValueFromRrule(rruleString, 'INTERVAL='); // Ex: INTERVAL=1
    const count = getIntParamValueFromRrule(rruleString, 'COUNT='); // Ex: COUNT=3
    const until = getStringParamValueFromRrule(rruleString, 'UNTIL='); // Ex: UNTIL=20250303T150059Z
    // Get byday as an array, for example : ['MO', 'TU']
    const byday = getStringParamValueFromRrule(rruleString, 'BYDAY=')?.split(','); // Ex: BYDAY=MO,TU,WE,TH,FR
    // Get bymonthday as an array, for example : [12, 18]
    const bymonthday = getStringParamValueFromRrule(rruleString, 'BYMONTHDAY=')?.split(',').map(monthday => parseInt(monthday, 10)); // Ex: BYMONTHDAY=18,12
    const bymonth = getStringParamValueFromRrule(rruleString, 'BYMONTH=')?.split(',').map(monthnum => parseInt(monthnum, 10)); // Ex: BYMONTH=1,4
    const bysetpos = getIntParamValueFromRrule(rruleString, 'BYSETPOS='); // Ex: BYSETPOS=-1

    return {
        type: userRole === 'Owner' ? 'CREATE_ROUND_CUSTOM_ERRORS' : 'CREATE_ROUND',
        payload: axios.post('/rounds/', { ...data })
            .then(res => {
                const durationInDays = moment(data.ended_at).diff(moment(data.started_at), 'days');
                if (durationInDays > 363) {
                    segmentTrack(EVENT_LONG_DURATION, { startedAt: data.started_at, endedAt: data.ended_at, durationInDays });
                }
                segmentTrack(EVENT_CREATE_ROUND, {
                    roundId: res.data.id,
                    roundView,
                    roundRrule: data.rrule,
                    roundRruleObject: {
                        freq, interval, count, until, byday, bymonthday, bymonth, bysetpos
                    },
                    isUntilEndOfMonth: data.is_until_end_of_month,
                    isEndingTheThirtyOrTwentyNineOption: isNotUntilEndOfMonthOption,
                    nbRoundDevicesByType,
                    validationCount: data.devices[0]?.validation_count
                });
                return res.data;
            })
    };
};

export const addRoundsCsvFile = csv_file => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('rounds', csv_file);
    return {
        type: 'ADD_ROUNDS_CSV_FILE',
        payload: axios.post('/rounds/save-rounds-from-file', formData, config)
            .then(res => res.data)
    };
};

export const getRounds = active => ({
    type: 'GET_ROUNDS',
    payload: axios.get(`/rounds?active=${active}`)
        .then(res => {
            return {
                data: res.data,
                active
            };
        })
});

export const editRound = (id, data, oldRoundState, userRole, isEndingTheThirtyOrTwentyNineOption) => {
    const formattedData = { ...data };
    const oldModifiedFieldsValues = {};
    // Retrieve the old state of fields that were modified
    Object.entries(oldRoundState).forEach(element => {
        if (Object.keys(data).includes(element[0])) {
            oldModifiedFieldsValues[`${element[0]}`] = element[1];
        }
    });
    return {
        type: userRole === 'Owner' ? 'EDIT_ROUND_CUSTOM_ERRORS' : 'EDIT_ROUND',
        payload: axios.patch(`/rounds/${id}/`, formattedData)
            .then(res => {
                const durationInDays = moment(data.ended_at).diff(moment(data.started_at), 'days');
                if (durationInDays > 363) {
                    segmentTrack(EVENT_LONG_DURATION, { startedAt: data.started_at, endedAt: data.ended_at, durationInDays });
                }
                segmentTrack(EVENT_MODIFY_ROUND, {
                    roundId: res.data.id,
                    newFieldsValues: data,
                    oldFieldsValues: oldModifiedFieldsValues,
                    isEndingTheThirtyOrTwentyNineOption
                });
                return res.data;
            })
    };
};

export const setRoundShownInDetailPopup = id => ({
    type: 'SET_ROUND_DETAIL',
    payload: axios.get(`/rounds/${id}/`)
        .then(res => res.data)
});

export const getRoundDataExport = (id, isArchivedRound) => {
    segmentTrack(EVENT_EXPORT_ROUND, { roundId: id, isArchivedRound });
    return {
        type: 'GET_ROUND_DATA_EXPORT',
        payload: axios.get(`/rounds/instances?round_id=${id}&action=export`)
            .then(res => res.data)
    };
};

export const getRoundInstanceDataExport = (instance, roundView) => {
    const instancesData = [{ instanceId: instance.id, instanceStatus: instance.status, roundId: instance.round }];
    segmentTrack(EVENT_EXPORT_INSTANCE, { instancesData, roundView });
    return {
        type: 'GET_ROUND_DATA_EXPORT',
        payload: axios.get(`/rounds/instances/${instancesData[0].instanceId}?round_id=${instancesData[0].roundId}&action=export`)
            .then(res => res.data)
    };
};

export const getRoundInstance = (instanceId, roundId) => ({
    type: 'GET_ROUND_INSTANCE',
    payload: axios.get(`/rounds/instances/${instanceId}?round_id=${roundId}`)
        .then(res => res.data)
});

export const getRoundInstances = (
    id, page = 1, pageSize = 20, from_started_at = null, to_started_at = null
) => {
    let filters = `?round_id=${id}&page=${page}&page_size=${pageSize}&status=INCOMPLETE,COMPLETE,IN_PROGRESS`;
    if (from_started_at) filters = `${filters}&from_date=${from_started_at}`;
    if (to_started_at) filters = `${filters}&to_date=${to_started_at}`;
    return {
        type: 'GET_ROUND_INSTANCES',
        payload: axios.get(`/rounds/instances${filters}`)
            .then(res => res.data)
    };
};

export const getInstancesFromTo = (from, to) => {
    const fromDate = `from_date=${from}`;
    const toDate = `to_date=${to}`;
    const filters = `?${fromDate}&${toDate}&page_size=15000`;
    return {
        type: 'GET_ROUND_INSTANCES',
        payload: axios.get(`/rounds/instances/${filters}`).then((res) => res.data)
    };
};

export const getDailyRoundsInstancesByStatus = (dailyOnly, status, refreshStore = true) => {
    let filterStatus;
    let filterDate;
    switch (status) {
        case 'FINISHED':
            filterStatus = 'COMPLETE,INCOMPLETE';
            filterDate = '-status,-ended_at';
            break;
        case 'NOT_STARTED':
            filterStatus = status;
            filterDate = 'started_at';
            break;
        case 'IN_PROGRESS':
        default:
            filterStatus = status;
            filterDate = 'ended_at';
            break;
    }
    return {
        type: refreshStore ? 'GET_DAILY_ROUNDS_INSTANCES' : 'NO_REDUCER',
        payload: axios.get(`/rounds/instances/?daily_only=${dailyOnly}&status=${filterStatus}&ordering=${filterDate}`)
            .then(res => ({
                data: res.data,
                status
            }))
    };
};

export const deleteRound = id => ({
    type: 'DELETE_ROUND',
    payload: axios.delete(`/rounds/${id}/`)
        .then(res => ({
            data: res.data,
            id
        }))
});

export const archiveRound = id => ({
    type: 'ARCHIVE_ROUND',
    payload: axios.patch(`/rounds/${id}/`, { active: false })
        .then(res => res.data)
});

export const updateActiveRoundsWithArchived = (archivedRounds) => {
    return {
        type: 'UPDATE_ACTIVE_ROUNDS_WITH_ARCHIVED',
        payload: archivedRounds
    };
};

export const archiveMultipleRounds = (roundsIDs) => {
    return (dispatch) => {
        dispatch(updateRoundsLoadingStatus(true));
        Promise.allSettled(roundsIDs.map((id) => axios.patch(`/rounds/${id}/`, { active: false })))
            .then((responses) => {
                const successfulApiCalls = responses
                    .filter((result) => result.status === 'fulfilled')
                    .map((result) => result?.value?.data);

                dispatch(updateActiveRoundsWithArchived(successfulApiCalls)); // list of archived rounds from API
                const apiCallsStatus = getApiCallsStatus(successfulApiCalls.length, responses.length);

                if (apiCallsStatus === BATCH_API_CALL_STATUS.ALL_FULFILLED) dispatch(successfulAllRoundsArchiveNotif());
                if (apiCallsStatus === BATCH_API_CALL_STATUS.PARTIAL_FULFILLED) dispatch(partialSuccessfulRoundsArchiveNotif());
                if (apiCallsStatus === BATCH_API_CALL_STATUS.ALL_REJECTED) dispatch(failedAllRoundsArchiveNotif());
            })
            .finally(() => dispatch(updateRoundsLoadingStatus(false)));
    };
};

export const getInstanceEventsList = (instance, isMobile = false, currentInstanceDateTime = null) => ({
    type: 'GET_INSTANCE_EVENTS_LIST',
    payload: axios.get(`/rounds/instances/${instance.id}/events/?round_id=${instance.round}&isMobile=${isMobile}`)
        .then(res => ({
            data: res.data,
            id: instance.id,
            currentInstanceDateTime,
            roundId: instance.round,
            instance
        }))
});

export const editInstanceEvent = (validationConditionId, passage, data) => ({
    type: 'EDIT_INSTANCE_EVENT',
    payload: axios
        .patch(`/rounds/validation-conditions/${validationConditionId}/events/${passage.event_id}/`, data)
        .catch(error => Promise.reject(error))
});

export const createInstanceEvent = (validationConditionId, data) => ({
    type: 'CREATE_INSTANCE_EVENT',
    payload: axios
        .post(`/rounds/validation-conditions/${validationConditionId}/events/`, data)
        .catch(error => Promise.reject(error))
});

export const updateRoundInstanceEventsList = eventsList => ({
    type: 'UPDATE_INSTANCE_EVENTS_LIST',
    payload: eventsList
});

export const postInstanceComments = (instance_id, text_content, uploaded_content_url, author_id) => {
    let payload = {
        author_id
    };
    if (!isEmpty(text_content)) {
        payload = { ...payload, text_content };
    }
    if (!isEmpty(uploaded_content_url)) {
        payload = { ...payload, uploaded_content_url };
    }
    return {
        type: 'POST_INSTANCE_COMMENTS',
        payload: axios.post(`rounds/instances/comments/?round_instance_id=${instance_id}`, payload)
            .then(res => res.data)
    };
};

export const deleteInstanceComments = round_instance_comment_id => {
    return {
        type: 'DELETE_INSTANCE_COMMENTS',
        payload: axios.delete(`rounds/instances/comments/${round_instance_comment_id}/`)
            .then(() => round_instance_comment_id)
    };
};

export const emptyInstanceEventsList = () => ({ type: 'EMPTY_INSTANCE_EVENTS_LIST' });

export const exportInstances = (instancesData, roundView) => {
    segmentTrack(EVENT_EXPORT_INSTANCE, { instancesData, roundView });
    const instance_ids = instancesData?.map(instance => instance.instanceId);
    return {
        type: 'EXPORT_INSTANCES',
        payload: axios.post('/rounds/instances/?action=export', { instance_ids })
            .then(res => res.data)
    };
};

export const deleteInstance = instance_id => ({
    type: 'DELETE_INSTANCE',
    payload: axios.delete(`/rounds/instances/${instance_id}`)
});

export const sendRoundNotifLink = (notified_users_ids, link) => ({
    type: 'SEND_ROUND_NOTIF_LINK',
    payload: axios.post('mails/?type=ROUND_NOTIF_LINK', { notified_users_ids, link })
        .then(res => res.data)
});

export const updateInstance = (instance_id, data) => ({
    type: 'UPDATE_INSTANCE',
    payload: axios.patch(`/rounds/instances/${instance_id}/`, data)
        .then(res => res.data)
});

export const updateRoundsLoadingStatus = (loadingStatus) => ({
    type: 'UPDATE_ROUNDS_LOADING_STATUS',
    payload: loadingStatus
});

export const updateInstanceStatus = (instanceId, status) => ({
    type: 'UPDATE_INSTANCE_STATUS',
    payload: { instanceId, status }
});


import axios from 'axios';

export const getDocuments = () => ({
    type: 'GET_DOCUMENT_LIST',
    payload: axios.get('/documents/')
        .then(res => res.data)
});

export const getDocumentById = documentId => ({
    type: 'GET_DOCUMENT_BY_ID',
    payload: axios.get(`/documents/${documentId}`)
        .then(res => res.data)
});

export const uploadDocument = file => {
    const formData = new FormData();
    formData.append('media', file);
    return {
        type: 'UPLOAD_DOCUMENT',
        payload: axios.post('/documents/upload-document', formData)
            .then(res => res.data)
    };
};

export const createDocument = file => ({
    type: 'CREATE_DOCUMENT',
    payload: axios.post('/documents/', file)
        .then(res => res.data)
});

export const editDocument = (docId, document) => ({
    type: 'EDIT_DOCUMENT',
    payload: axios.patch(`/documents/${docId}`, document)
        .then(res => res.data)
});

export const deleteDocument = documentId => ({
    type: 'DELETE_DOCUMENT',
    payload: axios.delete(`/documents/${documentId}/`).then(() => documentId)
});

// Libs
import React from 'react';
import classnames from 'classnames';
// Context
import UserGroups from '../TableComponents/UserGroups';

const UserGroupsEditableField = props => (
    <div className={classnames('info', props.multiline ? 'multiline-input' : '')}>
        <span className={'info-title'}>{props.title}</span>
        <UserGroups resource={props.user} groupType={props.groupType} />
    </div>
);

export default UserGroupsEditableField;

import axios from 'axios';

export const getDashboardList = () => ({
    type: 'GET_DASHBOARD_LIST',
    payload: axios.get('/dashboards/')
        .then(res => res.data)
});

export const createDashboard = name => ({
    type: 'CREATE_DASHBOARD',
    payload: axios.post('/dashboards/', { name })
        .then(res => res.data)
});

export const editDashboard = (dashboard_id, name) => ({
    type: 'EDIT_DASHBOARD',
    payload: axios.patch(`/dashboards/${dashboard_id}/`, { name })
        .then(res => res.data)
});

export const deleteDashboard = data => ({
    type: data.dashboard_ids.length > 1 ? 'DELETE_DASHBOARDS' : 'DELETE_DASHBOARD',
    payload: axios.delete('/dashboards/', { data })
        .then(res => (res.data.nb_dashboards_deleted === data.dashboard_ids.length ? data.dashboard_ids : ''))
});

export const updateDashboardSettings = (dashboard_id, layout) => ({
    type: 'UPDATE_DASHBOARD_SETTINGS',
    payload: axios.patch(`/dashboards/${dashboard_id}/`, { settings: layout })
        .then(res => res.data)
});

export const addDashboardFootbarButtons = button => ({ type: 'ADD_DASHBOARD_FOOTBAR_BUTTON', payload: button });

export const removeDashboardFootbarButton = button => ({ type: 'REMOVE_DASHBOARD_FOOTBAR_BUTTON', payload: button });


export const getTicketsDashboards = (timeSelection = null, from_timestamp = null, to_timestamp = null) => ({
    type: 'GET_TICKETS_DASHBOARDS',
    payload: axios.post('/dashboards/get_tickets_dashboards',
        { timeSelection, from_timestamp, to_timestamp }
    )
        .then(res => ({ data: res.data }))
        .catch(error => error)
});

export const getTicketsExport = (timeSelection = null, from_timestamp = null, to_timestamp = null) => ({
    type: 'GET_TICKETS_EXPORT',
    payload: axios.post('/dashboards/get_tickets_export', { timeSelection, from_timestamp, to_timestamp })
        .then(res => ({ data: res.data }))
        .catch(error => error)
});

export const getMetabaseUrl = () => ({
    type: 'GET_METABASE_URL',
    payload: axios.get('/dashboards/get_metabase_dashboard?dashboard_type=TICKET', {})
        .then(res => ({ data: res.data }))
});

export const getMetabaseUrlFromId = metabase_id => ({
    type: 'GET_METABASE_URL_FROM_ID',
    payload: axios.get(`/dashboards/get_metabase_dashboard?dashboard_type=TICKET&metabase_id=${metabase_id}`, {})
        .then(res => ({ data: res.data }))
});

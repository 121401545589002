import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    isArray, merge, isEqual
} from 'lodash';
import flatten, { unflatten } from 'flat';
import moment from 'moment';
import { deepNotEqual, isValid, objectHasProperty } from '../helper';
import { useFormState } from './api/useFormState';
import { useFootBar } from '../../footbar/FootBarContext';
import {
    areEqualsRoundsDevices,
    areEqualsRoundsEndedAt, areEqualsRoundsStartedAt,
    areEqualsRoundsRrules, areEqualsRoundsUsers,
    getRoundFromId, areEqualsRoundsJobs
} from '../../rounds/helper';

const useUpdateForm = (editableFields, store, id, moreFunction) => {
    const footbar = useFootBar();
    const { setFormState } = useFormState();

    const arrayToObjectEditableField = (state, array, value) => {
        const stateFlatten = flatten(state);
        let object = {};
        for (let i = array.length - 1; i > -1; i = i - 1) {
            if (array.length - 1 === i) {
                object = { [array[i]]: value || stateFlatten[array.join('.')] || '' };
            } else {
                object = { [array[i]]: object };
            }
        }
        return object;
    };

    const buildEditableField = (fields, data) => {
        let state = {};
        fields?.forEach(field => {
            if (field.props?.value !== undefined) {
                const nameField = field.props?.value;
                if (isArray(nameField)) {
                    state = merge(state, arrayToObjectEditableField(data, nameField));
                } else {
                    state = merge(state, { [nameField]: data[nameField] === null || data[nameField] === undefined ? '' : data[nameField] });
                }
            } else {
                // state = { state, field.renderValue };
            }
        });
        return state;
    };

    const [initialState, setInitialState] = useState({ ...buildEditableField(editableFields, store), [id]: store[id] });
    const [state, setState] = useState(initialState);
    const [isEdited, setIsEdited] = useState(false);
    const roundsList = useSelector(reduxState => reduxState.rounds.list);

    const updateState = (field, value) => {
        // Everytime we update a field, we put it in the editionObject
        if (Array.isArray(field)) {
            setState(prevState => {
                const newState = flatten(prevState);
                newState[field.join('.')] = value;
                return unflatten(newState);
            });
        } else setState(prevState => ({ ...prevState, [field]: value }));
    };

    useEffect(() => {
        setInitialState({ ...buildEditableField(editableFields, store), [id]: store[id] });
    }, [store]);

    useEffect(() => {
        setIsEdited(deepNotEqual(initialState, state));
        if (moreFunction) setFormState(moreFunction(state));
        else setFormState(state);
    }, [state, initialState, footbar.editionObject]);

    useEffect(() => {
        footbar.update({ isEdited });
    }, [isEdited]);

    useEffect(() => {
        if (footbar.editionMode) return;
        setState(initialState);
    }, [footbar.editionMode]);

    return {
        arrayToObjectEditableField,
        buildEditableField,
        updateState,
        initialState,
        state,
        setState
    };
};

export default useUpdateForm;

// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isNull, isEmpty } from 'lodash';
import classNames from 'classnames';
// Components
import { FormHelperText } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
// Styles
import '../styles/_select.scss';
import inputValidation from '../formValidation';
import { getTraductionOfGroup } from '../../groups/helper';
import { useComponentsPool } from '../../../ComponentsPool';

const CLASSES = {
    root: 'root',
    chip: 'chip'
};
const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 8;


const SelectIotRocket = props => {
    const useStyles = makeStyles(() => ({
        root: {
            maxWidth: 'fit-content'
        }
    }));

    const classes = useStyles();
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: !props.customWidth && 250
            }
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
        },
        getContentAnchorEl: null
    };
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const [selected, setSelected] = useState(props.selected); // eslint-disable-line
    const [renderValue, setRenderValue] = useState(props.renderValue !== undefined || props.multiple ? props.renderValue : '');
    const [showSelectAll] = useState(props.multiple && props.showSelectAll || false);
    const [errors, setErrors] = useState([]);
    // MORE OPTIONS
    const [isPlaceHolderToOption] = useState(props.isPlaceHolderToOption || false); // View placeholder in option list
    const [isCheckboxToOption] = useState(props.isCheckboxToOption || false); // View checkbox in option list
    const [chipRenderLimit] = useState(props.chipRenderLimit);

    let selectAllLabelProps = '';
    if (showSelectAll) {
        selectAllLabelProps = props.options.length !== selected.length || selected.length === 0
            ? t('common:select_all', { count: props.options.length })
            : t('common:unselect_all', { count: selected.length });
    }
    const [selectAllLabel, setSelectAllLabel] = useState(selectAllLabelProps);

    useEffect(() => {
        if (selected) {
            setSelectAllLabel(selectAllLabelProps);
        }
    }, [selected]);

    useEffect(() => {
        if (props.changeSelectValue) setSelected(props.changeSelectValue);
    }, [props.changeSelectValue]);

    useEffect(() => {
        if (props.emptyChip) {
            setSelected(props.emptyChip)
        }
    }, [props.emptyChip]);

    // disableRule based on useEffect and not onChange
    useEffect(() => {
        if (props.disableRule) {
            setErrors([]);
        }
    }, [props.disableRule]);

    useEffect(() => {
        if (props.emptySelect) {
            setSelected({
                "value": '',
                "id": ''
            });
            if (props.required || (props.rules && props.rules.includes('selectNotEmpty'))) {
                props.onChange({
                    "value": 'error_field',
                    "id": 'error_field'
                });
                setErrors({ message: 'not_empty' });
            } else {
                props.onChange({
                    "value": '',
                    "id": ''
                });
                setErrors([]);
            }
        }
    }, [props.emptySelect]);

    useEffect(() => {
        setRenderValue(props.renderValue);
    }, [props.renderValue]);

    const deleteChip = chip => {
        const chipData = [...selected];
        const index = selected.findIndex(item => item.id === chip);
        chipData.splice(index, 1);
        props.onChange(chipData);
        setSelected(chipData);
    };

    let RenderValueComponent = () => {
        if (props.multiple) {
            return selected.map(item => <span key={item.id}>{`${item.value}, `}</span>);
        }
        if (!isNull(selected) && selected !== undefined && !isEmpty(selected)) {
            return <span key={selected.id}>{selected.value}</span>;
        }
        return null;
    };

    if (renderValue === 'chip' && props.multiple) {
        let option = {};
        RenderValueComponent = () => {
            if (props.renderChipsWithIcons && chipRenderLimit) {
                return (
                    <>
                        {selected.slice(0, chipRenderLimit).map(
                            item => {
                                const traduced_group_name = getTraductionOfGroup(item.value, t);
                                return (
                                    <Chip
                                        component={'div'}
                                        icon={<Component componentName={'Icon'} type={item.value} />}
                                        key={item.id}
                                        label={traduced_group_name}
                                        className={CLASSES.chip}
                                    />
                                );
                        })}
                        {selected.length > chipRenderLimit && (
                            <Component componentName={'Popover'} openOnHover buttonLabel={`+${selected.length - chipRenderLimit}`} fabButton>
                                <div className={'config-group-popover'}>
                                    {selected.slice(chipRenderLimit, selected.length)
                                        .map(chipToDisplay => (
                                            <Chip
                                                component={'div'}
                                                icon={<Component componentName={'Icon'} type={chipToDisplay.value} />}
                                                key={chipToDisplay.id}
                                                label={getTraductionOfGroup(chipToDisplay.value, t)}
                                                className={CLASSES.chip}
                                            />
                                        ))
                                    }
                                </div>
                            </Component>
                        )}
                    </>
                );
            } else if (selected.length > chipRenderLimit) {
                if (!selected.isDisable) {
                    // TODO delete if possible only with AutoComplement MUI
                    option = { ...option, onDelete: () => setSelected({
                        "value": '',
                        "id": ''
                    }) };
                }
                return (
                    <Chip
                        component={'div'}
                        key={`+${selected.length} ${t('common:selected')}`}
                        label={`+${selected.length} ${t('common:selected')}`}
                        className={CLASSES.chip}
                    />
                );
            }
            return (
                <>
                    {selected.map(item => {
                        if (!item.isDisable) {
                            option = { ...option, onDelete: () => deleteChip(item.id) };
                        }
                        let traduced_group_name = getTraductionOfGroup(item.value, t);
                        return (
                            <Chip
                                component={'div'}
                                key={item.id}
                                label={item.hasEmojiAsIcon ? `${item.hasEmojiAsIcon} ${traduced_group_name}` : traduced_group_name}
                                className={CLASSES.chip}
                            />
                        );
                    })}
                </>
            );
        };
    }

    const alreadyInSelectedText = items => {
        const count = items.length - 2;
        const string = items.slice(0, 2).map(item => item.value);
        let label = `${t('common:already_there')} ${string}`;
        if (count >= 1) {
            label = `${label} ${t('common:and')} ${count} ${t('common:other')}.`;
        }
        return label;
    };

    const handleChange = select => {
        const error = [];
        if (select === []) {
            props.onChange([]);
            setSelected({
                "value": '',
                "id": ''
            });
            return;
        }
        const { target: { value }, currentTarget } = select;
        let all = [];
        if (!props.multiple) {
            all = JSON.parse(currentTarget.getAttribute('data'));
        } else if (value[value.length - 1] === 'select_all' && selected.length !== props.options.length) {
            all = props.options ? props.options : [];
        } else if (value[value.length - 1] === 'select_all' && selected.length === props.options.length) {
            all = props.options.filter(item => item.isDisable);
        } else {
            const data = JSON.parse(currentTarget.getAttribute('data'));
            all = [...selected];
            const index = selected.findIndex(item => item.id === data.id);
            if (index === -1) {
                all.push(data);
            } else {
                all.splice(index, 1);
            }
        }
        const rules = props.rules || [];
        if (props.required) rules.push('isRequired');
        if (!isEmpty(rules)) {
            rules?.forEach(rule => {
                const errorMessage = inputValidation(rule, all, props.list);
                if (errorMessage !== null) {
                    error.push(errorMessage);
                }
            });
        }
        setSelected(all);
        if (error.length > 0) {
            props.onChange('error_field');
            setErrors(error[0]);
        } else {
            props.onChange(all);
            setErrors([]);
        }

    };

    let value;
    if (props.multiple) {
        value = selected.length > 0 ? selected.map(item => item.id) : [];
    } else {
        value = isNull(selected) || selected.id === undefined ? '' : selected.id;
    }

    return (
        <FormControl
            required={props.required && props.placeholder && props.placeholder !== ''}
            className={classNames([props.className, 'iot-rocket-select'])}
            component={'div'}
        >
            <div className={'MuiFormControl-root MuiTextField-root iot-rocket-input MuiFormControl-marginNormal'}>
                <InputLabel htmlFor="select-label">
                    {t(props.placeHolder)}
                </InputLabel>
                <Select
                    disabled={props.disabled}
                    autoFocus={props.autoFocus}
                    name={'select-label'}
                    id={'select-label'}
                    value={value}
                    onChange={handleChange}
                    renderValue={RenderValueComponent}
                    input={<Input id="select-multiple" inputProps={{ required: props.required && props.placeholder !== ''}} />}
                    MenuProps={MenuProps}
                    multiple={props.multiple}
                    displayEmpty
                    variant={'standard'}
                >
                    {!props.multiple && !props.required && !isEmpty(props.options) && (
                        <MenuItem
                            className={`select-option none-option ${isEmpty(selected) ? 'Mui-selected' : ''}`}
                            value={''}
                            component={'div'}
                            data={JSON.stringify({
                                "value": '',
                                "id": ''
                            })}
                        >
                            <ListItemText primary={t('common:none')} />
                        </MenuItem>
                    )}
                    {isPlaceHolderToOption
                        && <MenuItem disabled value="" component={'div'}><em>{t(props.placeHolder)}</em></MenuItem>}
                    {showSelectAll && props.multiple && props.options.length !== 0
                        && <MenuItem key={'select_all'} value={'select_all'} component={'div'}>{selectAllLabel}</MenuItem>}
                    {props.options.length === 0
                        && <MenuItem value={''} disabled component={'div'}>{t('empty')}</MenuItem>}
                    {props.options.map(option => (
                        option.category ? (
                            <MenuItem key={option.category} disabled component={'div'}>
                                <span className={'group-option'}><em>{option.category}</em></span>
                            </MenuItem>
                        ) : (
                            <MenuItem
                                disabled={option.isDisable}
                                className={classNames(['select-option', props.className?.includes('select-priority') ? props.className : ''])}
                                component={'div'}
                                key={option.id}
                                data={JSON.stringify(option)}
                                value={option.id}
                            >
                                {isCheckboxToOption && (
                                    <Checkbox
                                        checked={selected.findIndex(select => select.id === option.id) > -1}
                                    />
                                )}
                                <ListItemText className={option.hasHighlightedText ? classes.root : ''} primary={option.value} />
                                {option.hasHighlightedText
                                    && <div className={'select-item-text-in-chip'}>{t('users:invitation_sent')}</div>
                                }
                            </MenuItem>
                        )
                    ))}
                </Select>
                {props.isSelectedToText.length > 0 && (
                    <FormHelperText>
                        <Tooltip
                            title={props.isSelectedToText.map(item => <p key={item.id}>{item.value}</p>)}
                        >
                            <span className="devices-already-in-group">
                                {alreadyInSelectedText(props.isSelectedToText)}
                            </span>
                        </Tooltip>
                    </FormHelperText>
                )}
                { !isEmpty(errors)
                    && <FormHelperText error>{t(`errors:${errors.message}`, { count: errors.count })}</FormHelperText>
                }
            </div>
        </FormControl>
    );
};

SelectIotRocket.defaultProps = {
    chipRenderLimit: 2,
    emptySelect: null,
    isCheckboxToOption: false,
    isPlaceHolderToOption: false,
    isSelectedToText: [],
    multiple: false,
    renderValue: '',
    required: false,
    selected: [],
    showSelectAll: false,
    placeHolder: ''
};

SelectIotRocket.propTypes = {
    chipRenderLimit: PropTypes.number,
    emptySelect: PropTypes.bool,
    isCheckboxToOption: PropTypes.bool,
    isPlaceHolderToOption: PropTypes.bool,
    isSelectedToText: PropTypes.array,
    multiple: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        category: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        type: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })).isRequired,
    placeHolder: PropTypes.string,
    renderValue: PropTypes.string,
    required: PropTypes.bool,
    selected: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })).isRequired,
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        }).isRequired,
        PropTypes.string
    ]),
    showSelectAll: PropTypes.bool
};

export default SelectIotRocket;

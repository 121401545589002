import axios from 'axios';

const getTemplateList = () => ({
    type: 'GET_TEMPLATE_LIST',
    payload: axios
        .get('/templates/')
        .then(res => res.data)
        .catch(error => {
            throw new Error(error);
        })
});

export default getTemplateList;

// Libs
import React, { forwardRef } from 'react';

export default forwardRef((_, ref) => (ref.current !== null ? (
    <svg
        preserveAspectRatio="xMinYMin meet"
        style={{
            width: '100px', height: '90px', transform: `translate(${ref.current.parentElement.clientWidth / 2 - 50}px, ${ref.current.parentElement.clientHeight / 2 - 90}px)`
        }}
    >
        <defs>
            <linearGradient id="logo-gradient">
                <stop offset="0.589428" stopColor="#e0e0e0" stopOpacity="1"><animate attributeName="offset" values="-2; -2; 1" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite" /></stop>
                <stop offset="1.58943" stopColor="#ffffff" stopOpacity="1"><animate attributeName="offset" values="-1; -1; 2" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite" /></stop>
                <stop offset="2.58943" stopColor="#e0e0e0" stopOpacity="1"><animate attributeName="offset" values="0; 0; 3" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite" /></stop>
            </linearGradient>
        </defs>
        <g style={{ transform: 'scale(0.2)' }}>
            <path
                fill="url('#logo-gradient')"
                d="M332.8 320h38.4c6.4 0 12.8-6.4 12.8-12.8V172.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-288 0h38.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zM496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"
            />
        </g>
    </svg>
) : <div />));

import update from 'immutability-helper';
import { findIndex } from 'lodash';

const initialState = () => ({
    list: []
});

let index;
const documentsReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'GET_DOCUMENT_LIST_FULFILLED':
            return update(state, { list: { $set: action.payload.results } });
        case 'CREATE_DOCUMENT_FULFILLED':
            return update(state, { list: { $push: [action.payload] } });
        case 'EDIT_DOCUMENT_FULFILLED':
            index = findIndex(state.list, item => item.id === action.payload.id);
            return update(state, { list: { [index]: { $set: action.payload } } });
        case 'DELETE_DOCUMENT_FULFILLED':
            index = findIndex(state.list, item => item.id === action.payload);
            return update(state, { list: { $splice: [[index, 1]] } });
        default:
            return state;
    }
};

export default documentsReducer;

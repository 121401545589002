// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// Context
import { useComponentsPool } from '../../../ComponentsPool';

const FootbarButton = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();

    const getComponent = () => {
        if (props.withConfirmationPopover) {
            return (
                <Component
                    componentName={'ConfirmationPopover'}
                    popoverText={props.popoverText}
                    customPopoverPosition={props.customPopoverPosition}
                    noArrow={props.noArrow}
                    cancelButtonLabel={props.cancelButtonLabel || !props.secondConfirmationButtonLabel && t('common:do_not_delete')}
                    confirmationButtonLabel={props.confirmationButtonLabel || t('common:yes_delete')}
                    secondConfirmationButtonLabel={props.secondConfirmationButtonLabel}
                    confirmationButtonAction={props.callbackAction}
                    secondConfirmationButtonAction={props.secondCallbackAction}
                    setIsOpenPopover={props.setIsOpenPopover}
                    customStyle={props.customStyle}
                    actionButton={(
                        <Component
                            componentName={'Button'}
                            onClick={() => {}}
                            startAdornment
                            icon={props.buttonIcon}
                            text={props.buttonText}
                            variant={!props.secondConfirmationButtonLabel && 'outlined'}
                            className={'confirmation-button'}
                        />
                    )}
                />
            );
        } if (props.withTimeSlotsPopover) {
            return (
                <Component
                    componentName={'TimeSlotsPopover'}
                    popoverText={props.popoverText}
                    cancelButtonLabel={props.cancelButtonLabel || t('common:cancel')}
                    confirmationButtonLabel={props.confirmationButtonLabel || t('common:validate')}
                    confirmationButtonAction={props.callbackAction}
                    withExportType={props.withExportType}
                    actionButton={(
                        <Component
                            componentName={'Button'}
                            onClick={() => {}}
                            startAdornment
                            icon={props.buttonIcon}
                            text={props.buttonText}
                            variant={'outlined'}
                            className={'confirmation-button'}
                        />
                    )}
                />
            );
        }
        return (
            <Component
                componentName={'Button'}
                onClick={props.callbackAction}
                startAdornment
                icon={props.buttonIcon}
                text={props.buttonText}
                variant={'outlined'}
                className={'confirmation-button'}
            />
        );
    }

    return props.displayCondition && (
        <>
            {getComponent()}
        </>
    );
};

FootbarButton.defaultProps = {
    displayCondition: false,
    confirmationButtonLabel: null,
    cancelButtonLabel: null,
    popoverText: null
};

FootbarButton.propTypes = {
    buttonIcon: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    callbackAction: PropTypes.func.isRequired,
    cancelButtonLabel: PropTypes.string,
    confirmationButtonLabel: PropTypes.string,
    displayCondition: PropTypes.bool.isRequired,
    popoverText: PropTypes.string,
    withConfirmationPopover: PropTypes.bool,
    withTimeSlotsPopover: PropTypes.bool
};

export default FootbarButton;

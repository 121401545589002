// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { groupBy, isEmpty } from 'lodash';
// Components
import Typography from '@material-ui/core/Typography';
// Context
import { useModal } from '../../modal/ModalContext';
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import { addDevice, getHardwareList, hardwareAlreadyAssign } from '../actions';
import createLoadingSelector from '../../layout/actions';
import CustomFieldCreateDevice from '../components/CustomFieldCreateDevice';

const loadingSelector = createLoadingSelector(['ADD_GROUP_TO_DEVICE']);
const CreateDevicePopup = () => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const modal = useModal();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const devices = useSelector(state => state.devices);
    const [groupIds, setGroupIds] = useState([]);
    const [name, setName] = useState('');
    const [customField, setCustomField] = useState(null);
    const [hardwareId, setHardwareId] = useState([]);

    useEffect(() => {
        dispatch(getHardwareList());
    }, []);

    const propsHandleSubmit = async () => {
        let device = {
            hardware_ids: hardwareId,
            group_ids: groupIds,
            device_name: name
        };
        if (customField !== null) {
            device = {
                ...device,
                ...customField
            };
        }
        const hardwareIds = Object.keys(groupBy(devices.list, 'hardware_ids')).filter(n => n);
        const updateObject = device;
        if (updateObject
            && updateObject.hardware_ids
            && updateObject.hardware_ids.filter(item => hardwareIds.includes(item)).length > 0) {
            dispatch(hardwareAlreadyAssign());
            modal.setData([device]);
        } else {
            await dispatch(addDevice([device]));
            modal.close();
        }
    };

    const head = (
        <>
            <span>{t('devices:create_new_device')}</span>
            <Typography variant={'subtitle2'}>{t('devices:create_new_device_description')}</Typography>
        </>
    );

    const content = (
        <div className={'iot-rocket-form'}>
            <Component
                componentName={'Input'}
                required
                label={'devices:create_device_name'}
                onChange={setName}
                value={name}
                className={'device-name'}
            />
            <CustomFieldCreateDevice onChange={setCustomField} />
            <Component componentName={'DeviceHardwareAssignment'} onChange={setHardwareId} />
            <Component
                componentName={'GroupSelector'}
                showSelectAll
                multiple
                isSelectedToText
                onChange={ids => setGroupIds(ids)}
            />
        </div>
    );

    return (
        <Component
            componentName={'Modal'}
            head={head}
            content={content}
            loading={isLoading}
            submitButton
            submitButtonLabel={'common:save'}
            onHandleSubmit={propsHandleSubmit}
            onSubmitDisabled={isEmpty(name)}
        />
    );
};

export default CreateDevicePopup;

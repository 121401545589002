// Libs
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Context
import Tooltip from '@material-ui/core/Tooltip/index';
import { useComponentsPool } from '../../../../ComponentsPool';
import MYDefaultLogo from '../../assets/send.png';

const UserInfo = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const [user] = useState(props.resource);
    const role = user.workspace_role && user.workspace_role?.workspace_role_name;

    return useMemo(() => (
        <div className={'user-info-table'}>
            <Tooltip
                placement="top"
                title={role ? t(`users:${role}`) : ''}
            >
                <div className={'user-pic'}>
                    <Component
                        componentName={'UserAvatarsGrouped'}
                        displayEmoji={user.invite_email && '✉️'}
                        user={user}
                        maxDisplayedUsers={2}
                    />
                    { user.workspace_role && (
                        user.workspace_role?.workspace_role_name === 'Admin'
        || user.workspace_role?.workspace_role_name === 'Owner'
                    ) && (
                        <div className={'icon-wrapper'}>
                            <Component componentName={'Icon'} type={'admin'} />
                        </div>
                    )}
                </div>
            </Tooltip>
            <div className={'user-names'}>
                <p>
                    {user?.invite_email
                        ? user?.invite_email
                        : `${user.first_name} ${user.last_name}`}
                </p>
                { isEmpty(user.custom_field.company) ? '' : <span>{user.custom_field.company}</span> }
            </div>
        </div>
    ), [user.company, user.first_name, user.last_name]);
};

export default UserInfo;

// Libs
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { forEach, map } from 'lodash';
// Components
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
// Context
import { useTranslation } from 'react-i18next';
import { useModal } from '../../modal/ModalContext';
import { useComponentsPool } from '../../../ComponentsPool';

import { getTraductionOfGroup } from '../helper';

// Actions

const tableTooltip = { tooltip: 'table-tooltip' };

const buildGroup = (props, groupList, modal) => {
    const groups = [];

    forEach(props.group_memberships, group_membership => {
        if (group_membership !== null) {
            const group_id = typeof group_membership === 'string' ? group_membership : group_membership.group.group_id;
            const isAdminOfGroup = props.resourceType === 'Device' ? false : group_membership.group_role && group_membership.group_role.group_role_name === 'Admin'; // props.resourceType !== 'Device' ?
            const params = {};
            const groupInfo = groupList.find(GM => GM.group_id === group_id);
            if (groupInfo !== undefined) {
                groups.push({
                    isSkill: groupInfo.custom_field?.type === 'SKILL',
                    isAdmin: !modal.getIsVisible() && isAdminOfGroup,
                    params,
                    groupInfo
                });
            }
        }
    });
    if (props.firstGroupTypeToAppear && props.firstGroupTypeToAppear === 'SKILL') {
        groups.sort((a, b) => Number(b.isSkill) - Number(a.isSkill));
    }
    return groups;
};

const GroupButton = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const groupList = props.isNotInGroupList && props.listToFilterFrom
        ? props.listToFilterFrom
        : useSelector(state => state.groups.list);
    const modal = useModal();
    const [groups, setGroups] = useState(() => buildGroup(props, groupList, modal));
    const [buttonLabel, setButtonLabel] = useState(`+${props.group_memberships.length - props.maxVisible}`);

    useEffect(() => {
        setGroups(() => buildGroup(props, groupList, modal));
        setButtonLabel(`+${props.group_memberships.length - props.maxVisible}`);
    }, [props.group_memberships]);

    let traduced_group_name;

    let icon = null;

    return (
        <div className={'button-wrapper'}>
            { map(groups.slice(0, props.maxVisible), (group, idx) => {
                traduced_group_name = getTraductionOfGroup(group.groupInfo.group_name, t);
                if (group.groupInfo.custom_field?.type === 'BATIMENT') {
                    icon = <Component componentName={'Icon'} type={`${props.iconType}Group`} />;
                } else if (group.groupInfo.custom_field?.type === 'SKILL') {
                    icon = <Component componentName={'Icon'} type={group.groupInfo.group_name} />;
                }
                return (
                    <Tooltip
                        placement={'top'}
                        key={`${group.groupInfo.group_id}-${idx}`}
                        classes={tableTooltip}
                        title={(<Component componentName={'Description'} content={traduced_group_name} />)}
                    >
                        <Chip
                            icon={icon}
                            label={traduced_group_name}
                            className={classNames([
                                'group-name',
                                { 'is-admin-group': group.isAdmin },
                                { 'is-skill-group': group.isSkill }
                            ])}
                            {...group.params}
                        />
                    </Tooltip>
                );
            })}
            { groups.length > props.maxVisible && (
                <Component callingComponentName={props.callingComponentName} componentName={'Popover'} buttonLabel={buttonLabel} fabButton>
                    <div className={'user-group-popover'}>
                        {
                            map(groups.slice(props.maxVisible, groups.length), (group, idx) => {
                                traduced_group_name = getTraductionOfGroup(group.groupInfo.group_name, t);
                                if (group.groupInfo.custom_field?.type === 'BATIMENT') {
                                    icon = <Component componentName={'Icon'} type={`${props.iconType}Group`} />;
                                } else if (group.groupInfo.custom_field?.type === 'SKILL') {
                                    icon = <Component componentName={'Icon'} type={traduced_group_name} />;
                                }
                                return (
                                    <Tooltip
                                        key={`${group.groupInfo.group_id}-${idx}`}
                                        classes={tableTooltip}
                                        title={(<Component componentName={'Description'} content={traduced_group_name} />)}
                                    >
                                        <Chip
                                            icon={icon}
                                            key={group.groupInfo.group_id}
                                            label={traduced_group_name}
                                            className={classNames([
                                                'group-name',
                                                { 'is-admin-group': group.isAdmin },
                                                { 'is-skill-group': group.isSkill }
                                            ])}
                                            {...group.params}
                                        />
                                    </Tooltip>
                                );
                            })
                        }
                    </div>
                </Component>
            )}
        </div>
    );
};

export default GroupButton;

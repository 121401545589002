const initialState = () => ({
    workspace: {
        acls: [],
        rights: {}
    },
    group: {
        acls: [],
        rights: {}
    }
});

const groupACLReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    const rights = {};
    switch (action.type) {
        case 'GET_GROUP_ACLS_PENDING':
            break;
        case 'GET_GROUP_ACLS_FULFILLED':
            newState.group.acls = action.payload.results;
            newState.group?.acls?.forEach(acl => {
                if (!rights[acl.group_role.group_role_name]) {
                    rights[acl.group_role.group_role_name] = [];
                }
                rights[acl.group_role.group_role_name].push(`${acl.group_action.group_action_name}:${acl.group_resource.group_resource_name}`); // eslint-disable-line
            });
            newState.group.rights = rights;
            break;
        case 'GET_WORKSPACE_ACLS_FULFILLED':
            newState.workspace.acls = action.payload.data.results;
            newState.workspace?.acls?.forEach(acl => {
                if (!rights[acl.workspace_role?.workspace_role_name]) {
                    rights[acl.workspace_role?.workspace_role_name] = [];
                }
                rights[acl.workspace_role?.workspace_role_name].push(`${acl.workspace_action.workspace_action_name}:${acl.workspace_resource.workspace_resource_name}`); // eslint-disable-line
            });
            newState.workspace.rights = rights;
            break;
        case 'LOGOUT_FULFILLED':
            return initialState();
        default:
            return state;
    }
    return newState;
};

export default groupACLReducer;

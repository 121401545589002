import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { EVENT_REFRESH_PAGE, segmentTrack } from '../layout/helper';

export const updateErrorFunc = payload => ({ type: 'ERROR', payload });

export const auth = (username, password) => ({
    type: 'LOGIN',
    payload: axios.post('auth/token', { username, password, workspace: localStorage.getItem('iot_rocket_workspace') })
        .then(res => res.data)
});

export const sendResetPasswordInvite = (email, lang) => ({
    type: 'SEND_RESET_PASSWORD_INVITE',
    payload: axios.post('users/reset-password', { lang, email })
});

export const checkAccountActivationToken = token => ({
    type: 'CHECK_ACCOUNT_ACTIVATION_TOKEN',
    payload: axios.post('users/check-account-activation', { token })
});

export const checkRegisterToken = token => ({
    type: 'CHECK_REGISTER_TOKEN',
    payload: axios.get(`users/register?token=${token}`)
});

export const checkResetPasswordToken = token => ({
    type: 'CHECK_RESET_PASSWORD_TOKEN',
    payload: axios.get(`users/reset-user-password?token=${token}`)
});

export const checkTokenBeforeRequest = () => {
    const refreshToken = localStorage.getItem('iot_rocket_refresh_JWT');
    return axios.post('auth/token/refresh', { refresh: refreshToken });
};

export const getRefreshedTokens = (token = null) => {
    const refreshToken = localStorage.getItem('iot_rocket_refresh_JWT');
    return {
        type: 'REFRESH_TOKENS',
        payload: axios.post('auth/token/refresh', { refresh: token === null ? refreshToken : token })
            .then(res => res.data)
            .catch(error => {
                console.error('REGISTER_FROM_INVITE', error); // eslint-disable-line
                return error;
            })
    };
};

export const loginByToken = token => {
    const userId = jwtDecode(token)?.user_id;
    const workspace = jwtDecode(token)?.workspace;
    segmentTrack(EVENT_REFRESH_PAGE, { userId }, { workspace });
    return {
        type: 'REFRESH_TOKENS',
        payload: axios.post('auth/token/refresh', { refresh: token })
            .then(res => res.data)
            .catch(error => {
                console.log(error); // eslint-disable-line
                return error;
            })
    };
};

export const register = (data, invite_token) => ({
    type: 'REGISTER_FROM_INVITE',
    payload: axios.post(`users/register?token=${invite_token}`, data)
        .then(res => res.data)
});

export const resetPasswordAction = (new_password, inviteToken) => ({
    type: 'RESET_PASSWORD',
    payload: axios.post(`users/reset-user-password?token=${inviteToken}`, { new_password })
        .then(() => {
            setTimeout(() => {
                window.location.href = '/login';
            }, 5000);
        })
});

export const refreshTokensFunc = payload => ({ type: 'REFRESH_TOKENS', payload });
export const refreshTokens = () => dispatch => {
    getRefreshedTokens()
        .then(res => { dispatch(refreshTokensFunc(res.data)); })
        .catch(error => {
            dispatch(updateErrorFunc('error'));
            console.log(error); // eslint-disable-line
        });
};

export const logout = (preventReload = false, allowLogin = false) => ({
    type: 'LOGOUT',
    payload: axios.post('auth/logout', {})
        .then(res => ({ data: res.data, preventReload, allowLogin }))
        .catch(error => { console.log(error); })// eslint-disable-line
});

export const createToken = (workspaceName, email, password) => ({
    type: 'CREATE_TOKEN',
    payload: axios.post('/auth/token', {
        workspace: workspaceName,
        username: email,
        password
    })
});


export const getSSORedirectionUrl = workspace => ({
    type: 'NO_REDUCER',
    payload: axios.get(`/cryptr/sso-redirection-url?workspace=${workspace}`).then(res => res.data)
});

export const loginByCryptr = code => ({
    type: 'LOGIN_BY_CRYPTR',
    payload: axios.post('/cryptr/login', {
        code,
        workspace: localStorage.getItem('iot_rocket_workspace')
    }).then(res => res.data)
});

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { Toast } from '../../../../notification/components';
import { getTimeOnTwoDigits } from '../../../../layout/helper';

const RoundOneTime = ({ started_at, ended_at, changeState }) => {
    const [t] = useTranslation();

    useEffect(() => {
        if (started_at && ended_at && ended_at === started_at) {
            toast(
                <Toast
                    message={t('notifications:hours_cannot_be_not_equal')}
                    icon={'frown'}
                    type={'error'}
                />, {
                    position: toast.POSITION.BOTTOM_LEFT,
                    className: 'normal',
                    bodyClassName: 'notification-body grow-font-size',
                    progressClassName: 'error-custom-progress-bar'
                }
            );
        }
    }, [started_at, ended_at]);

    return (
        <div className="round-ponctuelle">
            <div className="time-choice-text">{t('common:from_second')}</div>
            <TimePicker
                className={'hours-time-picker'}
                timeSteps={{ hours: 1, minutes: 5 }}
                ampm={false} // 24 hours clock
                value={started_at && dayjs().hour(started_at.split(':')[0]).minute(started_at.split(':')[1])}
                slotProps={{ textField: { placeholder: '--:--' }, actionBar: { sx: { display: 'none' } } }}
                onChange={time => changeState({ started_at: time ? `${getTimeOnTwoDigits(time.$H)}:${getTimeOnTwoDigits(time.$m)}` : 'error-field' })}
            />
            <div className="time-choice-text">{` ${t('common:to_second')}`}</div>
            <TimePicker
                className={'hours-time-picker'}
                timeSteps={{ hours: 1, minutes: 5 }}
                ampm={false} // 24 hours clock
                value={ended_at && dayjs().hour(ended_at.split(':')[0]).minute(ended_at.split(':')[1])}
                slotProps={{ textField: { placeholder: '--:--' }, actionBar: { sx: { display: 'none' } } }}
                onChange={time => changeState({ ended_at: time ? `${getTimeOnTwoDigits(time.$H)}:${getTimeOnTwoDigits(time.$m)}` : 'error-field' })}
            />
        </div>
    );
};

export default RoundOneTime;

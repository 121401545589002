// Libs
import React, { useState } from 'react';
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';
// Context
import { useSelector } from 'react-redux';
import { useComponentsPool } from '../../../ComponentsPool';

export default props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const levels = useSelector(state => state.tickets.priorityLevels);
    const [options] = useState(levels.map(level => ({ id: level?.id, value: t(`tickets:${level.name}`) })));
    const [selectedValue] = useState(props.value !== undefined
        ? find(options, option => option?.id === props.value) : undefined
    );
    const [priority, setPriority] = useState(props.value);

    const changePriority = (value) => {
        setPriority(value?.id);
        props.onChange(value?.id);
    };

    return (
        <Component
            componentName={'Select'}
            className={`select-priority-${priority}`}
            required={props.required}
            placeHolder={props.placeHolder}
            onChange={value => changePriority(value)}
            options={options}
            renderValue={'chip'}
            selected={selectedValue ? { value: t(`tickets:${selectedValue.value}`), id: selectedValue?.id } : undefined}
            disabled={props.disabled}
        />
    );
};

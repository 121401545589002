import { registerRoute } from 'workbox-routing';
import { NetworkFirst, StaleWhileRevalidate, CacheFirst } from 'workbox-strategies';
import { CacheFirstBeforeExpiryDate } from '../strategies/CacheFirstBeforeExpiryDate';
import { QRCodesPreloadCacheStrategy } from '../strategies/QRCodesPreloadCacheStrategy';

// Constants for cache strategies
const CACHE_FIRST_BEFORE_EXPIRY_DATE = 'CacheFirstBeforeExpiryDate';
const STALE_WHILE_REVALIDATE = 'StaleWhileRevalidate';
const NETWORK_FIRST = 'NetworkFirst';
const QRCODES_PRELOAD_CACHE_STRATEGY = 'QRCodesPreloadCacheStrategy';

const matchesAny = (url, patterns) => patterns.some(substring => url.includes(substring));

function checkInternetConnectivity() {
    const timeout = new Promise(
        (_, reject) => setTimeout(() => reject(new Error('Request timed out')), 3000)
    ); // Timeout après 3 secondes

    const fetchRequest = fetch('https://api.merciyanis.com/workspace/settings/', { method: 'HEAD', mode: 'no-cors' })
        .then(() => {
            console.log("then checkInternetConnectivity")
            return true;
        })
        .catch(error => {
            console.log("error checkInternetConnectivity :", error);
            return false;
        });

    return Promise.race([timeout, fetchRequest]);
}

function isConnectionSlow(apiUrl) {
    console.log("isConnectionSlow")
    if (!navigator.onLine) {
        return Promise.resolve(true);
    }
    console.log("pas true !!")
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if (connection) {
        const type = connection.effectiveType;
        if (type === 'slow-2g' || type === '2g' || type === '3g' || type === 'none' || type === 'unknown') {
            console.log("true via type")
            return Promise.resolve(true);
        }

        // Ajoute une vérification supplémentaire pour confirmer la connectivité
        return checkInternetConnectivity().then(isConnected => {
            if (!isConnected) {
                console.log("true via checkInternetConnectivity")
                return true;
            }
            console.log("connection ok false")
            return false;
        }).catch(error => {
            // Cas particulier où on considère que ce catch là est signe d'une erreur côté blocage client (proxy)
            // => on veut alors que l'utilisateur utilise dans tous les cas la stratégie NetworkFirst
            // => on va donc simuler que le réseau est bon (sinon récupère du cache)
            console.log("####### erreur catch: ");
            console.log(error);
            console.log(error.message);
            if (apiUrl.includes('novo-nordisk')) {
                return true;
            }
            return false;
        });
    }
    console.log("connection false")
    return Promise.resolve(false);
}

function shouldUseCacheStrategy(getUrl, request, cacheStrategy) {
    if (getUrl.host.endsWith(DOMAIN_NAME)) {
        // Check if specific strategy is requested in the header
        if (request.headers.get('X-Use-Strategy')) {
            return request.headers.get('X-Use-Strategy') === cacheStrategy;
        }

        // Request URL matching rules for each cache strategy
        const requestsWithCacheFirstBeforeExpiryDateStrategy = [
            '/workspace/?hardware_id=',
            '/devices/get-device-by-hardware-id/'
        ];

        // TODO spécifique client novo-nordisk + societe-genrales car la requête devices est trop longue
        const isWorkspacesWithDevicesStaleStrategy = getUrl.pathname === '/devices/' && (
            getUrl.href.includes('novo-nordisk') || getUrl.href.includes('societe-generale')
        );
        // TODO spécifique client cgi car la requête users est trop longue
        const isWorkspacesWithUsersStaleStrategy = getUrl.pathname === '/users/' && getUrl.href.includes('cgi');

        const isRequestWithStaleWhileRevalidateStrategy = isWorkspacesWithDevicesStaleStrategy
            || isWorkspacesWithUsersStaleStrategy;

        const requestsWithQRCodePreloadCacheStrategy = [
            '/preload_qrcodes_cache'
        ];
        switch (cacheStrategy) {
            case QRCODES_PRELOAD_CACHE_STRATEGY:
                return matchesAny(getUrl.href, requestsWithQRCodePreloadCacheStrategy);
            case STALE_WHILE_REVALIDATE:
                return isRequestWithStaleWhileRevalidateStrategy
                    && !matchesAny(getUrl.href, requestsWithCacheFirstBeforeExpiryDateStrategy);
            case CACHE_FIRST_BEFORE_EXPIRY_DATE:
                return matchesAny(getUrl.href, requestsWithCacheFirstBeforeExpiryDateStrategy);
            case NETWORK_FIRST:
                return !matchesAny(getUrl.href, requestsWithQRCodePreloadCacheStrategy)
                    && !isRequestWithStaleWhileRevalidateStrategy
                    && !matchesAny(getUrl.href, requestsWithCacheFirstBeforeExpiryDateStrategy);
            default:
                return false;
        }
    }
    return false;
}

// Fonction pour générer une clé de cache personnalisée pour les requêtes POST
function generateCacheKey(request) {
    const url = new URL(request.url);
    url.searchParams.sort();
    return `${url.pathname}?${url.searchParams.toString()}`;
}

// Stratégie personnalisée pour les requêtes POST spécifiques
async function customPostStrategy({ request, event }) {
    const cache = await caches.open('logged-in-cached-data');
    const cacheKey = generateCacheKey(request);

    const apiUrl = request.url;
    const connectionSlow = await isConnectionSlow(apiUrl);

    if (connectionSlow) {
        // Utiliser CacheFirst en cas de connexion lente
        const cachedResponse = await cache.match(cacheKey);
        if (cachedResponse) {
            event.waitUntil(
                (async () => {
                    try {
                        const networkResponse = await fetch(request.clone());
                        if (networkResponse && (networkResponse.status === 200 || networkResponse.status === 201)) {
                            const responseClone = networkResponse.clone();
                            const responseBody = await responseClone.blob();
                            await cache.put(cacheKey, new Response(responseBody, {
                                status: networkResponse.status,
                                statusText: networkResponse.statusText,
                                headers: networkResponse.headers
                            }));
                        }
                    } catch (error) {
                        console.error('Failed to fetch and update cache:', error);
                    }
                })()
            );
            return cachedResponse;
        } else {
            return fetch(request);
        }
    } else {
        // Utiliser NetworkFirst sinon
        try {
            const networkResponse = await fetch(request.clone());
            if (networkResponse && (networkResponse.status === 200 || networkResponse.status === 201)) {
                const responseClone = networkResponse.clone();
                const responseBody = await responseClone.blob();
                await cache.put(cacheKey, new Response(responseBody, {
                    status: networkResponse.status,
                    statusText: networkResponse.statusText,
                    headers: networkResponse.headers
                }));
            }
            return networkResponse;
        } catch (error) {
            const cachedResponse = await cache.match(cacheKey);
            if (cachedResponse) {
                return cachedResponse;
            }
            throw error;
        }
    }
}

export const setupRequestsCaching = () => {
    registerRoute(
        ({ url, request }) => request.method === 'POST'
            && (url.pathname.includes('auth/token/refresh')
                || url.pathname.includes('generate-hmac')
                || url.pathname.includes('dashboards/get_tickets_dashboards')
            ),
        customPostStrategy,
        'POST'
    );

    registerRoute(
        ({ url, request }) => shouldUseCacheStrategy(url, request, NETWORK_FIRST),
        async ({ request, event }) => {
            const apiUrl = request.url;

            const connectionSlow = await isConnectionSlow(apiUrl);
            if (connectionSlow) {
                return await new CacheFirst({
                    cacheName: 'logged-in-cached-data',
                    statuses: [200, 201, 202, 203, 204, 205, 206, 207, 208, 226]
                }).handle({ request, event });
            }
            return await new NetworkFirst({
                cacheName: 'logged-in-cached-data',
                statuses: [200, 201, 202, 203, 204, 205, 206, 207, 208, 226]
            }).handle({ request, event });
        },
        'GET'
    );

    registerRoute(
        ({ url, request }) => shouldUseCacheStrategy(url, request, QRCODES_PRELOAD_CACHE_STRATEGY),
        new QRCodesPreloadCacheStrategy({
            cacheName: 'logged-in-cached-data'
        }),
        'GET'
    );

    registerRoute(
        ({ url, request }) => shouldUseCacheStrategy(url, request, CACHE_FIRST_BEFORE_EXPIRY_DATE),
        new CacheFirstBeforeExpiryDate({
            cacheName: 'logged-in-cached-data'
        }),
        'GET'
    );

    registerRoute(
        ({ url, request }) => shouldUseCacheStrategy(url, request, STALE_WHILE_REVALIDATE),
        new StaleWhileRevalidate({
            cacheName: 'logged-in-cached-data',
            statuses: [200, 201, 202, 203, 204, 205, 206, 207, 208, 226]
        }),
        'GET'
    );

};

// Get all group ids from a device id
export const getSubscriptionTypes = userSubscriptions => ((
    {
        name: 'alert_ticket_all',
        icon: 'alert_ticket_all',
        checked: userSubscriptions.includes('TICKETS:all')
    },
    {
        name: 'alert_ticket_comment',
        icon: 'alert_ticket_comment',
        checked: userSubscriptions.includes('TICKETS:comment')

    },
    {
        name: 'alert_ticket_assignated',
        icon: 'alert_ticket_assignated',
        checked: userSubscriptions.includes('TICKETS:assignated')
    }
));


export const test = () => true;


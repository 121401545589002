// Libs
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { isMobile } from 'react-device-detect';
import { useComponentsPool } from '../../../ComponentsPool';
import { hideRouteInNavbar } from '../../core/actions';
import { isPassageDeviceType } from '../../devices/helper';
import { contractorRoleCanSeeCalendarView, userRoleCanSeeCalendarView } from '../../users/helper';


const NavigationBadge = props => {
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();

    const navigateDropdownRef = React.useRef();
    const currentUser = useSelector(state => state.users.currentUser);

    const workspaceSettings = useSelector(state => state.workspace.settings);
    const hide_contractor_device_view = workspaceSettings.hide_contractor_device_view;
    const show_user_calendar_view = workspaceSettings.show_user_calendar_view;
    const show_contractor_calendar_view = workspaceSettings.show_contractor_calendar_view;
    const deviceList = useSelector(store => store.devices.list);

    useEffect(() => { // display the button redirecting to a view in the navbar
        if (!isMobile) {
            // no matter the role if there are no passage devices we hide the rounds view
            if (!deviceList.find(device => isPassageDeviceType(device))) {
                dispatch(hideRouteInNavbar('rounds'));
            }
            if (currentUser.workspace_role?.workspace_role_name === 'User') {
                dispatch(hideRouteInNavbar('dashboards'));
                dispatch(hideRouteInNavbar('users'));
                dispatch(hideRouteInNavbar('devices'));
                dispatch(hideRouteInNavbar('settings'));
                if (!userRoleCanSeeCalendarView(show_user_calendar_view)) { dispatch(hideRouteInNavbar('rounds')); }
            } else if (currentUser.workspace_role?.workspace_role_name === 'Contractor') {
                dispatch(hideRouteInNavbar('dashboards'));
                dispatch(hideRouteInNavbar('users'));
                dispatch(hideRouteInNavbar('settings'));
                if (!contractorRoleCanSeeCalendarView(show_contractor_calendar_view)) { dispatch(hideRouteInNavbar('rounds')); }
                if (hide_contractor_device_view === undefined || hide_contractor_device_view) dispatch(hideRouteInNavbar('devices'));
            } else if (currentUser.workspace_role?.workspace_role_name === 'Admin') {
                dispatch(hideRouteInNavbar('settings'));
            }
        }
    }, []);

    return (
        <div className={'navigation-dropdown'} ref={navigateDropdownRef}>
            { props.isCustomSearchBar || props.inputFocused ? (
                <></>
            ) : (
                <div className={'search-icon'}>
                    <Component componentName={'Icon'} type={'search'} />
                </div>
            )}
        </div>
    );
};

export default NavigationBadge;

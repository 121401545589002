// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useHistory, useRouteMatch } from 'react-router';
// Context
import { isIE, isMobile } from 'react-device-detect';
import { useComponentsPool } from '../../../ComponentsPool';
// Hooks
import { DebugLocalStorage } from '../../../utils/hook/useDebug';
// Actions
import { notification } from '../../notification/actions';
import { auth, loginByToken, getSSORedirectionUrl } from '../actions';
import createLoadingSelector from '../../layout/actions';
import { setIsLoggable } from '../../users/actions';
import AzureADConnect from './AzureADConnect';
// Helpers
import { isContractorWithoutMail } from '../../users/helper';
import Button from "@material-ui/core/Button";

const getWorkspace = () => {
    try {
        return localStorage.getItem('iot_rocket_workspace');
    } catch (e) {
        throw new Error('Can not get local storage item iot_rocket_workspace');
    }
};

const loadingSelector = createLoadingSelector(['LOGIN']);
const LogInForm = props => {
    const match = useRouteMatch();
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const [loading, setLoading] = useState(false);
    let parsedEmail = '';
    window.location.href?.split('?').forEach(filter => {
        if (filter.includes('email=')) {
            parsedEmail = filter.split('email=')[1];
        }
    });
    const [email, setEmail] = useState(parsedEmail);
    const [password, setPassword] = useState('');
    const [workspace] = useState(getWorkspace());
    const [isDisabled, setIsDisabled] = useState(true);
    const [primaryColor] = useState(getComputedStyle(document.documentElement).getPropertyValue('--primary-color'));
    const currentUser = useSelector(state => state.users.currentUser);
    const history = useHistory();
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const [showAzureADConnect, setShowAzureADConnect] = useState(false);

    useEffect(() => {
        setShowAzureADConnect(
            workspaceSettings.show_azure_ad_connect && !isEmpty(workspaceSettings.cryptr_org_domain)
        );
    }, [workspaceSettings.show_azure_ad_connect, workspaceSettings.cryptr_org_domain]
    );

    useEffect(() => {
        setIsDisabled(isEmpty(email) || isEmpty(password) || password === 'error_field' || email === 'error_field');
    }, [email, password]);

    useEffect(() => {
        if (match && match.params.error && match.params.error.length > 0) {
            dispatch(notification(t(`notifications:${match.params.error}`), 'error-custom', 'frown'));
        }

        if (match && match.params.token && match.params.token.length > 0) {
            dispatch(loginByToken(atob(match.params.token)));
        }
    }, []);

    const onLogin = e => {
        e.preventDefault();
        setLoading(true);
        setTimeout(() => {
            if (email !== 'qrcodes@merciyanis.com' && email !== 'teams@merciyanis.com' && email !== 'messaging@merciyanis.com'
            && email !== 'samfm@merciyanis.com' && email !== 'servicenow@merciyanis.com') {
                dispatch(auth(email, password))
                    .then(() => {
                        dispatch(setIsLoggable(false));
                        // Only contractors without mail are redirected to the round view once logged in
                        if (isContractorWithoutMail(currentUser)) { history.push('/rounds'); }
                    });
            } else {
                dispatch(notification(t('notifications:bad_credentials'), 'error', 'frown'));
                setLoading(false);
            }
        }, 750);
    };

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    return (
        <div className={'iot-rocket-form home-form login-form'}>
            <Component componentName={'PageHeader'} subText={t('common:login_workspace_step2_form_title', { workspace })} />
            <div className={'form-wrapper'}>
                <DebugLocalStorage />
                <form onSubmit={onLogin}>
                    { showAzureADConnect && <AzureADConnect login /> }
                    {isIE && (
                        <div className="ie_compatibility">
                            <span className="ie_compatibility_title">
                                {`${t('common:ie_compatibility_title')} `}
                                <Component componentName={'Icon'} type={'exclamationTriangle'} />
                            </span>
                            <span>{t('common:ie_compatibility_text')}</span>
                            <div>
                                <span>{t('common:ie_compatibility_link')}</span>
                                <a href="https://support.microsoft.com/fr-fr/windows/changer-votre-navigateur-par-d%C3%A9faut-dans-windows-10-020c58c6-7d77-797a-b74e-8f07946c5db6#:~:text=Cliquez%20sur%20le%20bouton%20D%C3%A9marrer,Edge%20ou%20un%20autre%20navigateur.">
                                    {t('common:ie_compatibility_link_here')}
                                </a>
                            </div>
                            <span className="ie_compatibility_merciyanis">{t('common:ie_compatibility_merciyanis')}</span>
                        </div>
                    )}
                    <Component
                        componentName={'Input'}
                        autoFocus
                        required
                        startAdornment={<Component componentName={'Icon'} type={'email'} />}
                        label={props.label ? props.label : isMobile ? 'email_address_or_id' : 'common:email_address'}
                        onChange={setEmail}
                        value={email}
                    />
                    <Component
                        componentName={'Input'}
                        required
                        type={'password'}
                        startAdornment={<Component componentName={'Icon'} type={'password'} />}
                        label={'common:password'}
                        onChange={setPassword}
                    />
                    <div className="forget-password-button-container">
                        <Component
                            componentName={'Button'}
                            text={t('common:forgot_my_password')}
                            color={primaryColor}
                            onClick={() => (props.goBackToPreviousUrl
                                ? props.history.push({ pathname: '/forgetpassword', state: { goBackToPathname: props.history.location.pathname } })
                                : props.history.push('/forgetpassword'))
                            }
                        />
                    </div>
                    <div className="submit-button-wrapper login-button">
                        <Component
                            componentName={'Button'}
                            disabled={isDisabled}
                            onClick={onLogin}
                            type={'submit'}
                            text={t('common:log_in')}
                            loading={loading}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LogInForm;

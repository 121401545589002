// Library
import React from 'react';
import Container from '@material-ui/core/Container';
import { isMobile } from 'react-device-detect';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import ErrorBoundary from '../../errorBoundary';
import { getRoutes } from '../helper';
import { useComponentsPool } from '../../../ComponentsPool';

const routes = getRoutes();
const { Component } = useComponentsPool();

const CenterSection = () => (
    <div className={classnames('center-section', isMobile ? 'center-section-mobile' : '')}>
        <div className={classnames('body', isMobile && window.location.pathname !== '/tickets' ? 'body-mobile' : '')}>
            <Container maxWidth="xl" classes={{ root: 'body-container' }}>
                <ErrorBoundary>
                    { routes }
                    <Component componentName={'Changelog'} />
                </ErrorBoundary>
            </Container>
        </div>
    </div>
);

export default CenterSection;


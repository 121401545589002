// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { uniq, concat, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

// Context
import { useComponentsPool } from '../../../ComponentsPool';
import { useModal } from '../../modal/ModalContext';

// Helpers
import { getUserGroupIdsByGroupType } from '../../users/helper';
import {
    getTraductionOfGroup, getGroupByDeviceGroupsIds, getGroupById, getGroupNameFromId
} from '../helper';
import { getTemplatesFromSkill } from '../../templates/helper';

const getGroupList = (groups, alreadySelectedGroups, t) => {
    const returnGroups = [];
    groups?.forEach(group => {
        if (!alreadySelectedGroups.includes(group.group_id)) {
            returnGroups.push({ id: group.group_id, value: t(`groups:${group.group_name}`) });
        }
    });
    return returnGroups;
};

const getGroupListWithValues = (groups, isSelectedGroups, multiple, t) => {
    let result = [];
    if (multiple || isSelectedGroups?.length > 0 && !Array.isArray(isSelectedGroups[0])) {
        result = [];
        let traduced_group_name;
        isSelectedGroups?.forEach(group => {
            traduced_group_name = getTraductionOfGroup(getGroupNameFromId(groups, group), t);
            result.push({ id: group, value: traduced_group_name });
        });
    }
    return result;
};

const getSelectedGroups = (resource, groupType, groupList) => {
    let groups = [];
    if (Array.isArray(resource)) {
        resource?.forEach(data => {
            groups = concat(groups, data.group_ids);
        });
        return uniq(groups);
    }
    if (resource.custom_field) {
        if (groupType === 'BATIMENT' && resource.custom_field?.batiment_group_id) return [resource.custom_field.batiment_group_id];
        if (groupType === 'FLOOR' && resource.custom_field?.floor_group_id) return [resource.custom_field.floor_group_id];
        if (groupType === 'POSITION' && resource.custom_field?.position_group_id) return [resource.custom_field.position_group_id];
        if (groupType === 'SKILL' && resource.custom_field?.skill_group_id) return [resource.custom_field.skill_group_id];
    }
    if (resource.group_ids) {
        const result = [];
        const temp = getGroupByDeviceGroupsIds(groupList, resource.group_ids);
        temp?.forEach(group => {
            if (group?.custom_field?.type === groupType) result.push(group.group_id);
        });
        return result;
    }
    if (resource.group_memberships) return getUserGroupIdsByGroupType(resource, groupType);
    if (resource.group) return [resource.group]; // A ENLEVER, TEMPORAIRE
    if (resource.device_ids) return resource.device_ids;
    return [];
};


const setResource = (resource, list, item) => {
    if (!isEmpty(resource)) return resource;
    if (!isEmpty(list)) return list;
    if (!isEmpty(item)) return item;
    return null;
};


const GroupSelector = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const modal = useModal();
    const groupList = useSelector(state => state.groups.list);
    const templatesList = useSelector(state => state.templates.list);
    const resource = setResource(props.resource, modal.getList(), modal.getItem());
    const selectedGroups = resource ? getSelectedGroups(resource, props.groupType, groupList) : [];
    let filteredGroupList = groupList;
    const workspaceSettings = useSelector(state => state.workspace.settings);

    const titleMode = workspaceSettings.mode_title ? workspaceSettings.mode_title : 'close';
    const floorMode = workspaceSettings.mode_floor ? workspaceSettings.mode_floor : 'close';

    const currentUser = useSelector(state => state.users.currentUser);
    const focusedNavButton = useSelector(state => state.navbar.focusedNavButton);

    let flagFiltered = false;
    if (props.groupType === 'SKILL') {
        switch (currentUser.workspace_role?.workspace_role_name) {
            case 'Admin':
                if (workspaceSettings.filter_admin_on_skills && focusedNavButton === '/tickets') {
                    flagFiltered = true;
                }
                break;
            case 'Contractor':
                flagFiltered = true;
                break;
            case 'User':
                if (workspaceSettings.filter_user_on_skills) {
                    flagFiltered = true;
                }
                break;
            default:
                break;
        }
    } else if (props.groupType === 'BATIMENT') {
        switch (currentUser.workspace_role?.workspace_role_name) {
            case 'Admin':
                flagFiltered = focusedNavButton !== '/users';
                break;
            default:
                flagFiltered = true;
                break;
        }
    }

    if (flagFiltered) {
        const newGroups = [];
        if (currentUser.workspace_role?.workspace_role_name === 'Owner') {
            groupList?.forEach(group => {
                newGroups.push({
                    custom_field: group.custom_field,
                    group_id: group.group_id,
                    group_name: group.group_name,
                    group_ids: group.group_ids
                });
            });
        } else {
            currentUser.group_memberships?.forEach(group => {
                newGroups.push({
                    custom_field: group.group.custom_field,
                    group_id: group.group.group_id,
                    group_name: group.group.group_name,
                    group_ids: getGroupById(groupList, group.group.group_id)?.group_ids
                });
            });
        }
        filteredGroupList = newGroups;
    }

    if (props.groupType) {
        filteredGroupList = filteredGroupList.filter(g => g?.custom_field?.type === props.groupType);
    }

    if (props.isCreateTicketPopup && !isEmpty(filteredGroupList)) {
        if (props.groupType === 'SKILL' && titleMode === 'close') {
            filteredGroupList = filteredGroupList
                .filter(g => !isEmpty(getTemplatesFromSkill(templatesList, g.group_id)));
        } else if (props.groupType === 'BATIMENT' && floorMode === 'close') {
            filteredGroupList = filteredGroupList.filter(g => !isEmpty(g?.group_ids));
        }
    }

    const [options, setOptions] = useState(props.isSelectedToText
        ? getGroupList(filteredGroupList, selectedGroups, t)
        : getGroupList(filteredGroupList, [], t));

    let selected;
    if (props.multiple) {
        selected = !props.isSelectedToText ? getGroupListWithValues(groupList, selectedGroups, props.multiple, t) : [];
    } else {
        selected = getGroupListWithValues(groupList, selectedGroups, props.multiple, t);
        if (selected?.length > 0) selected = selected[0];
    }

    const [disabled, setDisabled] = useState(props.disabled ? props.disabled : false);

    const [old, setOld] = useState(null);

    const [emptySelect, setEmptySelect] = useState(false);

    const setOptionsFromParent = parent => {
        if (!parent || (Array.isArray(parent) && parent.length === 0)) {
            setDisabled(true);
        } else {
            setDisabled(false);
            const initialOptions = props.isSelectedToText
                ? getGroupList(filteredGroupList, selectedGroups, t)
                : getGroupList(filteredGroupList, [], t);

            setOptions(initialOptions.filter(option => getGroupById(groupList, parent)?.group_ids?.includes(option.id))
            );
        }
    };

    useEffect(() => {
        if (props.modeHierarchy && (!Array.isArray(props.parent)
            || (Array.isArray(props.parent) && props.parent.length === 0))) {
            if (old !== null && old !== props.parent) {
                setEmptySelect(true);
            }
            setOld(props.parent);
            setOptionsFromParent(props.parent);
        }
    }, [props.parent]);

    useEffect(() => {
        if (emptySelect) setEmptySelect(false);
    }, [emptySelect]);

    return (
        <Component
            componentName={'Select'}
            required={props.required}
            multiple={props.multiple}
            placeHolder={props.placeholder}
            emptySelect={emptySelect}
            disableRule={props.disableRule}
            rules={props.rules}
            onChange={groups => {
                if (props.multiple) {
                    if (groups === 'error_field') props.onChange([]);
                    else if (!isEmpty(groups) || !props.isRequired) props.onChange(groups.map(item => item.id));
                } else {
                    props.onChange(groups === null ? [] : groups.id);
                }
            }}
            options={options}
            renderValue={'chip'}
            selected={selected}
            isSelectedToText={props.isSelectedToText
                ? getGroupListWithValues(groupList, selectedGroups, props.multiple, t)
                : []
            }
            isFilled={props.isFilled}
            showSelectAll={props.showSelectAll}
            disabled={disabled}
        />
    );
};

GroupSelector.defaultProps = {
    isFilled: false,
    multiple: false,
    placeholder: 'groups:select_group',
    required: false,
    showSelectAll: false
};

GroupSelector.propTypes = {
    isFilled: PropTypes.bool,
    multiple: PropTypes.bool,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    showSelectAll: PropTypes.bool
};

export default GroupSelector;

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';

const ArchivedTicketAssignees = props => {
    const archiveTicketAssigneesIds = props.resource.assigners;
    const users = useSelector(state => state.users.list);
    const { Component } = useComponentsPool();

    return (
        <Component
            componentName={'FollowersAvatarsGrouped'}
            usersByGroups={archiveTicketAssigneesIds.map(assignee => find(users, user => user?.id === assignee))}
            shortenText
        />
    );
};

export default ArchivedTicketAssignees;

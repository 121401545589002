// Libs
import React, { useState } from 'react';
// Components
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';

const TypeImage = props => {
    const { Component } = useComponentsPool();
    const [editMode, setEditMode] = useState(false);
    const [newValue, setNewValue] = useState(props.value);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const setInputValue = () => {
        props.setNewValue(newValue);
        setEditMode(false);
    };

    return (
        <div className={'type-link'}>
            {editMode && (
                <React.Fragment>
                    <Component
                        componentName={'Input'}
                        value={newValue}
                        label={''}
                        onChange={value => setNewValue(value)}
                        type={props.number ? 'number' : ''}
                    />
                    <div
                        role={'button'}
                        tabIndex={0}
                        className={'icon-container'}
                        onClick={() => setInputValue()}
                    >
                        <Component componentName={'Icon'} type={'check'} color={'green'} />
                    </div>
                </React.Fragment>
            )}
            {!editMode && (
                <React.Fragment>
                    <Typography
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onClick={e => setAnchorEl(e.currentTarget)}
                        className={'link'}
                    >
                        <span>{props.value}</span>
                    </Typography>
                    <Popover
                        id={'workspace-settings-img'}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        onClose={() => setAnchorEl(null)}
                        disableRestoreFocus
                    >
                        <div className={'img-container'}>
                            <img src={props.value} alt={'preview'} />
                        </div>
                    </Popover>
                    <div
                        role={'button'}
                        tabIndex={0}
                        className={'edit-icon-container'}
                        onClick={() => setEditMode(true)}
                    >
                        <Component componentName={'Icon'} type={'edit'} />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default TypeImage;

// Libs
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, includes } from 'lodash';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useModal } from '../../../modal/ModalContext';
// Actions
import { removeUserFromGroup } from '../../actions';

const UserGroups = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const modal = useModal();
    const [user] = useState(props.resource || modal.getItem());
    const profile = useSelector(state => state.users.currentUser);
    const dispatch = useDispatch();
    const hasWorkspaceNfcTag = useSelector(state => state.nfcs.list).length > 0;

    let userGroups = [];
    if (user?.invite_email) {
        userGroups = user.group_memberships;
    } else if (props.groupType) {
        userGroups = user.group_memberships;
        userGroups = userGroups.filter(obj => obj.group?.custom_field?.type === props.groupType);
    } else {
        const workspaceSettings = useSelector(state => state.workspace.settings);
        const filterSkills = user.workspace_role?.workspace_role_name === 'Contractor'
            || (user.workspace_role?.workspace_role_name === 'Admin' && workspaceSettings.filter_admin_on_skills)
            || (user.workspace_role?.workspace_role_name === 'User' && workspaceSettings.filter_user_on_skills)
            || (user.workspace_role?.workspace_role_name === 'User' && workspaceSettings.supervision_skills_to_users);

        const excludeTypes = filterSkills ? ['BATIMENT', 'SKILL'] : ['BATIMENT'];
        userGroups = user.group_memberships;
        userGroups = userGroups.filter(obj => includes(excludeTypes, obj.group?.custom_field?.type));
    }

    return isEmpty(userGroups)
        ? (
            <i className={'placeholder_text'}>
                {
                    props.groupType
                        ? t(`common:not_in_group_${props.groupType}`)
                        : t('common:not_in_group')
                }
                {' '}
            </i>
        )
        : (
            <Component
                componentName={'GroupButton'}
                firstGroupTypeToAppear={'SKILL'}
                view={'user'}
                key={user.id}
                group_memberships={userGroups}
                groupRights={profile.group_rights}
                resourceType={'UserGroupMembership'}
                iconType={'user'}
                maxVisible={window.screen.width < 1280 && hasWorkspaceNfcTag ? 2 : 3}
                deleteIcon={!user?.invite_email}
                removeGroup={groupMembership => {
                    dispatch(removeUserFromGroup(user.id, groupMembership.user_group_membership_id));
                }}
                callingComponentName={'UserGroups'}
                index
            />
        );
};

export default UserGroups;

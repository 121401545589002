import React from 'react';

// eslint-disable-next-line no-process-env
if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line global-require
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true,
        trackHooks: true,
        titleColor: '#ff0000',
        diffNameColor: '#34b300',
        diffPathColor: '#0088ff',
    });
}

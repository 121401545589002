// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Context
import {
    find, isEmpty
} from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useComponentsPool } from '../../../ComponentsPool';
import { addFilter, removeFilter, resetFilters } from '../actions';

const CustomSearchBar = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navbar = useSelector(state => state.navbar);
    const [inputFocused, setInputFocused] = useState(false);
    const [query, setQuery] = useState('');
    const [displayRoutesDropdown, setRoutesDropdown] = useState(false);
    const [displayResultContainer, setDisplayResultContainer] = useState(false);
    const [emptyInput, setEmptyInput] = useState(false);
    const [inputFocus, setInputFocus] = useState(false);
    const [queryResultWidth, setQueryResultWidth] = useState(0);
    const [pathSelectorWidth, setPathSelectorWidth] = useState(0);

    const exitFocusMode = () => {
        setInputFocused(false);
        setInputFocus(false);
        if (!isEmpty(navbar.filters)) dispatch(resetFilters());
        setRoutesDropdown(false);
        setQuery('');
        setEmptyInput(!emptyInput);
    };

    const toggleFilter = filter => {
        if (!find(navbar.filters, res => res.id === filter.id)) {
            dispatch(resetFilters());
            setQuery('');
            dispatch(addFilter({
                id: filter.id,
                type: filter.category,
                name: filter.name,
                groups: filter.groups,
                groupType: filter.groupType,
                id_bis: filter.id_bis
            }));
        } else {
            dispatch(removeFilter(filter.id));
        }
    };

    useEffect(() => {
        setRoutesDropdown(false);
    }, [window.location.href]);

    useEffect(() => {
        if (inputFocused === true) setDisplayResultContainer(false);
    }, [inputFocused]);

    useEffect(() => {
        if (!isEmpty(navbar.filters)) {
            setInputFocused(true);
            setDisplayResultContainer(true);
            setEmptyInput(!emptyInput);
        }
    }, [navbar.filters]);

    useEffect(() => {
        if (displayRoutesDropdown) {
            setDisplayResultContainer(false);
        }
    }, [displayRoutesDropdown]);

    if (isMobile) {
        return <Component componentName={'MobileNavBar'} />;
    }

    useEffect(() => {
        exitFocusMode();
    }, [props.resetSearchbar]);

    return (
        <>
            <Component
                componentName={'CustomQueryResults'}
                toggleFilter={toggleFilter}
                query={query}
                clearText={() => setEmptyInput(!emptyInput)}
                displayResultContainer={displayResultContainer}
                inputFocus={inputFocus}
                width={queryResultWidth - pathSelectorWidth - 17}
                left={pathSelectorWidth + 38}
                searchbarName={props.searchbarName}
                devices={props.devices}
                groups={props.groups}
            />
            <Component
                componentName={'SearchInput'}
                emptyInput={emptyInput}
                setPathSelectorWidth={setPathSelectorWidth}
                setQueryResultWidth={setQueryResultWidth}
                clearText={() => setQuery('')}
                setDisplayResultContainer={setDisplayResultContainer}
                routesDropdownAction={value => setRoutesDropdown(value)}
                displayRoutesDropdown={displayRoutesDropdown}
                focused={inputFocused}
                setQuery={setQuery}
                query={query}
                expandSearchInput={() => {
                    if (!isEmpty(navbar.filters)) setDisplayResultContainer(true);
                    setInputFocused(true);
                }}
                exitFocusMode={() => exitFocusMode()}
                setInputEvent={focus => setInputFocus(focus)}
                searchbarName={props.searchbarName}
                isCustomSearchBar
            />
        </>
    );
};

export default CustomSearchBar;

import update from 'immutability-helper';
import {
    difference, concat, cloneDeep,
    find, findIndex, forEach, groupBy, isEmpty, map, remove
} from 'lodash';

const initColors = (device_data_types, config) => {
    const defaultColors = config.graphColors;
    let colors = [];
    forEach(device_data_types, (device_data_type, idx) => {
        colors = [...colors, defaultColors[idx % defaultColors.length]];
    });
    return colors;
};

const initialState = () => ({
    list: [],
    selectedDevicesToSetPreview: [],
    selectedDevicesPreviewGraph: {},
    selectedDevicesPreviewSettings: {},
    selectedDevices: [],
    selectedDevicesToConfigure: [],
    editingDevice: {},
    device_data_types: [],
    hardware_list: [],
    userRight: [],
    loading: false,
    editing: false,
    saving: false,
    total: 0,
    deviceShownInDetailsPopup: {},
    notConfiguredTableColumns: [{
        name: 'name',
        formTitle: true,
        title: 'devices:not_configured_devices',
        component: 'DeviceName',
        weight: 100
    }, {
        name: 'information',
        className: 'show-on-opened',
        isCapitalized: true,
        title: 'common:informations',
        component: 'DeviceTableInformationField',
        weight: 200
    }, {
        name: 'accessibility',
        className: 'show-on-opened',
        isCapitalized: true,
        title: 'common:accessibility',
        component: 'DeviceTableGroupsField',
        weight: 300
    }, {
        name: 'battery',
        title: 'data_types:battery_level',
        component: 'DeviceTableBatteryField',
        weight: 400
    }],
    configuredTableColumns: [{
        name: 'name',
        isCapitalized: true,
        formTitle: true,
        title: 'common:devices',
        component: 'DeviceName',
        weight: 100
    }, {
        name: 'location',
        title: 'devices:location',
        component: 'ObjectLocation',
        weight: 100
    }, {
        name: 'data',
        title: 'devices:data',
        component: 'DeviceData',
        weight: 400
    }],
    deviceGroupTableColumns: [{
        name: 'name',
        isCapitalized: true,
        formTitle: true,
        title: 'common:groups',
        component: 'GroupName',
        weight: 100
    }],
    editableFields: [{
        name: 'device_batiment_group',
        title: 'groups:batiment_group',
        componentName: 'EditableField',
        renderValue: 'DeviceSimpleViewGroup',
        editionComponent: 'GroupSelector',
        props: {
            required: true,
            value: ['custom_field', 'batiment_group_id'],
            placeholder: '',
            groupType: 'BATIMENT',
            multiple: false,
            serviceNowField: 'batiment'
        },
        weight: 400
    }, {
        name: 'device_hardware_ids',
        title: 'devices:hardware_id',
        renderValue: 'DeviceSimpleString',
        disabledInput: true,
        props: { value: 'hardware_ids' },
        weight: 300
    }, {
        name: 'device_associated_rounds',
        title: 'devices:associated_rounds',
        renderValue: 'DeviceAssociatedRoundsSimpleString',
        disabledInput: true,
        props: { value: 'associated_rounds' },
        weight: 800
    }, {
        name: 'device_mode',
        title: 'devices:device_mode',
        renderValue: 'DeviceMode',
        disabledInput: true,
        props: { value: ['custom_field', 'config', 'modes'] },
        weight: 700
    }, {
        name: 'device_max_jobs',
        title: 'devices:device_max_jobs',
        subtitle: 'devices:jobs_tooltip',
        componentName: 'EditableField',
        renderValue: 'DeviceMaxSelectedJobs',
        editionComponent: 'DeviceJobConfigSelector',
        props: {
            value: ['custom_field', 'config', 'max_selected_jobs'],
            required: true,
            placeholder: '',
            multiple: false
        },
        weight: 900
    }, {
        name: 'emergency_phone',
        title: 'devices:emergency_phone',
        renderValue: 'DeviceSimpleString',
        disabledInput: true,
        props: {
            value: ['custom_field', 'config', 'emergency_phone'],
            placeholder: '',
            multiple: false
        },
        weight: 1000
    }, {
        name: 'emergency_label_fr',
        title: 'devices:emergency_label_fr',
        renderValue: 'DeviceSimpleString',
        disabledInput: true,
        props: {
            value: ['custom_field', 'config', 'emergency_label_fr'],
            placeholder: '',
            multiple: false
        },
        weight: 1100
    }, {
        name: 'emergency_label_en',
        title: 'devices:emergency_label_en',
        renderValue: 'DeviceSimpleString',
        disabledInput: true,
        props: {
            value: ['custom_field', 'config', 'emergency_label_en'],
            placeholder: '',
            multiple: false
        },
        weight: 1100
    }
    ],
    detailPopupParts: [
        {
            width: '50%',
            component: 'DeviceDetailsPopupLeft'
        },
        {
            width: '50%',
            component: 'DeviceDetailsPopupRight'
        }
    ]
});

let index;
let deleteIndex;
let device_data_types;
let colors = [];
let config = {};
let editing_device = {};
const selectedDevicesPreviewGraph = {};
let generateDataTypes = {};
let detailDevice;
let newDevicesList;

const apply = (b, action) => b.map(item => {
    const deviceAtUpdate = find(action.payload, device => device.device_id === item.device_id);
    if (deviceAtUpdate === undefined) return item;
    return { ...item, ...deviceAtUpdate };
});
const devicesReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case 'RESET_SELECTED_DEVICE':
            return update(state, {
                deviceShownInDetailsPopup: { $set: {} }
            });
        case 'GET_HARDWARE_FULFILLED':
            return update(state, { hardware_list: { $set: action.payload } });
        case 'ADD_DEVICE_FOOTBAR_BUTTON':
            return update(state, { footbarButtons: { $push: action.payload } });
        case 'REMOVE_DEVICE_FOOTBAR_BUTTON':
            newDevicesList = cloneDeep(state.footbarButtons);
            remove(newDevicesList, type => type.component === action.payload);
            return update(state, { footbarButtons: { $set: newDevicesList } });
        case 'ADD_DEVICE_FULFILLED':
            return update(state, { list: { $merge: action.payload } });
        case 'LOADING_DEVICE':
            return update(state, { loading: { $set: action.payload } });
        case 'TOGGLE_DEVICE_GRAPH_EDIT':
            return update(state, { editing: { $set: action.payload } });
        case 'TOGGLE_DEVICE_GRAPH_SAVE':
            return update(state, { saving: { $set: action.payload } });
        case 'CHANGE_DEVICE_DETAIL_POPUP_PARTS':
            return update(state, { detailPopupParts: { $set: action.payload } });
        case 'GET_GRAPH_DATA_DEVICE_FULFILLED':
            device_data_types = action.payload.graphData.map(item => item.data_type);
            config = action.payload.config;
            colors = state.selectedDevicesPreviewGraph.colors !== undefined
            && !isEmpty(state.selectedDevicesPreviewGraph.colors)
                ? state.selectedDevicesPreviewGraph.colors
                : initColors(device_data_types, config);
            return update(state, {
                selectedDevicesPreviewGraph: {
                    _graph_data: { $set: action.payload.graphData },
                    device_data_types: { $set: device_data_types },
                    colors: { $set: colors }
                }
            });
        case 'REMOVE_GROUP_FULFILLED':
        case 'REMOVE_GROUPS_FULFILLED':
            return update(state, {
                list: {
                    $apply: b => b.map(item => ({ ...item, group_ids: difference(item.group_ids, action.payload) }))
                }
            });
        case 'SET_PREVIEW_SETTINGS_FULFILLED':
            return update(state, {
                selectedDevicesPreviewGraph: { $merge: action.payload }
            });
        case 'UPDATE_DEVICE_PREVIEW_GRAPH':
            return update(state, {
                selectedDevicesPreviewGraph: { $set: action.payload }
            });
        case 'EMPTY_DEVICE_PREVIEW_GRAPH':
            return update(state, { selectedDevicesPreviewGraph: { $merge: {} } });
        case 'DELETE_DEVICE_FULFILLED':
        case 'DELETE_DEVICES_FULFILLED':
            newDevicesList = cloneDeep(state.list);
            action.payload.device_ids.forEach(device => {
                index = findIndex(newDevicesList, dev => dev.device_id === device);
                newDevicesList.splice(index, 1);
            });
            return update(state, {
                list: { $set: newDevicesList },
                selectedDevices: { $set: [] },
                selectedDevicesToConfigure: { $set: [] },
                editingDevice: { $set: {} }
            });
        case 'ADD_DEVICE_TO_GROUP':
        case 'ADD_DEVICE_TO_GROUP_FULFILLED':
        case 'ADD_GROUP_TO_DEVICE_FULFILLED':
            return update(state, {
                list: { $apply: b => apply(b, action) },
                selectedDevices: { $set: [] },
                selectedDevicesToConfigure: { $set: [] },
                editingDevice: { $set: {} }
            });
        case 'ADD_GROUP_TO_DEVICE_LOCALLY':
            newDevicesList = cloneDeep(state.list);
            action.payload.device_ids?.forEach(device => {
                index = findIndex(state.list, dev => dev.device_id === device);
                newDevicesList[index] = {
                    ...state.list[index],
                    group_ids: concat(state.list[index].group_ids, action.payload.group_id)
                };
            });
            return update(state, { list: { $set: newDevicesList } });
        case 'RESET_DEVICE_FULFILLED':
        case 'EDIT_DEVICE_GROUPS_FULFILLED':
        case 'EDIT_DEVICE_LOCALLY':
        case 'EDIT_DEVICE_FULFILLED':
            if (state.deviceShownInDetailsPopup.device_id === action.payload[0].device_id) {
                return update(state, {
                    list: { $apply: b => apply(b, action) },
                    selectedDevices: { $set: [] },
                    editingDevice: { $set: {} },
                    deviceShownInDetailsPopup: { $set: action.payload[0] }
                });
            }
            return update(state, {
                list: { $apply: b => apply(b, action) },
                selectedDevices: { $set: [] },
                editingDevice: { $set: {} }
            });
        case 'GET_DEVICE_LIST_FULFILLED':
            const devices = action.payload.devices || action.payload;
            return update(state, {
                list:
                    {
                        $set: devices.filter(device => !device.device_groups
                            || device.device_groups.length === 0
                            || (device.device_groups && device.device_groups.find(group => !group.linked)))
                    }
            });
        case 'GET_LAST_RECEIVED_DEVICE_DATA_FULFILLED':
            const devices_data = action.payload;
            if (devices_data) {
                let updated_devices_list = [];
                updated_devices_list = state.list.map(device => {
                    const device_data = devices_data.find(elt => elt.device_reference === device.device_reference);
                    if (device_data) {
                        return { ...device, hardware_ids: device_data.hardware_ids, data: device_data.data };
                    }
                    return { ...device, hardware_ids: [], data: [] };
                });

                return update(state, {
                    list:
                        {
                            $set: updated_devices_list
                        }
                });
            }
            return state;
        case 'GET_FILTRED_DEVICE_LIST_FULFILLED':
            newState.list = action.payload.data;
            break;
        case 'GET_DEVICE_TYPE_FULFILLED':
            return update(state, { device_data_types: { $set: action.payload } });
        case 'TOGGLE_SELECTED_DEVICE':
            editing_device = action.payload.length === 1
                ? state.list.find(device => device.device_id === action.payload[0])
                : {};
            return update(state, {
                selectedDevices: { $set: action.payload },
                editingDevice: { $set: editing_device }
            });
        case 'REMOVE_ALL_SELECTED_DEVICE':
            return update(state, {
                selectedDevices: { $set: [] },
                selectedDevicesToConfigure: { $set: [] }
            });
        case 'TOGGLE_SELECTED_DEVICE_TO_SET_PREVIEW':
            if (newState.selectedDevicesToSetPreview.includes(action.payload)) {
                newState.selectedDevicesToSetPreview = newState.selectedDevicesToSetPreview.filter(
                    id => id !== action.payload
                );
                newState.editingDevice = {};
            } else {
                newState.selectedDevicesToSetPreview = [
                    ...state.selectedDevicesToSetPreview,
                    action.payload
                ];
                newState.editingDevice = newState.list.filter(
                    device => device.device_id === action.payload
                )[0];
                newState.editingDevice = {};
            }
            break;
        case 'TOGGLE_SELECTED_DEVICE_TO_CONFIGURE':
            if (newState.selectedDevicesToConfigure.includes(action.payload)) {
                newState.selectedDevicesToConfigure = newState.selectedDevicesToConfigure.filter(
                    id => id !== action.payload
                );
                newState.editingDevice = {};
            } else {
                newState.selectedDevicesToConfigure = [
                    ...state.selectedDevicesToConfigure,
                    action.payload
                ];
                newState.editingDevice = newState.list.filter(
                    device => device.device_id === action.payload
                )[0];
                newState.editingDevice = {};
            }
            break;
        case 'DELETE_DEVICES_FROM_GROUP_FULFILLED':
            newState.selectedDevices = [];
            newState.selectedDevicesToConfigure = [];
            break;
        case 'REMOVE_GROUP_TO_DEVICE_FULFILLED':
            index = findIndex(
                state.list,
                device => device.device_id === action.payload.device_id
            );
            return update(state, {
                list: {
                    [index]: { group_ids: { $set: action.payload.group_ids } }
                }
            });
        case 'TOGGLE_ALL_NON_CONFIGURED_DEVICES':
            if (newState.selectedDevicesToConfigure.length === 0) {
                newState.selectedDevicesToConfigure = action.payload;
            } else {
                newState.selectedDevicesToConfigure = [];
            }
            newState.editingDevice = {};
            break;
        case 'TOGGLE_ALL_DEVICES':
            if (newState.selectedDevices.length === 0) {
                newState.selectedDevices = action.payload;
            } else {
                newState.selectedDevices = [];
            }
            newState.editingDevice = {};
            break;
        case 'EMPTY_SELECTED_DEVICES_LIST':
            newState.selectedDevices = [];
            newState.selectedDevicesToConfigure = [];
            break;
        case 'SET_FOCUSED_NAV_BUTTON':
            if (action.payload !== 'devices') {
                return update(state, {
                    selectedDevices: { $set: [] },
                    selectedDevicesToConfigure: { $set: [] },
                    editingDevice: { $set: {} }
                });
            }
            break;
        case "SWITCH_TYPE_GRAPH": // eslint-disable-line
            return update(state, {
                selectedDevicesPreviewGraph: {
                    type: { $set: action.payload.type },
                    is_last_value: { $set: action.payload.is_last_value },
                    start_offset_time: {
                        $set: state.selectedDevicesPreviewGraph.start_offset_time === null
                            ? action.payload.config.graph.start_offset_time
                            : state.selectedDevicesPreviewGraph.start_offset_time
                    }
                }
            });
        case 'SET_EDITING_DEVICE':
            return update(state, { editingDevice: { $set: action.payload } });
        case 'DEVICES_DELETED_FROM_GROUP':
        case 'REMOVE_FILTER':
            newState.selectedDevices = [];
            newState.selectedDevicesToConfigure = [];
            break;
        case 'SET_NON_CONFIGURED_DEVICES_VISIBILITY':
            newState.NonConfiguredDevicesVisibility = action.payload;
            break;
        case 'UPDATE_DEVICE_PREVIEW_DATA_FULFILLED':
            map(action.payload, (data, key) => {
                selectedDevicesPreviewGraph[key] = { $set: data };
            });
            return update(state, {
                selectedDevicesPreviewGraph: {
                    ...selectedDevicesPreviewGraph
                }
            });
        case 'GET_DEVICE_PREVIEW_DATA_FULFILLED':
            // TODO Patch error back, not return device data type
            generateDataTypes = Object.keys(
                groupBy(action.payload.data, data => data.data_type)
            );
            colors = !isEmpty(action.payload.preview_settings.colors)
                ? action.payload.preview_settings.colors
                : initColors(generateDataTypes);
            return update(state, {
                selectedDevicesPreviewGraph: {
                    _graph_data: { $set: [] },
                    device_data_types: { $set: generateDataTypes },
                    // device_data_types: { $set: action.payload.preview_settings.device_data_types },
                    device_ids: { $set: action.payload.preview_settings.device_ids },
                    name: { $set: 'Graph Preview' },
                    is_last_value: { $set: false },
                    type: { $set: action.payload.preview_settings.graph_type },
                    start_offset_time: {
                        $set: action.payload.preview_settings.start_offset_time
                    },
                    colors: { $set: colors }
                }
            });
        case 'EMPTY_DEVICE_PREVIEW_DATA':
            return update(state, {
                selectedDevicesPreviewGraph: { $set: {} },
                selectedDevicesPreviewSettings: { $set: {} }
            });
        case 'BULK_UPDATE_PREVIEW_SETTINGS_FULFILLED':
            return update(state, { selectedDevicesPreviewSettings: { $set: [] } });
        case 'LOGOUT_FULFILLED':
            return initialState();
        case 'SET_DEVICE_DETAIL':
            detailDevice = find(state.list, { device_id: action.payload });
            return update(state, { deviceShownInDetailsPopup: { $merge: { ...detailDevice, dataExport: [] }}});
        case 'GET_DEVICE_SUMMARY_FULFILLED':
            return update(state, {
                deviceShownInDetailsPopup: {
                    summary: { $set: action.payload.data.summary },
                    totalSummary: { $set: action.payload.data.count }
                }
            });
        case 'GET_DEVICE_METABASE_URL_FULFILLED':
            return update(state, {
                deviceShownInDetailsPopup: {
                    metabaseUrl: { $set: action.payload.data }
                }
            });
        case 'ADD_DEVICE_EDITABLE_FIELD':
            return update(state, { editableFields: { $push: [action.payload] } });
        case 'REMOVE_DEVICE_EDITABLE_FIELD':
            deleteIndex = state.editableFields.findIndex(d => d.name === action.payload);
            if (deleteIndex > -1) {
                return update(state, { editableFields: { $splice: [[deleteIndex, 1]] } });
            }
            return state;
        case 'GET_DEVICE_DATA_PREVIEW_FULFILLED':
            return update(state, {
                deviceShownInDetailsPopup: {
                    data: { $set: action.payload.data }
                }
            });
        case 'GET_DEVICE_DATA_EXPORT_FULFILLED':
        case 'GET_DEVICES_EXPORT_FULFILLED':
            return update(state, {
                deviceShownInDetailsPopup: {
                    dataExport: { $set: action.payload.data }
                }
            });
        case 'GET_DEVICE_FULFILLED':
            if (action.payload) {
                if (!isEmpty(action.payload?.workspace) && isEmpty(action.payload?.device_id) ) {
                    if (!window.location.href.includes('localhost') && localStorage.getItem('iot_rocket_workspace')) {
                        if (localStorage.getItem('iot_rocket_workspace') !== 'app') {
                            window.location = window.location.href.replace(localStorage.getItem('iot_rocket_workspace'), action.payload.workspace);
                        }
                    }
                } else {
                    return update(state, { list: { $set: [action.payload] } });
                }
            }
            return state;
        case 'ARCHIVE_ROUND_FULFILLED':
            newDevicesList = cloneDeep(state.list);
            newDevicesList.map(device => {
                if (action.payload.round_devices.some(e => e.device_id === device.device_id)) {
                    const index_to_delete = device.associated_rounds.findIndex(round => round.id === action.payload.id);
                    device.associated_rounds.splice(index_to_delete, 1);
                    return device;
                }
                return device;
            });
            return update(state, { list: { $set: newDevicesList } });
        case 'CREATE_ROUND_FULFILLED':
            newDevicesList = cloneDeep(state.list);
            newDevicesList.map(device => {
                if (action.payload.round_devices.some(e => e.device_id === device.device_id)) {
                    device.associated_rounds.push({ id: action.payload.id, name: action.payload.name });
                    return device;
                }
                return device;
            });
            return update(state, { list: { $set: newDevicesList } });
        case 'EDIT_ROUND_FULFILLED':
            newDevicesList = cloneDeep(state.list);
            newDevicesList.map(device => {
                // New device in round
                if (action.payload.round_devices.some(e => e.device_id === device.device_id)
                    && !device.associated_rounds.some(round => round.id === action.payload.id)) {
                    device.associated_rounds.push({ id: action.payload.id, name: action.payload.name });
                    return device;
                }
                // Device has been deleted from round
                if (device.associated_rounds.some(round => round.id === action.payload.id)
                    && !action.payload.round_devices.some(e => e.device_id === device.device_id)) {
                    const index_to_delete = device.associated_rounds.findIndex(round => round.id === action.payload.id);
                    device.associated_rounds.splice(index_to_delete, 1);
                    return device;
                }
                return device;
            });
            return update(state, { list: { $set: newDevicesList } });
        case 'DELETE_ROUND_FULFILLED':
            newDevicesList = cloneDeep(state.list);
            newDevicesList.map(device => {
                if (device.associated_rounds.some(round => action.payload.id.includes(round.id))) {
                    const index_to_delete = device.associated_rounds.findIndex(round => round.id === action.payload.id);
                    device.associated_rounds.splice(index_to_delete, 1);
                    return device;
                }
                return device;
            });
            return update(state, { list: { $set: newDevicesList } });
        default:
            return state;
    }
    return newState;
};

export default devicesReducer;

// Libs
import React from 'react';
import classnames from 'classnames';
// Context
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { useFootBar } from '../../footbar/FootBarContext';
import { useComponentsPool } from '../../../ComponentsPool';
import { getMatchingAttributesFromServiceNow } from '../helper';


const EditableField = props => {
    const { Component } = useComponentsPool();
    const footBar = useFootBar();
    const editionMode = footBar.getEditionMode();
    const [t] = useTranslation();
    if (props.props.required === undefined) props.props.required = props.required;
    const serviceNowSwitcher = useSelector(state => state.workspace.settings.servicenow_switcher);

    return (
        <div className={classnames('editable-field', 'info', props.multiline ? 'multiline-input' : '')}>
            <div className={'editable-field-title-container'}>
                <span className={'info-title'}>
                    {`${t(props.title)} ${getMatchingAttributesFromServiceNow(serviceNowSwitcher, props.props.serviceNowField)}`}
                    {props.title && (editionMode || !props.editionComponent) && props.props?.required ? ' *' : ''}
                </span>
                {props.subtitle && (
                    <Tooltip
                        className={'tooltip'}
                        classes={{ tooltip: 'table-tooltip' }}
                        placement={'top'}
                        title={t(props.subtitle)}
                    >
                        <div>
                            <Component componentName={'Icon'} type={'info'} />
                        </div>
                    </Tooltip>
                )}
            </div>

            { editionMode && !props.disabledInput ? (
                <Component
                    componentName={props.editionComponent}
                    onChange={props.onChange}
                    {...props.props}
                    value={props.value} // erase value with getValue()
                    onBlur={props.onBlur ? props.onBlur : () => { }}
                />
            ) : (
                <Component
                    componentName={props.renderValue}
                    {...props.props}
                />
            )}
        </div>
    );
};

export default EditableField;

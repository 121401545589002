// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import { cancelNotification } from '../actions';

const ConfirmButton = props => {
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const [t] = useTranslation();

    return (
        <div className={'confirm-button'}>
            <Component
                componentName={'Button'}
                text={t('common:cancel')}
                onClick={() => dispatch(cancelNotification())}
            />
            <Component
                componentName={'Button'}
                text={t('common:follow')}
                onClick={async () => {
                    await dispatch(cancelNotification());
                    props.callback();
                }}
            />
        </div>
    );
};

export default ConfirmButton;

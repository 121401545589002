// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Context
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useIsOnline } from 'react-use-is-online';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { isEmpty } from 'lodash';
import Lottie from 'react-lottie';
// Icons
import Icon from '../../layout/components/Icon';
import successAnimation from '../../qrcodes/assets/check.json';
import pendingNetworkAnimation from '../../qrcodes/assets/pendingNetwork.json';
// Actions
import { savePassage } from '../../devices/actions';
import createLoadingSelector from '../../layout/actions';
// Helpers
import { useComponentsPool } from '../../../ComponentsPool';
import { canSeeMobileRoundView } from '../../users/helper';
import {
    EVENT_CANT_VALIDATE_QRCODE_PASSAGE,
    EVENT_VALIDATE_QRCODE_OFFLINE_PASSAGE,
    EVENT_VALIDATE_QRCODE_PASSAGE,
    segmentTrack
} from '../../layout/helper';
import UserLock from '../../qrcodes/assets/userLock.svg';
import LockUser from '../../qrcodes/assets/lockUser.svg';
import { setIsLoggable } from '../../users/actions';

const loadingSelector = createLoadingSelector(['GET_DEVICE', 'GET_USER_LIST', 'SAVE_PASSAGE']);

const ValidationPassage = props => {
    const [t] = useTranslation();
    const history = useHistory();
    const { isOnline } = useIsOnline();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const currentUser = useSelector(state => state.users.currentUser);
    const isLogged = useSelector(state => state.authentication.isLogged);
    const devicesList = useSelector(state => state.devices.list);
    const [passageDevice, setPassageDevice] = useState({});
    const [validationStatus, setValidationStatus] = useState('initialization');
    const [selectedJobs, setSelectedJobs] = useState([]);
    const [displayJobsDetail, setDisplayJobsDetail] = useState(false);
    const canSelectMultipleJobs = passageDevice.custom_field?.config?.max_selected_jobs?.toString() !== '1' && passageDevice.custom_field?.config?.max_selected_jobs;
    const [currentPassageId, setCurrentPassageId] = useState(null);


    useEffect(() => {
        if (devicesList?.length > 0) {
            setPassageDevice(devicesList[0]);
            setValidationStatus('not_ready');
        } else { setValidationStatus('disabled'); }
    }, [devicesList]);

    const getDeviceLocation = () => {
        const building = passageDevice.batiment_group ? `${passageDevice.batiment_group.group_name}` : '';
        const floor = passageDevice.floor_group ? ` - ${passageDevice.floor_group.group_name}` : '';
        const position = passageDevice.position_group ? ` - ${passageDevice.position_group.group_name}` : '';
        return building + floor + position;
    };

    const getBackToRoundViewComponent = () => {
        if (canSeeMobileRoundView(currentUser)) {
            const roundPath = window.location.href.replace(history.location.pathname, '/rounds');
            return (
                <div className={'qrcode-button'}>
                    <Button onClick={() => { window.location.href = roundPath; }}>
                        <p className={'qrcode-button-text'}>{t('rounds:go_to_round_view')}</p>
                    </Button>
                </div>
            );
        }
        return <></>;
    };

    const getJobsList = () => {
        const selectSeveralJobs = (e, jobToAddOrRemove) => {
            const selectedJobsCopy = [...selectedJobs];
            if (!selectedJobsCopy.find(selectedJob => selectedJob === jobToAddOrRemove)) {
                selectedJobsCopy.push(jobToAddOrRemove);
            } else {
                selectedJobsCopy.splice(selectedJobsCopy.indexOf(jobToAddOrRemove), 1);
            }
            setSelectedJobs(selectedJobsCopy);
        };

        if (passageDevice.job_groups?.length > 0) {
            const displayGroupsOnOneColumn = passageDevice.job_groups.find(jobGroup => jobGroup.group_name.length > 40);
            return (
                <div className={'jobs-container'}>
                    <h3>{t('passages:choose_passage_to_validate')}</h3>
                    <span className={'device-locations'}>{getDeviceLocation()}</span>
                    {/* Checkbox to select every job at once */}
                    {/* (if multiple jobs can be selected and there are more than one job associated to the qrcode) */}
                    { canSelectMultipleJobs && passageDevice.job_groups.length > 1 && (
                        <div
                            className={'all-jobs-checkbox'}
                            onClick={() => {
                                setSelectedJobs(selectedJobs.length > 0 ? [] : passageDevice.job_groups.map(jobGroup => jobGroup.group_name));
                            }}
                        >
                            {/* double div required to align to the right */}
                            <div className={'all-jobs-checkbox-content'}>
                                <Checkbox
                                    checked={selectedJobs.length === passageDevice.job_groups.length}
                                    indeterminate={selectedJobs.length > 0 && selectedJobs.length !== passageDevice.job_groups.length}
                                />
                                <span>{t('common:all_select')}</span>
                            </div>
                        </div>
                    )}
                    <div className={classNames(['jobs-wrapper', { 'jobs-on-one-column': displayGroupsOnOneColumn }])}>
                        { passageDevice.job_groups.map(jobGroup => {
                            const jobName = jobGroup.group_name;
                            return (
                                <div
                                    key={jobGroup.group_id}
                                    className={classNames({
                                        'choice-job': true,
                                        'choice-job-selected': selectedJobs.includes(jobName)
                                    })}
                                    role={'button'}
                                    tabIndex={0}
                                    onClick={canSelectMultipleJobs
                                        ? e => { selectSeveralJobs(e, jobName); }
                                        : () => { setSelectedJobs([jobName]); }
                                    }
                                >
                                    <div className="form-text">{ jobName }</div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="qrcode-button">
                        <Button
                            key={'validate_with_job'}
                            className={'validate-job'}
                            disabled={isEmpty(selectedJobs)}
                            onClick={() => {
                                dispatch(savePassage(passageDevice.device_id, currentUser.id, selectedJobs))
                                    .then(response => {
                                        setCurrentPassageId(response.value?.id);
                                        if (response?.value?.message === 'PENDING_NETWORK') {
                                            // Segment implements offline strategy to send track later
                                            segmentTrack(EVENT_VALIDATE_QRCODE_OFFLINE_PASSAGE,
                                                { qrCodeId: passageDevice.device_id }
                                            );
                                            setValidationStatus('pending_network');
                                        } else {
                                            segmentTrack(EVENT_VALIDATE_QRCODE_PASSAGE,
                                                { qrCodeId: passageDevice.device_id }
                                            );
                                            setValidationStatus('validated');
                                        }
                                    });
                            }}
                        >
                            <p className={'qrcode-button-text'}>{t('passages:validate_passage')}</p>
                        </Button>
                    </div>
                </div>
            );
        }
        return (
            <div className="validation-passage-wrapper">
                <h3 className='validation-passage-location'>{getDeviceLocation()}</h3>
                <div className="qrcode-button">
                    <Button
                        key={'validate_with_job'}
                        className={'validate-job'}
                        onClick={() => {
                            dispatch(savePassage(passageDevice.device_id, currentUser.id, selectedJobs))
                                .then(response => {
                                    setCurrentPassageId(response.value?.id);
                                    if (response?.value?.message === 'PENDING_NETWORK') {
                                        // Segment implements offline strategy to send track later
                                        segmentTrack(EVENT_VALIDATE_QRCODE_OFFLINE_PASSAGE,
                                            { qrCodeId: passageDevice.device_id }
                                        );
                                        setValidationStatus('pending_network');
                                    } else {
                                        segmentTrack(EVENT_VALIDATE_QRCODE_PASSAGE,
                                            { qrCodeId: passageDevice.device_id }
                                        );
                                        setValidationStatus('validated');
                                    }
                                });
                        }}
                    >
                        <p className={'qrcode-button-text'}>{t('passages:validate_passage_without_job')}</p>
                    </Button>
                </div>
            </div>
        );
    };

    const getValidationForm = () => {
        switch (validationStatus) {
            case 'report_problem':
                return (
                    <div className="report-problems-container">
                        <h3>{t('passages:add_comment_without_validation_question')}</h3>
                        <Component
                            relatedPassageId={null}
                            sendingType={'DeviceComment'}
                            componentName={'ValidationPassageComment'}
                            resourceId={passageDevice.device_id}
                            enabledEnter
                        />
                        { getBackToRoundViewComponent() }
                    </div>
                );
            case 'not_ready':
                return (
                    <div className={'validation-passage-container'}>
                        { getJobsList() }
                        {
                            isOnline && (
                                <div className={'comment-link-container'}>
                                    <div className={'or-line-container'}>
                                        <div className={'or-line'} />
                                        <span>{t('common:or')}</span>
                                        <div className={'or-line'} />
                                    </div>
                                    <div
                                        className={'comment-link'}
                                        onClick={() => {
                                            setValidationStatus('report_problem');
                                            segmentTrack(EVENT_CANT_VALIDATE_QRCODE_PASSAGE, { qrCodeId: passageDevice.device_id });
                                        }}
                                    >
                                        <Icon type={'caution'} />
                                        <span>{t('passages:add_comment_without_validation')}</span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                );
            case 'pending_network':
            case 'validated':
                return (
                    <div className="success-result success-with-redirection-to-round">
                        <span className={'device-locations'}>{getDeviceLocation()}</span>
                        <div className="validate-result">
                            <div className={'validate-icon'}>
                                <Lottie
                                    options={{
                                        loop: false,
                                        autoplay: true,
                                        animationData: validationStatus === 'validated'
                                            ? successAnimation
                                            : pendingNetworkAnimation
                                    }}
                                    height={'100%'}
                                    width={'100%'}
                                />
                            </div>
                        </div>
                        {
                            selectedJobs?.length === 0
                                ? (
                                    <>
                                        <div className={'jobs-detail'}>
                                            <h3>
                                                {
                                                    validationStatus === 'validated'
                                                        ? t('common:thank_you_without_job')
                                                        : t('common:passage_validation_pending_network')
                                                }
                                            </h3>
                                        </div>
                                        <div
                                            className={classNames({ 'validate-jobs': true, opened: displayJobsDetail })}
                                        >
                                            <h3 className={'highligth-text'}>
                                                {selectedJobs.map(job => <span key={job}>{job}</span>)}
                                            </h3>
                                        </div>
                                    </>
                                )
                                : (
                                    <>
                                        <div
                                            role={'button'}
                                            className={'jobs-detail'}
                                            onClick={() => { setDisplayJobsDetail(!displayJobsDetail); }}
                                            tabIndex={0}
                                        >
                                            <h3>
                                                {
                                                    validationStatus === 'validated'
                                                        ? `${t('common:thank_you')} ${selectedJobs?.length} ${selectedJobs?.length === 1
                                                            ? t('passages:validated_passage_message')
                                                            : t('passages:validated_passages_message')}`
                                                        : t('common:passage_validation_pending_network')
                                                }
                                            </h3>
                                            <Icon type={displayJobsDetail ? 'chevronUp' : 'chevronBottom'} />
                                        </div>
                                        <div
                                            className={classNames({ 'validate-jobs': true, opened: displayJobsDetail })}
                                        >
                                            <h3 className={'highligth-text'}>
                                                <ul>{selectedJobs.map(job => <li key={job}>{job}</li>)}</ul>
                                            </h3>
                                        </div>
                                    </>
                                )
                        }
                        {
                            validationStatus === 'validated'
                                && (
                                    <Component
                                        relatedPassageId={currentPassageId}
                                        sendingType={'DeviceComment'}
                                        componentName={'ValidationPassageComment'}
                                        resourceId={passageDevice.device_id}
                                        enabledEnter
                                    />
                                )
                        }
                        { getBackToRoundViewComponent() }
                    </div>
                );
            case 'disabled':
            default:
                return <Component componentName={'MobileErrorPage'} />;
        }
    };

    const getCannotAccessValidationPage = () => {
        return (
            <>
                {isLogged && <Component componentName={'MobileTopUserBanner'} hasGoBackArrow={props.qrcodesHasManyMode} />}
                <Component
                    componentName={'MobileErrorPage'}
                    icon={!isLogged ? <LockUser /> : <UserLock />}
                    title={!isLogged ? t('common:login_required') : t('common:unauthorized_access')}
                    subtitle={!isLogged ? t('passages:login_to_validate_passage') : t('common:disabled_passage_page')}
                    hasButton={!isLogged}
                    buttonAction={() => { dispatch(setIsLoggable(true)); } }
                    buttonText={t('common:log_in_second')}
                    hasGoBackArrow={!isLogged && props.qrcodesHasManyMode} // When the user is logged he can go back via the arrow on the MobileTopUserBanner
                />
            </>
        );
    };

    const mustntSeeValidationForm = !isLogged || currentUser?.workspace_role?.workspace_role_name === 'User';
    return (
        <div className="validation-passage-page">
            {mustntSeeValidationForm ? getCannotAccessValidationPage()
                : (
                    <>
                        <Component
                            componentName={'MobileTopUserBanner'}
                            hasGoBackArrow={props.qrcodesHasManyMode && validationStatus !== 'validated' && validationStatus !== 'report_problem'}
                        />
                        {isLoading
                            ? <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
                            : validationStatus !== 'initialization' && getValidationForm()
                        }
                    </>
                )
            }
        </div>
    );
};

export default ValidationPassage;

// Library
import React from 'react';
import classnames from 'classnames';
import { useIsOnline } from 'react-use-is-online';

// Context
import { useComponentsPool } from '../../ComponentsPool';

const Layout = () => {
    const { isOnline } = useIsOnline();

    const { Component } = useComponentsPool();
    return (
        <div className={classnames(['main-wrapper', { 'offline-mode': !isOnline }])}>
            <Component
                componentName={'TopSection'}
            />
            <Component
                componentName={'MiddleSection'}
            />
            <Component
                componentName={'BottomSection'}
            />
            <Component
                componentName={'OffgridSection'}
            />
        </div>
    );
};

export default Layout;


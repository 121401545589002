// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    isEmpty, find, includes, toLower, isString
} from 'lodash';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { uuid } from 'uuidv4';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { makeStyles, Modal } from '@material-ui/core';
// Context
import classnames from 'classnames';
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import createLoadingSelector from '../../layout/actions';
// styles
import '../styles/_ticket_logs.scss';
import { getUserFromId, getUserName, getUserNameFromEmail, isUserWithoutMail } from '../../users/helper';
import { getDeviceFromId, getDeviceNameFromId, isQRCodeDeclarativeDeviceType } from '../../devices/helper';
import { capitalize } from '../../core/helper';
import { getMessagingUserFromId } from '../../messaging/helper';
import { getGroupNameFromId, getTraductionOfGroup } from '../../groups/helper';
import { useFootBar } from '../../footbar/FootBarContext';
import {
    deleteComment, postTicketComments, shareTicketComment
} from '../actions';
import Icon from '../../layout/components/Icon';
import {
    downloadFileFromUrl, fileTypeAllowedForDocViewer, getInputFileUrlForThumbnail, iconForFileWithoutThumbnail, nbMaxFilesByInput
} from '../../layout/helper';
import FileViewer from '../../documents/components/FileViewer';

const useStyles = makeStyles(() => ({
    tooltip: {
        width: 150
    }
}));

const useStylesSlackTooltip = makeStyles(() => ({
    tooltip: {
        background: '#F2EEF3 !important',
        color: '#611f69 !important',
        padding: '15px !important'
    }
}));

const slackTooltip = useStylesSlackTooltip();

const loadingSelector = createLoadingSelector(['GET_TICKET_SUMMARY', 'GET_TICKET_COMMENTS', 'DELETE_COMMENT', 'POST_TICKET_COMMENTS']);
const TicketLogs = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const userList = useSelector(state => state.users.list);
    const deviceList = useSelector(state => state.devices.list);
    const currentUser = useSelector(state => state.users.currentUser);
    const messagingUserList = useSelector(state => state.messaging.list);
    const userRole = currentUser.workspace_role?.workspace_role_name;
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const logsSubjects = useSelector(state => state.logs.list);
    const groupList = useSelector(state => state.groups.list);
    const hide_user_author = userRole === 'User' && workspaceSettings.hide_user_author
        ? workspaceSettings.hide_user_author
        : false;
    const ticket = useSelector(state => state.tickets.ticketShownInDetailsPopup);
    const [ticketAuthorType, setTicketAuthorType] = useState('USER');
    const footbar = useFootBar();
    const dispatch = useDispatch();
    const [logComment, setLogComment] = useState('');
    const [emptyInput, setEmptyInput] = useState(false);
    const classes = useStyles();
    const editMode = footbar.getEditionMode();
    const [comments, setComments] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [previewedFile, setPreviewedFile] = useState(null);
    const [hoveredComIdx, setHoveredComIdx] = useState(null);
    const [displayDownloadButton, setDisplayDownloadButton] = useState(null);
    const commentExternalToPlatform = custom_field => custom_field?.type === 'TEAMS_MESSAGE' || custom_field.type === 'SLACK_MESSAGE'
        || custom_field.via_share === 'SLACK' || custom_field.via_share === 'TEAMS' || custom_field.via_share === 'EXTERNAL_FOLLOWER';

    const showShareIcon = () => {
        if (!isUserWithoutMail(currentUser)) {
            if (ticketAuthorType.includes('SLACK') || ticketAuthorType.includes('TEAMS')) {
                return currentUser.workspace_role?.workspace_role_name === 'Owner'
                    || currentUser.workspace_role?.workspace_role_name === 'Admin'
                    || currentUser.workspace_role?.workspace_role_name === 'Contractor' && workspaceSettings.show_chatbot_sharing_to_contractor;
            }
            if (ticketAuthorType === 'DEVICE' && isQRCodeDeclarativeDeviceType(getDeviceFromId(deviceList, ticket?.custom_field.author_device_id))) {
                return ticket.external_followers.length > 0 && (
                        currentUser.workspace_role?.workspace_role_name === 'Owner'
                        || currentUser.workspace_role?.workspace_role_name === 'Admin'
                        || currentUser.workspace_role?.workspace_role_name === 'Contractor'
                );
            }
        }
        return false;
    };

    const shareButtonSubject = () => {
        if (ticketAuthorType === 'DEVICE' && isQRCodeDeclarativeDeviceType(getDeviceFromId(deviceList, ticket?.custom_field.author_device_id))) {
            return 'EXTERNAL_FOLLOWER';
        }
        return ticketAuthorType;
    };

    const canShareCommOnChatbotIfIsCommAuthor = comment => {
        if (currentUser.workspace_role?.workspace_role_name === 'Owner') {
            return true;
        }
        return comment?.author === currentUser.id;
    };

    const showDeleteCommentIcon = log => editMode && (log.author === currentUser?.id || currentUser.workspace_role?.workspace_role_name === 'Admin' || currentUser.workspace_role?.workspace_role_name === 'Owner')

    const showEditableInputComment = log => editMode && log.author === currentUser?.id && !isMobile;

    const sendMessage = () => {
        let urls = [];
        if (!isEmpty(uploadedFiles)) { urls = uploadedFiles; }
        dispatch(postTicketComments(props.resourceId, logComment, currentUser.id, { type: 'MERCIYANIS_MESSAGE' }, urls));
        setEmptyInput(!emptyInput);
        setLogComment('');
        setUploadedFiles([]);
    };

    const shareComment = ticketTypeParam => {
        let urls = [];
        if (!isEmpty(uploadedFiles)) { urls = uploadedFiles; }
        dispatch(postTicketComments(props.resourceId, logComment, currentUser.id, { type: 'MERCIYANIS_MESSAGE' }, urls)).then(comment => {
            if (comment.value?.data?.id) {
                dispatch(shareTicketComment(comment.value.data.id, ticketTypeParam));
            }
        });
        setEmptyInput(!emptyInput);
        setLogComment('');
        setUploadedFiles([]);
    };

    const removeComment = log => {
        dispatch(deleteComment(log.id));
    };


    const disableSendComment = isEmpty(logComment?.trim()) && isEmpty(uploadedFiles) || logComment === 'error_field' || !ticket.active;
    let disableSharedButton = disableSendComment;
    if (shareButtonSubject() !== 'TEAMS') {
        disableSharedButton = isEmpty(logComment?.trim()) || !isEmpty(uploadedFiles) || logComment === 'error_field';
    }

    // everytime we change the comment or a field is being edited,
    // we need to disable the save if the comment is not sent
    useEffect(() => {
        if (footbar.isEdited) {
            if (!disableSendComment) {
                footbar.set({ editionObject: { isNotSentComment: true } });
            } else {
                footbar.set({ editionObject: { isNotSentComment: false } });
            }
        }
    }, [logComment, footbar.isEdited]);

    useEffect(() => {
        let authorType = 'USER';
        if (ticket.author) {
            authorType = 'USER';
        } else if (ticket.custom_field.author_device_id) {
            authorType = 'DEVICE';
        } else if (ticket.custom_field.author_messaging_user_id) {
            // TEAMS or SLACK
            authorType = getMessagingUserFromId(messagingUserList, ticket?.custom_field.author_messaging_user_id)?.type
                || 'USER';
        } else if (ticket.custom_field?.round_instance_id) {
            authorType = 'ROUND';
        }
        setTicketAuthorType(authorType);
    }, [ticket]);

    const isHiddenLog = (log, type) => hide_user_author && log.user_id !== currentUser?.id && type.includes('OUTPUTS')
        || (type.includes('OUTPUTS') && (log?.modification?.new?.custom_field?.resolution_date
        || log?.modification?.old?.custom_field?.resolution_date)
        || (log?.modification?.new?.custom_field?.taking_into_account_date
        || log?.modification?.old?.custom_field?.taking_into_account_date));

    const getLogType = log => {
        /*
            Here the different types of log :
            - OUTPUTS_LOG_CREATION
            - OUTPUTS_LOG_CREATION_FROM_DECLARATIVE_QRCODE
            - OUTPUTS_LOG_UPDATE_STATUS
            - OUTPUTS_LOG_UPDATE_FIELDS
            - OPERATION_SAMFM
            - OPERATION_SERVICENOW
            - OPERATION_DOIT
            - OPERATION_SHARINGCLOUD
            - OPERATION_SPINALCOM
            - OPERATION_ONEATALIAN
            - COMMENT_MERCIYANIS_MESSAGE
            - COMMENT_DEVICE_MESSAGE
            - COMMENT_SLACK_MESSAGE
            - COMMENT_TEAMS_MESSAGE
            - COMMENT_DOIT_MESSAGE
            - ROUND_INSTANCE_MESSAGE
        */
        let type = 'UNKNOWN';
        if (log.modification) { // OUTPUT_LOG TYPE
            if (log.action_type === 'CREATE') {
                const deviceId = log?.modification?.new?.custom_field?.author_device_id;
                if (deviceId && isQRCodeDeclarativeDeviceType(getDeviceFromId(deviceList, deviceId))) {
                    type = 'OUTPUTS_LOG_CREATION_FROM_DECLARATIVE_QRCODE';
                } else {
                    type = 'OUTPUTS_LOG_CREATION';
                }
            } else if (log.modification?.new?.status) {
                type = 'OUTPUTS_LOG_UPDATE_STATUS';
            } else {
                type = 'OUTPUTS_LOG_UPDATE_FIELDS';
            }
        } else if (log.tool_type) { // OPERATION TYPE (=SamFM, ServiceNow etc)
            type = `OPERATION_${log.tool_type.toUpperCase()}`;
        } else if (log.content || log.urls) { // COMMENT TYPE
            if (log.custom_field?.via_share) {
                type = `COMMENT_${log.custom_field?.via_share}_MESSAGE`;
            } else if (log.custom_field?.type === 'ROUND_INSTANCE_MESSAGE') {
                type = 'ROUND_INSTANCE_MESSAGE';
            } else {
                type = `COMMENT_${log.custom_field?.type}`;
            }
        }
        return type;
    };

    const getIconComponent = (iconType, iconExtraStyle, icon, userRef) => {
        switch (iconType) {
            case 'bubble':
                return <div className={`bubble ${iconExtraStyle}`} />;
            case 'icon':
                return (
                    <div className={iconExtraStyle}>
                        <Component
                            componentName={'Icon'}
                            type={icon}
                        />
                    </div>
                );
            case 'avatar':
                if (!isEmpty(userRef)) {
                    return (
                        <Component
                            componentName={'UserAvatar'}
                            picture={icon}
                            user={userRef}
                            size="28"
                        />
                    );
                } else {
                    return (
                        <Avatar
                            src={'https://res.cloudinary.com/iotv-keepcontact/image/upload/v1528904820/iot-rocket/user.png'}
                            size="25"
                            round
                        />
                    );
                }
            default:
                return <div className={'bubble'} />;
        }
    };

    const onChangeComment = (newContent, logObj) => {
        let flag = false;
        let popIndex = null;
        const tempComments = comments || [];
        tempComments.forEach((comment, idx) => {
            if (comment?.id === logObj?.id) {
                flag = true;

                if (logObj.content === newContent) {
                    popIndex = idx;
                } else if (comment) {
                    comment.content = newContent;
                }
            }
        });
        if (popIndex !== null) {
            tempComments.splice(popIndex, 1);
        }
        if (!flag) {
            tempComments.push({
                id: logObj?.id,
                content: newContent
            });
        }
        setComments(tempComments);
        props.updateState('updatedComments', comments);
    };

    // eslint-disable-next-line max-len
    const getContentComponent = (log, logIdx, contentType, contentExtraStyle, content, contentHeader,
        isEditableComment = false, isShareableComment = false, isDeleteableComment= false, multipleContent = false) => {
        if (contentType === 'text') {
            return (
                <div className={`log-text ${contentExtraStyle}`}>
                    <ListItemText primary={content} />
                </div>
            );
        } if (contentType === 'comment') {
            return (
                <div className={'comment'}>
                    <div className={'comment-header'}>
                        <div className={'text'}>{contentHeader}</div>
                        <div
                            className={'comment-actions'}
                            onMouseOver={() => setHoveredComIdx(logIdx)}
                            onMouseLeave={() => setHoveredComIdx(null)}
                        >
                            {/* Display the bin icon */}
                            { isDeleteableComment && showDeleteCommentIcon(log) && (
                                <Tooltip title={t('tickets:comment_remove')}>
                                    <Button className={'bin-button'} onClick={() => removeComment(log)} color={'primary'}>
                                        <Component componentName={'Icon'} type={'trash'} />
                                    </Button>
                                </Tooltip>
                            )}
                            {/* Display the arrow icon to share already existing comment*/}
                            { isShareableComment && (!editMode || isMobile) && showShareIcon() && canShareCommOnChatbotIfIsCommAuthor(log) && (
                                <Tooltip
                                    classes={{ tooltip: classes.tooltip }}
                                    title={t(`tickets:share_via_${shareButtonSubject()}`)}
                                    placement={'left'}
                                >
                                    <Button
                                        className={`share-comment-button ${toLower(shareButtonSubject())}-button`}
                                        onClick={() => dispatch(shareTicketComment(log?.id, shareButtonSubject()))}
                                    >
                                        <Component componentName={'Icon'} type={shareButtonSubject()} />
                                        <Component componentName={'Icon'} type={'shareArrowRight'} />
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    { isEditableComment && showEditableInputComment(log)
                        ? (
                            <Component
                                componentName={'Input'}
                                label={''}
                                onChange={value => onChangeComment(value, log)}
                                value={log.content}
                                multiline={4}
                                rules={['maxLength-512', 'selectNotEmpty']}
                            />
                        ) : (
                            <div className={classnames(!multipleContent && 'comment-content', footbar.getEditionMode() && 'comment-content-edition',
                                (!multipleContent && commentExternalToPlatform(log?.custom_field)) && `comment-from-${toLower(shareButtonSubject())}`)}
                            >
                                {content}
                            </div>
                        )
                    }
                </div>
            );
        }
        return <></>;
    };

    const getAuthorNameFromLog = (log, type) => {

        if (type.includes('OUTPUTS_')) {
            if (type === 'OUTPUTS_LOG_CREATION_FROM_DECLARATIVE_QRCODE') {
                if (log.user_id) { // If the ticket was created by someone with an account
                    const user = getUserFromId(userList, log.user_id);
                    if (user) {
                        return {
                            userAuthor: getUserName(user) || toLower(t('users:unknown')),
                            qrcodeAuthor: getDeviceNameFromId(deviceList, log.modification?.new?.custom_field?.author_device_id)
                        };
                    }
                } else { // If the ticket is from an anonymous person
                    return {
                        userAuthor: toLower(t('users:anonymous_user')),
                        qrcodeAuthor: log.username || toLower(t('devices:unknown'))
                    };
                }
            }
            if (hide_user_author && log.user_id !== currentUser?.id) {
                return log.user_id || log.author
                    ? t('users:unknown')
                    : t('devices:unknown');
            } if (log.user_id) {
                const user = getUserFromId(userList, log.user_id);
                if (user) {
                    return getUserName(user) || t('users:unknown');
                }
            }
            return log.username || t('devices:unknown');
        }
        if (log?.author) {
            const user = getUserFromId(userList, log.author);
            if (user) {
                return getUserName(user) || t('users:unknown');
            }
        } if (log?.custom_field?.author_device_id) {
            return getDeviceNameFromId(deviceList, log.custom_field.author_device_id)
                || t('devices:unknown');
        } if (log?.custom_field?.author_messaging_user_id) {
            return getMessagingUserFromId(messagingUserList, log.custom_field.author_messaging_user_id)?.user_name
                || t('users:unknown');
        }
        return log.username || t('logs:unknown');
    };

    // Sets the tooltip for each modification on a line
    const GenerateTooltip = ({ fields, length }) => fields.map((field, idx) => (
        <span key={field?.id}>
            {idx !== length - 1 && idx !== 0 && <span>{', '}</span>}
            {length !== 1 && idx === length - 1 && <span>{` ${t('common:and')} `}</span>}
            {field.newValue || field.oldValue ? (
                <Tooltip title={`${field.oldValue} -> ${field.newValue}`}>
                    <span className={'event_log'}>{field.text}</span>
                </Tooltip>
            ) : <span className={'event_log'}>{field.text}</span>}
        </span>
    ));

    const getFieldsModificationContent = (log, source) => {
        const target = log.modified_object_name || '';
        let newObject = log.modification?.new;
        let oldObject = log.modification?.old;
        const modifiedFields = [];

        if (log.action_type === 'ADD_ASSIGNER_USER' || log.action_type === 'REMOVE_ASSIGNER_USER') {
            oldObject = isString(oldObject)
                ? oldObject
                    .replace(/"/g, '')
                    .replace('[', '')
                    .replace(']', '')
                    .replace(/\s/g, '')
                    .split(',')
                : [];
            newObject = isString(newObject)
                ? newObject.replace(/"/g, '')
                    .replace(/"/g, '')
                    .replace('[', '')
                    .replace(']', '')
                    .replace(/\s/g, '')
                    .split(',')
                : [];

            const editionLog = find(logsSubjects, log => log?.name === 'assigners');
            let oldAssigners = '';
            if (!isEmpty(oldObject)) {
                oldObject.forEach(obj => {
                    if (oldAssigners !== '') oldAssigners = `${oldAssigners }, `;
                    oldAssigners = oldAssigners + getUserNameFromEmail(userList, obj);
                });
            }
            let newAssigners = '';
            if (!isEmpty(newObject)) {
                newObject.forEach(obj => {
                    if (newAssigners !== '') newAssigners = `${newAssigners }, `;
                    newAssigners = newAssigners + getUserNameFromEmail(userList, obj);
                });
            }
            modifiedFields.push({
                id: uuid(),
                text: !isEmpty(target) ? t(editionLog.translate) : t(editionLog.translateNoSubject),
                oldValue: oldAssigners,
                newValue: newAssigners
            });
        } else { // action_type === 'UPDATE'
            let keys = Object.keys(newObject);
            if (isEmpty(keys)) keys = Object.keys(oldObject);
            let editionLog;
            if (keys) {
                keys.forEach(key => {
                    editionLog = find(logsSubjects, log => log?.name === key);
                    let oldValue = '';
                    let newValue = '';
                    if (editionLog) {
                        oldValue = key === 'priority'
                            ? t(`tickets:priority_${oldObject[key]}`)
                            : oldObject[key];
                        newValue = key === 'priority'
                            ? t(`tickets:priority_${newObject[key]}`)
                            : newObject[key];
                        modifiedFields.push({
                            id: uuid(),
                            text: t(editionLog.translate),
                            oldValue,
                            newValue
                        });
                    }
                });
            }

            if (newObject?.custom_field || oldObject?.custom_field) {
                keys = Object.keys(newObject.custom_field);
                let oldGroup;
                let newGroup;
                if (keys) {
                    keys.forEach(key => {
                        oldGroup = '';
                        newGroup = '';
                        if (includes(key, '_id')) {
                            if (oldObject.custom_field[key] !== '') oldGroup = getTraductionOfGroup(getGroupNameFromId(groupList, oldObject.custom_field[key]), t);
                            else oldGroup = '';
                            if (newObject.custom_field[key] !== '') newGroup = getTraductionOfGroup(getGroupNameFromId(groupList, newObject.custom_field[key]), t);
                            else newGroup = '';

                            if (oldGroup !== undefined && newGroup !== undefined) {
                                modifiedFields.push({
                                    id: uuid(),
                                    text: target ? t(`logs:${key}`) : t(`logs:${key}`),
                                    oldValue: oldGroup,
                                    newValue: newGroup
                                });
                            }
                        } else if (key === 'counter') {
                            modifiedFields.push({
                                id: uuid(),
                                text: t('logs:edit_counter'),
                                oldValue: oldObject.custom_field[key],
                                newValue: newObject.custom_field[key]
                            });
                        }
                    });
                }
            }
        }

        if (isEmpty(modifiedFields)) {
            return '';
        }
        return (
            <>
                <span>{t('logs:updated', { source })}</span>
                <GenerateTooltip
                    fields={modifiedFields}
                    length={modifiedFields.length}
                />
            </>
        );
    };

    const getContentFromOperationTool = (log, type) => {
        if (type.includes('SAMFM')) {
            return (
                <>
                    <span className={'line'}>
                        <b>{t('tickets:operations_samfm_tool_id')}</b>
:
                        {log.custom_field?.tool_id}
                    </span>
                    <span className={'line'}>
                        <b>{t('tickets:operations_samfm_tool_status_label')}</b>
:
                        {log.custom_field?.tool_status_label}
                    </span>
                    <span className={'line'}>
                        <b>{t('tickets:operations_samfm_tool_instructions')}</b>
:
                        {log.custom_field?.tool_instructions}
                    </span>
                </>
            );
        } if (type.includes('SERVICENOW')) {
            return (
                <>
                    <b>{t(`tickets:${type}_number`)}</b>
                    { log.custom_field?.number }
                </>
            );
        } if (type.includes('DOIT')) {
            return (
                <>
                    <b>{t(`tickets:${type}_number`)}</b>
                    { log.custom_field?.id_tech }
                </>
            );
        } if (type.includes('SPINALCOM')) {
            return (
                <>
                    <b>{t(`tickets:${type}_number`)}</b>
                    { log.custom_field?.staticId }
                </>
            );
        } if (type.includes('NOUVELATRIUM')) {
            return (
                <>
                    <b>{t(`tickets:${type}_number`)}</b>
                    { log.custom_field?.id }
                </>
            );
        } if (type.includes('SHARINGCLOUD')) {
            return (
                <>
                    <span className={'line'}>
                        <b>{t(`tickets:${type}_number`)}</b>
                        :
                        { log.custom_field?.sharingcloud_id }
                    </span>
                    {!isEmpty(log.custom_field.author_name)
                        && (
                            <span className={'line'}>
                                <b>{t(`tickets:${type}_author_name`)}</b>
                                :
                                {log.custom_field?.author_name}
                            </span>
                        )
                    }
                    {!isEmpty(log.custom_field.author_email)
                        && (
                            <span className={'line'}>
                                <b>{t(`tickets:${type}_author_email`)}</b>
                                :
                                {log.custom_field.author_email}
                            </span>
                        )
                    }
                    {!isEmpty(log.custom_field.description)
                        && (
                            <span className={'line'}>
                                <b>{t(`tickets:${type}_description`)}</b>
                                :
                                {log.custom_field.description}
                            </span>
                        )
                    }
                </>
            );
        } return (
            <>
                <b>{t(`tickets:${type}_number`)}</b>
                { log.custom_field?.number }
            </>
        );
    };

    const getLogLineComponent = (log, type, logIdx) => {
        let iconType = '';
        let iconExtraStyle = '';
        let icon = '';
        let contentType = '';
        let contentExtraStyle = '';
        let content = '';
        let userRef = '';
        let contentHeader = '';
        let isEditableComment = false;
        let isShareableComment = false;
        let isDeleteableComment = false;
        let multipleContent = false;
        const authorName = getAuthorNameFromLog(log, type);
        if (type === 'OUTPUTS_LOG_CREATION') {
            iconType = 'bubble';
            content = t(
                `logs:new_${toLower(log?.modified_model_name)}_${ticketAuthorType}_text`,
                { source: authorName }
            );
            contentType = 'text';
        } else if (type === 'OUTPUTS_LOG_CREATION_FROM_DECLARATIVE_QRCODE') {
            iconType = 'bubble';
            const userAuthor = getAuthorNameFromLog(log, type).userAuthor;
            const qrcodeAuthor = getAuthorNameFromLog(log, type).qrcodeAuthor;
            content = t(`logs:new_${toLower(log?.modified_model_name)}_DECLARATIVE_QRCODE_text`, { author: userAuthor, source: qrcodeAuthor });
            contentType = 'text';
        } else if (type === 'OUTPUTS_LOG_UPDATE_STATUS') {
            iconType = 'icon';
            icon = 'slide_status';
            iconExtraStyle = 'status-icon';
            contentType = 'text';
            content = t('logs:updated_status', { source: authorName, new_status: t(`tickets:${log.modification?.new?.status}`) });
        } else if (type === 'OUTPUTS_LOG_UPDATE_FIELDS') {
            iconType = 'bubble';
            contentType = 'text';
            content = getFieldsModificationContent(log, authorName);
        } else if (type === 'COMMENT_DEVICE_MESSAGE') {
            const device = getDeviceFromId(deviceList, log.custom_field.author_device_id);
            iconType = 'icon';
            icon = device?.custom_field?.type || 'device';
            iconExtraStyle = 'device-icon';
            contentType = 'comment';
            content = log.content.split('\n').map((item, idx) => {
                return <span key={idx}>{item}<br/></span>;
            });
            contentHeader = `${authorName} ${t('tickets:commented')}`;
        } else if (type === 'COMMENT_MERCIYANIS_MESSAGE' || type === 'COMMENT_SLACK_MESSAGE' || type === 'COMMENT_TEAMS_MESSAGE' || type === 'COMMENT_DOIT_MESSAGE' || type === 'COMMENT_EXTERNAL_FOLLOWER_MESSAGE') {
            const isNotCommentFromPlatform = type !== 'COMMENT_MERCIYANIS_MESSAGE';
            if (isNotCommentFromPlatform) {
                iconType = 'icon';
                icon = type.split('COMMENT_')[1];
                if (type === 'COMMENT_EXTERNAL_FOLLOWER_MESSAGE') {
                    contentHeader = `${authorName} ${t('tickets:shared_to_requesters')}`;
                    iconExtraStyle = 'external-follower-icon';
                } else {
                    contentHeader = `${authorName} ${t('messaging:commented_messaging', { messaging_type: capitalize(icon.split('_MESSAGE')[0]) })}`;
                    iconExtraStyle = 'log-icon-circle';
                }
            } else { // COMMENT_MERCIYANIS_MESSAGE
                iconType = 'avatar';
                contentHeader = `${authorName} ${t('tickets:commented')}`;
                if (log.author) {
                    const user = getUserFromId(userList, log.author);
                    icon = user?.profile_picture_url;
                    userRef = user;
                }
                isDeleteableComment = true;
                isShareableComment = true;
                isEditableComment = log.content && log.urls.length === 0;
            }
            contentType = 'comment';
            if (log.content) {
                content = log.content.split('\n').map((item, idx) => {
                    return <span key={idx}>{item}<br/></span>;
                });
            }
            if (log.urls.length > 0) {
                const externalCommentStyle = commentExternalToPlatform(log.custom_field) ? `comment-from-${toLower(shareButtonSubject())}` : '';
                isShareableComment = !isNotCommentFromPlatform && ticketAuthorType.includes('TEAMS'); // We can only share Teams files + and only if they are not already shared
                const fileIdx = logIdx * nbMaxFilesByInput; // For the preview css and download button to appear on the right file
                multipleContent = true;
                content = (
                    <>
                        {content && <div className={`comment-with-multiple-content ${externalCommentStyle}`}>{content}</div>}
                        <div className={'comment-files-wrapper'}>
                            {log.urls.map((url, idx) => {
                                const fileType = url?.split('.')?.pop();
                                const fileThumbnailUrl = getInputFileUrlForThumbnail(url, url?.split('.')?.pop());
                                const fileCanBePreviewed = fileTypeAllowedForDocViewer.includes(url?.split('.')?.pop()?.toUpperCase());
                                return (
                                    <div className={`comment-with-multiple-content ${externalCommentStyle}`}>
                                        <div
                                            className={classnames(['file-input-content-overlay', {'file-buttons-wrapper': (fileIdx + idx === displayDownloadButton || isMobile)}, { 'file-with-preview': fileCanBePreviewed }])}
                                            onMouseOver={() => setDisplayDownloadButton(fileIdx + idx)}
                                            onMouseLeave={() => setDisplayDownloadButton(null)}
                                            onClick={() => {
                                                if (fileCanBePreviewed) {
                                                    setPreviewedFile({
                                                        title: url?.split('/')?.pop(),
                                                        type: url?.split('.')?.pop()?.toUpperCase(),
                                                        url
                                                    });
                                                } else {
                                                   downloadFileFromUrl(url);
                                                }
                                            }}
                                        >
                                            <div className={'file-buttons-content'}>
                                                {(fileIdx + idx === displayDownloadButton || isMobile)
                                                    && (
                                                        <Button onClick={e => {
                                                            e.stopPropagation();
                                                            downloadFileFromUrl(url);
                                                        }}>
                                                            <Icon componentName={'Icon'} type={'faCloudArrowDown'}/>
                                                        </Button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        {fileThumbnailUrl
                                            ? <img src={fileThumbnailUrl} alt={''} />
                                            : (
                                                <div className={'comment-file-without-preview'}>
                                                    <Icon componentName={'Icon'} type={iconForFileWithoutThumbnail(fileType, commentExternalToPlatform(log.custom_field))} />
                                                    <p>{url?.split('/')?.pop()}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                );
                            })}
                        </div>
                    </>
                );
            }
        } else if (type === 'ROUND_INSTANCE_MESSAGE') {
            iconType = 'bubble';
            iconExtraStyle = 'color-light-blue';
            contentType = 'text';
            content = (
                <p>
                    <b>{t('rounds:step_not_validated')}</b>
                    {` : ${log?.content}`}
                </p>
            );
        } else if (type.includes('OPERATION_')) {
            let operationType = 'OPERATION_UNKNOWN';
            const splittedOperationType = type.split('OPERATION_');
            if (splittedOperationType.length > 1) {
                operationType = splittedOperationType[1];
            }
            iconType = 'icon';
            icon = operationType;
            content = getContentFromOperationTool(log, type);
            contentHeader = t(`tickets:${type}_header_logs`);
            contentType = 'comment';
            contentExtraStyle = '';
        }
        return (
            <>
                <ListItemAvatar>
                    {getIconComponent(iconType, iconExtraStyle, icon, userRef)}
                </ListItemAvatar>
                {getContentComponent(log, logIdx, contentType, contentExtraStyle, content, contentHeader, isEditableComment, isShareableComment, isDeleteableComment, multipleContent)}
            </>
        );
    };

    return (
        <>
            <Component componentName={'LoaderBarTop'} className={'modal-loader'} isLoading={isLoading} />
            <div className={'logs'}>
                <div className={'logs-title'}>
                    {ticketAuthorType.includes('SLACK') && (
                        <Tooltip
                            classes={slackTooltip}
                            placement={'top-start'}
                            title={`💡 ${t('tickets:cannot_share_attachments_on_slack')}`}
                        >
                            <div className={'historic-info-icon'}>
                                <Component componentName={'Icon'} type={'info'} />
                            </div>
                        </Tooltip>
                    )}

                    <h2>{`${t('tickets:historic')}`}</h2>
                </div>
                {!ticket.active // if the ticket is archived, user cannot add a comment
                    ? <div style={{ marginTop: '20px' }} />
                    : (
                        <div className={'send-comment-container'}>
                            <Component
                                componentName={'Input'}
                                placeholder={t('tickets:add_comment')}
                                onChange={setLogComment}
                                multiline={2}
                                emptyInput={emptyInput}
                                rules={['maxLength-512']}
                                showAttachmentUploader
                                uploadedFiles={uploadedFiles}
                                setUploadedFiles={value => setUploadedFiles(value)}
                            />
                            <div className={classnames('send-button', !showShareIcon() && 'without-share-buttons', disableSendComment && 'disabled-button')}>
                                { showShareIcon() && ( // Teams, slack, or requesters buttons
                                    <Tooltip
                                        classes={{ tooltip: classes.tooltip }}
                                        title={t(`tickets:answer_via_${shareButtonSubject()}`)}
                                        placement={'top'}
                                    >
                                        <Button
                                            className={`answer-on-button answer-on-${toLower(shareButtonSubject())}-button`}
                                            onClick={() => shareComment(shareButtonSubject())}
                                            disabled={disableSharedButton}
                                        >
                                            {!(isMobile && shareButtonSubject() === 'EXTERNAL_FOLLOWER') // The text of requesters button if too long to be displayed on mobile with an icon
                                                && <Component componentName={'Icon'} type={shareButtonSubject()} />
                                            }
                                            <span>{t(`tickets:share_on_${shareButtonSubject()}`)}</span>
                                        </Button>
                                    </Tooltip>
                                )}
                                {/* Add comment or attach file button */}
                                { showShareIcon() && <p className={'text-between-comment-button'}>{t('common:or')}</p> }
                                <Tooltip
                                    classes={{ tooltip: 'table-tooltip' }}
                                    placement={'top'}
                                    title={t('tickets:add_the_comment_to_ticket')}
                                >
                                    <Button
                                        className={classnames(
                                            'send-on-my-button',
                                            !showShareIcon() && 'with-paper-plane-button',
                                            isMobile && showShareIcon() && shareButtonSubject() === 'EXTERNAL_FOLLOWER' && 'send-on-my-button-without-icon'
                                        )}
                                        onClick={sendMessage}
                                        color="primary"
                                        disabled={disableSendComment}
                                    >
                                        {!(isMobile && showShareIcon() && shareButtonSubject() === 'EXTERNAL_FOLLOWER') // The text of requesters button if too long to be displayed on mobile with an icon
                                           && <Component componentName={'Icon'} type={showShareIcon() ? 'MerciYanis' : 'send'} />
                                        }
                                        <span>{t('tickets:comment_on_merciyanis')}</span>
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    )
                }
                <List style={{ width: '100%', maxHeight: '100%', overflow: 'auto' }}>
                    {props.summary.map((log, idx) => {
                        if (log) {
                            const type = getLogType(log);
                            if (!isHiddenLog(log, type)) {
                                let datetime = '';
                                let date = '';
                                let time = '';
                                if (log.created_at) {
                                    datetime = moment(log?.created_at);
                                    date = datetime.format('DD/MM/YYYY');
                                    time = datetime.format('HH[h]mm');
                                }


                                return (
                                    <ListItem
                                        className={classnames([{ 'comment-actions-hovered': !isMobile && idx === hoveredComIdx }])}
                                        style={type.includes('COMMENT') || type.includes('OPERATION') ? {alignItems: 'flex-start'} : {}}
                                        key={`ticket-comment-${idx}`}
                                    >
                                        <ListItemText
                                            className={'datetime-item'}
                                            secondary={(
                                                <div className={'datetime-item-label'}>
                                                    <span>{date}</span>
                                                    <span>{time}</span>
                                                </div>
                                            )}
                                        />
                                        <div className={'list-line'}/>
                                        {getLogLineComponent(log, type, idx)}
                                    </ListItem>
                                );
                            }
                        }
                        return '';
                    })}
                </List>
                <Modal // File preview
                    className={isMobile ? 'file-viewer-wrapper-mobile' : 'file-viewer-wrapper'}
                    open={Boolean(previewedFile)}
                    onClose={() => setPreviewedFile(null)}
                >
                    <div className={'modal-file-viewer'}>
                        <FileViewer document={previewedFile} hasDocumentHeader={isMobile} closeDialog={() => setPreviewedFile(null)} />
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default TicketLogs;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { useComponentsPool } from '../../../../ComponentsPool';
import useRouter from '../../../../utils/hook/useRouter';
import { canCreateUpdateDeleteInstance, isUserOrContractorWithCalendarView } from '../../../users/helper';
import { getRounds } from '../../actions';
import queryString from 'query-string';
import { isEqual } from 'lodash';
import { DEFAULT_ROUND_VIEW } from "../../constants";

export const VIEW_LIST = 'list';
export const VIEW_CALENDAR = 'calendar';

// User role only give access to the calendar view if the setting show_user_calendar_view is true
export const getCurrentRoundView = (query, currentUserRole = '', workspaceSettings = []) =>
    isUserOrContractorWithCalendarView(currentUserRole, workspaceSettings)
        ? VIEW_CALENDAR
        : query?.view
        ? query.view
        : VIEW_LIST;

const RoundViewSwitcher = () => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const { query, history, location } = useRouter();
    const dispatch = useDispatch();
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const currentUserRole = useSelector(state => state.users.currentUser.workspace_role?.workspace_role_name);

    // redirection to calendar view for contractor and users with activated access to calendar view
    useEffect(() => {
        if (isUserOrContractorWithCalendarView(currentUserRole, workspaceSettings)) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('view', VIEW_CALENDAR);
            history.push({
                search: `?${searchParams}`
            });
        }
    }, []);

    const onClick = (view) => () => {
        const currentViewInURL = query['view'] ?? DEFAULT_ROUND_VIEW; // by default calendar view if nothing
        if (isEqual(currentViewInURL, view)) {
            return; // button does nothing if the view in the url is same as clicked button view mode
        }
        if (view === VIEW_LIST) {
            dispatch(getRounds(true));
        }

        const newQueryParams = { view };
        history.push({
            search: queryString.stringify(newQueryParams)
        });
    };

    const currentView = getCurrentRoundView(query);

    return canCreateUpdateDeleteInstance(currentUserRole) ? ( // only admin and owner can see view Switcher
        <div className={'change-calendar-view-mode-wrapper'}>
            <Tooltip placement={'top'} arrow={false} title={t('rounds:list_view')}>
                <IconButton onClick={onClick(VIEW_LIST)}>
                    <Component
                        componentName={'Icon'}
                        type={currentView === 'calendar' ? 'linesOutlined' : 'linesFilled'}
                        size="sm"
                    />
                </IconButton>
            </Tooltip>
            <Tooltip placement={'top'} arrow={false} title={t('rounds:calendar_view')}>
                <IconButton className={'calendar-view-button'} onClick={onClick(VIEW_CALENDAR)}>
                    <Component
                        componentName={'Icon'}
                        type={currentView === 'calendar' ? 'calendarFilled' : 'calendarOutlined'}
                        size="sm"
                    />
                </IconButton>
            </Tooltip>
        </div>
    ) : (
        <div className={'change-calendar-view-mode-wrapper'} /> // empty div if user has no access to view switcher => for centering the date in day view
    );
};

export default RoundViewSwitcher;

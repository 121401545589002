// Libs
import React from 'react';
// Components
import Switch from '@material-ui/core/Switch';

const TypeBool = props => (
    <Switch
        checked={props.value}
        onChange={value => props.setNewValue(value)}
        value={props.name}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
);

export default TypeBool;

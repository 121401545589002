import axios from 'axios';

export const getLatestChangelog = () => ({
    type: 'GET_LATEST_CHANGELOG',
    payload: axios.get('/changelogs/?page_size=1&ordering=-release_date/')
        .then(res => res.data)
        .catch(() => []) // To prevent the front end from crashing due to the changelog
});

export const userSawChangelog = (userId, releaseDate) => ({
    type: 'USER_SAW_CHANGELOG',
    payload: axios.post(`/changelogs/${releaseDate}/views/`, { user: userId })
        .then(res => res.data)
});



// Libs
import React, { useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// Components
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
// Context
import { useModal } from '../../modal/ModalContext';
import { useComponentsPool } from '../../../ComponentsPool';
// Scss
import '../styles/_popover.scss';

const getTitle = (isThreshold, t) => {
    const title = isThreshold ? t('graphs:deactivate_threshold') : t('graphs:activate_threshold');
    return `${title} ${ t('graphs:text_threshold')}`;
};

const PopoverIotRocket = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const modal = useModal();
    const divRef = React.useRef();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { buttonLabel, children, workspaceRole } = props;
    const [selected, setSelected] = React.useState(false);
    const [title, setTitle] = React.useState(() => getTitle(props.isThreshold, t));

    const handleClick = event => {
        event.stopPropagation();
        setSelected(!selected);
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (!props.open) setAnchorEl(null);
    }, [props.open]);

    useEffect(() => {
        setTitle(() => getTitle(props.isThreshold, t));
    }, [props.isThreshold]);

    let params = {};

    return (
        <>
            {props.buttonType !== undefined && props.buttonType === 'toggle' ? (
                <Chip
                    clickable
                    onClick={handleClick}
                    component={'div'}
                    label={title}
                    deleteIcon={<div><Component componentName={'Icon'} type={'close'} /></div>}
                    {...params}
                />
            ) : (
                <div
                    ref={divRef}
                    onMouseEnter={() => props.openOnHover && setAnchorEl(divRef.current)}
                    role={'button'}
                    tabIndex={0}
                    className={classNames(['action-button-wrapper', { 'is-admin-or-owner': workspaceRole }, { 'fab-button': props.fabButton }])}
                    onClick={handleClick}
                >
                    {buttonLabel}
                </div>
            )}
            <Popover
                classes={{
                    paper: `MuiPopover-paper no-overflow-paper ${props.callingComponentName === 'UserGroups' ? 'user-group-popover-wrapper' : ''} ${props.verticalAnchor === 'top' && props.verticalTransform === 'bottom' ? 'bottom-arrow' : ''} ${props.classNames}`
                }}
                style={props.openOnHover && { pointerEvents: 'none' }}
                onMouseLeave={() => props.openOnHover && setAnchorEl(null)}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: props.verticalAnchor || 'bottom', horizontal: props.horizontalAnchor || 'center' }}
                transformOrigin={{ vertical: props.verticalTransform || 'top', horizontal: props.horizontalTransform || 'center' }}
            >
                <OutsideClickHandler onOutsideClick={() => setAnchorEl(null)}>
                    {children}
                </OutsideClickHandler>
            </Popover>
        </>
    );
};

export default PopoverIotRocket;

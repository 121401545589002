// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
// Components
// Scss
import Button from '../../layout/components/Button';
import PageHeader from '../../authentication/components/PageHeader';


const NotFound = () => {
    const [t] = useTranslation();

    return (
        <div className={'not-found'}>
            <PageHeader subText={t('common:page_not_found')} />
            <div className="redirect-button-wrapper redirect-button">
                <Button
                    text={t('common:go_back_to_home_page')}
                    onClick={() => window.location.replace('https://www.merciyanis.com/connexion')}
                />
            </div>
        </div>
    );
};

export default NotFound;

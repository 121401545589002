// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isArray, isEmpty, sortBy } from 'lodash';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useModal } from '../../../modal/ModalContext';
import { useFootBar } from '../../../footbar/FootBarContext';
// Helper
import { getValue } from '../../../layout/helper';
import { isPassageDeviceType, isQRCodePassageDeviceType } from '../../helper';
import { getDeviceMetabaseUrl } from "../../actions";
import classNames from "classnames";

const DeviceDetailsPopupLeft = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const modal = useModal();
    const footBarDispatch = useFootBar();
    const dispatch = useDispatch();
    const device = useSelector(state => state.devices.deviceShownInDetailsPopup);
    const editableFields = useSelector(state => sortBy(state.devices.editableFields, 'weight'));
    const [primaryColor] = useState(getComputedStyle(document.documentElement).getPropertyValue('--primary-color'));
    const [graphData, setGraphData] = useState({
        colors: [primaryColor],
        device_ids: [device.device_id],
        _graph_data: device.data,
        metabaseUrl: ''
    });
    const required_fields = useSelector(state => state.workspace.settings.required_fields || '');

    const [extraFields, setExtraFields] = useState([]);
    const serviceNowSwitcher = useSelector(state => state.workspace.settings.servicenow_switcher);
    const spinalcomSwitcher = useSelector(state => state.workspace.settings.spinalcom_switcher);

    useEffect(() => {
        if (!isEmpty(device.metabaseUrl)) {
            const graphDataCopy = cloneDeep(graphData);
            graphDataCopy['metabaseUrl'] = device.metabaseUrl;
            setGraphData(graphDataCopy);
        }
    }, [device])

    useEffect(() => {
        if (device) {
            footBarDispatch.set({ editionMode: false });
            modal.setItem(device);

            // Get metabase url for this device
            if (!isEmpty(device.hardware_ids) && !isEmpty(device.custom_field?.type)) {
                let deviceType = device.custom_field.type;
                if (isQRCodePassageDeviceType(device)) {
                    deviceType = 'PASSAGE_QRCODE';
                }
                dispatch(getDeviceMetabaseUrl(device.hardware_ids[0], device.device_reference, deviceType));
            }

            if (!isEmpty(serviceNowSwitcher?.extra_attributes_matching)) { // Add extra attributes for ServiceNow
                const newExtraFields = cloneDeep(extraFields);
                serviceNowSwitcher?.extra_attributes_matching
                    .forEach(extraAttribute => {
                        if (!newExtraFields.some(element => element.name === extraAttribute)) {
                            newExtraFields.push({
                                componentName: 'EditableField',
                                editionComponent: 'Input',
                                name: extraAttribute,
                                props: {
                                    value: ['custom_field', extraAttribute],
                                    required: false,
                                    placeholder: '',
                                    multiline: 8,
                                    rules: ['maxLength-512']
                                },
                                renderValue: 'DeviceSimpleString',
                                title: `${extraAttribute} (${extraAttribute})`
                            });
                        }
                    });
                setExtraFields(newExtraFields);
            } else if (!isEmpty(spinalcomSwitcher)) { // Add extra attributes for spinalcom
                const newExtraFields = cloneDeep(extraFields);
                if (!newExtraFields.some(element => element.name === 'device_description')) {
                    newExtraFields.push({
                        componentName: 'EditableField',
                        editionComponent: 'Input',
                        name: 'device_description',
                        props: {
                            value: 'device_description',
                            required: false,
                            placeholder: '',
                            multiline: 1,
                            rules: ['maxLength-256']
                        },
                        renderValue: 'DeviceSimpleString',
                        title: t('devices:spinalcom_room')
                    });
                    setExtraFields(newExtraFields);
                    }
            }
        }
    }, []);

    const setRequiredOnField = (field, fieldNameInWorkspaceSettings) => {
        if (required_fields.includes(fieldNameInWorkspaceSettings)
            || isPassageDeviceType(device)) {
            field.props.required = true;
            if (isArray(field.props.rules) && !field.props.rules?.includes('isRequired')) {
                field.props.rules.push('isRequired');
            }
        } else {
            field.props.required = false;
            if (isArray(field.props.rules)) {
                const index = field.props.rules?.find(obj => obj === 'isRequired');
                if (index !== undefined) {
                    field.props.rules.splice(index, 1);
                }
            }
        }
    };

    return (
        <>
            <Component componentName={'DeviceDetailsGraph'} graphData={graphData} />
            <div
                className={classNames([
                    'resource-infos-wrapper',
                    { 'resource-infos-wrapper-with-metabase-dashboard': !isEmpty(graphData.metabaseUrl) },
                ])}
            >
                <div className={'title'}>
                    <span>{t('common:resource_name')}</span>
                    <Component
                        key={'device_name'}
                        componentName={'EditableField'}
                        title={''}
                        props={{
                            value: 'device_name',
                            required: true,
                            rules: ['maxLength-64', 'selectNotEmpty']
                        }}
                        value={() => getValue('device_name', device)}
                        renderValue={'ModalHeadSimpleString'}
                        editionComponent={'Input'}
                        onChange={values => props.updateState('device_name', values)}
                    />
                </div>
                <div className={'resource-infos'}>
                    { React.useMemo(() => editableFields.concat(extraFields).map(field => {
                        if (!field.props.rules) field.props.rules = [];
                        if (field.name === 'device_floor_group') {
                            field.props.parent = props.state.custom_field.batiment_group_id;
                            setRequiredOnField(field, 'floor');
                        }
                        if (field.name === 'device_position_group') {
                            setRequiredOnField(field, 'position');
                        }
                        if (field.name === 'device_associated_rounds' && !isPassageDeviceType(device)) {
                            return null;
                        }
                        if (field.name === 'device_mode' && device.custom_field.type !== 'QRCODE') {
                            return <></>;
                        }
                        if (field.name === 'device_max_jobs'
                            && (device.custom_field?.type !== 'QRCODE'
                            || device.custom_field?.type === 'QRCODE'
                            && !device.custom_field?.config?.modes.includes('PASSAGE_VALIDATION'))) {
                            return <></>;
                        }
                        if ((field.name === 'emergency_phone' || field.name === 'emergency_label')
                            && (device.custom_field?.type !== 'QRCODE'
                            || device.custom_field?.type === 'QRCODE'
                            && !device.custom_field?.config?.modes.includes('EMERGENCY_CALL'))) {
                            return <></>;
                        }
                        if (field.name === 'emergency_phone' || field.name === 'emergency_label_fr' || field.name === 'emergency_label_en') {
                            if (device.custom_field?.type !== 'QRCODE'
                            || device.custom_field?.type === 'QRCODE'
                            && !device.custom_field?.config?.modes.includes('EMERGENCY_CALL')) {
                                return <></>;
                            }
                            const lang = localStorage.getItem('i18nextLng');
                            // Default language is english
                            if (lang !== 'fr' && lang !== 'en' && field.name === 'emergency_label_fr'
                                || lang === 'fr' && field.name === 'emergency_label_en'
                                || lang === 'en' && field.name === 'emergency_label_fr') {
                                return <></>;
                            }
                        }
                        return (
                            <Component
                                key={field.title}
                                title={t(field.title)}
                                subtitle={t(field.subtitle)}
                                componentName={'EditableField'}
                                renderValue={field.renderValue}
                                editionComponent={field.editionComponent}
                                onChange={value => props.updateState(
                                    field.props.editedValue ? field.props.editedValue : field.props.value,
                                    value
                                )}
                                props={field.props}
                                disabledInput={field.disabledInput}
                                value={getValue(field.props.value, device)}
                            />
                        );
                    }), [editableFields])}
                </div>
            </div>
        </>
    );
};

export default DeviceDetailsPopupLeft;

import axios from 'axios';

export const getWorkspaceACLs = () => ({
    type: 'GET_WORKSPACE_ACLS',
    payload: axios.get('/acl/workspace/?page_size=1000')
        .catch(error => { throw new Error(error); })
});

export const getGroupACL = () => ({
    type: 'GET_GROUP_ACLS',
    payload: axios.get('/acl/group/?page_size=1000')
        .then(res => res.data)
        .catch(error => { throw new Error(error); })
});

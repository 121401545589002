// Libs
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
// Context
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';
import { Toast } from '../../../notification/components';
import { useComponentsPool } from '../../../../ComponentsPool';
// Helper
import {
    getPassageDevices,
    getDevicesInfosFromDevices,
    getDeviceListFromIds
} from '../../../devices/helper';
import {
    getGroupNameFromId,
    getCorrespondingGroups,
    getLocationGroups,
    sortByBuildingName
} from '../../../groups/helper';
import { useSecondMainColorTooltip } from '../../../layout/helper';

const RoundStepsLists = props => {
    const [exitSearchbar, setExitSearchbar] = useState(false);
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const tooltipStyle = useSecondMainColorTooltip();
    const groupList = useSelector(state => state.groups.list);
    const deviceList = useSelector(state => state.devices.list);
    const navbar = useSelector(state => state.navbar);
    const [checked, setChecked] = useState([]);

    useEffect(() => {
        setExitSearchbar(true);
    }, []);

    const getSelectedStepsList = devicesIds => (devicesIds.length > 0
        ? getDevicesInfosFromDevices(getDeviceListFromIds(deviceList, devicesIds), groupList).sort(sortByBuildingName)
        : []);

    const getAvailableStepsList = devicesIds => (devicesIds.length > 0
    // eslint-disable-next-line max-len
        ? getDevicesInfosFromDevices(getPassageDevices(deviceList), groupList)
            .filter(device => !devicesIds.includes(device.id)).sort(sortByBuildingName)
        // eslint-disable-next-line max-len
        : getDevicesInfosFromDevices(getPassageDevices(deviceList), groupList)
            .sort(sortByBuildingName));

    const [isInitialSelectedSteps, setIsInitialSelectedSteps] = useState(true);
    const [selectedSteps, setSelectedSteps] = useState(getSelectedStepsList(props.devices_ids));
    const [availableSteps, setAvailableSteps] = useState(getAvailableStepsList(props.devices_ids));

    const [matchingGroups, setMatchingGroups] = useState(
        getCorrespondingGroups(availableSteps, getLocationGroups(groupList))
    );

    useEffect(() => {
        setIsInitialSelectedSteps(true);
        setSelectedSteps(getSelectedStepsList(props.devices_ids));
        setAvailableSteps(getAvailableStepsList(props.devices_ids));
        setMatchingGroups(getCorrespondingGroups(availableSteps, getLocationGroups(groupList)));
    }, [props.devices_ids]);

    function not(a, b) {
        return a.filter(value => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
        return a.filter(value => b.indexOf(value) !== -1);
    }
    const leftChecked = intersection(checked, availableSteps);
    const rightChecked = intersection(checked, selectedSteps);

    useEffect(() => {
        if (navbar.filters.length > 0) setChecked(rightChecked);
    }, [navbar.filters]);

    function union(a, b) {
        return [...a, ...not(b, a)];
    }

    const getListTitle = listName => {
        if (listName === 'availableSteps') {
            return availableSteps.length <= 1
                ? t('rounds:round_available_step', { count: availableSteps.length })
                : t('rounds:round_available_steps', { count: availableSteps.length });
        }
        return selectedSteps.length <= 1
            ? t('rounds:round_selected_step', { count: selectedSteps.length })
            : t('rounds:round_selected_steps', { count: selectedSteps.length });
    };

    // filter the steps based on the searchbar filter
    const filterSteps = filter => {
        switch (filter.groupType) {
            case 'BATIMENT':
                return availableSteps.filter(step => step?.batiment_group_id === filter.id);
            case 'FLOOR':
                return availableSteps.filter(step => step?.floor_group_id === filter.id);
            case 'POSITION':
                return availableSteps.filter(step => step?.position_group_id === filter.id);
            default:
                return availableSteps.filter(step => step?.device_id === filter.id);
        }
    };

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const countCheckedSteps = steps => intersection(checked, steps).length;

    const handleToggleAll = steps => () => {
        if (countCheckedSteps(steps) === steps.length) {
            setChecked(not(checked, steps));
        } else {
            setChecked(union(checked, steps));
        }
    };

    const handleCheckedRight = () => {
        const newAvailableSteps = not(availableSteps, leftChecked);
        setAvailableSteps(newAvailableSteps.sort(sortByBuildingName));
        setMatchingGroups(getCorrespondingGroups(newAvailableSteps, getLocationGroups(groupList)));
        setSelectedSteps(selectedSteps.concat(leftChecked).sort(sortByBuildingName));
        setChecked(not(checked, leftChecked));
        setIsInitialSelectedSteps(false);
    };

    const handleCheckedLeft = () => {
        const newAvailableSteps = availableSteps.concat(rightChecked);
        setAvailableSteps(newAvailableSteps.sort(sortByBuildingName));
        setMatchingGroups(getCorrespondingGroups(newAvailableSteps, getLocationGroups(groupList)));
        setSelectedSteps(not(selectedSteps, rightChecked).sort(sortByBuildingName));
        setChecked(not(checked, rightChecked));
        setIsInitialSelectedSteps(false);
    };

    useEffect(() => {
        if (!isInitialSelectedSteps) {
            const selectedStepsIds = [];
            selectedSteps.forEach(step => selectedStepsIds.push(step.id));
            props.changeState({ devices_ids: selectedStepsIds });
        }
    }, [selectedSteps]);

    const customList = type => {
        let steps = selectedSteps;
        if (type === 'availableSteps') {
            steps = navbar.filters.length > 0 ? filterSteps(navbar.filters[0]) : availableSteps;
        }
        const countCheckedItems = countCheckedSteps(steps);
        const title = getListTitle(type);
        return (
            <>
                <div className="steps-list-header">
                    <p>{title}</p>
                    <p className={props.warningSelectedSteps && type === 'selectedSteps' && steps.length === 0 ? 'empty-steps' : ''}>{`(${steps.length})`}</p>
                    {props.hasTooltipOnTitle && type === 'availableSteps' && (
                        <Tooltip
                            className={'second-main-color-tooltip'}
                            placement={'top'}
                            classes={tooltipStyle}
                            title={t('rounds:round_steps_explanation')}
                        >
                            <div><Component componentName={'Icon'} type={'info'} /></div>
                        </Tooltip>
                    )}
                </div>
                <div className="searchbar-wrapper">
                    {type === 'availableSteps' ? (
                        <Component
                            componentName={'CustomSearchBar'}
                            devices={availableSteps}
                            groups={matchingGroups}
                            searchbarName={'stepsSearchbar'}
                            exitSearchbar={exitSearchbar}
                        />
                    ) : (
                        !props.hideRoundStepsExplanation && (
                            <div className="selected-step-explanation">
                                <Component componentName={'Icon'} type={'info'} />
                                <p>{t('rounds:selected-steps-explanation')}</p>
                            </div>
                        )
                    )}
                </div>
                <div className={classNames(['step-lists', { 'step-lists-without-section': props.hideSelectedListSection && type === 'selectedSteps' }])}>
                    <ListItem className="step-columns-name">
                        <div className="checkbox-all-steps">
                            <Checkbox
                                onClick={handleToggleAll(steps)}
                                checked={countCheckedItems === steps.length && steps.length !== 0}
                                indeterminate={countCheckedItems !== steps.length && countCheckedItems !== 0}
                                disabled={steps.length === 0}
                                inputProps={{ 'aria-label': 'all steps selected' }}
                            />
                        </div>
                        <ListItemText primary={t('devices:building_group')} />
                        <ListItemText
                            className="step-second-column"
                            primary={t('rounds:floor_and_position')}
                        />
                        <ListItemText
                            className="step-last-column"
                            primary={t('devices:device_name')}
                        />
                    </ListItem>
                    <Divider />
                    <List className={'step-list-content'} dense component="div" role="list">
                        {props.warningSelectedSteps && type === 'selectedSteps' && steps.length === 0 &&
                            <div className={'empty-steps-list'}>
                                {t('notifications:choose_step_for_round')}
                            </div>
                        }
                        {steps.map(value => {
                            const labelId = `transfer-list-all-item-${value}-label`;
                            return (
                                <ListItem
                                    key={value.id}
                                    role="listitem"
                                    button
                                    onClick={handleToggle(value)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={checked.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={labelId}
                                        primary={getGroupNameFromId(groupList, value?.batiment_group_id)}
                                    />
                                    <ListItemText
                                        className="step-second-column"
                                        id={labelId}
                                        primary={getGroupNameFromId(groupList, value?.floor_group_id)}
                                        secondary={getGroupNameFromId(groupList, value?.position_group_id)}
                                    />
                                    <ListItemText
                                        className="step-last-column"
                                        id={labelId}
                                        primary={value.device_name}
                                    />
                                </ListItem>
                            );
                        })}
                        <ListItem />
                    </List>
                </div>
            </>
        );
    };


    return (
        <div className={classNames(['ticket-step', 'form-control', 'create-round-modal', props.isEditionMode ? 'round-edition-steps-list' : ''])}>
            <div className={classNames(['create-round-step2', props.isDetailsPopup ? 'round-steps-center-with-tooltip' : '', props.withNewColor ? 'second-main-color-rounds-step-list' : ''])}>
                <div className="left-device-list-wrapper">
                    {customList('availableSteps')}
                </div>
                <div className="transfer-buttons">
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected selectedSteps"
                    >
                        <Component componentName={'Icon'} type={'rightArrow'} />
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected availableSteps"
                    >
                        <Component componentName={'Icon'} type={'leftArrow'} />
                    </Button>
                </div>
                <div className="right-device-list-wrapper">
                    {customList('selectedSteps')}
                </div>
            </div>
        </div>
    );
};

export default RoundStepsLists;

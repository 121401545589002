// Libs
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QrReader from 'modern-react-qr-reader';
// Context
import Typography from '@material-ui/core/Typography';
// Components
import { useComponentsPool } from '../../../../ComponentsPool';
import Icon from '../../../layout/components/Icon';

const MobileQRCodeScanner = () => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const [isError, setIsError] = useState(false);

    // We scan every 100 ms (delay), once a QR Code was scanned we redirect the user to it's link
    const handleScan = data => {
        if (data) {
            window.location.href = data;
        }
    };

    const handleError = error => {
        setIsError(error);
    };

    const Form = (
        <div className={'qrcode-scanner-container'}>
            <div className={'qrcode-scanner-header'}>
                <span style={{ fontWeight: 'bold' }}>{t('rounds:scan_the_qrcode')}</span>
                <span>{t('rounds:scanner_qrocde_explanation')}</span>
            </div>
            {
                !isError ? (
                    <div className={'qrcode-video-container'}>
                        <QrReader
                            delay={200}
                            facingMode={'environment'}
                            onError={handleError}
                            onScan={handleScan}
                            style={{ width: '100%', height: '100%'}}
                        />
                    </div>
                ) : <p className={'qrcode-error-text'}>{t('rounds:error_scanner')}</p>
            }
            <div className={'qrcode-icon'}>
                <Component componentName={'Icon'} type={'QRCodeScan'} />
            </div>
        </div>
    );

    return (
        <Component
            componentName={'Modal'}
            customModalRedirection={'MobileRoundsInstanceDetails'}
            noOverflow
            content={Form}
            classNames={'qrcode-scanner-popup-mobile'}
        />
    );
};
export default MobileQRCodeScanner;

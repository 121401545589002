import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';

const SimpleDialog = props => {
    const [open, setOpen] = useState(props.open);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <Dialog
            className={props.className}
            fullScreen
            open={open}
            onClose={() => props.closeModal()}
        >
            {props.content}
        </Dialog>
    );
};
export default SimpleDialog;

// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import { useComponentsPool } from '../../../ComponentsPool';
// Helper
import { getValue } from '../../layout/helper';
import { useFootBar } from '../../footbar/FootBarContext';
import { useModal } from '../../modal/ModalContext';
import { isEmail, getUserName, isUserWithoutMail } from '../helper';

const UserDetailsPopupLeftPart = props => {
    const [t] = useTranslation();
    const footBar = useFootBar();
    const modal = useModal();
    const users = useSelector(state => state.users);
    const user = users.userShownInDetailsPopup;
    const { Component } = useComponentsPool();
    const editableFields = useSelector(state => sortBy(state.users.editableFields, 'weight'));
    const [name, setName] = useState(getUserName(user, true));

    const editableFieldProps = field => {
        const propsCopy = field.props;
        // We must apply the rule isUserId to the email field when the user doesn't have an email
        if (field.name === 'UserEmail' && isUserWithoutMail(user)) {
            const isEmailRuleIdx = field.props.rules.findIndex(rule => rule === 'isEmail');
            if (isEmailRuleIdx !== -1) {
                propsCopy.rules[isEmailRuleIdx] = 'isUserId';
            } else if (!propsCopy.rules.includes('isUserId')) {
                propsCopy.rules.push('isUserId');
            }
            // we use this list to check if the identifier doesn't already exist when we modify it
            propsCopy.list = users.list.filter(usr => usr.username !== user.username);
        }
        return propsCopy;
    };

    const disabledFields = field => {
        // User without mail are the only users that can have their last name,  first name and email modified
        if (isUserWithoutMail(user)) {
            if (field.name === 'UserFirstname' || field.name === 'UserLastname' || field.name === 'UserEmail') { return false; }
        }
        return !!field.disabledInput;
    };

    useEffect(() => {
        footBar.set({ editionMode: false, editionObjectId: user.id, editionObject: {} });
        modal.setItem(user);
        setName(getUserName(user, true));
    }, [user]);

    return (
        <>
            {user?.profile_picture_url && (
                <div className="user-face">
                    <img src={user?.profile_picture_url} alt={'user face'} />
                </div>
            )}
            <div className={'resource-infos-wrapper'}>
                <div className={'title user-title'}>
                    <Component componentName={'UserAvatar'} user={user} picture={user?.profile_picture_url} size="70" />
                    <div className={'username'}>
                        <span>{name ? t('common:resource_name') : ''}</span>
                        <h3>{name}</h3>
                    </div>
                </div>
                <div className={'resource-infos'}>
                    {React.useMemo(() => editableFields.map(field => (
                        <Component
                            componentName={'EditableField'}
                            key={field.title}
                            title={field.title === 'common:email' ? isEmail(user) ? t(field.title) : 'users:user_identification' : t(field.title)}
                            subtitle={t(field.subtitle)}
                            renderValue={field.renderValue}
                            editionComponent={field.editionComponent}
                            onChange={value => props.updateState(field.props.value, value)}
                            props={editableFieldProps(field)}
                            disabledInput={disabledFields(field)}
                            value={field.props ? getValue(field.props.value, user) : ''}
                        />
                    )), [editableFields])}
                </div>
            </div>
        </>
    );
};

export default UserDetailsPopupLeftPart;

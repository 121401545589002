// Libs
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, kebabCase } from 'lodash';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { isMobile } from 'react-device-detect';
import { Modal } from '@material-ui/core';
// Components
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FileViewer from '../../documents/components/FileViewer';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Styles
import '../styles/_input.scss';
import inputValidation, { notMin, notMax, notStep } from '../formValidation';
import { sendCommentDocument } from '../../tickets/actions';
import { Toast } from '../../notification/components';
import {
    allowedInputFileType,
    fileTypeAllowedForDocViewer,
    getInputFileUrlForThumbnail,
    iconForFileWithoutThumbnail,
    nbMaxFilesByInput
} from '../helper';
import Icon from './Icon';

const IotRocketInput = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const [valueState, setValueState] = useState(props.value || props.isNullValue && props.value === 0 ? props.value : '');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState([]);
    const choosePicture = React.createRef();
    const dispatch = useDispatch();
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const [nbOfLoadingFiles, setNbOfLoadingFiles] = useState(0);
    const [previewedFile, setPreviewedFile] = useState(null);
    const bottomOfAttachmentsRef = useRef(null);

    useEffect(() => { // Force the input value to change without using onChange function
        if (props.changeInputValue !== 'error_field') setValueState(props.changeInputValue);
    }, [props.changeInputValue]);

    useEffect(() => {
        if (props.emptyInput !== undefined) {
            setValueState('');
            props.onChange('');
        }
    }, [props.emptyInput]);

    const setPropsChange = (value, e) => {
        const error = [];
        const rules = props.rules;
        setValueState(value);

        if (props?.type === 'number') {
            if (props.inputProps?.step !== undefined) {
                const errorMessage = notStep(value, props.inputProps.step);
                if (errorMessage) {
                    error.push(errorMessage);
                }
            }
        }

        if (props.inputProps?.min !== undefined) {
            const errorMessage = notMin(parseInt(value), props.inputProps.min);
            if (errorMessage !== null) {
                error.push(errorMessage);
            }
        }
        if (props.inputProps?.max != undefined) {
            const errorMessage = notMax(parseInt(value), props.inputProps.max);
            if (errorMessage !== null) {
                error.push(errorMessage);
            }
        }
        if (props.required) rules.push('isRequired');
        if (!isEmpty(rules)) {
            rules?.forEach(rule => {
                const errorMessage = inputValidation(rule, value, props.list);
                if (errorMessage !== null) {
                    error.push(errorMessage);
                }
            });
        }
        if (error.length > 0) {
            props.onChange('error_field', e);
            setErrors(error[0]);
        } else {
            props.onChange(value, e);
            setErrors([]);
        }
    };

    const sendAttachment = async files => {
        setNbOfLoadingFiles(files.length < nbMaxFilesByInput ? files.length : nbMaxFilesByInput);
        if (files) {
            const maxFileSize = workspaceSettings.profile_picture_max_file_size_bytes;
            const filesNotUploaded = [];
            const filesUrls = [];
            await Promise.all(Object.values(files).map(async file => {
                if (file.size <= maxFileSize && allowedInputFileType.includes(file?.type)) {
                    await dispatch(sendCommentDocument(file, props.isAnonymous))
                        .then(response => {
                            filesUrls.push(response.value.url_picture);
                        });
                } else {
                    filesNotUploaded.push(file);
                }
            })).then(() => {
                setNbOfLoadingFiles(0);
                let uploadedFiles = props.uploadedFiles.concat(filesUrls);
                if (filesNotUploaded.length > 0) {
                    const notifMessage = filesNotUploaded.length > 1
                        ? 'some_attachments_couldnt_be_added'
                        : filesNotUploaded[0].size > maxFileSize
                            ? 'upload_comment_document_error_size' : 'upload_comment_document_error_format';
                    toast(
                        <Toast
                            isConfirm
                            message={t(`notifications:${notifMessage}`)}
                            icon={'frown'}
                            type={'error'}
                        />, {
                            position: toast.POSITION.BOTTOM_LEFT,
                            className: 'normal',
                            bodyClassName: 'notification-body grow-font-size',
                            progressClassName: 'error-custom-progress-bar'
                        }
                    );
                }
                if (uploadedFiles.length > nbMaxFilesByInput) {
                    uploadedFiles = uploadedFiles.slice(0, nbMaxFilesByInput);
                    toast(
                        <Toast
                            isConfirm
                            message={t('notifications:limit_attachment_by_comment')}
                            icon={'exclamationTriangleLight'}
                            type={'warning'}
                        />, {
                            position: toast.POSITION.BOTTOM_LEFT,
                            className: 'normal',
                            bodyClassName: 'notification-body grow-font-size',
                            progressClassName: 'warning-progress-bar'
                        }
                    );
                }
                props.setUploadedFiles(uploadedFiles);
                bottomOfAttachmentsRef.current?.scrollIntoView({ behavior: 'smooth' }); // Automatic scroll to the uploaded attachments (to avoid not seeing them on small screen)
            });
        }
    };

    const mathsSymbols = ['e', 'E', '+', '-', '.', ',', '^', '¨'];
    return (
        <FormControl
            component={'div'}
            className={classNames([
                props.className,
                'iot-rocket-input',
                props.startAdornment ? 'with-start-adornment' : '',
                props.multiline > 1 ? 'multiline' : ''
            ])}
            error={errors.length > 0}
        >
            <div
                className={classNames([
                    'iot-rocket-input-wrapper',
                    props.showAttachmentUploader ? 'input-component-with-attachement' : ''
                ])}
            >
                { props.startAdornment && <div className={'start-adornment-wrapper'}>{props.startAdornment}</div> }
                <TextField
                    error={errors.length > 0}
                    autoFocus={props.autoFocus}
                    required={props.required !== undefined ? props.required : false}
                    placeholder={props.placeholder || ''}
                    type={showPassword ? 'text' : props?.type}
                    id={kebabCase(props.label)}
                    multiline={props.multiline > 1}
                    rows={props.multiline > 1 ? props.multiline : undefined}
                    label={t(props.label)}
                    value={valueState}
                    disabled={props.disabled}
                    onChange={e => {
                        const { target: { value } } = e;
                        if (props.onlyNumber && !isNaN(value)) setPropsChange(value, e);
                        if (!props.onlyNumber) setPropsChange(value, e);
                    }}
                    onKeyDown={ev => {
                        if (props.forbidMathsSymbolsSymbols) { mathsSymbols.includes(ev.key) && ev.preventDefault(); }
                        if (ev.key === 'Backspace' && props.onKeyDown !== undefined) { props.onKeyDown(); }
                    }}
                    onKeyPress={e => { if (props.onKeyPress) props.onKeyPress(e); }}
                    onBlur={props.onBlur ? props.onBlur : () => { }}
                    margin="normal"
                    step={props.step}
                    InputProps={{
                        endAdornment: props?.type === 'password' && (
                            <InputAdornment position="end">
                                <IconButton
                                    href={''}
                                    aria-label="Toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <Component componentName={'Icon'} type={'eye'} /> : <Component componentName={'Icon'} type={'eyeSlashed'} />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    inputProps={props.inputProps} // eslint-disable-line
                    onFocus={props.onFocus ? props.onFocus : () => {}}
                />
                { !isEmpty(errors)
                    && <FormHelperText error>{t(`errors:${errors.message}`,
                        {
                            count: errors.count,
                            uppercase: t(`errors:uppercase_${errors.uppercase}`),
                            lowercase: t(`errors:lowercase_${errors.lowercase}`),
                            digits: t(`errors:digits_${errors.digits}`),
                            symbols: t(`errors:symbols_${errors.symbols}`),
                        })
                    }</FormHelperText>
                }
                { props.showAttachmentUploader
                    && (
                        <div className={'add-comment-file-container'}>
                            <div
                                role={'button'}
                                className={'add-document-in-comment'}
                                onClick={() => choosePicture.current.click()}
                            >
                                <Component componentName={'Icon'} type={'attachment'} />
                                <span className={'add-comment-span'}>{t('rounds:add_attached_document')}</span>
                            </div>
                            <div className={'display-comment-file-wrapper'}>
                                {nbOfLoadingFiles !== 0 && [...Array(nbOfLoadingFiles)].map(() => <div className={'display-comment-file file-loading'}><CircularProgress /></div>)}
                                {props.uploadedFiles?.map(url => {
                                    const fileType = url?.split('.')?.pop();
                                    const fileThumbnailUrl = getInputFileUrlForThumbnail(url, url?.split('.')?.pop());
                                    const fileCanBePreviewed = fileTypeAllowedForDocViewer.includes(url?.split('.')?.pop()?.toUpperCase());
                                    return (
                                        <div
                                            className={classNames([fileThumbnailUrl ? 'display-comment-file' : 'display-comment-file-without-thumbnail', { 'display-comment-file-without-preview': !fileCanBePreviewed }])}
                                            onClick={() => {
                                                if (fileCanBePreviewed) {
                                                    setPreviewedFile({
                                                        title: url?.split('/')?.pop(),
                                                        type: url?.split('.')?.pop()?.toUpperCase(),
                                                        url
                                                    });
                                                }
                                            }}
                                        >
                                            <div className={'remove-comment-file'}>
                                                <div className={'button-container'}>
                                                    <Button
                                                        onClick={event => {
                                                            event.stopPropagation();
                                                            props.setUploadedFiles(uploadedFiles => uploadedFiles.filter(fileUrl => fileUrl !== url));
                                                        }}
                                                    >
                                                        <Component componentName={'Icon'} type={'close'} />
                                                    </Button>
                                                </div>
                                            </div>
                                            {fileThumbnailUrl
                                                ? <img src={fileThumbnailUrl} alt={url?.split('.')?.pop()} />
                                                : (
                                                    <div className={'comment-file-without-preview'}>
                                                        <Icon componentName={'Icon'} type={iconForFileWithoutThumbnail(fileType)} />
                                                        <p>{url?.split('/')?.pop()}</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    );
                                })}
                                <div ref={bottomOfAttachmentsRef} />
                            </div>
                            <input
                                ref={choosePicture}
                                hidden
                                type="file"
                                accept={allowedInputFileType.toString()}
                                onChange={e => {
                                    e.preventDefault();
                                    sendAttachment(e.target.files);
                                }}
                                multiple
                            />
                        </div>
                    )}
            </div>
            <Modal // File preview
                className={isMobile ? 'file-viewer-wrapper-mobile' : 'file-viewer-wrapper'}
                open={Boolean(previewedFile)}
                onClose={() => setPreviewedFile(null)}
            >
                <div className={'modal-file-viewer'}>
                    <FileViewer document={previewedFile} hasDocumentHeader={isMobile} closeDialog={() => setPreviewedFile(null)} />
                </div>
            </Modal>
        </FormControl>
    );
};

IotRocketInput.defaultProps = {
    disabled: false,
    label: '',
    list: [],
    multiline: 1,
    onlyNumber: false,
    required: false,
    rules: [],
    startAdornment: null,
    value: '',
    showAttachmentUploader: false,
    setUploadedFile: {},
    uploadedFile: '',
    isAnonymous: false
};

IotRocketInput.propTypes = {
    disabled: PropTypes.bool,
    isAnonymous: PropTypes.bool,
    label: PropTypes.string,
    list: PropTypes.array,
    multiline: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onlyNumber: PropTypes.bool,
    required: PropTypes.bool,
    rules: PropTypes.arrayOf(PropTypes.string),
    setUploadedFile: PropTypes.any,
    showAttachmentUploader: PropTypes.bool,
    startAdornment: PropTypes.any,
    uploadedFile: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IotRocketInput;

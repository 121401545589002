/* eslint-disable import/first */
import React from 'react';
import { action } from '@storybook/addon-actions';
import Button from '../modules/layout/components/Button';

export default { title: 'Button' };

const onChangeState = action('onChangeState');

export const defaultButton = () => (
    <Button
        onClick={onChangeState}
        text={'CLICK ME'}
    />
);

export const disabledButton = () => (
    <Button
        disabled
        onClick={onChangeState}
        text={'TRY ME'}
    />
);

export const coloredButton = () => (
    <Button
        color={'red'}
        onClick={onChangeState}
        text={'TRY ME'}
    />
);

export const withStartAdornmentButton = () => (
    <Button
        startAdornment
        icon={'leftArrow'}
        onClick={onChangeState}
        text={'TRY ME'}
    />
);

export const withEndAdornmentButton = () => (
    <Button
        endAdornment
        icon={'rightArrow'}
        onClick={onChangeState}
        text={'TRY ME'}
    />
);

export const withLink = () => (
    <Button
        href={'https://www.youtube.com/watch?v=DLzxrzFCyOs'}
        onClick={onChangeState}
        text={'TRY ME :)'}
    />
);

export const loadingButton = () => (
    <Button
        onClick={onChangeState}
        text={'TRY ME'}
        loading
    />
);

import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import store from '../../../../../store';
import Button from '../../../../layout/components/Button';
import {
    getVisibleUsers, isEmail,
    isUserOrContractorWithCalendarView
} from '../../../../users/helper';
import { sendRoundNotifLink } from '../../../actions';
import './sendMailNotification.scss';

const dispatch = store.dispatch;

const SendMailNotificationPopup = ({
    anchorEl, onClose
}) => {
    const [t] = useTranslation();

    const usersList = useSelector(({ users }) => users.list);
    const [selection, setSelection] = useState([]);

    useEffect(() => {
        if (!anchorEl) {
            setSelection([]);
        }
    }, [anchorEl]);

    const onChange = (e, newValue) => {
        setSelection(newValue);
    };

    const onSave = async () => {
        const notified_users_ids = selection.map(user => user.id);
        const link = window.location.href;
        dispatch(sendRoundNotifLink(notified_users_ids, link));
        onClose();
    };
    const currentUser = useSelector(state => state.users.currentUser);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const sorted = getVisibleUsers(usersList).filter(u => currentUser?.email !== u.email && isEmail(u)
        && (u.workspace_role?.workspace_role_name === 'Admin'
            || isUserOrContractorWithCalendarView(u.workspace_role?.workspace_role_name, workspaceSettings)
        )
    );
    sorted.sort((u1, u2) => {
        const m1 = `${u1.first_name} ${u1.last_name}`;
        const m2 = `${u2.first_name} ${u2.last_name}`;
        return m1.localeCompare(m2);
    });

    const canSave = selection.length > 0;

    return (
        <Popover
            id="send-mail-notification-popup"
            anchorEl={anchorEl}
            onClose={onClose}
            open={Boolean(anchorEl)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <div className="send-mail-notification-popup-content">
                <Box p={2} className="title">
                    {t('notifications:send_mail')}
                </Box>
                <Box p={2} className="select">
                    <Box className="select-label">
                        <span>{t('notifications:users_to_notify')}</span>
                    </Box>
                    <Autocomplete
                        multiple
                        limitTags={2}
                        disablePortal
                        className="mail-selection"
                        ListboxProps={{ style: { maxHeight: '20rem' } }}
                        disableCloseOnSelect
                        options={sorted}
                        getOptionLabel={option => `${option.first_name} ${option.last_name}`}
                        defaultValue={[]}
                        onChange={onChange}
                        value={selection}
                        renderInput={params => (
                            <TextField
                                {...params}
                                required
                                placeholder={selection.length === 0 && t('notifications:users_to_notify_placeholder')}
                                variant="standard"
                            />
                        )}
                    />
                </Box>
                <Box p={2} className="toolbar">
                    <Button onClick={onClose} text={t('common:cancel')} className="close" />
                    <Button onClick={onSave} text={t('common:send')} className="next" disabled={!canSave} />
                </Box>
            </div>
        </Popover>
    );
};

export default SendMailNotificationPopup;

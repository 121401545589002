// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getValue } from '../../../layout/helper';
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';
import { useModal } from '../../../modal/ModalContext';
import { getGroupNameFromId } from '../../../groups/helper';
import {
    getTemplatesFromSkill,
    getTemplatesIdsFromSkill,
    shapeTemplatesListAsGroupsList
} from '../../../templates/helper';

const SettingsDetailsPopupLeft = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const footBarDispatch = useFootBar();
    const modal = useModal();
    const group = useSelector(state => state.groups.groupShownInDetailsPopup);
    const [groupType, setGroupType] = useState(group?.custom_field?.type);
    const groupList = useSelector(state => state.groups.list);
    const templatesList = useSelector(state => state.templates.list);
    const [templates, setTemplates] = useState(getTemplatesFromSkill(templatesList, group.group_id));
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const floorMode = workspaceSettings.mode_floor ? workspaceSettings.mode_floor : 'close';
    const titleMode = workspaceSettings.mode_title ? workspaceSettings.mode_title : 'close';
    const currentUserRole = useSelector(state => state.users.currentUser.workspace_role?.workspace_role_name);

    useEffect(() => {
        setTemplates(getTemplatesFromSkill(templatesList, group.group_id));
    }, [templatesList]);

    useEffect(() => {
        footBarDispatch.set({ editionMode: false });
        modal.setItem(group);
        setGroupType(group?.custom_field?.type);
    }, [group]);

    const getSelectedOptions = () => {
        const options = [];
        if (groupType === 'BATIMENT') {
            group?.group_ids?.forEach(groupId => {
                const name = getGroupNameFromId(groupList, groupId);
                options.push({
                    value: groupId,
                    label: name
                });
            });
        } else if (groupType === 'SKILL') {
            templates?.forEach(template => {
                options.push({
                    value: template.id,
                    label: template.title
                });
            });
        }
        return options;
    };

    const getGroupOptions = () => {
        const options = [];
        if (groupType === 'BATIMENT') {
            groupList.forEach(element => {
                if (element.custom_field.type === 'FLOOR') {
                    options.push({
                        value: element.group_id,
                        label: element.group_name
                    });
                }
            });
        } else if (groupType === 'SKILL') {
            templatesList.forEach(element => {
                options.push({
                    value: element.id,
                    label: element.title
                });
            });
        }
        return options;
    };

    return (
        <div className={'resource-infos-wrapper'}>
            <div className={'title'}>
                <span>{t('common:resource_name')}</span>
                <Component
                    key={'group_name'}
                    componentName={'EditableField'}
                    title={''}
                    props={{
                        value: 'group_name',
                        required: true,
                        rules: ['maxLength-64', 'selectNotEmpty']
                    }}
                    value={() => getValue('group_name', group)}
                    renderValue={'ModalHeadSimpleString'}
                    editionComponent={'Input'}
                    onChange={values => props.updateState('group_name', values)}
                />
            </div>
            {
                floorMode === 'close' && group?.custom_field?.type === 'BATIMENT' && (
                    <div className={'resource-infos resouce-infos-full-width'}>
                        <Component
                            key={'group_groups'}
                            componentName={'EditableField'}
                            title={t('settings:floor_column_name')}
                            props={{
                                canCreate: currentUserRole === 'Owner',
                                value: 'group_groups',
                                required: true,
                                options: getGroupOptions(),
                                defaultValue: getSelectedOptions(),
                                placeholder: t('groups:select_floors'),
                                isMulti: true,
                                group_memberships: group.group_ids,
                                onChange: values => props.updateState('group_groups', values),
                                formatCreateLabel: values => `${t('common:create')} "${values}"`,
                                maxLength: 64,
                                maxVisible: 8
                            }}
                            renderValue={'GroupButton'}
                            editionComponent={'CreatableSelectComponent'}
                        />
                    </div>
                )
            }
            {
                titleMode === 'close' && group?.custom_field?.type === 'SKILL' && (
                    <div className={'resource-infos resouce-infos-full-width'}>
                        <Component
                            key={'group_templates'}
                            componentName={'EditableField'}
                            title={t('settings:templates_column_name')}
                            props={{
                                canCreate: currentUserRole === 'Owner',
                                value: 'group_templates',
                                required: true,
                                options: getGroupOptions(),
                                defaultValue: getSelectedOptions(),
                                placeholder: t('groups:select_floors'),
                                isMulti: true,
                                isNotInGroupList: true,
                                listToFilterFrom: shapeTemplatesListAsGroupsList(templates),
                                group_memberships: getTemplatesIdsFromSkill(templatesList, group.group_id),
                                onChange: values => props.updateState('group_templates', values),
                                formatCreateLabel: values => `${t('common:create')} "${values}"`,
                                maxLength: 128,
                                maxVisible: 8
                            }}
                            renderValue={'GroupButton'}
                            editionComponent={'CreatableSelectComponent'}
                        />
                    </div>
                )
            }
        </div>
    );
};
export default SettingsDetailsPopupLeft;

import { includes, isEmpty } from 'lodash';
import { isMobile } from 'react-device-detect';
import validator from 'validator';

import { isPassageDeviceType } from '../devices/helper';
import { ALERT_OPTIONS } from './constants';

// Get a concat of user name & last name from its ID
export const getUserFromId = (users, id) => users.find(user => user.id === id);

export const getUsersFromIds = (users, selectedUsersId) => {
    const selectedUsers = [];
    selectedUsersId?.forEach(id => {
        selectedUsers.push(getUserFromId(users, id));
    });
    return selectedUsers;
};

export const getUsersByGroupId = (users, groups, id) => {
    const usersByGroups = {};
    groups?.forEach(grp => {
        usersByGroups[grp.group_id] = [];
    });
    users?.forEach(user => {
        user.group_memberships?.forEach(group_membership => {
            if (usersByGroups[group_membership.group.group_id]) {
                usersByGroups[group_membership.group.group_id].push(user);
            }
        });
    });
    return usersByGroups[id];
};

// Add the 'name' to user object for UserAvatar to work
export const getUserName = (user, withoutCompany = false) => {
    if (!user) return undefined;
    const displayCompanyName = user.workspace_role?.workspace_role_name === 'Contractor' && !withoutCompany && !isEmpty(user.custom_field?.company);

    if (user.first_name === undefined) {
        if (displayCompanyName) { return `${user.custom_field?.company} - ${user.invite_email}`; }
        return user.invite_email;
    }

    if (user?.username === 'qrcodes@merciyanis.com') {
        return 'Un utilisateur anonyme';
    }
    if (displayCompanyName) {
        return `${user.custom_field?.company} - ${user.first_name || ''} ${user.last_name || ''}`;
    }

    return `${user.first_name || ''} ${user.last_name || ''}`;
};

export const addNametoUserObject = user => {
    const newUser = { ...user };
    if (user?.first_name && user.last_name) {
        newUser.name = getUserName(newUser, true);
    } else {
        newUser.name = undefined;
    }
    return newUser;
};

export const getFirstLetterUserName = user => {
    const first_name_letter = user.first_name.charAt(0);
    const last_name_letter = user.last_name.charAt(0);
    return `${first_name_letter} ${last_name_letter}`;
};

export const getUserGroupIds = user => {
    const groups = [];
    user.group_memberships?.forEach(membership => { groups.push(membership.group.group_id); });
    return groups;
};

export const getUserGroupIdsByGroupType = (user, groupType) => {
    const groups = [];
    user?.group_memberships?.forEach(membership => {
        if (membership?.group?.custom_field?.type === groupType) {
            groups.push(membership.group.group_id);
        }
    });
    return groups;
};

export const isEmail = user => user?.email && user.email?.length > 0 && validator.isEmail(user.email.trim());

export const isUserWithoutMail = currentUser => !isEmpty(currentUser) && !isEmail(currentUser);

export const isContractorWithoutMail = currentUser => isUserWithoutMail(currentUser) && currentUser.workspace_role?.workspace_role_name === 'Contractor';

export const isContractorWithMail = currentUser => !isUserWithoutMail(currentUser) && currentUser.workspace_role?.workspace_role_name === 'Contractor';

export const isOwnerOrAdmin = user => user && (user.workspace_role?.workspace_role_name === 'Owner' || user.workspace_role?.workspace_role_name === 'Admin');

export const canSeeMobileRoundView = currentUser => currentUser.workspace_role?.workspace_role_name === 'Owner'
    || currentUser.workspace_role?.workspace_role_name === 'Admin'
    || currentUser.workspace_role?.workspace_role_name === 'Contractor';

// Admins, Owners and Contractors with mail, who have Rondes as skill, can see the bottom navigation on mobile
// if they are on the round view or the tickets view
/**
 * This function is used to check if current user can see the bottom navigation bar on a mobile device
 * @param currentUser {object} Current connected user information
 * @param currentPath {string} Current path that user is navigation to
 * @param devices {[device]} List of the devices on the WS
 * @param workspaceSettings {object} All of the workspace settings
 * @return {boolean} If current user can see the bottom navigation bar on a mobile device
 */
export const canSeeMobileBottomNavigation = (currentUser, currentPath, devices, workspaceSettings) => {
    const canUsersWithoutEmailSeeTicketsViewOnMobile = !!workspaceSettings?.['show_tickets_view_for_users_without_email_on_mobile'];
    const hasPassageDeviceInWorkspace = devices.some(device => isPassageDeviceType(device));
    const isAllowedRoute = currentPath.includes('/tickets') || currentPath.includes('/rounds');
    const isOwner = currentUser.workspace_role?.workspace_role_name === 'Owner';
    const isAdmin = currentUser.workspace_role?.workspace_role_name === 'Admin';
    const hasRoundGroupMembership = currentUser?.group_memberships?.some(group => group?.group?.group_name === 'Rondes');

    // Have to be on mobile device
    if (!isMobile) return false;
    // User CS = MerciYanis
    if (isOwner) {
        return isAllowedRoute && hasPassageDeviceInWorkspace;
    }
    // User Admin
    if (isAdmin) {
        return isAllowedRoute && hasPassageDeviceInWorkspace;
    }
    // User Contractor with an e-mail address
    if (isContractorWithMail(currentUser)) {
        return isAllowedRoute && hasPassageDeviceInWorkspace && hasRoundGroupMembership;
    }
    // User Contractor without an e-mail address
    if (isContractorWithoutMail(currentUser)) {
        return isAllowedRoute && canUsersWithoutEmailSeeTicketsViewOnMobile;
    }
    // None of previous roles
    return false;
};

export const userRoleCanSeeCalendarView = showUserCalendarView => showUserCalendarView === true;
export const contractorRoleCanSeeCalendarView = showContractorCalendarView => showContractorCalendarView === true;

export const isUserOrContractorWithCalendarView = (currentUserRole, workspaceSettings) => currentUserRole === 'User' && userRoleCanSeeCalendarView(workspaceSettings?.show_user_calendar_view)
    || currentUserRole === 'Contractor' && contractorRoleCanSeeCalendarView(workspaceSettings?.show_contractor_calendar_view);

export const canCreateUpdateDeleteInstance = userRole => userRole === 'Admin' || userRole === 'Owner';

export const getVisibleUsers = (users, currentUser = null) => {
    let result = users.filter(user => user?.username !== 'merciyanis@iot-rocket.space'
        && user?.username !== 'samfm@merciyanis.com' && user?.username !== 'servicenow@merciyanis.com'
        && user?.username !== 'teams@merciyanis.com' && user?.username !== 'qrcodes@merciyanis.com');
    if (currentUser?.username !== 'merciyanis@iot-rocket.space') {
        result = result.filter(user => user?.username !== 'messaging@merciyanis.com');
    }
    return result;
};


export const getUsersOptionFromIds = (users, usersIdsList) => {
    const usersOptions = [];
    usersIdsList?.forEach(userId => {
        const user = getUserFromId(users, userId);
        usersOptions.push({ value: getUserName(user, true), id: user.id });
    });
    return usersOptions;
};

export const getUsersOptionFromUser = users => {
    const result = [];
    if (users) {
        users?.forEach(user => {
            result.push({ id: user.id, value: user.invite_email ? `✉️ ${getUserName(user)}` : getUserName(user) });
        });
        return result;
    }
    return result;
};

export const getUserNameFromEmail = (users, email) => {
    if (email) {
        const user = users.find(obj => obj?.username === email);
        if (user) return getUserName(user);
    }
    return '';
};

export const getUserGroupIdsByType = (user, groups, excludeType) => {
    const result = [];
    if (!isEmpty(excludeType)) {
        user.group_memberships?.forEach(membership => { result.push(membership.group.group_id); });
    }
    groups?.forEach(group => {
        if (group?.custom_field && !includes(excludeType, group.custom_field?.type)) result.push(group.group_id);
    });
    return result;
};

export const getUserGroupsByGroupType = (user, groupType) => {
    const groups = [];
    user?.group_memberships?.forEach(membership => {
        if (membership?.group?.custom_field?.type === groupType) {
            groups.push(membership.group);
        }
    });
    return groups;
};

export const requiredRoleToSeeRoundView = (userWorkspaceRole, workspaceSettings) => userWorkspaceRole === 'Admin' || userWorkspaceRole === 'Owner'
    || isUserOrContractorWithCalendarView(userWorkspaceRole, workspaceSettings);

export const getWorkspaceRoleNameFromId = (workspaceRoles, workspaceRoleId) => workspaceRoles.find(workspaceRole => workspaceRole.workspace_role_id === workspaceRoleId)?.workspace_role_name;

/**
 * Get available alerts for the current user based on the user role / workspace settings / user ticket mode / contractor mode / isSettingAlertsForHimself
 * @param userRole
 * @param workspaceSettings
 * @param userTicketsMode
 * @param contractorMode
 * @param isSettingAlertsForHimself
 * @return {{sms: [] | *[], email: [] | *[]}}
 */
export const getAvailableAlertsBasedOnRole = (userRole, workspaceSettings, userTicketsMode, contractorMode, isSettingAlertsForHimself = false, passageSensorExists) => {
    const emailAlerts = [];
    const smsAlerts = []
    const subscriptions = ALERT_OPTIONS(isSettingAlertsForHimself);

    /* TICKETS Alerts for EMAIL */
    // Owner, Admins, and Users and Contractor with ticketMode to all can have this alert
    if (
        userRole === 'Owner' ||
        userRole === 'Admin' ||
        (userRole === 'Contractor' && contractorMode === 'ALL') ||
        (userRole === 'User' && userTicketsMode === 'ALL')
    ) {
        emailAlerts.push(subscriptions.tickets.all);
    }
    // Every role have this alert
    emailAlerts.push(subscriptions.tickets.comment);
    // Owner, Contractors and Admins can see this alert
    if (userRole !== 'User') {
        emailAlerts.push(subscriptions.tickets.assignated);
    }

    /* ROUNDS Alerts for EMAIL */
    // Owner, Admins and Users with the calendar ws can see this alert
    // these alerts are not available if there is no passage capture in the workspace
    if (requiredRoleToSeeRoundView(userRole, workspaceSettings) && passageSensorExists) { // Round alerts
        emailAlerts.push(subscriptions.rounds.all, subscriptions.rounds.comment);
    }

    /* TICKETS alerts for SMS */
    // Owner, Admins, and Users and Contractor with ticketMode to all can have this alert => New tickets
    if (
        userRole === 'Owner'
        || userRole === 'Admin'
        || (userRole === 'Contractor' && contractorMode === 'ALL')
        || (userRole === 'User' && userTicketsMode === 'ALL')
    ) {
        smsAlerts.push(subscriptions.tickets.all);
    }
    // Allowed to all users => Ticket state updates
    smsAlerts.push(subscriptions.tickets.comment);
    // All users except tenant users ( occupant ) => Ticket assignments
    if (userRole !== 'User') {
        smsAlerts.push(subscriptions.tickets.assignated);
    }

    /* ROUNDS alerts for SMS */
    if (passageSensorExists && (userRole === 'Owner' || userRole === 'Admin' || userRole === 'Contractor')) {
        smsAlerts.push(subscriptions.rounds.instance_incomplete);
    }

    return {
        sms: smsAlerts,
        email: emailAlerts
    };
};

// Libs
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// Context
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import { useComponentsPool } from '../../../../ComponentsPool';
// Helper
import { getGroupNameFromId } from '../../../groups/helper';

const ArchivedTicketNameAndCategory = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const [ticket] = useState(props.resource);
    const groupsList = useSelector(state => state.groups.list);
    const skillName = getGroupNameFromId(groupsList, ticket?.custom_field.skill_group_id);

    return (
        <div className="table-name">
            <Tooltip
                placement="top"
                title={skillName ? t(`groups:${skillName}`) : ''}
            >
                <div>
                    <Component componentName={'Icon'} type={skillName || 'tickets'} />
                </div>
            </Tooltip>
            <div className={'table-row-content-text archived-ticket-table-category-key'}>
                <span className={'archived-titcket-title'}>{ticket.title}</span>
                <span className={'archived-titcket-key'}>{`#${ticket.key}`}</span>
            </div>
        </div>
    );
};

export default ArchivedTicketNameAndCategory;

// Libs
import React from 'react';
// Component
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
// Context
import { useModal } from '../../modal/ModalContext';
import { useComponentsPool } from '../../../ComponentsPool';

const BootstrapFab = withStyles({
    root: {
        boxShadow: 'none',
        backgroundColor: '#f7f7f7',
        border: '1px solid #eeeeee',
        color: '#363636'
    }
})(Fab);

const AddButton = props => {
    const { Component } = useComponentsPool();
    const modal = useModal();

    return (
        <div className={'add-button'}>
            <Tooltip placement={props.placement || 'top'} title={(<Component componentName={'Description'} content={props.tooltipDescription} />)}>
                <BootstrapFab
                    className={props.classes}
                    variant="extended"
                    aria-label="Details"
                    onClick={e => {
                        e.stopPropagation();
                        modal.update({ name: props.modalName, item: props.item });
                    }}
                >
                    <Component componentName={'Icon'} type={'plus'} />
                </BootstrapFab>
            </Tooltip>
        </div>
    );
};

export default AddButton;

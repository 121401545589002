import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

export const setupFirebaseMessaging = () => {
    self.addEventListener('notificationclick', event => {
        if (event.notification.data.FCM_MSG.data.url) {
            event.notification.close();
            event.waitUntil(
                self.clients.openWindow(event.notification.data.FCM_MSG.data.url)
            );
        }
    });
    // Firebase config
    // Import the functions you need from the SDKs you need
    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional

    // These are public, no security breach
    // Thus, this config cannot be set in a .env file, since it's not the same context as the app
    const firebaseConfig = {
        apiKey: 'AIzaSyBV_T1GDB12mz6oIDQfl72SRKaPGTWJt3I',
        authDomain: 'merciyanis-a6b39.firebaseapp.com',
        projectId: 'merciyanis-a6b39',
        storageBucket: 'merciyanis-a6b39.appspot.com',
        messagingSenderId: '256570681253',
        appId: '1:256570681253:web:52d830c41d7c9e20436a4c',
        measurementId: 'G-2L5MD0JM11'
    };

    // Initialize Firebase
    const app = firebase.initializeApp(firebaseConfig);

    const messaging = firebase.messaging(app);
    if (firebase.messaging.isSupported()) {
        messaging.onBackgroundMessage(payload => {
            console.log('[service-worker.js] Received background message ', payload);
            /* console.log(payload.data.url);*/
            /* if (payload.data.url) {
                window.location.replace(payload.data.url);
            }*/
            /* // Customize notification here
            const notificationTitle = payload.notification.title;
            const notificationOptions = {
                body: payload.notification.body,
                icon: payload.notification.image
            };
            self.registration.showNotification(notificationTitle,
                notificationOptions);*/


            // Pour l'instant pas besoin. Mais on va utiliser data car sinon duplication de notif
        });
    } else {
        console.error('Does not support firebase');
    }
}

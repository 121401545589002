import React, { createContext, useState } from 'react';

export const ConfigTabsContext = createContext(false);

function ConfigTabsProvider({ children }) {
    const [disableTabs, setDisableTabs] = useState(false);

    return (
        <ConfigTabsContext.Provider value={[disableTabs, setDisableTabs]}>
            {children}
        </ConfigTabsContext.Provider>
    );
}

export default ConfigTabsProvider;
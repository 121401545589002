// Libs
import React from 'react';
import { useSelector } from 'react-redux';
// Context
import { useComponentsPool } from '../../../ComponentsPool';

const DeviceActionButton = () => {
    const { Component } = useComponentsPool();
    const userRole = useSelector(state => state.users.currentUser.workspace_role?.workspace_role_name);

    return (
        <Component
            componentName={'ActionButton'}
            canUserCreate={userRole === 'Owner'}
            modalName={'DeviceAddPopup'}
            iconButton={(<Component componentName={'Icon'} type={'device'} />)}
            tooltipTitle={'devices:add_devices'}
        />
    );
};

export default DeviceActionButton;

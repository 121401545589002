import update from 'immutability-helper';

const initialState = () => ({
    routes: [
        { path: '/users', component: 'UserActionButton' },
        { path: '/devices', component: 'DeviceActionButton' },
        { path: '/dashboards', component: 'DashboardActionButton' },
        { path: '/groups', component: 'GroupActionButtonGroup' },
        { path: '/rounds', component: 'RoundActionButton' },
        { path: '/settings', component: 'SettingActionButton' },
        { path: '/tickets', component: 'TicketActionButton' },
        { path: '/tickets/:key', component: 'TicketActionButton' }
    ]
});

const actionButtonReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'ADD_ACTION_BUTTON_BAR':
            return update(state, { routes: { $push: [action.payload] } });
        default:
            return state;
    }
};

export default actionButtonReducer;

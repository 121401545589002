// Libs
import React from 'react';

const SimpleString = props => {
    // eslint-disable-next-line no-nested-ternary
    const value = props.value === ''
        ? props.isNullValue ? '-' : 0
        : props.value;
    return (
        <p className={'info-value'}>
            { value }
        </p>
    );
};

export default SimpleString;

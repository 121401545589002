import React from "react";

const SvgGauge = props => (
  <svg viewBox="0 0 512 512" {...props} width="1em" height="1em">
    <path
      d="M504.498 375.114h-8.575c-1.17-78.127-41.145-150.323-107.439-193.666a7.5 7.5 0 1 0-8.209 12.555c62.042 40.563 99.478 108.061 100.646 181.111h-75.815c-.878-36.767-15.486-71.498-41.355-98.137a7.5 7.5 0 0 0-10.761 10.449c23.136 23.825 36.236 54.844 37.112 87.688H303.32l59.702-156.994c4.316-11.347-.76-23.856-11.808-29.095-10.962-5.199-23.955-1.343-30.218 8.974L226.56 353.57a7.708 7.708 0 0 0-.291.524c-5.575 5.674-9.501 12.935-11.015 21.021h-93.358c1.063-40.405 20.359-77.38 53.313-101.885a7.5 7.5 0 0 0-8.951-12.037c-18.04 13.416-32.987 30.97-43.221 50.767-10.203 19.731-15.627 40.96-16.146 63.155H31.077c1.164-72.538 38.194-139.768 99.558-180.395a7.503 7.503 0 0 0 2.113-10.395 7.504 7.504 0 0 0-10.395-2.113C56.78 225.626 17.238 297.529 16.075 375.114H7.5c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h207.771c3.593 18.978 20.49 33.386 40.729 33.386s37.136-14.408 40.729-33.386H504.5a7.5 7.5 0 1 0-.002-15zM256 408.5c-14.577 0-26.435-11.631-26.435-25.928 0-14.296 11.858-25.927 26.435-25.927 14.577 0 26.435 11.631 26.435 25.927 0 14.297-11.858 25.928-26.435 25.928zm36.081-46.032c-7.122-12.422-20.627-20.822-36.082-20.822-1.63 0-3.24.093-4.822.275l82.641-136.141c2.222-3.659 7.037-5.069 10.968-3.204 3.911 1.856 5.724 6.244 4.216 10.21l-56.921 149.682z"
      data-original="#000000"
    />
    <path
      d="M213.361 106.68A287.166 287.166 0 0 1 256 103.5c41.892 0 82.134 8.881 119.663 26.401l-21.708 42.768a7.5 7.5 0 0 0 13.375 6.789l23.134-45.576a11.816 11.816 0 0 0 .66-9.213 11.939 11.939 0 0 0-6.195-6.977C344.568 98.321 301.19 88.5 256 88.5a302.065 302.065 0 0 0-44.873 3.348 7.5 7.5 0 0 0-6.299 8.533c.617 4.097 4.452 6.924 8.533 6.299zM189.69 268.151a11.98 11.98 0 0 0 10.708 6.587 12.15 12.15 0 0 0 5.133-1.143c15.838-7.355 32.818-11.085 50.469-11.085 2.224 0 4.467.061 6.669.182 4.108.204 7.673-2.943 7.898-7.08a7.5 7.5 0 0 0-7.08-7.898 138.004 138.004 0 0 0-7.487-.203c-18.899 0-37.126 3.808-54.219 11.32l-65.443-128.929a281.432 281.432 0 0 1 48.205-17.333 7.5 7.5 0 0 0 5.376-9.144 7.497 7.497 0 0 0-9.144-5.376 296.375 296.375 0 0 0-53.704 19.643 11.939 11.939 0 0 0-6.195 6.977 11.816 11.816 0 0 0 .66 9.213l68.154 134.269z"
      data-original="#000000"
    />
  </svg>
);

export default SvgGauge;

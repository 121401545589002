
import IconButton from '@material-ui/core/IconButton';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import exportInstancesToFile from './exportInstancesToFile';
import { exportInstances } from '../../../actions';
import { notification } from '../../../../notification/actions';
import store from '../../../../../store';
import { useComponentsPool } from '../../../../../ComponentsPool';
import useRouter from '../../../../../utils/hook/useRouter';

const dispatch = store.dispatch;

const ExportInstanceBtn = ({
    fileName, instanceToExport, withTooltip, canExportPdfAndCsv
}) => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const instances = useSelector(({ rounds }) => rounds.roundShownInDetailsPopup.instances);
    const options = [{ fileExtension: '.csv', buttonText: 'exportCSV' }, { fileExtension: '.pdf', buttonText: 'exportPDF' }];
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const devices = useSelector(state => state.devices.list);
    const currentUser = useSelector(state => state.users.currentUser);
    const workspace = localStorage.getItem('iot_rocket_workspace');

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const { query } = useRouter();
    const onClick = (fileExtension = '.csv') => {
        // instanceToExport is when it's a single instance export
        const toExport = instanceToExport
            ? [{ instanceId: instanceToExport.id, instanceStatus: instanceToExport.status, roundId: instanceToExport.round }]
            : instances ? instances.map(item => ({ instanceId: item.id, instanceStatus: item.status, roundId: item.round })) : [];
        if (toExport.length > 0) {
            dispatch(exportInstances(toExport, query?.view)).then(res => {
                if (res.value) {
                    exportInstancesToFile(res.value, fileName + fileExtension, t, devices, currentUser, workspace);
                } else {
                    dispatch(notification(t('notifications:export_instances_error'), 'error', 'frown'));
                }
            });
        }
    };

    const disabled = instances ? instances.length === 0 : true;

    return (
        canExportPdfAndCsv ? (
            <div className="export-pdf-csv-btn">
                <ButtonGroup
                    variant="contained"
                    ref={anchorRef}
                    aria-label="split button"
                >
                    <Button style={{ textTransform: 'none' }} onClick={() => onClick(options[selectedIndex].fileExtension)} disabled={disabled}>{t(`dashboards:${options[selectedIndex].buttonText}`)}</Button>
                    <Button size="small" onClick={() => setOpen(prevOpen => !prevOpen)} disabled={disabled}><ArrowDropDownIcon /></Button>
                </ButtonGroup>
                <Popper
                    style={{ width: 'inherit' }}
                    sx={{ zIndex: 1 }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    placement={'bottom-start'}
                >
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={option.buttonText}
                                                selected={index === selectedIndex}
                                                onClick={event => handleMenuItemClick(event, index)}
                                            >
                                                {t(`dashboards:${option.buttonText}`)}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        ) : (
            <Tooltip
                arrow={false}
                title={withTooltip ? t('rounds:export_event_data') : t('rounds:export_week_data')}
                placement={'top'}
            >
                <div className="export-btn">
                    <IconButton onClick={() => onClick()} disabled={disabled}>
                        <Component componentName={'Icon'} type={'export'} size="sm" />
                    </IconButton>
                </div>
            </Tooltip>
        )
    );
};

export default ExportInstanceBtn;

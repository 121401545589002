// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ArchivedTicketCounterAndPriority = props => {
    const levels = useSelector(state => state.tickets.priorityLevels);
    const archivedTicketLevelName = levels.find(level => level.id === props.resource.priority)?.name;
    const [t] = useTranslation();
    const currentUserRole = useSelector(state => state.users.currentUser.workspace_role?.workspace_role_name);

    return (
        <div className={'counter-and-priority'}>
            <span>{props.resource?.custom_field?.counter ? props.resource.custom_field.counter : '-'}</span>
            {currentUserRole !== 'User'
                && <span className={`archived-ticket-priority priority_${props.resource.priority}`}>{t(`tickets:${archivedTicketLevelName}`)}</span>
            }
        </div>
    );
};

export default ArchivedTicketCounterAndPriority;

// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Context
import { useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import Button from '@mui/material/Button';
import classnames from 'classnames';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { cloneDeep } from 'lodash';
import { getGroupNameFromId, getLocationAsFilters, getMultipleLocationAsFilters } from '../../groups/helper';
import Icon from '../../layout/components/Icon';
import { getDeviceFromId } from '../../devices/helper';
import { useSecondMainColorTooltip } from '../../layout/helper';

const transformDocumentListToObjectToFilters = (devices, documents) => {
    const documentsCopy = cloneDeep(documents);
    documentsCopy.forEach(doc => {
        const buildings_ids = [];
        const floors_ids = [];
        const positions_ids = [];
        const devices_ids = [];
        doc.currently_assigned_device_ids.forEach(current_device => { // For each doc retrieve the associated device and location groups
            const device = devices.find(device => device.device_id === current_device);
            if (device?.custom_field) { // This line filters the device and location groups the user has rights on
                if (!buildings_ids.includes(device.custom_field.batiment_group_id)) buildings_ids.push(device.custom_field.batiment_group_id);
                if (!floors_ids.includes(device.custom_field.floor_group_id)) floors_ids.push(device.custom_field.floor_group_id);
                if (!positions_ids.includes(device.custom_field.position_group_id)) positions_ids.push(device.custom_field.position_group_id);
                if (!devices_ids.includes(device.device_id)) devices_ids.push(device.device_id);
            }
        });
        doc.batiment_group_ids = buildings_ids,
        doc.floor_group_ids = floors_ids,
        doc.position_group_ids = positions_ids,
        doc.devices_ids = devices_ids;
    });
    return documentsCopy;
};

const DocumentSearchBar = props => {
    const [t] = useTranslation();
    const [currentFilter, setCurrentFilter] = useState(null);
    const [displayedFilters, setDisplayedFilters] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const groupList = useSelector(state => state.groups.list);
    const devices = useSelector(state => state.devices.list);

    // Ignore the following withing the input : accent, many spaces, uppercase
    const normalizeString = text => text?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.replace(/\s/g, '')?.toLowerCase();

    /* Objects to filter can look like this (if props.isDocumentListFilter):
       {
            "title": "Title of the object",
            "batiment_group_ids": ["id1", "id2"],
            "floor_group_ids": ["id1"],
            "position_group_ids": [],
            "devices_ids": ["id1", "id2"]
        }
        Or like this :
        {
            "batiment_group_id": "id",
            "floor_group_id": "id",
            "position_group_id": "",
            "device_id": "id"
        }
    */
    useEffect(() => { // Everytime the input content changes, display the right filters
        if (inputValue.trim() !== '') {
            let availableFilters = [];
            const objectsToFilters = props.isDocumentListFilter ? transformDocumentListToObjectToFilters(devices, props.objectsToFilter) : props.objectsToFilter;

            // "contains" filter
            availableFilters = availableFilters.concat([{ filterName: inputValue, filterBy: 'everyField' }]);

            if (props.filterBy.includes('locations')) { // Objects can be filtered by their locations (batiment, floor, position)
                availableFilters = props.isDocumentListFilter
                    ? availableFilters.concat(getMultipleLocationAsFilters(objectsToFilters, groupList))
                    : availableFilters.concat(getLocationAsFilters(objectsToFilters, groupList));
            }
            if (props.filterBy.includes('devices')) { // Objects can be filtered by their device_name
                if (props.isDocumentListFilter) {
                    objectsToFilters?.forEach(object => {
                        object.devices_ids.forEach(deviceId => {
                            availableFilters = availableFilters.concat([{ filterName: getDeviceFromId(devices, deviceId)?.device_name, filterIcon: 'QRCODE', filterBy: 'deviceName' }]);
                        });
                    });
                } else {
                    availableFilters = availableFilters.concat(objectsToFilters?.map(step => ({ filterName: step.device_name, filterIcon: 'QRCODE', filterBy: 'deviceName' })));
                }
            }
            if (props.filterBy.includes('title')) { // Objects can be filtered by their title
                availableFilters = availableFilters.concat(objectsToFilters?.map(step => ({ filterName: step.title, filterIcon: 'singleFile', filterBy: 'fileName' })));
            }

            availableFilters = [...new Map(availableFilters.map(filter => [JSON.stringify(filter), filter])).values()]; // Remove duplicate filters
            const filtersToDisplay = [];
            availableFilters.forEach(result => { // Check if the available filters matches the input value, if so, display them
                if (normalizeString(result.filterName)?.includes(normalizeString(inputValue))) {
                    filtersToDisplay.push(result);
                }
            });
            setDisplayedFilters(filtersToDisplay);
        } else {
            setDisplayedFilters([]);
        }
    }, [inputValue]);

    const filterDevicesNames = (devicesIds, normalisedFilterName) => devicesIds.map(deviceId => normalizeString(getDeviceFromId(devices, deviceId)?.device_name)).includes(normalisedFilterName);
    const containsFilterDeviceName = (devicesIds, normalisedFilterName) => {
        const devicesContainingFilter = devicesIds.filter(deviceId => normalizeString(getDeviceFromId(devices, deviceId)?.device_name).includes(normalisedFilterName));
        return devicesContainingFilter.length > 0 ? devicesContainingFilter : null; // Array can be empty since contains filter doesn't check if results are available
    };
    const filterLocations = (locationsIds, normalisedFilterName) => locationsIds.map(locId => normalizeString(getGroupNameFromId(groupList, locId))).includes(normalisedFilterName);
    const containsFilterLocations = (locationsIds, normalisedFilterName) => {
        const locationsContainingFilter = locationsIds.filter(locId => normalizeString(getGroupNameFromId(groupList, locId)).includes(normalisedFilterName));
        return locationsContainingFilter.length > 0 ? locationsContainingFilter : null; // Array can be empty since contains filter doesn't check if results are available
    };

    const filterResults = filter => { // Return the objects currently filtered
        const objectsToFilters = props.isDocumentListFilter ? transformDocumentListToObjectToFilters(devices, props.objectsToFilter) : props.objectsToFilter;
        const normalisedFilterName = normalizeString(filter.filterName);
        setInputValue('');
        let filteredObjects;
        if (filter.filterBy === 'fileName') {
            filteredObjects = objectsToFilters?.filter(object => normalizeString(object.title) === normalisedFilterName);
        } else if (filter.filterBy === 'deviceName') {
            filteredObjects = props.isDocumentListFilter
                ? objectsToFilters?.filter(object => filterDevicesNames(object.devices_ids, normalisedFilterName))
                : objectsToFilters?.filter(object => normalizeString(object.device_name) === normalisedFilterName);
        } else if (filter.filterBy === 'batiment') {
            filteredObjects = props.isDocumentListFilter
                ? objectsToFilters?.filter(object => filterLocations(object.batiment_group_ids, normalisedFilterName))
                : objectsToFilters?.filter(object => normalizeString(object.batiment_group_name) === normalisedFilterName);
        } else if (filter.filterBy === 'floor') {
            filteredObjects = props.isDocumentListFilter
                ? objectsToFilters?.filter(object => filterLocations(object.floor_group_ids, normalisedFilterName))
                : objectsToFilters?.filter(object => normalizeString(object.floor_group_name) === normalisedFilterName);
        } else if (filter.filterBy === 'position') {
            filteredObjects = props.isDocumentListFilter
                ? objectsToFilters?.filter(object => filterLocations(object.position_group_ids, normalisedFilterName))
                : objectsToFilters?.filter(object => normalizeString(object.position_group_name) === normalisedFilterName);
        } else if (filter.filterBy === 'everyField') {
             filteredObjects = props.isDocumentListFilter
                ? objectsToFilters?.filter(
                    object => normalizeString(object.title).includes(normalizeString(inputValue))
                        || containsFilterDeviceName(object.devices_ids, normalizeString(inputValue))
                        || containsFilterLocations(object.batiment_group_ids, normalizeString(inputValue))
                        || containsFilterLocations(object.floor_group_ids, normalizeString(inputValue))
                        || containsFilterLocations(object.position_group_ids, normalizeString(inputValue))
                ) : objectsToFilters.filter(
                    object => normalizeString(object.device_name).includes(normalizeString(inputValue))
                        || normalizeString(object.batiment_group_name).includes(normalizeString(inputValue))
                        || normalizeString(object.floor_group_name).includes(normalizeString(inputValue))
                        || normalizeString(object.position_group_name).includes(normalizeString(inputValue))
                );
        }
        props.setResults(filteredObjects);
    };

    useEffect(() => { // Everytime objects to filter changes we need to check if they are still correctly filtered
        if (currentFilter) {
            filterResults(currentFilter);
        }
    }, [props.objectsToFilter]);

    const ExitSearchButton = () => (
        <Tooltip
            classes={useSecondMainColorTooltip()}
            position={'bottom'}
            title={t('common:erase')}
        >
            <Button
                onClick={() => {
                    setDisplayedFilters([]);
                    setInputValue('');
                    setCurrentFilter(null);
                    props.setResults(props.objectsToFilter);
                }}
            >
                <Icon type={'close'} />
            </Button>
        </Tooltip>
    );

    const InputContent = () => {
        if (currentFilter) {
            return (
                <>
                    <Icon type={'search'} />
                    <Chip
                        className={currentFilter.filterIcon ? 'current-filter-chip' : 'current-filter-chip-without-icon'}
                        icon={currentFilter.filterIcon && <Icon type={currentFilter.filterIcon} />}
                        label={currentFilter.filterName}
                    />
                </>
            );
        }
        return <Icon type={'search'} />;
    };

    return (
        <div className={classnames(['document-searchbar-wrapper', { 'document-searchbar-wrapper-opened': displayedFilters.length > 0 }])}>
            <TextField
                className={'searchbar-textfield'}
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                placeholder={!currentFilter && t(`rounds:${props.isDocumentListFilter ? 'search_documents_description' : 'search_qrcodes_description'}`)}
                InputProps={{
                    startAdornment: <InputContent />,
                    endAdornment: (currentFilter || inputValue.trim() !== '') && <ExitSearchButton />,
                    disableUnderline: true
                }}
            />
            {displayedFilters.length > 0 && (
                <List className={'searchbar-filters-list'}>
                    {displayedFilters.map(filter => (
                        <ListItem
                            onClick={() => {
                                setCurrentFilter(filter);
                                filterResults(filter);
                            }}
                            disablePadding
                        >
                            <ListItemButton>
                                <ListItemIcon className={classnames({ 'filter-without-icon': !filter.filterIcon })}>
                                    {filter.filterIcon && <Icon type={filter.filterIcon} />}
                                </ListItemIcon>
                                <ListItemText primary={filter.filterName} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            )}
        </div>
    );
};

export default DocumentSearchBar;

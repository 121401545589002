// Libs
import React, { useEffect, useState } from 'react';
import { uuid } from 'uuidv4';
import classNames from 'classnames';

const initSteps = (stepsNb, activeStep) => {
    const steps = [];
    for (let i = 0; i < stepsNb; i = i + 1) {
        steps.push({
            completed: i < activeStep,
            active: activeStep === i,
            id: uuid()
        });
    }
    return steps;
};

const Stepper = props => {
    const [steps, setSteps] = useState(initSteps(props.steps, props.activeStep));

    useEffect(() => {
        setSteps(initSteps(props.steps, props.activeStep));
    }, [props.activeStep]);

    return (
        <div className={'iot-rocket-stepper'}>
            { steps.map((step, ndx) => (
                <React.Fragment key={step.id}>
                    <div className={classNames('step', step.completed && 'completed-step', step.active && 'active-step')} />
                    { ndx !== props.steps - 1 && <div className={'step-divider'} />}
                </React.Fragment>
            ))}
        </div>
    );
};

export default Stepper;

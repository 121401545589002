// Libs
import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { sortBy } from 'lodash';
// Actions
import createLoadingSelector from '../../../layout/actions';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';
import { noFinishedEventToExportNotif } from '../../../notification/actions';

const loadingSelector = createLoadingSelector(['EDIT_ROUND', 'EDIT_ROUND_CUSTOM_ERRORS', 'GET_ROUND_DATA_EXPORT']);

const RoundDetailsPopup = () => {
    const { Component } = useComponentsPool();
    const rounds = useSelector(state => state.rounds);
    const round = rounds.roundShownInDetailsPopup;
    const dataExport = rounds.dataExport;
    const dispatch = useDispatch();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const footbar = useFootBar();
    const editionMode = footbar.getEditionMode();
    const [wasInEditionMode, setWasInEditionMode] = useState(false);
    const [closeButtonAnimation, setCloseButtonAnimation] = useState(false);

    const content = rounds.detailPopupParts.map(part => ({
        width: part.width,
        content: <Component
            componentName={part.component}
            state={round}
        />
    }));

    // the animation from the edition mode to the vizualisation mode can only happen if we were/are in editionMode
    useEffect(() => {
        if (editionMode) {
            setWasInEditionMode(true);
            setCloseButtonAnimation(true);
        } else {
            footbar.set({ editionObject: {}, isEdited: false });
        }
        if (wasInEditionMode && !editionMode) {
            setCloseButtonAnimation(true);
        }
    }, [editionMode]);

    useEffect(() => {
         if (editionMode || (wasInEditionMode && !editionMode)) {
            // set setCloseButtonAnimation to false when the animation is over
            setTimeout(function () {
                setCloseButtonAnimation(false)
            }, 600);
         }
    }, [closeButtonAnimation]);

    const getRightPopupStyle = () => {
        if (footbar.getEditionMode()) {
            return 'details-popup-full-width';
        }
        if (wasInEditionMode) {
            return 'details-popup-initial-width';
        }
        return '';
    }

    useEffect(() => {
        // No instances to export or no finished event available => Export will be empty so we display an error
        if (round.dataExport?.length === 0 && round.instances?.every(elt => ['NOT_STARTED', 'IN_PROGRESS'].includes(elt.status))) {
            dispatch(noFinishedEventToExportNotif());
        }
    }, [round.dataExport]);

    return (
        <Component
            componentName={'Modal'}
            loading={isLoading}
            contentWidth={'100%'}
            content={content}
            classNames={'details-popup ' + getRightPopupStyle()}
            closeButtonHideAndReappear={closeButtonAnimation}
        />
    );
};

export default RoundDetailsPopup;

// Libs
import React from 'react';
// Context
import { useTranslation } from 'react-i18next';
import { useComponentsPool } from '../../../ComponentsPool';

const DataTypesSelector = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();

    return (
        <Component
            componentName={'Select'}
            renderValue={'chip'}
            multiple
            rules={props.rules}
            placeHolder={t('graphs:select_data')}
            selected={props.selected}
            options={props.options}
            onChange={props.onChange}
        />
    );
};

export default DataTypesSelector;

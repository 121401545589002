// Libs
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Context
import { useComponentsPool } from '../../../ComponentsPool';

const ButtonTableToggle = props => {
    const { Component } = useComponentsPool();

    useEffect(() => {
        if (props.index) {
            const button = document.querySelector(`#button-toggle-table-${props.index}`);
            if (props.open) {
                button.nextSibling.firstElementChild.style.overflow = 'auto'; // Show scroll bar
                button.firstElementChild.style.transform = 'rotate(90deg)'; // Rotate Icon Arrow
            } else {
                button.nextSibling.firstElementChild.style.overflow = 'hidden'; // Show scroll bar
                button.firstElementChild.style.transform = 'rotate(-90deg)'; // Rotate Icon Arrow
            }
        }
    }, [props.open]);

    return (
        <div
            role={'button'}
            tabIndex={-1}
            className={'button-toggle-table'}
            id={`button-toggle-table-${props.index}`}
            onClick={() => props.resize()}
        >
            <Component componentName={'Icon'} type={'rightArrow'} />
        </div>
    );
};

ButtonTableToggle.propTypes = {
    resize: PropTypes.func.isRequired
};

export default ButtonTableToggle;

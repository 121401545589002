// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useComponentsPool } from '../../../../ComponentsPool';
import { getAssignersByGroupId } from '../../helper';
import { getUserName, getUsersFromIds } from '../../../users/helper';
// Notification
import { Toast } from '../../../notification/components';

const getOptions = users => {
    const returnGroups = [];
    users.forEach(user => {
        returnGroups.push({ id: user?.id, value: getUserName(user) });
    });
    return returnGroups.sort((a, b) => {
        if (a.value > b.value) return 1;
        if (a.value < b.value) return -1;
        return 0;
    });
};
const SelectAssigners = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const users = useSelector(state => state.users.list);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setSelected(getOptions(getUsersFromIds(users, props.selected)));
    }, []);

    useEffect(() => {
        const assigners = getAssignersByGroupId(
            users,
            workspaceSettings.filter_admin_on_skills,
            props.batimentGroupId,
            props.skillGroupId
        );
        setOptions(getOptions(assigners));

        // Chaque fois que le bâtiment et la catégorie du ticket sont modifiés
        // => vérifier que les utilisateurs assignés ont toujours le droit de l'être
        // si ce n'est pas le cas les déssassigner et générer un message informatif
        const newSelectedAssignerIds = [];
        const newSelectedAssigners = [];
        const deletedAssigners = [];
        selected.map(userOption => {
            if (assigners.find(assigner => assigner?.id === userOption?.id)) {
                newSelectedAssignerIds.push(userOption?.id);
                newSelectedAssigners.push(userOption);
            } else {
                deletedAssigners.push(userOption);
            }
        });

        if (deletedAssigners.length > 0) {
            props.onChange(newSelectedAssignerIds);
            deletedAssigners.map(assigner => {
                toast(
                    <Toast
                        isConfirm
                        message={t('notifications:assigned_user_removed_from_ticket', { userName: assigner.value })}
                        icon={'check'}
                        type={'successful'}
                    />, {
                        position: toast.POSITION.BOTTOM_LEFT,
                        className: 'normal',
                        bodyClassName: 'notification-body grow-font-size',
                        progressClassName: 'successful-progress-bar'
                    }
                );
            });
        }
    }, [props.batimentGroupId, props.skillGroupId]);

    useEffect(() => {
        setSelected(getOptions(getUsersFromIds(users, props.selected)));
    }, [props.selected]);

    return (
        <Component
            componentName={'Select'}
            multiple
            placeHolder={''}
            onChange={usersSelected => props.onChange(usersSelected.map(user => user.id))}
            options={options}
            renderValue={'chip'}
            showSelectAll
            selected={selected}
            emptyChip={selected}
            disabled={props.disabled}
        />
    );
};

export default SelectAssigners;


// Library
import React from 'react';

const LeftSection = () => (
    <div
        className="left-section"
    />
);
export default LeftSection;


// Lib
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Context
import { toast } from 'react-toastify';
import { useComponentsPool } from '../../../../ComponentsPool';
// Actions
import { updateWorkspaceSetting } from '../../../core/actions';
import { Toast } from '../../../notification/components';
import { EVENT_MODIFY_WORKSPACE_SETTINGS, segmentTrack } from '../../../layout/helper';
import { ConfigTabsContext } from '../../ConfigContext';

const UsersConfiguration = props => {
    const { t } = useTranslation();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const [currentlyModifiedConfig, setCurrentlyModifiedConfig] = useState([]);
    const [cancelModifs, setCancelModifs] = useState(false);
    const [enableSaveButton, setEnableSaveButton] = useState(false);
    const [, setDisableTabs] = useContext(ConfigTabsContext);
    const tabs = [
        {
            componentName: 'UsersRights',
            configName: 'configure_users_rights'
        },
        {
            componentName: 'ContractorsRights',
            configName: 'configure_contractors_rights'
        }
    ];

    useEffect(() => { // Enable save button, cancel button, and tabs when needed
        const enableSave = currentlyModifiedConfig.length !== 0;
        setEnableSaveButton(enableSave);
        setDisableTabs(enableSave);
    }, [currentlyModifiedConfig]);

    return (
        <div className={'configuration-page-content'}>
            <Component componentName={tabs[props.tabIdx].componentName} cancelModifs={cancelModifs} setCancelModfis={value => setCancelModifs(value)} modifyCurrentConfig={newValue => setCurrentlyModifiedConfig(newValue)} />
            <div className={'configuration-menu-footer'}>
                <Component
                    componentName={'Button'}
                    className={'custom-cancel-button'}
                    onClick={() => setCancelModifs(true)}
                    text={t('common:cancel')}
                    disabled={!enableSaveButton}
                />
                <Component
                    componentName={'Button'}
                    className={'custom-save-button'}
                    onClick={async () => {
                        let hasWorked = true;
                        await Promise.all(currentlyModifiedConfig.map(async configToModify => {
                            await dispatch(updateWorkspaceSetting(
                                configToModify.configName,
                                configToModify.configValue,
                                Object.prototype.hasOwnProperty.call(workspaceSettings, configToModify.configName),
                                true
                            )).catch(() => { hasWorked = false; });
                        })).then(() => {
                            const modifiedWorkspaceSettings = currentlyModifiedConfig.map(modifiedConfig => ({
                                workspaceSettingName: modifiedConfig.configName,
                                oldWorkspaceSettingValue: workspaceSettings[modifiedConfig.configName],
                                newWorkspaceSettingValue: modifiedConfig.configValue
                            }));
                            segmentTrack(EVENT_MODIFY_WORKSPACE_SETTINGS, { modifiedWorkspaceSettings });
                            toast(
                                <Toast
                                    message={t(`notifications:${hasWorked ? 'modifications_were_made' : 'modifications_werent_made'}`)}
                                    icon={hasWorked ? 'check' : 'frown'}
                                    type={hasWorked ? 'successful' : 'error'}
                                />, {
                                    position: toast.POSITION.BOTTOM_LEFT,
                                    className: 'normal',
                                    bodyClassName: 'notification-body grow-font-size',
                                    progressClassName: hasWorked ? 'successful-progress-bar' : 'error-custom-progress-bar'
                                }
                            );
                            setCurrentlyModifiedConfig([]);
                            setEnableSaveButton(false);
                            setCancelModifs(false);
                            setDisableTabs(false);
                        });
                    }}
                    text={t('common:save')}
                    disabled={!enableSaveButton}
                />
            </div>
        </div>
    );
};

export default UsersConfiguration;

// Libs
import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useModal } from '../../../modal/ModalContext';

const UserNfcTag = props => {
    const { Component } = useComponentsPool();
    const modal = useModal();
    const [user] = useState(props.resource || modal.getItem());

    return !user.nfc_tag
        ? <></>
        : (
            <Tooltip
                position={'top'}
                title={(<Component componentName={'Description'} content={user.nfc_tag} />)}
            >
                <Chip
                    icon={<Component componentName={'Icon'} type={'nfcTag'} />}
                    label={user.nfc_tag}
                    className={'group-name'}
                />
            </Tooltip>
        );
};

export default UserNfcTag;

// Libs
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Lottie from 'react-lottie';
import classNames from 'classnames';
import { useComponentsPool } from '../../../../ComponentsPool';
// Actions
import { updateTicket } from '../../../tickets/actions';
import { setCurrentQRCodeTicket } from '../../actions';
// Helpers
import { EVENT_LET_USER_MAIL, isValid, segmentTrack } from '../../../layout/helper';
import { isEmail } from '../../../users/helper';
// Icons
import successAnimation from '../../assets/check.json';
import checkMailAnimation from '../../assets/successMail.json';
import errorMailAnimation from '../../assets/errorMail.json';
import Icon from '../../../layout/components/Icon';

const NewTicketFormValidation = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const relatedTicket = props?.relatedTicket;
    const isLogged = useSelector(state => state.authentication.isLogged);
    const currentUser = useSelector(state => state.users.currentUser);
    const isLoggedOrWithoutMail = isLogged && isEmail(currentUser);
    const [email, setEmail] = useState(isLoggedOrWithoutMail ? currentUser?.email : '');
    const [followIncident, setFollowIncident] = useState(false);
    const [validationStatus, setValidationStatus] = useState('not_ready');
    const [hasTriedToFollow, setHasTriedToFollow] = useState(false);
    const mandatoryMailInQrcode = useSelector(state => state.workspace.settings?.mandatory_mail_in_qrcode);

    // when we have the relatedTicket, put it in the store and set the user's right
    useEffect(() => {
        if (relatedTicket) {
            dispatch(setCurrentQRCodeTicket(relatedTicket));
        }
    }, [relatedTicket]);

    const handleChangeEmail = value => {
        if (isValid(value)) {
            setEmail('');
        } else {
            setEmail(value);
        }
    };

    // if the user has no rights we save him as an external follower
    const updateTicketFollowers = () => {
        segmentTrack(EVENT_LET_USER_MAIL, { qrCodeId: relatedTicket?.custom_field?.author_device_id, mailGivenByFollower: email });
        setHasTriedToFollow(true);
        dispatch(updateTicket(
            relatedTicket.id,
            {
                external_followers: [
                    {
                        mail: email
                    }
                ],
                is_declarative: true
            }, true)).then(() => {
            setValidationStatus('validated');
            dispatch(setCurrentQRCodeTicket(null));
        });
    };

    const hideValidateFollowButton = isLoggedOrWithoutMail && !followIncident || !isLoggedOrWithoutMail && !email;
    const getComponent = () => {
        switch (validationStatus) {
            case 'not_ready':
                return (
                    <>
                        <span className={'valid-incident-text'}>
                            {`${t('passages:your_request') } `}
                            {`${t('passages:was_sent') } `}
                        </span>
                        <span className={'incident-tile-summary'}>{relatedTicket?.title}</span>
                        <div className="valid-incident-animation">
                            <Lottie
                                options={{
                                    loop: false,
                                    autoplay: true,
                                    animationData: successAnimation
                                }}
                                height={'100%'}
                                width={'100%'}
                            />
                        </div>
                        {isLoggedOrWithoutMail ? (
                            <>
                                <span className="follow-incident-text">
                                    {t('passages:follow_request')}
                                </span>
                                <div className="incident-checkbox-follow">
                                    <Checkbox
                                        checked={followIncident}
                                        onChange={() => setFollowIncident(!followIncident)}
                                    />
                                    <p>{`${t('passages:follow_request_checkbox') } ${ t('common:optional')}`}</p>
                                </div>
                            </>
                        ) : mandatoryMailInQrcode ? (
                            <span className={'valid-incident-text'}>
                                <p>{`${t('common:thanks')} !`}</p>
                            </span>
                        ) : (
                            <>
                                <span className="follow-incident-text">{t('passages:sign_up_to_follow_request')}</span>
                                <Component
                                    className={'email-input'}
                                    componentName={'Input'}
                                    rules={['isEmail']}
                                    startAdornment={<Component componentName={'Icon'} type={'email'} />}
                                    label={`${t('common:email_address') } ${ t('common:optional')}`}
                                    onChange={value => handleChangeEmail(value.trim())}
                                />
                            </>
                        )}
                        {!hideValidateFollowButton && (
                            <div className={'qrcode-button'}>
                                <Button
                                    key={'validate_with_job'}
                                    onClick={() => updateTicketFollowers()}
                                >
                                    <p className={'qrcode-button-text'}>{t('passages:validate_passage')}</p>
                                </Button>
                            </div>
                        )}
                    </>
                );
            case 'validated':
                return (
                    <div className={'following_request_success'}>
                        <div className={'following_request_animation'}>
                            <Lottie
                                options={{
                                    loop: false,
                                    autoplay: true,
                                    animationData: checkMailAnimation
                                }}
                                height={'100%'}
                                width={'100%'}
                            />
                        </div>
                        <span className={'valid-incident-text'}>
                            <p>{t('passages:you_are_following')}</p>
                            <p><b>{t('passages:the_request')}</b></p>
                        </span>
                    </div>
                );
            default:
                return (
                    <Component componentName={'MobileErrorPage'} hasGoBackArrow={props.hasGoBackArrow} lottieIcon={hasTriedToFollow && errorMailAnimation} />
                );
        }
    };

    return (
        getComponent()
    );
};

export default NewTicketFormValidation;

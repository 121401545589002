// Libs
import React from 'react';
import classNames from 'classnames';
// Components
import Chip from '@material-ui/core/Chip';
// Context
import { useSelector } from 'react-redux';
import { useComponentsPool } from '../../../../ComponentsPool';

export default props => {
    const { Component } = useComponentsPool();
    const groups = useSelector(state => state.groups.list);

    const groupBatiment = groups.find(g => g.group_id === props.ticket?.custom_field?.batiment_group_id);
    const groupPosition = groups.find(g => g.group_id === props.ticket?.custom_field?.position_group_id);
    const groupFloor = groups.find(g => g.group_id === props.ticket?.custom_field?.floor_group_id);
    const currentUser = useSelector(state => state.users.currentUser);
    const hasFloor = groupPosition || groupFloor;
    return (
        <div className={'lower-part'}>
        <div className={classNames('group-wrapper', hasFloor && 'group-wrapper-with-floor')}>
                { groupBatiment
                    ? (
                        <Chip
                            label={groupBatiment?.group_name}
                        />
                    ) : null }
                { hasFloor
                    ? <div className={"ticket-view-floor"}>{groupFloor?.group_name || groupPosition?.group_name}</div>
                    : null }
            </div>
            {currentUser.workspace_role.workspace_role_name !== 'User' &&
                <Component
                    componentName={'TicketAssignee'}
                    assigners={props.ticket.assigners}
                    shortenText
                />
            }
        </div>
    );
};


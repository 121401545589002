// Libs
import React from 'react';
import { useSelector } from 'react-redux';

const MerciYanisLogoNavbar = () => {
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const MerciYanisLogo = workspaceSettings.logo_url;
    return (
        <div className={'my-logo-navbar'}>
            <img src={MerciYanisLogo} alt={'MerciYanis logo'} />
        </div>
    );
};

export default MerciYanisLogoNavbar;

// Libs
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { forEach, toLower } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import { resetFilters, setFocusedNavButton } from '../actions';

const getDefaultOptions = (t, settings) => {
    const options = [];
    const modules = settings.modules
        .sort((moduleA, moduleB) => {
            const weightA = moduleA.weight ? moduleA.weight : 0;
            const weightB = moduleB.weight ? moduleB.weight : 0;
            if (weightA > weightB) return 1;
            if (weightA < weightB) return -1;
            return 0;
        });
    forEach(modules, ({
        route, subRoutes, icon, inSearchBar, override, label
    }) => {
        if (route && route !== '' && inSearchBar && !override) {
            options.push({
                to: `/${route}`,
                type: icon,
                text: t(label)
            });
        }
        if (subRoutes && subRoutes.length > 0) {
            forEach(subRoutes, subRoute => {
                if (subRoute.inSearchBar) {
                    options.push({
                        to: `/${subRoute.route}`,
                        type: subRoute.icon,
                        text: subRoute.label,
                        isSubRoute: true
                    });
                }
            });
        }
    });
    return options;
};

const NavigationDropdown = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const settings = useSelector(state => state.core.settings);
    const [defaultOptions] = useState(() => getDefaultOptions(t, settings));
    const focusedNavButton = useSelector(state => state.navbar.focusedNavButton);

    const navigate = link => {
        dispatch(setFocusedNavButton(toLower(link)));
        history.push(link);
    };

    return (
        <div className={classNames(['routes-dropdown', props.routesDropdown ? 'active' : ''])}>
            { defaultOptions.map((option, ndx) => (
                <li
                    key={option.to}
                    className={focusedNavButton === option.to ? 'selected' : ''}
                >
                    <div
                        className={'link-wrapper'}
                        tabIndex={ndx}
                        role={'button'}
                        onClick={() => {
                            if (!settings.filterByGroupRelation) dispatch(resetFilters());
                            navigate(option.to);
                        }}
                    >
                        <div className={'svg-wrapper'}><Component componentName={'Icon'} type={option.type} /></div>
                        <span>{t(option.text)}</span>
                        { focusedNavButton === option.to && <Component componentName={'Icon'} type={'check'} /> }
                    </div>
                </li>
            ))}
        </div>
    );
};

export default NavigationDropdown;


export default {
    list: {
        // LAYOUT
        Layout: { file: './modules/layout/index' },
        AppLoader: { file: './modules/layout/components/AppLoader' },
        Input: { file: './modules/layout/components/Input' },
        InputAutocomplete: { file: './modules/layout/components/InputAutocomplete' },
        Select: { file: './modules/layout/components/Select' },
        SimpleString: { file: './modules/layout/components/SimpleString' },
        Modal: { file: './modules/modal/Components/Modal' },
        Icon: { file: './modules/layout/components/Icon' },
        ChipButton: { file: './modules/layout/components/ChipButton' },
        DateWeekPicker: { file: './modules/layout/components/DateWeekPicker' },
        History: { file: './modules/layout/components/History' },
        SimpleHour: { file: './modules/layout/components/SimpleHour' },
        LoaderBarTop: { file: './modules/layout/components/LoaderBarTop' },
        Button: { file: './modules/layout/components/Button' },
        Popover: { file: './modules/layout/components/Popover' },
        ConfirmationPopover: { file: './modules/layout/components/ConfirmationPopover' },
        TimeSlotsPopover: { file: './modules/layout/components/TimeSlotsPopover' },
        Table: { file: './modules/layout/components/Table' },
        NoData: { file: './modules/layout/components/NoData' },
        Description: { file: './modules/layout/components/Description' },
        FootbarWrapper: { file: './modules/footbar/index' },
        Footbar: { file: './modules/footbar/components/Footbar' },
        DeviceAndGroupSelector: { file: './modules/layout/components/DeviceAndGroupSelector' },
        DeviceAndGroupSelectorView: { file: './modules/layout/components/DeviceAndGroupSelectorView' },
        RoleSelector: { file: './modules/users/components/RoleSelector' },
        InlineDoubleForm: { file: './modules/layout/forms/InlineDoubleForm' },
        EditableField: { file: './modules/layout/components/EditableField' },
        ActionButton: { file: './modules/layout/components/ActionButton' },
        DateRangePicker: { file: './modules/layout/components/DateRangePicker' },
        Calendar: { file: './modules/layout/components/Calendar' },
        Stepper: { file: './modules/layout/components/Stepper' },
        AddButton: { file: './modules/layout/components/AddButton' },
        GroupedItems: { file: './modules/layout/components/GroupedItems' },
        DataTypesSelector: { file: './modules/layout/components/DataTypesSelector' },
        HomeIllustration: { file: './modules/layout/components/HomeIllustration' },
        PageHeader: { file: './modules/authentication/components/PageHeader' },
        LogInForm: { file: './modules/authentication/components/LogInForm' },
        CreatableSelectComponent: { file: './modules/layout/components/CreatableSelectComponent' },
        // NAVBAR
        NavigationBar: { file: './modules/navbar/index' },
        Filters: { file: './modules/navbar/components/Filters' },
        NavigationBadge: { file: './modules/navbar/components/NavigationBadge' },
        NavButton: { file: './modules/navbar/components/NavButton' },
        NavigationDropdown: { file: './modules/navbar/components/NavigationDropdown' },
        QueryResults: { file: './modules/navbar/components/QueryResults' },
        NotificationMenu: { file: './modules/navbar/components/NotificationMenu' },
        HelpMenu: { file: './modules/navbar/components/HelpMenu' },
        SidebarNav: { file: './modules/navbar/components/SidebarNav' },
        SearchInput: { file: './modules/navbar/components/SearchInput' },
        ProfilePicture: { file: './modules/navbar/components/ProfilePicture' },
        WorkspaceSettings: { file: './modules/navbar/components/WorkspaceSettings' },
        CustomQueryResults: { file: './modules/navbar/components/CustomQueryResults' },
        CustomSearchBar: { file: './modules/navbar/components/CustomSearchBar' },
        MerciYanisLogoNavbar: { file: './modules/navbar/components/MerciYanisLogoNavbar' },
        // WORKSPACE
        WorkspaceConfigurationMenu: { file: './modules/workspace/components/WorkspaceConfigurationMenu' },
        WorkspaceConfigurationHomePage: { file: './modules/workspace/components/WorkspaceConfigurationHomePage' },
        TicketsConfiguration: { file: './modules/workspace/components/TicketsConfiguration/TicketsConfiguration' },
        ArchivedTicketsConfiguration: { file: './modules/workspace/components/TicketsConfiguration/ArchivedTicketsConfiguration' },
        DevicesConfiguration: { file: './modules/workspace/components/DevicesConfiguration/DevicesConfiguration' },
        DevicesAlertsTimeSlot: { file: './modules/workspace/components/DevicesConfiguration/DevicesAlertsTimeSlot' },
        QRCodesConfiguration: { file: './modules/workspace/components/QRCodesConfiguration/QRCodesConfiguration' },
        QRCodesDocuments: { file: './modules/workspace/components/QRCodesConfiguration/QRCodesDocuments' },
        UsersConfiguration: { file: './modules/workspace/components/UsersConfiguration/UsersConfiguration' },
        UsersRights: { file: './modules/workspace/components/UsersConfiguration/UsersRights' },
        ContractorsRights: { file: './modules/workspace/components/UsersConfiguration/ContractorsRights' },
        RoundsConfiguration: { file: './modules/workspace/components/RoundsConfiguration/RoundsConfiguration' },
        RoundsTicketsConfiguration: { file: './modules/workspace/components/RoundsConfiguration/RoundsTicketsConfiguration' },
        // WORKSPACES
        MultiWorkspacesHomePage: { file: './modules/workspaces/components/MultiWorkspacesHomePage' },
        WorkspaceCreationPage: { file: './modules/workspaces/components/WorkspaceCreationPage' },
        // DEVICES
        DevicesIndex: { file: './modules/devices/components/DevicesIndex' },
        DevicesTable: { file: './modules/devices/components/DevicesTable' },
        DeviceSimpleString: { file: './modules/devices/components/DeviceSimpleString' },
        DeviceName: { file: './modules/devices/components/TableComponents/DeviceName' },
        DevicesSelector: { file: './modules/devices/components/DevicesSelector' },
        DeviceTableInformationField: { file: './modules/devices/components/TableComponents/DeviceInformation' },
        DeviceTableGroupsField: { file: './modules/devices/components/TableComponents/DeviceGroups' },
        DeviceTableBatteryField: { file: './modules/devices/components/TableComponents/DeviceBattery' },
        ObjectLocation: { file: './modules/devices/components/TableComponents/ObjectLocation' },
        DeviceData: { file: './modules/devices/components/TableComponents/DeviceData' },
        CreateDevicePopup: { file: './modules/devices/popups/CreateDevicePopup' },
        DeviceDetailsPopup: { file: './modules/devices/popups/DeviceDetailsPopup' },
        DeviceDetailsPopupLeft: { file: './modules/devices/components/PopupComponents/DeviceDetailsPopupLeft' },
        DeviceDetailsPopupRight: { file: './modules/devices/components/PopupComponents/DeviceDetailsPopupRight' },
        DeviceHardwareAssignmentEditableField: { file: './modules/devices/components/PopupComponents/DeviceHardwareAssignmentEditableField' },
        DeviceHardwareAssignment: { file: './modules/devices/components/DeviceHardwareAssignment' },
        DeviceGroups: { file: './modules/devices/components/TableComponents/DeviceGroups' },
        DeviceGroupsEditableField: { file: './modules/devices/components/PopupComponents/DeviceGroupsEditableField' },
        DeviceDetailsGraph: { file: './modules/devices/components/PopupComponents/DeviceDetailsGraph' },
        DeviceMeasureEditableField: { file: './modules/devices/components/PopupComponents/DeviceMeasureEditableField' },
        DeviceAddPopup: { file: './modules/devices/components/PopupComponents/DeviceAddPopup' },
        DeviceDateRangeFilter: { file: './modules/devices/components/PopupComponents/DeviceDateRangeFilter' },
        ResetDeviceButton: { file: './modules/devices/components/FootbarButtons/ResetDeviceButton' },
        AddGroupToDeviceButton: { file: './modules/devices/components/FootbarButtons/AddGroupToDeviceButton' },
        ExportDataButton: { file: './modules/devices/components/FootbarButtons/ExportDataButton' },
        ExportInfoButton: { file: './modules/devices/components/FootbarButtons/ExportInfoButton' },
        DeleteDeviceButton: { file: './modules/devices/components/FootbarButtons/DeleteDeviceButton' },
        DeviceGroupSimpleViewGroup: { file: './modules/devices/components/DeviceGroupSimpleViewGroup' },
        DeviceGroupHardwareIdsSimpleString: { file: './modules/devices/components/DeviceGroupHardwareIdsSimpleString' },
        DeviceAssociatedRoundsSimpleString: { file: './modules/devices/components/PopupComponents/DeviceAssociatedRoundsSimpleString' },
        DeviceMode: { file: './modules/devices/components/DeviceMode' },
        DeviceSimpleViewGroup: { file: './modules/devices/components/DeviceSimpleViewGroup' },
        DeviceJobConfigSelector: { file: './modules/devices/components/PopupComponents/DeviceJobConfigSelector' },
        DeviceMaxSelectedJobs: { file: './modules/devices/components/PopupComponents/DeviceMaxSelectedJobs' },
        // NFCS
        SelectNfcTag: { file: './modules/nfcs/components/SelectNfcTag' },
        // USERS
        UsersIndex: { file: './modules/users/components/UsersIndex' },
        UsersTable: { file: './modules/users/components/UsersTable' },
        UserSimpleString: { file: './modules/users/components/UserSimpleString' },
        UserTableNameField: { file: './modules/users/components/TableComponents/UserName' },
        UserExpiration: { file: './modules/users/components/TableComponents/UserExpiration' },
        UserTableGroupsField: { file: './modules/users/components/TableComponents/UserGroups' },
        UserNfcTag: { file: './modules/users/components/TableComponents/UserNfcTag' },
        UserInfo: { file: './modules/users/components/TableComponents/UserInfo' },
        UserContact: { file: './modules/users/components/TableComponents/UserContact' },
        UsersSelector: { file: './modules/users/components/UsersSelector' },
        UserAvatarsGrouped: { file: './modules/users/components/UserAvatarsGrouped' },
        UserAvatar: { file: './modules/users/components/UserAvatar' },
        AddGroupToUserForm: { file: './modules/users/popups/AddGroupToUserForm' },
        UserGroupsEditableField: { file: './modules/users/components/PopupComponents/UserGroupsEditableField' },
        UserAlerts: { file: './modules/users/components/PopupComponents/UserAlerts' },
        CSVAddUserWithoutMail: { file: './modules/users/components/PopupComponents/CSVAddUserWithoutMail' },
        CSVInvitePopup: { file: './modules/users/components/PopupComponents/CSVInvitePopup' },
        CSVAddNfcPopup: { file: './modules/users/components/PopupComponents/CSVAddNfcPopup' },
        UserWorkspaceRoleEditableField: { file: './modules/users/components/PopupComponents/UserWorkspaceRoleEditableField' },
        UserDetailsPopup: { file: './modules/users/popups/UserDetailsPopup' },
        UserDetailsPopupLeftPart: { file: './modules/users/popups/UserDetailsPopupLeftPart' },
        UserDetailsPopupRightPart: { file: './modules/users/popups/UserDetailsPopupRightPart' },
        EditUserProfilePopup: { file: './modules/users/popups/EditUserProfilePopup' },
        AddGroupToUserPopup: { file: './modules/users/popups/AddGroupToUserPopup' },
        InvitePopup: { file: './modules/users/popups/InvitePopup' },
        AddUserToGroupButton: { file: './modules/users/components/FootbarButtons/AddUserToGroupButton' },
        DeleteInviteButton: { file: './modules/users/components/FootbarButtons/DeleteInviteButton' },
        DeleteUserButton: { file: './modules/users/components/FootbarButtons/DeleteUserButton' },
        MakeAdminButton: { file: './modules/users/components/FootbarButtons/MakeAdminButton' },
        RemoveUserFromGroupButton: { file: './modules/users/components/FootbarButtons/RemoveUserFromGroupButton' },
        ResendEmailButton: { file: './modules/users/components/FootbarButtons/ResendEmailButton' },
        // DASHBOARD
        DashboardsIndex: { file: './modules/dashboards/components/DashboardsIndex' },
        DashboardsTable: { file: './modules/dashboards/components/DashboardsTable' },
        DashboardTableName: { file: './modules/dashboards/components/TableComponents/DashboardName' },
        DashboardTableGroups: { file: './modules/dashboards/components/TableComponents/DashboardGroups' },
        GaugeGraph: { file: './modules/dashboards/components/graphComponents/GaugeGraph' },
        SatisfactionGraph: { file: './modules/dashboards/components/graphComponents/SatisfactionGraph' },
        RatingGraph: { file: './modules/dashboards/components/graphComponents/RatingGraph' },
        PollGraph: { file: './modules/dashboards/components/graphComponents/PollGraph' },
        TopGraph: { file: './modules/dashboards/components/graphComponents/TopGraph' },
        LineGraph: { file: './modules/dashboards/components/graphComponents/CurveGraph' },
        GraphNoData: { file: './modules/dashboards/components/GraphNoData' },
        OptionGraph: { file: './modules/dashboards/components/OptionGraph' },
        // DOCUMENTS
        FileViewer: { file: './modules/documents/components/FileViewer' },
        // GROUPS
        GroupsIndex: { file: './modules/groups/components/GroupsIndex' },
        GroupsTable: { file: './modules/groups/components/GroupsTable' },
        AddGroupToDevicePopup: { file: './modules/devices/popups/AddGroupToDevicePopup' },
        CreateGroupPopup: { file: './modules/groups/popups/CreateGroupPopup' },
        AddUserToGroupPopup: { file: './modules/groups/popups/AddUserToGroupPopup' },
        GroupDetailsPopup: { file: './modules/groups/popups/GroupDetailsPopup' },
        GroupDetailsPopupLeftPart: { file: './modules/groups/popups/GroupDetailsPopupLeftPart' },
        GroupDetailsPopupRightPart: { file: './modules/groups/popups/GroupDetailsPopupRightPart' },
        GroupSelector: { file: './modules/groups/components/GroupSelector' },
        GroupButton: { file: './modules/groups/components/GroupButton' },
        GroupName: { file: './modules/groups/components/TableComponents/GroupName' },
        DeviceGroupName: { file: './modules/groups/components/TableComponents/DeviceGroupName' },
        GroupTableUsersField: { file: './modules/groups/components/TableComponents/GroupUsers' },
        GroupTableDevicesField: { file: './modules/groups/components/TableComponents/GroupAddDevice' },
        CSVAddBuildingsPopup: { file: './modules/groups/components/ConfigPopups/CSVAddBuildingsPopup' },
        CSVAddSkillsPopup: { file: './modules/groups/components/ConfigPopups/CSVAddSkillsPopup' },
        GroupSelect: { file: './modules/groups/components/GroupSelect' },
        GroupString: { file: './modules/groups/components/GroupString' },
        // MODAL
        DebugModal: { file: './modules/modal/Components/DebugModal' },
        ModalHeadSimpleString: { file: './modules/modal/Components/ModalHeadSimpleString' },
        // ACTION BUTTONS
        DashboardActionButton: { file: './modules/dashboards/components/ActionButton' },
        UserActionButton: { file: './modules/users/components/ActionButton' },
        SettingActionButton: { file: './modules/settings/components/ActionButton' },
        GroupActionButtonGroup: { file: './modules/groups/components/ActionButton' },
        DeviceActionButton: { file: './modules/devices/components/ActionButton' },
        // FOOTBAR
        UserActionBar: { file: './modules/users/components/UserActionBar' },
        GroupActionBar: { file: './modules/groups/components/GroupActionBar' },
        DeviceActionBar: { file: './modules/devices/components/DeviceActionBar' },
        FootbarButton: { file: './modules/footbar/components/FootbarButton' },
        ActionBarCheckbox: { file: './modules/footbar/components/ActionBarCheckbox' },
        SwitchModeButtons: { file: './modules/footbar/components/SwitchModeButtons' },
        SubBar: { file: './modules/layout/components/SubBar' },
        // SECTIONS
        TopSection: { file: './modules/layout/sections/TopSection' },
        MiddleSection: { file: './modules/layout/sections/MiddleSection' },
        BottomSection: { file: './modules/layout/sections/BottomSection' },
        LeftSection: { file: './modules/layout/sections/LeftSection' },
        CenterSection: { file: './modules/layout/sections/CenterSection' },
        RightSection: { file: './modules/layout/sections/RightSection' },
        OffgridSection: { file: './modules/layout/sections/OffgridSection' },
        // QRCODES
        QRCodeRouter: { file: './modules/qrcodes/index' },
        DocumentQRCodeForm: { file: './modules/qrcodes/components/DocumentQRCodeForm' },
        MobileDocumentQRCodeFileViewer: { file: './modules/qrcodes/components/MobileDocumentQRCodeFileViewer' },
        NewTicketForm: { file: './modules/qrcodes/components/IssueReport/NewTicketForm' },
        NewTicketFormValidationPage: { file: './modules/qrcodes/components/IssueReport/NewTicketFormValidation' },
        // PASSAGES
        ValidationPassageComment: {file: './modules/passages/MobileComponents/ValidationPassageComment'},
        ValidationPassage: { file: './modules/passages/MobileComponents/ValidationPassage' },
        VisualizationPassage: { file: './modules/passages/MobileComponents/VisualizationPassage' }, 
        // CHANGELOG
        Changelog: { file: './modules/changelogs/index.js' },
        ChangelogModal: { file: './modules/changelogs/components/ChangelogModal' },
        ChangelogModalFooter: { file: './modules/changelogs/components/ChangelogModalFooter' },

        // MOBILE
        MobileNavBar: { file: './modules/mobile/components/MobileNavBar' },
        MobileTopUserBanner: { file: './modules/mobile/components/MobileTopUserBanner' },
        MobileErrorPage: { file: './modules/mobile/components/MobileErrorPage' },
        // TEMPLATES
        TicketTemplateSelector: { file: './modules/templates/components/TicketTemplateSelector' },
        // ROUNDS
        RoundsTable: { file: './modules/rounds/components/RoundsTable' },
        ImportRoundsPopup: { file: './modules/rounds/components/ImportRoundsPopup' },
        RoundsCalendar: { file: './modules/rounds/components/RoundsCalendar' },
        MobileQRCodeScanner: { file: './modules/rounds/components/Mobile/MobileQRCodeScanner' },
        MobileRoundsView: { file: './modules/rounds/components/Mobile/MobileRoundsView' },
        MobileRoundsInstanceDetails: { file: './modules/rounds/components/Mobile/MobileRoundsInstanceDetails' },
        RoundName: { file: './modules/rounds/components/TableComponents/RoundName' },
        RoundBuildings: { file: './modules/rounds/components/TableComponents/RoundBuildings' },
        BuildingButton: { file: './modules/rounds/components/TableComponents/BuildingButton' },
        RoundRecurrenceSimpleString: { file: './modules/rounds/components/TableComponents/RoundRecurrenceSimpleString' },
        RoundFollowersAvatar: { file: './modules/rounds/components/TableComponents/RoundFollowersAvatar' },
        FollowersAvatarsGrouped: { file: './modules/rounds/components/TableComponents/FollowersAvatarsGrouped' },
        RoundArchivingDate: { file: './modules/rounds/components/TableComponents/RoundArchivingDate' },
        RoundModalFirstSection: { file: './modules/rounds/components/PopupComponents/RoundModalFirstSection' },
        RoundModalSecondSection: { file: './modules/rounds/components/PopupComponents/RoundModalSecondSection' },
        RoundModalThirdSection: { file: './modules/rounds/components/PopupComponents/RoundModalThirdSection' },
        RoundSteps: { file: './modules/rounds/components/PopupComponents/RoundSteps' },
        RoundPlanification: { file: './modules/rounds/components/PopupComponents/RoundPlanification' },
        RoundDetailsPopup: { file: './modules/rounds/components/PopupComponents/RoundDetailsPopup' },
        RoundDetailsPopupPartLeft: { file: './modules/rounds/components/PopupComponents/RoundDetailsPopupPartLeft' },
        RoundsCreateEditModal: { file: './modules/rounds/components/RoundsCreateEditModal' },
        RoundDetailsPopupPartRight: { file: './modules/rounds/components/PopupComponents/RoundDetailsPopupPartRight' },
        RoundDetailsDrawer: { file: './modules/rounds/components/PopupComponents/RoundDetailsDrawer' },
        RoundHistory: { file: './modules/rounds/components/PopupComponents/RoundHistory'},
        RoundInstanceEventsLog: { file: './modules/rounds/components/PopupComponents/RoundInstanceEventsLog' },
        SelectRoundUsers: { file: './modules/rounds/components/PopupComponents/SelectRoundUsers' },
        SelectRoundJobs: { file: './modules/rounds/components/PopupComponents/SelectRoundJobs' },
        RoundDeviceName: { file: './modules/rounds/components/StepComponents/RoundDeviceName' },
        RoundDeviceMaxValidationCount: { file: './modules/rounds/components/StepComponents/RoundDeviceMaxValidationCount' },
        StepLocation: { file: './modules/rounds/components/StepComponents/StepLocation' },
        RoundRecurrence: { file: './modules/rounds/components/StepComponents/RoundRecurrence' },
        RoundOneTimeRruleAndDateFields: { file: './modules/rounds/components/StepComponents/RoundOneTimeRruleAndDateFields' },
        RoundRecurrentRruleAndDateFields: { file: './modules/rounds/components/StepComponents/RoundRecurrentRruleAndDateFields' },
        RoundOneTime: { file: './modules/rounds/components/CalendarComponents/CalendarCreationPopup/RoundOneTime' },
        DayPicker: { file: './modules/rounds/components/CalendarComponents/CalendarCreationPopup/DayPicker' },
        DaysPicker: { file: './modules/rounds/components/CalendarComponents/CalendarCreationPopup/DaysPicker' },
        RoundStepsLists: { file: './modules/rounds/components/StepComponents/RoundStepsLists' },
        RoundActionBar: { file: './modules/rounds/components/footbar/RoundActionBar' },
        DeleteRoundFootbarButton: { file: './modules/rounds/components/footbar/DeleteRoundFootbarButton' },
        ArchiveRoundFootbarButton: { file: './modules/rounds/components/footbar/ArchiveRoundFootbarButton' },
        RoundActionButton: { file: './modules/rounds/components/RoundActionButton' },
        RoundUsersText: { file: './modules/rounds/components/PopupComponents/RoundUsersText' },
        RoundJobsText: { file: './modules/rounds/components/PopupComponents/RoundJobsText' },
        // SETTINGS
        BuildingsTable: { file: './modules/settings/components/BuildingsTable' },
        SkillsTable: { file: './modules/settings/components/SkillsTable' },
        JobsTable: { file: './modules/settings/components/JobsTable' },
        SettingViewSwitcher: { file: './modules/settings/components/SettingViewSwitcher' },
        SettingsTableSkillsIcons: { file: './modules/settings/components/TableComponents/SettingsTableSkillsIcons' },
        SettingsTable: { file: './modules/settings/components/TableComponents/SettingsTable' },
        SettingsTableHead: { file: './modules/settings/components/TableComponents/SettingsTableHead' },
        SettingsTableToolbar: { file: './modules/settings/components/TableComponents/SettingsTableToolbar' },
        SettingsDetailsPopup: { file: './modules/settings/components/popup/SettingsDetailsPopup' },
        SettingsDetailsPopupLeft: { file: './modules/settings/components/popup/SettingsDetailsPopupLeft' },
        SettingsActionBar: { file: './modules/settings/components/SettingsActionBar' },
        // TICKETS
        Tickets: { file: './modules/tickets/components/Tickets' },
        TicketActionButton: { file: './modules/tickets/components/TicketActionButton' },
        TicketViewLowerPart: { file: './modules/tickets/components/TicketViewParts/TicketViewLowerPart' },
        TicketViewMiddlePart: { file: './modules/tickets/components/TicketViewParts/TicketViewMiddlePart' },
        TicketViewUpperPart: { file: './modules/tickets/components/TicketViewParts/TicketViewUpperPart' },
        TicketFootbar: { file: './modules/tickets/components/footbar/TicketFootbar' },
        DeleteTicketFootbarButton: { file: './modules/tickets/components/footbar/DeleteTicketFootbarButton' },
        TicketDetailsPopup: { file: './modules/tickets/components/popups/TicketDetailsPopup' },
        TicketDetailsPopupPartLeft: { file: './modules/tickets/components/popups/TicketDetailsPopupPartLeft' },
        TicketDetailsPopupPartRight: { file: './modules/tickets/components/popups/TicketDetailsPopupPartRight' },
        TicketSimpleString: { file: './modules/tickets/components/TicketSimpleString' },
        TicketSimpleViewGroup: { file: './modules/tickets/components/TicketSimpleViewGroup' },
        TicketAssignee: { file: './modules/tickets/components/TicketAssignee' },
        TicketFollowers: { file: './modules/tickets/components/TicketFollowers' },
        TicketPriority: { file: './modules/tickets/components/TicketPriority' },
        TicketPrioritySelector: { file: './modules/tickets/components/TicketPrioritySelector' },
        TicketStatusSelector: { file: './modules/tickets/components/TicketStatusSelector' },
        TicketLogs: { file: './modules/tickets/components/TicketLogs' },
        TicketCreatePopup: { file: './modules/tickets/components/popups/TicketCreatePopup' },
        TicketInputShowGroupName: { file: './modules/tickets/components/TicketInputShowGroupName' },
        TicketGroups: { file: './modules/tickets/components/PopupComponents/TicketGroups' },
        TogglePrioritiesButton: { file: './modules/tickets/components/PopupComponents/TogglePrioritiesButton' },
        TicketToggleGroups: { file: './modules/tickets/components/TicketToggleGroups' },
        ArchivedTicketsTable: { file: './modules/tickets/components/ArchivedTicket/ArchivedTicketsTable' },
        ArchivedTicketNameAndCategory: { file: './modules/tickets/components/ArchivedTicket/ArchivedTicketNameAndCategory' },
        ArchivedTicketAssignees: { file: './modules/tickets/components/ArchivedTicket/ArchivedTicketAssignees' },
        ArchivedTicketDates: { file: './modules/tickets/components/ArchivedTicket/ArchivedTicketDates' },
        ArchivedTicketCounterAndPriority: { file: './modules/tickets/components/ArchivedTicket/ArchivedTicketCounterAndPriority' },
        SelectAssigners: { file: './modules/tickets/components/PopupComponents/SelectAssigners' },
        TicketAssigners: { file: './modules/tickets/components/PopupComponents/TicketAssigners' },
        TeamsCommentForm: { file: './modules/tickets/components/TeamsCommentPage/TeamsCommentForm' },
        TagsCreatableSelect: { file: './modules/tickets/components/PopupComponents/TagsCreatableSelect' }
    }
};

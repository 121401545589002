import React, {
    useCallback, useEffect, useMemo, useState
} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// Components
import Avatar from 'react-avatar';
import {
    cloneDeep, isEmpty, find, takeWhile, findIndex, set
} from 'lodash';
// Context
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import { uuid } from 'uuidv4';
import { useComponentsPool } from '../../../../ComponentsPool';

// Helper
import { getUserFromId } from '../../../users/helper';
// Actions
import { deleteInstanceComments } from '../../actions';
import { deleteDeviceComments } from '../../../devices/actions';
import { isDateTimeOutOfRange } from '../../../core/helper';
import { canDisplayDateTimeInputOfCurrentRoundEvent, getStatusFromInstanceAndEventStatus, isInAreaPassage } from '../../helper';
import { getDeviceFromId } from '../../../devices/helper';
import { useIsFirstRender } from "../../../../utils/hook/useIsFirstRender";


const RoundInstanceEventsLog = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.users.currentUser);
    const eventsList = props.eventsList;
    const index = props.eventIndex;
    const event = props.event;
    const currentInstance = props.currentInstance;
    const users = useSelector(state => state.users.list);
    const [hasDateError, setHasDateError] = useState(false);
    const [hasTimeError, setHasTimeError] = useState(false);
    const [updatedDateTime, setUpdatedDateTime] = useState(null);
    const isFirstRender = useIsFirstRender()

    const devices = useSelector(state => state.devices.list);
    const [isHoveredCommentIdx, setIsHoveredCommentIdx] = useState(null);
    const [generatedEventId] = useState(uuid());
    // User cannot put passages out of the round slot and cannot create passages in the future
    const limitDate = dayjs(props.currentInstance.ended_at) < dayjs() ? dayjs(props.currentInstance.ended_at) : dayjs();

    const defaultDateTimeForInvalidEvent = dayjs(props.currentInstance.ended_at) < dayjs()
        ? dayjs(props.currentInstance.started_at) // start of the round instance
        : dayjs() // now

    const [hasOutOfRangeError, setHasOutOfRangeError] = useState(false);

    const dateOrTimeHasError = useMemo(() => hasDateError || hasTimeError, [hasDateError, hasTimeError]);

    useEffect(() => {
        // re-initiate the states onClose/onOpen
        setHasDateError(false);
        setHasTimeError(false);
        setHasOutOfRangeError(false);
    }, [props.isInEditionMode]);

    const useStyles = makeStyles(() => ({
        tooltip: {
            '&::before': {
                width: '500px'
            }
        }
    }));
    const classes = useStyles();

    const deleteComment = (instanceCommentId, deviceCommentId, authorId) => {
        if (currentUser.id === authorId) {
            if (instanceCommentId) {
                dispatch(deleteInstanceComments(instanceCommentId));
            } else if (deviceCommentId) {
                dispatch(deleteDeviceComments(event.event_detail.device_id, deviceCommentId));
            }
        }
    };

    const isValidEventDateTime = dateTime => {
        // check if the dateTime is valid
        if (!dateTime) return false;
        return dayjs(dateTime).isValid();
    };

    const getEventPrePayload = eventTimestamp => {
        if (event.event_detail.id) {
            return {
                eventId: event.event_detail.id,
                initialTimestamp: eventTimestamp,
                modificationType: 'update'
            };
        }
        return {
            eventId: generatedEventId,
            initialTimestamp: eventTimestamp,
            modificationType: 'create'
        };
    };

    const refreshOutOfSlot = useCallback(newTimestamp => {
        const isOutOfRange = isDateTimeOutOfRange(
            newTimestamp,
            props.currentInstance.started_at,
            limitDate?.toISOString() // toISOString needed to convert dayjs() to moment()
        );
        setHasOutOfRangeError(isOutOfRange);
        return isOutOfRange || dateOrTimeHasError;
    }, [dateOrTimeHasError]);

    const hasDatetimeError = useMemo(() => hasOutOfRangeError || dateOrTimeHasError, [hasOutOfRangeError, dateOrTimeHasError]);

    const updateEditedPassages = (initTimestamp, newTimestamp, validationConditionID, eventId, modificationType) => {
        const editedPassagesCopy = cloneDeep(props.editedPassages);
        const newPassage = {
            modificationType,
            event_id: eventId,
            event_timestamp: newTimestamp?.format('YYYY-MM-DDTHH:mm:ssZ'),
            event_validation_condition_id: validationConditionID,
            hasError: refreshOutOfSlot(newTimestamp)
        };

        const existingPassageIndex = findIndex(editedPassagesCopy, { event_id: eventId });
        // initTimestamp is a string for update case / a dayjs object for create case => optional chaining in case of new timestamp is null ( remove the input values )
        const hasSameValueAsInitial = dayjs(newTimestamp?.toISOString()).isSame(dayjs(initTimestamp), 'minute');
        if (existingPassageIndex !== -1) {
            if (modificationType === 'update' && hasSameValueAsInitial && !refreshOutOfSlot(newTimestamp)) {
                // remove the passage from edited passages if the new value is the same as the initial value
                editedPassagesCopy.splice(existingPassageIndex, 1);
            } else {
                // update existing passage modification
                editedPassagesCopy[existingPassageIndex] = newPassage;
            }
        } else {
            // create a new passage modification
            editedPassagesCopy.push(newPassage);
        }
        props.setEditedPassages(editedPassagesCopy);
    };

    // Following function updates only the status of a passage / or create new one with an ID and hasError field for tracking fields with error in updatedPassageList
    const updateEditedPassageErrorStatus = (eventID, hasError) => {
        const editedPassagesCopy = cloneDeep(props.editedPassages);
        const existingPassageIndex = findIndex(editedPassagesCopy, { event_id: eventID });
        if (existingPassageIndex !== -1) {
            set(editedPassagesCopy[existingPassageIndex], 'hasError', hasError);
        } else {
            editedPassagesCopy.push({
                event_id: eventID,
                hasError
            });
        }
        props.setEditedPassages(editedPassagesCopy);
    };

    useEffect(() => {
        if (!isFirstRender) {
            const initialEventTimestamp = event.event_timestamp || defaultDateTimeForInvalidEvent; // or any other initial timestamp
            const prePayload = getEventPrePayload(initialEventTimestamp);
            const validationConditionID = event.event_detail.validation_condition_id;

            updateEditedPassages(
                initialEventTimestamp,
                updatedDateTime,
                validationConditionID,
                prePayload.eventId,
                prePayload.modificationType
            );
        }
    }, [updatedDateTime]);

    const handleTimeChange = (time, initialEventTimestamp) => {
        const prePayload = getEventPrePayload(initialEventTimestamp);
        const currentPassage = find(props.editedPassages, { event_id: prePayload.eventId });
        const baseTimestamp = currentPassage?.event_timestamp ?? prePayload.initialTimestamp;

        let newTimestamp;

        if (!isValidEventDateTime(time)) {
            setHasTimeError(true);
        } else {
            setHasTimeError(false);
            newTimestamp = moment(baseTimestamp.toString()).set({ h: time.$H, m: time.$m, s: 0 });
        }
        if (!newTimestamp && !currentPassage?.event_timestamp) {
            updateEditedPassageErrorStatus(prePayload.eventId, true);
            return;
        }
        setUpdatedDateTime(newTimestamp || moment(currentPassage?.event_timestamp));
    };

    const handleDateChange = (date, initialEventTimestamp) => {
        const prePayload = getEventPrePayload(initialEventTimestamp);
        const currentPassage = find(props.editedPassages, { event_id: prePayload.eventId });
        const baseTimestamp = currentPassage?.event_timestamp ?? prePayload.initialTimestamp;

        let newTimestamp;

        if (!isValidEventDateTime(date)) {
            setHasDateError(true);
        } else {
            setHasDateError(false);
            newTimestamp = moment(baseTimestamp.toString()).set({ D: date.$D, M: date.$M, y: date.$y });
        }
        if (!newTimestamp && !currentPassage?.event_timestamp) {
            updateEditedPassageErrorStatus(prePayload.eventId, true);
            return;
        }
        setUpdatedDateTime(newTimestamp || moment(currentPassage?.event_timestamp));
    };


    const getDateTimeFieldProps = (event_timestamp, event_type, status, onChange) => {
        if (status === 'OK') {
            return {
                defaultValue: dayjs(event_timestamp),
                onChange: current => onChange(current, event_timestamp)
            };
        }
        return {
            defaultValue: defaultDateTimeForInvalidEvent,
            onChange: current => onChange(current, defaultDateTimeForInvalidEvent)
        };
    };

    const getStatusText = (eventStatus, event_timestamp, event_type, isPassageInEditionMode, instanceStatus) => {
        if (isPassageInEditionMode && canDisplayDateTimeInputOfCurrentRoundEvent(instanceStatus, eventStatus)) {
            return (
                <>
                    <DateField
                        className={classnames('datefield-picker', { 'datefield-picker-in-error': hasDateError || hasOutOfRangeError })}
                        format="DD/MM/YYYY"
                        slotProps={{ textField: { placeholder: '___/___/___' } }}
                        {...getDateTimeFieldProps(event_timestamp, event_type, eventStatus, handleDateChange)}
                    />
                    <TimePicker
                        className={classnames('hours-time-picker', { 'hours-time-picker-in-error': hasDatetimeError })}
                        timeSteps={{ hours: 1, minutes: 5 }}
                        ampm={false} // 24 hours clock
                        slotProps={{ textField: { placeholder: '--:--' }, actionBar: { sx: { display: 'none' } } }}
                        {...getDateTimeFieldProps(event_timestamp, event_type, eventStatus, handleTimeChange)}
                    />
                </>
            );
        }
        if (eventStatus === 'OK' || event_type === 'COMMENT') {
            const eventDate = moment(event_timestamp).format('DD/MM/YYYY');
            const eventTime = moment(event_timestamp).format('HH[h]mm');
            return (
                <>
                    <span>{eventDate}</span>
                    <span>{eventTime}</span>
                </>
            );
        }
        return <span>{t('rounds:step_not_validated')}</span>;
    };

    const getAvatar = (event_type, authorId) => {
        if (event_type === 'COMMENT') {
            if (authorId) {
                const author = getUserFromId(users, authorId);
                return (
                    <div className={'row-avatar'}>
                        <Component
                            componentName={'UserAvatar'}
                            picture={author?.profile_picture_url}
                            user={author}
                            size="25"
                        />
                    </div>
                );
            }
            return (
                <div className={'comment-bubble'}>
                    <Avatar
                        src={'https://res.cloudinary.com/iotv-keepcontact/image/upload/v1528904820/iot-rocket/user.png'}
                        size="25"
                        round
                    />
                </div>
            );
        }
        return <></>;
    };

    const getPassageComponent = eventDetail => {
        if (eventDetail) {
            let passageDetails;

            const status = getStatusFromInstanceAndEventStatus(currentInstance.status, eventDetail.status);

            if (eventDetail.agent_name) {
                if (status === 'NOT_STARTED' || status === 'IN_PROGRESS') {
                    passageDetails = `${t('rounds:to_be_validated_by')} ${t(`users:${eventDetail.agent_name}`)}`;
                } else if (status === 'COMPLETE') {
                    passageDetails = `${t('rounds:validated_by')} ${t(`users:${eventDetail.agent_name}`)}`;
                } else {
                    passageDetails = `${t('rounds:not_validated_by')} ${t(`users:${eventDetail.agent_name}`)}`;
                }
            } else if (status === 'NOT_STARTED' || status === 'IN_PROGRESS') {
                passageDetails = t('rounds:to_be_validated_with');
            } else if (status === 'COMPLETE') {
                const device = getDeviceFromId(devices, eventDetail.device_id);
                passageDetails = isInAreaPassage(device) ? t('passages:passage_status_OK_IN_AREA') : t('passages:passage_status_OK');
            } else {
                passageDetails = t('passages:passage_status_ANOMALY');
            }
            if (!isEmpty(eventDetail.job_names)) {
                passageDetails = `${passageDetails} : ${eventDetail.job_names.join(', ')}`;
            }
            return (
                <div className={'passage-content'}>
                    <span className={'passage-location'}>{`${eventDetail.building_name || ''}, ${eventDetail.floor_name || ''}, ${eventDetail.position_name || ''} - ${eventDetail?.device_name}`}</span>
                    <span className={'passage-device-name'}>{passageDetails}</span>
                </div>
            );
        }
        return <></>;
    };

    const isImage = file => {
        let suffix = file.split('.');
        if (suffix.length > 0) {
            suffix = suffix[suffix.length - 1];
        } else {
            return false;
        }
        return suffix === 'jpg' || suffix === 'jpeg' || suffix === 'bmp' || suffix === 'png' || suffix === 'svg';
    };

    const getCommentComponent = (idx, eventDetail) => {
        const location = eventDetail.device_name && eventDetail.building_name
            ? `${eventDetail.device_name} - ${eventDetail.building_name}, ${eventDetail.floor_name}, ${eventDetail.position_name}`
            : '';
        const attachedDocumentUrl = eventDetail.uploaded_content_url;
        return (
            <div
                className={'comment-content'}
                onMouseEnter={() => setIsHoveredCommentIdx(idx)}
                onMouseLeave={() => setIsHoveredCommentIdx(null)}
            >
                <span className={'comment-header'}>
                    <div className={'comment-info'}>
                        <p>{`${t(`users:${eventDetail.author_name}`)} ${t('rounds:user_add_comment')}`}</p>
                        { isHoveredCommentIdx === index
                            && currentUser.id === eventDetail.author_id
                            && (
                                <Tooltip
                                    arrow={false}
                                    classes={{ tooltip: classes.tooltip }}
                                    title={t('tickets:comment_remove')}
                                    placement={'top'}
                                >
                                    <div
                                        tabIndex={0}
                                        role={'button'}
                                        className={'delete-comment-button'}
                                    >
                                        <Button
                                            onClick={() => deleteComment(eventDetail.round_instance_comment_id,
                                                eventDetail.device_comment_id, eventDetail.author_id)}
                                            color="primary"
                                        >
                                            <Component componentName={'Icon'} type={'trash'} />
                                        </Button>
                                    </div>
                                </Tooltip>
                            )
                        }
                    </div>
                    <p className={'comment-device-name'}>{location}</p>
                </span>
                { !isEmpty(eventDetail.comment)
                    && (
                        <div className={'comment-text'}>
                            <span>{eventDetail.comment}</span>
                        </div>
                    )
                }
                {
                    !isEmpty(attachedDocumentUrl)
                    && (
                        <div className={'comment-text'}>
                            {isImage(attachedDocumentUrl) ? <img src={attachedDocumentUrl} alt={''} /> : <a href={attachedDocumentUrl}>{t('rounds:document_link')}</a>}
                        </div>
                    )
                }
            </div>
        );
    };

    const getStatusIcon = (passageStatus, instanceStatus) => {
        let passageStatusAnomaly;

        if (instanceStatus === 'IN_PROGRESS') {
            passageStatusAnomaly = 'StopWatchIcon';
        } else if (instanceStatus === 'NOT_STARTED') {
            passageStatusAnomaly = 'TimerOff';
        } else {
            passageStatusAnomaly = 'ErrorRounded';
        }

        return (
            <div className={passageStatusAnomaly === 'TimerOff' ? 'row-status-not-started-icon' : 'row-status-icon'}>
                <Component
                    componentName={'Icon'}
                    type={passageStatus === 'OK' ? 'CheckCircleRounded' : passageStatusAnomaly}
                />
            </div>
        );
    };

    return (
        <div className={'row-item'} key={`row-${index}`}>
            <div className={'row-icon'}>
                <span className={'row-details'}>
                    {getStatusText(event.event_detail?.status, event.event_timestamp, event.event_type, event.event_type === 'PASSAGE' && props.isInEditionMode, currentInstance.status)}
                </span>
                <div className={'row-timeline'}>
                    {event.event_type === 'PASSAGE' && getStatusIcon(event.event_detail?.status, currentInstance.status)}
                    {event.event_type === 'COMMENT' && getAvatar(event.event_type, event.event_detail?.author_id)}
                    {eventsList.length !== index + 1 && <div className={'line'} />}
                </div>
            </div>
            <div className={'row-content'}>
                {event.event_type === 'PASSAGE' && getPassageComponent(event.event_detail)}
                {event.event_type === 'COMMENT' && getCommentComponent(index, event.event_detail)}
            </div>
        </div>
    );
};

export default RoundInstanceEventsLog;

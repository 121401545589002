// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
// Context
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from 'react-redux';
import { useComponentsPool } from '../../../../ComponentsPool';

const SettingsTableSkillsIcons = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const user_skills_hidden = useSelector(state => state.workspace.settings.user_skills_hidden);
    const userSkillsHiddenArray = user_skills_hidden ? user_skills_hidden.split(',').map(hiddenSkill => hiddenSkill.trim()) : [];
    const SmallAvatar = styled(Avatar)(() => ({
        width: 18,
        height: 18,
        border: '2px solid white'
    }));

    return (
        <div className="stacked-table-icons">
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={userSkillsHiddenArray.includes(props.skillName) && (
                    <Tooltip
                        placement="top"
                        title={t('settings:hidden_for_users')}
                    >
                        <SmallAvatar className={'small-stacked-avatar'}>
                            <Component componentName={'Icon'} type={'eyeSlashed'} />
                        </SmallAvatar>
                    </Tooltip>
                )}
            >
                <Tooltip
                    placement="top"
                    title={props.skillName ? t(`groups:${props.skillName}`) : ''}
                >
                    <Avatar>
                        <Component componentName={'Icon'} type={props.skillName ? props.skillName : 'tickets'} />
                    </Avatar>
                </Tooltip>
            </Badge>
        </div>
    );
};

export default SettingsTableSkillsIcons;

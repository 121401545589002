// Libs
import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const GroupSelect = props => (
    <Select
        value={props.value}
        label={props.title}
        onChange={props.onChange}
    >
        { props.options?.map(option => <MenuItem defaultValue="" key={option.id} value={option.id}>{option.value}</MenuItem>) }
    </Select>
);

export default GroupSelect;

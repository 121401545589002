// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
// Components
import Popover from '@material-ui/core/Popover';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Scss
import '../styles/_popover.scss';
import { setExportTimeSlots } from '../../core/actions';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';


const TimeSlotsPopover = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selected, setSelected] = useState(false);
    const maxMonthSlot = 1;
    const defaultWeekSlot = 1;
    const defaultTimeSlots = {
        startDate: moment().subtract(defaultWeekSlot, 'weeks').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        type: 'csv'
    };
    const [disableValidateButton, setDisableValidateButton] = useState(false);
    const [radioButtonValue, setRadioButtonValue] = useState(defaultTimeSlots.type);
    const [currentTimeSlots, setCurrentTimeSlots] = useState(defaultTimeSlots);

    const default_payload = {
        from_timestamp: defaultTimeSlots.startDate,
        to_timestamp: defaultTimeSlots.endDate,
        type: defaultTimeSlots.type
    };

    // Initialize export time slots with default values
    useEffect(() => {
        setCurrentTimeSlots(default_payload);
        dispatch(setExportTimeSlots(default_payload));
    }, []);

    const handleClick = event => {
        event.stopPropagation();
        setSelected(!selected);
        setAnchorEl(event.currentTarget);
        setCurrentTimeSlots(default_payload);
        setRadioButtonValue(defaultTimeSlots.type);
        dispatch(setExportTimeSlots(default_payload));
    };

    const handleConfirmation = () => {
        props.confirmationButtonAction();
        setAnchorEl(null);
    };

    const handleExportTimeSlotsChange = changedDates => {
        if (changedDates.startDate && changedDates.endDate) {
            const payload = {
                from_timestamp: changedDates.startDate,
                to_timestamp: changedDates.endDate,
                type: currentTimeSlots.type
            };
            setCurrentTimeSlots(payload);
            dispatch(setExportTimeSlots(payload));
        }
    };

    const handleExportTypeChange = changedType => {
        setRadioButtonValue(changedType);
        if (changedType) {
            const payload = {
                from_timestamp: currentTimeSlots.from_timestamp,
                to_timestamp: currentTimeSlots.to_timestamp,
                type: changedType
            };
            setCurrentTimeSlots(payload);
            dispatch(setExportTimeSlots(payload));
        }
    };

    const handleErrorChange = isError => {
        setDisableValidateButton(isError);
    };

    const getExportTypeComponent = () => (
        <div className={'popover-export-type'}>
            <div>
                <p>{t('export:export_to_format')}</p>
            </div>
            <RadioGroup row value={radioButtonValue} onChange={(e) => handleExportTypeChange(e.target.value)}>
                <FormControlLabel value="csv" control={<Radio />} label={'.csv'} />
                <FormControlLabel value="pdf" control={<Radio />} label={'.pdf'} />
            </RadioGroup>
        </div>
    );

    return (
        <>
            <div role={'button'} tabIndex={0} onClick={handleClick}>
                {props.actionButton}
            </div>
            <Popover
                classes={{ paper: 'MuiPopover-paper no-overflow-paper bottom-arrow delete-popover' }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <div className={'popover-content timeslots-popover-content'}>
                    <p>{props.popoverText}</p>
                    <div className="device-date-range-filter-component">
                        <Component
                            componentName={'DeviceDateRangeFilter'}
                            onDateChange={d => handleExportTimeSlotsChange(d)}
                            onErrorChange={isError => handleErrorChange(isError)}
                            startDate={defaultTimeSlots.startDate}
                            endDate={defaultTimeSlots.endDate}
                            maxMonthSlot={maxMonthSlot}
                        />
                    </div>
                    {props.withExportType && getExportTypeComponent()}
                    <div className={'popover-actions'}>
                        {props.cancelButtonLabel && (
                            <Component
                                componentName={'Button'}
                                onClick={() => setAnchorEl(null)}
                                text={props.cancelButtonLabel}
                                variant={'outlined'}
                                className={'cancel-button'}
                            />
                        )}
                        <Component
                            componentName={'Button'}
                            onClick={() => handleConfirmation()}
                            text={props.confirmationButtonLabel}
                            variant={'outlined'}
                            className={'confirmation-timeslots-button'}
                            disabled={disableValidateButton}
                        />
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default TimeSlotsPopover;

// Libs
import React, { useEffect, useState } from 'react';
// Components
import { useSelector } from 'react-redux';
import { useIsOnline } from 'react-use-is-online';
import { useHistory } from 'react-router';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import WaveIcon from './assets/wave.svg';
import { useComponentsPool } from '../../ComponentsPool';
import AuthenticationBackground from '../layout/components/AuthenticationBackground';
import createLoadingSelector from '../layout/actions';
import {
    EVENT_SCAN_QRCODE,
    EVENT_CLICK_ON_EMERGENCY_CALL_BUTTON,
    segmentTrack,
    segmentTrackLink
} from '../layout/helper';
import Icon from '../layout/components/Icon';
import { allQRCodeModes, isLargerModeIconStyle } from '../devices/helper';
import { shouldDisableQRCodeMode } from './helper';
import { loadIntercomScript } from '../core/helper';

// Wait the getCurrentUser request answer to send userId in segment tracks
const loadingSelector = createLoadingSelector(['GET_DEVICE', 'GET_CURRENT_USER', 'GET_GROUP_LIST']);

const QRCodeRouter = () => {
    const { Component } = useComponentsPool();
    const { isOnline } = useIsOnline();
    const [t] = useTranslation();
    const isLoggable = useSelector(state => state.users.is_loggable);
    const currentUser = useSelector(state => state.users.currentUser);
    const devices = useSelector(state => state.devices.list);
    const [device, setDevice] = useState(null);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const isLogged = useSelector(state => state.authentication.isLogged);
    const history = useHistory();
    const [qrcodeModes, setQRCodeModes] = useState([]);
    const transitionBetweenPageTime = 400;
    const modesOrderForAdminAndContractor = ['PASSAGE_VALIDATION', 'PASSAGE_VISUALIZATION', 'DOCUMENT', 'ISSUE_REPORT'];
    const modesOrderForUserAndAnonymous = ['ISSUE_REPORT', 'DOCUMENT', 'PASSAGE_VISUALIZATION']; // PASSAGE_VALIDATION isn't available for them
    const currentQRCodeForm = allQRCodeModes.find(mode => history.location.pathname.includes(`${mode}`));
    const [emergencyConfig, setEmergencyConfig] = useState({});

    // TODO TEMPORAIRE: pour une demo sur https://app.merciyanis.com/qrcodes/demo-chatbot
    const [isChatbotDemoQRCode, setIsChatbotDemoQRCode] = useState(false);
    const currentUserHMAC = useSelector(state => state.users.currentUserHMAC);
    const workspaceSettings = useSelector(state => state.workspace.settings);

    useEffect(() => {
        // Initialisation d'Intercom ou update de la config
        loadIntercomScript(INTERCOM_APP_ID)
            .then(() => {
                if (currentUser?.id && currentUserHMAC) {
                    // L'utilisateur est connecté
                    window.Intercom('shutdown');
                    window.Intercom('boot', {
                        api_base: 'https://api-iam.eu.intercom.io',
                        app_id: INTERCOM_APP_ID,
                        user_id: currentUser?.id,
                        email: currentUser?.email,
                        name : `${currentUser?.first_name} ${currentUser?.last_name}`,
                        avatar: {
                            'type': 'avatar',
                            'image_url' : currentUser?.profile_picture_url
                        },
                        user_hash: currentUserHMAC,
                        hide_default_launcher: true,
                        workspace: workspaceSettings.name,
                        role: currentUser?.workspace_role?.workspace_role_name
                    });
                } else {
                    // L'utilisateur n'est pas connecté
                    window.Intercom('shutdown');
                    window.Intercom('boot', {
                        api_base: 'https://api-iam.eu.intercom.io',
                        app_id: INTERCOM_APP_ID,
                        avatar: {
                            'type': 'avatar',
                            'image_url' : ''
                        },
                        hide_default_launcher: true,
                        workspace: workspaceSettings.name
                    });
                }
                if (
                    history.location.pathname.includes('/qrcodes/QR_DEM_0406')
                    || workspaceSettings.name === 'danone'
                ) {
                    setIsChatbotDemoQRCode(true);
                }
            });


        // Nettoyage : arrêt de Intercom et suppression des callbacks lorsque le composant est démonté
        return () => {
            if (typeof window.Intercom === 'function') {
                window.Intercom('shutdown');
            }
        };

    }, [currentUserHMAC, currentUser]);

    const useStyles = makeStyles(() => ({
        paper: {
            position: 'relative',
            width: '100%',
            height: '100%',
            boxShadow: 'none'
        }
    }));
    const classes = useStyles();

    const sortQrcodeModes = modesOnQRCode => {
        const sortedModes = []; // Modes are sorted based on the user role

        if (currentUser?.workspace_role?.workspace_role_name === 'Owner' || currentUser?.workspace_role?.workspace_role_name === 'Admin'
            || currentUser?.workspace_role?.workspace_role_name === 'Contractor'
        ) {
            modesOrderForAdminAndContractor.forEach(mode => {
                const modeToOrder = modesOnQRCode.find(qrCodeMode => qrCodeMode === mode);
                if (modeToOrder) { sortedModes.push(modeToOrder); }
            });
        } else {
            modesOrderForUserAndAnonymous.forEach(mode => {
                const modeToOrder = modesOnQRCode.find(qrCodeMode => qrCodeMode === mode);
                if (modeToOrder) { sortedModes.push(modeToOrder); }
            });
        }
        return sortedModes;
    };

    useEffect(() => {
        if (devices?.length > 0) {
            const deviceConfig = devices[0].custom_field?.config;
            setDevice(devices[0]);
            setQRCodeModes(deviceConfig?.modes);
            if (devices[0]?.hardware_ids?.length > 0 && devices[0]?.hardware_ids[0] === 'a355fd71') {
                window.location.href = 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1666789766/MO_Entretien_Quotidien_Bureau.pdf';
            }
            if (deviceConfig?.modes.includes('EMERGENCY_CALL') && deviceConfig?.emergency_phone && deviceConfig?.emergency_label_fr && deviceConfig?.emergency_label_en) {
                const lang = localStorage.getItem('i18nextLng');
                // Default language is english
                setEmergencyConfig({
                    'phone': deviceConfig.emergency_phone,
                    'label': lang?.includes('fr') ? deviceConfig.emergency_label_fr : deviceConfig.emergency_label_en
                });
            } else {
                setEmergencyConfig({});
            }
        } else {
            setDevice([]);
            setEmergencyConfig({});
        }
    }, [devices, currentUser]);

    useEffect(() => {
        if (qrcodeModes?.length > 0) {
            segmentTrack(EVENT_SCAN_QRCODE, { qrCodeId: device?.device_id, qrCodeMode: qrcodeModes });
            if (qrcodeModes.length === 1) {
                history.push({
                    pathname: `${history.location.pathname.split(`/${currentQRCodeForm}`)[0]}/${qrcodeModes[0]}`
                });
            }
        }
    }, [qrcodeModes]);

    useEffect(() => {
        // Track click on emergency call button
        const linkElement = document.getElementById('emergency-call-link');

        if (linkElement) {
            segmentTrackLink(linkElement, EVENT_CLICK_ON_EMERGENCY_CALL_BUTTON,
                { qrCodeId: device?.device_id, qrCodeMode: qrcodeModes });
        }

        return () => {};
    }, []);

    // return the right form (passage validation, issue report) or an error page
    const getRightQRCodeForm = () => {
        switch (currentQRCodeForm) {
            case 'PASSAGE_VALIDATION':
                return (
                    <Slide
                        direction="left"
                        in
                        appear={qrcodeModes?.length > 1} // we hide the animation if this is not a qrcode with multiple modes
                        timeout={{ enter: transitionBetweenPageTime }}
                    >
                        <Paper className={classes.paper}>
                            <Component
                                componentName={'ValidationPassage'}
                                qrcodesHasManyMode={qrcodeModes?.length > 1}
                            />
                        </Paper>
                    </Slide>
                );
            case 'ISSUE_REPORT':
                return (
                    <Slide
                        direction="left"
                        appear={qrcodeModes?.length > 1} // we hide the animation if this is not a qrcode with multiple modes
                        in
                        timeout={{ enter: transitionBetweenPageTime }}
                    >
                        <Paper className={classes.paper}>
                            <Component
                                componentName={'NewTicketForm'}
                                qrcodesHasManyMode={qrcodeModes?.length > 1}
                            />
                        </Paper>
                    </Slide>
                );
            case 'DOCUMENT':
                return (
                    <Slide
                        direction="left"
                        appear={qrcodeModes?.length > 1} // we hide the animation if this is not a qrcode with multiple modes
                        in
                        timeout={{ enter: transitionBetweenPageTime }}
                    >
                        <Paper className={classes.paper}>
                            <Component
                                componentName={'DocumentQRCodeForm'}
                                qrcodesHasManyMode={qrcodeModes?.length > 1}
                                device={device}
                            />
                        </Paper>
                    </Slide>
                );
            case 'PASSAGE_VISUALIZATION':
                return (
                    <Slide
                        direction="left"
                        appear={qrcodeModes?.length > 1} // we hide the animation if this is not a qrcode with multiple modes
                        in
                        timeout={{ enter: transitionBetweenPageTime }}
                    >
                        <Paper className={classes.paper}>
                            <Component
                                componentName={'VisualizationPassage'}
                                qrcodesHasManyMode={qrcodeModes?.length > 1}
                                device={device}
                            />
                        </Paper>
                    </Slide>
                );
            default:
                return <Component componentName={'MobileErrorPage'} addLoadingOnPage hasGoBackArrow={qrcodeModes?.length > 1} />;
        }
    };
    if (isLoading || device === null) { return <Component componentName={'LoaderBarTop'} isLoading={isLoading} />; }

    const getQRCodeButtonComponent = mode => {
        if (!mode) {
            return <></>;
        }
        if (shouldDisableQRCodeMode(mode, isOnline)) {
            return (
                <div className={'qrcode-mode-button qrcode-disabled-mode'}>
                    <div
                        className={classNames(['qrcode-mode-button-left-part'])}
                    >
                        <Icon type={'OFFLINE_MODE'} />
                    </div>
                    <div className={'qrcode-mode-button-right-part-offline'}>
                        <p className={'mode-name'}>{t(`passages:${mode.toLowerCase()}_mode`)}</p>
                        <p className={'offline-explanations-mode'}>
                            {`${t('common:this_actions_requires')} `}
                            <span className="underline-text">{t('common:internet_connection')}</span>
                        </p>
                    </div>
                </div>
            );
        }
        // Online mode
        return (
            <div className={'qrcode-mode-button'}>
                <div
                    className={classNames([
                        'qrcode-mode-button-left-part',
                        { 'qrcode-mode-button-larger-icon': isLargerModeIconStyle(mode) }
                    ])}
                >
                    <Icon type={mode} />
                </div>
                <div
                    className={'qrcode-mode-button-right-part'}
                    onClick={() => {
                        history.push(
                            { pathname: `${history.location.pathname.split(`/${currentQRCodeForm}`)[0]}/${mode}` }
                        );
                    }}
                >
                    <p>{t(`passages:${mode?.toLowerCase()}_mode`)}</p>
                    <div className={'qrcode-arrow-back'}>
                        <Icon type="ArrowForwardIcon" />
                    </div>
                </div>
            </div>
        );
    };

    // If the qrcode has one mode OR many modes and the url contains a mode, we need to redirect the user to the right qrcode form
    const displaySpecificQRCodeForm = qrcodeModes?.length === 1 || currentQRCodeForm;
    return (
        <div className="main-wrapper qrcode-main-wrapper">
            {!isLoggable && <Component componentName={'TopSection'} />}
            {device && device?.length === 0 || qrcodeModes?.length === 0
                ? <Component componentName={'MobileErrorPage'} />
                : (
                    isLoggable ? (
                        <AuthenticationBackground>
                            <div className="content">
                                <div className="step-wrapper">
                                    <Component componentName={'LogInForm'} label={'common:id_or_email_address'} goBackToPreviousUrl history={history} />
                                </div>
                            </div>
                        </AuthenticationBackground>
                    ) : (
                        <>
                            <Slide appear={false} in={!displaySpecificQRCodeForm} style={currentQRCodeForm && { display: 'none' }} direction="right" timeout={{ enter: transitionBetweenPageTime, exit: transitionBetweenPageTime }}>
                                <div className="incident-page">
                                    <div className={'qrcode-form-title-wrapper'}>
                                        <p>{t('passages:what_will_you_do_today')}</p>
                                    </div>
                                    <div className={'qrcode-form-wrapper'}>
                                        {sortQrcodeModes(qrcodeModes)?.map(mode => getQRCodeButtonComponent(mode))}
                                        { isChatbotDemoQRCode && <Component componentName={'HelpMenu'} isDemo /> }
                                    </div>
                                </div>
                            </Slide>
                            {displaySpecificQRCodeForm && getRightQRCodeForm()}
                        </>
                    )
                )
            }
            {
                !isLoggable && !isEmpty(emergencyConfig) && !displaySpecificQRCodeForm
                    && (
                        <div className="emergency-container">
                            <div className="emergency-button">
                                <a id="emergency-call-link" href={`tel:${emergencyConfig.phone}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    {emergencyConfig.label}
                                </a>
                            </div>
                            <WaveIcon className={'emergency-wave'} />
                        </div>
                    )
            }
        </div>
    );
};


export default React.memo(QRCodeRouter);

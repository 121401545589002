// Libs
import React from 'react';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useComponentsPool } from '../../../../ComponentsPool';
import { getGroupNameFromId } from '../../../groups/helper';

export default props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const groupsList = useSelector(state => state.groups.list);
    const skillName = getGroupNameFromId(groupsList, props.ticket?.custom_field?.skill_group_id);

    return (
        <div className={'middle-part'}>
            <div className={'ticket-title-wrapper'}>
                <Tooltip
                    placement="top"
                    title={skillName ? t(`groups:${skillName}`) : ''}
                >
                    <div>
                        <Component componentName={'Icon'} type={skillName || 'tickets'} />
                    </div>
                </Tooltip>
                <Tooltip placement="top" title={props.ticket.title?.length > 40 ? props.ticket.title : ''}>
                    <p>{props.ticket.title}</p>
                </Tooltip>
            </div>
            <div className={'ticket-creation-date-wrapper'}>
                {moment(props.ticket.created_at).format('DD/MM/YY HH[h]mm')}
            </div>
        </div>
    );
};

// Libs
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter, isEmpty, sortBy } from 'lodash';
// Context
import { useModal } from '../../modal/ModalContext';
import { useFootBar } from '../../footbar/FootBarContext';
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import { editUser } from '../actions';
import { useFormState } from '../../layout/forms/api/useFormState';
import { getGroupList } from '../../groups/actions';
import { getTickets } from '../../tickets/actions';
import getNfcTagIdByName from '../../nfcs/helper';
import { isUserWithoutMail } from '../helper';

const UserActionBar = () => {
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const footBar = useFootBar();
    const modal = useModal();
    const { formState } = useFormState();
    const workspaceRole = useSelector(state => state.workspace.roles);
    const navbar = useSelector(state => state.navbar);
    const users = useSelector(state => state.users.list);
    const profile = useSelector(state => state.users.currentUser);
    const user = useSelector(state => state.users.userShownInDetailsPopup);
    const userWorkspaceRights = useMemo(() => profile.workspace_rights);
    const buttons = useSelector(state => sortBy(state.users.footbarButtons, 'weight'));
    const [filteredGroups, setFilteredGroups] = useState(filter(navbar.filters, navbarFilter => navbarFilter?.type === 'Group' || navbarFilter?.type === 'userGroup')
        .map(navbarFilter => navbarFilter.id));
    const item = modal.getWithFootBar() ? modal.getItem() : footBar.getItem();
    const list = modal.getWithFootBar() ? [modal.getItem()] : footBar.getList();
    const hasInvitations = useMemo(() => list.some(user => user?.invite_email));
    const hasConfiguredUsers = useMemo(() => list.some(user => user?.email));
    const hasGroupedUsers = useMemo(() => list.some(user => user?.email && !isEmpty(user.group_memberships)));
    const nfcs = useSelector(state => state.nfcs.list);

    useEffect(() => {
        setFilteredGroups(filter(navbar.filters, navbarFilter => navbarFilter?.type === 'Group' || navbarFilter?.type === 'userGroup')
            .map(navbarFilter => navbarFilter.id));
    }, [navbar.filters]);

    const footbarButtons = !isEmpty(item) ? buttons.map(button => (
        <Component
            key={button.component}
            componentName={button.component}
            workspaceRole={workspaceRole}
            hasInvitations={hasInvitations}
            hasConfiguredUsers={hasConfiguredUsers}
            hasGroupedUsers={hasGroupedUsers}
            userWorkspaceRights={userWorkspaceRights}
            filteredGroups={filteredGroups}
            list={list}
        />
    )) : [];

    const switchButtonCallback = () => {
        if (formState.nfc_tag || formState.nfc_tag === '') { // We send nfc_id as the field to edit the user nfc tag
            formState.nfc_id = formState.nfc_tag ? getNfcTagIdByName(nfcs, formState.nfc_tag) : null;
            delete formState.nfc_tag;
        }
        return dispatch(editUser(formState.id, formState)).then(() => {
            if (profile.id === formState.id) {
                dispatch(getGroupList());
                dispatch(getTickets(true));
            }
        });
    };

    return (
        <Component
            componentName={'Footbar'}
            actionButtons={footbarButtons}
            modal={'UserDetailsPopup'}
            resourceList={filter(users, user => user?.invite_email === undefined)}
            switchButtonCallback={() => switchButtonCallback()}
            resourceType={'User'}
            locale={'users'}
        />
    );
};

export default UserActionBar;

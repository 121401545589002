// library
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
// Context
import { useComponentsPool } from '../../../ComponentsPool';

const RouterNav = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const defaultRoute = useSelector(state => state.navbar.defaultRoute);
    const settings = useSelector(state => state.core.settings);
    const [currentRoute, setCurrentRoute] = useState(defaultRoute);

    useEffect(() => {
        setCurrentRoute(document.location.pathname === '/' ? `/${defaultRoute}` : window.location.pathname);
    }, [defaultRoute, window.location.pathname]);

    return props.focused && (
        <ul className={'navigation-buttons'}>
            { sortBy(settings.modules, 'weight')
                .filter(({ isNavbarButton, override }) => isNavbarButton && !override)
                .map(button => (
                    <li
                        className={classNames([{ selected: currentRoute === `/${button.route}` }])}
                        key={button.label}
                    >
                        <Component
                            componentName={'NavButton'}
                            icon={button.icon}
                            label={t(button.label)}
                            route={`/${button.route}`}
                            focused={props.focused}
                            currentRoute={currentRoute}
                            setCurrentRoute={setCurrentRoute}
                            disabled={button.isDisabled}
                        />
                    </li>
                ))}
        </ul>
    );
};

RouterNav.propTypes = {
    focused: PropTypes.bool.isRequired
};

export default RouterNav;

import update from 'immutability-helper';
import {
    findIndex, find, cloneDeep, remove
} from 'lodash';
import moment from 'moment';

export const initialState = () => ({
    groupShownInDetailsPopup: {},
    list: [],
    roles: [],
    total: 0,
    itemDisplayedOnChipList: 1,
    dateFrom: moment().subtract(6, 'months'),
    dateTo: moment(),
    editableFields: [{
        name: 'group_batiment_group',
        title: 'groups:batiment_group',
        componentName: 'EditableField',
        renderValue: 'DeviceGroupSimpleViewGroup',
        editionComponent: 'GroupSelector',
        props: {
            required: true,
            value: ['custom_field', 'batiment_group_id'],
            placeholder: '',
            groupType: 'BATIMENT',
            multiple: false
        },
        weight: 400
    }, {
        name: 'group_hardware_ids',
        title: 'groups:hardware_ids',
        renderValue: 'DeviceGroupHardwareIdsSimpleString',
        disabledInput: true,
        props: { value: 'hardware_ids' },
        weight: 300
    }],
    tableColumns: [],
    footbarButtons: [{ component: 'ExportDataButton', weight: 100 }],
    detailPopupParts: [
        { width: '50%', component: 'GroupDetailsPopupLeftPart' },
        { width: '50%', component: 'GroupDetailsPopupRightPart' }
    ]
});

let index = 0;
let detailGroup;
let newList;
let deleteIndex;

const skillsAtTheEnd = ['Autre', 'Autres', 'Divers', 'Autres demandes', 'Autre demande'];

const groupReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'RESET_SELECTED_GROUP':
            return update(state, {
                groupShownInDetailsPopup: { $set: {} }
            });
        case 'ADD_GROUP_FOOTBAR_BUTTON':
            return update(state, { footbarButtons: { $push: action.payload } });
        case 'REMOVE_GROUP_FOOTBAR_BUTTON':
            newList = cloneDeep(state.footbarButtons);
            remove(newList, type => type.component === action.payload);
            return update(state, { footbarButtons: { $set: newList } });
        case 'EDIT_ITEMS_DISPLAYED_ON_CHIP_LIST':
            return update(state, { itemDisplayedOnChipList: { $set: action.payload } });
        case 'CREATE_GROUP_FULFILLED':
            return update(state, { list: { $push: [action.payload] } });
        case 'REMOVE_GROUP_FULFILLED':
        case 'REMOVE_GROUPS_FULFILLED':
            newList = cloneDeep(state.list);
            action.payload?.forEach(droppedGroup => {
                newList.splice(findIndex(newList, item => item.group_id === droppedGroup), 1);
            });
            return update(state, { list: { $set: newList } });
        case 'EDIT_GROUP_FULFILLED':
        case 'EDIT_BATIMENT_FULFILLED':
        case 'EDIT_SKILL_FULFILLED':
            index = findIndex(state.list, item => item.group_id === action.payload.res.group_id);
            if (state.groupShownInDetailsPopup.group_id === action.payload.res.group_id) {
                return update(state, {
                    list: { [index]: { $set: action.payload.res } },
                    groupShownInDetailsPopup: { $set: action.payload.res }
                });
            }
            return update(state, { list: { [index]: { $set: action.payload.res } } });
        case 'GET_GROUP_LIST_FULFILLED':
            const groups = action.payload.groups || action.payload;
            return update(state, {
                list: {
                    $set: groups.sort((a, b) => {
                        if (skillsAtTheEnd.includes(a?.group_name)) return 1;
                        if (skillsAtTheEnd.includes(b?.group_name)) return -1;
                        return 0;
                    })
                },
                device_groups: { $set: groups.filter(group => group?.custom_field?.type === 'DEVICE' && group.custom_field?.linked && group.custom_field?.linked !== 'false' && group.devices?.length > 0) }
            });
        case 'GET_GROUP_ROLES_FULFILLED':
            return update(state, { roles: { $set: action.payload.data.results } });
        case 'GET_GROUP_SUMMARY_FULFILLED':
            return update(state, {
                groupShownInDetailsPopup: {
                    summary: { $set: action.payload.data.summary },
                    totalSummary: { $set: action.payload.data.count }
                }
            });
        case 'SET_GROUP_DETAIL':
            detailGroup = find(state.list, { group_id: action.payload });
            return update(state, { groupShownInDetailsPopup: { $set: detailGroup } });
        case 'GET_GROUP_FULFILLED':
            return update(state, { groupShownInDetailsPopup: { $set: action.payload.data } });
        case 'LOGOUT_FULFILLED':
            return initialState();
        case 'ADD_GROUP_EDITABLE_FIELD':
            return update(state, { editableFields: { $push: [action.payload] } });
        case 'REMOVE_GROUP_EDITABLE_FIELD':
            deleteIndex = state.editableFields.findIndex(d => d.name === action.payload);
            if (deleteIndex > -1) {
                return update(state, { editableFields: { $splice: [[deleteIndex, 1]] } });
            }
            return state;
        case 'ADD_GROUP_TABLE_FIELD':
            return update(state, { tableColumns: { $push: [action.payload] } });
        case 'REMOVE_GROUP_TABLE_FIELD':
            deleteIndex = state.tableColumns.findIndex(d => d.name === action.payload);
            if (deleteIndex > -1) {
                return update(state, { tableColumns: { $splice: [[deleteIndex, 1]] } });
            }
            return state;
        case 'GET_GROUP_DATA_PREVIEW_FULFILLED':
            return update(state, {
                groupShownInDetailsPopup: {
                    data: { $set: action.payload.data }
                }
            });
        case 'RESET_GROUP_DATA_PREVIEW':
            return update(state, {
                groupShownInDetailsPopup: {
                    data: { $set: {} }
                }
            });
        case 'GET_GROUP_DATA_EXPORT_FULFILLED':
            return update(state, {
                groupShownInDetailsPopup: {
                    dataExport: { $set: action.payload.data }
                }

            });
        case 'DELETE_BUILDING_FULFILLED':
        case 'DELETE_BUILDINGS_FULFILLED':
            newList = cloneDeep(state.list);
            action.payload?.map(groupId => {
                remove(newList, group => group.group_id === groupId);
            });
            return update(state, {
                list: { $set: newList }
            });
        case 'UPDATE_BUILDING_FULFILLED':
            index = findIndex(state.list, group => group.group_id === action.payload.group_id);
            return update(state, { list: { [index]: { $set: action.payload } } });
        case 'CREATE_BUILDING_FULFILLED':
            newList = cloneDeep(state.list);
            newList.unshift(action.payload);
            return update(state, { list: { $set: newList } });
        case 'DELETE_GROUP_FULFILLED':
        case 'DELETE_BATIMENT_FULFILLED':
        case 'DELETE_SKILL_FULFILLED':
        case 'DELETE_JOB_FULFILLED':
        case 'DELETE_JOBS_FULFILLED':
        case 'DELETE_GROUPS_FULFILLED':
        case 'DELETE_BATIMENTS_FULFILLED':
        case 'DELETE_SKILLS_FULFILLED':
            newList = cloneDeep(state.list);
            action.payload.group_ids.forEach(groupToDelete => {
                remove(newList, group => group?.group_id === groupToDelete);
            });
            return update(state, { list: { $set: newList } });
        default:
            return state;
    }
};

export default groupReducer;

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Helper
import { getUserName, getUsersFromIds, getVisibleUsers } from '../../users/helper';

const getFollowers = (followersIds, users) => {
    const followerUsers = getVisibleUsers(getUsersFromIds(users, followersIds));
    const result = [];
    followerUsers.forEach(follower => {
        result.push({ id: follower?.id, value: getUserName(follower) });
    });
    return result;
};

const getExternalFollowers = (external_followers) => {
    const result = [];
    external_followers?.forEach(external_follower => {
        result.push({ id: external_follower?.id, value: external_follower.mail });
    });
    return result;
};

const TicketFollowers = props => {
    const ticketInDetailsPopup = useSelector(state => state.tickets.ticketShownInDetailsPopup);
    const users = useSelector(state => state.users.list);
    const followersIds = ticketInDetailsPopup.followers;
    const followers = props.value === 'external_followers' ? getExternalFollowers(ticketInDetailsPopup.external_followers) : getFollowers(followersIds, users);
    const { Component } = useComponentsPool();

    return (
        <Component
            componentName={'ChipButton'}
            maxVisible={3}
            chipsToDisplay={followers}
        />
    );
};

export default TicketFollowers;

// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useComponentsPool } from '../../../ComponentsPool';
import { getDeviceFromJob, getGroupsListFromType } from '../../groups/helper';

const JobsTable = () => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const groupList = useSelector(state => state.groups.list);
    const deviceList = useSelector(state => state.devices.list);
    const jobsList = getGroupsListFromType(groupList, 'JOB');
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(jobsList
            .map(group => ({
                name: group.group_name,
                id: group.group_id,
                group_ids: group.group_ids,
                linked_devices_count: getDeviceFromJob(group.group_id, deviceList).length
            }))
        );
    }, [groupList, deviceList]);

    const headCells = [
        {
            id: 'name',
            toHide: false,
            hasIcon: false,
            positionToRight: false,
            disablePadding: false,
            label: t('settings:first_column_name')
        },
        {
            id: 'linked_resources',
            hasIcon: false,
            positionToRight: false,
            disablePadding: false,
            label: t('settings:linked_resources')
        }
    ];

    const columnsToHide = headCells.filter(cell => cell.toHide).map(cell => cell.id);
    return (
        <Component
            componentName={'SettingsTable'}
            headCells={headCells}
            tableTitle={'JOB'}
            rows={rows}
            columnsToHide={columnsToHide}
        />
    );
};

export default JobsTable;

// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { filter, find } from 'lodash';
import { Tooltip } from '@material-ui/core';

// Context
import { useFootBar } from '../../footbar/FootBarContext';
import { useComponentsPool } from '../../../ComponentsPool';
import { useModal } from '../../modal/ModalContext';
import {
    getWorkspaceRoleNameFromId,
    isUserWithoutMail
} from '../helper';


const UserDetailsPopupRightPart = props => {
    const { Component } = useComponentsPool();
    const modal = useModal();
    const [t] = useTranslation();
    const footBar = useFootBar();
    const editionMode = footBar.getEditionMode();
    const user = useSelector(state => state.users.userShownInDetailsPopup);
    const [roleExplanations, setRoleExplanations] = useState('');
    const roles = useSelector(state => state.workspace.roles);
    const [listRole] = useState(filter(roles, role => role.workspace_role_name !== 'Owner').map(role => ({
        id: role?.workspace_role_id,
        value: t(`users:${role.workspace_role_name}`)
    })));

    const contractorId = find(roles, role => role.workspace_role_name === 'Contractor').workspace_role_id;
    const userId = find(roles, role => role.workspace_role_name === 'User').workspace_role_id;
    const adminId = find(roles, role => role.workspace_role_name === 'Admin' || role.workspace_role_name === 'Owner')
        .workspace_role_id;

    const [disabledSkills, setDisabledSkills] = useState(true);
    const [isSupervisorUser, setIsSupervisorUser] = useState(false);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const hasNoMailUser = isUserWithoutMail(user);

    const getRightRoleExplanation = userRoleId => {
        switch (userRoleId) {
            case contractorId:
                setRoleExplanations(t('users:explanations_invite_step1_contractor'));
                return;
            case adminId:
                setRoleExplanations(t('users:explanations_invite_step1_admin'));
                return;
            case userId:
                setRoleExplanations(t('users:explanations_invite_step1_user'));
                return;
            default:
                setRoleExplanations('');
        }
    };

    useEffect(() => {
        if (user.workspace_role?.workspace_role_id === contractorId
            || user.workspace_role?.workspace_role_id === userId && workspaceSettings.filter_user_on_skills === true
            || user.workspace_role?.workspace_role_id === userId && workspaceSettings.supervision_skills_to_users === true
            || user.workspace_role?.workspace_role_id === adminId && workspaceSettings.filter_admin_on_skills === true
            || user?.username === 'messaging@merciyanis.com'
        ) {
            setDisabledSkills(false);
            if (user.workspace_role?.workspace_role_id === userId && workspaceSettings.supervision_skills_to_users === true) {
                setIsSupervisorUser(true);
            }
        }
        if (user.workspace_role?.workspace_role_id) getRightRoleExplanation(user.workspace_role.workspace_role_id);
    }, []);

    useEffect(() => {
        if (props.state?.workspace_role_id) {
            getRightRoleExplanation(props.state.workspace_role_id);

            if (props.state?.workspace_role_id === contractorId
                || props.state?.workspace_role_id === userId && workspaceSettings.filter_user_on_skills === true
                || props.state?.workspace_role_id === userId && workspaceSettings.supervision_skills_to_users === true
                || props.state?.workspace_role_id === adminId && workspaceSettings.filter_admin_on_skills === true
                || user?.username === 'messaging@merciyanis.com')
            {
                setDisabledSkills(false);
                if (props.state?.workspace_role_id === userId && workspaceSettings.supervision_skills_to_users === true) {
                    setIsSupervisorUser(true);
                }
            } else {
                setDisabledSkills(true);
            }
        }
    }, [props.state.workspace_role_id]);

    useEffect(() => {
        footBar.set({ editionMode: false });
        modal.setItem(user);
    }, [user]);

    useEffect(() => {
        // if we cancel the changes, we check if we need to hide the field skill
        if (user.workspace_role?.workspace_role_id === contractorId
            || user.workspace_role?.workspace_role_id === userId && workspaceSettings.filter_user_on_skills === true
            || user.workspace_role?.workspace_role_id === userId && workspaceSettings.supervision_skills_to_users === true
            || user.workspace_role?.workspace_role_id === adminId && workspaceSettings.filter_admin_on_skills === true
            || user?.username === 'messaging@merciyanis.com'
        ) {
            setDisabledSkills(false);
            if (user.workspace_role?.workspace_role_id === userId && workspaceSettings.supervision_skills_to_users === true) {
                setIsSupervisorUser(true);
            }
        } else {
            setDisabledSkills(true);
        }
    }, [editionMode]);

    return (
        <div className={'resource-infos-wrapper user-side user-side-right'}>
            <div className={'title user-title'}>
                <h3>{t('common:configuration')}</h3>
                <span className={'warning-desassignation'}>{t('users:warning_user_fields_modif')}</span>
            </div>
            <div className={'resource-infos'}>
                <Component
                    title={t('common:workspace_role')}
                    subtitle={roleExplanations}
                    componentName={'EditableField'}
                    renderValue={'UserSimpleString'}
                    editionComponent={'Select'}
                    props={{
                        required: true,
                        rules: [''],
                        value: ['workspace_role', 'workspace_role_name'],
                        options: listRole,
                        placeholder: '',
                        isSelectedToText: '',
                        selected: {
                            id: user.workspace_role.workspace_role_id,
                            value: t(`users:${user.workspace_role?.workspace_role_name}`)
                        },
                        disabled: hasNoMailUser
                    }}
                    onChange={value => {
                        props.updateState('workspace_role_id', value.id);
                    }}
                />
                <Component
                    title={t('groups:select_group')}
                    componentName={'EditableField'}
                    editionComponent={'GroupSelector'}
                    renderValue={'UserGroupsEditableField'}
                    showSelectAll
                    props={{
                        rules: ['selectNotEmpty'],
                        value: ['custom_field', 'batiment_group'],
                        placeholder: '',
                        groupType: 'BATIMENT',
                        multiple: true,
                        required: true,
                        showSelectAll: true
                    }}
                    onChange={groups => props.updateState('building_ids', groups)}
                />
                {
                    !disabledSkills && (
                        <Component
                            title={isSupervisorUser ? t('groups:skill_supervisor') : t('groups:skill')}
                            subtitle={isSupervisorUser
                                ? t('users:explanation_supervision_skill')
                                : t('users:explanation_skill')
                            }
                            componentName={'EditableField'}
                            editionComponent={'GroupSelector'}
                            renderValue={'UserGroupsEditableField'}
                            showSelectAll
                            props={{
                                rules: [isSupervisorUser ? '' : 'selectNotEmpty'],
                                value: ['custom_field', 'skill_group'],
                                placeholder: '',
                                groupType: 'SKILL',
                                multiple: true,
                                required: !isSupervisorUser,
                                showSelectAll: true
                            }}
                            onChange={groups => {
                                props.updateState('skill_ids', groups);
                            }}
                        />

                    )}
                <Component
                    componentName={'UserAlerts'}
                    userDetailPopupFormState={props.state}
                    updateState={value => props.updateState('subscriptions', value)}
                    user={user}
                    currentRoleName={props.state?.workspace_role_id
                        ? getWorkspaceRoleNameFromId(roles, props.state.workspace_role_id)
                        : user.workspace_role?.workspace_role_name
                    }
                />
            </div>
        </div>
    );
};

export default UserDetailsPopupRightPart;

// Libs
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ArchivedTicketDates = props => {
    const [t] = useTranslation();
    return (
        <div className={'table-row-content-text creation-and-closing-dates'}>
            <span>{moment(props.resource.created_at).format(`[${t('tickets:created_the')}] DD/MM/YYYY [${t('common:at_second')}] HH[h]mm`)}</span>
            <span>
                {
                props.resource.custom_field?.resolution_date
                    ? moment(props.resource.custom_field?.resolution_date).format(`[${t('tickets:closed_the')}] DD/MM/YYYY [${t('common:at_second')}] HH[h]mm`)
                    : ''
                }
            </span>
        </div>
    );
};

export default ArchivedTicketDates;

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { Noise } from 'noisejs';
import * as PIXI from 'pixi.js-legacy';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';

import CompanyLogo from '../CompanyLogo';

PIXI.utils.skipHello();

const topWaveAnimations = new PIXI.Application({
    transparent: true,
    antialias: true,
    autoDensity: true,
    resolution: 2
});

const bottomWaveAnimations = new PIXI.Application({
    transparent: true,
    antialias: true,
    autoDensity: true,
    resolution: 2
});

import { isEdge, isIE } from "react-device-detect";

const WaveIllustrationTop = () => {
    const logo = useSelector(state => state.workspace.settings.logo_url);
    const primaryColor = '#011056';

    React.useLayoutEffect(() => {
        if (!isIE && !isEdge) {
            const canvasColorFilled = document.createElement('canvas');
            canvasColorFilled.width = 640;
            canvasColorFilled.height = 400;
            const contextCanvasColorFilled = canvasColorFilled.getContext('2d');
            contextCanvasColorFilled.fillStyle = primaryColor;
            contextCanvasColorFilled.rect(0, 0, 640, 400);
            contextCanvasColorFilled.fill();

            const canvasColorFilledLighter = document.createElement('canvas');
            canvasColorFilledLighter.width = 640;
            canvasColorFilledLighter.height = 400;
            const contextCanvasColorFilledLighter = canvasColorFilledLighter.getContext('2d');
            contextCanvasColorFilledLighter.fillStyle = `${primaryColor}77`;
            contextCanvasColorFilledLighter.rect(0, 0, 640, 400);
            contextCanvasColorFilledLighter.fill();

            document.getElementById('wave-top').querySelectorAll('*').forEach(n => n.remove());
            document.getElementById('wave-bottom').querySelectorAll('*').forEach(n => n.remove());
            document.getElementById('wave-top').appendChild(topWaveAnimations.view);
            document.getElementById('wave-bottom').appendChild(bottomWaveAnimations.view);
            let count = 0;

            // build a rope!
            const ropeLength = 1500 / 100;

            const points = [];
            const points2 = [];
            const points3 = [];
            const points4 = [];

            for (let i = 0; i < 100; i = i + 1) {
                points.push(new PIXI.Point(i * ropeLength, 0));
                points2.push(new PIXI.Point(i * ropeLength, 0));
                points3.push(new PIXI.Point(i * ropeLength, 0));
                points4.push(new PIXI.Point(i * ropeLength, 0));
            }

            const strip = new PIXI.SimpleRope(PIXI.Texture.from(canvasColorFilled), points);
            const strip2 = new PIXI.SimpleRope(PIXI.Texture.from(canvasColorFilledLighter), points2);
            const strip3 = new PIXI.SimpleRope(PIXI.Texture.from(canvasColorFilled), points);
            const strip4 = new PIXI.SimpleRope(PIXI.Texture.from(canvasColorFilledLighter), points2);

            strip.x = -459;
            strip2.x = -449;
            strip2.y = 15;
            strip3.x = -459;
            strip4.x = -449;
            strip4.y = 15;

            const snakeContainer = new PIXI.Container();
            const snakeContainer2 = new PIXI.Container();
            // const snakeContainer_2 = new PIXI.Container();
            snakeContainer.x = -320;
            snakeContainer.y = 500;
            snakeContainer2.x = -320;
            snakeContainer2.y = 500;

            topWaveAnimations.stage.addChild(snakeContainer);
            bottomWaveAnimations.stage.addChild(snakeContainer2);

            snakeContainer.addChild(strip);
            snakeContainer.addChild(strip2);
            snakeContainer.rotation = -Math.PI / 4;

            snakeContainer2.addChild(strip3);
            snakeContainer2.addChild(strip4);
            snakeContainer2.rotation = -Math.PI / 4;

            const noise = new Noise(Math.random() * 60000);

            let perlinX1;
            let perlinY1;
            let perlinX2;
            let perlinY2;
            let perlin2X1;
            let perlin2Y1;
            let perlin2X2;
            let perlin2Y2;

            const offsetX1 = parseInt(Math.random() * 100000, 10);
            const offsetY1 = parseInt(Math.random() * 100000, 10);
            const offsetX2 = parseInt(Math.random() * 100000, 10);
            const offsetY2 = parseInt(Math.random() * 100000, 10);
            const offsetX1_2 = parseInt(Math.random() * 100000, 10);
            const offsetY1_2 = parseInt(Math.random() * 100000, 10);
            const offsetX2_2 = parseInt(Math.random() * 100000, 10);
            const offsetY2_2 = parseInt(Math.random() * 100000, 10);

            topWaveAnimations.ticker.add(() => {
                count = count + 0.002;

                // make the snake
                for (let i = 0; i < points.length; i = i + 1) {
                    perlinX1 = noise.simplex2(offsetX1 + count + i / 20, 0);
                    perlinY1 = noise.simplex2(offsetY1 + count + i / 50, 0);
                    perlinX2 = noise.simplex2(offsetX2 + count + i / 20, 0);
                    perlinY2 = noise.simplex2(offsetY2 + count + i / 70, 0);

                    points[i].x = i * ropeLength + perlinX1 * 20;
                    points[i].y = perlinY1 * 50;
                    points2[i].x = i * ropeLength + perlinX2 * 20;
                    points2[i].y = perlinY2 * 50;
                }
            });

            bottomWaveAnimations.ticker.add(() => {
                count = count + 0.002;

                // make the snake
                for (let i = 0; i < points.length; i = i + 1) {
                    perlin2X1 = noise.simplex2(offsetX1_2 + count + i / 20, 0);
                    perlin2Y1 = noise.simplex2(offsetY1_2 + count + i / 50, 0);
                    perlin2X2 = noise.simplex2(offsetX2_2 + count + i / 20, 0);
                    perlin2Y2 = noise.simplex2(offsetY2_2 + count + i / 70, 0);

                    points3[i].x = i * ropeLength + perlin2X1 * 20;
                    points3[i].y = perlin2Y1 * 50;
                    points4[i].x = i * ropeLength + perlin2X2 * 20;
                    points4[i].y = perlin2Y2 * 50;
                }
            });
        }
    }, [logo]);

    if (isEdge || isIE) {
        return (
            <div className="step0"></div>
        );
    }
    return (
        <div className="step0">
            <div id="wave-top" />
            <CompanyLogo logo={logo} />
        </div>
    );
};

const WaveIllustrationBottom = () => {
    const { Component } = useComponentsPool();

    return (
        <div className="step0">
            <div id="wave-bottom" />
            <div className="workspace-illustration">
                <Component componentName={'HomeIllustration'} />
            </div>
        </div>
    );
};

export { WaveIllustrationTop, WaveIllustrationBottom };

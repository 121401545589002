// Libs
import React from 'react';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';

const DashboardName = props => {
    const { Component } = useComponentsPool();

    return (
        <div className="table-name" key={props.resource.dashboard_id}>
            <Component componentName={'Icon'} type={'dashboard'} />
            <div className="group">
                <div className={'title'}>{props.resource.name}</div>
            </div>
        </div>
    );
};

export default DashboardName;

// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Context
import { useModal } from '../../modal/ModalContext';
// Helper
import { getDeviceFromId } from '../helper';
import { getId, getValue } from '../../layout/helper';

const DeviceMode = props => {
    const [t] = useTranslation();
    const modal = useModal();
    const devices = useSelector(state => state.devices.list);
    const device = getDeviceFromId(devices, getId(modal.getItem()));
    const value = getValue(props.value, device);
    const inputValues = value?.length > 0 && value.map((mode, idx) => (mode ? t(`devices:${mode}`) + (idx < value.length - 1 ? ', ' : '') : t('common:undefined')));

    return (
        <p className={`info-value ${value ? '' : 'undefined'}`}>
            {inputValues}
        </p>
    );
};

export default DeviceMode;

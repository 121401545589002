import axios from 'axios';
import moment from 'moment';
import { isEmpty } from 'lodash';
import store from '../../store';
import { getTicketComments, updateTicketOnState } from '../tickets/actions';
import getMessagingUsersList from '../messaging/actions';
import { updateInstanceStatus } from '../rounds/actions';
import { hasRightOnTicket } from '../tickets/helper';

export const layoutReady = () => ({ type: 'LAYOUT_READY' });

export const resourceLoaded = () => ({ type: 'RESOURCE_LOADED' });

export const getMinMax = type => ({ type: 'GET_MIN_MAX', payload: type });

export const setGeolocalisation = latLng => ({ type: 'SET_GEOLOCALISATION', payload: latLng });

export const setInitializationError = error => ({ type: 'SET_INITIALIZATION_ERROR', payload: error });

export const updateWorkspaceSetting = (workspaceSetting, value, isCreated, hideNotif = false) => {
    const edition = {};
    edition[workspaceSetting] = value;
    if (isCreated) {
        return {
            type: hideNotif ? 'UPDATE_WORKSPACE_SETTINGS' : 'UPDATE_WORKSPACE_SETTING',
            payload: axios.patch('/workspace/settings/', edition)
                .then(res => res.data)
        };
    }
    return {
        type: hideNotif ? 'UPDATE_WORKSPACE_SETTINGS' : 'UPDATE_WORKSPACE_SETTING',
        payload: axios.post('/workspace/settings/', edition)
            .then(res => res.data)
    };
};

export const addModule = module => ({ type: 'ADD_MODULE', module });

export const addResourceId = id => ({ type: 'ADD_RESOURCE_ID_PROPERTY', id });

export const addResourceName = id => ({ type: 'ADD_RESOURCE_NAME_PROPERTY', id });

export const overrideComponent = (module, path) => ({ type: 'OVERRIDE_COMPONENT', payload: { module, path } });

export const addComponent = (module, path) => ({ type: 'ADD_COMPONENT', payload: { module, path } });

export const addComponents = component => ({ type: 'ADD_COMPONENTS', payload: component });

export const setComponent = (module, path) => ({ type: 'SET_COMPONENT', payload: { module, path } });

export const hideRouteInNavbar = route => ({ type: 'HIDE_ROUTE_IN_NAVBAR', payload: route });

export const hideRouteInDropdown = route => ({ type: 'HIDE_ROUTE_IN_DROPDOWN', payload: route });

export const addAggregationOption = option => ({ type: 'ADD_AGGREGATION_OPTION', payload: option });

export const changeDeviceMainDatatype = dataType => ({ type: 'CHANGE_DEVICE_MAIN_DATATYPE', payload: dataType });

export const execWebSocket = data => ({ type: 'NEW_WEBSOCKET_MESSAGE', payload: data });

// eslint-disable-next-line consistent-return
export const newWebsocketMessage = (data, currentUser,
    filter_admin_on_skills, filter_user_on_skills, user_tickets_mode, groupList,
    contractor_tickets_mode) => dispatch => {
    if (data.message_data) {
        if (data.message_type === 'UPDATE_ROUND_INSTANCE') {
            // Update round instance status from received websocket message
            return dispatch(updateInstanceStatus(data.message_data.instance_id, data.message_data.status));
        }
        if ((data.message_type === 'NEW_TICKET' || data.message_type === 'UPDATE_TICKET')
        && !isEmpty(data.message_data) && hasRightOnTicket(
            data.message_data, currentUser, filter_admin_on_skills,
            filter_user_on_skills, user_tickets_mode, groupList, contractor_tickets_mode)
        ) {
            dispatch(updateTicketOnState(data.message_data, data.message_type));
            if (!isEmpty(data.message_data?.custom_field?.author_messaging_user_id)) {
                dispatch(getMessagingUsersList());
            }
        }
        if (data.message_type === 'CREATE_NOTIFICATION') {
            const ticketIdShownInDetailsPopupId = store.getState().tickets.ticketShownInDetailsPopup?.id;
            if (ticketIdShownInDetailsPopupId === data.message_data?.object?.related_ticket?.id) {
                dispatch(getTicketComments(ticketIdShownInDetailsPopupId));
            }
        }
        dispatch(execWebSocket(data));
    }
};

export const setExportTimeSlots = timeSlots => {
    const payload = {
        from_timestamp: moment(timeSlots?.from_timestamp).set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DD HH:mm:ss'),
        to_timestamp: moment(timeSlots?.to_timestamp).set({ hour: 23, minute: 59, second: 59}).format('YYYY-MM-DD HH:mm:ss'),
        type: timeSlots?.type ? timeSlots.type : 'csv'
    };
    return {
        type: 'SET_EXPORT_TIME_SLOTS',
        payload: {
            timeSlots: payload
        }
    };
};

// Libs
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';
import QuestionLaptop from '../../layout/assets/question-laptop.svg';
import QRCodeBackButton from '../../qrcodes/components/QRCodeBackButton';
import { EVENT_ERROR_QRCODE, segmentTrack } from '../../layout/helper';
import LoaderBarTop from '../../layout/components/LoaderBarTop';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const MobileErrorPage = props => {
    const [t] = useTranslation();
    const devices = useSelector(state => state.devices.list);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(async () => {
        if (props.addLoadingOnPage) {
            await sleep(100);
            setIsLoading(false);
        } else { setIsLoading(false); }
    }, []);

    // useEffect(async () => {
    //     if (props.sendSegment && devices.length > 0) {
    //         segmentTrack(EVENT_ERROR_QRCODE, { qrCodeId: devices[0].device_id, displayedQRCodeForm, qrCodeMode: devices[0].custom_field?.config?.modes });
    //     }
    // }, [props.sendSegment]);

    if (isLoading) { return <LoaderBarTop isLoading />; }
    return (
        <div className="qrcode-simple-page">
            {props.hasGoBackArrow && <QRCodeBackButton />}
            <div className={'qrcode-simple-page-content'}>
                <div className={'qrcode-simple-page-picture-text'}>
                    {props.lottieIcon ? (
                        <div className={'qrcode-page-lottie'}>
                            <Lottie options={{ loop: false, autoplay: true, animationData: props.lottieIcon }} />
                        </div>
                    ) : props.icon || <QuestionLaptop />
                    }
                    <h3 className={'qrcode-simple-page-title'}>{props.title || t('errors:error_occured')}</h3>
                    <p className={'qrcode-simple-page-subtitle'}>{props.subtitle || t('errors:something_went_wrong_try_after')}</p>
                </div>
                {props.hasButton && (
                    <div className={'qrcode-button'}>
                        <Button onClick={() => props.buttonAction()}>
                            <p className={'qrcode-button-text'}>{props.buttonText}</p>
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MobileErrorPage;

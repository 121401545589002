// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
// Context
import { useComponentsPool } from '../../../ComponentsPool';

export default () => {
    const { Component } = useComponentsPool();
    const userWorkspaceRights = useSelector(state => state.users.currentUser.workspace_rights);
    const groupList = useSelector(state => state.groups.list);
    const ticketsActiveMode = useSelector(state => state.tickets.list.find(category => category?.id !== 'ARCHIVED')?.isVisible === true);

    if (!isEmpty(groupList)) {
        return (
            <Component
                componentName={'ActionButton'}
                canUserCreate={userWorkspaceRights.includes('CREATE:Ticket') && ticketsActiveMode}
                modalName={'TicketCreatePopup'}
                iconButton={(<Component componentName={'Icon'} type={'ticket_action_button'} />)}
                tooltipTitle={'tickets:ticket_ticketbutton'}
            />
        );
    }
    return <></>;
};

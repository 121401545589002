// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';

// Scss
import '../scss/googleConnect.scss';
import { useComponentsPool } from '../../../ComponentsPool';
import { getSSORedirectionUrl } from '../actions';
import { notification } from '../../notification/actions';

const AzureADConnect = () => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const workspace = localStorage.getItem('iot_rocket_workspace');
    const dispatch = useDispatch();

    return (
        <div className={'google-connect'}>
            <div className={'sso_wrapper'}>
                <Component componentName={'Icon'} type={'azure_ad'} />
                <Button
                    className={'sso_connect_button'}
                    onClick={() => {
                        dispatch(getSSORedirectionUrl(workspace))
                            .then(redirect_url => {
                                if (redirect_url.value) {
                                    window.location.href = redirect_url.value;
                                } else {
                                    dispatch(notification(
                                        `${t('notifications:get_sso_redirection_url_error')} ${t('common:contact_your_admin')}`,
                                        'error',
                                        'frown'
                                    ));
                                }
                            })
                            .catch(() => {
                                dispatch(notification(
                                    `${t('notifications:get_sso_redirection_url_error')} ${t('common:contact_your_admin')}`,
                                    'error',
                                    'frown'
                                ));
                            });
                    }}
                >
                    <span>{t('common:connect_with_azure_ad')}</span>
                </Button>
            </div>
            <div className="separator">{t('common:or')}</div>
            <div className={'horizontal-bar'} />
            <div className="connect-with-merciyanis-title">{t('common:login_with_merciyanis_account')}</div>
        </div>
    );
};

export default AzureADConnect;

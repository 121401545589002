import React, { useEffect } from 'react';
// Libs
import ReactEcharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
// Helper
import CountUp from 'react-countup';
import { isEmpty } from 'lodash';
import { getPressCountInTotal, getPressCountUnit, easeOutQuad } from '../../helper';
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';


const RatingGraph = props => {
    const eChartsRef = React.useRef(null);
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const footBar = useFootBar();
    const symbols = [
        'path://M21.57,2.049c0.303-0.612,0.927-1,1.609-1c0.682,0,1.307,0.388,1.609,1l5.771,11.695c0.261,0.529,0.767,0.896,1.352,0.981\n'
        + '\t\tL44.817,16.6c0.677,0.098,1.237,0.572,1.448,1.221c0.211,0.649,0.035,1.363-0.454,1.839l-9.338,9.104\n'
        + '\t\tc-0.423,0.412-0.616,1.006-0.517,1.588l2.204,12.855c0.114,0.674-0.161,1.354-0.715,1.756c-0.553,0.4-1.284,0.453-1.89,0.137\n'
        + '\t\tl-11.544-6.07c-0.522-0.275-1.147-0.275-1.67,0l-11.544,6.069c-0.604,0.317-1.337,0.265-1.89-0.136\n'
        + '\t\tc-0.553-0.401-0.829-1.082-0.714-1.756l2.204-12.855c0.1-0.582-0.094-1.176-0.517-1.588L0.542,19.66\n'
        + '\t\tc-0.489-0.477-0.665-1.19-0.454-1.839c0.211-0.649,0.772-1.123,1.449-1.221l12.908-1.875c0.584-0.085,1.09-0.452,1.351-0.982\n'
        + '\t\tL21.57,2.049z'
    ];

    const graphData = props.graphData;

    const ratingsTotal = jsonObject => {
        let total = 0;
        Object.keys(jsonObject).forEach(data => {
            total = total + jsonObject[data];
        });
        return total;
    };

    const ratingsAverage = (jsonObject, numberOfRatings) => {
        let total = 0;
        Object.keys(jsonObject).forEach(data => {
            total = total + jsonObject[data] * data;
        });
        return parseFloat((total / numberOfRatings).toFixed(2));
    };

    const numberOfStars = Object.keys(graphData).length;
    const ratingTotal = ratingsTotal(graphData);
    const ratingAverage = ratingsAverage(graphData, ratingTotal);

    const animationDelay = 0.3;
    const animationDuration = 1.5;
    const timeoutDelay = animationDelay * 1000;

    useEffect(() => {
        // animation for the stars on the graph
        setTimeout(() => {
            // eslint-disable-next-line no-unused-expressions
            eChartsRef.current?.getEchartsInstance().setOption({
                series: [{
                    animationDurationUpdate: animationDuration * 1000,
                    value: ratingAverage,
                    data: [ratingAverage]
                }]
            });
        }, timeoutDelay);
    }, [graphData, footBar.getEditionMode(), footBar.getItem()]);

    const getTooltipLines = () => {
        let lines = '';
        Object.keys(graphData).forEach(rating => {
            const labelPressCount = `${graphData[rating]} ${getPressCountUnit(t, graphData[rating])}`;
            lines = `${lines }<div style="display: flex; justify-content: space-between">
                ${rating} ${Number(rating) <= 1 ? t('graphs:star') : t('graphs:stars')}<b>${labelPressCount}</b>
            </div>`;
        });
        return lines;
    };

    const tooltipSetting = {
        position: { right: '1%', top: '-42%' },
        formatter(params) {
            return `<div style="display: flex; flex-direction: column; justify-content: space-between">
                <b><p style="font-size: 16px; color: #4c4c4c">${params.marker}${t('graphs:click_by_star')}</p></b><br/>
                ${getTooltipLines()}
            </div>`;
        }
    };

    const getWidth = starsCount => (starsCount > 7 ? 95 : starsCount * 12);
    if (isEmpty(graphData)) {
        return (
            <Component componentName={'GraphNoData'} text={t('dashboards:no_satisfaction_data')} />
        );
    }
    return (
        <div className={'echarts-graph-wrapper'}>
            <p className={'graph-title'}>
                <CountUp
                    end={ratingTotal}
                    delay={animationDelay}
                    duration={animationDuration}
                    useEasing
                    easingFn={easeOutQuad}
                />
                {` ${ getPressCountInTotal(t, ratingTotal)}`}
            </p>
            <ReactEcharts
                className={'echarts-graph'}
                ref={eChartsRef}
                style={{ width: '100%', height: '100%' }}
                option={{
                    xAxis: {
                        max: numberOfStars,
                        show: false
                    },
                    yAxis: {
                        data: [t('devices:press_count')],
                        show: false
                    },
                    grid: {
                        top: 'center',
                        right: 'center',
                        width: `${getWidth(numberOfStars)}%`,
                        // same height as the icons
                        height: 70
                    },
                    tooltip: tooltipSetting,
                    series: [{
                        value: 0,
                        data: [0],
                        type: 'pictorialBar',
                        symbol: symbols[0],
                        symbolRepeat: numberOfStars,
                        symbolClip: true,
                        symbolSize: 70,
                        symbolBoundingData: numberOfStars,
                        itemStyle: {
                            color: '#ffce0a'
                        },
                        z: 2
                    }, {
                        // background graph
                        silent: true,
                        type: 'pictorialBar',
                        itemStyle: {
                            normal: {
                                color: '#dbd8ce',
                                opacity: 0.9
                            }
                        },
                        animationDuration: 0,
                        symbolRepeat: numberOfStars,
                        symbol: symbols[0],
                        symbolSize: 70,
                        data: [numberOfStars],
                        z: 1
                    }]
                }}
            />
            <div className={'graph-legends-wrapper'}>
                <div className={'graph-legends'}>
                    <div className={'graph-legend'}>
                        <p className={'legend'}>
                            <CountUp
                                end={ratingAverage}
                                decimals={2}
                                decimal=","
                                delay={animationDelay}
                                duration={animationDuration}
                                useEasing
                                easingFn={easeOutQuad}
                            />
                            {' '}
                            /
                            {' '}
                            {numberOfStars}
                        </p>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default RatingGraph;

// Libs
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// Context
import classNames from 'classnames';
import { useComponentsPool } from '../../ComponentsPool';
// SCSS
import './style.scss';
// Helpers
import { canSeeMobileBottomNavigation } from '../users/helper';
import { MainContext } from '../layout/MainContext';

export default () => {
    const routes = useSelector(state => state.actionButton.routes);
    const { getComponentFile } = useComponentsPool();
    const currentUser = useSelector(state => state.users.currentUser);
    const history = useHistory();
    const devices = useSelector(state => state.devices.list);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const [currentComponentOnTop] = useContext(MainContext);

    const isHigherActionButton = () => { // Check if the action button needs to be higher than usual depending on the currently displayed page
        if (currentComponentOnTop === 'MobileInstancePassagesList') return false;
        if (currentComponentOnTop === 'MobileRoundsInstanceDetails') return true;
        return canSeeMobileBottomNavigation(currentUser, history.location.pathname, devices, workspaceSettings);
    };

    return (
        <div className={classNames('action-bar-wrapper', isHigherActionButton() ? 'action-bar-with-bottom-navigation' : '')}>
            <Switch>
                { routes.map(route => (
                    <Route
                        exact
                        key={route.path}
                        path={route.path}
                        component={getComponentFile(route.component)}
                    />
                ))}
            </Switch>
        </div>
    );
};

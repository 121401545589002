// Lib
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Context
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import Switch from '@material-ui/core/Switch';
import { Tooltip } from '@material-ui/core';
import { useComponentsPool } from '../../../../ComponentsPool';
import Icon from '../../../layout/components/Icon';
import { createDocument, uploadDocument } from '../../../documents/actions';
import DevicesTransferList from '../../../devices/components/DevicesTransferList';
import {
    deepNotEqual,
    EVENT_IMPORT_FILE_ERROR,
    getInputFileUrlForThumbnail,
    segmentTrack,
    useSecondMainColorTooltip
} from '../../../layout/helper';
import { ConfigTabsContext } from '../../ConfigContext';
import {
    getDocumentInputFileAcceptFormats,
    documentFormatIsAvailable
} from '../../../documents/helper';
import { Toast } from '../../../notification/components';


const CreateQRCodesDocument = props => {
    const { t } = useTranslation();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const tooltipStyle = useSecondMainColorTooltip();
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [displayEditFileButton, setDisplayEditFileButton] = useState(false);
    const [userIsChoosingFile, setUserIsChoosingFile] = useState(false);
    const [initialDocument] = useState({
        title: '',
        description: '',
        currently_assigned_device_ids: [],
        url: '',
        needs_authentication: false
    });
    const [newDocument, setNewDocument] = useState(initialDocument);
    const [enableSaveButton, setEnableSaveButton] = useState(false);
    const [enableCancelButton, setEnableCancelButton] = useState(false);
    const [disableTabs, setDisableTabs] = useContext(ConfigTabsContext);

    const uploadImage = files => {
        setIsLoadingImg(true);
        const fileFormat = files[0]?.type?.split('/')[1];
        if (fileFormat && documentFormatIsAvailable(fileFormat)) {
            dispatch(uploadDocument(files[0])).then(res => {
                // The type is only modified for the preview to work
                setNewDocument({ ...newDocument, url: res.value.media_url, type: fileFormat.toUpperCase() });
                setTimeout(() => { // File takes around 0.5 sec to appear
                    setIsLoadingImg(false);
                }, 500);
            }).catch(error => {
                segmentTrack(EVENT_IMPORT_FILE_ERROR, { fileSizeInBytes: files[0]?.size, fileFormat });
                toast(
                    <Toast
                        message={t(`notifications:${error.response?.status === 413 ? 'upload_document_error_size' : 'upload_document_error'}`)}
                        icon={'frown'}
                        type={'error'}
                    />, {
                        position: toast.POSITION.BOTTOM_LEFT,
                        className: 'normal',
                        bodyClassName: 'notification-body grow-font-size',
                        progressClassName: 'error-custom-progress-bar'
                    }
                );
                setTimeout(() => { // File takes around 0.5 sec to appear
                    setIsLoadingImg(false);
                }, 500);
            });
        } else {
            toast(
                <Toast
                    message={t('notifications:upload_document_error_format')}
                    icon={'frown'}
                    type={'error'}
                />, {
                    position: toast.POSITION.BOTTOM_LEFT,
                    className: 'normal',
                    bodyClassName: 'notification-body grow-font-size',
                    progressClassName: 'error-custom-progress-bar'
                }
            );
            segmentTrack(EVENT_IMPORT_FILE_ERROR, { fileSizeInBytes: files[0]?.size, fileFormat });
            setTimeout(() => { // File takes around 0.5 sec to appear
                setIsLoadingImg(false);
            }, 500);
        }
    };

    useEffect(() => { // Enable save button, cancel button, and tabs when needed
        const isNotEmptyTitle = newDocument.title.trim() !== initialDocument.title;
        const isNotEmptyFile = newDocument.url !== initialDocument.url;
        const enableSave = isNotEmptyTitle && isNotEmptyFile && !Object.values(newDocument).find(conf => conf === 'error_field');
        const enableCancel = deepNotEqual(initialDocument, newDocument) || Object.values(newDocument).find(conf => conf === 'error_field');

        setEnableSaveButton(enableSave);
        setEnableCancelButton(enableCancel);
        setDisableTabs(enableSave || enableCancel);
    }, [newDocument]);

    return (
        <div className={'configuration-page-content configuration-page-content-at-the-front'}>
            <Tooltip placement={'right'} classes={tooltipStyle} title={disableTabs ? t('common:switch_button_mode_eye_disabled') : ''}>
                <div className={disableTabs && 'disabled-button'}>
                    <Button className={'go-back-button'} disabled={disableTabs} onClick={() => props.goBackToDocumentsListView()}>
                        <Icon componentName={'Icon'} type={'leftArrow'} />
                        <span>{t('common:back')}</span>
                    </Button>
                </div>
            </Tooltip>
            <div className={'edit-document-page'}>
                <div className={'info-section'}>
                    <span>💡</span>
                    {t('documents:choose_document_and_qrcodes')}
                </div>
                <div className={classNames(['document-preview-and-inputs', { 'document-img-is-loading': isLoadingImg }])}>
                    <div className={'document-edit-file-img-wrapper'} onClick={() => { !userIsChoosingFile && newDocument.url && !isLoadingImg && props.setPreviewedDocument(newDocument); }}>
                        <div
                            className={classNames(['document-card-content-overlay', { 'preview-button-wrapper': newDocument.url && displayEditFileButton }, { 'add-file-button-wrapper': !newDocument.url }])}
                            onMouseOver={() => setDisplayEditFileButton(true)}
                            onMouseLeave={() => setDisplayEditFileButton(false)}
                        >
                            {newDocument.url && displayEditFileButton && (
                                <Button
                                    className={'upload-file-button'}
                                    component="label" // For the upload component to work
                                    htmlFor={'hidden-input'}
                                    onMouseOver={() => setUserIsChoosingFile(true)}
                                    onMouseLeave={() => setUserIsChoosingFile(false)}
                                >
                                    <Icon type={'faPen'} />
                                    <span>{t('custom:edit_default')}</span>

                                </Button>
                            )}
                            <input
                                id={'hidden-input'}
                                type="file"
                                accept={getDocumentInputFileAcceptFormats()}
                                onChange={e => { uploadImage(e.target.files); }}
                                hidden
                            />
                        </div>
                        {isLoadingImg && <CircularProgress />}
                        {newDocument.url
                            ? <img alt="file-preview" src={getInputFileUrlForThumbnail(newDocument.url, newDocument.type)} />
                            : !isLoadingImg && (
                                <div className={'no-file-yet-wrapper'}>
                                    <Button
                                        className={'upload-file-button'}
                                        component="label" // For the upload component to work
                                        htmlFor={'hidden-input'}
                                    >
                                        <Icon type={'attachment'} />
                                        <p>{t('documents:choose_document')}</p>
                                    </Button>
                                </div>
                            )
                        }
                    </div>
                    <div className={'inputs'}>
                        <Component
                            title={t('documents:document_name')}
                            componentName={'EditableField'}
                            renderValue={'Input'}
                            required
                            props={{
                                value: newDocument.title,
                                changeInputValue: newDocument.title,
                                required: true,
                                rules: ['maxLength-40', 'selectNotEmpty'],
                                onChange: value => setNewDocument({ ...newDocument, title: value })
                            }}
                        />
                        <Component
                            title={t('common:description')}
                            componentName={'EditableField'}
                            renderValue={'Input'}
                            props={{
                                value: newDocument.description,
                                changeInputValue: newDocument.description,
                                rules: ['maxLength-80'],
                                onChange: value => setNewDocument({ ...newDocument, description: value })
                            }}
                        />
                        <div className={'accessibility-title'}>{t('documents:visibility')}</div>
                        <div className={'second-main-color-switch switch-with-one-text'}>
                            <Switch
                                checked={!newDocument.needs_authentication}
                                onChange={e => setNewDocument({ ...newDocument, needs_authentication: !e.target.checked })}
                            />
                            <span>{t('documents:accessible_with_account')}</span>
                        </div>
                    </div>
                </div>
                <DevicesTransferList
                    hiddenSelectedDevicesIds={[]}
                    devices_ids={newDocument.currently_assigned_device_ids} // QR Codes are filtered in DevicesTransferList component
                    changeState={values => setNewDocument({ ...newDocument, currently_assigned_device_ids: values.devices_ids })}
                />
            </div>
            <div className={'configuration-menu-footer'}>
                <Component
                    componentName={'Button'}
                    className={'custom-cancel-button'}
                    onClick={() => setNewDocument(initialDocument)}
                    text={t('common:cancel')}
                    disabled={!enableCancelButton}
                />
                <Component
                    componentName={'Button'}
                    className={'custom-save-button'}
                    onClick={() => {
                        dispatch(createDocument(newDocument)).then(() => {
                            props.goBackToDocumentsListView();
                            setDisableTabs(false);
                        });
                    }}
                    text={t('common:save')}
                    disabled={!enableSaveButton}
                />
            </div>
        </div>
    );
};

export default CreateQRCodesDocument;

import React from 'react';
import './InstanceEvent.scss';
import classnames from 'classnames';
// Helper
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import { getHoursAndMinutesFromDateWithoutOffset } from '../../../layout/helper';
import { getCalendarInstanceSizeClassName, getCalendarTitleNbLinesClassName } from '../../helper';

const InstanceEvent = props => {
    const event = props.event;
    const resizedEventDirection = props.event.resizedDirection;
    const resizedEventAction = props.event.action;

    // if the event is being resized (downwardly since upwardly is impossible), the start date of the event doesn't change
    const eventStart = resizedEventDirection ? moment(event.resource?.started_at).toDate() : event.start;
    const eventEnd = event.end;

    const eventDate = `${getHoursAndMinutesFromDateWithoutOffset(eventStart, 'h', true)} - ${getHoursAndMinutesFromDateWithoutOffset(eventEnd, 'h', true)}`;
    const useStyles = makeStyles(() => ({
        tooltip: {
            '&::before': {
                marginLeft: '35px'
            }
        }
    }));
    const classes = useStyles();
    const tooltip = `${eventDate} : ${event.title} `;

    return (
        <Tooltip
            arrow={false}
            classes={{ tooltip: classes.tooltip }}
            placement={'top'}
            title={resizedEventAction ? '' : tooltip}
        >
            <div className="instance-event-content">
                <div className={classnames('event-title', getCalendarTitleNbLinesClassName(eventEnd, eventStart))}>{event.title}</div>
                {getCalendarInstanceSizeClassName(eventEnd, eventStart, event.allDay) !== 'shortest-instance' && (
                    <div className="event-date">
                        {eventDate}
                    </div>
                )}
            </div>
        </Tooltip>
    );
};
export default InstanceEvent;

// Libs
import React from 'react';

const SubBar = () => {

    return (
        <div className={'subbar'}>
            <div>Button 1</div>
        </div>
    );
};

export default SubBar;

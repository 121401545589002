// Lib
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DropzoneArea } from 'material-ui-dropzone';
import { isEmpty, isEqual, filter, isNil, omit, sortBy } from 'lodash';
import { isMobile } from 'react-device-detect';
// Components
import UserAvatar from '../components/UserAvatar';
// Context
import { useModal } from '../../modal/ModalContext';
import { useComponentsPool } from '../../../ComponentsPool';
import { useFootBar } from '../../footbar/FootBarContext';
// Actions
import { sendPicture, editUser, editUserProfileErrorNotif } from '../actions';
import createLoadingSelector from '../../layout/actions';
// SCSS
import '../styles/_edit_profile_popup.scss';
// Helper
import { isEmail } from '../helper';

const loadingSelector = createLoadingSelector(['EDIT_USER', 'UPLOAD_AVATAR']);

export default () => {
    const { t } = useTranslation();
    const { Component } = useComponentsPool();
    const modal = useModal();
    const footBar = useFootBar();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const users = useSelector(state => state.users);
    const core = useSelector(state => state.core);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const currentUser = users.currentUser;
    const [picture, setPicture] = useState(currentUser?.profile_picture_url);
    const [user, setUser] = useState({
        first_name: currentUser?.first_name,
        last_name: currentUser?.last_name,
        email: currentUser?.email,
        telephone: currentUser?.telephone ?? '',
        old_password: '',
        new_password: '',
        subscriptions: currentUser.subscriptions
    });
    const [editedFields, setEditedFields] = useState({})
    const isFieldValueValid = (value) => value !== 'error_field' && !isEmpty(value);

    const resetFields = () => {
        setUser(prevState => ({ ...prevState, old_password: '', new_password: '' }));
    };

    const canSubmitProfileForm = (() => {
        const hasEditedFields = !isEmpty(editedFields);
        const hasFieldError = Object.keys(user).some((key) => user[key] === 'error_field');
        const hasPasswordFieldsError =
            (
                (isEmpty(user.old_password) || isEmpty(user.new_password)) &&
                (!isEmpty(user.old_password) || !isEmpty(user.new_password))
            ) ||
            (!isEmpty(user.old_password) && !isEmpty(user.new_password) && user.old_password === user.new_password);

        return hasEditedFields && !hasFieldError && !hasPasswordFieldsError;
    })();

    const updateEditedFields = (index, value) => {
        if (
            isNil(index) ||
            isNil(value) ||
            (!currentUser.hasOwnProperty(index) && !(index === 'old_password' || index === 'new_password')) ||
            value === 'error_field'
        ) {
            console.error(
                `index = ${index}, value = ${value} OR current user has this field = ${currentUser.hasOwnProperty(
                    index
                )} `
            );
            const fieldToRemove = index.includes('password') ? ['old_password', 'new_password'] : index;
            const updatedEditedFields = omit(editedFields, fieldToRemove);
            setEditedFields(updatedEditedFields);
            return;
        }
        if (index === 'old_password' || index === 'new_password') {
            const newPassword = index === 'new_password' ? value : user.new_password;
            const oldPassword = index === 'old_password' ? value : user.old_password;
            if (value === '' || !isFieldValueValid(newPassword) || !isFieldValueValid(oldPassword) || newPassword === oldPassword) {
                const updatedEditedFields = omit(editedFields, ['new_password', 'old_password']);
                setEditedFields(updatedEditedFields);
                return;
            }
            setEditedFields({ ...editedFields, new_password: newPassword, old_password: oldPassword });
            return;
        }
        if (index === 'subscriptions') {
            // Noting changed = same data as the beginning => in case of no subscriptions
            if (isEmpty(value) && isEqual(value, currentUser[index])) {
                const updatedEditedFields = omit(editedFields, index);
                setEditedFields(updatedEditedFields);
                return;
            }
            // All subscriptions are deactivated
            if (isEmpty(value)) {
                setEditedFields({ ...editedFields, [index]: value });
                return;
            }
            // Subscriptions comparison
            const areSubscriptionsModified = value.some((newSub) => {
                const defaultSubIndex = currentUser[index].findIndex((currentUserSub) =>
                    isEqual(currentUserSub.strategies, newSub.strategies)
                );
                if (defaultSubIndex === -1) return true;
                const haveSameTypes = isEqual(sortBy(currentUser[index][defaultSubIndex].types), sortBy(newSub.types));
                if (!haveSameTypes) return true;
            });
            // if modified
            if (areSubscriptionsModified) {
                setEditedFields({ ...editedFields, [index]: value });
                return;
            }
            // remove field if nothing modified
            const updatedEditedFields = omit(editedFields, index);
            setEditedFields(updatedEditedFields);
            return;
        }
        // New value is different than default value ( init value )
        if (!isEqual(currentUser[index], value)) {
            setEditedFields({ ...editedFields, [index]: value });
        } else {
            const updatedEditedFields = omit(editedFields, index);
            setEditedFields(updatedEditedFields);
        }
    };

    const updateUser = async () => {
        const isNewPicture = picture !== currentUser?.profile_picture_url;
        try {
            await dispatch(editUser(currentUser.id, editedFields, 'EDIT_USER_PROFILE'));
        } catch (e) {
            console.error(e);
            dispatch(editUserProfileErrorNotif());
        }
        if (isNewPicture) {
            dispatch(sendPicture(picture));
        }
        resetFields();
        modal.close();
        footBar.close();
    };


    const updateUserProfile = (index, value) => {
        updateEditedFields(index, value);
        setUser(prev => ({ ...prev, [index]: value }))
    };

    const head = <span>{t('common:edit_my_profile')}</span>;

    const content = (
        <div className={isMobile ? 'edit-profile-wrapper-mobile' : 'edit-profile-wrapper'}>
            <div className={'content'}>
                <div className={'edit-profile-picture-container'}>
                    <div className={'input-zone'}>
                        <UserAvatar user={currentUser} picture={picture} size="100" />
                        <div className={'dropZone'}>
                            <DropzoneArea
                                filesLimit={999}
                                onChange={files => setPicture(files[files.length - 1])}
                                maxFileSize={core.settings.profilPictureMaxSize}
                                acceptedFiles={['image/*']}
                                dropzoneText={''}
                                clearOnUnmount
                                showPreviews={false}
                                showPreviewsInDropzone={false}
                                showAlerts={false}
                                showFileNamesInPreview={false}
                            />
                        </div>
                    </div>
                    <span>{t('common:upload_picture')}</span>
                    {picture !== '' && (
                        <Component
                            componentName={'Button'}
                            variant={'outlined'}
                            onClick={() => {
                                setPicture('');
                            }}
                            text={t('common:remove_profile_picture')}
                        />
                    )}
                </div>
                <div className={'content modal-edit-content-user form'}>
                    <div className={'double-input'}>
                        <Component
                            componentName={'Input'}
                            required
                            rules={['isAcceptableString', 'maxLength-30']}
                            label={'users:firstname'}
                            value={user?.first_name}
                            onChange={value => updateUserProfile('first_name', value)}
                        />
                        <Component
                            componentName={'Input'}
                            required
                            rules={['isAcceptableString', 'maxLength-150']}
                            label={'users:lastname'}
                            value={user?.last_name}
                            onChange={value => updateUserProfile('last_name', value)}
                        />
                    </div>
                    <Component
                        componentName={'Input'}
                        disabled={!isEmail(currentUser)}
                        required
                        rules={['isEmail', 'checkIfUserAlreadyExists', 'maxLength-254']}
                        list={filter(users.list, u => u?.username !== user?.email)}
                        label={isEmail(user) ? 'users:email_address' : 'users:user_identification'}
                        value={user?.email}
                        onChange={value => updateUserProfile('email', value.trim())}
                    />
                    <Component
                        componentName={'Input'}
                        required={workspaceSettings.mandatory_telephone}
                        rules={['isPhoneNumber']}
                        label={'common:phone_number'}
                        value={user?.telephone}
                        onChange={value => updateUserProfile('telephone', value)}
                    />
                    { isEmpty(currentUser.social_id) && isEmail(currentUser) && (
                        <div className={'double-input'}>
                            <Component
                                componentName={'Input'}
                                type={'password'}
                                label={'users:oldpassword'}
                                onChange={value => updateUserProfile('old_password', value)}
                            />
                            <Component
                                componentName={'Input'}
                                rules={['isPassword', 'maxLength-128']}
                                type={'password'}
                                label={'users:newpassword'}
                                onChange={value => updateUserProfile('new_password', value)}
                            />
                        </div>
                    )
                    }
                </div>
            </div>
            <Component
                componentName={'UserAlerts'}
                user={currentUser}
                userProfilePopupFormState={user}
                updateState={value => updateUserProfile('subscriptions', value)}
                dontHaveEditionMode
                isSettingAlertsForHimself
            />
        </div>
    );

    return (
        <Component
            componentName={'Modal'}
            contentWidth={!isMobile && '80%'}
            contentHeight={isMobile && '80%'}
            classNames={'editUser'}
            head={head}
            content={content}
            loading={isLoading}
            submitButton
            submitButtonLabel={'common:save'}
            onHandleSubmit={updateUser}
            onSubmitDisabled={!canSubmitProfileForm && picture === currentUser?.profile_picture_url}
        />
    );
};

import { Strategy } from 'workbox-strategies';


function extractAndValidateURL(url) {
    if (url.includes('/workspace/?hardware_id=')){
        // Expression régulière pour extraire la partie souhaitée
        const regex = /\/workspace\/\?hardware_id=([a-zA-Z0-9-_]+)/;
        // Utiliser match pour extraire la partie correspondante
        const match = url.match(regex);
        // Vérifier si la correspondance a réussi et renvoyer le résultat
        if (match && match[0]) {
            return match[0];
        }
    } else if (url.includes('/devices/get-device-by-hardware-id/')) {
        // Expression régulière pour extraire la partie souhaitée
        const regex = /\/devices\/get-device-by-hardware-id\/([a-zA-Z0-9-_]+)/;
        // Utiliser match pour extraire la partie correspondante
        const match = url.match(regex);
        // Vérifier si la correspondance a réussi et renvoyer le résultat
        if (match && match[0]) {
            return match[0];
        }
    }
    // Si aucun match n'est trouvé ou si le format n'est pas celui attendu, lancer une erreur
    return null;
}
async function findResponseInCache(partialUrl) {
    const cache = await caches.open('logged-in-cached-data');
    const requests = await cache.keys(); // Obtenez toutes les requêtes en cache

    // Créer un tableau pour stocker les paires request/response avec leurs dates
    const requestWithDates = [];

    for (const request of requests) {
        if (request.url.includes(partialUrl)) {
            const response = await cache.match(request);
            if (response) {
                const dateHeader = response.headers.get('X-Cache-Date');
                requestWithDates.push({ request, response, date: new Date(dateHeader) });
            }
        }
    }

    // Trier par date, les plus récentes d'abord
    requestWithDates.sort((a, b) => b.date - a.date);

    // Retourner la première réponse triée si disponible
    return requestWithDates.length > 0 ? requestWithDates[0].response : null;
}


export async function fetchRequestAndPutInCache(handler, request) {
    try {
        const networkResponse = await handler.fetch(request);
        // Clone the response because the response is a stream that can only be read once
        const responseToCache = networkResponse.clone();
        // Add a custom header with the current date/time
        const customHeaders = new Headers(responseToCache.headers);
        customHeaders.append('X-Cache-Date', new Date().toISOString());
        if (responseToCache.status >= 200 && responseToCache.status < 210) {
            const responseWithCustomHeader = new Response(responseToCache.body, {
                status: responseToCache.status,
                statusText: responseToCache.statusText,
                headers: customHeaders
            });
            // Cache modified response
            await handler.cachePut(request, responseWithCustomHeader);
        }
        return networkResponse;
    } catch (error) {
        let cacheResponse = null;
        if (request.url.includes('/workspace/?hardware_id=')) {
            // Si la requête pour l'id spécifié n'est pas dans le cache
            // => renvoie la réponse du dernier QR Code scanné
            // Ne fonctionne pas si l'utilisateur scanne des QR Codes de plusieurs workspaces différents
            cacheResponse = await findResponseInCache('/workspace/?hardware_id=');
        } if (request.url.includes('/devices/get-device-by-hardware-id/')) {
            // Si la requête a été créé via la stratégie QRCodesPreloadCacheStrategy
            // Alors il faut passer par findResponseInCache => cacheMatch renverra false
            const subUrl = extractAndValidateURL(request.url);
            if (subUrl) {
                cacheResponse = await findResponseInCache(subUrl);
            }
        }
        return cacheResponse || error;
    }
}

/**
 * Stratégie Custom Workbox pour la gestion des requêtes avec validation de la date d'expiration du cache.
 *
 * Contexte:
 * Cette stratégie est utilisée pour les requêtes nécessitant une réponse fiable et à jour, en vérifiant la date d'expiration des éléments en cache.
 *
 * Processus:
 * 1. Vérifier si une réponse pour cette requête existe dans le cache local, avec les conditions suivantes :
 *    - La réponse doit être de moins de 24 heures.
 *    - La réponse doit être au bon format.
 * 2. Si une réponse valide est trouvée dans le cache, elle est utilisée directement.
 * 3. Sinon, effectuer un appel réseau pour obtenir une réponse fraîche et la mettre en cache.
 *
 * Gestion de la réponse:
 * - Si la réponse provient du cache, vérifier son format :
 *   a. Pour les requêtes vers '/workspace/?hardware_id=', vérifier que le champ 'workspace' est présent dans la réponse JSON.
 *   b. Pour les requêtes vers '/devices/get-device-by-hardware-id/', vérifier que le champ 'device_id' est présent dans la réponse JSON.
 * - Si le format n'est pas correct, effectuer un appel réseau et mettre à jour le cache avec la réponse fraîche.
 * - Si la réponse provient du réseau, elle est mise en cache avec une date de cache actuelle.
 * - Vérifier la date de cache :
 *   a. Si la date de cache est supérieure à 24 heures, effectuer un appel réseau pour obtenir une nouvelle réponse et la mettre en cache.
 *
 * Gestion des erreurs:
 * - En cas d'erreur durant le processus de récupération ou de mise en cache, renvoyer une réponse d'erreur appropriée à l'utilisateur.
 */
class CacheFirstBeforeExpiryDate extends Strategy {
    async _handle(request, handler) {
        // En 1er je récupère la réponse du cache
        // => enregistré depuis la requête elle même OU
        // => enregistré depuis la requête /preload_qrcodes_cache
        const cacheResponse = await findResponseInCache(extractAndValidateURL(request.url))
            || await handler.cacheMatch(request);

        if (cacheResponse) {
            const clonedResponse = cacheResponse.clone();
            const json = await clonedResponse.json();
            // Je vérifie si la réponse récupéré depuis le cache a le bon format => sinon je fetch
            if (
                request.url.includes('/workspace/?hardware_id=') && !json.workspace
                || request.url.includes('/devices/get-device-by-hardware-id/') && !json.device_id
            ) {
                // If the response in the cache doesn't have the right format => do a fetch
                return fetchRequestAndPutInCache(handler, request);
            }
        } else {
            // If there is no response in the cache => perform a fetch
            return fetchRequestAndPutInCache(handler, request);
        }

        // If the answer is in the cache, check the caching date
        const cachedDate = cacheResponse?.headers.get('X-Cache-Date');
        if (cachedDate) {
            const now = new Date();
            const cachedTime = new Date(cachedDate);
            const age = (now - cachedTime) / (1000 * 60 * 60); // Conversion en h
            if (age > 24) {
                // If the cache was retrieved more than 24 hours ago => do a fetch
                return fetchRequestAndPutInCache(handler, request);
            }
        } else {
            // If the cache save date is not available => perform a fetch
            return fetchRequestAndPutInCache(handler, request);
        }
        /* If the response in the cache
            - has the correct format and
            - has not exceeded 24h and
            - has no cached date
        => return it */
        return cacheResponse;
    }
}

export { CacheFirstBeforeExpiryDate };
import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import { loginByCryptr } from '../../authentication/actions';
import { useComponentsPool } from '../../../ComponentsPool';
import useRouter from '../../../utils/hook/useRouter';
import PageHeader from '../../authentication/components/PageHeader';

const SsoRedirection = () => {
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const router = useRouter();
    const { Component } = useComponentsPool();
    const location = useLocation();
    const [isError, setIsError] = useState(false);


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
        if (code) {
            dispatch(loginByCryptr(code))
                .catch(() => {
                    setIsError(true);
                });
        } else {
            setIsError(true);
        }
    }, []);

    if (isError) {
        return (
            <div className={'not-found'}>
                <PageHeader
                    text={t('notifications:get_sso_redirection_url_error')}
                    subText={t('common:contact_your_admin')}
                />
                <div className="redirect-button">
                    <Button
                        onClick={() => {
                            router.push('/login');
                        }}
                    >
                        <span>{t('common:go_back_to_home_page')}</span>
                    </Button>
                </div>
            </div>
        );
    }
    return <Component componentName={'AppLoader'} />;
};

export default SsoRedirection;

import jwtDecode from 'jwt-decode';
import update from 'immutability-helper';
import {
    find, findIndex, cloneDeep, difference, remove
} from 'lodash';

const initialState = () => ({
    list: [],
    currentUser: {},
    userShownInDetailsPopup: {},
    invitedUsersTableColumns: [{
        name: 'name',
        formTitle: true,
        title: 'users:invitations_sent',
        component: 'UserInfo',
        weight: 100
    }, {
        name: 'expiration',
        formTitle: false,
        title: 'users:expiration',
        component: 'UserExpiration',
        weight: 200
    }, {
        name: 'accessibility',
        className: 'show-on-opened',
        isCapitalized: true,
        title: 'common:accessibility',
        component: 'UserTableGroupsField',
        weight: 300,
        props: {
            multiple: true
        }
    }],
    ncUsersTableColumns: [{
        name: 'name',
        className: 'show-on-opened',
        formTitle: true,
        title: 'users:user_non_configured',
        component: 'UserTableNameField',
        weight: 100
    }],
    usersTableColumns: [{
        name: 'name',
        formTitle: true,
        title: 'users:users',
        component: 'UserInfo',
        weight: 100
    }, {
        name: 'accessibility',
        title: 'common:accessibility',
        component: 'UserTableGroupsField',
        weight: 300
    }, {
        name: 'contact',
        formTitle: false,
        title: 'users:contact',
        component: 'UserContact',
        weight: 200
    }],
    editableFields: [{
        name: 'UserCompany',
        title: 'common:company',
        renderValue: 'UserSimpleString',
        editionComponent: 'Input',
        props: {
            rules: ['maxLength-64'],
            value: ['custom_field', 'company'],
            label: ''
        },
        weight: 700
    }, {
        name: 'UserFirstname',
        title: 'users:firstname',
        renderValue: 'UserSimpleString',
        editionComponent: 'Input',
        disabledInput: true,
        props: {
            required: true,
            value: 'first_name',
            rules: ['isAcceptableString'],
            label: ''
        },
        weight: 100
    }, {
        name: 'UserLastname',
        title: 'users:lastname',
        renderValue: 'UserSimpleString',
        editionComponent: 'Input',
        disabledInput: true,
        props: {
            required: true,
            value: 'last_name',
            label: ''
        },
        weight: 200
    }, {
        name: 'UserEmail',
        title: 'common:email',
        renderValue: 'UserSimpleString',
        editionComponent: 'Input',
        disabledInput: true,
        props: {
            required: true,
            value: 'email',
            rules: ['isEmail'],
            label: ''
        },
        weight: 300
    }, {
        name: 'UserPhoneNumber',
        title: 'common:phone_number',
        renderValue: 'UserSimpleString',
        editionComponent: 'Input',
        props: {
            value: 'telephone',
            rules: ['isPhoneNumber'],
            label: ''
        },
        weight: 600
    }],
    footbarButtons: [
        { component: 'DeleteUserButton', weight: 200 },
        { component: 'DeleteInviteButton', weight: 200 },
        { component: 'ResendEmailButton', weight: 400 }
    ],
    detailPopupParts: [
        { width: '50%', component: 'UserDetailsPopupLeftPart' },
        { width: '50%', component: 'UserDetailsPopupRightPart' }
    ],
    usersMergedOnTable: false,
    playingSound: false,
    is_loggable: false
});

let newState;
let groupMemberShipInvite = [];
let index = 0;
let group_membership_index = 0;
let detailUser;
let newList;
let deleteIndex;
const skillsAtTheEnd = ['Autre', 'Autres', 'Divers', 'Autres demandes', 'Autre demande'];

const userReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'ADD_GROUP_TO_USER_FULFILLED':
            return update(state, {
                list: {
                    $apply: b => b.map(item => {
                        const userAtUpdate = find(action.payload, user => user.id === item.id);
                        if (userAtUpdate === undefined) return item;
                        return { ...item, ...userAtUpdate };
                    })
                }
            });
        case 'REMOVE_GROUP_FULFILLED':
        case 'REMOVE_GROUPS_FULFILLED':
            return update(state, {
                list: {
                    $apply: b => b.map(item => ({
                        ...item,
                        group_memberships: difference(item.group_memberships, action.payload)
                    }))
                }
            });
        case 'REMOVE_GROUP_FROM_USER_FULFILLED': // group_memberships
            index = findIndex(state.list, item => item.id === action.payload.id);
            group_membership_index = findIndex(
                state.list[index].group_memberships,
                group_membership => group_membership.user_group_membership_id === action.payload.group_id
            );
            return update(state, {
                list: { [index]: { group_memberships: { $splice: [[group_membership_index, 1]] } } }
            });
        case 'DELETE_INVITE_FULFILLED':
        case 'DELETE_INVITES_FULFILLED':
        case 'DELETE_USER_FULFILLED':
        case 'DELETE_USERS_FULFILLED':
            newList = cloneDeep(state.list);
            action.payload?.forEach(user_id => {
                newList.splice(findIndex(newList, item => item.id === user_id), 1);
            });
            return update(state, { list: { $set: newList } });
        case 'CHANGE_ROLE_FULFILLED': // group_memberships
            index = findIndex(state.list, item => item.id === action.payload.id);
            return update(state, {
                list: {
                    [index]: {
                        group_memberships: { $set: action.payload.group_memberships },
                        workspace_role: { $set: action.payload.workspace_role }
                    }
                }
            });
        case 'UPLOAD_AVATAR_PENDING':
            return update(state, { isLoading: { $set: true } });
        case 'UPLOAD_AVATAR_FAILED':
        case 'UPLOAD_AVATAR_REJECTED':
            return update(state, { isLoading: { $set: false } });
        case 'UPLOAD_AVATAR_FULFILLED':
            index = state.list.findIndex(user => user.id === state.currentUser.id);
            return update(state, {
                currentUser: { profile_picture_url: { $set: action.payload.url_picture } },
                list: { [index]: { profile_picture_url: { $set: action.payload.url_picture } } }
            });
        case 'SET_GROUP_RIGHTS':
            return update(state, {
                groupRights: { $set: action.payload }, // TODO Deprecated in future version
                currentUser: { group_rights: { $set: action.payload } }
            });
        case 'SET_WORKSPACE_RIGHTS':
            return update(state, { currentUser: { workspace_rights: { $set: action.payload } } });
        case 'GET_USER_LIST_FULFILLED':
            newState = update(state, { list: { $push: action.payload.results } });
            // Réordonner le champ group_memberships de chaque élément de la liste
            newState.list = newState.list.map(user => {
                // Votre logique de tri ici
                user.group_memberships.sort((a, b) => {
                    const indexA = skillsAtTheEnd.indexOf(a.group.group_name);
                    const indexB = skillsAtTheEnd.indexOf(b.group.group_name);

                    // Si le group_name est dans la liste, placez-le à la fin, sinon triez par group_name
                    if (indexA !== -1 && indexB !== -1) {
                        return indexA - indexB;
                    } else if (indexA !== -1) {
                        return 1; // Placez A à la fin
                    } else if (indexB !== -1) {
                        return -1; // Placez B à la fin
                    } else {
                        if (a.group.group_name < b.group.group_name) return -1;
                        if (a.group.group_name > b.group.group_name) return 1;
                    }
                });

                return user;
            });

            return newState;
        case 'GET_USER_FULFILLED':
            return update(state, { userShownInDetailsPopup: { $set: action.payload } });
        case 'GET_CURRENT_USER_FULFILLED':
            newState = update(state, { currentUser: { $set: action.payload } });
            if (newState.currentUser && newState.currentUser.group_memberships) {
                // Réordonner le champ group_memberships de currentUser
                newState.currentUser.group_memberships.sort((a, b) => {
                    const indexA = skillsAtTheEnd.indexOf(a.group.group_name);
                    const indexB = skillsAtTheEnd.indexOf(b.group.group_name);

                    // Si le group_name est dans la liste, placez-le à la fin, sinon triez par group_name
                    if (indexA !== -1 && indexB !== -1) {
                        return indexA - indexB;
                    } else if (indexA !== -1) {
                        return 1; // Placez A à la fin
                    } else if (indexB !== -1) {
                        return -1; // Placez B à la fin
                    } else {
                        if (a.group.group_name < b.group.group_name) return -1;
                        if (a.group.group_name > b.group.group_name) return 1;
                    }
                });
            }

            return newState;
        case 'GET_CURRENT_USER_HMAC_FULFILLED':
            return update(state, { currentUserHMAC: { $set: action.payload } });
        case 'GET_CURRENT_USER_HMAC_FAILED':
        case 'GET_CURRENT_USER_HMAC_REJECTED':
            return update(state, { currentUserHMAC: { $set: '' } });
        case 'INVITE_USER_FULFILLED':
            groupMemberShipInvite = [];
            groupMemberShipInvite[0] = { ...action.payload };
            groupMemberShipInvite[0].group_memberships = action.payload.invite_group_memberships;
            return update(state, { list: { $push: groupMemberShipInvite }, selectedUsersToConfigure: { $set: [] } });
        case 'GET_INVITATIONS_LIST_FULFILLED':
        case 'INVITE_USERS_FROM_CSV_FULFILLED':
            groupMemberShipInvite = action.payload.map(user => ({ ...user, group_memberships: user.invite_group_memberships })); // eslint-disable-line
            return update(state, { list: { $push: groupMemberShipInvite } });
        case 'EDIT_USER_FULFILLED':
            index = findIndex(state.list, item => item.id === action.payload.id);
            if (state.userShownInDetailsPopup.id === action.payload.id) {
                if (state.currentUser.id === action.payload.id) {
                    return update(state, {
                        currentUser: { $merge: action.payload },
                        list: { [index]: { $set: action.payload } },
                        userShownInDetailsPopup: { $set: { ...state.userShownInDetailsPopup, ...action.payload } }
                    });
                }
                return update(state, {
                    list: { [index]: { $set: action.payload } },
                    userShownInDetailsPopup: { $set: { ...state.userShownInDetailsPopup, ...action.payload } }
                });
            }
            if (state.currentUser.id === action.payload.id) {
                return update(state, {
                    list: { [index]: { $set: action.payload } },
                    currentUser: { $merge: action.payload }
                });
            }
            return update(state, { list: { [index]: { $set: action.payload } } });
        case 'EDIT_USER_PROFILE_FULFILLED':
            index = findIndex(state.list, item => item.id === action.payload.id);
            if (state.currentUser.id === action.payload.id) {
                return update(state, {
                    list: { [index]: { $set: action.payload } },
                    currentUser: { $merge: action.payload }
                });
            }
            return update(state, { list: { [index]: { $set: action.payload } } });
        case 'LOGOUT_FULFILLED':
            newState = initialState();
            newState.is_loggable = action.payload.allowLogin;
            return newState;
        case 'GET_NFC_LIST_FULFILLED':
            // If the workspace has nfc tags we add a nfc tag column on the user table and an nfc field on the user popup
            if (action.payload.length > 0) {
                const nfcColumnIdx = findIndex(state.usersTableColumns, field => field.name === 'nfc');
                const nfcTagColumn = {
                    name: 'nfc',
                    formTitle: false,
                    title: 'common:nfc_tags',
                    component: 'UserNfcTag',
                    weight: 400
                };
                const nfcPopupFieldIdx = findIndex(state.editableFields, field => field.name === 'UserNfc');
                const nfcTagPopupField = {
                    name: 'UserNfc',
                    title: 'common:nfc_tag',
                    renderValue: 'UserSimpleString',
                    editionComponent: 'SelectNfcTag',
                    props: {
                        rules: ['maxLength-64'],
                        value: 'nfc_tag',
                        label: ''
                    },
                    weight: 800
                };
                if (nfcColumnIdx === -1 && nfcPopupFieldIdx === -1) {
                    return update(state,
                        { usersTableColumns: { $push: [nfcTagColumn] }, editableFields: { $push: [nfcTagPopupField] } }
                    );
                }
            }
            return state;
        case 'SET_USER_DETAIL':
            detailUser = find(state.list, { id: action.payload });
            return update(state, { userShownInDetailsPopup: { $merge: detailUser } });
        case 'GET_USER_SUMMARY_FULFILLED':
            return update(state, {
                userShownInDetailsPopup: {
                    summary: { $set: action.payload.data.summary },
                    totalSummary: { $set: action.payload.data.count }
                }
            });
        case 'CHANGE_USER_DETAIL_POPUP_PARTS':
            return update(state, { detailPopupParts: { $set: action.payload } });
        case 'ADD_INVITE_TABLE_FIELD':
            return update(state, { invitedUsersTableColumns: { $push: [action.payload] } });
        case 'REMOVE_INVITE_TABLE_FIELD':
            deleteIndex = state.invitedUsersTableColumns.findIndex(d => d.name === action.payload);
            if (deleteIndex > -1) {
                return update(state, { invitedUsersTableColumns: { $splice: [[deleteIndex, 1]] } });
            }
            return state;
        case 'REMOVE_USER_TABLE_COLUMN':
            index = state.invitedUsersTableColumns.findIndex(d => d.name === action.payload);
            deleteIndex = state.ncUsersTableColumns.findIndex(d => d.name === action.payload);
            newList = state.usersTableColumns.findIndex(d => d.name === action.payload);
            if (deleteIndex > -1 && index > -1 && newList > -1) {
                return update(state, {
                    invitedUsersTableColumns: { $splice: [[index, 1]] },
                    ncUsersTableColumns: { $splice: [[deleteIndex, 1]] },
                    usersTableColumns: { $splice: [[newList, 1]] }
                });
            }
            if (deleteIndex > -1) {
                return update(state, { ncUsersTableColumns: { $splice: [[deleteIndex, 1]] } });
            }
            if (index > -1) {
                return update(state, { invitedUsersTableColumns: { $splice: [[index, 1]] } });
            }
            if (newList > -1) {
                return update(state, { usersTableColumns: { $splice: [[newList, 1]] } });
            }
            return state;
        case 'RESEND_INVITE_FULFILLED':
            index = findIndex(state.list, item => item.id === action.payload.id);
            return update(state, { list: { [index]: { $set: action.payload } } });
        case 'NEW_WEBSOCKET_MESSAGE':
            if (action.payload.message_type === 'NEW_TICKET'
            && action.payload.message_data.users_play_sound?.includes(state.currentUser.id)) {
                return update(state, {
                    playingSound: { $set: true }
                });
            }
            return state;
        case 'SET_PLAYING_SOUND_OFF':
            return update(state, {
                playingSound: { $set: false }
            });
        case 'IS_LOGGABLE_USER':
            return update(state, { is_loggable: { $set: action.payload }});
        default:
            return state;
    }
};

export default userReducer;

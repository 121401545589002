// Libs
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { Tooltip } from '@material-ui/core';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
// Helper
import { filterTable } from '../../../navbar/helper';

const filterGroup = (filters, list) => filterTable(
    filters,
    list,
    [],
    [],
    ['dashboard', 'group']
);
const [columnExtensions] = useState([
    { columnName: 'location', sortingEnabled: false },
    { columnName: 'device_name', sortingEnabled: false }
]);

const stepColumns = [{
    name: 'location',
    formTitle: true,
    title: '',
    component: 'StepLocation',
    weight: 100
}, {
    name: 'round_device_name',
    formTitle: true,
    title: '',
    component: 'RoundDeviceName',
    weight: 200
}, {
    name: 'round_devices_max_validation_count',
    formTitle: true,
    component: 'RoundDeviceMaxValidationCount',
    weight: 300
}
];

export default () => {
    const [t] = useTranslation();
    const { getComponentFile, Component } = useComponentsPool();
    const round = useSelector(state => state.rounds.roundShownInDetailsPopup);
    const stepsCount = round.round_devices.length ? round.round_devices?.length : 0;
    const stepsValidationCount = round.round_devices_max_validation_count || 1;
    let stepTitle = '';
    if (stepsCount === 1) {
        stepTitle = t('rounds:round_device_count');
    } else if (stepsCount > 1) {
        stepTitle = t('rounds:round_devices_count', { count: stepsCount });
    } else {
        stepTitle = t('rounds:no_devices');
    }
    const prepareColumn = (column, count) => {
        let title = t(column.title, { count });
        if (column.isCapitalized) {
            title = capitalize(title);
        }
        if (column.formTitle) {
            title = (
                <div className={'table-head-title'}>
                    <span>{title}</span>
                </div>
            );
        }
        return {
            round_devices_max_validation_count: `${stepsValidationCount.toString()} ${t('rounds:times')}`,
            name: column.name,
            title,
            component: column.component ? getComponentFile(column.component) : null
        };
    };

    const stepsColumns = stepColumns.map(column => prepareColumn(column, stepColumns.length));

    const getHeightFirstTable = () => {
        let addition = 0;
        addition = addition - 25;
        return addition;
    };
    return (
        <div className={'round-steps-container'}>
            <div className={'round-steps-vizualize-header'}>
                <span className={'steps-count'}>{stepTitle}</span>
                <Tooltip
                    className={'tooltip'}
                    classes={{ tooltip: 'table-tooltip' }}
                    placement={'top'}
                    title={t('rounds:round_steps_explanation')}
                >
                    <div>
                        <Component componentName={'Icon'} type={'info'} />
                    </div>
                </Tooltip>
            </div>
            {
                stepsCount > 0
                    ? (<AutoSizer disableWidth>
                        {({ height }) => (
                            <div style={{ height: height + getHeightFirstTable() }}>
                                <Component
                                    componentName={'Table'}
                                    height={height + getHeightFirstTable()}
                                    open
                                    columns={stepsColumns}
                                    columnExtensions={columnExtensions}
                                    list={filterGroup([], round.round_devices)}
                                    onRowClick={() => {}}
                                />
                            </div>
                        )}
                    </AutoSizer>)
                    : (<div className={'no-data'}>
                        <div className={'no-data-text'}>
                            <span>{t('rounds:no_step')}</span>
                        </div>
                    </div>)
            }
        </div>
    );
};

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default props => {
    const [t] = useTranslation();
    const tickets = useSelector(state => state.tickets);
    const ticket = tickets.ticketShownInDetailsPopup;
    const value = ticket[props.renderValue];

    return (
        <p className={`info-value ${value ? '' : 'undefined'}`}>
            {props.withTranslation ? t(`tickets:${value}`) : value || t('common:undefined')}
        </p>
    );
};

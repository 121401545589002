// Libs
import React from 'react';
import { isEmpty } from 'lodash';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
// Context
import { useComponentsPool } from '../../../ComponentsPool';

const tableTooltip = { tooltip: 'table-tooltip' };

const ChipButton = props => {
    const { Component } = useComponentsPool();
    const maxVisible = props.maxVisible;
    const chipsToDisplay = props.chipsToDisplay;
    const iconType = props.iconType;
    const icon = iconType ? <Component componentName={'Icon'} type={iconType} /> : false;

    const displayedChips = chipToDisplay => {
        if (chipToDisplay?.id) {
            const iconComponent = chipToDisplay.iconType
                ? <Component componentName={'Icon'} type={chipToDisplay.iconType} />
                : icon;
            return (
                <Tooltip
                    key={chipToDisplay.id}
                    classes={tableTooltip}
                    title={(<Component componentName={'Description'} content={chipToDisplay.value} />)}
                >
                    <Chip
                        icon={iconComponent}
                        key={chipToDisplay.id}
                        label={chipToDisplay.value}
                        className={'group-name'}
                    />
                </Tooltip>
            );
        }
        return <></>;
    };

    if (isEmpty(chipsToDisplay)) {
        return <p className={'empty-value'}>{'-'}</p>;
    }

    return (
        <div className={'chip-button-wrapper'}>
            { chipsToDisplay.length <= maxVisible
                ? chipsToDisplay.map(chipToDisplay => displayedChips(chipToDisplay))
                : (
                    <div>
                        { chipsToDisplay.slice(0, maxVisible).map(
                            chipToDisplay => displayedChips(chipToDisplay)
                        )}
                        <div className={'popover-button'}>
                            <Component componentName={'Popover'} buttonLabel={`+${chipsToDisplay.length - maxVisible}`} fabButton>
                                <div className={'user-group-popover'}>
                                    {
                                        chipsToDisplay.slice(maxVisible, chipsToDisplay.length)
                                            .map(chipToDisplay => displayedChips(chipToDisplay))
                                    }
                                </div>
                            </Component>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default ChipButton;


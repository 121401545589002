// Libs
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useModal } from '../../../modal/ModalContext';
// Helper
import { getUsersByGroupId, addNametoUserObject, getUserName } from '../../../users/helper';
import { Tooltip } from '@material-ui/core';

const setUsersByGroup = (groupUsers, users, groups, item) => {
    if (groupUsers) return groupUsers;
    if (!isEmpty(item)) {
        return getUsersByGroupId(users, groups, item.group_id);
    }
    return null;
};

const FollowersAvatarsGrouped = props => {
    const { Component } = useComponentsPool();
    const users = useSelector(state => state.users.list);
    const groups = useSelector(state => state.groups.list);
    const maxDisplayedUsers = 5;
    const [t] = useTranslation();
    const modal = useModal();
    const usersByGroups = setUsersByGroup(props.usersByGroups, users, groups, modal.getItem());

    return useMemo(() => (
        <div className="grouped-avatar">
            <div
                className={'users-preview-wrapper'}
            >
                { usersByGroups ? usersByGroups.slice(0, maxDisplayedUsers).map((user, index) => (
                    <Tooltip
                        placement="top"
                        title={getUserName(user, true)}
                    >
                        <div className="user-preview-wrapper" key={user?.id} style={{ zIndex: maxDisplayedUsers - index }}>
                            <Component
                                componentName={'UserAvatar'}
                                displayEmoji={user.invite_email && props.displayEmoji}
                                picture={user?.profile_picture_url}
                                user={addNametoUserObject(user)}
                                size="35"
                            />
                        </div>
                    </Tooltip>
                )) : (
                    <div className="user-preview-wrapper solo-avatar">
                        <Component
                            componentName={'UserAvatar'}
                            picture={props.user?.profile_picture_url}
                            user={addNametoUserObject(props.user)}
                            size="35"
                        />
                    </div>
                )}
                { usersByGroups && usersByGroups.length > maxDisplayedUsers && (
                    <div className="other-members">
                        <p>{`+${usersByGroups.length - maxDisplayedUsers}`}</p>
                        {!props.shortenText
                        && <p>{`${t('common:member', { count: usersByGroups.length }).toLowerCase()}`}</p>
                        }
                    </div>
                )}
            </div>
        </div>
    ), [usersByGroups]);
};

export default FollowersAvatarsGrouped;

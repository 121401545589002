// /* eslint-disable import/first */
// import React, { useState } from 'react';
// import { action } from '@storybook/addon-actions';
// import { Typography } from '@material-ui/core';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import FormGroup from '@material-ui/core/FormGroup';
// import Input from '../modules/layout/components/Input';
// import Modal from '../modules/modal/Components/Modal';
// import '../modules/layout/styles/_theme.scss';
//
// const head = <span>{'Welcome to IoT Rocket !'}</span>;
// const onHandleSubmit = action('onHandleSubmit');
//
// const customTitle = (
//     <React.Fragment>
//         <span>{'Welcome to IoT Rocket !'}</span>
//         <Typography variant={'subtitle2'}>{'This is a custom title with Typography Component'}</Typography>
//     </React.Fragment>
// );

import update from 'immutability-helper';
import { findIndex, cloneDeep } from 'lodash';
import config from '../../config.json';
import components from '../../components';

const initialState = () => ({
    ready: false,
    resourceLoaded: false,
    loading: false,
    findSetting: {},
    settings: config,
    components,
    initializationError: false,
    geoLocalisation: { lat: 43.543933, lng: 1.511661 },
    resourceNameProperties: ['name', 'device_name', 'device_reference', 'dasboard_name', 'group_name', 'title'],
    resourceIdProperties: ['id', 'device_id', 'dashboard_id', 'group_id'],
    table: {
        disableRowClick: ['invited']
    },
    exportTimeSlots: {
        from_timestamp: null,
        to_timestamp: null,
        type: 'csv'
    }
});

// const minMaxIndex = 0;
let index;
let newList;

const coreReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'LAYOUT_READY':
            return update(state, { ready: { $set: true } });
        case 'HIDE_ROUTE_IN_NAVBAR':
            index = findIndex(state.settings.modules, module => module.route === action.payload);
            if (index !== -1) {
                return update(state, {
                    settings: { modules: { [index]: { isNavbarButton: { $set: false }, route: { $set: false } } } }
                });
            }
            return state;
        case 'HIDE_ROUTE_IN_DROPDOWN':
            index = findIndex(state.settings.modules, module => module.route === action.payload);
            return update(state, {
                settings: { modules: { [index]: { inSearchBar: { $set: false } } } }
            });
        case 'ADD_MODULE':
            return update(state, { settings: { modules: { $unshift: [action.module] } } });
        case 'ADD_RESOURCE_NAME_PROPERTY':
            return update(state, { resourceNameProperties: { $push: action.id } });
        case 'ADD_AGGREGATION_OPTION':
            return update(state, { settings: { graph: { aggregation_options: { $push: action.payload } } } });
        case 'ADD_RESOURCE_ID_PROPERTY':
            return update(state, { resourceIdProperties: { $push: action.id } });
        case 'OVERRIDE_COMPONENT':
            return update(state, {
                components: {
                    list: { [action.payload.module]: { file: { $set: action.payload.path } } }
                }
            });
        case 'CHANGE_DEVICE_MAIN_DATATYPE':
            return update(state, {
                settings: {
                    battery_label: { $set: action.payload }
                }
            });
        case 'ADD_COMPONENT':
        case 'SET_COMPONENT':
            return update(state, {
                components: {
                    list: { [action.payload.module]: { $set: { file: action.payload.path } } }
                }
            });
        case 'SET_TABLE':
            return update(state, { table: { $set: { ...action.payload } } });
        case 'ADD_COMPONENTS':
            newList = cloneDeep(state.components.list);
            action.payload?.forEach(component => {
                newList[component.module] = { file: component.path };
            });
            return update(state, { components: { list: { $set: newList } } });
        case 'RESOURCE_LOADED':
            return update(state, { resourceLoaded: { $set: true }, loading: { $set: false } });
        case 'SET_INITIALIZATION_ERROR':
            return update(state, { initializationError: { $set: action.payload } });
        case 'LOGOUT_FULFILLED':
            return update(state, { ready: { $set: true }, resourceLoaded: { $set: false } });
        case 'SET_EXPORT_TIME_SLOTS':
            return update(state, { exportTimeSlots: { $set: action.payload.timeSlots } });
        default:
            return state;
    }
};

export default coreReducer;

// Libs
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
import { useModal } from '../../modal/ModalContext';
// Helper
import { getDeviceListByGroupId } from '../helper';

const getDeviceList = (devices, groups, isSelectedToText) => {
    if (isSelectedToText) {
        return devices.filter(device => {
            if (device.group_ids.length === 0) { return device; }
            return !device.group_ids.includes(groups.group_id);
        }).map(device => ({
            value: device.device_name || device.device_reference, id: device.device_id
        }));
    }
    return map(devices, device => ({ value: device.device_name || device.device_reference, id: device.device_id }));
};

const getDeviceListInGroup = (devices, groups) => getDeviceListByGroupId(devices, groups.group_id).map(device => ({
    value: device.device_name || device.device_reference, id: device.device_id
}));

const DevicesSelector = props => {
    const { Component } = useComponentsPool();
    const modal = useModal();
    const devices = useSelector(state => state.devices.list);
    const [groups] = useState(modal.getItem() !== undefined ? modal.getItem() : {});
    const [selectedDevices] = useState(() => getDeviceListInGroup(devices, groups));

    return (
        <Component
            componentName={'Select'}
            rules={props.rules}
            required={props.required}
            multiple={props.multiple}
            placeHolder={props.placeHolder}
            onChange={usersSelected => props.onChange(usersSelected)}
            options={getDeviceList(devices, groups, selectedDevices)}
            renderValue={'chip'}
            showSelectAll
            isSelectedToText={selectedDevices}
        />
    );
};

DevicesSelector.defaultProps = {
    multiple: true,
    placeHolder: 'devices:select_device',
    required: false

};

DevicesSelector.propTypes = {
    multiple: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    placeHolder: PropTypes.string,
    required: PropTypes.bool
};

export default DevicesSelector;

// Libs
import React from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import Icon from '../../layout/components/Icon';
import { allQRCodeModes } from '../../devices/helper';

const QRCodeBackButton = () => {
    const history = useHistory();

    return (
        <Button
            className={'qrcode-with-modes-back-button'}
            onClick={() => {
                // Redirect to the qrcode main page
                const currentQRCodeForm = allQRCodeModes.find(mode => history.location.pathname.includes(`${mode}`));
                history.push({ pathname: history.location.pathname.split(`/${currentQRCodeForm}`)[0] });
            }}
        >
            <Icon type={'ArrowBackIcon'} />
        </Button>
    );
};

export default QRCodeBackButton;

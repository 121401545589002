import moment from 'moment';
import {RRule} from 'rrule';
import {isEqual, sortBy} from 'lodash';
// Helper
import {
    getDeviceFromId,
    getDeviceListFromIds, hasLWPAreaConfig,
    isQrcodeOrTaqtDeviceType,
    isQRCodePassageDeviceType
} from '../devices/helper';
import { getVisibleUsers, isUserWithoutMail } from '../users/helper';
import {
    EVENT_WRONG_ROUND_RECURRENCE,
    getHoursAndMinutesFromDate,
    objectHasProperty, segmentTrack
} from '../layout/helper';
import store from '../../store';
import get from "lodash/get";
import { BATCH_API_CALL_STATUS, ROUND_INSTANCE_EVENT_STATUS, ROUND_INSTANCE_STATUS } from "./constants";

moment.locale('fr');

const frenchStrings = {
    error_field: 'X',
    every: 'tou(te)s les',
    until: 'jusqu\'au',
    day: 'jours',
    days: 'jours',
    week: 'semaines',
    weeks: 'semaines',
    weeksdays: 'semaines chaque jour',
    on: 'le(s)',
    in: 'en',
    'on the': 'dans la',
    for: 'pour',
    and: 'et',
    or: 'ou',
    at: 'à',
    last: 'dernier',
    '(~ approximate)': '(~ approximative)',
    times: 'heures',
    time: 'heure',
    minutes: 'minutes',
    hours: 'minute',
    weekdays: 'jours de la semaine',
    weekday: 'jours de la semaine',
    months: 'mois',
    month: 'mois',
    years: 'années',
    year: 'années',
    monday: 'lundi',
    tuesday: 'mardi',
    wednesday: 'mercredi',
    thursday: 'jeudi',
    friday: 'vendredi',
    saturday: 'samedi',
    sunday: 'dimanche',
    january: 'janvier',
    february: 'février',
    march: 'mars',
    april: 'avril',
    may: 'mai',
    june: 'juin',
    july: 'juillet',
    august: 'août',
    september: 'septembre',
    october: 'octobre',
    november: 'novembre',
    december: 'décembre'
};

export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'];

export const gettext = id => {
    if (id) return frenchStrings[id.toLowerCase().trim()] || id;
    return '';
};

export const getRoundInstanceStatus = (status, validatedSteps) => {
    if (status === 'INCOMPLETE' && validatedSteps !== 0) {
        return 'PARTIAL_INCOMPLETE';
    }
    return status;
};

export const getRoundInstanceStatusByValidatedSteps = (validatedSteps, expectedSteps, instanceRoundStatus) => {
    if (validatedSteps === expectedSteps) { // fully validated
        return 'COMPLETE';
    } else if (
        validatedSteps > 0 &&
        validatedSteps < expectedSteps &&
        (instanceRoundStatus === 'INCOMPLETE' || instanceRoundStatus === 'IN_PROGRESS')
    ) { // partially validated
        return instanceRoundStatus;
    }
    return instanceRoundStatus; // Return the original status if none of the conditions are met
};

export const getStatusFromInstanceAndEventStatus = (instanceStatus, eventsStatus) => {
    if (instanceStatus === 'IN_PROGRESS') { return eventsStatus === 'ANOMALY' ? 'IN_PROGRESS' : 'COMPLETE'; }
    if (instanceStatus === 'INCOMPLETE') { return eventsStatus === 'ANOMALY' ? 'INCOMPLETE' : 'COMPLETE'; }
    if (instanceStatus === 'COMPLETE') { return 'COMPLETE'; }
    return 'NOT_STARTED';
};

export const isMoreThan24Hours = (eventStart, eventEnd) => {
    return moment.duration(moment(eventEnd).diff(moment(eventStart))).asHours() >= 24;
};

export const isOnWholeDay = (eventStart, eventEnd) => {
    return moment(eventStart).hours() === 0 && moment(eventStart).minutes() === 0 && moment(eventStart).seconds() === 0
        && moment(eventEnd).hours() === 23 && moment(eventEnd).minutes() === 59 && moment(eventEnd).seconds() === 59;
};

export const isAllDayEvent = (eventStart, eventEnd) => { // If the event is considered as "allDay" (property of events for react-big-calendar, in order to appear at the top)
    // An event occuring on a whole day or that lasts more than 24 hours is considered as "allDay"
    return isOnWholeDay(eventStart, eventEnd) || isMoreThan24Hours(eventStart, eventEnd);
};

export const exportRoundTableToCsvFile = (filename = '', rows = [], roundName = '', t) => {
    const processInstance = function (row = [], jobsList = []) {
        const started_date = moment(row.started_at).format('DD/MM/YYYY HH:mm:ss');
        const ended_at = moment(row.ended_at).format('DD/MM/YYYY HH:mm:ss');
        let instance_row = `${roundName};${started_date};${ended_at};${t(`rounds:${getRoundInstanceStatus(row.status, row.validated_steps_count)}`)}`;
        instance_row = row.instance_infos.completed
            ? `${instance_row};${row.instance_infos.completed}`
            : `${instance_row};0`;
        instance_row = row.instance_infos.not_completed
            ? `${instance_row};${row.instance_infos.not_completed}`
            : `${instance_row};0`;
        if (row.instance_infos?.job_completion_metrics?.length > 0) {
            const metric_without_job = row.instance_infos?.job_completion_metrics?.find(metric => metric.job_name === '');
            instance_row = metric_without_job && metric_without_job
                ? `${instance_row};${metric_without_job.count}`
                : `${instance_row};0`;
            jobsList.forEach(job => {
                const metric = row.instance_infos?.job_completion_metrics
                    .find(job_metric => job_metric.job_name === job);
                instance_row = metric
                    ? `${instance_row};${metric.count}`
                    : `${instance_row};0`;
            });
        } else {
            instance_row = `${instance_row};0`;
            jobsList.forEach(() => {
                instance_row = `${instance_row};0`;
            });
        }

        return `${instance_row}\n`;
    };
    const processHeader = function (jobsList) {
        let header = `${t('rounds:round_name')};${t('rounds:started_at')};${t('rounds:ended_at')}`;
        header = `${header};${t('rounds:instance_status')};${t('rounds:completed_jobs')}`;
        header = `${header};${t('rounds:not_completed_jobs')};${t('rounds:metrics_without_job')}`;

        jobsList.forEach(job => {
            header = `${header};${t('rounds:job_metrics')} ${job}`;
        });

        return `${header}\n`;
    };

    if (rows.length > 0) {
        let csvFile = '';
        const jobs = rows[0].job_names ? rows[0].job_names : [];
        csvFile = processHeader(jobs);
        rows.forEach(row => {
            csvFile = csvFile + processInstance(row, jobs);
        });

        const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvFile], {type: 'text/csv;charset=utf-8;'});
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
};

export const isInAreaPassage = device => {
    if (device?.custom_field.type !== 'LWP') return false;
    const areaConfig = device?.data?.find(data => data?.data_type === 'geofence_config')?.data_points[0];
    const isValidAreaConfig = areaConfig && hasLWPAreaConfig(areaConfig.value);
    return isValidAreaConfig;
};

export const exportRoundInstanceTableToCsvFile = (filename = '', rows = [], round = '', devices = [], started_at, ended_at, t) => {
    const processRow = function (row = []) {
        if (row.event_type === 'PASSAGE' || row.event_type === 'COMMENT') {
            // Already treated if a passage is associated to a comment (avoid duplicates)
            if (get(row, 'event_detail.related_device_message_id', null)) {
                return '';
            }
            const device = getDeviceFromId(devices, row.event_detail?.device_id);
            const event_type = row.event_type === 'PASSAGE'
                ? t(`rounds:event_type_${row.event_type}`)
                : t(`rounds:event_type_${row.event_type}_${device ? 'on_device' : 'on_round'}`);
            const statusText = row.event_detail?.status === 'OK' && isInAreaPassage(device) ? t('passages:passage_status_OK_IN_AREA') : t(`passages:passage_status_${row.event_detail?.status}`);

            const row_info = {
                round: {
                    name: round.name || ''
                },
                instance: {
                    started_at: moment(started_at).format('DD/MM/YYYY HH:mm') || '',
                    ended_at: moment(ended_at).format('DD/MM/YYYY HH:mm') || ''
                },
                device: device ? {
                    name: device.device_name || '',
                    building_name: row.event_detail?.building_name || '',
                    floor_name: row.event_detail?.floor_name || '',
                    position_name: row.event_detail?.position_name || '',
                    hardware_id: row.event_detail?.device_hardware_id || device.hardware_ids?.length > 0 && device.hardware_ids[0] || '',
                    type: row.event_detail?.device_type && t(`devices:${row.event_detail?.device_type}`)
                        || device.custom_field?.type && t(`devices:${device.custom_field.type}`)
                        || ''
                } : {
                    name: '',
                    building_name: '',
                    floor_name: '',
                    position_name: '',
                    hardware_id: '',
                    type: ''
                },
                passage: {
                    agent_name: t(`users:${row.event_detail?.agent_name}`) || t(`users:${row.event_detail?.author_name}`) || '',
                    job_names: row.event_detail?.job_names || '',
                    comment: row.event_detail?.comment?.replace(/\n/g, ' ') || '',
                    uploaded_content_url: row.event_detail?.uploaded_content_url || '',
                    passage_type: t(`passages:${row.event_detail?.passage_type}`) || '',
                    timestamp: row.event_timestamp
                        ? moment(row.event_timestamp).format('DD/MM/YYYY HH:mm:ss')
                        : '',
                    status: row.event_detail?.status && statusText || ''
                },
                event_type: event_type
            };

            let passage_row = `${row_info.event_type};${row_info.passage.timestamp};${row_info.passage.status}`;
            passage_row = `${passage_row};${row_info.passage.agent_name};${row_info.passage.job_names}`;
            passage_row = `${passage_row};${row_info.passage.passage_type};${row_info.device.hardware_id}`;
            passage_row = `${passage_row};${row_info.device.name};${row_info.device.type}`;
            passage_row = `${passage_row};${row_info.device.building_name};${row_info.device.floor_name}`;
            passage_row = `${passage_row};${row_info.device.position_name}`;
            if (row.event_type === 'PASSAGE') {
                const text_content = get(row, 'event_detail.comments[0].text_content', '');
                passage_row = `${passage_row};${text_content || ''}`;
                const uploaded_content_url = get(row, 'event_detail.comments[0].uploaded_content_url', '');
                passage_row = `${passage_row};${uploaded_content_url || ''}`;
            } else {
                const text_content = row_info.passage.comment;
                passage_row = `${passage_row};${ text_content || ''}`;
                const uploaded_content_url = row_info.passage.uploaded_content_url;
                passage_row = `${passage_row};${ uploaded_content_url || ''}`;
            }
            passage_row = `${passage_row};${row_info.round.name}`;
            passage_row = `${passage_row};${row_info.instance.started_at};${row_info.instance.ended_at}`;
            return `${passage_row}\n`;
        }
        return '';
    };
    const processHeader = function () {
        let header = `${t('rounds:event_type')};${t('passages:passage_timestamp')};${t('passages:passage_status')}`;
        header = `${header};${t('users:user')};${t('export:jobs')};${t('export:passage_type')}`;
        header = `${header};${t('passages:device_hardware_id')};${t('devices:device_name')};${t('devices:device_type')}`;
        header = `${header};${t('devices:building_group')};${t('devices:device_floor')};${t('devices:device_position')}`;
        header = `${header};${t('export:comment')};${t('export:comment_file')};${t('rounds:round_name')}`;
        header = `${header};${t('rounds:started_at')};${t('rounds:ended_at')}`;
        return `${header}\n`;
    };

    if (rows.length > 0) {
        let csvFile = '';
        csvFile = processHeader();
        rows.forEach(row => {
            csvFile = csvFile + processRow(row);
        });

        const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvFile], {type: 'text/csv;charset=utf-8;'});
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
};

// Check whether someone has every building of devices of a round
// (devices must only be qrcodes or taqts for assignees to be related to the round)
export const userCanBeRelatedToRound = (user, usersType, deviceList, stepsIds) => {
    // if there aren't any devices, nobody can be assigned
    if (stepsIds.length === 0) {
        return false;
    }
    // get the users building as a list of ids
    const usersBuilding = user.group_memberships.filter(group => group.group.custom_field?.type === 'BATIMENT');
    const usersBuildingIds = [];
    usersBuilding.forEach(building => usersBuildingIds.push(building.group.group_id));

    // get the building of each step(device)
    const stepsBuildingIds = [];
    stepsIds.forEach(deviceId => {
        if (!stepsBuildingIds.includes(getDeviceFromId(deviceList, deviceId)?.custom_field?.batiment_group_id)) {
            stepsBuildingIds.push(getDeviceFromId(deviceList, deviceId)?.custom_field?.batiment_group_id);
        }
    });

    // check if the user has all the buildings of the steps
    const userHasStepBuilding = [];
    stepsBuildingIds.forEach(stepBuildingId => {
        if (usersBuildingIds.find(userBuilding => userBuilding.includes(stepBuildingId))) {
            userHasStepBuilding.push(stepBuildingId);
        }
    });

    // if assignees have the right building, they cannot be selected unless there is only qrcodes or taqts devices on the round
    if (usersType === 'assignees' && userHasStepBuilding.length === stepsBuildingIds.length) {
        return getDeviceListFromIds(deviceList, stepsIds)
            .every(device => isQrcodeOrTaqtDeviceType(device.custom_field?.type));
    }

    return userHasStepBuilding.length === stepsBuildingIds.length;
};

export const getFollowersAndAssignees = (userList, deviceList, stepsIds, usersType) => {
    const users = [];
    // Assignees cannot be 'Users' nor 'Invites'
    const canBeAssignee = user => user?.workspace_role?.workspace_role_name !== 'User' && user?.invite_email === undefined;
    // Followers cannot be 'Users' nor someone without mail BUT they can be 'Invites'
    const canBeFollower = user => user?.workspace_role?.workspace_role_name !== 'User' && (!isUserWithoutMail(user) || user?.invite_email);
    getVisibleUsers(userList).filter(user => (
        usersType === 'assignees' ? canBeAssignee(user) : canBeFollower(user)
    )).forEach(user => {
        if (userCanBeRelatedToRound(user, usersType, deviceList, stepsIds)) {
            users.push(user);
        }
    });
    return users;
};

// do not change the order of the array
export const getWeekDaysName = () => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const getDayName = dayIdx => getWeekDaysName()[dayIdx];

export const getWeekDays = () => [ // do not change the order of the list !!!
    {id: 'MO', value: 'Monday'},
    {id: 'TU', value: 'Tuesday'},
    {id: 'WE', value: 'Wednesday'},
    {id: 'TH', value: 'Thursday'},
    {id: 'FR', value: 'Friday'},
    {id: 'SA', value: 'Saturday'},
    {id: 'SU', value: 'Sunday'}
];

export const getWeekDaysValueFromRrule = rrule => {
    const selectedDays = [];
    const rruleDays = rrule?.split(';').find(rrulePart => rrulePart.includes('BYDAY='));
    // eslint-disable-next-line no-unused-expressions
    rruleDays?.replace('BYDAY=', '')?.split(',')?.forEach(rruleDay => {
        const day = getWeekDays().find(weekDay => weekDay.id === rruleDay);
        if (day) {
            selectedDays.push(day.value);
        }
    });
    return selectedDays;
};

export const getIntervalFromRrule = rrule => {
    const rruleInterval = rrule?.split(';').find(rrulePart => rrulePart.includes('INTERVAL='));
    return rruleInterval?.replace('INTERVAL=', '');
};

export const getStringParamValueFromRrule = (rrule, paramNam) => {
    const rruleInterval = rrule?.split(';').find(rrulePart => rrulePart.includes(paramNam));
    return rruleInterval?.replace(paramNam, '');
};

export const getIntParamValueFromRrule = (rrule, paramNam) => {
    const rruleInterval = rrule?.split(';').find(rrulePart => rrulePart.includes(paramNam));
    return rruleInterval?.replace(paramNam, '') ? parseInt(rruleInterval?.replace(paramNam, ''), 10) : null;
};

export const getByWeekDayFromSelectedDays = selectedDays => {
    // put the days in the rrule format
    const newRruleWeekDay = [];
    selectedDays.forEach(day => {
        const dayId = getWeekDays().find(weekDay => weekDay.value === day).id;
        newRruleWeekDay.push(RRule[dayId]);
    });
    return newRruleWeekDay;
};

export const getRoundFromId = (roundsList, id) => roundsList.find(round => round.id === id);

export const areEqualsRoundsRrules = (firstRoundRrule, secondeRoundRrule) => {
    const firstRruleParameters = firstRoundRrule.replace('RRULE:', '').split(';');
    const secondRruleParameters = secondeRoundRrule.replace('RRULE:', '').split(';');

    // compare rrules days
    const firstRruleDays = firstRruleParameters.find(rrulePart => rrulePart.includes('BYDAY='))?.replace('BYDAY=', '');
    const firstRruleDaysArray = firstRruleDays?.split(',');
    const secondRruleDays = secondRruleParameters.find(rrulePart => rrulePart.includes('BYDAY='))?.replace('BYDAY=', '');
    const secondRruleDaysArray = secondRruleDays?.split(',');

    // compare other rrule parameters
    const firstRruleDaysIdx = firstRruleParameters.findIndex(rrulePart => rrulePart.includes('BYDAY='));
    const secondRruleDaysIdx = secondRruleParameters.findIndex(rrulePart => rrulePart.includes('BYDAY='));
    if (firstRruleDaysIdx !== -1 && secondRruleDaysIdx !== -1) {
        firstRruleParameters.splice(firstRruleDaysIdx, 1);
        secondRruleParameters.splice(secondRruleDaysIdx, 1);
    }

    let areEqualDays;
    if (!firstRruleDaysArray && !secondRruleDaysArray) { // If there isn't a byday param, we consider the rrules days equals
        areEqualDays = true;
    } else {
        areEqualDays = secondRruleDaysArray?.length === firstRruleDaysArray?.length && secondRruleDaysArray.every(day => firstRruleDaysArray.includes(day));
    }

    // rrules are equales if the rrule days, and parameters are equals
    return areEqualDays && isEqual(firstRruleParameters, secondRruleParameters);
};

export const areEqualsRoundsStartedAt = (firstRound, secondRound) => {
    const firstRoundStartedAt = objectHasProperty(firstRound, 'started_at')
        ? firstRound.started_at
        : secondRound.started_at;
    return moment(firstRoundStartedAt).format('HH:mm') === moment(secondRound.started_at).format('HH:mm');
};

export const areEqualsRoundsEndedAt = (firstRound, secondRound) => {
    const firstRoundEndedAt = objectHasProperty(firstRound, 'ended_at')
        ? firstRound.ended_at
        : secondRound.ended_at;
    return moment(firstRoundEndedAt).format('HH:mm') === moment(secondRound.ended_at).format('HH:mm');
};
export const areEqualsRoundsDevices = (firstRoundDevices, secondRoundDevices) => {
    const secondRoundDevicesString = secondRoundDevices.map(device => JSON.stringify(device));
    return firstRoundDevices.length === secondRoundDevices.length
        && firstRoundDevices.map(device => JSON.stringify(device)).every(deviceAsString => secondRoundDevicesString.includes(deviceAsString));
};

export const areEqualsRoundsUsers = (firstRoundUsers, secondRoundUsers) => (
    firstRoundUsers?.length === secondRoundUsers?.length
    && firstRoundUsers?.every(user => secondRoundUsers?.includes(user))
);

export const areEqualsRoundsJobs = (firstJobs, secondJobs) => {
    return isEqual(firstJobs.sort(), secondJobs.sort());
}

export const roundDoesntHaveOnlyQrcodePassageDevices = (deviceList, devicesIds) => {
    if (devicesIds.length === 0) { return false; }
    const roundDevices = getDeviceListFromIds(deviceList, devicesIds);
    return !roundDevices.every(device => isQRCodePassageDeviceType(device));
};

/**
 * This function returns true if there is no QR code passage device in the workspace
 * @param deviceList
 * @return {boolean}
 */
export const workspaceDoesntHaveQrcodePassageDevices = deviceList => !deviceList.some(device => isQRCodePassageDeviceType(device));

export const roundDoesntHaveOnlyQrcodeOrTaqtDevices = (deviceList, devicesIds) => {
    const roundDevices = getDeviceListFromIds(deviceList, devicesIds);
    if (roundDevices.length === 0) {
        return true;
    }
    return !roundDevices.every(device => isQrcodeOrTaqtDeviceType(device.custom_field?.type));
};

export const getRoundEditionLeftSideBottomFields = (footbar, initialRound) => {
    const initialRoundFollowers = initialRound.round_followers?.concat(initialRound.round_invite_followers);
    const initialRoundAssignees = initialRound.round_assignees;
    const initialRoundExpectedJobs = initialRound.round_expected_jobs;
    return {
        followers: { isDisplayed: initialRoundFollowers.length > 0 },
        assignees: { isDisplayed: initialRoundAssignees.length > 0 },
        expectedJobs: { isDisplayed: initialRoundExpectedJobs.length > 0 }
    };
};

export const maxNumberOfChipsInFieldOfPopupLeftSide = numberOfFields => {
    switch (numberOfFields) {
        case 1:
            return 6;
        case 2:
            return 3;
        case 3:
            return 2;
        default:
            return 2;
    }
};

export const traduceRRule = (t, rrule, roundName, started_date, ended_date, isUntilEndOfMonth = false, enableSegmentError = false) => {
    let sentence = '';
    let unknowRecurrence = false;
    if (rrule) {
        // Retrieve every rrule params
        const rruleString = rrule.replace('RRULE:', '');
        const freq = getStringParamValueFromRrule(rruleString, 'FREQ='); // Ex: FREQ=DAILY
        const interval = getIntParamValueFromRrule(rruleString, 'INTERVAL='); // Ex: INTERVAL=1
        const count = getIntParamValueFromRrule(rruleString, 'COUNT='); // Ex: COUNT=3
        const until = getStringParamValueFromRrule(rruleString, 'UNTIL='); // Ex: UNTIL=20250303T150059Z
        // Get byday as an array, for example : ['MO', 'TU']
        const byday = getStringParamValueFromRrule(rruleString, 'BYDAY=')?.split(','); // Ex: BYDAY=MO,TU,WE,TH,FR
        // Get bymonthday as an array, for example : [12, 18]
        const bymonthday = getStringParamValueFromRrule(rruleString, 'BYMONTHDAY=')?.split(',').map(monthday => parseInt(monthday, 10)); // Ex: BYMONTHDAY=18,12
        const bymonth = getStringParamValueFromRrule(rruleString, 'BYMONTH=')?.split(',').map(monthnum => parseInt(monthnum, 10)); // Ex: BYMONTH=1,4
        const bysetpos = getIntParamValueFromRrule(rruleString, 'BYSETPOS='); // Ex: BYSETPOS=-1

        if (count === 1) { // If it's a round that only has one instance
            // Translate hours
            const started_hour = started_date && moment(started_date).isValid()
                ? getHoursAndMinutesFromDate(started_date, 'h')
                : null;
            const ended_hour = ended_date && moment(ended_date).isValid()
                ? getHoursAndMinutesFromDate(ended_date, 'h')
                : null;

            if (isMoreThan24Hours(started_date, ended_date)) { // If the duration of the instance is > 24 hours
                if (isOnWholeDay(started_date, ended_date)) { // If instances are on a whole day
                    sentence = `${t('graphs:custom_date', { // Sentence : Du DD/MM/YY au DD/MM/YY, toute la journée
                        startDate: moment(started_date).format('DD/MM/YY'),
                        endDate: moment(ended_date).format('DD/MM/YY')
                    })}, ${t('common:all_day').toLowerCase()}`;
                } else { // If instances have custom hours
                    if (started_hour && ended_hour) {
                        sentence = `${t('common:from_date_hour_to_date_hour', { // Sentence : Du DD/MM/YY à HH[h]mm au DD/MM/YY à HH[h]mm
                            startDate: moment(started_date).format('DD/MM/YY'),
                            startHour: started_hour,
                            endDate: moment(ended_date).format('DD/MM/YY'),
                            endHour: ended_hour
                        })}`;
                    } else {
                        sentence = `${t('graphs:custom_date', { // Sentence : Du DD/MM/YY au DD/MM/YY
                            startDate: moment(started_date).format('DD/MM/YY'),
                            endDate: moment(ended_date).format('DD/MM/YY')
                        })}`;
                    }
                }
            } else { // If the duration of the instance is < 24 hours
                sentence = `${t('common:the')} ${moment(started_date).format('DD/MM/YY')}`;
                if (isOnWholeDay(started_date, ended_date)) {
                    sentence = `${sentence}, ${t('common:all_day').toLowerCase()}`; // Sentence : Le DD/MM/YY, toute la journée
                } else {
                    sentence = `${sentence} ${t('common:from_second')} ${started_hour} ${t('common:to_second')} ${ended_hour}`; // Sentence : Le DD/MM/YY de HH[h]mm à HH[h]mm
                }
            }
        } else { // If it's a round that has multiple instances
            const isEveryDay = byday?.length === 7;
            const isEveryDayButWeekends = byday?.length === 5 && !byday?.includes('SA') && !byday?.includes('SU');
            if (freq === 'DAILY') {
                if (!byday) { // If the events do not occur on specific days of the week
                    sentence = interval > 1 ? t('common:every_x_day', { nbDay: interval }) : t('common:everyday'); // Sentence: Tous les x jours / Tous les jours
                } else { // If the events occur on specific days of the week
                    if (isEveryDay) { // If it occurs every day besides the weekend
                        sentence = t('common:everyday'); // Sentence : Tous les jours
                    } else if (isEveryDayButWeekends) { // If it occurs every day besides the weekend
                        sentence = t('common:every_day_of_the_week_without_weekend'); // Sentence : Tous les jours (du lundi au vendredi)
                    } else {
                        unknowRecurrence = true;
                    }
                }
            } else if (freq === 'WEEKLY') {
                if (!byday) {
                    // Sentence : Toutes les x semaines / Toutes les semaines
                    sentence = interval > 1 ? `${t('common:every_x_week', { nbWeek: interval })}` : t('common:every_week');
                } else { // If the events occur on specific days
                    const days = [];
                    let byDayIdx = 0;
                    getWeekDays().forEach(weekDay => {
                        if (byday.includes(weekDay.id)) {
                            let dayName = t(`common:${weekDay.value.toLowerCase()}`);
                            if (interval <= 1 && localStorage.getItem('i18nextLng').slice(0, 2) === 'fr') { dayName = `${dayName}s`; } // In french we need to pluralize the text for the sentence Tous les lundis
                            days.push(dayName);
                            if (byDayIdx !== byday.length - 1) { // If it is not the last byday
                                days.push(byday.length - 2 === byDayIdx ? ` ${t('common:and')} ` : ', ');
                            }
                            byDayIdx = byDayIdx + 1;
                        }
                    });
                    const daysString = days.join('');
                    if (isEveryDay) { // Sentence : Toutes les x semaines, tous les jours / Tous les jours
                        sentence = interval > 1 ? `${t('common:every_x_week', { nbWeek: interval })}, ${t('common:everyday').toLowerCase()}` : `${t('common:everyday')}`;
                    } else if (isEveryDayButWeekends) { // Sentence : Toutes les x semaines, tous les jours (du lundi au vendredi) / Tous les jours (du lundi au vendredi)
                        sentence = interval > 1 ? `${t('common:every_x_week', { nbWeek: interval })}, ${t('common:every_day_of_the_week_without_weekend').toLowerCase()}` : `${t('common:every_day_of_the_week_without_weekend')}`;
                    } else { // Sentence : Toutes les x semaines le [noms des jours] / Tous les [noms des jours]
                        sentence = interval > 1 ? `${t('common:every_x_week', { nbWeek: interval })} ${t('common:the')} ${daysString}` : `${t('common:every')} ${daysString}`;
                    }
                }
            } else if (freq === 'MONTHLY') {
                sentence = interval > 1 ? `${t('common:every_x_month', { nbMonth: interval })}` : t('common:every_month'); // Sentence : Tous les x mois / Tous les mois
                if (bysetpos === -1) {
                    sentence = `${sentence} ${t('common:on_the_last_day')}`; // Sentence : [sentence] le dernier jour
                } else if (bymonthday) {
                    // Sentence : [sentence] du XX au dernier jour du mois / [sentence] le XX
                    sentence = `${sentence} ${isUntilEndOfMonth
                        ? `${t('graphs:custom_label', { from_timestamp: bymonthday[0], to_timestamp: t('common:last_day_of_the_month') })}`
                        : `${t('common:the')} ${bymonthday[0]}`}`;
                }
            } else if (freq === 'YEARLY') {
                sentence = interval > 1 ? `${t('common:every_x_year', { nbYear: interval })}` : t('common:every_year'); // Sentence : Tous les x ans / Tous les ans
                if (bymonthday && bymonth) {
                    sentence = `${sentence} ${t('common:the')} ${bymonthday[0]} ${t(`common:${months[bymonth[0] - 1].toLowerCase()}`)}`; // Sentence : [sentence] le XX [nom du mois]
                }
            } else {
                unknowRecurrence = true;
            }

            // End of recurrence (if there is one)
            if (!isNaN(count) && count > 1) {
                sentence = `${sentence} ${t('common:stops_after_x_events', { nbEvents: count })}`; // Sentence : [sentence] (s'arrête après {{nbEvents}} événements)
            } else if (until) {
                sentence = `${sentence} ${t('common:until_the')} ${moment(until).format('DD/MM/YY')}`; // Sentence : [sentence] jusqu'au DD/MM/YY
            }
        }

        // In order not to be spam, only send the segment if we are clicking on an instance (calendar) or if we are modifying a round (list)
        if (enableSegmentError && unknowRecurrence) {
            segmentTrack(EVENT_WRONG_ROUND_RECURRENCE, {
                roundName,
                rrule,
                userMail: store.getState()?.users?.currentUser?.email
            });
        }
    }
    return sentence;
};

export const getCalendarInstanceSizeClassName = (eventEnd, eventStart, allDay) => {
    const instanceDurationInMinute = Math.floor(Math.abs(eventEnd - eventStart) / 1000 / 60);
    if (allDay) {
        return 'all-day-instance';
    }
    if (instanceDurationInMinute < 45) {
        return 'shortest-instance';
    }
    if (instanceDurationInMinute < 75) {
        return 'short-instance';
    }
    return 'normal-or-big-instance';
};

export const getCalendarTitleNbLinesClassName = (eventEnd, eventStart) => {
    const instanceDurationInMinute = Math.floor(Math.abs(eventEnd - eventStart) / 1000 / 60);
    // if the instance lasts more than 2:30 the max number of line isn't specified
    if (instanceDurationInMinute >= 150) {
        return 'all-lines-title';
    }
    // if the instance lasts more than 2:00 the title is on three lines max
    if (instanceDurationInMinute >= 120) {
        return 'three-lines-title';
    }
    // if the instance lasts more than 1:45 the title is on two lines max
    if (instanceDurationInMinute >= 105) {
        return 'two-lines-title';
    }
    // if it lasts less than 1:45 the title is on one line max
    return 'one-line-title';
};

export const getOneTimeRoundRRule = () => new RRule({
    freq: RRule.DAILY, // default freq
    interval: 1,
    count: 1 // the number of occurences to be created
});

export const EVENT_MAX_DURATION = 366;

export const nextDayDate = (date) => {
    return moment(date).add(1, 'days');
};

export const previousDayDate = (date) => {
    return moment(date).subtract(1, 'days');
};

export const nextWeekDate = (date) => {
    return moment(date).add(7, 'days');
};

export const previousWeekDate = (date) => {
    return moment(date).subtract(7, 'days');
};

export const canDisplayDateTimeInputOfCurrentRoundEvent = (instanceStatus, eventStatus) => {
    return (
        instanceStatus === ROUND_INSTANCE_STATUS.COMPLETE ||
        instanceStatus === ROUND_INSTANCE_STATUS.INCOMPLETE ||
        (instanceStatus === ROUND_INSTANCE_STATUS.IN_PROGRESS && eventStatus === ROUND_INSTANCE_EVENT_STATUS.OK)
    );
};

/**
 * This function returns the status of the batch API calls based on the number of successful API calls and the total number of responses
 * @param {number} numberOfSuccessfulApiCalls
 * @param {number} totalNumberOfResponses
 * @return {string}
 */
export const getApiCallsStatus = (numberOfSuccessfulApiCalls, totalNumberOfResponses) => {
    return totalNumberOfResponses === numberOfSuccessfulApiCalls
        ? BATCH_API_CALL_STATUS.ALL_FULFILLED
        : numberOfSuccessfulApiCalls === 0
        ? BATCH_API_CALL_STATUS.ALL_REJECTED
        : BATCH_API_CALL_STATUS.PARTIAL_FULFILLED;
};
// Libs
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Context
import { useModal } from '../../modal/ModalContext';
import { useFootBar } from '../../footbar/FootBarContext';
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import { addGroupToUser } from '../actions';
import createLoadingSelector from '../../layout/actions';
// Helper
import { getUserName } from '../helper';

const loadingSelector = createLoadingSelector(['ADD_GROUP_TO_USER']);
const AddGroupToUserPopup = () => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const modal = useModal();
    const footBar = useFootBar();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const [user] = useState(modal.getItem());
    const [groupSelected, setGroupSelected] = useState([]);
    const [groupRoleId, setGroupRoleId] = useState('');

    const addUserToGroup = params => {
        dispatch(addGroupToUser([{
            user_id: params.userId,
            group_ids: params.groupSelected,
            group_role_id: params.groupRoleId
        }]));
        modal.close();
        footBar.close();
    };

    const head = <span>{t('users:add_group_to_user_name', { name: user !== {} ? getUserName(user) : '' })}</span>;

    const content = (
        <div className={'iot-rocket-form'}>
            <Component
                componentName={'AddGroupToUserForm'}
                required
                multiple
                onGroupSelectionChange={value => setGroupSelected(value)}
                onChangeRole={value => setGroupRoleId(value.id)}
            />
        </div>
    );

    return (
        <Component
            componentName={'Modal'}
            head={head}
            content={content}
            loading={isLoading}
            closeButton
            submitButton
            submitButtonLabel={'common:save'}
            closeButtonLabel={'common:cancel'}
            onHandleSubmit={() => addUserToGroup({ groupSelected, groupRoleId, userId: user.id })}
            onSubmitDisabled={isEmpty(groupSelected) || groupRoleId === ''}
        />
    );
};

export default AddGroupToUserPopup;

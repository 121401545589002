import React, { useEffect } from 'react';
// Libs
import ReactEcharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
// Helper
import CountUp from 'react-countup';
import { getPressCountInTotal, getPressCountUnit, easeOutQuad } from '../../helper';
import { useComponentsPool } from '../../../../ComponentsPool';

const SatisfactionGraph = props => {
    const eChartsRef = React.useRef(null);
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const symbols = [
        'path://M37.26,56.73c0.47,0.42,1.2,0.38,1.62-0.09c2.39-2.67,5.83-4.2,9.45-4.2c3.62,0,7.06,1.53,9.45,4.2c0.42,0.47,1.15,0.51,1.62,0.09c0.47-0.42,0.51-1.15,0.09-1.62c-2.82-3.15-6.89-4.96-11.16-4.96c-4.27,0-8.34,1.81-11.16,4.96C36.75,55.59,36.79,56.31,37.26,56.73zM48.33,18.09c-14.52,0-26.34,11.82-26.34,26.34s11.82,26.34,26.34,26.34s26.34-11.82,26.34-26.34S62.85,18.09,48.33,18.09z M48.33,68.48c-13.26,0-24.05-10.79-24.05-24.05s10.79-24.05,24.05-24.05s24.05,10.79,24.05,24.05S61.59,68.48,48.33,68.48zM63.22,37.56c0-0.63-0.51-1.15-1.15-1.15s-1.15,0.51-1.15,1.15c0,1.89-1.54,3.44-3.44,3.44s-3.44-1.54-3.44-3.44c0-0.63-0.51-1.15-1.15-1.15c-0.63,0-1.15,0.51-1.15,1.15c0,3.16,2.57,5.73,5.73,5.73S63.22,40.72,63.22,37.56zM35.73,37.56c0-0.63-0.51-1.15-1.15-1.15c-0.63,0-1.15,0.51-1.15,1.15c0,3.16,2.57,5.73,5.73,5.73c3.16,0,5.73-2.57,5.73-5.73c0-0.63-0.51-1.15-1.15-1.15c-0.63,0-1.15,0.51-1.15,1.15c0,1.89-1.54,3.44-3.44,3.44C37.27,40.99,35.73,39.45,35.73,37.56z',
        'path://M6023 9344 c-337 -36 -699 -130 -997 -259 -424 -182 -826 -460 -1142 -788 -757 -785 -1099 -1851 -943 -2932 135 -937 662 -1790 1452 -2348 397 -281 875 -482 1364 -572 391 -72 853 -74 1243 -5 1188 212 2185 1028 2633 2155 363 910 319 1953 -119 2827 -541 1080 -1572 1796 -2769 1923 -173 18 -552 18 -722 -1z m767 -308 c496 -66 942 -233 1355 -507 718 -477 1215 -1222 1374 -2059 42 -223 53 -378 48 -660 -7 -370 -45 -600 -152 -925 -164 -500 -434 -929 -811 -1295 -598 -579 -1386 -900 -2214 -900 -1641 0 -3017 1256 -3171 2893 -17 183 -7 584 19 752 103 665 395 1261 849 1736 272 284 555 491 908 664 357 176 701 272 1120 315 126 12 538 4 675 -14zM4781 7582c-48-24-73-62-78-119-5-59 18-108 66-140l34-23 1523 2 1522 3 27 25c83 78 71 204-25 252-33 17-127 18-1535 18-1408 0-1502-1-1534-18zM4990 5659c-135-22-256-117-319-248-34-72-36-81-36-181 0-100 2-109 36-181 86-179 263-275 458-248 232 33 403 266 364 495-42 241-265 402-503 363zM7561 5659c-83-14-173-63-236-130-165-173-165-426 1-600 267-279 745-86 744 301-1 269-244 474-509 429z M4781 7582c-48-24-73-62-78-119-5-59 18-108 66-140l34-23 1523 2 1522 3 27 25c83 78 71 204-25 252-33 17-127 18-1535 18-1408 0-1502-1-1534-18zM4990 5659c-135-22-256-117-319-248-34-72-36-81-36-181 0-100 2-109 36-181 86-179 263-275 458-248 232 33 403 266 364 495-42 241-265 402-503 363zM7561 5659c-83-14-173-63-236-130-165-173-165-426 1-600 267-279 745-86 744 301-1 269-244 474-509 429z',
        'path://M59.4,50.45c-0.47-0.42-1.2-0.38-1.62,0.09c-2.39,2.67-5.83,4.2-9.45,4.2c-3.62,0-7.06-1.53-9.45-4.2c-0.42-0.47-1.15-0.51-1.62-0.09c-0.47,0.42-0.51,1.15-0.09,1.62c2.82,3.15,6.89,4.96,11.16,4.96c4.27,0,8.34-1.81,11.16-4.96C59.91,51.59,59.87,50.87,59.4,50.45zM48.33,18.09c-14.52,0-26.34,11.82-26.34,26.34s11.82,26.34,26.34,26.34s26.34-11.82,26.34-26.34S62.85,18.09,48.33,18.09z M48.33,68.48c-13.26,0-24.05-10.79-24.05-24.05s10.79-24.05,24.05-24.05s24.05,10.79,24.05,24.05S61.59,68.48,48.33,68.48zM57.49,36.41c-3.16,0-5.73,2.57-5.73,5.73c0,0.63,0.51,1.15,1.15,1.15c0.63,0,1.15-0.51,1.15-1.15c0-1.89,1.54-3.44,3.44-3.44s3.44,1.54,3.44,3.44c0,0.63,0.51,1.15,1.15,1.15s1.15-0.51,1.15-1.15C63.22,38.98,60.65,36.41,57.49,36.41zM42.6,42.14c0,0.63,0.51,1.15,1.15,1.15c0.63,0,1.15-0.51,1.15-1.15c0-3.16-2.57-5.73-5.73-5.73c-3.16,0-5.73,2.57-5.73,5.73c0,0.63,0.51,1.15,1.15,1.15c0.63,0,1.15-0.51,1.15-1.15c0-1.89,1.54-3.44,3.44-3.44C41.06,38.7,42.6,40.25,42.6,42.14z'
    ];
    const graphData = props.graphData;
    const satisfactionsTotal = graphData.low_satisfaction_count
        + graphData.medium_satisfaction_count + graphData.high_satisfaction_count;
    const getSatisfactionPercentage = value => Math.round(value * 100 / satisfactionsTotal);

    const animationDelay = 0.3;
    const animationDuration = 1.5;
    const timeoutDelay = animationDelay * 1000;

    const dissatisfiedLabel = capitalize(t('graphs:dissatisfied'));
    const neutralLabel = capitalize(t('graphs:neutral'));
    const satisfiedLabel = capitalize(t('graphs:satisfied'));

    const tooltipSetting = {
        formatter(params) {
            const labelPressCount = `${params.data.tooltipValue } ${ getPressCountUnit(t, params.data.tooltipValue)}`;
            return `<div style="width: 150px; display: flex; justify-content: space-between">
                <p>${params.marker}${params.name}</p>
                <p><b>${labelPressCount}</b></p>
            </div>`;
        }
    };

    const iconSize = props.iconSize || window.innerHeight / 6.5;

    useEffect(() => {
        // animation for the stars on the graph
        setTimeout(() => {
            // eslint-disable-next-line no-unused-expressions
            eChartsRef.current?.getEchartsInstance().setOption({
                series: [{
                    name: 'full',
                    symbolBoundingData: 150,
                    type: 'pictorialBar',
                    animationDuration: 0,
                    symbolSize: [iconSize, iconSize],
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: 'transparent'
                            }, {
                                offset: 1, color: 'transparent'
                            }]
                        }
                    },
                    data: [{
                        value: 0,
                        symbol: symbols[0]
                    }, {
                        value: 0,
                        symbol: symbols[1]
                    }, {
                        value: 0,
                        symbol: symbols[2]
                    }]
                }, {
                    type: 'pictorialBar',
                    symbolClip: false,
                    symbolSize: [iconSize, iconSize],
                    data: [{
                        tooltipValue: graphData.low_satisfaction_count,
                        value: satisfactionsTotal,
                        symbol: symbols[0],
                        itemStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: '#ff7077'
                                }, {
                                    offset: 1, color: '#f74136'
                                }]
                            }
                        }
                    },
                    {
                        tooltipValue: graphData.medium_satisfaction_count,
                        value: satisfactionsTotal,
                        symbol: symbols[1],
                        itemStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: '#FFD755'
                                }, {
                                    offset: 1, color: '#ffb700'
                                }]
                            }
                        }
                    }, {
                        tooltipValue: graphData.high_satisfaction_count,
                        value: satisfactionsTotal,
                        symbol: symbols[2],
                        itemStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: '#83ffa4'
                                }, {
                                    offset: 1, color: '#A3FF83'
                                }]
                            }
                        }
                    }]
                }]
            });
        }, timeoutDelay);
    }, [graphData]);

    const isEmptyGraph = graphData.high_satisfaction_count === 0
        && graphData.medium_satisfaction_count === 0
        && graphData.low_satisfaction_count === 0;

    if (isEmptyGraph) {
        return (
            <Component componentName={'GraphNoData'} text={t('dashboards:no_satisfaction_data')} />
        );
    }
    return (
        <div className={'echarts-graph-wrapper'}>
            <p className={'graph-title'}>
                <CountUp
                    end={satisfactionsTotal}
                    delay={animationDelay}
                    duration={animationDuration}
                    useEasing
                    easingFn={easeOutQuad}
                />
                {` ${ getPressCountInTotal(t, satisfactionsTotal)}`}
            </p>
            <ReactEcharts
                className={'echarts-graph'}
                ref={eChartsRef}
                style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                option={{
                    tooltip: tooltipSetting,
                    xAxis: {
                        data: [dissatisfiedLabel, neutralLabel, satisfiedLabel],
                        show: false
                    },
                    yAxis: {
                        show: false
                    },
                    grid: {
                        top: 'center',
                        right: 'center',
                        width: '80%',
                        // same height as the icons
                        height: iconSize + 15
                    },
                    series: [{
                        type: 'pictorialBar',
                        symbolSize: [iconSize, iconSize],
                        data: [{
                            value: 0,
                            symbol: symbols[0],
                            itemStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: 'transparent'
                                    }, {
                                        offset: 1, color: 'transparent'
                                    }]
                                }
                            }
                        },
                        {
                            value: 0,
                            symbol: symbols[1],
                            itemStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: 'transparent'
                                    }, {
                                        offset: 1, color: 'transparent'
                                    }]
                                }
                            }
                        }, {
                            value: 0,
                            symbol: symbols[2],
                            itemStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: 'transparent'
                                    }, {
                                        offset: 1, color: 'transparent'
                                    }]
                                }
                            }
                        }]
                    }]
                }}
            />
            <div className={'graph-legends-wrapper'}>
                <div className={'graph-legends'}>
                    <div className={'graph-legend'}>
                        <p className={'legend'}>
                            <CountUp
                                end={getSatisfactionPercentage(graphData.low_satisfaction_count)}
                                delay={animationDelay}
                                duration={animationDuration}
                                useEasing
                                easingFn={easeOutQuad}
                            />
                            {' '}
                            %
                        </p>
                        <div className={'sub-legend'}>
                            <CountUp
                                end={graphData.low_satisfaction_count}
                                delay={animationDelay}
                                duration={animationDuration}
                                useEasing
                                easingFn={easeOutQuad}
                            />
                            {' '}
                            {getPressCountUnit(t, graphData.low_satisfaction_count)}
                        </div>
                    </div>
                    <div className={'graph-legend'}>
                        <p className={'legend'}>
                            <CountUp
                                end={getSatisfactionPercentage(graphData.medium_satisfaction_count)}
                                delay={animationDelay}
                                duration={animationDuration}
                                useEasing
                                easingFn={easeOutQuad}
                            />
                            {' '}
                            %
                        </p>
                        <div className={'sub-legend'}>
                            <CountUp
                                end={graphData.medium_satisfaction_count}
                                delay={animationDelay}
                                duration={animationDuration}
                                useEasing
                                easingFn={easeOutQuad}
                            />
                            {' '}
                            {getPressCountUnit(t, graphData.medium_satisfaction_count)}
                        </div>
                    </div>
                    <div className={'graph-legend'}>
                        <p className={'legend'}>
                            <CountUp
                                end={getSatisfactionPercentage(graphData.high_satisfaction_count)}
                                delay={animationDelay}
                                duration={animationDuration}
                                useEasing
                                easingFn={easeOutQuad}
                            />
                            {' '}
                            %
                        </p>
                        <div className={'sub-legend'}>
                            <CountUp
                                end={graphData.high_satisfaction_count}
                                delay={animationDelay}
                                duration={animationDuration}
                                useEasing
                                easingFn={easeOutQuad}
                            />
                            {' '}
                            {getPressCountUnit(t, graphData.high_satisfaction_count)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SatisfactionGraph;

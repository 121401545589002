// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
import { useModal } from '../../modal/ModalContext';

export default () => {
    const { Component } = useComponentsPool();
    const userWorkspaceRights = useSelector(state => state.users.currentUser.workspace_rights);
    const currentUser = useSelector(state => state.users.currentUser);
    const [t] = useTranslation();
    const modal = useModal();

    if (currentUser.workspace_role?.workspace_role_name === 'Owner') {
        return (
            <Component
                componentName={'ActionButton'}
                canUserCreate={userWorkspaceRights.includes('CREATE:User')}
                modalName={'InvitePopup'}
                iconButton={(<Component componentName={'Icon'} type={'addUser'} />)}
                tooltipTitle={'descriptions:users_actionbar'}
                actions={[
                    {
                        icon: <Component componentName={'Icon'} type={'addUser'} />,
                        name: t('descriptions:users_actionbar'),
                        onClick: () => modal.update({ name: 'InvitePopup' })
                    },
                    {
                        icon: <Component componentName={'Icon'} type={'csvFile'} />,
                        name: t('descriptions:users_actionbar_from_csv'),
                        onClick: () => modal.update({ name: 'CSVInvitePopup' })
                    },
                    {
                        icon: <Component componentName={'Icon'} type={'csvFile'} />,
                        name: t('descriptions:users_without_mail_actionbar_from_csv'),
                        onClick: () => modal.update({ name: 'CSVAddUserWithoutMail' })
                    },
                    {
                        icon: <Component componentName={'Icon'} type={'csvFile'} />,
                        name: t('descriptions:nfc_tags_actionbar_from_csv'),
                        onClick: () => modal.update({ name: 'CSVAddNfcPopup' })
                    }
                ]}
            />
        );
    }
    return (
        <Component
            componentName={'ActionButton'}
            canUserCreate={userWorkspaceRights.includes('CREATE:User')}
            modalName={'InvitePopup'}
            iconButton={(<Component componentName={'Icon'} type={'addUser'} />)}
            tooltipTitle={'descriptions:users_actionbar'}
        />
    );
};

/* eslint-disable import/first */
import React, { useState } from 'react';
import { action } from '@storybook/addon-actions';
import { Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import Input from '../modules/layout/components/Input';
import Modal from '../modules/modal/Components/Modal';
import '../modules/layout/styles/_theme.scss';
import '../modules/layout/styles/_logs.scss';

const head = <span>{'Welcome to IoT Rocket !'}</span>;
const onHandleSubmit = action('onHandleSubmit');

const customTitle = (
    <React.Fragment>
        <span>{'Welcome to IoT Rocket !'}</span>
        <Typography variant={'subtitle2'}>{'This is a custom title with Typography Component'}</Typography>
    </React.Fragment>
);

export default { title: 'Modal' };

const SizeableModal = () => {
    const [withCloseButton, setWithCloseButton] = useState(true);
    const [withSubmitButton, setWithSubmitButton] = useState(true);
    const [submitButtonText, setSubmitButtonText] = useState('SAVE');
    const [cancelButtonText, setCancelButtonText] = useState('CANCEL');

    const content = (
        <div className={'iot-rocket-form'} style={{ margin: 'auto', width: '370px' }}>
            <FormGroup>
                <h4>{'Enable/disable elements'}</h4>
                <FormControlLabel
                    control={<Switch checked={withCloseButton} onChange={() => setWithCloseButton(!withCloseButton)} />}
                    label="Enable close button"
                />
                <FormControlLabel
                    control={
                        <Switch checked={withSubmitButton} onChange={() => setWithSubmitButton(!withSubmitButton)} />
                    }
                    label="Enable submit button"
                />
            </FormGroup>
            <FormGroup>
                <h4>{'Change button labels'}</h4>
                <Input
                    label={'Save Button label'}
                    value={submitButtonText}
                    onChangeState={setSubmitButtonText}
                />
                <Input
                    label={'Cancel Button label'}
                    value={cancelButtonText}
                    onChangeState={setCancelButtonText}
                />
            </FormGroup>
        </div>
    );

    return (
        <Modal
            head={<span>{'Customizable modal'}</span>}
            content={content}
            loading={false}
            closeButton={withCloseButton}
            submitButton={withSubmitButton}
            submitButtonLabel={submitButtonText}
            closeButtonLabel={cancelButtonText}
            onHandleSubmit={onHandleSubmit}
            onSubmitDisabled
        />
    );
};

const SubmitEnable = () => {
    const [value, setValue] = useState('');

    const content = (
        <div className={'iot-rocket-form'}>
            <Input
                autoFocus
                required
                rules={['isEmail']}
                label={'Email Address'}
                onChangeState={setValue}
            />
        </div>
    );

    return (
        <Modal
            head={<span>{'Submit button enable with value !'}</span>}
            content={content}
            loading={false}
            closeButton
            submitButton
            submitButtonLabel={'common:save'}
            closeButtonLabel={'common:close'}
            onHandleSubmit={onHandleSubmit}
            onSubmitDisabled={value.length === 0}
        />
    );
};

const StepModal = () => {
    const [currentStep, setCurrentStep] = useState(0);

    const steps = [{
        disabled: false,
        content: <span>{'First step'}</span>,
        submitButtonLabelTitle: 'common:next_step'
    }, {
        disabled: false,
        content: <span>{'Second step'}</span>,
        submitButtonLabelTitle: 'common:next_step'
    }, {
        disabled: true,
        content: <span>{'Third step'}</span>,
        submitButtonLabelTitle: 'common:next_step'
    }];

    return (
        <Modal
            loading={false}
            head={head}
            content={steps[currentStep].content}
            disabledStep={steps[currentStep].disabled}
            steps={steps.length}
            currentStep={currentStep}
            closeButton
            submitButtonLabel={steps[currentStep].submitButtonLabelTitle}
            onHandleNextStep={() => setCurrentStep(currentStep + 1)}
            onHandlePreviousStep={() => setCurrentStep(currentStep - 1)}
            onHandleSubmit={onHandleSubmit}
        />
    );
};

const ModalTemplate = () => {
    const leftContent = (
        <div>
            {'This is the left content for the modal'}
            <br />
            {'The space at the bottom of the modal is for the footbar'}
        </div>
    );

    const rightContent = (
        <div>
            {'This is the right content for the modal'}
        </div>
    );

    const content = [
        { width: '65%', content: leftContent },
        { width: '35%', content: rightContent }
    ];

    return (
        <Modal
            content={content}
            onHandleSubmit={onHandleSubmit}
            contentWidth={'100%'}
            classNames={'details-popup'}
        />
    );
};

export const modal = () => (
    <Modal
        head={head}
        content={''}
        loading={false}
        closeButton
        submitButton
        submitButtonLabel={'common:save'}
        closeButtonLabel={'common:close'}
        onHandleSubmit={onHandleSubmit}
        onSubmitDisabled
    />
);

export const customTitleModal = () => (
    <Modal
        head={customTitle}
        content={'You will have to do your own CSS for custom titles.'}
        loading={false}
        closeButton
        submitButton
        submitButtonLabel={'common:save'}
        closeButtonLabel={'common:close'}
        onHandleSubmit={onHandleSubmit}
        onSubmitDisabled
    />
);

export const loadingModal = () => (
    <Modal
        head={head}
        content={'This is loading for ever.'}
        loading
        closeButton
        submitButton
        submitButtonLabel={'common:save'}
        closeButtonLabel={'common:close'}
        onHandleSubmit={onHandleSubmit}
        onSubmitDisabled
    />
);

export const submitEnable = () => <SubmitEnable />;
export const stepper = () => <StepModal />;
export const customButtons = () => <SizeableModal />;
export const templateWithModal = () => <ModalTemplate />;

import React from 'react';

const initState = {
    name: '', // Name popup
    list: [], // list array of object
    item: {}, // Item at edit
    data: [], // Item at edit
    isVisible: false,
    isEdit: false,
    isSave: false,
    withFootBar: false,
    update: () => {},
    getWithFootBar: () => {},
    getItem: () => {},
    getName: () => {},
    getIsVisible: () => {},
    getData: () => {},
    setData: () => {},
    close: () => {},
    open: () => {}
};
export const ModalStateContext = React.createContext(initState);
export const ModalDispatchContext = React.createContext(null);

const modalReducer = (state, action) => {
    switch (action.type) {
        case 'isVisible':
        case 'update':
            return { ...state, ...action.payload };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

const findItem = (props, pState) => {
    if (pState.list.length === 0 && Object.keys(pState.item).length > 0) {
        if (props !== undefined) return pState.item[props];
        return pState.item;
    }
    if (pState.list.length !== 0) {
        if (props === undefined) return pState.list[0];
        return pState.list[0][props];
    }
    if (props === undefined) return pState.list[0];
    return pState.item;
};

const ModalProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(modalReducer, initState);

    const setModal = payload => {
        dispatch({ type: 'update', payload });
    };

    const getters = {
        getIsVisible: () => state.isVisible,
        getWithFootBar: () => state.withFootBar,
        getItem: props => findItem(props, state),
        getData: () => state.data,
        getList: () => state.list,
        getIsSave: () => state.isSave,
        getName: () => state.name
    };

    const setters = {
        open: name => setModal({ ...state, name, isVisible: name !== '' }),
        setItem: item => setModal({ item: { ...state.item, ...item } }),
        setWithFootBar: withFootBar => setModal({ ...state, withFootBar, isVisible: withFootBar }),
        setSave: isSave => setModal({ ...state, isSave }),
        setData: data => setModal({ ...state, data }),
        reset: () => setModal(initState),
        close: () => setModal(initState),
        update: param => setModal({ ...param, isVisible: param.name !== '', item: param.item || {} })
    };
    return (
        <ModalStateContext.Provider value={{ ...state, ...getters, ...setters }}>
            <ModalDispatchContext.Provider value={dispatch}>
                {children}
            </ModalDispatchContext.Provider>
        </ModalStateContext.Provider>
    );
};


const useModal = () => {
    const context = React.useContext(ModalStateContext);
    if (context === undefined) {
        throw new Error('usePopupState must be used within a ModalProvider');
    }
    return context;
};

// const usePopupDispatch = () => {
//     const context = React.useContext(ModalDispatchContext);
//     if (context === undefined) {
//         throw new Error('usePopupDispatch must be used within a ModalProvider');
//     }
//     return context;
// };

export { ModalProvider, useModal };

// Libs
import React, {
    useRef, useEffect, useState, useMemo
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
    isEmpty, uniq, concat, filter, remove, cloneDeep, find, isEqual
} from 'lodash';
// Components
import { FormControl, ListItemText, MenuItem } from '@material-ui/core';
// Context
import { useModal } from '../../modal/ModalContext';
import { useComponentsPool } from '../../../ComponentsPool';
// Helper
import { getDevicesOfGroup } from '../../devices/helper';
import { setDevicesAndGroupsOptions } from '../helper';
import { getGroupById } from '../../groups/helper';
// SCSS
import '../styles/_device_and_group_selector.scss';

const getDefaultOptions = (devices, groups, t, showAllDevices, excludeParent) => {
    const options = setDevicesAndGroupsOptions(devices, groups, t, true, showAllDevices);
    return excludeParent ? remove(options, option => option.id !== excludeParent) : options;
};

const formHelperText = (first, second, third, onClickFunction) => (
    <div
        role={'button'}
        tabIndex={0}
        onClick={() => { if (onClickFunction) onClickFunction(); }}
    >
        {`${first} `}
        <strong>{second}</strong>
        {` ${third}`}
    </div>
);

const getSelectedGroups = (selectedGroups, groupId, groupsList, options) => {
    let selected_groups = [];
    let groups = selectedGroups;
    if (isEmpty(selectedGroups) && groupId) {
        groups = getGroupById(groupsList, groupId).group_ids;
    }
    if (!isEmpty(groups)) {
        selected_groups = filter(options, option => groups.includes(option.id));
    }
    return selected_groups;
};

const getDevices = (devicesList, groupId) => {
    const selectedDevices = [];
    devicesList?.forEach(device => {
        if (device.group_ids.includes(groupId)) selectedDevices.push(device.device_id);
    });
    return selectedDevices;
};

const getSelectedDevices = (selectedDevices, groupId, devicesList, options) => {
    let selected_devices = [];
    let devices = selectedDevices;
    if (isEmpty(selectedDevices) && groupId) {
        devices = getDevices(devicesList, groupId);
    }
    if (!isEmpty(devices)) {
        selected_devices = filter(options, option => devices.includes(option.id));
    }
    return selected_devices;
};

const DeviceAndGroupSelector = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const modal = useModal();
    const groups = useSelector(state => state.groups.list);
    const devices = useSelector(state => state.devices.list);
    const [groupId] = useState(props.group_id || (modal.getItem() ? modal.getItem().group_id : null));
    const [defaultOptions] = useState(() => getDefaultOptions(
        devices, groups, t, props.showAllDevices, props.excludeParent
    ));
    const [options, setOptions] = useState(defaultOptions);
    const [disabled] = useState(props.disabled === undefined ? false : props.disabled);
    // options selected
    const [allSelected, setAllSelected] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [helperTexts, setHelperTexts] = useState(['', '', '']);
    const [selectedGroups, setSelectedGroups] = useState(
        () => getSelectedGroups(props.selectedGroups, groupId, groups, options)
    );
    const [selectedDevices, setSelectedDevices] = useState(
        () => getSelectedDevices(props.selectedDevices, groupId, devices, options)
    );
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [deviceIds, setDeviceIds] = useState([]);
    const [hoverOptionId, setHoverOptionId] = useState(null); // Current hover option id
    const [totalDevices] = useState(options.filter(option => option?.type === 'device' && !isEmpty(option.group_ids)).length);
    // MORE OPTIONS
    const [isPlaceHolderToOption] = useState(props.isPlaceHolderToOption || false); // View placeholder in option list

    const selectAllOptions = () => {
        if (!allSelected) {
            const allGroups = defaultOptions.filter(option => option?.type === 'group');
            setSelectedGroups(allGroups);
            setSelectedOptions(allGroups);
        } else {
            setSelectedGroups([]);
            setSelectedOptions([]);
        }
        setAllSelected(!allSelected);
        setSelectedDevices([]);
    };

    const sendData = () => {
        props.onChange({
            selected_groups: selectedGroups.map(group => group.id),
            selected_devices: selectedDevices.map(device => device.id),
            device_ids: deviceIds
        });
        if (!props.liveUpdate) {
            setIsFocused(false);
        }
    };

    const fetchDeviceIds = () => {
        const selectedGroupIds = selectedGroups.map(group => group.id);
        const selectedDeviceIds = selectedDevices.map(device => device.id);
        let allSelectedDevices = [];
        selectedGroupIds?.forEach(currentGroup => {
            allSelectedDevices = uniq(concat(allSelectedDevices, getDevicesOfGroup(devices, groups, currentGroup)));
        });
        setDeviceIds(uniq(concat(selectedDeviceIds, allSelectedDevices)));
    };

    // Update description text under component
    useEffect(() => {
        if (deviceIds.length) {
            setHelperTexts([
                t('common:select-help-text-selected-pre', { count: deviceIds.length }),
                t('common:devices').toLowerCase(),
                t('common:select-help-text-selected-suf')
            ]);
        } else if (deviceIds.length === 0 && helperTexts[0].length > 0) {
            setHelperTexts([`${t('common:please_select')} ${t('common:devices').toLowerCase()}`, '', '']);
        }
    }, [deviceIds]);

    // Unselect devices if group are selected
    useEffect(() => {
        // first check all group are selected
        const totalGroupOptions = defaultOptions.filter(o => o?.type === 'group').length;
        if (selectedGroups.length === totalGroupOptions && !props.showAllDevices && !allSelected) {
            selectAllOptions();
            return;
        }
        const selectedGroupIds = selectedGroups.map(group => group.id);
        const selectedDevicesCopy = cloneDeep(selectedDevices);
        const selectedOptionsCopy = cloneDeep(selectedOptions);
        selectedDevices?.forEach(device => {
            device.group_ids?.forEach(id => {
                if (selectedGroupIds.includes(id)) {
                    remove(selectedDevicesCopy, deviceSelected => deviceSelected.id === device.id); // eslint-disable-line
                    remove(selectedOptionsCopy, deviceSelected => deviceSelected.id === device.id); // eslint-disable-line
                }
            });
        });
        setSelectedDevices(selectedDevicesCopy);
        setSelectedOptions(selectedOptionsCopy);
    }, [selectedGroups]);

    // On focus => sort array
    // On unfocus => send data
    useEffect(() => {
        if (isFocused) {
            const optionsCopy = [];
            options?.forEach(option => {
                const id = option.id;
                if (option?.type === 'group') {
                    optionsCopy.push({ ...option, selected: !!find(selectedGroups, group => group.id === id) });
                } else if (option?.type === 'device') {
                    optionsCopy.push({ ...option, selected: !!find(selectedDevices, device => device.id === id) });
                }
            });
            setOptions(optionsCopy.sort((optionA, optionB) => {
                if (optionA.selected !== optionB.selected) return optionA.selected ? -1 : 1; // selected first
                if (optionA?.type !== optionB?.type) return optionA.type === 'group' ? -1 : 1; // then group first
                if (optionA.value !== optionB.value) return optionA.value < optionB.value ? -1 : 1; // then order value
                return 0;
            }));
        }
    }, [isFocused]);

    // Compute deviceIds
    useEffect(() => {
        fetchDeviceIds();
        if (props.liveUpdate) sendData();
    }, [selectedGroups, selectedDevices]);

    // Init component
    useEffect(() => {
        fetchDeviceIds();
        setSelectedOptions(concat(selectedDevices, selectedGroups));
    }, [props.selectedDevices, selectedGroups]);

    // Clean in and out component
    const useOutDetect = ref => { // To close dropdown
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                sendData();
                if (!selectedOptions) {
                    setHelperTexts([
                        `${t('common:please_select')} ${t('common:devices').toLowerCase()}`,
                        helperTexts[1],
                        helperTexts[2]
                    ]);
                }
            }
        };
        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        });
    };

    const dropdownRef = useRef(null);
    useOutDetect(dropdownRef);

    const useFocus = () => {
        const htmlElRef = useRef(null);
        const setFocus = () => htmlElRef.current && htmlElRef.current.focus();
        return [htmlElRef, setFocus];
    };

    const [keywordInputRef, setkeywordInputFocus] = useFocus(); // Set focus on keywordInput

    // Update inner component state (text and selection)
    const handleChange = (type, option) => {
        if (type === 'selected') {
            const currentSelection = [...selectedOptions];
            if (option === 'select_all') {
                selectAllOptions();
            } else {
                const index = selectedOptions.findIndex(item => item.id === option.id);
                if (index === -1) {
                    currentSelection.push(option);
                } else {
                    currentSelection.splice(index, 1);
                }
                setSelectedOptions(currentSelection);
                const selected_groups = currentSelection.filter(row => row.type === 'group');
                const selected_devices = currentSelection.filter(row => row.type === 'device');
                if (!isEqual(selected_groups, selectedGroups)) {
                    setSelectedGroups(selected_groups);
                }
                if (!isEqual(selected_devices, selectedDevices)) setSelectedDevices(selected_devices);
            }
        } else if (type === 'keyword' && props.selectType) {
            option.persist();
            setKeyword(option.target.value);
        }
    };

    // Update desc text
    const placeHolder = { devices: t('devices').toLowerCase() };
    if (isPlaceHolderToOption) {
        if (selectedOptions.length === 0) {
            placeHolder.pre = t('select-input-empty-text-pre');
            placeHolder.suf = t('select-input-empty-text-suf');
        } else {
            placeHolder.pre = t('select-input-non-empty-text-pre');
            placeHolder.suf = t('select-input-non-empty-text-suf');
        }
    }

    // Filter option by input
    let filteredOptions = options;
    if (keyword) {
        filteredOptions = options.filter(
            option => selectedOptions.findIndex(select => select.id === option.id) > -1
            || option.value.toLowerCase().includes(keyword.toLowerCase())
        );
        const _groups = filteredOptions.filter(option => option.type === 'group');
        const _devices = options.filter(option => option.group_ids);
        _groups?.forEach(group => {
            const filteredDevices = _devices.filter(
                device => device.group_ids.findIndex(group_id => group_id === group.id) > -1
            );
            filteredOptions = filteredOptions.concat(filteredDevices);
        });
        filteredOptions = uniq(filteredOptions);
    }

    // Rendering component and options
    const isRequired = deviceIds.length === 0 && props.required;
    const selectedGroupIds = selectedGroups.map(g => g.id);

    const MenuItemList = useMemo(() => !allSelected && filteredOptions.map(option => {
        // do not show already selected options
        if (option.type === 'device') {
            for (let i = 0; i < option.group_ids.length; i = i + 1) {
                if (selectedGroupIds.includes(option.group_ids[i])) return false;
            }
        }
        const isSelected = Array.isArray(selectedOptions)
            ? selectedOptions.findIndex(select => select.id === option.id) > -1
            : selectedOptions.id === option.id;
        return (
            <MenuItem
                className={classNames(isSelected
                    ? 'select-option Mui-selected' : 'select-option')}
                component={'div'}
                key={option.id}
                onMouseOver={() => setHoverOptionId(option.id)}
                onFocus={() => {}}
                onClick={() => handleChange('selected', option)}
            >
                {props.showIcon && option.type === 'group' && <Component componentName={'Icon'} type={'deviceGroup'} />}
                {props.showIcon && option.type === 'device' && <Component componentName={'Icon'} type={'device'} />}
                <ListItemText
                    classes={{ root: 'max-ellipsis' }}
                    primary={option.value}
                />
                {props.showDesc && (
                    <ListItemText
                            primary={option.type === 'device' // eslint-disable-line
                            ? option.desc.text
                                : isSelected // eslint-disable-line
                                ? option.id === hoverOptionId
                                    ? option.desc.hoverSelected
                                    : option.desc.selected
                                : option.id === hoverOptionId
                                    ? option.desc.hover : option.desc.text}
                        classes={{ root: 'align-right', primary: 'description' }}
                    />
                )}
                <div className={'icon'}>
                    {isSelected && option.id !== hoverOptionId && <Component componentName={'Icon'} type={'check'} />}
                    {isSelected && option.id === hoverOptionId && <Component componentName={'Icon'} type={'close'} />}
                </div>
            </MenuItem>
        );
    }), [selectedOptions, props.showIcon, filteredOptions, allSelected]);

    return (
        <FormControl
            required={props.required}
            className={classNames([props.className, 'device-and-group-selector'])}
            component={'div'}
        >
            <div
                className={classNames(props.isFilled ? 'filled-input' : '',
                    'MuiFormControl-root MuiTextField-root iot-rocket-input MuiFormControl-marginNormal'
                )}
            >
                {!isFocused ? (
                    <div className="keyword">
                        <input
                            type="text"
                            name="keyword"
                            className="keyword"
                            onClick={() => {
                                if (!disabled) setIsFocused(true);
                            }}
                            value={props.selectType ? keyword : ''}
                            onChange={e => handleChange('keyword', e)}
                        />
                        <div
                            role={'button'}
                            tabIndex={0}
                            className={classNames('placeholder', keyword.length > 0 && 'hidden')}
                            onClick={() => {
                                if (!disabled) setIsFocused(true);
                            }}
                        >
                            {formHelperText(placeHolder.pre, placeHolder.devices, placeHolder.suf)}
                        </div>
                    </div>
                ) : (
                    <div className="dropdown" ref={dropdownRef} onMouseLeave={() => setHoverOptionId(null)}>
                        <input
                            type="text"
                            name="keyword"
                            className="keyword"
                            ref={keywordInputRef}
                            value={props.selectType ? keyword : ''}
                            onChange={e => handleChange('keyword', e)}
                        />
                        <div
                            role={'button'}
                            tabIndex={0}
                            className={classNames('placeholder', 'focused', keyword.length > 0 && 'hidden')}
                            onClick={setkeywordInputFocus}
                        >
                            {formHelperText(placeHolder.pre, placeHolder.devices, placeHolder.suf)}
                        </div>
                        <div className="options">
                            {options.length > 0 ? (
                                <>
                                    { !keyword && (
                                        <MenuItem
                                            className={classNames('select-option', allSelected ? ' Mui-selected all-selected' : '')}
                                            key={'select_all'}
                                            value={'select_all'}
                                            onFocus={() => {}}
                                            component={'div'}
                                            onMouseOver={() => setHoverOptionId('select_all')}
                                            onMouseLeave={() => setHoverOptionId(null)}
                                            onClick={() => handleChange('selected', 'select_all')}
                                        >
                                            <Component componentName={'Icon'} type={'deviceGroup'} />
                                            <ListItemText primary={t('devices:select_all_devices')} />
                                            <ListItemText
                                                primary={allSelected // eslint-disable-line
                                                    ? hoverOptionId === 'select_all'
                                                        ? t('common:select-item-group-hover-selected', { count: totalDevices })
                                                        : t('common:select-item-group-selected', { count: totalDevices })
                                                    : hoverOptionId === 'select_all'
                                                        ? t('common:select-all-devices', { count: totalDevices })
                                                        : t('common:select-item-group-device-count', { count: totalDevices })
                                                }
                                                classes={{ root: 'align-right', primary: 'description' }}
                                            />
                                            <div className={'icon'}>
                                                {allSelected && hoverOptionId !== 'select_all' && <Component componentName={'Icon'} type={'check'} />}
                                                {allSelected && hoverOptionId === 'select_all' && <Component componentName={'Icon'} type={'close'} />}
                                            </div>
                                        </MenuItem>
                                    )}
                                    { MenuItemList }
                                </>
                            ) : (
                                <MenuItem className="select-option" component={'div'}>
                                    <ListItemText primary={t('common:no-data-to-display')} />
                                </MenuItem>
                            )}
                        </div>
                    </div>
                )}
                <div
                    role={'button'}
                    tabIndex={0}
                    className="search-icon"
                    onClick={() => { setIsFocused(true); }}
                >
                    <Component componentName={'Icon'} type={'search'} />
                </div>
            </div>
            <div className={'form-helper-text-wrapper'}>
                <span className={classNames('clickable-helper', !isRequired ? 'primary' : 'required')}>
                    {formHelperText(helperTexts[0], helperTexts[1], helperTexts[2], () => setIsFocused(true))}
                </span>
            </div>
        </FormControl>
    );
};

DeviceAndGroupSelector.defaultProps = {
    disabled: false,
    isFilled: false,
    isPlaceHolderToOption: true,
    selectType: 'keywords',
    showDesc: false,
    showIcon: false
};

DeviceAndGroupSelector.propTypes = {
    disabled: PropTypes.bool,
    isFilled: PropTypes.bool,
    isPlaceHolderToOption: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    selectType: PropTypes.string,
    showDesc: PropTypes.bool,
    showIcon: PropTypes.bool
};

export default DeviceAndGroupSelector;

import update from 'immutability-helper';

const initialState = () => ({
    latestChangelog: []
});


const changelogsReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'GET_LATEST_CHANGELOG_FULFILLED':
            if (action.payload?.results?.length > 0) {
                return update(state, {
                    latestChangelog: { $set: action.payload.results[0] }
                });
            }
            return state;
        case 'USER_SAW_CHANGELOG_FULFILLED':
            return update(state, {
                latestChangelog: { current_user_saw_changelog: { $set: true } }
            });
        default:
            return state;
    }
};

export default changelogsReducer;

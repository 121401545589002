import React from "react";

const SvgPiechart = props => (
  <svg width="1em" height="1em" viewBox="0 0 26.522 26.655" {...props}>
    <g data-name="Group 63">
      <g data-name="Group 62">
        <g data-name="Group 60">
          <path
            data-name="Path 138"
            d="M22.936 13.996H12.6V3.656a.749.749 0 0 0-.752-.752 11.844 11.844 0 1 0 11.84 11.844.753.753 0 0 0-.752-.752zM11.844 25.081a10.34 10.34 0 0 1-.752-20.652v10.312a.749.749 0 0 0 .752.752h10.312a10.356 10.356 0 0 1-10.312 9.588zm0 0"
          />
        </g>
        <g data-name="Group 61">
          <path
            data-name="Path 139"
            d="M26.533 11.856A11.844 11.844 0 0 0 14.685.056a.749.749 0 0 0-.752.752V11.9a.749.749 0 0 0 .752.752h11.092a.749.749 0 0 0 .752-.752zm-11.1-.7V1.595a10.341 10.341 0 0 1 9.56 9.553h-9.56zm0 0"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgPiechart;

// Libs
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { map, filter, find } from 'lodash';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
import { useModal } from '../../modal/ModalContext';
// Helper
import { getUserName, getVisibleUsers } from '../helper';
import { getUsersByGroupId, getUsersNotInGroupByGroupId } from '../../groups/helper';

const getUserList = (users, groups, isSelectedToText) => {
    const usersWithoutInvites = filter(users, user => user?.invite_email === undefined);
    if (isSelectedToText) {
        return getUsersNotInGroupByGroupId(usersWithoutInvites, groups.group_id).map(user => ({
            value: getUserName(user), id: user.id
        }));
    }
    return map(usersWithoutInvites, user => ({ value: getUserName(user), id: user.id }));
};

const getUserListInGroup = (users, groups) => getUsersByGroupId(users, groups.group_id).map(user => ({
    value: getUserName(user), id: user.id
}));

const UsersSelector = props => {
    const { Component } = useComponentsPool();
    const modal = useModal();
    const users = useSelector(state => state.users.list);
    const visibleUsers = getVisibleUsers(users);
    const [groups] = useState(modal.getItem() !== undefined ? modal.getItem() : {});
    const [value] = useState(props.value
        ? props.value.map(user => find(visibleUsers, u => u.id === user)).map(user => ({
            if (user) {
                return { value: getUserName(user), id: user.id };
            }
        }))
        : undefined
    );
    const [selectedUsers] = useState(() => getUserListInGroup(visibleUsers, groups));

    return (
        <Component
            componentName={'Select'}
            required={props.required}
            multiple={props.multiple}
            placeHolder={props.placeHolder}
            onChange={usersSelected => props.onChange(usersSelected.map(user => user.id))}
            options={getUserList(visibleUsers, groups, selectedUsers)}
            renderValue={'chip'}
            showSelectAll
            selected={value}
            isSelectedToText={selectedUsers}
        />
    );
};

UsersSelector.defaultProps = {
    multiple: true,
    placeHolder: 'users:select_user',
    required: false
};

UsersSelector.propTypes = {
    multiple: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    placeHolder: PropTypes.string,
    required: PropTypes.bool
};

export default UsersSelector;

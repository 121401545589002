// Libs
import React, {
    forwardRef, useEffect, useLayoutEffect, useRef, useState
} from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { groupBy, toArray } from 'lodash';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// Components
import { useTranslation } from 'react-i18next';
// Default graph config
import GraphConfig from './default.graph.config';
// Helper
import { shallowCompare } from '../../../core/helper';


moment.locale(localStorage.getItem('i18nextLng'));
Highcharts.setOptions(GraphConfig.lang);


Highcharts.setOptions({
    lang: {
        months: moment.months(),
        weekdays: moment.weekdays(),
        shortMonths: moment.monthsShort()
    }
});


const getUniqueDataTypes = graph => {
    const dataTypes = graph._graph_data.map(_graph_data => _graph_data.data_type);
    return dataTypes.filter((item, pos, self) => self.indexOf(item) === pos);
};

const getSeries = (graph, coreSettings, t) => {
    const graphColor = '#011056';
    const seriesList = [];
    const uniqueTypes = getUniqueDataTypes(graph);
    graph._graph_data.forEach((graph_data, i) => {
        // graph_data = graph_data[i]
        const line = {};
        if (i !== 0) line.visible = false;
        line.name = t(`devices:${graph_data.data_type}`);
        // line.name = graph_data.data_type;
        line.lineWidth = GraphConfig.exporting.curve.lineWidth;
        line.yAxis = uniqueTypes.indexOf(graph_data.data_type);
        line.stack = uniqueTypes.indexOf(graph_data.data_type); // Reference for groups in legends
        line.marker = { symbol: 'circle' };
        line.color = graphColor;
        line.tooltip = { valueSuffix: graph_data.unit !== undefined ? graph_data.unit : graph_data.data_points.length > 0 ? graph_data.data_points[0].unit : '' }; // eslint-disable-line
        if (graph_data.data_points.length === 0) {
            line.data = [];
        } else {
            line.data = graph_data.data_points.map(point => {
                let value;
                if (graph_data.data_type === 'temperature') {
                    value = point.temperature;
                } else if (graph_data.data_type === 'humidity') {
                    value = point.humidity;
                } else if (graph_data.data_type === 'co2') {
                    value = point.co2;
                } else if (graph_data.data_type === 'press_count') {
                    value = point.press_count;
                } else if (graph_data.data_type === 'luminosity') {
                    value = point.luminosity;
                } else if (graph_data.data_type === 'state') {
                    value = point.state;
                } else if (graph_data.data_type === 'status') {
                    value = point.state;
                } else if (graph_data.data_type === 'people') {
                    value = point.people;
                } else {
                    value = point.value;
                }
                return [moment(point.timestamp).unix() * 1000, value]
            });
        }
        line.enableMouseTracking = true;
        seriesList.push(line);
    });
    if (coreSettings.graph.curve.group_by_data_type) {
        let groups = groupBy(seriesList, serie => serie.yAxis);
        groups = toArray(groups).flat();
        return groups;
    }
    return seriesList;
};

const getConditionFromDataType = (conditions, data_type) => conditions.find(condition => (data_type === 'temperature'
    && (condition.code === 'Temp' || condition.code === '' || condition.code === null))
    || data_type === 'humidity' && condition.code === 'Humi'
    || data_type === 'co2' && condition.code === 'CO2'
);


const getYaxis = (t, graph, props, selectedName) => {
    const uniqueTypes = getUniqueDataTypes(graph);
    const nbAxis = uniqueTypes.length;
    const yAxisList = [];
    let visibleAxis = 0;
    if (selectedName) {
        uniqueTypes.filter((type, idx) => {
            if (t(`devices:${type}`) === selectedName) visibleAxis = idx;
        });
    }

    for (let i = 0; i < nbAxis; i = i + 1) {
        const unit = graph._graph_data[i].unit !== undefined // eslint-disable-line
            ? graph._graph_data[i].unit
            : graph._graph_data[i].data_points.length > 0 ? graph._graph_data[i].data_points[0].unit : '';
        const color = '#434343';
        const condition = getConditionFromDataType(props.conditions, graph._graph_data[i].data_type);

        const limit_min = condition?.limit_min || 0;
        const limit_max = condition?.limit_max || 0;

        const axis = {
            visible: i === visibleAxis,
            title: {
                style: {
                    fontSize: '14px',
                    color
                },
                text: `${t(`devices:${uniqueTypes[i]}`)} ${unit}`
            },
            labels: {
                enabled: true,
                format: '{value}',
                style: {
                    fontSize: '14px',
                    color
                }
            },
            plotBands: [
                {
                    from: limit_min,
                    to: limit_max,
                    color: '#BFC2D0',
                    label: {
                        style: {
                            color: '#000C44',
                            fontSize: '12px'
                        }
                    }
                }
            ]
        };

        axis.opposite = false;
        yAxisList.push(axis);
    }
    return yAxisList;
};

const LineGraph = forwardRef((props, ref) => {
    // const dashboards = useSelector(state => state.dashboards);
    const [t] = useTranslation();
    const coreSettings = useSelector(state => state.core.settings);
    const chart = useRef(ref);
    const [graph, setGraph] = useState(props.graph);

    // Options graph
    const [options, setOptions] = useState({
        boost: GraphConfig.boost,
        reflow: GraphConfig.reflow,
        credits: GraphConfig.credits,
        title: GraphConfig.title,
        noData: GraphConfig.noData,
        time: GraphConfig.time,
        chart: {
            ...GraphConfig.chart,
            type: 'spline',
            zoomType: 'xy'
        },
        plotOptions: {
            line: { enableMouseTracking: props.showLegend },
            allowPointSelect: props.showLegend,
            series: {
                dataLabels: { enabled: false },
                showInNavigator: true,
                events: {
                    show: function () {
                        var chart = this.chart,
                            series = chart.series,
                            i = series.length,
                            otherSeries;
                        while (i--) {
                            otherSeries = series[i];
                            if (otherSeries != this && otherSeries.visible) {
                                otherSeries.hide();
                            }
                        }
                    },
                    legendItemClick: function() {
                        setOptions(prevState => ({
                            ...prevState,
                            yAxis: getYaxis(t, graph, props, t(`devices:${this.name}`) )
                        }));
                        if (this.visible) {
                            return false;
                        }
                    }
                },
                allowPointSelect: props.showLegend,
                marker: {
                    symbol: 'circle',
                    enabledThreshold: 10,
                    states: {
                        hover: {
                            lineWidth: 1.2,
                            lineColor: null,
                            fillColor: null
                        }
                    },
                    lineWidth: 1.6,
                    lineColor: null
                }
            }
        },
        xAxis: {
            tickWidth: 0,
            lineWidth: 3,
            crosshair: { color: '#999' },
            type: 'datetime',
            dateTimeLabelFormats: {
                second: '%d/%m %Hh%M',
                minute: '%d/%m %Hh%M',
                hour: '%d/%m %Hh',
                day: '%d/%m',
                week: '%d/%m',
                month: '%m',
                year: '%Y'
            },
            // min: getMin(graph),
            // max: getMax(graph),
            title: { text: null },
            gridLineWidth: 0,
            minPadding: 0.05,
            maxPadding: 0.05,
            labels: {
                enabled: props.showLegend,
                style: {
                    fontSize: '14px'
                }
            }
        },
        navigator: { ...GraphConfig.navigator, enabled: props.showLegend },
        legend: { ...GraphConfig.legend, enabled: props.showLegend },
        tooltip: {
            ...GraphConfig.tooltip,
            enabled: props.showLegend,
            headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
            pointFormat: '<span style="color:{point.color}">\u25CF</span><b>{point.y}</b><br/>',
            footerFormat: '',
            dateTimeLabelFormats: {
                second: '%d/%m/%Y %Hh%M',
                minute: '%d/%m/%Y %Hh%M',
                hour: '%d/%m/%Y %Hh%M',
                day: '%d/%m/%Y %Hh%M',
                week: '%d/%m/%Y',
                month: '%m/%Y',
                year: '%Y'
            },
            valueDecimals: 1
        },
        series: getSeries(graph, coreSettings, t),
        yAxis: getYaxis(t, graph, props)
    });

    // Change temporality update datas
    useEffect(() => {
        if (shallowCompare(graph._graph_data, props.graph._graph_data)) {
            setGraph(props.graph);
            setOptions(prevState => ({
                ...prevState,
                series: getSeries(props.graph, coreSettings, t),
                yAxis: getYaxis(t, props.graph, props),
                tooltip: {
                    ...GraphConfig.tooltip,
                    enabled: props.showLegend,
                    headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
                    pointFormat: '<span style="color:{point.color}">\u25CF</span><b>{point.y}</b><br/>',
                    footerFormat: '',
                    dateTimeLabelFormats: {
                        second: '%d/%m/%Y %Hh%M',
                        minute: '%d/%m/%Y %Hh%M',
                        hour: '%d/%m/%Y %Hh%M',
                        day: '%d/%m/%Y %Hh%M',
                        week: '%d/%m/%Y',
                        month: '%m/%Y',
                        year: '%Y'
                    },
                    valueDecimals: 1
                }
            }));
        }
    }, [props.graph._graph_data]);

    useLayoutEffect(() => {
        if (props.rect && chart && chart.current !== null) {
            chart.current.chart.setSize(props.rect.width - 30, props.rect.height - 75);
            Highcharts.setOptions({
                lang: {
                    months: moment.months(),
                    weekdays: moment.weekdays(),
                    shortMonths: moment.monthsShort()
                }
            });
        }
    }, [props.rect]);

    return (
        <div
            className={classNames(['graph-component', 'line'])}
            id={'graph-view-curve'}
            style={{ width: '95%' }}
        >
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chart}
            />
        </div>
    );
});

LineGraph.defaultProps = {
    editing: false,
    showLegend: false
};

LineGraph.propTypes = {
    editing: PropTypes.bool,
    graph: PropTypes.shape({
        _graph_data: PropTypes.array,
        aggregate_operation_name: PropTypes.string,
        aggregate_period_name: PropTypes.string,
        colors: PropTypes.array,
        dashboard_ids: PropTypes.array,
        device_data_min_max: PropTypes.array,
        // device_data_seuil_min_max: PropTypes.shape,
        device_data_types: PropTypes.array,
        device_ids: PropTypes.array,
        from_timestamp: PropTypes.string,
        graph_id: PropTypes.string,
        group_id: PropTypes.string,
        is_last_value: PropTypes.bool,
        max_value: PropTypes.number,
        metric_text: PropTypes.string,
        min_value: PropTypes.number,
        name: PropTypes.string,
        start_offset_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        to_timestamp: PropTypes.string,
        type: PropTypes.string
    }).isRequired,
    showLegend: PropTypes.bool
};

export default LineGraph;

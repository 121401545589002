// Libs
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import 'react-date-range/dist/styles.css';
import { toast } from 'react-toastify';
import { Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Radio, RadioGroup } from '@mui/material';
import Chip from '@material-ui/core/Chip';
import { RRule } from 'rrule';
import moment from 'moment/moment';
import classnames from 'classnames';
import {
    getByWeekDayFromSelectedDays,
    getDayName,
    EVENT_MAX_DURATION,
    getIntParamValueFromRrule,
    getStringParamValueFromRrule,
    gettext,
    getWeekDays,
    months
} from '../../helper';
import { useComponentsPool } from '../../../../ComponentsPool';
import {
    getUntilDateFromDateAndEndedAt,
    getUntilDateIsoStringFromDateAndEndedAt,
    useSecondMainColorTooltip
} from '../../../layout/helper';
import { Toast } from '../../../notification/components';


const RoundModalThirdSection = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const initialRender = useRef(true);
    const tooltipStyle = useSecondMainColorTooltip();
    const yearsLimit = 3;
    const monthsLimit = 24;
    const weeksLimit = 53;
    const daysLimit = EVENT_MAX_DURATION;
    const isLessThan24Hours = props.state.ended_at.diff(props.state.started_at, 'hours', true) < 24;
    const isLessThanOrEqualTo24Hours = props.state.ended_at.diff(props.state.started_at, 'hours', true) <= 24;
    const areDaysOfTheWeek = getDayName(props.state.started_at.day()) !== 'Saturday' && getDayName(props.state.started_at.day()) !== 'Sunday'
            && getDayName(props.state.ended_at.day()) !== 'Saturday' && getDayName(props.state.ended_at.day()) !== 'Sunday';
    const isLessThanAWeek = props.state.ended_at.diff(props.state.started_at, 'weeks', true) < 1;
    const isLessThanMaxWeeks = props.state.ended_at.diff(props.state.started_at, 'weeks', true) < weeksLimit;
    const isLessThanAMonth = props.state.ended_at.diff(props.state.started_at, 'months', true) < 1;
    const isLessThanMaxMonths = props.state.ended_at.diff(props.state.started_at, 'months', true) < monthsLimit;
    const isLessThanAYear = props.state.ended_at.diff(props.state.started_at, 'years', true) < 1;
    const isLessThanOrEqualToAYear = props.state.ended_at.diff(props.state.started_at, 'years', true) <= 1;
    const startedAtDayAndMonth = t(`common:${props.state.started_at.date()} ${ t(`common:${months[props.state.started_at.month()].toLowerCase()}`)}`);
    const endedAtDayAndMonth = t(`common:${props.state.ended_at.date()} ${ t(`common:${months[props.state.ended_at.month()].toLowerCase()}`)}`);
    const weekOptionValue = isLessThanOrEqualTo24Hours
        ? t('common:every_week_on', { dayName: t(`common:${getDayName(props.state.started_at.day()).toLowerCase()}`) })
        : t('common:every_week_from', { startDayName: t(`common:${getDayName(props.state.started_at.day()).toLowerCase()}`), endDayName: t(`common:${getDayName(props.state.ended_at.day()).toLowerCase()}`) });
    const monthOptionValue = isLessThanOrEqualTo24Hours
        ? t('common:every_month_the', { dayNumber: props.state.started_at.date() })
        : props.state.started_at.date() !== props.state.ended_at.date()
            ? t('common:every_month_from', { nbStartDay: props.state.started_at.date(), nbEndDay: props.state.ended_at.date()})
            : t('common:every_month');
    const yearOptionValue = isLessThanOrEqualTo24Hours
        ? t('common:every_year_the', { day: startedAtDayAndMonth })
        : t('common:every_year_from', { startDayName: startedAtDayAndMonth, endDayName: endedAtDayAndMonth });
    const endOfRecurrenceOptions = [
        {
            id: 'never', value: t('common:never')
        },
        {
            id: 'after', value: t('common:after_second')
        },
        {
            id: 'the', value: t('common:the')
        }
    ];
    const customRecurrenceOptions = [
        {
            id: 'custom_day', freq: RRule.DAILY, value: t('common:every_x_day', { nbDay: 'X' }), isDisplayed: true, isDefaultOption: true
        },
        {
            id: 'custom_week', freq: RRule.WEEKLY, value: t('common:every_x_week', { nbWeek: 'X' }), isDisplayed: isLessThanMaxWeeks
        },
        {
            id: 'custom_month', freq: RRule.MONTHLY, value: t('common:every_x_month', { nbMonth: 'X' }), isDisplayed: isLessThanMaxMonths
        },
        {
            id: 'custom_year', freq: RRule.YEARLY, value: t('common:every_x_year', { nbYear: 'X' }), isDisplayed: isLessThanOrEqualToAYear
        }
    ];
    const recurrenceOptions = [
        {
            id: 'none', freq: RRule.DAILY, value: t('common:none_feminine'), isDisplayed: true, isDefaultOption: true
        },
        {
            id: 'day', freq: RRule.DAILY, value: t('common:everyday'), isDisplayed: isLessThan24Hours
        },
        {
            id: 'dayoftheweek', freq: RRule.DAILY, value: t('common:every_day_of_the_week_without_weekend'), isDisplayed: isLessThan24Hours && areDaysOfTheWeek
        },
        {
            id: 'week', freq: RRule.WEEKLY, value: weekOptionValue, isDisplayed: isLessThanAWeek
        },
        {
            id: 'month', freq: RRule.MONTHLY, value: monthOptionValue, isDisplayed: isLessThanAMonth
        },
        {
            id: 'year', freq: RRule.YEARLY, value: yearOptionValue, isDisplayed: isLessThanAYear
        }
    ];
    const rruleString = props.state.rrule?.replace('RRULE:', '');
    const [isCustomRecurrence, setIsCustomRecurrence] = useState(
        props.isEditionModal
            ? (
                // By default, even if it's a custom recurrence (when we created it),
                // if we find à corresponding 'not custom' recurrence, we choose the 'not custom' recurrence
                // => The only way for it to be a custom recurrence by default on the edition modal is :
                // if the interval is > 1 OR it's a WEEKLY rrule with custom days (but not only one day, not every day and not every day but weekends)
                getIntParamValueFromRrule(rruleString, 'INTERVAL=') > 1
                || getStringParamValueFromRrule(rruleString, 'FREQ=') === 'WEEKLY'
                && getStringParamValueFromRrule(rruleString, 'BYDAY=')?.split(',').length > 1
                && getStringParamValueFromRrule(rruleString, 'BYDAY=')?.split(',').sort().join('') !== ['MO', 'TU', 'WE', 'TH', 'FR'].sort().join('')
                && getStringParamValueFromRrule(rruleString, 'BYDAY=')?.split(',').length !== 7
            ) : false
    );

    const [displayedRecurrenceOptions, setDisplayedRecurrenceOptions] = useState((isCustomRecurrence ? customRecurrenceOptions : recurrenceOptions).filter(option => option.isDisplayed));
    const getEditionModalDefaultValues = () => { // Retrieve the values to put in every recurrence fields for the edition modal
        const freq = getStringParamValueFromRrule(rruleString, 'FREQ='); // Ex: FREQ=DAILY
        const interval = getIntParamValueFromRrule(rruleString, 'INTERVAL='); // Ex: INTERVAL=1
        const count = getIntParamValueFromRrule(rruleString, 'COUNT='); // Ex: COUNT=3
        const until = getStringParamValueFromRrule(rruleString, 'UNTIL='); // Ex: UNTIL=20250303T150059Z
        // Get byday as an array, for example : ['MO', 'TU']
        const byweekday = getStringParamValueFromRrule(rruleString, 'BYDAY=')?.split(','); // Ex: BYDAY=MO,TU,WE,TH,FR
        // Get bymonthday as an array, for example : [12, 18]
        const bymonthday = getStringParamValueFromRrule(rruleString, 'BYMONTHDAY=')?.split(',').map(monthday => parseInt(monthday, 10)); // Ex: BYMONTHDAY=18,12
        const bymonth = getStringParamValueFromRrule(rruleString, 'BYMONTH=')?.split(',').map(monthnum => parseInt(monthnum, 10)); // Ex: BYMONTH=1,4
        const bysetpos = getIntParamValueFromRrule(rruleString, 'BYSETPOS='); // Ex: BYSETPOS=-1
        const isEveryDayButWeekends = byweekday?.sort().join('') === ['MO', 'TU', 'WE', 'TH', 'FR'].sort().join('');

        // Filter the recurrence options based on the current freq
        let selectedOption = displayedRecurrenceOptions.filter(opt => opt.isDisplayed).filter(opt => opt.freq === RRule[`${freq}`]);

        if (freq === 'WEEKLY' && interval === 1 && (isEveryDayButWeekends || byweekday?.length === 7)) { // Equals to every day or every day but weekend
            selectedOption = [displayedRecurrenceOptions.filter(opt => opt.isDisplayed)
                .filter(opt => opt.freq === RRule.DAILY)
                .find(option => option.id === (isEveryDayButWeekends ? 'dayoftheweek' : 'day'))];
        } else if (freq === 'DAILY' && selectedOption.length > 1) { // Since there are multiple recurrence with 'freq: RRule.DAILY' we need to find the right one depending on the given rrule
            if (count === 1) {
                selectedOption = [selectedOption.find(option => option.id === 'none')]; // No recurrence option
            } else if (isEveryDayButWeekends) {
                selectedOption = [selectedOption.find(option => option.id === 'dayoftheweek')]; // Every day but weekend recurrence
            } else {
                selectedOption = [selectedOption.find(option => option.id === 'day')]; // Every day recurrence
            }
        }
        const newRec = selectedOption[0];

        const newRRule = {
            freq: newRec.freq,
            interval,
            byweekday: byweekday?.map(weekDayId => RRule[weekDayId]),
            bymonthday,
            bysetpos: !isNaN(bysetpos) ? bysetpos : null,
            bymonth,
            count,
            until
        };
        let newEndRec;
        if (until) {
            newEndRec = endOfRecurrenceOptions.find(opt => opt.id === 'the');
        } else if (selectedOption.id === 'none' || !count) {
            newEndRec = endOfRecurrenceOptions.find(opt => opt.id === 'never');
        } else {
            newEndRec = endOfRecurrenceOptions.find(opt => opt.id === 'after');
        }
        return [newRec, newRRule, newEndRec];
    };

    const [recurrence, setRecurrence] = useState(props.isEditionModal && initialRender.current ? getEditionModalDefaultValues()[0] : displayedRecurrenceOptions.find(opt => opt.isDefaultOption));
    const [endOfRecurrence, setEndOfRecurrence] = useState(props.isEditionModal && initialRender.current ? getEditionModalDefaultValues()[2] : endOfRecurrenceOptions.find(opt => opt.id === 'never'));
    const initialRrule = { // RRULE:FREQ=DAILY;INTERVAL=1;COUNT=1
        freq: recurrence.freq,
        interval: 1,
        byweekday: null,
        bymonthday: null,
        bysetpos: null,
        bymonth: null,
        count: 1,
        until: null
    };
    const isDisabledRecurrence = props.areInErrorEventsDates;
    const [rrule, setRrule] = useState(props.isEditionModal && initialRender.current ? getEditionModalDefaultValues()[1] : initialRrule);
    const minYearInterval = Math.floor(props.state.ended_at.diff(props.state.started_at, 'years', true)) + 1;
    const minMonthInterval = Math.floor(props.state.ended_at.diff(props.state.started_at, 'months', true)) + 1;
    const minWeekInterval = Math.floor(props.state.ended_at.diff(props.state.started_at, 'weeks', true)) + 1;
    const minDayInterval = Math.floor(props.state.ended_at.diff(props.state.started_at, 'days', true)) + 1;
    const startedAtDayIdx = props.state.started_at.day();
    const weekDays = getWeekDays();
    const startedAtDay = startedAtDayIdx === 0 ? weekDays[weekDays.length - 1] : weekDays[startedAtDayIdx - 1];
    const [selectedDays, setSelectedDays] = useState(
        props.isEditionModal
            ? weekDays.filter(weekDay => getStringParamValueFromRrule(rruleString, 'BYDAY=')?.split(',').includes(weekDay.id)).map(wk => wk.value)
            : [startedAtDay.value]
    );

    useEffect(() => { // Everytime the custom recurrence checkbox changes, we need to reset the recurrence list and the current recurrence
        if (!initialRender.current || !props.isEditionModal) {
            const defaultRecurrenceOptions = (isCustomRecurrence ? customRecurrenceOptions : recurrenceOptions).filter(option => option.isDisplayed);
            setDisplayedRecurrenceOptions(defaultRecurrenceOptions);
            setRecurrence(defaultRecurrenceOptions.find(opt => opt.isDefaultOption));
        }
    }, [isCustomRecurrence]);

    useEffect(() => { // Everytime the dates change, we need to reset the recurrence list and the current recurrence when required to
        if (!initialRender.current || !props.isEditionModal) {
            const newRecurrenceOptions = (isCustomRecurrence ? customRecurrenceOptions : recurrenceOptions).filter(option => option.isDisplayed);
            const intervalIsWrong = recurrence.id !== 'none' && (
                recurrence.freq === RRule.DAILY && (rrule.interval < minDayInterval || rrule.interval > daysLimit)
                || recurrence.freq === RRule.WEEKLY && (rrule.interval < minWeekInterval || rrule.interval > weeksLimit)
                || recurrence.freq === RRule.MONTHLY && (rrule.interval < minMonthInterval || rrule.interval > monthsLimit)
                || recurrence.freq === RRule.YEARLY && (rrule.interval < minYearInterval || rrule.interval > yearsLimit)
            );

            // If the current reccurence is not available in the list OR the selected interval is wrong
            // => We reset the recurrence and display a notif explaining why
            if (!newRecurrenceOptions.find(option => option.id === recurrence.id && option.value === recurrence.value) || intervalIsWrong) {
                // Couldn't put the new notif format (with dispatch) because this notif couldn't appear at the same time as the eventDurationErrorNotif
                toast(<Toast
                    message={t('notifications:recurrence_was_reset')}
                    icon={'exclamationTriangleLight'}
                    type={'warning'}
                />, {
                    position: toast.POSITION.BOTTOM_LEFT,
                    className: 'normal',
                    bodyClassName: 'notification-body grow-font-size',
                    progressClassName: 'warning-progress-bar',
                    autoClose: 7000
                });
                // props.setIsChosenRecurrenceValue(false); // If we displayed the notif once, we don't want to display it a second time unless the recurrence is modified
                setRecurrence(newRecurrenceOptions.find(opt => opt.isDefaultOption));
            }
            setDisplayedRecurrenceOptions(newRecurrenceOptions);
        }
    }, [props.state.started_at, props.state.ended_at]);

    useEffect(() => { // Every time the recurrence changes we reset the selectedDays, endOfRecurrence and is_until_end_of_month fields
        if (!initialRender.current || !props.isEditionModal) {
            setSelectedDays([startedAtDay.value]);
            setEndOfRecurrence(endOfRecurrenceOptions.find(opt => opt.id === 'never'));
            // By default, events lasting more than 24 hours, ending on the 31 and with the MONTHLY freq must be considered as is_until_end_of_month
            props.setState({ ...props.state, is_until_end_of_month: props.state.ended_at.date() === 31 && !isLessThan24Hours && isLessThanAMonth && recurrence.freq === RRule.MONTHLY });
            // After changing the recurrence, we set the rrule depending on the right default values for each recurrence
            if (recurrence.freq === RRule.DAILY) { // DAILY rrule can only modify the count, interval and byweekday params
                if (recurrence.id === 'none') { // The none option default value is the initialRrule
                    setRrule(initialRrule);
                } else {
                    setRrule({
                        ...initialRrule,
                        count: null,
                        interval: minDayInterval,
                        byweekday: recurrence.id === 'dayoftheweek'
                            ? getWeekDays().filter(weekDay => weekDay.id !== 'SA' && weekDay.id !== 'SU').map(weekDay => RRule[weekDay.id])
                            : null
                    });
                }
            } else if (recurrence.freq === RRule.WEEKLY) { // WEEKLY rrule can only modify the count, interval and byweekday params
                setRrule({
                    ...initialRrule,
                    count: null,
                    interval: minWeekInterval,
                    byweekday: isLessThan24Hours ? getByWeekDayFromSelectedDays([startedAtDay.value]) : null
                });
            } else if (recurrence.freq === RRule.MONTHLY) { // MONTHLY rrule can only modify the count, interval, byweekday, bymonthday and bysetpos params
                // By default the selected radio option is NOT the "untilLastDayOfMonth" (so we do not set the bysetpos and byweekday here)
                setRrule({
                    ...initialRrule,
                    count: null,
                    interval: minMonthInterval,
                    bymonthday: props.state.started_at.date(),
                    bysetpos: null
                });
            } else if (recurrence.freq === RRule.YEARLY) { // YEARLY rrule can only modify the count, interval, bymonth and bymonthday params
                setRrule({
                    ...initialRrule,
                    count: null,
                    interval: minYearInterval,
                    bymonthday: props.state.started_at.date(),
                    bymonth: props.state.started_at.month() + 1
                });
            }
        }
    }, [recurrence]);

    useEffect(() => { // Check if the section is complete and update the rrule
        if (!initialRender.current || !props.isEditionModal) {
            props.setSectionValidation(!isNaN(rrule.interval) && !isNaN(rrule.count));
            props.setState({...props.state, rrule: new RRule(rrule).toString()});
        }
    }, [rrule]);

    useEffect(() => {
        initialRender.current = false;
    }, []);

    const intervalInputComponent = (firstText, lastText, min, max) => (
        <div className={'interval-input'}>
            {firstText}
            <Component
                componentName={'EditableField'}
                renderValue={'Input'}
                editionComponent={'Input'}
                required
                props={{
                    disabled: isDisabledRecurrence,
                    value: rrule.interval,
                    changeInputValue: rrule.interval,
                    forbidMathsSymbolsSymbols: true,
                    onlyNumber: true,
                    type: 'number',
                    onChange: value => { if (value) { setRrule({ ...rrule, interval: Number(value) }); } },
                    inputProps: { min, max, step: 1 },
                    error: true
                }}
            />
            {lastText}
        </div>
    );

    const getYearComponent = () => intervalInputComponent(t('common:every'), t('dashboards:year_plural'), minYearInterval, yearsLimit);

    const getMonthComponent = () => {
        const monthInputInterval = recurrence.id === 'custom_month' && intervalInputComponent(t('common:every'), t('rounds:month'), minMonthInterval, monthsLimit);
        if (isLessThan24Hours && props.state.started_at.date() === props.state.started_at.daysInMonth()) {
            // If events last less than 24 hours and started_at is the last day of the month
            return (
                <RadioGroup
                    className={'fields-with-radio-button'}
                    defaultValue={getIntParamValueFromRrule(rruleString, 'BYSETPOS=') === -1 ? 'untilLastDayOfMonth' : 'startDay'}
                    onChange={event => {
                        if (event.target.value === 'untilLastDayOfMonth') {
                            setRrule({
                                ...rrule, byweekday: getWeekDays().map(weekDay => RRule[weekDay.id]), bysetpos: -1, bymonthday: null
                            });
                            props.setState({...props.state, is_until_end_of_month: true});
                        } else {
                            setRrule({
                                ...rrule, byweekday: null, bysetpos: null, bymonthday: props.state.started_at.date()
                            });
                            props.setState({...props.state, is_until_end_of_month: false});
                        }
                    }}
                >
                    <div className={'recurrence-fields'}>{monthInputInterval}</div>
                    <div className={'radio-option-with-tooltip'}>
                        <FormControlLabel
                            className={'second-main-color-radio'}
                            value={'startDay'}
                            control={<Radio />}
                            label={`${t('common:the')} ${props.state.started_at.date()}`}
                        />
                        <Tooltip
                            className={'second-main-color-tooltip'}
                            placement={'top'}
                            classes={tooltipStyle}
                            title={t('rounds:the_last_x_day_of_month_option', { nbDay: props.state.started_at.daysInMonth() })}
                        >
                            <div><Component componentName={'Icon'} type={'info'} /></div>
                        </Tooltip>
                    </div>
                    <div className={'radio-option-with-tooltip'}>
                        <FormControlLabel
                            className={'second-main-color-radio'}
                            value={'untilLastDayOfMonth'}
                            control={<Radio />}
                            label={`${t('common:the')} ${t('common:last_day_of_the_month')}`}
                        />
                        <Tooltip
                            className={'second-main-color-tooltip'}
                            placement={'top'}
                            classes={tooltipStyle}
                            title={t('rounds:the_last_day_of_month_option')}
                        >
                            <div><Component componentName={'Icon'} type={'info'} /></div>
                        </Tooltip>
                    </div>
                </RadioGroup>
            );
        }
        if (isLessThanAMonth && props.state.ended_at.date() === props.state.ended_at.daysInMonth() && props.state.ended_at.date() !== 31) {
            if (props.state.ended_at.date() === 31) {
                return <></>;
            } else {
                // If events last more than or are equal to 24hours but last less than 1 month and ended_at is the last day of the month
                return (
                    <RadioGroup
                        className={'fields-with-radio-button'}
                        defaultValue={props.state.is_until_end_of_month ? 'untilLastDayOfMonth' : 'startDay'}
                        onChange={event => {
                            if (event.target.value === 'untilLastDayOfMonth') {
                                setRrule({...rrule, bymonthday: props.state.started_at.date()});
                                props.setState({...props.state, is_until_end_of_month: true});
                            } else {
                                setRrule({...rrule, bymonthday: props.state.started_at.date()});
                                props.setState({...props.state, is_until_end_of_month: false});
                            }
                        }}
                    >
                        <div className={'recurrence-fields'}>{monthInputInterval}</div>
                        <div className={'radio-option-with-tooltip'}>
                            <FormControlLabel
                                className={'second-main-color-radio'}
                                value={'startDay'}
                                control={<Radio/>}
                                label={`${t('graphs:custom_date', { startDate: props.state.started_at.date(),  endDate: props.state.ended_at.date() })}`}
                            />
                            <Tooltip
                                className={'second-main-color-tooltip'}
                                placement={'top'}
                                classes={tooltipStyle}
                                title={t('rounds:until_last_x_day_of_month_option', {nbDay: props.state.ended_at.daysInMonth()})}
                            >
                                <div><Component componentName={'Icon'} type={'info'}/></div>
                            </Tooltip>
                        </div>
                        <div className={'radio-option-with-tooltip'}>
                            <FormControlLabel
                                className={'second-main-color-radio'}
                                value={'untilLastDayOfMonth'}
                                control={<Radio/>}
                                label={t('common:from_date_to_end_of_month', {date: props.state.started_at.date()})}
                            />
                            <Tooltip
                                className={'second-main-color-tooltip'}
                                placement={'top'}
                                classes={tooltipStyle}
                                title={t('rounds:until_last_day_of_month_option')}
                            >
                                <div><Component componentName={'Icon'} type={'info'}/></div>
                            </Tooltip>
                        </div>
                    </RadioGroup>
                );
            }
        }
        return monthInputInterval; // Else we display the interval field
    };

    const getWeekComponent = () => {
        const weekInputInterval = intervalInputComponent(t('common:every_feminine'), t('common:weeks'), minWeekInterval, weeksLimit);
        if (isLessThan24Hours) { // If events last less than 24 hours
            return (
                <>
                    {weekInputInterval}
                    <div className={'weekday-choice-wrapper'}>
                        <div className={'weekday-choice'}>
                            {weekDays.map(weekDay => (
                                <Chip
                                    key={weekDay.id}
                                    clickable
                                    classes={selectedDays?.includes(weekDay.value)
                                        ? { root: 'weekday-chip selected-weekday-chip' }
                                        : { root: 'weekday-chip' }
                                    }
                                    value={weekDay.value}
                                    onClick={() => {
                                        const selectedDaysCopy = selectedDays?.length > 0 ? [...selectedDays] : [];
                                        if (!selectedDaysCopy.find(selectedDay => selectedDay === weekDay.value)) {
                                            selectedDaysCopy.push(weekDay.value);
                                        } else if (weekDay.value !== startedAtDay.value) { // We cannot remove the day selected by default
                                            selectedDaysCopy.splice(selectedDaysCopy.indexOf(weekDay.value), 1);
                                        }
                                        setSelectedDays(selectedDaysCopy);
                                        setRrule({ ...rrule, byweekday: getByWeekDayFromSelectedDays(selectedDaysCopy) });
                                    }}
                                    className={'group-name'}
                                    component={'div'}
                                    label={gettext(weekDay.value).charAt(0).toUpperCase()}
                                />
                            ))}
                            {selectedDays?.length === 7 && <span className={'all-days-selected-text-star'}>*</span>}
                        </div>
                        {selectedDays?.length === 7 && <span className={'all-days-selected-text'}>{`* ${t('common:all_days_selected')}`}</span>}
                    </div>
                </>
            );
        }
        if (isLessThanAWeek) { // If events last more than or are equal to 24hours but last less than a week
            const fromDayToDayString = `${t('common:weeks')} ${ t('graphs:custom_label', { from_timestamp: t(`common:${getDayName(props.state.started_at.day()).toLowerCase()}`), to_timestamp: t(`common:${getDayName(props.state.ended_at.day()).toLowerCase()}`) })}`;
            return intervalInputComponent(t('common:every_feminine'), fromDayToDayString, minWeekInterval, weeksLimit);
        }
        return weekInputInterval; // Else we display the interval field
    };

    const getDayComponent = () => intervalInputComponent(t('common:every'), t('rounds:days'), minDayInterval, daysLimit);

    return (
        <>
            <div className={'section-explanation'}>
                <Trans>{t('rounds:recurrence_explanation')}</Trans>
            </div>
            <div className={'fields-wrapper'}>
                <div className={'second-main-color-checkbox'}>
                    <Checkbox
                        checked={isCustomRecurrence}
                        onClick={() => setIsCustomRecurrence(!isCustomRecurrence)}
                    />
                    {t('common:personalize')}
                </div>
                <div className={'recurrence-fields-wrapper'}>
                    <span className={'recurrence-text'}>{`${t('rounds:recurrence')} :`}</span>
                    <Tooltip
                        title={isDisabledRecurrence ? <Trans>{t('rounds:disabled_recurrence')}</Trans> : ''}
                        className={'second-main-color-tooltip'}
                        placement={'top'}
                        classes={tooltipStyle}
                    >
                        <div className={classnames(['recurrence-fields', { 'custom-recurrence-fields': isCustomRecurrence }])}>
                            <Component
                                componentName={'EditableField'}
                                renderValue={'Select'}
                                editionComponent={'Select'}
                                required
                                props={{
                                    required: true,
                                    disabled: isDisabledRecurrence,
                                    options: displayedRecurrenceOptions,
                                    selected: recurrence,
                                    changeSelectValue: recurrence,
                                    onChange: value => setRecurrence(value)
                                }}
                            />
                            {recurrence.id === 'custom_day' && getDayComponent()}
                            {recurrence.id === 'custom_week' && getWeekComponent()}
                            {(recurrence.id === 'custom_month' || recurrence.id === 'month') && getMonthComponent()}
                            {recurrence.id === 'custom_year' && getYearComponent()}
                        </div>
                    </Tooltip>
                </div>
            </div>
            <div className={'fields-wrapper'}>
                <div className={'fields-wrapper-title'}>{t('rounds:end_of_recurrence_title')}</div>
                <div>
                    <Tooltip
                        title={recurrence.id === 'none' ? <Trans>{t('rounds:disabled_end_of_recurrence')}</Trans> : ''}
                        className={'second-main-color-tooltip'}
                        placement={'top'}
                        classes={tooltipStyle}
                    >
                        <div className={'end-of-recurrence-fields'}>
                            <span className={'end-of-recurrence-text'}>{`${t('rounds:end_of_recurrence')} :`}</span>
                            <Component
                                componentName={'EditableField'}
                                renderValue={'Select'}
                                editionComponent={'Select'}
                                required
                                props={{
                                    required: true,
                                    disabled: recurrence.id === 'none',
                                    options: endOfRecurrenceOptions,
                                    selected: endOfRecurrence,
                                    changeSelectValue: endOfRecurrence,
                                    onChange: value => {
                                        // Update the end of recurrence field + reset the rrule with the right default values
                                        setEndOfRecurrence(value);
                                        if (value.id === 'after') {
                                            setRrule({ ...rrule, count: 1, until: null });
                                        } else if (value.id === 'the') {
                                            setRrule({ ...rrule, count: null, until: getUntilDateIsoStringFromDateAndEndedAt(moment(props.state.ended_at), props.state.ended_at.format('HH[:]mm')) });
                                        } else {
                                            setRrule({ ...rrule, count: null, until: null });
                                        }
                                    }
                                }}
                            />
                            {endOfRecurrence.id === 'after' && (
                                <>
                                    <Component
                                        title={''}
                                        required
                                        componentName={'EditableField'}
                                        renderValue={'Input'}
                                        editionComponent={'Input'}
                                        value={getIntParamValueFromRrule(rruleString, 'COUNT=') || 1} // Value for editionModal
                                        props={{
                                            value: 1,
                                            forbidMathsSymbolsSymbols: true,
                                            onlyNumber: true,
                                            type: 'number',
                                            onChange: value => { // We cannot put empty values
                                                if (value) { setRrule({ ...rrule, count: Number(value), until: null }); }
                                            },
                                            inputProps: { min: 1, step: 1 },
                                            error: true
                                        }}
                                    />
                                    {t(`rounds:${getIntParamValueFromRrule(rruleString, 'COUNT=') > 1 ? 'instances' : 'instance'}`)}
                                </>
                            )}
                            {endOfRecurrence.id === 'the' && (
                                <Component
                                    componentName={'DayPicker'}
                                    customString={''}
                                    date={getUntilDateFromDateAndEndedAt(moment(rrule.until || props.state.ended_at), moment(props.state.ended_at).format('HH[:]mm'))}
                                    minDate={moment(props.state.ended_at).toDate()}
                                    onChange={value => {
                                        setRrule({ ...rrule, count: null, until: getUntilDateIsoStringFromDateAndEndedAt(value, props.state.ended_at.format('HH[:]mm')) });
                                    }}
                                />
                            )}
                        </div>
                    </Tooltip>
                </div>
            </div>
            <div className={'fields-wrapper second-main-color-chip'}>
                <div className={'fields-wrapper-title'}>
                    {t('rounds:select_people_to_alert')}
                    <Tooltip
                        className={'second-main-color-tooltip'}
                        placement={'top'}
                        classes={tooltipStyle}
                        title={t('rounds:select_people_to_alert_explanation')}
                    >
                        <div><Component componentName={'Icon'} type={'info'} /></div>
                    </Tooltip>
                </div>
                <Component
                    componentName={'EditableField'}
                    renderValue={'SelectRoundUsers'}
                    editionComponent={'SelectRoundUsers'}
                    props={{
                        usersFieldType: 'followers',
                        deviceIds: props.state.devices.map(device => device.id),
                        onChange: value => {
                            props.setState({ ...props.state, followers_ids: value.followers_ids, invite_followers_ids: value.invite_followers_ids })
                        },
                        selected: props.state.followers_ids?.concat(props.state.invite_followers_ids)
                    }}
                />
            </div>
            <div className={'fields-wrapper second-main-color-chip'}>
                <div className={'fields-wrapper-title'}>
                    {t('rounds:select_people_to_assign')}
                    <Tooltip
                        className={'second-main-color-tooltip'}
                        placement={'top'}
                        classes={tooltipStyle}
                        title={t('rounds:select_people_to_assign_explanation')}
                    >
                        <div><Component componentName={'Icon'} type={'info'} /></div>
                    </Tooltip>
                </div>
                <Component
                    componentName={'EditableField'}
                    renderValue={'SelectRoundUsers'}
                    editionComponent={'SelectRoundUsers'}
                    props={{
                        usersFieldType: 'assignees',
                        deviceIds: props.state.devices.map(device => device.id),
                        onChange: value => props.setState({ ...props.state, assignees_ids: value }),
                        selected: props.state.assignees_ids
                    }}
                />
            </div>
        </>
    );
};

export default RoundModalThirdSection;

import { find } from 'lodash';

// Get a dashboard by id
export const getDashboardById = (dashboards, dashboard_id) => find(dashboards, { dashboard_id });

export const formatStringToTime = value => {
    switch (value) {
        case '90 00:00:00':
            return 60 * 60 * 24 * 90;
        case '30 00:00:00':
            return 60 * 60 * 24 * 30;
        case '14 00:00:00':
            return 60 * 60 * 24 * 14;
        case '7 00:00:00':
            return 60 * 60 * 24 * 7;
        case '1 00:00:00':
            return 60 * 60 * 24;
        case '0 1:00:00':
        case '01:00:00':
        case '1:00:00':
            return 60 * 60;
        default:
            return null;
    }
};

export const getPressCountUnit = (t, value) => (
    value <= 1
        ? t('devices:press_count_unit')
        : t('devices:press_count_units')
);

export const getPressCountInTotal = (t, value) => (
    value === 1
        ? t('graphs:click_in_total')
        : t('graphs:click_in_total_plural')
);

export const easeOutQuad = (a, b, c, d) => {
    return -c *(a/=d)*(a-2) + b;
}


export default function helper() {
    return 'dashboard helper';
}

// Libs
import moment from 'moment';
import 'moment/locale/fr';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Context
import { useDispatch, useSelector } from 'react-redux';
// Components
import { getPassageVisualization } from '../actions';
import createLoadingSelector from '../../layout/actions';
import CalendarClock from '../../layout/assets/calendarClock.svg';
import QRCodeBackButton from '../../qrcodes/components/QRCodeBackButton';

// Helpers
import { useComponentsPool } from '../../../ComponentsPool';
import { getUserFromId, getUserName, canSeeMobileRoundView } from '../../users/helper';
import {isEmpty} from "lodash";

moment.locale(localStorage.getItem('i18nextLng'));

const loadingSelector = createLoadingSelector(['GET_DEVICE', 'GET_USER_LIST', 'GET_PASSAGE_VISUALIZATION']);

const VisualizationPassage = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const currentUser = useSelector(state => state.users.currentUser);
    const userList = useSelector(state => state.users.list);
    const devicesList = useSelector(state => state.devices.list);
    const [passageDevice, setPassageDevice] = useState({});
    const [passages, setPassages] = useState({ data: [] });
    const isLogged = useSelector(state => state.authentication.isLogged);
    const canAccessVisualizationPassagePage = passages.data?.length > 0;
    const workspaceSettings = useSelector(state => state.workspace.settings);

    useEffect(() => {
        if (devicesList.length > 0) {
            setPassageDevice(devicesList[0]);
            dispatch(getPassageVisualization(devicesList[0].device_id, !isLogged)).then(res => setPassages(res.value));
        }
    }, []);

    const getCannotAccessVisualizationPassagePage = () => (
        <Component
            componentName={'MobileErrorPage'}
            title={t('passages:no_passage')}
            subtitle={t('passages:teams_fix_it')}
            icon={<CalendarClock />}
            hasGoBackArrow={props.qrcodesHasManyMode}
        />
    );

    const getDeviceLocation = () => {
        const locationParts = [];

        if (passageDevice.batiment_group?.group_name) { locationParts.push(passageDevice.batiment_group.group_name); }
        if (passageDevice.floor_group?.group_name) { locationParts.push(passageDevice.floor_group.group_name); }
        if (passageDevice.position_group?.group_name) { locationParts.push(passageDevice.position_group.group_name); }

        return locationParts.join(' - ');
    };

    const getAgentAndJobs = item => {
        // Workspace setting for occupants and non-connected users => allows them to see service name on last ten visits 
        const canSeeServiceNameOfLastTenVisits = workspaceSettings?.['show_jobs_of_qrcode_passages_for_users_and_non_connected_users'] ?? false;
        if (canSeeMobileRoundView(currentUser)) {
            const agent_identity = getUserName(getUserFromId(userList, item.agent)) || t('passages:anonymous_user');
            return (
                <>
                    <div className={'passage-agent-wrapper'}>
                        <p className={'title'}>{`${t('passages:passage_executed_by')} :`}</p>
                        <span className={'text'}>{agent_identity}</span>
                    </div>
                    {item.jobs.length > 0 && (
                        <>
                            <p className={'title'}>{`${t('passages:services')} :`}</p>
                            <span className={'text'}>{item.jobs.join(', ')}</span>
                        </>
                    )}
                </>
            );
        }
        // Only Non-connected users OR occupants with enabled WS (canSeeServiceNameOfLastTenVisits) can see the name of jobs.
        return (
            <>
                <p className={'title'}>{t('passages:passage_completed')}</p>
                { canSeeServiceNameOfLastTenVisits && !isEmpty(item.jobs) ? (
                    <span className={'text'}>{item.jobs.join(', ')}</span>
                ) : null }
            </>
        );
    };

    const getPassageItem = (item, previousItem, index) => {
        let date = '';
        let time = '';
        let displayDate = true;

        // Calculate date and time of the passage
        if (item.timestamp) {
            const datetime = moment(item.timestamp);
            date = datetime.format('dddd D MMMM').replace(/^\w/, firstLetter => firstLetter.toUpperCase());
            const offSet = -new Date().getTimezoneOffset();
            time = datetime.utcOffset(offSet).format('HH[h]mm');
        }

        // Check if we need to display the date or if it was already displayed
        if (previousItem?.timestamp && item?.timestamp) {
            const previousDate = moment(previousItem.timestamp).format('dddd D MMMM');
            const currentDate = moment(item.timestamp).format('dddd D MMMM');
            displayDate = currentDate !== previousDate;
        }

        return (
            <>
                {displayDate && (
                    <div className={'passages-date'}>
                        <div className="date-line" />
                        <p>{date}</p>
                        <div className="date-line" />
                    </div>
                )}
                <div className={'passage-wrapper'} key={`passage-${index}`}>
                    <div className={'passage-time'}>{time}</div>
                    <div className={'passage-line-wrapper'}>
                        <div className={'passage-line'} />
                        <div className={'passage-bubble'} />
                    </div>
                    <div className={'passage-job-and-agent-wrapper'}>
                        {getAgentAndJobs(item)}
                    </div>
                </div>
            </>
        );
    };

    const getPassagesList = () => {
        const itemList = [];
        for (let i = 0; i < passages.data.length; i++) {
            const currentItem = passages.data[i];
            const previousItem = i > 0 ? passages.data[i - 1] : null;

            const listItem = getPassageItem(currentItem, previousItem, i);
            itemList.push(listItem);
        }
        return itemList;
    };

    const getLastTenPassages = () => (
        <>
            <div className={'visualization-passage-title-wrapper'}>
                {props.qrcodesHasManyMode && <QRCodeBackButton />}
                <div className={'visualization-passage-title'}>
                    <p>{t('passages:last_ten_passages')}</p>
                    <span className={'qrcode-device-locations'}>{getDeviceLocation()}</span>
                </div>
            </div>
            <div className="visualization-passages-content-wrapper">
                <div className={'visualization-passages-content'}>
                    {getPassagesList()}
                </div>
            </div>
        </>
    );

    return (
        <div className={'incident-page'}>
            {isLoading
                ? <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
                : canAccessVisualizationPassagePage
                    ? getLastTenPassages()
                    : getCannotAccessVisualizationPassagePage()
            }
        </div>
    );
};

export default VisualizationPassage;

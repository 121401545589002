// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useComponentsPool } from '../../../ComponentsPool';
import { getTemplatesIdsFromSkill, shapeTemplatesListAsGroupsList } from '../../templates/helper';
import { getTicketsFromSkill, getGroupNameFromId } from '../../groups/helper';
import { concatTickets } from '../../tickets/helper';

const SkillsTable = () => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const groupList = useSelector(state => state.groups.list);
    const [rows, setRows] = useState([]);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const templatesList = useSelector(state => state.templates.list);
    const [templates, setTemplates] = useState(shapeTemplatesListAsGroupsList(templatesList));
    const ticketList = useSelector(state => state.tickets.list);
    const modeTitleIsClose = workspaceSettings.mode_title === 'close';
    const oneSkillHasTemplatesTitle = templatesList.find(template => template.groups?.length > 0);

    useEffect(() => {
        setTemplates(shapeTemplatesListAsGroupsList(templatesList));
    }, [templatesList]);

    useEffect(() => {
        setRows(groupList
            .filter(group => group.custom_field.type === 'SKILL')
            .map(group => ({
                name: group.group_name,
                id: group.group_id,
                nameIcon: getGroupNameFromId(groupList, group.group_id),
                group_ids: getTemplatesIdsFromSkill(templatesList, group.group_id),
                linked_tickets_count: getTicketsFromSkill(group.group_id, concatTickets(ticketList)).length
            }))
        );
    }, [groupList, ticketList]);

    const headCells = [
        {
            id: 'name',
            toHide: false,
            hasIcon: true,
            positionToRight: false,
            disablePadding: false,
            label: t('settings:first_column_name')
        },
        {
            id: 'templates_groups',
            toHide: !(modeTitleIsClose && oneSkillHasTemplatesTitle),
            hasIcon: false,
            positionToRight: false,
            disablePadding: false,
            label: t('settings:templates_column_name')
        }, {
            id: 'linked_resources',
            hasIcon: false,
            positionToRight: false,
            disablePadding: false,
            label: t('settings:linked_resources')
        }
    ];

    const columnsToHide = headCells.filter(cell => cell.toHide).map(cell => cell.id);
    return (
        <Component
            componentName={'SettingsTable'}
            headCells={headCells}
            tableTitle={'SKILL'}
            rows={rows}
            columnsToHide={columnsToHide}
            templatesList={templates}
        />
    );
};

export default SkillsTable;

import axios from 'axios';

const getWorkspaceByDeviceHardwareId = (hardware_id, refreshStore = true, forcingStrategy = null) => {
    const headers = {};

    if (forcingStrategy) {
        headers['X-Use-Strategy'] = forcingStrategy;
    }

    return {
        type: refreshStore ? 'GET_WORKSPACE_BY_DEVICE' : 'NO_REDUCER',
        payload: axios.get(`/workspace/?hardware_id=${hardware_id}`, { headers })
            .then(res => res.data)
    };
};

export default getWorkspaceByDeviceHardwareId;

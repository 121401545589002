// Libs
import update from 'immutability-helper';

const initialState = () => ({
    currentQRCodeTicket: null
});

const qrcodesReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'SET_CURRENT_QRCODE_FORM_TICKET':
            return update(state, { currentQRCodeTicket: { $set: action.payload } });
        default:
            return state;
    }
};

export default qrcodesReducer;

// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Helper
import { getDevicesOfGroup } from '../../../devices/helper';

const GroupAddDevice = props => {
    const [t] = useTranslation();
    const deviceList = useSelector(state => state.devices.list);
    const groupList = useSelector(state => state.groups.list);
    const [devices, setDevices] = useState(getDevicesOfGroup(deviceList, groupList, props.resource.group_id));

    useEffect(() => {
        setDevices(getDevicesOfGroup(deviceList, groupList, props.resource.group_id));
    }, [props.resource, deviceList]);

    return (
        <div className={'information-column-container'}>
            <span>{t('common:deviceWithCount', { count: devices.length })}</span>
        </div>
    );
};

export default GroupAddDevice;

// Libs
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import { Tooltip } from '@material-ui/core';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import moment from 'moment/moment';
import classnames from 'classnames';
import { getTimeOnTwoDigits, useSecondMainColorTooltip } from '../../../layout/helper';
import 'react-date-range/dist/styles.css';
import {
    eventDurationErrorNotif,
    startedAtBiggerThanEndedatErrorNotif
} from '../../../notification/actions';
import { useComponentsPool } from '../../../../ComponentsPool';
import { EVENT_MAX_DURATION } from '../../helper';


const RoundModalFirstSection = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const [isOnWholeDay, setIsOnWholeDay] = useState(props.isEditionModal
        ? props.state.started_at.hours() === 0 && props.state.started_at.minutes() === 0
            && props.state.ended_at.hours() === 23 && props.state.ended_at.minutes() === 59
        : false
    );
    const tooltipStyle = useSecondMainColorTooltip();
    const customTooltipStyle = useSecondMainColorTooltip({ padding: '30px !important', alignment: 'left' });
    const [startHoursAndMinutes, setStartHoursAndMinutes] = useState(props.isEditionModal
        ? `${props.state.started_at.hours()}:${props.state.started_at.minutes()}`
        : null
    );
    const [endHoursAndMinutes, setEndHoursAndMinutes] = useState(props.isEditionModal
        ? `${props.state.ended_at.hours()}:${props.state.ended_at.minutes()}`
        : null
    );
    const dispatch = useDispatch();
    const [areInErrorDates, setAreInErrorDates] = useState(false);

    const changeOnWholeDay = () => {
        // If it's going to be OnWholeDay we set the hours and minutes to 00:00:00 and 23:59:59, and we reset the time slot fields
        // else we reset both the dates and the time slot fields
        props.setState({
            ...props.state,
            started_at: moment(props.state.started_at).set({ h: !isOnWholeDay ? 0 : 8, m: 0, s: 0 }),
            ended_at: moment(props.state.ended_at).set({ h: !isOnWholeDay ? 23 : 9, m: 59, s: 59 })
            // for !isOnWholeDay put hours different from 00:00 and 23:59 to prevent checkbox from checking itself when filling one of the hours field with 00:00 or 23:59
        });
        setStartHoursAndMinutes(null);
        setEndHoursAndMinutes(null);
        setIsOnWholeDay(!isOnWholeDay);
    };

    const changeTimeSlot = (timeSlotName, time) => {
        const hours = parseInt(getTimeOnTwoDigits(time.$H), 10);
        const minutes = parseInt(getTimeOnTwoDigits(time.$m), 10);
        if ( // If the timeslots are equal to 00:00 and 23:59 we set the check the onWholeDay checkbox
            timeSlotName === 'start' && hours === 0 && minutes === 0
            && moment(props.state.ended_at).hours() === 23 && moment(props.state.ended_at).minutes() === 59
            || timeSlotName === 'end' && hours === 23 && minutes === 59
            && moment(props.state.started_at).hours() === 0 && moment(props.state.started_at).minutes() === 0
        ) {
            changeOnWholeDay();
        } else if (timeSlotName === 'start') { // Update props.state.started_at
            setStartHoursAndMinutes(`${hours}:${minutes}`);
            props.setState({ ...props.state, started_at: moment(props.state.started_at).set({ h: hours, m: minutes, s: 0 }) });
        } else { // Update props.state.ended_at
            setEndHoursAndMinutes(`${hours}:${minutes}`);
            props.setState({ ...props.state, ended_at: moment(props.state.ended_at).set({ h: hours, m: minutes, s: 59 }) });
        }
    };

    useEffect(() => { // Check whether the event last less than a minute or more than a year
        const diffInMinutes = moment(props.state.ended_at).diff(moment(props.state.started_at), 'minutes');
        const diffInDays = moment(props.state.ended_at).diff(moment(props.state.started_at), 'days');
        if (startHoursAndMinutes && endHoursAndMinutes && moment(props.state.started_at) > moment(props.state.ended_at)) { // Started at is bigger than ended at
            setAreInErrorDates(true);
            dispatch(startedAtBiggerThanEndedatErrorNotif());
        } else if (startHoursAndMinutes && endHoursAndMinutes && diffInMinutes === 0) { // Events last less than a minute
            setAreInErrorDates(true);
            dispatch(eventDurationErrorNotif(true));
        } else if (diffInDays >= EVENT_MAX_DURATION) { // Events last more than the authorized duration
            setAreInErrorDates(true);
            dispatch(eventDurationErrorNotif(false));
        } else {
            setAreInErrorDates(false);
        }
    }, [props.state.started_at, props.state.ended_at]);

    useEffect(() => { // Check if the section is complete
        let isValidSection;
        const isValidRoundName = props.state.name && props.state.name !== 'error_field';
        const areValidHours = startHoursAndMinutes && startHoursAndMinutes !== 'error_field' && endHoursAndMinutes && endHoursAndMinutes !== 'error_field';
        if (isOnWholeDay) { // If the checkbox is checked, we don't need the time fields to be full for the section to be complete
            isValidSection = isValidRoundName && !areInErrorDates;
            props.setAreInErrorEventsDates(areInErrorDates); // If event duration is valid, user can choose a recurrence
        } else {
            isValidSection = isValidRoundName && areValidHours && !areInErrorDates;
            props.setAreInErrorEventsDates(!areValidHours || areInErrorDates); // If event duration is valid, user can choose a recurrence
        }
        props.setSectionValidation(isValidSection);
    }, [props.state.name, startHoursAndMinutes, endHoursAndMinutes, areInErrorDates, isOnWholeDay]);

    return (
        <>
            <div className={'section-explanation'}>
                <Trans>{t('rounds:event_explanation')}</Trans>
                <div><Trans>{t('rounds:round_explanation')}</Trans></div>
            </div>
            {props.isEditionModal
                && <div className={'section-explanation-with-margin-top'}><Trans>{t('rounds:round_edition_explanation')}</Trans></div>
            }
            <div className={'fields-wrapper'}>
                <div className={'fields-wrapper-title'}>{t('rounds:define_a_name')}</div>
                <Component
                    componentName={'EditableField'}
                    renderValue={'Input'}
                    editionComponent={'Input'}
                    required
                    value={props.state.name}
                    props={{
                        rules: ['maxLength-100'],
                        label: '',
                        value: props.state.name,
                        onChange: value => props.setState({ ...props.state, name: value })
                    }}
                />
            </div>
            <div className={'fields-wrapper'}>
                <div className={'fields-wrapper-title'}>
                    {props.isEditionModal ? t('rounds:set_next_event_duration') : t('rounds:set_first_event_duration')}
                    <Tooltip
                        className={'second-main-color-tooltip'}
                        placement={'top'}
                        classes={tooltipStyle}
                        title={t('rounds:event_duration_explanation')}
                    >
                        <div><Component componentName={'Icon'} type={'info'} /></div>
                    </Tooltip>
                </div>
                <div className={'second-main-color-checkbox'}>
                    <Checkbox
                        onClick={() => changeOnWholeDay()}
                        checked={isOnWholeDay}
                    />
                    {t('rounds:whole_day')}
                    <Tooltip
                        className={'second-main-color-tooltip'}
                        placement={'top'}
                        classes={tooltipStyle}
                        title={t('rounds:whole_day_explanation')}
                    >
                        <div><Component componentName={'Icon'} type={'info'} /></div>
                    </Tooltip>
                </div>
                <div className={classnames(['duration-fields', { 'dates-are-in-error': areInErrorDates }])}>
                    <Tooltip
                        className={'second-main-color-tooltip'}
                        placement={'left'}
                        classes={customTooltipStyle}
                        title={<Trans>{t(`rounds:event_duration_dates_explanation${props.isEditionModal ? '_shorter' : ''}`)}</Trans>}
                    >
                        <div><Component componentName={'Icon'} type={'info'} /></div>
                    </Tooltip>
                    <Component
                        componentName={'DayPicker'}
                        customString={`${t('rounds:beginning')}:`}
                        minDate={props.isEditionModal && moment().toDate()}
                        date={props.state.started_at}
                        onChange={value => {
                            const newStartedAt = moment(value).set({ h: moment(props.state.started_at).hours(), m: moment(props.state.started_at).minutes(), s: 0 });
                            const newEndedAt = moment(value).set({ h: moment(props.state.ended_at).hours(), m: moment(props.state.ended_at).minutes(), s: 59 });
                            // If the startDate is > to the endDate, we set the endDate to the same day
                            if (moment(value) > moment(props.state.ended_at)) {
                                props.setState({ ...props.state, started_at: newStartedAt, ended_at: newEndedAt });
                            } else {
                                props.setState({ ...props.state, started_at: newStartedAt });
                            }
                        }}
                    />
                    {!isOnWholeDay && (
                        <>
                            <span className={'time-choice-text'}>{` ${t('common:to_second')}`}</span>
                            <TimePicker
                                className={'hours-time-picker'}
                                timeSteps={{ hours: 1, minutes: 5 }}
                                ampm={false} // 24 hours clock
                                value={startHoursAndMinutes && dayjs().hour(startHoursAndMinutes.split(':')[0]).minute(startHoursAndMinutes.split(':')[1])}
                                slotProps={{ textField: { placeholder: '--:--' }, actionBar: { sx: { display: 'none' } } }}
                                onChange={time => {
                                    if (time && !isNaN(time.$H) && !isNaN(time.$m)) {
                                        changeTimeSlot('start', time);
                                    } else {
                                        setStartHoursAndMinutes('error_field');
                                    }
                                }}
                            />
                        </>
                    )}
                </div>
                <div className={classnames(['duration-fields', { 'dates-are-in-error': areInErrorDates }])}>
                    <Component
                        componentName={'DayPicker'}
                        customString={`${t('rounds:end')}:`}
                        minDate={moment(props.state.started_at).toDate()}
                        date={props.state.ended_at}
                        onChange={value => {
                            const newEndedAt = moment(value).set({ h: moment(props.state.ended_at).hours(), m: moment(props.state.ended_at).minutes(), s: 59 });
                            props.setState({ ...props.state, ended_at: newEndedAt });
                        }}
                    />
                    {!isOnWholeDay && (
                        <>
                            <span className={'time-choice-text'}>{` ${t('common:to_second')}`}</span>
                            <TimePicker
                                className={'hours-time-picker'}
                                timeSteps={{ hours: 1, minutes: 5 }}
                                ampm={false} // 24 hours clock
                                value={endHoursAndMinutes && dayjs().hour(endHoursAndMinutes.split(':')[0]).minute(endHoursAndMinutes.split(':')[1])}
                                slotProps={{ textField: { placeholder: '--:--' }, actionBar: { sx: { display: 'none' } } }}
                                onChange={time => {
                                    if (time && !isNaN(time.$H) && !isNaN(time.$m)) {
                                        changeTimeSlot('end', time);
                                    } else {
                                        setEndHoursAndMinutes('error_field');
                                    }
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className={'section-explanation'}>
                {moment(props.state.started_at).isSame(props.state.ended_at, 'day')
                    ? (
                        <Trans>
                            {`${props.isEditionModal ? t('rounds:next_event_duration_one_day') : t('rounds:first_event_duration_one_day')} `}
                            <span className={'bold-text'}>{moment(props.state.started_at).format('DD/MM/YY')}</span>
                            {`. ${t('rounds:define_recurrence_aim')}`}
                        </Trans>
                    ) : (
                        <Trans>
                            {`${props.isEditionModal ? t('rounds:next_event_duration') : t('rounds:first_event_duration')} `}
                            <span className={'bold-text'}>{`${moment(props.state.started_at).format('DD/MM/YY')} - `}</span>
                            <span className={'bold-text'}>{moment(props.state.ended_at).format('DD/MM/YY')}</span>
                            {`. ${t('rounds:define_recurrence_aim')}`}
                        </Trans>
                    )
                }
            </div>
        </>
    );
};

export default RoundModalFirstSection;

import classnames from 'classnames';
import moment from 'moment';
import { getCalendarInstanceSizeClassName, getRoundInstanceStatus } from '../../helper';
import { isOneDayTimeSlot } from '../../../layout/helper';

export const calendarFormats = {
    dayFormat: 'ddd DD/MM',
    timeGutterFormat: 'H[h]mm'
};

export const eventPropGetter = event => {
    const instance = event.resource;
    const eventStart = event.start;
    const eventEnd = event.end;
    const isOneDayEvent = isOneDayTimeSlot(moment(eventStart).format('HH:mm'), moment(eventEnd).format('HH:mm'));
    const stickToEndOfCalendar = isOneDayEvent && moment(eventStart).hours() === 23
        && moment(eventStart).minutes() > 30 && moment(eventEnd).hours() === 23;

    if (instance) {
        return {
            className: classnames('instance-event', { 'sticked-to-calendar-bottom': stickToEndOfCalendar },
                getRoundInstanceStatus(instance.status, instance.validated_steps_count),
                getCalendarInstanceSizeClassName(eventEnd, eventStart, event.allDay))
        };
    }
    return {
        className: classnames('instance-event', { 'sticked-to-calendar-bottom': stickToEndOfCalendar },
            event.groupedStatus, getCalendarInstanceSizeClassName(eventEnd, eventStart, event.allDay))
    };
};

import update from 'immutability-helper';

const initialState = () => ({
    image: null
});

const errorBoundaryReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'SET_IMAGE':
            return update(state, { image: { $set: action.payload } });
        default:
            return state;
    }
};

export default errorBoundaryReducer;

// Here you can find all the enums that are used in this module

export const TicketMovementDirection = Object.freeze({
    UP: 'up',
    DOWN: 'down'
});

export const ticketMovementType = Object.freeze({
    SINGLE_TICKET: 'single_ticket',
    MULTIPLE_TICKETS: 'multiple_tickets'
})

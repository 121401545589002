import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { DialogContent } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import '../style.changelog.scss';
import { useModal } from '../../modal/ModalContext';
import { userSawChangelog } from '../actions';
import { useComponentsPool } from '../../../ComponentsPool';

const dispatch = useDispatch();

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)(props => {
    const {
        children, classes, onClose, ...other
    } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


const gfm = require('remark-gfm');

const ChangelogModal = () => {
    const modal = useModal();
    const { Component } = useComponentsPool();
    const currentUser = useSelector(state => state.users.currentUser);
    const changelog = useSelector(state => state.changelogs.latestChangelog);
    const markdown = changelog.content;
    let changelogTitle = '';
    let changelogContent = '';
    const splittedContent = markdown?.split('---');
    if (splittedContent?.length > 1) {
        changelogTitle = splittedContent[0];
        changelogContent = splittedContent[1];
    }
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        modal.close(); // so we can reopen the modal when we want (with modal.open())
        if (currentUser?.id && !changelog?.current_user_saw_changelog) {
            dispatch(userSawChangelog(currentUser.id, changelog.release_date));
        }
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            scroll={'paper'}

            className={'changelog-modal-container'}
        >
            <DialogTitle id="customized-dialog-title" className={'changelog-header'} onClose={handleClose}>
                <Typography gutterBottom>
                    <ReactMarkdown linkTarget="_blank" remarkPlugins={[gfm]}>
                        {changelogTitle}
                    </ReactMarkdown>
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    <ReactMarkdown linkTarget="_blank" remarkPlugins={[gfm]} className={'changelog-content'}>{changelogContent}</ReactMarkdown>
                </Typography>
            </DialogContent>
            <Component componentName={'ChangelogModalFooter'} />
        </Dialog>
    );
};

export default ChangelogModal;

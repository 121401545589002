// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';

import createLoadingSelector from '../../../layout/actions';
import { retrieveLwpAreaConfig } from '../../../devices/helper';

const loadingSelector = createLoadingSelector(['GET_ROUND_INSTANCES']);
const statusChoices = ['COMPLETE', 'INCOMPLETE', 'IN_PROGRESS'];

const RoundDetailsPopupPartRight = () => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const round = useSelector(state => state.rounds.roundShownInDetailsPopup);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const [instances, setInstances] = useState([]);
    const devices = useSelector(state => state.devices.list);

    useEffect(() => {
        if (devices.find(device => device.custom_field.type === 'LWP')) { retrieveLwpAreaConfig(dispatch, devices); }
    }, []);

    useEffect(() => {
        if (round.instances) {
            setInstances(round.instances.filter(instance => statusChoices.includes(instance.status)));
        }
    }, [round.instances]);

    return (
        <>
            <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
            <Component
                componentName={'History'}
                title={t('rounds:historic')}
                resourceId={round.id}
                events={instances || []}
                totalEvents={instances?.length}
                defaultHeight={50}
                type={'RoundInstances'}
            />
        </>
    );
};

export default RoundDetailsPopupPartRight;


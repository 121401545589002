// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, filter, find } from 'lodash';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
import { useModal } from '../../modal/ModalContext';
// Actions
import { inviteTeammate } from '../actions';
// Helper
import createLoadingSelector from '../../layout/actions';
import { getLocaleLanguage } from '../../core/helper';

const isNotValid = value => value === 'error_field' || isEmpty(value);

const loadingSelector = createLoadingSelector(['INVITE_USER']);
const InvitePopup = () => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const modal = useModal();
    const dispatch = useDispatch();
    const [disableSkillRule, setDisableSkillRule] = useState(false);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const [roleExplanations, setRoleExplanations] = useState('');
    const users = useSelector(state => state.users.list);
    const roles = useSelector(state => state.workspace.roles);
    const [listRole] = useState(filter(roles, role => role.workspace_role_name !== 'Owner').map(role => ({
        id: role?.workspace_role_id,
        value: t(`users:${role?.workspace_role_name}`)
    })));
    const [contractorId] = useState(find(roles, role => role.workspace_role_name === 'Contractor').workspace_role_id);
    const [adminId] = useState(find(roles, role =>
        role.workspace_role_name === 'Admin' ||
        role.workspace_role_name === 'Owner').workspace_role_id);
    const [userId] = useState(find(roles, role => role.workspace_role_name === 'User').workspace_role_id);

    const [disabled, setDisabled] = useState(true);
    const [state, setState] = useState({
        lang: '',
        email: '',
        company: '',
        group_ids: [],
        workspace_role_id: '',
        skills: []
    });
    const [isSupervisorMode, setIsSupervisorMode] = useState(false);

    useEffect(() => {
        setDisabled(
            isNotValid(state?.email)
            || isEmpty(state.building_ids)
            || state.building_ids === 'error_field'
            || isEmpty(state.workspace_role_id)
            || state.workspace_role_id === contractorId && isEmpty(state.skill_ids)
        );
    }, [state]);

    useEffect(() => {
        if (state.workspace_role_id === contractorId) {
            setDisableSkillRule(false);
        } else {
            setDisableSkillRule(true);
        }
        switch (state.workspace_role_id) {
            case contractorId:
                setIsSupervisorMode(false);
                setState(prevState => ({ ...prevState, skills: [] }));
                setRoleExplanations(t('users:explanations_invite_step1_contractor'));
                return;
            case adminId:
                setIsSupervisorMode(false);
                setRoleExplanations(t('users:explanations_invite_step1_admin'));
                return;
            case userId:
                if (workspaceSettings.supervision_skills_to_users === true) {
                    setIsSupervisorMode(true);
                }
                setRoleExplanations(t('users:explanations_invite_step1_user'));
                return;
            default:
                setRoleExplanations('');
                return;
        }
    }, [state.workspace_role_id]);

    useEffect(() => {
        if (state.workspace_role_id === contractorId) {
            setDisableSkillRule(false);
        } else {
            setDisableSkillRule(true);
        }
    }, [state.skill_ids]);

    const inviteUser = async () => {
        const {
            email, company, building_ids, skill_ids, workspace_role_id, skills
        } = state;

        const inviteFields = {};

        inviteFields.lang = getLocaleLanguage();
        inviteFields.email = email;
        inviteFields.workspace_role_id = workspace_role_id;
        inviteFields.building_ids = building_ids;
        inviteFields.skill_ids = skill_ids;

        if (company) { inviteFields.company = company; }

        dispatch(inviteTeammate(inviteFields));

        // setExplanations(t('users:explanations_invite_step0'));
        modal.close();
    };

    const head = (
        <div>
            <p className={'title-popup'}>{t('users:add_teammate')}</p>
            <p className={'subtitle-popup'}>{t('users:explanations_invite_step0')}</p>
        </div>
    );

    const content = (
        <div className={'iot-rocket-form'}>
            <Component
                autoFocus
                componentName={'EditableField'}
                renderValue={'Input'}
                title={t('common:email_address')}
                required
                props={{
                    list: users,
                    rules: ['checkIfUserAlreadyExists', 'isEmail', 'maxLength-64', 'selectNotEmpty'],
                    onChange: value => setState(prevState => ({ ...prevState, email: value.trim() }))
                }}
            />
            <Component
                title={t('common:company')}
                componentName={'EditableField'}
                renderValue={'Input'}
                props={{
                    rules: ['isAcceptableString', 'maxLength-64'],
                    onChange: value => setState(prevState => ({ ...prevState, company: value }))
                }}
            />
            <Component
                title={t('common:workspace_role')}
                subtitle={roleExplanations}
                componentName={'EditableField'}
                renderValue={'Select'}
                required
                props={{
                    rules: ['selectNotEmpty'],
                    options: listRole,
                    onChange: value => setState(prevState => ({ ...prevState, workspace_role_id: value.id }))
                }}
            />
            <Component
                title={t('groups:select_group')}
                componentName={'EditableField'}
                renderValue={'GroupSelector'}
                required
                props={{
                    rules: ['selectNotEmpty'],
                    groupType: 'BATIMENT',
                    value: 'group_ids',
                    showSelectAll: true,
                    multiple: true,
                    placeholder: '',
                    onChange: value => setState(prevState => ({ ...prevState, building_ids: value }))
                }}
            />
            {((state.workspace_role_id === contractorId) ||
                (state.workspace_role_id === userId && workspaceSettings.filter_user_on_skills === true) ||
                isSupervisorMode ||
                (state.workspace_role_id === adminId && workspaceSettings.filter_admin_on_skills === true)) &&
            (
                <Component
                    title={
                        isSupervisorMode
                            ? t('groups:skill_supervisor')
                            : t('users:skills')
                    }
                    subtitle={
                        isSupervisorMode
                            ? t('users:explanation_supervision_skill')
                            : t('users:explanation_skill')
                    }
                    componentName={'EditableField'}
                    renderValue={'GroupSelector'}
                    required
                    props={{
                        rules: [disableSkillRule ? '' : 'selectNotEmpty'],
                        disableRule : disableSkillRule,
                        groupType: 'SKILL',
                        value: 'group_ids',
                        multiline: 10,
                        multiple: true,
                        showSelectAll: true,
                        placeholder: '',
                        required: !isSupervisorMode,
                        onChange: value => setState(prevState => ({ ...prevState, skill_ids: value }))
                    }}
                />
            )}
        </div>
    );

    return (
        <Component
            componentName={'Modal'}
            head={head}
            content={content}
            loading={isLoading}
            submitButton
            submitButtonLabel={'common:save'}
            onHandleSubmit={inviteUser}
            onSubmitDisabled={disabled}
        />
    );
};

export default InvitePopup;

// Libs
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    CellMeasurer, CellMeasurerCache, InfiniteLoader, List
} from 'react-virtualized';
import { useComponentsPool } from '../../../ComponentsPool';
import { getRoundInstanceDataExport, getRoundInstances } from '../../rounds/actions';
import {
    exportRoundInstanceTableToCsvFile,
    getRoundInstanceStatus,
    isMoreThan24Hours
} from '../../rounds/helper';
import useRouter from '../../../utils/hook/useRouter';
import { EVENT_DATA_EXPORT_TIMEOUT, segmentTrack } from '../helper';


const cache = new CellMeasurerCache({ fixedWidth: true, defaultHeight: 90 });

const History = props => {
    const useStyles = makeStyles(() => ({
        tooltip: {
            '&::before': {
                marginLeft: '35px'
            }
        }
    }));

    const classes = useStyles();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const [t] = useTranslation();
    let viewWrapperRef = useRef();
    const [maxAvailableHeight, setMaxAvailableHeight] = useState(0);
    const [maxAvailableWidth, setMaxAvailableWidth] = useState(0);
    const round = useSelector(state => state.rounds.roundShownInDetailsPopup);
    const devices = useSelector(state => state.devices.list);
    const roundId = useSelector(({ rounds }) => rounds.roundShownInDetailsPopup?.id);

    useEffect(() => {
        setMaxAvailableWidth(viewWrapperRef.clientWidth);
        setMaxAvailableHeight(viewWrapperRef.clientHeight);
    }, [props.events]);

    // called on init and date changes in the date picker
    const loadRoundInstances = date => {
        dispatch(getRoundInstances(
            roundId,
            1,
            100,
            date?.startDate.format('YYYY-MM-DD'),
            date?.endDate.format('YYYY-MM-DD')
        ));
    };

    // get current week round instances on init
    useEffect(() => {
        const date = {
            startDate: moment().startOf('isoWeek'),
            endDate: moment().endOf('isoWeek')
        };
        loadRoundInstances(date);
    }, []);

    const getStatusIcon = (status, validatedSteps) => (
        <Tooltip
            placement={'top'}
            title={(<Component componentName={'Description'} content={t(`rounds:${getRoundInstanceStatus(status, validatedSteps)}_explanations`)} />)}
        >
            <div className={'status-icon'}>
                <Component componentName={'Icon'} type={status === 'COMPLETE' ? 'CheckCircleRounded' : status === 'IN_PROGRESS' ? 'StopWatchIcon' : 'ErrorRounded'} />
            </div>
        </Tooltip>
    );

    const { query } = useRouter();
    const exportInstance = instance => {
        dispatch(getRoundInstanceDataExport(instance, query?.view)).then(res => {
            if (res.action?.payload) {
                const fileName = `${t('rounds:export_instance_file_name_prefix')
                    + round.name.toLowerCase().replace(/\s+/g, '_').replace(/[^\w\s]/gi, '_').replace(' ', '_') }_${
                    moment(instance.started_at).format('DD_MM_YYYY')
                }.csv`;
                exportRoundInstanceTableToCsvFile(fileName, res.action.payload, round, devices,
                    instance.started_at, instance.ended_at, t);
            }
        }).catch(exception => {
            segmentTrack(EVENT_DATA_EXPORT_TIMEOUT,
                {
                    round_name: round.name,
                    round_instance: {
                        status : instance.status,
                        started_at: instance.started_at?.toString(),
                        ended_at: instance.ended_at?.toString()
                    },
                    exception: exception.toString()
                });

            throw exception;
        });
    };

    const getHeight = ref => cache.rowHeight(ref) + 10;
    const isRowLoaded = ({ index }) => !props.hasMore || index < props.events.length;
    const rowRenderer = ({ key, index, style }) => {
        const newStyle = { ...style };
        if (style.height === 'auto') {
            newStyle.top = 50 * index;
            newStyle.height = 50;
        }
        if (props?.type === 'RoundInstances') {
            const started_at = moment(props.events[index].started_at);
            const ended_at = moment(props.events[index].ended_at);
            const date = isMoreThan24Hours(props.events[index]?.started_at, props.events[index]?.ended_at)
                ? `${t('graphs:custom_date', { // Sentence : Du DD/MM/YY au DD/MM/YY
                    startDate: started_at.format('DD/MM/YY'),
                    endDate: ended_at.format('DD/MM/YY')
                })}`
                : started_at.format('[Le] DD/MM/YYYY');

            return props.events[index] && (
                <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index} style={newStyle}>
                    <div className="log-line" style={newStyle}>
                        <div className="log-line-content">
                            <div className={'log-timeline'}>
                                {props.totalEvents !== index + 1 && <div className={'line'} />}
                                {getStatusIcon(props.events[index].status, props.events[index].validated_steps_count)}
                            </div>
                            <div className="round-event-content">
                                <p>{date}</p>
                                <Tooltip
                                    arrow={false}
                                    classes={{ tooltip: classes.tooltip }}
                                    title={t('rounds:export-passages-detail')}
                                    placement={'top'}
                                >
                                    <div
                                        tabIndex={0}
                                        role={'button'}
                                        className={'export-passage-button'}
                                        onClick={() => exportInstance(props.events[index])}
                                    >
                                        <Button>
                                            <Component componentName={'Icon'} type={'export'} />
                                        </Button>
                                    </div>
                                </Tooltip>
                                <Component componentName={'RoundDetailsDrawer'} instance={props.events[index]} />
                            </div>
                        </div>
                    </div>
                </CellMeasurer>
            );
        }
        return <></>;
    };
    const getEventsComponent = () => {
        if (props.events?.length > 0) {
            return (
                <div
                    style={{ display: 'flex', height: '100%' }}
                    ref={divElement => { viewWrapperRef = divElement; }}
                >
                    <div style={{ flex: '1 1 auto' }}>
                        <InfiniteLoader
                            isRowLoaded={isRowLoaded}
                            loadMoreRows={props.loadMore}
                            rowCount={props.hasMore ? props.events.length + 1 : props.events.length}
                            overscanRowCount={1}
                            minimumBatchSize={1}
                            threshold={1}
                        >
                            {({ onRowsRendered, registerChild }) => (
                                <List
                                    ref={registerChild}
                                    height={maxAvailableHeight}
                                    rowCount={props.events.length}
                                    deferredMeasurementCache={cache}
                                    rowHeight={props.defaultHeight || getHeight()}
                                    rowRenderer={rowRenderer}
                                    onRowsRendered={onRowsRendered}
                                    width={maxAvailableWidth}
                                />
                            )}
                        </InfiniteLoader>
                    </div>
                </div>
            );
        }
        return (
            <div className={'no-historic-container'}>
                <Component componentName={'NoData'} view={'historic'} />
            </div>
        );
    };

    return (
        <div className="logs logs-historic">
            <div className={'logs-title'}>
                <h2>{`${props.title}`}</h2>
            </div>
            <div className={'date-picker-wrapper'}>
                <Component componentName={'DateWeekPicker'} onChange={loadRoundInstances} />
            </div>
            { getEventsComponent() }
        </div>
    );
};


export default History;


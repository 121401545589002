// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';
// Actions
import { deleteTickets } from '../../actions';

export default () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const footbar = useFootBar();
    const { Component } = useComponentsPool();
    const [list, setList] = useState(footbar.getList());

    useEffect(() => {
        setList(footbar.getList());
    }, [footbar.getList()]);

    const removeTickets = () => {
        dispatch(deleteTickets({ ticket_ids: footbar.getList().map(ticket => ticket?.id) }));
        footbar.close();
    };

    return (
        <Component
            componentName={'FootbarButton'}
            withConfirmationPopover
            displayCondition
            buttonText={list.length > 1 ? t('tickets:delete_tickets') : t('tickets:delete_ticket')}
            popoverText={list.length > 1 ? t('tickets:delete_tickets_confirmation') : t('tickets:delete_ticket_confirmation')}
            callbackAction={removeTickets}
            buttonIcon={'trash'}
            key={'trash'}
        />
    );
};

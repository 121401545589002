import { useRef } from 'react'

/**
 * Custom hook for determining if the component is rendering for the first time.
 * @returns {boolean} A boolean value indicating whether the component is rendering for the first time.
 * @example
 * const isFirstRender = useIsFirstRender();
 * // Use isFirstRender to conditionally execute code only on the initial render.
 */
export function useIsFirstRender() {
    const isFirst = useRef(true);
    if (isFirst.current) {
        isFirst.current = false;
        return true;
    }
    return isFirst.current;
}
/* eslint-disable import/first */
import React from 'react';
import { addDecorator } from '@storybook/react';
import { action } from '@storybook/addon-actions';
import FormControl from '@material-ui/core/FormControl';
import { createStore } from 'redux';
import DeviceAndGroupSelector from '../modules/layout/components/DeviceAndGroupSelector';
import Provider from './Provider';

export default { title: 'Devices and groups selector' };

const onChangeState = action('onChangeState');

const store = createStore(() => ({
    devices: {
        list: [{
            device_id: '52f42a68',
            device_name: 'Device #1 (Avec groupes)',
            group_ids: ['45de233f']
        },
        {
            device_id: '52f42a69',
            device_name: 'Device #2 (Avec groupes)',
            group_ids: ['45de233f']
        },
        {
            device_id: '52f42a70',
            device_name: 'Device #3 (Sans groupes)',
            group_ids: []
        },
        {
            device_id: '52f42a71',
            device_name: 'Device #4 (Sans groupes)',
            group_ids: []
        }]
    },
    groups: {
        list: [{
            group_id: '45de233f',
            group_name: 'Group #1'
        },
        {
            group_id: '45de233g',
            group_name: 'Group #2'
        }]
    }
}));

addDecorator(storyFn => (
    <FormControl component={'div'} style={{ width: '600px', fontFamily: 'Lato' }}>
        <Provider store={store}>
            {storyFn()}
        </Provider>
    </FormControl>
));

export const selector = () => (
    <DeviceAndGroupSelector
        required
        onChange={onChangeState}
        showDesc
        showIcon
        selectedDevices={['52f42a69', '52f42a68']}
        selectedGroups={[]}
        selectType={'keyword'}
    />
);

// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Context
import { useModal } from '../../modal/ModalContext';
import { useComponentsPool } from '../../../ComponentsPool';
import { useFootBar } from '../../footbar/FootBarContext';
// Actions
import { addGroupToUser } from '../../users/actions';
import createLoadingSelector from '../../layout/actions';

const loadingSelector = createLoadingSelector(['ADD_GROUP_TO_USER']);
const AddUserToGroupPopup = () => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const modal = useModal();
    const footBar = useFootBar();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const [group] = useState(modal.getItem());
    const [usersToAdd, setUsersToAdd] = useState([]);
    const [role, setRole] = useState(null);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setDisabled(usersToAdd.length === 0 || role === null);
    }, [usersToAdd, role]);

    const addUserToGroup = async () => {
        const data = usersToAdd.map(user => ({ user_id: user, group_ids: [group.group_id], group_role_id: role.id }));
        await dispatch(addGroupToUser(data));
        dispatch({ type: 'ADD_GROUP_TO_USER_NOTIF_FULFILLED', payload: data.length - 1 });
        modal.close();
        footBar.close();
    };

    const head = <span>{t('users:add_member_to_group', { name: group.group_name })}</span>;

    const content = (
        <div className={'iot-rocket-form'}>
            <Component
                componentName={'UsersSelector'}
                rules={['selectNotEmpty']}
                required
                multiple
                onChange={setUsersToAdd}
            />
            <Component componentName={'RoleSelector'} onChange={setRole} />
        </div>
    );

    return (
        <Component
            componentName={'Modal'}
            head={head}
            content={content}
            loading={isLoading}
            closeButton
            submitButton
            submitButtonLabel={'common:save'}
            closeButtonLabel={'common:cancel'}
            onHandleSubmit={addUserToGroup}
            onSubmitDisabled={disabled}
        />
    );
};

export default AddUserToGroupPopup;

// Library
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { toUpper } from 'lodash';
import { connect } from 'react-redux';
// Components
import Button from '@material-ui/core/Button';
// SVG
import OopsLogo from './assets/oopss.svg';
// Scss
import './style.error.scss';
import axios from "axios";

class ErrorBoundary extends PureComponent {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch = (error, info) => {
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        console.log(error, info); // eslint-disable-line

        const workspace = localStorage?.getItem('iot_rocket_workspace') || 'Unknown workspace';
        const payload = {
            external_reference: 'merciyanis_app',
            url: window.location.href,
            error: error.toString(),
            message: info,
            workspace
        };
        axios.post('errors/', payload)
            .then(res => res.data);
    };

    render() {
        const { template, isFullscreen } = this.props;
        if (this.state.hasError) {
            // You can render any custom fallback UI
            if (template === 'graph') {
                return (
                    <div className={classnames({ 'error-wrapper': true, fullscreen: isFullscreen })}>
                        <div className="subwrapper">
                            {this.props.t('errors:something_went_wrong')}
                        </div>
                    </div>
                );
            }
            return (
                <div className={classnames({ 'error-wrapper': true, fullscreen: isFullscreen })}>
                    <div className={'content'}>
                        {this.props.image === null ? <OopsLogo /> : this.props.image}
                        <div className="something">
                            <p>{`Oups ! ${this.props.t('errors:something_went_wrong')} :(`}</p>
                            <Button onClick={() => { window.location.href = '/';}}>
                                {this.props.t('errors:go_back_home')}
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

const mapStateToProps = state => ({
    image: state.errorBoundary.image
});

export default withTranslation()(connect(mapStateToProps)(ErrorBoundary));

import axios from 'axios';

const getSubscriptions = () => ({
    type: 'GET_SUBSCRIPTION_LIST',
    payload: axios
        .get('/subscriptions/')
        .then(res => res.data)
        .catch(error => {
            throw new Error(error);
        })
});

export function updateSubscription(subscriptionUpdatedFields, subscriptionId) {
    return {
        type: 'UPDATE_SUBSCRIPTION',
        payload: axios
            .patch(`/subscriptions/${subscriptionId}/`, { ...subscriptionUpdatedFields })
            .then(res => res.data)
            .catch(error => {
                if (error.response.status === 403) {
                    throw new Error(error);
                }
                console.error("UPDATE_SUBSCRIPTIONS_ERROR", error); // eslint-disable-line
                return error;
            })
    };
}

export function deleteSubscription(subscriptionId) {
    return {
        type: 'DELETE_SUBSCRIPTION',
        payload: axios.delete(`/subscriptions/${subscriptionId}`)
            .then(() => subscriptionId)
            .catch(error => {
                console.log('DELETE_SUBSCRIPTION_ERROR', error); // eslint-disable-line
                return error;
            })
    };
}

export function createSubscription(data) {
    return {
        type: 'CREATE_SUBSCRIPTION',
        payload: axios
            .post('/subscriptions/', data)
            .then(res => res.data)
            .catch(error => {
                console.error('CREATE_SUBSCRIPTION_ERROR', error); // eslint-disable-line
                return error;
            })
    };
}


export default getSubscriptions;

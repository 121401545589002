// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

// Context
import { useTranslation } from 'react-i18next';
import { useComponentsPool } from '../../../ComponentsPool';
import useRouter from '../../../utils/hook/useRouter';
import { getCurrentRoundView, VIEW_CALENDAR } from './RoundViewSwitcher/RoundViewSwitcher';
import { useModal } from '../../modal/ModalContext';

export default () => {
    const { Component } = useComponentsPool();
    const userWorkspaceRights = useSelector(state => state.users.currentUser.workspace_rights);
    const activeMode = useSelector(state => state.rounds.active_mode);
    const { query } = useRouter();
    const isCalendarView = getCurrentRoundView(query) === VIEW_CALENDAR;
    const [t] = useTranslation();
    const modal = useModal();
    const currentUser = useSelector(state => state.users.currentUser);

    const getOwnerActionsOnPC = () => [
        {
            icon: <Component componentName={'Icon'} type={'rounds'} />,
            name: t('rounds:round_roundbutton'),
            onClick: () => modal.update({ name: 'RoundsCreateEditModal' })
        }, {
            icon: <Component componentName={'Icon'} type={'csvFile'} />,
            name: t('rounds:import_rounds'),
            onClick: () => modal.update({ name: 'ImportRoundsPopup' })
        }
    ];

    return (
        // We should see the action button on mobile even if the url contains 'calendar'
        (isMobile ? modal.name !== 'MobileQRCodeScanner' && modal.name !== 'EditUserProfilePopup' : !isCalendarView) && (
            <Component
                componentName={'ActionButton'}
                isOnTopActionButton={isMobile} // On mobile the action button must be visible on top of Modal (!= on pc)
                canUserCreate={userWorkspaceRights.includes('CREATE:Round') && activeMode}
                modalName={isMobile ? 'MobileQRCodeScanner' : 'RoundsCreateEditModal'}
                iconButton={(<Component componentName={'Icon'} type={isMobile ? 'QRCodeScan' : 'rounds'} />)}
                tooltipTitle={isMobile ? 'rounds:scan_a_qrcode' : 'rounds:round_roundbutton'}
                actions={currentUser.workspace_role?.workspace_role_name === 'Owner' && !isMobile && getOwnerActionsOnPC()}
            />
        )
    );
};

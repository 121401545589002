import axios from 'axios';

const getMessagingUsersList = () => ({
    type: 'GET_MESSAGING_USERS_LIST',
    payload: axios
        .get('/messaging/')
        .then(res => res.data)
});

export default getMessagingUsersList;

// Libs
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';
// Context
import { useComponentsPool } from '../../../ComponentsPool';

export default props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const tickets = useSelector(state => state.tickets.list);
    const [options] = useState(tickets.map(category => ({ value: t(`tickets:${category.name}`), id: category?.id })));
    const [selectedValue] = useState(props.value ? find(tickets, category => category?.id === props.value) : undefined);

    return (
        <Component
            componentName={'Select'}
            required={props.required}
            placeHolder={props.placeHolder}
            onChange={value => props.onChange(value?.id)}
            options={options}
            renderValue={'chip'}
            selected={selectedValue ? { value: t(`tickets:${selectedValue.name}`), id: selectedValue?.id } : undefined}
        />
    );
};

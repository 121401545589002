// Libs
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import {
    concat, filter, find, includes, isArray, isEmpty, uniqBy
} from 'lodash';
import { AutoSizer, List } from 'react-virtualized';
// Components
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Helper
import { getTraductionOfGroup } from '../../groups/helper';

const formatName = (name, subText, t) => {
    const formText = text => {
        let returnText = '';
        if (text.length > 3) {
            for (let i = 0; i < 3; i = i + 1) {
                returnText = `${returnText} ${text[i]}, `;
            }
            return `${t('common:related_to')} ${returnText} ${t('common:and')} ${text.length - 3} ${t('common:other')}`;
        }
        return text.join(', ');
    };

    return (
        <div className={'result-text-container'}>
            <p>{name}</p>
            {/* eslint-disable-next-line no-nested-ternary */}
            { isArray(subText) ? subText.length > 3 ? (
                <Tooltip title={subText.map(text => <p key={text}>{text}</p>)}>
                    <em>{formText(subText)}</em>
                </Tooltip>
            ) : <em>{subText.join(', ')}</em> : <em>{subText}</em>}
        </div>
    );
};

const searchByDeviceName = (options, props) => {
    let name = '';
    let hardware_id = '';
    props.devices.forEach(device => {
        name = device.device_name;
        if (device.hardware_ids?.length > 0) {
            hardware_id = device.hardware_ids[0];
            name = `${name} (${hardware_id})`;
        }
        options.push({
            category: 'device',
            queryName: name,
            displayedName:
                formatName(device.device_name || hardware_id, hardware_id, props.t),
            name,
            groups: device.group_ids,
            id: device.device_id,
            groupType: device.custom_field?.type
        });
    });
    return options;
};

const searchByGroups = (options, props, t) => {
    props.groups.forEach(group => {
        if (group) {
            options.push({
                queryName: getTraductionOfGroup(group.group_name, t).toLowerCase(),
                category: 'group',
                name: group.group_name,
                id: group.group_id,
                groupType: group.custom_field?.type
            });
        }
    });
    return options;
};

const setOptions = (searchbarName, props, t) => {
    const options = [];
    if (searchbarName === 'stepsSearchbar' || searchbarName === 'qrcodesSearchbar') {
        searchByDeviceName(options, props);
        searchByGroups(options, props, t);
    }
    return options;
};

const CustomQueryResults = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const [minimumQuerySize] = useState(1);
    const navbarFilters = useSelector(state => state.navbar.filters);
    const users = useSelector(state => state.users.list);
    const tickets = useSelector(state => state.tickets.list);
    const dashboards = useSelector(state => state.dashboards.list);
    const groups = props.groups ? props.groups : useSelector(state => state.groups.list);
    const devices = props.devices ? props.devices : useSelector(state => state.devices.list);
    const searchbarName = props.searchbarName;
    const [allResults, setAllResults] = useState(() => setOptions(searchbarName, {
        devices, groups
    }, t));
    const [matchingResults, setMatchingResults] = useState([]);
    const [filtersToOptions, setFiltersToOptions] = useState([]);

    const addGroupsRelated = queryResults => queryResults;

    // Filters the list comparing the query and the names
    const filterResults = () => {
        const queryResults = [];
        setMatchingResults([]);
        if (props.query.length >= minimumQuerySize) {
            let normalizeQueryName = '';
            let normalizeName = '';
            const normalizeQuery = props.query?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.replace(/\s/g, '')?.toLowerCase();
            let normalizeTraducedName = '';
            let normalizeNfcTag = '';

            allResults.forEach(result => {
                normalizeQueryName = result.queryName?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.replace(/\s/g, '')?.toLowerCase();
                normalizeName = result.name?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.replace(/\s/g, '')?.toLowerCase();
                normalizeTraducedName = getTraductionOfGroup(props.query, t)?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.replace(/\s/g, '')?.toLowerCase();
                normalizeNfcTag = result.nfc_tag?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.replace(/\s/g, '')?.toLowerCase();

                if (includes(normalizeQueryName || normalizeName, normalizeQuery)
                    || includes(normalizeQueryName || normalizeName, normalizeTraducedName)
                    || includes(normalizeNfcTag, normalizeQuery)
                ) {
                    queryResults.push(result);
                }
            });

            setMatchingResults(uniqBy(concat(filtersToOptions, addGroupsRelated(queryResults)), 'id'));
        }
    };

    useEffect(() => {
        if (props.inputFocus && !isEmpty(filter(navbarFilters, f => f.category === 'defaultOptions'))) {
            setMatchingResults(filtersToOptions.slice(0));
        }
        if (!isEmpty(props.query)) {
            filterResults();
        }
    }, [props.inputFocus]);

    useEffect(() => {
        const matchingFilters = [];
        filter(navbarFilters, f => f.type !== 'defaultOptions' && f.type !== 'tickets').forEach(currentFilter => {
            matchingFilters.push(find(allResults, result => result.id === currentFilter.id));
        });
        setFiltersToOptions(matchingFilters);
        filterResults();
    }, [navbarFilters]);

    useEffect(() => {
        filterResults();
    }, [props.query]);

    // Reload results if there is a new device, user, dashboard, group
    useEffect(() => {
        const setResult = async () => {
            await setAllResults(setOptions(searchbarName, {
                devices, groups
            }, t));
            filterResults();
        };
        setResult();
    }, [devices, users, dashboards, groups, tickets, window.location.pathname]);

    // eslint-disable-next-line consistent-return
    const rowRenderer = ({ index, style }) => {
        const result = matchingResults[index];
        if (result) {
            const selected = find(navbarFilters, res => res.id === result.id);
            const iconType = result.category === 'link'
                ? 'group'
                : result.groupType
                    ? result.groupType === 'SKILL'
                        ? result.name
                        : result.groupType
                    : result.category;
            const displayName = !selected && typeof result.displayedName === 'string' && result.category === 'group'
                ? getTraductionOfGroup(result.displayedName, t)
                : result.name && result.category === 'group'
                    ? <p>{getTraductionOfGroup(result.name, t)}</p>
                    : <p>{result.name}</p>;
            return (
                <Button
                    key={result.id}
                    style={style}
                    className={classNames('result-container', selected ? 'selected-result' : '')}
                    onClick={() => props.toggleFilter(result)}
                >
                    <Component componentName={'Icon'} type={iconType} />
                    { displayName }
                </Button>
            );
        }
    };

    const rowCount = matchingResults.length;

    return !isEmpty(matchingResults) && !isEmpty(props.query) && (
        <div
            className={classNames(
                'query-results-container',
                { 'query-results-container-maximized': props.displayResultContainer },
                { 'footbar-is-top': FOOTBAR_POSITION === 'top' }
            )}
            style={props.displayResultContainer && matchingResults.length > 0
                ? { padding: '10px 0 0 0', width: props.width, left: props.left }
                : {}}
        >
            <div style={{ flex: '1 1 auto', height: `${rowCount * 40}px` }}>
                <AutoSizer>
                    {({ width, height }) => (
                        <List
                            height={height}
                            width={width}
                            rowCount={rowCount}
                            rowHeight={40}
                            rowRenderer={rowRenderer}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    )
};

export default withRouter(CustomQueryResults);

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { useComponentsPool } from '../../../ComponentsPool';


const DeviceGroupHardwareIdsSimpleString = () => {
    const { Component } = useComponentsPool();
    const deviceGroupList = useSelector(state => state.groups.groupShownInDetailsPopup);
    const devices = deviceGroupList.devices || [];
    const hardware_ids = [];
    devices.forEach(device => {
        if (device.hardware_ids?.length > 0) {
            hardware_ids.push({ id: device.hardware_ids[0], value: device.hardware_ids[0] });
        }
    });

    return (
        <Component
            componentName={'ChipButton'}
            maxVisible={3}
            chipsToDisplay={hardware_ids}
        />
    );
};

export default DeviceGroupHardwareIdsSimpleString;

// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Context
import { useSelector } from 'react-redux';
import { useComponentsPool } from '../../../../ComponentsPool';
// Helper
import { getDeviceGroupIcon } from '../../helper';

const DeviceDetailsGraph = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const group = useSelector(state => state.groups.groupShownInDetailsPopup);

    const getNoDataComponent = () => (
        <div className="graph-container">
            <div className={'no-data-to-display-img'}>
                <span>{t('common:no-data-to-display')}</span>
            </div>
        </div>
    );

    // GROUP graphs
    if (props.type === 'GROUP') {
        if (!isEmpty(props.graphData)) {
            const type = getDeviceGroupIcon(group);
            let graphName = '';
            if (type === 'BUTTON_SATISFACTION') graphName = 'SatisfactionGraph';
            if (type === 'BUTTON_RATING') graphName = 'RatingGraph';
            if (type === 'BUTTON_POLL') graphName = 'PollGraph';
            if (!isEmpty(graphName)) {
                return (
                    <div className="graph-container">
                        <Component
                            componentName={graphName}
                            graphData={props.graphData}
                        />
                    </div>
                );
            }
        }
        return getNoDataComponent();
    }

    // DEVICE graphs
    const device = useSelector(state => state.devices.deviceShownInDetailsPopup);
    const deviceTypesGraph = ['TEMPERATURE_CONNIT', 'AIRWITS', 'AIRWITS_CO2', 'TES-5MRO'];

    if (deviceTypesGraph.includes(device?.custom_field?.type)) {
        if (props.graphData?._graph_data?.length > 0
            && props.graphData._graph_data.some(item => item.data_type === 'temperature'
                    || item.data_type === 'humidity'
                    || item.data_type === 'co2'
            )) {
            const graphData = {
                ...props.graphData,
                _graph_data: [props.graphData._graph_data?.filter(item => item.data_type === 'temperature'
                    || item.data_type === 'humidity'
                    || item.data_type === 'co2'
                )]
            };
            graphData._graph_data = graphData._graph_data[0];
            return (
                <div className="graph-container">
                    <Component
                        componentName={'LineGraph'}
                        className={'graph'}
                        graph={graphData}
                        conditions={device?.conditions}
                        showLegend
                        editing={false}
                        mode={'details'}
                    />
                </div>
            );
        }
        return getNoDataComponent();
    }

    if (!isEmpty(props.graphData?.metabaseUrl)) {
        return (
            <div className="metabase-device-container">
                <iframe
                    allowTransparency
                    src={props.graphData?.metabaseUrl}
                />
            </div>
        );
    }
    return <></>;
};

export default DeviceDetailsGraph;

// Library
import React from 'react';

// Context
import { useComponentsPool } from '../../../ComponentsPool';
import { useFootBar } from '../../footbar/FootBarContext';

const TopSection = () => {
    const { Component } = useComponentsPool();
    const footBar = useFootBar();
    return (
        <div className="top-section">
            <Component
                componentName={'NavigationBar'}
            />
            <Component
                componentName={'FootbarWrapper'}
            />
            {
                FOOTBAR_POSITION === 'top' && !footBar.isVisible
                    ? <Component componentName={'SubBar'} />
                    : null
            }
        </div>
    );
};

export default TopSection;

// Lib
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
// Context
import Tooltip from '@material-ui/core/Tooltip';
import { Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@mui/material/Dialog';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Paper from '@mui/material/Paper';
import { useComponentsPool } from '../../../../ComponentsPool';
import { getDocumentTypeIcon } from '../../../documents/helper';
import Icon from '../../../layout/components/Icon';
import {
    EVENT_OPEN_DOCUMENT_CONFIG_MENU, getInputFileUrlForThumbnail,
    getScssVariables,
    segmentTrack
} from '../../../layout/helper';
import noDocuments from '../../../layout/assets/documentsIllustration.png';
import createLoadingSelector from '../../../layout/actions';
import { getDocumentById, getDocuments } from '../../../documents/actions';
import FileViewer from '../../../documents/components/FileViewer';
import EditQRCodesDocument from './EditQRCodesDocument';
import { userCanSeeDevice } from '../../../devices/helper';
import CreateQRCodesDocument from './CreateQRCodesDocument';
import DocumentSearchBar from '../../../navbar/components/DocumentSearchBar';

const loadingSelector = createLoadingSelector(['GET_DOCUMENT_LIST']);
const QRCodesDocuments = () => {
    const { t } = useTranslation();
    const { Component } = useComponentsPool();
    const documents = useSelector(state => state.documents.list)?.sort((a, b) => b.currently_assigned_device_ids.length - a.currently_assigned_device_ids.length);
    const [filteredDocuments, setFilteredDocuments] = useState(documents);
    const dispatch = useDispatch();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const devices = useSelector(state => state.devices.list);
    const [previewedDocument, setPreviewedDocument] = useState(null);
    const [displayPreviewButton, setDisplayPreviewButton] = useState(null);
    const [documentInEdition, setDocumentInEdition] = useState(null);
    const [documentIsBeingCreated, setDocumentIsBeingCreated] = useState(false);
    const [isVisibleSearchBar, setIsVisibleSearchBar] = useState(true);

    const useStylesQRCodesTooltip = makeStyles(() => ({
        tooltip: {
            background: `${getScssVariables().secondMainColorLightest} !important`,
            color: `${getScssVariables().secondMainColor} !important`,
            padding: '15px 15px 5px 15px !important'
        }
    }));
    const useStylesDocumentTooltip = makeStyles(() => ({
        tooltip: {
            background: `${getScssVariables().secondMainColorLightest} !important`,
            color: `${getScssVariables().secondMainColor} !important`,
            padding: '15px !important',
            fontWeight: '600 !important'
        }
    }));
    const useStyles2 = makeStyles(() => ({
        tooltip: {
            background: '#FFF4DA !important',
            color: `${getScssVariables().inProgressColor} !important`,
            padding: '15px !important',
            fontWeight: '600 !important'
        }
    }));
    const useStylesPaper = makeStyles(() => ({
        paper: {
            zIndex: 2, // Prevent searchbar to go on top when the paper is sliding
            position: 'absolute',
            width: '100%',
            height: '100%',
            boxShadow: 'none !important'
        }
    }));
    const classes = useStylesPaper();
    const documentQRCodesTooltip = useStylesQRCodesTooltip();
    const documentTooltip = useStylesDocumentTooltip();
    const noAssignmentDocumentTooltip = useStyles2();

    useEffect(() => {
        dispatch(getDocuments());
        segmentTrack(EVENT_OPEN_DOCUMENT_CONFIG_MENU);
    }, []);

    useEffect(() => {
        setFilteredDocuments(documents)
    }, [documents]);

    const slideDuration = 500;
    useEffect(() => { // Prevent searchbar from disappearing too fast (before the slide of the paper finished)
        if (documentIsBeingCreated || documentInEdition) {
            setTimeout(() => {
                setFilteredDocuments(documents); // Reset the filters
                setIsVisibleSearchBar(false);
            }, slideDuration);
        } else {
            setIsVisibleSearchBar(true);
        }
    }, [documentIsBeingCreated, documentInEdition]);

    const getTooltipContent = document => {
        let hiddenQRCodes = 0;
        let visibleQRCodes = 0;
        const tooltipLines = [];

        document.currently_assigned_device_ids.map(assignedDeviceId => {
            const deviceName = userCanSeeDevice(devices, assignedDeviceId);
            if (!deviceName) { // We hide the names of QR Codes the user doesn't have rights on
                hiddenQRCodes = hiddenQRCodes + 1;
            } else { // We display 8 QR Codes name maximum
                visibleQRCodes = visibleQRCodes + 1;
                if (tooltipLines.length < 8) {
                    tooltipLines.push(deviceName);
                }
            }
        });

        if (visibleQRCodes > 8) { tooltipLines.push('...'); }
        if (hiddenQRCodes > 0) {
            const multipleHiddenQRCodes = hiddenQRCodes > 1;
            if (visibleQRCodes === 0) { // If there is only hidden qrcodes
                tooltipLines.push(t(`documents:concealed_${multipleHiddenQRCodes ? 'qrcodes' : 'qrcode'}`, { numberOfQrcodes: hiddenQRCodes }));
            } else {
                tooltipLines.push(`+${t(`documents:concealed_${multipleHiddenQRCodes ? 'qrcodes' : 'qrcode'}`, { numberOfQrcodes: hiddenQRCodes })}`);
            }
        }
        return tooltipLines.map(line => <p style={{ marginBottom: '10px' }}>{line}</p>);
    };

    const isDocumentWithoutQrCode = doc => doc.currently_assigned_device_ids.length === 0;
    return (
        <>
            <div className={'configuration-page-content'}>
                <div className={'configuration-page-top-content'}>
                    <Component componentName={'Icon'} type={'file'} />
                    <div className={'configuration-page-title'}>
                        <h3>{t('workspace:configure_qrcodes_documents')}</h3>
                    </div>
                </div>
                {documents.length > 0 && isVisibleSearchBar && (
                    <div className={'documents-list-searchbar-wrapper'}>
                        <DocumentSearchBar
                            objectsToFilter={documents}
                            isDocumentListFilter
                            filterBy={['locations', 'devices', 'title']}
                            setResults={results => setFilteredDocuments(results)}
                        />
                    </div>
                )}
                <div className={'configuration-page-text configuration-page-document-grid' }>
                    <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
                    <Grid container spacing={3} columns={window.screen.width <= 1280 ? 3 : 4}>
                        {(documents.length > 0 && filteredDocuments.length > 0)
                            ? filteredDocuments.map((document, docIdx) => (
                                <Grid item xs={1}>
                                    <div
                                        className={classNames(['configuration-page-document-card-wrapper', { 'configuration-page-no-assignment-document-card-wrapper': isDocumentWithoutQrCode(document) }])}
                                    >
                                        <div className={'document-card-content-wrapper'}>
                                            <div className={classNames(['document-card-content', { 'png-document-card-content': document.type === 'PNG' }])}>
                                                <div
                                                    className={classNames(['document-card-content-overlay', { 'preview-button-wrapper': docIdx === displayPreviewButton }])}
                                                    onMouseOver={() => setDisplayPreviewButton(docIdx)}
                                                    onMouseLeave={() => setDisplayPreviewButton(null)}
                                                    onClick={() => setPreviewedDocument(document)}
                                                >
                                                    {docIdx === displayPreviewButton
                                                        && (
                                                            <Button onClick={e => {
                                                                e.stopPropagation();
                                                                dispatch(getDocumentById(document.id)).then(res => setDocumentInEdition(res.value));
                                                            }}>
                                                                <Icon componentName={'Icon'} type={'faPen'} />
                                                                <span>{t('custom:edit_default')}</span>
                                                            </Button>
                                                        )
                                                    }
                                                </div>
                                                <img
                                                    alt="file-preview"
                                                    src={getInputFileUrlForThumbnail(document.url, document.type)}
                                                />
                                            </div>
                                        </div>
                                        <div className={'document-card-footer'}>
                                            <div className={'document-card-footer-main-info'}>
                                                <div className={'document-card-footer-left-side'}>
                                                    <Tooltip placement="top" title={document.type} classes={documentTooltip}>
                                                        <div><Icon type={getDocumentTypeIcon(document.type, true)} /></div>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title={document.title} classes={documentTooltip}>
                                                        <p>{document.title}</p>
                                                    </Tooltip>
                                                </div>
                                                <Tooltip
                                                    placement={'bottom'}
                                                    classes={!isDocumentWithoutQrCode(document) ? documentQRCodesTooltip : noAssignmentDocumentTooltip}
                                                    title={!isDocumentWithoutQrCode(document)
                                                        ? getTooltipContent(document)
                                                        : t('passages:document_doesnt_appear_on_qrcode')
                                                    }
                                                >
                                                    <div className={'document-card-chip'}>
                                                        <p>{document.currently_assigned_device_ids.length}</p>
                                                        <Icon componentName={'Icon'} type={'QRCODE'} />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            )) : ( // If the document list is loading or there aren't any documents
                                <div className={'no-documents-illustration-wrapper'}>
                                    {!isLoading && (
                                        <>
                                            <img alt="no-document" src={noDocuments} />
                                            <p>{documents.length === 0 ? t('workspace:you_dont_have_documents') : t('common:no_result')}</p>
                                        </>
                                    )}
                                </div>
                            )
                        }
                    </Grid>
                </div>
            </div>
            <Slide // Display Document Edition
                direction="left"
                in={Boolean(documentInEdition) && !documentIsBeingCreated}
                timeout={{ enter: slideDuration, exit: slideDuration }}
                mountOnEnter
                unmountOnExit
            >
                <Paper className={classes.paper}>
                    <EditQRCodesDocument
                        documentInEdition={documentInEdition}
                        goBackToDocumentsListView={() => setDocumentInEdition(null)}
                        setPreviewedDocument={document => setPreviewedDocument(document)}
                    />
                </Paper>
            </Slide>
            <Slide // Display Document Creation
                direction="left"
                in={documentIsBeingCreated && !documentInEdition}
                timeout={{ enter: slideDuration, exit: slideDuration }}
                mountOnEnter
                unmountOnExit
            >
                <Paper className={classes.paper}>
                    <CreateQRCodesDocument
                        goBackToDocumentsListView={() => setDocumentIsBeingCreated(false)}
                        setPreviewedDocument={document => setPreviewedDocument(document)}
                    />
                </Paper>
            </Slide>
            <Dialog // Preview document
                className={'file-viewer-wrapper'}
                open={Boolean(previewedDocument)}
                onClose={() => setPreviewedDocument(null)}
            >
                <FileViewer document={previewedDocument} />
            </Dialog>
            {!documentInEdition && !documentIsBeingCreated && ( // Action Button
                <div className={'action-bar-wrapper'}>
                    <div className={'action-button modal-action-button'}>
                        <Tooltip
                            placement="left"
                            classes={documentTooltip}
                            title={t('documents:create_document')}
                        >
                            <Fab aria-label={'Group'} onClick={() => setDocumentIsBeingCreated(true)}>{<Icon type={'plus'} />}</Fab>
                        </Tooltip>
                    </div>
                </div>
            )}
        </>
    );
};

export default QRCodesDocuments;

// Libs
import React, { useEffect, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
// isMobile
import { isMobile } from 'react-device-detect';
// Components
import Drawer from '@material-ui/core/Drawer';
import { AppBar } from '@material-ui/core';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// Context
import { useComponentsPool } from '../../ComponentsPool';
import { useFootBar } from './FootBarContext';
import { useModal } from '../modal/ModalContext';
// SCSS
import './style.footbar.scss';
// Helpers
import { canSeeMobileBottomNavigation } from '../users/helper';

const FootBar = () => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const footBar = useFootBar();
    const modal = useModal();
    const routes = useSelector(state => state.footbar.routes);
    const { getComponentFile } = useComponentsPool();
    const history = useHistory();
    const devices = useSelector(state => state.devices.list);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const currentUser = useSelector(state => state.users.currentUser);

    // eslint-disable-next-line consistent-return
    const getPathByButtonValue = value => {
        // eslint-disable-next-line default-case
        switch (value) {
            case 0:
                return '/tickets';
            case 1:
                return '/rounds';
        }
    };

    const [currentValue, setCurrentValue] = useState(history.location.pathname.includes('/tickets') ? 0 : 1);
    const [currentPath, setCurrentPath] = useState(getPathByButtonValue(currentValue));

    // if a user enter /fdsjksdfh he will be redirected so we need to get the right path again
     useEffect(() => {
        setCurrentValue(history.location.pathname.includes('/tickets') ? 0 : 1);
    }, [history.location.pathname]);

    useEffect(() => {
        setCurrentPath(getPathByButtonValue(currentValue));
    }, [currentValue]);

    if (isMobile) {
        return canSeeMobileBottomNavigation(currentUser, history.location.pathname, devices, workspaceSettings) ? (
            <div className={'footbar-bottom-navigation-mobile'}>
                <BottomNavigation
                    showLabels
                    value={currentValue}
                    onChange={(event, value) => {
                        setCurrentValue(value);
                        history.push(getPathByButtonValue(value));
                    }}
                >
                    <BottomNavigationAction
                        label={t('tickets:tickets')}
                        icon={<Component componentName={'Icon'} type={currentPath === '/tickets' ? 'ticketsFilled' : 'tickets'} />}
                    />
                    <BottomNavigationAction
                        label={t('rounds:planning')}
                        icon={<Component componentName={'Icon'} type={currentPath === '/rounds' ? 'roundsFilled' : 'rounds'} />}
                    />
                </BottomNavigation>
            </div>
        ) : <></>;
    }
    return (
        <Drawer
            className={classNames([
                'footBar-wrapper',
                { zIndex: modal.getWithFootBar() },
                FOOTBAR_POSITION === 'top' ? 'footBar-wrapper-top' : null,
                modal.isVisible ? 'popup-shown' : null
            ])}
            transitionDuration={FOOTBAR_POSITION === 'top' ? 0 : 220}
            variant="persistent"
            anchor={FOOTBAR_POSITION === 'top' ? 'top' : 'bottom'}
            open={footBar.getIsVisible() || footBar.getSelected().length !== 0 || modal.getWithFootBar()}
        >
            <AppBar position="static">
                <Switch>
                    { routes.map(route => (
                        <Route
                            exact
                            key={route.path}
                            path={route.path}
                            component={getComponentFile(route.component)}
                        />
                    ))}
                </Switch>
            </AppBar>
        </Drawer>
    );
};

export default withRouter(FootBar);

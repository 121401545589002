// Libs
import React from 'react';
// Components
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
// Context
import classNames from 'classnames';
import { useComponentsPool } from '../../../ComponentsPool';

const DataButton = props => {
    const { Component } = useComponentsPool();

    return (
        <div className={'button-wrapper'}>
            <Tooltip placement={'top'} title={props.tooltip}>
                <Chip
                    className={classNames(['group-name', 'data-preview-button', { 'component-important': props.state }])}
                    icon={<Component componentName={'Icon'} type={props.icon} />}
                    label={props.label}
                />
            </Tooltip>
        </div>

    );
};

export default DataButton;

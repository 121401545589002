exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/*\n * @Import mixin\n * Important for call hexToRGB\n */\n:root {\n  --background-color: #F6F1F0;\n  --background-color-lighter: #F9F6F5;\n  --primary-color--hsl: 279;\n  --primary-color: #011056;\n  --primary-color-lightest: #F2F3F6;\n  --main-color: #064892;\n  --primary-color--rgb: 1, 16, 86;\n  --secondary-color: #E5E5E5;\n  --secondary-color--rgb: 229, 229, 229;\n  --high-light-color: #3ECCCC;\n  --medium-high-light-color: #395ced;\n  --medium-light-color: #3dc3e3;\n  --second-main-color: #7084E9;\n  --second-main-color-light: #DFE3FA;\n  --second-main-color-lightest: #F1F3FD;\n  --text-color: #353C3A;\n  --text-color-lighter: #5E6462;\n  --text-color-lighter2: #909493;\n  --text-color-lightest: #E1E2E1;\n  --green-color: #3ECCCC;\n  --red-color: #DD2476;\n  --orange-color: #FF9306;\n  --error-color: #f44336;\n  --text-color-form: rgba(0, 0, 0, 0.87);\n  --ticket-priority-1-color: #ffb50d;\n  --ticket-priority-2-color: #ff0000;\n  --incomplete-color: #FF2323;\n  --incomplete-color-lighter: #FEDCDF;\n  --incomplete-color-lightest: #FFEDEE;\n  --complete-color: #57C9AE;\n  --complete-color-lighter: #D1F6ED;\n  --complete-color-lightest: #E6FAF5;\n  --not-started-color: #D4DAF8;\n  --in-progress-color: #ffb50d;\n  --in-progress-color-lighter: #FFF2C4;\n  --in-progress-color-lightest: #FFF8DF;\n  --grouped-color: #BCBCBC;\n}", ""]);
// Exports
exports.locals = {
	"primaryColor": "var(--primary-color)",
	"secondMainColor": "var(--second-main-color)",
	"secondMainColorLightest": "var(--second-main-color-lightest)",
	"inProgressColor": "var(--in-progress-color)",
	"textColor": "var(--text-color)",
	"textColorLighter2": "var(--text-color-lighter2)"
};
import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from 'react-avatar';
import { isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';
// Components
import { useComponentsPool } from '../../../../ComponentsPool';
// Helper
import { getUserFromId } from '../../../users/helper';
// Actions
import { deleteInstanceComments } from '../../actions';
import { deleteDeviceComments } from '../../../devices/actions';
import { getScssVariables } from "../../../layout/helper";
import CommentsBubble from '../../../layout/assets/commentsBubble.svg';
import PictureAndTexts from "../../../layout/components/PictureAndTexts";

const MobileInstanceCommentsList = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.users.currentUser);
    const comments = props.comments;
    const users = useSelector(state => state.users.list);

    const [isHoveredCommentIdx, setIsHoveredCommentIdx] = useState(null);
    const useStyles = makeStyles(() => ({
        tooltip: {
            '&::before': {
                width: '500px'
            }
        }
    }));
    const classes = useStyles();

    const deleteComment = (instanceCommentId, deviceCommentId, authorId, deviceId) => {
        if (currentUser.id === authorId) {
            if (instanceCommentId) {
                dispatch(deleteInstanceComments(instanceCommentId));
            }
            else if (deviceCommentId) {
                dispatch(deleteDeviceComments(deviceId, deviceCommentId));
            }
        }
    };

    const getStatusText = (status, event_timestamp) => {
        const eventDate = moment(event_timestamp).format('DD/MM/YY');
        const eventTime = moment(event_timestamp).format('HH[h]mm');
        return <span>{`${eventDate} ${eventTime}`}</span>;
    };

    const getAvatar = (authorId) => {
        if (authorId) {
            const author = getUserFromId(users, authorId);
            return (
                <div className={'row-avatar'}>
                    <Component
                        componentName={'UserAvatar'}
                        picture={author?.profile_picture_url}
                        user={author}
                        size="25"
                    />
                </div>
            );
        }
        return (
            <div className={'comment-bubble'}>
                <Avatar
                    src={'https://res.cloudinary.com/iotv-keepcontact/image/upload/v1528904820/iot-rocket/user.png'}
                    size="25"
                    round
                />
            </div>
        );
    };

    const isImage = file => {
        let suffix = file.split('.');
        if (suffix.length > 0) {
            suffix = suffix[suffix.length - 1];
        } else {
            return false;
        }
        return suffix === 'jpg' || suffix === 'jpeg' || suffix === 'bmp' || suffix === 'png' || suffix === 'svg';
    };

    const getCommentComponent = (idx, eventDetail) => {
        const location = eventDetail.device_name && eventDetail.building_name
            ? `${eventDetail.device_name} - ${eventDetail.building_name}, ${eventDetail.floor_name}, ${eventDetail.position_name}`
            : '';
        const attachedDocumentUrl = eventDetail.uploaded_content_url;
        return (
            <div
                className={'comment-content'}
                onMouseEnter={() => setIsHoveredCommentIdx(idx)}
                onMouseLeave={() => setIsHoveredCommentIdx(null)}
            >
                <span className={'comment-header'}>
                    <div className={'comment-info-mobile'}>
                        <p>{`${t(`users:${eventDetail.author_name}`)} ${t('rounds:user_add_comment')}`}</p>
                        { currentUser.id === eventDetail.author_id
                            && (
                                <div
                                    tabIndex={0}
                                    role={'button'}
                                    className={'delete-comment-button'}
                                >
                                    <Button
                                        onClick={() => deleteComment(eventDetail.round_instance_comment_id,
                                            eventDetail.device_comment_id, eventDetail.author_id, eventDetail.device_id
                                        )}
                                        color="primary"
                                    >
                                        <Component componentName={'Icon'} type={'trash'} />
                                    </Button>
                                </div>
                            )
                        }
                    </div>
                    <p className={'comment-device-name'}>{location}</p>
                </span>
                { !isEmpty(eventDetail.comment)
                    && (
                        <div className={'comment-text'}>
                            <span>{eventDetail.comment}</span>
                        </div>
                    )
                }
                {
                    !isEmpty(attachedDocumentUrl)
                    && (
                        <div className={'comment-text'}>
                            {isImage(attachedDocumentUrl) ? <img src={attachedDocumentUrl} alt={''} /> : <a href={attachedDocumentUrl}>{t('rounds:document_link')}</a>}
                        </div>
                    )
                }
            </div>
        );
    };

    return (
        <div className="logs logs-historic instance-with-margin-right">
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flex: '1 1 auto' }}>
                    {comments.length === 0
                        ? (
                            <PictureAndTexts
                                picture={<CommentsBubble />}
                                customSvgWidth={'40vw'}
                                subText={t('rounds:no_comments_on_this_event')}
                                customSubTextColor={getScssVariables().textColorLighter2}
                                customSubTextMarginBottom={'50px'}
                            />
                        )
                        : comments.map((event, index) => (
                            <div className={'row-item-mobile'} key={`row-${index}`}>
                                <div className={'row-icon'}>
                                    <span className={'row-details'}>
                                        {getStatusText(event.event_detail?.status, event.event_timestamp)}
                                    </span>
                                    <div className={'row-timeline'}>
                                        {getAvatar(event.event_detail?.author_id)}
                                        {comments.length !== index + 1 && <div className={'line'} />}
                                    </div>
                                </div>
                                <div className={'row-content'}>
                                    {getCommentComponent(index, event.event_detail)}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default MobileInstanceCommentsList;

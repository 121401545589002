// Libs
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
import { useModal } from '../../modal/ModalContext';
import { useFootBar } from '../../footbar/FootBarContext';
import { useFormState } from '../../layout/forms/api/useFormState';
// Actions
import { editGroup, getGroupList } from '../../groups/actions';
import getTemplateList from '../../templates/actions';

const SettingsActionBar = () => {
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const footBar = useFootBar();
    const modal = useModal();
    const { formState } = useFormState();
    const userWorkspaceRights = useSelector(state => state.users.currentUser.workspace_rights);
    const groups = useSelector(state => state.groups.list);
    const buttons = useSelector(state => sortBy(state.groups.footbarButtons, 'weight'));
    const list = modal.getWithFootBar() ? [modal.getItem()] : footBar.getList();

    const updateGroup = () => {
        dispatch(editGroup(formState.group_id, {
            group_name: formState.group_name,
            group_groups: formState.group_groups,
            group_templates: formState.group_templates
        }, formState?.custom_field?.type)).then(() => {
            if (formState?.custom_field?.type === 'BATIMENT') {
                dispatch(getGroupList());
            } else if (formState?.custom_field?.type === 'SKILL') {
                dispatch(getTemplateList());
            }
            modal.close();
        });
    };

    const footbarButtons = useMemo(() => buttons.map(button => (
        <Component
            key={button.component}
            componentName={button.component}
            userWorkspaceRights={userWorkspaceRights}
            list={list}
        />
    )), [buttons]);

    return (
        <Component
            componentName={'Footbar'}
            actionButtons={footbarButtons}
            modal={'SettingsDetailsPopup'}
            resourceList={groups}
            switchButtonCallback={() => updateGroup()}
            resourceType={'Group'}
            locale={'groups'}
        />
    );
};

export default SettingsActionBar;

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
// Context
import { useComponentsPool } from '../../../ComponentsPool';

export default () => {
    const { Component } = useComponentsPool();
    const userWorkspaceRights = useSelector(state => state.users.currentUser.workspace_rights);

    return (
        <Component
            componentName={'ActionButton'}
            canUserCreate={userWorkspaceRights.includes('CREATE:Group')}
            modalName={'CreateGroupPopup'}
            iconButton={(<Component componentName={'Icon'} type={'deviceGroup'} />)}
            tooltipTitle={'descriptions:groups_actionbar'}
        />
    );
};

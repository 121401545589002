import axios from 'axios';
import moment from 'moment';
import { isEmpty } from 'lodash';

export const notification = payload => ({ type: 'NOTIFICATION', payload });

export const editDevice = (id, data, msg) => ({
    type: msg || 'EDIT_DEVICE',
    payload: axios.patch(`/devices/${id}/`, data)
        .then(res => res.data)
});

export const resetSelectedDevice = () => ({ type: 'RESET_SELECTED_DEVICE' });

export const editDeviceLocally = (id, data, msg) => ({
    type: msg || 'EDIT_DEVICE_LOCALLY',
    payload: { device_id: id, ...data }
});

export const addDeviceToGroupLocally = (device_ids, group_id) => ({
    type: 'ADD_GROUP_TO_DEVICE_LOCALLY',
    payload: { device_ids, group_id }
});

export const updateGroupToDevices = (ids, group_ids) => ({
    type: 'ADD_GROUP_TO_DEVICE',
    payload: axios.patch('/devices/', { group_ids, ids })
        .then(res => res.data)
});

export const getDeviceList = () => ({
    type: 'GET_DEVICE_LIST',
    payload: axios.get('/devices/') // /page/${page}
        .then(res => res.data)
});

export const getLastReceivedDeviceData = (deviceType = null, refreshStore = true, isAccessibleOffline = false) => {
    const queryParams = [];
    if (deviceType) {
        queryParams.push(`deviceType=${deviceType}`);
    } if (isAccessibleOffline) {
        queryParams.push('isAccessibleOffline=true');
    }
    const filter = queryParams.length ? `?${queryParams.join('&')}` : '';
    return {
        type: refreshStore ? 'GET_LAST_RECEIVED_DEVICE_DATA' : 'NO_REDUCER',
        payload: axios.get(`/devices/last-received-device-data${filter}`)
            .then(res => res.data)
    };
};

export const preloadQRCodesCache = forcingStrategy => {
    const headers = {};
    if (forcingStrategy) {
        headers['X-Option-For-QRCodesPreloadCacheStrategy'] = forcingStrategy;
    }
    return {
        type: 'NO_REDUCER',
        payload: axios.get('/devices/preload_qrcodes_cache', { headers })
            .then(res => res.data)
    };
};


export const setDeviceShownInDetailPopup = device => ({ type: 'SET_DEVICE_DETAIL', payload: device });

export const getHardwareList = () => ({
    type: 'GET_HARDWARE',
    payload: axios.get('/devices/hardwares')
        .then(res => res.data)
});

export const addDevice = devices => ({
    type: 'ADD_DEVICE',
    payload: axios.post('/devices/', { devices })
        .then(({ data }) => data)
});

export const hardwareAlreadyAssign = () => ({ type: 'HARDWARE_IDS_ALREADY_ASSIGN', payload: true });

export const addDeviceEditableField = field => ({ type: 'ADD_DEVICE_EDITABLE_FIELD', payload: field });

export const getPreviewDeviceData = device_id => ({
    type: 'GET_DEVICE_DATA_PREVIEW',
    payload: axios.get(`/devices/get_device_data_preview/${device_id}`)
        .then(res => ({ device_id, data: res.data }))
        .catch(error => error)
});

export const getDeviceDataExport = device_id => ({
    type: 'GET_DEVICE_DATA_EXPORT',
    payload: axios.get(`/devices/get_device_data_export/${device_id}`)
        .then(res => ({ device_id, data: res.data }))
        .catch(error => error)
});

export const getDevicesExport = (devices_ids, timeSlots = {}) => ({
    type: 'GET_DEVICES_EXPORT',
    payload: axios.post('/devices/', { devices_ids }, {
        params: {
            action: 'export',
            time_slots: JSON.stringify(timeSlots)
        }
    })
        .then(res => ({ devices_ids, data: res.data }))
        .catch(error => error)
});


export const addDeviceCsvFile = csv_file => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('devices', csv_file);
    return {
        type: 'ADD_DEVICE_CSV_FILE',
        payload: axios.post('/devices/save-devices-from-file', formData, config)
            .then(res => res.data)
    };
};

export const savePassage = (deviceId, userId, jobNames) => ({
    type: 'SAVE_PASSAGE',
    payload: axios.post(`/devices/${deviceId}/messages`, {
        user_id: userId,
        jobs: jobNames,
        timestamp: moment().format()
    })
        .then(res => res.data)
});

export const getDeviceByHardwareId = (hardware_id, anonymousConnexion = false,
    refreshStore = true, forcingStrategy = null) => {
    const headers = anonymousConnexion
        ? {
            'x-master-api-key': QRCODE_ISSUE_REPORT_API_KEY,
            username: USERNAME_ISSUE_REPORT,
            password: PASSWORD_ISSUE_REPORT
        }
        : {};
    if (forcingStrategy) {
        headers['X-Use-Strategy'] = forcingStrategy;
    }

    return {
        type: refreshStore ? 'GET_DEVICE' : 'NO_REDUCER',
        payload: axios.get(`/devices/get-device-by-hardware-id/${hardware_id}`, { headers })
            .then(res => res.data)
    };
};

export const postDeviceComments = (device_id, textContent, fileUrl, authorId, relatedMessageId) => {
    const payload = {
        text_content: isEmpty(textContent) ? null : textContent, // Set '' to null
        uploaded_content_url: fileUrl,
        author_id: authorId,
        related_message_id: relatedMessageId
    };
    return {
        type: 'POST_DEVICE_COMMENTS',
        payload: axios.post(`/devices/${device_id}/comments/`, payload)
            .then(res => res.data)
    };
};

export const deleteDeviceComments = (device_id, device_comment_id) => ({
    type: 'DELETE_DEVICE_COMMENTS',
    payload: axios.delete(`devices/${device_id}/comments/${device_comment_id}/`)
        .then(() => device_comment_id)
});

export const deleteDevices = data => ({
    type: data.device_ids.length > 1 ? 'DELETE_DEVICES' : 'DELETE_DEVICE',
    payload: axios.delete('/devices/', { data })
        .then(() => data)
});

export const getDeviceMetabaseUrl = (hardware_id, device_reference, device_type) => ({
    type: 'GET_DEVICE_METABASE_URL',
    payload: axios.get(`dashboards/get_metabase_dashboard?dashboard_type=DEVICE&hardware_id=${
        hardware_id}&device_reference=${device_reference}&device_type=${device_type}`)
        .then(res => ({ data: res.data }))
});

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import moment from 'moment';
// Components
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';
// Helper
import { isValid } from '../../../layout/helper';
import {
    getRoundEditionLeftSideBottomFields,
    getIntervalFromRrule,
    maxNumberOfChipsInFieldOfPopupLeftSide, workspaceDoesntHaveQrcodePassageDevices
} from '../../helper';
import RoundsCreateEditModal from '../RoundsCreateEditModal';

const RoundDetailsPopupPartLeft = props => {
    const footbar = useFootBar();
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const round = useSelector(state => state.rounds.roundShownInDetailsPopup);
    const editableFields = useSelector(state => sortBy(state.rounds.editableFields, 'weight'));
    const roundDevicesField = editableFields.find(field => field.name === 'round_devices');
    const roundAssigneesField = editableFields.find(field => field.name === 'assignees');
    const roundFollowersField = editableFields.find(field => field.name === 'followers');
    const roundExpectedJobsField = editableFields.find(field => field.name === 'expectedjobs');
    const roundPlanificationField = editableFields.find(field => field.name === 'planification');
    const deviceList = useSelector(state => state.devices.list);
    const bottomFields = getRoundEditionLeftSideBottomFields(footbar, round);
    const isDisplayedFollowersField = bottomFields.followers.isDisplayed && roundFollowersField;
    const isDisplayedAssigneesField = bottomFields.assignees.isDisplayed && roundAssigneesField;
    const isDisplayedJobsField = bottomFields.expectedJobs.isDisplayed && roundExpectedJobsField
        && !workspaceDoesntHaveQrcodePassageDevices(deviceList);
    const allBottomFieldsAreHidden = !isDisplayedFollowersField && !isDisplayedAssigneesField && !isDisplayedJobsField;
    // eslint-disable-next-line max-len
    const maxNumberOfChipsToDisplay = maxNumberOfChipsInFieldOfPopupLeftSide(Object.entries(bottomFields).filter(obj => obj[1].isDisplayed).length);

    return (
        <>
            <div className={'resource-infos-wrapper round-side'}>
                <div className={'title round-header'}>
                    <div className={'round-clouds-wrapper'}>
                        <Component componentName={'Icon'} type={'roundClouds'} />
                    </div>
                    <div className={'round-title'}>
                        <Component componentName={'Icon'} type={'roundsFilled2'} />
                        <div className={'round-title-planification'}>
                            <Component
                                key={props.state.id}
                                componentName={'EditableField'}
                                title={''}
                                props={{
                                    value: 'name',
                                    required: true,
                                    rules: ['maxLength-64', 'selectNotEmpty']
                                }}
                                renderValue={'ModalHeadSimpleString'}
                            />
                            <Component
                                key={`${roundPlanificationField.name}-${props.state.id}`}
                                title={t(roundPlanificationField.title)}
                                subtitle={t(roundPlanificationField.subtitle)}
                                componentName={'EditableField'}
                                renderValue={roundPlanificationField.renderValue}
                                props={{
                                    ...roundPlanificationField.props,
                                    rrule: props.state.rrule,
                                    started_at: props.state.started_at,
                                    ended_at: props.state.ended_at,
                                    interval: getIntervalFromRrule(props.state.rrule),
                                    isNotValidName: isValid(props.state.name)
                                }}
                            />
                            {!props.state.active && props.state.deleted_at && (
                                <span className={'archived-date'}>
                                    {t(
                                        'rounds:archived_since',
                                        { date: moment(props.state.deleted_at).format('DD/MM/YYYY') }
                                    )}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className={'round-steps-users-wrapper'}>
                    {
                        footbar.getEditionMode() ? (
                            <RoundsCreateEditModal
                                isEditionModal
                                updateState={roundState => props.updateState(roundState)}
                                name={props.state.name}
                                started_at={props.state.round_next_up_instance?.started_at}
                                ended_at={props.state.round_next_up_instance?.ended_at}
                                is_until_end_of_month={props.state.is_until_end_of_month}
                                rrule={props.state.rrule}
                                devices={props.state.round_devices.map(device => ({ id: device.device_id, validation_count: props.state.round_devices_max_validation_count }))}
                                followers_ids={props.state.round_followers.map(follower => follower.id)}
                                invite_followers_ids={props.state.round_invite_followers.map(follower => follower.id)}
                                assignees_ids={props.state.round_assignees.map(assignee => assignee.id)}
                                round_expected_jobs={props.state.round_expected_jobs}
                            />
                        ) : (
                            <>
                                <div className={
                                    allBottomFieldsAreHidden
                                        ? 'resource-infos round-step-full-height'
                                        : 'resource-infos round-step-custom-height'
                                }>
                                    <Component
                                        key={`${roundDevicesField.name}-${props.state.id}`}
                                        title={t(roundDevicesField.title)}
                                        subtitle={t(roundDevicesField.subtitle)}
                                        componentName={'EditableField'}
                                        renderValue={roundDevicesField.renderValue}
                                        props={roundDevicesField.props}
                                    />
                                </div>
                                <div className={allBottomFieldsAreHidden ? '' : 'round-bottom-fields-wrapper'}>
                                    {isDisplayedFollowersField && (
                                        <div className={'round-bottom-field-container'}>
                                            <Component
                                                key={`${roundFollowersField.name}-${props.state.id}`}
                                                title={t('rounds:people_to_alert')}
                                                subtitle={t(roundFollowersField.subtitle)}
                                                componentName={'EditableField'}
                                                renderValue={roundFollowersField.renderValue}
                                                props={{
                                                    ...roundFollowersField.props,
                                                    usersFieldType: 'followers',
                                                    deviceIds: props.state.round_devices.map(device => device.device_id),
                                                    selected: props.state.round_followers.map(follower => follower.id),
                                                    chipsRenderLimit: maxNumberOfChipsToDisplay
                                                }}
                                            />
                                        </div>
                                    )}
                                    {isDisplayedAssigneesField && (
                                        <div className={'round-bottom-field-container'}>
                                            <Component
                                                key={`${roundAssigneesField.name}-${props.state.id}`}
                                                title={t('rounds:round_assignees')}
                                                subtitle={t(roundAssigneesField.subtitle)}
                                                componentName={'EditableField'}
                                                renderValue={roundAssigneesField.renderValue}
                                                props={{
                                                    ...roundAssigneesField.props,
                                                    usersFieldType: 'assignees',
                                                    deviceIds: props.state.round_devices.map(device => device.device_id),
                                                    selected: props.state.round_assignees.map(assignee => assignee.id),
                                                    chipsRenderLimit: maxNumberOfChipsToDisplay
                                                }}
                                            />
                                        </div>
                                    )}
                                    {isDisplayedJobsField && (
                                        <div className={'round-bottom-field-container'}>
                                            <Component
                                                key={`${roundExpectedJobsField.name}-${props.state.id}`}
                                                title={t('rounds:expected_jobs_without_facultative')}
                                                subtitle={t(roundExpectedJobsField.subtitle)}
                                                componentName={'EditableField'}
                                                renderValue={roundExpectedJobsField.renderValue}
                                                props={{
                                                    ...roundExpectedJobsField.props,
                                                    assigneesIds: props.state.round_assignees.map(assignee => assignee.id),
                                                    devicesIds: props.state.round_devices.map(device => device.device_id),
                                                    selected: props.state.round_expected_jobs,
                                                    chipsRenderLimit: maxNumberOfChipsToDisplay
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default RoundDetailsPopupPartLeft;

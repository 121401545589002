// Libs
import React from 'react';
// Components
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
// SCSS
import '../styles/_inline_double_form.scss';

const Warning = props => (
    <div className="no-telephone-users">
        <p>{props.warning}</p>
        { props.users && props.users.length > 0 ? <ul>{props.users.map(user => <li key={user}>{user}</li>)}</ul> : '' }
    </div>
);

const InlineDoubleForm = props => (
    <div className="inline-double-form">
        { props.title &&
            <p className="workspace_role_title">{`${props.title} ${props.required ? '*' : ''}`}</p>
        }
        <div className="popup_toggle geofencing_alerts_toggle">
            <ToggleButtonGroup
                exclusive
                value={props.selector}
                onChange={props.onChange}
                classes={{ root: 'toggle_button_group' }}
            >
                <ToggleButton
                    href={''}
                    value={props.values[0]}
                    classes={{ root: 'toggle_button', selected: 'toggle_button-selected', label: 'toggle_button_label' }}
                >
                    {props.displayedValues ? props.displayedValues[0] : props.values[0]}
                </ToggleButton>
                <ToggleButton
                    href={''}
                    value={props.values[1]}
                    classes={{ root: 'toggle_button', selected: 'toggle_button-selected', label: 'toggle_button_label' }}
                >
                    {props.displayedValues ? props.displayedValues[1] : props.values[1]}
                </ToggleButton>
            </ToggleButtonGroup>
            {props.displayWarning && props.message.length > 0 && <Warning warning={props.message} users={props.list} />}
        </div>
    </div>
);

export default InlineDoubleForm;

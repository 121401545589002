// Libs
import React, { useState } from 'react';
// Context
import { useComponentsPool } from '../../../ComponentsPool';

const customField = CUSTOM_FIELD_DEVICE === '' || CUSTOM_FIELD_DEVICE === '""' ? [] : CUSTOM_FIELD_DEVICE.split(','); // eslint-disable-line
let customFieldObject = {};
customField.forEach(item => { customFieldObject = { ...customFieldObject, [item]: '' }; });
const CustomFieldCreateDevice = props => {
    const { Component } = useComponentsPool();

    const [fields, setFields] = useState(customFieldObject);
    const onChange = (item, value) => {
        setFields({ ...fields, [item]: value });
        props.onChange({ ...fields, [item]: value });
    };
    return Object.keys(customFieldObject).map(item => (
        <Component
            componentName={'Input'}
            key={item}
            required
            label={`devices:${item}`}
            onChange={value => onChange(item, value)}
            value={fields[item]}
            className={`device-${item}`}
        />
    ));
};

export default CustomFieldCreateDevice;

// Libs
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarCheck,
    faCalendarDay,
    faCheck,
    faEllipsisV,
    faFilter,
    faSearch,
    faArrowUp,
    faFileCheck,
    faWifiSlash
} from '@fortawesome/pro-regular-svg-icons';
import {
    faArchive,
    faArrowLeft,
    faBell,
    faBuilding,
    faCalendarAlt,
    faChevronLeft,
    faChevronRight,
    faCog,
    faPen,
    faSlidersH,
    faCommentDots,
    faCrown,
    faEnvelope,
    faEye,
    faFileExport,
    faChartLine,
    faFaceFrownSlight,
    faHandSparkles,
    faHistory,
    faInfoCircle,
    faExclamationTriangle as faExclamationTriangleLight,
    faList,
    faMapMarkerAlt,
    faPaperclip,
    faPhoneAlt,
    faSms,
    faCheckCircle,
    faPlus,
    faSave,
    faSearchLocation,
    faSignInAlt,
    faSignOutAlt,
    faChartSimple,
    faTicket,
    faHouse,
    faTimes,
    faTrashAlt,
    faUser,
    faUsers,
    faUserEdit,
    faUserMinus,
    faUserPlus,
    faUserTag,
    faWrench,
    faGlobeEurope,
    faTag,
    faQuestionCircle as faQuestionMark,
    faBookOpen,
    faHouseDamage,
    faSeedling,
    faArrowsRotate
} from '@fortawesome/pro-light-svg-icons';
import {
    faCloudArrowDown, faUser as faUserFilled, faUsers as faUsersFilled, faUserFriends as faUserFriendsFilled, faChartSimple as faChartSimpleFilled,
    faChevronDown, faChevronUp, faBell as faBellFilled, faEyeSlash, faEdit, faQuestionCircle, faFileExcel,
    faExclamationTriangle, faUserTimes, faTicket as faTicketAltFilled, faPaperPlane, faWifi, faShare,
    faCircleQuestion as faCircleQuestionFilled
} from '@fortawesome/free-solid-svg-icons';
import {
    faLockAlt, faMapMarker, faCamera, faPaperPlaneTop, faBellOn, faBellSlash
} from '@fortawesome/pro-solid-svg-icons';
import EventIcon from '@material-ui/icons/Event';
import Error from '@material-ui/icons/Error';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRounded from '@material-ui/icons/ArrowForwardIosRounded';
// SVG
import { faChevronDoubleRight, faClipboardList, faImage } from '@fortawesome/pro-duotone-svg-icons';
import ArrowRightIcon from '../assets/arrow-right.svg';
import TimerOffIcon from '../assets/icons/time-left.svg';
import PercentIcon from '../assets/icons/percent.svg';
import GoogleIcon from '../assets/icons/googleIcon.svg';
import GroupIcon from '../assets/icons/group.svg';
import ChatbotIcon from '../../qrcodes/assets/ai_chatbot.svg';
import ExternalLink from '../assets/icons/external-link.svg';
import OutcomingPhone from '../assets/icons/outcoming-phone.svg';
import GroupFilledIcon from '../assets/icons/groupFilled.svg';
import DeviceGroupFilledIcon from '../assets/icons/device-group-icon.svg';
import DeviceFilledIcon from '../../devices/assets/icons/deviceSolid.svg';
import KeyIcon from '../../workspace/assets/keyIcon.svg';
import LockIcon from '../../groups/assets/lock.svg';
import PersonFalling from '../../groups/assets/person-falling.svg';
import ExclamationPoint from '../../groups/assets/exclamation-point.svg';
import EnvelopIcon from '../../workspace/assets/EnvelopIcon.svg';
import WorkspaceIcon from '../../workspace/assets/workspaceIcon.svg';
import MonkeyWrenchIcon from '../../workspace/assets/monkeyWrench.svg';
import ArchiveClockIcon from '../../workspace/assets/archiveClock.svg';
import MailTicketIcon from '../../workspace/assets/mailTicket.svg';
import ClockEnveloppeIcon from '../../workspace/assets/clockEnveloppe.svg';
import FileIcon from '../../workspace/assets/file.svg';
import ContractorIcon from '../../workspace/assets/contractor.svg';
import UserIcon from '../../workspace/assets/user.svg';
import AddGraphIcon from '../assets/icons/createGraph.svg';
import UploadIcon from '../../devices/assets/icons/file-upload.svg';
import PenIcon from '../../devices/assets/icons/pen.svg';
import StopWatchIcon from '../../rounds/assets/stopwatch.svg';
import StopWatchClockIcon from '../../rounds/assets/stopwatchClock.svg';
import UserStopWatchIcon from '../../rounds/assets/userStopwatch.svg';
import UserClock from '../../rounds/assets/userClock.svg';
import UserClipboardCheck from '../../rounds/assets/userClipboadCheck.svg';
import UserClipboardCross from '../../rounds/assets/userClipboadCross.svg';
import ClipboardCross from '../../rounds/assets/clipboardCross.svg';
import SparkleIcon from '../../changelogs/assets/sparkle.svg';
import CautionIcon from '../assets/warning.svg';
import CautionIcon2 from '../../groups/assets/warning2.svg';
import CircleCheckIcon from '../../groups/assets/circleCheck.svg';
import SuitIcon from '../../groups/assets/suit.svg';
import HandUpIcon from '../assets/hand-up.svg';
import BackIcon from '../assets/back.svg';
import VideoIcon from '../assets/video.svg';
import VideoFilledIcon from '../assets/videoFilled.svg';
import PictureIcon from '../assets/picture.svg';
import PictureFilledIcon from '../assets/pictureFilled.svg';
import PdfIcon from '../assets/pdf.svg';
import PdfFilledIcon from '../assets/pdfFilled.svg';
import GifIcon from '../assets/gif.svg';
import ExcelFile from '../assets/excelFile.svg';
import ExcelFileWhite from '../assets/excelFileWhite.svg';
import WordFile from '../assets/wordFile.svg';
import WordFileWhite from '../assets/wordFileWhite.svg';
import SingleFileIcon from '../assets/file.svg';
import GifFilledIcon from '../assets/gifFilled.svg';
import UnknownTypeIcon from '../assets/unknownType.svg';
import PadlockIcon from '../assets/padlock.svg';
// Graph Icons
import CurveGraph from '../../dashboards/assets/images/svg/Curve.svg';
import GaugeGraph from '../../dashboards/assets/images/svg/Gauge.svg';
import GraphNoData from '../assets/graphIcons/noData.svg';
import DeviceNoData from '../assets/default-nodata.svg';
import CalendarOutlined from '../../rounds/assets/calendarOutlined.svg';
import CalendarFilled from '../../rounds/assets/calendarFilled.svg';
import LinesFilled from '../../rounds/assets/linesFilled.svg';
import LinesOutlined from '../../rounds/assets/linesOutlined.svg';
import ExportFilledIcon from '../../devices/assets/icons/exportFilled.svg';
import DeviceIcon from '../../devices/assets/icons/device.svg';
import ButtonIcon from '../../devices/assets/icons/button.svg';
import TemperatureIcon from '../../devices/assets/icons/thermometer.svg';
import HumidityIcon from '../../devices/assets/icons/humidity.svg';
import CO2Icon from '../../devices/assets/icons/CO2.svg';
import SenseDryIcon from '../../devices/assets/icons/sensedry.svg';
import SimpleLeakIcon from '../../devices/assets/icons/simpleleak.svg';
import SatisfactionIcon from '../../devices/assets/icons/satisfaction.svg';
import ResetIcon from '../../devices/assets/icons/reset.svg';
import CounterIcon from '../../devices/assets/icons/counter.svg';
import LuminosityIcon from '../../devices/assets/icons/luminosity.svg';
import BuildingIcon from '../../devices/assets/icons/building.svg';
import NotImplementedIcon from '../../devices/assets/icons/not_implemented.svg';
import QRCodeIcon from '../../devices/assets/icons/qrcode.svg';
import QRCodeScanIcon from '../../rounds/assets/qrcodeScan.svg';
import QRCodeScannerOutlineIcon from '../../rounds/assets/scannerOutline.svg';
import PassageValidation from '../../qrcodes/assets/passage_validation.svg';
import PassageVisualization from '../../qrcodes/assets/passage_visualization.svg';
import OfflineIcon from '../../qrcodes/assets/offline.svg';
import IssueReport from '../../qrcodes/assets/issue_report.svg';
import Document from '../../qrcodes/assets/document.svg';
import TaqtIcon from '../../devices/assets/icons/taqt.svg';
import ButtonPassageIcon from '../../devices/assets/icons/passage_button.svg';
import PollIcon from '../../devices/assets/icons/poll.svg';
import RatingIcon from '../../devices/assets/icons/rating.svg';
import DeviceGroupIcon from '../../devices/assets/icons/devicegroup.svg';
import SmartDeskIcon from '../../devices/assets/icons/smartdesk.svg';
import TorkTowelIcon from '../../devices/assets/icons/tork-towel.svg';
import TorkBinIcon from '../../devices/assets/icons/tork-bin.svg';
import TorkDispenserIcon from '../../devices/assets/icons/tork-dispenser.svg';
import Tes5MroIcon from '../../devices/assets/icons/smartdesk.svg';
import HotDeskingIcon from '../../devices/assets/icons/hotdesking.svg';
import PresenceLedIcon from '../../devices/assets/icons/presence-led.svg';
import LedIcon from '../../devices/assets/icons/led.svg';
import NoDataIcon from '../assets/icons/ErrorLaptop.svg';
import TipsIcon from '../icons/assets/tips.svg';
import Illustration from '../icons/assets/illustration-accueil.svg';
import CsvIcon from '../icons/assets/csv.svg';
import CleaningIcon from '../../groups/assets/cleaning.svg';
import BroomBinIcon from '../../groups/assets/broom-bin.svg';
import CleaningTools from '../../groups/assets/cleaningTools.svg';
import TechniqueIcon from '../../groups/assets/technique.svg';
import ElectricityIcon from '../../groups/assets/electricity.svg';
import PlumbingIcon from '../../groups/assets/plumbing.svg';
import SecurityIcon from '../../groups/assets/security.svg';
import CVCIcon from '../../groups/assets/cvc.svg';
import HDMIIcon from '../../groups/assets/hdmi.svg';
import ITIcon from '../../groups/assets/it.svg';
import ComputerSafetyIcon from '../../groups/assets/computerSafety.svg';
import FurnitureIcon from '../../groups/assets/furniture.svg';
import OfficeSuppliesIcon from '../../groups/assets/office-supplies.svg';
import WorkIcon from '../../groups/assets/works.svg';
import WorkClockIcon from '../../groups/assets/working-hours.svg';
import SuppliesIcon from '../../groups/assets/supplies.svg';
import WindowsIcon from '../../groups/assets/windows.svg';
import CopierIcon from '../../groups/assets/copier.svg';
import ToiletIcon from '../../groups/assets/toilet.svg';
import AuditoriumIcon from '../../groups/assets/auditorium.svg';
import BreakIcon from '../../groups/assets/break.svg';
import MedicalIcon from '../../groups/assets/medical.svg';
import OffboardingIcon from '../../groups/assets/offboarding.svg';
import OnboardingIcon from '../../groups/assets/onboarding.svg';
import ShuttleIcon from '../../groups/assets/shuttle.svg';
import VisitIcon from '../../groups/assets/visit.svg';
import ArchivesIcon from '../../groups/assets/archives.svg';
import FilesIcon from '../../groups/assets/files.svg';
import LockersIcon from '../../groups/assets/locker.svg';
import SportIcon from '../../groups/assets/dumbbell.svg';
import DistributorIcon from '../../groups/assets/distributor.svg';
import DishIcon from '../../groups/assets/dish.svg';
import PalettierIcon from '../../groups/assets/palettier.svg';
import ConveyorIcon from '../../groups/assets/conveyor.svg';
import QuaisIcon from '../../groups/assets/quais.svg';
import ElevatorIcon from '../../groups/assets/elevator.svg';
import StairsIcon from '../../groups/assets/stairs.svg';
import GoodsLiftIcon from '../../groups/assets/scissor-lift.svg';
import GateIcon from '../../groups/assets/gate.svg';
import TentIcon from '../../groups/assets/chapiteau.svg';
import ExtractionIcon from '../../groups/assets/extraction.svg';
import SecurityCamIcon from '../../groups/assets/surveillance.svg';
import TrackIcon from '../../groups/assets/empreinte.svg';
import RentIcon from '../../groups/assets/deal.svg';
import EcoIcon from '../../groups/assets/durabilite.svg';
import ToolsIcon from '../../groups/assets/tool-box.svg';
import IntercomIcon from '../../groups/assets/intercom.svg';
import ScooterIcon from '../../groups/assets/scooter.svg';
import DoorIcon from '../../groups/assets/door_opened.svg';
import CarolersIcon from '../../groups/assets/carolers.svg';
import CarIcon from '../../groups/assets/car.svg';
import TaxiIcon from '../../groups/assets/taxi.svg';
import DiscussionIcon from '../../groups/assets/discussion.svg';
import MarketingIcon from '../../groups/assets/marketing.svg';
import TechnicalIcon from '../../groups/assets/technical.svg';
import ReservationsIcon from '../../groups/assets/reservations.svg';
import ProblemIcon from '../../groups/assets/problem.svg';
import RearrangementIcon from '../../groups/assets/rearrangement.svg';
import PrinterIcon from '../../groups/assets/printer.svg';
import BinIcon from '../../groups/assets/bin.svg';
import MeetingroomIcon from '../../groups/assets/meeting_room.svg';
import Meetingroom2Icon from '../../groups/assets/meeting-room2.svg';
import LivingRoomIcon from '../../groups/assets/living-room.svg';
import TableIcon from '../../groups/assets/table.svg';
import DistribIcon from '../../groups/assets/distrib.svg';
import DisplayIcon from '../../groups/assets/display.svg';
import BoxIcon from '../../groups/assets/box.svg';
import CoffeeIcon from '../../groups/assets/coffee.svg';
import BadgeIcon from '../../groups/assets/badge.svg';
import TestIcon from '../../groups/assets/test.svg';
import LightIcon from '../../groups/assets/light.svg';
import OutletIcon from '../../groups/assets/outlet.svg';
import ParkingIcon from '../../groups/assets/parking.svg';
import ConsoIcon from '../../groups/assets/conso.svg';
import CloseIcon from '../../groups/assets/close.svg';
import GreenSpaceIcon from '../../groups/assets/greenspace.svg';
import TerraceIcon from '../../groups/assets/terrace.svg';
import GardenFenceIcon from '../../groups/assets/yard.svg';
import GardeningToolsIcon from '../../groups/assets/gardening-tools.svg';
import BikeIcon from '../../groups/assets/bicycle.svg';
import TrashIcon from '../../groups/assets/garbage.svg';
import EscalatorIcon from '../../groups/assets/escalator.svg';
import RondeIcon from '../../groups/assets/ronde.svg';
import AchatsIcon from '../../groups/assets/achats.svg';
import CardIcon from '../../groups/assets/business.svg';
import AccountLock from '../../groups/assets/accountLock.svg';
import FlowerIcon from '../../groups/assets/flower.svg';
import CourrierIcon from '../../groups/assets/envelope.svg';
import PhoneIcon from '../../groups/assets/phone.svg';
import MobileAppIcon from '../../groups/assets/mobile-app.svg';
import ConsoTaqtIcon from '../../groups/assets/conso_taqt.svg';
import ConsumerIcon from '../../groups/assets/consumer.svg';
import RoundsIcon from '../../groups/assets/clipboard.svg';
import AccompanyIcon from '../../groups/assets/accompagnement.svg';
import ProtectionIcon from '../../groups/assets/assurance-sante.svg';
import VideoIconGroup from '../../groups/assets/videoIcon.svg';
import CameraIcon from '../../groups/assets/camera.svg';
import PackageIcon from '../../groups/assets/colis.svg';
import FieldIcon from '../../groups/assets/dossier.svg';
import ApplianceIcon from '../../groups/assets/electromenager.svg';
import MeetingIcon from '../../groups/assets/entretien.svg';
import FacilityIcon from '../../groups/assets/facility.svg';
import ProjectionIcon from '../../groups/assets/projecteur.svg';
import RestaurantIcon from '../../groups/assets/restaurant.svg';
import Restaurant2Icon from '../../groups/assets/restaurant2.svg';
import MoneyIcon from '../../groups/assets/salaire.svg';
import ReceiptIcon from '../../groups/assets/facture.svg';
import RiskIcon from '../../groups/assets/signal-accident.svg';
import StoreIcon from '../../groups/assets/stores.svg';
import TeaIcon from '../../groups/assets/tisane.svg';
import TpeIcon from '../../groups/assets/tpe.svg';
import HolidayIcon from '../../groups/assets/vacances.svg';
import VirusIcon from '../../groups/assets/virus.svg';
import WifiIcon from '../../groups/assets/wifi.svg';
import WifiIcon2 from '../../groups/assets/wifi2.svg';
import AdminsysIcon from '../../groups/assets/adminsys.svg';
import OthersIcon from '../../groups/assets/autre-divers.svg';
import BoxIdea from '../../groups/assets/box-idea.svg';
import ContractIcon from '../../groups/assets/contrat.svg';
import MaintenanceIcon from '../../groups/assets/maintenance.svg';
import WallIcon from '../../groups/assets/mur.svg';
import TabletIcon from '../../groups/assets/tablette-informatique.svg';
import AllIcon from '../../subscriptions/assets/ticket.svg';
import CommentIcon from '../../subscriptions/assets/comment.svg';
import IncompleteIcon from '../../subscriptions/assets/incomplete.svg';
import AssignatedIcon from '../../subscriptions/assets/assignated.svg';
import SuccessDove from '../../passages/assets/SuccessDove.svg';
import ErrorLaptop from '../../passages/assets/ErrorLaptop.svg';
import RoundsIconFilled from '../../rounds/assets/clipboard_filled.svg';
import RoundClouds from '../../rounds/assets/RoundClouds.svg';
import RoundsIconFilled2 from '../../rounds/assets/clipboard_filled_2.svg';
import WrenchIcon from '../../settings/assets/wrench.svg';
import WrenchIconFilled from '../../settings/assets/wrench_filled.svg';
import NormalPriority from '../../tickets/assets/normal_priority.svg';
import HighPriority from '../../tickets/assets/high_priority.svg';
import UrgentPriority from '../../tickets/assets/urgent_priority.svg';
import ShareIcon from '../../tickets/assets/share.svg';
import MerciYanisIcon from '../../tickets/assets/merciyanis-logo.svg';
import SlackIcon from '../../tickets/assets/slack.svg';
import TeamsIcon from '../../tickets/assets/teams.svg';
import TeamsSecondIcon from '../../tickets/assets/teams-without-circle.svg';
import AzureADIcon from '../assets/azure_ad.svg';
import AskIcon from '../../groups/assets/demande.svg';
import ClaimIcon from '../../groups/assets/claim.svg';
import QualityIcon from '../../groups/assets/quality.svg';
import PressureIcon from '../../groups/assets/pressure.svg';
import ClimIcon from '../../groups/assets/clim.svg';
import HeaterIcon from '../../groups/assets/heater.svg';
import FireIcon from '../../groups/assets/fire.svg';
import LiftIcon from '../../groups/assets/forklift.svg';
import StoveIcon from '../../groups/assets/stove.svg';
import TopIcon from '../../groups/assets/top.svg';
import FountainIcon from '../../groups/assets/fountain.svg';
import PlanIcon from '../../groups/assets/plan.svg';
import FrozenWaterIcon from '../../groups/assets/frozenwater.svg';
import WaterIcon from '../../groups/assets/water.svg';
import ConstructionIcon from '../../groups/assets/brickwall.svg';
import GazIcon from '../../groups/assets/gaz.svg';
import FloorIcon from '../../groups/assets/floor.svg';
import RoofIcon from '../../groups/assets/roof.svg';
import RoadIcon from '../../groups/assets/route.svg';
import VentilationIcon from '../../groups/assets/ventilation.svg';
import OperationIcon from '../assets/share.svg';
import NfcTag from '../../nfcs/assets/nfc_tag.svg';
import TeaCup from '../assets/tea-cup.svg';
import BellTicketIcon from '../assets/bellTicket.svg';
import BellTicketFilledIcon from '../assets/bellTicketFilled.svg';

let iconByType = {
    marker: <FontAwesomeIcon icon={faMapMarker} />,
    user: <FontAwesomeIcon icon={faUser} />,
    userMultiple: <FontAwesomeIcon icon={faUsers} />,
    userMultipleFilled: <FontAwesomeIcon icon={faUsersFilled} />,
    users: <FontAwesomeIcon icon={faUser} />,
    userFilled: <FontAwesomeIcon icon={faUserFilled} />,
    deviceFilled: <DeviceFilledIcon />,
    deviceGroupFilled: <DeviceGroupFilledIcon />,
    graphNoDataIcon: <GraphNoData />,
    deviceNoData: <DeviceNoData />,
    device: <DeviceIcon />,
    csv: <FontAwesomeIcon icon={faFileExcel} />,
    userGroupFilled: <FontAwesomeIcon icon={faUserFriendsFilled} />,
    group: <GroupIcon />,
    groups: <GroupIcon />,
    groupFilled: <GroupFilledIcon />,
    AGRESSION: <CautionIcon2 />,
    caution: <CautionIcon />,
    'Anomalies-Aléas': <CautionIcon2 />,
    'Contrôles': <CircleCheckIcon />,
    handUp: <HandUpIcon />,
    'Réclamation adhérents': <HandUpIcon />,
    goBack: <BackIcon />,
    dashboard: <FontAwesomeIcon icon={faChartSimple} />,
    dashboards: <FontAwesomeIcon icon={faChartSimple} />,
    dashboardFilled: <FontAwesomeIcon icon={faChartSimpleFilled} />,
    alert: <FontAwesomeIcon icon={faBell} />,
    bellOn: <FontAwesomeIcon icon={faBellOn} />,
    bellSlash: <FontAwesomeIcon icon={faBellSlash} />,
    filterBy: <FontAwesomeIcon icon={faFilter} />,
    search: <FontAwesomeIcon icon={faSearch} />,
    '19.Objet perdu/trouvé': <FontAwesomeIcon icon={faSearch} />,
    alertFilled: <FontAwesomeIcon icon={faBellFilled} />,
    option: <FontAwesomeIcon icon={faEllipsisV} />,
    eye: <FontAwesomeIcon icon={faEye} />,
    eyeSlashed: <FontAwesomeIcon icon={faEyeSlash} />,
    close: <FontAwesomeIcon icon={faTimes} />,
    image: <FontAwesomeIcon icon={faImage} />,
    email: <FontAwesomeIcon icon={faEnvelope} />,
    password: <FontAwesomeIcon icon={faLockAlt} />,
    leftArrow: <FontAwesomeIcon icon={faChevronLeft} />,
    rightArrow: <FontAwesomeIcon icon={faChevronRight} />,
    frown: <FontAwesomeIcon icon={faFaceFrownSlight} />,
    infoCircle: <FontAwesomeIcon icon={faInfoCircle} />,
    check: <FontAwesomeIcon icon={faCheck} />,
    plus: <FontAwesomeIcon icon={faPlus} />,
    trash: <FontAwesomeIcon icon={faTrashAlt} />,
    cog: <FontAwesomeIcon icon={faCog} />,
    faPen: <FontAwesomeIcon icon={faPen} />,
    faSlidersH: <FontAwesomeIcon icon={faSlidersH} />,
    addUser: <FontAwesomeIcon icon={faUserPlus} />,
    removeUser: <FontAwesomeIcon icon={faUserMinus} />,
    save: <FontAwesomeIcon icon={faSave} />,
    exportTable: <FontAwesomeIcon icon={faList} />,
    edit: <FontAwesomeIcon icon={faEdit} />,
    info: <FontAwesomeIcon icon={faQuestionCircle} />,
    arrowLeft: <FontAwesomeIcon icon={faArrowLeft} />,
    faCloudArrowDown: <FontAwesomeIcon icon={faCloudArrowDown} />,
    faArrowsRotate: <FontAwesomeIcon icon={faArrowsRotate} />,
    chevronBottom: <FontAwesomeIcon icon={faChevronDown} />,
    chevronUp: <FontAwesomeIcon icon={faChevronUp} />,
    logout: <FontAwesomeIcon icon={faSignOutAlt} />,
    login: <FontAwesomeIcon icon={faSignInAlt} />,
    editProfile: <FontAwesomeIcon icon={faUserEdit} />,
    faWifiSlash: <FontAwesomeIcon icon={faWifiSlash} />,
    accessWorkspaces: <FontAwesomeIcon icon={faGlobeEurope} />,
    exclamationTriangle: <FontAwesomeIcon icon={faExclamationTriangle} />,
    exclamationTriangleLight: <FontAwesomeIcon icon={faExclamationTriangleLight} />,
    faCalendarAlt: <FontAwesomeIcon icon={faCalendarAlt} />,
    calendarOutlined: <CalendarOutlined />,
    calendarFilled: <CalendarFilled />,
    linesFilled: <LinesFilled />,
    linesOutlined: <LinesOutlined />,
    shareArrowRight: <FontAwesomeIcon icon={faShare} />,
    tags: <FontAwesomeIcon icon={faTag} />,
    tips: <TipsIcon />,
    teaCup: <TeaCup />,
    homeIllustration: <Illustration />,
    csvFile: <CsvIcon />,
    event: <EventIcon />,
    ErrorRounded: <Error style={{ color: '#FF4C4C' }} />,
    CheckCircleRounded: <CheckCircle style={{ color: '#57C9AE' }} />,
    TimerOff: <TimerOffIcon />,
    PercentIcon: <PercentIcon />,
    CheckCircleRoundedOutlined: <CheckCircleOutlineIcon />,
    ErrorRoundedOutlined: <ErrorOutlineIcon />,
    // users
    phone: <FontAwesomeIcon className="admin-icon" icon={faPhoneAlt} />,
    outcomingPhone: <OutcomingPhone />,
    sms: <FontAwesomeIcon className="admin-icon" icon={faSms} />,
    emailSub: <FontAwesomeIcon className="admin-icon" icon={faCheckCircle} />,
    userGroup: <BuildingIcon />,
    crown: <BuildingIcon />,
    admin: <FontAwesomeIcon className="admin-icon" icon={faCrown} />,

    // svg
    google: <GoogleIcon />,
    video: <VideoIcon />,
    videoFilled: <VideoFilledIcon />,
    picture: <PictureIcon />,
    pictureFilled: <PictureFilledIcon />,
    pdf: <PdfIcon />,
    pdfFilled: <PdfFilledIcon />,
    gif: <GifIcon />,
    singleFile: <SingleFileIcon />,
    excelFile: <ExcelFile />,
    excelFileWhite: <ExcelFileWhite />,
    wordFile: <WordFile />,
    wordFileWhite: <WordFileWhite />,
    gifFilled: <GifFilledIcon />,
    unknownType: <UnknownTypeIcon />,
    padlock: <PadlockIcon />,
    key: <KeyIcon />,
    externalLink: <ExternalLink />,
    'INF - Comptes & Accés': <AccountLock />,
    envelop: <EnvelopIcon />,
    workspace: <WorkspaceIcon />,
    monkeyWrench: <MonkeyWrenchIcon />,
    archiveClock: <ArchiveClockIcon />,
    mailTicket: <MailTicketIcon />,
    clockEnveloppe: <ClockEnveloppeIcon />,
    file: <FileIcon />,
    contractorConfig: <ContractorIcon />,
    userConfig: <UserIcon />,
    addGraph: <AddGraphIcon />,
    upload: <UploadIcon />,
    pen: <PenIcon />,
    share: <ShareIcon />,
    LineGraph: <CurveGraph />,
    GaugeGraph: <GaugeGraph />,
    ArrowRight: <ArrowRightIcon />,
    ArrowBackIcon: <ArrowBackIosRoundedIcon />,
    ArrowForwardIcon: <ArrowForwardIosRounded />,
    ArrowUpwardIcon: <faArrowUp />,
    StopWatchIcon: <StopWatchIcon />,
    StopWatchClockIcon: <StopWatchClockIcon />,
    UserStopWatchIcon: <UserStopWatchIcon />,
    UserClock: <UserClock />,
    UserClipboardCheck: <UserClipboardCheck />,
    UserClipboardCross: <UserClipboardCross />,
    ClipboardCross: <ClipboardCross />,
    'Alerte non anonymisée en externe': <ClipboardCross />,
    sparkle: <SparkleIcon />,
    linkedin: <linkedin />,
    helpInterrogationMark: <FontAwesomeIcon icon={faQuestionMark} />,
    helpInterrogationMarkFilled: <FontAwesomeIcon icon={faCircleQuestionFilled} />,
    bookOpen: <FontAwesomeIcon icon={faBookOpen} />,
    shortcutOpener: <OpenInNewIcon style={{ fontSize: 14 }} />,

    bellTicket: <BellTicketIcon />,
    bellTicketFilled: <BellTicketFilledIcon />,

    // Nfc tags
    nfcTag: <NfcTag />,


    // Devices
    devices: <DeviceIcon />,
    deviceGroup: <BuildingIcon />,
    AIRWITS: <HumidityIcon />,
    AIRWITS_CO2: <CO2Icon />,
    TEMPERATURE_CONNIT: <TemperatureIcon />,
    BUTTON: <ButtonIcon />,
    BUTTON_RESET: <ResetIcon />,
    SENSE_Dry_Contact_4_v1: <SenseDryIcon />,
    INFRARED: <CounterIcon />,
    XORTEC_LUMI: <LuminosityIcon />,
    LUMINOSITY: <LuminosityIcon />,
    SIMPLE_LEAK: <SimpleLeakIcon />,
    TAQT: <TaqtIcon />,
    LWP: <PersonFalling />,
    QRCODE: <QRCodeIcon />,
    QRCodeScan: <QRCodeScanIcon />,
    QRCodeScannerOutline: <QRCodeScannerOutlineIcon />,
    PASSAGE_VALIDATION: <PassageValidation />,
    PASSAGE_VISUALIZATION: <PassageVisualization />,
    ISSUE_REPORT: <IssueReport />,
    DOCUMENT: <Document />,
    PASSAGE_BUTTON: <ButtonPassageIcon />,
    OFFLINE_MODE: <OfflineIcon />,
    no_data: <NoDataIcon />,
    not_implemented: <NotImplementedIcon />,
    temperature: <TemperatureIcon />,
    luminosity: <LuminosityIcon />,
    co2: <CO2Icon />,
    humidity: <HumidityIcon />,
    export: <FontAwesomeIcon icon={faFileExport} />,
    dataExport: <FontAwesomeIcon icon={faChartLine} />,
    exportFilled: <ExportFilledIcon />,
    BUTTON_SATISFACTION: <SatisfactionIcon />,
    BUTTON_POLL: <PollIcon />,
    BUTTON_RATING: <RatingIcon />,
    DEVICE_GROUP: <DeviceGroupIcon />,
    SMARTDESK: <SmartDeskIcon />,
    TORK_TOWEL: <TorkTowelIcon />,
    TORK_BIN: <TorkBinIcon />,
    TORK_DISPENSER: <TorkDispenserIcon />,
    'TES-5MRO': <Tes5MroIcon />,
    LED: <LedIcon />,
    HOT_DESKING: <HotDeskingIcon />,
    PRESENCE_LED_DEVICE_GROUP: <PresenceLedIcon />,
    // navbar
    BATIMENT: <FontAwesomeIcon icon={faBuilding} />,
    FLOOR: <FontAwesomeIcon icon={faMapMarkerAlt} />,
    POSITION: <FontAwesomeIcon icon={faSearchLocation} />,
    assigners: <FontAwesomeIcon icon={faUserTag} />,
    contractor_role: <FontAwesomeIcon icon={faWrench} />,
    user_role: <FontAwesomeIcon icon={faUser} />,
    admin_role: <FontAwesomeIcon icon={faCrown} />,
    invite: <FontAwesomeIcon icon={faPaperPlane} />,
    messagingUsers: <FontAwesomeIcon icon={faCommentDots} />,
    time: <FontAwesomeIcon icon={faHistory} />,
    to_clean: <FontAwesomeIcon icon={faHandSparkles} />,
    OTHER: <OthersIcon />,
    Other: <OthersIcon />,
    '20.Autres demandes': <OthersIcon />,
    'Autres demandes': <OthersIcon />,
    Autres: <OthersIcon />,
    Autre: <OthersIcon />,
    'Anomalie PTI': <ExclamationPoint />,
    'Général': <OthersIcon />,
    'Autre Demande': <OthersIcon />,
    'Autre demande pour les Services Généraux': <OthersIcon />,
    'Idée d\'amélioration': <BoxIdea />,
    'Contrôle d\'accès': <OthersIcon />,
    CLEANING: <CleaningIcon />,
    Cleanliness: <CleaningIcon />,
    'Nettoyage': <CleaningIcon />,
    Ménage: <CleaningIcon />,
    'Proprete': <CleaningIcon />,
    'Propreté (Non conformité, Absence de prestation...)': <CleaningIcon />,
    'Services': <AskIcon />,
    'Demandes': <AskIcon />,
    'Demande d\'intervention': <AskIcon />,
    '22. Demande de fourniture et diable': <AskIcon />,
    'Réclamations': <ClaimIcon />,
    'Ménage/Propreté': <CleaningIcon />,
    'Sanitaires/ Propreté': <CleaningIcon />,
    'Qualité': <QualityIcon />,
    Technique: <TechniqueIcon />,
    'Murs, Sols, Plafonds': <TechniqueIcon />,
    'Maitenance 2ème niveau': <TechniqueIcon />,
    'MG - Ménage': <CleaningIcon />,
    Propreté: <CleaningIcon />,
    'GEN - Electricité': <ElectricityIcon />,
    ELECTRICITY: <ElectricityIcon />,
    '12.Eclairage / Electricité': <ElectricityIcon />,
    'Électricité/Éclairage': <ElectricityIcon />,
    'Electrical Installation': <ElectricityIcon />,
    Électricité: <ElectricityIcon />,
    Electricité: <ElectricityIcon />,
    'Electricite': <ElectricityIcon />,
    'MG - Electricité': <ElectricityIcon />,
    PLUMBING: <PlumbingIcon />,
    Plumbing: <PlumbingIcon />,
    'GEN - Plomberie': <PlumbingIcon />,
    Eau: <PlumbingIcon />,
    'Plomberie': <PlumbingIcon />,
    'Plomberie / sanitaire': <PlumbingIcon />,
    'Plomberie & Sanitaire': <PlumbingIcon />,
    'MG - Plomberie': <PlumbingIcon />,
    SECURITY: <SecurityIcon />,
    Sécurité: <SecurityIcon />,
    'MG - Sécurité': <SecurityIcon />,
    'CONTROLE D\'ACCES': <SecurityIcon />,
    'Contrôle d\'accès (Pb équipement)': <SecurityIcon />,
    ACCES: <SecurityIcon />,
    'Accès / Alarmes': <SecurityIcon />,
    'Accès bâtiment': <SecurityIcon />,
    AdminSys: <AdminsysIcon />,
    CLIM: <ClimIcon />,
    CLIMATISATION: <ClimIcon />,
    '11.Clim / Ventilation / Chauffage': <ClimIcon />,
    'Climatisation': <ClimIcon />,
    CVC: <ClimIcon />,
    'CVC (aération)': <ClimIcon />,
    'Chauffage, Ventilation, Clim': <ClimIcon />,
    'Chauffage-Ventilation-Climatisation': <ClimIcon />,
    'Clim/Ventilation/Chauffage': <ClimIcon />,
    'Clime/Chauffage': <ClimIcon />,
    'Location Materiel': <RentIcon />,
    'Prêt De Materiel': <RentIcon />,
    'Clos et couvert': <ToolsIcon />,
    'Opérations travaux (OT)': <ToolsIcon />,
    Factotum: <ToolsIcon />,
    'Maitenance 1er niveau': <ToolsIcon />,
    'Remise en état': <ToolsIcon />,
    'Aspiration Extraction': <ExtractionIcon />,
    'Capteur clim': <ClimIcon />,
    'MG - Climatisation': <ClimIcon />,
    'Clim / Ventilation / Chauffage': <ClimIcon />,
    'Air Conditionning': <ClimIcon />,
    'CHAUFFAGE / CLIMATISATION': <CVCIcon />,
    'Chauffage / climatisation': <CVCIcon />,
    'GEN - CVC': <CVCIcon />,
    'Chaufferie/Climatisation': <CVCIcon />,
    'Air comprimé': <PressureIcon />,
    'Air comprime': <PressureIcon />,
    'Reseau D\'Eau': <WaterIcon />,
    'Dégât des eaux': <WaterIcon />,
    'Voirie': <RoadIcon />,
    'GEN - Travaux': <ConstructionIcon />,
    'Travaux Gros Oeuvre': <ConstructionIcon />,
    'Travaux Neufs Sur Batiment': <ConstructionIcon />,
    'Travaux Second Oeuvre': <ConstructionIcon />,
    'Reseau Gaz': <GazIcon />,
    'RGPD': <LockIcon />,
    'Clés': <KeyIcon />,
    'Comportement / Tenues': <SuitIcon />,
    'Materiel De Restauration': <StoveIcon />,
    'HEATING SYSTEM': <HeaterIcon />,
    'Détection Incendie': <FireIcon />,
    '18.Sécurité incendie': <FireIcon />,
    'Detection Protection Incendie': <FireIcon />,
    'Réseau d\'eau glacée': <FrozenWaterIcon />,
    CHAUFFAGE: <HeaterIcon />,
    'Chauffage': <HeaterIcon />,
    'Moyen D\'Acces En Hauteur': <TopIcon />,
    'Algeco Chapiteaux': <TentIcon />,
    'Contrôle D\'Acces & Surveillance Video': <SecurityCamIcon />,
    'Gestion Faune Sauvage': <TrackIcon />,
    'CLIMATISATION / CHAUFFAGE': <CVCIcon />,
    'Climatisation Ventilation Chauffage': <CVCIcon />,
    'Climatisation / Chauffage': <CVCIcon />,
    'Consommable / Plomberie': <FontAwesomeIcon icon={faTicket} />,
    'Ventilation': <VentilationIcon />,
    CONNECTOR: <HDMIIcon />,
    'Réseau': <HDMIIcon />,
    'Accès internet et wifi du réseau commercial': <FontAwesomeIcon icon={faWifi} />,
    Connectique: <HDMIIcon />,
    CONNECTIQUE: <HDMIIcon />,
    'INF - Connexion': <WifiIcon2 />,
    WIFI: <WifiIcon />,
    WiFi: <WifiIcon />,
    chatbot: <ChatbotIcon />,
    'Réseaux Internet': <WifiIcon />,
    'Murs / Plafond': <WallIcon />,
    'Cloisons': <WallIcon />,
    'Toiture étanchéité': <RoofIcon />,
    'Toiture Etancheite': <RoofIcon />,
    'INF - Sécurité': <ComputerSafetyIcon />,
    IT: <ITIcon />,
    'Numérisation': <ITIcon />,
    COMPUTING: <ITIcon />,
    'INF- Matériel Informatique': <ITIcon />,
    Informatique: <ITIcon />,
    Ordinateur: <ITIcon />,
    'Informatiques SDR': <ITIcon />,
    '07.IT': <ITIcon />,
    'GEN - Mobilier': <FurnitureIcon />,
    FURNITURE: <FurnitureIcon />,
    'Sol': <FloorIcon />,
    Stores: <StoreIcon />,
    Tisanerie: <TeaIcon />,
    '03.Tisanerie / Coin café': <TeaIcon />,
    'MG - Mobilier': <FurnitureIcon />,
    Mobilier: <FurnitureIcon />,
    WORK: <WorkIcon />,
    'Arrivées et départs': <WorkClockIcon />,
    'Petits travaux d\'entretien': <WorkIcon />,
    'MG - Travaux': <WorkIcon />,
    Travaux: <WorkIcon />,
    'Petits travaux': <WorkIcon />,
    SUPPLIES: <SuppliesIcon />,
    Fournitures: <SuppliesIcon />,
    Furnitures: <SuppliesIcon />,
    'Fournitures de bureau': <SuppliesIcon />,
    PAPETERIE: <SuppliesIcon />,
    Papeterie: <SuppliesIcon />,
    FOURNITURES: <SuppliesIcon />,
    'Fournitures SDR': <SuppliesIcon />,
    'SUPPLIES / CONSUMABLE': <SuppliesIcon />,
    'Papier, enveloppes, factures': <SuppliesIcon />,
    'Petites fournitures': <SuppliesIcon />,
    'Petite fournitures': <SuppliesIcon />,
    'Petite founriture': <SuppliesIcon />,
    'Reprographie': <SuppliesIcon />,
    'MG - Fournitures': <SuppliesIcon />,
    'FENETRES / PORTES': <WindowsIcon />,
    'Portes et fenetres': <WindowsIcon />,
    'Fenêtres / portes': <WindowsIcon />,
    '13.Portes et fenêtres': <WindowsIcon />,
    JOINERY: <WindowsIcon />,
    MENUISERIE: <WindowsIcon />,
    WINDOW: <WindowsIcon />,
    Vitrerie: <WindowsIcon />,
    'Engins de levage': <LiftIcon />,
    'Manutention Levage': <LiftIcon />,
    'GEN - Fontaine à eau': <FountainIcon />,
    'Fontaine d\'eau': <FountainIcon />,
    'Distributeur Cafe & Fontaine A Eau': <FountainIcon />,
    'Distributeur à boisson, Fontaine': <FountainIcon />,
    'Fontaine à eau': <FountainIcon />,
    PHOTOCOPIEURS: <CopierIcon />,
    Photocopieurs: <CopierIcon />,
    'Copieur, Matériel multimédia': <CopierIcon />,
    'Copieurs/Scanner/Imprimantes': <CopierIcon />,
    'INF - Copieurs - Imprimantes': <CopierIcon />,
    'Copieurs / Scanner / Imprimantes': <CopierIcon />,
    '06.Copieurs / Imprimantes': <CopierIcon />,
    SANITAIRES: <ToiletIcon />,
    Sanitaires: <ToiletIcon />,
    '09.Sanitaires': <ToiletIcon />,
    Sanitaire: <ToiletIcon />,
    'Cleaning Bureaux': <FurnitureIcon />,
    'Cleaning toilettes': <ToiletIcon />,
    'Cleaning pantry / Espaces communs': <CleaningIcon />,
    'Nettoyage, Gestion déchets': <BroomBinIcon />,
    'Prestation non quotidiennes': <CleaningTools />,
    'GEN - Propreté': <CleaningTools />,
    'Produit Propreté': <CleaningTools />,
    'Contrôle Propreté': <CleaningTools />,
    'Réapprovisionnement': <CleaningTools />,
    'Eco-label' : <EcoIcon />,
    'Couverts / Tasses / Verres': <TeaIcon />,
    'Open Space': <TableIcon />,
    'Salles de réunion': <MeetingroomIcon />,
    'Réservation salle visiteur': <MeetingroomIcon />,
    'Salles de réunion/Evénements/Réceptions': <Meetingroom2Icon />,
    'Réservation salle de réunion': <Meetingroom2Icon />,
    'Meeting Room Booking': <Meetingroom2Icon />,
    'Propreté Usage': <CounterIcon />,
    'Papier en tête / Demande d\'édition': <FilesIcon />,
    'Consigne': <FilesIcon />,
    'Courriers / Plis': <FontAwesomeIcon icon={faEnvelope} />,
    'Lockers': <LockersIcon />,
    'Casiers': <LockersIcon />,
    'Vestiaire': <LockersIcon />,
    'Vestiaires': <LockersIcon />,
    'Casier Flex': <LockersIcon />,
    '21.Casiers connectés VECOS': <LockersIcon />,
    ARCHIVES: <ArchivesIcon />,
    Archives: <ArchivesIcon />,
    '17.Archivage': <ArchivesIcon />,
    SERRURERIE: <KeyIcon />,
    Serrurerie: <KeyIcon />,
    'Plans': <PlanIcon />,
    'Plans Permis Autorisation': <PlanIcon />,
    METALWORK: <KeyIcon />,
    Projection: <ProjectionIcon />,
    'DISTRIBUTEURS / FONTAINE': <DistributorIcon />,
    'DISTRIBUTEURS / FONTAINES': <DistributorIcon />,
    'Distributeurs/Fontaines': <DistributorIcon />,
    'Distributeurs / fontaine': <DistributorIcon />,
    'Distributeurs de boissons/friandises': <DistributorIcon />,
    'Distributeurs - Fontaines à eau - Machines à café': <DistributorIcon />,
    'MG - Distributeurs café et eau': <DistributorIcon />,
    'MG - Distributeur café et eau': <DistributorIcon />,
    'Distributeurs/Fontaines à eau/Machines à café': <DistributorIcon />,
    'Food & Beverage': <TeaIcon />,
    '24. Plateforme video': <VideoIconGroup />,
    'Audiovisuel': <VideoIconGroup />,
    Caméra: <CameraIcon />,
    'Café': <CoffeeIcon />,
    'Cafétéria / Tea point': <CoffeeIcon />,
    'Guest Coffee Break': <CoffeeIcon />,
    'Coffee Break & Banquet': <DishIcon />,
    CAFETERIA: <DishIcon />,
    Catering: <DishIcon />,
    CAFÉTÉRIA: <DishIcon />,
    Cafétéria: <DishIcon />,
    '04.Cafétéria / Recéption / Petits-déjeuners': <DishIcon />,
    PALETTIER: <PalettierIcon />,
    Palettier: <PalettierIcon />,
    'Service Logistique': <PalettierIcon />,
    'Service Logistique PARIS': <PalettierIcon />,
    'Service Logistique AUBERVILLIERS': <PalettierIcon />,
    CONVOYEUR: <ConveyorIcon />,
    Convoyeur: <ConveyorIcon />,
    QUAIS: <QuaisIcon />,
    Quais: <QuaisIcon />,
    'Livraisons et stockage': <QuaisIcon />,
    'Stock': <QuaisIcon />,
    'Stocks': <QuaisIcon />,
    ELEVATOR: <ElevatorIcon />,
    Ascenseur: <ElevatorIcon />,
    ASCENSEUR: <ElevatorIcon />,
    '02.Ascenseurs': <ElevatorIcon />,
    'Palier Ascenseur': <ElevatorIcon />,
    'Ascenceurs portes auto': <ElevatorIcon />,
    'Ascenseur, Monte-charge': <GoodsLiftIcon />,
    'Ascenseurs et escaliers': <StairsIcon />,
    GATE: <GateIcon />,
    'Site Access': <GateIcon />,
    PORTAIL: <GateIcon />,
    'Portails Automatiques': <GateIcon />,
    'Portes / portails / barrières automatiques': <GateIcon />,
    '21.Porte automatique': <GateIcon />,
    'MG - Accès (portail, code)': <GateIcon />,
    'Porte automatique': <GateIcon />,
    'Accès': <KeyIcon />,
    '01.Badge et accès au bâtiment': <KeyIcon />,
    'Objets trouvés': <KeyIcon />,
    INTERCOM: <IntercomIcon />,
    INTERPHONE: <IntercomIcon />,
    Application: <MobileAppIcon />,
    'NETAT\'MOBILITE': <ScooterIcon />,
    'NETAT\'MOBILITÉ': <ScooterIcon />,
    'Netat’Mobilité': <ScooterIcon />,
    'ACCES LOCAUX': <DoorIcon />,
    'ACCÈS LOCAUX': <DoorIcon />,
    'Accès locaux': <DoorIcon />,
    'Gestion locaux et badges': <DoorIcon />,
    'Accueil': <DoorIcon />,
    'Porte Fenetres & Serrurerie': <DoorIcon />,
    'GEN - Portes et fenêtres': <DoorIcon />,
    'Demande d\'accès exceptionnel': <DoorIcon />,
    'Portes et fenêtres': <DoorIcon />,
    'SPORT - CHORALE': <CarolersIcon />,
    'Sport - Chorale': <CarolersIcon />,
    'Vestiaires et Fitness': <SportIcon />,
    '22.Salle de Sport': <SportIcon />,
    Fitness: <SportIcon />,
    CAR: <CarIcon />,
    Taxis: <TaxiIcon />,
    Véhicules: <CarIcon />,
    'Vehicules': <CarIcon />,
    'MG - Véhicules': <CarIcon />,
    'GEN - Véhicule de Service': <CarIcon />,
    DISCUSSION: <DiscussionIcon />,
    'Editique': <DiscussionIcon />,
    'Restauration en salle de réunion' :<DishIcon />,
    MARKETING: <MarketingIcon />,
    TECHNICAL: <TechnicalIcon />,
    'TECHNICAL SUPPLIES': <TechnicalIcon />,
    'Fournitures techniques': <TechnicalIcon />,
    Satisfaction: <SatisfactionIcon />,
    SATISFACTION: <SatisfactionIcon />,
    'Satisfaction cafétéria': <SatisfactionIcon />,
    'WORKING ENVIRONMENT': <SatisfactionIcon />,
    'Environnement de travail': <SatisfactionIcon />,
    RESERVATIONS: <ReservationsIcon />,
    'RÉSERVATIONS & AMÉNAGEMENT RIE OU LOOD': <ReservationsIcon />,
    'Réservations et aménagements Rie ou Lood': <ReservationsIcon />,
    Réservations: <ReservationsIcon />,
    'MG - Réservation': <ReservationsIcon />,
    'Réservation espace, Restauration': <MeetingroomIcon />,
    'Agencement espace intérieur': <LivingRoomIcon />,
    'URGENCES': <ProblemIcon />,
    'Problème technique': <ProblemIcon />,
    DYSFONCTIONNEMENT: <ProblemIcon />,
    Dysfonctionnement: <ProblemIcon />,
    'Matériel défectueux': <ProblemIcon />,
    'Dysfonctionnement - Anomalie': <ProblemIcon />,
    REARRANGEMENT: <RearrangementIcon />,
    Projets: <RearrangementIcon />,
    Déménagement: <RearrangementIcon />,
    'Déménagement / Emménagement': <RearrangementIcon />,
    'Déménagements et Travaux': <PackageIcon />,
    '14.Mobilier, Aménagements et travaux': <PackageIcon />,
    PROVISION: <RearrangementIcon />,
    'Mise à disposition': <RearrangementIcon />,
    'Demande de course': <PackageIcon />,
    'AMÉNAGEMENT / DÉMÉNAGEMENT': <PackageIcon />,
    'Aménagement / Déménagement': <PackageIcon />,
    'Déménagement / Mobilier': <PackageIcon />,
    'Déménagement /Mobilier': <PackageIcon />,
    'Mouvements intra site': <PackageIcon />,
    Aménagement: <RearrangementIcon />,
    PRINTER: <PrinterIcon />,
    Printer: <PrinterIcon />,
    Imprimante: <PrinterIcon />,
    'Copieurs - Imprimantes': <PrinterIcon />,
    'Copieurs / Imprimantes': <PrinterIcon />,
    Copieur: <PrinterIcon />,
    Copieurs: <PrinterIcon />,
    'Point copieur': <PrinterIcon />,
    'Papiers / Encre photocopieurs': <PrinterIcon />,
    BIN: <BinIcon />,
    Poubelle: <BinIcon />,
    'MEETING ROOM SETUP': <MeetingroomIcon />,
    'Mise en place salle de réunion': <MeetingIcon />,
    '05.Salles de réunion': <MeetingIcon />,
    'Équipe': <MeetingIcon />,
    'Conciergerie': <MeetingIcon />,
    DISTRIBUTOR: <DistribIcon />,
    'Distributeurs - Fontaines à eau': <DistribIcon />,
    'Machine à café': <DistribIcon />,
    DISPLAY: <DisplayIcon />,
    Affichage: <DisplayIcon />,
    HANDLING: <BoxIcon />,
    'Shipping / Delivery' : <PackageIcon />,
    COFFEE: <CoffeeIcon />,
    'Café/machine à café': <CoffeeIcon />,
    'Pôle café': <CoffeeIcon />,
    BADGE: <BadgeIcon />,
    'Badge': <BadgeIcon />,
    'Badge et accès au bâtiment': <BadgeIcon />,
    'Contrôle d\'accés': <BadgeIcon />,
    'Carte de visite': <BadgeIcon />,
    TEST: <TestIcon />,
    'Test': <TestIcon />,
    'Machines à café': <CoffeeIcon />,
    'Test médiamétrie': <TestIcon />,
    LIGHT: <LightIcon />,
    Lumière: <LightIcon />,
    'Eclairage/Prise de courant': <LightIcon />,
    OUTLET: <OutletIcon />,
    'Prise électrique': <OutletIcon />,
    'Éclairage / prise de courant': <OutletIcon />,
    'Voiture': <CarIcon />,
    'Transport': <ShuttleIcon />,
    'Parc auto': <CarIcon />,
    'PARKING VOITURES / VÉLOS': <ParkingIcon />,
    Parking: <ParkingIcon />,
    'Parking/Garage': <ParkingIcon />,
    'Guest Parking': <ParkingIcon />,
    'CONSOMMABLES HYGIÈNE': <ConsoIcon />,
    'Consommables hygiène': <ConsoIcon />,
    PEST: <CloseIcon />,
    Nuisible: <CloseIcon />,
    '3D': <CloseIcon />,
    '10.Nuisibles': <CloseIcon />,
    Manutention: <MaintenanceIcon />,
    'PRESTA PR': <MaintenanceIcon />,
    'PRESTA ST': <MaintenanceIcon />,
    'PRESTA NG': <MaintenanceIcon />,
    'GREEN SPACES': <GreenSpaceIcon />,
    'MG - Espaces verts': <GreenSpaceIcon />,
    'Jardinage': <GreenSpaceIcon />,
    'Espaces Verts': <GreenSpaceIcon />,
    'Espaces verts': <GreenSpaceIcon />,
    'Plantes vertes': <FontAwesomeIcon icon={faSeedling} />,
    'Plantes': <FontAwesomeIcon icon={faSeedling} />,
    '15.Plantes/Espaces Verts': <GreenSpaceIcon />,
    'Intervention espaces extérieurs': <GardenFenceIcon />,
    '25. Terrasses': <TerraceIcon />,
    'Surfaces extérieures': <GardeningToolsIcon />,
    BIKE: <BikeIcon />,
    Vélos: <BikeIcon />,
    Vélo: <BikeIcon />,
    'WASTE MANAGEMENT': <TrashIcon />,
    'Déchet / Archive': <TrashIcon />,
    '08.Propreté / déchets': <TrashIcon />,
    'Destruction':  <TrashIcon />,
    'Gestion des déchets': <TrashIcon />,
    'Gestion Des Dechets': <TrashIcon />,
    'Déchets': <TrashIcon />,
    'MG - Gestion des déchets': <TrashIcon />,
    'Propreté/Déchets': <TrashIcon />,
    'Propreté / déchets': <TrashIcon />,
    ESCALATOR: <EscalatorIcon />,
    Escalator: <EscalatorIcon />,
    RONDE: <RondeIcon />,
    Ronde: <RondeIcon />,
    'CO2': <CO2Icon />,
    PARKING: <ParkingIcon />,
    'Parking voitures / vélos': <ParkingIcon />,
    'Parking / garage': <ParkingIcon />,
    ACHATS: <AchatsIcon />,
    Achats: <AchatsIcon />,
    ZZDIVERSE: <FontAwesomeIcon icon={faTicket} />,
    Divers: <FontAwesomeIcon icon={faTicket} />,
    'MG - Divers': <FontAwesomeIcon icon={faTicket} />,
    'BUSINESS CARD': <CardIcon />,
    'Vêtement de travail': <CardIcon />,
    'Cartes de visite': <CardIcon />,
    FLEURISTE: <FlowerIcon />,
    Fleuriste: <FlowerIcon />,
    COURRIER: <CourrierIcon />,
    Courrier: <CourrierIcon />,
    '16.Courrier': <CourrierIcon />,
    'Service Courrier': <CourrierIcon />,
    'MG - Courriers, colis, livraisons': <CourrierIcon />,
    'MG - Courrier Colis Livraison': <CourrierIcon />,
    'Envois express (coursier/fedex)': <PackageIcon />,
    PCSECURITY: <SecurityIcon />,
    'PC Sécurité': <SecurityIcon />,
    'Contrôle accès' : <SecurityIcon />,
    TELEPHONY: <PhoneIcon />,
    'INF - Téléphonie': <PhoneIcon />,
    'Telephonie': <PhoneIcon />,
    'Téléphonie': <PhoneIcon />,
    Accompagnement: <AccompanyIcon />,
    Personnel: <AccompanyIcon />,
    Auditorium: <AuditoriumIcon />,
    Break: <BreakIcon />,
    Medical: <MedicalIcon />,
    Médical: <MedicalIcon />,
    'Traçabilité': <FontAwesomeIcon icon={faFileCheck} />,
    'Sinistre': <FontAwesomeIcon icon={faHouseDamage} />,
    'INF - Matériel Médical': <MedicalIcon />,
    'Offboarding (Précisez si IT ou OM)': <OffboardingIcon />,
    'Offboarding IT': <OffboardingIcon />,
    'Offboarding OM': <OffboardingIcon />,
    'Onboarding (Précisez si IT ou OM)': <OnboardingIcon />,
    'Onboarding IT': <OnboardingIcon />,
    'Onboarding OM': <OnboardingIcon />,
    'Office Supplies': <OfficeSuppliesIcon />,
    'Distributeurs Divers': <OfficeSuppliesIcon />,
    '23. Intendance': <OfficeSuppliesIcon />,
    'Shuttle': <ShuttleIcon />,
    'Visit': <VisitIcon />,
    'Visite client / Audit': <VisitIcon />,
    'Visit Card': <VisitIcon />,
    'Site Access Card': <VisitIcon />,
    'MG - Téléphone portable': <PhoneIcon />,
    DIVERS: <FontAwesomeIcon icon={faTicket} />,
    ZZDIVERS: <FontAwesomeIcon icon={faTicket} />,
    'Gestion Fournisseurs': <ContractIcon />,
    'Gestion fournisseur': <ContractIcon />,
    'MG - Contrats': <ContractIcon />,
    'MG - Ramassage de fonds': <FontAwesomeIcon icon={faTicket} />,
    'MG - Tablettes': <TabletIcon />,
    'MG - Terminal paiement électronique': <TpeIcon />,
    'RH - Ticket Restaurant': <RestaurantIcon />,
    'Restaurant le FoRest': <Restaurant2Icon />,
    'Restaurant': <Restaurant2Icon />,
    'Restauration': <Restaurant2Icon />,
    'PAO': <ContractIcon />,
    'RH - Mutuelle': <ProtectionIcon />,
    'RH - Paie': <MoneyIcon />,
    'Credit Card Purchase': <ReceiptIcon />,
    'Refacturation': <ReceiptIcon />,
    'Demande de devis': <ReceiptIcon />,
    'Devis': <ReceiptIcon />,
    'Signaler une situation à risque d’accident': <RiskIcon />,
    'RH - Congés': <HolidayIcon />,
    'Travel': <HolidayIcon />,
    'Voyages': <HolidayIcon />,
    'RH - Demande d\'entretien': <MeetingIcon />,
    'Pause Client': <MeetingIcon />,
    'RH - Dossier Administratif Personnel': <FieldIcon />,
    'RH – Dossier Admin Personnel': <FieldIcon />,
    'RH - Divers': <FontAwesomeIcon icon={faTicket} />,
    'MG - Consommables': <ConsoIcon />,
    VRD: <FontAwesomeIcon icon={faTicket} />,
    'Réseaux secs': <FontAwesomeIcon icon={faTicket} />,
    'Gros oeuvre': <WorkIcon />,
    Covid19: <VirusIcon />,
    'Désinfection COVID': <VirusIcon />,
    Étanchéité: <PlumbingIcon />,
    Platrerie: <WorkIcon />,
    Isolation: <WorkIcon />,
    Revêtement: <WorkIcon />,
    Carrelage: <WorkIcon />,
    Peinture: <WorkIcon />,
    Charpente: <WorkIcon />,
    Parquet: <WorkIcon />,
    Enduits: <WorkIcon />,
    Menuiserie: <WorkIcon />,
    Électroménager: <ApplianceIcon />,
    'Équipements divers': <FontAwesomeIcon icon={faTicket} />,
    'Services Généraux': <FacilityIcon />,
    'Multiservice': <FacilityIcon />,
    Bâtiment: <BuildingIcon />,
    Consommables: <ConsoTaqtIcon />,
    Consommable: <ConsumerIcon />,
    'Contrôle Consommables': <ConsumerIcon />,
    Maintenance: <MaintenanceIcon />,
    'Maintenance - Entretien': <MaintenanceIcon />,
    'Travaux-Maintenance': <MaintenanceIcon />,
    Rondes: <RoundsIcon />,
    Commande: <RoundsIcon />,
    Walkthrough: <RoundsIcon />,
    'MG - Entretien': <CleaningIcon />,
    'Température': <TemperatureIcon />,
    'Temperature': <TemperatureIcon />,
    'Humidité': <HumidityIcon />,
    'Humidity': <HumidityIcon />,
    TICKETS_all: <AllIcon />,
    TICKETS_comment: <CommentIcon />,
    TICKETS_assignated: <AssignatedIcon />,
    ROUNDS_all: <RoundsIcon />,
    ROUNDS_comment: <CommentIcon />,
    ROUNDS_instance_incomplete: <IncompleteIcon />,
    SuccessDove: <SuccessDove />,
    ErrorLaptop: <ErrorLaptop />,
    rounds: <RoundsIcon />,
    roundsFilled: <RoundsIconFilled />,
    roundClouds: <RoundClouds />,
    roundsFilled2: <RoundsIconFilled2 />,
    archive: <FontAwesomeIcon icon={faArchive} />,
    Archivage: <FontAwesomeIcon icon={faArchive} />,
    company: <FontAwesomeIcon icon={faBuilding} />,
    // SETTINGS
    settings: <WrenchIcon />,
    settingsFilled: <WrenchIconFilled />,
    // TICKETS
    ticket: <FontAwesomeIcon className="icon-ticket" icon={faClipboardList} />,
    ticket_action_button: <FontAwesomeIcon icon={faTicket} />,
    calendar: <FontAwesomeIcon icon={faCalendarDay} />,
    send: <FontAwesomeIcon icon={faPaperPlaneTop} />,
    noUser: <FontAwesomeIcon icon={faUserTimes} />,
    normalPriority: <NormalPriority />,
    highPriority: <HighPriority />,
    urgentPriority: <UrgentPriority />,
    dateFilter: <FontAwesomeIcon icon={faCalendarCheck} />,
    house: <FontAwesomeIcon icon={faHouse} />,
    tickets: <FontAwesomeIcon icon={faTicket} />,
    ticketsFilled: <FontAwesomeIcon icon={faTicketAltFilled} />,
    attachment: <FontAwesomeIcon icon={faPaperclip} />,
    photo: <FontAwesomeIcon icon={faCamera} />,
    MerciYanis: <MerciYanisIcon />,
    SLACK: <SlackIcon />,
    TEAMS: <TeamsSecondIcon />,
    SLACK_MESSAGE: <SlackIcon />,
    TEAMS_MESSAGE: <TeamsIcon />,
    EXTERNAL_FOLLOWER: <FontAwesomeIcon icon={faEnvelope} />,
    EXTERNAL_FOLLOWER_MESSAGE: <FontAwesomeIcon icon={faEnvelope} />,
    slide_status: <FontAwesomeIcon icon={faChevronDoubleRight} />,
    SAMFM: <img
        src={'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1589461030/samfm/samfm.jpg'}
        alt={''}
    />,
    SERVICENOW: <img
        src={'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1614787428/servicenow/logo-service-now-orga.png'}
        alt={''}
    />,
    GSF: <img
        src={'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1707839153/connecteurs/Logo-GSF_1.png'}
        alt={''}
    />,
    OPERATION_UNKNOWN: <OperationIcon className={'comment-icon'} />,
    DOIT: <OperationIcon className={'comment-icon'} />,
    NOUVELATRIUM: <img
        src={'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1690381643/connecteur/veolys.png'}
        alt={''}
    />,
    SHARINGCLOUD: <img
        src={'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1668691047/connecteurs/output-onlinepngtools.png'}
        alt={''}
    />,
    SPINALCOM: <img
        src={'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1668692994/connecteurs/797-spinalcom-49-1526481829.jpg'}
        alt={''}
    />,
    DOIT_MESSAGE: <OperationIcon className={'comment-icon'} />,
    ONEATALIAN: <img
        src={'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1660725980/plateforme-icon/atalian-logo.png'}
        alt={''}
    />,
    azure_ad: <AzureADIcon />
};

PLUGINS.forEach(plugin => {
    try {
        const startupIcons = require(`../../../plugins/${plugin}/app/Icons`).default; // eslint-disable-line
        iconByType = { ...iconByType, ...startupIcons };
    } catch (e) {
        console.log(`Plugin ${plugin} has no icon file`); // eslint-disable-line
    }
});

const Icon = ({ rotate, color, type }) => {
    const [style] = useState({ transform: rotate ? 'rotate(-45deg)' : '', color });
    const GetIcon = () => iconByType[type];
    return iconByType[type] === undefined ? <div /> : rotate || color ? <div style={style}><GetIcon /></div> : <GetIcon />; // eslint-disable-line
};

Icon.defaultProps = {
    rotate: false,
    color: ''
};

Icon.propTypes = {
    color: PropTypes.string,
    rotate: PropTypes.bool,
    type: PropTypes.string.isRequired
};

export default memo(Icon);

export const COLUMN_EXTENSIONS = [
    { columnName: 'round_name', sortingEnabled: false },
    { columnName: 'building', sortingEnabled: false },
    { columnName: 'recurrence', sortingEnabled: false },
    { columnName: 'round_followers', sortingEnabled: false }
];

export const START_DATE_PARAM = 'selectedStartedDate';
export const END_DATE_PARAM = 'selectedEndedDate';
export const PARAMS_DATE_FORMAT = 'YYYY-MM-DD';

// Max number of months after the current date for displaying the rounds instances
export const MAX_NEXT_DATE_RANGE = 3;

// Default calendar view
export const DEFAULT_ROUND_VIEW = 'calendar';

export const BATCH_API_CALL_STATUS = Object.freeze({
    ALL_FULFILLED: 'ALL_FULFILLED',
    PARTIAL_FULFILLED: 'PARTIAL_FULFILLED',
    ALL_REJECTED: 'ALL_REJECTED'
});

export const ROUND_INSTANCE_STATUS = Object.freeze({
    INCOMPLETE: 'INCOMPLETE',
    IN_PROGRESS: 'IN_PROGRESS',
    PARTIAL_INCOMPLETE: 'PARTIAL_INCOMPLETE',
    COMPLETE: 'COMPLETE',
    NOT_STARTED: 'NOT_STARTED',
});

export const ROUND_INSTANCE_EVENT_STATUS = Object.freeze({
    OK: 'OK',
    ANOMALY: 'ANOMALY'
});

// Libs
import React from 'react';
import { isMobile } from 'react-device-detect';
// Components
import { WaveIllustrationTop, WaveIllustrationBottom } from './background-components/WaveIllustrations';
import CloudIllustrations from './background-components/cloudIllustrations';
// Scss
import './background-components/style.scss';
import './background-components/routerAnimations.scss';

const Background = props => {
    if (isMobile) {
        return (
            <div className="background-wrapper">
                <div className="main-container login-form-mobile">
                    <div className={props.fullWidth ? 'router-wrapper-full-width' : 'router-wrapper'}>
                        { props.children }
                    </div>
                    <div className="cloud-illustration-wrapper">
                        <CloudIllustrations />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="background-wrapper">
            <div className="main-container">
                <div className="top-wrapper">
                    <WaveIllustrationTop />
                </div>
                <div className={props.fullWidth ? 'router-wrapper-full-width' : 'router-wrapper'}>
                    { props.children }
                </div>
                <div className="cloud-illustration-wrapper">
                    <CloudIllustrations />
                </div>
                <div className="bottom-wrapper">
                    <WaveIllustrationBottom />
                </div>
            </div>
        </div>
    );
};

export default Background;

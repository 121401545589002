// Libs
import React from 'react';
import { useSelector } from 'react-redux';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
// Actions
import createLoadingSelector from '../../../layout/actions';
import useUpdateForm from "../../../layout/forms/UseUpdateForm";

const loadingSelector = createLoadingSelector(['EDIT_GROUP']);
const SettingsDetailsPopup = props => {
    const { Component } = useComponentsPool();
    const group = useSelector(state => state.groups.groupShownInDetailsPopup);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));

    const moreFunction = state => () => ({
        ...state
    });
    const { updateState, state } = useUpdateForm([{ props: { value: 'custom_field' } }, { props: { value: 'group_name' } }], group, 'group_id', moreFunction);

    const content = [{
        width: '100%',
        content: <Component
            componentName={'SettingsDetailsPopupLeft'}
            item={props.item}
            updateState={(resource, value) => updateState(resource, value)}
            state={state}
        />
    }];

    return (
        <Component
            componentName={'Modal'}
            loading={isLoading}
            content={content}
            contentWidth={'100%'}
            classNames={'details-popup'}
        />
    );
};

export default SettingsDetailsPopup;

// Libs
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// Custom providers
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { FootBarProvider } from './modules/footbar/FootBarContext';
import { ModalProvider } from './modules/modal/ModalContext';
import { FormProvider } from './modules/layout/forms/api/useFormState';
import { ComponentsPoolProvider } from './ComponentsPool';
import store from './store';
import i18n from './i18n';
// Component
import ErrorBoundary from './modules/errorBoundary';
import ConfigTabsProvider from './modules/workspace/ConfigContext';
import MainProvider from './modules/layout/MainContext';

const Providers = ({ children }) => (
    <HelmetProvider>
        <I18nextProvider i18n={i18n}>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato:400,700,900" />
            <Provider store={store}>
                <ErrorBoundary isFullscreen>
                    <Router>
                        <ComponentsPoolProvider>
                            <FootBarProvider>
                                <ModalProvider>
                                    <FormProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MainProvider>
                                                <ConfigTabsProvider>
                                                    {children}
                                                </ConfigTabsProvider>
                                            </MainProvider>
                                        </LocalizationProvider>
                                    </FormProvider>
                                </ModalProvider>
                            </FootBarProvider>
                        </ComponentsPoolProvider>
                    </Router>
                </ErrorBoundary>
            </Provider>
        </I18nextProvider>
    </HelmetProvider>
);

export default Providers;

// Libs
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
// Context
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useComponentsPool } from '../../../../../ComponentsPool';
import { getWorkspaceGroupIdsByGroupType } from '../../../helper';
import { getGroupNameFromId } from '../../../../groups/helper';
import { getUserGroupsByGroupType } from '../../../../users/helper';

export default props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const groupList = useSelector(state => state.groups.list);
    const floorMode = workspaceSettings.mode_floor ? workspaceSettings.mode_floor : 'close';
    const positionMode = workspaceSettings.mode_position ? workspaceSettings.mode_position : 'close';

    const required_fields = workspaceSettings.required_fields ? workspaceSettings.required_fields : '';
    const currentUser = useSelector(state => state.users.currentUser);
    const batiments = getUserGroupsByGroupType(currentUser, 'BATIMENT');

    const [floors, setFloors] = useState(floorMode === 'close' && props?.batiment_group_id
        ? getWorkspaceGroupIdsByGroupType(groupList, 'FLOOR', props?.batiment_group_id)
        : []
    );
    const [positions, setPositions] = useState(positionMode === 'close' && props?.floor_group_id
        ? getWorkspaceGroupIdsByGroupType(groupList, 'POSITION', props?.floor_group_id)
        : []
    );

    const getBatimentOptions = () => map(batiments, group => ({ value: group.group_name, id: group.group_id }));

    const getFloorOptions = () => map(
        floors, group => ({ value: getGroupNameFromId(groupList, group), id: group })
    );

    const getPositionOptions = () => map(
        positions, group => ({ value: getGroupNameFromId(groupList, group), id: group })
    );

    // Default value to enable the save button
    useEffect(() => {
        if (batiments?.length === 1) {
            props.changeState({ batiment_group_id : batiments[0].group_id })
        }
    }, []);

    // Get the right floor list when the batiment change and reset the position list
    useEffect(() => {
        if (floorMode === 'close') {
            setFloors(getWorkspaceGroupIdsByGroupType(groupList, "FLOOR", props.batiment_group_id));
            setPositions([]);
        }
    }, [props.batiment_group_id]);

    // Get the right position list when the batiment change
    useEffect(() => {
        if (positionMode === 'close') {
            setPositions(getWorkspaceGroupIdsByGroupType(groupList, "POSITION", props.floor_group_id));
        }
    }, [props.floor_group_id]);

    const getBatimentComponent = () => {
        const title = t('tickets:batiment');
        if (batiments?.length === 1) {
            return (
                <Component
                    componentName={'EditableField'}
                    renderValue={'Select'}
                    required
                    title={title}
                    props={{
                        placeHolder: '',
                        onChange: value => props.changeState({ batiment_group_id: value.id }),
                        options: getBatimentOptions(),
                        selected: { value: batiments[0].group_name, id: batiments[0].group_id },
                        disabled: true
                    }}
                />
            );
        } if (batiments.length === 2 || batiments.length === 3) {
            // both required props are needed for the EditableField and TicketToggleGroups
            return (
                <Component
                    componentName={'EditableField'}
                    renderValue={'TicketToggleGroups'}
                    required
                    title={title}
                    props={{
                        options: getBatimentOptions(),
                        onChange: value => props.changeState({ batiment_group_id: value }),
                        required: true,
                        selected: props?.batiment_group_id,
                        isCreateTicketPopup: true,
                        groupType: 'BATIMENT'
                    }}
                />
            );
        }
        return (
            <Component
                componentName={'EditableField'}
                renderValue={'GroupSelector'}
                required
                title={title}
                props={{
                    placeholder: '',
                    onChange: value => props.changeState({ batiment_group_id: value }),
                    groupType: 'BATIMENT',
                    isCreateTicketPopup: true
                }}
            />
        );
    };

    const getFloorComponent = () => {
        const title = t('tickets:floor');
        if (floorMode === 'close') {
            if (floors.length === 2 || floors.length === 3) {
                // both required props are needed for the EditableField and TicketToggleGroups
                return (
                    <Component
                        componentName={'EditableField'}
                        renderValue={'TicketToggleGroups'}
                        required={required_fields.includes('floor')}
                        title={title}
                        props={{
                            options: getFloorOptions(),
                            onChange: value => props.changeState({ floor_group_id: value }),
                            required: required_fields.includes('floor'),
                            selected: props.floor_group_id
                        }}
                    />
                );
            }
            return (
                <Component
                    componentName={'EditableField'}
                    renderValue={'GroupSelector'}
                    required={required_fields.includes('floor')}
                    title={title}
                    props={{
                        placeholder: '',
                        onChange: value => props.changeState({floor_group_id: value}),
                        groupType: 'FLOOR',
                        modeHierarchy: true,
                        parent: props?.batiment_group_id,
                        object: 'ticket',
                        isCreateTicketPopup: true
                    }}
                />
            )
        }
        return (
            <Component
                componentName={'EditableField'}
                renderValue={'Input'}
                required={required_fields.includes('floor')}
                title={title}
                props={{
                    rules: ['isAcceptableString', 'maxLength-64'],
                    label: '',
                    onChange: value => props.changeState({floor_label: value}),
                    value: props.floor_label
                }}
            />
        );
    };

    const getPositionComponent = () => {
        const title = t('tickets:position');
        if (positionMode === 'close') {
            if (positions.length === 2 || positions.length === 3) {
                return (
                    <Component
                        componentName={'EditableField'}
                        renderValue={'TicketToggleGroups'}
                        required={required_fields.includes('position')}
                        title={title}
                        props={{
                            options: getPositionOptions(),
                            onChange: value => props.changeState({ position_group_id: value }),
                            required: required_fields.includes('position'),
                            selected: props.position_group_id
                        }}
                    />
                );
            }
            return (
                <Component
                    componentName={'EditableField'}
                    renderValue={'Input'}
                    required={required_fields.includes('position')}
                    title={title}
                    props={{
                        rules: ['isAcceptableString', 'maxLength-64'],
                        label: '',
                        onChange: value => props.changeState({position_label: value}),
                        value: props.position_label
                    }}
                />
            )

        }
        return (
            <Component
                componentName={'EditableField'}
                renderValue={'Input'}
                required={required_fields.includes('position')}
                title={title}
                props={{
                    rules: ['isAcceptableString', 'maxLength-64'],
                    label: '',
                    onChange: value => props.changeState({position_label: value}),
                    value: props.position_label
                }}
            />
        )
    };

    return (
        <div className={classNames(['ticket-step', 'form-control'])}>
            <div className={'editable-field info'}>
                {getBatimentComponent()}
            </div>
            <div className={'editable-field info'}>
                {getFloorComponent()}
            </div>
            <div className={'editable-field info'}>
                {getPositionComponent()}
            </div>
        </div>
    );
};

import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useComponentsPool } from '../../../../../ComponentsPool';
import classNames from 'classnames';

const DateNavigatorArrow = ({ onClick, type, disabled, tooltipText }) => {
    const { Component } = useComponentsPool();

    const arrowDirection = type === 'previous' ? 'calendar-previous-arrow-button' : '';
    const disabledStyles = disabled === true ? 'calendar-arrow-button-disabled' : '';

    return (
        <>
            <Tooltip placement={'top'} arrow={false} title={tooltipText}>
                <div
                    className={classNames(['calendar-arrow-button', arrowDirection, disabledStyles])}
                    onClick={disabled ? () => {} : onClick}
                >
                    <div className={'arrow-icon-wrapper'}>
                        <Component componentName={'Icon'} type={'ArrowRight'} />
                    </div>
                </div>
            </Tooltip>
        </>
    );
};

export default DateNavigatorArrow;

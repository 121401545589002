// Lib
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Fab from '@material-ui/core/Fab';
// Context
import { useSelector } from 'react-redux';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { isPassageDeviceType, isQRCodeDocumentDeviceType } from '../../devices/helper';
import { useComponentsPool } from '../../../ComponentsPool';
import { useModal } from '../../modal/ModalContext';
import Icon from '../../layout/components/Icon';
import { EVENT_OPEN_CONFIGURATION_MENU, segmentTrack, useSecondMainColorTooltip } from '../../layout/helper';
import { ConfigTabsContext } from '../ConfigContext';

const WorkspaceConfigurationMenu = () => {
    const { t } = useTranslation();
    const { Component } = useComponentsPool();
    const modal = useModal();
    const [pageName, setPageName] = useState('WorkspaceConfigurationHomePage');
    const [pageTabIdx, setPageTabIdx] = useState(0);
    const devices = useSelector(state => state.devices.list);
    const [disableTabs, setDisableTabs] = useContext(ConfigTabsContext);
    const tooltipStyle = useSecondMainColorTooltip();
    const [menuTabs, setMenuTabs] = React.useState([
        {
            icon: 'house',
            title: t('common:home'),
            headerTitle: t('workspace:configure_workspace'),
            pageName: 'WorkspaceConfigurationHomePage'
        },
        {
            icon: 'tickets',
            title: t('tickets:tickets'),
            headerTitle: t('workspace:configure_tickets'),
            pageName: 'TicketsConfiguration',
            isVisible: true,
            isOpen: true,
            subTabs: [
                { title: 'configure_auto_ticket_archiving' }
            ]
        }, {
            icon: 'devices',
            title: t('common:devices'),
            headerTitle: t('workspace:configure_devices'),
            pageName: 'DevicesConfiguration',
            isVisible: devices.find(device => device?.custom_field?.type === 'TEMPERATURE_CONNIT' || device?.custom_field?.type === 'AIRWITS' || device?.custom_field?.type === 'AIRWITS_CO2'),
            isOpen: true,
            subTabs: [
                { title: 'configure_devices_alerts_conditions' }
            ]
        }, {
            icon: 'QRCODE',
            title: t('common:qrcodes'),
            headerTitle: t('workspace:configure_qrcodes'),
            pageName: 'QRCodesConfiguration',
            isVisible: true,
            isOpen: true,
            subTabs: [
                { title: 'configure_qrcodes_documents' }
            ]
        }, {
            icon: 'users',
            title: t('users:users'),
            headerTitle: t('workspace:configure_users'),
            pageName: 'UsersConfiguration',
            isVisible: true,
            isOpen: true,
            subTabs: [
                { title: 'configure_users_rights' },
                { title: 'configure_contractors_rights' }
            ]
        }, {
            icon: 'rounds',
            title: t('rounds:planning'),
            headerTitle: t('workspace:configure_rounds'),
            pageName: 'RoundsConfiguration',
            isVisible: devices.find(device => isPassageDeviceType(device)),
            isOpen: true,
            subTabs: [
                { title: 'configure_rounds_tickets' }
            ]
        }
    ]);

    useEffect(() => {
        segmentTrack(EVENT_OPEN_CONFIGURATION_MENU);
        setDisableTabs(false);
    }, []);

    const toggleCollapse = clickedItem => {
        const itemIdx = menuTabs.findIndex(item => item.title === clickedItem.title);
        const copy = [...menuTabs];
        if (itemIdx !== -1) {
            copy[itemIdx].isOpen = !copy[itemIdx].isOpen;
            setMenuTabs(copy);
        }
    };

    const changeTab = (tab, tabIdx) => {
        setPageName(tab.pageName);
        setPageTabIdx(tabIdx || 0);
    };

    return (
        <div className={'configuration-menu-wrapper'}>
            <div className={'configuration-menu-header'}>
                <Component componentName={'Icon'} type={menuTabs.find(item => pageName === item.pageName)?.icon} />
                <span>{menuTabs.find(item => pageName === item.pageName)?.headerTitle}</span>
                <Fab className={'button-close-modal button-close-menu'} onClick={() => modal.close()}>
                    <Component componentName={'Icon'} type={'close'} />
                </Fab>
            </div>
            <div className={'configuration-menu-content'}>
                <div className={'configuration-menu-navigation-menu'}>
                    {menuTabs.map(tab => (
                        <Tooltip placement={'right'} classes={tooltipStyle} title={disableTabs ? t('common:switch_button_mode_eye_disabled') : ''}>
                            <div className={disableTabs && 'disabled'}>
                                {!tab.subTabs ? ( // HomePage Tab
                                    <div className={classNames(['navigation-tab-icon-and-title navigation-tab-homepage', { 'navigation-current-tab': pageName === tab.pageName }])}>
                                        <div className={'navigation-tab-icon'}><Icon type={tab.icon} /></div>
                                        <Button
                                            className={'navigation-tab-text'}
                                            onClick={() => changeTab(tab)}
                                            disabled={disableTabs}
                                        >
                                            {tab.title}
                                        </Button>
                                    </div>
                                ) : tab.isVisible && ( // All tabs besides HomePage
                                    <>
                                        <div className={classNames(['navigation-tab-icon-and-title', { 'navigation-current-tab': pageName === tab.pageName }])}>
                                            <Button
                                                className={classNames(['navigation-tab-collapse-icon', { 'navigation-tab-is-collapsed': !tab.isOpen }])}
                                                onClick={() => toggleCollapse(tab)}
                                                disabled={disableTabs}
                                            >
                                                <ExpandMore />
                                            </Button>
                                            <div className={'navigation-tab-icon'}><Icon type={tab.icon} /></div>
                                            <Button
                                                className={'navigation-tab-text'}
                                                onClick={() => changeTab(tab)}
                                                disabled={disableTabs}
                                            >
                                                {t(`workspace:${tab.title}`)}
                                            </Button>
                                        </div>
                                        <Collapse in={tab.isOpen}>
                                            {tab.subTabs.map((subTab, subTabIdx) => (
                                                <Button
                                                    className={classNames(['navigation-tab-text', { 'navigation-current-sub-tab': pageName === tab.pageName && subTabIdx === pageTabIdx }])}
                                                    onClick={() => changeTab(tab, subTabIdx)}
                                                    disabled={disableTabs}
                                                >
                                                    {t(`workspace:${subTab.title}`)}
                                                </Button>
                                            ))}
                                        </Collapse>
                                    </>
                                )}
                            </div>
                        </Tooltip>
                    ))}
                </div>
                <div className={'configuration-page-wrapper'}>
                    <Component
                        componentName={pageName}
                        tabIdx={pageTabIdx}
                        changeTab={(tab, tabIdx) => changeTab(tab, tabIdx)}
                        menuTabs={menuTabs}
                    />
                </div>
            </div>
        </div>
    );
};

export default WorkspaceConfigurationMenu;

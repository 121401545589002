import { ExpirationPlugin } from 'workbox-expiration';
import { createHandlerBoundToURL } from 'workbox-precaching';
import { registerRoute } from 'workbox-routing';
import { StaleWhileRevalidate, CacheFirst } from 'workbox-strategies';
import { googleFontsCache } from 'workbox-recipes';

export const setupAssetsCaching = () => {
    googleFontsCache();

    // Cache routes for Cloudinary assets using CacheFirst strategy.
    registerRoute(
        ({ url }) => url.host === 'res.cloudinary.com',
        new CacheFirst()
    );

    // Handler for HTML navigation requests not matching specific resource patterns.
    const fileExtensionRegexp = new RegExp('/[^/?]+\\.[^/]+$');
    registerRoute(
        ({ request, url }) => request.mode === 'navigate'
            && !url.pathname.startsWith('/_')
            && !url.pathname.match(fileExtensionRegexp),
        createHandlerBoundToURL('/index.html')
    );

    // Cache same-origin PNG files using StaleWhileRevalidate strategy.
    const imageCacheName = 'images';
    registerRoute(
        ({ url }) => url.origin === self.location.origin && url.pathname.endsWith('.png'),
        new StaleWhileRevalidate({
            cacheName: imageCacheName,
            plugins: [
                // Ensure that once this runtime cache reaches a maximum size the
                // least-recently used images are removed.
                new ExpirationPlugin({ maxEntries: 50 })
            ]
        })
    );
};


import React from 'react';

const ActionRow = props => (
    <div className="action-row-wrapper">
        { props.buttons.map(item => (
            <button
                key={item.id}
                type="button"
                className="button"
                onClick={item.onClick}
            >
                {item.name}
            </button>
        ))}
    </div>
);

export default ActionRow;

// Libs
// Context
import {
    capitalize, cloneDeep, filter, isEmpty, sortBy
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { useComponentsPool } from '../../../ComponentsPool';
import useRouter from '../../../utils/hook/useRouter';
import { useFootBar } from '../../footbar/FootBarContext';
import createLoadingSelector from '../../layout/actions';
import { useModal } from '../../modal/ModalContext';
// Helper
import { filterTable } from '../../navbar/helper';
// Actions
import { setRoundShownInDetailPopup } from '../actions';
import RoundToolbar from './RoundToolbar/RoundToolbar';
import { getCurrentRoundView, VIEW_CALENDAR, VIEW_LIST } from './RoundViewSwitcher/RoundViewSwitcher';
import Icon from '../../layout/components/Icon';
import { updateWorkspaceSetting } from '../../core/actions';
import InformationBanner from '../../layout/components/InformationBanner';
import { COLUMN_EXTENSIONS } from "../constants";
import { useIsFirstRender } from "../../../utils/hook/useIsFirstRender";

const customFilterFunctions = {
    round: props => filter(props.list, round => round.id === props.id),
    rounds: props => filter(props.list, round => (props.isCalendarFilter ? round.round_name : round.name)?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '')?.replace(/\s/g, '')?.toLowerCase().includes(
        props?.id?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.replace(/\s/g, '')?.toLowerCase()
    ))
};

const filterGroup = (filters, list, props, isCalendarFilter = false) => {
    const newFilters = cloneDeep(filters);
    let newList = cloneDeep(list);

    newFilters.forEach(paramFilter => {
        if (paramFilter.filterFunction) {
            newList = paramFilter.filterFunction(list);
        }
    });

    return filterTable(
        filter(filters, f => f.type !== 'defaultOptions'),
        newList,
        props.list,
        [],
        ['round', 'rounds'],
        customFilterFunctions,
        true,
        isCalendarFilter
    );
};
const loadingSelector = createLoadingSelector(['GET_ROUNDS', 'EXPORT_INSTANCES', 'ARCHIVE_ROUND']);

const RoundsTable = () => {
    const [calendarCurrentWeekFirstDay, setCalendarCurrentWeekFirstDay] = useState('');
    const [currentView, setCurrentView] = useState('');
    const { getComponentFile, Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const footBar = useFootBar();
    const modal = useModal();
    const currentUser = useSelector(state => state.users.currentUser);
    const roundsList = useSelector(state => state.rounds.list);
    const filters = useSelector(state => state.navbar.filters);
    const roundShownInDetailsPopupInstances = useSelector(({ rounds }) => rounds.roundShownInDetailsPopup.instances);
    const [calendarInstances, setCalendarInstances] = useState(roundShownInDetailsPopupInstances);
    const active_mode = useSelector(state => state.rounds.active_mode);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const roundViewHasBanner = Object.prototype.hasOwnProperty.call(workspaceSettings, 'round_alerts')
        && workspaceSettings.round_alerts === false && currentUser.workspace_role?.workspace_role_name === 'Owner';
    const [rounds, setRounds] = useState(roundsList);
    const isFirstRender = useIsFirstRender()
    const { query } = useRouter();

    const roundsTableColumns = useSelector(state => sortBy(state.rounds.roundsTableColumns, 'weight'));
    const archivedRoundsTableColumns = useSelector(state => sortBy(state.rounds.archivedRoundsTableColumns, 'weight'));
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const areRoundsLoading = useSelector(state => state.rounds.isLoading);

    const isListView = getCurrentRoundView(query) === VIEW_LIST;
    const isCalendarView = getCurrentRoundView(query) === VIEW_CALENDAR;

    const prepareColumn = (column, count) => {
        let title = t(column.title, { count });
        if (column.isCapitalized) {
            title = capitalize(title);
        }
        if (column.formTitle) {
            title = (
                <div className={'table-head-title'}>
                    <span>{title}</span>
                    <em>{`(${count})`}</em>
                </div>
            );
        }
        return {
            name: column.name,
            title,
            component: column.component ? getComponentFile(column.component) : null
        };
    };

    const RoundsTableColumns = roundsTableColumns.map(column => prepareColumn(column, rounds.length));
    const ArchivedRoundsTableColumns = archivedRoundsTableColumns.map(column => prepareColumn(column, rounds.length));

    // Update list, if data updated
    useEffect(() => {
        setRounds(filterGroup(filters, roundsList, { list: roundsList }));
        const roundInstances = roundShownInDetailsPopupInstances;
        setCalendarInstances(filterGroup(filters, roundInstances, { list: roundInstances }, true));
    }, [roundsList, filters, roundShownInDetailsPopupInstances]);

    const toggleSelected = selected => {
        const selectFiltered = roundsList.filter(round => selected.includes(round.id));
        footBar.update({ selected, list: selectFiltered, type: 'round' });
    };

    // OnClick row table
    const getLogs = async round => {
        await dispatch(setRoundShownInDetailPopup(round.id));
        const selected = roundsList.find(item => round.id === item.id);
        footBar.close();
        modal.update({ name: 'RoundDetailsPopup', item: selected, withFootBar: true });
    };

    // Deselect the rows when we switch the round mode or round view mode
    useEffect(() => {
        // not to be executed on the first render because there is no mode change #performance
        if (!isFirstRender) {
            if (footBar.selected?.length > 0) {
                footBar.update({ selected: [] });
            }
        }
    }, [isCalendarView, active_mode]);

    const displayRoundTableContent = () => (
        rounds?.length > 0
            ? (
                <AutoSizer disableWidth>
                    {({ height }) => (
                        <div style={{ height }}>
                            <Component
                                disableTableCheckbox={!active_mode}
                                componentName={'Table'}
                                height={height}
                                open={false}
                                columns={!active_mode
                                    ? ArchivedRoundsTableColumns
                                    : RoundsTableColumns
                                }
                                toggleSelected={toggleSelected}
                                columnExtensions={COLUMN_EXTENSIONS}
                                list={filterGroup(filters, roundsList, { list: roundsList })}
                                onRowClick={round => getLogs(round)}
                            />
                        </div>
                    )}
                </AutoSizer>
            ) : <Component componentName={'NoData'} view={'round'} />
    );


    return (
        <div className={'table-wrapper round-list'}>
            <Component componentName={'LoaderBarTop'} isLoading={isLoading || areRoundsLoading} />
            {roundViewHasBanner && (
                <InformationBanner
                    icon={<Icon type={'ErrorRoundedOutlined'} />}
                    primaryLabel={t('rounds:rounds_alerts_explanation')}
                    buttonLabel={t('rounds:rounds_alerts_activation')}
                    marginBottom={'10px'}
                    onClick={() => {
                        dispatch(updateWorkspaceSetting(
                            'round_alerts',
                            true,
                            true
                        ));
                    }}
                />
            )}
            <RoundToolbar
                onCalendarWeekChange={firstDayOfTheWeek => setCalendarCurrentWeekFirstDay(firstDayOfTheWeek)}
                currentView={currentView}
            />
            { isListView && isEmpty(roundsList)
                ? (
                    <div className={'no-data'}>
                        <Component componentName={'NoData'} view={'round'} />
                    </div>
                ) : (
                    <div className={roundViewHasBanner ? 'round-table-with-banner' : 'round-table'}>
                        {isListView && displayRoundTableContent()}
                        {isCalendarView
                            && (
                                <Component
                                    currentWeekFirstDay={calendarCurrentWeekFirstDay}
                                    onViewChange={view => setCurrentView(view)}
                                    componentName={'RoundsCalendar'}
                                    calendarInstances={calendarInstances}
                                />
                            )
                        }
                    </div>
                )
            }
        </div>
    );
};

export default RoundsTable;

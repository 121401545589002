export const notification = (message, type, icon, count) => ({
    type: 'NOTIFICATION',
    payload: {
        message, type, icon, count
    }
});

export const cancelNotification = () => ({ type: 'RESET_NOTIFICATION', payload: { message: '', type: '', icon: '' } });

export const addNotificationOnAction = data => ({ type: 'ADD_NOTIFICATION_ON_ACTION', payload: data });

export const eventDurationErrorNotif = isTooShort => ({ type: isTooShort ? 'EVENT_IS_TOO_SHORT_ERROR' : 'EVENT_IS_TOO_LONG_ERROR' });

export const noFinishedEventToExportNotif = () => ({ type: 'NO_FINISHED_EVENT_TO_EXPORT_ERROR' });

export const startedAtBiggerThanEndedatErrorNotif = () => ({ type: 'STARTED_AT_BIGGER_THAN_ENDED_AT_ERROR' });

export const removedJobsWarningNotif = jobsNumber => ({ type: jobsNumber === 1 ? 'JOB_WAS_REMOVED_WARNING' : 'JOBS_WERE_REMOVED_WARNING' });

export const removedAssigneesWarningNotif = peopleNumber => ({ type: peopleNumber === 1 ? 'ASSIGNEE_WAS_REMOVED_WARNING' : 'ASSIGNEES_WERE_REMOVED_WARNING' });

export const removedFollowersWarningNotif = peopleNumber => ({ type: peopleNumber === 1 ? 'FOLLOWER_WAS_REMOVED_WARNING' : 'FOLLOWERS_WERE_REMOVED_WARNING' });

export const resetRecurrenceWarningNotif = () => ({ type: 'RECURRENCE_WAS_RESET_WARNING' });

export const alertSmsNoNumberWarningNotif = () => ({ type: 'ALERT_SMS_NO_NUMBER_WARNING' });

export const successfulAllEventsUpdateNotif = () => ({ type: 'ALL_EVENTS_WERE_UPDATED_SUCCESS' });

export const partialSuccessfulEventsUpdateNotif = () => ({ type: 'SOME_EVENTS_WERENT_UPDATED_WARNING' });

export const failedAllEventsUpdateNotif = () => ({ type: 'NO_EVENTS_UPDATED_ERROR' });

export const successfulAllRoundsArchiveNotif = () => ({ type: 'ALL_ROUNDS_WERE_ARCHIVED_SUCCESS' });

export const partialSuccessfulRoundsArchiveNotif = () => ({ type: 'SOME_ROUNDS_WERENT_ARCHIVED_WARNING' });

export const failedAllRoundsArchiveNotif = () => ({ type: 'NO_ROUNDS_ARCHIVED_ERROR' });
import React from "react";

const SvgAppercu = props  => (
  <svg viewBox="0 0 121.27 145.17" width="1em" height="1em" {...props}>
    <g data-name="Layer 2">
      <g data-name="Capa 1">
        <path d="M5.67 145.17A5.67 5.67 0 010 139.5V5.67a5.67 5.67 0 0111.34 0V139.5a5.67 5.67 0 01-5.67 5.67z" />
        <path d="M115.6 145H5.67a5.67 5.67 0 110-11.33H115.6a5.67 5.67 0 110 11.33z" />
        <path d="M5.67 135.89a5.67 5.67 0 01-2.42-10.8l81.46-38.44L100 10.14a5.67 5.67 0 0111.12 2.22L94.71 94.47 8.09 135.34a5.62 5.62 0 01-2.42.55z" />
        <circle cx={90.06} cy={88.7} r={13.43} />
        <circle cx={105.51} cy={14.27} r={13.43} />
      </g>
    </g>
  </svg>
);

export default SvgAppercu;

import React from 'react';
import Cloud1 from './assets/big-cloud.svg';
import Cloud2 from './assets/little-cloud.svg';

const Cloud = props => (
    <div className={props.class} style={{ top: props.top, left: props.left }}>
        <div className={'icon-wrapper'}>
            {props.src}
        </div>
    </div>
);

const CloudIllustrations = () => (
    <div className="cloud-illustration-container step0">
        <div
            className="cloud-illustration step0"
            style={{ opacity: 1 }}
        >
            <Cloud
                top="7%"
                left="38%"
                src={<Cloud1 />}
                class="cloud1"
            />
            <Cloud
                top="18%"
                left="72%"
                src={<Cloud2 />}
                class="cloud cloud2"
            />
            <Cloud
                top="6%"
                left="84%"
                src={<Cloud2 />}
                class="cloud cloud3"
            />
        </div>
    </div>
);

export default CloudIllustrations;


import IconButton from '@material-ui/core/IconButton';
import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import { useComponentsPool } from '../../../../../ComponentsPool';
import SendMailNotificationPopup from './SendMailNotificationPopup';


const SendMailNotificationBtn = () => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const [anchorEl, setAnchorEl] = useState(null);
    const useStyles = makeStyles(() => ({
        tooltip: {
            '&::before': {
                marginLeft: '35px'
            }
        }
    }));
    const classes = useStyles();

    const onClick = e => {
        setAnchorEl(e.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div className="send-mail-notification-btn">
                <Tooltip
                    placement={'top'}
                    arrow={false}
                    classes={{tooltip: classes.tooltip}}
                    title={t('notifications:share_calendar')}
                >
                    <IconButton onClick={onClick}>
                        <Component componentName={'Icon'} type={'share'} size="sm" />
                    </IconButton>
                </Tooltip>
            </div>
            <SendMailNotificationPopup anchorEl={anchorEl} onClose={onClose} />
        </>
    );
};

export default SendMailNotificationBtn;

import Chip from '@material-ui/core/Chip/Chip';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './InstanceStatusChip.scss';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getRoundInstanceStatus } from '../../../helper';

const InstanceStatusChip = ({ instance, withTooltip }) => {
    const [t] = useTranslation();
    const label = t(`rounds:${getRoundInstanceStatus(instance.status, instance.validated_steps_count)}`);
    const useStyles = makeStyles(() => ({
        tooltip: {
            marginLeft: '-85px !important'
        }
    }));
    const classes = useStyles();

    return (
        <Tooltip
            arrow={false}
            classes={{ tooltip: classes.tooltip }}
            title={withTooltip ? t(t(`rounds:${getRoundInstanceStatus(instance.status, instance.validated_steps_count)}_explanations`)) : ''}
            placement={'top'}
        >
            <Chip label={label} className={classnames('instance-status-chip', getRoundInstanceStatus(instance.status, instance.validated_steps_count))} />
        </Tooltip>
    );
};

export default InstanceStatusChip;

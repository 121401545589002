// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useComponentsPool } from '../../../ComponentsPool';

const GraphNoData = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();

    return (
        <div className={props.noDataGraph ? "nodata-wrapper-adjust-size" : "nodata-wrapper"}>
            <div className="nodata-container">
                <div className="nodata-image">
                    <Component componentName={'Icon'} type={'no_data'} />
                </div>
                <div className="nodata-text">
                    {props.text || t('dashboards:unavailable-data').charAt(0).toUpperCase()
                    + t('dashboards:unavailable-data').substring(1)}
                </div>
            </div>
        </div>
    );
};

export default GraphNoData;

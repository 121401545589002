// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { useComponentsPool } from '../../ComponentsPool';
import { addGroupEditableField} from './actions';
import store from '../../store';

// Context
const dispatch = store.dispatch;

const Groups = () => {
    const { Component } = useComponentsPool();
    return <Component componentName={'GroupsIndex'} />;
};

export default Groups;

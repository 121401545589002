// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cloneDeep, find } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip/index';
import { isMobile } from 'react-device-detect';

// Context
import Checkbox from '@material-ui/core/Checkbox';
// Helper
import { useFootBar } from '../../../footbar/FootBarContext';
import { enableDeleteOnTicket } from '../../helper';

export default props => {
    const [t] = useTranslation();
    const footbar = useFootBar();
    const [isSelected, setIsSelected] = useState(false);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const currentUser = useSelector(state => state.users.currentUser);

    useEffect(() => {
        setIsSelected(find(cloneDeep(footbar.getList()), obj => obj.id === props.ticket.id));
    }, [footbar.getList()]);

    const enableDelete = !isMobile && enableDeleteOnTicket(isMobile, currentUser, props.ticket, workspaceSettings);

    return (
        <div className={'upper-part'}>
            <div className={'upper-part-key'}>{`#${props.ticket.key}`}</div>
            { enableDelete && (
                <Tooltip
                    className={'tooltip'}
                    classes={{ tooltip: 'table-tooltip' }}
                    placement={'top'}
                    title={!footbar.selected.includes(props.ticket.id) ? t('tickets:select_ticket') : t('tickets:unselect_ticket')}
                >
                    <Checkbox
                        checked={isSelected}
                        onChange={props.toggleSelection}
                    />
                </Tooltip>
            )}
        </div>
    );
};

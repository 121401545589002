// Libs
import React from 'react';
import PropTypes from 'prop-types';
// SCSS
import '../styles/_loader_top.scss';

const LoaderBarTop = props => props.isLoading && <div className={props.className} />;

LoaderBarTop.defaultProps = {
    className: 'top-loader',
    isLoading: false
};

LoaderBarTop.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool
};

export default LoaderBarTop;

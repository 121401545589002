// Library
import React from 'react';
import { useSelector } from 'react-redux';

import PopupManager from '../../modal/index';
import ActionButton from '../../actionButton';

const OffgridSection = () => {
    const navbar = useSelector(state => state.navbar);
    return (
        <div className="offgrid-section">
            {!navbar.isVisible && <ActionButton />}
            <PopupManager />
        </div>
    );
};

export default OffgridSection;


// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
// Context
import { useFootBar } from '../../../footbar/FootBarContext';
import { useComponentsPool } from '../../../../ComponentsPool';

const DeviceHardwareAssignmentEditableField = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const footBar = useFootBar();

    const renderValue = () => {
        if (footBar.getEditionMode()) {
            return (
                <Component
                    componentName={'DeviceHardwareAssignment'}
                    noPlaceholder
                    onChangeState={value => props.callback('hardware_ids', value)}
                    value={props.device.hardware_ids}
                />
            );
        }
        return <p className={`info-value ${props.value ? '' : 'undefined'}`}>{props.value ? `${props.value}` : t('common:undefined')}</p>;
    };

    return (
        <div className={classnames('info', props.multiline ? 'multiline-input' : '')}>
            <span className={'info-title'}>{props.title}</span>
            { renderValue() }
        </div>
    );
};

export default DeviceHardwareAssignmentEditableField;

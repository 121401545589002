// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { uniq, concat } from 'lodash';
import { useTranslation } from 'react-i18next';

// Helper
import { getDevicesOfGroup } from '../../devices/helper';
import { useComponentsPool } from '../../../ComponentsPool';

const DeviceAndGroupSelectorView = props => {
    const groupList = useSelector(store => store.groups.list);
    const deviceList = useSelector(store => store.devices.list);
    const [t] = useTranslation();
    const { Component } = useComponentsPool();

    let allSelectedDevices = [];
    props.selectedGroups?.forEach(currentGroup => {
        const devicesIds = getDevicesOfGroup(deviceList, groupList, currentGroup);
        allSelectedDevices = uniq(concat(allSelectedDevices, devicesIds));
    });
    const deviceIds = uniq(concat(props.selectedDevices, allSelectedDevices));

    return (
        <div className="device-group-selector-view">
            <div>
                <Component componentName={'Icon'} type={'device'} />
                {t('graphs:device_number', { count: deviceIds.length })}
            </div>
            <div>
                <Component componentName={'Icon'} type={'groups'} />
                {t('graphs:group_number', { count: props.selectedGroups.length })}
            </div>
        </div>
    );
};

export default DeviceAndGroupSelectorView;


// Libs
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';

const DeviceMeasureEditableField = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const footBar = useFootBar();
    const editionMode = footBar.getEditionMode();

    const defaultDisabled = false;
    const defaultMin = 0;
    const defaultMax = 1000;
    const defaultStep = 1;
    const defaultName = '';

    const getIconComponent = () => {
        const iconComponent = (
            <div className="measure">
                <div className="form-icon">
                    <div className="icon">
                        { props.icon }
                    </div>
                    <span className="label">
                        { props.title }
                    </span>
                </div>
            </div>
        );
        if (props.tooltipText) {
            return <Tooltip placement={'top'} title={props.tooltipText}>{iconComponent}</Tooltip>;
        }
        return iconComponent;
    };

    const onBlur = () => {
        if (props.onBlur) {
            props.onBlur();
        }
    };

    const editableFields = [{
        disabled: props.disabledLeft ? props.disabledLeft : defaultDisabled,
        name: props.nameLeft ? props.nameLeft : defaultName,
        title: '',
        label: t('devices:measure_min'),
        renderValue: 'SimpleString',
        editionComponent: 'Input',
        value: props.valueLeft ? props.valueLeft : '',
        onChange: props.onChangeLeft,
        onlyNumber: true,
        props: {
            type: 'number',
            step: props.step ? props.step : defaultStep,
            label: '',
            min: props.minLeft ? props.minLeft : defaultMin,
            max: props.maxLeft ? props.maxLeft : defaultMax,
            value: props.valueLeft || props.valueLeft === 0 ? props.valueLeft : '',
            inputProps: {
                min: props.minLeft ? props.minLeft : defaultMin,
                max: props.maxLeft ? props.maxLeft : defaultMax,
                step: props.step ? props.step : defaultStep
            },
            isNullValue: true
        }
    }, {
        disabled: props.disabledRight ? props.disabledRight : defaultDisabled,
        name: props.nameRight ? props.nameRight : defaultName,
        title: '',
        label: t('devices:measure_max'),
        renderValue: 'SimpleString',
        editionComponent: 'Input',
        value: props.valueRight ? props.valueRight : '',
        onChange: props.onChangeRight,
        onlyNumber: true,
        props: {
            type: 'number',
            step: props.step ? props.step : defaultStep,
            label: '',
            min: props.minRight ? props.minRight : defaultMin,
            max: props.maxRight ? props.maxRight : defaultMax,
            value: props.valueRight || props.valueRight === 0 ? props.valueRight : '',
            inputProps: {
                min: props.minRight ? props.minRight : defaultMin,
                max: props.maxRight ? props.maxRight : defaultMax,
                step: props.step ? props.step : defaultStep
            },
            isNullValue: true
        }
    }];

    return (
        <div className={classNames(
            'config_measure',
            props.hour_start || props.hour_end ? 'conditions-with-hours' : ''
        )}
        >
            {props.hour_start || props.hour_end
                ? (
                    <div className={'conditions-with-hours'}>
                        <span>{t('devices:hour_start')}</span>
                        <Component
                            componentName={'EditableField'}
                            editionComponent={'Input'}
                            renderValue={'SimpleHour'}
                            title={''}
                            required
                            value={props.hour_start}
                            props={{
                                onChange: value => props.onChangeStartHour(value),
                                value: props.hour_start,
                                type: 'time'
                            }}
                            onBlur={() => onBlur()}
                        />
                        <span>{t('devices:hour_end')}</span>
                        <Component
                            componentName={'EditableField'}
                            editionComponent={'Input'}
                            renderValue={'SimpleHour'}
                            title={''}
                            required
                            value={props.hour_end}
                            props={{
                                onChange: value => props.onChangeEndHour(value),
                                value: props.hour_end,
                                type: 'time'
                            }}
                            onBlur={() => onBlur()}
                        />
                    </div>
                )
                : getIconComponent()
            }
            <div className={'content'}>
                <div className="vert_list2">
                    {
                        (props.hour_start || props.hour_end) && getIconComponent()
                    }
                    { editableFields.map((field, idx) => (
                        <div key={`div${field.title}${idx}`} className="value_input_wrapper">
                            <Component
                                key={`${field.title}${idx}`}
                                title={t(field.title)}
                                componentName={'EditableField'}
                                renderValue={field.renderValue !== '' ? field.renderValue : ''}
                                editionComponent={field.editionComponent}
                                onChange={field.onChange}
                                props={field.props}
                                disabledInput={field.disabledInput || field.disabled}
                                value={field.props.value !== '' ? field.props.value : ''}
                                onBlur={() => onBlur()}
                            />
                            <div className="value_text">{ field.label }</div>
                        </div>
                    ))}
                    { editionMode && props.enabledTrash ? (
                        <Button className={'trash-button'} onClick={props.onDelete} color={'primary'}>
                            <Component componentName={'Icon'} type={'trash'} />
                        </Button>
                    ) : <></>}
                </div>
            </div>
        </div>
    );
};

export default DeviceMeasureEditableField;

import React from 'react';

export default <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
>
    <g>
        <g>
            <path d="M504.498,375.114h-8.575c-1.17-78.127-41.145-150.323-107.439-193.666c-3.467-2.267-8.116-1.294-10.382,2.173     c-2.266,3.467-1.294,8.115,2.173,10.382c62.042,40.563,99.478,108.061,100.646,181.111h-75.815     c-0.878-36.767-15.486-71.498-41.355-98.137c-2.884-2.971-7.632-3.043-10.605-0.156c-2.971,2.885-3.041,7.634-0.156,10.605     c23.136,23.825,36.236,54.844,37.112,87.688H303.32l59.702-156.994c4.316-11.347-0.76-23.856-11.808-29.095     c-10.962-5.199-23.955-1.343-30.218,8.974L226.56,353.57c-0.104,0.172-0.202,0.347-0.291,0.524     c-5.575,5.674-9.501,12.935-11.015,21.021h-93.358c1.063-40.405,20.359-77.38,53.313-101.885     c3.323-2.472,4.015-7.17,1.543-10.494c-2.473-3.323-7.17-4.014-10.494-1.543c-18.04,13.416-32.987,30.97-43.221,50.767     c-10.203,19.731-15.627,40.96-16.146,63.155H31.077c1.164-72.538,38.194-139.768,99.558-180.395     c3.453-2.288,4.399-6.941,2.113-10.395c-2.288-3.454-6.941-4.396-10.395-2.113C56.78,225.626,17.238,297.529,16.075,375.114H7.5     c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h207.771c3.593,18.978,20.49,33.386,40.729,33.386s37.136-14.408,40.729-33.386     H504.5c4.143,0,7.5-3.357,7.5-7.5C512,378.472,508.643,375.114,504.498,375.114z M256,408.5     c-14.577,0-26.435-11.631-26.435-25.928c0-14.296,11.858-25.927,26.435-25.927c14.577,0,26.435,11.631,26.435,25.927     C282.435,396.869,270.577,408.5,256,408.5z M292.081,362.468c-7.122-12.422-20.627-20.822-36.082-20.822     c-1.63,0-3.24,0.093-4.822,0.275l82.641-136.141c2.222-3.659,7.037-5.069,10.968-3.204c3.911,1.856,5.724,6.244,4.216,10.21     L292.081,362.468z" data-original="#000000" className="active-path" />
            <path d="M213.361,106.68c14.006-2.11,28.352-3.18,42.639-3.18c41.892,0,82.134,8.881,119.663,26.401l-21.708,42.768     c-1.875,3.693-0.4,8.208,3.293,10.082c1.088,0.552,2.247,0.814,3.389,0.814c2.734,0,5.371-1.502,6.693-4.107l23.134-45.576     c1.463-2.881,1.697-6.153,0.66-9.213c-1.045-3.083-3.245-5.561-6.195-6.977C344.568,98.321,301.19,88.5,256,88.5     c-15.033,0-30.131,1.126-44.873,3.348c-4.096,0.617-6.916,4.437-6.299,8.533C205.445,104.478,209.28,107.305,213.361,106.68z" data-original="#000000" className="active-path" />
            <path d="M189.69,268.151c2.12,4.177,6.313,6.587,10.708,6.587c1.718,0,3.466-0.368,5.133-1.143     c15.838-7.355,32.818-11.085,50.469-11.085c2.224,0,4.467,0.061,6.669,0.182c4.108,0.204,7.673-2.943,7.898-7.08     c0.226-4.136-2.944-7.672-7.08-7.898c-2.474-0.135-4.992-0.203-7.487-0.203c-18.899,0-37.126,3.808-54.219,11.32l-65.443-128.929     c15.472-7.218,31.674-13.044,48.205-17.333c4.009-1.04,6.416-5.134,5.376-9.144c-1.041-4.01-5.131-6.419-9.144-5.376     c-18.458,4.789-36.526,11.397-53.704,19.643c-2.95,1.416-5.15,3.894-6.195,6.977c-1.037,3.06-0.803,6.332,0.66,9.213     L189.69,268.151z" data-original="#000000" className="active-path" />
        </g>
    </g>
               </svg>;

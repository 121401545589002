import axios from 'axios';

export const addFilter = filter => ({ type: 'ADD_FILTER', payload: filter });

export const removeFilter = id => ({ type: 'REMOVE_FILTER', payload: id });

export const resetFilters = () => ({ type: 'RESET_FILTERS' });

export const setFocusedNavButton = route => ({ type: 'SET_FOCUSED_NAV_BUTTON', payload: route });

export const setWorkspaceSettingsPanel = open => ({ type: 'SET_WORKSPACE_SETTINGS_PANEL', payload: open });

export const getNotifications = () => ({
    type: 'GET_NOTIFICATIONS',
    payload: axios.get('/notifications/')
        .then(res => res.data)
});

export const updateNotification = (notifId, data) => ({
    type: 'UPDATE_NOTIFICATION',
    payload: axios.patch(`/notifications/${notifId}/`, data)
        .then(res => res.data)
});

export const setDefaultRoute = route => ({ type: 'SET_DEFAULT_ROUTE', payload: route });

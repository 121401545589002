// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupBy, forEach } from 'lodash';
import { useTranslation } from 'react-i18next';
// Components
import Chip from '@material-ui/core/Chip';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import { removeFilter, setFocusedNavButton } from '../actions';
import { getTraductionOfGroup } from '../../groups/helper';

const Filters = props => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const filters = useSelector(state => state.navbar.filters);
    const [filtersGrouped, setFiltersGrouped] = useState([]);
    const { Component } = useComponentsPool();

    const deleteChip = filter => {
        if (filter?.type === 'link') props.goBack();
        dispatch(removeFilter(filter.id));
        setTimeout(() => {
            dispatch(setFocusedNavButton(props.pathname));
        }, 50);
    };

    useEffect(() => {
        const filtersArray = [];
        forEach(groupBy(filters, 'type'), value => {
            filtersArray.push(value);
        });
        setFiltersGrouped(filtersArray);
    }, [filters]);

    return (
        (!props.isCustomSearchBar ? true : filtersGrouped.length > 0) && (
            <div className={'filters-container'}>
                {filtersGrouped.map(type => (type.length > 2 ? (
                    <div className={'filter-wrapper'}>
                        <Chip
                            variant={'outlined'}
                            component={'div'}
                            icon={<Component componentName={'Icon'} type={type[0]?.type === 'link' ? 'group' : type[0]?.type}/>}
                            label={t(`${type[0].type}s:type_selection`, {count: type.length})}
                            deleteIcon={<div><Component componentName={'Icon'} type={'close'}/></div>}
                            onDelete={() => type.forEach(filter => { deleteChip(filter); })}
                        />
                    </div>
                ) : type.map(filter => (
                    <div className={'filter-wrapper'} key={filter.id}>
                        <Chip
                            variant={'outlined'}
                            component={'div'}
                            icon={<Component componentName={'Icon'} type={
                                filter.type === 'link'
                                    ? 'group'
                                    : filter.groupType
                                        ? filter.groupType === 'SKILL'
                                            ? filter.name
                                            : filter.groupType
                                        : filter.type
                            }/>}
                            label={filter.type === 'link' || filter.type === 'group'
                                ? getTraductionOfGroup(filter.name, t)
                                : t(filter.name)
                            }
                            deleteIcon={<div><Component componentName={'Icon'} type={'close'}/></div>}
                            onDelete={() => deleteChip(filter)}
                        />
                    </div>
                ))))}
            </div>
        )
    );
};

export default Filters;

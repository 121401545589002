// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import { Tooltip } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import Switch from '@material-ui/core/Switch';
import { cloneDeep, isEmpty } from 'lodash';

// Context
import { useFootBar } from '../../../footbar/FootBarContext';
import { useComponentsPool } from '../../../../ComponentsPool';
import {
    getAvailableAlertsBasedOnRole,
    isUserWithoutMail
} from '../../helper';
import { askPermission } from '../../../../index';
import {
    addUserNotificationRegistration,
    deleteUserNotificationRegistration,
    getUserNotificationRegistration
} from '../../actions';
import createLoadingSelector from '../../../layout/actions';
import { isPassageDeviceType } from '../../../devices/helper';
import { alertSmsNoNumberWarningNotif } from '../../../notification/actions';
import { useIsFirstRender } from '../../../../utils/hook/useIsFirstRender';

const loadingSelector = createLoadingSelector([
    'ADD_USER_NOTIFICATION_REGISTRATION',
    'GET_USER_NOTIFICATION_REGISTRATION',
    'DELETE_USER_NOTIFICATION_REGISTRATION'
]);
const UserAlerts = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const footBar = useFootBar();
    const editionMode = footBar.getEditionMode();
    const user = props.user;
    const dispatch = useDispatch();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const userRole = props.currentRoleName ? props.currentRoleName : user.workspace_role?.workspace_role_name;
    const currentUser = useSelector(state => state.users.currentUser);
    const hasNoMailUser = isUserWithoutMail(user);
    const contractorMode = useSelector(state => state.workspace.settings.contractor_tickets_mode);
    const userTicketsMode = useSelector(state => state.workspace.settings.user_tickets_mode);
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const deviceList = useSelector(state => state.devices.list);
    const [notifToken, setNotifToken] = useState(null);
    const isFirstRender = useIsFirstRender();
    const [enablePushNotif, setEnablePushNotif] = useState(false);
    const notificationsPushExplanations = deviceList.find(device => isPassageDeviceType(device))
        ? t('subscriptions:push_notification_current_user_explanations_with_rounds')
        : t('subscriptions:push_notification_current_user_explanations');
    const userCanHaveSmsAlerts = props.userDetailPopupFormState?.telephone && props.userDetailPopupFormState?.telephone !== 'error_field';
    // SMS alerts are hidden in the profile popup
    const currentUserCanSeeSmsAlerts = currentUser.workspace_role?.workspace_role_name.toLowerCase() === 'owner' && !props.isSettingAlertsForHimself;

    useEffect(() => {
        const currentUserRole = currentUser.workspace_role?.workspace_role_name.toLowerCase();
        const userHasSmsAlerts = user.subscriptions.some(subscription => subscription.strategies.includes('sms'));
        /* IF It is not profile view,
        * User's phone number is removed during the editing process,
        * ONLY OWNER and ADMIN can see the message
        * User should not have any SMS alerts
        * */
        if (
            !props.isSettingAlertsForHimself &&
            (currentUserRole === 'owner' || currentUserRole === 'admin') &&
            userHasSmsAlerts &&
            (
                (user?.telephone && !props.userDetailPopupFormState?.telephone) ||
                (!user?.telephone && !props.userDetailPopupFormState?.telephone && !isFirstRender)
            )
        ) {
            dispatch(alertSmsNoNumberWarningNotif());
            // remove SMS alerts from user's subscriptions list
            const { subscriptions = [] } = props.userDetailPopupFormState ?? {};
            const newAlerts = subscriptions.filter((subscription) => !subscription.strategies.includes('sms'));
            props.updateState(newAlerts);
        }
    }, [props.userDetailPopupFormState?.telephone]);

    useEffect(async () => {
        // If on user profile edition popup
        if (props.isSettingAlertsForHimself) {
            if ('Notification' in window) {
                const token = await navigator.serviceWorker.ready
                    .then(() => askPermission())
                    .catch(() => { setNotifToken(null); });
                if (token) {
                    setNotifToken(token);
                    // Get current status of user notification (activated or deactivated ?)
                    dispatch(getUserNotificationRegistration(token)).then(result => {
                        if (result.value?.length > 0) {
                            setEnablePushNotif(true);
                        }
                    });
                }
            }
        }
    }, []);

    const passageSensorExists = deviceList.some(device => isPassageDeviceType(device));

    // A list of available alerts for displayed user in the user detail popup
    const userAvailableAlerts = getAvailableAlertsBasedOnRole(
        userRole,
        workspaceSettings,
        userTicketsMode,
        contractorMode,
        props.isSettingAlertsForHimself,
        passageSensorExists
    );

    const hasSubscription = (subscriptionStrategy, subscriptionType) => {
        // userDetailPopupFormState for user detail view & props.user for profile view
        if (
            !isEmpty(props.userDetailPopupFormState?.subscriptions)
            || !isEmpty(props.userProfilePopupFormState?.subscriptions)
        ) {
            const { subscriptions } = props.userDetailPopupFormState ?? props.userProfilePopupFormState;
            for (const subscription of subscriptions) {
                if (
                    subscription.strategies.includes(subscriptionStrategy) &&
                    subscription.types.includes(subscriptionType)
                ) {
                    return true;
                }
            }
        }
        return false;
    };

    const handleAlertCheckbox = (alertStrategy, alertType) => {
        if (editionMode || props.dontHaveEditionMode) {
            let newAlerts = [];
            const { subscriptions = [] } = props.userDetailPopupFormState ?? props.userProfilePopupFormState;
            const userSubscriptions = isEmpty(subscriptions) ? [] : cloneDeep(subscriptions);

            const FoundAlertStrategy = userSubscriptions?.find(subscription =>
                subscription.strategies.includes(alertStrategy)
            );
            if (!FoundAlertStrategy) {
                newAlerts = [...userSubscriptions, { strategies: [alertStrategy], types: [alertType] }];
            }
            if (FoundAlertStrategy) {
                userSubscriptions?.forEach(subscription => {
                    const copy_subscription = cloneDeep(subscription);
                    if (!subscription.strategies.includes(alertStrategy)) {
                        newAlerts = [...newAlerts, copy_subscription];
                        return;
                    }
                    if (subscription.types.includes(alertType) && subscription.types.length === 1) {
                        return;
                    }
                    if (subscription.types.includes(alertType)) {
                        const alertTypeIndex = copy_subscription.types.indexOf(alertType);
                        copy_subscription.types.splice(alertTypeIndex, 1);
                        newAlerts = [...newAlerts, copy_subscription];
                    } else {
                        copy_subscription.types = [...copy_subscription.types, alertType];
                        newAlerts = [...newAlerts, copy_subscription];
                    }
                });
            }
            props.updateState(newAlerts);
        }
    }

    return (
        <div className="choice-alerts">
            {/* Email alerts column */}
            {!hasNoMailUser && (
                <div className="alert-column">
                    <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
                    <div className="alerts-explanation">
                        {t(
                            `${
                                props.isSettingAlertsForHimself
                                    ? 'subscriptions:email_subscriptions_current_user'
                                    : 'common:email_subscriptions'
                            }`
                        )}
                    </div>
                    {userAvailableAlerts?.email.map((subscription) => (
                        <Tooltip
                            key={`tooltip_${subscription.id}`}
                            classes={{ tooltip: 'table-tooltip' }}
                            placement={'top'}
                            title={
                                <Component
                                    componentName={'Description'}
                                    className="action_button"
                                    content={t(subscription.explanations)}
                                />
                            }
                        >
                            <div
                                className={classNames({
                                    'choice-alert': true,
                                    'choice-alert-selected': hasSubscription('email', subscription.id),
                                    'choice-alert-disabled': props.dontHaveEditionMode ? false : !editionMode
                                })}
                                role={'button'}
                                tabIndex={0}
                                onClick={() => handleAlertCheckbox('email', subscription.id)}
                            >
                                {(editionMode || props.dontHaveEditionMode) && (
                                    <div className="form-checkbox">
                                        <Checkbox checked={hasSubscription('email', subscription.id)} />
                                    </div>
                                )}
                                <div className="form-text">
                                    <div className="title">
                                        <Component componentName={'Icon'} type={subscription.icon.replace(':', '_')} />
                                        {t(`subscriptions:${subscription.value.replace(':', '_')}`)}
                                    </div>
                                </div>
                            </div>
                        </Tooltip>
                    ))}
                </div>
            )}
            {/* SMS alerts column */}
            { currentUserCanSeeSmsAlerts ? (
                <div className="alert-column">
                    <div className="alerts-explanation">
                        {t(
                            `${
                                props.isSettingAlertsForHimself
                                    ? 'subscriptions:sms_subscriptions_current_user'
                                    : 'common:sms_subscriptions'
                            }`
                        )}
                    </div>

                    { !userCanHaveSmsAlerts ? (
                        <div className="alert-message-box">
                            <p className="alert-message-icon">💡</p>
                            <p className="alert-message-text">{t('subscriptions:sms_alerts_no_number_warning')}</p>
                        </div>
                    ) : null }

                    { userCanHaveSmsAlerts
                        ? userAvailableAlerts?.sms.map(subscription => (
                            <Tooltip
                                key={`tooltip_${subscription.id}`}
                                classes={{ tooltip: 'table-tooltip' }}
                                placement={'top'}
                                title={
                                    <Component
                                        componentName={'Description'}
                                        className="action_button"
                                        content={t(subscription.explanations)}
                                    />
                                }
                            >
                                <div
                                    className={classNames({
                                        'choice-alert': true,
                                        'choice-alert-selected': hasSubscription('sms', subscription.id),
                                        'choice-alert-disabled': props.dontHaveEditionMode ? false : !editionMode
                                    })}
                                    role={'button'}
                                    tabIndex={0}
                                    onClick={() => handleAlertCheckbox('sms', subscription.id)}
                                >
                                    {(editionMode || props.dontHaveEditionMode) && (
                                        <div className="form-checkbox">
                                            <Checkbox checked={hasSubscription('sms', subscription.id)} />
                                        </div>
                                    )}
                                    <div className="form-text">
                                        <div className="title">
                                            <Component
                                                componentName={'Icon'}
                                                type={subscription.icon.replace(':', '_')}
                                            />
                                            {t(`subscriptions:${subscription.value.replace(':', '_')}`)}
                                        </div>
                                    </div>
                                </div>
                            </Tooltip>
                        ))
                        : null
                    }
                </div>
            ) : null }
            {props.isSettingAlertsForHimself && notifToken && (
                <div style={{ marginTop: '40px' }}>
                    <div className={'editable-field-title-container'}>
                        {isMobile ? (
                            <div className="alerts-explanation" style={{ display: 'flex', flexDirection: 'column' }}>
                                {t('subscriptions:push_notifications_current_user')}
                                <span className={'mobile-subtitle'}>{notificationsPushExplanations}</span>
                            </div>
                        ) : (
                            <div className="alerts-explanation" style={{ display: 'flex', flexDirection: 'row' }}>
                                <span className={'info-title'}>
                                    {t('subscriptions:push_notifications_current_user')}
                                </span>
                                <Tooltip
                                    className={'tooltip'}
                                    classes={{ tooltip: 'table-tooltip' }}
                                    placement={'top'}
                                    title={notificationsPushExplanations}
                                >
                                    <div>
                                        <Component componentName={'Icon'} type={'info'} />
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                    <div className={'second-main-color-switch'}>
                        <span>{t('subscriptions:push_notification_current_user_desactivate')}</span>
                        <Switch
                            checked={enablePushNotif}
                            onChange={async () => {
                                setEnablePushNotif(!enablePushNotif);
                            }}
                            onClick={async () => {
                                if (!enablePushNotif) {
                                    dispatch(addUserNotificationRegistration({ token: notifToken }));
                                } else {
                                    dispatch(deleteUserNotificationRegistration(notifToken));
                                }
                            }}
                        />
                        <span>
                            { isMobile
                                ? t('subscriptions:push_notification_current_user_mobile')
                                : t('subscriptions:push_notification_current_user_desktop')
                            }
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserAlerts;

// Lib
import React, {useEffect, useRef, useState} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import Fab from '@material-ui/core/Fab';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
// Context
import classnames from 'classnames';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import Button from '../../layout/components/Button';
import { useComponentsPool } from '../../../ComponentsPool';
import { useModal } from '../../modal/ModalContext';
import useRouter from '../../../utils/hook/useRouter';
import Icon from '../../layout/components/Icon';
import CalendarRecurrence from '../../layout/assets/calendarRecurrence.svg';
import CalendarDates from '../../layout/assets/calendarDates.svg';
import ClipboardSteps from '../../layout/assets/clipboardSteps.svg';
import { createRound } from '../actions';
import { objectHasProperty, useSecondMainColorTooltip } from '../../layout/helper';
import {
    areEqualsRoundsDevices, areEqualsRoundsJobs, areEqualsRoundsRrules, areEqualsRoundsUsers, traduceRRule
} from '../helper';
import { useFootBar } from '../../footbar/FootBarContext';
import createLoadingSelector from '../../layout/actions';
import { resetFilters } from '../../navbar/actions';

const loadingSelector = createLoadingSelector(['CREATE_ROUND', 'CREATE_ROUND_CUSTOM_ERRORS']);

const RoundsCreateEditModal = props => {
    const { t } = useTranslation();
    const { Component } = useComponentsPool();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const dispatch = useDispatch();
    const tooltipStyle = useSecondMainColorTooltip();
    const modal = useModal();
    const { query } = useRouter();
    const devices = useSelector(state => state.devices.list);
    const currentUserRole = useSelector(state => state.users.currentUser.workspace_role?.workspace_role_name);
    // Once at least 30% (0.3) of a section is seen we put it in color in the stepper
    const { ref: refFirstSection, inView: inViewFirstSection } = useInView({ threshold: 0.3 });
    const { ref: refSecondSection, inView: inViewSecondSection } = useInView({ threshold: 0.3 });
    const { ref: refThirdSection, inView: inViewThirdSection } = useInView({ threshold: 0.3 });
    const [areInErrorEventsDates, setAreInErrorEventsDates] = useState(false);
    const footbar = useFootBar();
    const [state, setState] = useState({
        name: props.name || null,
        started_at: moment(props.started_at).set({ s: 0 }), // if props.started_at is undefined it takes the current date
        ended_at: moment(props.ended_at).set({ s: 59 }), // if props.ended_at is undefined it takes the current date
        is_until_end_of_month: props.is_until_end_of_month || false,
        rrule: props.rrule || null,
        devices: props.devices || [],
        followers_ids: props.followers_ids || [],
        invite_followers_ids: props.invite_followers_ids || [],
        assignees_ids: props.assignees_ids || [],
        round_expected_jobs: props.round_expected_jobs || []
    });
    const [sections, setSections] = useState([
        {
            icon: <CalendarDates />,
            title: props.isEditionModal ? t('rounds:modify_events') : t('rounds:plan_event'),
            ref: refFirstSection,
            linkRef: useRef(null),
            isValidSection: props.isEditionModal || false,
            sectionName: 'RoundModalFirstSection'
        },
        {
            icon: <ClipboardSteps />,
            title: props.isEditionModal ? t('rounds:modify_steps') : t('rounds:select_steps'),
            ref: refSecondSection,
            linkRef: useRef(null),
            isValidSection: props.isEditionModal || false,
            sectionName: 'RoundModalSecondSection'
        },
        {
            icon: <CalendarRecurrence />,
            title: props.isEditionModal ? t('rounds:modify_recurrence') : t('rounds:define_recurrence'),
            ref: refThirdSection,
            linkRef: useRef(null),
            isValidSection: props.isEditionModal || false,
            sectionName: 'RoundModalThirdSection'
        }
    ]);
    const [currentlyModifiedSections, setCurrentlyModifiedSections] = useState([]);
    const invalidSections = sections.filter(section => !section.isValidSection);
    const [wasClickedSaveButton, setWasClickedSaveButton] = useState(false);
    const isDisableSave = invalidSections.length !== 0 || wasClickedSaveButton;

    useEffect(() => {
        if (props.isEditionModal) {
            const updatedState = cloneDeep(state);
            const modifiedSections = [];

            // For each section, remove values that are the same as the initial values in order to not allow round edition if none of the fields changed
            // Section 1
            if (isEqual(props.name, updatedState.name)) { delete updatedState.name; }
            if ( // We send the started_at and ended_at values to the back if at least one of the fields was updated, if not we do not send those fields
                // We do not handle those case : disable save if only 'started_at' didn't really change // disable save if only 'ended_at' didn't really change
                moment(props.started_at).format('DD/MM/YYYY HH:mm:ss') === moment(updatedState.started_at).format('DD/MM/YYYY HH:mm:ss')
                && moment(props.ended_at).format('DD/MM/YYYY HH:mm:ss') === moment(updatedState.ended_at).format('DD/MM/YYYY HH:mm:ss')
            ) {
                delete updatedState.started_at;
                delete updatedState.ended_at;
            }
            if (objectHasProperty(updatedState, 'name') || objectHasProperty(updatedState, 'started_at') || objectHasProperty(updatedState, 'ended_at')) {
                modifiedSections.push(0);
            }
            // Section 2
            if (areEqualsRoundsDevices(props.devices, updatedState.devices)) { delete updatedState.devices; }
            if (areEqualsRoundsJobs(props.round_expected_jobs, updatedState.round_expected_jobs)) { delete updatedState.round_expected_jobs; }
            if (objectHasProperty(updatedState, 'devices') || objectHasProperty(updatedState, 'round_expected_jobs')) {
                modifiedSections.push(1);
            }
            // Section 3
            if (isEqual(props.is_until_end_of_month, updatedState.is_until_end_of_month)) { delete updatedState.is_until_end_of_month; }
            if (areEqualsRoundsRrules(props.rrule, updatedState.rrule)) { delete updatedState.rrule; }
            if (areEqualsRoundsUsers(props.followers_ids, updatedState.followers_ids)) { delete updatedState.followers_ids; }
            if (areEqualsRoundsUsers(props.invite_followers_ids, updatedState.invite_followers_ids)) { delete updatedState.invite_followers_ids; }
            if (areEqualsRoundsUsers(props.assignees_ids, updatedState.assignees_ids)) { delete updatedState.assignees_ids; }
            if (objectHasProperty(updatedState, 'is_until_end_of_month') || objectHasProperty(updatedState, 'rrule')
                || objectHasProperty(updatedState, 'followers_ids') || objectHasProperty(updatedState, 'invite_followers_ids')
                || objectHasProperty(updatedState, 'assignees_ids')
            ) {
                modifiedSections.push(2);
            }

            // To display the right icon in the stepper
            setCurrentlyModifiedSections(modifiedSections);

            // Change the foobar params to know if the save, cancel, and close buttons need to be disabled or not
            const isEditedRound = !isEmpty(updatedState);
            footbar.set({ isEdited: isEditedRound, editionObject: isEditedRound ? updatedState : {}, isError: sections.filter(section => !section.isValidSection).length > 0 });
        }
    }, [state, sections]);

    const disableSaveTooltip = () => {
        const sectionsNames = [];
        invalidSections.forEach((section, sectionIdx) => {
            sectionsNames.push(`"${section.title}"`);
            if (sectionIdx !== invalidSections.length - 1) { // If it is not the last section
                sectionsNames.push(invalidSections.length - 2 === sectionIdx ? ` ${t('common:and')} ` : ', ');
            }
        });
        return t('rounds:disabled_round_creation', { sectionsNames: sectionsNames.join('') });
    };
    return (
        <div className={classnames(['round-modal', { 'round-edition-modal': props.isEditionModal }])}>
            <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
            <div className={'round-modal-header'}>
                <Stepper alternativeLabel>
                    {sections.map((section, idx) => (
                        <Step key={section.title}>
                            <StepLabel
                                className={idx === 0 && inViewFirstSection || idx === 1 && inViewSecondSection || idx === 2 && inViewThirdSection ? 'is-currently-viewed-section' : ''}
                                onClick={() => section.linkRef.current?.scrollIntoView({ behavior: 'smooth' })}
                                StepIconComponent={() => (
                                    props.isEditionModal
                                        ? currentlyModifiedSections.includes(idx) ? <Icon type={'faPen'} /> : <Icon type={'check'} />
                                        : section.isValidSection && idx !== 2 ? <Icon type={'check'} /> : <div className={'stepper-circle'} /> // Only the last section cannot be checked
                                )}
                            >
                                {section.title}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {!props.isEditionModal && (
                    <Fab className={'button-close-modal'} onClick={() => modal.close()}>
                        <Component componentName={'Icon'} type={'close'} />
                    </Fab>
                )}
            </div>
            <div className={'round-modal-main-content-wrapper'}>
                {sections.map((section, idx) => (
                    <div ref={section.ref} className={'round-modal-section-wrapper'}>
                        <div ref={section.linkRef} className={'round-modal-section'}>
                            <div className={'round-modal-section-header'}>
                                <div className={'round-modal-section-icon'}>{section.icon}</div>
                                <div className={'round-modal-section-title'}>{section.title}</div>
                            </div>
                            <div className={'round-modal-section-content'}>
                                <Component
                                    componentName={section.sectionName}
                                    isEditionModal={props.isEditionModal}
                                    state={state}
                                    setState={values => {setState(values)}}
                                    areInErrorEventsDates={idx === 2 ? areInErrorEventsDates : null} // Only the last section can access this value
                                    setAreInErrorEventsDates={value => setAreInErrorEventsDates(value)}
                                    setSectionValidation={value => { // We can only update the 'isValidSection' param of a section
                                        const updatedSections = sections.map((item, itemIdx) => (itemIdx === idx ? { ...item, isValidSection: value } : item));
                                        setSections(updatedSections);
                                    }}
                                />
                            </div>
                        </div>
                        {idx !== sections.length - 1 && <div className={'round-modal-section-divider'} />}
                    </div>
                ))}
            </div>
            {!props.isEditionModal && (
                <div className={'round-modal-footer'}>
                    <Tooltip
                        className={'second-main-color-tooltip'}
                        placement={'top'}
                        classes={tooltipStyle}
                        title={isDisableSave && !wasClickedSaveButton ? <Trans>{disableSaveTooltip()}</Trans> : ''}
                    >
                        <div>
                            <Button
                                className={'custom-save-button'}
                                onClick={() => {
                                    // Prevent multiple round creation
                                    setWasClickedSaveButton(true);
                                    dispatch(createRound(state, query?.view, devices, currentUserRole))
                                        .then(res => {
                                            dispatch(resetFilters());
                                            traduceRRule(t, res.value.rrule, res.value.name, res.value.started_at, res.value.ended_at, res.value.is_until_end_of_month, true);
                                            modal.close();
                                        })
                                        .catch(() => { setWasClickedSaveButton(false); });
                                }}
                                text={t('common:save')}
                                disabled={isDisableSave || wasClickedSaveButton}
                            />
                        </div>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};

export default RoundsCreateEditModal;

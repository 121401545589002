// Libs
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Contexts
import { useModal } from '../../modal/ModalContext';
import { useComponentsPool } from '../../../ComponentsPool';
import { useFootBar } from '../../footbar/FootBarContext';
// Actions
import { updateGroupToDevices } from '../actions';
import createLoadingSelector from '../../layout/actions';

const loadingSelector = createLoadingSelector(['ADD_GROUP_TO_DEVICE']);
const AddGroupToDevicePopup = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const modal = useModal();
    const footBar = useFootBar();
    const { Component } = useComponentsPool();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const [devicesList] = useState(modal.getWithFootBar() ? [modal.getItem()] : footBar.getList());
    const [groupIds, setGroupIds] = useState([]);
    const [name] = useState(() => (modal.getWithFootBar() ? modal.getItem('device_name') : footBar.getItem('device_name')));

    const propsHandleSubmit = async () => {
        await dispatch(updateGroupToDevices(footBar.getSelected(), groupIds));
        modal.close();
        footBar.close();
    };

    const head = <span>{t('devices:add_group_to_device', { count: devicesList.length, name })}</span>;

    const content = (
        <div className={'iot-rocket-form'}>
            <Component
                componentName={'GroupSelector'}
                required
                multiple
                showSelectAll
                isSelectedToText
                onChange={ids => setGroupIds(ids)}
            />
        </div>
    );

    return (
        <Component
            componentName={'Modal'}
            head={head}
            content={content}
            loading={isLoading}
            submitButton
            submitButtonLabel={'common:save'}
            onHandleSubmit={propsHandleSubmit}
            onSubmitDisabled={groupIds.length === 0}
        />
    );
};

export default AddGroupToDevicePopup;

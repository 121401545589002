// Libs
import React, { useMemo } from 'react';
// Context
import { useModal } from '../ModalContext';
import { useComponentsPool } from '../../../ComponentsPool';
// SCSS
import '../_debugModal.scss';

// Actions
const JSONStringify = json => {
    let objectJson = json;
    if (typeof objectJson !== 'string') {
        objectJson = JSON.stringify(json, undefined, '\t');
    }
    return objectJson
        .replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, match => match); // eslint-disable-line
};

const DebugModal = () => {
    const modal = useModal();
    const { Component } = useComponentsPool();

    const head = useMemo(() => <span>DEBUG</span>);
    const content = useMemo(() => (
        <div className={'iot-rocket-form'} style={{ overflow: 'auto' }}>
            <pre>{JSONStringify(JSON.stringify(modal.getItem(), undefined, 4))}</pre>
        </div>
    ));

    return (
        <Component
            componentName={'Modal'}
            head={head}
            content={content}
            loading={false}
        />
    );
};

export default DebugModal;

// Libs
import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';

// Context
const SettingsTableHead = props => {
    const { order, orderBy, numSelected } = props;
    const rowCount = props.rows.length;

    const onRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        props.setOrder(isAsc ? 'desc' : 'asc');
        props.setOrderBy(property);
    };

    const onSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = props.rows.map(n => n.id);
            props.setSelected(newSelecteds);
            return;
        }
        props.setSelected([]);
    };

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead sx={{ height: props.customHeight }}>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        style={{ color: props.checkboxColor }}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </TableCell>
                {props.headCells
                    .filter(headCell => !props.columnsToHide.includes(headCell.id))
                    .map(headCell => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.positionToRight ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {/* We can only sort name column */}
                            {headCell?.id?.includes('name')
                                ? (
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                )
                                : headCell.label
                            }
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
};

SettingsTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
    setOrder: PropTypes.func.isRequired,
    setOrderBy: PropTypes.func.isRequired,
    setSelected: PropTypes.func.isRequired
};

export default SettingsTableHead;

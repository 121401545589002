// Libs
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';
// Actions
import { deleteInvitation } from '../../actions';

export default props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const footBar = useFootBar();

    return (
        <Component
            componentName={'FootbarButton'}
            withConfirmationPopover
            displayCondition={props.userWorkspaceRights.includes('UPDATE:User') && props.hasInvitations && !props.hasConfiguredUsers}
            buttonText={props.list.length > 1 ? t('descriptions:remove_invites_from_group') : t('descriptions:remove_invite_from_group')}
            callbackAction={() => {
                dispatch(deleteInvitation({ invite_ids: props.list.map(invite => invite.id) }));
                footBar.close();
            }}
            popoverText={props.list.length > 1 ? t('users:remove_invite_plural') : t('users:remove_invite')}
            buttonIcon={'trash'}
        />
    );
};

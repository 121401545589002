import { Strategy } from 'workbox-strategies';


function checkInternetConnectivity() {
    const timeout = new Promise(
        (_, reject) => setTimeout(() => reject(new Error('Request timed out')), 3000)
    ); // Timeout après 3 secondes

    const fetchRequest = fetch('https://api.merciyanis.com/workspace/settings/', { method: 'HEAD', mode: 'no-cors' })
        .then(() => {
            console.log("then checkInternetConnectivity")
            return true;
        })
        .catch(error => {
            console.log("error checkInternetConnectivity :", error);
            return false;
        });

    return Promise.race([timeout, fetchRequest]);
}

export async function fetchRequestAndPutInCache(handler, request) {
    const networkResponse = await handler.fetch(request);
    // Clone the response because the response is a stream that can only be read once
    const responseToCache = networkResponse.clone();
    // Add a custom header with the current date/time
    const customHeaders = new Headers(responseToCache.headers);
    customHeaders.append('X-Cache-Date', new Date().toISOString());
    const responseWithCustomHeader = new Response(responseToCache.body, {
        status: responseToCache.status,
        statusText: responseToCache.statusText,
        headers: customHeaders
    });
    // Cache modified response
    await handler.cachePut(request, responseWithCustomHeader);
    return networkResponse;
}

function validatePreloadQRCodesCacheFormat(jsonArray) {
    // Vérifier que l'entrée est un tableau
    if (!Array.isArray(jsonArray)) {
        return false;
    }

    // Parcourir chaque objet du tableau pour vérifier la présence des clés requises
    for (const item of jsonArray) {
        // Vérifier que chaque objet a les clés nécessaires
        if (typeof item !== 'object' || item === null
            || !item.hasOwnProperty('hardware_ids')
            || !item.hasOwnProperty('workspace')
            || !item.hasOwnProperty('device_id')) {
            return false;
        }
    }

    // Si toutes les vérifications sont passées, retourner true
    return true;
}


async function constructNewRequestInCacheFromRequest(
    newUrl, newOrigin, newResponseBody,
    originRequest, originResponseBody,
    handler
) {
    const url = new URL(
        newUrl,
        origin
    );
    // Construction nouvel url + clone des attributs de la requête initiale
    const newRequest = new Request(url, {
        ...originRequest
    });
    const customHeaders = new Headers(originRequest.headers);
    customHeaders.append('X-Cache-Date', new Date().toISOString());
    // Mets dans le cache que les requêtes
    const responseWithCustomHeader = new Response(JSON.stringify(newResponseBody), {
        ...originResponseBody,
        headers: customHeaders
    });
    // Put in cache
    await handler.cachePut(newRequest, responseWithCustomHeader);
}

export async function preloadEachQRCodeRequestResponse(requestResponseBody, request, handler, requestResponse) {
    if (requestResponse.status >= 200 && requestResponse.status < 210) {
        for (const device of requestResponseBody) {
            // Pour chaque device contenu dans la réponse (requestResponseBody):
            // On précharge et on met en cache la réponse des 2 requêtes suivantes :
            //      1. /devices/get-device-by-hardware-id/ID_DU_DEVICE
            //      2. /workspace/?hardware_id=ID_DU_DEVICE
            if (device.hardware_ids && device.hardware_ids.length > 0) {
                // Mise en cache requête 1. /devices/get-device-by-hardware-id/ID_DU_DEVICE
                await constructNewRequestInCacheFromRequest(
                    `/devices/get-device-by-hardware-id/${device.hardware_ids[0]}`, // newUrl
                    location.origin, // newOrigin
                    device, // newResponseBody
                    request, // originRequest
                    requestResponseBody, // originResponseBody
                    handler
                );
                // Mise en cache requête 2. /workspace/?hardware_id=ID_DU_DEVICE
                await constructNewRequestInCacheFromRequest(
                    `/workspace/?hardware_id=${device.hardware_ids[0]}`, // newUrl
                    location.origin, // newOrigin
                    { workspace: device.workspace }, // newResponseBody
                    request, // originRequest
                    requestResponseBody, // originResponseBody
                    handler
                );
            }
        }
    }
}
/**
 * Stratégie Custom Workbox pour la gestion des requêtes QRCode.
 *
 * Contexte:
 * Cette stratégie est utilisée uniquement pour l'endpoint qui récupère toutes les informations sur tous les QR Codes en une seule requête.
 *
 * Processus:
 * 1. Vérifier si une réponse pour cette requête existe dans le cache local, avec les conditions suivantes :
 *    - La réponse doit être de moins de 24 heures.
 *    - La réponse doit être au bon format.
 * 2. Si une réponse valide est trouvée dans le cache, elle est utilisée directement.
 * 3. Sinon, effectuer un appel réseau pour obtenir une réponse fraîche (si erreur prendre réponse du cache au bon format ((peu importe si périmé))
 *
 * Gestion de la réponse:
 * - Si la réponse provient du réseau, elle est mise en cache pour une utilisation ultérieure.
 * - Indépendamment de la source de la réponse (cache ou réseau), effectuer les actions suivantes pour chaque QR Code dans la réponse :
 *   a. Construire et stocker dans le cache les requêtes individuelles pour chaque QR Code en utilisant deux formats de requête :
 *      i. /devices/get-device-by-hardware-id/ID_DU_DEVICE
 *      ii. /workspace/?hardware_id=ID_DU_DEVICE
 *
 * Gestion des erreurs:
 * - En cas d'erreur durant le processus de récupération ou de mise en cache, renvoyer un code d'erreur approprié à l'utilisateur.
 */
class QRCodesPreloadCacheStrategy extends Strategy {
    async _handle(request, handler) {
        const option = request.headers.get('X-Option-For-QRCodesPreloadCacheStrategy');

        // 1. On récupère la dernière réponse de la requête mise en cache
        let requestResponseToReturn = null;
        const cacheResponse = await handler.cacheMatch(request);

        // 2. On vérifie si le cache est valide
        // Le cache est valide si :
        //      - il y a une réponse dans le cache
        //      - la réponse a moins de 24h
        //      - la réponse a le format attendu (cf fonction validatePreloadQRCodesCacheFormat)
        let hasCacheResponse = false;
        let cacheWithinValidity = false;
        let isGoodCacheFormatResponse = false;
        let cacheResponseJson = null;
        // Vérification s'il y a une réponse dans le cache
        if (cacheResponse) {
            hasCacheResponse = true;
            const clonedCacheResponse = cacheResponse.clone();

            // Vérification si la réponse a moins de 24h
            const cachedDate = cacheResponse?.headers.get('X-Cache-Date');
            if (cachedDate) {
                const now = new Date();
                const cachedTime = new Date(cachedDate);
                const age = (now - cachedTime) / (1000 * 60 * 60); // Conversion en h
                if (age <= 24) {
                    cacheWithinValidity = true;
                }
            }
            // Vérification si la réponse a le format attendy
            cacheResponseJson = await clonedCacheResponse.json();
            if (validatePreloadQRCodesCacheFormat(cacheResponseJson)) {
                isGoodCacheFormatResponse = true;
            }
        }
        const isValidCacheResponse = hasCacheResponse && cacheWithinValidity && isGoodCacheFormatResponse;

        // 3. Si la réponse du cache n'est pas valide OU si X-Option-For-QRCodesPreloadCacheStrategy vaut NetworkFirst:
        //  => On fait un appel réseau => on utilise la réponse pour construire notre cache par QR Code
        //      si pas de réponse => on utilise le cache s'il est valide à l'exception de la péremption (plus de 24h)
        //      si une réponse => on utilise la réponse directement
        //      sinon => on renvoie une erreur
        let requestResponseBody = null;
        if (isValidCacheResponse && cacheResponseJson && option !== 'NetworkFirst') {
            requestResponseBody = cacheResponseJson;
            requestResponseToReturn = cacheResponse;
        } else {
            // Test de connectivité avant de faire la requête réseau
            const isConnected = await checkInternetConnectivity();

            if (!isConnected && cacheResponseJson && hasCacheResponse) {
                // Si la connectivité est lente ou absente, utiliser immédiatement la réponse du cache
                console.log('Connectivité réseau absente ou lente. Utilisation du cache.');
                requestResponseBody = cacheResponseJson;
                requestResponseToReturn = cacheResponse;
            } else {
                try {
                    requestResponseToReturn = await fetchRequestAndPutInCache(handler, request);
                    const responseClone = requestResponseToReturn.clone();
                    requestResponseBody = await responseClone.json();
                } catch (error) {
                    // si pas de réponse => on utilise le cache s'il est valide à l'exception de la péremption (plus de 24h)
                    if (cacheResponseJson && isGoodCacheFormatResponse && hasCacheResponse) {
                        requestResponseBody = cacheResponseJson;
                        requestResponseToReturn = cacheResponse;
                    } else {
                        return error;
                    }
                }
            }
        }
        if (requestResponseBody) {
            await preloadEachQRCodeRequestResponse(requestResponseBody, request, handler, requestResponseToReturn);
            return requestResponseToReturn;
        }
        return new Response('Not Found', { status: 404 });
    }
}

export { QRCodesPreloadCacheStrategy };

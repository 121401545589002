import get from 'lodash/get';
import moment from 'moment';
import { saveStringToCsv, saveStringToPdf } from '../../../../../utils/fileUtils';
import { isInAreaPassage } from '../../../helper';
import { getDeviceFromId } from '../../../../devices/helper';

const columnSeparator = ';';
const lineSeparator = '\n';
const dateFormat = 'DD/MM/YYYY HH:mm:ss';

const formatDate = (data, path) => {
    const dataStr = get(data, path, '');
    if (dataStr?.length > 0) {
        return moment(dataStr).format(dateFormat);
    }
    return dataStr;
};

const headers = [
    'rounds:round_name',
    'rounds:started_at',
    'rounds:ended_at',
    'rounds:event_type',
    'passages:passage_timestamp',
    'passages:passage_status',
    'common:users',
    'passages:validated_passages_message_file',
    'common:passage_type',
    'devices:device_hardware_id',
    'devices:device_name',
    'devices:device_type',
    'dashboards:csv_header_batiment',
    'devices:device_floor',
    'devices:device_position',
    'devices:comment_sent',
    'export:comment_file'
];

const exportInstancesToFile = (data, fileName, t, devices, currentUser = '', workspace = '') => {
    if (!data) return [];
    let content = '';
    headers.forEach(header => {
        content = content + t(header) + columnSeparator;
    });

    const all = data.reduce((agg, ronde) => {
        ronde.events?.forEach(event => {
            // Skipping comments already related to a passage
            if (get(event, 'event_detail.related_device_message_id', null)) {
                return;
            }
            const columns = [];
            columns.push(ronde.round_name);

            const start = formatDate(ronde, 'round_started_at');
            columns.push(start);

            const end = formatDate(ronde, 'round_ended_at');
            columns.push(end);

            if (event.event_type === 'COMMENT') {
                if (get(event, 'event_detail.device_id', null)) {
                    columns.push(t(`rounds:event_type_${event.event_type}_on_device`));
                } else {
                    columns.push(t(`rounds:event_type_${event.event_type}_on_round`));
                }
            } else if (event.event_type === 'PASSAGE') {
                columns.push(t(`rounds:event_type_${event.event_type}`));
            } else {
                columns.push('');
            }

            const eventDate = formatDate(event, 'event_timestamp');
            columns.push(eventDate);

            const status = get(event, 'event_detail.status', '');
            const device = getDeviceFromId(devices, event.event_detail.device_id);
            const statusText = status === 'OK' && isInAreaPassage(device) ? t('passages:passage_status_OK_IN_AREA') : t(`passages:passage_status_${status}`);
            columns.push(status ? statusText : '');

            const author = get(event, 'event_detail.author_name', get(event, 'event_detail.agent_name',''));
            columns.push(author);

            const jobs = get(event, 'event_detail.job_names', []);
            columns.push(jobs.join(','));

            const jobType = get(event, 'event_detail.passage_type', '');
            columns.push(jobType);

            const idDeviceHardware = get(event, 'event_detail.device_hardware_id', '');
            columns.push(idDeviceHardware);

            const deviceName = get(event, 'event_detail.device_name', '');
            columns.push(deviceName);

            const deviceType = get(event, 'event_detail.device_type', '');
            columns.push(t(`devices:${deviceType}`));

            const buildingName = get(event, 'event_detail.building_name', '');
            columns.push(buildingName);

            const floorName = get(event, 'event_detail.floor_name', '');
            columns.push(floorName);

            const positionName = get(event, 'event_detail.position_name', '');
            columns.push(positionName);

            if (event.event_type === 'PASSAGE') {
                const comment = get(event, 'event_detail.comments[0].text_content', '');
                columns.push(comment);
                const contentUrl = get(event, 'event_detail.comments[0].uploaded_content_url', '');
                columns.push(contentUrl);
            } else {
                const comment = get(event, 'event_detail.comment', '');
                columns.push(comment);
                const contentUrl = get(event, 'event_detail.uploaded_content_url', '');
                columns.push(contentUrl);
            }

            const row = columns.join(columnSeparator);
            agg.push(row);
        });
        return agg;
    }, []);

    all.forEach(row => {
        content = content + lineSeparator;
        content = content + row;
    });

    if (fileName.endsWith('.pdf')) {
        saveStringToPdf(content,
            fileName,
            '',
            '',
            `${t('export:exported_by')} ${currentUser?.first_name} ${currentUser?.last_name} ${t('common:date_the_at', { dayAndMonth: moment().format('DD/MM/YYYY'), hoursAndMinutes: moment().format('HH:mm:ss') }).toLowerCase()}`,
            workspace);

    } else {
        saveStringToCsv(content, fileName);
    }
};

export default exportInstancesToFile;

// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import classNames from 'classnames';
import { getMetabaseUrlFromId, getTicketsDashboards, getTicketsExport } from '../actions';
import { hasTeamsConnexion } from '../../messaging/helper';
import { useComponentsPool } from '../../../ComponentsPool';
import createLoadingSelector from '../../layout/actions';
import store from '../../../store';
import { hasRatingDevice, hasSatisfactionDevice } from '../../devices/helper';
import { notification } from '../../notification/actions';
import { isJSONArray } from '../../layout/helper';

const dispatch = store.dispatch;

const timeOptions = {
    'graphs:one_hour': 60 * 60,
    'graphs:one_day': 60 * 60 * 24,
    'graphs:one_week': 60 * 60 * 24 * 7,
    'graphs:two_week': 60 * 60 * 24 * 14,
    'graphs:one_month': 60 * 60 * 24 * 30,
    'graphs:three_month': 60 * 60 * 24 * 90,
    'graphs:since_beginning': null
};

const loadingSelector = createLoadingSelector(['GET_TICKETS_EXPORT']);
const DashboardsTable = () => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const dashboards = useSelector(state => state.dashboards);
    const filters = useSelector(state => state.navbar.filters);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const messagingUsers = useSelector(state => state.messaging.list);
    const deviceGroups = useSelector(state => state.groups.device_groups);
    const metabaseMapping = useSelector(state => state.workspace.settings.metabase_dashboards_mapping);

    const [selectedMetabaseDashboard, setSelectedMetabaseDashboard] = useState(0);
    const [selectedMetabaseUrl, setSelectedMetabaseUrl] = useState('');
    const [selectedTime, setSelectedTime] = useState('graphs:since_beginning');

    const filterGroup = filtersParam => {
        const newFilters = cloneDeep(filtersParam);
        newFilters?.forEach(paramFilter => {
            dispatch(getTicketsDashboards(timeOptions[paramFilter.name]));
            setSelectedTime(paramFilter.name);
        });

        if (newFilters.length === 0) {
            dispatch(getTicketsDashboards());
            setSelectedTime('graphs:since_beginning');
        }
    };

    useEffect(() => {
        filterGroup(filters);
    }, [filters]);

    const noTicketData = dashboards.ticketsDashboard
        && dashboards.ticketsDashboard.repartition_tickets?.data?.length === 0
        && dashboards.ticketsDashboard.top_skills?.length === 0
        && dashboards.ticketsDashboard.top_buildings?.series?.length === 0
        && dashboards.ticketsDashboard.top_tickets?.length === 0;

    const exportTableToCsvFile = data => {
        const fileName = `export_tickets_${t(selectedTime).normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '').replace(/\s/g, '_')
            .toLowerCase()}.csv`;
        const has_teams_connexion = hasTeamsConnexion(messagingUsers);
        let header = `${t('dashboards:csv_header_key')};${
            t('dashboards:csv_header_title')};${
            t('dashboards:csv_description_field')};${
            t('dashboards:csv_header_skill')};${
            t('dashboards:csv_header_priority')};${
            t('dashboards:csv_header_batiment')};${
            t('dashboards:csv_header_floor')};${
            t('dashboards:csv_header_position')};${
            t('dashboards:csv_header_assigners')};${
            t('dashboards:csv_header_author')};${
            t('dashboards:csv_header_tags')};${
            t('dashboards:csv_header_comments')};${
            t('dashboards:csv_header_created_date')};${
            t('dashboards:csv_header_resolution_date')};${
            t('dashboards:csv_header_resolution_time')};${
            t('dashboards:csv_header_taking_into_account_time')}`;

        if (has_teams_connexion) {
            header = `${header};${
                t('dashboards:csv_header_quality_rating_via_teams')};${
                t('dashboards:csv_header_resolution_time_rating_via_teams')};${
                t('dashboards:csv_header_communication_rating_via_teams')}`;
        }

        header = `${header};${
            t('dashboards:csv_header_counter')}`;

        const csvFile = `${header}\n${data}`;
        const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) { // feature detection
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    const getSatisfactionGraphTitle = () => (
        <span className={'top-graph-title'}>
            {t('dashboards:satisfaction_graph_title')}
        </span>
    );

    const getSatisfactionGraph = () => {
        if (!hasSatisfactionDevice(deviceGroups)) {
            return <></>;
        }
        const satisfactionData = dashboards.ticketsDashboard.groups_satisfaction
            && Object.values(dashboards.ticketsDashboard.groups_satisfaction).find(e => e > 0)
            && dashboards.ticketsDashboard.groups_satisfaction;
        if (satisfactionData) {
            return (
                <div className={'tickets-dashboard-row satisfaction-row graph-container'}>
                    {getSatisfactionGraphTitle()}
                    <Component
                        componentName={'SatisfactionGraph'}
                        graphData={satisfactionData}
                    />
                </div>
            );
        }
        return (
            <div className={'tickets-dashboard-row empty-satisfaction-row'}>
                {getSatisfactionGraphTitle()}
                <Component componentName={'NoData'} textOnly view={'dashboard'} />
            </div>
        );
    };

    const getRatingGraphTitle = () => <span className={'top-graph-title'}>{t('dashboards:rating_graph_title')}</span>;
    const getRatingGraph = () => {
        if (!hasRatingDevice(deviceGroups)) {
            return <></>;
        }
        const ratingData = dashboards.ticketsDashboard.groups_rating
            && Object.values(dashboards.ticketsDashboard.groups_rating).find(e => e > 0)
            && dashboards.ticketsDashboard.groups_rating;
        if (ratingData) {
            return (
                <div className={'tickets-dashboard-row satisfaction-row graph-container'}>
                    {getRatingGraphTitle()}
                    <Component
                        componentName={'RatingGraph'}
                        graphData={ratingData}
                    />
                </div>
            );
        }
        return (
            <div className={'tickets-dashboard-row empty-satisfaction-row'}>
                {getRatingGraphTitle()}
                <Component componentName={'NoData'} textOnly view={'dashboard'} />
            </div>
        );
    };

    const getTicketsGraph = () => {
        if (noTicketData) return <></>;
        return (
            <>
                <div className={'tickets-dashboard-row middle-row'}>
                    <div className={'tickets-tops'}>
                        <Component
                            componentName={'TopGraph'}
                            label={'top_tickets'}
                            top={dashboards.ticketsDashboard.top_tickets}
                        />
                        <div className={'top-skills-buildings'}>
                            <div className={'top-skills'}>
                                <Component
                                    componentName={'GaugeGraph'}
                                    label={'top_skills'}
                                    data={dashboards.ticketsDashboard.top_skills}
                                />
                            </div>
                            <div className={'top-buildings'}>
                                <Component
                                    componentName={'GaugeGraph'}
                                    label={'top_buildings'}
                                    data={dashboards.ticketsDashboard.top_buildings}
                                    isDrillDown
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'tickets-dashboard-row lower-row'}>
                    <Component
                        componentName={'GaugeGraph'}
                        label={'repartition_tickets'}
                        from_date={dashboards.ticketsDashboard.repartition_tickets.from_date}
                        period_name={dashboards.ticketsDashboard.repartition_tickets.period_name}
                        data={dashboards.ticketsDashboard.repartition_tickets.data}
                        withTopLegend
                    />
                </div>
            </>
        );
    };

    if (!isEmpty(dashboards.metabaseUrl)) {
        return (
            <div className={'metabase-container'}>
                <iframe
                    allowTransparency
                    src={dashboards.metabaseUrl}
                />
            </div>
        );
    }

    useEffect(() => {
        if (!isEmpty(metabaseMapping)) {
            if (metabaseMapping.length >= selectedMetabaseDashboard + 1) {
                const metabaseId = metabaseMapping[selectedMetabaseDashboard].metabase_id;
                dispatch(getMetabaseUrlFromId(metabaseId)).then(response => {
                    setSelectedMetabaseUrl(response?.value?.data);
                }).catch(() => {
                    setSelectedMetabaseUrl('ERROR');
                });
            } else {
                setSelectedMetabaseUrl('ERROR');
            }
        }
    }, [selectedMetabaseDashboard]);

    const getMetabaseDashboard = () => {
        if (isEmpty(selectedMetabaseUrl)) {
            return <></>;
        } if (selectedMetabaseUrl === 'ERROR') {
            return (
                <div style={{ height: '80vh' }}>
                    <Component componentName={'NoData'} />
                </div>
            );
        }
        return (
            <iframe
                allowTransparency
                src={selectedMetabaseUrl}
            />
        );
    };

    if (!isEmpty(metabaseMapping) && isJSONArray(metabaseMapping)) {
        return (
            <div>
                <div className={'dashboard-tabs-container'}>
                    {
                        metabaseMapping.map((dashboard, index) => {
                            if (dashboard.tab_name && dashboard.metabase_id) {
                                return (
                                    <div
                                        role={'button'}
                                        tabIndex={0}
                                        className={classNames([
                                            'dashboard-tab',
                                            { 'selected': selectedMetabaseDashboard === index }
                                        ])}
                                        onClick={() => setSelectedMetabaseDashboard(index)}
                                    >
                                        { dashboard.tab_name }
                                    </div>
                                );
                            }
                            return null;
                        })
                    }
                </div>
                <div className={'metabase-container'}>
                    {getMetabaseDashboard()}
                </div>
            </div>
        );
    }

    if (dashboards.ticketsDashboard?.metrics) {
        return (
            <>
                <div className={'tickets-dashboard-container'}>
                    <div className={'header-dashboards-tickets'}>
                        <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
                        <Component
                            componentName={'Button'}
                            onClick={() => {
                                const metric_new = dashboards.ticketsDashboard?.metrics?.find(metric => metric.name === 'metrics_new')?.value;
                                if (metric_new && metric_new <= 4000) {
                                    dispatch(getTicketsExport(timeOptions[selectedTime]))
                                        .then(result => {
                                            exportTableToCsvFile(result.action.payload.data?.export_data);
                                        });
                                } else {
                                    dispatch(notification(
                                        t('notifications:too_many_tickets_to_export'),
                                        'warning',
                                        'exclamationTriangleLight'
                                    ));
                                }
                            }}
                            startAdornment={<Component componentName={'Icon'} type={'export'} />}
                            disabled={noTicketData}
                            icon={'export'}
                            text={t('dashboards:export_tickets')}
                            variant={'outlined'}
                            className={'confirmation-button save-button'}
                        />
                    </div>
                    <div className={'scrollable-container'}>
                        <div className={'tickets-dashboard-row upper-row'}>
                            {
                                dashboards.ticketsDashboard?.metrics?.map(metric => (
                                    <div className={`ticket-metric ${metric.name}`}>
                                        <span className={'metric-number'}>{metric.value}</span>
                                        <div className={'metric-legends'}>
                                            <span
                                                className={'metric-legend-upper'}
                                            >
                                                {t(`dashboards:${metric.name}`)}
                                            </span>
                                            <span className={'metric-legend-lower'}>
                                                {
                                                    metric.name === 'metric_in_progress' || metric.name === 'metrics_in_progress'
                                                        ? t('dashboards:metric_in_progress_label')
                                                        : t(selectedTime).toLowerCase()
                                                }
                                            </span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        {getTicketsGraph()}
                        {getSatisfactionGraph()}
                        {getRatingGraph()}
                    </div>
                </div>
            </>
        );
    }
    return <Component componentName={'GraphNoData'} />;
};

export default DashboardsTable;

// Libs
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Context
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import { useComponentsPool } from '../../../../ComponentsPool';
import { Toast } from '../../../notification/components';
import { useModal } from '../../../modal/ModalContext';
// Actions
import createLoadingSelector from '../../../layout/actions';
import { addNfcTagFromCSV } from '../../actions';

const loadingSelector = createLoadingSelector(['ADD_NFC_TAG_CSV_FILE']);

const CSVAddNfcPopup = () => {
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const modal = useModal();
    const choosePicture = React.createRef();
    const [csvFile, setCsvFile] = useState('');
    const [isCompliantCsvFile, setIsCompliantCsvFile] = useState(false);

    const showUploader = () => {
        choosePicture.current.click();
    };

    const fileChangedHandler = e => {
        e.preventDefault();
        if (e.target.files?.length > 0) {
            setCsvFile(e.target.files[0]);
            if (e.target.files[0].size < 2500000) {
                setIsCompliantCsvFile(true);
            } else {
                setIsCompliantCsvFile(false);
                toast(
                    <Toast
                        isConfirm
                        message={t('notifications:is_not_compliant_device_csv')}
                        icon={'frown'}
                        type={'error'}
                    />, {
                        position: toast.POSITION.BOTTOM_LEFT,
                        className: 'normal',
                        bodyClassName: 'notification-body grow-font-size',
                        progressClassName: 'error-custom-progress-bar'
                    }
                );
            }
        }
    };

    const addNfcTag = async () => {
        dispatch(addNfcTagFromCSV(csvFile))
            .then(() => {
                modal.close();
            });
    };

    const Form = (
        <div className={'csv-popup'}>
            <div className={'add-csv-file'}>
                <div className="doc-links">
                    <a href="https://www.notion.so/merciyanis/Importer-des-badges-NFC-398d8271eb2444f986f2aaaa78c11702?pvs=4" rel="noopener noreferrer" target="_blank">
                        {t('common:doc_link')}
                    </a>
                    <br />
                    <a href="https://docs.google.com/spreadsheets/d/1M3QHrE70baUjQ4AiDRtCuZ0Nns8sCGJYiSz0ZV0Vv54/edit#gid=0" rel="noopener noreferrer" target="_blank">
                        {t('common:csv_link')}
                    </a>
                </div>
                <Button
                    onClick={showUploader}
                >
                    <span>{t('common:import_csv_file')}</span>
                </Button>
            </div>
            <input
                ref={choosePicture}
                hidden
                type="file"
                accept=".csv"
                onChange={e => fileChangedHandler(e)}
            />
            <div>
                <span>{t('common:imported_file') + (csvFile ? ` ${ csvFile.name}` : ` ${ t('common:none')}`)}</span>
            </div>
        </div>
    );

    const disableSave = !isCompliantCsvFile || !csvFile;

    return (
        <>
            <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
            <Component
                componentName={'Modal'}
                noOverflow
                head={<span className={'title-ticket-create-popup'}>{t('descriptions:nfc_tags_actionbar_from_csv')}</span>}
                content={Form}
                classNames={'ticket-create-popup'}
                submitButton
                submitButtonLabel={'common:save'}
                onHandleSubmit={addNfcTag}
                onSubmitDisabled={disableSave}
            />
        </>
    );
};
export default CSVAddNfcPopup;

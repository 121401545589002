// Libs
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { getTimeSlotFromStringWithDate } from '../../../layout/helper';
import { getOneTimeRoundRRule } from '../../helper';

const RoundOneTimeRruleAndDateFields = props => {
    const { Component } = useComponentsPool();
    const { t } = useTranslation();
    const [dayDate, setDayDate] = useState(moment(props.startDate).toDate());
    const startedAt = moment(props.started_at).format('HH[:]mm');
    const endedAt = moment(props.ended_at).format('HH[:]mm');

    // we put the right rrule, it will always be the same for a onetimeround
    useEffect(() => {
        props.changeState({ rrule: getOneTimeRoundRRule().toString() });
    }, []);

    // everytime we select a new day or we change the hours
    const onHoursChange = (newValue = null, chosenDay = null) => {
        const startedAtHours = newValue?.started_at ? newValue.started_at : startedAt;
        const endedAtHours = newValue?.ended_at ? newValue.ended_at : endedAt;
        // chosenDay ? chosenDay : dayDate
        const chosenDayDate = chosenDay || dayDate;
        const timeSlot = getTimeSlotFromStringWithDate(startedAtHours, endedAtHours, moment(chosenDayDate).format());
        props.changeState(timeSlot);
    };

    const onDayDateChange = selectedDayDate => {
        setDayDate(selectedDayDate);
        onHoursChange(null, selectedDayDate);
    };

    return (
        <>
            <Component
                componentName={'DayPicker'}
                customString={t('common:the')}
                date={dayDate}
                onChange={e => onDayDateChange(e)}
            />
            <Component
                componentName={'RoundOneTime'}
                started_at={startedAt}
                ended_at={endedAt}
                changeState={onHoursChange}
            />
        </>
    );
};

export default RoundOneTimeRruleAndDateFields;

// Libs
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { every, groupBy, isEmpty } from 'lodash';
// Context
import { useFootBar } from '../../footbar/FootBarContext';
import { useModal } from '../../modal/ModalContext';
import { useComponentsPool } from '../../../ComponentsPool';
import { useFormState } from '../../layout/forms/api/useFormState';
// Actions
import {
    editDevice, getLastReceivedDeviceData, hardwareAlreadyAssign
} from '../actions';

// MerciYanis
import { editGroup, getGroupList, resetSelectedGroup } from '../../groups/actions';

const DeviceActionBar = () => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const devices = useSelector(state => state.devices.list);
    const groups = useSelector(state => state.groups.list);
    const currentUser = useSelector(state => state.users.currentUser);
    const isOwner = currentUser.workspace_role?.workspace_role_name === 'Owner';

    const device = useSelector(state => state.devices.deviceShownInDetailsPopup);
    const group = useSelector(state => state.groups.groupShownInDetailsPopup);

    const footBar = useFootBar();
    const dispatch = useDispatch();
    const modal = useModal();
    const { formState } = useFormState();
    const workspace_rights = useSelector(state => state.users.currentUser.workspace_rights);
    const currentUsername = useSelector(state => state.users.currentUser?.username);
    const list = modal.getWithFootBar() ? [modal.getItem()] : footBar.getList();
    const workspace = localStorage.getItem('iot_rocket_workspace');

    const switchButtonCallback = () => {
        const hardwareIds = Object.keys(groupBy(devices, 'hardware_ids')).filter(n => !device.hardware_ids.includes(n));
        if (formState && formState.hardware_ids
            && formState.hardware_ids.filter(item => hardwareIds.includes(item)).length > 0) {
            return dispatch(hardwareAlreadyAssign());
        }
        if (device && device.custom_field?.config?.modes) {
            formState.custom_field.config.modes = device.custom_field?.config.modes;
        }
        return dispatch(editDevice(formState.device_id, formState))
            .then(() => {
                dispatch(getGroupList());
            });
    };

    const updateGroup = () => {
        dispatch(editGroup(formState.group_id, formState))
            .then(() => {
                dispatch(getGroupList()).then(() => {
                    dispatch(getLastReceivedDeviceData());
                    dispatch(resetSelectedGroup());
                });
                modal.close();
            });
    };

    const footbarButtons = useMemo(() => {
        let displayDeviceDeleteButton = false;
        if (currentUsername === 'merciyanis@iot-rocket.space' && list.length > 0 && !list.some(deviceObj => deviceObj.group_id)) {
            displayDeviceDeleteButton = true;
        }
        const deleteButton = displayDeviceDeleteButton
            ? (
                <Component
                    key={'DeleteDeviceButton'}
                    componentName={'DeleteDeviceButton'}
                    userWorkspaceRights={workspace_rights}
                    list={list}
                />
            )
            : <></>;

        let displayDeviceExportButton = false;
        let deviceType = null;
        const maxLimit = workspace === 'novo-nordisk' ? 750 : 500;
        if (list.length > 0 && list.length <= maxLimit) {
            deviceType = list[0].custom_field.type;
            const hasDevicesWithSameType = list.every(device => device.custom_field.type === deviceType); // all devices have the same type
            const onlySingleDevicesAreSelected = every(list, (device) => !!device.device_id) // all devices have device_id = all single devices
            const onlyGroupDevicesAreSelected= every(list, (group) => !!group.group_id) // only groupDevices are selected

            const nonOwnerExportButtonConditions = (onlySingleDevicesAreSelected && hasDevicesWithSameType) || onlyGroupDevicesAreSelected // single devices should have the same type / group devices can have same or diffrent types 

            if (
                isOwner ||
                (!isOwner && nonOwnerExportButtonConditions) // Non owner can export only single devices or deviceGroups with same type separately
            ) {
                displayDeviceExportButton = true;
            }
        }
        const exportButton = displayDeviceExportButton
            ? (
                <Component
                    key={'ExportDataButton'}
                    componentName={'ExportDataButton'}
                    userWorkspaceRights={workspace_rights}
                    list={list}
                    deviceType={deviceType}
                />
            )
            : <span key={'error_action_button'} className={'error_action_button'}>{ t('export:no_export_on_multiple_devices') }</span>;

        const exportInfoButton = (
            <Component
                key={'ExportInfoButton'}
                componentName={'ExportInfoButton'}
                list={list}
            />
        );
        return [deleteButton, exportInfoButton, exportButton];
    }, [list]);

    if (!isEmpty(group)) {
        return (
            <Component
                componentName={'Footbar'}
                actionButtons={footbarButtons}
                modal={'GroupDetailsPopup'}
                resourceList={groups}
                switchButtonCallback={() => updateGroup()}
                resourceType={'Group'}
                locale={'groups'}
            />
        );
    }
    return (
        <Component
            componentName={'Footbar'}
            actionButtons={footbarButtons}
            modal={'DeviceDetailsPopup'}
            resourceList={devices}
            switchButtonCallback={() => switchButtonCallback()}
            resourceType={'Device'}
            locale={'devices'}
        />
    );
};

export default DeviceActionBar;

import axios from 'axios';

export const setCurrentQRCodeTicket = ticket => ({ type: 'SET_CURRENT_QRCODE_FORM_TICKET', payload: ticket });

export const getQRCodeDocuments = (deviceId, anonymousConnexion = false) => {
    const headers = anonymousConnexion
        ? {
            'x-master-api-key': QRCODE_ISSUE_REPORT_API_KEY,
            username: USERNAME_ISSUE_REPORT,
            password: PASSWORD_ISSUE_REPORT
        }
        : {};
    return {
        type: 'GET_QRCODE_DOCUMENTS',
        payload: axios.get(`/devices/${deviceId}/documents/`, { headers }).then(res => res.data)
    };
};

export const getQRCodeDocumentContent = (deviceId, documentId, anonymousConnexion = false) => {
    const headers = anonymousConnexion
        ? {
            'x-master-api-key': QRCODE_ISSUE_REPORT_API_KEY,
            username: USERNAME_ISSUE_REPORT,
            password: PASSWORD_ISSUE_REPORT
        }
        : {};
    return {
        type: 'GET_QRCODE_DOCUMENT_CONTENT',
        payload: axios.get(`/devices/${deviceId}/documents/${documentId}`, { headers }).then(res => res.data)
    };
};


// Libs
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
import { useModal } from '../../modal/ModalContext';
// Helper
import { getUsersByGroupId, addNametoUserObject } from '../helper';

const setUsersByGroup = (groupUsers, users, groups, item) => {
    if (groupUsers) return groupUsers;
    if (!isEmpty(item)) {
        return getUsersByGroupId(users, groups, item.group_id);
    }
    return null;
};

const getTransparentComponents = count => (count === 2 ? <div className="transparentMember" /> : <></>);

const UserAvatarsGrouped = props => {
    const { Component } = useComponentsPool();
    const users = useSelector(state => state.users.list);
    const groups = useSelector(state => state.groups.list);
    const maxDisplayedUsers = 2;
    const [t] = useTranslation();
    const modal = useModal();
    const usersByGroups = setUsersByGroup(props.usersByGroups, users, groups, modal.getItem());
    // Allows to align the assignments on the ticket regardless of the number
    const [transparentDiv, setTransparentDiv] = useState(usersByGroups
        ? getTransparentComponents(usersByGroups.length)
        : <></>
    );

    useEffect(() => {
        if (usersByGroups) {
            setTransparentDiv(getTransparentComponents(usersByGroups.length));
        }
    }, [usersByGroups]);

    return useMemo(() => (
        <div className="grouped-avatar">
            { usersByGroups ? usersByGroups.slice(0, maxDisplayedUsers).map((user, index) => (
                <div className="user-preview-wrapper" key={user?.id} style={{ zIndex: maxDisplayedUsers - index }}>
                    <Component
                        componentName={'UserAvatar'}
                        picture={user?.profile_picture_url}
                        user={addNametoUserObject(user)}
                        size="35"
                    />
                </div>
            )) : (
                <div className="user-preview-wrapper solo-avatar">
                    <Component
                        componentName={'UserAvatar'}
                        displayEmoji={props.displayEmoji}
                        picture={props.user?.profile_picture_url}
                        user={addNametoUserObject(props.user)}
                        size="35"
                    />
                </div>
            )}
            { usersByGroups && usersByGroups.length > maxDisplayedUsers && (
                <div className="other-members">
                    <p>{`+${usersByGroups.length - maxDisplayedUsers}`}</p>
                    {!props.shortenText
                        && <p>{`${t('common:member', { count: usersByGroups.length }).toLowerCase()}`}</p>
                    }
                </div>
            )}
            {transparentDiv}
        </div>
    ), [usersByGroups]);
};

export default UserAvatarsGrouped;

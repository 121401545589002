import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import { fr } from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useComponentsPool } from '../../../ComponentsPool';
import useClickOutside from '../../../utils/hook/useClickOutside';
import { RoundsContext } from '../../rounds/RoundsContext';
import useRouter from '../../../utils/hook/useRouter';
import { END_DATE_PARAM, MAX_NEXT_DATE_RANGE, START_DATE_PARAM } from '../../rounds/constants';
import { useIsFirstRender } from "../../../utils/hook/useIsFirstRender";
import { isEqual } from "lodash";

const now = moment();
const nowDate = new Date();
export const maxDate = new Date(nowDate.setMonth(nowDate.getMonth() + MAX_NEXT_DATE_RANGE)).setDate(nowDate.getDate() - nowDate.getDay());

const DateWeekPicker = props => {
    const roundsContext = useContext(RoundsContext);
    const { query } = useRouter();
    const isFirstRender = useIsFirstRender();
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const calendarRef = useRef(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const closeDatePicker = () => setShowDatePicker(false);
    const currentInstanceDateTime = useSelector(
        state => state.rounds.roundInstanceShownInDetailsPopup.currentInstanceDateTime
    );
    const { showLabel = true } = props;
    const defaultDate = moment(roundsContext.getters.selectedDayOnCalendar); // start date from queryParam or default to start of the current week
    const [datePickerRange, setDatePickerRange] = useState([
        {
            startDate: defaultDate.clone().startOf('isoWeek'),
            endDate: defaultDate.clone().endOf('isoWeek'),
            key: 'selection'
        }
    ]);

    // update date picker range based on url changes
    useEffect(() => {
        const newState = [{
            startDate: moment(query[START_DATE_PARAM]),
            endDate: moment(query[END_DATE_PARAM]),
            key: 'selection'
        }]
        if(!isFirstRender && !isEqual(datePickerRange, newState)){ // for avoiding the infinite loop
            setDatePickerRange(newState)
        }
    }, [query]);

    useClickOutside(calendarRef, closeDatePicker);

    const onDateChangeHandler = (item) => {
        const newDatePickerRange = [getFirstAndLastDayOfWeek(item.selection)]
        setDatePickerRange(newDatePickerRange); // set new dateRange for updating the datePickerRange
        setShowDatePicker(false); // close the modal
        props.onChange(newDatePickerRange[0]) // call the onChange props (function) of the component
    };

    let displayDate = t('common:unknown_from_to');
    if (datePickerRange.length > 0) {
        const startOfCurrentWeek = now.startOf('isoWeek');
        const startDate = query[START_DATE_PARAM] ? moment(query[START_DATE_PARAM]) : datePickerRange[0].startDate
        const endDate = query[END_DATE_PARAM] ? moment(query[END_DATE_PARAM]) : datePickerRange[0].endDate
        if (startDate.isSame(startOfCurrentWeek)) {
            displayDate = t('common:since_monday');
        } else {
            displayDate = `${t('common:from')} ${startDate.format('DD/MM/YY')} ${t('common:to')} ${endDate.format('DD/MM/YY')}`;
        }
    }

    const getFirstAndLastDayOfWeek = day => {
        const week = day;
        week.startDate = moment(day.startDate).startOf('isoWeek');
        week.endDate = moment(day.startDate).endOf('isoWeek');
        return week;
    };

    if (currentInstanceDateTime) {
        return (
            <div className={'selected-date-range single-date'}>
                {showLabel && <span className={'label-displayer'}>{`${t('common:selected-day')} :`}</span>}
                <div className={'range-displayer'}>
                    <span>{currentInstanceDateTime}</span>
                </div>
            </div>
        );
    }
    return (
        <div className="date-week-picker" ref={calendarRef}>
            <div className={'selected-date-range'}>
                {showLabel && <span className={'label-displayer'}>{`${t('common:selected-range')} :`}</span>}
                <div className={'range-displayer'}>
                    <span>{displayDate}</span>
                    <IconButton onClick={() => setShowDatePicker(!showDatePicker)}>
                        <Component componentName={'Icon'} type={'event'} />
                    </IconButton>
                </div>
            </div>
            {showDatePicker && (
                <DateRange
                    ranges={datePickerRange}
                    focusedRange={[0, 0]}
                    onChange={item => onDateChangeHandler(item)}
                    showDateDisplay={false}
                    dragSelectionEnableld={false}
                    locale={fr}
                    showMonthAndYearPickers={false}
                    rangeColors={['#011056FF']}
                    onBlur={() => setShowDatePicker(false)}
                    className={'date-range'}
                    maxDate={new Date(maxDate)}
                />
            )}
        </div>
    );
};

export default DateWeekPicker;

import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Workbox } from 'workbox-window';
import { useDispatch, useSelector } from 'react-redux';
import { useIsOnline } from 'react-use-is-online';
import Icon from './Icon';
import InformationBanner from './InformationBanner';
import { isQRCodePassageDeviceType } from '../../devices/helper';
import { sendMessageToServiceWorker } from '../../core/offlineHelpers';
import { notification } from '../../notification/actions';
import LoaderBarTop from './LoaderBarTop';

const wb = new Workbox('/service-worker.js');

const OfflineBanner = () => {
    const [t] = useTranslation();
    const { isOnline } = useIsOnline();
    const dispatch = useDispatch();
    const isLogged = useSelector(state => state.authentication.isLogged);
    const devices = useSelector(state => state.devices.list);
    const [pendingCount, setPendingCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);

    const flushQueue = useCallback(() => {
        setIsLoading(true);
        sendMessageToServiceWorker(wb, {
            action: 'FLUSH_QUEUE',
            queueName: 'deviceMessages'
        }).then(count => {
            if (count === 0) {
                dispatch(notification(
                    t('notifications:flush_pending_passages_on_service_worker_success'),
                    'successful',
                    'check'
                ));
                setPendingCount(0);
            } else {
                dispatch(notification(
                    t('notifications:flush_pending_passages_on_service_worker_error'),
                    'error-custom',
                    'frown'
                ));
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    // Explicitly requests the number of messages waiting to load to Service Worker
    const requestServiceWorkerPendingMessageCount = () => {
        if (isMobile
            && 'serviceWorker' in navigator
            && navigator.serviceWorker.controller
            && isLogged
            && devices.some(device => isQRCodePassageDeviceType(device))
        ) {
            sendMessageToServiceWorker(wb, {
                action: 'GET_PENDING_DEVICE_MESSAGES_COUNT'
            }).then(count => {
                setPendingCount(count);
                if (isOnline && count > 0) {
                    flushQueue();
                }
            });
        }
    };

    // Service Worker can also send the pending count itself to the app in the event of a change
    const serviceWorkerMessageHandler = event => {
        if (event.data && event.data.type === 'BROADCAST_PENDING_DEVICE_MESSAGES_COUNT') {
            setPendingCount(event.data.value);
        }
    };

    useEffect(() => {
        if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
            // updateViaCache ensures that the browser always checks for the latest version of the serviceworker
            wb.register({ updateViaCache: 'none' }).then(registration => {
                // Explicitly requests the number of messages waiting to load
                requestServiceWorkerPendingMessageCount(wb);

                // Listening for changes in the status of the installed worker service
                registration.addEventListener('updatefound', () => {
                    const installingWorker = registration.installing;
                    if (installingWorker) {
                        installingWorker.onstatechange = () => {
                            if (installingWorker.state === 'installed' && navigator.serviceWorker.controller) {
                                // New content is available : prompts the user to update the page
                                setShowUpdate(true);
                            }
                        };
                    }
                });

                // Then subscribe to receive updates
                navigator.serviceWorker.addEventListener('message', serviceWorkerMessageHandler);

                return () => {
                    navigator.serviceWorker.removeEventListener('message', serviceWorkerMessageHandler);
                };
            });
            return () => {};
        }
        return () => {};
    }, []);

    useEffect(() => {
        // Fetch pending count at each status change (off or on)
        requestServiceWorkerPendingMessageCount(wb);
    }, [isOnline]);

    let icon = <Icon componentName={'Icon'} type={'faWifiSlash'} />;
    const hasPendingCount = pendingCount > 0;
    let primaryLabel = '';
    let secondaryLabel = '';
    let buttonLabel = null;
    let onClick = null;

    if (showUpdate && isOnline) {
        icon = <Icon componentName={'Icon'} type={'faArrowsRotate'} />;
        primaryLabel = t('common:new_application_version_available');
        buttonLabel = t('common:reload_client_serviceworker');
        onClick = () => {
            // updateViaCache ensures that the browser always checks for the latest version of the serviceworker
            wb.register({ updateViaCache: 'none' }).then(() => sendMessageToServiceWorker(wb,{
                action: 'RELOAD_CLIENT_SERVICEWORKER'
            }).then(result => {
                if (result.status === 'success') {
                    window.location.reload();
                } else {
                    dispatch(notification(t('notifications:reload_client_serviceworker_error'), 'error', 'frown'));
                }
            }));
        };
    } else {
        // ONLINE CASES
        // Cases where no banner should be displayed:
        if (isOnline && (!isMobile || !hasPendingCount)) {
            return <></>;
        }
        if (isOnline && isMobile && hasPendingCount) {
            primaryLabel = t('common:online_after_offline_message');
            if (pendingCount === 1) {
                secondaryLabel = t('common:offline_mobile_warning_with_passage', { pendingCount });
            } else {
                secondaryLabel = t('common:offline_mobile_warning_with_passages', { pendingCount });
            }
            buttonLabel = t('common:retry_passage_fetch_online');
            onClick = () => { flushQueue(); };
        }
        // OFFLINE CASES
        if (!isOnline) {
            primaryLabel = t('common:offline_warning');
            if (!isMobile) {
                secondaryLabel = t('common:offline_desktop_warning');
            } else {
                if (hasPendingCount) {
                    if (pendingCount === 1) {
                        secondaryLabel = t('common:offline_mobile_warning_with_passage', { pendingCount });
                    } else {
                        secondaryLabel = t('common:offline_mobile_warning_with_passages', { pendingCount });
                    }
                }
            }
        }
    }

    return (
        <>
            <LoaderBarTop isLoading={isLoading} />
            <InformationBanner
                icon={icon}
                primaryLabel={primaryLabel}
                secondaryLabel={secondaryLabel}
                buttonLabel={buttonLabel}
                onClick={onClick}
            />
        </>
    );
};

export default OfflineBanner;
// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';
// Helper
import { getRoundEditionLeftSideBottomFields, maxNumberOfChipsInFieldOfPopupLeftSide } from '../../helper';

const RoundJobsText = props => {
    const round = useSelector(state => state.rounds.roundShownInDetailsPopup);
    const footbar = useFootBar();
    const bottomFields = getRoundEditionLeftSideBottomFields(footbar, round);
    // eslint-disable-next-line max-len
    const maxNumberOfChipsToDisplay = maxNumberOfChipsInFieldOfPopupLeftSide(Object.entries(bottomFields).filter(obj => obj[1].isDisplayed).length);

    const getOptions = jobs => {
        const returnGroups = [];
        jobs.forEach((job, idx) => {
            returnGroups.push({ id: `job-${idx}`, value: job });
        });
        return returnGroups.sort((a, b) => {
            if (a.value > b.value) return 1;
            if (a.value < b.value) return -1;
            return 0;
        });
    };

    const round_jobs = getOptions(props.selected ? props.selected : round.round_expected_jobs);
    const { Component } = useComponentsPool();

    if (isEmpty(round_jobs)) return '-';
    return (
        <div className={'followers-container'}>
            <Component
                componentName={'ChipButton'}
                maxVisible={maxNumberOfChipsToDisplay}
                chipsToDisplay={round_jobs}
            />
        </div>
    );
};

export default RoundJobsText;

// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';

// Context
import { useFootBar } from '../FootBarContext';

const ActionBarCheckbox = () => {
    const [t] = useTranslation();
    const footBar = useFootBar();
    const checkedTickets = footBar.getList();

    // Checked element/s message handler
    const checkedTicketsText = (checkedTickets) => {
        if (checkedTickets.length > 1) {
            return t('common:your_selection_plural', { nb: checkedTickets.length });
        } else if (checkedTickets.length === 1) {
            return t('common:your_selection');
        }
    };

    return (
        <div className="selected_users">
            {/* Checked element/s message */}
            {checkedTickets.length >= 1 ? <p>{checkedTicketsText(checkedTickets)}</p> : null}
        </div>
    );
};

export default ActionBarCheckbox;

// Libs
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, find } from 'lodash';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';
// Actions
import { deleteUser } from '../../actions';
import { getTickets } from '../../../tickets/actions';

export default props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const footBar = useFootBar();
    const hasOwner = useMemo(() => props.list.some(user => user.workspace_role && user.workspace_role?.workspace_role_name === 'Owner'));
    const profile = useSelector(state => state.users.currentUser);

    return (
        <Component
            componentName={'FootbarButton'}
            withConfirmationPopover
            displayCondition={props.userWorkspaceRights.includes('DELETE:User')
            && !isEmpty(props.list) && isEmpty(props.filteredGroups) && !hasOwner && !props.hasInvitations
            && props.hasConfiguredUsers && find(props.list, user => user.id === profile.id) === undefined}
            buttonText={t('descriptions:remove_user_from_workspace', { count: props.list.length })}
            callbackAction={() => {
                dispatch(deleteUser({ user_ids: props.list.map(user => user.id) }))
                    .then(() => {
                        dispatch(getTickets());
                    });
                footBar.close();
            }}
            popoverText={props.list.length > 1 ? t('users:remove_user_plural') : t('users:remove_user')}
            buttonIcon={'trash'}
        />
    );
};

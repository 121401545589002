// Libs
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import Avatar from 'react-avatar';
import { isString } from 'lodash';
// SCSS
import '../../layout/styles/_avatar.scss';
import { getUserName } from '../helper';
import { getScssVariables } from '../../layout/helper';

const isImage = file => {
    const fileName = file.name || file.path;
    const suffix = fileName.substr(fileName.indexOf('.') + 1).toLowerCase();
    return suffix === 'jpg' || suffix === 'jpeg' || suffix === 'bmp' || suffix === 'png' || suffix === 'svg';
};

const AvatarIot = memo(props => {
    if (props.picture !== undefined && props.picture !== null && !isString(props.picture)) {
        return isImage(props.picture) ? React.createElement('img', { className: '', role: 'presentation', src: URL.createObjectURL(props.picture) }) : '';
    }
    return (
        <Avatar
            name={getUserName(props.user, true)}
            src={props.picture}
            size={props.size}
            round
            onClick={props.onClick}
        />
    );
});

const UserAvatar = memo(props => {
    const avatarColors = useSelector(state => state.workspace.settings.avatar_colors);
    if (props.displayEmoji) {
        return (
            <Avatar
                value={props.displayEmoji}
                color={getScssVariables().primaryColor}
                size={props.size}
                round
                onClick={props.onClick}
            />
        );
    }
    return (
        props.picture ? <AvatarIot {...props} /> : (
            <Avatar
                name={getUserName(props.user, true)}
                colors={avatarColors}
                size={props.size}
                round
                textSizeRatio={2}
                onClick={props.onClick}
            />
        )
    );
});

export default UserAvatar;

// Libs
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
// Component
import { Toast, ConfirmButton } from './components';
// Context
import { useFormState } from '../layout/forms/api/useFormState';
import { useModal } from '../modal/ModalContext';
// Actions
import { editDevice, addDevice } from '../devices/actions';
// Scss
import 'react-toastify/dist/ReactToastify.css';
import './style.notification.scss';

const NotificationManager = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const { formState } = useFormState();
    const modal = useModal();
    const notification = useSelector(state => state.notification.messageInformation);

    useEffect(() => {
        if (notification?.message?.length > 0) {
            const info = { count: notification.count || 0, name: notification.name || '' };
            if (notification?.toast_type === 'confirm') {
                // /!\ Not generic: Only for device /!\
                toast(<Toast
                    isConfirm
                    message={t(`notifications:${notification.message}`, info)}
                    subMessage={t(`notifications:${notification.sub_message}`)}
                    icon={notification.icon || 'check'}
                    type={notification?.type}
                />, {
                    closeButton: <ConfirmButton
                        callback={async () => {
                            if (modal.getData().length > 0) {
                                await dispatch(addDevice(modal.getData()));
                                modal.setData([]);
                            } else {
                                await dispatch(editDevice(formState.device_id, formState)); // eslint-disable-line
                            }
                            modal.close();
                        }}
                    />,
                    autoClose: false,
                    position: toast.POSITION.BOTTOM_LEFT,
                    className: 'confirm',
                    bodyClassName: 'notification-body grow-font-size'
                });
            } else {
                toast(<Toast
                    message={t(`notifications:${notification.message}`, info)}
                    icon={notification.icon || 'check'}
                    type={notification?.type}
                />, {
                    position: toast.POSITION.BOTTOM_LEFT,
                    className: 'normal',
                    bodyClassName: 'notification-body grow-font-size',
                    progressClassName: `${notification.type}-progress-bar`
                });
            }
        }
    }, [notification]);

    return <ToastContainer />;
};

export default NotificationManager;

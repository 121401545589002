// Libs
import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Context
import { useComponentsPool } from '../../../../../ComponentsPool';


export default props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const currentUser = useSelector(state => state.users.currentUser);
    const currentUserRole = currentUser.workspace_role?.workspace_role_name;
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const workspace = localStorage.getItem('iot_rocket_workspace');
    const hasRightToSeeAssignersField = currentUserRole === 'Admin'
        || currentUserRole === 'Owner'
        || currentUserRole === 'Contractor' && (workspaceSettings.is_contractor_rights_on_assignment || false);
    const hasRightToSeePriorityField = currentUserRole === 'Admin'
        || currentUserRole === 'Owner'
        || currentUserRole === 'Contractor' && (workspaceSettings.is_contractor_rights_on_priority || false)
        || currentUserRole === 'User' && (workspaceSettings.is_user_rights_on_priority || false);


    return (
        <div className={classNames(['ticket-step', 'form-control'])}>
            { hasRightToSeePriorityField
                && (
                    <div className={'editable-field info'}>
                        <Component
                            componentName={'EditableField'}
                            renderValue={'TogglePrioritiesButton'}
                            required
                            title={t('tickets:priority')}
                            props={{
                                placeHolder: '',
                                onChange: value => props.changeState({ priority: value }),
                                value: props.priority
                            }}
                        />
                    </div>
                )
            }
            { workspace !== 'diot-siaci' && hasRightToSeeAssignersField
                && (
                    <div className={'editable-field info'}>
                        <span className={'info-title'}>{t('tickets:assigned_user')}</span>
                        <Component
                            componentName={'EditableField'}
                            renderValue={'SelectAssigners'}
                            props={{
                                batimentGroupId: props?.batiment_group_id,
                                skillGroupId: props?.skill_group_id,
                                onChange: value => props.changeState({ assigners: value }),
                                selected: props.assigners
                            }}
                        />
                    </div>
                )
            }
        </div>
    );
};

// Libs
import React, { useState } from 'react';
// Context
import { useComponentsPool } from '../../ComponentsPool';
// Scss
import './style.scss';
import { BUILDINGS_LIST, JOBS_LIST, SKILLS_LIST } from './components/SettingViewSwitcher';

export default () => {
    const { Component } = useComponentsPool();

    const [currentList, setCurrentList] = useState(BUILDINGS_LIST);

    const getTableComponent = () => {
        if (currentList === BUILDINGS_LIST) return <Component componentName={'BuildingsTable'} />;
        if (currentList === SKILLS_LIST) return <Component componentName={'SkillsTable'} />;
        if (currentList === JOBS_LIST) return <Component componentName={'JobsTable'} />;
        return <div />;
    };


    return (
        <div className={'settings-view-wrapper'}>
            <Component componentName={'SettingViewSwitcher'} currentList={currentList} setCurrentList={setCurrentList} />
            { getTableComponent() }
        </div>
    );
};

// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// Notification
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Tooltip } from '@material-ui/core';
import { useComponentsPool } from '../../../../ComponentsPool';
// Helper
import {
    getGroupsListFromType, getJobGroupIdByExpectedJobName
} from '../../../groups/helper';
import { roundDoesntHaveOnlyQrcodePassageDevices } from '../../helper';
import { useSecondMainColorTooltip } from '../../../layout/helper';
import { getDeviceFromId } from '../../../devices/helper';
import { Toast } from '../../../notification/components';

const getOptions = jobs => {
    const returnGroups = [];
    jobs.forEach(job => {
        returnGroups.push({ id: job.group_id, value: job.group_name });
    });
    return returnGroups.sort((a, b) => {
        if (a.value > b.value) return 1;
        if (a.value < b.value) return -1;
        return 0;
    });
};
const SelectRoundJobs = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const groupList = useSelector(state => state.groups.list);
    const deviceList = useSelector(state => state.devices.list);
    const jobsList = getGroupsListFromType(groupList, 'JOB');
    const devicesJobs = props.devicesIds.map(deviceId => getDeviceFromId(deviceList, deviceId).custom_field?.job_group_ids);
    const options = getOptions(jobsList).filter(job => devicesJobs.every(deviceJobs => deviceJobs?.includes(job.id)));
    const [selected, setSelected] = useState(
        props.selected
            ? props.selected.map(expectedJob => ({ id: getJobGroupIdByExpectedJobName(expectedJob, groupList), value: expectedJob }))
            : []
    );
    const tooltipStyle = useSecondMainColorTooltip();

    const hasDisabledText = () => {
        if (props.devicesIds.length === 0) { return t('rounds:round_no_step_select_for_jobs_field'); }
        if (roundDoesntHaveOnlyQrcodePassageDevices(deviceList, props.devicesIds)) { return t('rounds:round_no_expected_jobs_field'); }
        if (!roundDoesntHaveOnlyQrcodePassageDevices(deviceList, props.devicesIds) && devicesJobs.includes(undefined)) {
            // If it's a passage QR Code but there isn't a job associated to it
            return t('rounds:round_no_jobs_associated_field');
        }
        return false;
    };

    useEffect(() => {
        // everytime the round's devices are modified
        // => check that the selected jobs still matches the devices
        // if not remove the wrong jobs and display a notif
        const jobsToRemove = [];
        selected.forEach(selectedJob => {
            // If at least one of the device doesn't have a job or if the selected jobs doesn't match the devices jobs
            if (devicesJobs.length === 0 || devicesJobs.includes(undefined) || !devicesJobs.every(deviceJobs => deviceJobs?.includes(selectedJob.id, groupList))) {
                jobsToRemove.push(selectedJob);
            }
        });
        if (jobsToRemove.length > 0) {
            const jobsToKeep = selected.filter(selectedJob => !jobsToRemove.includes(selectedJob));
            setSelected(jobsToKeep);
            props.onChange(jobsToKeep.map(job => job.value));
            // Couldn't put the new notif format (with dispatch) because this notif couldn't appear at the same time as the SelectRoundUsers notif
            toast(
                <Toast
                    message={t(`notifications:${jobsToRemove.length === 1 ? 'job_was_removed' : 'jobs_were_removed' }`)}
                    icon={'exclamationTriangleLight'}
                    type={'warning'}
                />, {
                    position: toast.POSITION.BOTTOM_LEFT,
                    className: 'normal',
                    bodyClassName: 'notification-body grow-font-size',
                    progressClassName: 'warning-progress-bar'
                }
            );
        }
    }, [props.devicesIds]);

    return (
        <Tooltip
            placement={'top'}
            classes={tooltipStyle}
            title={hasDisabledText() ? <Component componentName={'Description'} content={<Trans>{hasDisabledText()}</Trans>} /> : ''}
        >
            {/* required div for the tooltip to work*/}
            <div>
                <Component
                    componentName={'Select'}
                    multiple
                    placeHolder={''}
                    onChange={jobsSelected => {
                        setSelected(jobsSelected);
                        props.onChange(jobsSelected.map(job => job.value));
                    }}
                    options={options}
                    renderValue={'chip'}
                    showSelectAll
                    selected={selected}
                    emptyChip={selected}
                    disabled={props.disabled ? props.disabled : hasDisabledText()}
                    chipRenderLimit={props.chipsRenderLimit ? props.chipsRenderLimit : 3}
                />
            </div>
        </Tooltip>
    );
};

export default SelectRoundJobs;


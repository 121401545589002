import axios from 'axios/index';

export const saveLastPassage = (agent, device, job) => ({
    type: 'SAVE_AGENT_LAST_PASSAGE',
    payload: axios.post('/passages/save_agent_last_passage', { agent, device, job })
        .then(res => res.data)
});


export const getPassageVisualization = (hardware_id, anonymousConnexion = false) => {
    const headers = anonymousConnexion
        ? {
            'x-master-api-key': QRCODE_ISSUE_REPORT_API_KEY,
            username: USERNAME_ISSUE_REPORT,
            password: PASSWORD_ISSUE_REPORT
        }
        : {};
    return {
        type: 'GET_PASSAGE_VISUALIZATION',
        payload: axios.get(`/devices/get_passage_visualization_data/${hardware_id}`, { headers }).then(res => res.data)
    };
};


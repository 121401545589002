// Libs
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import { useComponentsPool } from '../../ComponentsPool';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { getRounds } from './actions';
import RoundsProvider from "./RoundsContext";
import { DEFAULT_ROUND_VIEW } from "./constants";

const Rounds = () => {
    const { Component } = useComponentsPool();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isMobile && !location.search.includes('view=list')) { // By default, we redirect to the calendar view
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('view', DEFAULT_ROUND_VIEW);
            history.push({ search: `?${searchParams}` });
        } else if (location.search.includes('view=list')) {
            dispatch(getRounds(true));
        }
    }, []);

    if (isMobile) {
        return <Component componentName={'MobileRoundsView'} />;
    }

    return (
        <RoundsProvider>
            <Component componentName={'RoundsTable'} />
        </RoundsProvider>
    );
};

export default React.memo(Rounds);

// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
// Context
import { useModal } from '../ModalContext';
import { getValue } from '../../layout/helper';

const ModalHeadSimpleString = props => {
    const [t] = useTranslation();
    const modal = useModal();
    const value = getValue(props.value, modal.getItem());
    return (
        <p className={`info-value ${value ? '' : 'undefined'}`}>
            {value ? `${value}` : t('common:undefined')}
        </p>
    );
};

export default ModalHeadSimpleString;

/**
 * This function returns an object that represents all of available alert types, alert display text, and its icon name
 * @param { boolean } [isSettingAlertsForHimself = false] to set if alerts are for current user profile page or users profile modification popup
 * @return { { ALERT_TYPES : { ALERT_SUB_TYPES : { id:string, value:string, explanations:string, icon:string } } } } Alerts options
 */
export const ALERT_OPTIONS = (isSettingAlertsForHimself = false) => ({
    tickets: {
        // New tickets
        all: {
            id: 'TICKETS:all',
            value: 'TICKETS:all',
            explanations: `subscriptions:${
                isSettingAlertsForHimself ? 'TICKETS_all_explanations_current_user' : 'TICKETS_all_explanations'
            }`,
            icon: 'TICKETS_all'
        },
        // Ticket state updates
        comment: {
            id: 'TICKETS:comment',
            value: 'TICKETS:comment',
            explanations: `subscriptions:${
                isSettingAlertsForHimself ? 'TICKETS_comment_explanations_current_user' : 'TICKETS_comment_explanations'
            }`,
            icon: 'TICKETS_comment'
        },
        // Ticket assignments
        assignated: {
            id: 'TICKETS:assignated',
            value: 'TICKETS:assignated',
            explanations: `subscriptions:${
                isSettingAlertsForHimself
                    ? 'TICKETS_assignated_explanations_current_user'
                    : 'TICKETS_assignated_explanations'
            }`,
            icon: 'TICKETS_assignated'
        }
    },
    rounds: {
        // New rounds
        all: {
            id: 'ROUNDS:all',
            value: 'ROUNDS:all',
            explanations: `subscriptions:${
                isSettingAlertsForHimself ? 'ROUNDS_all_explanations_current_user' : 'ROUNDS_all_explanations'
            }`,
            icon: 'ROUNDS_all'
        },
        // Round state updates
        comment: {
            id: 'ROUNDS:comment',
            value: 'ROUNDS:comment',
            explanations: `subscriptions:${
                isSettingAlertsForHimself ? 'ROUNDS_comment_explanations_current_user' : 'ROUNDS_comment_explanations'
            }`,
            icon: 'ROUNDS_comment'
        },
        instance_incomplete: {
            id: 'ROUNDS:instance_incomplete',
            value: 'ROUNDS:instance_incomplete',
            explanations: `subscriptions:${
                isSettingAlertsForHimself ? 'ROUNDS_instance_incomplete_explanations_current_user' : 'ROUNDS_instance_incomplete_explanations'
            }`,
            icon: 'ROUNDS_instance_incomplete'
        }
    }
});

// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { groupBy, isEmpty } from 'lodash';
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';
import { getDevicesExport } from '../../actions';
import { prepareExportContents } from '../../../layout/helper';
import { getGroupsExport } from '../../../groups/actions';
import { notification } from '../../../notification/actions';
import { saveStringToPdf } from '../../../../utils/fileUtils';
import { isPassageDeviceType } from '../../helper';
import moment from 'moment/moment';
import { saveCsvFile } from '../../../core/helper';

const ExportDataButton = props => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const { Component } = useComponentsPool();
    const currentUser = useSelector(state => state.users.currentUser);
    const workspace = localStorage.getItem('iot_rocket_workspace');
    const footBar = useFootBar();
    const list = footBar.getList();
    const groupList = useSelector((state) => state.groups);
    const deviceList = useSelector((state) => state.devices.list);
    const exportTimeSlots = useSelector((state) => state.core.exportTimeSlots);

    function exportTableToCsvFile(exportType, filename, rows, t, deviceType) {
        const csvFile = prepareExportContents(exportType, groupList, deviceList, rows, t, deviceType);
        if (isEmpty(csvFile)) return null;
        return { csvFile, filename };
    }

    // For exporting the data of single devices and group devices from the API
    const exportDevicesDataFromAPI = async (devicesList, exportType) => {
        const groupedDevicesByType = groupBy(devicesList, (device) => device.custom_field.type);
        const promises = Object.entries(groupedDevicesByType).map(([deviceType, devices]) => {
            const ids = devices.map((device) => (exportType === 'DEVICE' ? device.device_id : device.group_id));
            const exportAction = exportType === 'DEVICE' ? getDevicesExport : getGroupsExport;
            return dispatch(exportAction(ids, exportTimeSlots)).then((result) => ({
                deviceType,
                devices,
                result: result ? result.action.payload : []
            }));
        });

        return await Promise.allSettled(promises);
    };

    const exportData = async () => {
        if (!isEmpty(list)) {
            const singleDevicesList = [];
            const groupDevicesList = [];

            // Split the list into single devices and group devices
            list.forEach((item) => {
                // Exclude "SIMPLE_LEAK" devices
                if (item.custom_field.type !== 'SIMPLE_LEAK' && item?.device_id) {
                    singleDevicesList.push(item);
                } else if ('group_id' in item && item.group_id) {
                    groupDevicesList.push(item);
                }
            });

            const sensorsWithNoData = []; // for tracking the deviceTypes that have no data or failed api calls
            if (singleDevicesList.length > 0) {
                // Single devices export process
                const results = await exportDevicesDataFromAPI(singleDevicesList, 'DEVICE');
                results.forEach(({ status, value }) => {
                    const { deviceType, devices: singleDevices, result: datas } = value;
                    if (status === 'fulfilled') {
                        if (exportTimeSlots.type === 'pdf') {
                            const csvFile = prepareExportContents('DEVICE', groupList, deviceList, datas, t, deviceType);
                            const dateFrom = exportTimeSlots.from_timestamp.split(' ')[0];
                            const dateTo = exportTimeSlots.to_timestamp.split(' ')[0];
                            if (!isEmpty(csvFile)) {
                                saveStringToPdf(csvFile,
                                    `${t('devices:export_file_name')}${dateFrom}${' - '}${dateTo}.pdf`,
                                    `${t('devices:export_file_title', {
                                        date_from: dateFrom,
                                        date_to: dateTo
                                    })}`,
                                    'DEVICE_LIST',
                                    `${t('export:exported_by')} ${currentUser?.first_name} ${currentUser?.last_name} ${t('common:date_the_at', {dayAndMonth: moment().format('DD/MM/YYYY'), hoursAndMinutes: moment().format('HH:mm:ss')}).toLowerCase()}`,
                                    workspace
                                );
                            } else {
                                const isPlural = datas.devices_ids?.length > 1;
                                dispatch(
                                    notification(
                                        isPlural
                                            ? t('notifications:no_data_in_export_multiple')
                                            : t('notifications:no_data_in_export'),
                                        'successful',
                                        'infoCircle'
                                    )
                                );
                            }
                        } else {
                            const dateFrom = exportTimeSlots.from_timestamp.split(' ')[0].split('-').reverse().join('_');
                            const dateTo = exportTimeSlots.to_timestamp.split(' ')[0].split('-').reverse().join('_');
                            const csvFile = exportTableToCsvFile(
                                'DEVICE',
                                `${t('devices:export_file_name')}${t(`export:${deviceType}`)}_${t('common:from').toLowerCase()}_${dateFrom}_${t('common:to')}_${dateTo}.csv`,
                                datas,
                                t,
                                deviceType
                            );
                            if (!csvFile) {
                                // Add for no data responses
                                sensorsWithNoData.push({ [deviceType]: singleDevices });
                                return;
                            }
                            saveCsvFile(csvFile.csvFile, csvFile.filename);
                        }
                    } else {
                        // Add for failed api calls
                        sensorsWithNoData.push({ [deviceType]: singleDevices });
                    }
                });
            }
            if (groupDevicesList.length > 0) {
                // Group devices export process
                const results = await exportDevicesDataFromAPI(groupDevicesList, 'GROUP');
                results.forEach(({ status, value }) => {
                    const { deviceType, devices: groupDevices, result: datas } = value;
                    if (status === 'fulfilled') {
                        const dateFrom = exportTimeSlots.from_timestamp.split(' ')[0].split('-').reverse().join('_');
                        const dateTo = exportTimeSlots.to_timestamp.split(' ')[0].split('-').reverse().join('_');
                        const csvFile = exportTableToCsvFile(
                            'GROUP',
                            `${t('devices:export_file_name')}${t('common:from').toLowerCase()}_${dateFrom}_${t('common:to')}_${dateTo}.csv`,
                            datas,
                            t,
                            deviceType
                        );
                        if (!csvFile) {
                            // Add for no data responses
                            sensorsWithNoData.push({ [deviceType]: groupDevices });
                            return;
                        }
                        saveCsvFile(csvFile.csvFile, csvFile.filename);
                    } else {
                        // Add for failed api calls
                        sensorsWithNoData.push({ [deviceType]: groupDevices });
                    }
                });
            }

            // Send the notification of fail or no data responses at the end of the export process
            if (sensorsWithNoData.length > 0) {
                const isPlural = sensorsWithNoData.length > 1;
                dispatch(
                    notification(
                        isPlural ? t('notifications:no_data_in_export_multiple') : t('notifications:no_data_in_export'),
                        'successful',
                        'infoCircle'
                    )
                );
            }
        }
    };

    return (
        <Component
            componentName={'FootbarButton'}
            displayCondition
            withTimeSlotsPopover
            withExportType={list.every(deviceObj => isPassageDeviceType(deviceObj))}
            buttonText={t('devices:export_data')}
            popoverText={t('export:export_devices_data')}
            callbackAction={() => exportData()}
            buttonIcon={'dataExport'}
        />
    );
};

export default ExportDataButton;

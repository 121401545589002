// Libs
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Components
import PageHeader from './PageHeader';
// Actions
import { checkRegisterToken, auth, register } from '../actions';
import createLoadingSelector from '../../layout/actions';
// Helper
import { isValid } from '../../layout/helper';


const loadingSelector = createLoadingSelector(['CHECK_REGISTER_TOKEN']);
const SignUpInvitationForm = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const authentication = useSelector(state => state.authentication);
    const mandatoryPhone = useSelector(state => state.workspace.settings.mandatory_telephone);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [registerCheck, setRegisterCheck] = useState(authentication.registerCheck);
    const [isInit, setIsInit] = useState(true);
    const lang = localStorage.getItem('i18nextLng');

    const getRightSentenceFromInviteRole = () => {
        if (registerCheck?.workspace_role_name) {
            return t(`common:page_header_welcome_lower_text_${registerCheck?.workspace_role_name?.toLowerCase()}`);
        }
        return '';
    };

    useEffect(() => {
        let disabled = isValid(firstname) || isValid(lastname) || isValid(password);
        if (mandatoryPhone) {
            disabled = isValid(firstname) || isValid(lastname) || isValid(password) || isValid(phone);
        }
        if (phone === 'error_field') {
            disabled = true;
        }
        setIsDisabled(disabled);
    }, [firstname, lastname, password, phone]);

    useEffect(() => {
        const getRegisterCheck = async () => {
            await dispatch(checkRegisterToken(props.location.search.split('=')[1]));
            setRegisterCheck(authentication.registerCheck);
            setIsInit(false);
        };
        if (isInit) {
            getRegisterCheck();
        }
    }, [isInit]);

    const onRegister = async () => {
        setIsDisabled(true);
        const data = { firstname, lastname, tel: phone, password, lang: lang.slice(0, 2) }; // eslint-disable-line
        await dispatch(register(data, props.location.search.split('=')[1]));
        dispatch(auth(registerCheck?.email, password));
    };

    if (!isLoading && registerCheck.isValid === false) {
        return <Redirect to="/login" />;
    }
    if (!isLoading && registerCheck?.isValid && registerCheck?.email) {
        return (
            <div className="home-form signup">
                <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
                <PageHeader subText={getRightSentenceFromInviteRole()} />
                <form className="form-wrapper signup-form">
                    <div className='sign-up-email'>
                        <Component componentName={'Icon'} type={'email'} />
                        <p>{`${registerCheck?.email}`}</p>
                    </div>
                    <div className='form-wrapper signup-fields'>
                        <Component
                            componentName={'Input'}
                            autoFocus
                            required
                            rules={['isAcceptableString']}
                            startAdornment={<Component componentName={'Icon'} type={'userFilled'} />}
                            label={'common:given_name'}
                            onChange={setFirstName}
                        />
                        <Component
                            componentName={'Input'}
                            required
                            rules={['isAcceptableString']}
                            startAdornment={<Component componentName={'Icon'} type={'userFilled'} />}
                            label={'common:family_name'}
                            onChange={setLastName}
                        />
                        <Component
                            componentName={'Input'}
                            onlyNumber
                            required={mandatoryPhone}
                            rules={['isPhoneNumber']}
                            startAdornment={<Component componentName={'Icon'} type={'phone'} />}
                            label={'common:phone_number'}
                            onChange={setPhone}
                        />
                        <Component
                            componentName={'Input'}
                            required
                            type={'password'}
                            rules={['isPassword']}
                            startAdornment={<Component componentName={'Icon'} type={'password'} />}
                            label={'common:password'}
                            onChange={setPassword}
                        />
                    </div>
                </form>
                <div className="form-wrapper">
                    <div className="submit-button-wrapper create-account-button">
                        <Component
                            componentName={'Button'}
                            onClick={onRegister}
                            disabled={isDisabled}
                            text={t('common:sign_up')}
                        />
                    </div>
                </div>
            </div>
        );
    }
    return <Component componentName={'LoaderBarTop'} isLoading={isLoading} />;
};

export default SignUpInvitationForm;

// Lib
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
// Context
import { useComponentsPool } from '../../../ComponentsPool';

const WorkspaceConfigurationHomePage = props => {
    const { t } = useTranslation();
    const { Component } = useComponentsPool();

    return (
        <div className={'configuration-menu-main-content'}>
            <Grid container spacing={3} columns={window.screen.width <= 1024 ? 3 : 4}>
                {props.menuTabs.map(tab => tab.subTabs && tab.isVisible && (
                    <Grid item xs={1}>
                        <div className={'configuration-menu-card'}>
                            <div className={'card-header'}>
                                <Component componentName={'Icon'} type={tab.icon} />
                                <h3>{t(`workspace:${tab.title}`)}</h3>
                            </div>
                            {tab.subTabs.map((subItem, subTabIdx) => (
                                <div className={'card-content'}>
                                    <div role={'button'} tabIndex={0} className={'card-link-button'} onClick={() => props.changeTab(tab, subTabIdx)}>
                                        <span>{t(`workspace:${subItem.title}`)}</span>
                                        <Component componentName={'Icon'} type={'rightArrow'} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default WorkspaceConfigurationHomePage;

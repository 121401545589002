// Libs
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import { sendResetPasswordInvite } from '../actions';
import createLoadingSelector from '../../layout/actions';
// Helper
import { isValid } from '../../layout/helper';

const loadingSelector = createLoadingSelector(['SEND_RESET_PASSWORD_INVITE']);

const ForgetPasswordForm = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [disableReset, setDisableReset] = useState(false);
    const [primaryColor] = useState(getComputedStyle(document.documentElement).getPropertyValue('--primary-color'));
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));

    const onResetPassword = () => {
        dispatch(sendResetPasswordInvite(email, localStorage.getItem('i18nextLng').slice(0, 2)));
        setDisableReset(true);
    };

    // Everytime the content of the input changes, user must be able to receive a mail again
    useEffect(() => {
        setDisableReset(false);
    }, [email]);

    return (
        <div className={'home-form'}>
            <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
            <Component componentName={'PageHeader'} formExplanationText={props.location.state?.formExplanationText} subText={t('common:reset_your_password')} />
            <div className={'form-wrapper'}>
                <Component
                    componentName={'Input'}
                    required
                    rules={['isEmail']}
                    startAdornment={<Component componentName={'Icon'} type={'email'} />}
                    label={'common:email_address'}
                    onChange={value => setEmail(value.trim())}
                />
                <div className="actions-bar">
                    <Component
                        componentName={'Button'}
                        onClick={() => (props.location.state?.goBackToPathname
                            ? props.history.push(props.location.state.goBackToPathname)
                            : props.history.push('/login'))
                        }
                        startAdornment
                        icon={'leftArrow'}
                        color={primaryColor}
                        text={
                            props.location.state?.loginRedirectButtonText
                                ? props.location.state.loginRedirectButtonText
                                : t('descriptions:go_back')
                        }
                    />
                    <Component
                        componentName={'Button'}
                        onClick={onResetPassword}
                        disabled={disableReset || isValid(email)}
                        text={t('common:reset_password')}
                    />
                </div>
            </div>
        </div>
    );
};

export default ForgetPasswordForm;

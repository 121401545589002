// Libs
import React from 'react';
import moment from 'moment';

const SimpleHour = props => (
    <p className={'info-hour'}>
        { props.value ? moment(props.value, 'HH:mm').format('HH[h]mm') : ''}
    </p>
);

export default SimpleHour;

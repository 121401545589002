import update from 'immutability-helper';

const initialState = () => ({
    list: [
        { name: 'alert_name', subject: 'alerts', translate: 'alerts:log_name' },
        { name: 'recipient_for_notifications', subject: 'alerts', translate: 'alerts:logs_receivers' },
        { name: 'recipient_group_for_notifications', subject: 'alerts', translate: 'alerts:logs_receivers' },
        { name: 'check_interval', subject: 'alerts', translate: 'alerts:logs_check_interval', isTime: true }, // eslint-disable-line
        { name: 'consecutive_trigger_delay', subject: 'alerts', translate: 'alerts:logs_consecutive_trigger_delay', isTime: true }, // eslint-disable-line
        { name: 'notification_strategy_names', subject: 'alerts', translate: 'alerts:logs_receivers' },
        { name: 'workspace_role', subject: 'user', translate: 'users:workspace_role' },
        { name: 'first_name', subjectWithTarget: 'user', translate: 'logs:the_first_name', translateNoSubject: 'logs:first_name' }, // eslint-disable-line
        { name: 'last_name', subjectWithTarget: 'user', translate: 'logs:the_last_name', translateNoSubject: 'logs:last_name' }, // eslint-disable-line
        { name: 'email', subjectWithTarget: 'user', translate: 'logs:the_email', translateNoSubject: 'logs:email' }, // eslint-disable-line
        { name: 'telephone', subjectWithTarget: 'user', translate: 'logs:the_telephone', translateNoSubject: 'logs:telephone' }, // eslint-disable-line
        { name: 'password', subject: null, translate: 'logs:password' },
        { name: 'name', subject: 'dashboard', translate: 'logs:name' },
        { name: 'group_name', subject: 'group', translate: 'logs:name' },
        { name: 'device_name', subject: 'device', translate: 'logs:name' },
        { name: 'device_position', subject: 'device', translate: 'logs:position' },
        { name: 'device_description', subject: 'device', translate: 'logs:description' },
        { name: 'device_address', subject: 'device', translate: 'logs:address' },
        { name: 'hardware_ids', subject: 'device', translate: 'logs:hardware_ids' },
        { name: 'profile_picture_url', subject: null, translate: 'logs:profile_picture' },
        { name: 'settings', subject: 'dashboard', translate: 'logs:settings' },
        { name: 'title', subjectWithTarget: 'ticket', translate: 'logs:edit_title' },
        { name: 'status', subjectWithTarget: 'ticket', translate: 'logs:edit_status' },
        { name: 'assigners', subjectWithTarget: 'ticket', translate: 'logs:assigners', translateNoSubject: 'logs:assigners' },
        { name: 'priority', subjectWithTarget: 'ticket', translate: 'logs:edit_priority' },
        { name: 'counter', subjectWithTarget: 'ticket', translate: 'logs:edit_counter' },
        { name: 'counter', subjectWithTarget: 'ticket', translate: 'logs:edit_counter' },
        { name: 'priority', subjectWithTarget: 'ticket', translate: 'logs:edit_priority' }
    ]
});

const logsReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'ADD_LOG_TYPE':
            return update(state, { list: { $push: [action.payload] } });
        default:
            return state;
    }
};

export default logsReducer;

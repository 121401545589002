import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

export const saveStringToCsv = (content, filename) => {
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), content], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

export default saveStringToCsv;

export const saveStringToPdf = (csvStrContent, filename, title = '', type = '', exportedBy = '', workspaceName = '') => {
    const addExportHeaders = doc => {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFont('helvetica', 'italic');
        doc.setFontSize(8);
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);

            // Exporter
            doc.text(`${workspaceName} ${workspaceName && exportedBy ? ' - ' : ''} ${exportedBy}`, 45, doc.internal.pageSize.height - 25, {
                align: 'left'
            });

            // Page number
            doc.text(`${ String(i) }/${ String(pageCount)}`, doc.internal.pageSize.width - 45,
                doc.internal.pageSize.height - 25, {
                    align: 'right'
                });
        }
    };
    if (type === 'DEVICE_LIST') {
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = 'landscape'; // portrait or landscape

        const pdf = new jsPDF(orientation, unit, size);

        const lines = csvStrContent.split('\n');
        const header = lines.shift();

        const body = lines.map(line => line.split(';'));

        const content = {
            startY: 110,
            styles: { fontSize: 7 },
            head: [header.split(';')],
            body,
            horizontalPageBreak: true
        };

        pdf.addImage('https://res.cloudinary.com/dkdjhrtdv/image/upload/v1546783402/logo-3.png', 'PNG', 45, 30, 150, 51);
        if (title) {
            pdf.setFontSize(9);
            pdf.text(title, 42, 105);
        }
        pdf.autoTable(content);
        addExportHeaders(pdf);
        pdf.save(filename);
    } else {
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = 'landscape'; // portrait or landscape

        const pdf = new jsPDF(orientation, unit, size);

        pdf.setFontSize(12);

        const lines = csvStrContent.split('\n');
        const header = lines[0];
        lines.shift();

        const body = lines.map(line => line.split(';'));

        const content = {
            startY: 170,
            styles: { fontSize: 8 },
            head: [header.split(';')],
            body,
            horizontalPageBreak: true
        };
        pdf.addImage('https://res.cloudinary.com/dkdjhrtdv/image/upload/v1546783402/logo-3.png', 'PNG', 45, 40, 200, 72);

        pdf.autoTable(content);
        addExportHeaders(pdf);
        pdf.save(filename);
    }
};

// Libs
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { deleteGroups } from '../../../groups/actions';
import { getTickets } from '../../../tickets/actions';

const SettingsTableToolbar = props => {
    const numSelected = props.numSelected;
    const selected = props.selected;
    const groupType = props.tableTitle;
    const [t] = useTranslation();
    const dispatch = useDispatch();

    return (
        <Toolbar
            sx={{
                height: props.customHeight,
                // Add padding to the toolbar
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...numSelected > 0 && {
                    bgcolor: theme => alpha(
                        theme.palette.primary.main,
                        theme.palette.action.activatedOpacity
                    )
                }
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected}
                    {' '}
                    {t(`settings:selected${numSelected > 1 ? '_plural' : ''}`)}
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {t(`settings:${props.tableTitle}`)}
                </Typography>
            )}
            {numSelected > 0 && (
                <Tooltip title={t('common:delete')}>
                    <IconButton
                        onClick={() => dispatch(deleteGroups({ group_ids: selected }, groupType))
                            .then(() => {
                                dispatch(getTickets(true));
                                props.setSelected([]);
                            })
                        }
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>

    );
};

SettingsTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    tableTitle: PropTypes.string.isRequired
};

export default SettingsTableToolbar;

export const getDocumentTypeIcon = (type, filledIcon = false) => {
    if (type === 'GIF') { return filledIcon ? 'gifFilled' : 'gif'; }
    if (type === 'MP4') { return filledIcon ? 'videoFilled' : 'video'; }
    if (type === 'PDF') { return filledIcon ? 'pdfFilled' : 'pdf'; }
    return filledIcon ? 'pictureFilled' : 'picture';
};

export const getAvailableDocumentFormats = () => ['pdf', 'png', 'jpeg', 'jpg', 'gif', 'mp4'];

export const getDocumentInputFileAcceptFormats = () => getAvailableDocumentFormats().map(format => `.${format}`).join();

export const documentFormatIsAvailable = fileFormat => getAvailableDocumentFormats().includes(fileFormat);

// Libs
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { isEmpty } from 'lodash';
import Lottie from 'react-lottie';
import { isMobile } from 'react-device-detect';
import { postTicketComments } from '../../actions';
import { useComponentsPool } from '../../../../ComponentsPool';
import createLoadingSelector from '../../../layout/actions';
import '../../styles/_teams_comment_form.scss';
import { getAnimationOrImgByIncident } from '../../../qrcodes/helper';
import Icon from '../../../layout/components/Icon';

const loadingSelector = createLoadingSelector(['UPLOAD_COMMENT_DOCUMENT', 'POST_TICKET_COMMENTS']);

const TeamsCommentForm = () => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const ticket = useSelector(state => state.tickets.ticketShownInDetailsPopup);
    const dispatch = useDispatch();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const [logComment, setLogComment] = useState('');
    const [emptyInput, setEmptyInput] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const disableSendComment = isEmpty(logComment?.trim()) && isEmpty(uploadedFiles) || logComment === 'error_field';
    const currentUser = useSelector(state => state.users.currentUser);
    const isLogged = useSelector(state => state.authentication.isLogged);
    const [isSent, setIsSent] = useState(false);

    const sendMessage = () => {
        const custom_field = {
            type: 'TEAMS_MESSAGE',
            source: 'ANONYMOUS_TEAMS'
        };
        dispatch(
            postTicketComments(
                ticket.id,
                logComment,
                currentUser.id,
                custom_field,
                uploadedFiles,
                !isLogged
            )
        ).then(() => {
            setIsSent(true);
            setEmptyInput(!emptyInput);
            setLogComment('');
            setUploadedFiles([]);
        });
    };
    if (isEmpty(ticket)) {
        return (
            <div className="main-wrapper teams-comment-form">
                <div className={'validation-passage-page'}>
                    <div className="success-result">
                        <div className="validate-result">
                            <Icon type="ErrorLaptop" />
                        </div>
                        <h3>{`${t('notifications:error')}.`}</h3>
                        <h3>{t('common:please_contact_responsible')}</h3>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            className={classnames([
                'teams-comment-page-wrapper',
                isMobile && 'mobile-version'
            ])}
        >
            <div className={'teams-comment-page-content'}>
                { isLoading && <Component componentName={'LoaderBarTop'} isLoading={isLoading} /> }
                <div className={'ticket-header'}>
                    <span className={'form-title'}>{t('tickets:add_comment_to_your_demand')}</span>
                    <span className={'ticket-information'}>{`${ticket.title} (n°${ticket.key})`}</span>
                    <Lottie
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: isSent ? getAnimationOrImgByIncident(2, 'approved-checked-flat', false) : getAnimationOrImgByIncident(2, 'consultation-flat', false)
                        }}
                        height={'100%'}
                        width={'100%'}
                    />
                </div>
                <div className={'send-comment-container'}>
                    {
                        isSent
                            ? <span className={'message-is-sent'}>{t('tickets:your_comment_is_sent')}</span>
                            : (
                                <>
                                    <Component
                                        componentName={'Input'}
                                        placeholder={t('tickets:your_comment')}
                                        onChange={setLogComment}
                                        multiline={6}
                                        emptyInput={emptyInput}
                                        rules={['maxLength-512']}
                                        showAttachmentUploader
                                        uploadedFiles={uploadedFiles}
                                        setUploadedFiles={value => setUploadedFiles(value)}
                                        isAnonymous={isEmpty(currentUser)}
                                    />
                                    <div
                                        className={classnames(
                                            'send-button',
                                            'without-share-buttons',
                                            disableSendComment && 'disabled-button'
                                        )}
                                    >
                                        <Tooltip
                                            classes={{ tooltip: 'table-tooltip' }}
                                            placement={'top'}
                                            title={t('tickets:add_the_comment_to_ticket')}
                                        >
                                            <Button
                                                className={classnames(
                                                    'send-on-my-button',
                                                    'with-paper-plane-button'
                                                )}
                                                onClick={sendMessage}
                                                color="primary"
                                                disabled={disableSendComment}
                                            >
                                                <Component componentName={'Icon'} type={'send'} />
                                                <span>{t('common:send')}</span>
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </>
                            )
                    }
                </div>
            </div>
        </div>
    );
};

export default TeamsCommentForm;


// import all jsonFiles (the name of the file must be the same as the incident name)
const importAllJsonFiles = r => {
    const jsonFiles = {};
    r.keys().forEach(item => { jsonFiles[item.replace('./', '')] = r(item); });
    return jsonFiles;
};
const jsonFiles = importAllJsonFiles(require.context('./assets/incidentsForm', false, /\.(json)$/));

const relatedSvg = {
    'soap-rubbing-hands': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1627375284/incident_qrcode_declaratif/soap-rubbing-hands.svg',
    'hand-sanitizer': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1627368997/incident_qrcode_declaratif/hand-sanitizer.svg',
    'wiping-hands': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1627379039/incident_qrcode_declaratif/wiping-hands.svg',
    'water-drop': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1627375336/incident_qrcode_declaratif/water-drop.svg',
    'light-bulb': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1627373986/incident_qrcode_declaratif/light-bulb.svg',
    'hot-thermometer': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1627374507/incident_qrcode_declaratif/hot-thermometer.svg',
    wifi: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1627369128/incident_qrcode_declaratif/wifi.svg',
    plug: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1627374938/incident_qrcode_declaratif/plug.svg',
    bin: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1627375178/incident_qrcode_declaratif/bin.svg',
    projector: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1627374106/incident_qrcode_declaratif/projector.svg',
    'broom-cleaning': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1626987468/incident_qrcode_declaratif/broom-cleaning.svg',
    dissatisfied: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1640942869/incident_qrcode_declaratif/dissatisfied.svg',
    satisfied: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1640942412/incident_qrcode_declaratif/satisfied.svg',
    question: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1626987468/incident_qrcode_declaratif/question.svg',
    'remote-control': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1645202095/incident_qrcode_declaratif/remote-control.svg',
    phone: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1645202615/incident_qrcode_declaratif/phone.svg',
    screen: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1645535115/incident_qrcode_declaratif/screen.svg',
    other: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1641922090/incident_qrcode_declaratif/other.svg',
    'toilet-paper': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1646052311/incident_qrcode_declaratif/toilet-paper.svg',
    'doors-windows': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1651157674/incident_qrcode_declaratif/doors-windows.svg',
    'green-spaces': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1651157901/incident_qrcode_declaratif/green-spaces.svg',
    elevator: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1651158019/incident_qrcode_declaratif/elevator.svg',
    'broken-pipe': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1651158115/incident_qrcode_declaratif/broken-pipe.svg',
    'sanitary-equipment': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1651158215/incident_qrcode_declaratif/sanitary-equipment.svg',
    disinsectisation: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1651158639/incident_qrcode_declaratif/disinsectisation.svg',
    'coffee-machine': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1652348620/incident_qrcode_declaratif/coffee-machine.svg',
    'candy-machine': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1652348932/incident_qrcode_declaratif/candy-machine.svg',
    glass: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1652349106/incident_qrcode_declaratif/glass.svg',
    kettle: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1652349235/incident_qrcode_declaratif/kettle.svg',
    'paper-waste': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1652349425/incident_qrcode_declaratif/paper-waste.svg',
    scan: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1652349760/incident_qrcode_declaratif/scan.svg',
    'user-scan': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1652349915/incident_qrcode_declaratif/user-scan.svg',
    furniture: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1652350337/incident_qrcode_declaratif/furniture.svg',
    connectors: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1652350157/incident_qrcode_declaratif/connectors.svg',
    printer: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1652349558/incident_qrcode_declaratif/printer.svg',
    'technical-problem': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1652803313/incident_qrcode_declaratif/technical-problem.svg',
    'coffee-cup': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1653659826/incident_qrcode_declaratif/coffee-cup.svg',
    'tea-cup': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1653646049/incident_qrcode_declaratif/tea-cup.svg',
    avatar: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1653659355/incident_qrcode_declaratif/avatar.svg',
    'coffee-corner': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1653661096/incident_qrcode_declaratif/coffee-corner.svg',
    cup: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1653912825/incident_qrcode_declaratif/cup.svg',
    recycling: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1656331665/incident_qrcode_declaratif/recycling.svg',
    error: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1656331672/incident_qrcode_declaratif/error.svg',
    bell: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1657179864/incident_qrcode_declaratif/bell.svg',
    newspaper: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1657179986/incident_qrcode_declaratif/newspaper.svg',
    'window-curtain': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1663088274/incident_qrcode_declaratif/window-curtain.svg',
    building: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1663088453/incident_qrcode_declaratif/building.svg',
    closet: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1663088680/incident_qrcode_declaratif/closet.svg',
    notebook: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1657179978/incident_qrcode_declaratif/notebook.svg',
    key: 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677160123/incident_qrcode_declaratif/key.svg',
    'gas-pump': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1686561905/incident_qrcode_declaratif/gas-pump.svg',
    'first-aid-kit': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1709645716/incident_qrcode_declaratif/first-aid-kit.svg',
    'coffee-beans-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1663863123/incident_qrcode_declaratif/coffee-beans-flat.svg',
    'salt-pepper-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1663863125/incident_qrcode_declaratif/salt-pepper-flat.svg',
    'milk-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1663863125/incident_qrcode_declaratif/milk-flat.svg',
    'coffee-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1663863123/incident_qrcode_declaratif/coffee-flat.svg',
    'hand-washing-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1663863123/incident_qrcode_declaratif/hand-washing-flat.svg',
    'vacuum-cleaner-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1663863125/incident_qrcode_declaratif/vacuum-cleaner-flat.svg',
    'dishwasher-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1663863123/incident_qrcode_declaratif/dishwasher-flat.svg',
    'coffee-machine-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1663863123/incident_qrcode_declaratif/coffee-machine-flat.svg',
    'glass-water-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1663863123/incident_qrcode_declaratif/glass-water-flat.svg',
    'question-bubble-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1663863126/incident_qrcode_declaratif/question-bubble-flat.svg',
    'dispenser-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1666125930/incident_qrcode_declaratif/dispenser-flat.svg',
    'water-drop-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1666125930/incident_qrcode_declaratif/water-drop-flat.svg',
    'toilet-paper-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1666125930/incident_qrcode_declaratif/toilet-paper-flat.svg',
    'consultation-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1666186985/incident_qrcode_declaratif/consultation-flat.svg',
    'approved-checked-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1666270366/incident_qrcode_declaratif/approved-checked-flat.svg',
    'fork-knife-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1666700757/incident_qrcode_declaratif/fork-knife-flat.svg',
    'computer-mouse-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1669909103/incident_qrcode_declaratif/computer-mouse-flat.svg',
    'adjust-office-chair-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1669909103/incident_qrcode_declaratif/adjust-office-chair-flat.svg',
    'thermometer-morph-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1669909103/incident_qrcode_declaratif/thermometer-morph-flat.svg',
    'projector-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1669909077/incident_qrcode_declaratif/projector-flat.svg',
    'tool-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1669910122/incident_qrcode_declaratif/tool-flat.svg',
    'bulb-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1670919331/incident_qrcode_declaratif/bulb-flat.svg',
    'building-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1670864049/incident_qrcode_declaratif/building-flat.svg',
    'trash-bin-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1670862937/incident_qrcode_declaratif/trash-bin-flat.svg',
    'fridge-morph-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1670864035/incident_qrcode_declaratif/fridge-morph-flat.svg',
    'appointment-schedule-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1670864041/incident_qrcode_declaratif/appointment-schedule-flat.svg',
    'archive-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1670862937/incident_qrcode_declaratif/archive-flat.svg',
    'roof-rain-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1670864066/incident_qrcode_declaratif/roof-rain-flat.svg',
    'elevator-doors-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1670862939/incident_qrcode_declaratif/elevator-doors-flat.svg',
    'roadblock-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1670862939/incident_qrcode_declaratif/roadblock-flat.svg',
    'key-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1670862939/incident_qrcode_declaratif/key-flat.svg',
    'printer-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1670864059/incident_qrcode_declaratif/printer-flat.svg',
    'park-bench-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1670862939/incident_qrcode_declaratif/park-bench-flat.svg',
    'trowel-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1670864072/incident_qrcode_declaratif/trowel-flat.svg',
    'pen-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1674640988/incident_qrcode_declaratif/pen-flat.svg',
    'earphone-telephone-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1674640988/incident_qrcode_declaratif/earphone-telephone-flat.svg',
    'business-card-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1674640988/incident_qrcode_declaratif/business-card-flat.svg',
    'air-pump-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677702307/incident_qrcode_declaratif/air-pump-flat.svg',
    'antibacterial-spray-disinfection-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677702307/incident_qrcode_declaratif/antibacterial-spray-disinfection-flat.svg',
    'interface-settings-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677702305/incident_qrcode_declaratif/interface-settings-flat.svg',
    'chips-snacks-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677702305/incident_qrcode_declaratif/chips-snacks-flat.svg',
    'electric-car-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677702305/incident_qrcode_declaratif/electric-car-flat.svg',
    'burning-fuel-flame-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677702305/incident_qrcode_declaratif/burning-fuel-flame-flat.svg',
    'first-aid-kit-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677702305/incident_qrcode_declaratif/first-aid-kit-flat.svg',
    'juice-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677702305/incident_qrcode_declaratif/juice-flat.svg',
    'microwave-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677702303/incident_qrcode_declaratif/microwave-flat.svg',
    'spa-aromatic-stick-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677702303/incident_qrcode_declaratif/spa-aromatic-stick-flat.svg',
    'sink-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677702303/incident_qrcode_declaratif/sink-flat.svg',
    'wheelchair-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677702303/incident_qrcode_declaratif/wheelchair-flat.svg',
    'lock-unlock-morph-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677702303/incident_qrcode_declaratif/lock-unlock-morph-flat.svg',
    'wifi-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677702303/incident_qrcode_declaratif/wifi-flat.svg',
    'bicycle-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1677787838/incident_qrcode_declaratif/bicycle-flat.svg',
    'scanner-image-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679418358/incident_qrcode_declaratif/scanner-image-flat.svg',
    'paper-waste-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679479116/incident_qrcode_declaratif/paper-waste-flat.svg',
    'pantone-color-palette-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679418377/incident_qrcode_declaratif/pantone-color-palette-flat.svg',
    'gears-settings-double-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679418377/incident_qrcode_declaratif/gears-settings-double-flat.svg',
    'window-shutters-curtain-down-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679418377/incident_qrcode_declaratif/window-shutters-curtain-down-flat.svg',
    'service-alt-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679418377/incident_qrcode_declaratif/service-alt-flat.svg',
    'lost-and-found-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679418377/incident_qrcode_declaratif/lost-and-found-flat.svg',
    'hot-temperature-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679418378/incident_qrcode_declaratif/hot-temperature-flat.svg',
    'fire-extinguisher-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679418378/incident_qrcode_declaratif/fire-extinguisher-flat.svg',
    'envelope-mail-notification-open-morph-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679418378/incident_qrcode_declaratif/envelope-mail-notification-open-morph-flat.svg',
    'electric-power-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679418379/incident_qrcode_declaratif/electric-power-flat.svg',
    'cold-temperature-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679418379/incident_qrcode_declaratif/cold-temperature-flat.svg',
    'bug-pest-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679418379/incident_qrcode_declaratif/bug-pest-flat.svg',
    'shredder-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679504606/incident_qrcode_declaratif/shredder-flat.svg',
    'exclamation-mark-bubble-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679504606/incident_qrcode_declaratif/exclamation-mark-bubble-flat.svg',
    'siren-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679504606/incident_qrcode_declaratif/siren-flat.svg',
    'assembling-delivery-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1679926702/incident_qrcode_declaratif/assembling-delivery-flat.svg',
    'toilet-bowl-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1682621816/incident_qrcode_declaratif/toilet-bowl-flat.svg',
    'light-switch-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1682621816/incident_qrcode_declaratif/light-switch-flat.svg',
    'broom-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1682621816/incident_qrcode_declaratif/broom-flat.svg',
    'alcoholic-licence-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1684760997/incident_qrcode_declaratif/alcoholic-licence-flat.svg',
    'soda-can-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1684760997/incident_qrcode_declaratif/soda-can-flat.svg',
    'bio-mass-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1684760997/incident_qrcode_declaratif/bio-mass-flat.svg',
    'recycling-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1684760997/incident_qrcode_declaratif/recycling-flat.svg',
    'basket-trolley-shopping-card-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1684835333/incident_qrcode_declaratif/basket-trolley-shopping-card-flat.svg',
    'closet-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1685390153/incident_qrcode_declaratif/closet-flat.svg',
    'locked-before-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1685390101/incident_qrcode_declaratif/locked-before-flat.svg',
    'door-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1685903352/incident_qrcode_declaratif/door-flat.svg',
    'scooter-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1685903352/incident_qrcode_declaratif/scooter-flat.svg',
    'furniture-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1686560660/incident_qrcode_declaratif/furniture-flat.svg',
    'washing-dishes-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1686560658/incident_qrcode_declaratif/washing-dishes-flat.svg',
    'sponge-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1686560658/incident_qrcode_declaratif/sponge-flat.svg',
    'open-window-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1686560658/incident_qrcode_declaratif/open-window-flat.svg',
    'makeup-mirror-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1686560658/incident_qrcode_declaratif/makeup-mirror-flat.svg',
    'cleaning-surface-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1686560659/incident_qrcode_declaratif/cleaning-surface-flat.svg',
    'bathtub-mirror-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1686560658/incident_qrcode_declaratif/bathtub-mirror-flat.svg',
    'copy-morph-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687183515/incident_qrcode_declaratif/copy-morph-flat.svg',
    'usb-cable-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687183515/incident_qrcode_declaratif/usb-cable-flat.svg',
    'water-pipe-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687183515/incident_qrcode_declaratif/water-pipe-flat.svg',
    'tv-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687183514/incident_qrcode_declaratif/tv-flat.svg',
    'request-support-service-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687183514/incident_qrcode_declaratif/request-support-service-flat.svg',
    'heating-radiator-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687183514/incident_qrcode_declaratif/heating-radiator-flat.svg',
    'touch-pad-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687183514/incident_qrcode_declaratif/touch-pad-flat.svg',
    'remote-control-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687183513/incident_qrcode_declaratif/remote-control-flat.svg',
    'inkwell-feather-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687183513/incident_qrcode_declaratif/inkwell-feather-flat.svg',
    'avatar-icon-calm-cross-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687183512/incident_qrcode_declaratif/avatar-icon-calm-cross-flat.svg',
    'electric-teapot-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687183512/incident_qrcode_declaratif/electric-teapot-flat.svg',
    'fountain-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687183512/incident_qrcode_declaratif/fountain-flat.svg',
    'crayons-pastels-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687183512/incident_qrcode_declaratif/crayons-pastels-flat.svg',
    'cinema-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687183512/incident_qrcode_declaratif/cinema-flat.svg',
    'airconditioner-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687247131/incident_qrcode_declaratif/airconditioner-flat.svg',
    'hand-washing-step-2-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687247131/incident_qrcode_declaratif/hand-washing-step-2-flat.svg',
    'candy-machine-lottery-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1687247131/incident_qrcode_declaratif/candy-machine-lottery-flat.svg',
    'headphones-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1689258989/incident_qrcode_declaratif/headphones-flat.svg',
    'wire-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1689258987/incident_qrcode_declaratif/wire-flat.svg',
    'computer-display-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1689258987/incident_qrcode_declaratif/computer-display-flat.svg',
    'speaker-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1689258987/incident_qrcode_declaratif/speaker-flat.svg',
    'web-camera-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1689258987/incident_qrcode_declaratif/web-camera-flat.svg',
    'computer-speakers-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1689258987/incident_qrcode_declaratif/computer-speakers-flat.svg',
    'computer-laptop-notebook-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1689258987/incident_qrcode_declaratif/computer-laptop-notebook-flat.svg',
    'towel-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1689444525/incident_qrcode_declaratif/towel-flat.svg',
    'microphone-recording-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1690900559/incident_qrcode_declaratif/microphone-recording-flat.svg',
    'video-conference-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1690900558/incident_qrcode_declaratif/video-conference-flat.svg',
    'signal-streams-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1690900558/incident_qrcode_declaratif/signal-streams-flat.svg',
    'tree-planting-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1692903312/incident_qrcode_declaratif/tree-planting-flat.svg',
    'potted-flower-plant-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1692903312/incident_qrcode_declaratif/potted-flower-plant-flat.svg',
    'cutter-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1692903312/incident_qrcode_declaratif/cutter-flat.svg',
    'water-hose-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1692903311/incident_qrcode_declaratif/water-hose-flat.svg',
    'watering-can-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1692903311/incident_qrcode_declaratif/watering-can-flat.svg',
    'weight-gym-fitness-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1698336685/incident_qrcode_declaratif/weight-gym-fitness-flat.svg',
    'duster-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1698844315/incident_qrcode_declaratif/duster-flat.svg',
    'edit-document-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1698844315/incident_qrcode_declaratif/edit-document-flat.svg',
    'farm-cow-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1698844315/incident_qrcode_declaratif/farm-cow-flat.svg',
    'maple-leaf-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1698844315/incident_qrcode_declaratif/maple-leaf-flat.svg',
    'plate-warm-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1698844315/incident_qrcode_declaratif/plate-warm-flat.svg',
    'wooden-floor-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1698844315/incident_qrcode_declaratif/wooden-floor-flat.svg',
    'kettle-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1704981387/incident_qrcode_declaratif/kettle-flat.svg',
    'tea-bag-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1704981387/incident_qrcode_declaratif/tea-bag-flat.svg',
    'green-tea-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1704981387/incident_qrcode_declaratif/green-tea-flat.svg',
    'ping-pong-table-tennis-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1707829813/incident_qrcode_declaratif/ping-pong-table-tennis-flat.svg',
    'love-care-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1707829812/incident_qrcode_declaratif/love-care-flat.svg',
    'cross-trainer-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1707829811/incident_qrcode_declaratif/cross-trainer-flat.svg',
    'cloakroom-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1708608175/incident_qrcode_declaratif/cloakroom-flat.svg',
    'avatar-man-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1708608175/incident_qrcode_declaratif/avatar-man-flat.svg',
    'avatar-female-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1708608175/incident_qrcode_declaratif/avatar-female-flat.svg',
    'shower-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1708608175/incident_qrcode_declaratif/shower-flat.svg',
    'shield-security-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1714478905/incident_qrcode_declaratif/shield-security-flat.svg',
    'review-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1714478900/incident_qrcode_declaratif/review-flat.svg',
    'error-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1714478888/incident_qrcode_declaratif/error-flat.svg',
    'edit-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1714478881/incident_qrcode_declaratif/edit-flat.svg',
    'avatar-user-in-circle-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1714478875/incident_qrcode_declaratif/avatar-user-in-circle-flat.svg',
    'avatar-icon-search-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1714478870/incident_qrcode_declaratif/avatar-icon-search-flat.svg',
    'employee-working-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1718716767/incident_qrcode_declaratif/employee-working-flat.svg',
    'smart-lock-card-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1718716767/incident_qrcode_declaratif/smart-lock-card-flat.svg',
    'disconnect-network-offline-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1720530810/incident_qrcode_declaratif/disconnect-network-offline-flat.svg',
    'crane-truck-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1720530810/incident_qrcode_declaratif/crane-truck-flat.svg',
    'meeting-conference-room-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1720530810/incident_qrcode_declaratif/meeting-conference-room-flat.svg',
    'pin-pad-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1720530810/incident_qrcode_declaratif/pin-pad-flat.svg',
    'charging-station-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1721229708/incident_qrcode_declaratif/charging-station-flat.svg',
    'document-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1721229713/incident_qrcode_declaratif/document-flat.svg',
    'fan-head-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1721229731/incident_qrcode_declaratif/fan-head-flat.svg',
    'baggage-lock-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1725893902/incident_qrcode_declaratif/baggage-lock-flat.svg',
    'cmyk-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1725893904/incident_qrcode_declaratif/cmyk-flat.svg',
    'door-handle-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1725893907/incident_qrcode_declaratif/door-handle-flat.svg',
    'hammer-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1725893910/incident_qrcode_declaratif/hammer-flat.svg',
    'hand-with-phone-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1725893911/incident_qrcode_declaratif/hand-with-phone-flat.svg',
    'lock-unlock-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1725893914/incident_qrcode_declaratif/lock-unlock-flat.svg',
    'blackboard-clean-hover-pinch-flat': 'https://res.cloudinary.com/dkdjhrtdv/image/upload/v1729606726/incident_qrcode_declaratif/blackboard-clean-hover-pinch-flat.svg'
};

export const getAnimationOrImgByIncident = (pageNumber, incidentName, hasCustomColor = false) => {
    const image = relatedSvg[incidentName];
    if (image) {
        if (pageNumber === 1) { return image; }
        return hasCustomColor ? jsonFiles[`${incidentName}-white.json`] : jsonFiles[`${incidentName}.json`];
    }
    if (pageNumber === 1) { return relatedSvg.other; }
    return hasCustomColor ? jsonFiles['other-white.json'] : jsonFiles['other.json'];
};

const disabledModesWhileOffline = ['PASSAGE_VISUALIZATION', 'ISSUE_REPORT', 'DOCUMENT'];
export const shouldDisableQRCodeMode = (mode, isOnline) => !isOnline && disabledModesWhileOffline.includes(mode);

// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

import { useComponentsPool } from '../../../../ComponentsPool';
// Helper
import {
    userCanBeRelatedToRound,
    roundDoesntHaveOnlyQrcodeOrTaqtDevices,
    getFollowersAndAssignees
} from '../../helper';
import { getUserName, getUsersFromIds } from '../../../users/helper';
import { useSecondMainColorTooltip } from '../../../layout/helper';
import {
    removedAssigneesWarningNotif,
    removedFollowersWarningNotif
} from '../../../notification/actions';

const getOptions = users => {
    const returnGroups = [];
    users.forEach(user => {
        returnGroups.push({ id: user.id, value: getUserName(user), hasHighlightedText: user.invite_email, hasEmojiAsIcon: user.invite_email && '✉️' });
    });
    return returnGroups.sort((a, b) => {
        if (a.value > b.value) return 1;
        if (a.value < b.value) return -1;
        return 0;
    });
};
const SelectRoundUsers = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const users = useSelector(state => state.users.list);
    const devices = useSelector(state => state.devices.list);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const tooltipStyle = useSecondMainColorTooltip();

    useEffect(() => {
        const roundUsers = getFollowersAndAssignees(users, devices, props.deviceIds, props.usersFieldType);
        setOptions(getOptions(roundUsers));

        // everytime the round's steps are modified and roundUsers are already selected
        // => check that the round users are still in at least one of the building (of the steps)
        // if not remove them from the roundUsers and put display a notif
        if (props.selected) {
            const newRoundUsers = [];
            const deleteRoundUsers = [];
            const roundUsersList = getUsersFromIds(users, props.selected);
            roundUsersList.forEach(roundUser => {
                // assignees and followers (with mail) have the same rights
                if (userCanBeRelatedToRound(roundUser, props.usersFieldType, devices, props.deviceIds)) {
                    newRoundUsers.push(roundUser);
                } else {
                    deleteRoundUsers.push(roundUser);
                }
            });
            if (deleteRoundUsers.length > 0) {
                props.onChange(
                    props.usersFieldType === 'assignees'
                        ? newRoundUsers.map(user => user.id)
                        : {
                            followers_ids: newRoundUsers.filter(user => !user.invite_email).map(user => user.id),
                            invite_followers_ids: newRoundUsers.filter(user => user.invite_email).map(user => user.id)
                        }
                );
                if (props.usersFieldType === 'assignees') {
                    dispatch(removedAssigneesWarningNotif(deleteRoundUsers.length));
                } else {
                    dispatch(removedFollowersWarningNotif(deleteRoundUsers.length));
                }
            }
        }
    }, [props.deviceIds, props.onChange]);

    useEffect(() => {
        setSelected(getOptions(getUsersFromIds(users, props.selected)));
    }, [props.selected]);

    const hasDisabledText = () => {
        if (props.deviceIds.length === 0) {
            return t(`rounds:${props.usersFieldType === 'assignees' ? 'round_no_step_select_for_assignees_field' : 'round_no_step_select_for_followers_field'}`);
        }
        if (props.usersFieldType === 'assignees' && roundDoesntHaveOnlyQrcodeOrTaqtDevices(devices, props.deviceIds)) {
            return t('rounds:round_no_assigned_user_field');
        }
        return false;
    };

    return (
        <Tooltip
            placement={'top'}
            classes={tooltipStyle}
            title={hasDisabledText() ? <Component componentName={'Description'} content={<Trans>{hasDisabledText()}</Trans>} /> : ''}
        >
            {/* required div for the tooltip to work*/}
            <div>
                <Component
                    componentName={'Select'}
                    multiple
                    placeHolder={''}
                    onChange={usersSelected => {
                        props.onChange(
                            props.usersFieldType === 'assignees'
                                ? usersSelected.map(user => user.id)
                                : {
                                    followers_ids: usersSelected.filter(user => !user.hasEmojiAsIcon).map(user => user.id),
                                    invite_followers_ids: usersSelected.filter(user => user.hasEmojiAsIcon).map(user => user.id)
                                }
                        );
                    }}
                    options={options}
                    renderValue={'chip'}
                    showSelectAll
                    selected={selected}
                    emptyChip={selected}
                    disabled={props.disabled ? props.disabled : hasDisabledText()}
                    chipRenderLimit={props.chipsRenderLimit ? props.chipsRenderLimit : 3}
                />
            </div>
        </Tooltip>
    );
};

export default SelectRoundUsers;


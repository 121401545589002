// Libs
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import { useModal } from '../../../modal/ModalContext';
import { useFootBar } from '../../../footbar/FootBarContext';
import { useFormState } from '../../../layout/forms/api/useFormState';

// Helper
import { concatTickets } from '../../helper';
// Actions
import { editTicket } from '../../actions';
import { getGroupList } from '../../../groups/actions';
import { removeParamFromURL } from '../../../core/helper';

export default () => {
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const tickets = useSelector(state => state.tickets);
    const modal = useModal();
    const footBar = useFootBar();
    const { formState } = useFormState();
    const list = modal.getWithFootBar() ? [modal.getItem()] : footBar.getList();

    const switchButtonCallback = () => {
        return dispatch(editTicket(formState?.id, formState)).then(() => {
            dispatch(getGroupList());
            // Clean the ticket key from the page URL
            const cleanedURL = removeParamFromURL(window.location.href, 'key');
            window.history.replaceState({}, '', cleanedURL);
        });
    };

    const footbarButtons = useMemo(() => [
        <Component
            key={'1'}
            componentName={'DeleteTicketFootbarButton'}
            list={list}
        />
    ], []);

    return (
        <Component
            componentName={'Footbar'}
            actionButtons={footbarButtons}
            modal={'TicketDetailsPopup'}
            resourceList={concatTickets(tickets.list)}
            switchButtonCallback={switchButtonCallback}
            resourceType={'Ticket'}
            locale={'tickets'}
        />
    );
};

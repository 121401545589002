// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    capitalize, isEmpty, sortBy, cloneDeep, filter
} from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
// Context
import { useFootBar } from '../../footbar/FootBarContext';
import { useModal } from '../../modal/ModalContext';
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import {
    setDeviceShownInDetailPopup,
    getPreviewDeviceData,
    getLastReceivedDeviceData
} from '../actions';
// Helper
import { filterTable } from '../../navbar/helper';
import createLoadingSelector from '../../layout/actions';

import { getObjectType } from '../../layout/helper';
import { getGroup, getPreviewDeviceGroupData } from '../../groups/actions';


const customFilterFunctions = {
    device: props => filter(props.list, obj => obj.device_id === props.id || obj.group_id === props.id),
    group: props => filter(props.list, obj => obj.group_id === props.id
        || obj.custom_field?.batiment_group_id === props.id
        || obj.custom_field?.floor_group_id === props.id
        || obj.custom_field?.position_group_id === props.id
        || obj.custom_field?.skill_group_id === props.id
    )
};

const filterGroup = (filters, list, props) => {
    const newFilters = cloneDeep(filters);
    let newList = cloneDeep(list);

    newFilters.forEach(paramFilter => {
        if (paramFilter.filterFunction) {
            newList = paramFilter.filterFunction(list);
        }
    });

    return filterTable(
        filter(filters, f => f.type !== 'defaultOptions'),
        newList,
        [],
        props.devicesList,
        ['device', 'group'],
        customFilterFunctions,
        true
    );
};

const loadingSelector = createLoadingSelector(['GET_DEVICE_DATA_PREVIEW', 'GET_DEVICES_EXPORT', 'GET_GROUPS_EXPORT', 'GET_LAST_RECEIVED_DEVICE_DATA']);

const sortDeviceAndGroupByName = (a, b) => {
    const aName = a.group_name || a.device_name;
    const bName = b.group_name || b.device_name;
    if (aName < bName) return -1;
    if (aName > bName) return 1;
    return 0;
};

const DevicesTable = props => {
    const devicesList = useSelector(state => state.devices.list);
    const deviceGroups = useSelector(state => state.groups.device_groups || []);
    const configuredTableColumns = useSelector(state => sortBy(state.devices.configuredTableColumns, 'weight'));
    const table = useSelector(state => state.core.table);
    const footBar = useFootBar();
    const modal = useModal();
    const dispatch = useDispatch();
    const filters = useSelector(state => state.navbar.filters);
    const [t] = useTranslation();
    const [devices, setDevices] = useState(devicesList.concat(deviceGroups).sort(sortDeviceAndGroupByName));
    const [selectable, setSelectable] = useState([]);
    const [columnExtensions] = useState([]);
    const { getComponentFile, Component } = useComponentsPool();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));

    const prepareColumn = (column, count) => {
        let title = t(column.title, {count});
        if (column.isCapitalized) {
            title = capitalize(title);
        }
        if (column.formTitle) {
            title = (
                <div className={'table-head-title'}>
                    <span>{title}</span>
                    <em>{`(${count})`}</em>
                </div>
            );
        }
        return {
            name: column.name,
            title,
            component: column.component ? getComponentFile(column.component) : null
        };
    };
    const DeviceTableColumns = configuredTableColumns.map(column => prepareColumn(column, devices.length));

    useEffect(() => {
        dispatch(getLastReceivedDeviceData());
    }, []);
    // Update list, if data updated
    useEffect(() => {
        setDevices(filterGroup(filters, devicesList.concat(deviceGroups)
            .map(item => {
                setSelectable([...selectable, item.device_id]);
                return {
                    ...item
                };
            }), {devicesList}).sort(sortDeviceAndGroupByName));
    }, [devicesList, filters]);

    const getLogs = async object => {
        const objectType = getObjectType(object, devicesList, deviceGroups);
        if (objectType === 'DEVICE') {
            await dispatch(setDeviceShownInDetailPopup(object.id));
            await dispatch(getPreviewDeviceData(object.id));
            const selected = devicesList.find(item => object.id === item.device_id);
            footBar.close();
            modal.update({ name: 'DeviceDetailsPopup', item: selected, withFootBar: true });
        } else if (objectType === 'GROUP') {
            await dispatch(getGroup(object.id));
            await dispatch(getPreviewDeviceGroupData(object.id, moment().subtract(6, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')));
            const selected = deviceGroups.find(item => object.id === item.group_id);
            footBar.close();
            modal.update({ name: 'GroupDetailsPopup', item: selected, withFootBar: true });
        }
    };

    const toggleSelected = selected => {
        const devicesFiltered = devicesList.filter(device => selected.includes(device.device_id));
        const groupsFiltered = deviceGroups.filter(group => selected.includes(group.group_id));
        footBar.update({ selected, list: devicesFiltered.concat(groupsFiltered), type: 'device' });
    };

    return isEmpty(devicesList.concat(deviceGroups)) ? <Component componentName={'NoData'} type={'devices'} /> : (
        <div className={'table-wrapper'}>
            <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
            <div className={'table-autoSizer'}>
                <AutoSizer disableWidth>
                    {({ height }) => (
                        <div style={{ height }}>
                            <Component
                                componentName={'Table'}
                                selectable={selectable}
                                height={height}
                                toggleSelected={toggleSelected}
                                columns={DeviceTableColumns}
                                columnExtensions={columnExtensions}
                                open={open}
                                isSort={false}
                                list={devices}
                                onRowClick={device => {
                                    if (!table.disableRowClick.includes('device-configured')) {
                                        getLogs(device);
                                        if (props.onRowClick) {
                                            props.onRowClick(device);
                                        }
                                    }
                                }}
                            />
                        </div>
                    )}
                </AutoSizer>
            </div>
        </div>
    );
};

export default DevicesTable;

/* eslint-disable import/first */
import React, { useState } from 'react';
import { addDecorator } from '@storybook/react';
import { action } from '@storybook/addon-actions';
import FormControl from '@material-ui/core/FormControl';
import Input from '../modules/layout/components/Input';
import Icon from '../modules/layout/components/Icon';

export default { title: 'Input' };

addDecorator(storyFn => (
    <FormControl component={'div'} style={{ width: '300px' }}>
        {storyFn()}
    </FormControl>
));

const onChangeState = action('onChangeState');

export const defaultInput = () => (
    <Input
        label={'Default'}
        onChangeState={onChangeState}
    />
);
export const withAutofocus = () => (
    <Input
        autoFocus
        label={'Default'}
        onChangeState={onChangeState}
    />
);
export const disabled = () => (
    <Input
        disabled
        label={'Default'}
        onChangeState={onChangeState}
    />
);
export const required = () => (
    <Input
        required
        label={'Default'}
        onChangeState={onChangeState}
    />
);
export const withControlledValue = () => {
    const [value, setValue] = useState('John Doe');

    const onChange = val => {
        setValue(val);
        onChangeState(val);
    };

    return (
        <>
            <Input
                label={'With controlled value'}
                value={value}
                onChangeState={onChange}
            />
            <Input
                label={'With controlled value'}
                value={value}
                onChangeState={onChange}
            />
        </>
    );
};
export const onlyNumber = () => (
    <Input
        onlyNumber
        label={'Only Number'}
        onChangeState={onChangeState}
    />
);
export const withStartAdornment = () => (
    <Input
        startAdornment={<Icon type={'password'} />}
        label={'With StartAdornment'}
        onChangeState={onChangeState}
    />
);
export const typePassword = () => (
    <Input
        label={'Type password'}
        onChangeState={onChangeState}
        type={'password'}
    />
);
export const withRule = () => (
    <React.Fragment>
        <Input
            rules={['isAcceptableString', 'maxLength-64']}
            label={'With rule'}
            onChangeState={onChangeState}
        />
        <p>{'Type "<" for example'}</p>
    </React.Fragment>
);

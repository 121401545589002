/* eslint-disable import/first */
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { action } from '@storybook/addon-actions';
import { addDecorator } from '@storybook/react';
import Select from '../modules/layout/components/Select';

const options = [
    { id: '1', value: 'Homer Simpson' },
    { id: '2', value: 'Marge Simpson' },
    { id: '3', value: 'Bart Simpson' },
    { id: '4', value: 'Lisa Simpson' },
    { id: '5', value: 'Maggie Simpson' }
];

export default { title: 'Select' };

const onChange = action('onChange');

addDecorator(storyFn => (
    <FormControl component={'div'} style={{ width: '300px' }}>
        {storyFn()}
    </FormControl>
));

export const defaultSelect = () => (
    <Select
        placeHolder={'Default'}
        onChange={onChange}
        options={options}
    />
);
export const selectWithRequired = () => (
    <Select
        required
        placeHolder={'Required'}
        onChange={onChange}
        options={options}
    />
);
export const disabledSelect = () => (
    <Select
        disabled
        placeHolder={'Disabled'}
        onChange={onChange}
        options={options}
    />
);
export const multipleValues = () => (
    <Select
        multiple
        placeHolder={'Multiple Values'}
        onChange={onChange}
        options={options}
    />
);
export const chipRenderLimitation = () => (
    <Select
        multiple
        placeHolder={'Multiple Values'}
        onChange={onChange}
        options={options}
        chipRenderLimit={4}
    />
);
export const multipleValuesWithChip = () => (
    <Select
        multiple
        renderValue={'chip'}
        placeHolder={'Multiple Values With Chip'}
        onChange={onChange}
        options={options}
    />
);
export const preselectedValues = () => (
    <Select
        multiple
        renderValue={'chip'}
        placeHolder={'Preselected Values'}
        onChange={onChange}
        options={options}
        selected={[{ id: '1', value: 'Homer Simpson' }]}
    />
);
export const selectAllOptionEnabled = () => (
    <Select
        multiple
        renderValue={'chip'}
        placeHolder={'"Select All" in options'}
        onChange={onChange}
        options={options}
        showSelectAll
    />
);
export const placeholderInOptions = () => (
    <Select
        multiple
        renderValue={'chip'}
        placeHolder={'Placeholder in options'}
        onChange={onChange}
        options={options}
        isPlaceHolderToOption
    />
);
export const filledSelect = () => (
    <Select
        multiple
        renderValue={'chip'}
        placeHolder={'Filled select'}
        onChange={onChange}
        options={options}
        isFilled
    />
);
export const checkboxToOptions = () => (
    <Select
        multiple
        renderValue={'chip'}
        placeHolder={'Checkbox to options'}
        onChange={onChange}
        options={options}
        isCheckboxToOption
    />
);

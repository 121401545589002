import update from 'immutability-helper';

const initialState = () => ({
    list: []
});

const messagingReducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'GET_MESSAGING_USERS_LIST_FULFILLED':
            return update(state, { list: { $push: action.payload } });

        default:
            return state;
    }
};

export default messagingReducer;

import React, { createContext, useState } from 'react';

export const MainContext = createContext(false);

function MainProvider({ children }) {
    const [currentComponentOnTop, setCurrentComponentOnTop] = useState(null);

    return (
        <MainContext.Provider value={[currentComponentOnTop, setCurrentComponentOnTop]}>
            {children}
        </MainContext.Provider>
    );
}

export default MainProvider;
// Lib
import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { useComponentsPool } from '../../../ComponentsPool';
import { checkSecurityCode, checkWorkspaceExist, createWorkspace } from '../../workspace/actions';
import createLoadingSelector from '../../layout/actions';
import { Toast } from '../../notification/components';
import PageHeader from '../../authentication/components/PageHeader';

const loadingSelector = createLoadingSelector(['CHECK_SECURITY_CODE', 'CREATE_WORKSPACE', 'CHECK_WORKSPACE_NAME']);

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const WorkspaceCreationPage = () => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));

    const [workspaceName, setWorkspaceName] = useState('');
    const [securityCode, setSecurityCode] = useState('');
    const [authorName, setAuthorName] = useState('');

    const createWorkspaceFromName = () => {
        dispatch(checkSecurityCode(securityCode))
            .then(() => {
                dispatch(checkWorkspaceExist(workspaceName))
                    .then(() => {
                        dispatch(createWorkspace(workspaceName, securityCode, authorName))
                            .then(async response => {
                                const newWorkspaceName = response?.value?.data?.workspace_slug;
                                if (newWorkspaceName) {
                                    await sleep(2000);
                                    window.location.href = `https://${newWorkspaceName}.app.${DOMAIN_NAME}`;
                                } else {
                                    toast(
                                        <Toast
                                            isConfirm
                                            message={t('notifications:create_workspace_error')}
                                            icon={'frown'}
                                            type={'error'}
                                        />, {
                                            position: toast.POSITION.BOTTOM_LEFT,
                                            className: 'normal',
                                            bodyClassName: 'notification-body grow-font-size',
                                            progressClassName: 'error-custom-progress-bar'
                                        }
                                    );
                                }
                            });
                    });
            });
    };

    return (
        <div className="content">
            <div className="step-wrapper">
                <div className={'iot-rocket-form home-form login-form'}>
                    <PageHeader text={t('common:create_workspace_text')} subText={' '} />
                    <div className={'form-wrapper'}>
                        <Tooltip title={t('common:workspace_name_explanations')}>
                            <Component
                                componentName={'Input'}
                                autoFocus
                                required
                                startAdornment={<Component componentName={'Icon'} type={'workspace'} />}
                                label={t('common:workspace_name')}
                                onChange={setWorkspaceName}
                                value={workspaceName}
                            />
                        </Tooltip>
                        <Component
                            componentName={'Input'}
                            required
                            type={'password'}
                            startAdornment={<Component componentName={'Icon'} type={'password'} />}
                            label={t('forms:workspace_security_code')}
                            onChange={setSecurityCode}
                        />
                        <Component
                            componentName={'Input'}
                            required
                            startAdornment={<Component componentName={'Icon'} type={'user'} />}
                            label={t('workspaces:your_name')}
                            onChange={setAuthorName}
                            value={authorName}
                        />
                        <div className="submit-button-wrapper login-button">
                            <Component
                                componentName={'Button'}
                                disabled={
                                    isEmpty(workspaceName)
                                    || isEmpty(securityCode)
                                    || isEmpty(authorName)
                                    || workspaceName === 'error_field'
                                    || securityCode === 'error_field'
                                    || authorName === 'error_field'
                                }
                                onClick={createWorkspaceFromName}
                                type={'submit'}
                                text={t('common:create_workspace')}
                                loading={isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkspaceCreationPage;

// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useComponentsPool } from '../../../ComponentsPool';
import SimpleDialog from '../../modal/Components/SimpleDialog';
import createLoadingSelector from '../../layout/actions';
import { getQRCodeDocumentContent } from '../actions';
import FileViewer from '../../documents/components/FileViewer';

const loadingSelector = createLoadingSelector(['GET_QRCODE_DOCUMENT_CONTENT']);
const MobileDocumentQRCodeFileViewer = props => {
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const [document, setDocument] = useState();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const isLogged = useSelector(state => state.authentication.isLogged);

    useEffect(() => {
        if (props.device?.device_id) {
            dispatch(getQRCodeDocumentContent(props.device?.device_id, props.fileId, !isLogged)).then(res => setDocument(res.value));
        }
    }, []);

    return (
        <>
            {isLoading && <Component componentName={'LoaderBarTop'} isLoading={isLoading} />}
            <SimpleDialog
                className={'file-viewer-wrapper-mobile'}
                open={!isLoading}
                closeModal={() => props.closeModal()}
                content={<FileViewer document={document} hasDocumentHeader closeDialog={() => props.closeModal()} />}
            />
        </>
    );
};
export default MobileDocumentQRCodeFileViewer;

// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useComponentsPool } from '../../../ComponentsPool';

const GroupDetailsPopupRight = () => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();

    return (
        <div className="logs">
            <div className={'logs-title'}>
                <h2>{t('devices:configuration')}</h2>
            </div>
            <div className="config_measures_empty">
                <div className="empty-table-wrapper">
                    <Component componentName={'Icon'} type={'not_implemented'} />
                    <span>{t('groups:no_configuration')}</span>
                </div>
            </div>
        </div>
    );
};

export default GroupDetailsPopupRight;

// Libs
// Context
import {
    capitalize, cloneDeep, filter, isEmpty, sortBy
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import classnames from 'classnames';
import { useComponentsPool } from '../../../../ComponentsPool';
import { useFootBar } from '../../../footbar/FootBarContext';
import createLoadingSelector from '../../../layout/actions';
import { useModal } from '../../../modal/ModalContext';
// Helper
import { filterTable } from '../../../navbar/helper';
import { customTicketFilterFunctions, customTicketFiltersByRole, openTicket } from '../../helper';

const filterGroup = (filters, list, currentUserRole) => {
    const newFilters = cloneDeep(filters);
    let newList = cloneDeep(list);

    newFilters.forEach(paramFilter => {
        if (paramFilter.filterFunction) {
            newList = paramFilter.filterFunction(list);
        }
    });

    return filterTable(
        filter(filters, f => f.type !== 'defaultOptions'),
        newList,
        [],
        [],
        customTicketFiltersByRole[currentUserRole],
        customTicketFilterFunctions,
        true
    );
};

const ArchivedTicketsTable = () => {
    const { getComponentFile, Component } = useComponentsPool();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const modal = useModal();
    const archivedTicketsList = useSelector(state => state.tickets.list);
    const filters = useSelector(state => state.navbar.filters);
    const groupList = useSelector(state => state.groups.list);
    const currentUser = useSelector(state => state.users.currentUser);
    const archivedTicketsTableColumns = useSelector(state => sortBy(state.tickets.archivedTicketsTableColumns, 'weight'))?.filter(col => !col.hide_roles?.includes(currentUser.workspace_role?.workspace_role_name));
    const notifications = useSelector(state => state.navbar.notificationsList);
    const [filteredTickets, setFilteredTickets] = useState(archivedTicketsList.find(status => status.id === 'ARCHIVED')?.tickets);
    const [open] = useState(false);

    const prepareColumn = (column, count) => {
        let title = t(column.title, { count });
        if (column.customUserTitle && currentUser.workspace_role?.workspace_role_name === 'User') {
            title = t(column.customUserTitle);
        }
        if (column.isCapitalized) {
            title = capitalize(title);
        }
        if (column.formTitle) {
            title = (
                <div className={'table-head-title'}>
                    <span>{title}</span>
                    <em>{`(${count})`}</em>
                </div>
            );
        }
        return {
            name: column.name,
            title,
            component: column.component ? getComponentFile(column.component) : null
        };
    };

    const ArchivedTicketsTableColumns = archivedTicketsTableColumns.map(column => prepareColumn(column, filteredTickets.length));

    // Update list, if data is updated
    useEffect(() => {
        setFilteredTickets(filterGroup(filters, archivedTicketsList.find(status => status.id === 'ARCHIVED')?.tickets, currentUser.workspace_role.workspace_role_name));
    }, [archivedTicketsList, filters]);

    const footbar = useFootBar();

    return (
        <div className={classnames('table-wrapper', isEmpty(filteredTickets) ? 'table-without-data' : 'archived-ticket-table')}>
            { isEmpty(filteredTickets)
                ? (
                    <div className={'no-data'}>
                        <Component componentName={'NoData'} view={'archived_ticket'} />
                    </div>
                ) : (
                    <div className={'archived-ticket-table-autoSizer'}>
                        <AutoSizer disableWidth>
                            {({ height }) => (
                                <div style={{ height }}>
                                    <Component
                                        disableTableCheckbox
                                        toggleSelected={() => {}} // display the selected column even though it's disable (to add some padding)
                                        componentName={'Table'}
                                        height={height}
                                        open={open}
                                        columns={ArchivedTicketsTableColumns}
                                        list={filteredTickets}
                                        onRowClick={tableFieldsName => openTicket(
                                            null,
                                            dispatch,
                                            footbar,
                                            modal,
                                            groupList,
                                            notifications,
                                            filteredTickets.find(archivedTicket => archivedTicket.id === tableFieldsName.id),
                                            null
                                        )}
                                    />
                                </div>
                            )}
                        </AutoSizer>
                    </div>
                )
            }
        </div>
    );
};

export default ArchivedTicketsTable;

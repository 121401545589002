import axios from 'axios';
import update from 'immutability-helper';

const initialState = () => ({
    isLogged: false,
    isTokenExpired: null,
    accessJWT: null,
    refreshJWT: null,
    registerCheck: {
        isValid: null
    },
    resetPassword: {
        tokenCheck: {
            isValid: null
        }
    },
    formErrors: {}
});

const authReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case 'LOGIN_ERROR':
            localStorage.removeItem('iot_rocket_refresh_JWT');
            localStorage.removeItem('iot_rocket_access_JWT');
            return update(state, {
                formErrors: { $set: action.payload },
                isLogged: { $set: false }
            });
        case 'LOGIN_FULFILLED':
        case 'LOGIN_BY_CRYPTR_FULFILLED':
        case 'REFRESH_TOKENS':
        case 'REFRESH_TOKENS_FULFILLED':
            if (action.payload.response !== undefined && action.payload.response.status === 401) {
                localStorage.removeItem('iot_rocket_refresh_JWT');
                localStorage.removeItem('iot_rocket_access_JWT');
                window.location.href = '/';
                return update(state, {
                    refreshJWT: { $set: null },
                    accessJWT: { $set: null },
                    isLogged: { $set: false },
                    isTokenExpired: { $set: true }
                });
            }
            if (action.payload.refresh) {
                localStorage.setItem('iot_rocket_refresh_JWT', action.payload.refresh);
                localStorage.setItem('iot_rocket_access_JWT', action.payload.access);
                axios.defaults.headers.common.Authorization = `Bearer ${action.payload.access}`;
            } else if (!navigator.onLine) {
                // If no network, then initialize axios with the last acess_token retrieved
                axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('iot_rocket_access_JWT')}`;
            }
            return update(state, {
                refreshJWT: { $set: action.payload.refresh },
                accessJWT: { $set: action.payload.access },
                isLogged: { $set: true },
                isTokenExpired: { $set: false }
            });
        case 'REFRESH_TOKENS_FAILED':
        case 'REFRESH_TOKENS_REJECTED':
            localStorage.removeItem('iot_rocket_refresh_JWT');
            localStorage.removeItem('iot_rocket_access_JWT');
            return update(state, {
                refreshJWT: { $set: null },
                accessJWT: { $set: null },
                isLogged: { $set: false },
                isTokenExpired: { $set: true }
            });
        case 'LOGOUT_FULFILLED':
            localStorage.removeItem('iot_rocket_refresh_JWT');
            localStorage.removeItem('iot_rocket_access_JWT');
            axios.defaults.headers.common.Authorization = null;
            if (!action.payload.preventReload) { window.location.reload(); }
            return update(state, {
                refreshJWT: { $set: '' },
                accessJWT: { $set: '' },
                isLogged: { $set: false }
            });
        case 'CHECK_REGISTER_TOKEN_FAILED':
        case 'CHECK_REGISTER_TOKEN_REJECTED':
        case 'REGISTER_FROM_INVITE_REJECTED':
        case 'REGISTER_FROM_INVITE_FAILED':
            newState.registerCheck.isValid = false;
            break;
        case 'CHECK_REGISTER_TOKEN_FULFILLED':
            newState.registerCheck.isValid = true;
            newState.registerCheck.email = action.payload.data.mail;
            newState.registerCheck.workspace_role_name = action.payload.data.workspace_role_name;
            break;
        case 'CHECK_RESET_PASSWORD_TOKEN_PENDING':
            break;
        case 'RESET_PASSWORD':
            break;
        case 'RESET_PASSWORD_TOKEN_FAILED':
        case 'CHECK_RESET_PASSWORD_TOKEN_FAILED':
        case 'SEND_RESET_PASSWORD_INVITE_FAILED':
        case 'RESET_PASSWORD_REJECTED':
        case 'CHECK_RESET_PASSWORD_TOKEN_REJECTED':
        case 'SEND_RESET_PASSWORD_INVITE_REJECTED':
            newState.resetPassword.tokenCheck.isValid = false;
            break;
        case 'CHECK_RESET_PASSWORD_TOKEN_FULFILLED':
            newState.resetPassword.tokenCheck.isValid = true;
            break;
        case 'SEND_RESET_PASSWORD_INVITE_FULFILLED':
            newState.resetPassword.tokenCheck.isValid = true;
            break;
        default:
            return state;
    }
    return newState;
};

export default authReducer;

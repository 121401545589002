// Libs
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
// Components
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';

// Actions

const tableTooltip = { tooltip: 'table-tooltip' };

const BuildingButton = props => {
    const { Component } = useComponentsPool();
    const [groups, setGroups] = useState(() => props.group_memberships);
    const [buttonLabel, setButtonLabel] = useState(`+${props.group_memberships.length - props.maxVisible}`);

    useEffect(() => {
        setGroups(props.group_memberships);
        setButtonLabel(`+${props.group_memberships.length - props.maxVisible}`);
    }, [props.group_memberships]);

    let icon = false;
    return (
        <div className={'button-wrapper'}>
            { map(groups.slice(0, props.maxVisible), group => {
                icon = <Component componentName={'Icon'} type={`${props.iconType}Group`} />;
                return (
                    <Tooltip
                        placement={'top'}
                        key={group.id}
                        classes={tableTooltip}
                        title={(<Component componentName={'Description'} content={group.name} />)}
                    >
                        <Chip
                            icon={icon}
                            label={group.name}
                            className={classNames(['group-name is-admin-group'])}
                        />
                    </Tooltip>
                );
            })}
            { groups.length > props.maxVisible && (
                <Component componentName={'Popover'} buttonLabel={buttonLabel} fabButton>
                    <div className={'user-group-popover'}>
                        {
                            map(groups.slice(props.maxVisible, groups.length), group => {
                                icon = <Component componentName={'Icon'} type={`${props.iconType}Group`} />;
                                return (
                                    <Tooltip
                                        key={group.id}
                                        classes={tableTooltip}
                                        title={(<Component componentName={'Description'} content={group.name} />)}
                                    >
                                        <Chip
                                            icon={icon}
                                            key={group.id}
                                            label={group.name}
                                            className={classNames(['group-name is-admin-group'])}
                                        />
                                    </Tooltip>
                                );
                            })
                        }
                    </div>
                </Component>
            )}
        </div>
    );
};

export default BuildingButton;

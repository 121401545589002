// Libs
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import SwipeableViews from 'react-swipeable-views';
import { isMobile } from 'react-device-detect';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// Actions
import Fab from '@material-ui/core/Fab';
import { useTranslation } from 'react-i18next';
import createLoadingSelector from '../../../layout/actions';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';
import useUpdateForm from '../../../layout/forms/UseUpdateForm';
import { EVENT_OPEN_TICKET_HISTORIC_MOBILE, segmentTrack } from '../../../layout/helper';

const loadingSelector = createLoadingSelector(['EDIT_TICKET']);
const TicketDetailsPopup = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const tickets = useSelector(state => state.tickets);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const editableFields = useSelector(state => sortBy(state.tickets.editableFields, 'weight'));
    const ticket = useSelector(state => state.tickets.ticketShownInDetailsPopup);
    const { updateState, state } = useUpdateForm([...editableFields, { props: { value: 'title' }}, { props: { value: 'updatedComments' }}], ticket, 'id');
    const [displayedPageIdx, setDisplayedPageIdx] = useState(0);
    const [closeMobileModal, setCloseMobileModal] = useState(false);
    const content = tickets.detailPopupParts.map(part => ({
        width: part.width,
        content: <Component
            componentName={part.component}
            item={props.item}
            updateState={(resource, value) => updateState(resource, value)}
            state={state}
        />
    }));

    if (isMobile) {
        return (
            <Component
                modalName={'ticketDetailsPopup'}
                componentName={'Modal'}
                loading={isLoading}
                removeModalCloseButton
                closeModal={closeMobileModal}
                content={(
                    <>
                        <div className={'tickets-details-tabs'}>
                            <Tabs
                                value={displayedPageIdx}
                                onChange={(e, value) => {
                                    setDisplayedPageIdx(value);
                                    if (value === 1) {
                                        segmentTrack(EVENT_OPEN_TICKET_HISTORIC_MOBILE, { ticketId: ticket.id, historicOpenedViaTab: true });
                                    }
                                }}
                                variant="fullWidth"
                            >
                                <Tab disableRipple value={0} label={t('tickets:ticket_informations')} />
                                <Tab disableRipple value={1} label={t('tickets:historic')} />
                            </Tabs>
                            <Fab
                                className={'topbar-button-close-modal'}
                                onClick={() => setCloseMobileModal(true)}
                            >
                                <Component componentName={'Icon'} type={'close'} />
                            </Fab>
                        </div>
                        <SwipeableViews
                            index={displayedPageIdx}
                            onChangeIndex={currentIndex => {
                                setDisplayedPageIdx(currentIndex);
                                if (currentIndex === 1) segmentTrack(EVENT_OPEN_TICKET_HISTORIC_MOBILE, { ticketId: ticket.id, historicOpenedViaTab: false });
                            }}
                        >
                            {tickets.detailPopupParts.map((part, i) => (
                                <Component
                                    key={`${i}-swipeable`}
                                    componentName={part.component}
                                    item={props.item}
                                    updateState={(resource, value) => updateState(resource, value)}
                                    state={state}
                                />
                            ))}
                        </SwipeableViews>
                    </>
                )}
                contentWidth={'100%'}
                classNames={'tickets-details-popup details-popup side-content'}
            />
        );
    }
    return (
        <Component
            modalName={'ticketDetailsPopup'}
            componentName={'Modal'}
            loading={isLoading}
            content={content}
            contentWidth={'100%'}
            classNames={'tickets-details-popup details-popup'}
        />
    );
};

export default TicketDetailsPopup;

// Libs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';
// Context
import { useComponentsPool } from '../../ComponentsPool';
// SCSS
import './style.changelog.scss';

const Changelog = () => {
    const { Component } = useComponentsPool();
    const changelog = useSelector(state => state.changelogs.latestChangelog);
    const currentUser = useSelector(state => state.users.currentUser);
    const [isRightRoleToSeeChangelog, setIsRightRoleToSeeChangelog] = useState(false);

    useEffect(() => {
        const role = currentUser?.workspace_role?.workspace_role_name;
        setIsRightRoleToSeeChangelog(role === 'Admin' || role === 'Owner');
    }, [currentUser]);

    if (!isMobile
        && isRightRoleToSeeChangelog
        && !isEmpty(changelog)
        && !changelog.current_user_saw_changelog) {
        return <Component componentName={'ChangelogModal'} />;
    }

    return <></>;
};

export default Changelog;

// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Helper
import { getUsersByGroupId } from '../../../users/helper';
// Context
import { useComponentsPool } from '../../../../ComponentsPool';

const GroupUsers = props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const profile = useSelector(state => state.users.currentUser);
    const usersList = useSelector(state => state.users.list);
    const groupsList = useSelector(state => state.groups.list);
    const users = getUsersByGroupId(usersList, groupsList, props.resource.group_id);

    const right = profile.workspace_rights.includes('ADD_TO_GROUP:User') !== undefined
        ? profile.workspace_rights
        : profile.group_rights[props.resource.group_id];

    return (
        <div className={'group-users'}>
            { !isEmpty(users) && (
                <Component
                    componentName={'UserAvatarsGrouped'}
                    usersByGroups={users}
                    {...props.props}
                />
            )}
            { right.includes('ADD_TO_GROUP:User') && (
                <Component
                    componentName={'AddButton'}
                    tooltipDescription={t('descriptions:add_user_to_group')}
                    modalName={'AddUserToGroupPopup'}
                    item={props.resource}
                />
            )}
        </div>
    );
};

export default GroupUsers;

// Libs
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { Backdrop, Tooltip, Fab } from '@material-ui/core';
// Context
import { useFootBar } from '../../footbar/FootBarContext';
import { useModal } from '../../modal/ModalContext';
import { useComponentsPool } from '../../../ComponentsPool';
// Scss
import '../styles/_actionButton.scss';
// Helpers

export default props => {
    const { Component } = useComponentsPool();
    const [t] = useTranslation();
    const modal = useModal();
    const footBar = useFootBar();
    const [open, setOpen] = useState(false);
    const isOpenWorkspaceSettingsDrawer = useSelector(state => state.navbar.workspaceSettingsPanel);

    const hideActionButton = props.isOnTopActionButton // Action button on top of modal, footbar and ws drawer
        ? false
        : (footBar.getIsVisible() || modal.getIsVisible() || isOpenWorkspaceSettingsDrawer) && props.canUserCreate;

    if (hideActionButton) return null;

    return (props.isOnTopActionButton || props.canUserCreate) && (
        <div className={'action-button'}>
            {props.actions && props.actions.length > 0 ? (
                <>
                    <Backdrop open={open || false} />
                    <SpeedDial
                        ariaLabel="SpeedDial tooltip example"
                        style={{ position: 'fixed', bottom: '2rem', right: '2rem' }}
                        icon={props.iconButton}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        open={open}
                    >
                        {props.actions.map(action => (
                            <SpeedDialAction
                                key={action.name}
                                icon={(
                                    <div
                                        tabIndex={0}
                                        role={'button'}
                                        key={action.name}
                                        onClick={() => {
                                            setOpen(false);
                                            if (action.onClick) action.onClick();
                                        }}
                                    >
                                        {action.icon}
                                        {action.name}
                                    </div>
                                )}
                                tooltipTitle={''}
                                // tooltipOpen
                                onClick={() => {
                                    setOpen(false);
                                    if (action.onClick) action.onClick();
                                }}
                                title={''}
                            />
                        ))}
                    </SpeedDial>
                </>
            ) : (
                <Tooltip
                    placement="left"
                    title={<Component componentName={'Description'} className="action_button" content={t(props.tooltipTitle)} />}
                >
                    <Fab aria-label={'Group'} onClick={() => modal.open(props.modalName)}>
                        {props.iconButton}
                    </Fab>
                </Tooltip>
            )}
        </div>
    );
};

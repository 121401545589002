// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useComponentsPool } from '../../../../ComponentsPool';

const TopGraph = props => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();

    if (props.top?.length > 0) {
        return (
            <div className="top-graph-container">
                <span className="top-graph-title">{t(`dashboards:${props.label}`)}</span>
                <div className="top-graph-data">
                    {props.top.map(data => (
                        <div className="incident">
                            <span className="incident-title">{data.name}</span>
                            <span className="incident-quantity">
                                {
                                    `${data.value } ${
                                        data.value <= 1
                                            ? t('tickets:incident')
                                            : t('tickets:incidents')}`
                                }
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return <Component componentName={'GraphNoData'} />;
};

export default TopGraph;
